import { Component } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
  selector: 'timecards-menu',
  template: `
    <span class="mr-1" *ngIf="'Pages' | permission">
      <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='timecards-dashboard' || headerService.display_sub_page===''}" (click)="headerService.displaySubPage('timecards-dashboard')" type="button">
        <fa-icon [icon]="icon.farDashboard" class="mr-1"></fa-icon>
          {{ 'Dashboard' | localize }}
      </button>
    </span>
    <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='timecards-statements'}" (click)="headerService.displaySubPage('timecards-statements')" type="button">
            <fa-icon [icon]="icon.farFileText" class="mr-1"></fa-icon>
            {{ 'Statements' | localize }}
        </button>
    </span>
    <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='timecards-prefilled'}" (click)="headerService.displaySubPage('timecards-prefilled')" type="button">
            <fa-icon [icon]="icon.farFill" class="mr-1"></fa-icon>
            {{ 'Prefilled' | localize }}
        </button>
    </span>
    <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='timecards-in-process'}" (click)="headerService.displaySubPage('timecards-in-process')" type="button">
            <fa-icon [icon]="icon.farTasks" class="mr-1"></fa-icon>
            {{ 'In Process' | localize }}
        </button>
    </span>
    <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='timecards-processed'}" (click)="headerService.displaySubPage('timecards-processed')" type="button">
            <fa-icon [icon]="icon.farThumbsUp" class="mr-1"></fa-icon>
            {{ 'Processed' | localize }}
        </button>
    </span>
    <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='timecards-history'}" (click)="headerService.displaySubPage('timecards-history')" type="button">
            <fa-icon [icon]="icon.farHistory" class="mr-1"></fa-icon>
            {{ 'History' | localize }}
        </button>
    </span>
    
  `,
  styles: ``
})
export class TimecardsMenuComponent extends AppComponentBase {

}
