import { Component } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
  selector: 'requests-menu',
  template: `
    <span class="mr-1">
      <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='requests-dashboard' || headerService.display_sub_page===''}" (click)="headerService.displaySubPage('requests-dashboard')" type="button">
        <fa-icon [icon]="icon.farDashboard" class="mr-1"></fa-icon>
          {{ 'Dashboard' | localize }}
      </button>
    </span>
    <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='requests-scheduled'}" (click)="headerService.displaySubPage('requests-scheduled')" type="button">
            <fa-icon [icon]="icon.farCalendarAlt" class="mr-1"></fa-icon>
            {{ 'Scheduled' | localize }}
        </button>
    </span>
    <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='requests-completed'}" (click)="headerService.displaySubPage('requests-completed')" type="button">
            <fa-icon [icon]="icon.farCheckCircle" class="mr-1"></fa-icon>
            {{ 'Completed' | localize }}
        </button>
    </span>
    <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='requests-cancelled'}" (click)="headerService.displaySubPage('requests-cancelled')" type="button">
            <fa-icon [icon]="icon.farTimesCircle" class="mr-1"></fa-icon>
            {{ 'Cancelled' | localize }}
        </button>
    </span>
    
  `,
  styles: ``
})
export class RequestsMenuComponent extends AppComponentBase {

}
