import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderRoutingModule } from './header-routing.module';
import { HeaderComponent } from './header.component';
import { AppSharedModule } from '@app/shared/app-shared.module';
import { BillingMenuComponent } from './menus/billing-menu.component';
import { TimecardsMenuComponent } from './menus/timecards-menu.component';
import { RequestsMenuComponent } from './menus/requests-menu.component';
import { JobsMenuComponent } from './menus/jobs-menu.component';
import { ProfileMenuComponent } from './menus/profile-menu.component';
import { HomeMenuComponent } from './menus/home-menu.component';

@NgModule({
  declarations: [
    HeaderComponent,
    BillingMenuComponent,
    TimecardsMenuComponent,
    RequestsMenuComponent,
    JobsMenuComponent,
    ProfileMenuComponent,
    HomeMenuComponent,
  ],
  imports: [
    CommonModule,
    HeaderRoutingModule,
    AppSharedModule
  ],
  exports: [
    HeaderComponent
  ]
})
export class HeaderModule { }
