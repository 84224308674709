import { Component } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';

@Component({
  selector: 'home-menu',
  template: `
    <span class="mr-1">
      <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='home-dashboard' || headerService.display_sub_page===''}" (click)="headerService.displaySubPage('home-dashboard')" type="button">
        <fa-icon [icon]="icon.farDashboard" class="mr-1"></fa-icon>
          {{ 'Dashboard' | localize }}
      </button>
    </span>
    <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='home-leave'}" (click)="headerService.displaySubPage('home-leave')" type="button">
            <fa-icon [icon]="icon.farIslandTropical" class="mr-1"></fa-icon>
            {{ 'Leave' | localize }}
        </button>
    </span>
    <span class="mr-1" *ngIf="'Pages' | permission">
        <button class="button-4" [ngClass]="{'n-menu-btn-selected':headerService.display_sub_page==='home-referrals'}" (click)="headerService.displaySubPage('home-referrals')" type="button">
            <fa-icon [icon]="icon.farShare" class="mr-1"></fa-icon>
            {{ 'Referrals' | localize }}
        </button>
    </span>
    
  `,
  styles: ``
})
export class HomeMenuComponent extends AppComponentBase {

}
