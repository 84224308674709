import { Injectable } from '@angular/core';
import { FaIconLibraryInterface } from '@fortawesome/angular-fontawesome';
import { IconDefinition, IconName, IconPack, IconPrefix } from '@fortawesome/fontawesome-svg-core';

import { fa0 as fad0 }   from '@fortawesome/pro-duotone-svg-icons';
import { fa0 as fal0 }   from '@fortawesome/pro-light-svg-icons';
import { fa0 as far0 }   from '@fortawesome/pro-regular-svg-icons';
import { fa0 as fas0 }   from '@fortawesome/pro-solid-svg-icons';
import { fa00 as fad00 }   from '@fortawesome/pro-duotone-svg-icons';
import { fa00 as fal00 }   from '@fortawesome/pro-light-svg-icons';
import { fa00 as far00 }   from '@fortawesome/pro-regular-svg-icons';
import { fa00 as fas00 }   from '@fortawesome/pro-solid-svg-icons';
import { fa1 as fad1 }   from '@fortawesome/pro-duotone-svg-icons';
import { fa1 as fal1 }   from '@fortawesome/pro-light-svg-icons';
import { fa1 as far1 }   from '@fortawesome/pro-regular-svg-icons';
import { fa1 as fas1 }   from '@fortawesome/pro-solid-svg-icons';
import { fa100 as fad100 }   from '@fortawesome/pro-duotone-svg-icons';
import { fa100 as fal100 }   from '@fortawesome/pro-light-svg-icons';
import { fa100 as far100 }   from '@fortawesome/pro-regular-svg-icons';
import { fa100 as fas100 }   from '@fortawesome/pro-solid-svg-icons';
import { fa2 as fad2 }   from '@fortawesome/pro-duotone-svg-icons';
import { fa2 as fal2 }   from '@fortawesome/pro-light-svg-icons';
import { fa2 as far2 }   from '@fortawesome/pro-regular-svg-icons';
import { fa2 as fas2 }   from '@fortawesome/pro-solid-svg-icons';
import { fa3 as fad3 }   from '@fortawesome/pro-duotone-svg-icons';
import { fa3 as fal3 }   from '@fortawesome/pro-light-svg-icons';
import { fa3 as far3 }   from '@fortawesome/pro-regular-svg-icons';
import { fa3 as fas3 }   from '@fortawesome/pro-solid-svg-icons';
import { fa360Degrees as fad360Degrees }   from '@fortawesome/pro-duotone-svg-icons';
import { fa360Degrees as fal360Degrees }   from '@fortawesome/pro-light-svg-icons';
import { fa360Degrees as far360Degrees }   from '@fortawesome/pro-regular-svg-icons';
import { fa360Degrees as fas360Degrees }   from '@fortawesome/pro-solid-svg-icons';
import { fa4 as fad4 }   from '@fortawesome/pro-duotone-svg-icons';
import { fa4 as fal4 }   from '@fortawesome/pro-light-svg-icons';
import { fa4 as far4 }   from '@fortawesome/pro-regular-svg-icons';
import { fa4 as fas4 }   from '@fortawesome/pro-solid-svg-icons';
import { fa5 as fad5 }   from '@fortawesome/pro-duotone-svg-icons';
import { fa5 as fal5 }   from '@fortawesome/pro-light-svg-icons';
import { fa5 as far5 }   from '@fortawesome/pro-regular-svg-icons';
import { fa5 as fas5 }   from '@fortawesome/pro-solid-svg-icons';
import { fa6 as fad6 }   from '@fortawesome/pro-duotone-svg-icons';
import { fa6 as fal6 }   from '@fortawesome/pro-light-svg-icons';
import { fa6 as far6 }   from '@fortawesome/pro-regular-svg-icons';
import { fa6 as fas6 }   from '@fortawesome/pro-solid-svg-icons';
import { fa7 as fad7 }   from '@fortawesome/pro-duotone-svg-icons';
import { fa7 as fal7 }   from '@fortawesome/pro-light-svg-icons';
import { fa7 as far7 }   from '@fortawesome/pro-regular-svg-icons';
import { fa7 as fas7 }   from '@fortawesome/pro-solid-svg-icons';
import { fa8 as fad8 }   from '@fortawesome/pro-duotone-svg-icons';
import { fa8 as fal8 }   from '@fortawesome/pro-light-svg-icons';
import { fa8 as far8 }   from '@fortawesome/pro-regular-svg-icons';
import { fa8 as fas8 }   from '@fortawesome/pro-solid-svg-icons';
import { fa9 as fad9 }   from '@fortawesome/pro-duotone-svg-icons';
import { fa9 as fal9 }   from '@fortawesome/pro-light-svg-icons';
import { fa9 as far9 }   from '@fortawesome/pro-regular-svg-icons';
import { fa9 as fas9 }   from '@fortawesome/pro-solid-svg-icons';
import { faA as fadA }   from '@fortawesome/pro-duotone-svg-icons';
import { faA as falA }   from '@fortawesome/pro-light-svg-icons';
import { faA as farA }   from '@fortawesome/pro-regular-svg-icons';
import { faA as fasA }   from '@fortawesome/pro-solid-svg-icons';
import { faAbacus as fadAbacus }   from '@fortawesome/pro-duotone-svg-icons';
import { faAbacus as falAbacus }   from '@fortawesome/pro-light-svg-icons';
import { faAbacus as farAbacus }   from '@fortawesome/pro-regular-svg-icons';
import { faAbacus as fasAbacus }   from '@fortawesome/pro-solid-svg-icons';
import { faAccentGrave as fadAccentGrave }   from '@fortawesome/pro-duotone-svg-icons';
import { faAccentGrave as falAccentGrave }   from '@fortawesome/pro-light-svg-icons';
import { faAccentGrave as farAccentGrave }   from '@fortawesome/pro-regular-svg-icons';
import { faAccentGrave as fasAccentGrave }   from '@fortawesome/pro-solid-svg-icons';
import { faAcorn as fadAcorn }   from '@fortawesome/pro-duotone-svg-icons';
import { faAcorn as falAcorn }   from '@fortawesome/pro-light-svg-icons';
import { faAcorn as farAcorn }   from '@fortawesome/pro-regular-svg-icons';
import { faAcorn as fasAcorn }   from '@fortawesome/pro-solid-svg-icons';
import { faAd as fadAd }   from '@fortawesome/pro-duotone-svg-icons';
import { faAd as falAd }   from '@fortawesome/pro-light-svg-icons';
import { faAd as farAd }   from '@fortawesome/pro-regular-svg-icons';
import { faAd as fasAd }   from '@fortawesome/pro-solid-svg-icons';
import { faAdd as fadAdd }   from '@fortawesome/pro-duotone-svg-icons';
import { faAdd as falAdd }   from '@fortawesome/pro-light-svg-icons';
import { faAdd as farAdd }   from '@fortawesome/pro-regular-svg-icons';
import { faAdd as fasAdd }   from '@fortawesome/pro-solid-svg-icons';
import { faAddressBook as fadAddressBook }   from '@fortawesome/pro-duotone-svg-icons';
import { faAddressBook as falAddressBook }   from '@fortawesome/pro-light-svg-icons';
import { faAddressBook as farAddressBook }   from '@fortawesome/pro-regular-svg-icons';
import { faAddressBook as fasAddressBook }   from '@fortawesome/pro-solid-svg-icons';
import { faAddressCard as fadAddressCard }   from '@fortawesome/pro-duotone-svg-icons';
import { faAddressCard as falAddressCard }   from '@fortawesome/pro-light-svg-icons';
import { faAddressCard as farAddressCard }   from '@fortawesome/pro-regular-svg-icons';
import { faAddressCard as fasAddressCard }   from '@fortawesome/pro-solid-svg-icons';
import { faAdjust as fadAdjust }   from '@fortawesome/pro-duotone-svg-icons';
import { faAdjust as falAdjust }   from '@fortawesome/pro-light-svg-icons';
import { faAdjust as farAdjust }   from '@fortawesome/pro-regular-svg-icons';
import { faAdjust as fasAdjust }   from '@fortawesome/pro-solid-svg-icons';
import { faAirConditioner as fadAirConditioner }   from '@fortawesome/pro-duotone-svg-icons';
import { faAirConditioner as falAirConditioner }   from '@fortawesome/pro-light-svg-icons';
import { faAirConditioner as farAirConditioner }   from '@fortawesome/pro-regular-svg-icons';
import { faAirConditioner as fasAirConditioner }   from '@fortawesome/pro-solid-svg-icons';
import { faAirFreshener as fadAirFreshener }   from '@fortawesome/pro-duotone-svg-icons';
import { faAirFreshener as falAirFreshener }   from '@fortawesome/pro-light-svg-icons';
import { faAirFreshener as farAirFreshener }   from '@fortawesome/pro-regular-svg-icons';
import { faAirFreshener as fasAirFreshener }   from '@fortawesome/pro-solid-svg-icons';
import { faAirplay as fadAirplay }   from '@fortawesome/pro-duotone-svg-icons';
import { faAirplay as falAirplay }   from '@fortawesome/pro-light-svg-icons';
import { faAirplay as farAirplay }   from '@fortawesome/pro-regular-svg-icons';
import { faAirplay as fasAirplay }   from '@fortawesome/pro-solid-svg-icons';
import { faAlarmClock as fadAlarmClock }   from '@fortawesome/pro-duotone-svg-icons';
import { faAlarmClock as falAlarmClock }   from '@fortawesome/pro-light-svg-icons';
import { faAlarmClock as farAlarmClock }   from '@fortawesome/pro-regular-svg-icons';
import { faAlarmClock as fasAlarmClock }   from '@fortawesome/pro-solid-svg-icons';
import { faAlarmExclamation as fadAlarmExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faAlarmExclamation as falAlarmExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faAlarmExclamation as farAlarmExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faAlarmExclamation as fasAlarmExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faAlarmPlus as fadAlarmPlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faAlarmPlus as falAlarmPlus }   from '@fortawesome/pro-light-svg-icons';
import { faAlarmPlus as farAlarmPlus }   from '@fortawesome/pro-regular-svg-icons';
import { faAlarmPlus as fasAlarmPlus }   from '@fortawesome/pro-solid-svg-icons';
import { faAlarmSnooze as fadAlarmSnooze }   from '@fortawesome/pro-duotone-svg-icons';
import { faAlarmSnooze as falAlarmSnooze }   from '@fortawesome/pro-light-svg-icons';
import { faAlarmSnooze as farAlarmSnooze }   from '@fortawesome/pro-regular-svg-icons';
import { faAlarmSnooze as fasAlarmSnooze }   from '@fortawesome/pro-solid-svg-icons';
import { faAlbum as fadAlbum }   from '@fortawesome/pro-duotone-svg-icons';
import { faAlbum as falAlbum }   from '@fortawesome/pro-light-svg-icons';
import { faAlbum as farAlbum }   from '@fortawesome/pro-regular-svg-icons';
import { faAlbum as fasAlbum }   from '@fortawesome/pro-solid-svg-icons';
import { faAlbumCirclePlus as fadAlbumCirclePlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faAlbumCirclePlus as falAlbumCirclePlus }   from '@fortawesome/pro-light-svg-icons';
import { faAlbumCirclePlus as farAlbumCirclePlus }   from '@fortawesome/pro-regular-svg-icons';
import { faAlbumCirclePlus as fasAlbumCirclePlus }   from '@fortawesome/pro-solid-svg-icons';
import { faAlbumCircleUser as fadAlbumCircleUser }   from '@fortawesome/pro-duotone-svg-icons';
import { faAlbumCircleUser as falAlbumCircleUser }   from '@fortawesome/pro-light-svg-icons';
import { faAlbumCircleUser as farAlbumCircleUser }   from '@fortawesome/pro-regular-svg-icons';
import { faAlbumCircleUser as fasAlbumCircleUser }   from '@fortawesome/pro-solid-svg-icons';
import { faAlbumCollection as fadAlbumCollection }   from '@fortawesome/pro-duotone-svg-icons';
import { faAlbumCollection as falAlbumCollection }   from '@fortawesome/pro-light-svg-icons';
import { faAlbumCollection as farAlbumCollection }   from '@fortawesome/pro-regular-svg-icons';
import { faAlbumCollection as fasAlbumCollection }   from '@fortawesome/pro-solid-svg-icons';
import { faAlbumCollectionCirclePlus as fadAlbumCollectionCirclePlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faAlbumCollectionCirclePlus as falAlbumCollectionCirclePlus }   from '@fortawesome/pro-light-svg-icons';
import { faAlbumCollectionCirclePlus as farAlbumCollectionCirclePlus }   from '@fortawesome/pro-regular-svg-icons';
import { faAlbumCollectionCirclePlus as fasAlbumCollectionCirclePlus }   from '@fortawesome/pro-solid-svg-icons';
import { faAlbumCollectionCircleUser as fadAlbumCollectionCircleUser }   from '@fortawesome/pro-duotone-svg-icons';
import { faAlbumCollectionCircleUser as falAlbumCollectionCircleUser }   from '@fortawesome/pro-light-svg-icons';
import { faAlbumCollectionCircleUser as farAlbumCollectionCircleUser }   from '@fortawesome/pro-regular-svg-icons';
import { faAlbumCollectionCircleUser as fasAlbumCollectionCircleUser }   from '@fortawesome/pro-solid-svg-icons';
import { faAlicorn as fadAlicorn }   from '@fortawesome/pro-duotone-svg-icons';
import { faAlicorn as falAlicorn }   from '@fortawesome/pro-light-svg-icons';
import { faAlicorn as farAlicorn }   from '@fortawesome/pro-regular-svg-icons';
import { faAlicorn as fasAlicorn }   from '@fortawesome/pro-solid-svg-icons';
import { faAlien as fadAlien }   from '@fortawesome/pro-duotone-svg-icons';
import { faAlien as falAlien }   from '@fortawesome/pro-light-svg-icons';
import { faAlien as farAlien }   from '@fortawesome/pro-regular-svg-icons';
import { faAlien as fasAlien }   from '@fortawesome/pro-solid-svg-icons';
import { faAlien8bit as fadAlien8bit }   from '@fortawesome/pro-duotone-svg-icons';
import { faAlien8bit as falAlien8bit }   from '@fortawesome/pro-light-svg-icons';
import { faAlien8bit as farAlien8bit }   from '@fortawesome/pro-regular-svg-icons';
import { faAlien8bit as fasAlien8bit }   from '@fortawesome/pro-solid-svg-icons';
import { faAlienMonster as fadAlienMonster }   from '@fortawesome/pro-duotone-svg-icons';
import { faAlienMonster as falAlienMonster }   from '@fortawesome/pro-light-svg-icons';
import { faAlienMonster as farAlienMonster }   from '@fortawesome/pro-regular-svg-icons';
import { faAlienMonster as fasAlienMonster }   from '@fortawesome/pro-solid-svg-icons';
import { faAlignCenter as fadAlignCenter }   from '@fortawesome/pro-duotone-svg-icons';
import { faAlignCenter as falAlignCenter }   from '@fortawesome/pro-light-svg-icons';
import { faAlignCenter as farAlignCenter }   from '@fortawesome/pro-regular-svg-icons';
import { faAlignCenter as fasAlignCenter }   from '@fortawesome/pro-solid-svg-icons';
import { faAlignJustify as fadAlignJustify }   from '@fortawesome/pro-duotone-svg-icons';
import { faAlignJustify as falAlignJustify }   from '@fortawesome/pro-light-svg-icons';
import { faAlignJustify as farAlignJustify }   from '@fortawesome/pro-regular-svg-icons';
import { faAlignJustify as fasAlignJustify }   from '@fortawesome/pro-solid-svg-icons';
import { faAlignLeft as fadAlignLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faAlignLeft as falAlignLeft }   from '@fortawesome/pro-light-svg-icons';
import { faAlignLeft as farAlignLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faAlignLeft as fasAlignLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faAlignRight as fadAlignRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faAlignRight as falAlignRight }   from '@fortawesome/pro-light-svg-icons';
import { faAlignRight as farAlignRight }   from '@fortawesome/pro-regular-svg-icons';
import { faAlignRight as fasAlignRight }   from '@fortawesome/pro-solid-svg-icons';
import { faAlignSlash as fadAlignSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faAlignSlash as falAlignSlash }   from '@fortawesome/pro-light-svg-icons';
import { faAlignSlash as farAlignSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faAlignSlash as fasAlignSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faAllergies as fadAllergies }   from '@fortawesome/pro-duotone-svg-icons';
import { faAllergies as falAllergies }   from '@fortawesome/pro-light-svg-icons';
import { faAllergies as farAllergies }   from '@fortawesome/pro-regular-svg-icons';
import { faAllergies as fasAllergies }   from '@fortawesome/pro-solid-svg-icons';
import { faAlt as fadAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faAlt as falAlt }   from '@fortawesome/pro-light-svg-icons';
import { faAlt as farAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faAlt as fasAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faAmbulance as fadAmbulance }   from '@fortawesome/pro-duotone-svg-icons';
import { faAmbulance as falAmbulance }   from '@fortawesome/pro-light-svg-icons';
import { faAmbulance as farAmbulance }   from '@fortawesome/pro-regular-svg-icons';
import { faAmbulance as fasAmbulance }   from '@fortawesome/pro-solid-svg-icons';
import { faAmericanSignLanguageInterpreting as fadAmericanSignLanguageInterpreting }   from '@fortawesome/pro-duotone-svg-icons';
import { faAmericanSignLanguageInterpreting as falAmericanSignLanguageInterpreting }   from '@fortawesome/pro-light-svg-icons';
import { faAmericanSignLanguageInterpreting as farAmericanSignLanguageInterpreting }   from '@fortawesome/pro-regular-svg-icons';
import { faAmericanSignLanguageInterpreting as fasAmericanSignLanguageInterpreting }   from '@fortawesome/pro-solid-svg-icons';
import { faAmpersand as fadAmpersand }   from '@fortawesome/pro-duotone-svg-icons';
import { faAmpersand as falAmpersand }   from '@fortawesome/pro-light-svg-icons';
import { faAmpersand as farAmpersand }   from '@fortawesome/pro-regular-svg-icons';
import { faAmpersand as fasAmpersand }   from '@fortawesome/pro-solid-svg-icons';
import { faAmpGuitar as fadAmpGuitar }   from '@fortawesome/pro-duotone-svg-icons';
import { faAmpGuitar as falAmpGuitar }   from '@fortawesome/pro-light-svg-icons';
import { faAmpGuitar as farAmpGuitar }   from '@fortawesome/pro-regular-svg-icons';
import { faAmpGuitar as fasAmpGuitar }   from '@fortawesome/pro-solid-svg-icons';
import { faAnalytics as fadAnalytics }   from '@fortawesome/pro-duotone-svg-icons';
import { faAnalytics as falAnalytics }   from '@fortawesome/pro-light-svg-icons';
import { faAnalytics as farAnalytics }   from '@fortawesome/pro-regular-svg-icons';
import { faAnalytics as fasAnalytics }   from '@fortawesome/pro-solid-svg-icons';
import { faAnchor as fadAnchor }   from '@fortawesome/pro-duotone-svg-icons';
import { faAnchor as falAnchor }   from '@fortawesome/pro-light-svg-icons';
import { faAnchor as farAnchor }   from '@fortawesome/pro-regular-svg-icons';
import { faAnchor as fasAnchor }   from '@fortawesome/pro-solid-svg-icons';
import { faAnchorCircleCheck as fadAnchorCircleCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faAnchorCircleCheck as falAnchorCircleCheck }   from '@fortawesome/pro-light-svg-icons';
import { faAnchorCircleCheck as farAnchorCircleCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faAnchorCircleCheck as fasAnchorCircleCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faAnchorCircleExclamation as fadAnchorCircleExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faAnchorCircleExclamation as falAnchorCircleExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faAnchorCircleExclamation as farAnchorCircleExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faAnchorCircleExclamation as fasAnchorCircleExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faAnchorCircleXmark as fadAnchorCircleXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faAnchorCircleXmark as falAnchorCircleXmark }   from '@fortawesome/pro-light-svg-icons';
import { faAnchorCircleXmark as farAnchorCircleXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faAnchorCircleXmark as fasAnchorCircleXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faAnchorLock as fadAnchorLock }   from '@fortawesome/pro-duotone-svg-icons';
import { faAnchorLock as falAnchorLock }   from '@fortawesome/pro-light-svg-icons';
import { faAnchorLock as farAnchorLock }   from '@fortawesome/pro-regular-svg-icons';
import { faAnchorLock as fasAnchorLock }   from '@fortawesome/pro-solid-svg-icons';
import { faAngel as fadAngel }   from '@fortawesome/pro-duotone-svg-icons';
import { faAngel as falAngel }   from '@fortawesome/pro-light-svg-icons';
import { faAngel as farAngel }   from '@fortawesome/pro-regular-svg-icons';
import { faAngel as fasAngel }   from '@fortawesome/pro-solid-svg-icons';
import { faAngle as fadAngle }   from '@fortawesome/pro-duotone-svg-icons';
import { faAngle as falAngle }   from '@fortawesome/pro-light-svg-icons';
import { faAngle as farAngle }   from '@fortawesome/pro-regular-svg-icons';
import { faAngle as fasAngle }   from '@fortawesome/pro-solid-svg-icons';
import { faAngle90 as fadAngle90 }   from '@fortawesome/pro-duotone-svg-icons';
import { faAngle90 as falAngle90 }   from '@fortawesome/pro-light-svg-icons';
import { faAngle90 as farAngle90 }   from '@fortawesome/pro-regular-svg-icons';
import { faAngle90 as fasAngle90 }   from '@fortawesome/pro-solid-svg-icons';
import { faAngleDoubleDown as fadAngleDoubleDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faAngleDoubleDown as falAngleDoubleDown }   from '@fortawesome/pro-light-svg-icons';
import { faAngleDoubleDown as farAngleDoubleDown }   from '@fortawesome/pro-regular-svg-icons';
import { faAngleDoubleDown as fasAngleDoubleDown }   from '@fortawesome/pro-solid-svg-icons';
import { faAngleDoubleLeft as fadAngleDoubleLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faAngleDoubleLeft as falAngleDoubleLeft }   from '@fortawesome/pro-light-svg-icons';
import { faAngleDoubleLeft as farAngleDoubleLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faAngleDoubleLeft as fasAngleDoubleLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faAngleDoubleRight as fadAngleDoubleRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faAngleDoubleRight as falAngleDoubleRight }   from '@fortawesome/pro-light-svg-icons';
import { faAngleDoubleRight as farAngleDoubleRight }   from '@fortawesome/pro-regular-svg-icons';
import { faAngleDoubleRight as fasAngleDoubleRight }   from '@fortawesome/pro-solid-svg-icons';
import { faAngleDoubleUp as fadAngleDoubleUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faAngleDoubleUp as falAngleDoubleUp }   from '@fortawesome/pro-light-svg-icons';
import { faAngleDoubleUp as farAngleDoubleUp }   from '@fortawesome/pro-regular-svg-icons';
import { faAngleDoubleUp as fasAngleDoubleUp }   from '@fortawesome/pro-solid-svg-icons';
import { faAngleDown as fadAngleDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faAngleDown as falAngleDown }   from '@fortawesome/pro-light-svg-icons';
import { faAngleDown as farAngleDown }   from '@fortawesome/pro-regular-svg-icons';
import { faAngleDown as fasAngleDown }   from '@fortawesome/pro-solid-svg-icons';
import { faAngleLeft as fadAngleLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faAngleLeft as falAngleLeft }   from '@fortawesome/pro-light-svg-icons';
import { faAngleLeft as farAngleLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faAngleLeft as fasAngleLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faAngleRight as fadAngleRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faAngleRight as falAngleRight }   from '@fortawesome/pro-light-svg-icons';
import { faAngleRight as farAngleRight }   from '@fortawesome/pro-regular-svg-icons';
import { faAngleRight as fasAngleRight }   from '@fortawesome/pro-solid-svg-icons';
import { faAnglesDown as fadAnglesDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faAnglesDown as falAnglesDown }   from '@fortawesome/pro-light-svg-icons';
import { faAnglesDown as farAnglesDown }   from '@fortawesome/pro-regular-svg-icons';
import { faAnglesDown as fasAnglesDown }   from '@fortawesome/pro-solid-svg-icons';
import { faAnglesLeft as fadAnglesLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faAnglesLeft as falAnglesLeft }   from '@fortawesome/pro-light-svg-icons';
import { faAnglesLeft as farAnglesLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faAnglesLeft as fasAnglesLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faAnglesRight as fadAnglesRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faAnglesRight as falAnglesRight }   from '@fortawesome/pro-light-svg-icons';
import { faAnglesRight as farAnglesRight }   from '@fortawesome/pro-regular-svg-icons';
import { faAnglesRight as fasAnglesRight }   from '@fortawesome/pro-solid-svg-icons';
import { faAnglesUp as fadAnglesUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faAnglesUp as falAnglesUp }   from '@fortawesome/pro-light-svg-icons';
import { faAnglesUp as farAnglesUp }   from '@fortawesome/pro-regular-svg-icons';
import { faAnglesUp as fasAnglesUp }   from '@fortawesome/pro-solid-svg-icons';
import { faAngleUp as fadAngleUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faAngleUp as falAngleUp }   from '@fortawesome/pro-light-svg-icons';
import { faAngleUp as farAngleUp }   from '@fortawesome/pro-regular-svg-icons';
import { faAngleUp as fasAngleUp }   from '@fortawesome/pro-solid-svg-icons';
import { faAngry as fadAngry }   from '@fortawesome/pro-duotone-svg-icons';
import { faAngry as falAngry }   from '@fortawesome/pro-light-svg-icons';
import { faAngry as farAngry }   from '@fortawesome/pro-regular-svg-icons';
import { faAngry as fasAngry }   from '@fortawesome/pro-solid-svg-icons';
import { faAnkh as fadAnkh }   from '@fortawesome/pro-duotone-svg-icons';
import { faAnkh as falAnkh }   from '@fortawesome/pro-light-svg-icons';
import { faAnkh as farAnkh }   from '@fortawesome/pro-regular-svg-icons';
import { faAnkh as fasAnkh }   from '@fortawesome/pro-solid-svg-icons';
import { faApartment as fadApartment }   from '@fortawesome/pro-duotone-svg-icons';
import { faApartment as falApartment }   from '@fortawesome/pro-light-svg-icons';
import { faApartment as farApartment }   from '@fortawesome/pro-regular-svg-icons';
import { faApartment as fasApartment }   from '@fortawesome/pro-solid-svg-icons';
import { faAperture as fadAperture }   from '@fortawesome/pro-duotone-svg-icons';
import { faAperture as falAperture }   from '@fortawesome/pro-light-svg-icons';
import { faAperture as farAperture }   from '@fortawesome/pro-regular-svg-icons';
import { faAperture as fasAperture }   from '@fortawesome/pro-solid-svg-icons';
import { faApostrophe as fadApostrophe }   from '@fortawesome/pro-duotone-svg-icons';
import { faApostrophe as falApostrophe }   from '@fortawesome/pro-light-svg-icons';
import { faApostrophe as farApostrophe }   from '@fortawesome/pro-regular-svg-icons';
import { faApostrophe as fasApostrophe }   from '@fortawesome/pro-solid-svg-icons';
import { faAppleAlt as fadAppleAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faAppleAlt as falAppleAlt }   from '@fortawesome/pro-light-svg-icons';
import { faAppleAlt as farAppleAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faAppleAlt as fasAppleAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faAppleCore as fadAppleCore }   from '@fortawesome/pro-duotone-svg-icons';
import { faAppleCore as falAppleCore }   from '@fortawesome/pro-light-svg-icons';
import { faAppleCore as farAppleCore }   from '@fortawesome/pro-regular-svg-icons';
import { faAppleCore as fasAppleCore }   from '@fortawesome/pro-solid-svg-icons';
import { faAppleCrate as fadAppleCrate }   from '@fortawesome/pro-duotone-svg-icons';
import { faAppleCrate as falAppleCrate }   from '@fortawesome/pro-light-svg-icons';
import { faAppleCrate as farAppleCrate }   from '@fortawesome/pro-regular-svg-icons';
import { faAppleCrate as fasAppleCrate }   from '@fortawesome/pro-solid-svg-icons';
import { faAppleWhole as fadAppleWhole }   from '@fortawesome/pro-duotone-svg-icons';
import { faAppleWhole as falAppleWhole }   from '@fortawesome/pro-light-svg-icons';
import { faAppleWhole as farAppleWhole }   from '@fortawesome/pro-regular-svg-icons';
import { faAppleWhole as fasAppleWhole }   from '@fortawesome/pro-solid-svg-icons';
import { faArchive as fadArchive }   from '@fortawesome/pro-duotone-svg-icons';
import { faArchive as falArchive }   from '@fortawesome/pro-light-svg-icons';
import { faArchive as farArchive }   from '@fortawesome/pro-regular-svg-icons';
import { faArchive as fasArchive }   from '@fortawesome/pro-solid-svg-icons';
import { faArchway as fadArchway }   from '@fortawesome/pro-duotone-svg-icons';
import { faArchway as falArchway }   from '@fortawesome/pro-light-svg-icons';
import { faArchway as farArchway }   from '@fortawesome/pro-regular-svg-icons';
import { faArchway as fasArchway }   from '@fortawesome/pro-solid-svg-icons';
import { faAreaChart as fadAreaChart }   from '@fortawesome/pro-duotone-svg-icons';
import { faAreaChart as falAreaChart }   from '@fortawesome/pro-light-svg-icons';
import { faAreaChart as farAreaChart }   from '@fortawesome/pro-regular-svg-icons';
import { faAreaChart as fasAreaChart }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowAltCircleDown as fadArrowAltCircleDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowAltCircleDown as falArrowAltCircleDown }   from '@fortawesome/pro-light-svg-icons';
import { faArrowAltCircleDown as farArrowAltCircleDown }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowAltCircleDown as fasArrowAltCircleDown }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowAltCircleLeft as fadArrowAltCircleLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowAltCircleLeft as falArrowAltCircleLeft }   from '@fortawesome/pro-light-svg-icons';
import { faArrowAltCircleLeft as farArrowAltCircleLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowAltCircleLeft as fasArrowAltCircleLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowAltCircleRight as fadArrowAltCircleRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowAltCircleRight as falArrowAltCircleRight }   from '@fortawesome/pro-light-svg-icons';
import { faArrowAltCircleRight as farArrowAltCircleRight }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowAltCircleRight as fasArrowAltCircleRight }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowAltCircleUp as fadArrowAltCircleUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowAltCircleUp as falArrowAltCircleUp }   from '@fortawesome/pro-light-svg-icons';
import { faArrowAltCircleUp as farArrowAltCircleUp }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowAltCircleUp as fasArrowAltCircleUp }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowAltDown as fadArrowAltDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowAltDown as falArrowAltDown }   from '@fortawesome/pro-light-svg-icons';
import { faArrowAltDown as farArrowAltDown }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowAltDown as fasArrowAltDown }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowAltFromBottom as fadArrowAltFromBottom }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowAltFromBottom as falArrowAltFromBottom }   from '@fortawesome/pro-light-svg-icons';
import { faArrowAltFromBottom as farArrowAltFromBottom }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowAltFromBottom as fasArrowAltFromBottom }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowAltFromLeft as fadArrowAltFromLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowAltFromLeft as falArrowAltFromLeft }   from '@fortawesome/pro-light-svg-icons';
import { faArrowAltFromLeft as farArrowAltFromLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowAltFromLeft as fasArrowAltFromLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowAltFromRight as fadArrowAltFromRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowAltFromRight as falArrowAltFromRight }   from '@fortawesome/pro-light-svg-icons';
import { faArrowAltFromRight as farArrowAltFromRight }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowAltFromRight as fasArrowAltFromRight }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowAltFromTop as fadArrowAltFromTop }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowAltFromTop as falArrowAltFromTop }   from '@fortawesome/pro-light-svg-icons';
import { faArrowAltFromTop as farArrowAltFromTop }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowAltFromTop as fasArrowAltFromTop }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowAltLeft as fadArrowAltLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowAltLeft as falArrowAltLeft }   from '@fortawesome/pro-light-svg-icons';
import { faArrowAltLeft as farArrowAltLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowAltLeft as fasArrowAltLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowAltRight as fadArrowAltRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowAltRight as falArrowAltRight }   from '@fortawesome/pro-light-svg-icons';
import { faArrowAltRight as farArrowAltRight }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowAltRight as fasArrowAltRight }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowAltSquareDown as fadArrowAltSquareDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowAltSquareDown as falArrowAltSquareDown }   from '@fortawesome/pro-light-svg-icons';
import { faArrowAltSquareDown as farArrowAltSquareDown }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowAltSquareDown as fasArrowAltSquareDown }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowAltSquareLeft as fadArrowAltSquareLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowAltSquareLeft as falArrowAltSquareLeft }   from '@fortawesome/pro-light-svg-icons';
import { faArrowAltSquareLeft as farArrowAltSquareLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowAltSquareLeft as fasArrowAltSquareLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowAltSquareRight as fadArrowAltSquareRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowAltSquareRight as falArrowAltSquareRight }   from '@fortawesome/pro-light-svg-icons';
import { faArrowAltSquareRight as farArrowAltSquareRight }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowAltSquareRight as fasArrowAltSquareRight }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowAltSquareUp as fadArrowAltSquareUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowAltSquareUp as falArrowAltSquareUp }   from '@fortawesome/pro-light-svg-icons';
import { faArrowAltSquareUp as farArrowAltSquareUp }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowAltSquareUp as fasArrowAltSquareUp }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowAltToBottom as fadArrowAltToBottom }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowAltToBottom as falArrowAltToBottom }   from '@fortawesome/pro-light-svg-icons';
import { faArrowAltToBottom as farArrowAltToBottom }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowAltToBottom as fasArrowAltToBottom }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowAltToLeft as fadArrowAltToLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowAltToLeft as falArrowAltToLeft }   from '@fortawesome/pro-light-svg-icons';
import { faArrowAltToLeft as farArrowAltToLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowAltToLeft as fasArrowAltToLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowAltToRight as fadArrowAltToRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowAltToRight as falArrowAltToRight }   from '@fortawesome/pro-light-svg-icons';
import { faArrowAltToRight as farArrowAltToRight }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowAltToRight as fasArrowAltToRight }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowAltToTop as fadArrowAltToTop }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowAltToTop as falArrowAltToTop }   from '@fortawesome/pro-light-svg-icons';
import { faArrowAltToTop as farArrowAltToTop }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowAltToTop as fasArrowAltToTop }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowAltUp as fadArrowAltUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowAltUp as falArrowAltUp }   from '@fortawesome/pro-light-svg-icons';
import { faArrowAltUp as farArrowAltUp }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowAltUp as fasArrowAltUp }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowCircleDown as fadArrowCircleDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowCircleDown as falArrowCircleDown }   from '@fortawesome/pro-light-svg-icons';
import { faArrowCircleDown as farArrowCircleDown }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowCircleDown as fasArrowCircleDown }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowCircleLeft as fadArrowCircleLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowCircleLeft as falArrowCircleLeft }   from '@fortawesome/pro-light-svg-icons';
import { faArrowCircleLeft as farArrowCircleLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowCircleLeft as fasArrowCircleLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowCircleRight as fadArrowCircleRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowCircleRight as falArrowCircleRight }   from '@fortawesome/pro-light-svg-icons';
import { faArrowCircleRight as farArrowCircleRight }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowCircleRight as fasArrowCircleRight }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowCircleUp as fadArrowCircleUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowCircleUp as falArrowCircleUp }   from '@fortawesome/pro-light-svg-icons';
import { faArrowCircleUp as farArrowCircleUp }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowCircleUp as fasArrowCircleUp }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowDown as fadArrowDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDown as falArrowDown }   from '@fortawesome/pro-light-svg-icons';
import { faArrowDown as farArrowDown }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowDown as fasArrowDown }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowDown19 as fadArrowDown19 }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDown19 as falArrowDown19 }   from '@fortawesome/pro-light-svg-icons';
import { faArrowDown19 as farArrowDown19 }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowDown19 as fasArrowDown19 }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowDown91 as fadArrowDown91 }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDown91 as falArrowDown91 }   from '@fortawesome/pro-light-svg-icons';
import { faArrowDown91 as farArrowDown91 }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowDown91 as fasArrowDown91 }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowDownArrowUp as fadArrowDownArrowUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDownArrowUp as falArrowDownArrowUp }   from '@fortawesome/pro-light-svg-icons';
import { faArrowDownArrowUp as farArrowDownArrowUp }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowDownArrowUp as fasArrowDownArrowUp }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowDownAZ as fadArrowDownAZ }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDownAZ as falArrowDownAZ }   from '@fortawesome/pro-light-svg-icons';
import { faArrowDownAZ as farArrowDownAZ }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowDownAZ as fasArrowDownAZ }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowDownBigSmall as fadArrowDownBigSmall }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDownBigSmall as falArrowDownBigSmall }   from '@fortawesome/pro-light-svg-icons';
import { faArrowDownBigSmall as farArrowDownBigSmall }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowDownBigSmall as fasArrowDownBigSmall }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowDownFromDottedLine as fadArrowDownFromDottedLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDownFromDottedLine as falArrowDownFromDottedLine }   from '@fortawesome/pro-light-svg-icons';
import { faArrowDownFromDottedLine as farArrowDownFromDottedLine }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowDownFromDottedLine as fasArrowDownFromDottedLine }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowDownFromLine as fadArrowDownFromLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDownFromLine as falArrowDownFromLine }   from '@fortawesome/pro-light-svg-icons';
import { faArrowDownFromLine as farArrowDownFromLine }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowDownFromLine as fasArrowDownFromLine }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowDownLeft as fadArrowDownLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDownLeft as falArrowDownLeft }   from '@fortawesome/pro-light-svg-icons';
import { faArrowDownLeft as farArrowDownLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowDownLeft as fasArrowDownLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowDownLeftAndArrowUpRightToCenter as fadArrowDownLeftAndArrowUpRightToCenter }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDownLeftAndArrowUpRightToCenter as falArrowDownLeftAndArrowUpRightToCenter }   from '@fortawesome/pro-light-svg-icons';
import { faArrowDownLeftAndArrowUpRightToCenter as farArrowDownLeftAndArrowUpRightToCenter }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowDownLeftAndArrowUpRightToCenter as fasArrowDownLeftAndArrowUpRightToCenter }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowDownLong as fadArrowDownLong }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDownLong as falArrowDownLong }   from '@fortawesome/pro-light-svg-icons';
import { faArrowDownLong as farArrowDownLong }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowDownLong as fasArrowDownLong }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowDownRight as fadArrowDownRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDownRight as falArrowDownRight }   from '@fortawesome/pro-light-svg-icons';
import { faArrowDownRight as farArrowDownRight }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowDownRight as fasArrowDownRight }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowDownShortWide as fadArrowDownShortWide }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDownShortWide as falArrowDownShortWide }   from '@fortawesome/pro-light-svg-icons';
import { faArrowDownShortWide as farArrowDownShortWide }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowDownShortWide as fasArrowDownShortWide }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowDownSmallBig as fadArrowDownSmallBig }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDownSmallBig as falArrowDownSmallBig }   from '@fortawesome/pro-light-svg-icons';
import { faArrowDownSmallBig as farArrowDownSmallBig }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowDownSmallBig as fasArrowDownSmallBig }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowDownSquareTriangle as fadArrowDownSquareTriangle }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDownSquareTriangle as falArrowDownSquareTriangle }   from '@fortawesome/pro-light-svg-icons';
import { faArrowDownSquareTriangle as farArrowDownSquareTriangle }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowDownSquareTriangle as fasArrowDownSquareTriangle }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowDownToArc as fadArrowDownToArc }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDownToArc as falArrowDownToArc }   from '@fortawesome/pro-light-svg-icons';
import { faArrowDownToArc as farArrowDownToArc }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowDownToArc as fasArrowDownToArc }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowDownToBracket as fadArrowDownToBracket }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDownToBracket as falArrowDownToBracket }   from '@fortawesome/pro-light-svg-icons';
import { faArrowDownToBracket as farArrowDownToBracket }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowDownToBracket as fasArrowDownToBracket }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowDownToDottedLine as fadArrowDownToDottedLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDownToDottedLine as falArrowDownToDottedLine }   from '@fortawesome/pro-light-svg-icons';
import { faArrowDownToDottedLine as farArrowDownToDottedLine }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowDownToDottedLine as fasArrowDownToDottedLine }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowDownToLine as fadArrowDownToLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDownToLine as falArrowDownToLine }   from '@fortawesome/pro-light-svg-icons';
import { faArrowDownToLine as farArrowDownToLine }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowDownToLine as fasArrowDownToLine }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowDownToSquare as fadArrowDownToSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDownToSquare as falArrowDownToSquare }   from '@fortawesome/pro-light-svg-icons';
import { faArrowDownToSquare as farArrowDownToSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowDownToSquare as fasArrowDownToSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowDownTriangleSquare as fadArrowDownTriangleSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDownTriangleSquare as falArrowDownTriangleSquare }   from '@fortawesome/pro-light-svg-icons';
import { faArrowDownTriangleSquare as farArrowDownTriangleSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowDownTriangleSquare as fasArrowDownTriangleSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowDownUpAcrossLine as fadArrowDownUpAcrossLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDownUpAcrossLine as falArrowDownUpAcrossLine }   from '@fortawesome/pro-light-svg-icons';
import { faArrowDownUpAcrossLine as farArrowDownUpAcrossLine }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowDownUpAcrossLine as fasArrowDownUpAcrossLine }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowDownUpLock as fadArrowDownUpLock }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDownUpLock as falArrowDownUpLock }   from '@fortawesome/pro-light-svg-icons';
import { faArrowDownUpLock as farArrowDownUpLock }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowDownUpLock as fasArrowDownUpLock }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowDownWideShort as fadArrowDownWideShort }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDownWideShort as falArrowDownWideShort }   from '@fortawesome/pro-light-svg-icons';
import { faArrowDownWideShort as farArrowDownWideShort }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowDownWideShort as fasArrowDownWideShort }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowDownZA as fadArrowDownZA }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowDownZA as falArrowDownZA }   from '@fortawesome/pro-light-svg-icons';
import { faArrowDownZA as farArrowDownZA }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowDownZA as fasArrowDownZA }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowFromBottom as fadArrowFromBottom }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowFromBottom as falArrowFromBottom }   from '@fortawesome/pro-light-svg-icons';
import { faArrowFromBottom as farArrowFromBottom }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowFromBottom as fasArrowFromBottom }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowFromLeft as fadArrowFromLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowFromLeft as falArrowFromLeft }   from '@fortawesome/pro-light-svg-icons';
import { faArrowFromLeft as farArrowFromLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowFromLeft as fasArrowFromLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowFromRight as fadArrowFromRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowFromRight as falArrowFromRight }   from '@fortawesome/pro-light-svg-icons';
import { faArrowFromRight as farArrowFromRight }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowFromRight as fasArrowFromRight }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowFromTop as fadArrowFromTop }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowFromTop as falArrowFromTop }   from '@fortawesome/pro-light-svg-icons';
import { faArrowFromTop as farArrowFromTop }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowFromTop as fasArrowFromTop }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowLeft as fadArrowLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowLeft as falArrowLeft }   from '@fortawesome/pro-light-svg-icons';
import { faArrowLeft as farArrowLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowLeft as fasArrowLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowLeftFromLine as fadArrowLeftFromLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowLeftFromLine as falArrowLeftFromLine }   from '@fortawesome/pro-light-svg-icons';
import { faArrowLeftFromLine as farArrowLeftFromLine }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowLeftFromLine as fasArrowLeftFromLine }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowLeftLong as fadArrowLeftLong }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowLeftLong as falArrowLeftLong }   from '@fortawesome/pro-light-svg-icons';
import { faArrowLeftLong as farArrowLeftLong }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowLeftLong as fasArrowLeftLong }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowLeftLongToLine as fadArrowLeftLongToLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowLeftLongToLine as falArrowLeftLongToLine }   from '@fortawesome/pro-light-svg-icons';
import { faArrowLeftLongToLine as farArrowLeftLongToLine }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowLeftLongToLine as fasArrowLeftLongToLine }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowLeftRotate as fadArrowLeftRotate }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowLeftRotate as falArrowLeftRotate }   from '@fortawesome/pro-light-svg-icons';
import { faArrowLeftRotate as farArrowLeftRotate }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowLeftRotate as fasArrowLeftRotate }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowLeftToLine as fadArrowLeftToLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowLeftToLine as falArrowLeftToLine }   from '@fortawesome/pro-light-svg-icons';
import { faArrowLeftToLine as farArrowLeftToLine }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowLeftToLine as fasArrowLeftToLine }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowPointer as fadArrowPointer }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowPointer as falArrowPointer }   from '@fortawesome/pro-light-svg-icons';
import { faArrowPointer as farArrowPointer }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowPointer as fasArrowPointer }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowRight as fadArrowRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowRight as falArrowRight }   from '@fortawesome/pro-light-svg-icons';
import { faArrowRight as farArrowRight }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowRight as fasArrowRight }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowRightArrowLeft as fadArrowRightArrowLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowRightArrowLeft as falArrowRightArrowLeft }   from '@fortawesome/pro-light-svg-icons';
import { faArrowRightArrowLeft as farArrowRightArrowLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowRightArrowLeft as fasArrowRightArrowLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowRightFromArc as fadArrowRightFromArc }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowRightFromArc as falArrowRightFromArc }   from '@fortawesome/pro-light-svg-icons';
import { faArrowRightFromArc as farArrowRightFromArc }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowRightFromArc as fasArrowRightFromArc }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowRightFromBracket as fadArrowRightFromBracket }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowRightFromBracket as falArrowRightFromBracket }   from '@fortawesome/pro-light-svg-icons';
import { faArrowRightFromBracket as farArrowRightFromBracket }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowRightFromBracket as fasArrowRightFromBracket }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowRightFromFile as fadArrowRightFromFile }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowRightFromFile as falArrowRightFromFile }   from '@fortawesome/pro-light-svg-icons';
import { faArrowRightFromFile as farArrowRightFromFile }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowRightFromFile as fasArrowRightFromFile }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowRightFromLine as fadArrowRightFromLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowRightFromLine as falArrowRightFromLine }   from '@fortawesome/pro-light-svg-icons';
import { faArrowRightFromLine as farArrowRightFromLine }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowRightFromLine as fasArrowRightFromLine }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowRightLong as fadArrowRightLong }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowRightLong as falArrowRightLong }   from '@fortawesome/pro-light-svg-icons';
import { faArrowRightLong as farArrowRightLong }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowRightLong as fasArrowRightLong }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowRightLongToLine as fadArrowRightLongToLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowRightLongToLine as falArrowRightLongToLine }   from '@fortawesome/pro-light-svg-icons';
import { faArrowRightLongToLine as farArrowRightLongToLine }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowRightLongToLine as fasArrowRightLongToLine }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowRightRotate as fadArrowRightRotate }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowRightRotate as falArrowRightRotate }   from '@fortawesome/pro-light-svg-icons';
import { faArrowRightRotate as farArrowRightRotate }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowRightRotate as fasArrowRightRotate }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowRightToArc as fadArrowRightToArc }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowRightToArc as falArrowRightToArc }   from '@fortawesome/pro-light-svg-icons';
import { faArrowRightToArc as farArrowRightToArc }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowRightToArc as fasArrowRightToArc }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowRightToBracket as fadArrowRightToBracket }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowRightToBracket as falArrowRightToBracket }   from '@fortawesome/pro-light-svg-icons';
import { faArrowRightToBracket as farArrowRightToBracket }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowRightToBracket as fasArrowRightToBracket }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowRightToCity as fadArrowRightToCity }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowRightToCity as falArrowRightToCity }   from '@fortawesome/pro-light-svg-icons';
import { faArrowRightToCity as farArrowRightToCity }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowRightToCity as fasArrowRightToCity }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowRightToFile as fadArrowRightToFile }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowRightToFile as falArrowRightToFile }   from '@fortawesome/pro-light-svg-icons';
import { faArrowRightToFile as farArrowRightToFile }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowRightToFile as fasArrowRightToFile }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowRightToLine as fadArrowRightToLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowRightToLine as falArrowRightToLine }   from '@fortawesome/pro-light-svg-icons';
import { faArrowRightToLine as farArrowRightToLine }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowRightToLine as fasArrowRightToLine }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowRotateBack as fadArrowRotateBack }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowRotateBack as falArrowRotateBack }   from '@fortawesome/pro-light-svg-icons';
import { faArrowRotateBack as farArrowRotateBack }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowRotateBack as fasArrowRotateBack }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowRotateBackward as fadArrowRotateBackward }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowRotateBackward as falArrowRotateBackward }   from '@fortawesome/pro-light-svg-icons';
import { faArrowRotateBackward as farArrowRotateBackward }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowRotateBackward as fasArrowRotateBackward }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowRotateForward as fadArrowRotateForward }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowRotateForward as falArrowRotateForward }   from '@fortawesome/pro-light-svg-icons';
import { faArrowRotateForward as farArrowRotateForward }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowRotateForward as fasArrowRotateForward }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowRotateLeft as fadArrowRotateLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowRotateLeft as falArrowRotateLeft }   from '@fortawesome/pro-light-svg-icons';
import { faArrowRotateLeft as farArrowRotateLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowRotateLeft as fasArrowRotateLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowRotateRight as fadArrowRotateRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowRotateRight as falArrowRotateRight }   from '@fortawesome/pro-light-svg-icons';
import { faArrowRotateRight as farArrowRotateRight }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowRotateRight as fasArrowRotateRight }   from '@fortawesome/pro-solid-svg-icons';
import { faArrows as fadArrows }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrows as falArrows }   from '@fortawesome/pro-light-svg-icons';
import { faArrows as farArrows }   from '@fortawesome/pro-regular-svg-icons';
import { faArrows as fasArrows }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsAlt as fadArrowsAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsAlt as falArrowsAlt }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsAlt as farArrowsAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsAlt as fasArrowsAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsAltH as fadArrowsAltH }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsAltH as falArrowsAltH }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsAltH as farArrowsAltH }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsAltH as fasArrowsAltH }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsAltV as fadArrowsAltV }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsAltV as falArrowsAltV }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsAltV as farArrowsAltV }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsAltV as fasArrowsAltV }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsCross as fadArrowsCross }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsCross as falArrowsCross }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsCross as farArrowsCross }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsCross as fasArrowsCross }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsDownToLine as fadArrowsDownToLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsDownToLine as falArrowsDownToLine }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsDownToLine as farArrowsDownToLine }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsDownToLine as fasArrowsDownToLine }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsDownToPeople as fadArrowsDownToPeople }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsDownToPeople as falArrowsDownToPeople }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsDownToPeople as farArrowsDownToPeople }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsDownToPeople as fasArrowsDownToPeople }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsFromDottedLine as fadArrowsFromDottedLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsFromDottedLine as falArrowsFromDottedLine }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsFromDottedLine as farArrowsFromDottedLine }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsFromDottedLine as fasArrowsFromDottedLine }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsFromLine as fadArrowsFromLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsFromLine as falArrowsFromLine }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsFromLine as farArrowsFromLine }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsFromLine as fasArrowsFromLine }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsH as fadArrowsH }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsH as falArrowsH }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsH as farArrowsH }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsH as fasArrowsH }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsLeftRight as fadArrowsLeftRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsLeftRight as falArrowsLeftRight }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsLeftRight as farArrowsLeftRight }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsLeftRight as fasArrowsLeftRight }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsLeftRightToLine as fadArrowsLeftRightToLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsLeftRightToLine as falArrowsLeftRightToLine }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsLeftRightToLine as farArrowsLeftRightToLine }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsLeftRightToLine as fasArrowsLeftRightToLine }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsMaximize as fadArrowsMaximize }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsMaximize as falArrowsMaximize }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsMaximize as farArrowsMaximize }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsMaximize as fasArrowsMaximize }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsMinimize as fadArrowsMinimize }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsMinimize as falArrowsMinimize }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsMinimize as farArrowsMinimize }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsMinimize as fasArrowsMinimize }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowSquareDown as fadArrowSquareDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowSquareDown as falArrowSquareDown }   from '@fortawesome/pro-light-svg-icons';
import { faArrowSquareDown as farArrowSquareDown }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowSquareDown as fasArrowSquareDown }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowSquareLeft as fadArrowSquareLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowSquareLeft as falArrowSquareLeft }   from '@fortawesome/pro-light-svg-icons';
import { faArrowSquareLeft as farArrowSquareLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowSquareLeft as fasArrowSquareLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowSquareRight as fadArrowSquareRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowSquareRight as falArrowSquareRight }   from '@fortawesome/pro-light-svg-icons';
import { faArrowSquareRight as farArrowSquareRight }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowSquareRight as fasArrowSquareRight }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowSquareUp as fadArrowSquareUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowSquareUp as falArrowSquareUp }   from '@fortawesome/pro-light-svg-icons';
import { faArrowSquareUp as farArrowSquareUp }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowSquareUp as fasArrowSquareUp }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsRepeat as fadArrowsRepeat }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsRepeat as falArrowsRepeat }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsRepeat as farArrowsRepeat }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsRepeat as fasArrowsRepeat }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsRepeat1 as fadArrowsRepeat1 }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsRepeat1 as falArrowsRepeat1 }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsRepeat1 as farArrowsRepeat1 }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsRepeat1 as fasArrowsRepeat1 }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsRetweet as fadArrowsRetweet }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsRetweet as falArrowsRetweet }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsRetweet as farArrowsRetweet }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsRetweet as fasArrowsRetweet }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsRotate as fadArrowsRotate }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsRotate as falArrowsRotate }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsRotate as farArrowsRotate }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsRotate as fasArrowsRotate }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsSpin as fadArrowsSpin }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsSpin as falArrowsSpin }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsSpin as farArrowsSpin }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsSpin as fasArrowsSpin }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsSplitUpAndLeft as fadArrowsSplitUpAndLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsSplitUpAndLeft as falArrowsSplitUpAndLeft }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsSplitUpAndLeft as farArrowsSplitUpAndLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsSplitUpAndLeft as fasArrowsSplitUpAndLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsToCircle as fadArrowsToCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsToCircle as falArrowsToCircle }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsToCircle as farArrowsToCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsToCircle as fasArrowsToCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsToDot as fadArrowsToDot }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsToDot as falArrowsToDot }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsToDot as farArrowsToDot }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsToDot as fasArrowsToDot }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsToDottedLine as fadArrowsToDottedLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsToDottedLine as falArrowsToDottedLine }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsToDottedLine as farArrowsToDottedLine }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsToDottedLine as fasArrowsToDottedLine }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsToEye as fadArrowsToEye }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsToEye as falArrowsToEye }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsToEye as farArrowsToEye }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsToEye as fasArrowsToEye }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsToLine as fadArrowsToLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsToLine as falArrowsToLine }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsToLine as farArrowsToLine }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsToLine as fasArrowsToLine }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsTurnRight as fadArrowsTurnRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsTurnRight as falArrowsTurnRight }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsTurnRight as farArrowsTurnRight }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsTurnRight as fasArrowsTurnRight }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsTurnToDots as fadArrowsTurnToDots }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsTurnToDots as falArrowsTurnToDots }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsTurnToDots as farArrowsTurnToDots }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsTurnToDots as fasArrowsTurnToDots }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsUpDown as fadArrowsUpDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsUpDown as falArrowsUpDown }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsUpDown as farArrowsUpDown }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsUpDown as fasArrowsUpDown }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsUpDownLeftRight as fadArrowsUpDownLeftRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsUpDownLeftRight as falArrowsUpDownLeftRight }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsUpDownLeftRight as farArrowsUpDownLeftRight }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsUpDownLeftRight as fasArrowsUpDownLeftRight }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsUpToLine as fadArrowsUpToLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsUpToLine as falArrowsUpToLine }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsUpToLine as farArrowsUpToLine }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsUpToLine as fasArrowsUpToLine }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowsV as fadArrowsV }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowsV as falArrowsV }   from '@fortawesome/pro-light-svg-icons';
import { faArrowsV as farArrowsV }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowsV as fasArrowsV }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowToBottom as fadArrowToBottom }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowToBottom as falArrowToBottom }   from '@fortawesome/pro-light-svg-icons';
import { faArrowToBottom as farArrowToBottom }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowToBottom as fasArrowToBottom }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowToLeft as fadArrowToLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowToLeft as falArrowToLeft }   from '@fortawesome/pro-light-svg-icons';
import { faArrowToLeft as farArrowToLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowToLeft as fasArrowToLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowToRight as fadArrowToRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowToRight as falArrowToRight }   from '@fortawesome/pro-light-svg-icons';
import { faArrowToRight as farArrowToRight }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowToRight as fasArrowToRight }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowToTop as fadArrowToTop }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowToTop as falArrowToTop }   from '@fortawesome/pro-light-svg-icons';
import { faArrowToTop as farArrowToTop }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowToTop as fasArrowToTop }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowTrendDown as fadArrowTrendDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowTrendDown as falArrowTrendDown }   from '@fortawesome/pro-light-svg-icons';
import { faArrowTrendDown as farArrowTrendDown }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowTrendDown as fasArrowTrendDown }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowTrendUp as fadArrowTrendUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowTrendUp as falArrowTrendUp }   from '@fortawesome/pro-light-svg-icons';
import { faArrowTrendUp as farArrowTrendUp }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowTrendUp as fasArrowTrendUp }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowTurnDown as fadArrowTurnDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowTurnDown as falArrowTurnDown }   from '@fortawesome/pro-light-svg-icons';
import { faArrowTurnDown as farArrowTurnDown }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowTurnDown as fasArrowTurnDown }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowTurnDownLeft as fadArrowTurnDownLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowTurnDownLeft as falArrowTurnDownLeft }   from '@fortawesome/pro-light-svg-icons';
import { faArrowTurnDownLeft as farArrowTurnDownLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowTurnDownLeft as fasArrowTurnDownLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowTurnDownRight as fadArrowTurnDownRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowTurnDownRight as falArrowTurnDownRight }   from '@fortawesome/pro-light-svg-icons';
import { faArrowTurnDownRight as farArrowTurnDownRight }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowTurnDownRight as fasArrowTurnDownRight }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowTurnRight as fadArrowTurnRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowTurnRight as falArrowTurnRight }   from '@fortawesome/pro-light-svg-icons';
import { faArrowTurnRight as farArrowTurnRight }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowTurnRight as fasArrowTurnRight }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowTurnUp as fadArrowTurnUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowTurnUp as falArrowTurnUp }   from '@fortawesome/pro-light-svg-icons';
import { faArrowTurnUp as farArrowTurnUp }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowTurnUp as fasArrowTurnUp }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUp as fadArrowUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUp as falArrowUp }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUp as farArrowUp }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUp as fasArrowUp }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUp19 as fadArrowUp19 }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUp19 as falArrowUp19 }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUp19 as farArrowUp19 }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUp19 as fasArrowUp19 }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUp91 as fadArrowUp91 }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUp91 as falArrowUp91 }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUp91 as farArrowUp91 }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUp91 as fasArrowUp91 }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpArrowDown as fadArrowUpArrowDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpArrowDown as falArrowUpArrowDown }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUpArrowDown as farArrowUpArrowDown }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpArrowDown as fasArrowUpArrowDown }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpAZ as fadArrowUpAZ }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpAZ as falArrowUpAZ }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUpAZ as farArrowUpAZ }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpAZ as fasArrowUpAZ }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpBigSmall as fadArrowUpBigSmall }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpBigSmall as falArrowUpBigSmall }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUpBigSmall as farArrowUpBigSmall }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpBigSmall as fasArrowUpBigSmall }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpFromArc as fadArrowUpFromArc }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpFromArc as falArrowUpFromArc }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUpFromArc as farArrowUpFromArc }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpFromArc as fasArrowUpFromArc }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpFromBracket as fadArrowUpFromBracket }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpFromBracket as falArrowUpFromBracket }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUpFromBracket as farArrowUpFromBracket }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpFromBracket as fasArrowUpFromBracket }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpFromDottedLine as fadArrowUpFromDottedLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpFromDottedLine as falArrowUpFromDottedLine }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUpFromDottedLine as farArrowUpFromDottedLine }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpFromDottedLine as fasArrowUpFromDottedLine }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpFromGroundWater as fadArrowUpFromGroundWater }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpFromGroundWater as falArrowUpFromGroundWater }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUpFromGroundWater as farArrowUpFromGroundWater }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpFromGroundWater as fasArrowUpFromGroundWater }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpFromLine as fadArrowUpFromLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpFromLine as falArrowUpFromLine }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUpFromLine as farArrowUpFromLine }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpFromLine as fasArrowUpFromLine }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpFromSquare as fadArrowUpFromSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpFromSquare as falArrowUpFromSquare }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUpFromSquare as farArrowUpFromSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpFromSquare as fasArrowUpFromSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpFromWaterPump as fadArrowUpFromWaterPump }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpFromWaterPump as falArrowUpFromWaterPump }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUpFromWaterPump as farArrowUpFromWaterPump }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpFromWaterPump as fasArrowUpFromWaterPump }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpLeft as fadArrowUpLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpLeft as falArrowUpLeft }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUpLeft as farArrowUpLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpLeft as fasArrowUpLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpLeftFromCircle as fadArrowUpLeftFromCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpLeftFromCircle as falArrowUpLeftFromCircle }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUpLeftFromCircle as farArrowUpLeftFromCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpLeftFromCircle as fasArrowUpLeftFromCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpLong as fadArrowUpLong }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpLong as falArrowUpLong }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUpLong as farArrowUpLong }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpLong as fasArrowUpLong }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpRight as fadArrowUpRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpRight as falArrowUpRight }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUpRight as farArrowUpRight }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpRight as fasArrowUpRight }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpRightAndArrowDownLeftFromCenter as fadArrowUpRightAndArrowDownLeftFromCenter }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpRightAndArrowDownLeftFromCenter as falArrowUpRightAndArrowDownLeftFromCenter }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUpRightAndArrowDownLeftFromCenter as farArrowUpRightAndArrowDownLeftFromCenter }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpRightAndArrowDownLeftFromCenter as fasArrowUpRightAndArrowDownLeftFromCenter }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpRightDots as fadArrowUpRightDots }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpRightDots as falArrowUpRightDots }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUpRightDots as farArrowUpRightDots }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpRightDots as fasArrowUpRightDots }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpRightFromSquare as fadArrowUpRightFromSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpRightFromSquare as falArrowUpRightFromSquare }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUpRightFromSquare as farArrowUpRightFromSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpRightFromSquare as fasArrowUpRightFromSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpShortWide as fadArrowUpShortWide }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpShortWide as falArrowUpShortWide }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUpShortWide as farArrowUpShortWide }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpShortWide as fasArrowUpShortWide }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpSmallBig as fadArrowUpSmallBig }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpSmallBig as falArrowUpSmallBig }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUpSmallBig as farArrowUpSmallBig }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpSmallBig as fasArrowUpSmallBig }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpSquareTriangle as fadArrowUpSquareTriangle }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpSquareTriangle as falArrowUpSquareTriangle }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUpSquareTriangle as farArrowUpSquareTriangle }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpSquareTriangle as fasArrowUpSquareTriangle }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpToDottedLine as fadArrowUpToDottedLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpToDottedLine as falArrowUpToDottedLine }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUpToDottedLine as farArrowUpToDottedLine }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpToDottedLine as fasArrowUpToDottedLine }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpToLine as fadArrowUpToLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpToLine as falArrowUpToLine }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUpToLine as farArrowUpToLine }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpToLine as fasArrowUpToLine }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpTriangleSquare as fadArrowUpTriangleSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpTriangleSquare as falArrowUpTriangleSquare }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUpTriangleSquare as farArrowUpTriangleSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpTriangleSquare as fasArrowUpTriangleSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpWideShort as fadArrowUpWideShort }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpWideShort as falArrowUpWideShort }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUpWideShort as farArrowUpWideShort }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpWideShort as fasArrowUpWideShort }   from '@fortawesome/pro-solid-svg-icons';
import { faArrowUpZA as fadArrowUpZA }   from '@fortawesome/pro-duotone-svg-icons';
import { faArrowUpZA as falArrowUpZA }   from '@fortawesome/pro-light-svg-icons';
import { faArrowUpZA as farArrowUpZA }   from '@fortawesome/pro-regular-svg-icons';
import { faArrowUpZA as fasArrowUpZA }   from '@fortawesome/pro-solid-svg-icons';
import { faAslInterpreting as fadAslInterpreting }   from '@fortawesome/pro-duotone-svg-icons';
import { faAslInterpreting as falAslInterpreting }   from '@fortawesome/pro-light-svg-icons';
import { faAslInterpreting as farAslInterpreting }   from '@fortawesome/pro-regular-svg-icons';
import { faAslInterpreting as fasAslInterpreting }   from '@fortawesome/pro-solid-svg-icons';
import { faAssistiveListeningSystems as fadAssistiveListeningSystems }   from '@fortawesome/pro-duotone-svg-icons';
import { faAssistiveListeningSystems as falAssistiveListeningSystems }   from '@fortawesome/pro-light-svg-icons';
import { faAssistiveListeningSystems as farAssistiveListeningSystems }   from '@fortawesome/pro-regular-svg-icons';
import { faAssistiveListeningSystems as fasAssistiveListeningSystems }   from '@fortawesome/pro-solid-svg-icons';
import { faAsterisk as fadAsterisk }   from '@fortawesome/pro-duotone-svg-icons';
import { faAsterisk as falAsterisk }   from '@fortawesome/pro-light-svg-icons';
import { faAsterisk as farAsterisk }   from '@fortawesome/pro-regular-svg-icons';
import { faAsterisk as fasAsterisk }   from '@fortawesome/pro-solid-svg-icons';
import { faAt as fadAt }   from '@fortawesome/pro-duotone-svg-icons';
import { faAt as falAt }   from '@fortawesome/pro-light-svg-icons';
import { faAt as farAt }   from '@fortawesome/pro-regular-svg-icons';
import { faAt as fasAt }   from '@fortawesome/pro-solid-svg-icons';
import { faAtlas as fadAtlas }   from '@fortawesome/pro-duotone-svg-icons';
import { faAtlas as falAtlas }   from '@fortawesome/pro-light-svg-icons';
import { faAtlas as farAtlas }   from '@fortawesome/pro-regular-svg-icons';
import { faAtlas as fasAtlas }   from '@fortawesome/pro-solid-svg-icons';
import { faAtom as fadAtom }   from '@fortawesome/pro-duotone-svg-icons';
import { faAtom as falAtom }   from '@fortawesome/pro-light-svg-icons';
import { faAtom as farAtom }   from '@fortawesome/pro-regular-svg-icons';
import { faAtom as fasAtom }   from '@fortawesome/pro-solid-svg-icons';
import { faAtomAlt as fadAtomAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faAtomAlt as falAtomAlt }   from '@fortawesome/pro-light-svg-icons';
import { faAtomAlt as farAtomAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faAtomAlt as fasAtomAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faAtomSimple as fadAtomSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faAtomSimple as falAtomSimple }   from '@fortawesome/pro-light-svg-icons';
import { faAtomSimple as farAtomSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faAtomSimple as fasAtomSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faAudioDescription as fadAudioDescription }   from '@fortawesome/pro-duotone-svg-icons';
import { faAudioDescription as falAudioDescription }   from '@fortawesome/pro-light-svg-icons';
import { faAudioDescription as farAudioDescription }   from '@fortawesome/pro-regular-svg-icons';
import { faAudioDescription as fasAudioDescription }   from '@fortawesome/pro-solid-svg-icons';
import { faAudioDescriptionSlash as fadAudioDescriptionSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faAudioDescriptionSlash as falAudioDescriptionSlash }   from '@fortawesome/pro-light-svg-icons';
import { faAudioDescriptionSlash as farAudioDescriptionSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faAudioDescriptionSlash as fasAudioDescriptionSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faAustralSign as fadAustralSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faAustralSign as falAustralSign }   from '@fortawesome/pro-light-svg-icons';
import { faAustralSign as farAustralSign }   from '@fortawesome/pro-regular-svg-icons';
import { faAustralSign as fasAustralSign }   from '@fortawesome/pro-solid-svg-icons';
import { faAutomobile as fadAutomobile }   from '@fortawesome/pro-duotone-svg-icons';
import { faAutomobile as falAutomobile }   from '@fortawesome/pro-light-svg-icons';
import { faAutomobile as farAutomobile }   from '@fortawesome/pro-regular-svg-icons';
import { faAutomobile as fasAutomobile }   from '@fortawesome/pro-solid-svg-icons';
import { faAvocado as fadAvocado }   from '@fortawesome/pro-duotone-svg-icons';
import { faAvocado as falAvocado }   from '@fortawesome/pro-light-svg-icons';
import { faAvocado as farAvocado }   from '@fortawesome/pro-regular-svg-icons';
import { faAvocado as fasAvocado }   from '@fortawesome/pro-solid-svg-icons';
import { faAward as fadAward }   from '@fortawesome/pro-duotone-svg-icons';
import { faAward as falAward }   from '@fortawesome/pro-light-svg-icons';
import { faAward as farAward }   from '@fortawesome/pro-regular-svg-icons';
import { faAward as fasAward }   from '@fortawesome/pro-solid-svg-icons';
import { faAwardSimple as fadAwardSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faAwardSimple as falAwardSimple }   from '@fortawesome/pro-light-svg-icons';
import { faAwardSimple as farAwardSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faAwardSimple as fasAwardSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faAxe as fadAxe }   from '@fortawesome/pro-duotone-svg-icons';
import { faAxe as falAxe }   from '@fortawesome/pro-light-svg-icons';
import { faAxe as farAxe }   from '@fortawesome/pro-regular-svg-icons';
import { faAxe as fasAxe }   from '@fortawesome/pro-solid-svg-icons';
import { faAxeBattle as fadAxeBattle }   from '@fortawesome/pro-duotone-svg-icons';
import { faAxeBattle as falAxeBattle }   from '@fortawesome/pro-light-svg-icons';
import { faAxeBattle as farAxeBattle }   from '@fortawesome/pro-regular-svg-icons';
import { faAxeBattle as fasAxeBattle }   from '@fortawesome/pro-solid-svg-icons';
import { faB as fadB }   from '@fortawesome/pro-duotone-svg-icons';
import { faB as falB }   from '@fortawesome/pro-light-svg-icons';
import { faB as farB }   from '@fortawesome/pro-regular-svg-icons';
import { faB as fasB }   from '@fortawesome/pro-solid-svg-icons';
import { faBaby as fadBaby }   from '@fortawesome/pro-duotone-svg-icons';
import { faBaby as falBaby }   from '@fortawesome/pro-light-svg-icons';
import { faBaby as farBaby }   from '@fortawesome/pro-regular-svg-icons';
import { faBaby as fasBaby }   from '@fortawesome/pro-solid-svg-icons';
import { faBabyCarriage as fadBabyCarriage }   from '@fortawesome/pro-duotone-svg-icons';
import { faBabyCarriage as falBabyCarriage }   from '@fortawesome/pro-light-svg-icons';
import { faBabyCarriage as farBabyCarriage }   from '@fortawesome/pro-regular-svg-icons';
import { faBabyCarriage as fasBabyCarriage }   from '@fortawesome/pro-solid-svg-icons';
import { faBackpack as fadBackpack }   from '@fortawesome/pro-duotone-svg-icons';
import { faBackpack as falBackpack }   from '@fortawesome/pro-light-svg-icons';
import { faBackpack as farBackpack }   from '@fortawesome/pro-regular-svg-icons';
import { faBackpack as fasBackpack }   from '@fortawesome/pro-solid-svg-icons';
import { faBackspace as fadBackspace }   from '@fortawesome/pro-duotone-svg-icons';
import { faBackspace as falBackspace }   from '@fortawesome/pro-light-svg-icons';
import { faBackspace as farBackspace }   from '@fortawesome/pro-regular-svg-icons';
import { faBackspace as fasBackspace }   from '@fortawesome/pro-solid-svg-icons';
import { faBackward as fadBackward }   from '@fortawesome/pro-duotone-svg-icons';
import { faBackward as falBackward }   from '@fortawesome/pro-light-svg-icons';
import { faBackward as farBackward }   from '@fortawesome/pro-regular-svg-icons';
import { faBackward as fasBackward }   from '@fortawesome/pro-solid-svg-icons';
import { faBackwardFast as fadBackwardFast }   from '@fortawesome/pro-duotone-svg-icons';
import { faBackwardFast as falBackwardFast }   from '@fortawesome/pro-light-svg-icons';
import { faBackwardFast as farBackwardFast }   from '@fortawesome/pro-regular-svg-icons';
import { faBackwardFast as fasBackwardFast }   from '@fortawesome/pro-solid-svg-icons';
import { faBackwardStep as fadBackwardStep }   from '@fortawesome/pro-duotone-svg-icons';
import { faBackwardStep as falBackwardStep }   from '@fortawesome/pro-light-svg-icons';
import { faBackwardStep as farBackwardStep }   from '@fortawesome/pro-regular-svg-icons';
import { faBackwardStep as fasBackwardStep }   from '@fortawesome/pro-solid-svg-icons';
import { faBacon as fadBacon }   from '@fortawesome/pro-duotone-svg-icons';
import { faBacon as falBacon }   from '@fortawesome/pro-light-svg-icons';
import { faBacon as farBacon }   from '@fortawesome/pro-regular-svg-icons';
import { faBacon as fasBacon }   from '@fortawesome/pro-solid-svg-icons';
import { faBacteria as fadBacteria }   from '@fortawesome/pro-duotone-svg-icons';
import { faBacteria as falBacteria }   from '@fortawesome/pro-light-svg-icons';
import { faBacteria as farBacteria }   from '@fortawesome/pro-regular-svg-icons';
import { faBacteria as fasBacteria }   from '@fortawesome/pro-solid-svg-icons';
import { faBacterium as fadBacterium }   from '@fortawesome/pro-duotone-svg-icons';
import { faBacterium as falBacterium }   from '@fortawesome/pro-light-svg-icons';
import { faBacterium as farBacterium }   from '@fortawesome/pro-regular-svg-icons';
import { faBacterium as fasBacterium }   from '@fortawesome/pro-solid-svg-icons';
import { faBadge as fadBadge }   from '@fortawesome/pro-duotone-svg-icons';
import { faBadge as falBadge }   from '@fortawesome/pro-light-svg-icons';
import { faBadge as farBadge }   from '@fortawesome/pro-regular-svg-icons';
import { faBadge as fasBadge }   from '@fortawesome/pro-solid-svg-icons';
import { faBadgeCheck as fadBadgeCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faBadgeCheck as falBadgeCheck }   from '@fortawesome/pro-light-svg-icons';
import { faBadgeCheck as farBadgeCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faBadgeCheck as fasBadgeCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faBadgeDollar as fadBadgeDollar }   from '@fortawesome/pro-duotone-svg-icons';
import { faBadgeDollar as falBadgeDollar }   from '@fortawesome/pro-light-svg-icons';
import { faBadgeDollar as farBadgeDollar }   from '@fortawesome/pro-regular-svg-icons';
import { faBadgeDollar as fasBadgeDollar }   from '@fortawesome/pro-solid-svg-icons';
import { faBadgePercent as fadBadgePercent }   from '@fortawesome/pro-duotone-svg-icons';
import { faBadgePercent as falBadgePercent }   from '@fortawesome/pro-light-svg-icons';
import { faBadgePercent as farBadgePercent }   from '@fortawesome/pro-regular-svg-icons';
import { faBadgePercent as fasBadgePercent }   from '@fortawesome/pro-solid-svg-icons';
import { faBadgerHoney as fadBadgerHoney }   from '@fortawesome/pro-duotone-svg-icons';
import { faBadgerHoney as falBadgerHoney }   from '@fortawesome/pro-light-svg-icons';
import { faBadgerHoney as farBadgerHoney }   from '@fortawesome/pro-regular-svg-icons';
import { faBadgerHoney as fasBadgerHoney }   from '@fortawesome/pro-solid-svg-icons';
import { faBadgeSheriff as fadBadgeSheriff }   from '@fortawesome/pro-duotone-svg-icons';
import { faBadgeSheriff as falBadgeSheriff }   from '@fortawesome/pro-light-svg-icons';
import { faBadgeSheriff as farBadgeSheriff }   from '@fortawesome/pro-regular-svg-icons';
import { faBadgeSheriff as fasBadgeSheriff }   from '@fortawesome/pro-solid-svg-icons';
import { faBadminton as fadBadminton }   from '@fortawesome/pro-duotone-svg-icons';
import { faBadminton as falBadminton }   from '@fortawesome/pro-light-svg-icons';
import { faBadminton as farBadminton }   from '@fortawesome/pro-regular-svg-icons';
import { faBadminton as fasBadminton }   from '@fortawesome/pro-solid-svg-icons';
import { faBagel as fadBagel }   from '@fortawesome/pro-duotone-svg-icons';
import { faBagel as falBagel }   from '@fortawesome/pro-light-svg-icons';
import { faBagel as farBagel }   from '@fortawesome/pro-regular-svg-icons';
import { faBagel as fasBagel }   from '@fortawesome/pro-solid-svg-icons';
import { faBagShopping as fadBagShopping }   from '@fortawesome/pro-duotone-svg-icons';
import { faBagShopping as falBagShopping }   from '@fortawesome/pro-light-svg-icons';
import { faBagShopping as farBagShopping }   from '@fortawesome/pro-regular-svg-icons';
import { faBagShopping as fasBagShopping }   from '@fortawesome/pro-solid-svg-icons';
import { faBagsShopping as fadBagsShopping }   from '@fortawesome/pro-duotone-svg-icons';
import { faBagsShopping as falBagsShopping }   from '@fortawesome/pro-light-svg-icons';
import { faBagsShopping as farBagsShopping }   from '@fortawesome/pro-regular-svg-icons';
import { faBagsShopping as fasBagsShopping }   from '@fortawesome/pro-solid-svg-icons';
import { faBaguette as fadBaguette }   from '@fortawesome/pro-duotone-svg-icons';
import { faBaguette as falBaguette }   from '@fortawesome/pro-light-svg-icons';
import { faBaguette as farBaguette }   from '@fortawesome/pro-regular-svg-icons';
import { faBaguette as fasBaguette }   from '@fortawesome/pro-solid-svg-icons';
import { faBahai as fadBahai }   from '@fortawesome/pro-duotone-svg-icons';
import { faBahai as falBahai }   from '@fortawesome/pro-light-svg-icons';
import { faBahai as farBahai }   from '@fortawesome/pro-regular-svg-icons';
import { faBahai as fasBahai }   from '@fortawesome/pro-solid-svg-icons';
import { faBahtSign as fadBahtSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faBahtSign as falBahtSign }   from '@fortawesome/pro-light-svg-icons';
import { faBahtSign as farBahtSign }   from '@fortawesome/pro-regular-svg-icons';
import { faBahtSign as fasBahtSign }   from '@fortawesome/pro-solid-svg-icons';
import { faBalanceScale as fadBalanceScale }   from '@fortawesome/pro-duotone-svg-icons';
import { faBalanceScale as falBalanceScale }   from '@fortawesome/pro-light-svg-icons';
import { faBalanceScale as farBalanceScale }   from '@fortawesome/pro-regular-svg-icons';
import { faBalanceScale as fasBalanceScale }   from '@fortawesome/pro-solid-svg-icons';
import { faBalanceScaleLeft as fadBalanceScaleLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faBalanceScaleLeft as falBalanceScaleLeft }   from '@fortawesome/pro-light-svg-icons';
import { faBalanceScaleLeft as farBalanceScaleLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faBalanceScaleLeft as fasBalanceScaleLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faBalanceScaleRight as fadBalanceScaleRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faBalanceScaleRight as falBalanceScaleRight }   from '@fortawesome/pro-light-svg-icons';
import { faBalanceScaleRight as farBalanceScaleRight }   from '@fortawesome/pro-regular-svg-icons';
import { faBalanceScaleRight as fasBalanceScaleRight }   from '@fortawesome/pro-solid-svg-icons';
import { faBalloon as fadBalloon }   from '@fortawesome/pro-duotone-svg-icons';
import { faBalloon as falBalloon }   from '@fortawesome/pro-light-svg-icons';
import { faBalloon as farBalloon }   from '@fortawesome/pro-regular-svg-icons';
import { faBalloon as fasBalloon }   from '@fortawesome/pro-solid-svg-icons';
import { faBalloons as fadBalloons }   from '@fortawesome/pro-duotone-svg-icons';
import { faBalloons as falBalloons }   from '@fortawesome/pro-light-svg-icons';
import { faBalloons as farBalloons }   from '@fortawesome/pro-regular-svg-icons';
import { faBalloons as fasBalloons }   from '@fortawesome/pro-solid-svg-icons';
import { faBallot as fadBallot }   from '@fortawesome/pro-duotone-svg-icons';
import { faBallot as falBallot }   from '@fortawesome/pro-light-svg-icons';
import { faBallot as farBallot }   from '@fortawesome/pro-regular-svg-icons';
import { faBallot as fasBallot }   from '@fortawesome/pro-solid-svg-icons';
import { faBallotCheck as fadBallotCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faBallotCheck as falBallotCheck }   from '@fortawesome/pro-light-svg-icons';
import { faBallotCheck as farBallotCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faBallotCheck as fasBallotCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faBallPile as fadBallPile }   from '@fortawesome/pro-duotone-svg-icons';
import { faBallPile as falBallPile }   from '@fortawesome/pro-light-svg-icons';
import { faBallPile as farBallPile }   from '@fortawesome/pro-regular-svg-icons';
import { faBallPile as fasBallPile }   from '@fortawesome/pro-solid-svg-icons';
import { faBan as fadBan }   from '@fortawesome/pro-duotone-svg-icons';
import { faBan as falBan }   from '@fortawesome/pro-light-svg-icons';
import { faBan as farBan }   from '@fortawesome/pro-regular-svg-icons';
import { faBan as fasBan }   from '@fortawesome/pro-solid-svg-icons';
import { faBanana as fadBanana }   from '@fortawesome/pro-duotone-svg-icons';
import { faBanana as falBanana }   from '@fortawesome/pro-light-svg-icons';
import { faBanana as farBanana }   from '@fortawesome/pro-regular-svg-icons';
import { faBanana as fasBanana }   from '@fortawesome/pro-solid-svg-icons';
import { faBanBug as fadBanBug }   from '@fortawesome/pro-duotone-svg-icons';
import { faBanBug as falBanBug }   from '@fortawesome/pro-light-svg-icons';
import { faBanBug as farBanBug }   from '@fortawesome/pro-regular-svg-icons';
import { faBanBug as fasBanBug }   from '@fortawesome/pro-solid-svg-icons';
import { faBandage as fadBandage }   from '@fortawesome/pro-duotone-svg-icons';
import { faBandage as falBandage }   from '@fortawesome/pro-light-svg-icons';
import { faBandage as farBandage }   from '@fortawesome/pro-regular-svg-icons';
import { faBandage as fasBandage }   from '@fortawesome/pro-solid-svg-icons';
import { faBandAid as fadBandAid }   from '@fortawesome/pro-duotone-svg-icons';
import { faBandAid as falBandAid }   from '@fortawesome/pro-light-svg-icons';
import { faBandAid as farBandAid }   from '@fortawesome/pro-regular-svg-icons';
import { faBandAid as fasBandAid }   from '@fortawesome/pro-solid-svg-icons';
import { faBangladeshiTakaSign as fadBangladeshiTakaSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faBangladeshiTakaSign as falBangladeshiTakaSign }   from '@fortawesome/pro-light-svg-icons';
import { faBangladeshiTakaSign as farBangladeshiTakaSign }   from '@fortawesome/pro-regular-svg-icons';
import { faBangladeshiTakaSign as fasBangladeshiTakaSign }   from '@fortawesome/pro-solid-svg-icons';
import { faBanjo as fadBanjo }   from '@fortawesome/pro-duotone-svg-icons';
import { faBanjo as falBanjo }   from '@fortawesome/pro-light-svg-icons';
import { faBanjo as farBanjo }   from '@fortawesome/pro-regular-svg-icons';
import { faBanjo as fasBanjo }   from '@fortawesome/pro-solid-svg-icons';
import { faBank as fadBank }   from '@fortawesome/pro-duotone-svg-icons';
import { faBank as falBank }   from '@fortawesome/pro-light-svg-icons';
import { faBank as farBank }   from '@fortawesome/pro-regular-svg-icons';
import { faBank as fasBank }   from '@fortawesome/pro-solid-svg-icons';
import { faBanParking as fadBanParking }   from '@fortawesome/pro-duotone-svg-icons';
import { faBanParking as falBanParking }   from '@fortawesome/pro-light-svg-icons';
import { faBanParking as farBanParking }   from '@fortawesome/pro-regular-svg-icons';
import { faBanParking as fasBanParking }   from '@fortawesome/pro-solid-svg-icons';
import { faBanSmoking as fadBanSmoking }   from '@fortawesome/pro-duotone-svg-icons';
import { faBanSmoking as falBanSmoking }   from '@fortawesome/pro-light-svg-icons';
import { faBanSmoking as farBanSmoking }   from '@fortawesome/pro-regular-svg-icons';
import { faBanSmoking as fasBanSmoking }   from '@fortawesome/pro-solid-svg-icons';
import { faBarChart as fadBarChart }   from '@fortawesome/pro-duotone-svg-icons';
import { faBarChart as falBarChart }   from '@fortawesome/pro-light-svg-icons';
import { faBarChart as farBarChart }   from '@fortawesome/pro-regular-svg-icons';
import { faBarChart as fasBarChart }   from '@fortawesome/pro-solid-svg-icons';
import { faBarcode as fadBarcode }   from '@fortawesome/pro-duotone-svg-icons';
import { faBarcode as falBarcode }   from '@fortawesome/pro-light-svg-icons';
import { faBarcode as farBarcode }   from '@fortawesome/pro-regular-svg-icons';
import { faBarcode as fasBarcode }   from '@fortawesome/pro-solid-svg-icons';
import { faBarcodeAlt as fadBarcodeAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faBarcodeAlt as falBarcodeAlt }   from '@fortawesome/pro-light-svg-icons';
import { faBarcodeAlt as farBarcodeAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faBarcodeAlt as fasBarcodeAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faBarcodeRead as fadBarcodeRead }   from '@fortawesome/pro-duotone-svg-icons';
import { faBarcodeRead as falBarcodeRead }   from '@fortawesome/pro-light-svg-icons';
import { faBarcodeRead as farBarcodeRead }   from '@fortawesome/pro-regular-svg-icons';
import { faBarcodeRead as fasBarcodeRead }   from '@fortawesome/pro-solid-svg-icons';
import { faBarcodeScan as fadBarcodeScan }   from '@fortawesome/pro-duotone-svg-icons';
import { faBarcodeScan as falBarcodeScan }   from '@fortawesome/pro-light-svg-icons';
import { faBarcodeScan as farBarcodeScan }   from '@fortawesome/pro-regular-svg-icons';
import { faBarcodeScan as fasBarcodeScan }   from '@fortawesome/pro-solid-svg-icons';
import { faBarnSilo as fadBarnSilo }   from '@fortawesome/pro-duotone-svg-icons';
import { faBarnSilo as falBarnSilo }   from '@fortawesome/pro-light-svg-icons';
import { faBarnSilo as farBarnSilo }   from '@fortawesome/pro-regular-svg-icons';
import { faBarnSilo as fasBarnSilo }   from '@fortawesome/pro-solid-svg-icons';
import { faBars as fadBars }   from '@fortawesome/pro-duotone-svg-icons';
import { faBars as falBars }   from '@fortawesome/pro-light-svg-icons';
import { faBars as farBars }   from '@fortawesome/pro-regular-svg-icons';
import { faBars as fasBars }   from '@fortawesome/pro-solid-svg-icons';
import { faBarsFilter as fadBarsFilter }   from '@fortawesome/pro-duotone-svg-icons';
import { faBarsFilter as falBarsFilter }   from '@fortawesome/pro-light-svg-icons';
import { faBarsFilter as farBarsFilter }   from '@fortawesome/pro-regular-svg-icons';
import { faBarsFilter as fasBarsFilter }   from '@fortawesome/pro-solid-svg-icons';
import { faBarsProgress as fadBarsProgress }   from '@fortawesome/pro-duotone-svg-icons';
import { faBarsProgress as falBarsProgress }   from '@fortawesome/pro-light-svg-icons';
import { faBarsProgress as farBarsProgress }   from '@fortawesome/pro-regular-svg-icons';
import { faBarsProgress as fasBarsProgress }   from '@fortawesome/pro-solid-svg-icons';
import { faBarsSort as fadBarsSort }   from '@fortawesome/pro-duotone-svg-icons';
import { faBarsSort as falBarsSort }   from '@fortawesome/pro-light-svg-icons';
import { faBarsSort as farBarsSort }   from '@fortawesome/pro-regular-svg-icons';
import { faBarsSort as fasBarsSort }   from '@fortawesome/pro-solid-svg-icons';
import { faBarsStaggered as fadBarsStaggered }   from '@fortawesome/pro-duotone-svg-icons';
import { faBarsStaggered as falBarsStaggered }   from '@fortawesome/pro-light-svg-icons';
import { faBarsStaggered as farBarsStaggered }   from '@fortawesome/pro-regular-svg-icons';
import { faBarsStaggered as fasBarsStaggered }   from '@fortawesome/pro-solid-svg-icons';
import { faBaseball as fadBaseball }   from '@fortawesome/pro-duotone-svg-icons';
import { faBaseball as falBaseball }   from '@fortawesome/pro-light-svg-icons';
import { faBaseball as farBaseball }   from '@fortawesome/pro-regular-svg-icons';
import { faBaseball as fasBaseball }   from '@fortawesome/pro-solid-svg-icons';
import { faBaseballBall as fadBaseballBall }   from '@fortawesome/pro-duotone-svg-icons';
import { faBaseballBall as falBaseballBall }   from '@fortawesome/pro-light-svg-icons';
import { faBaseballBall as farBaseballBall }   from '@fortawesome/pro-regular-svg-icons';
import { faBaseballBall as fasBaseballBall }   from '@fortawesome/pro-solid-svg-icons';
import { faBaseballBatBall as fadBaseballBatBall }   from '@fortawesome/pro-duotone-svg-icons';
import { faBaseballBatBall as falBaseballBatBall }   from '@fortawesome/pro-light-svg-icons';
import { faBaseballBatBall as farBaseballBatBall }   from '@fortawesome/pro-regular-svg-icons';
import { faBaseballBatBall as fasBaseballBatBall }   from '@fortawesome/pro-solid-svg-icons';
import { faBasketball as fadBasketball }   from '@fortawesome/pro-duotone-svg-icons';
import { faBasketball as falBasketball }   from '@fortawesome/pro-light-svg-icons';
import { faBasketball as farBasketball }   from '@fortawesome/pro-regular-svg-icons';
import { faBasketball as fasBasketball }   from '@fortawesome/pro-solid-svg-icons';
import { faBasketballBall as fadBasketballBall }   from '@fortawesome/pro-duotone-svg-icons';
import { faBasketballBall as falBasketballBall }   from '@fortawesome/pro-light-svg-icons';
import { faBasketballBall as farBasketballBall }   from '@fortawesome/pro-regular-svg-icons';
import { faBasketballBall as fasBasketballBall }   from '@fortawesome/pro-solid-svg-icons';
import { faBasketballHoop as fadBasketballHoop }   from '@fortawesome/pro-duotone-svg-icons';
import { faBasketballHoop as falBasketballHoop }   from '@fortawesome/pro-light-svg-icons';
import { faBasketballHoop as farBasketballHoop }   from '@fortawesome/pro-regular-svg-icons';
import { faBasketballHoop as fasBasketballHoop }   from '@fortawesome/pro-solid-svg-icons';
import { faBasketShopping as fadBasketShopping }   from '@fortawesome/pro-duotone-svg-icons';
import { faBasketShopping as falBasketShopping }   from '@fortawesome/pro-light-svg-icons';
import { faBasketShopping as farBasketShopping }   from '@fortawesome/pro-regular-svg-icons';
import { faBasketShopping as fasBasketShopping }   from '@fortawesome/pro-solid-svg-icons';
import { faBasketShoppingSimple as fadBasketShoppingSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faBasketShoppingSimple as falBasketShoppingSimple }   from '@fortawesome/pro-light-svg-icons';
import { faBasketShoppingSimple as farBasketShoppingSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faBasketShoppingSimple as fasBasketShoppingSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faBat as fadBat }   from '@fortawesome/pro-duotone-svg-icons';
import { faBat as falBat }   from '@fortawesome/pro-light-svg-icons';
import { faBat as farBat }   from '@fortawesome/pro-regular-svg-icons';
import { faBat as fasBat }   from '@fortawesome/pro-solid-svg-icons';
import { faBath as fadBath }   from '@fortawesome/pro-duotone-svg-icons';
import { faBath as falBath }   from '@fortawesome/pro-light-svg-icons';
import { faBath as farBath }   from '@fortawesome/pro-regular-svg-icons';
import { faBath as fasBath }   from '@fortawesome/pro-solid-svg-icons';
import { faBathtub as fadBathtub }   from '@fortawesome/pro-duotone-svg-icons';
import { faBathtub as falBathtub }   from '@fortawesome/pro-light-svg-icons';
import { faBathtub as farBathtub }   from '@fortawesome/pro-regular-svg-icons';
import { faBathtub as fasBathtub }   from '@fortawesome/pro-solid-svg-icons';
import { faBattery as fadBattery }   from '@fortawesome/pro-duotone-svg-icons';
import { faBattery as falBattery }   from '@fortawesome/pro-light-svg-icons';
import { faBattery as farBattery }   from '@fortawesome/pro-regular-svg-icons';
import { faBattery as fasBattery }   from '@fortawesome/pro-solid-svg-icons';
import { faBattery0 as fadBattery0 }   from '@fortawesome/pro-duotone-svg-icons';
import { faBattery0 as falBattery0 }   from '@fortawesome/pro-light-svg-icons';
import { faBattery0 as farBattery0 }   from '@fortawesome/pro-regular-svg-icons';
import { faBattery0 as fasBattery0 }   from '@fortawesome/pro-solid-svg-icons';
import { faBattery1 as fadBattery1 }   from '@fortawesome/pro-duotone-svg-icons';
import { faBattery1 as falBattery1 }   from '@fortawesome/pro-light-svg-icons';
import { faBattery1 as farBattery1 }   from '@fortawesome/pro-regular-svg-icons';
import { faBattery1 as fasBattery1 }   from '@fortawesome/pro-solid-svg-icons';
import { faBattery2 as fadBattery2 }   from '@fortawesome/pro-duotone-svg-icons';
import { faBattery2 as falBattery2 }   from '@fortawesome/pro-light-svg-icons';
import { faBattery2 as farBattery2 }   from '@fortawesome/pro-regular-svg-icons';
import { faBattery2 as fasBattery2 }   from '@fortawesome/pro-solid-svg-icons';
import { faBattery3 as fadBattery3 }   from '@fortawesome/pro-duotone-svg-icons';
import { faBattery3 as falBattery3 }   from '@fortawesome/pro-light-svg-icons';
import { faBattery3 as farBattery3 }   from '@fortawesome/pro-regular-svg-icons';
import { faBattery3 as fasBattery3 }   from '@fortawesome/pro-solid-svg-icons';
import { faBattery4 as fadBattery4 }   from '@fortawesome/pro-duotone-svg-icons';
import { faBattery4 as falBattery4 }   from '@fortawesome/pro-light-svg-icons';
import { faBattery4 as farBattery4 }   from '@fortawesome/pro-regular-svg-icons';
import { faBattery4 as fasBattery4 }   from '@fortawesome/pro-solid-svg-icons';
import { faBattery5 as fadBattery5 }   from '@fortawesome/pro-duotone-svg-icons';
import { faBattery5 as falBattery5 }   from '@fortawesome/pro-light-svg-icons';
import { faBattery5 as farBattery5 }   from '@fortawesome/pro-regular-svg-icons';
import { faBattery5 as fasBattery5 }   from '@fortawesome/pro-solid-svg-icons';
import { faBatteryBolt as fadBatteryBolt }   from '@fortawesome/pro-duotone-svg-icons';
import { faBatteryBolt as falBatteryBolt }   from '@fortawesome/pro-light-svg-icons';
import { faBatteryBolt as farBatteryBolt }   from '@fortawesome/pro-regular-svg-icons';
import { faBatteryBolt as fasBatteryBolt }   from '@fortawesome/pro-solid-svg-icons';
import { faBatteryCar as fadBatteryCar }   from '@fortawesome/pro-duotone-svg-icons';
import { faBatteryCar as falBatteryCar }   from '@fortawesome/pro-light-svg-icons';
import { faBatteryCar as farBatteryCar }   from '@fortawesome/pro-regular-svg-icons';
import { faBatteryCar as fasBatteryCar }   from '@fortawesome/pro-solid-svg-icons';
import { faBatteryEmpty as fadBatteryEmpty }   from '@fortawesome/pro-duotone-svg-icons';
import { faBatteryEmpty as falBatteryEmpty }   from '@fortawesome/pro-light-svg-icons';
import { faBatteryEmpty as farBatteryEmpty }   from '@fortawesome/pro-regular-svg-icons';
import { faBatteryEmpty as fasBatteryEmpty }   from '@fortawesome/pro-solid-svg-icons';
import { faBatteryExclamation as fadBatteryExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faBatteryExclamation as falBatteryExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faBatteryExclamation as farBatteryExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faBatteryExclamation as fasBatteryExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faBatteryFull as fadBatteryFull }   from '@fortawesome/pro-duotone-svg-icons';
import { faBatteryFull as falBatteryFull }   from '@fortawesome/pro-light-svg-icons';
import { faBatteryFull as farBatteryFull }   from '@fortawesome/pro-regular-svg-icons';
import { faBatteryFull as fasBatteryFull }   from '@fortawesome/pro-solid-svg-icons';
import { faBatteryHalf as fadBatteryHalf }   from '@fortawesome/pro-duotone-svg-icons';
import { faBatteryHalf as falBatteryHalf }   from '@fortawesome/pro-light-svg-icons';
import { faBatteryHalf as farBatteryHalf }   from '@fortawesome/pro-regular-svg-icons';
import { faBatteryHalf as fasBatteryHalf }   from '@fortawesome/pro-solid-svg-icons';
import { faBatteryLow as fadBatteryLow }   from '@fortawesome/pro-duotone-svg-icons';
import { faBatteryLow as falBatteryLow }   from '@fortawesome/pro-light-svg-icons';
import { faBatteryLow as farBatteryLow }   from '@fortawesome/pro-regular-svg-icons';
import { faBatteryLow as fasBatteryLow }   from '@fortawesome/pro-solid-svg-icons';
import { faBatteryQuarter as fadBatteryQuarter }   from '@fortawesome/pro-duotone-svg-icons';
import { faBatteryQuarter as falBatteryQuarter }   from '@fortawesome/pro-light-svg-icons';
import { faBatteryQuarter as farBatteryQuarter }   from '@fortawesome/pro-regular-svg-icons';
import { faBatteryQuarter as fasBatteryQuarter }   from '@fortawesome/pro-solid-svg-icons';
import { faBatterySlash as fadBatterySlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faBatterySlash as falBatterySlash }   from '@fortawesome/pro-light-svg-icons';
import { faBatterySlash as farBatterySlash }   from '@fortawesome/pro-regular-svg-icons';
import { faBatterySlash as fasBatterySlash }   from '@fortawesome/pro-solid-svg-icons';
import { faBatteryThreeQuarters as fadBatteryThreeQuarters }   from '@fortawesome/pro-duotone-svg-icons';
import { faBatteryThreeQuarters as falBatteryThreeQuarters }   from '@fortawesome/pro-light-svg-icons';
import { faBatteryThreeQuarters as farBatteryThreeQuarters }   from '@fortawesome/pro-regular-svg-icons';
import { faBatteryThreeQuarters as fasBatteryThreeQuarters }   from '@fortawesome/pro-solid-svg-icons';
import { faBed as fadBed }   from '@fortawesome/pro-duotone-svg-icons';
import { faBed as falBed }   from '@fortawesome/pro-light-svg-icons';
import { faBed as farBed }   from '@fortawesome/pro-regular-svg-icons';
import { faBed as fasBed }   from '@fortawesome/pro-solid-svg-icons';
import { faBedAlt as fadBedAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faBedAlt as falBedAlt }   from '@fortawesome/pro-light-svg-icons';
import { faBedAlt as farBedAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faBedAlt as fasBedAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faBedBunk as fadBedBunk }   from '@fortawesome/pro-duotone-svg-icons';
import { faBedBunk as falBedBunk }   from '@fortawesome/pro-light-svg-icons';
import { faBedBunk as farBedBunk }   from '@fortawesome/pro-regular-svg-icons';
import { faBedBunk as fasBedBunk }   from '@fortawesome/pro-solid-svg-icons';
import { faBedEmpty as fadBedEmpty }   from '@fortawesome/pro-duotone-svg-icons';
import { faBedEmpty as falBedEmpty }   from '@fortawesome/pro-light-svg-icons';
import { faBedEmpty as farBedEmpty }   from '@fortawesome/pro-regular-svg-icons';
import { faBedEmpty as fasBedEmpty }   from '@fortawesome/pro-solid-svg-icons';
import { faBedFront as fadBedFront }   from '@fortawesome/pro-duotone-svg-icons';
import { faBedFront as falBedFront }   from '@fortawesome/pro-light-svg-icons';
import { faBedFront as farBedFront }   from '@fortawesome/pro-regular-svg-icons';
import { faBedFront as fasBedFront }   from '@fortawesome/pro-solid-svg-icons';
import { faBedPulse as fadBedPulse }   from '@fortawesome/pro-duotone-svg-icons';
import { faBedPulse as falBedPulse }   from '@fortawesome/pro-light-svg-icons';
import { faBedPulse as farBedPulse }   from '@fortawesome/pro-regular-svg-icons';
import { faBedPulse as fasBedPulse }   from '@fortawesome/pro-solid-svg-icons';
import { faBee as fadBee }   from '@fortawesome/pro-duotone-svg-icons';
import { faBee as falBee }   from '@fortawesome/pro-light-svg-icons';
import { faBee as farBee }   from '@fortawesome/pro-regular-svg-icons';
import { faBee as fasBee }   from '@fortawesome/pro-solid-svg-icons';
import { faBeer as fadBeer }   from '@fortawesome/pro-duotone-svg-icons';
import { faBeer as falBeer }   from '@fortawesome/pro-light-svg-icons';
import { faBeer as farBeer }   from '@fortawesome/pro-regular-svg-icons';
import { faBeer as fasBeer }   from '@fortawesome/pro-solid-svg-icons';
import { faBeerFoam as fadBeerFoam }   from '@fortawesome/pro-duotone-svg-icons';
import { faBeerFoam as falBeerFoam }   from '@fortawesome/pro-light-svg-icons';
import { faBeerFoam as farBeerFoam }   from '@fortawesome/pro-regular-svg-icons';
import { faBeerFoam as fasBeerFoam }   from '@fortawesome/pro-solid-svg-icons';
import { faBeerMug as fadBeerMug }   from '@fortawesome/pro-duotone-svg-icons';
import { faBeerMug as falBeerMug }   from '@fortawesome/pro-light-svg-icons';
import { faBeerMug as farBeerMug }   from '@fortawesome/pro-regular-svg-icons';
import { faBeerMug as fasBeerMug }   from '@fortawesome/pro-solid-svg-icons';
import { faBeerMugEmpty as fadBeerMugEmpty }   from '@fortawesome/pro-duotone-svg-icons';
import { faBeerMugEmpty as falBeerMugEmpty }   from '@fortawesome/pro-light-svg-icons';
import { faBeerMugEmpty as farBeerMugEmpty }   from '@fortawesome/pro-regular-svg-icons';
import { faBeerMugEmpty as fasBeerMugEmpty }   from '@fortawesome/pro-solid-svg-icons';
import { faBell as fadBell }   from '@fortawesome/pro-duotone-svg-icons';
import { faBell as falBell }   from '@fortawesome/pro-light-svg-icons';
import { faBell as farBell }   from '@fortawesome/pro-regular-svg-icons';
import { faBell as fasBell }   from '@fortawesome/pro-solid-svg-icons';
import { faBellConcierge as fadBellConcierge }   from '@fortawesome/pro-duotone-svg-icons';
import { faBellConcierge as falBellConcierge }   from '@fortawesome/pro-light-svg-icons';
import { faBellConcierge as farBellConcierge }   from '@fortawesome/pro-regular-svg-icons';
import { faBellConcierge as fasBellConcierge }   from '@fortawesome/pro-solid-svg-icons';
import { faBellExclamation as fadBellExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faBellExclamation as falBellExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faBellExclamation as farBellExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faBellExclamation as fasBellExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faBellOn as fadBellOn }   from '@fortawesome/pro-duotone-svg-icons';
import { faBellOn as falBellOn }   from '@fortawesome/pro-light-svg-icons';
import { faBellOn as farBellOn }   from '@fortawesome/pro-regular-svg-icons';
import { faBellOn as fasBellOn }   from '@fortawesome/pro-solid-svg-icons';
import { faBellPlus as fadBellPlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faBellPlus as falBellPlus }   from '@fortawesome/pro-light-svg-icons';
import { faBellPlus as farBellPlus }   from '@fortawesome/pro-regular-svg-icons';
import { faBellPlus as fasBellPlus }   from '@fortawesome/pro-solid-svg-icons';
import { faBells as fadBells }   from '@fortawesome/pro-duotone-svg-icons';
import { faBells as falBells }   from '@fortawesome/pro-light-svg-icons';
import { faBells as farBells }   from '@fortawesome/pro-regular-svg-icons';
import { faBells as fasBells }   from '@fortawesome/pro-solid-svg-icons';
import { faBellSchool as fadBellSchool }   from '@fortawesome/pro-duotone-svg-icons';
import { faBellSchool as falBellSchool }   from '@fortawesome/pro-light-svg-icons';
import { faBellSchool as farBellSchool }   from '@fortawesome/pro-regular-svg-icons';
import { faBellSchool as fasBellSchool }   from '@fortawesome/pro-solid-svg-icons';
import { faBellSchoolSlash as fadBellSchoolSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faBellSchoolSlash as falBellSchoolSlash }   from '@fortawesome/pro-light-svg-icons';
import { faBellSchoolSlash as farBellSchoolSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faBellSchoolSlash as fasBellSchoolSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faBellSlash as fadBellSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faBellSlash as falBellSlash }   from '@fortawesome/pro-light-svg-icons';
import { faBellSlash as farBellSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faBellSlash as fasBellSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faBenchTree as fadBenchTree }   from '@fortawesome/pro-duotone-svg-icons';
import { faBenchTree as falBenchTree }   from '@fortawesome/pro-light-svg-icons';
import { faBenchTree as farBenchTree }   from '@fortawesome/pro-regular-svg-icons';
import { faBenchTree as fasBenchTree }   from '@fortawesome/pro-solid-svg-icons';
import { faBetamax as fadBetamax }   from '@fortawesome/pro-duotone-svg-icons';
import { faBetamax as falBetamax }   from '@fortawesome/pro-light-svg-icons';
import { faBetamax as farBetamax }   from '@fortawesome/pro-regular-svg-icons';
import { faBetamax as fasBetamax }   from '@fortawesome/pro-solid-svg-icons';
import { faBezierCurve as fadBezierCurve }   from '@fortawesome/pro-duotone-svg-icons';
import { faBezierCurve as falBezierCurve }   from '@fortawesome/pro-light-svg-icons';
import { faBezierCurve as farBezierCurve }   from '@fortawesome/pro-regular-svg-icons';
import { faBezierCurve as fasBezierCurve }   from '@fortawesome/pro-solid-svg-icons';
import { faBible as fadBible }   from '@fortawesome/pro-duotone-svg-icons';
import { faBible as falBible }   from '@fortawesome/pro-light-svg-icons';
import { faBible as farBible }   from '@fortawesome/pro-regular-svg-icons';
import { faBible as fasBible }   from '@fortawesome/pro-solid-svg-icons';
import { faBicycle as fadBicycle }   from '@fortawesome/pro-duotone-svg-icons';
import { faBicycle as falBicycle }   from '@fortawesome/pro-light-svg-icons';
import { faBicycle as farBicycle }   from '@fortawesome/pro-regular-svg-icons';
import { faBicycle as fasBicycle }   from '@fortawesome/pro-solid-svg-icons';
import { faBiking as fadBiking }   from '@fortawesome/pro-duotone-svg-icons';
import { faBiking as falBiking }   from '@fortawesome/pro-light-svg-icons';
import { faBiking as farBiking }   from '@fortawesome/pro-regular-svg-icons';
import { faBiking as fasBiking }   from '@fortawesome/pro-solid-svg-icons';
import { faBikingMountain as fadBikingMountain }   from '@fortawesome/pro-duotone-svg-icons';
import { faBikingMountain as falBikingMountain }   from '@fortawesome/pro-light-svg-icons';
import { faBikingMountain as farBikingMountain }   from '@fortawesome/pro-regular-svg-icons';
import { faBikingMountain as fasBikingMountain }   from '@fortawesome/pro-solid-svg-icons';
import { faBinary as fadBinary }   from '@fortawesome/pro-duotone-svg-icons';
import { faBinary as falBinary }   from '@fortawesome/pro-light-svg-icons';
import { faBinary as farBinary }   from '@fortawesome/pro-regular-svg-icons';
import { faBinary as fasBinary }   from '@fortawesome/pro-solid-svg-icons';
import { faBinaryCircleCheck as fadBinaryCircleCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faBinaryCircleCheck as falBinaryCircleCheck }   from '@fortawesome/pro-light-svg-icons';
import { faBinaryCircleCheck as farBinaryCircleCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faBinaryCircleCheck as fasBinaryCircleCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faBinaryLock as fadBinaryLock }   from '@fortawesome/pro-duotone-svg-icons';
import { faBinaryLock as falBinaryLock }   from '@fortawesome/pro-light-svg-icons';
import { faBinaryLock as farBinaryLock }   from '@fortawesome/pro-regular-svg-icons';
import { faBinaryLock as fasBinaryLock }   from '@fortawesome/pro-solid-svg-icons';
import { faBinarySlash as fadBinarySlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faBinarySlash as falBinarySlash }   from '@fortawesome/pro-light-svg-icons';
import { faBinarySlash as farBinarySlash }   from '@fortawesome/pro-regular-svg-icons';
import { faBinarySlash as fasBinarySlash }   from '@fortawesome/pro-solid-svg-icons';
import { faBinoculars as fadBinoculars }   from '@fortawesome/pro-duotone-svg-icons';
import { faBinoculars as falBinoculars }   from '@fortawesome/pro-light-svg-icons';
import { faBinoculars as farBinoculars }   from '@fortawesome/pro-regular-svg-icons';
import { faBinoculars as fasBinoculars }   from '@fortawesome/pro-solid-svg-icons';
import { faBiohazard as fadBiohazard }   from '@fortawesome/pro-duotone-svg-icons';
import { faBiohazard as falBiohazard }   from '@fortawesome/pro-light-svg-icons';
import { faBiohazard as farBiohazard }   from '@fortawesome/pro-regular-svg-icons';
import { faBiohazard as fasBiohazard }   from '@fortawesome/pro-solid-svg-icons';
import { faBird as fadBird }   from '@fortawesome/pro-duotone-svg-icons';
import { faBird as falBird }   from '@fortawesome/pro-light-svg-icons';
import { faBird as farBird }   from '@fortawesome/pro-regular-svg-icons';
import { faBird as fasBird }   from '@fortawesome/pro-solid-svg-icons';
import { faBirthdayCake as fadBirthdayCake }   from '@fortawesome/pro-duotone-svg-icons';
import { faBirthdayCake as falBirthdayCake }   from '@fortawesome/pro-light-svg-icons';
import { faBirthdayCake as farBirthdayCake }   from '@fortawesome/pro-regular-svg-icons';
import { faBirthdayCake as fasBirthdayCake }   from '@fortawesome/pro-solid-svg-icons';
import { faBitcoinSign as fadBitcoinSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faBitcoinSign as falBitcoinSign }   from '@fortawesome/pro-light-svg-icons';
import { faBitcoinSign as farBitcoinSign }   from '@fortawesome/pro-regular-svg-icons';
import { faBitcoinSign as fasBitcoinSign }   from '@fortawesome/pro-solid-svg-icons';
import { faBlackboard as fadBlackboard }   from '@fortawesome/pro-duotone-svg-icons';
import { faBlackboard as falBlackboard }   from '@fortawesome/pro-light-svg-icons';
import { faBlackboard as farBlackboard }   from '@fortawesome/pro-regular-svg-icons';
import { faBlackboard as fasBlackboard }   from '@fortawesome/pro-solid-svg-icons';
import { faBlanket as fadBlanket }   from '@fortawesome/pro-duotone-svg-icons';
import { faBlanket as falBlanket }   from '@fortawesome/pro-light-svg-icons';
import { faBlanket as farBlanket }   from '@fortawesome/pro-regular-svg-icons';
import { faBlanket as fasBlanket }   from '@fortawesome/pro-solid-svg-icons';
import { faBlanketFire as fadBlanketFire }   from '@fortawesome/pro-duotone-svg-icons';
import { faBlanketFire as falBlanketFire }   from '@fortawesome/pro-light-svg-icons';
import { faBlanketFire as farBlanketFire }   from '@fortawesome/pro-regular-svg-icons';
import { faBlanketFire as fasBlanketFire }   from '@fortawesome/pro-solid-svg-icons';
import { faBlender as fadBlender }   from '@fortawesome/pro-duotone-svg-icons';
import { faBlender as falBlender }   from '@fortawesome/pro-light-svg-icons';
import { faBlender as farBlender }   from '@fortawesome/pro-regular-svg-icons';
import { faBlender as fasBlender }   from '@fortawesome/pro-solid-svg-icons';
import { faBlenderPhone as fadBlenderPhone }   from '@fortawesome/pro-duotone-svg-icons';
import { faBlenderPhone as falBlenderPhone }   from '@fortawesome/pro-light-svg-icons';
import { faBlenderPhone as farBlenderPhone }   from '@fortawesome/pro-regular-svg-icons';
import { faBlenderPhone as fasBlenderPhone }   from '@fortawesome/pro-solid-svg-icons';
import { faBlind as fadBlind }   from '@fortawesome/pro-duotone-svg-icons';
import { faBlind as falBlind }   from '@fortawesome/pro-light-svg-icons';
import { faBlind as farBlind }   from '@fortawesome/pro-regular-svg-icons';
import { faBlind as fasBlind }   from '@fortawesome/pro-solid-svg-icons';
import { faBlinds as fadBlinds }   from '@fortawesome/pro-duotone-svg-icons';
import { faBlinds as falBlinds }   from '@fortawesome/pro-light-svg-icons';
import { faBlinds as farBlinds }   from '@fortawesome/pro-regular-svg-icons';
import { faBlinds as fasBlinds }   from '@fortawesome/pro-solid-svg-icons';
import { faBlindsOpen as fadBlindsOpen }   from '@fortawesome/pro-duotone-svg-icons';
import { faBlindsOpen as falBlindsOpen }   from '@fortawesome/pro-light-svg-icons';
import { faBlindsOpen as farBlindsOpen }   from '@fortawesome/pro-regular-svg-icons';
import { faBlindsOpen as fasBlindsOpen }   from '@fortawesome/pro-solid-svg-icons';
import { faBlindsRaised as fadBlindsRaised }   from '@fortawesome/pro-duotone-svg-icons';
import { faBlindsRaised as falBlindsRaised }   from '@fortawesome/pro-light-svg-icons';
import { faBlindsRaised as farBlindsRaised }   from '@fortawesome/pro-regular-svg-icons';
import { faBlindsRaised as fasBlindsRaised }   from '@fortawesome/pro-solid-svg-icons';
import { faBlock as fadBlock }   from '@fortawesome/pro-duotone-svg-icons';
import { faBlock as falBlock }   from '@fortawesome/pro-light-svg-icons';
import { faBlock as farBlock }   from '@fortawesome/pro-regular-svg-icons';
import { faBlock as fasBlock }   from '@fortawesome/pro-solid-svg-icons';
import { faBlockBrick as fadBlockBrick }   from '@fortawesome/pro-duotone-svg-icons';
import { faBlockBrick as falBlockBrick }   from '@fortawesome/pro-light-svg-icons';
import { faBlockBrick as farBlockBrick }   from '@fortawesome/pro-regular-svg-icons';
import { faBlockBrick as fasBlockBrick }   from '@fortawesome/pro-solid-svg-icons';
import { faBlockBrickFire as fadBlockBrickFire }   from '@fortawesome/pro-duotone-svg-icons';
import { faBlockBrickFire as falBlockBrickFire }   from '@fortawesome/pro-light-svg-icons';
import { faBlockBrickFire as farBlockBrickFire }   from '@fortawesome/pro-regular-svg-icons';
import { faBlockBrickFire as fasBlockBrickFire }   from '@fortawesome/pro-solid-svg-icons';
import { faBlockQuestion as fadBlockQuestion }   from '@fortawesome/pro-duotone-svg-icons';
import { faBlockQuestion as falBlockQuestion }   from '@fortawesome/pro-light-svg-icons';
import { faBlockQuestion as farBlockQuestion }   from '@fortawesome/pro-regular-svg-icons';
import { faBlockQuestion as fasBlockQuestion }   from '@fortawesome/pro-solid-svg-icons';
import { faBlockQuote as fadBlockQuote }   from '@fortawesome/pro-duotone-svg-icons';
import { faBlockQuote as falBlockQuote }   from '@fortawesome/pro-light-svg-icons';
import { faBlockQuote as farBlockQuote }   from '@fortawesome/pro-regular-svg-icons';
import { faBlockQuote as fasBlockQuote }   from '@fortawesome/pro-solid-svg-icons';
import { faBlog as fadBlog }   from '@fortawesome/pro-duotone-svg-icons';
import { faBlog as falBlog }   from '@fortawesome/pro-light-svg-icons';
import { faBlog as farBlog }   from '@fortawesome/pro-regular-svg-icons';
import { faBlog as fasBlog }   from '@fortawesome/pro-solid-svg-icons';
import { faBlueberries as fadBlueberries }   from '@fortawesome/pro-duotone-svg-icons';
import { faBlueberries as falBlueberries }   from '@fortawesome/pro-light-svg-icons';
import { faBlueberries as farBlueberries }   from '@fortawesome/pro-regular-svg-icons';
import { faBlueberries as fasBlueberries }   from '@fortawesome/pro-solid-svg-icons';
import { faBluetooth as fadBluetooth }   from '@fortawesome/pro-duotone-svg-icons';
import { faBluetooth as falBluetooth }   from '@fortawesome/pro-light-svg-icons';
import { faBluetooth as farBluetooth }   from '@fortawesome/pro-regular-svg-icons';
import { faBluetooth as fasBluetooth }   from '@fortawesome/pro-solid-svg-icons';
import { faBold as fadBold }   from '@fortawesome/pro-duotone-svg-icons';
import { faBold as falBold }   from '@fortawesome/pro-light-svg-icons';
import { faBold as farBold }   from '@fortawesome/pro-regular-svg-icons';
import { faBold as fasBold }   from '@fortawesome/pro-solid-svg-icons';
import { faBolt as fadBolt }   from '@fortawesome/pro-duotone-svg-icons';
import { faBolt as falBolt }   from '@fortawesome/pro-light-svg-icons';
import { faBolt as farBolt }   from '@fortawesome/pro-regular-svg-icons';
import { faBolt as fasBolt }   from '@fortawesome/pro-solid-svg-icons';
import { faBoltAuto as fadBoltAuto }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoltAuto as falBoltAuto }   from '@fortawesome/pro-light-svg-icons';
import { faBoltAuto as farBoltAuto }   from '@fortawesome/pro-regular-svg-icons';
import { faBoltAuto as fasBoltAuto }   from '@fortawesome/pro-solid-svg-icons';
import { faBoltLightning as fadBoltLightning }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoltLightning as falBoltLightning }   from '@fortawesome/pro-light-svg-icons';
import { faBoltLightning as farBoltLightning }   from '@fortawesome/pro-regular-svg-icons';
import { faBoltLightning as fasBoltLightning }   from '@fortawesome/pro-solid-svg-icons';
import { faBoltSlash as fadBoltSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoltSlash as falBoltSlash }   from '@fortawesome/pro-light-svg-icons';
import { faBoltSlash as farBoltSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faBoltSlash as fasBoltSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faBomb as fadBomb }   from '@fortawesome/pro-duotone-svg-icons';
import { faBomb as falBomb }   from '@fortawesome/pro-light-svg-icons';
import { faBomb as farBomb }   from '@fortawesome/pro-regular-svg-icons';
import { faBomb as fasBomb }   from '@fortawesome/pro-solid-svg-icons';
import { faBone as fadBone }   from '@fortawesome/pro-duotone-svg-icons';
import { faBone as falBone }   from '@fortawesome/pro-light-svg-icons';
import { faBone as farBone }   from '@fortawesome/pro-regular-svg-icons';
import { faBone as fasBone }   from '@fortawesome/pro-solid-svg-icons';
import { faBoneBreak as fadBoneBreak }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoneBreak as falBoneBreak }   from '@fortawesome/pro-light-svg-icons';
import { faBoneBreak as farBoneBreak }   from '@fortawesome/pro-regular-svg-icons';
import { faBoneBreak as fasBoneBreak }   from '@fortawesome/pro-solid-svg-icons';
import { faBong as fadBong }   from '@fortawesome/pro-duotone-svg-icons';
import { faBong as falBong }   from '@fortawesome/pro-light-svg-icons';
import { faBong as farBong }   from '@fortawesome/pro-regular-svg-icons';
import { faBong as fasBong }   from '@fortawesome/pro-solid-svg-icons';
import { faBook as fadBook }   from '@fortawesome/pro-duotone-svg-icons';
import { faBook as falBook }   from '@fortawesome/pro-light-svg-icons';
import { faBook as farBook }   from '@fortawesome/pro-regular-svg-icons';
import { faBook as fasBook }   from '@fortawesome/pro-solid-svg-icons';
import { faBookAlt as fadBookAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookAlt as falBookAlt }   from '@fortawesome/pro-light-svg-icons';
import { faBookAlt as farBookAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faBookAlt as fasBookAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faBookArrowRight as fadBookArrowRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookArrowRight as falBookArrowRight }   from '@fortawesome/pro-light-svg-icons';
import { faBookArrowRight as farBookArrowRight }   from '@fortawesome/pro-regular-svg-icons';
import { faBookArrowRight as fasBookArrowRight }   from '@fortawesome/pro-solid-svg-icons';
import { faBookArrowUp as fadBookArrowUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookArrowUp as falBookArrowUp }   from '@fortawesome/pro-light-svg-icons';
import { faBookArrowUp as farBookArrowUp }   from '@fortawesome/pro-regular-svg-icons';
import { faBookArrowUp as fasBookArrowUp }   from '@fortawesome/pro-solid-svg-icons';
import { faBookAtlas as fadBookAtlas }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookAtlas as falBookAtlas }   from '@fortawesome/pro-light-svg-icons';
import { faBookAtlas as farBookAtlas }   from '@fortawesome/pro-regular-svg-icons';
import { faBookAtlas as fasBookAtlas }   from '@fortawesome/pro-solid-svg-icons';
import { faBookBible as fadBookBible }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookBible as falBookBible }   from '@fortawesome/pro-light-svg-icons';
import { faBookBible as farBookBible }   from '@fortawesome/pro-regular-svg-icons';
import { faBookBible as fasBookBible }   from '@fortawesome/pro-solid-svg-icons';
import { faBookBlank as fadBookBlank }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookBlank as falBookBlank }   from '@fortawesome/pro-light-svg-icons';
import { faBookBlank as farBookBlank }   from '@fortawesome/pro-regular-svg-icons';
import { faBookBlank as fasBookBlank }   from '@fortawesome/pro-solid-svg-icons';
import { faBookBookmark as fadBookBookmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookBookmark as falBookBookmark }   from '@fortawesome/pro-light-svg-icons';
import { faBookBookmark as farBookBookmark }   from '@fortawesome/pro-regular-svg-icons';
import { faBookBookmark as fasBookBookmark }   from '@fortawesome/pro-solid-svg-icons';
import { faBookCircle as fadBookCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookCircle as falBookCircle }   from '@fortawesome/pro-light-svg-icons';
import { faBookCircle as farBookCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faBookCircle as fasBookCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faBookCircleArrowRight as fadBookCircleArrowRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookCircleArrowRight as falBookCircleArrowRight }   from '@fortawesome/pro-light-svg-icons';
import { faBookCircleArrowRight as farBookCircleArrowRight }   from '@fortawesome/pro-regular-svg-icons';
import { faBookCircleArrowRight as fasBookCircleArrowRight }   from '@fortawesome/pro-solid-svg-icons';
import { faBookCircleArrowUp as fadBookCircleArrowUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookCircleArrowUp as falBookCircleArrowUp }   from '@fortawesome/pro-light-svg-icons';
import { faBookCircleArrowUp as farBookCircleArrowUp }   from '@fortawesome/pro-regular-svg-icons';
import { faBookCircleArrowUp as fasBookCircleArrowUp }   from '@fortawesome/pro-solid-svg-icons';
import { faBookCopy as fadBookCopy }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookCopy as falBookCopy }   from '@fortawesome/pro-light-svg-icons';
import { faBookCopy as farBookCopy }   from '@fortawesome/pro-regular-svg-icons';
import { faBookCopy as fasBookCopy }   from '@fortawesome/pro-solid-svg-icons';
import { faBookDead as fadBookDead }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookDead as falBookDead }   from '@fortawesome/pro-light-svg-icons';
import { faBookDead as farBookDead }   from '@fortawesome/pro-regular-svg-icons';
import { faBookDead as fasBookDead }   from '@fortawesome/pro-solid-svg-icons';
import { faBookFont as fadBookFont }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookFont as falBookFont }   from '@fortawesome/pro-light-svg-icons';
import { faBookFont as farBookFont }   from '@fortawesome/pro-regular-svg-icons';
import { faBookFont as fasBookFont }   from '@fortawesome/pro-solid-svg-icons';
import { faBookHeart as fadBookHeart }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookHeart as falBookHeart }   from '@fortawesome/pro-light-svg-icons';
import { faBookHeart as farBookHeart }   from '@fortawesome/pro-regular-svg-icons';
import { faBookHeart as fasBookHeart }   from '@fortawesome/pro-solid-svg-icons';
import { faBookJournalWhills as fadBookJournalWhills }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookJournalWhills as falBookJournalWhills }   from '@fortawesome/pro-light-svg-icons';
import { faBookJournalWhills as farBookJournalWhills }   from '@fortawesome/pro-regular-svg-icons';
import { faBookJournalWhills as fasBookJournalWhills }   from '@fortawesome/pro-solid-svg-icons';
import { faBookLaw as fadBookLaw }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookLaw as falBookLaw }   from '@fortawesome/pro-light-svg-icons';
import { faBookLaw as farBookLaw }   from '@fortawesome/pro-regular-svg-icons';
import { faBookLaw as fasBookLaw }   from '@fortawesome/pro-solid-svg-icons';
import { faBookmark as fadBookmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookmark as falBookmark }   from '@fortawesome/pro-light-svg-icons';
import { faBookmark as farBookmark }   from '@fortawesome/pro-regular-svg-icons';
import { faBookmark as fasBookmark }   from '@fortawesome/pro-solid-svg-icons';
import { faBookmarkCircle as fadBookmarkCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookmarkCircle as falBookmarkCircle }   from '@fortawesome/pro-light-svg-icons';
import { faBookmarkCircle as farBookmarkCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faBookmarkCircle as fasBookmarkCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faBookmarkSlash as fadBookmarkSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookmarkSlash as falBookmarkSlash }   from '@fortawesome/pro-light-svg-icons';
import { faBookmarkSlash as farBookmarkSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faBookmarkSlash as fasBookmarkSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faBookMedical as fadBookMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookMedical as falBookMedical }   from '@fortawesome/pro-light-svg-icons';
import { faBookMedical as farBookMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faBookMedical as fasBookMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faBookOpen as fadBookOpen }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookOpen as falBookOpen }   from '@fortawesome/pro-light-svg-icons';
import { faBookOpen as farBookOpen }   from '@fortawesome/pro-regular-svg-icons';
import { faBookOpen as fasBookOpen }   from '@fortawesome/pro-solid-svg-icons';
import { faBookOpenAlt as fadBookOpenAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookOpenAlt as falBookOpenAlt }   from '@fortawesome/pro-light-svg-icons';
import { faBookOpenAlt as farBookOpenAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faBookOpenAlt as fasBookOpenAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faBookOpenCover as fadBookOpenCover }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookOpenCover as falBookOpenCover }   from '@fortawesome/pro-light-svg-icons';
import { faBookOpenCover as farBookOpenCover }   from '@fortawesome/pro-regular-svg-icons';
import { faBookOpenCover as fasBookOpenCover }   from '@fortawesome/pro-solid-svg-icons';
import { faBookOpenReader as fadBookOpenReader }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookOpenReader as falBookOpenReader }   from '@fortawesome/pro-light-svg-icons';
import { faBookOpenReader as farBookOpenReader }   from '@fortawesome/pro-regular-svg-icons';
import { faBookOpenReader as fasBookOpenReader }   from '@fortawesome/pro-solid-svg-icons';
import { faBookQuran as fadBookQuran }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookQuran as falBookQuran }   from '@fortawesome/pro-light-svg-icons';
import { faBookQuran as farBookQuran }   from '@fortawesome/pro-regular-svg-icons';
import { faBookQuran as fasBookQuran }   from '@fortawesome/pro-solid-svg-icons';
import { faBookReader as fadBookReader }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookReader as falBookReader }   from '@fortawesome/pro-light-svg-icons';
import { faBookReader as farBookReader }   from '@fortawesome/pro-regular-svg-icons';
import { faBookReader as fasBookReader }   from '@fortawesome/pro-solid-svg-icons';
import { faBooks as fadBooks }   from '@fortawesome/pro-duotone-svg-icons';
import { faBooks as falBooks }   from '@fortawesome/pro-light-svg-icons';
import { faBooks as farBooks }   from '@fortawesome/pro-regular-svg-icons';
import { faBooks as fasBooks }   from '@fortawesome/pro-solid-svg-icons';
import { faBookSection as fadBookSection }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookSection as falBookSection }   from '@fortawesome/pro-light-svg-icons';
import { faBookSection as farBookSection }   from '@fortawesome/pro-regular-svg-icons';
import { faBookSection as fasBookSection }   from '@fortawesome/pro-solid-svg-icons';
import { faBookSkull as fadBookSkull }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookSkull as falBookSkull }   from '@fortawesome/pro-light-svg-icons';
import { faBookSkull as farBookSkull }   from '@fortawesome/pro-regular-svg-icons';
import { faBookSkull as fasBookSkull }   from '@fortawesome/pro-solid-svg-icons';
import { faBooksMedical as fadBooksMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faBooksMedical as falBooksMedical }   from '@fortawesome/pro-light-svg-icons';
import { faBooksMedical as farBooksMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faBooksMedical as fasBooksMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faBookSparkles as fadBookSparkles }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookSparkles as falBookSparkles }   from '@fortawesome/pro-light-svg-icons';
import { faBookSparkles as farBookSparkles }   from '@fortawesome/pro-regular-svg-icons';
import { faBookSparkles as fasBookSparkles }   from '@fortawesome/pro-solid-svg-icons';
import { faBookSpells as fadBookSpells }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookSpells as falBookSpells }   from '@fortawesome/pro-light-svg-icons';
import { faBookSpells as farBookSpells }   from '@fortawesome/pro-regular-svg-icons';
import { faBookSpells as fasBookSpells }   from '@fortawesome/pro-solid-svg-icons';
import { faBookTanakh as fadBookTanakh }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookTanakh as falBookTanakh }   from '@fortawesome/pro-light-svg-icons';
import { faBookTanakh as farBookTanakh }   from '@fortawesome/pro-regular-svg-icons';
import { faBookTanakh as fasBookTanakh }   from '@fortawesome/pro-solid-svg-icons';
import { faBookUser as fadBookUser }   from '@fortawesome/pro-duotone-svg-icons';
import { faBookUser as falBookUser }   from '@fortawesome/pro-light-svg-icons';
import { faBookUser as farBookUser }   from '@fortawesome/pro-regular-svg-icons';
import { faBookUser as fasBookUser }   from '@fortawesome/pro-solid-svg-icons';
import { faBoombox as fadBoombox }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoombox as falBoombox }   from '@fortawesome/pro-light-svg-icons';
import { faBoombox as farBoombox }   from '@fortawesome/pro-regular-svg-icons';
import { faBoombox as fasBoombox }   from '@fortawesome/pro-solid-svg-icons';
import { faBoot as fadBoot }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoot as falBoot }   from '@fortawesome/pro-light-svg-icons';
import { faBoot as farBoot }   from '@fortawesome/pro-regular-svg-icons';
import { faBoot as fasBoot }   from '@fortawesome/pro-solid-svg-icons';
import { faBoothCurtain as fadBoothCurtain }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoothCurtain as falBoothCurtain }   from '@fortawesome/pro-light-svg-icons';
import { faBoothCurtain as farBoothCurtain }   from '@fortawesome/pro-regular-svg-icons';
import { faBoothCurtain as fasBoothCurtain }   from '@fortawesome/pro-solid-svg-icons';
import { faBootHeeled as fadBootHeeled }   from '@fortawesome/pro-duotone-svg-icons';
import { faBootHeeled as falBootHeeled }   from '@fortawesome/pro-light-svg-icons';
import { faBootHeeled as farBootHeeled }   from '@fortawesome/pro-regular-svg-icons';
import { faBootHeeled as fasBootHeeled }   from '@fortawesome/pro-solid-svg-icons';
import { faBorderAll as fadBorderAll }   from '@fortawesome/pro-duotone-svg-icons';
import { faBorderAll as falBorderAll }   from '@fortawesome/pro-light-svg-icons';
import { faBorderAll as farBorderAll }   from '@fortawesome/pro-regular-svg-icons';
import { faBorderAll as fasBorderAll }   from '@fortawesome/pro-solid-svg-icons';
import { faBorderBottom as fadBorderBottom }   from '@fortawesome/pro-duotone-svg-icons';
import { faBorderBottom as falBorderBottom }   from '@fortawesome/pro-light-svg-icons';
import { faBorderBottom as farBorderBottom }   from '@fortawesome/pro-regular-svg-icons';
import { faBorderBottom as fasBorderBottom }   from '@fortawesome/pro-solid-svg-icons';
import { faBorderBottomRight as fadBorderBottomRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faBorderBottomRight as falBorderBottomRight }   from '@fortawesome/pro-light-svg-icons';
import { faBorderBottomRight as farBorderBottomRight }   from '@fortawesome/pro-regular-svg-icons';
import { faBorderBottomRight as fasBorderBottomRight }   from '@fortawesome/pro-solid-svg-icons';
import { faBorderCenterH as fadBorderCenterH }   from '@fortawesome/pro-duotone-svg-icons';
import { faBorderCenterH as falBorderCenterH }   from '@fortawesome/pro-light-svg-icons';
import { faBorderCenterH as farBorderCenterH }   from '@fortawesome/pro-regular-svg-icons';
import { faBorderCenterH as fasBorderCenterH }   from '@fortawesome/pro-solid-svg-icons';
import { faBorderCenterV as fadBorderCenterV }   from '@fortawesome/pro-duotone-svg-icons';
import { faBorderCenterV as falBorderCenterV }   from '@fortawesome/pro-light-svg-icons';
import { faBorderCenterV as farBorderCenterV }   from '@fortawesome/pro-regular-svg-icons';
import { faBorderCenterV as fasBorderCenterV }   from '@fortawesome/pro-solid-svg-icons';
import { faBorderInner as fadBorderInner }   from '@fortawesome/pro-duotone-svg-icons';
import { faBorderInner as falBorderInner }   from '@fortawesome/pro-light-svg-icons';
import { faBorderInner as farBorderInner }   from '@fortawesome/pro-regular-svg-icons';
import { faBorderInner as fasBorderInner }   from '@fortawesome/pro-solid-svg-icons';
import { faBorderLeft as fadBorderLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faBorderLeft as falBorderLeft }   from '@fortawesome/pro-light-svg-icons';
import { faBorderLeft as farBorderLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faBorderLeft as fasBorderLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faBorderNone as fadBorderNone }   from '@fortawesome/pro-duotone-svg-icons';
import { faBorderNone as falBorderNone }   from '@fortawesome/pro-light-svg-icons';
import { faBorderNone as farBorderNone }   from '@fortawesome/pro-regular-svg-icons';
import { faBorderNone as fasBorderNone }   from '@fortawesome/pro-solid-svg-icons';
import { faBorderOuter as fadBorderOuter }   from '@fortawesome/pro-duotone-svg-icons';
import { faBorderOuter as falBorderOuter }   from '@fortawesome/pro-light-svg-icons';
import { faBorderOuter as farBorderOuter }   from '@fortawesome/pro-regular-svg-icons';
import { faBorderOuter as fasBorderOuter }   from '@fortawesome/pro-solid-svg-icons';
import { faBorderRight as fadBorderRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faBorderRight as falBorderRight }   from '@fortawesome/pro-light-svg-icons';
import { faBorderRight as farBorderRight }   from '@fortawesome/pro-regular-svg-icons';
import { faBorderRight as fasBorderRight }   from '@fortawesome/pro-solid-svg-icons';
import { faBorderStyle as fadBorderStyle }   from '@fortawesome/pro-duotone-svg-icons';
import { faBorderStyle as falBorderStyle }   from '@fortawesome/pro-light-svg-icons';
import { faBorderStyle as farBorderStyle }   from '@fortawesome/pro-regular-svg-icons';
import { faBorderStyle as fasBorderStyle }   from '@fortawesome/pro-solid-svg-icons';
import { faBorderStyleAlt as fadBorderStyleAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faBorderStyleAlt as falBorderStyleAlt }   from '@fortawesome/pro-light-svg-icons';
import { faBorderStyleAlt as farBorderStyleAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faBorderStyleAlt as fasBorderStyleAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faBorderTop as fadBorderTop }   from '@fortawesome/pro-duotone-svg-icons';
import { faBorderTop as falBorderTop }   from '@fortawesome/pro-light-svg-icons';
import { faBorderTop as farBorderTop }   from '@fortawesome/pro-regular-svg-icons';
import { faBorderTop as fasBorderTop }   from '@fortawesome/pro-solid-svg-icons';
import { faBorderTopLeft as fadBorderTopLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faBorderTopLeft as falBorderTopLeft }   from '@fortawesome/pro-light-svg-icons';
import { faBorderTopLeft as farBorderTopLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faBorderTopLeft as fasBorderTopLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faBoreHole as fadBoreHole }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoreHole as falBoreHole }   from '@fortawesome/pro-light-svg-icons';
import { faBoreHole as farBoreHole }   from '@fortawesome/pro-regular-svg-icons';
import { faBoreHole as fasBoreHole }   from '@fortawesome/pro-solid-svg-icons';
import { faBottleDroplet as fadBottleDroplet }   from '@fortawesome/pro-duotone-svg-icons';
import { faBottleDroplet as falBottleDroplet }   from '@fortawesome/pro-light-svg-icons';
import { faBottleDroplet as farBottleDroplet }   from '@fortawesome/pro-regular-svg-icons';
import { faBottleDroplet as fasBottleDroplet }   from '@fortawesome/pro-solid-svg-icons';
import { faBottleWater as fadBottleWater }   from '@fortawesome/pro-duotone-svg-icons';
import { faBottleWater as falBottleWater }   from '@fortawesome/pro-light-svg-icons';
import { faBottleWater as farBottleWater }   from '@fortawesome/pro-regular-svg-icons';
import { faBottleWater as fasBottleWater }   from '@fortawesome/pro-solid-svg-icons';
import { faBowArrow as fadBowArrow }   from '@fortawesome/pro-duotone-svg-icons';
import { faBowArrow as falBowArrow }   from '@fortawesome/pro-light-svg-icons';
import { faBowArrow as farBowArrow }   from '@fortawesome/pro-regular-svg-icons';
import { faBowArrow as fasBowArrow }   from '@fortawesome/pro-solid-svg-icons';
import { faBowlChopsticks as fadBowlChopsticks }   from '@fortawesome/pro-duotone-svg-icons';
import { faBowlChopsticks as falBowlChopsticks }   from '@fortawesome/pro-light-svg-icons';
import { faBowlChopsticks as farBowlChopsticks }   from '@fortawesome/pro-regular-svg-icons';
import { faBowlChopsticks as fasBowlChopsticks }   from '@fortawesome/pro-solid-svg-icons';
import { faBowlChopsticksNoodles as fadBowlChopsticksNoodles }   from '@fortawesome/pro-duotone-svg-icons';
import { faBowlChopsticksNoodles as falBowlChopsticksNoodles }   from '@fortawesome/pro-light-svg-icons';
import { faBowlChopsticksNoodles as farBowlChopsticksNoodles }   from '@fortawesome/pro-regular-svg-icons';
import { faBowlChopsticksNoodles as fasBowlChopsticksNoodles }   from '@fortawesome/pro-solid-svg-icons';
import { faBowlFood as fadBowlFood }   from '@fortawesome/pro-duotone-svg-icons';
import { faBowlFood as falBowlFood }   from '@fortawesome/pro-light-svg-icons';
import { faBowlFood as farBowlFood }   from '@fortawesome/pro-regular-svg-icons';
import { faBowlFood as fasBowlFood }   from '@fortawesome/pro-solid-svg-icons';
import { faBowlHot as fadBowlHot }   from '@fortawesome/pro-duotone-svg-icons';
import { faBowlHot as falBowlHot }   from '@fortawesome/pro-light-svg-icons';
import { faBowlHot as farBowlHot }   from '@fortawesome/pro-regular-svg-icons';
import { faBowlHot as fasBowlHot }   from '@fortawesome/pro-solid-svg-icons';
import { faBowlingBall as fadBowlingBall }   from '@fortawesome/pro-duotone-svg-icons';
import { faBowlingBall as falBowlingBall }   from '@fortawesome/pro-light-svg-icons';
import { faBowlingBall as farBowlingBall }   from '@fortawesome/pro-regular-svg-icons';
import { faBowlingBall as fasBowlingBall }   from '@fortawesome/pro-solid-svg-icons';
import { faBowlingBallPin as fadBowlingBallPin }   from '@fortawesome/pro-duotone-svg-icons';
import { faBowlingBallPin as falBowlingBallPin }   from '@fortawesome/pro-light-svg-icons';
import { faBowlingBallPin as farBowlingBallPin }   from '@fortawesome/pro-regular-svg-icons';
import { faBowlingBallPin as fasBowlingBallPin }   from '@fortawesome/pro-solid-svg-icons';
import { faBowlingPins as fadBowlingPins }   from '@fortawesome/pro-duotone-svg-icons';
import { faBowlingPins as falBowlingPins }   from '@fortawesome/pro-light-svg-icons';
import { faBowlingPins as farBowlingPins }   from '@fortawesome/pro-regular-svg-icons';
import { faBowlingPins as fasBowlingPins }   from '@fortawesome/pro-solid-svg-icons';
import { faBowlRice as fadBowlRice }   from '@fortawesome/pro-duotone-svg-icons';
import { faBowlRice as falBowlRice }   from '@fortawesome/pro-light-svg-icons';
import { faBowlRice as farBowlRice }   from '@fortawesome/pro-regular-svg-icons';
import { faBowlRice as fasBowlRice }   from '@fortawesome/pro-solid-svg-icons';
import { faBowlSalad as fadBowlSalad }   from '@fortawesome/pro-duotone-svg-icons';
import { faBowlSalad as falBowlSalad }   from '@fortawesome/pro-light-svg-icons';
import { faBowlSalad as farBowlSalad }   from '@fortawesome/pro-regular-svg-icons';
import { faBowlSalad as fasBowlSalad }   from '@fortawesome/pro-solid-svg-icons';
import { faBowlScoop as fadBowlScoop }   from '@fortawesome/pro-duotone-svg-icons';
import { faBowlScoop as falBowlScoop }   from '@fortawesome/pro-light-svg-icons';
import { faBowlScoop as farBowlScoop }   from '@fortawesome/pro-regular-svg-icons';
import { faBowlScoop as fasBowlScoop }   from '@fortawesome/pro-solid-svg-icons';
import { faBowlScoops as fadBowlScoops }   from '@fortawesome/pro-duotone-svg-icons';
import { faBowlScoops as falBowlScoops }   from '@fortawesome/pro-light-svg-icons';
import { faBowlScoops as farBowlScoops }   from '@fortawesome/pro-regular-svg-icons';
import { faBowlScoops as fasBowlScoops }   from '@fortawesome/pro-solid-svg-icons';
import { faBowlShavedIce as fadBowlShavedIce }   from '@fortawesome/pro-duotone-svg-icons';
import { faBowlShavedIce as falBowlShavedIce }   from '@fortawesome/pro-light-svg-icons';
import { faBowlShavedIce as farBowlShavedIce }   from '@fortawesome/pro-regular-svg-icons';
import { faBowlShavedIce as fasBowlShavedIce }   from '@fortawesome/pro-solid-svg-icons';
import { faBowlSoftServe as fadBowlSoftServe }   from '@fortawesome/pro-duotone-svg-icons';
import { faBowlSoftServe as falBowlSoftServe }   from '@fortawesome/pro-light-svg-icons';
import { faBowlSoftServe as farBowlSoftServe }   from '@fortawesome/pro-regular-svg-icons';
import { faBowlSoftServe as fasBowlSoftServe }   from '@fortawesome/pro-solid-svg-icons';
import { faBowlSpoon as fadBowlSpoon }   from '@fortawesome/pro-duotone-svg-icons';
import { faBowlSpoon as falBowlSpoon }   from '@fortawesome/pro-light-svg-icons';
import { faBowlSpoon as farBowlSpoon }   from '@fortawesome/pro-regular-svg-icons';
import { faBowlSpoon as fasBowlSpoon }   from '@fortawesome/pro-solid-svg-icons';
import { faBox as fadBox }   from '@fortawesome/pro-duotone-svg-icons';
import { faBox as falBox }   from '@fortawesome/pro-light-svg-icons';
import { faBox as farBox }   from '@fortawesome/pro-regular-svg-icons';
import { faBox as fasBox }   from '@fortawesome/pro-solid-svg-icons';
import { faBoxAlt as fadBoxAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoxAlt as falBoxAlt }   from '@fortawesome/pro-light-svg-icons';
import { faBoxAlt as farBoxAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faBoxAlt as fasBoxAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faBoxArchive as fadBoxArchive }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoxArchive as falBoxArchive }   from '@fortawesome/pro-light-svg-icons';
import { faBoxArchive as farBoxArchive }   from '@fortawesome/pro-regular-svg-icons';
import { faBoxArchive as fasBoxArchive }   from '@fortawesome/pro-solid-svg-icons';
import { faBoxBallot as fadBoxBallot }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoxBallot as falBoxBallot }   from '@fortawesome/pro-light-svg-icons';
import { faBoxBallot as farBoxBallot }   from '@fortawesome/pro-regular-svg-icons';
import { faBoxBallot as fasBoxBallot }   from '@fortawesome/pro-solid-svg-icons';
import { faBoxCheck as fadBoxCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoxCheck as falBoxCheck }   from '@fortawesome/pro-light-svg-icons';
import { faBoxCheck as farBoxCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faBoxCheck as fasBoxCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faBoxCircleCheck as fadBoxCircleCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoxCircleCheck as falBoxCircleCheck }   from '@fortawesome/pro-light-svg-icons';
import { faBoxCircleCheck as farBoxCircleCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faBoxCircleCheck as fasBoxCircleCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faBoxDollar as fadBoxDollar }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoxDollar as falBoxDollar }   from '@fortawesome/pro-light-svg-icons';
import { faBoxDollar as farBoxDollar }   from '@fortawesome/pro-regular-svg-icons';
import { faBoxDollar as fasBoxDollar }   from '@fortawesome/pro-solid-svg-icons';
import { faBoxes as fadBoxes }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoxes as falBoxes }   from '@fortawesome/pro-light-svg-icons';
import { faBoxes as farBoxes }   from '@fortawesome/pro-regular-svg-icons';
import { faBoxes as fasBoxes }   from '@fortawesome/pro-solid-svg-icons';
import { faBoxesAlt as fadBoxesAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoxesAlt as falBoxesAlt }   from '@fortawesome/pro-light-svg-icons';
import { faBoxesAlt as farBoxesAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faBoxesAlt as fasBoxesAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faBoxesPacking as fadBoxesPacking }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoxesPacking as falBoxesPacking }   from '@fortawesome/pro-light-svg-icons';
import { faBoxesPacking as farBoxesPacking }   from '@fortawesome/pro-regular-svg-icons';
import { faBoxesPacking as fasBoxesPacking }   from '@fortawesome/pro-solid-svg-icons';
import { faBoxesStacked as fadBoxesStacked }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoxesStacked as falBoxesStacked }   from '@fortawesome/pro-light-svg-icons';
import { faBoxesStacked as farBoxesStacked }   from '@fortawesome/pro-regular-svg-icons';
import { faBoxesStacked as fasBoxesStacked }   from '@fortawesome/pro-solid-svg-icons';
import { faBoxFragile as fadBoxFragile }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoxFragile as falBoxFragile }   from '@fortawesome/pro-light-svg-icons';
import { faBoxFragile as farBoxFragile }   from '@fortawesome/pro-regular-svg-icons';
import { faBoxFragile as fasBoxFragile }   from '@fortawesome/pro-solid-svg-icons';
import { faBoxFull as fadBoxFull }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoxFull as falBoxFull }   from '@fortawesome/pro-light-svg-icons';
import { faBoxFull as farBoxFull }   from '@fortawesome/pro-regular-svg-icons';
import { faBoxFull as fasBoxFull }   from '@fortawesome/pro-solid-svg-icons';
import { faBoxHeart as fadBoxHeart }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoxHeart as falBoxHeart }   from '@fortawesome/pro-light-svg-icons';
import { faBoxHeart as farBoxHeart }   from '@fortawesome/pro-regular-svg-icons';
import { faBoxHeart as fasBoxHeart }   from '@fortawesome/pro-solid-svg-icons';
import { faBoxingGlove as fadBoxingGlove }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoxingGlove as falBoxingGlove }   from '@fortawesome/pro-light-svg-icons';
import { faBoxingGlove as farBoxingGlove }   from '@fortawesome/pro-regular-svg-icons';
import { faBoxingGlove as fasBoxingGlove }   from '@fortawesome/pro-solid-svg-icons';
import { faBoxOpen as fadBoxOpen }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoxOpen as falBoxOpen }   from '@fortawesome/pro-light-svg-icons';
import { faBoxOpen as farBoxOpen }   from '@fortawesome/pro-regular-svg-icons';
import { faBoxOpen as fasBoxOpen }   from '@fortawesome/pro-solid-svg-icons';
import { faBoxOpenFull as fadBoxOpenFull }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoxOpenFull as falBoxOpenFull }   from '@fortawesome/pro-light-svg-icons';
import { faBoxOpenFull as farBoxOpenFull }   from '@fortawesome/pro-regular-svg-icons';
import { faBoxOpenFull as fasBoxOpenFull }   from '@fortawesome/pro-solid-svg-icons';
import { faBoxTaped as fadBoxTaped }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoxTaped as falBoxTaped }   from '@fortawesome/pro-light-svg-icons';
import { faBoxTaped as farBoxTaped }   from '@fortawesome/pro-regular-svg-icons';
import { faBoxTaped as fasBoxTaped }   from '@fortawesome/pro-solid-svg-icons';
import { faBoxTissue as fadBoxTissue }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoxTissue as falBoxTissue }   from '@fortawesome/pro-light-svg-icons';
import { faBoxTissue as farBoxTissue }   from '@fortawesome/pro-regular-svg-icons';
import { faBoxTissue as fasBoxTissue }   from '@fortawesome/pro-solid-svg-icons';
import { faBoxUp as fadBoxUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoxUp as falBoxUp }   from '@fortawesome/pro-light-svg-icons';
import { faBoxUp as farBoxUp }   from '@fortawesome/pro-regular-svg-icons';
import { faBoxUp as fasBoxUp }   from '@fortawesome/pro-solid-svg-icons';
import { faBoxUsd as fadBoxUsd }   from '@fortawesome/pro-duotone-svg-icons';
import { faBoxUsd as falBoxUsd }   from '@fortawesome/pro-light-svg-icons';
import { faBoxUsd as farBoxUsd }   from '@fortawesome/pro-regular-svg-icons';
import { faBoxUsd as fasBoxUsd }   from '@fortawesome/pro-solid-svg-icons';
import { faBracket as fadBracket }   from '@fortawesome/pro-duotone-svg-icons';
import { faBracket as falBracket }   from '@fortawesome/pro-light-svg-icons';
import { faBracket as farBracket }   from '@fortawesome/pro-regular-svg-icons';
import { faBracket as fasBracket }   from '@fortawesome/pro-solid-svg-icons';
import { faBracketCurly as fadBracketCurly }   from '@fortawesome/pro-duotone-svg-icons';
import { faBracketCurly as falBracketCurly }   from '@fortawesome/pro-light-svg-icons';
import { faBracketCurly as farBracketCurly }   from '@fortawesome/pro-regular-svg-icons';
import { faBracketCurly as fasBracketCurly }   from '@fortawesome/pro-solid-svg-icons';
import { faBracketCurlyLeft as fadBracketCurlyLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faBracketCurlyLeft as falBracketCurlyLeft }   from '@fortawesome/pro-light-svg-icons';
import { faBracketCurlyLeft as farBracketCurlyLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faBracketCurlyLeft as fasBracketCurlyLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faBracketCurlyRight as fadBracketCurlyRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faBracketCurlyRight as falBracketCurlyRight }   from '@fortawesome/pro-light-svg-icons';
import { faBracketCurlyRight as farBracketCurlyRight }   from '@fortawesome/pro-regular-svg-icons';
import { faBracketCurlyRight as fasBracketCurlyRight }   from '@fortawesome/pro-solid-svg-icons';
import { faBracketLeft as fadBracketLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faBracketLeft as falBracketLeft }   from '@fortawesome/pro-light-svg-icons';
import { faBracketLeft as farBracketLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faBracketLeft as fasBracketLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faBracketRound as fadBracketRound }   from '@fortawesome/pro-duotone-svg-icons';
import { faBracketRound as falBracketRound }   from '@fortawesome/pro-light-svg-icons';
import { faBracketRound as farBracketRound }   from '@fortawesome/pro-regular-svg-icons';
import { faBracketRound as fasBracketRound }   from '@fortawesome/pro-solid-svg-icons';
import { faBracketRoundRight as fadBracketRoundRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faBracketRoundRight as falBracketRoundRight }   from '@fortawesome/pro-light-svg-icons';
import { faBracketRoundRight as farBracketRoundRight }   from '@fortawesome/pro-regular-svg-icons';
import { faBracketRoundRight as fasBracketRoundRight }   from '@fortawesome/pro-solid-svg-icons';
import { faBrackets as fadBrackets }   from '@fortawesome/pro-duotone-svg-icons';
import { faBrackets as falBrackets }   from '@fortawesome/pro-light-svg-icons';
import { faBrackets as farBrackets }   from '@fortawesome/pro-regular-svg-icons';
import { faBrackets as fasBrackets }   from '@fortawesome/pro-solid-svg-icons';
import { faBracketsCurly as fadBracketsCurly }   from '@fortawesome/pro-duotone-svg-icons';
import { faBracketsCurly as falBracketsCurly }   from '@fortawesome/pro-light-svg-icons';
import { faBracketsCurly as farBracketsCurly }   from '@fortawesome/pro-regular-svg-icons';
import { faBracketsCurly as fasBracketsCurly }   from '@fortawesome/pro-solid-svg-icons';
import { faBracketSquare as fadBracketSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faBracketSquare as falBracketSquare }   from '@fortawesome/pro-light-svg-icons';
import { faBracketSquare as farBracketSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faBracketSquare as fasBracketSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faBracketSquareRight as fadBracketSquareRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faBracketSquareRight as falBracketSquareRight }   from '@fortawesome/pro-light-svg-icons';
import { faBracketSquareRight as farBracketSquareRight }   from '@fortawesome/pro-regular-svg-icons';
import { faBracketSquareRight as fasBracketSquareRight }   from '@fortawesome/pro-solid-svg-icons';
import { faBracketsRound as fadBracketsRound }   from '@fortawesome/pro-duotone-svg-icons';
import { faBracketsRound as falBracketsRound }   from '@fortawesome/pro-light-svg-icons';
import { faBracketsRound as farBracketsRound }   from '@fortawesome/pro-regular-svg-icons';
import { faBracketsRound as fasBracketsRound }   from '@fortawesome/pro-solid-svg-icons';
import { faBracketsSquare as fadBracketsSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faBracketsSquare as falBracketsSquare }   from '@fortawesome/pro-light-svg-icons';
import { faBracketsSquare as farBracketsSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faBracketsSquare as fasBracketsSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faBraille as fadBraille }   from '@fortawesome/pro-duotone-svg-icons';
import { faBraille as falBraille }   from '@fortawesome/pro-light-svg-icons';
import { faBraille as farBraille }   from '@fortawesome/pro-regular-svg-icons';
import { faBraille as fasBraille }   from '@fortawesome/pro-solid-svg-icons';
import { faBrain as fadBrain }   from '@fortawesome/pro-duotone-svg-icons';
import { faBrain as falBrain }   from '@fortawesome/pro-light-svg-icons';
import { faBrain as farBrain }   from '@fortawesome/pro-regular-svg-icons';
import { faBrain as fasBrain }   from '@fortawesome/pro-solid-svg-icons';
import { faBrainArrowCurvedRight as fadBrainArrowCurvedRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faBrainArrowCurvedRight as falBrainArrowCurvedRight }   from '@fortawesome/pro-light-svg-icons';
import { faBrainArrowCurvedRight as farBrainArrowCurvedRight }   from '@fortawesome/pro-regular-svg-icons';
import { faBrainArrowCurvedRight as fasBrainArrowCurvedRight }   from '@fortawesome/pro-solid-svg-icons';
import { faBrainCircuit as fadBrainCircuit }   from '@fortawesome/pro-duotone-svg-icons';
import { faBrainCircuit as falBrainCircuit }   from '@fortawesome/pro-light-svg-icons';
import { faBrainCircuit as farBrainCircuit }   from '@fortawesome/pro-regular-svg-icons';
import { faBrainCircuit as fasBrainCircuit }   from '@fortawesome/pro-solid-svg-icons';
import { faBrakeWarning as fadBrakeWarning }   from '@fortawesome/pro-duotone-svg-icons';
import { faBrakeWarning as falBrakeWarning }   from '@fortawesome/pro-light-svg-icons';
import { faBrakeWarning as farBrakeWarning }   from '@fortawesome/pro-regular-svg-icons';
import { faBrakeWarning as fasBrakeWarning }   from '@fortawesome/pro-solid-svg-icons';
import { faBrazilianRealSign as fadBrazilianRealSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faBrazilianRealSign as falBrazilianRealSign }   from '@fortawesome/pro-light-svg-icons';
import { faBrazilianRealSign as farBrazilianRealSign }   from '@fortawesome/pro-regular-svg-icons';
import { faBrazilianRealSign as fasBrazilianRealSign }   from '@fortawesome/pro-solid-svg-icons';
import { faBreadLoaf as fadBreadLoaf }   from '@fortawesome/pro-duotone-svg-icons';
import { faBreadLoaf as falBreadLoaf }   from '@fortawesome/pro-light-svg-icons';
import { faBreadLoaf as farBreadLoaf }   from '@fortawesome/pro-regular-svg-icons';
import { faBreadLoaf as fasBreadLoaf }   from '@fortawesome/pro-solid-svg-icons';
import { faBreadSlice as fadBreadSlice }   from '@fortawesome/pro-duotone-svg-icons';
import { faBreadSlice as falBreadSlice }   from '@fortawesome/pro-light-svg-icons';
import { faBreadSlice as farBreadSlice }   from '@fortawesome/pro-regular-svg-icons';
import { faBreadSlice as fasBreadSlice }   from '@fortawesome/pro-solid-svg-icons';
import { faBreadSliceButter as fadBreadSliceButter }   from '@fortawesome/pro-duotone-svg-icons';
import { faBreadSliceButter as falBreadSliceButter }   from '@fortawesome/pro-light-svg-icons';
import { faBreadSliceButter as farBreadSliceButter }   from '@fortawesome/pro-regular-svg-icons';
import { faBreadSliceButter as fasBreadSliceButter }   from '@fortawesome/pro-solid-svg-icons';
import { faBridge as fadBridge }   from '@fortawesome/pro-duotone-svg-icons';
import { faBridge as falBridge }   from '@fortawesome/pro-light-svg-icons';
import { faBridge as farBridge }   from '@fortawesome/pro-regular-svg-icons';
import { faBridge as fasBridge }   from '@fortawesome/pro-solid-svg-icons';
import { faBridgeCircleCheck as fadBridgeCircleCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faBridgeCircleCheck as falBridgeCircleCheck }   from '@fortawesome/pro-light-svg-icons';
import { faBridgeCircleCheck as farBridgeCircleCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faBridgeCircleCheck as fasBridgeCircleCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faBridgeCircleExclamation as fadBridgeCircleExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faBridgeCircleExclamation as falBridgeCircleExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faBridgeCircleExclamation as farBridgeCircleExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faBridgeCircleExclamation as fasBridgeCircleExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faBridgeCircleXmark as fadBridgeCircleXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faBridgeCircleXmark as falBridgeCircleXmark }   from '@fortawesome/pro-light-svg-icons';
import { faBridgeCircleXmark as farBridgeCircleXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faBridgeCircleXmark as fasBridgeCircleXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faBridgeLock as fadBridgeLock }   from '@fortawesome/pro-duotone-svg-icons';
import { faBridgeLock as falBridgeLock }   from '@fortawesome/pro-light-svg-icons';
import { faBridgeLock as farBridgeLock }   from '@fortawesome/pro-regular-svg-icons';
import { faBridgeLock as fasBridgeLock }   from '@fortawesome/pro-solid-svg-icons';
import { faBridgeSuspension as fadBridgeSuspension }   from '@fortawesome/pro-duotone-svg-icons';
import { faBridgeSuspension as falBridgeSuspension }   from '@fortawesome/pro-light-svg-icons';
import { faBridgeSuspension as farBridgeSuspension }   from '@fortawesome/pro-regular-svg-icons';
import { faBridgeSuspension as fasBridgeSuspension }   from '@fortawesome/pro-solid-svg-icons';
import { faBridgeWater as fadBridgeWater }   from '@fortawesome/pro-duotone-svg-icons';
import { faBridgeWater as falBridgeWater }   from '@fortawesome/pro-light-svg-icons';
import { faBridgeWater as farBridgeWater }   from '@fortawesome/pro-regular-svg-icons';
import { faBridgeWater as fasBridgeWater }   from '@fortawesome/pro-solid-svg-icons';
import { faBriefcase as fadBriefcase }   from '@fortawesome/pro-duotone-svg-icons';
import { faBriefcase as falBriefcase }   from '@fortawesome/pro-light-svg-icons';
import { faBriefcase as farBriefcase }   from '@fortawesome/pro-regular-svg-icons';
import { faBriefcase as fasBriefcase }   from '@fortawesome/pro-solid-svg-icons';
import { faBriefcaseArrowRight as fadBriefcaseArrowRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faBriefcaseArrowRight as falBriefcaseArrowRight }   from '@fortawesome/pro-light-svg-icons';
import { faBriefcaseArrowRight as farBriefcaseArrowRight }   from '@fortawesome/pro-regular-svg-icons';
import { faBriefcaseArrowRight as fasBriefcaseArrowRight }   from '@fortawesome/pro-solid-svg-icons';
import { faBriefcaseBlank as fadBriefcaseBlank }   from '@fortawesome/pro-duotone-svg-icons';
import { faBriefcaseBlank as falBriefcaseBlank }   from '@fortawesome/pro-light-svg-icons';
import { faBriefcaseBlank as farBriefcaseBlank }   from '@fortawesome/pro-regular-svg-icons';
import { faBriefcaseBlank as fasBriefcaseBlank }   from '@fortawesome/pro-solid-svg-icons';
import { faBriefcaseClock as fadBriefcaseClock }   from '@fortawesome/pro-duotone-svg-icons';
import { faBriefcaseClock as falBriefcaseClock }   from '@fortawesome/pro-light-svg-icons';
import { faBriefcaseClock as farBriefcaseClock }   from '@fortawesome/pro-regular-svg-icons';
import { faBriefcaseClock as fasBriefcaseClock }   from '@fortawesome/pro-solid-svg-icons';
import { faBriefcaseMedical as fadBriefcaseMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faBriefcaseMedical as falBriefcaseMedical }   from '@fortawesome/pro-light-svg-icons';
import { faBriefcaseMedical as farBriefcaseMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faBriefcaseMedical as fasBriefcaseMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faBrightness as fadBrightness }   from '@fortawesome/pro-duotone-svg-icons';
import { faBrightness as falBrightness }   from '@fortawesome/pro-light-svg-icons';
import { faBrightness as farBrightness }   from '@fortawesome/pro-regular-svg-icons';
import { faBrightness as fasBrightness }   from '@fortawesome/pro-solid-svg-icons';
import { faBrightnessLow as fadBrightnessLow }   from '@fortawesome/pro-duotone-svg-icons';
import { faBrightnessLow as falBrightnessLow }   from '@fortawesome/pro-light-svg-icons';
import { faBrightnessLow as farBrightnessLow }   from '@fortawesome/pro-regular-svg-icons';
import { faBrightnessLow as fasBrightnessLow }   from '@fortawesome/pro-solid-svg-icons';
import { faBringForward as fadBringForward }   from '@fortawesome/pro-duotone-svg-icons';
import { faBringForward as falBringForward }   from '@fortawesome/pro-light-svg-icons';
import { faBringForward as farBringForward }   from '@fortawesome/pro-regular-svg-icons';
import { faBringForward as fasBringForward }   from '@fortawesome/pro-solid-svg-icons';
import { faBringFront as fadBringFront }   from '@fortawesome/pro-duotone-svg-icons';
import { faBringFront as falBringFront }   from '@fortawesome/pro-light-svg-icons';
import { faBringFront as farBringFront }   from '@fortawesome/pro-regular-svg-icons';
import { faBringFront as fasBringFront }   from '@fortawesome/pro-solid-svg-icons';
import { faBroadcastTower as fadBroadcastTower }   from '@fortawesome/pro-duotone-svg-icons';
import { faBroadcastTower as falBroadcastTower }   from '@fortawesome/pro-light-svg-icons';
import { faBroadcastTower as farBroadcastTower }   from '@fortawesome/pro-regular-svg-icons';
import { faBroadcastTower as fasBroadcastTower }   from '@fortawesome/pro-solid-svg-icons';
import { faBroccoli as fadBroccoli }   from '@fortawesome/pro-duotone-svg-icons';
import { faBroccoli as falBroccoli }   from '@fortawesome/pro-light-svg-icons';
import { faBroccoli as farBroccoli }   from '@fortawesome/pro-regular-svg-icons';
import { faBroccoli as fasBroccoli }   from '@fortawesome/pro-solid-svg-icons';
import { faBroom as fadBroom }   from '@fortawesome/pro-duotone-svg-icons';
import { faBroom as falBroom }   from '@fortawesome/pro-light-svg-icons';
import { faBroom as farBroom }   from '@fortawesome/pro-regular-svg-icons';
import { faBroom as fasBroom }   from '@fortawesome/pro-solid-svg-icons';
import { faBroomBall as fadBroomBall }   from '@fortawesome/pro-duotone-svg-icons';
import { faBroomBall as falBroomBall }   from '@fortawesome/pro-light-svg-icons';
import { faBroomBall as farBroomBall }   from '@fortawesome/pro-regular-svg-icons';
import { faBroomBall as fasBroomBall }   from '@fortawesome/pro-solid-svg-icons';
import { faBrowser as fadBrowser }   from '@fortawesome/pro-duotone-svg-icons';
import { faBrowser as falBrowser }   from '@fortawesome/pro-light-svg-icons';
import { faBrowser as farBrowser }   from '@fortawesome/pro-regular-svg-icons';
import { faBrowser as fasBrowser }   from '@fortawesome/pro-solid-svg-icons';
import { faBrowsers as fadBrowsers }   from '@fortawesome/pro-duotone-svg-icons';
import { faBrowsers as falBrowsers }   from '@fortawesome/pro-light-svg-icons';
import { faBrowsers as farBrowsers }   from '@fortawesome/pro-regular-svg-icons';
import { faBrowsers as fasBrowsers }   from '@fortawesome/pro-solid-svg-icons';
import { faBrush as fadBrush }   from '@fortawesome/pro-duotone-svg-icons';
import { faBrush as falBrush }   from '@fortawesome/pro-light-svg-icons';
import { faBrush as farBrush }   from '@fortawesome/pro-regular-svg-icons';
import { faBrush as fasBrush }   from '@fortawesome/pro-solid-svg-icons';
import { faBucket as fadBucket }   from '@fortawesome/pro-duotone-svg-icons';
import { faBucket as falBucket }   from '@fortawesome/pro-light-svg-icons';
import { faBucket as farBucket }   from '@fortawesome/pro-regular-svg-icons';
import { faBucket as fasBucket }   from '@fortawesome/pro-solid-svg-icons';
import { faBug as fadBug }   from '@fortawesome/pro-duotone-svg-icons';
import { faBug as falBug }   from '@fortawesome/pro-light-svg-icons';
import { faBug as farBug }   from '@fortawesome/pro-regular-svg-icons';
import { faBug as fasBug }   from '@fortawesome/pro-solid-svg-icons';
import { faBugs as fadBugs }   from '@fortawesome/pro-duotone-svg-icons';
import { faBugs as falBugs }   from '@fortawesome/pro-light-svg-icons';
import { faBugs as farBugs }   from '@fortawesome/pro-regular-svg-icons';
import { faBugs as fasBugs }   from '@fortawesome/pro-solid-svg-icons';
import { faBugSlash as fadBugSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faBugSlash as falBugSlash }   from '@fortawesome/pro-light-svg-icons';
import { faBugSlash as farBugSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faBugSlash as fasBugSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faBuilding as fadBuilding }   from '@fortawesome/pro-duotone-svg-icons';
import { faBuilding as falBuilding }   from '@fortawesome/pro-light-svg-icons';
import { faBuilding as farBuilding }   from '@fortawesome/pro-regular-svg-icons';
import { faBuilding as fasBuilding }   from '@fortawesome/pro-solid-svg-icons';
import { faBuildingCircleArrowRight as fadBuildingCircleArrowRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faBuildingCircleArrowRight as falBuildingCircleArrowRight }   from '@fortawesome/pro-light-svg-icons';
import { faBuildingCircleArrowRight as farBuildingCircleArrowRight }   from '@fortawesome/pro-regular-svg-icons';
import { faBuildingCircleArrowRight as fasBuildingCircleArrowRight }   from '@fortawesome/pro-solid-svg-icons';
import { faBuildingCircleCheck as fadBuildingCircleCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faBuildingCircleCheck as falBuildingCircleCheck }   from '@fortawesome/pro-light-svg-icons';
import { faBuildingCircleCheck as farBuildingCircleCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faBuildingCircleCheck as fasBuildingCircleCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faBuildingCircleExclamation as fadBuildingCircleExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faBuildingCircleExclamation as falBuildingCircleExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faBuildingCircleExclamation as farBuildingCircleExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faBuildingCircleExclamation as fasBuildingCircleExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faBuildingCircleXmark as fadBuildingCircleXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faBuildingCircleXmark as falBuildingCircleXmark }   from '@fortawesome/pro-light-svg-icons';
import { faBuildingCircleXmark as farBuildingCircleXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faBuildingCircleXmark as fasBuildingCircleXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faBuildingColumns as fadBuildingColumns }   from '@fortawesome/pro-duotone-svg-icons';
import { faBuildingColumns as falBuildingColumns }   from '@fortawesome/pro-light-svg-icons';
import { faBuildingColumns as farBuildingColumns }   from '@fortawesome/pro-regular-svg-icons';
import { faBuildingColumns as fasBuildingColumns }   from '@fortawesome/pro-solid-svg-icons';
import { faBuildingFlag as fadBuildingFlag }   from '@fortawesome/pro-duotone-svg-icons';
import { faBuildingFlag as falBuildingFlag }   from '@fortawesome/pro-light-svg-icons';
import { faBuildingFlag as farBuildingFlag }   from '@fortawesome/pro-regular-svg-icons';
import { faBuildingFlag as fasBuildingFlag }   from '@fortawesome/pro-solid-svg-icons';
import { faBuildingLock as fadBuildingLock }   from '@fortawesome/pro-duotone-svg-icons';
import { faBuildingLock as falBuildingLock }   from '@fortawesome/pro-light-svg-icons';
import { faBuildingLock as farBuildingLock }   from '@fortawesome/pro-regular-svg-icons';
import { faBuildingLock as fasBuildingLock }   from '@fortawesome/pro-solid-svg-icons';
import { faBuildingNgo as fadBuildingNgo }   from '@fortawesome/pro-duotone-svg-icons';
import { faBuildingNgo as falBuildingNgo }   from '@fortawesome/pro-light-svg-icons';
import { faBuildingNgo as farBuildingNgo }   from '@fortawesome/pro-regular-svg-icons';
import { faBuildingNgo as fasBuildingNgo }   from '@fortawesome/pro-solid-svg-icons';
import { faBuildings as fadBuildings }   from '@fortawesome/pro-duotone-svg-icons';
import { faBuildings as falBuildings }   from '@fortawesome/pro-light-svg-icons';
import { faBuildings as farBuildings }   from '@fortawesome/pro-regular-svg-icons';
import { faBuildings as fasBuildings }   from '@fortawesome/pro-solid-svg-icons';
import { faBuildingShield as fadBuildingShield }   from '@fortawesome/pro-duotone-svg-icons';
import { faBuildingShield as falBuildingShield }   from '@fortawesome/pro-light-svg-icons';
import { faBuildingShield as farBuildingShield }   from '@fortawesome/pro-regular-svg-icons';
import { faBuildingShield as fasBuildingShield }   from '@fortawesome/pro-solid-svg-icons';
import { faBuildingUn as fadBuildingUn }   from '@fortawesome/pro-duotone-svg-icons';
import { faBuildingUn as falBuildingUn }   from '@fortawesome/pro-light-svg-icons';
import { faBuildingUn as farBuildingUn }   from '@fortawesome/pro-regular-svg-icons';
import { faBuildingUn as fasBuildingUn }   from '@fortawesome/pro-solid-svg-icons';
import { faBuildingUser as fadBuildingUser }   from '@fortawesome/pro-duotone-svg-icons';
import { faBuildingUser as falBuildingUser }   from '@fortawesome/pro-light-svg-icons';
import { faBuildingUser as farBuildingUser }   from '@fortawesome/pro-regular-svg-icons';
import { faBuildingUser as fasBuildingUser }   from '@fortawesome/pro-solid-svg-icons';
import { faBuildingWheat as fadBuildingWheat }   from '@fortawesome/pro-duotone-svg-icons';
import { faBuildingWheat as falBuildingWheat }   from '@fortawesome/pro-light-svg-icons';
import { faBuildingWheat as farBuildingWheat }   from '@fortawesome/pro-regular-svg-icons';
import { faBuildingWheat as fasBuildingWheat }   from '@fortawesome/pro-solid-svg-icons';
import { faBullhorn as fadBullhorn }   from '@fortawesome/pro-duotone-svg-icons';
import { faBullhorn as falBullhorn }   from '@fortawesome/pro-light-svg-icons';
import { faBullhorn as farBullhorn }   from '@fortawesome/pro-regular-svg-icons';
import { faBullhorn as fasBullhorn }   from '@fortawesome/pro-solid-svg-icons';
import { faBullseye as fadBullseye }   from '@fortawesome/pro-duotone-svg-icons';
import { faBullseye as falBullseye }   from '@fortawesome/pro-light-svg-icons';
import { faBullseye as farBullseye }   from '@fortawesome/pro-regular-svg-icons';
import { faBullseye as fasBullseye }   from '@fortawesome/pro-solid-svg-icons';
import { faBullseyeArrow as fadBullseyeArrow }   from '@fortawesome/pro-duotone-svg-icons';
import { faBullseyeArrow as falBullseyeArrow }   from '@fortawesome/pro-light-svg-icons';
import { faBullseyeArrow as farBullseyeArrow }   from '@fortawesome/pro-regular-svg-icons';
import { faBullseyeArrow as fasBullseyeArrow }   from '@fortawesome/pro-solid-svg-icons';
import { faBullseyePointer as fadBullseyePointer }   from '@fortawesome/pro-duotone-svg-icons';
import { faBullseyePointer as falBullseyePointer }   from '@fortawesome/pro-light-svg-icons';
import { faBullseyePointer as farBullseyePointer }   from '@fortawesome/pro-regular-svg-icons';
import { faBullseyePointer as fasBullseyePointer }   from '@fortawesome/pro-solid-svg-icons';
import { faBurger as fadBurger }   from '@fortawesome/pro-duotone-svg-icons';
import { faBurger as falBurger }   from '@fortawesome/pro-light-svg-icons';
import { faBurger as farBurger }   from '@fortawesome/pro-regular-svg-icons';
import { faBurger as fasBurger }   from '@fortawesome/pro-solid-svg-icons';
import { faBurgerCheese as fadBurgerCheese }   from '@fortawesome/pro-duotone-svg-icons';
import { faBurgerCheese as falBurgerCheese }   from '@fortawesome/pro-light-svg-icons';
import { faBurgerCheese as farBurgerCheese }   from '@fortawesome/pro-regular-svg-icons';
import { faBurgerCheese as fasBurgerCheese }   from '@fortawesome/pro-solid-svg-icons';
import { faBurgerFries as fadBurgerFries }   from '@fortawesome/pro-duotone-svg-icons';
import { faBurgerFries as falBurgerFries }   from '@fortawesome/pro-light-svg-icons';
import { faBurgerFries as farBurgerFries }   from '@fortawesome/pro-regular-svg-icons';
import { faBurgerFries as fasBurgerFries }   from '@fortawesome/pro-solid-svg-icons';
import { faBurgerGlass as fadBurgerGlass }   from '@fortawesome/pro-duotone-svg-icons';
import { faBurgerGlass as falBurgerGlass }   from '@fortawesome/pro-light-svg-icons';
import { faBurgerGlass as farBurgerGlass }   from '@fortawesome/pro-regular-svg-icons';
import { faBurgerGlass as fasBurgerGlass }   from '@fortawesome/pro-solid-svg-icons';
import { faBurgerLettuce as fadBurgerLettuce }   from '@fortawesome/pro-duotone-svg-icons';
import { faBurgerLettuce as falBurgerLettuce }   from '@fortawesome/pro-light-svg-icons';
import { faBurgerLettuce as farBurgerLettuce }   from '@fortawesome/pro-regular-svg-icons';
import { faBurgerLettuce as fasBurgerLettuce }   from '@fortawesome/pro-solid-svg-icons';
import { faBurgerSoda as fadBurgerSoda }   from '@fortawesome/pro-duotone-svg-icons';
import { faBurgerSoda as falBurgerSoda }   from '@fortawesome/pro-light-svg-icons';
import { faBurgerSoda as farBurgerSoda }   from '@fortawesome/pro-regular-svg-icons';
import { faBurgerSoda as fasBurgerSoda }   from '@fortawesome/pro-solid-svg-icons';
import { faBurn as fadBurn }   from '@fortawesome/pro-duotone-svg-icons';
import { faBurn as falBurn }   from '@fortawesome/pro-light-svg-icons';
import { faBurn as farBurn }   from '@fortawesome/pro-regular-svg-icons';
import { faBurn as fasBurn }   from '@fortawesome/pro-solid-svg-icons';
import { faBurrito as fadBurrito }   from '@fortawesome/pro-duotone-svg-icons';
import { faBurrito as falBurrito }   from '@fortawesome/pro-light-svg-icons';
import { faBurrito as farBurrito }   from '@fortawesome/pro-regular-svg-icons';
import { faBurrito as fasBurrito }   from '@fortawesome/pro-solid-svg-icons';
import { faBurst as fadBurst }   from '@fortawesome/pro-duotone-svg-icons';
import { faBurst as falBurst }   from '@fortawesome/pro-light-svg-icons';
import { faBurst as farBurst }   from '@fortawesome/pro-regular-svg-icons';
import { faBurst as fasBurst }   from '@fortawesome/pro-solid-svg-icons';
import { faBus as fadBus }   from '@fortawesome/pro-duotone-svg-icons';
import { faBus as falBus }   from '@fortawesome/pro-light-svg-icons';
import { faBus as farBus }   from '@fortawesome/pro-regular-svg-icons';
import { faBus as fasBus }   from '@fortawesome/pro-solid-svg-icons';
import { faBusAlt as fadBusAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faBusAlt as falBusAlt }   from '@fortawesome/pro-light-svg-icons';
import { faBusAlt as farBusAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faBusAlt as fasBusAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faBusinessFront as fadBusinessFront }   from '@fortawesome/pro-duotone-svg-icons';
import { faBusinessFront as falBusinessFront }   from '@fortawesome/pro-light-svg-icons';
import { faBusinessFront as farBusinessFront }   from '@fortawesome/pro-regular-svg-icons';
import { faBusinessFront as fasBusinessFront }   from '@fortawesome/pro-solid-svg-icons';
import { faBusinessTime as fadBusinessTime }   from '@fortawesome/pro-duotone-svg-icons';
import { faBusinessTime as falBusinessTime }   from '@fortawesome/pro-light-svg-icons';
import { faBusinessTime as farBusinessTime }   from '@fortawesome/pro-regular-svg-icons';
import { faBusinessTime as fasBusinessTime }   from '@fortawesome/pro-solid-svg-icons';
import { faBusSchool as fadBusSchool }   from '@fortawesome/pro-duotone-svg-icons';
import { faBusSchool as falBusSchool }   from '@fortawesome/pro-light-svg-icons';
import { faBusSchool as farBusSchool }   from '@fortawesome/pro-regular-svg-icons';
import { faBusSchool as fasBusSchool }   from '@fortawesome/pro-solid-svg-icons';
import { faBusSimple as fadBusSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faBusSimple as falBusSimple }   from '@fortawesome/pro-light-svg-icons';
import { faBusSimple as farBusSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faBusSimple as fasBusSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faButter as fadButter }   from '@fortawesome/pro-duotone-svg-icons';
import { faButter as falButter }   from '@fortawesome/pro-light-svg-icons';
import { faButter as farButter }   from '@fortawesome/pro-regular-svg-icons';
import { faButter as fasButter }   from '@fortawesome/pro-solid-svg-icons';
import { faC as fadC }   from '@fortawesome/pro-duotone-svg-icons';
import { faC as falC }   from '@fortawesome/pro-light-svg-icons';
import { faC as farC }   from '@fortawesome/pro-regular-svg-icons';
import { faC as fasC }   from '@fortawesome/pro-solid-svg-icons';
import { faCab as fadCab }   from '@fortawesome/pro-duotone-svg-icons';
import { faCab as falCab }   from '@fortawesome/pro-light-svg-icons';
import { faCab as farCab }   from '@fortawesome/pro-regular-svg-icons';
import { faCab as fasCab }   from '@fortawesome/pro-solid-svg-icons';
import { faCabin as fadCabin }   from '@fortawesome/pro-duotone-svg-icons';
import { faCabin as falCabin }   from '@fortawesome/pro-light-svg-icons';
import { faCabin as farCabin }   from '@fortawesome/pro-regular-svg-icons';
import { faCabin as fasCabin }   from '@fortawesome/pro-solid-svg-icons';
import { faCabinetFiling as fadCabinetFiling }   from '@fortawesome/pro-duotone-svg-icons';
import { faCabinetFiling as falCabinetFiling }   from '@fortawesome/pro-light-svg-icons';
import { faCabinetFiling as farCabinetFiling }   from '@fortawesome/pro-regular-svg-icons';
import { faCabinetFiling as fasCabinetFiling }   from '@fortawesome/pro-solid-svg-icons';
import { faCableCar as fadCableCar }   from '@fortawesome/pro-duotone-svg-icons';
import { faCableCar as falCableCar }   from '@fortawesome/pro-light-svg-icons';
import { faCableCar as farCableCar }   from '@fortawesome/pro-regular-svg-icons';
import { faCableCar as fasCableCar }   from '@fortawesome/pro-solid-svg-icons';
import { faCactus as fadCactus }   from '@fortawesome/pro-duotone-svg-icons';
import { faCactus as falCactus }   from '@fortawesome/pro-light-svg-icons';
import { faCactus as farCactus }   from '@fortawesome/pro-regular-svg-icons';
import { faCactus as fasCactus }   from '@fortawesome/pro-solid-svg-icons';
import { faCake as fadCake }   from '@fortawesome/pro-duotone-svg-icons';
import { faCake as falCake }   from '@fortawesome/pro-light-svg-icons';
import { faCake as farCake }   from '@fortawesome/pro-regular-svg-icons';
import { faCake as fasCake }   from '@fortawesome/pro-solid-svg-icons';
import { faCakeCandles as fadCakeCandles }   from '@fortawesome/pro-duotone-svg-icons';
import { faCakeCandles as falCakeCandles }   from '@fortawesome/pro-light-svg-icons';
import { faCakeCandles as farCakeCandles }   from '@fortawesome/pro-regular-svg-icons';
import { faCakeCandles as fasCakeCandles }   from '@fortawesome/pro-solid-svg-icons';
import { faCakeSlice as fadCakeSlice }   from '@fortawesome/pro-duotone-svg-icons';
import { faCakeSlice as falCakeSlice }   from '@fortawesome/pro-light-svg-icons';
import { faCakeSlice as farCakeSlice }   from '@fortawesome/pro-regular-svg-icons';
import { faCakeSlice as fasCakeSlice }   from '@fortawesome/pro-solid-svg-icons';
import { faCalculator as fadCalculator }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalculator as falCalculator }   from '@fortawesome/pro-light-svg-icons';
import { faCalculator as farCalculator }   from '@fortawesome/pro-regular-svg-icons';
import { faCalculator as fasCalculator }   from '@fortawesome/pro-solid-svg-icons';
import { faCalculatorAlt as fadCalculatorAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalculatorAlt as falCalculatorAlt }   from '@fortawesome/pro-light-svg-icons';
import { faCalculatorAlt as farCalculatorAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faCalculatorAlt as fasCalculatorAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faCalculatorSimple as fadCalculatorSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalculatorSimple as falCalculatorSimple }   from '@fortawesome/pro-light-svg-icons';
import { faCalculatorSimple as farCalculatorSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faCalculatorSimple as fasCalculatorSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendar as fadCalendar }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendar as falCalendar }   from '@fortawesome/pro-light-svg-icons';
import { faCalendar as farCalendar }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendar as fasCalendar }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarAlt as fadCalendarAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarAlt as falCalendarAlt }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarAlt as farCalendarAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarAlt as fasCalendarAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarArrowDown as fadCalendarArrowDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarArrowDown as falCalendarArrowDown }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarArrowDown as farCalendarArrowDown }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarArrowDown as fasCalendarArrowDown }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarArrowUp as fadCalendarArrowUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarArrowUp as falCalendarArrowUp }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarArrowUp as farCalendarArrowUp }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarArrowUp as fasCalendarArrowUp }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarCheck as fadCalendarCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarCheck as falCalendarCheck }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarCheck as farCalendarCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarCheck as fasCalendarCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarCircle as fadCalendarCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarCircle as falCalendarCircle }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarCircle as farCalendarCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarCircle as fasCalendarCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarCircleExclamation as fadCalendarCircleExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarCircleExclamation as falCalendarCircleExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarCircleExclamation as farCalendarCircleExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarCircleExclamation as fasCalendarCircleExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarCircleMinus as fadCalendarCircleMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarCircleMinus as falCalendarCircleMinus }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarCircleMinus as farCalendarCircleMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarCircleMinus as fasCalendarCircleMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarCirclePlus as fadCalendarCirclePlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarCirclePlus as falCalendarCirclePlus }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarCirclePlus as farCalendarCirclePlus }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarCirclePlus as fasCalendarCirclePlus }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarCircleUser as fadCalendarCircleUser }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarCircleUser as falCalendarCircleUser }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarCircleUser as farCalendarCircleUser }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarCircleUser as fasCalendarCircleUser }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarClock as fadCalendarClock }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarClock as falCalendarClock }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarClock as farCalendarClock }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarClock as fasCalendarClock }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarDay as fadCalendarDay }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarDay as falCalendarDay }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarDay as farCalendarDay }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarDay as fasCalendarDay }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarDays as fadCalendarDays }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarDays as falCalendarDays }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarDays as farCalendarDays }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarDays as fasCalendarDays }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarDownload as fadCalendarDownload }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarDownload as falCalendarDownload }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarDownload as farCalendarDownload }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarDownload as fasCalendarDownload }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarEdit as fadCalendarEdit }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarEdit as falCalendarEdit }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarEdit as farCalendarEdit }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarEdit as fasCalendarEdit }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarExclamation as fadCalendarExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarExclamation as falCalendarExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarExclamation as farCalendarExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarExclamation as fasCalendarExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarHeart as fadCalendarHeart }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarHeart as falCalendarHeart }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarHeart as farCalendarHeart }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarHeart as fasCalendarHeart }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarImage as fadCalendarImage }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarImage as falCalendarImage }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarImage as farCalendarImage }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarImage as fasCalendarImage }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarLines as fadCalendarLines }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarLines as falCalendarLines }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarLines as farCalendarLines }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarLines as fasCalendarLines }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarLinesPen as fadCalendarLinesPen }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarLinesPen as falCalendarLinesPen }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarLinesPen as farCalendarLinesPen }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarLinesPen as fasCalendarLinesPen }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarMinus as fadCalendarMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarMinus as falCalendarMinus }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarMinus as farCalendarMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarMinus as fasCalendarMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarNote as fadCalendarNote }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarNote as falCalendarNote }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarNote as farCalendarNote }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarNote as fasCalendarNote }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarPen as fadCalendarPen }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarPen as falCalendarPen }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarPen as farCalendarPen }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarPen as fasCalendarPen }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarPlus as fadCalendarPlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarPlus as falCalendarPlus }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarPlus as farCalendarPlus }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarPlus as fasCalendarPlus }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarRange as fadCalendarRange }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarRange as falCalendarRange }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarRange as farCalendarRange }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarRange as fasCalendarRange }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendars as fadCalendars }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendars as falCalendars }   from '@fortawesome/pro-light-svg-icons';
import { faCalendars as farCalendars }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendars as fasCalendars }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarStar as fadCalendarStar }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarStar as falCalendarStar }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarStar as farCalendarStar }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarStar as fasCalendarStar }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarTime as fadCalendarTime }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarTime as falCalendarTime }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarTime as farCalendarTime }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarTime as fasCalendarTime }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarTimes as fadCalendarTimes }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarTimes as falCalendarTimes }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarTimes as farCalendarTimes }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarTimes as fasCalendarTimes }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarUpload as fadCalendarUpload }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarUpload as falCalendarUpload }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarUpload as farCalendarUpload }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarUpload as fasCalendarUpload }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarWeek as fadCalendarWeek }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarWeek as falCalendarWeek }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarWeek as farCalendarWeek }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarWeek as fasCalendarWeek }   from '@fortawesome/pro-solid-svg-icons';
import { faCalendarXmark as fadCalendarXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faCalendarXmark as falCalendarXmark }   from '@fortawesome/pro-light-svg-icons';
import { faCalendarXmark as farCalendarXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faCalendarXmark as fasCalendarXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faCamcorder as fadCamcorder }   from '@fortawesome/pro-duotone-svg-icons';
import { faCamcorder as falCamcorder }   from '@fortawesome/pro-light-svg-icons';
import { faCamcorder as farCamcorder }   from '@fortawesome/pro-regular-svg-icons';
import { faCamcorder as fasCamcorder }   from '@fortawesome/pro-solid-svg-icons';
import { faCamera as fadCamera }   from '@fortawesome/pro-duotone-svg-icons';
import { faCamera as falCamera }   from '@fortawesome/pro-light-svg-icons';
import { faCamera as farCamera }   from '@fortawesome/pro-regular-svg-icons';
import { faCamera as fasCamera }   from '@fortawesome/pro-solid-svg-icons';
import { faCameraAlt as fadCameraAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faCameraAlt as falCameraAlt }   from '@fortawesome/pro-light-svg-icons';
import { faCameraAlt as farCameraAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faCameraAlt as fasCameraAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faCameraCctv as fadCameraCctv }   from '@fortawesome/pro-duotone-svg-icons';
import { faCameraCctv as falCameraCctv }   from '@fortawesome/pro-light-svg-icons';
import { faCameraCctv as farCameraCctv }   from '@fortawesome/pro-regular-svg-icons';
import { faCameraCctv as fasCameraCctv }   from '@fortawesome/pro-solid-svg-icons';
import { faCameraCircle as fadCameraCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faCameraCircle as falCameraCircle }   from '@fortawesome/pro-light-svg-icons';
import { faCameraCircle as farCameraCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faCameraCircle as fasCameraCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faCameraHome as fadCameraHome }   from '@fortawesome/pro-duotone-svg-icons';
import { faCameraHome as falCameraHome }   from '@fortawesome/pro-light-svg-icons';
import { faCameraHome as farCameraHome }   from '@fortawesome/pro-regular-svg-icons';
import { faCameraHome as fasCameraHome }   from '@fortawesome/pro-solid-svg-icons';
import { faCameraMovie as fadCameraMovie }   from '@fortawesome/pro-duotone-svg-icons';
import { faCameraMovie as falCameraMovie }   from '@fortawesome/pro-light-svg-icons';
import { faCameraMovie as farCameraMovie }   from '@fortawesome/pro-regular-svg-icons';
import { faCameraMovie as fasCameraMovie }   from '@fortawesome/pro-solid-svg-icons';
import { faCameraPolaroid as fadCameraPolaroid }   from '@fortawesome/pro-duotone-svg-icons';
import { faCameraPolaroid as falCameraPolaroid }   from '@fortawesome/pro-light-svg-icons';
import { faCameraPolaroid as farCameraPolaroid }   from '@fortawesome/pro-regular-svg-icons';
import { faCameraPolaroid as fasCameraPolaroid }   from '@fortawesome/pro-solid-svg-icons';
import { faCameraRetro as fadCameraRetro }   from '@fortawesome/pro-duotone-svg-icons';
import { faCameraRetro as falCameraRetro }   from '@fortawesome/pro-light-svg-icons';
import { faCameraRetro as farCameraRetro }   from '@fortawesome/pro-regular-svg-icons';
import { faCameraRetro as fasCameraRetro }   from '@fortawesome/pro-solid-svg-icons';
import { faCameraRotate as fadCameraRotate }   from '@fortawesome/pro-duotone-svg-icons';
import { faCameraRotate as falCameraRotate }   from '@fortawesome/pro-light-svg-icons';
import { faCameraRotate as farCameraRotate }   from '@fortawesome/pro-regular-svg-icons';
import { faCameraRotate as fasCameraRotate }   from '@fortawesome/pro-solid-svg-icons';
import { faCameraSecurity as fadCameraSecurity }   from '@fortawesome/pro-duotone-svg-icons';
import { faCameraSecurity as falCameraSecurity }   from '@fortawesome/pro-light-svg-icons';
import { faCameraSecurity as farCameraSecurity }   from '@fortawesome/pro-regular-svg-icons';
import { faCameraSecurity as fasCameraSecurity }   from '@fortawesome/pro-solid-svg-icons';
import { faCameraSlash as fadCameraSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faCameraSlash as falCameraSlash }   from '@fortawesome/pro-light-svg-icons';
import { faCameraSlash as farCameraSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faCameraSlash as fasCameraSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faCameraViewfinder as fadCameraViewfinder }   from '@fortawesome/pro-duotone-svg-icons';
import { faCameraViewfinder as falCameraViewfinder }   from '@fortawesome/pro-light-svg-icons';
import { faCameraViewfinder as farCameraViewfinder }   from '@fortawesome/pro-regular-svg-icons';
import { faCameraViewfinder as fasCameraViewfinder }   from '@fortawesome/pro-solid-svg-icons';
import { faCameraWeb as fadCameraWeb }   from '@fortawesome/pro-duotone-svg-icons';
import { faCameraWeb as falCameraWeb }   from '@fortawesome/pro-light-svg-icons';
import { faCameraWeb as farCameraWeb }   from '@fortawesome/pro-regular-svg-icons';
import { faCameraWeb as fasCameraWeb }   from '@fortawesome/pro-solid-svg-icons';
import { faCameraWebSlash as fadCameraWebSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faCameraWebSlash as falCameraWebSlash }   from '@fortawesome/pro-light-svg-icons';
import { faCameraWebSlash as farCameraWebSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faCameraWebSlash as fasCameraWebSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faCampfire as fadCampfire }   from '@fortawesome/pro-duotone-svg-icons';
import { faCampfire as falCampfire }   from '@fortawesome/pro-light-svg-icons';
import { faCampfire as farCampfire }   from '@fortawesome/pro-regular-svg-icons';
import { faCampfire as fasCampfire }   from '@fortawesome/pro-solid-svg-icons';
import { faCampground as fadCampground }   from '@fortawesome/pro-duotone-svg-icons';
import { faCampground as falCampground }   from '@fortawesome/pro-light-svg-icons';
import { faCampground as farCampground }   from '@fortawesome/pro-regular-svg-icons';
import { faCampground as fasCampground }   from '@fortawesome/pro-solid-svg-icons';
import { faCancel as fadCancel }   from '@fortawesome/pro-duotone-svg-icons';
import { faCancel as falCancel }   from '@fortawesome/pro-light-svg-icons';
import { faCancel as farCancel }   from '@fortawesome/pro-regular-svg-icons';
import { faCancel as fasCancel }   from '@fortawesome/pro-solid-svg-icons';
import { faCandleHolder as fadCandleHolder }   from '@fortawesome/pro-duotone-svg-icons';
import { faCandleHolder as falCandleHolder }   from '@fortawesome/pro-light-svg-icons';
import { faCandleHolder as farCandleHolder }   from '@fortawesome/pro-regular-svg-icons';
import { faCandleHolder as fasCandleHolder }   from '@fortawesome/pro-solid-svg-icons';
import { faCandy as fadCandy }   from '@fortawesome/pro-duotone-svg-icons';
import { faCandy as falCandy }   from '@fortawesome/pro-light-svg-icons';
import { faCandy as farCandy }   from '@fortawesome/pro-regular-svg-icons';
import { faCandy as fasCandy }   from '@fortawesome/pro-solid-svg-icons';
import { faCandyBar as fadCandyBar }   from '@fortawesome/pro-duotone-svg-icons';
import { faCandyBar as falCandyBar }   from '@fortawesome/pro-light-svg-icons';
import { faCandyBar as farCandyBar }   from '@fortawesome/pro-regular-svg-icons';
import { faCandyBar as fasCandyBar }   from '@fortawesome/pro-solid-svg-icons';
import { faCandyCane as fadCandyCane }   from '@fortawesome/pro-duotone-svg-icons';
import { faCandyCane as falCandyCane }   from '@fortawesome/pro-light-svg-icons';
import { faCandyCane as farCandyCane }   from '@fortawesome/pro-regular-svg-icons';
import { faCandyCane as fasCandyCane }   from '@fortawesome/pro-solid-svg-icons';
import { faCandyCorn as fadCandyCorn }   from '@fortawesome/pro-duotone-svg-icons';
import { faCandyCorn as falCandyCorn }   from '@fortawesome/pro-light-svg-icons';
import { faCandyCorn as farCandyCorn }   from '@fortawesome/pro-regular-svg-icons';
import { faCandyCorn as fasCandyCorn }   from '@fortawesome/pro-solid-svg-icons';
import { faCanFood as fadCanFood }   from '@fortawesome/pro-duotone-svg-icons';
import { faCanFood as falCanFood }   from '@fortawesome/pro-light-svg-icons';
import { faCanFood as farCanFood }   from '@fortawesome/pro-regular-svg-icons';
import { faCanFood as fasCanFood }   from '@fortawesome/pro-solid-svg-icons';
import { faCannabis as fadCannabis }   from '@fortawesome/pro-duotone-svg-icons';
import { faCannabis as falCannabis }   from '@fortawesome/pro-light-svg-icons';
import { faCannabis as farCannabis }   from '@fortawesome/pro-regular-svg-icons';
import { faCannabis as fasCannabis }   from '@fortawesome/pro-solid-svg-icons';
import { faCapsules as fadCapsules }   from '@fortawesome/pro-duotone-svg-icons';
import { faCapsules as falCapsules }   from '@fortawesome/pro-light-svg-icons';
import { faCapsules as farCapsules }   from '@fortawesome/pro-regular-svg-icons';
import { faCapsules as fasCapsules }   from '@fortawesome/pro-solid-svg-icons';
import { faCar as fadCar }   from '@fortawesome/pro-duotone-svg-icons';
import { faCar as falCar }   from '@fortawesome/pro-light-svg-icons';
import { faCar as farCar }   from '@fortawesome/pro-regular-svg-icons';
import { faCar as fasCar }   from '@fortawesome/pro-solid-svg-icons';
import { faCarAlt as fadCarAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faCarAlt as falCarAlt }   from '@fortawesome/pro-light-svg-icons';
import { faCarAlt as farCarAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faCarAlt as fasCarAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faCaravan as fadCaravan }   from '@fortawesome/pro-duotone-svg-icons';
import { faCaravan as falCaravan }   from '@fortawesome/pro-light-svg-icons';
import { faCaravan as farCaravan }   from '@fortawesome/pro-regular-svg-icons';
import { faCaravan as fasCaravan }   from '@fortawesome/pro-solid-svg-icons';
import { faCaravanAlt as fadCaravanAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faCaravanAlt as falCaravanAlt }   from '@fortawesome/pro-light-svg-icons';
import { faCaravanAlt as farCaravanAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faCaravanAlt as fasCaravanAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faCaravanSimple as fadCaravanSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faCaravanSimple as falCaravanSimple }   from '@fortawesome/pro-light-svg-icons';
import { faCaravanSimple as farCaravanSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faCaravanSimple as fasCaravanSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faCarBattery as fadCarBattery }   from '@fortawesome/pro-duotone-svg-icons';
import { faCarBattery as falCarBattery }   from '@fortawesome/pro-light-svg-icons';
import { faCarBattery as farCarBattery }   from '@fortawesome/pro-regular-svg-icons';
import { faCarBattery as fasCarBattery }   from '@fortawesome/pro-solid-svg-icons';
import { faCarBolt as fadCarBolt }   from '@fortawesome/pro-duotone-svg-icons';
import { faCarBolt as falCarBolt }   from '@fortawesome/pro-light-svg-icons';
import { faCarBolt as farCarBolt }   from '@fortawesome/pro-regular-svg-icons';
import { faCarBolt as fasCarBolt }   from '@fortawesome/pro-solid-svg-icons';
import { faCarBuilding as fadCarBuilding }   from '@fortawesome/pro-duotone-svg-icons';
import { faCarBuilding as falCarBuilding }   from '@fortawesome/pro-light-svg-icons';
import { faCarBuilding as farCarBuilding }   from '@fortawesome/pro-regular-svg-icons';
import { faCarBuilding as fasCarBuilding }   from '@fortawesome/pro-solid-svg-icons';
import { faCarBump as fadCarBump }   from '@fortawesome/pro-duotone-svg-icons';
import { faCarBump as falCarBump }   from '@fortawesome/pro-light-svg-icons';
import { faCarBump as farCarBump }   from '@fortawesome/pro-regular-svg-icons';
import { faCarBump as fasCarBump }   from '@fortawesome/pro-solid-svg-icons';
import { faCarBurst as fadCarBurst }   from '@fortawesome/pro-duotone-svg-icons';
import { faCarBurst as falCarBurst }   from '@fortawesome/pro-light-svg-icons';
import { faCarBurst as farCarBurst }   from '@fortawesome/pro-regular-svg-icons';
import { faCarBurst as fasCarBurst }   from '@fortawesome/pro-solid-svg-icons';
import { faCarBus as fadCarBus }   from '@fortawesome/pro-duotone-svg-icons';
import { faCarBus as falCarBus }   from '@fortawesome/pro-light-svg-icons';
import { faCarBus as farCarBus }   from '@fortawesome/pro-regular-svg-icons';
import { faCarBus as fasCarBus }   from '@fortawesome/pro-solid-svg-icons';
import { faCarCircleBolt as fadCarCircleBolt }   from '@fortawesome/pro-duotone-svg-icons';
import { faCarCircleBolt as falCarCircleBolt }   from '@fortawesome/pro-light-svg-icons';
import { faCarCircleBolt as farCarCircleBolt }   from '@fortawesome/pro-regular-svg-icons';
import { faCarCircleBolt as fasCarCircleBolt }   from '@fortawesome/pro-solid-svg-icons';
import { faCarCrash as fadCarCrash }   from '@fortawesome/pro-duotone-svg-icons';
import { faCarCrash as falCarCrash }   from '@fortawesome/pro-light-svg-icons';
import { faCarCrash as farCarCrash }   from '@fortawesome/pro-regular-svg-icons';
import { faCarCrash as fasCarCrash }   from '@fortawesome/pro-solid-svg-icons';
import { faCardClub as fadCardClub }   from '@fortawesome/pro-duotone-svg-icons';
import { faCardClub as falCardClub }   from '@fortawesome/pro-light-svg-icons';
import { faCardClub as farCardClub }   from '@fortawesome/pro-regular-svg-icons';
import { faCardClub as fasCardClub }   from '@fortawesome/pro-solid-svg-icons';
import { faCardDiamond as fadCardDiamond }   from '@fortawesome/pro-duotone-svg-icons';
import { faCardDiamond as falCardDiamond }   from '@fortawesome/pro-light-svg-icons';
import { faCardDiamond as farCardDiamond }   from '@fortawesome/pro-regular-svg-icons';
import { faCardDiamond as fasCardDiamond }   from '@fortawesome/pro-solid-svg-icons';
import { faCardHeart as fadCardHeart }   from '@fortawesome/pro-duotone-svg-icons';
import { faCardHeart as falCardHeart }   from '@fortawesome/pro-light-svg-icons';
import { faCardHeart as farCardHeart }   from '@fortawesome/pro-regular-svg-icons';
import { faCardHeart as fasCardHeart }   from '@fortawesome/pro-solid-svg-icons';
import { faCards as fadCards }   from '@fortawesome/pro-duotone-svg-icons';
import { faCards as falCards }   from '@fortawesome/pro-light-svg-icons';
import { faCards as farCards }   from '@fortawesome/pro-regular-svg-icons';
import { faCards as fasCards }   from '@fortawesome/pro-solid-svg-icons';
import { faCardsBlank as fadCardsBlank }   from '@fortawesome/pro-duotone-svg-icons';
import { faCardsBlank as falCardsBlank }   from '@fortawesome/pro-light-svg-icons';
import { faCardsBlank as farCardsBlank }   from '@fortawesome/pro-regular-svg-icons';
import { faCardsBlank as fasCardsBlank }   from '@fortawesome/pro-solid-svg-icons';
import { faCardSpade as fadCardSpade }   from '@fortawesome/pro-duotone-svg-icons';
import { faCardSpade as falCardSpade }   from '@fortawesome/pro-light-svg-icons';
import { faCardSpade as farCardSpade }   from '@fortawesome/pro-regular-svg-icons';
import { faCardSpade as fasCardSpade }   from '@fortawesome/pro-solid-svg-icons';
import { faCaretCircleDown as fadCaretCircleDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faCaretCircleDown as falCaretCircleDown }   from '@fortawesome/pro-light-svg-icons';
import { faCaretCircleDown as farCaretCircleDown }   from '@fortawesome/pro-regular-svg-icons';
import { faCaretCircleDown as fasCaretCircleDown }   from '@fortawesome/pro-solid-svg-icons';
import { faCaretCircleLeft as fadCaretCircleLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faCaretCircleLeft as falCaretCircleLeft }   from '@fortawesome/pro-light-svg-icons';
import { faCaretCircleLeft as farCaretCircleLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faCaretCircleLeft as fasCaretCircleLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faCaretCircleRight as fadCaretCircleRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faCaretCircleRight as falCaretCircleRight }   from '@fortawesome/pro-light-svg-icons';
import { faCaretCircleRight as farCaretCircleRight }   from '@fortawesome/pro-regular-svg-icons';
import { faCaretCircleRight as fasCaretCircleRight }   from '@fortawesome/pro-solid-svg-icons';
import { faCaretCircleUp as fadCaretCircleUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faCaretCircleUp as falCaretCircleUp }   from '@fortawesome/pro-light-svg-icons';
import { faCaretCircleUp as farCaretCircleUp }   from '@fortawesome/pro-regular-svg-icons';
import { faCaretCircleUp as fasCaretCircleUp }   from '@fortawesome/pro-solid-svg-icons';
import { faCaretDown as fadCaretDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faCaretDown as falCaretDown }   from '@fortawesome/pro-light-svg-icons';
import { faCaretDown as farCaretDown }   from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown as fasCaretDown }   from '@fortawesome/pro-solid-svg-icons';
import { faCaretLeft as fadCaretLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faCaretLeft as falCaretLeft }   from '@fortawesome/pro-light-svg-icons';
import { faCaretLeft as farCaretLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faCaretLeft as fasCaretLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faCaretRight as fadCaretRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faCaretRight as falCaretRight }   from '@fortawesome/pro-light-svg-icons';
import { faCaretRight as farCaretRight }   from '@fortawesome/pro-regular-svg-icons';
import { faCaretRight as fasCaretRight }   from '@fortawesome/pro-solid-svg-icons';
import { faCaretSquareDown as fadCaretSquareDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faCaretSquareDown as falCaretSquareDown }   from '@fortawesome/pro-light-svg-icons';
import { faCaretSquareDown as farCaretSquareDown }   from '@fortawesome/pro-regular-svg-icons';
import { faCaretSquareDown as fasCaretSquareDown }   from '@fortawesome/pro-solid-svg-icons';
import { faCaretSquareLeft as fadCaretSquareLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faCaretSquareLeft as falCaretSquareLeft }   from '@fortawesome/pro-light-svg-icons';
import { faCaretSquareLeft as farCaretSquareLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faCaretSquareLeft as fasCaretSquareLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faCaretSquareRight as fadCaretSquareRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faCaretSquareRight as falCaretSquareRight }   from '@fortawesome/pro-light-svg-icons';
import { faCaretSquareRight as farCaretSquareRight }   from '@fortawesome/pro-regular-svg-icons';
import { faCaretSquareRight as fasCaretSquareRight }   from '@fortawesome/pro-solid-svg-icons';
import { faCaretSquareUp as fadCaretSquareUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faCaretSquareUp as falCaretSquareUp }   from '@fortawesome/pro-light-svg-icons';
import { faCaretSquareUp as farCaretSquareUp }   from '@fortawesome/pro-regular-svg-icons';
import { faCaretSquareUp as fasCaretSquareUp }   from '@fortawesome/pro-solid-svg-icons';
import { faCaretUp as fadCaretUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faCaretUp as falCaretUp }   from '@fortawesome/pro-light-svg-icons';
import { faCaretUp as farCaretUp }   from '@fortawesome/pro-regular-svg-icons';
import { faCaretUp as fasCaretUp }   from '@fortawesome/pro-solid-svg-icons';
import { faCarGarage as fadCarGarage }   from '@fortawesome/pro-duotone-svg-icons';
import { faCarGarage as falCarGarage }   from '@fortawesome/pro-light-svg-icons';
import { faCarGarage as farCarGarage }   from '@fortawesome/pro-regular-svg-icons';
import { faCarGarage as fasCarGarage }   from '@fortawesome/pro-solid-svg-icons';
import { faCarMechanic as fadCarMechanic }   from '@fortawesome/pro-duotone-svg-icons';
import { faCarMechanic as falCarMechanic }   from '@fortawesome/pro-light-svg-icons';
import { faCarMechanic as farCarMechanic }   from '@fortawesome/pro-regular-svg-icons';
import { faCarMechanic as fasCarMechanic }   from '@fortawesome/pro-solid-svg-icons';
import { faCarMirrors as fadCarMirrors }   from '@fortawesome/pro-duotone-svg-icons';
import { faCarMirrors as falCarMirrors }   from '@fortawesome/pro-light-svg-icons';
import { faCarMirrors as farCarMirrors }   from '@fortawesome/pro-regular-svg-icons';
import { faCarMirrors as fasCarMirrors }   from '@fortawesome/pro-solid-svg-icons';
import { faCarOn as fadCarOn }   from '@fortawesome/pro-duotone-svg-icons';
import { faCarOn as falCarOn }   from '@fortawesome/pro-light-svg-icons';
import { faCarOn as farCarOn }   from '@fortawesome/pro-regular-svg-icons';
import { faCarOn as fasCarOn }   from '@fortawesome/pro-solid-svg-icons';
import { faCarRear as fadCarRear }   from '@fortawesome/pro-duotone-svg-icons';
import { faCarRear as falCarRear }   from '@fortawesome/pro-light-svg-icons';
import { faCarRear as farCarRear }   from '@fortawesome/pro-regular-svg-icons';
import { faCarRear as fasCarRear }   from '@fortawesome/pro-solid-svg-icons';
import { faCarriageBaby as fadCarriageBaby }   from '@fortawesome/pro-duotone-svg-icons';
import { faCarriageBaby as falCarriageBaby }   from '@fortawesome/pro-light-svg-icons';
import { faCarriageBaby as farCarriageBaby }   from '@fortawesome/pro-regular-svg-icons';
import { faCarriageBaby as fasCarriageBaby }   from '@fortawesome/pro-solid-svg-icons';
import { faCarrot as fadCarrot }   from '@fortawesome/pro-duotone-svg-icons';
import { faCarrot as falCarrot }   from '@fortawesome/pro-light-svg-icons';
import { faCarrot as farCarrot }   from '@fortawesome/pro-regular-svg-icons';
import { faCarrot as fasCarrot }   from '@fortawesome/pro-solid-svg-icons';
import { faCars as fadCars }   from '@fortawesome/pro-duotone-svg-icons';
import { faCars as falCars }   from '@fortawesome/pro-light-svg-icons';
import { faCars as farCars }   from '@fortawesome/pro-regular-svg-icons';
import { faCars as fasCars }   from '@fortawesome/pro-solid-svg-icons';
import { faCarSide as fadCarSide }   from '@fortawesome/pro-duotone-svg-icons';
import { faCarSide as falCarSide }   from '@fortawesome/pro-light-svg-icons';
import { faCarSide as farCarSide }   from '@fortawesome/pro-regular-svg-icons';
import { faCarSide as fasCarSide }   from '@fortawesome/pro-solid-svg-icons';
import { faCarSideBolt as fadCarSideBolt }   from '@fortawesome/pro-duotone-svg-icons';
import { faCarSideBolt as falCarSideBolt }   from '@fortawesome/pro-light-svg-icons';
import { faCarSideBolt as farCarSideBolt }   from '@fortawesome/pro-regular-svg-icons';
import { faCarSideBolt as fasCarSideBolt }   from '@fortawesome/pro-solid-svg-icons';
import { faCartArrowDown as fadCartArrowDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faCartArrowDown as falCartArrowDown }   from '@fortawesome/pro-light-svg-icons';
import { faCartArrowDown as farCartArrowDown }   from '@fortawesome/pro-regular-svg-icons';
import { faCartArrowDown as fasCartArrowDown }   from '@fortawesome/pro-solid-svg-icons';
import { faCartArrowUp as fadCartArrowUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faCartArrowUp as falCartArrowUp }   from '@fortawesome/pro-light-svg-icons';
import { faCartArrowUp as farCartArrowUp }   from '@fortawesome/pro-regular-svg-icons';
import { faCartArrowUp as fasCartArrowUp }   from '@fortawesome/pro-solid-svg-icons';
import { faCartCircleArrowDown as fadCartCircleArrowDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faCartCircleArrowDown as falCartCircleArrowDown }   from '@fortawesome/pro-light-svg-icons';
import { faCartCircleArrowDown as farCartCircleArrowDown }   from '@fortawesome/pro-regular-svg-icons';
import { faCartCircleArrowDown as fasCartCircleArrowDown }   from '@fortawesome/pro-solid-svg-icons';
import { faCartCircleArrowUp as fadCartCircleArrowUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faCartCircleArrowUp as falCartCircleArrowUp }   from '@fortawesome/pro-light-svg-icons';
import { faCartCircleArrowUp as farCartCircleArrowUp }   from '@fortawesome/pro-regular-svg-icons';
import { faCartCircleArrowUp as fasCartCircleArrowUp }   from '@fortawesome/pro-solid-svg-icons';
import { faCartCircleCheck as fadCartCircleCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faCartCircleCheck as falCartCircleCheck }   from '@fortawesome/pro-light-svg-icons';
import { faCartCircleCheck as farCartCircleCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faCartCircleCheck as fasCartCircleCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faCartCircleExclamation as fadCartCircleExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faCartCircleExclamation as falCartCircleExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faCartCircleExclamation as farCartCircleExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faCartCircleExclamation as fasCartCircleExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faCartCirclePlus as fadCartCirclePlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faCartCirclePlus as falCartCirclePlus }   from '@fortawesome/pro-light-svg-icons';
import { faCartCirclePlus as farCartCirclePlus }   from '@fortawesome/pro-regular-svg-icons';
import { faCartCirclePlus as fasCartCirclePlus }   from '@fortawesome/pro-solid-svg-icons';
import { faCartCircleXmark as fadCartCircleXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faCartCircleXmark as falCartCircleXmark }   from '@fortawesome/pro-light-svg-icons';
import { faCartCircleXmark as farCartCircleXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faCartCircleXmark as fasCartCircleXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faCartFlatbed as fadCartFlatbed }   from '@fortawesome/pro-duotone-svg-icons';
import { faCartFlatbed as falCartFlatbed }   from '@fortawesome/pro-light-svg-icons';
import { faCartFlatbed as farCartFlatbed }   from '@fortawesome/pro-regular-svg-icons';
import { faCartFlatbed as fasCartFlatbed }   from '@fortawesome/pro-solid-svg-icons';
import { faCartFlatbedBoxes as fadCartFlatbedBoxes }   from '@fortawesome/pro-duotone-svg-icons';
import { faCartFlatbedBoxes as falCartFlatbedBoxes }   from '@fortawesome/pro-light-svg-icons';
import { faCartFlatbedBoxes as farCartFlatbedBoxes }   from '@fortawesome/pro-regular-svg-icons';
import { faCartFlatbedBoxes as fasCartFlatbedBoxes }   from '@fortawesome/pro-solid-svg-icons';
import { faCartFlatbedEmpty as fadCartFlatbedEmpty }   from '@fortawesome/pro-duotone-svg-icons';
import { faCartFlatbedEmpty as falCartFlatbedEmpty }   from '@fortawesome/pro-light-svg-icons';
import { faCartFlatbedEmpty as farCartFlatbedEmpty }   from '@fortawesome/pro-regular-svg-icons';
import { faCartFlatbedEmpty as fasCartFlatbedEmpty }   from '@fortawesome/pro-solid-svg-icons';
import { faCartFlatbedSuitcase as fadCartFlatbedSuitcase }   from '@fortawesome/pro-duotone-svg-icons';
import { faCartFlatbedSuitcase as falCartFlatbedSuitcase }   from '@fortawesome/pro-light-svg-icons';
import { faCartFlatbedSuitcase as farCartFlatbedSuitcase }   from '@fortawesome/pro-regular-svg-icons';
import { faCartFlatbedSuitcase as fasCartFlatbedSuitcase }   from '@fortawesome/pro-solid-svg-icons';
import { faCarTilt as fadCarTilt }   from '@fortawesome/pro-duotone-svg-icons';
import { faCarTilt as falCarTilt }   from '@fortawesome/pro-light-svg-icons';
import { faCarTilt as farCarTilt }   from '@fortawesome/pro-regular-svg-icons';
import { faCarTilt as fasCarTilt }   from '@fortawesome/pro-solid-svg-icons';
import { faCartMinus as fadCartMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faCartMinus as falCartMinus }   from '@fortawesome/pro-light-svg-icons';
import { faCartMinus as farCartMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faCartMinus as fasCartMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faCartPlus as fadCartPlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faCartPlus as falCartPlus }   from '@fortawesome/pro-light-svg-icons';
import { faCartPlus as farCartPlus }   from '@fortawesome/pro-regular-svg-icons';
import { faCartPlus as fasCartPlus }   from '@fortawesome/pro-solid-svg-icons';
import { faCartShopping as fadCartShopping }   from '@fortawesome/pro-duotone-svg-icons';
import { faCartShopping as falCartShopping }   from '@fortawesome/pro-light-svg-icons';
import { faCartShopping as farCartShopping }   from '@fortawesome/pro-regular-svg-icons';
import { faCartShopping as fasCartShopping }   from '@fortawesome/pro-solid-svg-icons';
import { faCartShoppingFast as fadCartShoppingFast }   from '@fortawesome/pro-duotone-svg-icons';
import { faCartShoppingFast as falCartShoppingFast }   from '@fortawesome/pro-light-svg-icons';
import { faCartShoppingFast as farCartShoppingFast }   from '@fortawesome/pro-regular-svg-icons';
import { faCartShoppingFast as fasCartShoppingFast }   from '@fortawesome/pro-solid-svg-icons';
import { faCarTunnel as fadCarTunnel }   from '@fortawesome/pro-duotone-svg-icons';
import { faCarTunnel as falCarTunnel }   from '@fortawesome/pro-light-svg-icons';
import { faCarTunnel as farCarTunnel }   from '@fortawesome/pro-regular-svg-icons';
import { faCarTunnel as fasCarTunnel }   from '@fortawesome/pro-solid-svg-icons';
import { faCartXmark as fadCartXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faCartXmark as falCartXmark }   from '@fortawesome/pro-light-svg-icons';
import { faCartXmark as farCartXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faCartXmark as fasCartXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faCarWash as fadCarWash }   from '@fortawesome/pro-duotone-svg-icons';
import { faCarWash as falCarWash }   from '@fortawesome/pro-light-svg-icons';
import { faCarWash as farCarWash }   from '@fortawesome/pro-regular-svg-icons';
import { faCarWash as fasCarWash }   from '@fortawesome/pro-solid-svg-icons';
import { faCarWrench as fadCarWrench }   from '@fortawesome/pro-duotone-svg-icons';
import { faCarWrench as falCarWrench }   from '@fortawesome/pro-light-svg-icons';
import { faCarWrench as farCarWrench }   from '@fortawesome/pro-regular-svg-icons';
import { faCarWrench as fasCarWrench }   from '@fortawesome/pro-solid-svg-icons';
import { faCashRegister as fadCashRegister }   from '@fortawesome/pro-duotone-svg-icons';
import { faCashRegister as falCashRegister }   from '@fortawesome/pro-light-svg-icons';
import { faCashRegister as farCashRegister }   from '@fortawesome/pro-regular-svg-icons';
import { faCashRegister as fasCashRegister }   from '@fortawesome/pro-solid-svg-icons';
import { faCassetteBetamax as fadCassetteBetamax }   from '@fortawesome/pro-duotone-svg-icons';
import { faCassetteBetamax as falCassetteBetamax }   from '@fortawesome/pro-light-svg-icons';
import { faCassetteBetamax as farCassetteBetamax }   from '@fortawesome/pro-regular-svg-icons';
import { faCassetteBetamax as fasCassetteBetamax }   from '@fortawesome/pro-solid-svg-icons';
import { faCassetteTape as fadCassetteTape }   from '@fortawesome/pro-duotone-svg-icons';
import { faCassetteTape as falCassetteTape }   from '@fortawesome/pro-light-svg-icons';
import { faCassetteTape as farCassetteTape }   from '@fortawesome/pro-regular-svg-icons';
import { faCassetteTape as fasCassetteTape }   from '@fortawesome/pro-solid-svg-icons';
import { faCassetteVhs as fadCassetteVhs }   from '@fortawesome/pro-duotone-svg-icons';
import { faCassetteVhs as falCassetteVhs }   from '@fortawesome/pro-light-svg-icons';
import { faCassetteVhs as farCassetteVhs }   from '@fortawesome/pro-regular-svg-icons';
import { faCassetteVhs as fasCassetteVhs }   from '@fortawesome/pro-solid-svg-icons';
import { faCastle as fadCastle }   from '@fortawesome/pro-duotone-svg-icons';
import { faCastle as falCastle }   from '@fortawesome/pro-light-svg-icons';
import { faCastle as farCastle }   from '@fortawesome/pro-regular-svg-icons';
import { faCastle as fasCastle }   from '@fortawesome/pro-solid-svg-icons';
import { faCat as fadCat }   from '@fortawesome/pro-duotone-svg-icons';
import { faCat as falCat }   from '@fortawesome/pro-light-svg-icons';
import { faCat as farCat }   from '@fortawesome/pro-regular-svg-icons';
import { faCat as fasCat }   from '@fortawesome/pro-solid-svg-icons';
import { faCatSpace as fadCatSpace }   from '@fortawesome/pro-duotone-svg-icons';
import { faCatSpace as falCatSpace }   from '@fortawesome/pro-light-svg-icons';
import { faCatSpace as farCatSpace }   from '@fortawesome/pro-regular-svg-icons';
import { faCatSpace as fasCatSpace }   from '@fortawesome/pro-solid-svg-icons';
import { faCauldron as fadCauldron }   from '@fortawesome/pro-duotone-svg-icons';
import { faCauldron as falCauldron }   from '@fortawesome/pro-light-svg-icons';
import { faCauldron as farCauldron }   from '@fortawesome/pro-regular-svg-icons';
import { faCauldron as fasCauldron }   from '@fortawesome/pro-solid-svg-icons';
import { faCctv as fadCctv }   from '@fortawesome/pro-duotone-svg-icons';
import { faCctv as falCctv }   from '@fortawesome/pro-light-svg-icons';
import { faCctv as farCctv }   from '@fortawesome/pro-regular-svg-icons';
import { faCctv as fasCctv }   from '@fortawesome/pro-solid-svg-icons';
import { faCediSign as fadCediSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faCediSign as falCediSign }   from '@fortawesome/pro-light-svg-icons';
import { faCediSign as farCediSign }   from '@fortawesome/pro-regular-svg-icons';
import { faCediSign as fasCediSign }   from '@fortawesome/pro-solid-svg-icons';
import { faCentSign as fadCentSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faCentSign as falCentSign }   from '@fortawesome/pro-light-svg-icons';
import { faCentSign as farCentSign }   from '@fortawesome/pro-regular-svg-icons';
import { faCentSign as fasCentSign }   from '@fortawesome/pro-solid-svg-icons';
import { faCertificate as fadCertificate }   from '@fortawesome/pro-duotone-svg-icons';
import { faCertificate as falCertificate }   from '@fortawesome/pro-light-svg-icons';
import { faCertificate as farCertificate }   from '@fortawesome/pro-regular-svg-icons';
import { faCertificate as fasCertificate }   from '@fortawesome/pro-solid-svg-icons';
import { faChain as fadChain }   from '@fortawesome/pro-duotone-svg-icons';
import { faChain as falChain }   from '@fortawesome/pro-light-svg-icons';
import { faChain as farChain }   from '@fortawesome/pro-regular-svg-icons';
import { faChain as fasChain }   from '@fortawesome/pro-solid-svg-icons';
import { faChainBroken as fadChainBroken }   from '@fortawesome/pro-duotone-svg-icons';
import { faChainBroken as falChainBroken }   from '@fortawesome/pro-light-svg-icons';
import { faChainBroken as farChainBroken }   from '@fortawesome/pro-regular-svg-icons';
import { faChainBroken as fasChainBroken }   from '@fortawesome/pro-solid-svg-icons';
import { faChainHorizontal as fadChainHorizontal }   from '@fortawesome/pro-duotone-svg-icons';
import { faChainHorizontal as falChainHorizontal }   from '@fortawesome/pro-light-svg-icons';
import { faChainHorizontal as farChainHorizontal }   from '@fortawesome/pro-regular-svg-icons';
import { faChainHorizontal as fasChainHorizontal }   from '@fortawesome/pro-solid-svg-icons';
import { faChainHorizontalSlash as fadChainHorizontalSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faChainHorizontalSlash as falChainHorizontalSlash }   from '@fortawesome/pro-light-svg-icons';
import { faChainHorizontalSlash as farChainHorizontalSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faChainHorizontalSlash as fasChainHorizontalSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faChainSlash as fadChainSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faChainSlash as falChainSlash }   from '@fortawesome/pro-light-svg-icons';
import { faChainSlash as farChainSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faChainSlash as fasChainSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faChair as fadChair }   from '@fortawesome/pro-duotone-svg-icons';
import { faChair as falChair }   from '@fortawesome/pro-light-svg-icons';
import { faChair as farChair }   from '@fortawesome/pro-regular-svg-icons';
import { faChair as fasChair }   from '@fortawesome/pro-solid-svg-icons';
import { faChairOffice as fadChairOffice }   from '@fortawesome/pro-duotone-svg-icons';
import { faChairOffice as falChairOffice }   from '@fortawesome/pro-light-svg-icons';
import { faChairOffice as farChairOffice }   from '@fortawesome/pro-regular-svg-icons';
import { faChairOffice as fasChairOffice }   from '@fortawesome/pro-solid-svg-icons';
import { faChalkboard as fadChalkboard }   from '@fortawesome/pro-duotone-svg-icons';
import { faChalkboard as falChalkboard }   from '@fortawesome/pro-light-svg-icons';
import { faChalkboard as farChalkboard }   from '@fortawesome/pro-regular-svg-icons';
import { faChalkboard as fasChalkboard }   from '@fortawesome/pro-solid-svg-icons';
import { faChalkboardTeacher as fadChalkboardTeacher }   from '@fortawesome/pro-duotone-svg-icons';
import { faChalkboardTeacher as falChalkboardTeacher }   from '@fortawesome/pro-light-svg-icons';
import { faChalkboardTeacher as farChalkboardTeacher }   from '@fortawesome/pro-regular-svg-icons';
import { faChalkboardTeacher as fasChalkboardTeacher }   from '@fortawesome/pro-solid-svg-icons';
import { faChalkboardUser as fadChalkboardUser }   from '@fortawesome/pro-duotone-svg-icons';
import { faChalkboardUser as falChalkboardUser }   from '@fortawesome/pro-light-svg-icons';
import { faChalkboardUser as farChalkboardUser }   from '@fortawesome/pro-regular-svg-icons';
import { faChalkboardUser as fasChalkboardUser }   from '@fortawesome/pro-solid-svg-icons';
import { faChampagneGlass as fadChampagneGlass }   from '@fortawesome/pro-duotone-svg-icons';
import { faChampagneGlass as falChampagneGlass }   from '@fortawesome/pro-light-svg-icons';
import { faChampagneGlass as farChampagneGlass }   from '@fortawesome/pro-regular-svg-icons';
import { faChampagneGlass as fasChampagneGlass }   from '@fortawesome/pro-solid-svg-icons';
import { faChampagneGlasses as fadChampagneGlasses }   from '@fortawesome/pro-duotone-svg-icons';
import { faChampagneGlasses as falChampagneGlasses }   from '@fortawesome/pro-light-svg-icons';
import { faChampagneGlasses as farChampagneGlasses }   from '@fortawesome/pro-regular-svg-icons';
import { faChampagneGlasses as fasChampagneGlasses }   from '@fortawesome/pro-solid-svg-icons';
import { faChargingStation as fadChargingStation }   from '@fortawesome/pro-duotone-svg-icons';
import { faChargingStation as falChargingStation }   from '@fortawesome/pro-light-svg-icons';
import { faChargingStation as farChargingStation }   from '@fortawesome/pro-regular-svg-icons';
import { faChargingStation as fasChargingStation }   from '@fortawesome/pro-solid-svg-icons';
import { faChartArea as fadChartArea }   from '@fortawesome/pro-duotone-svg-icons';
import { faChartArea as falChartArea }   from '@fortawesome/pro-light-svg-icons';
import { faChartArea as farChartArea }   from '@fortawesome/pro-regular-svg-icons';
import { faChartArea as fasChartArea }   from '@fortawesome/pro-solid-svg-icons';
import { faChartBar as fadChartBar }   from '@fortawesome/pro-duotone-svg-icons';
import { faChartBar as falChartBar }   from '@fortawesome/pro-light-svg-icons';
import { faChartBar as farChartBar }   from '@fortawesome/pro-regular-svg-icons';
import { faChartBar as fasChartBar }   from '@fortawesome/pro-solid-svg-icons';
import { faChartBullet as fadChartBullet }   from '@fortawesome/pro-duotone-svg-icons';
import { faChartBullet as falChartBullet }   from '@fortawesome/pro-light-svg-icons';
import { faChartBullet as farChartBullet }   from '@fortawesome/pro-regular-svg-icons';
import { faChartBullet as fasChartBullet }   from '@fortawesome/pro-solid-svg-icons';
import { faChartCandlestick as fadChartCandlestick }   from '@fortawesome/pro-duotone-svg-icons';
import { faChartCandlestick as falChartCandlestick }   from '@fortawesome/pro-light-svg-icons';
import { faChartCandlestick as farChartCandlestick }   from '@fortawesome/pro-regular-svg-icons';
import { faChartCandlestick as fasChartCandlestick }   from '@fortawesome/pro-solid-svg-icons';
import { faChartColumn as fadChartColumn }   from '@fortawesome/pro-duotone-svg-icons';
import { faChartColumn as falChartColumn }   from '@fortawesome/pro-light-svg-icons';
import { faChartColumn as farChartColumn }   from '@fortawesome/pro-regular-svg-icons';
import { faChartColumn as fasChartColumn }   from '@fortawesome/pro-solid-svg-icons';
import { faChartGantt as fadChartGantt }   from '@fortawesome/pro-duotone-svg-icons';
import { faChartGantt as falChartGantt }   from '@fortawesome/pro-light-svg-icons';
import { faChartGantt as farChartGantt }   from '@fortawesome/pro-regular-svg-icons';
import { faChartGantt as fasChartGantt }   from '@fortawesome/pro-solid-svg-icons';
import { faChartLine as fadChartLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faChartLine as falChartLine }   from '@fortawesome/pro-light-svg-icons';
import { faChartLine as farChartLine }   from '@fortawesome/pro-regular-svg-icons';
import { faChartLine as fasChartLine }   from '@fortawesome/pro-solid-svg-icons';
import { faChartLineDown as fadChartLineDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faChartLineDown as falChartLineDown }   from '@fortawesome/pro-light-svg-icons';
import { faChartLineDown as farChartLineDown }   from '@fortawesome/pro-regular-svg-icons';
import { faChartLineDown as fasChartLineDown }   from '@fortawesome/pro-solid-svg-icons';
import { faChartLineUp as fadChartLineUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faChartLineUp as falChartLineUp }   from '@fortawesome/pro-light-svg-icons';
import { faChartLineUp as farChartLineUp }   from '@fortawesome/pro-regular-svg-icons';
import { faChartLineUp as fasChartLineUp }   from '@fortawesome/pro-solid-svg-icons';
import { faChartMixed as fadChartMixed }   from '@fortawesome/pro-duotone-svg-icons';
import { faChartMixed as falChartMixed }   from '@fortawesome/pro-light-svg-icons';
import { faChartMixed as farChartMixed }   from '@fortawesome/pro-regular-svg-icons';
import { faChartMixed as fasChartMixed }   from '@fortawesome/pro-solid-svg-icons';
import { faChartNetwork as fadChartNetwork }   from '@fortawesome/pro-duotone-svg-icons';
import { faChartNetwork as falChartNetwork }   from '@fortawesome/pro-light-svg-icons';
import { faChartNetwork as farChartNetwork }   from '@fortawesome/pro-regular-svg-icons';
import { faChartNetwork as fasChartNetwork }   from '@fortawesome/pro-solid-svg-icons';
import { faChartPie as fadChartPie }   from '@fortawesome/pro-duotone-svg-icons';
import { faChartPie as falChartPie }   from '@fortawesome/pro-light-svg-icons';
import { faChartPie as farChartPie }   from '@fortawesome/pro-regular-svg-icons';
import { faChartPie as fasChartPie }   from '@fortawesome/pro-solid-svg-icons';
import { faChartPieAlt as fadChartPieAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faChartPieAlt as falChartPieAlt }   from '@fortawesome/pro-light-svg-icons';
import { faChartPieAlt as farChartPieAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faChartPieAlt as fasChartPieAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faChartPieSimple as fadChartPieSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faChartPieSimple as falChartPieSimple }   from '@fortawesome/pro-light-svg-icons';
import { faChartPieSimple as farChartPieSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faChartPieSimple as fasChartPieSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faChartPyramid as fadChartPyramid }   from '@fortawesome/pro-duotone-svg-icons';
import { faChartPyramid as falChartPyramid }   from '@fortawesome/pro-light-svg-icons';
import { faChartPyramid as farChartPyramid }   from '@fortawesome/pro-regular-svg-icons';
import { faChartPyramid as fasChartPyramid }   from '@fortawesome/pro-solid-svg-icons';
import { faChartRadar as fadChartRadar }   from '@fortawesome/pro-duotone-svg-icons';
import { faChartRadar as falChartRadar }   from '@fortawesome/pro-light-svg-icons';
import { faChartRadar as farChartRadar }   from '@fortawesome/pro-regular-svg-icons';
import { faChartRadar as fasChartRadar }   from '@fortawesome/pro-solid-svg-icons';
import { faChartScatter as fadChartScatter }   from '@fortawesome/pro-duotone-svg-icons';
import { faChartScatter as falChartScatter }   from '@fortawesome/pro-light-svg-icons';
import { faChartScatter as farChartScatter }   from '@fortawesome/pro-regular-svg-icons';
import { faChartScatter as fasChartScatter }   from '@fortawesome/pro-solid-svg-icons';
import { faChartScatter3d as fadChartScatter3d }   from '@fortawesome/pro-duotone-svg-icons';
import { faChartScatter3d as falChartScatter3d }   from '@fortawesome/pro-light-svg-icons';
import { faChartScatter3d as farChartScatter3d }   from '@fortawesome/pro-regular-svg-icons';
import { faChartScatter3d as fasChartScatter3d }   from '@fortawesome/pro-solid-svg-icons';
import { faChartScatterBubble as fadChartScatterBubble }   from '@fortawesome/pro-duotone-svg-icons';
import { faChartScatterBubble as falChartScatterBubble }   from '@fortawesome/pro-light-svg-icons';
import { faChartScatterBubble as farChartScatterBubble }   from '@fortawesome/pro-regular-svg-icons';
import { faChartScatterBubble as fasChartScatterBubble }   from '@fortawesome/pro-solid-svg-icons';
import { faChartSimple as fadChartSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faChartSimple as falChartSimple }   from '@fortawesome/pro-light-svg-icons';
import { faChartSimple as farChartSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faChartSimple as fasChartSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faChartSimpleHorizontal as fadChartSimpleHorizontal }   from '@fortawesome/pro-duotone-svg-icons';
import { faChartSimpleHorizontal as falChartSimpleHorizontal }   from '@fortawesome/pro-light-svg-icons';
import { faChartSimpleHorizontal as farChartSimpleHorizontal }   from '@fortawesome/pro-regular-svg-icons';
import { faChartSimpleHorizontal as fasChartSimpleHorizontal }   from '@fortawesome/pro-solid-svg-icons';
import { faChartTreeMap as fadChartTreeMap }   from '@fortawesome/pro-duotone-svg-icons';
import { faChartTreeMap as falChartTreeMap }   from '@fortawesome/pro-light-svg-icons';
import { faChartTreeMap as farChartTreeMap }   from '@fortawesome/pro-regular-svg-icons';
import { faChartTreeMap as fasChartTreeMap }   from '@fortawesome/pro-solid-svg-icons';
import { faChartUser as fadChartUser }   from '@fortawesome/pro-duotone-svg-icons';
import { faChartUser as falChartUser }   from '@fortawesome/pro-light-svg-icons';
import { faChartUser as farChartUser }   from '@fortawesome/pro-regular-svg-icons';
import { faChartUser as fasChartUser }   from '@fortawesome/pro-solid-svg-icons';
import { faChartWaterfall as fadChartWaterfadll }   from '@fortawesome/pro-duotone-svg-icons';
import { faChartWaterfall as falChartWaterfalll }   from '@fortawesome/pro-light-svg-icons';
import { faChartWaterfall as farChartWaterfarll }   from '@fortawesome/pro-regular-svg-icons';
import { faChartWaterfall as fasChartWaterfasll }   from '@fortawesome/pro-solid-svg-icons';
import { faCheck as fadCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faCheck as falCheck }   from '@fortawesome/pro-light-svg-icons';
import { faCheck as farCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faCheck as fasCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faCheckCircle as fadCheckCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faCheckCircle as falCheckCircle }   from '@fortawesome/pro-light-svg-icons';
import { faCheckCircle as farCheckCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faCheckCircle as fasCheckCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faCheckDouble as fadCheckDouble }   from '@fortawesome/pro-duotone-svg-icons';
import { faCheckDouble as falCheckDouble }   from '@fortawesome/pro-light-svg-icons';
import { faCheckDouble as farCheckDouble }   from '@fortawesome/pro-regular-svg-icons';
import { faCheckDouble as fasCheckDouble }   from '@fortawesome/pro-solid-svg-icons';
import { faCheckSquare as fadCheckSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faCheckSquare as falCheckSquare }   from '@fortawesome/pro-light-svg-icons';
import { faCheckSquare as farCheckSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faCheckSquare as fasCheckSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faCheckToSlot as fadCheckToSlot }   from '@fortawesome/pro-duotone-svg-icons';
import { faCheckToSlot as falCheckToSlot }   from '@fortawesome/pro-light-svg-icons';
import { faCheckToSlot as farCheckToSlot }   from '@fortawesome/pro-regular-svg-icons';
import { faCheckToSlot as fasCheckToSlot }   from '@fortawesome/pro-solid-svg-icons';
import { faCheese as fadCheese }   from '@fortawesome/pro-duotone-svg-icons';
import { faCheese as falCheese }   from '@fortawesome/pro-light-svg-icons';
import { faCheese as farCheese }   from '@fortawesome/pro-regular-svg-icons';
import { faCheese as fasCheese }   from '@fortawesome/pro-solid-svg-icons';
import { faCheeseburger as fadCheeseburger }   from '@fortawesome/pro-duotone-svg-icons';
import { faCheeseburger as falCheeseburger }   from '@fortawesome/pro-light-svg-icons';
import { faCheeseburger as farCheeseburger }   from '@fortawesome/pro-regular-svg-icons';
import { faCheeseburger as fasCheeseburger }   from '@fortawesome/pro-solid-svg-icons';
import { faCheeseSwiss as fadCheeseSwiss }   from '@fortawesome/pro-duotone-svg-icons';
import { faCheeseSwiss as falCheeseSwiss }   from '@fortawesome/pro-light-svg-icons';
import { faCheeseSwiss as farCheeseSwiss }   from '@fortawesome/pro-regular-svg-icons';
import { faCheeseSwiss as fasCheeseSwiss }   from '@fortawesome/pro-solid-svg-icons';
import { faCherries as fadCherries }   from '@fortawesome/pro-duotone-svg-icons';
import { faCherries as falCherries }   from '@fortawesome/pro-light-svg-icons';
import { faCherries as farCherries }   from '@fortawesome/pro-regular-svg-icons';
import { faCherries as fasCherries }   from '@fortawesome/pro-solid-svg-icons';
import { faChess as fadChess }   from '@fortawesome/pro-duotone-svg-icons';
import { faChess as falChess }   from '@fortawesome/pro-light-svg-icons';
import { faChess as farChess }   from '@fortawesome/pro-regular-svg-icons';
import { faChess as fasChess }   from '@fortawesome/pro-solid-svg-icons';
import { faChessBishop as fadChessBishop }   from '@fortawesome/pro-duotone-svg-icons';
import { faChessBishop as falChessBishop }   from '@fortawesome/pro-light-svg-icons';
import { faChessBishop as farChessBishop }   from '@fortawesome/pro-regular-svg-icons';
import { faChessBishop as fasChessBishop }   from '@fortawesome/pro-solid-svg-icons';
import { faChessBishopAlt as fadChessBishopAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faChessBishopAlt as falChessBishopAlt }   from '@fortawesome/pro-light-svg-icons';
import { faChessBishopAlt as farChessBishopAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faChessBishopAlt as fasChessBishopAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faChessBishopPiece as fadChessBishopPiece }   from '@fortawesome/pro-duotone-svg-icons';
import { faChessBishopPiece as falChessBishopPiece }   from '@fortawesome/pro-light-svg-icons';
import { faChessBishopPiece as farChessBishopPiece }   from '@fortawesome/pro-regular-svg-icons';
import { faChessBishopPiece as fasChessBishopPiece }   from '@fortawesome/pro-solid-svg-icons';
import { faChessBoard as fadChessBoard }   from '@fortawesome/pro-duotone-svg-icons';
import { faChessBoard as falChessBoard }   from '@fortawesome/pro-light-svg-icons';
import { faChessBoard as farChessBoard }   from '@fortawesome/pro-regular-svg-icons';
import { faChessBoard as fasChessBoard }   from '@fortawesome/pro-solid-svg-icons';
import { faChessClock as fadChessClock }   from '@fortawesome/pro-duotone-svg-icons';
import { faChessClock as falChessClock }   from '@fortawesome/pro-light-svg-icons';
import { faChessClock as farChessClock }   from '@fortawesome/pro-regular-svg-icons';
import { faChessClock as fasChessClock }   from '@fortawesome/pro-solid-svg-icons';
import { faChessClockAlt as fadChessClockAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faChessClockAlt as falChessClockAlt }   from '@fortawesome/pro-light-svg-icons';
import { faChessClockAlt as farChessClockAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faChessClockAlt as fasChessClockAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faChessClockFlip as fadChessClockFlip }   from '@fortawesome/pro-duotone-svg-icons';
import { faChessClockFlip as falChessClockFlip }   from '@fortawesome/pro-light-svg-icons';
import { faChessClockFlip as farChessClockFlip }   from '@fortawesome/pro-regular-svg-icons';
import { faChessClockFlip as fasChessClockFlip }   from '@fortawesome/pro-solid-svg-icons';
import { faChessKing as fadChessKing }   from '@fortawesome/pro-duotone-svg-icons';
import { faChessKing as falChessKing }   from '@fortawesome/pro-light-svg-icons';
import { faChessKing as farChessKing }   from '@fortawesome/pro-regular-svg-icons';
import { faChessKing as fasChessKing }   from '@fortawesome/pro-solid-svg-icons';
import { faChessKingAlt as fadChessKingAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faChessKingAlt as falChessKingAlt }   from '@fortawesome/pro-light-svg-icons';
import { faChessKingAlt as farChessKingAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faChessKingAlt as fasChessKingAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faChessKingPiece as fadChessKingPiece }   from '@fortawesome/pro-duotone-svg-icons';
import { faChessKingPiece as falChessKingPiece }   from '@fortawesome/pro-light-svg-icons';
import { faChessKingPiece as farChessKingPiece }   from '@fortawesome/pro-regular-svg-icons';
import { faChessKingPiece as fasChessKingPiece }   from '@fortawesome/pro-solid-svg-icons';
import { faChessKnight as fadChessKnight }   from '@fortawesome/pro-duotone-svg-icons';
import { faChessKnight as falChessKnight }   from '@fortawesome/pro-light-svg-icons';
import { faChessKnight as farChessKnight }   from '@fortawesome/pro-regular-svg-icons';
import { faChessKnight as fasChessKnight }   from '@fortawesome/pro-solid-svg-icons';
import { faChessKnightAlt as fadChessKnightAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faChessKnightAlt as falChessKnightAlt }   from '@fortawesome/pro-light-svg-icons';
import { faChessKnightAlt as farChessKnightAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faChessKnightAlt as fasChessKnightAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faChessKnightPiece as fadChessKnightPiece }   from '@fortawesome/pro-duotone-svg-icons';
import { faChessKnightPiece as falChessKnightPiece }   from '@fortawesome/pro-light-svg-icons';
import { faChessKnightPiece as farChessKnightPiece }   from '@fortawesome/pro-regular-svg-icons';
import { faChessKnightPiece as fasChessKnightPiece }   from '@fortawesome/pro-solid-svg-icons';
import { faChessPawn as fadChessPawn }   from '@fortawesome/pro-duotone-svg-icons';
import { faChessPawn as falChessPawn }   from '@fortawesome/pro-light-svg-icons';
import { faChessPawn as farChessPawn }   from '@fortawesome/pro-regular-svg-icons';
import { faChessPawn as fasChessPawn }   from '@fortawesome/pro-solid-svg-icons';
import { faChessPawnAlt as fadChessPawnAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faChessPawnAlt as falChessPawnAlt }   from '@fortawesome/pro-light-svg-icons';
import { faChessPawnAlt as farChessPawnAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faChessPawnAlt as fasChessPawnAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faChessPawnPiece as fadChessPawnPiece }   from '@fortawesome/pro-duotone-svg-icons';
import { faChessPawnPiece as falChessPawnPiece }   from '@fortawesome/pro-light-svg-icons';
import { faChessPawnPiece as farChessPawnPiece }   from '@fortawesome/pro-regular-svg-icons';
import { faChessPawnPiece as fasChessPawnPiece }   from '@fortawesome/pro-solid-svg-icons';
import { faChessQueen as fadChessQueen }   from '@fortawesome/pro-duotone-svg-icons';
import { faChessQueen as falChessQueen }   from '@fortawesome/pro-light-svg-icons';
import { faChessQueen as farChessQueen }   from '@fortawesome/pro-regular-svg-icons';
import { faChessQueen as fasChessQueen }   from '@fortawesome/pro-solid-svg-icons';
import { faChessQueenAlt as fadChessQueenAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faChessQueenAlt as falChessQueenAlt }   from '@fortawesome/pro-light-svg-icons';
import { faChessQueenAlt as farChessQueenAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faChessQueenAlt as fasChessQueenAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faChessQueenPiece as fadChessQueenPiece }   from '@fortawesome/pro-duotone-svg-icons';
import { faChessQueenPiece as falChessQueenPiece }   from '@fortawesome/pro-light-svg-icons';
import { faChessQueenPiece as farChessQueenPiece }   from '@fortawesome/pro-regular-svg-icons';
import { faChessQueenPiece as fasChessQueenPiece }   from '@fortawesome/pro-solid-svg-icons';
import { faChessRook as fadChessRook }   from '@fortawesome/pro-duotone-svg-icons';
import { faChessRook as falChessRook }   from '@fortawesome/pro-light-svg-icons';
import { faChessRook as farChessRook }   from '@fortawesome/pro-regular-svg-icons';
import { faChessRook as fasChessRook }   from '@fortawesome/pro-solid-svg-icons';
import { faChessRookAlt as fadChessRookAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faChessRookAlt as falChessRookAlt }   from '@fortawesome/pro-light-svg-icons';
import { faChessRookAlt as farChessRookAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faChessRookAlt as fasChessRookAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faChessRookPiece as fadChessRookPiece }   from '@fortawesome/pro-duotone-svg-icons';
import { faChessRookPiece as falChessRookPiece }   from '@fortawesome/pro-light-svg-icons';
import { faChessRookPiece as farChessRookPiece }   from '@fortawesome/pro-regular-svg-icons';
import { faChessRookPiece as fasChessRookPiece }   from '@fortawesome/pro-solid-svg-icons';
import { faChestnut as fadChestnut }   from '@fortawesome/pro-duotone-svg-icons';
import { faChestnut as falChestnut }   from '@fortawesome/pro-light-svg-icons';
import { faChestnut as farChestnut }   from '@fortawesome/pro-regular-svg-icons';
import { faChestnut as fasChestnut }   from '@fortawesome/pro-solid-svg-icons';
import { faChevronCircleDown as fadChevronCircleDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faChevronCircleDown as falChevronCircleDown }   from '@fortawesome/pro-light-svg-icons';
import { faChevronCircleDown as farChevronCircleDown }   from '@fortawesome/pro-regular-svg-icons';
import { faChevronCircleDown as fasChevronCircleDown }   from '@fortawesome/pro-solid-svg-icons';
import { faChevronCircleLeft as fadChevronCircleLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faChevronCircleLeft as falChevronCircleLeft }   from '@fortawesome/pro-light-svg-icons';
import { faChevronCircleLeft as farChevronCircleLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faChevronCircleLeft as fasChevronCircleLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faChevronCircleRight as fadChevronCircleRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faChevronCircleRight as falChevronCircleRight }   from '@fortawesome/pro-light-svg-icons';
import { faChevronCircleRight as farChevronCircleRight }   from '@fortawesome/pro-regular-svg-icons';
import { faChevronCircleRight as fasChevronCircleRight }   from '@fortawesome/pro-solid-svg-icons';
import { faChevronCircleUp as fadChevronCircleUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faChevronCircleUp as falChevronCircleUp }   from '@fortawesome/pro-light-svg-icons';
import { faChevronCircleUp as farChevronCircleUp }   from '@fortawesome/pro-regular-svg-icons';
import { faChevronCircleUp as fasChevronCircleUp }   from '@fortawesome/pro-solid-svg-icons';
import { faChevronDoubleDown as fadChevronDoubleDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faChevronDoubleDown as falChevronDoubleDown }   from '@fortawesome/pro-light-svg-icons';
import { faChevronDoubleDown as farChevronDoubleDown }   from '@fortawesome/pro-regular-svg-icons';
import { faChevronDoubleDown as fasChevronDoubleDown }   from '@fortawesome/pro-solid-svg-icons';
import { faChevronDoubleLeft as fadChevronDoubleLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faChevronDoubleLeft as falChevronDoubleLeft }   from '@fortawesome/pro-light-svg-icons';
import { faChevronDoubleLeft as farChevronDoubleLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faChevronDoubleLeft as fasChevronDoubleLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faChevronDoubleRight as fadChevronDoubleRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faChevronDoubleRight as falChevronDoubleRight }   from '@fortawesome/pro-light-svg-icons';
import { faChevronDoubleRight as farChevronDoubleRight }   from '@fortawesome/pro-regular-svg-icons';
import { faChevronDoubleRight as fasChevronDoubleRight }   from '@fortawesome/pro-solid-svg-icons';
import { faChevronDoubleUp as fadChevronDoubleUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faChevronDoubleUp as falChevronDoubleUp }   from '@fortawesome/pro-light-svg-icons';
import { faChevronDoubleUp as farChevronDoubleUp }   from '@fortawesome/pro-regular-svg-icons';
import { faChevronDoubleUp as fasChevronDoubleUp }   from '@fortawesome/pro-solid-svg-icons';
import { faChevronDown as fadChevronDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faChevronDown as falChevronDown }   from '@fortawesome/pro-light-svg-icons';
import { faChevronDown as farChevronDown }   from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown as fasChevronDown }   from '@fortawesome/pro-solid-svg-icons';
import { faChevronLeft as fadChevronLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faChevronLeft as falChevronLeft }   from '@fortawesome/pro-light-svg-icons';
import { faChevronLeft as farChevronLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faChevronLeft as fasChevronLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faChevronRight as fadChevronRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faChevronRight as falChevronRight }   from '@fortawesome/pro-light-svg-icons';
import { faChevronRight as farChevronRight }   from '@fortawesome/pro-regular-svg-icons';
import { faChevronRight as fasChevronRight }   from '@fortawesome/pro-solid-svg-icons';
import { faChevronsDown as fadChevronsDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faChevronsDown as falChevronsDown }   from '@fortawesome/pro-light-svg-icons';
import { faChevronsDown as farChevronsDown }   from '@fortawesome/pro-regular-svg-icons';
import { faChevronsDown as fasChevronsDown }   from '@fortawesome/pro-solid-svg-icons';
import { faChevronsLeft as fadChevronsLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faChevronsLeft as falChevronsLeft }   from '@fortawesome/pro-light-svg-icons';
import { faChevronsLeft as farChevronsLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faChevronsLeft as fasChevronsLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faChevronSquareDown as fadChevronSquareDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faChevronSquareDown as falChevronSquareDown }   from '@fortawesome/pro-light-svg-icons';
import { faChevronSquareDown as farChevronSquareDown }   from '@fortawesome/pro-regular-svg-icons';
import { faChevronSquareDown as fasChevronSquareDown }   from '@fortawesome/pro-solid-svg-icons';
import { faChevronSquareLeft as fadChevronSquareLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faChevronSquareLeft as falChevronSquareLeft }   from '@fortawesome/pro-light-svg-icons';
import { faChevronSquareLeft as farChevronSquareLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faChevronSquareLeft as fasChevronSquareLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faChevronSquareRight as fadChevronSquareRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faChevronSquareRight as falChevronSquareRight }   from '@fortawesome/pro-light-svg-icons';
import { faChevronSquareRight as farChevronSquareRight }   from '@fortawesome/pro-regular-svg-icons';
import { faChevronSquareRight as fasChevronSquareRight }   from '@fortawesome/pro-solid-svg-icons';
import { faChevronSquareUp as fadChevronSquareUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faChevronSquareUp as falChevronSquareUp }   from '@fortawesome/pro-light-svg-icons';
import { faChevronSquareUp as farChevronSquareUp }   from '@fortawesome/pro-regular-svg-icons';
import { faChevronSquareUp as fasChevronSquareUp }   from '@fortawesome/pro-solid-svg-icons';
import { faChevronsRight as fadChevronsRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faChevronsRight as falChevronsRight }   from '@fortawesome/pro-light-svg-icons';
import { faChevronsRight as farChevronsRight }   from '@fortawesome/pro-regular-svg-icons';
import { faChevronsRight as fasChevronsRight }   from '@fortawesome/pro-solid-svg-icons';
import { faChevronsUp as fadChevronsUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faChevronsUp as falChevronsUp }   from '@fortawesome/pro-light-svg-icons';
import { faChevronsUp as farChevronsUp }   from '@fortawesome/pro-regular-svg-icons';
import { faChevronsUp as fasChevronsUp }   from '@fortawesome/pro-solid-svg-icons';
import { faChevronUp as fadChevronUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faChevronUp as falChevronUp }   from '@fortawesome/pro-light-svg-icons';
import { faChevronUp as farChevronUp }   from '@fortawesome/pro-regular-svg-icons';
import { faChevronUp as fasChevronUp }   from '@fortawesome/pro-solid-svg-icons';
import { faChild as fadChild }   from '@fortawesome/pro-duotone-svg-icons';
import { faChild as falChild }   from '@fortawesome/pro-light-svg-icons';
import { faChild as farChild }   from '@fortawesome/pro-regular-svg-icons';
import { faChild as fasChild }   from '@fortawesome/pro-solid-svg-icons';
import { faChildren as fadChildren }   from '@fortawesome/pro-duotone-svg-icons';
import { faChildren as falChildren }   from '@fortawesome/pro-light-svg-icons';
import { faChildren as farChildren }   from '@fortawesome/pro-regular-svg-icons';
import { faChildren as fasChildren }   from '@fortawesome/pro-solid-svg-icons';
import { faChildRifle as fadChildRifle }   from '@fortawesome/pro-duotone-svg-icons';
import { faChildRifle as falChildRifle }   from '@fortawesome/pro-light-svg-icons';
import { faChildRifle as farChildRifle }   from '@fortawesome/pro-regular-svg-icons';
import { faChildRifle as fasChildRifle }   from '@fortawesome/pro-solid-svg-icons';
import { faChimney as fadChimney }   from '@fortawesome/pro-duotone-svg-icons';
import { faChimney as falChimney }   from '@fortawesome/pro-light-svg-icons';
import { faChimney as farChimney }   from '@fortawesome/pro-regular-svg-icons';
import { faChimney as fasChimney }   from '@fortawesome/pro-solid-svg-icons';
import { faChocolateBar as fadChocolateBar }   from '@fortawesome/pro-duotone-svg-icons';
import { faChocolateBar as falChocolateBar }   from '@fortawesome/pro-light-svg-icons';
import { faChocolateBar as farChocolateBar }   from '@fortawesome/pro-regular-svg-icons';
import { faChocolateBar as fasChocolateBar }   from '@fortawesome/pro-solid-svg-icons';
import { faChopsticks as fadChopsticks }   from '@fortawesome/pro-duotone-svg-icons';
import { faChopsticks as falChopsticks }   from '@fortawesome/pro-light-svg-icons';
import { faChopsticks as farChopsticks }   from '@fortawesome/pro-regular-svg-icons';
import { faChopsticks as fasChopsticks }   from '@fortawesome/pro-solid-svg-icons';
import { faChurch as fadChurch }   from '@fortawesome/pro-duotone-svg-icons';
import { faChurch as falChurch }   from '@fortawesome/pro-light-svg-icons';
import { faChurch as farChurch }   from '@fortawesome/pro-regular-svg-icons';
import { faChurch as fasChurch }   from '@fortawesome/pro-solid-svg-icons';
import { faCircle as fadCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircle as falCircle }   from '@fortawesome/pro-light-svg-icons';
import { faCircle as farCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faCircle as fasCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faCircle0 as fadCircle0 }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircle0 as falCircle0 }   from '@fortawesome/pro-light-svg-icons';
import { faCircle0 as farCircle0 }   from '@fortawesome/pro-regular-svg-icons';
import { faCircle0 as fasCircle0 }   from '@fortawesome/pro-solid-svg-icons';
import { faCircle1 as fadCircle1 }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircle1 as falCircle1 }   from '@fortawesome/pro-light-svg-icons';
import { faCircle1 as farCircle1 }   from '@fortawesome/pro-regular-svg-icons';
import { faCircle1 as fasCircle1 }   from '@fortawesome/pro-solid-svg-icons';
import { faCircle2 as fadCircle2 }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircle2 as falCircle2 }   from '@fortawesome/pro-light-svg-icons';
import { faCircle2 as farCircle2 }   from '@fortawesome/pro-regular-svg-icons';
import { faCircle2 as fasCircle2 }   from '@fortawesome/pro-solid-svg-icons';
import { faCircle3 as fadCircle3 }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircle3 as falCircle3 }   from '@fortawesome/pro-light-svg-icons';
import { faCircle3 as farCircle3 }   from '@fortawesome/pro-regular-svg-icons';
import { faCircle3 as fasCircle3 }   from '@fortawesome/pro-solid-svg-icons';
import { faCircle4 as fadCircle4 }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircle4 as falCircle4 }   from '@fortawesome/pro-light-svg-icons';
import { faCircle4 as farCircle4 }   from '@fortawesome/pro-regular-svg-icons';
import { faCircle4 as fasCircle4 }   from '@fortawesome/pro-solid-svg-icons';
import { faCircle5 as fadCircle5 }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircle5 as falCircle5 }   from '@fortawesome/pro-light-svg-icons';
import { faCircle5 as farCircle5 }   from '@fortawesome/pro-regular-svg-icons';
import { faCircle5 as fasCircle5 }   from '@fortawesome/pro-solid-svg-icons';
import { faCircle6 as fadCircle6 }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircle6 as falCircle6 }   from '@fortawesome/pro-light-svg-icons';
import { faCircle6 as farCircle6 }   from '@fortawesome/pro-regular-svg-icons';
import { faCircle6 as fasCircle6 }   from '@fortawesome/pro-solid-svg-icons';
import { faCircle7 as fadCircle7 }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircle7 as falCircle7 }   from '@fortawesome/pro-light-svg-icons';
import { faCircle7 as farCircle7 }   from '@fortawesome/pro-regular-svg-icons';
import { faCircle7 as fasCircle7 }   from '@fortawesome/pro-solid-svg-icons';
import { faCircle8 as fadCircle8 }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircle8 as falCircle8 }   from '@fortawesome/pro-light-svg-icons';
import { faCircle8 as farCircle8 }   from '@fortawesome/pro-regular-svg-icons';
import { faCircle8 as fasCircle8 }   from '@fortawesome/pro-solid-svg-icons';
import { faCircle9 as fadCircle9 }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircle9 as falCircle9 }   from '@fortawesome/pro-light-svg-icons';
import { faCircle9 as farCircle9 }   from '@fortawesome/pro-regular-svg-icons';
import { faCircle9 as fasCircle9 }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleA as fadCircleA }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleA as falCircleA }   from '@fortawesome/pro-light-svg-icons';
import { faCircleA as farCircleA }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleA as fasCircleA }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleAmpersand as fadCircleAmpersand }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleAmpersand as falCircleAmpersand }   from '@fortawesome/pro-light-svg-icons';
import { faCircleAmpersand as farCircleAmpersand }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleAmpersand as fasCircleAmpersand }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleArrowDown as fadCircleArrowDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleArrowDown as falCircleArrowDown }   from '@fortawesome/pro-light-svg-icons';
import { faCircleArrowDown as farCircleArrowDown }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleArrowDown as fasCircleArrowDown }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleArrowDownLeft as fadCircleArrowDownLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleArrowDownLeft as falCircleArrowDownLeft }   from '@fortawesome/pro-light-svg-icons';
import { faCircleArrowDownLeft as farCircleArrowDownLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleArrowDownLeft as fasCircleArrowDownLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleArrowDownRight as fadCircleArrowDownRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleArrowDownRight as falCircleArrowDownRight }   from '@fortawesome/pro-light-svg-icons';
import { faCircleArrowDownRight as farCircleArrowDownRight }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleArrowDownRight as fasCircleArrowDownRight }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleArrowLeft as fadCircleArrowLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleArrowLeft as falCircleArrowLeft }   from '@fortawesome/pro-light-svg-icons';
import { faCircleArrowLeft as farCircleArrowLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleArrowLeft as fasCircleArrowLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleArrowRight as fadCircleArrowRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleArrowRight as falCircleArrowRight }   from '@fortawesome/pro-light-svg-icons';
import { faCircleArrowRight as farCircleArrowRight }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleArrowRight as fasCircleArrowRight }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleArrowUp as fadCircleArrowUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleArrowUp as falCircleArrowUp }   from '@fortawesome/pro-light-svg-icons';
import { faCircleArrowUp as farCircleArrowUp }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleArrowUp as fasCircleArrowUp }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleArrowUpLeft as fadCircleArrowUpLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleArrowUpLeft as falCircleArrowUpLeft }   from '@fortawesome/pro-light-svg-icons';
import { faCircleArrowUpLeft as farCircleArrowUpLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleArrowUpLeft as fasCircleArrowUpLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleArrowUpRight as fadCircleArrowUpRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleArrowUpRight as falCircleArrowUpRight }   from '@fortawesome/pro-light-svg-icons';
import { faCircleArrowUpRight as farCircleArrowUpRight }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleArrowUpRight as fasCircleArrowUpRight }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleB as fadCircleB }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleB as falCircleB }   from '@fortawesome/pro-light-svg-icons';
import { faCircleB as farCircleB }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleB as fasCircleB }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleBolt as fadCircleBolt }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleBolt as falCircleBolt }   from '@fortawesome/pro-light-svg-icons';
import { faCircleBolt as farCircleBolt }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleBolt as fasCircleBolt }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleBookmark as fadCircleBookmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleBookmark as falCircleBookmark }   from '@fortawesome/pro-light-svg-icons';
import { faCircleBookmark as farCircleBookmark }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleBookmark as fasCircleBookmark }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleBookOpen as fadCircleBookOpen }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleBookOpen as falCircleBookOpen }   from '@fortawesome/pro-light-svg-icons';
import { faCircleBookOpen as farCircleBookOpen }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleBookOpen as fasCircleBookOpen }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleC as fadCircleC }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleC as falCircleC }   from '@fortawesome/pro-light-svg-icons';
import { faCircleC as farCircleC }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleC as fasCircleC }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleCalendar as fadCircleCalendar }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleCalendar as falCircleCalendar }   from '@fortawesome/pro-light-svg-icons';
import { faCircleCalendar as farCircleCalendar }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleCalendar as fasCircleCalendar }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleCamera as fadCircleCamera }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleCamera as falCircleCamera }   from '@fortawesome/pro-light-svg-icons';
import { faCircleCamera as farCircleCamera }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleCamera as fasCircleCamera }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleCaretDown as fadCircleCaretDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleCaretDown as falCircleCaretDown }   from '@fortawesome/pro-light-svg-icons';
import { faCircleCaretDown as farCircleCaretDown }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleCaretDown as fasCircleCaretDown }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleCaretLeft as fadCircleCaretLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleCaretLeft as falCircleCaretLeft }   from '@fortawesome/pro-light-svg-icons';
import { faCircleCaretLeft as farCircleCaretLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleCaretLeft as fasCircleCaretLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleCaretRight as fadCircleCaretRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleCaretRight as falCircleCaretRight }   from '@fortawesome/pro-light-svg-icons';
import { faCircleCaretRight as farCircleCaretRight }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleCaretRight as fasCircleCaretRight }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleCaretUp as fadCircleCaretUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleCaretUp as falCircleCaretUp }   from '@fortawesome/pro-light-svg-icons';
import { faCircleCaretUp as farCircleCaretUp }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleCaretUp as fasCircleCaretUp }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleCheck as fadCircleCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleCheck as falCircleCheck }   from '@fortawesome/pro-light-svg-icons';
import { faCircleCheck as farCircleCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleCheck as fasCircleCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleChevronDown as fadCircleChevronDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleChevronDown as falCircleChevronDown }   from '@fortawesome/pro-light-svg-icons';
import { faCircleChevronDown as farCircleChevronDown }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleChevronDown as fasCircleChevronDown }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleChevronLeft as fadCircleChevronLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleChevronLeft as falCircleChevronLeft }   from '@fortawesome/pro-light-svg-icons';
import { faCircleChevronLeft as farCircleChevronLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleChevronLeft as fasCircleChevronLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleChevronRight as fadCircleChevronRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleChevronRight as falCircleChevronRight }   from '@fortawesome/pro-light-svg-icons';
import { faCircleChevronRight as farCircleChevronRight }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleChevronRight as fasCircleChevronRight }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleChevronUp as fadCircleChevronUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleChevronUp as falCircleChevronUp }   from '@fortawesome/pro-light-svg-icons';
import { faCircleChevronUp as farCircleChevronUp }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleChevronUp as fasCircleChevronUp }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleD as fadCircleD }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleD as falCircleD }   from '@fortawesome/pro-light-svg-icons';
import { faCircleD as farCircleD }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleD as fasCircleD }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleDashed as fadCircleDashed }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleDashed as falCircleDashed }   from '@fortawesome/pro-light-svg-icons';
import { faCircleDashed as farCircleDashed }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleDashed as fasCircleDashed }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleDivide as fadCircleDivide }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleDivide as falCircleDivide }   from '@fortawesome/pro-light-svg-icons';
import { faCircleDivide as farCircleDivide }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleDivide as fasCircleDivide }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleDollar as fadCircleDollar }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleDollar as falCircleDollar }   from '@fortawesome/pro-light-svg-icons';
import { faCircleDollar as farCircleDollar }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleDollar as fasCircleDollar }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleDollarToSlot as fadCircleDollarToSlot }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleDollarToSlot as falCircleDollarToSlot }   from '@fortawesome/pro-light-svg-icons';
import { faCircleDollarToSlot as farCircleDollarToSlot }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleDollarToSlot as fasCircleDollarToSlot }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleDot as fadCircleDot }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleDot as falCircleDot }   from '@fortawesome/pro-light-svg-icons';
import { faCircleDot as farCircleDot }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleDot as fasCircleDot }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleDown as fadCircleDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleDown as falCircleDown }   from '@fortawesome/pro-light-svg-icons';
import { faCircleDown as farCircleDown }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleDown as fasCircleDown }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleDownLeft as fadCircleDownLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleDownLeft as falCircleDownLeft }   from '@fortawesome/pro-light-svg-icons';
import { faCircleDownLeft as farCircleDownLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleDownLeft as fasCircleDownLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleDownRight as fadCircleDownRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleDownRight as falCircleDownRight }   from '@fortawesome/pro-light-svg-icons';
import { faCircleDownRight as farCircleDownRight }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleDownRight as fasCircleDownRight }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleE as fadCircleE }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleE as falCircleE }   from '@fortawesome/pro-light-svg-icons';
import { faCircleE as farCircleE }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleE as fasCircleE }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleEllipsis as fadCircleEllipsis }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleEllipsis as falCircleEllipsis }   from '@fortawesome/pro-light-svg-icons';
import { faCircleEllipsis as farCircleEllipsis }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleEllipsis as fasCircleEllipsis }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleEllipsisVertical as fadCircleEllipsisVertical }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleEllipsisVertical as falCircleEllipsisVertical }   from '@fortawesome/pro-light-svg-icons';
import { faCircleEllipsisVertical as farCircleEllipsisVertical }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleEllipsisVertical as fasCircleEllipsisVertical }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleEnvelope as fadCircleEnvelope }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleEnvelope as falCircleEnvelope }   from '@fortawesome/pro-light-svg-icons';
import { faCircleEnvelope as farCircleEnvelope }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleEnvelope as fasCircleEnvelope }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleExclamation as fadCircleExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleExclamation as falCircleExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faCircleExclamation as farCircleExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleExclamation as fasCircleExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleExclamationCheck as fadCircleExclamationCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleExclamationCheck as falCircleExclamationCheck }   from '@fortawesome/pro-light-svg-icons';
import { faCircleExclamationCheck as farCircleExclamationCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleExclamationCheck as fasCircleExclamationCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleF as fadCircleF }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleF as falCircleF }   from '@fortawesome/pro-light-svg-icons';
import { faCircleF as farCircleF }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleF as fasCircleF }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleG as fadCircleG }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleG as falCircleG }   from '@fortawesome/pro-light-svg-icons';
import { faCircleG as farCircleG }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleG as fasCircleG }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleH as fadCircleH }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleH as falCircleH }   from '@fortawesome/pro-light-svg-icons';
import { faCircleH as farCircleH }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleH as fasCircleH }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleHalf as fadCircleHalf }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleHalf as falCircleHalf }   from '@fortawesome/pro-light-svg-icons';
import { faCircleHalf as farCircleHalf }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleHalf as fasCircleHalf }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleHalfStroke as fadCircleHalfStroke }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleHalfStroke as falCircleHalfStroke }   from '@fortawesome/pro-light-svg-icons';
import { faCircleHalfStroke as farCircleHalfStroke }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleHalfStroke as fasCircleHalfStroke }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleHeart as fadCircleHeart }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleHeart as falCircleHeart }   from '@fortawesome/pro-light-svg-icons';
import { faCircleHeart as farCircleHeart }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleHeart as fasCircleHeart }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleI as fadCircleI }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleI as falCircleI }   from '@fortawesome/pro-light-svg-icons';
import { faCircleI as farCircleI }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleI as fasCircleI }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleInfo as fadCircleInfo }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleInfo as falCircleInfo }   from '@fortawesome/pro-light-svg-icons';
import { faCircleInfo as farCircleInfo }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleInfo as fasCircleInfo }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleJ as fadCircleJ }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleJ as falCircleJ }   from '@fortawesome/pro-light-svg-icons';
import { faCircleJ as farCircleJ }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleJ as fasCircleJ }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleK as fadCircleK }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleK as falCircleK }   from '@fortawesome/pro-light-svg-icons';
import { faCircleK as farCircleK }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleK as fasCircleK }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleL as fadCircleL }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleL as falCircleL }   from '@fortawesome/pro-light-svg-icons';
import { faCircleL as farCircleL }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleL as fasCircleL }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleLeft as fadCircleLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleLeft as falCircleLeft }   from '@fortawesome/pro-light-svg-icons';
import { faCircleLeft as farCircleLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleLeft as fasCircleLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleLocationArrow as fadCircleLocationArrow }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleLocationArrow as falCircleLocationArrow }   from '@fortawesome/pro-light-svg-icons';
import { faCircleLocationArrow as farCircleLocationArrow }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleLocationArrow as fasCircleLocationArrow }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleM as fadCircleM }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleM as falCircleM }   from '@fortawesome/pro-light-svg-icons';
import { faCircleM as farCircleM }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleM as fasCircleM }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleMicrophone as fadCircleMicrophone }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleMicrophone as falCircleMicrophone }   from '@fortawesome/pro-light-svg-icons';
import { faCircleMicrophone as farCircleMicrophone }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleMicrophone as fasCircleMicrophone }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleMicrophoneLines as fadCircleMicrophoneLines }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleMicrophoneLines as falCircleMicrophoneLines }   from '@fortawesome/pro-light-svg-icons';
import { faCircleMicrophoneLines as farCircleMicrophoneLines }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleMicrophoneLines as fasCircleMicrophoneLines }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleMinus as fadCircleMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleMinus as falCircleMinus }   from '@fortawesome/pro-light-svg-icons';
import { faCircleMinus as farCircleMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleMinus as fasCircleMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleN as fadCircleN }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleN as falCircleN }   from '@fortawesome/pro-light-svg-icons';
import { faCircleN as farCircleN }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleN as fasCircleN }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleNodes as fadCircleNodes }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleNodes as falCircleNodes }   from '@fortawesome/pro-light-svg-icons';
import { faCircleNodes as farCircleNodes }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleNodes as fasCircleNodes }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleNotch as fadCircleNotch }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleNotch as falCircleNotch }   from '@fortawesome/pro-light-svg-icons';
import { faCircleNotch as farCircleNotch }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleNotch as fasCircleNotch }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleO as fadCircleO }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleO as falCircleO }   from '@fortawesome/pro-light-svg-icons';
import { faCircleO as farCircleO }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleO as fasCircleO }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleP as fadCircleP }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleP as falCircleP }   from '@fortawesome/pro-light-svg-icons';
import { faCircleP as farCircleP }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleP as fasCircleP }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleParking as fadCircleParking }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleParking as falCircleParking }   from '@fortawesome/pro-light-svg-icons';
import { faCircleParking as farCircleParking }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleParking as fasCircleParking }   from '@fortawesome/pro-solid-svg-icons';
import { faCirclePause as fadCirclePause }   from '@fortawesome/pro-duotone-svg-icons';
import { faCirclePause as falCirclePause }   from '@fortawesome/pro-light-svg-icons';
import { faCirclePause as farCirclePause }   from '@fortawesome/pro-regular-svg-icons';
import { faCirclePause as fasCirclePause }   from '@fortawesome/pro-solid-svg-icons';
import { faCirclePhone as fadCirclePhone }   from '@fortawesome/pro-duotone-svg-icons';
import { faCirclePhone as falCirclePhone }   from '@fortawesome/pro-light-svg-icons';
import { faCirclePhone as farCirclePhone }   from '@fortawesome/pro-regular-svg-icons';
import { faCirclePhone as fasCirclePhone }   from '@fortawesome/pro-solid-svg-icons';
import { faCirclePhoneFlip as fadCirclePhoneFlip }   from '@fortawesome/pro-duotone-svg-icons';
import { faCirclePhoneFlip as falCirclePhoneFlip }   from '@fortawesome/pro-light-svg-icons';
import { faCirclePhoneFlip as farCirclePhoneFlip }   from '@fortawesome/pro-regular-svg-icons';
import { faCirclePhoneFlip as fasCirclePhoneFlip }   from '@fortawesome/pro-solid-svg-icons';
import { faCirclePhoneHangup as fadCirclePhoneHangup }   from '@fortawesome/pro-duotone-svg-icons';
import { faCirclePhoneHangup as falCirclePhoneHangup }   from '@fortawesome/pro-light-svg-icons';
import { faCirclePhoneHangup as farCirclePhoneHangup }   from '@fortawesome/pro-regular-svg-icons';
import { faCirclePhoneHangup as fasCirclePhoneHangup }   from '@fortawesome/pro-solid-svg-icons';
import { faCirclePlay as fadCirclePlay }   from '@fortawesome/pro-duotone-svg-icons';
import { faCirclePlay as falCirclePlay }   from '@fortawesome/pro-light-svg-icons';
import { faCirclePlay as farCirclePlay }   from '@fortawesome/pro-regular-svg-icons';
import { faCirclePlay as fasCirclePlay }   from '@fortawesome/pro-solid-svg-icons';
import { faCirclePlus as fadCirclePlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faCirclePlus as falCirclePlus }   from '@fortawesome/pro-light-svg-icons';
import { faCirclePlus as farCirclePlus }   from '@fortawesome/pro-regular-svg-icons';
import { faCirclePlus as fasCirclePlus }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleQ as fadCircleQ }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleQ as falCircleQ }   from '@fortawesome/pro-light-svg-icons';
import { faCircleQ as farCircleQ }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleQ as fasCircleQ }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleQuarter as fadCircleQuarter }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleQuarter as falCircleQuarter }   from '@fortawesome/pro-light-svg-icons';
import { faCircleQuarter as farCircleQuarter }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleQuarter as fasCircleQuarter }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleQuarters as fadCircleQuarters }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleQuarters as falCircleQuarters }   from '@fortawesome/pro-light-svg-icons';
import { faCircleQuarters as farCircleQuarters }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleQuarters as fasCircleQuarters }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleQuestion as fadCircleQuestion }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleQuestion as falCircleQuestion }   from '@fortawesome/pro-light-svg-icons';
import { faCircleQuestion as farCircleQuestion }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleQuestion as fasCircleQuestion }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleR as fadCircleR }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleR as falCircleR }   from '@fortawesome/pro-light-svg-icons';
import { faCircleR as farCircleR }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleR as fasCircleR }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleRadiation as fadCircleRadiation }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleRadiation as falCircleRadiation }   from '@fortawesome/pro-light-svg-icons';
import { faCircleRadiation as farCircleRadiation }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleRadiation as fasCircleRadiation }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleRight as fadCircleRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleRight as falCircleRight }   from '@fortawesome/pro-light-svg-icons';
import { faCircleRight as farCircleRight }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleRight as fasCircleRight }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleS as fadCircleS }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleS as falCircleS }   from '@fortawesome/pro-light-svg-icons';
import { faCircleS as farCircleS }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleS as fasCircleS }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleSmall as fadCircleSmall }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleSmall as falCircleSmall }   from '@fortawesome/pro-light-svg-icons';
import { faCircleSmall as farCircleSmall }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleSmall as fasCircleSmall }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleSort as fadCircleSort }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleSort as falCircleSort }   from '@fortawesome/pro-light-svg-icons';
import { faCircleSort as farCircleSort }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleSort as fasCircleSort }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleSortDown as fadCircleSortDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleSortDown as falCircleSortDown }   from '@fortawesome/pro-light-svg-icons';
import { faCircleSortDown as farCircleSortDown }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleSortDown as fasCircleSortDown }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleSortUp as fadCircleSortUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleSortUp as falCircleSortUp }   from '@fortawesome/pro-light-svg-icons';
import { faCircleSortUp as farCircleSortUp }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleSortUp as fasCircleSortUp }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleStar as fadCircleStar }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleStar as falCircleStar }   from '@fortawesome/pro-light-svg-icons';
import { faCircleStar as farCircleStar }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleStar as fasCircleStar }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleStop as fadCircleStop }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleStop as falCircleStop }   from '@fortawesome/pro-light-svg-icons';
import { faCircleStop as farCircleStop }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleStop as fasCircleStop }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleT as fadCircleT }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleT as falCircleT }   from '@fortawesome/pro-light-svg-icons';
import { faCircleT as farCircleT }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleT as fasCircleT }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleThreeQuarters as fadCircleThreeQuarters }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleThreeQuarters as falCircleThreeQuarters }   from '@fortawesome/pro-light-svg-icons';
import { faCircleThreeQuarters as farCircleThreeQuarters }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleThreeQuarters as fasCircleThreeQuarters }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleTrash as fadCircleTrash }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleTrash as falCircleTrash }   from '@fortawesome/pro-light-svg-icons';
import { faCircleTrash as farCircleTrash }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleTrash as fasCircleTrash }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleU as fadCircleU }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleU as falCircleU }   from '@fortawesome/pro-light-svg-icons';
import { faCircleU as farCircleU }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleU as fasCircleU }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleUp as fadCircleUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleUp as falCircleUp }   from '@fortawesome/pro-light-svg-icons';
import { faCircleUp as farCircleUp }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleUp as fasCircleUp }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleUpLeft as fadCircleUpLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleUpLeft as falCircleUpLeft }   from '@fortawesome/pro-light-svg-icons';
import { faCircleUpLeft as farCircleUpLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleUpLeft as fasCircleUpLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleUpRight as fadCircleUpRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleUpRight as falCircleUpRight }   from '@fortawesome/pro-light-svg-icons';
import { faCircleUpRight as farCircleUpRight }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleUpRight as fasCircleUpRight }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleUser as fadCircleUser }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleUser as falCircleUser }   from '@fortawesome/pro-light-svg-icons';
import { faCircleUser as farCircleUser }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleUser as fasCircleUser }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleV as fadCircleV }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleV as falCircleV }   from '@fortawesome/pro-light-svg-icons';
import { faCircleV as farCircleV }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleV as fasCircleV }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleVideo as fadCircleVideo }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleVideo as falCircleVideo }   from '@fortawesome/pro-light-svg-icons';
import { faCircleVideo as farCircleVideo }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleVideo as fasCircleVideo }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleW as fadCircleW }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleW as falCircleW }   from '@fortawesome/pro-light-svg-icons';
import { faCircleW as farCircleW }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleW as fasCircleW }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleWaveformLines as fadCircleWaveformLines }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleWaveformLines as falCircleWaveformLines }   from '@fortawesome/pro-light-svg-icons';
import { faCircleWaveformLines as farCircleWaveformLines }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleWaveformLines as fasCircleWaveformLines }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleX as fadCircleX }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleX as falCircleX }   from '@fortawesome/pro-light-svg-icons';
import { faCircleX as farCircleX }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleX as fasCircleX }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleXmark as fadCircleXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleXmark as falCircleXmark }   from '@fortawesome/pro-light-svg-icons';
import { faCircleXmark as farCircleXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleXmark as fasCircleXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleY as fadCircleY }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleY as falCircleY }   from '@fortawesome/pro-light-svg-icons';
import { faCircleY as farCircleY }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleY as fasCircleY }   from '@fortawesome/pro-solid-svg-icons';
import { faCircleZ as fadCircleZ }   from '@fortawesome/pro-duotone-svg-icons';
import { faCircleZ as falCircleZ }   from '@fortawesome/pro-light-svg-icons';
import { faCircleZ as farCircleZ }   from '@fortawesome/pro-regular-svg-icons';
import { faCircleZ as fasCircleZ }   from '@fortawesome/pro-solid-svg-icons';
import { faCitrus as fadCitrus }   from '@fortawesome/pro-duotone-svg-icons';
import { faCitrus as falCitrus }   from '@fortawesome/pro-light-svg-icons';
import { faCitrus as farCitrus }   from '@fortawesome/pro-regular-svg-icons';
import { faCitrus as fasCitrus }   from '@fortawesome/pro-solid-svg-icons';
import { faCitrusSlice as fadCitrusSlice }   from '@fortawesome/pro-duotone-svg-icons';
import { faCitrusSlice as falCitrusSlice }   from '@fortawesome/pro-light-svg-icons';
import { faCitrusSlice as farCitrusSlice }   from '@fortawesome/pro-regular-svg-icons';
import { faCitrusSlice as fasCitrusSlice }   from '@fortawesome/pro-solid-svg-icons';
import { faCity as fadCity }   from '@fortawesome/pro-duotone-svg-icons';
import { faCity as falCity }   from '@fortawesome/pro-light-svg-icons';
import { faCity as farCity }   from '@fortawesome/pro-regular-svg-icons';
import { faCity as fasCity }   from '@fortawesome/pro-solid-svg-icons';
import { faClapperboard as fadClapperboard }   from '@fortawesome/pro-duotone-svg-icons';
import { faClapperboard as falClapperboard }   from '@fortawesome/pro-light-svg-icons';
import { faClapperboard as farClapperboard }   from '@fortawesome/pro-regular-svg-icons';
import { faClapperboard as fasClapperboard }   from '@fortawesome/pro-solid-svg-icons';
import { faClapperboardPlay as fadClapperboardPlay }   from '@fortawesome/pro-duotone-svg-icons';
import { faClapperboardPlay as falClapperboardPlay }   from '@fortawesome/pro-light-svg-icons';
import { faClapperboardPlay as farClapperboardPlay }   from '@fortawesome/pro-regular-svg-icons';
import { faClapperboardPlay as fasClapperboardPlay }   from '@fortawesome/pro-solid-svg-icons';
import { faClarinet as fadClarinet }   from '@fortawesome/pro-duotone-svg-icons';
import { faClarinet as falClarinet }   from '@fortawesome/pro-light-svg-icons';
import { faClarinet as farClarinet }   from '@fortawesome/pro-regular-svg-icons';
import { faClarinet as fasClarinet }   from '@fortawesome/pro-solid-svg-icons';
import { faClawMarks as fadClawMarks }   from '@fortawesome/pro-duotone-svg-icons';
import { faClawMarks as falClawMarks }   from '@fortawesome/pro-light-svg-icons';
import { faClawMarks as farClawMarks }   from '@fortawesome/pro-regular-svg-icons';
import { faClawMarks as fasClawMarks }   from '@fortawesome/pro-solid-svg-icons';
import { faClinicMedical as fadClinicMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faClinicMedical as falClinicMedical }   from '@fortawesome/pro-light-svg-icons';
import { faClinicMedical as farClinicMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faClinicMedical as fasClinicMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faClipboard as fadClipboard }   from '@fortawesome/pro-duotone-svg-icons';
import { faClipboard as falClipboard }   from '@fortawesome/pro-light-svg-icons';
import { faClipboard as farClipboard }   from '@fortawesome/pro-regular-svg-icons';
import { faClipboard as fasClipboard }   from '@fortawesome/pro-solid-svg-icons';
import { faClipboardCheck as fadClipboardCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faClipboardCheck as falClipboardCheck }   from '@fortawesome/pro-light-svg-icons';
import { faClipboardCheck as farClipboardCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faClipboardCheck as fasClipboardCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faClipboardList as fadClipboardList }   from '@fortawesome/pro-duotone-svg-icons';
import { faClipboardList as falClipboardList }   from '@fortawesome/pro-light-svg-icons';
import { faClipboardList as farClipboardList }   from '@fortawesome/pro-regular-svg-icons';
import { faClipboardList as fasClipboardList }   from '@fortawesome/pro-solid-svg-icons';
import { faClipboardListCheck as fadClipboardListCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faClipboardListCheck as falClipboardListCheck }   from '@fortawesome/pro-light-svg-icons';
import { faClipboardListCheck as farClipboardListCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faClipboardListCheck as fasClipboardListCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faClipboardMedical as fadClipboardMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faClipboardMedical as falClipboardMedical }   from '@fortawesome/pro-light-svg-icons';
import { faClipboardMedical as farClipboardMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faClipboardMedical as fasClipboardMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faClipboardPrescription as fadClipboardPrescription }   from '@fortawesome/pro-duotone-svg-icons';
import { faClipboardPrescription as falClipboardPrescription }   from '@fortawesome/pro-light-svg-icons';
import { faClipboardPrescription as farClipboardPrescription }   from '@fortawesome/pro-regular-svg-icons';
import { faClipboardPrescription as fasClipboardPrescription }   from '@fortawesome/pro-solid-svg-icons';
import { faClipboardQuestion as fadClipboardQuestion }   from '@fortawesome/pro-duotone-svg-icons';
import { faClipboardQuestion as falClipboardQuestion }   from '@fortawesome/pro-light-svg-icons';
import { faClipboardQuestion as farClipboardQuestion }   from '@fortawesome/pro-regular-svg-icons';
import { faClipboardQuestion as fasClipboardQuestion }   from '@fortawesome/pro-solid-svg-icons';
import { faClipboardUser as fadClipboardUser }   from '@fortawesome/pro-duotone-svg-icons';
import { faClipboardUser as falClipboardUser }   from '@fortawesome/pro-light-svg-icons';
import { faClipboardUser as farClipboardUser }   from '@fortawesome/pro-regular-svg-icons';
import { faClipboardUser as fasClipboardUser }   from '@fortawesome/pro-solid-svg-icons';
import { faClock as fadClock }   from '@fortawesome/pro-duotone-svg-icons';
import { faClock as falClock }   from '@fortawesome/pro-light-svg-icons';
import { faClock as farClock }   from '@fortawesome/pro-regular-svg-icons';
import { faClock as fasClock }   from '@fortawesome/pro-solid-svg-icons';
import { faClockDesk as fadClockDesk }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockDesk as falClockDesk }   from '@fortawesome/pro-light-svg-icons';
import { faClockDesk as farClockDesk }   from '@fortawesome/pro-regular-svg-icons';
import { faClockDesk as fasClockDesk }   from '@fortawesome/pro-solid-svg-icons';
import { faClockEight as fadClockEight }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockEight as falClockEight }   from '@fortawesome/pro-light-svg-icons';
import { faClockEight as farClockEight }   from '@fortawesome/pro-regular-svg-icons';
import { faClockEight as fasClockEight }   from '@fortawesome/pro-solid-svg-icons';
import { faClockEightThirty as fadClockEightThirty }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockEightThirty as falClockEightThirty }   from '@fortawesome/pro-light-svg-icons';
import { faClockEightThirty as farClockEightThirty }   from '@fortawesome/pro-regular-svg-icons';
import { faClockEightThirty as fasClockEightThirty }   from '@fortawesome/pro-solid-svg-icons';
import { faClockEleven as fadClockEleven }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockEleven as falClockEleven }   from '@fortawesome/pro-light-svg-icons';
import { faClockEleven as farClockEleven }   from '@fortawesome/pro-regular-svg-icons';
import { faClockEleven as fasClockEleven }   from '@fortawesome/pro-solid-svg-icons';
import { faClockElevenThirty as fadClockElevenThirty }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockElevenThirty as falClockElevenThirty }   from '@fortawesome/pro-light-svg-icons';
import { faClockElevenThirty as farClockElevenThirty }   from '@fortawesome/pro-regular-svg-icons';
import { faClockElevenThirty as fasClockElevenThirty }   from '@fortawesome/pro-solid-svg-icons';
import { faClockFive as fadClockFive }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockFive as falClockFive }   from '@fortawesome/pro-light-svg-icons';
import { faClockFive as farClockFive }   from '@fortawesome/pro-regular-svg-icons';
import { faClockFive as fasClockFive }   from '@fortawesome/pro-solid-svg-icons';
import { faClockFiveThirty as fadClockFiveThirty }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockFiveThirty as falClockFiveThirty }   from '@fortawesome/pro-light-svg-icons';
import { faClockFiveThirty as farClockFiveThirty }   from '@fortawesome/pro-regular-svg-icons';
import { faClockFiveThirty as fasClockFiveThirty }   from '@fortawesome/pro-solid-svg-icons';
import { faClockFour as fadClockFour }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockFour as falClockFour }   from '@fortawesome/pro-light-svg-icons';
import { faClockFour as farClockFour }   from '@fortawesome/pro-regular-svg-icons';
import { faClockFour as fasClockFour }   from '@fortawesome/pro-solid-svg-icons';
import { faClockFourThirty as fadClockFourThirty }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockFourThirty as falClockFourThirty }   from '@fortawesome/pro-light-svg-icons';
import { faClockFourThirty as farClockFourThirty }   from '@fortawesome/pro-regular-svg-icons';
import { faClockFourThirty as fasClockFourThirty }   from '@fortawesome/pro-solid-svg-icons';
import { faClockNine as fadClockNine }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockNine as falClockNine }   from '@fortawesome/pro-light-svg-icons';
import { faClockNine as farClockNine }   from '@fortawesome/pro-regular-svg-icons';
import { faClockNine as fasClockNine }   from '@fortawesome/pro-solid-svg-icons';
import { faClockNineThirty as fadClockNineThirty }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockNineThirty as falClockNineThirty }   from '@fortawesome/pro-light-svg-icons';
import { faClockNineThirty as farClockNineThirty }   from '@fortawesome/pro-regular-svg-icons';
import { faClockNineThirty as fasClockNineThirty }   from '@fortawesome/pro-solid-svg-icons';
import { faClockOne as fadClockOne }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockOne as falClockOne }   from '@fortawesome/pro-light-svg-icons';
import { faClockOne as farClockOne }   from '@fortawesome/pro-regular-svg-icons';
import { faClockOne as fasClockOne }   from '@fortawesome/pro-solid-svg-icons';
import { faClockOneThirty as fadClockOneThirty }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockOneThirty as falClockOneThirty }   from '@fortawesome/pro-light-svg-icons';
import { faClockOneThirty as farClockOneThirty }   from '@fortawesome/pro-regular-svg-icons';
import { faClockOneThirty as fasClockOneThirty }   from '@fortawesome/pro-solid-svg-icons';
import { faClockRotateLeft as fadClockRotateLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockRotateLeft as falClockRotateLeft }   from '@fortawesome/pro-light-svg-icons';
import { faClockRotateLeft as farClockRotateLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faClockRotateLeft as fasClockRotateLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faClockSeven as fadClockSeven }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockSeven as falClockSeven }   from '@fortawesome/pro-light-svg-icons';
import { faClockSeven as farClockSeven }   from '@fortawesome/pro-regular-svg-icons';
import { faClockSeven as fasClockSeven }   from '@fortawesome/pro-solid-svg-icons';
import { faClockSevenThirty as fadClockSevenThirty }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockSevenThirty as falClockSevenThirty }   from '@fortawesome/pro-light-svg-icons';
import { faClockSevenThirty as farClockSevenThirty }   from '@fortawesome/pro-regular-svg-icons';
import { faClockSevenThirty as fasClockSevenThirty }   from '@fortawesome/pro-solid-svg-icons';
import { faClockSix as fadClockSix }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockSix as falClockSix }   from '@fortawesome/pro-light-svg-icons';
import { faClockSix as farClockSix }   from '@fortawesome/pro-regular-svg-icons';
import { faClockSix as fasClockSix }   from '@fortawesome/pro-solid-svg-icons';
import { faClockSixThirty as fadClockSixThirty }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockSixThirty as falClockSixThirty }   from '@fortawesome/pro-light-svg-icons';
import { faClockSixThirty as farClockSixThirty }   from '@fortawesome/pro-regular-svg-icons';
import { faClockSixThirty as fasClockSixThirty }   from '@fortawesome/pro-solid-svg-icons';
import { faClockTen as fadClockTen }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockTen as falClockTen }   from '@fortawesome/pro-light-svg-icons';
import { faClockTen as farClockTen }   from '@fortawesome/pro-regular-svg-icons';
import { faClockTen as fasClockTen }   from '@fortawesome/pro-solid-svg-icons';
import { faClockTenThirty as fadClockTenThirty }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockTenThirty as falClockTenThirty }   from '@fortawesome/pro-light-svg-icons';
import { faClockTenThirty as farClockTenThirty }   from '@fortawesome/pro-regular-svg-icons';
import { faClockTenThirty as fasClockTenThirty }   from '@fortawesome/pro-solid-svg-icons';
import { faClockThree as fadClockThree }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockThree as falClockThree }   from '@fortawesome/pro-light-svg-icons';
import { faClockThree as farClockThree }   from '@fortawesome/pro-regular-svg-icons';
import { faClockThree as fasClockThree }   from '@fortawesome/pro-solid-svg-icons';
import { faClockThreeThirty as fadClockThreeThirty }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockThreeThirty as falClockThreeThirty }   from '@fortawesome/pro-light-svg-icons';
import { faClockThreeThirty as farClockThreeThirty }   from '@fortawesome/pro-regular-svg-icons';
import { faClockThreeThirty as fasClockThreeThirty }   from '@fortawesome/pro-solid-svg-icons';
import { faClockTwelve as fadClockTwelve }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockTwelve as falClockTwelve }   from '@fortawesome/pro-light-svg-icons';
import { faClockTwelve as farClockTwelve }   from '@fortawesome/pro-regular-svg-icons';
import { faClockTwelve as fasClockTwelve }   from '@fortawesome/pro-solid-svg-icons';
import { faClockTwelveThirty as fadClockTwelveThirty }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockTwelveThirty as falClockTwelveThirty }   from '@fortawesome/pro-light-svg-icons';
import { faClockTwelveThirty as farClockTwelveThirty }   from '@fortawesome/pro-regular-svg-icons';
import { faClockTwelveThirty as fasClockTwelveThirty }   from '@fortawesome/pro-solid-svg-icons';
import { faClockTwo as fadClockTwo }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockTwo as falClockTwo }   from '@fortawesome/pro-light-svg-icons';
import { faClockTwo as farClockTwo }   from '@fortawesome/pro-regular-svg-icons';
import { faClockTwo as fasClockTwo }   from '@fortawesome/pro-solid-svg-icons';
import { faClockTwoThirty as fadClockTwoThirty }   from '@fortawesome/pro-duotone-svg-icons';
import { faClockTwoThirty as falClockTwoThirty }   from '@fortawesome/pro-light-svg-icons';
import { faClockTwoThirty as farClockTwoThirty }   from '@fortawesome/pro-regular-svg-icons';
import { faClockTwoThirty as fasClockTwoThirty }   from '@fortawesome/pro-solid-svg-icons';
import { faClone as fadClone }   from '@fortawesome/pro-duotone-svg-icons';
import { faClone as falClone }   from '@fortawesome/pro-light-svg-icons';
import { faClone as farClone }   from '@fortawesome/pro-regular-svg-icons';
import { faClone as fasClone }   from '@fortawesome/pro-solid-svg-icons';
import { faClose as fadClose }   from '@fortawesome/pro-duotone-svg-icons';
import { faClose as falClose }   from '@fortawesome/pro-light-svg-icons';
import { faClose as farClose }   from '@fortawesome/pro-regular-svg-icons';
import { faClose as fasClose }   from '@fortawesome/pro-solid-svg-icons';
import { faClosedCaptioning as fadClosedCaptioning }   from '@fortawesome/pro-duotone-svg-icons';
import { faClosedCaptioning as falClosedCaptioning }   from '@fortawesome/pro-light-svg-icons';
import { faClosedCaptioning as farClosedCaptioning }   from '@fortawesome/pro-regular-svg-icons';
import { faClosedCaptioning as fasClosedCaptioning }   from '@fortawesome/pro-solid-svg-icons';
import { faClosedCaptioningSlash as fadClosedCaptioningSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faClosedCaptioningSlash as falClosedCaptioningSlash }   from '@fortawesome/pro-light-svg-icons';
import { faClosedCaptioningSlash as farClosedCaptioningSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faClosedCaptioningSlash as fasClosedCaptioningSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faClothesHanger as fadClothesHanger }   from '@fortawesome/pro-duotone-svg-icons';
import { faClothesHanger as falClothesHanger }   from '@fortawesome/pro-light-svg-icons';
import { faClothesHanger as farClothesHanger }   from '@fortawesome/pro-regular-svg-icons';
import { faClothesHanger as fasClothesHanger }   from '@fortawesome/pro-solid-svg-icons';
import { faCloud as fadCloud }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloud as falCloud }   from '@fortawesome/pro-light-svg-icons';
import { faCloud as farCloud }   from '@fortawesome/pro-regular-svg-icons';
import { faCloud as fasCloud }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudArrowDown as fadCloudArrowDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudArrowDown as falCloudArrowDown }   from '@fortawesome/pro-light-svg-icons';
import { faCloudArrowDown as farCloudArrowDown }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudArrowDown as fasCloudArrowDown }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudArrowUp as fadCloudArrowUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudArrowUp as falCloudArrowUp }   from '@fortawesome/pro-light-svg-icons';
import { faCloudArrowUp as farCloudArrowUp }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudArrowUp as fasCloudArrowUp }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudBolt as fadCloudBolt }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudBolt as falCloudBolt }   from '@fortawesome/pro-light-svg-icons';
import { faCloudBolt as farCloudBolt }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudBolt as fasCloudBolt }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudBoltMoon as fadCloudBoltMoon }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudBoltMoon as falCloudBoltMoon }   from '@fortawesome/pro-light-svg-icons';
import { faCloudBoltMoon as farCloudBoltMoon }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudBoltMoon as fasCloudBoltMoon }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudBoltSun as fadCloudBoltSun }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudBoltSun as falCloudBoltSun }   from '@fortawesome/pro-light-svg-icons';
import { faCloudBoltSun as farCloudBoltSun }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudBoltSun as fasCloudBoltSun }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudCheck as fadCloudCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudCheck as falCloudCheck }   from '@fortawesome/pro-light-svg-icons';
import { faCloudCheck as farCloudCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudCheck as fasCloudCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudDownload as fadCloudDownload }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudDownload as falCloudDownload }   from '@fortawesome/pro-light-svg-icons';
import { faCloudDownload as farCloudDownload }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudDownload as fasCloudDownload }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudDownloadAlt as fadCloudDownloadAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudDownloadAlt as falCloudDownloadAlt }   from '@fortawesome/pro-light-svg-icons';
import { faCloudDownloadAlt as farCloudDownloadAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudDownloadAlt as fasCloudDownloadAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudDrizzle as fadCloudDrizzle }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudDrizzle as falCloudDrizzle }   from '@fortawesome/pro-light-svg-icons';
import { faCloudDrizzle as farCloudDrizzle }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudDrizzle as fasCloudDrizzle }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudExclamation as fadCloudExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudExclamation as falCloudExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faCloudExclamation as farCloudExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudExclamation as fasCloudExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudFog as fadCloudFog }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudFog as falCloudFog }   from '@fortawesome/pro-light-svg-icons';
import { faCloudFog as farCloudFog }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudFog as fasCloudFog }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudHail as fadCloudHail }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudHail as falCloudHail }   from '@fortawesome/pro-light-svg-icons';
import { faCloudHail as farCloudHail }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudHail as fasCloudHail }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudHailMixed as fadCloudHailMixed }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudHailMixed as falCloudHailMixed }   from '@fortawesome/pro-light-svg-icons';
import { faCloudHailMixed as farCloudHailMixed }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudHailMixed as fasCloudHailMixed }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudMeatball as fadCloudMeatball }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudMeatball as falCloudMeatball }   from '@fortawesome/pro-light-svg-icons';
import { faCloudMeatball as farCloudMeatball }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudMeatball as fasCloudMeatball }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudMinus as fadCloudMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudMinus as falCloudMinus }   from '@fortawesome/pro-light-svg-icons';
import { faCloudMinus as farCloudMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudMinus as fasCloudMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudMoon as fadCloudMoon }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudMoon as falCloudMoon }   from '@fortawesome/pro-light-svg-icons';
import { faCloudMoon as farCloudMoon }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudMoon as fasCloudMoon }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudMoonRain as fadCloudMoonRain }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudMoonRain as falCloudMoonRain }   from '@fortawesome/pro-light-svg-icons';
import { faCloudMoonRain as farCloudMoonRain }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudMoonRain as fasCloudMoonRain }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudMusic as fadCloudMusic }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudMusic as falCloudMusic }   from '@fortawesome/pro-light-svg-icons';
import { faCloudMusic as farCloudMusic }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudMusic as fasCloudMusic }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudPlus as fadCloudPlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudPlus as falCloudPlus }   from '@fortawesome/pro-light-svg-icons';
import { faCloudPlus as farCloudPlus }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudPlus as fasCloudPlus }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudQuestion as fadCloudQuestion }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudQuestion as falCloudQuestion }   from '@fortawesome/pro-light-svg-icons';
import { faCloudQuestion as farCloudQuestion }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudQuestion as fasCloudQuestion }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudRain as fadCloudRain }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudRain as falCloudRain }   from '@fortawesome/pro-light-svg-icons';
import { faCloudRain as farCloudRain }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudRain as fasCloudRain }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudRainbow as fadCloudRainbow }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudRainbow as falCloudRainbow }   from '@fortawesome/pro-light-svg-icons';
import { faCloudRainbow as farCloudRainbow }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudRainbow as fasCloudRainbow }   from '@fortawesome/pro-solid-svg-icons';
import { faClouds as fadClouds }   from '@fortawesome/pro-duotone-svg-icons';
import { faClouds as falClouds }   from '@fortawesome/pro-light-svg-icons';
import { faClouds as farClouds }   from '@fortawesome/pro-regular-svg-icons';
import { faClouds as fasClouds }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudShowers as fadCloudShowers }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudShowers as falCloudShowers }   from '@fortawesome/pro-light-svg-icons';
import { faCloudShowers as farCloudShowers }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudShowers as fasCloudShowers }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudShowersHeavy as fadCloudShowersHeavy }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudShowersHeavy as falCloudShowersHeavy }   from '@fortawesome/pro-light-svg-icons';
import { faCloudShowersHeavy as farCloudShowersHeavy }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudShowersHeavy as fasCloudShowersHeavy }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudShowersWater as fadCloudShowersWater }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudShowersWater as falCloudShowersWater }   from '@fortawesome/pro-light-svg-icons';
import { faCloudShowersWater as farCloudShowersWater }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudShowersWater as fasCloudShowersWater }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudSlash as fadCloudSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudSlash as falCloudSlash }   from '@fortawesome/pro-light-svg-icons';
import { faCloudSlash as farCloudSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudSlash as fasCloudSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudSleet as fadCloudSleet }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudSleet as falCloudSleet }   from '@fortawesome/pro-light-svg-icons';
import { faCloudSleet as farCloudSleet }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudSleet as fasCloudSleet }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudsMoon as fadCloudsMoon }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudsMoon as falCloudsMoon }   from '@fortawesome/pro-light-svg-icons';
import { faCloudsMoon as farCloudsMoon }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudsMoon as fasCloudsMoon }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudSnow as fadCloudSnow }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudSnow as falCloudSnow }   from '@fortawesome/pro-light-svg-icons';
import { faCloudSnow as farCloudSnow }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudSnow as fasCloudSnow }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudsSun as fadCloudsSun }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudsSun as falCloudsSun }   from '@fortawesome/pro-light-svg-icons';
import { faCloudsSun as farCloudsSun }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudsSun as fasCloudsSun }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudSun as fadCloudSun }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudSun as falCloudSun }   from '@fortawesome/pro-light-svg-icons';
import { faCloudSun as farCloudSun }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudSun as fasCloudSun }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudSunRain as fadCloudSunRain }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudSunRain as falCloudSunRain }   from '@fortawesome/pro-light-svg-icons';
import { faCloudSunRain as farCloudSunRain }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudSunRain as fasCloudSunRain }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudUpload as fadCloudUpload }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudUpload as falCloudUpload }   from '@fortawesome/pro-light-svg-icons';
import { faCloudUpload as farCloudUpload }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudUpload as fasCloudUpload }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudUploadAlt as fadCloudUploadAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudUploadAlt as falCloudUploadAlt }   from '@fortawesome/pro-light-svg-icons';
import { faCloudUploadAlt as farCloudUploadAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudUploadAlt as fasCloudUploadAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudWord as fadCloudWord }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudWord as falCloudWord }   from '@fortawesome/pro-light-svg-icons';
import { faCloudWord as farCloudWord }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudWord as fasCloudWord }   from '@fortawesome/pro-solid-svg-icons';
import { faCloudXmark as fadCloudXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faCloudXmark as falCloudXmark }   from '@fortawesome/pro-light-svg-icons';
import { faCloudXmark as farCloudXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faCloudXmark as fasCloudXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faClover as fadClover }   from '@fortawesome/pro-duotone-svg-icons';
import { faClover as falClover }   from '@fortawesome/pro-light-svg-icons';
import { faClover as farClover }   from '@fortawesome/pro-regular-svg-icons';
import { faClover as fasClover }   from '@fortawesome/pro-solid-svg-icons';
import { faClub as fadClub }   from '@fortawesome/pro-duotone-svg-icons';
import { faClub as falClub }   from '@fortawesome/pro-light-svg-icons';
import { faClub as farClub }   from '@fortawesome/pro-regular-svg-icons';
import { faClub as fasClub }   from '@fortawesome/pro-solid-svg-icons';
import { faCny as fadCny }   from '@fortawesome/pro-duotone-svg-icons';
import { faCny as falCny }   from '@fortawesome/pro-light-svg-icons';
import { faCny as farCny }   from '@fortawesome/pro-regular-svg-icons';
import { faCny as fasCny }   from '@fortawesome/pro-solid-svg-icons';
import { faCocktail as fadCocktail }   from '@fortawesome/pro-duotone-svg-icons';
import { faCocktail as falCocktail }   from '@fortawesome/pro-light-svg-icons';
import { faCocktail as farCocktail }   from '@fortawesome/pro-regular-svg-icons';
import { faCocktail as fasCocktail }   from '@fortawesome/pro-solid-svg-icons';
import { faCoconut as fadCoconut }   from '@fortawesome/pro-duotone-svg-icons';
import { faCoconut as falCoconut }   from '@fortawesome/pro-light-svg-icons';
import { faCoconut as farCoconut }   from '@fortawesome/pro-regular-svg-icons';
import { faCoconut as fasCoconut }   from '@fortawesome/pro-solid-svg-icons';
import { faCode as fadCode }   from '@fortawesome/pro-duotone-svg-icons';
import { faCode as falCode }   from '@fortawesome/pro-light-svg-icons';
import { faCode as farCode }   from '@fortawesome/pro-regular-svg-icons';
import { faCode as fasCode }   from '@fortawesome/pro-solid-svg-icons';
import { faCodeBranch as fadCodeBranch }   from '@fortawesome/pro-duotone-svg-icons';
import { faCodeBranch as falCodeBranch }   from '@fortawesome/pro-light-svg-icons';
import { faCodeBranch as farCodeBranch }   from '@fortawesome/pro-regular-svg-icons';
import { faCodeBranch as fasCodeBranch }   from '@fortawesome/pro-solid-svg-icons';
import { faCodeCommit as fadCodeCommit }   from '@fortawesome/pro-duotone-svg-icons';
import { faCodeCommit as falCodeCommit }   from '@fortawesome/pro-light-svg-icons';
import { faCodeCommit as farCodeCommit }   from '@fortawesome/pro-regular-svg-icons';
import { faCodeCommit as fasCodeCommit }   from '@fortawesome/pro-solid-svg-icons';
import { faCodeCompare as fadCodeCompare }   from '@fortawesome/pro-duotone-svg-icons';
import { faCodeCompare as falCodeCompare }   from '@fortawesome/pro-light-svg-icons';
import { faCodeCompare as farCodeCompare }   from '@fortawesome/pro-regular-svg-icons';
import { faCodeCompare as fasCodeCompare }   from '@fortawesome/pro-solid-svg-icons';
import { faCodeFork as fadCodeFork }   from '@fortawesome/pro-duotone-svg-icons';
import { faCodeFork as falCodeFork }   from '@fortawesome/pro-light-svg-icons';
import { faCodeFork as farCodeFork }   from '@fortawesome/pro-regular-svg-icons';
import { faCodeFork as fasCodeFork }   from '@fortawesome/pro-solid-svg-icons';
import { faCodeMerge as fadCodeMerge }   from '@fortawesome/pro-duotone-svg-icons';
import { faCodeMerge as falCodeMerge }   from '@fortawesome/pro-light-svg-icons';
import { faCodeMerge as farCodeMerge }   from '@fortawesome/pro-regular-svg-icons';
import { faCodeMerge as fasCodeMerge }   from '@fortawesome/pro-solid-svg-icons';
import { faCodePullRequest as fadCodePullRequest }   from '@fortawesome/pro-duotone-svg-icons';
import { faCodePullRequest as falCodePullRequest }   from '@fortawesome/pro-light-svg-icons';
import { faCodePullRequest as farCodePullRequest }   from '@fortawesome/pro-regular-svg-icons';
import { faCodePullRequest as fasCodePullRequest }   from '@fortawesome/pro-solid-svg-icons';
import { faCodePullRequestClosed as fadCodePullRequestClosed }   from '@fortawesome/pro-duotone-svg-icons';
import { faCodePullRequestClosed as falCodePullRequestClosed }   from '@fortawesome/pro-light-svg-icons';
import { faCodePullRequestClosed as farCodePullRequestClosed }   from '@fortawesome/pro-regular-svg-icons';
import { faCodePullRequestClosed as fasCodePullRequestClosed }   from '@fortawesome/pro-solid-svg-icons';
import { faCodePullRequestDraft as fadCodePullRequestDraft }   from '@fortawesome/pro-duotone-svg-icons';
import { faCodePullRequestDraft as falCodePullRequestDraft }   from '@fortawesome/pro-light-svg-icons';
import { faCodePullRequestDraft as farCodePullRequestDraft }   from '@fortawesome/pro-regular-svg-icons';
import { faCodePullRequestDraft as fasCodePullRequestDraft }   from '@fortawesome/pro-solid-svg-icons';
import { faCodeSimple as fadCodeSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faCodeSimple as falCodeSimple }   from '@fortawesome/pro-light-svg-icons';
import { faCodeSimple as farCodeSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faCodeSimple as fasCodeSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faCoffee as fadCoffee }   from '@fortawesome/pro-duotone-svg-icons';
import { faCoffee as falCoffee }   from '@fortawesome/pro-light-svg-icons';
import { faCoffee as farCoffee }   from '@fortawesome/pro-regular-svg-icons';
import { faCoffee as fasCoffee }   from '@fortawesome/pro-solid-svg-icons';
import { faCoffeeBean as fadCoffeeBean }   from '@fortawesome/pro-duotone-svg-icons';
import { faCoffeeBean as falCoffeeBean }   from '@fortawesome/pro-light-svg-icons';
import { faCoffeeBean as farCoffeeBean }   from '@fortawesome/pro-regular-svg-icons';
import { faCoffeeBean as fasCoffeeBean }   from '@fortawesome/pro-solid-svg-icons';
import { faCoffeeBeans as fadCoffeeBeans }   from '@fortawesome/pro-duotone-svg-icons';
import { faCoffeeBeans as falCoffeeBeans }   from '@fortawesome/pro-light-svg-icons';
import { faCoffeeBeans as farCoffeeBeans }   from '@fortawesome/pro-regular-svg-icons';
import { faCoffeeBeans as fasCoffeeBeans }   from '@fortawesome/pro-solid-svg-icons';
import { faCoffeePot as fadCoffeePot }   from '@fortawesome/pro-duotone-svg-icons';
import { faCoffeePot as falCoffeePot }   from '@fortawesome/pro-light-svg-icons';
import { faCoffeePot as farCoffeePot }   from '@fortawesome/pro-regular-svg-icons';
import { faCoffeePot as fasCoffeePot }   from '@fortawesome/pro-solid-svg-icons';
import { faCoffeeTogo as fadCoffeeTogo }   from '@fortawesome/pro-duotone-svg-icons';
import { faCoffeeTogo as falCoffeeTogo }   from '@fortawesome/pro-light-svg-icons';
import { faCoffeeTogo as farCoffeeTogo }   from '@fortawesome/pro-regular-svg-icons';
import { faCoffeeTogo as fasCoffeeTogo }   from '@fortawesome/pro-solid-svg-icons';
import { faCoffin as fadCoffin }   from '@fortawesome/pro-duotone-svg-icons';
import { faCoffin as falCoffin }   from '@fortawesome/pro-light-svg-icons';
import { faCoffin as farCoffin }   from '@fortawesome/pro-regular-svg-icons';
import { faCoffin as fasCoffin }   from '@fortawesome/pro-solid-svg-icons';
import { faCoffinCross as fadCoffinCross }   from '@fortawesome/pro-duotone-svg-icons';
import { faCoffinCross as falCoffinCross }   from '@fortawesome/pro-light-svg-icons';
import { faCoffinCross as farCoffinCross }   from '@fortawesome/pro-regular-svg-icons';
import { faCoffinCross as fasCoffinCross }   from '@fortawesome/pro-solid-svg-icons';
import { faCog as fadCog }   from '@fortawesome/pro-duotone-svg-icons';
import { faCog as falCog }   from '@fortawesome/pro-light-svg-icons';
import { faCog as farCog }   from '@fortawesome/pro-regular-svg-icons';
import { faCog as fasCog }   from '@fortawesome/pro-solid-svg-icons';
import { faCogs as fadCogs }   from '@fortawesome/pro-duotone-svg-icons';
import { faCogs as falCogs }   from '@fortawesome/pro-light-svg-icons';
import { faCogs as farCogs }   from '@fortawesome/pro-regular-svg-icons';
import { faCogs as fasCogs }   from '@fortawesome/pro-solid-svg-icons';
import { faCoin as fadCoin }   from '@fortawesome/pro-duotone-svg-icons';
import { faCoin as falCoin }   from '@fortawesome/pro-light-svg-icons';
import { faCoin as farCoin }   from '@fortawesome/pro-regular-svg-icons';
import { faCoin as fasCoin }   from '@fortawesome/pro-solid-svg-icons';
import { faCoinBlank as fadCoinBlank }   from '@fortawesome/pro-duotone-svg-icons';
import { faCoinBlank as falCoinBlank }   from '@fortawesome/pro-light-svg-icons';
import { faCoinBlank as farCoinBlank }   from '@fortawesome/pro-regular-svg-icons';
import { faCoinBlank as fasCoinBlank }   from '@fortawesome/pro-solid-svg-icons';
import { faCoinFront as fadCoinFront }   from '@fortawesome/pro-duotone-svg-icons';
import { faCoinFront as falCoinFront }   from '@fortawesome/pro-light-svg-icons';
import { faCoinFront as farCoinFront }   from '@fortawesome/pro-regular-svg-icons';
import { faCoinFront as fasCoinFront }   from '@fortawesome/pro-solid-svg-icons';
import { faCoins as fadCoins }   from '@fortawesome/pro-duotone-svg-icons';
import { faCoins as falCoins }   from '@fortawesome/pro-light-svg-icons';
import { faCoins as farCoins }   from '@fortawesome/pro-regular-svg-icons';
import { faCoins as fasCoins }   from '@fortawesome/pro-solid-svg-icons';
import { faCoinVertical as fadCoinVertical }   from '@fortawesome/pro-duotone-svg-icons';
import { faCoinVertical as falCoinVertical }   from '@fortawesome/pro-light-svg-icons';
import { faCoinVertical as farCoinVertical }   from '@fortawesome/pro-regular-svg-icons';
import { faCoinVertical as fasCoinVertical }   from '@fortawesome/pro-solid-svg-icons';
import { faColon as fadColon }   from '@fortawesome/pro-duotone-svg-icons';
import { faColon as falColon }   from '@fortawesome/pro-light-svg-icons';
import { faColon as farColon }   from '@fortawesome/pro-regular-svg-icons';
import { faColon as fasColon }   from '@fortawesome/pro-solid-svg-icons';
import { faColonSign as fadColonSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faColonSign as falColonSign }   from '@fortawesome/pro-light-svg-icons';
import { faColonSign as farColonSign }   from '@fortawesome/pro-regular-svg-icons';
import { faColonSign as fasColonSign }   from '@fortawesome/pro-solid-svg-icons';
import { faColumns as fadColumns }   from '@fortawesome/pro-duotone-svg-icons';
import { faColumns as falColumns }   from '@fortawesome/pro-light-svg-icons';
import { faColumns as farColumns }   from '@fortawesome/pro-regular-svg-icons';
import { faColumns as fasColumns }   from '@fortawesome/pro-solid-svg-icons';
import { faColumns3 as fadColumns3 }   from '@fortawesome/pro-duotone-svg-icons';
import { faColumns3 as falColumns3 }   from '@fortawesome/pro-light-svg-icons';
import { faColumns3 as farColumns3 }   from '@fortawesome/pro-regular-svg-icons';
import { faColumns3 as fasColumns3 }   from '@fortawesome/pro-solid-svg-icons';
import { faComet as fadComet }   from '@fortawesome/pro-duotone-svg-icons';
import { faComet as falComet }   from '@fortawesome/pro-light-svg-icons';
import { faComet as farComet }   from '@fortawesome/pro-regular-svg-icons';
import { faComet as fasComet }   from '@fortawesome/pro-solid-svg-icons';
import { faComma as fadComma }   from '@fortawesome/pro-duotone-svg-icons';
import { faComma as falComma }   from '@fortawesome/pro-light-svg-icons';
import { faComma as farComma }   from '@fortawesome/pro-regular-svg-icons';
import { faComma as fasComma }   from '@fortawesome/pro-solid-svg-icons';
import { faCommand as fadCommand }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommand as falCommand }   from '@fortawesome/pro-light-svg-icons';
import { faCommand as farCommand }   from '@fortawesome/pro-regular-svg-icons';
import { faCommand as fasCommand }   from '@fortawesome/pro-solid-svg-icons';
import { faComment as fadComment }   from '@fortawesome/pro-duotone-svg-icons';
import { faComment as falComment }   from '@fortawesome/pro-light-svg-icons';
import { faComment as farComment }   from '@fortawesome/pro-regular-svg-icons';
import { faComment as fasComment }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentAlt as fadCommentAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentAlt as falCommentAlt }   from '@fortawesome/pro-light-svg-icons';
import { faCommentAlt as farCommentAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentAlt as fasCommentAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentAltArrowDown as fadCommentAltArrowDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentAltArrowDown as falCommentAltArrowDown }   from '@fortawesome/pro-light-svg-icons';
import { faCommentAltArrowDown as farCommentAltArrowDown }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentAltArrowDown as fasCommentAltArrowDown }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentAltArrowUp as fadCommentAltArrowUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentAltArrowUp as falCommentAltArrowUp }   from '@fortawesome/pro-light-svg-icons';
import { faCommentAltArrowUp as farCommentAltArrowUp }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentAltArrowUp as fasCommentAltArrowUp }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentAltCaptions as fadCommentAltCaptions }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentAltCaptions as falCommentAltCaptions }   from '@fortawesome/pro-light-svg-icons';
import { faCommentAltCaptions as farCommentAltCaptions }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentAltCaptions as fasCommentAltCaptions }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentAltCheck as fadCommentAltCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentAltCheck as falCommentAltCheck }   from '@fortawesome/pro-light-svg-icons';
import { faCommentAltCheck as farCommentAltCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentAltCheck as fasCommentAltCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentAltDollar as fadCommentAltDollar }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentAltDollar as falCommentAltDollar }   from '@fortawesome/pro-light-svg-icons';
import { faCommentAltDollar as farCommentAltDollar }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentAltDollar as fasCommentAltDollar }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentAltDots as fadCommentAltDots }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentAltDots as falCommentAltDots }   from '@fortawesome/pro-light-svg-icons';
import { faCommentAltDots as farCommentAltDots }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentAltDots as fasCommentAltDots }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentAltEdit as fadCommentAltEdit }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentAltEdit as falCommentAltEdit }   from '@fortawesome/pro-light-svg-icons';
import { faCommentAltEdit as farCommentAltEdit }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentAltEdit as fasCommentAltEdit }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentAltExclamation as fadCommentAltExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentAltExclamation as falCommentAltExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faCommentAltExclamation as farCommentAltExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentAltExclamation as fasCommentAltExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentAltImage as fadCommentAltImage }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentAltImage as falCommentAltImage }   from '@fortawesome/pro-light-svg-icons';
import { faCommentAltImage as farCommentAltImage }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentAltImage as fasCommentAltImage }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentAltLines as fadCommentAltLines }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentAltLines as falCommentAltLines }   from '@fortawesome/pro-light-svg-icons';
import { faCommentAltLines as farCommentAltLines }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentAltLines as fasCommentAltLines }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentAltMedical as fadCommentAltMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentAltMedical as falCommentAltMedical }   from '@fortawesome/pro-light-svg-icons';
import { faCommentAltMedical as farCommentAltMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentAltMedical as fasCommentAltMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentAltMinus as fadCommentAltMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentAltMinus as falCommentAltMinus }   from '@fortawesome/pro-light-svg-icons';
import { faCommentAltMinus as farCommentAltMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentAltMinus as fasCommentAltMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentAltMusic as fadCommentAltMusic }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentAltMusic as falCommentAltMusic }   from '@fortawesome/pro-light-svg-icons';
import { faCommentAltMusic as farCommentAltMusic }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentAltMusic as fasCommentAltMusic }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentAltPlus as fadCommentAltPlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentAltPlus as falCommentAltPlus }   from '@fortawesome/pro-light-svg-icons';
import { faCommentAltPlus as farCommentAltPlus }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentAltPlus as fasCommentAltPlus }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentAltQuote as fadCommentAltQuote }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentAltQuote as falCommentAltQuote }   from '@fortawesome/pro-light-svg-icons';
import { faCommentAltQuote as farCommentAltQuote }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentAltQuote as fasCommentAltQuote }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentAltSlash as fadCommentAltSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentAltSlash as falCommentAltSlash }   from '@fortawesome/pro-light-svg-icons';
import { faCommentAltSlash as farCommentAltSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentAltSlash as fasCommentAltSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentAltSmile as fadCommentAltSmile }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentAltSmile as falCommentAltSmile }   from '@fortawesome/pro-light-svg-icons';
import { faCommentAltSmile as farCommentAltSmile }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentAltSmile as fasCommentAltSmile }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentAltText as fadCommentAltText }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentAltText as falCommentAltText }   from '@fortawesome/pro-light-svg-icons';
import { faCommentAltText as farCommentAltText }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentAltText as fasCommentAltText }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentAltTimes as fadCommentAltTimes }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentAltTimes as falCommentAltTimes }   from '@fortawesome/pro-light-svg-icons';
import { faCommentAltTimes as farCommentAltTimes }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentAltTimes as fasCommentAltTimes }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentArrowDown as fadCommentArrowDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentArrowDown as falCommentArrowDown }   from '@fortawesome/pro-light-svg-icons';
import { faCommentArrowDown as farCommentArrowDown }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentArrowDown as fasCommentArrowDown }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentArrowUp as fadCommentArrowUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentArrowUp as falCommentArrowUp }   from '@fortawesome/pro-light-svg-icons';
import { faCommentArrowUp as farCommentArrowUp }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentArrowUp as fasCommentArrowUp }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentArrowUpRight as fadCommentArrowUpRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentArrowUpRight as falCommentArrowUpRight }   from '@fortawesome/pro-light-svg-icons';
import { faCommentArrowUpRight as farCommentArrowUpRight }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentArrowUpRight as fasCommentArrowUpRight }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentCaptions as fadCommentCaptions }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentCaptions as falCommentCaptions }   from '@fortawesome/pro-light-svg-icons';
import { faCommentCaptions as farCommentCaptions }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentCaptions as fasCommentCaptions }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentCheck as fadCommentCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentCheck as falCommentCheck }   from '@fortawesome/pro-light-svg-icons';
import { faCommentCheck as farCommentCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentCheck as fasCommentCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentCode as fadCommentCode }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentCode as falCommentCode }   from '@fortawesome/pro-light-svg-icons';
import { faCommentCode as farCommentCode }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentCode as fasCommentCode }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentDollar as fadCommentDollar }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentDollar as falCommentDollar }   from '@fortawesome/pro-light-svg-icons';
import { faCommentDollar as farCommentDollar }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentDollar as fasCommentDollar }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentDots as fadCommentDots }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentDots as falCommentDots }   from '@fortawesome/pro-light-svg-icons';
import { faCommentDots as farCommentDots }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentDots as fasCommentDots }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentEdit as fadCommentEdit }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentEdit as falCommentEdit }   from '@fortawesome/pro-light-svg-icons';
import { faCommentEdit as farCommentEdit }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentEdit as fasCommentEdit }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentExclamation as fadCommentExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentExclamation as falCommentExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faCommentExclamation as farCommentExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentExclamation as fasCommentExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentImage as fadCommentImage }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentImage as falCommentImage }   from '@fortawesome/pro-light-svg-icons';
import { faCommentImage as farCommentImage }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentImage as fasCommentImage }   from '@fortawesome/pro-solid-svg-icons';
import { faCommenting as fadCommenting }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommenting as falCommenting }   from '@fortawesome/pro-light-svg-icons';
import { faCommenting as farCommenting }   from '@fortawesome/pro-regular-svg-icons';
import { faCommenting as fasCommenting }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentLines as fadCommentLines }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentLines as falCommentLines }   from '@fortawesome/pro-light-svg-icons';
import { faCommentLines as farCommentLines }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentLines as fasCommentLines }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentMedical as fadCommentMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentMedical as falCommentMedical }   from '@fortawesome/pro-light-svg-icons';
import { faCommentMedical as farCommentMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentMedical as fasCommentMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentMiddle as fadCommentMiddle }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentMiddle as falCommentMiddle }   from '@fortawesome/pro-light-svg-icons';
import { faCommentMiddle as farCommentMiddle }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentMiddle as fasCommentMiddle }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentMiddleAlt as fadCommentMiddleAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentMiddleAlt as falCommentMiddleAlt }   from '@fortawesome/pro-light-svg-icons';
import { faCommentMiddleAlt as farCommentMiddleAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentMiddleAlt as fasCommentMiddleAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentMiddleTop as fadCommentMiddleTop }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentMiddleTop as falCommentMiddleTop }   from '@fortawesome/pro-light-svg-icons';
import { faCommentMiddleTop as farCommentMiddleTop }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentMiddleTop as fasCommentMiddleTop }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentMiddleTopAlt as fadCommentMiddleTopAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentMiddleTopAlt as falCommentMiddleTopAlt }   from '@fortawesome/pro-light-svg-icons';
import { faCommentMiddleTopAlt as farCommentMiddleTopAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentMiddleTopAlt as fasCommentMiddleTopAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentMinus as fadCommentMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentMinus as falCommentMinus }   from '@fortawesome/pro-light-svg-icons';
import { faCommentMinus as farCommentMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentMinus as fasCommentMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentMusic as fadCommentMusic }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentMusic as falCommentMusic }   from '@fortawesome/pro-light-svg-icons';
import { faCommentMusic as farCommentMusic }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentMusic as fasCommentMusic }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentPen as fadCommentPen }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentPen as falCommentPen }   from '@fortawesome/pro-light-svg-icons';
import { faCommentPen as farCommentPen }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentPen as fasCommentPen }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentPlus as fadCommentPlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentPlus as falCommentPlus }   from '@fortawesome/pro-light-svg-icons';
import { faCommentPlus as farCommentPlus }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentPlus as fasCommentPlus }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentQuestion as fadCommentQuestion }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentQuestion as falCommentQuestion }   from '@fortawesome/pro-light-svg-icons';
import { faCommentQuestion as farCommentQuestion }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentQuestion as fasCommentQuestion }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentQuote as fadCommentQuote }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentQuote as falCommentQuote }   from '@fortawesome/pro-light-svg-icons';
import { faCommentQuote as farCommentQuote }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentQuote as fasCommentQuote }   from '@fortawesome/pro-solid-svg-icons';
import { faComments as fadComments }   from '@fortawesome/pro-duotone-svg-icons';
import { faComments as falComments }   from '@fortawesome/pro-light-svg-icons';
import { faComments as farComments }   from '@fortawesome/pro-regular-svg-icons';
import { faComments as fasComments }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentsAlt as fadCommentsAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentsAlt as falCommentsAlt }   from '@fortawesome/pro-light-svg-icons';
import { faCommentsAlt as farCommentsAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentsAlt as fasCommentsAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentsAltDollar as fadCommentsAltDollar }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentsAltDollar as falCommentsAltDollar }   from '@fortawesome/pro-light-svg-icons';
import { faCommentsAltDollar as farCommentsAltDollar }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentsAltDollar as fasCommentsAltDollar }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentsDollar as fadCommentsDollar }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentsDollar as falCommentsDollar }   from '@fortawesome/pro-light-svg-icons';
import { faCommentsDollar as farCommentsDollar }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentsDollar as fasCommentsDollar }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentSlash as fadCommentSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentSlash as falCommentSlash }   from '@fortawesome/pro-light-svg-icons';
import { faCommentSlash as farCommentSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentSlash as fasCommentSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentSmile as fadCommentSmile }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentSmile as falCommentSmile }   from '@fortawesome/pro-light-svg-icons';
import { faCommentSmile as farCommentSmile }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentSmile as fasCommentSmile }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentSms as fadCommentSms }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentSms as falCommentSms }   from '@fortawesome/pro-light-svg-icons';
import { faCommentSms as farCommentSms }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentSms as fasCommentSms }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentsQuestion as fadCommentsQuestion }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentsQuestion as falCommentsQuestion }   from '@fortawesome/pro-light-svg-icons';
import { faCommentsQuestion as farCommentsQuestion }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentsQuestion as fasCommentsQuestion }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentsQuestionCheck as fadCommentsQuestionCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentsQuestionCheck as falCommentsQuestionCheck }   from '@fortawesome/pro-light-svg-icons';
import { faCommentsQuestionCheck as farCommentsQuestionCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentsQuestionCheck as fasCommentsQuestionCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentText as fadCommentText }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentText as falCommentText }   from '@fortawesome/pro-light-svg-icons';
import { faCommentText as farCommentText }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentText as fasCommentText }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentTimes as fadCommentTimes }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentTimes as falCommentTimes }   from '@fortawesome/pro-light-svg-icons';
import { faCommentTimes as farCommentTimes }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentTimes as fasCommentTimes }   from '@fortawesome/pro-solid-svg-icons';
import { faCommentXmark as fadCommentXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faCommentXmark as falCommentXmark }   from '@fortawesome/pro-light-svg-icons';
import { faCommentXmark as farCommentXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faCommentXmark as fasCommentXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faCompactDisc as fadCompactDisc }   from '@fortawesome/pro-duotone-svg-icons';
import { faCompactDisc as falCompactDisc }   from '@fortawesome/pro-light-svg-icons';
import { faCompactDisc as farCompactDisc }   from '@fortawesome/pro-regular-svg-icons';
import { faCompactDisc as fasCompactDisc }   from '@fortawesome/pro-solid-svg-icons';
import { faCompass as fadCompass }   from '@fortawesome/pro-duotone-svg-icons';
import { faCompass as falCompass }   from '@fortawesome/pro-light-svg-icons';
import { faCompass as farCompass }   from '@fortawesome/pro-regular-svg-icons';
import { faCompass as fasCompass }   from '@fortawesome/pro-solid-svg-icons';
import { faCompassDrafting as fadCompassDrafting }   from '@fortawesome/pro-duotone-svg-icons';
import { faCompassDrafting as falCompassDrafting }   from '@fortawesome/pro-light-svg-icons';
import { faCompassDrafting as farCompassDrafting }   from '@fortawesome/pro-regular-svg-icons';
import { faCompassDrafting as fasCompassDrafting }   from '@fortawesome/pro-solid-svg-icons';
import { faCompassSlash as fadCompassSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faCompassSlash as falCompassSlash }   from '@fortawesome/pro-light-svg-icons';
import { faCompassSlash as farCompassSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faCompassSlash as fasCompassSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faCompress as fadCompress }   from '@fortawesome/pro-duotone-svg-icons';
import { faCompress as falCompress }   from '@fortawesome/pro-light-svg-icons';
import { faCompress as farCompress }   from '@fortawesome/pro-regular-svg-icons';
import { faCompress as fasCompress }   from '@fortawesome/pro-solid-svg-icons';
import { faCompressAlt as fadCompressAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faCompressAlt as falCompressAlt }   from '@fortawesome/pro-light-svg-icons';
import { faCompressAlt as farCompressAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faCompressAlt as fasCompressAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faCompressArrows as fadCompressArrows }   from '@fortawesome/pro-duotone-svg-icons';
import { faCompressArrows as falCompressArrows }   from '@fortawesome/pro-light-svg-icons';
import { faCompressArrows as farCompressArrows }   from '@fortawesome/pro-regular-svg-icons';
import { faCompressArrows as fasCompressArrows }   from '@fortawesome/pro-solid-svg-icons';
import { faCompressArrowsAlt as fadCompressArrowsAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faCompressArrowsAlt as falCompressArrowsAlt }   from '@fortawesome/pro-light-svg-icons';
import { faCompressArrowsAlt as farCompressArrowsAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faCompressArrowsAlt as fasCompressArrowsAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faCompressWide as fadCompressWide }   from '@fortawesome/pro-duotone-svg-icons';
import { faCompressWide as falCompressWide }   from '@fortawesome/pro-light-svg-icons';
import { faCompressWide as farCompressWide }   from '@fortawesome/pro-regular-svg-icons';
import { faCompressWide as fasCompressWide }   from '@fortawesome/pro-solid-svg-icons';
import { faComputer as fadComputer }   from '@fortawesome/pro-duotone-svg-icons';
import { faComputer as falComputer }   from '@fortawesome/pro-light-svg-icons';
import { faComputer as farComputer }   from '@fortawesome/pro-regular-svg-icons';
import { faComputer as fasComputer }   from '@fortawesome/pro-solid-svg-icons';
import { faComputerClassic as fadComputerClassic }   from '@fortawesome/pro-duotone-svg-icons';
import { faComputerClassic as falComputerClassic }   from '@fortawesome/pro-light-svg-icons';
import { faComputerClassic as farComputerClassic }   from '@fortawesome/pro-regular-svg-icons';
import { faComputerClassic as fasComputerClassic }   from '@fortawesome/pro-solid-svg-icons';
import { faComputerMouse as fadComputerMouse }   from '@fortawesome/pro-duotone-svg-icons';
import { faComputerMouse as falComputerMouse }   from '@fortawesome/pro-light-svg-icons';
import { faComputerMouse as farComputerMouse }   from '@fortawesome/pro-regular-svg-icons';
import { faComputerMouse as fasComputerMouse }   from '@fortawesome/pro-solid-svg-icons';
import { faComputerMouseScrollwheel as fadComputerMouseScrollwheel }   from '@fortawesome/pro-duotone-svg-icons';
import { faComputerMouseScrollwheel as falComputerMouseScrollwheel }   from '@fortawesome/pro-light-svg-icons';
import { faComputerMouseScrollwheel as farComputerMouseScrollwheel }   from '@fortawesome/pro-regular-svg-icons';
import { faComputerMouseScrollwheel as fasComputerMouseScrollwheel }   from '@fortawesome/pro-solid-svg-icons';
import { faComputerSpeaker as fadComputerSpeaker }   from '@fortawesome/pro-duotone-svg-icons';
import { faComputerSpeaker as falComputerSpeaker }   from '@fortawesome/pro-light-svg-icons';
import { faComputerSpeaker as farComputerSpeaker }   from '@fortawesome/pro-regular-svg-icons';
import { faComputerSpeaker as fasComputerSpeaker }   from '@fortawesome/pro-solid-svg-icons';
import { faConciergeBell as fadConciergeBell }   from '@fortawesome/pro-duotone-svg-icons';
import { faConciergeBell as falConciergeBell }   from '@fortawesome/pro-light-svg-icons';
import { faConciergeBell as farConciergeBell }   from '@fortawesome/pro-regular-svg-icons';
import { faConciergeBell as fasConciergeBell }   from '@fortawesome/pro-solid-svg-icons';
import { faConstruction as fadConstruction }   from '@fortawesome/pro-duotone-svg-icons';
import { faConstruction as falConstruction }   from '@fortawesome/pro-light-svg-icons';
import { faConstruction as farConstruction }   from '@fortawesome/pro-regular-svg-icons';
import { faConstruction as fasConstruction }   from '@fortawesome/pro-solid-svg-icons';
import { faContactBook as fadContactBook }   from '@fortawesome/pro-duotone-svg-icons';
import { faContactBook as falContactBook }   from '@fortawesome/pro-light-svg-icons';
import { faContactBook as farContactBook }   from '@fortawesome/pro-regular-svg-icons';
import { faContactBook as fasContactBook }   from '@fortawesome/pro-solid-svg-icons';
import { faContactCard as fadContactCard }   from '@fortawesome/pro-duotone-svg-icons';
import { faContactCard as falContactCard }   from '@fortawesome/pro-light-svg-icons';
import { faContactCard as farContactCard }   from '@fortawesome/pro-regular-svg-icons';
import { faContactCard as fasContactCard }   from '@fortawesome/pro-solid-svg-icons';
import { faContainerStorage as fadContainerStorage }   from '@fortawesome/pro-duotone-svg-icons';
import { faContainerStorage as falContainerStorage }   from '@fortawesome/pro-light-svg-icons';
import { faContainerStorage as farContainerStorage }   from '@fortawesome/pro-regular-svg-icons';
import { faContainerStorage as fasContainerStorage }   from '@fortawesome/pro-solid-svg-icons';
import { faConveyorBelt as fadConveyorBelt }   from '@fortawesome/pro-duotone-svg-icons';
import { faConveyorBelt as falConveyorBelt }   from '@fortawesome/pro-light-svg-icons';
import { faConveyorBelt as farConveyorBelt }   from '@fortawesome/pro-regular-svg-icons';
import { faConveyorBelt as fasConveyorBelt }   from '@fortawesome/pro-solid-svg-icons';
import { faConveyorBeltAlt as fadConveyorBeltAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faConveyorBeltAlt as falConveyorBeltAlt }   from '@fortawesome/pro-light-svg-icons';
import { faConveyorBeltAlt as farConveyorBeltAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faConveyorBeltAlt as fasConveyorBeltAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faConveyorBeltBoxes as fadConveyorBeltBoxes }   from '@fortawesome/pro-duotone-svg-icons';
import { faConveyorBeltBoxes as falConveyorBeltBoxes }   from '@fortawesome/pro-light-svg-icons';
import { faConveyorBeltBoxes as farConveyorBeltBoxes }   from '@fortawesome/pro-regular-svg-icons';
import { faConveyorBeltBoxes as fasConveyorBeltBoxes }   from '@fortawesome/pro-solid-svg-icons';
import { faConveyorBeltEmpty as fadConveyorBeltEmpty }   from '@fortawesome/pro-duotone-svg-icons';
import { faConveyorBeltEmpty as falConveyorBeltEmpty }   from '@fortawesome/pro-light-svg-icons';
import { faConveyorBeltEmpty as farConveyorBeltEmpty }   from '@fortawesome/pro-regular-svg-icons';
import { faConveyorBeltEmpty as fasConveyorBeltEmpty }   from '@fortawesome/pro-solid-svg-icons';
import { faCookie as fadCookie }   from '@fortawesome/pro-duotone-svg-icons';
import { faCookie as falCookie }   from '@fortawesome/pro-light-svg-icons';
import { faCookie as farCookie }   from '@fortawesome/pro-regular-svg-icons';
import { faCookie as fasCookie }   from '@fortawesome/pro-solid-svg-icons';
import { faCookieBite as fadCookieBite }   from '@fortawesome/pro-duotone-svg-icons';
import { faCookieBite as falCookieBite }   from '@fortawesome/pro-light-svg-icons';
import { faCookieBite as farCookieBite }   from '@fortawesome/pro-regular-svg-icons';
import { faCookieBite as fasCookieBite }   from '@fortawesome/pro-solid-svg-icons';
import { faCopy as fadCopy }   from '@fortawesome/pro-duotone-svg-icons';
import { faCopy as falCopy }   from '@fortawesome/pro-light-svg-icons';
import { faCopy as farCopy }   from '@fortawesome/pro-regular-svg-icons';
import { faCopy as fasCopy }   from '@fortawesome/pro-solid-svg-icons';
import { faCopyright as fadCopyright }   from '@fortawesome/pro-duotone-svg-icons';
import { faCopyright as falCopyright }   from '@fortawesome/pro-light-svg-icons';
import { faCopyright as farCopyright }   from '@fortawesome/pro-regular-svg-icons';
import { faCopyright as fasCopyright }   from '@fortawesome/pro-solid-svg-icons';
import { faCorn as fadCorn }   from '@fortawesome/pro-duotone-svg-icons';
import { faCorn as falCorn }   from '@fortawesome/pro-light-svg-icons';
import { faCorn as farCorn }   from '@fortawesome/pro-regular-svg-icons';
import { faCorn as fasCorn }   from '@fortawesome/pro-solid-svg-icons';
import { faCorner as fadCorner }   from '@fortawesome/pro-duotone-svg-icons';
import { faCorner as falCorner }   from '@fortawesome/pro-light-svg-icons';
import { faCorner as farCorner }   from '@fortawesome/pro-regular-svg-icons';
import { faCorner as fasCorner }   from '@fortawesome/pro-solid-svg-icons';
import { faCouch as fadCouch }   from '@fortawesome/pro-duotone-svg-icons';
import { faCouch as falCouch }   from '@fortawesome/pro-light-svg-icons';
import { faCouch as farCouch }   from '@fortawesome/pro-regular-svg-icons';
import { faCouch as fasCouch }   from '@fortawesome/pro-solid-svg-icons';
import { faCouchSmall as fadCouchSmall }   from '@fortawesome/pro-duotone-svg-icons';
import { faCouchSmall as falCouchSmall }   from '@fortawesome/pro-light-svg-icons';
import { faCouchSmall as farCouchSmall }   from '@fortawesome/pro-regular-svg-icons';
import { faCouchSmall as fasCouchSmall }   from '@fortawesome/pro-solid-svg-icons';
import { faCow as fadCow }   from '@fortawesome/pro-duotone-svg-icons';
import { faCow as falCow }   from '@fortawesome/pro-light-svg-icons';
import { faCow as farCow }   from '@fortawesome/pro-regular-svg-icons';
import { faCow as fasCow }   from '@fortawesome/pro-solid-svg-icons';
import { faCowbell as fadCowbell }   from '@fortawesome/pro-duotone-svg-icons';
import { faCowbell as falCowbell }   from '@fortawesome/pro-light-svg-icons';
import { faCowbell as farCowbell }   from '@fortawesome/pro-regular-svg-icons';
import { faCowbell as fasCowbell }   from '@fortawesome/pro-solid-svg-icons';
import { faCowbellCirclePlus as fadCowbellCirclePlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faCowbellCirclePlus as falCowbellCirclePlus }   from '@fortawesome/pro-light-svg-icons';
import { faCowbellCirclePlus as farCowbellCirclePlus }   from '@fortawesome/pro-regular-svg-icons';
import { faCowbellCirclePlus as fasCowbellCirclePlus }   from '@fortawesome/pro-solid-svg-icons';
import { faCowbellMore as fadCowbellMore }   from '@fortawesome/pro-duotone-svg-icons';
import { faCowbellMore as falCowbellMore }   from '@fortawesome/pro-light-svg-icons';
import { faCowbellMore as farCowbellMore }   from '@fortawesome/pro-regular-svg-icons';
import { faCowbellMore as fasCowbellMore }   from '@fortawesome/pro-solid-svg-icons';
import { faCrab as fadCrab }   from '@fortawesome/pro-duotone-svg-icons';
import { faCrab as falCrab }   from '@fortawesome/pro-light-svg-icons';
import { faCrab as farCrab }   from '@fortawesome/pro-regular-svg-icons';
import { faCrab as fasCrab }   from '@fortawesome/pro-solid-svg-icons';
import { faCrateApple as fadCrateApple }   from '@fortawesome/pro-duotone-svg-icons';
import { faCrateApple as falCrateApple }   from '@fortawesome/pro-light-svg-icons';
import { faCrateApple as farCrateApple }   from '@fortawesome/pro-regular-svg-icons';
import { faCrateApple as fasCrateApple }   from '@fortawesome/pro-solid-svg-icons';
import { faCrateEmpty as fadCrateEmpty }   from '@fortawesome/pro-duotone-svg-icons';
import { faCrateEmpty as falCrateEmpty }   from '@fortawesome/pro-light-svg-icons';
import { faCrateEmpty as farCrateEmpty }   from '@fortawesome/pro-regular-svg-icons';
import { faCrateEmpty as fasCrateEmpty }   from '@fortawesome/pro-solid-svg-icons';
import { faCreditCard as fadCreditCard }   from '@fortawesome/pro-duotone-svg-icons';
import { faCreditCard as falCreditCard }   from '@fortawesome/pro-light-svg-icons';
import { faCreditCard as farCreditCard }   from '@fortawesome/pro-regular-svg-icons';
import { faCreditCard as fasCreditCard }   from '@fortawesome/pro-solid-svg-icons';
import { faCreditCardAlt as fadCreditCardAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faCreditCardAlt as falCreditCardAlt }   from '@fortawesome/pro-light-svg-icons';
import { faCreditCardAlt as farCreditCardAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faCreditCardAlt as fasCreditCardAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faCreditCardBlank as fadCreditCardBlank }   from '@fortawesome/pro-duotone-svg-icons';
import { faCreditCardBlank as falCreditCardBlank }   from '@fortawesome/pro-light-svg-icons';
import { faCreditCardBlank as farCreditCardBlank }   from '@fortawesome/pro-regular-svg-icons';
import { faCreditCardBlank as fasCreditCardBlank }   from '@fortawesome/pro-solid-svg-icons';
import { faCreditCardFront as fadCreditCardFront }   from '@fortawesome/pro-duotone-svg-icons';
import { faCreditCardFront as falCreditCardFront }   from '@fortawesome/pro-light-svg-icons';
import { faCreditCardFront as farCreditCardFront }   from '@fortawesome/pro-regular-svg-icons';
import { faCreditCardFront as fasCreditCardFront }   from '@fortawesome/pro-solid-svg-icons';
import { faCreemee as fadCreemee }   from '@fortawesome/pro-duotone-svg-icons';
import { faCreemee as falCreemee }   from '@fortawesome/pro-light-svg-icons';
import { faCreemee as farCreemee }   from '@fortawesome/pro-regular-svg-icons';
import { faCreemee as fasCreemee }   from '@fortawesome/pro-solid-svg-icons';
import { faCricket as fadCricket }   from '@fortawesome/pro-duotone-svg-icons';
import { faCricket as falCricket }   from '@fortawesome/pro-light-svg-icons';
import { faCricket as farCricket }   from '@fortawesome/pro-regular-svg-icons';
import { faCricket as fasCricket }   from '@fortawesome/pro-solid-svg-icons';
import { faCricketBatBall as fadCricketBatBall }   from '@fortawesome/pro-duotone-svg-icons';
import { faCricketBatBall as falCricketBatBall }   from '@fortawesome/pro-light-svg-icons';
import { faCricketBatBall as farCricketBatBall }   from '@fortawesome/pro-regular-svg-icons';
import { faCricketBatBall as fasCricketBatBall }   from '@fortawesome/pro-solid-svg-icons';
import { faCroissant as fadCroissant }   from '@fortawesome/pro-duotone-svg-icons';
import { faCroissant as falCroissant }   from '@fortawesome/pro-light-svg-icons';
import { faCroissant as farCroissant }   from '@fortawesome/pro-regular-svg-icons';
import { faCroissant as fasCroissant }   from '@fortawesome/pro-solid-svg-icons';
import { faCrop as fadCrop }   from '@fortawesome/pro-duotone-svg-icons';
import { faCrop as falCrop }   from '@fortawesome/pro-light-svg-icons';
import { faCrop as farCrop }   from '@fortawesome/pro-regular-svg-icons';
import { faCrop as fasCrop }   from '@fortawesome/pro-solid-svg-icons';
import { faCropAlt as fadCropAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faCropAlt as falCropAlt }   from '@fortawesome/pro-light-svg-icons';
import { faCropAlt as farCropAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faCropAlt as fasCropAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faCropSimple as fadCropSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faCropSimple as falCropSimple }   from '@fortawesome/pro-light-svg-icons';
import { faCropSimple as farCropSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faCropSimple as fasCropSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faCross as fadCross }   from '@fortawesome/pro-duotone-svg-icons';
import { faCross as falCross }   from '@fortawesome/pro-light-svg-icons';
import { faCross as farCross }   from '@fortawesome/pro-regular-svg-icons';
import { faCross as fasCross }   from '@fortawesome/pro-solid-svg-icons';
import { faCrosshairs as fadCrosshairs }   from '@fortawesome/pro-duotone-svg-icons';
import { faCrosshairs as falCrosshairs }   from '@fortawesome/pro-light-svg-icons';
import { faCrosshairs as farCrosshairs }   from '@fortawesome/pro-regular-svg-icons';
import { faCrosshairs as fasCrosshairs }   from '@fortawesome/pro-solid-svg-icons';
import { faCrow as fadCrow }   from '@fortawesome/pro-duotone-svg-icons';
import { faCrow as falCrow }   from '@fortawesome/pro-light-svg-icons';
import { faCrow as farCrow }   from '@fortawesome/pro-regular-svg-icons';
import { faCrow as fasCrow }   from '@fortawesome/pro-solid-svg-icons';
import { faCrown as fadCrown }   from '@fortawesome/pro-duotone-svg-icons';
import { faCrown as falCrown }   from '@fortawesome/pro-light-svg-icons';
import { faCrown as farCrown }   from '@fortawesome/pro-regular-svg-icons';
import { faCrown as fasCrown }   from '@fortawesome/pro-solid-svg-icons';
import { faCrutch as fadCrutch }   from '@fortawesome/pro-duotone-svg-icons';
import { faCrutch as falCrutch }   from '@fortawesome/pro-light-svg-icons';
import { faCrutch as farCrutch }   from '@fortawesome/pro-regular-svg-icons';
import { faCrutch as fasCrutch }   from '@fortawesome/pro-solid-svg-icons';
import { faCrutches as fadCrutches }   from '@fortawesome/pro-duotone-svg-icons';
import { faCrutches as falCrutches }   from '@fortawesome/pro-light-svg-icons';
import { faCrutches as farCrutches }   from '@fortawesome/pro-regular-svg-icons';
import { faCrutches as fasCrutches }   from '@fortawesome/pro-solid-svg-icons';
import { faCruzeiroSign as fadCruzeiroSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faCruzeiroSign as falCruzeiroSign }   from '@fortawesome/pro-light-svg-icons';
import { faCruzeiroSign as farCruzeiroSign }   from '@fortawesome/pro-regular-svg-icons';
import { faCruzeiroSign as fasCruzeiroSign }   from '@fortawesome/pro-solid-svg-icons';
import { faCrystalBall as fadCrystalBall }   from '@fortawesome/pro-duotone-svg-icons';
import { faCrystalBall as falCrystalBall }   from '@fortawesome/pro-light-svg-icons';
import { faCrystalBall as farCrystalBall }   from '@fortawesome/pro-regular-svg-icons';
import { faCrystalBall as fasCrystalBall }   from '@fortawesome/pro-solid-svg-icons';
import { faCube as fadCube }   from '@fortawesome/pro-duotone-svg-icons';
import { faCube as falCube }   from '@fortawesome/pro-light-svg-icons';
import { faCube as farCube }   from '@fortawesome/pro-regular-svg-icons';
import { faCube as fasCube }   from '@fortawesome/pro-solid-svg-icons';
import { faCubes as fadCubes }   from '@fortawesome/pro-duotone-svg-icons';
import { faCubes as falCubes }   from '@fortawesome/pro-light-svg-icons';
import { faCubes as farCubes }   from '@fortawesome/pro-regular-svg-icons';
import { faCubes as fasCubes }   from '@fortawesome/pro-solid-svg-icons';
import { faCubesStacked as fadCubesStacked }   from '@fortawesome/pro-duotone-svg-icons';
import { faCubesStacked as falCubesStacked }   from '@fortawesome/pro-light-svg-icons';
import { faCubesStacked as farCubesStacked }   from '@fortawesome/pro-regular-svg-icons';
import { faCubesStacked as fasCubesStacked }   from '@fortawesome/pro-solid-svg-icons';
import { faCucumber as fadCucumber }   from '@fortawesome/pro-duotone-svg-icons';
import { faCucumber as falCucumber }   from '@fortawesome/pro-light-svg-icons';
import { faCucumber as farCucumber }   from '@fortawesome/pro-regular-svg-icons';
import { faCucumber as fasCucumber }   from '@fortawesome/pro-solid-svg-icons';
import { faCupcake as fadCupcake }   from '@fortawesome/pro-duotone-svg-icons';
import { faCupcake as falCupcake }   from '@fortawesome/pro-light-svg-icons';
import { faCupcake as farCupcake }   from '@fortawesome/pro-regular-svg-icons';
import { faCupcake as fasCupcake }   from '@fortawesome/pro-solid-svg-icons';
import { faCupStraw as fadCupStraw }   from '@fortawesome/pro-duotone-svg-icons';
import { faCupStraw as falCupStraw }   from '@fortawesome/pro-light-svg-icons';
import { faCupStraw as farCupStraw }   from '@fortawesome/pro-regular-svg-icons';
import { faCupStraw as fasCupStraw }   from '@fortawesome/pro-solid-svg-icons';
import { faCupStrawSwoosh as fadCupStrawSwoosh }   from '@fortawesome/pro-duotone-svg-icons';
import { faCupStrawSwoosh as falCupStrawSwoosh }   from '@fortawesome/pro-light-svg-icons';
import { faCupStrawSwoosh as farCupStrawSwoosh }   from '@fortawesome/pro-regular-svg-icons';
import { faCupStrawSwoosh as fasCupStrawSwoosh }   from '@fortawesome/pro-solid-svg-icons';
import { faCupTogo as fadCupTogo }   from '@fortawesome/pro-duotone-svg-icons';
import { faCupTogo as falCupTogo }   from '@fortawesome/pro-light-svg-icons';
import { faCupTogo as farCupTogo }   from '@fortawesome/pro-regular-svg-icons';
import { faCupTogo as fasCupTogo }   from '@fortawesome/pro-solid-svg-icons';
import { faCurling as fadCurling }   from '@fortawesome/pro-duotone-svg-icons';
import { faCurling as falCurling }   from '@fortawesome/pro-light-svg-icons';
import { faCurling as farCurling }   from '@fortawesome/pro-regular-svg-icons';
import { faCurling as fasCurling }   from '@fortawesome/pro-solid-svg-icons';
import { faCurlingStone as fadCurlingStone }   from '@fortawesome/pro-duotone-svg-icons';
import { faCurlingStone as falCurlingStone }   from '@fortawesome/pro-light-svg-icons';
import { faCurlingStone as farCurlingStone }   from '@fortawesome/pro-regular-svg-icons';
import { faCurlingStone as fasCurlingStone }   from '@fortawesome/pro-solid-svg-icons';
import { faCustard as fadCustard }   from '@fortawesome/pro-duotone-svg-icons';
import { faCustard as falCustard }   from '@fortawesome/pro-light-svg-icons';
import { faCustard as farCustard }   from '@fortawesome/pro-regular-svg-icons';
import { faCustard as fasCustard }   from '@fortawesome/pro-solid-svg-icons';
import { faCut as fadCut }   from '@fortawesome/pro-duotone-svg-icons';
import { faCut as falCut }   from '@fortawesome/pro-light-svg-icons';
import { faCut as farCut }   from '@fortawesome/pro-regular-svg-icons';
import { faCut as fasCut }   from '@fortawesome/pro-solid-svg-icons';
import { faCutlery as fadCutlery }   from '@fortawesome/pro-duotone-svg-icons';
import { faCutlery as falCutlery }   from '@fortawesome/pro-light-svg-icons';
import { faCutlery as farCutlery }   from '@fortawesome/pro-regular-svg-icons';
import { faCutlery as fasCutlery }   from '@fortawesome/pro-solid-svg-icons';
import { faD as fadD }   from '@fortawesome/pro-duotone-svg-icons';
import { faD as falD }   from '@fortawesome/pro-light-svg-icons';
import { faD as farD }   from '@fortawesome/pro-regular-svg-icons';
import { faD as fasD }   from '@fortawesome/pro-solid-svg-icons';
import { faDagger as fadDagger }   from '@fortawesome/pro-duotone-svg-icons';
import { faDagger as falDagger }   from '@fortawesome/pro-light-svg-icons';
import { faDagger as farDagger }   from '@fortawesome/pro-regular-svg-icons';
import { faDagger as fasDagger }   from '@fortawesome/pro-solid-svg-icons';
import { faDash as fadDash }   from '@fortawesome/pro-duotone-svg-icons';
import { faDash as falDash }   from '@fortawesome/pro-light-svg-icons';
import { faDash as farDash }   from '@fortawesome/pro-regular-svg-icons';
import { faDash as fasDash }   from '@fortawesome/pro-solid-svg-icons';
import { faDashboard as fadDashboard }   from '@fortawesome/pro-duotone-svg-icons';
import { faDashboard as falDashboard }   from '@fortawesome/pro-light-svg-icons';
import { faDashboard as farDashboard }   from '@fortawesome/pro-regular-svg-icons';
import { faDashboard as fasDashboard }   from '@fortawesome/pro-solid-svg-icons';
import { faDatabase as fadDatabase }   from '@fortawesome/pro-duotone-svg-icons';
import { faDatabase as falDatabase }   from '@fortawesome/pro-light-svg-icons';
import { faDatabase as farDatabase }   from '@fortawesome/pro-regular-svg-icons';
import { faDatabase as fasDatabase }   from '@fortawesome/pro-solid-svg-icons';
import { faDeaf as fadDeaf }   from '@fortawesome/pro-duotone-svg-icons';
import { faDeaf as falDeaf }   from '@fortawesome/pro-light-svg-icons';
import { faDeaf as farDeaf }   from '@fortawesome/pro-regular-svg-icons';
import { faDeaf as fasDeaf }   from '@fortawesome/pro-solid-svg-icons';
import { faDeafness as fadDeafness }   from '@fortawesome/pro-duotone-svg-icons';
import { faDeafness as falDeafness }   from '@fortawesome/pro-light-svg-icons';
import { faDeafness as farDeafness }   from '@fortawesome/pro-regular-svg-icons';
import { faDeafness as fasDeafness }   from '@fortawesome/pro-solid-svg-icons';
import { faDebug as fadDebug }   from '@fortawesome/pro-duotone-svg-icons';
import { faDebug as falDebug }   from '@fortawesome/pro-light-svg-icons';
import { faDebug as farDebug }   from '@fortawesome/pro-regular-svg-icons';
import { faDebug as fasDebug }   from '@fortawesome/pro-solid-svg-icons';
import { faDedent as fadDedent }   from '@fortawesome/pro-duotone-svg-icons';
import { faDedent as falDedent }   from '@fortawesome/pro-light-svg-icons';
import { faDedent as farDedent }   from '@fortawesome/pro-regular-svg-icons';
import { faDedent as fasDedent }   from '@fortawesome/pro-solid-svg-icons';
import { faDeer as fadDeer }   from '@fortawesome/pro-duotone-svg-icons';
import { faDeer as falDeer }   from '@fortawesome/pro-light-svg-icons';
import { faDeer as farDeer }   from '@fortawesome/pro-regular-svg-icons';
import { faDeer as fasDeer }   from '@fortawesome/pro-solid-svg-icons';
import { faDeerRudolph as fadDeerRudolph }   from '@fortawesome/pro-duotone-svg-icons';
import { faDeerRudolph as falDeerRudolph }   from '@fortawesome/pro-light-svg-icons';
import { faDeerRudolph as farDeerRudolph }   from '@fortawesome/pro-regular-svg-icons';
import { faDeerRudolph as fasDeerRudolph }   from '@fortawesome/pro-solid-svg-icons';
import { faDeleteLeft as fadDeleteLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faDeleteLeft as falDeleteLeft }   from '@fortawesome/pro-light-svg-icons';
import { faDeleteLeft as farDeleteLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faDeleteLeft as fasDeleteLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faDeleteRight as fadDeleteRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faDeleteRight as falDeleteRight }   from '@fortawesome/pro-light-svg-icons';
import { faDeleteRight as farDeleteRight }   from '@fortawesome/pro-regular-svg-icons';
import { faDeleteRight as fasDeleteRight }   from '@fortawesome/pro-solid-svg-icons';
import { faDemocrat as fadDemocrat }   from '@fortawesome/pro-duotone-svg-icons';
import { faDemocrat as falDemocrat }   from '@fortawesome/pro-light-svg-icons';
import { faDemocrat as farDemocrat }   from '@fortawesome/pro-regular-svg-icons';
import { faDemocrat as fasDemocrat }   from '@fortawesome/pro-solid-svg-icons';
import { faDesktop as fadDesktop }   from '@fortawesome/pro-duotone-svg-icons';
import { faDesktop as falDesktop }   from '@fortawesome/pro-light-svg-icons';
import { faDesktop as farDesktop }   from '@fortawesome/pro-regular-svg-icons';
import { faDesktop as fasDesktop }   from '@fortawesome/pro-solid-svg-icons';
import { faDesktopAlt as fadDesktopAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faDesktopAlt as falDesktopAlt }   from '@fortawesome/pro-light-svg-icons';
import { faDesktopAlt as farDesktopAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faDesktopAlt as fasDesktopAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faDesktopArrowDown as fadDesktopArrowDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faDesktopArrowDown as falDesktopArrowDown }   from '@fortawesome/pro-light-svg-icons';
import { faDesktopArrowDown as farDesktopArrowDown }   from '@fortawesome/pro-regular-svg-icons';
import { faDesktopArrowDown as fasDesktopArrowDown }   from '@fortawesome/pro-solid-svg-icons';
import { faDesktopCode as fadDesktopCode }   from '@fortawesome/pro-duotone-svg-icons';
import { faDesktopCode as falDesktopCode }   from '@fortawesome/pro-light-svg-icons';
import { faDesktopCode as farDesktopCode }   from '@fortawesome/pro-regular-svg-icons';
import { faDesktopCode as fasDesktopCode }   from '@fortawesome/pro-solid-svg-icons';
import { faDesktopMedical as fadDesktopMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faDesktopMedical as falDesktopMedical }   from '@fortawesome/pro-light-svg-icons';
import { faDesktopMedical as farDesktopMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faDesktopMedical as fasDesktopMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faDesktopSlash as fadDesktopSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faDesktopSlash as falDesktopSlash }   from '@fortawesome/pro-light-svg-icons';
import { faDesktopSlash as farDesktopSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faDesktopSlash as fasDesktopSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faDewpoint as fadDewpoint }   from '@fortawesome/pro-duotone-svg-icons';
import { faDewpoint as falDewpoint }   from '@fortawesome/pro-light-svg-icons';
import { faDewpoint as farDewpoint }   from '@fortawesome/pro-regular-svg-icons';
import { faDewpoint as fasDewpoint }   from '@fortawesome/pro-solid-svg-icons';
import { faDharmachakra as fadDharmachakra }   from '@fortawesome/pro-duotone-svg-icons';
import { faDharmachakra as falDharmachakra }   from '@fortawesome/pro-light-svg-icons';
import { faDharmachakra as farDharmachakra }   from '@fortawesome/pro-regular-svg-icons';
import { faDharmachakra as fasDharmachakra }   from '@fortawesome/pro-solid-svg-icons';
import { faDiagnoses as fadDiagnoses }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiagnoses as falDiagnoses }   from '@fortawesome/pro-light-svg-icons';
import { faDiagnoses as farDiagnoses }   from '@fortawesome/pro-regular-svg-icons';
import { faDiagnoses as fasDiagnoses }   from '@fortawesome/pro-solid-svg-icons';
import { faDiagramCells as fadDiagramCells }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiagramCells as falDiagramCells }   from '@fortawesome/pro-light-svg-icons';
import { faDiagramCells as farDiagramCells }   from '@fortawesome/pro-regular-svg-icons';
import { faDiagramCells as fasDiagramCells }   from '@fortawesome/pro-solid-svg-icons';
import { faDiagramLeanCanvas as fadDiagramLeanCanvas }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiagramLeanCanvas as falDiagramLeanCanvas }   from '@fortawesome/pro-light-svg-icons';
import { faDiagramLeanCanvas as farDiagramLeanCanvas }   from '@fortawesome/pro-regular-svg-icons';
import { faDiagramLeanCanvas as fasDiagramLeanCanvas }   from '@fortawesome/pro-solid-svg-icons';
import { faDiagramNested as fadDiagramNested }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiagramNested as falDiagramNested }   from '@fortawesome/pro-light-svg-icons';
import { faDiagramNested as farDiagramNested }   from '@fortawesome/pro-regular-svg-icons';
import { faDiagramNested as fasDiagramNested }   from '@fortawesome/pro-solid-svg-icons';
import { faDiagramNext as fadDiagramNext }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiagramNext as falDiagramNext }   from '@fortawesome/pro-light-svg-icons';
import { faDiagramNext as farDiagramNext }   from '@fortawesome/pro-regular-svg-icons';
import { faDiagramNext as fasDiagramNext }   from '@fortawesome/pro-solid-svg-icons';
import { faDiagramPredecessor as fadDiagramPredecessor }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiagramPredecessor as falDiagramPredecessor }   from '@fortawesome/pro-light-svg-icons';
import { faDiagramPredecessor as farDiagramPredecessor }   from '@fortawesome/pro-regular-svg-icons';
import { faDiagramPredecessor as fasDiagramPredecessor }   from '@fortawesome/pro-solid-svg-icons';
import { faDiagramPrevious as fadDiagramPrevious }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiagramPrevious as falDiagramPrevious }   from '@fortawesome/pro-light-svg-icons';
import { faDiagramPrevious as farDiagramPrevious }   from '@fortawesome/pro-regular-svg-icons';
import { faDiagramPrevious as fasDiagramPrevious }   from '@fortawesome/pro-solid-svg-icons';
import { faDiagramProject as fadDiagramProject }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiagramProject as falDiagramProject }   from '@fortawesome/pro-light-svg-icons';
import { faDiagramProject as farDiagramProject }   from '@fortawesome/pro-regular-svg-icons';
import { faDiagramProject as fasDiagramProject }   from '@fortawesome/pro-solid-svg-icons';
import { faDiagramSankey as fadDiagramSankey }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiagramSankey as falDiagramSankey }   from '@fortawesome/pro-light-svg-icons';
import { faDiagramSankey as farDiagramSankey }   from '@fortawesome/pro-regular-svg-icons';
import { faDiagramSankey as fasDiagramSankey }   from '@fortawesome/pro-solid-svg-icons';
import { faDiagramSubtask as fadDiagramSubtask }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiagramSubtask as falDiagramSubtask }   from '@fortawesome/pro-light-svg-icons';
import { faDiagramSubtask as farDiagramSubtask }   from '@fortawesome/pro-regular-svg-icons';
import { faDiagramSubtask as fasDiagramSubtask }   from '@fortawesome/pro-solid-svg-icons';
import { faDiagramSuccessor as fadDiagramSuccessor }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiagramSuccessor as falDiagramSuccessor }   from '@fortawesome/pro-light-svg-icons';
import { faDiagramSuccessor as farDiagramSuccessor }   from '@fortawesome/pro-regular-svg-icons';
import { faDiagramSuccessor as fasDiagramSuccessor }   from '@fortawesome/pro-solid-svg-icons';
import { faDiagramVenn as fadDiagramVenn }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiagramVenn as falDiagramVenn }   from '@fortawesome/pro-light-svg-icons';
import { faDiagramVenn as farDiagramVenn }   from '@fortawesome/pro-regular-svg-icons';
import { faDiagramVenn as fasDiagramVenn }   from '@fortawesome/pro-solid-svg-icons';
import { faDial as fadDial }   from '@fortawesome/pro-duotone-svg-icons';
import { faDial as falDial }   from '@fortawesome/pro-light-svg-icons';
import { faDial as farDial }   from '@fortawesome/pro-regular-svg-icons';
import { faDial as fasDial }   from '@fortawesome/pro-solid-svg-icons';
import { faDialHigh as fadDialHigh }   from '@fortawesome/pro-duotone-svg-icons';
import { faDialHigh as falDialHigh }   from '@fortawesome/pro-light-svg-icons';
import { faDialHigh as farDialHigh }   from '@fortawesome/pro-regular-svg-icons';
import { faDialHigh as fasDialHigh }   from '@fortawesome/pro-solid-svg-icons';
import { faDialLow as fadDialLow }   from '@fortawesome/pro-duotone-svg-icons';
import { faDialLow as falDialLow }   from '@fortawesome/pro-light-svg-icons';
import { faDialLow as farDialLow }   from '@fortawesome/pro-regular-svg-icons';
import { faDialLow as fasDialLow }   from '@fortawesome/pro-solid-svg-icons';
import { faDialMax as fadDialMax }   from '@fortawesome/pro-duotone-svg-icons';
import { faDialMax as falDialMax }   from '@fortawesome/pro-light-svg-icons';
import { faDialMax as farDialMax }   from '@fortawesome/pro-regular-svg-icons';
import { faDialMax as fasDialMax }   from '@fortawesome/pro-solid-svg-icons';
import { faDialMed as fadDialMed }   from '@fortawesome/pro-duotone-svg-icons';
import { faDialMed as falDialMed }   from '@fortawesome/pro-light-svg-icons';
import { faDialMed as farDialMed }   from '@fortawesome/pro-regular-svg-icons';
import { faDialMed as fasDialMed }   from '@fortawesome/pro-solid-svg-icons';
import { faDialMedHigh as fadDialMedHigh }   from '@fortawesome/pro-duotone-svg-icons';
import { faDialMedHigh as falDialMedHigh }   from '@fortawesome/pro-light-svg-icons';
import { faDialMedHigh as farDialMedHigh }   from '@fortawesome/pro-regular-svg-icons';
import { faDialMedHigh as fasDialMedHigh }   from '@fortawesome/pro-solid-svg-icons';
import { faDialMedLow as fadDialMedLow }   from '@fortawesome/pro-duotone-svg-icons';
import { faDialMedLow as falDialMedLow }   from '@fortawesome/pro-light-svg-icons';
import { faDialMedLow as farDialMedLow }   from '@fortawesome/pro-regular-svg-icons';
import { faDialMedLow as fasDialMedLow }   from '@fortawesome/pro-solid-svg-icons';
import { faDialMin as fadDialMin }   from '@fortawesome/pro-duotone-svg-icons';
import { faDialMin as falDialMin }   from '@fortawesome/pro-light-svg-icons';
import { faDialMin as farDialMin }   from '@fortawesome/pro-regular-svg-icons';
import { faDialMin as fasDialMin }   from '@fortawesome/pro-solid-svg-icons';
import { faDialOff as fadDialOff }   from '@fortawesome/pro-duotone-svg-icons';
import { faDialOff as falDialOff }   from '@fortawesome/pro-light-svg-icons';
import { faDialOff as farDialOff }   from '@fortawesome/pro-regular-svg-icons';
import { faDialOff as fasDialOff }   from '@fortawesome/pro-solid-svg-icons';
import { faDiamond as fadDiamond }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiamond as falDiamond }   from '@fortawesome/pro-light-svg-icons';
import { faDiamond as farDiamond }   from '@fortawesome/pro-regular-svg-icons';
import { faDiamond as fasDiamond }   from '@fortawesome/pro-solid-svg-icons';
import { faDiamondExclamation as fadDiamondExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiamondExclamation as falDiamondExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faDiamondExclamation as farDiamondExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faDiamondExclamation as fasDiamondExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faDiamondTurnRight as fadDiamondTurnRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiamondTurnRight as falDiamondTurnRight }   from '@fortawesome/pro-light-svg-icons';
import { faDiamondTurnRight as farDiamondTurnRight }   from '@fortawesome/pro-regular-svg-icons';
import { faDiamondTurnRight as fasDiamondTurnRight }   from '@fortawesome/pro-solid-svg-icons';
import { faDice as fadDice }   from '@fortawesome/pro-duotone-svg-icons';
import { faDice as falDice }   from '@fortawesome/pro-light-svg-icons';
import { faDice as farDice }   from '@fortawesome/pro-regular-svg-icons';
import { faDice as fasDice }   from '@fortawesome/pro-solid-svg-icons';
import { faDiceD10 as fadDiceD10 }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiceD10 as falDiceD10 }   from '@fortawesome/pro-light-svg-icons';
import { faDiceD10 as farDiceD10 }   from '@fortawesome/pro-regular-svg-icons';
import { faDiceD10 as fasDiceD10 }   from '@fortawesome/pro-solid-svg-icons';
import { faDiceD12 as fadDiceD12 }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiceD12 as falDiceD12 }   from '@fortawesome/pro-light-svg-icons';
import { faDiceD12 as farDiceD12 }   from '@fortawesome/pro-regular-svg-icons';
import { faDiceD12 as fasDiceD12 }   from '@fortawesome/pro-solid-svg-icons';
import { faDiceD20 as fadDiceD20 }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiceD20 as falDiceD20 }   from '@fortawesome/pro-light-svg-icons';
import { faDiceD20 as farDiceD20 }   from '@fortawesome/pro-regular-svg-icons';
import { faDiceD20 as fasDiceD20 }   from '@fortawesome/pro-solid-svg-icons';
import { faDiceD4 as fadDiceD4 }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiceD4 as falDiceD4 }   from '@fortawesome/pro-light-svg-icons';
import { faDiceD4 as farDiceD4 }   from '@fortawesome/pro-regular-svg-icons';
import { faDiceD4 as fasDiceD4 }   from '@fortawesome/pro-solid-svg-icons';
import { faDiceD6 as fadDiceD6 }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiceD6 as falDiceD6 }   from '@fortawesome/pro-light-svg-icons';
import { faDiceD6 as farDiceD6 }   from '@fortawesome/pro-regular-svg-icons';
import { faDiceD6 as fasDiceD6 }   from '@fortawesome/pro-solid-svg-icons';
import { faDiceD8 as fadDiceD8 }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiceD8 as falDiceD8 }   from '@fortawesome/pro-light-svg-icons';
import { faDiceD8 as farDiceD8 }   from '@fortawesome/pro-regular-svg-icons';
import { faDiceD8 as fasDiceD8 }   from '@fortawesome/pro-solid-svg-icons';
import { faDiceFive as fadDiceFive }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiceFive as falDiceFive }   from '@fortawesome/pro-light-svg-icons';
import { faDiceFive as farDiceFive }   from '@fortawesome/pro-regular-svg-icons';
import { faDiceFive as fasDiceFive }   from '@fortawesome/pro-solid-svg-icons';
import { faDiceFour as fadDiceFour }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiceFour as falDiceFour }   from '@fortawesome/pro-light-svg-icons';
import { faDiceFour as farDiceFour }   from '@fortawesome/pro-regular-svg-icons';
import { faDiceFour as fasDiceFour }   from '@fortawesome/pro-solid-svg-icons';
import { faDiceOne as fadDiceOne }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiceOne as falDiceOne }   from '@fortawesome/pro-light-svg-icons';
import { faDiceOne as farDiceOne }   from '@fortawesome/pro-regular-svg-icons';
import { faDiceOne as fasDiceOne }   from '@fortawesome/pro-solid-svg-icons';
import { faDiceSix as fadDiceSix }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiceSix as falDiceSix }   from '@fortawesome/pro-light-svg-icons';
import { faDiceSix as farDiceSix }   from '@fortawesome/pro-regular-svg-icons';
import { faDiceSix as fasDiceSix }   from '@fortawesome/pro-solid-svg-icons';
import { faDiceThree as fadDiceThree }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiceThree as falDiceThree }   from '@fortawesome/pro-light-svg-icons';
import { faDiceThree as farDiceThree }   from '@fortawesome/pro-regular-svg-icons';
import { faDiceThree as fasDiceThree }   from '@fortawesome/pro-solid-svg-icons';
import { faDiceTwo as fadDiceTwo }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiceTwo as falDiceTwo }   from '@fortawesome/pro-light-svg-icons';
import { faDiceTwo as farDiceTwo }   from '@fortawesome/pro-regular-svg-icons';
import { faDiceTwo as fasDiceTwo }   from '@fortawesome/pro-solid-svg-icons';
import { faDigging as fadDigging }   from '@fortawesome/pro-duotone-svg-icons';
import { faDigging as falDigging }   from '@fortawesome/pro-light-svg-icons';
import { faDigging as farDigging }   from '@fortawesome/pro-regular-svg-icons';
import { faDigging as fasDigging }   from '@fortawesome/pro-solid-svg-icons';
import { faDigitalTachograph as fadDigitalTachograph }   from '@fortawesome/pro-duotone-svg-icons';
import { faDigitalTachograph as falDigitalTachograph }   from '@fortawesome/pro-light-svg-icons';
import { faDigitalTachograph as farDigitalTachograph }   from '@fortawesome/pro-regular-svg-icons';
import { faDigitalTachograph as fasDigitalTachograph }   from '@fortawesome/pro-solid-svg-icons';
import { faDiploma as fadDiploma }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiploma as falDiploma }   from '@fortawesome/pro-light-svg-icons';
import { faDiploma as farDiploma }   from '@fortawesome/pro-regular-svg-icons';
import { faDiploma as fasDiploma }   from '@fortawesome/pro-solid-svg-icons';
import { faDirections as fadDirections }   from '@fortawesome/pro-duotone-svg-icons';
import { faDirections as falDirections }   from '@fortawesome/pro-light-svg-icons';
import { faDirections as farDirections }   from '@fortawesome/pro-regular-svg-icons';
import { faDirections as fasDirections }   from '@fortawesome/pro-solid-svg-icons';
import { faDiscDrive as fadDiscDrive }   from '@fortawesome/pro-duotone-svg-icons';
import { faDiscDrive as falDiscDrive }   from '@fortawesome/pro-light-svg-icons';
import { faDiscDrive as farDiscDrive }   from '@fortawesome/pro-regular-svg-icons';
import { faDiscDrive as fasDiscDrive }   from '@fortawesome/pro-solid-svg-icons';
import { faDisease as fadDisease }   from '@fortawesome/pro-duotone-svg-icons';
import { faDisease as falDisease }   from '@fortawesome/pro-light-svg-icons';
import { faDisease as farDisease }   from '@fortawesome/pro-regular-svg-icons';
import { faDisease as fasDisease }   from '@fortawesome/pro-solid-svg-icons';
import { faDisplay as fadDisplay }   from '@fortawesome/pro-duotone-svg-icons';
import { faDisplay as falDisplay }   from '@fortawesome/pro-light-svg-icons';
import { faDisplay as farDisplay }   from '@fortawesome/pro-regular-svg-icons';
import { faDisplay as fasDisplay }   from '@fortawesome/pro-solid-svg-icons';
import { faDisplayArrowDown as fadDisplayArrowDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faDisplayArrowDown as falDisplayArrowDown }   from '@fortawesome/pro-light-svg-icons';
import { faDisplayArrowDown as farDisplayArrowDown }   from '@fortawesome/pro-regular-svg-icons';
import { faDisplayArrowDown as fasDisplayArrowDown }   from '@fortawesome/pro-solid-svg-icons';
import { faDisplayCode as fadDisplayCode }   from '@fortawesome/pro-duotone-svg-icons';
import { faDisplayCode as falDisplayCode }   from '@fortawesome/pro-light-svg-icons';
import { faDisplayCode as farDisplayCode }   from '@fortawesome/pro-regular-svg-icons';
import { faDisplayCode as fasDisplayCode }   from '@fortawesome/pro-solid-svg-icons';
import { faDisplayMedical as fadDisplayMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faDisplayMedical as falDisplayMedical }   from '@fortawesome/pro-light-svg-icons';
import { faDisplayMedical as farDisplayMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faDisplayMedical as fasDisplayMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faDisplaySlash as fadDisplaySlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faDisplaySlash as falDisplaySlash }   from '@fortawesome/pro-light-svg-icons';
import { faDisplaySlash as farDisplaySlash }   from '@fortawesome/pro-regular-svg-icons';
import { faDisplaySlash as fasDisplaySlash }   from '@fortawesome/pro-solid-svg-icons';
import { faDistributeSpacingHorizontal as fadDistributeSpacingHorizontal }   from '@fortawesome/pro-duotone-svg-icons';
import { faDistributeSpacingHorizontal as falDistributeSpacingHorizontal }   from '@fortawesome/pro-light-svg-icons';
import { faDistributeSpacingHorizontal as farDistributeSpacingHorizontal }   from '@fortawesome/pro-regular-svg-icons';
import { faDistributeSpacingHorizontal as fasDistributeSpacingHorizontal }   from '@fortawesome/pro-solid-svg-icons';
import { faDistributeSpacingVertical as fadDistributeSpacingVertical }   from '@fortawesome/pro-duotone-svg-icons';
import { faDistributeSpacingVertical as falDistributeSpacingVertical }   from '@fortawesome/pro-light-svg-icons';
import { faDistributeSpacingVertical as farDistributeSpacingVertical }   from '@fortawesome/pro-regular-svg-icons';
import { faDistributeSpacingVertical as fasDistributeSpacingVertical }   from '@fortawesome/pro-solid-svg-icons';
import { faDitto as fadDitto }   from '@fortawesome/pro-duotone-svg-icons';
import { faDitto as falDitto }   from '@fortawesome/pro-light-svg-icons';
import { faDitto as farDitto }   from '@fortawesome/pro-regular-svg-icons';
import { faDitto as fasDitto }   from '@fortawesome/pro-solid-svg-icons';
import { faDivide as fadDivide }   from '@fortawesome/pro-duotone-svg-icons';
import { faDivide as falDivide }   from '@fortawesome/pro-light-svg-icons';
import { faDivide as farDivide }   from '@fortawesome/pro-regular-svg-icons';
import { faDivide as fasDivide }   from '@fortawesome/pro-solid-svg-icons';
import { faDizzy as fadDizzy }   from '@fortawesome/pro-duotone-svg-icons';
import { faDizzy as falDizzy }   from '@fortawesome/pro-light-svg-icons';
import { faDizzy as farDizzy }   from '@fortawesome/pro-regular-svg-icons';
import { faDizzy as fasDizzy }   from '@fortawesome/pro-solid-svg-icons';
import { faDna as fadDna }   from '@fortawesome/pro-duotone-svg-icons';
import { faDna as falDna }   from '@fortawesome/pro-light-svg-icons';
import { faDna as farDna }   from '@fortawesome/pro-regular-svg-icons';
import { faDna as fasDna }   from '@fortawesome/pro-solid-svg-icons';
import { faDog as fadDog }   from '@fortawesome/pro-duotone-svg-icons';
import { faDog as falDog }   from '@fortawesome/pro-light-svg-icons';
import { faDog as farDog }   from '@fortawesome/pro-regular-svg-icons';
import { faDog as fasDog }   from '@fortawesome/pro-solid-svg-icons';
import { faDogLeashed as fadDogLeashed }   from '@fortawesome/pro-duotone-svg-icons';
import { faDogLeashed as falDogLeashed }   from '@fortawesome/pro-light-svg-icons';
import { faDogLeashed as farDogLeashed }   from '@fortawesome/pro-regular-svg-icons';
import { faDogLeashed as fasDogLeashed }   from '@fortawesome/pro-solid-svg-icons';
import { faDollar as fadDollar }   from '@fortawesome/pro-duotone-svg-icons';
import { faDollar as falDollar }   from '@fortawesome/pro-light-svg-icons';
import { faDollar as farDollar }   from '@fortawesome/pro-regular-svg-icons';
import { faDollar as fasDollar }   from '@fortawesome/pro-solid-svg-icons';
import { faDollarCircle as fadDollarCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faDollarCircle as falDollarCircle }   from '@fortawesome/pro-light-svg-icons';
import { faDollarCircle as farDollarCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faDollarCircle as fasDollarCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faDollarSign as fadDollarSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faDollarSign as falDollarSign }   from '@fortawesome/pro-light-svg-icons';
import { faDollarSign as farDollarSign }   from '@fortawesome/pro-regular-svg-icons';
import { faDollarSign as fasDollarSign }   from '@fortawesome/pro-solid-svg-icons';
import { faDollarSquare as fadDollarSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faDollarSquare as falDollarSquare }   from '@fortawesome/pro-light-svg-icons';
import { faDollarSquare as farDollarSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faDollarSquare as fasDollarSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faDolly as fadDolly }   from '@fortawesome/pro-duotone-svg-icons';
import { faDolly as falDolly }   from '@fortawesome/pro-light-svg-icons';
import { faDolly as farDolly }   from '@fortawesome/pro-regular-svg-icons';
import { faDolly as fasDolly }   from '@fortawesome/pro-solid-svg-icons';
import { faDollyBox as fadDollyBox }   from '@fortawesome/pro-duotone-svg-icons';
import { faDollyBox as falDollyBox }   from '@fortawesome/pro-light-svg-icons';
import { faDollyBox as farDollyBox }   from '@fortawesome/pro-regular-svg-icons';
import { faDollyBox as fasDollyBox }   from '@fortawesome/pro-solid-svg-icons';
import { faDollyEmpty as fadDollyEmpty }   from '@fortawesome/pro-duotone-svg-icons';
import { faDollyEmpty as falDollyEmpty }   from '@fortawesome/pro-light-svg-icons';
import { faDollyEmpty as farDollyEmpty }   from '@fortawesome/pro-regular-svg-icons';
import { faDollyEmpty as fasDollyEmpty }   from '@fortawesome/pro-solid-svg-icons';
import { faDollyFlatbed as fadDollyFlatbed }   from '@fortawesome/pro-duotone-svg-icons';
import { faDollyFlatbed as falDollyFlatbed }   from '@fortawesome/pro-light-svg-icons';
import { faDollyFlatbed as farDollyFlatbed }   from '@fortawesome/pro-regular-svg-icons';
import { faDollyFlatbed as fasDollyFlatbed }   from '@fortawesome/pro-solid-svg-icons';
import { faDollyFlatbedAlt as fadDollyFlatbedAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faDollyFlatbedAlt as falDollyFlatbedAlt }   from '@fortawesome/pro-light-svg-icons';
import { faDollyFlatbedAlt as farDollyFlatbedAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faDollyFlatbedAlt as fasDollyFlatbedAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faDollyFlatbedEmpty as fadDollyFlatbedEmpty }   from '@fortawesome/pro-duotone-svg-icons';
import { faDollyFlatbedEmpty as falDollyFlatbedEmpty }   from '@fortawesome/pro-light-svg-icons';
import { faDollyFlatbedEmpty as farDollyFlatbedEmpty }   from '@fortawesome/pro-regular-svg-icons';
import { faDollyFlatbedEmpty as fasDollyFlatbedEmpty }   from '@fortawesome/pro-solid-svg-icons';
import { faDolphin as fadDolphin }   from '@fortawesome/pro-duotone-svg-icons';
import { faDolphin as falDolphin }   from '@fortawesome/pro-light-svg-icons';
import { faDolphin as farDolphin }   from '@fortawesome/pro-regular-svg-icons';
import { faDolphin as fasDolphin }   from '@fortawesome/pro-solid-svg-icons';
import { faDonate as fadDonate }   from '@fortawesome/pro-duotone-svg-icons';
import { faDonate as falDonate }   from '@fortawesome/pro-light-svg-icons';
import { faDonate as farDonate }   from '@fortawesome/pro-regular-svg-icons';
import { faDonate as fasDonate }   from '@fortawesome/pro-solid-svg-icons';
import { faDongSign as fadDongSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faDongSign as falDongSign }   from '@fortawesome/pro-light-svg-icons';
import { faDongSign as farDongSign }   from '@fortawesome/pro-regular-svg-icons';
import { faDongSign as fasDongSign }   from '@fortawesome/pro-solid-svg-icons';
import { faDoNotEnter as fadDoNotEnter }   from '@fortawesome/pro-duotone-svg-icons';
import { faDoNotEnter as falDoNotEnter }   from '@fortawesome/pro-light-svg-icons';
import { faDoNotEnter as farDoNotEnter }   from '@fortawesome/pro-regular-svg-icons';
import { faDoNotEnter as fasDoNotEnter }   from '@fortawesome/pro-solid-svg-icons';
import { faDonut as fadDonut }   from '@fortawesome/pro-duotone-svg-icons';
import { faDonut as falDonut }   from '@fortawesome/pro-light-svg-icons';
import { faDonut as farDonut }   from '@fortawesome/pro-regular-svg-icons';
import { faDonut as fasDonut }   from '@fortawesome/pro-solid-svg-icons';
import { faDoorClosed as fadDoorClosed }   from '@fortawesome/pro-duotone-svg-icons';
import { faDoorClosed as falDoorClosed }   from '@fortawesome/pro-light-svg-icons';
import { faDoorClosed as farDoorClosed }   from '@fortawesome/pro-regular-svg-icons';
import { faDoorClosed as fasDoorClosed }   from '@fortawesome/pro-solid-svg-icons';
import { faDoorOpen as fadDoorOpen }   from '@fortawesome/pro-duotone-svg-icons';
import { faDoorOpen as falDoorOpen }   from '@fortawesome/pro-light-svg-icons';
import { faDoorOpen as farDoorOpen }   from '@fortawesome/pro-regular-svg-icons';
import { faDoorOpen as fasDoorOpen }   from '@fortawesome/pro-solid-svg-icons';
import { faDotCircle as fadDotCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faDotCircle as falDotCircle }   from '@fortawesome/pro-light-svg-icons';
import { faDotCircle as farDotCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faDotCircle as fasDotCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faDoughnut as fadDoughnut }   from '@fortawesome/pro-duotone-svg-icons';
import { faDoughnut as falDoughnut }   from '@fortawesome/pro-light-svg-icons';
import { faDoughnut as farDoughnut }   from '@fortawesome/pro-regular-svg-icons';
import { faDoughnut as fasDoughnut }   from '@fortawesome/pro-solid-svg-icons';
import { faDove as fadDove }   from '@fortawesome/pro-duotone-svg-icons';
import { faDove as falDove }   from '@fortawesome/pro-light-svg-icons';
import { faDove as farDove }   from '@fortawesome/pro-regular-svg-icons';
import { faDove as fasDove }   from '@fortawesome/pro-solid-svg-icons';
import { faDown as fadDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faDown as falDown }   from '@fortawesome/pro-light-svg-icons';
import { faDown as farDown }   from '@fortawesome/pro-regular-svg-icons';
import { faDown as fasDown }   from '@fortawesome/pro-solid-svg-icons';
import { faDownFromDottedLine as fadDownFromDottedLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faDownFromDottedLine as falDownFromDottedLine }   from '@fortawesome/pro-light-svg-icons';
import { faDownFromDottedLine as farDownFromDottedLine }   from '@fortawesome/pro-regular-svg-icons';
import { faDownFromDottedLine as fasDownFromDottedLine }   from '@fortawesome/pro-solid-svg-icons';
import { faDownFromLine as fadDownFromLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faDownFromLine as falDownFromLine }   from '@fortawesome/pro-light-svg-icons';
import { faDownFromLine as farDownFromLine }   from '@fortawesome/pro-regular-svg-icons';
import { faDownFromLine as fasDownFromLine }   from '@fortawesome/pro-solid-svg-icons';
import { faDownLeft as fadDownLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faDownLeft as falDownLeft }   from '@fortawesome/pro-light-svg-icons';
import { faDownLeft as farDownLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faDownLeft as fasDownLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faDownLeftAndUpRightToCenter as fadDownLeftAndUpRightToCenter }   from '@fortawesome/pro-duotone-svg-icons';
import { faDownLeftAndUpRightToCenter as falDownLeftAndUpRightToCenter }   from '@fortawesome/pro-light-svg-icons';
import { faDownLeftAndUpRightToCenter as farDownLeftAndUpRightToCenter }   from '@fortawesome/pro-regular-svg-icons';
import { faDownLeftAndUpRightToCenter as fasDownLeftAndUpRightToCenter }   from '@fortawesome/pro-solid-svg-icons';
import { faDownload as fadDownload }   from '@fortawesome/pro-duotone-svg-icons';
import { faDownload as falDownload }   from '@fortawesome/pro-light-svg-icons';
import { faDownload as farDownload }   from '@fortawesome/pro-regular-svg-icons';
import { faDownload as fasDownload }   from '@fortawesome/pro-solid-svg-icons';
import { faDownLong as fadDownLong }   from '@fortawesome/pro-duotone-svg-icons';
import { faDownLong as falDownLong }   from '@fortawesome/pro-light-svg-icons';
import { faDownLong as farDownLong }   from '@fortawesome/pro-regular-svg-icons';
import { faDownLong as fasDownLong }   from '@fortawesome/pro-solid-svg-icons';
import { faDownRight as fadDownRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faDownRight as falDownRight }   from '@fortawesome/pro-light-svg-icons';
import { faDownRight as farDownRight }   from '@fortawesome/pro-regular-svg-icons';
import { faDownRight as fasDownRight }   from '@fortawesome/pro-solid-svg-icons';
import { faDownToBracket as fadDownToBracket }   from '@fortawesome/pro-duotone-svg-icons';
import { faDownToBracket as falDownToBracket }   from '@fortawesome/pro-light-svg-icons';
import { faDownToBracket as farDownToBracket }   from '@fortawesome/pro-regular-svg-icons';
import { faDownToBracket as fasDownToBracket }   from '@fortawesome/pro-solid-svg-icons';
import { faDownToDottedLine as fadDownToDottedLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faDownToDottedLine as falDownToDottedLine }   from '@fortawesome/pro-light-svg-icons';
import { faDownToDottedLine as farDownToDottedLine }   from '@fortawesome/pro-regular-svg-icons';
import { faDownToDottedLine as fasDownToDottedLine }   from '@fortawesome/pro-solid-svg-icons';
import { faDownToLine as fadDownToLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faDownToLine as falDownToLine }   from '@fortawesome/pro-light-svg-icons';
import { faDownToLine as farDownToLine }   from '@fortawesome/pro-regular-svg-icons';
import { faDownToLine as fasDownToLine }   from '@fortawesome/pro-solid-svg-icons';
import { faDraftingCompass as fadDraftingCompass }   from '@fortawesome/pro-duotone-svg-icons';
import { faDraftingCompass as falDraftingCompass }   from '@fortawesome/pro-light-svg-icons';
import { faDraftingCompass as farDraftingCompass }   from '@fortawesome/pro-regular-svg-icons';
import { faDraftingCompass as fasDraftingCompass }   from '@fortawesome/pro-solid-svg-icons';
import { faDragon as fadDragon }   from '@fortawesome/pro-duotone-svg-icons';
import { faDragon as falDragon }   from '@fortawesome/pro-light-svg-icons';
import { faDragon as farDragon }   from '@fortawesome/pro-regular-svg-icons';
import { faDragon as fasDragon }   from '@fortawesome/pro-solid-svg-icons';
import { faDrawCircle as fadDrawCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faDrawCircle as falDrawCircle }   from '@fortawesome/pro-light-svg-icons';
import { faDrawCircle as farDrawCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faDrawCircle as fasDrawCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faDrawPolygon as fadDrawPolygon }   from '@fortawesome/pro-duotone-svg-icons';
import { faDrawPolygon as falDrawPolygon }   from '@fortawesome/pro-light-svg-icons';
import { faDrawPolygon as farDrawPolygon }   from '@fortawesome/pro-regular-svg-icons';
import { faDrawPolygon as fasDrawPolygon }   from '@fortawesome/pro-solid-svg-icons';
import { faDrawSquare as fadDrawSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faDrawSquare as falDrawSquare }   from '@fortawesome/pro-light-svg-icons';
import { faDrawSquare as farDrawSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faDrawSquare as fasDrawSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faDreidel as fadDreidel }   from '@fortawesome/pro-duotone-svg-icons';
import { faDreidel as falDreidel }   from '@fortawesome/pro-light-svg-icons';
import { faDreidel as farDreidel }   from '@fortawesome/pro-regular-svg-icons';
import { faDreidel as fasDreidel }   from '@fortawesome/pro-solid-svg-icons';
import { faDriversLicense as fadDriversLicense }   from '@fortawesome/pro-duotone-svg-icons';
import { faDriversLicense as falDriversLicense }   from '@fortawesome/pro-light-svg-icons';
import { faDriversLicense as farDriversLicense }   from '@fortawesome/pro-regular-svg-icons';
import { faDriversLicense as fasDriversLicense }   from '@fortawesome/pro-solid-svg-icons';
import { faDrone as fadDrone }   from '@fortawesome/pro-duotone-svg-icons';
import { faDrone as falDrone }   from '@fortawesome/pro-light-svg-icons';
import { faDrone as farDrone }   from '@fortawesome/pro-regular-svg-icons';
import { faDrone as fasDrone }   from '@fortawesome/pro-solid-svg-icons';
import { faDroneAlt as fadDroneAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faDroneAlt as falDroneAlt }   from '@fortawesome/pro-light-svg-icons';
import { faDroneAlt as farDroneAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faDroneAlt as fasDroneAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faDroneFront as fadDroneFront }   from '@fortawesome/pro-duotone-svg-icons';
import { faDroneFront as falDroneFront }   from '@fortawesome/pro-light-svg-icons';
import { faDroneFront as farDroneFront }   from '@fortawesome/pro-regular-svg-icons';
import { faDroneFront as fasDroneFront }   from '@fortawesome/pro-solid-svg-icons';
import { faDroplet as fadDroplet }   from '@fortawesome/pro-duotone-svg-icons';
import { faDroplet as falDroplet }   from '@fortawesome/pro-light-svg-icons';
import { faDroplet as farDroplet }   from '@fortawesome/pro-regular-svg-icons';
import { faDroplet as fasDroplet }   from '@fortawesome/pro-solid-svg-icons';
import { faDropletDegree as fadDropletDegree }   from '@fortawesome/pro-duotone-svg-icons';
import { faDropletDegree as falDropletDegree }   from '@fortawesome/pro-light-svg-icons';
import { faDropletDegree as farDropletDegree }   from '@fortawesome/pro-regular-svg-icons';
import { faDropletDegree as fasDropletDegree }   from '@fortawesome/pro-solid-svg-icons';
import { faDropletPercent as fadDropletPercent }   from '@fortawesome/pro-duotone-svg-icons';
import { faDropletPercent as falDropletPercent }   from '@fortawesome/pro-light-svg-icons';
import { faDropletPercent as farDropletPercent }   from '@fortawesome/pro-regular-svg-icons';
import { faDropletPercent as fasDropletPercent }   from '@fortawesome/pro-solid-svg-icons';
import { faDropletSlash as fadDropletSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faDropletSlash as falDropletSlash }   from '@fortawesome/pro-light-svg-icons';
import { faDropletSlash as farDropletSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faDropletSlash as fasDropletSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faDrum as fadDrum }   from '@fortawesome/pro-duotone-svg-icons';
import { faDrum as falDrum }   from '@fortawesome/pro-light-svg-icons';
import { faDrum as farDrum }   from '@fortawesome/pro-regular-svg-icons';
import { faDrum as fasDrum }   from '@fortawesome/pro-solid-svg-icons';
import { faDrumSteelpan as fadDrumSteelpan }   from '@fortawesome/pro-duotone-svg-icons';
import { faDrumSteelpan as falDrumSteelpan }   from '@fortawesome/pro-light-svg-icons';
import { faDrumSteelpan as farDrumSteelpan }   from '@fortawesome/pro-regular-svg-icons';
import { faDrumSteelpan as fasDrumSteelpan }   from '@fortawesome/pro-solid-svg-icons';
import { faDrumstick as fadDrumstick }   from '@fortawesome/pro-duotone-svg-icons';
import { faDrumstick as falDrumstick }   from '@fortawesome/pro-light-svg-icons';
import { faDrumstick as farDrumstick }   from '@fortawesome/pro-regular-svg-icons';
import { faDrumstick as fasDrumstick }   from '@fortawesome/pro-solid-svg-icons';
import { faDrumstickBite as fadDrumstickBite }   from '@fortawesome/pro-duotone-svg-icons';
import { faDrumstickBite as falDrumstickBite }   from '@fortawesome/pro-light-svg-icons';
import { faDrumstickBite as farDrumstickBite }   from '@fortawesome/pro-regular-svg-icons';
import { faDrumstickBite as fasDrumstickBite }   from '@fortawesome/pro-solid-svg-icons';
import { faDryer as fadDryer }   from '@fortawesome/pro-duotone-svg-icons';
import { faDryer as falDryer }   from '@fortawesome/pro-light-svg-icons';
import { faDryer as farDryer }   from '@fortawesome/pro-regular-svg-icons';
import { faDryer as fasDryer }   from '@fortawesome/pro-solid-svg-icons';
import { faDryerAlt as fadDryerAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faDryerAlt as falDryerAlt }   from '@fortawesome/pro-light-svg-icons';
import { faDryerAlt as farDryerAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faDryerAlt as fasDryerAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faDryerHeat as fadDryerHeat }   from '@fortawesome/pro-duotone-svg-icons';
import { faDryerHeat as falDryerHeat }   from '@fortawesome/pro-light-svg-icons';
import { faDryerHeat as farDryerHeat }   from '@fortawesome/pro-regular-svg-icons';
import { faDryerHeat as fasDryerHeat }   from '@fortawesome/pro-solid-svg-icons';
import { faDuck as fadDuck }   from '@fortawesome/pro-duotone-svg-icons';
import { faDuck as falDuck }   from '@fortawesome/pro-light-svg-icons';
import { faDuck as farDuck }   from '@fortawesome/pro-regular-svg-icons';
import { faDuck as fasDuck }   from '@fortawesome/pro-solid-svg-icons';
import { faDumbbell as fadDumbbell }   from '@fortawesome/pro-duotone-svg-icons';
import { faDumbbell as falDumbbell }   from '@fortawesome/pro-light-svg-icons';
import { faDumbbell as farDumbbell }   from '@fortawesome/pro-regular-svg-icons';
import { faDumbbell as fasDumbbell }   from '@fortawesome/pro-solid-svg-icons';
import { faDumpster as fadDumpster }   from '@fortawesome/pro-duotone-svg-icons';
import { faDumpster as falDumpster }   from '@fortawesome/pro-light-svg-icons';
import { faDumpster as farDumpster }   from '@fortawesome/pro-regular-svg-icons';
import { faDumpster as fasDumpster }   from '@fortawesome/pro-solid-svg-icons';
import { faDumpsterFire as fadDumpsterFire }   from '@fortawesome/pro-duotone-svg-icons';
import { faDumpsterFire as falDumpsterFire }   from '@fortawesome/pro-light-svg-icons';
import { faDumpsterFire as farDumpsterFire }   from '@fortawesome/pro-regular-svg-icons';
import { faDumpsterFire as fasDumpsterFire }   from '@fortawesome/pro-solid-svg-icons';
import { faDungeon as fadDungeon }   from '@fortawesome/pro-duotone-svg-icons';
import { faDungeon as falDungeon }   from '@fortawesome/pro-light-svg-icons';
import { faDungeon as farDungeon }   from '@fortawesome/pro-regular-svg-icons';
import { faDungeon as fasDungeon }   from '@fortawesome/pro-solid-svg-icons';
import { faE as fadE }   from '@fortawesome/pro-duotone-svg-icons';
import { faE as falE }   from '@fortawesome/pro-light-svg-icons';
import { faE as farE }   from '@fortawesome/pro-regular-svg-icons';
import { faE as fasE }   from '@fortawesome/pro-solid-svg-icons';
import { faEar as fadEar }   from '@fortawesome/pro-duotone-svg-icons';
import { faEar as falEar }   from '@fortawesome/pro-light-svg-icons';
import { faEar as farEar }   from '@fortawesome/pro-regular-svg-icons';
import { faEar as fasEar }   from '@fortawesome/pro-solid-svg-icons';
import { faEarDeaf as fadEarDeaf }   from '@fortawesome/pro-duotone-svg-icons';
import { faEarDeaf as falEarDeaf }   from '@fortawesome/pro-light-svg-icons';
import { faEarDeaf as farEarDeaf }   from '@fortawesome/pro-regular-svg-icons';
import { faEarDeaf as fasEarDeaf }   from '@fortawesome/pro-solid-svg-icons';
import { faEarListen as fadEarListen }   from '@fortawesome/pro-duotone-svg-icons';
import { faEarListen as falEarListen }   from '@fortawesome/pro-light-svg-icons';
import { faEarListen as farEarListen }   from '@fortawesome/pro-regular-svg-icons';
import { faEarListen as fasEarListen }   from '@fortawesome/pro-solid-svg-icons';
import { faEarMuffs as fadEarMuffs }   from '@fortawesome/pro-duotone-svg-icons';
import { faEarMuffs as falEarMuffs }   from '@fortawesome/pro-light-svg-icons';
import { faEarMuffs as farEarMuffs }   from '@fortawesome/pro-regular-svg-icons';
import { faEarMuffs as fasEarMuffs }   from '@fortawesome/pro-solid-svg-icons';
import { faEarth as fadEarth }   from '@fortawesome/pro-duotone-svg-icons';
import { faEarth as falEarth }   from '@fortawesome/pro-light-svg-icons';
import { faEarth as farEarth }   from '@fortawesome/pro-regular-svg-icons';
import { faEarth as fasEarth }   from '@fortawesome/pro-solid-svg-icons';
import { faEarthAfrica as fadEarthAfrica }   from '@fortawesome/pro-duotone-svg-icons';
import { faEarthAfrica as falEarthAfrica }   from '@fortawesome/pro-light-svg-icons';
import { faEarthAfrica as farEarthAfrica }   from '@fortawesome/pro-regular-svg-icons';
import { faEarthAfrica as fasEarthAfrica }   from '@fortawesome/pro-solid-svg-icons';
import { faEarthAmerica as fadEarthAmerica }   from '@fortawesome/pro-duotone-svg-icons';
import { faEarthAmerica as falEarthAmerica }   from '@fortawesome/pro-light-svg-icons';
import { faEarthAmerica as farEarthAmerica }   from '@fortawesome/pro-regular-svg-icons';
import { faEarthAmerica as fasEarthAmerica }   from '@fortawesome/pro-solid-svg-icons';
import { faEarthAmericas as fadEarthAmericas }   from '@fortawesome/pro-duotone-svg-icons';
import { faEarthAmericas as falEarthAmericas }   from '@fortawesome/pro-light-svg-icons';
import { faEarthAmericas as farEarthAmericas }   from '@fortawesome/pro-regular-svg-icons';
import { faEarthAmericas as fasEarthAmericas }   from '@fortawesome/pro-solid-svg-icons';
import { faEarthAsia as fadEarthAsia }   from '@fortawesome/pro-duotone-svg-icons';
import { faEarthAsia as falEarthAsia }   from '@fortawesome/pro-light-svg-icons';
import { faEarthAsia as farEarthAsia }   from '@fortawesome/pro-regular-svg-icons';
import { faEarthAsia as fasEarthAsia }   from '@fortawesome/pro-solid-svg-icons';
import { faEarthEurope as fadEarthEurope }   from '@fortawesome/pro-duotone-svg-icons';
import { faEarthEurope as falEarthEurope }   from '@fortawesome/pro-light-svg-icons';
import { faEarthEurope as farEarthEurope }   from '@fortawesome/pro-regular-svg-icons';
import { faEarthEurope as fasEarthEurope }   from '@fortawesome/pro-solid-svg-icons';
import { faEarthOceania as fadEarthOceania }   from '@fortawesome/pro-duotone-svg-icons';
import { faEarthOceania as falEarthOceania }   from '@fortawesome/pro-light-svg-icons';
import { faEarthOceania as farEarthOceania }   from '@fortawesome/pro-regular-svg-icons';
import { faEarthOceania as fasEarthOceania }   from '@fortawesome/pro-solid-svg-icons';
import { faEclipse as fadEclipse }   from '@fortawesome/pro-duotone-svg-icons';
import { faEclipse as falEclipse }   from '@fortawesome/pro-light-svg-icons';
import { faEclipse as farEclipse }   from '@fortawesome/pro-regular-svg-icons';
import { faEclipse as fasEclipse }   from '@fortawesome/pro-solid-svg-icons';
import { faEclipseAlt as fadEclipseAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faEclipseAlt as falEclipseAlt }   from '@fortawesome/pro-light-svg-icons';
import { faEclipseAlt as farEclipseAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faEclipseAlt as fasEclipseAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faEdit as fadEdit }   from '@fortawesome/pro-duotone-svg-icons';
import { faEdit as falEdit }   from '@fortawesome/pro-light-svg-icons';
import { faEdit as farEdit }   from '@fortawesome/pro-regular-svg-icons';
import { faEdit as fasEdit }   from '@fortawesome/pro-solid-svg-icons';
import { faEgg as fadEgg }   from '@fortawesome/pro-duotone-svg-icons';
import { faEgg as falEgg }   from '@fortawesome/pro-light-svg-icons';
import { faEgg as farEgg }   from '@fortawesome/pro-regular-svg-icons';
import { faEgg as fasEgg }   from '@fortawesome/pro-solid-svg-icons';
import { faEggFried as fadEggFried }   from '@fortawesome/pro-duotone-svg-icons';
import { faEggFried as falEggFried }   from '@fortawesome/pro-light-svg-icons';
import { faEggFried as farEggFried }   from '@fortawesome/pro-regular-svg-icons';
import { faEggFried as fasEggFried }   from '@fortawesome/pro-solid-svg-icons';
import { faEggplant as fadEggplant }   from '@fortawesome/pro-duotone-svg-icons';
import { faEggplant as falEggplant }   from '@fortawesome/pro-light-svg-icons';
import { faEggplant as farEggplant }   from '@fortawesome/pro-regular-svg-icons';
import { faEggplant as fasEggplant }   from '@fortawesome/pro-solid-svg-icons';
import { faEject as fadEject }   from '@fortawesome/pro-duotone-svg-icons';
import { faEject as falEject }   from '@fortawesome/pro-light-svg-icons';
import { faEject as farEject }   from '@fortawesome/pro-regular-svg-icons';
import { faEject as fasEject }   from '@fortawesome/pro-solid-svg-icons';
import { faElephant as fadElephant }   from '@fortawesome/pro-duotone-svg-icons';
import { faElephant as falElephant }   from '@fortawesome/pro-light-svg-icons';
import { faElephant as farElephant }   from '@fortawesome/pro-regular-svg-icons';
import { faElephant as fasElephant }   from '@fortawesome/pro-solid-svg-icons';
import { faElevator as fadElevator }   from '@fortawesome/pro-duotone-svg-icons';
import { faElevator as falElevator }   from '@fortawesome/pro-light-svg-icons';
import { faElevator as farElevator }   from '@fortawesome/pro-regular-svg-icons';
import { faElevator as fasElevator }   from '@fortawesome/pro-solid-svg-icons';
import { faEllipsis as fadEllipsis }   from '@fortawesome/pro-duotone-svg-icons';
import { faEllipsis as falEllipsis }   from '@fortawesome/pro-light-svg-icons';
import { faEllipsis as farEllipsis }   from '@fortawesome/pro-regular-svg-icons';
import { faEllipsis as fasEllipsis }   from '@fortawesome/pro-solid-svg-icons';
import { faEllipsisH as fadEllipsisH }   from '@fortawesome/pro-duotone-svg-icons';
import { faEllipsisH as falEllipsisH }   from '@fortawesome/pro-light-svg-icons';
import { faEllipsisH as farEllipsisH }   from '@fortawesome/pro-regular-svg-icons';
import { faEllipsisH as fasEllipsisH }   from '@fortawesome/pro-solid-svg-icons';
import { faEllipsisHAlt as fadEllipsisHAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faEllipsisHAlt as falEllipsisHAlt }   from '@fortawesome/pro-light-svg-icons';
import { faEllipsisHAlt as farEllipsisHAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faEllipsisHAlt as fasEllipsisHAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faEllipsisStroke as fadEllipsisStroke }   from '@fortawesome/pro-duotone-svg-icons';
import { faEllipsisStroke as falEllipsisStroke }   from '@fortawesome/pro-light-svg-icons';
import { faEllipsisStroke as farEllipsisStroke }   from '@fortawesome/pro-regular-svg-icons';
import { faEllipsisStroke as fasEllipsisStroke }   from '@fortawesome/pro-solid-svg-icons';
import { faEllipsisStrokeVertical as fadEllipsisStrokeVertical }   from '@fortawesome/pro-duotone-svg-icons';
import { faEllipsisStrokeVertical as falEllipsisStrokeVertical }   from '@fortawesome/pro-light-svg-icons';
import { faEllipsisStrokeVertical as farEllipsisStrokeVertical }   from '@fortawesome/pro-regular-svg-icons';
import { faEllipsisStrokeVertical as fasEllipsisStrokeVertical }   from '@fortawesome/pro-solid-svg-icons';
import { faEllipsisV as fadEllipsisV }   from '@fortawesome/pro-duotone-svg-icons';
import { faEllipsisV as falEllipsisV }   from '@fortawesome/pro-light-svg-icons';
import { faEllipsisV as farEllipsisV }   from '@fortawesome/pro-regular-svg-icons';
import { faEllipsisV as fasEllipsisV }   from '@fortawesome/pro-solid-svg-icons';
import { faEllipsisVAlt as fadEllipsisVAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faEllipsisVAlt as falEllipsisVAlt }   from '@fortawesome/pro-light-svg-icons';
import { faEllipsisVAlt as farEllipsisVAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faEllipsisVAlt as fasEllipsisVAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faEllipsisVertical as fadEllipsisVertical }   from '@fortawesome/pro-duotone-svg-icons';
import { faEllipsisVertical as falEllipsisVertical }   from '@fortawesome/pro-light-svg-icons';
import { faEllipsisVertical as farEllipsisVertical }   from '@fortawesome/pro-regular-svg-icons';
import { faEllipsisVertical as fasEllipsisVertical }   from '@fortawesome/pro-solid-svg-icons';
import { faEmptySet as fadEmptySet }   from '@fortawesome/pro-duotone-svg-icons';
import { faEmptySet as falEmptySet }   from '@fortawesome/pro-light-svg-icons';
import { faEmptySet as farEmptySet }   from '@fortawesome/pro-regular-svg-icons';
import { faEmptySet as fasEmptySet }   from '@fortawesome/pro-solid-svg-icons';
import { faEngine as fadEngine }   from '@fortawesome/pro-duotone-svg-icons';
import { faEngine as falEngine }   from '@fortawesome/pro-light-svg-icons';
import { faEngine as farEngine }   from '@fortawesome/pro-regular-svg-icons';
import { faEngine as fasEngine }   from '@fortawesome/pro-solid-svg-icons';
import { faEngineExclamation as fadEngineExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faEngineExclamation as falEngineExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faEngineExclamation as farEngineExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faEngineExclamation as fasEngineExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faEngineWarning as fadEngineWarning }   from '@fortawesome/pro-duotone-svg-icons';
import { faEngineWarning as falEngineWarning }   from '@fortawesome/pro-light-svg-icons';
import { faEngineWarning as farEngineWarning }   from '@fortawesome/pro-regular-svg-icons';
import { faEngineWarning as fasEngineWarning }   from '@fortawesome/pro-solid-svg-icons';
import { faEnvelope as fadEnvelope }   from '@fortawesome/pro-duotone-svg-icons';
import { faEnvelope as falEnvelope }   from '@fortawesome/pro-light-svg-icons';
import { faEnvelope as farEnvelope }   from '@fortawesome/pro-regular-svg-icons';
import { faEnvelope as fasEnvelope }   from '@fortawesome/pro-solid-svg-icons';
import { faEnvelopeBadge as fadEnvelopeBadge }   from '@fortawesome/pro-duotone-svg-icons';
import { faEnvelopeBadge as falEnvelopeBadge }   from '@fortawesome/pro-light-svg-icons';
import { faEnvelopeBadge as farEnvelopeBadge }   from '@fortawesome/pro-regular-svg-icons';
import { faEnvelopeBadge as fasEnvelopeBadge }   from '@fortawesome/pro-solid-svg-icons';
import { faEnvelopeCircle as fadEnvelopeCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faEnvelopeCircle as falEnvelopeCircle }   from '@fortawesome/pro-light-svg-icons';
import { faEnvelopeCircle as farEnvelopeCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faEnvelopeCircle as fasEnvelopeCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faEnvelopeCircleCheck as fadEnvelopeCircleCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faEnvelopeCircleCheck as falEnvelopeCircleCheck }   from '@fortawesome/pro-light-svg-icons';
import { faEnvelopeCircleCheck as farEnvelopeCircleCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faEnvelopeCircleCheck as fasEnvelopeCircleCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faEnvelopeDot as fadEnvelopeDot }   from '@fortawesome/pro-duotone-svg-icons';
import { faEnvelopeDot as falEnvelopeDot }   from '@fortawesome/pro-light-svg-icons';
import { faEnvelopeDot as farEnvelopeDot }   from '@fortawesome/pro-regular-svg-icons';
import { faEnvelopeDot as fasEnvelopeDot }   from '@fortawesome/pro-solid-svg-icons';
import { faEnvelopeOpen as fadEnvelopeOpen }   from '@fortawesome/pro-duotone-svg-icons';
import { faEnvelopeOpen as falEnvelopeOpen }   from '@fortawesome/pro-light-svg-icons';
import { faEnvelopeOpen as farEnvelopeOpen }   from '@fortawesome/pro-regular-svg-icons';
import { faEnvelopeOpen as fasEnvelopeOpen }   from '@fortawesome/pro-solid-svg-icons';
import { faEnvelopeOpenDollar as fadEnvelopeOpenDollar }   from '@fortawesome/pro-duotone-svg-icons';
import { faEnvelopeOpenDollar as falEnvelopeOpenDollar }   from '@fortawesome/pro-light-svg-icons';
import { faEnvelopeOpenDollar as farEnvelopeOpenDollar }   from '@fortawesome/pro-regular-svg-icons';
import { faEnvelopeOpenDollar as fasEnvelopeOpenDollar }   from '@fortawesome/pro-solid-svg-icons';
import { faEnvelopeOpenText as fadEnvelopeOpenText }   from '@fortawesome/pro-duotone-svg-icons';
import { faEnvelopeOpenText as falEnvelopeOpenText }   from '@fortawesome/pro-light-svg-icons';
import { faEnvelopeOpenText as farEnvelopeOpenText }   from '@fortawesome/pro-regular-svg-icons';
import { faEnvelopeOpenText as fasEnvelopeOpenText }   from '@fortawesome/pro-solid-svg-icons';
import { faEnvelopes as fadEnvelopes }   from '@fortawesome/pro-duotone-svg-icons';
import { faEnvelopes as falEnvelopes }   from '@fortawesome/pro-light-svg-icons';
import { faEnvelopes as farEnvelopes }   from '@fortawesome/pro-regular-svg-icons';
import { faEnvelopes as fasEnvelopes }   from '@fortawesome/pro-solid-svg-icons';
import { faEnvelopesBulk as fadEnvelopesBulk }   from '@fortawesome/pro-duotone-svg-icons';
import { faEnvelopesBulk as falEnvelopesBulk }   from '@fortawesome/pro-light-svg-icons';
import { faEnvelopesBulk as farEnvelopesBulk }   from '@fortawesome/pro-regular-svg-icons';
import { faEnvelopesBulk as fasEnvelopesBulk }   from '@fortawesome/pro-solid-svg-icons';
import { faEnvelopeSquare as fadEnvelopeSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faEnvelopeSquare as falEnvelopeSquare }   from '@fortawesome/pro-light-svg-icons';
import { faEnvelopeSquare as farEnvelopeSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faEnvelopeSquare as fasEnvelopeSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faEquals as fadEquals }   from '@fortawesome/pro-duotone-svg-icons';
import { faEquals as falEquals }   from '@fortawesome/pro-light-svg-icons';
import { faEquals as farEquals }   from '@fortawesome/pro-regular-svg-icons';
import { faEquals as fasEquals }   from '@fortawesome/pro-solid-svg-icons';
import { faEraser as fadEraser }   from '@fortawesome/pro-duotone-svg-icons';
import { faEraser as falEraser }   from '@fortawesome/pro-light-svg-icons';
import { faEraser as farEraser }   from '@fortawesome/pro-regular-svg-icons';
import { faEraser as fasEraser }   from '@fortawesome/pro-solid-svg-icons';
import { faEscalator as fadEscalator }   from '@fortawesome/pro-duotone-svg-icons';
import { faEscalator as falEscalator }   from '@fortawesome/pro-light-svg-icons';
import { faEscalator as farEscalator }   from '@fortawesome/pro-regular-svg-icons';
import { faEscalator as fasEscalator }   from '@fortawesome/pro-solid-svg-icons';
import { faEthernet as fadEthernet }   from '@fortawesome/pro-duotone-svg-icons';
import { faEthernet as falEthernet }   from '@fortawesome/pro-light-svg-icons';
import { faEthernet as farEthernet }   from '@fortawesome/pro-regular-svg-icons';
import { faEthernet as fasEthernet }   from '@fortawesome/pro-solid-svg-icons';
import { faEur as fadEur }   from '@fortawesome/pro-duotone-svg-icons';
import { faEur as falEur }   from '@fortawesome/pro-light-svg-icons';
import { faEur as farEur }   from '@fortawesome/pro-regular-svg-icons';
import { faEur as fasEur }   from '@fortawesome/pro-solid-svg-icons';
import { faEuro as fadEuro }   from '@fortawesome/pro-duotone-svg-icons';
import { faEuro as falEuro }   from '@fortawesome/pro-light-svg-icons';
import { faEuro as farEuro }   from '@fortawesome/pro-regular-svg-icons';
import { faEuro as fasEuro }   from '@fortawesome/pro-solid-svg-icons';
import { faEuroSign as fadEuroSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faEuroSign as falEuroSign }   from '@fortawesome/pro-light-svg-icons';
import { faEuroSign as farEuroSign }   from '@fortawesome/pro-regular-svg-icons';
import { faEuroSign as fasEuroSign }   from '@fortawesome/pro-solid-svg-icons';
import { faExchange as fadExchange }   from '@fortawesome/pro-duotone-svg-icons';
import { faExchange as falExchange }   from '@fortawesome/pro-light-svg-icons';
import { faExchange as farExchange }   from '@fortawesome/pro-regular-svg-icons';
import { faExchange as fasExchange }   from '@fortawesome/pro-solid-svg-icons';
import { faExchangeAlt as fadExchangeAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faExchangeAlt as falExchangeAlt }   from '@fortawesome/pro-light-svg-icons';
import { faExchangeAlt as farExchangeAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faExchangeAlt as fasExchangeAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faExclamation as fadExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faExclamation as falExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faExclamation as farExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faExclamation as fasExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faExclamationCircle as fadExclamationCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faExclamationCircle as falExclamationCircle }   from '@fortawesome/pro-light-svg-icons';
import { faExclamationCircle as farExclamationCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faExclamationCircle as fasExclamationCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faExclamationSquare as fadExclamationSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faExclamationSquare as falExclamationSquare }   from '@fortawesome/pro-light-svg-icons';
import { faExclamationSquare as farExclamationSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faExclamationSquare as fasExclamationSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faExclamationTriangle as fadExclamationTriangle }   from '@fortawesome/pro-duotone-svg-icons';
import { faExclamationTriangle as falExclamationTriangle }   from '@fortawesome/pro-light-svg-icons';
import { faExclamationTriangle as farExclamationTriangle }   from '@fortawesome/pro-regular-svg-icons';
import { faExclamationTriangle as fasExclamationTriangle }   from '@fortawesome/pro-solid-svg-icons';
import { faExpand as fadExpand }   from '@fortawesome/pro-duotone-svg-icons';
import { faExpand as falExpand }   from '@fortawesome/pro-light-svg-icons';
import { faExpand as farExpand }   from '@fortawesome/pro-regular-svg-icons';
import { faExpand as fasExpand }   from '@fortawesome/pro-solid-svg-icons';
import { faExpandAlt as fadExpandAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faExpandAlt as falExpandAlt }   from '@fortawesome/pro-light-svg-icons';
import { faExpandAlt as farExpandAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faExpandAlt as fasExpandAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faExpandArrows as fadExpandArrows }   from '@fortawesome/pro-duotone-svg-icons';
import { faExpandArrows as falExpandArrows }   from '@fortawesome/pro-light-svg-icons';
import { faExpandArrows as farExpandArrows }   from '@fortawesome/pro-regular-svg-icons';
import { faExpandArrows as fasExpandArrows }   from '@fortawesome/pro-solid-svg-icons';
import { faExpandArrowsAlt as fadExpandArrowsAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faExpandArrowsAlt as falExpandArrowsAlt }   from '@fortawesome/pro-light-svg-icons';
import { faExpandArrowsAlt as farExpandArrowsAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faExpandArrowsAlt as fasExpandArrowsAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faExpandWide as fadExpandWide }   from '@fortawesome/pro-duotone-svg-icons';
import { faExpandWide as falExpandWide }   from '@fortawesome/pro-light-svg-icons';
import { faExpandWide as farExpandWide }   from '@fortawesome/pro-regular-svg-icons';
import { faExpandWide as fasExpandWide }   from '@fortawesome/pro-solid-svg-icons';
import { faExplodingHead as fadExplodingHead }   from '@fortawesome/pro-duotone-svg-icons';
import { faExplodingHead as falExplodingHead }   from '@fortawesome/pro-light-svg-icons';
import { faExplodingHead as farExplodingHead }   from '@fortawesome/pro-regular-svg-icons';
import { faExplodingHead as fasExplodingHead }   from '@fortawesome/pro-solid-svg-icons';
import { faExplosion as fadExplosion }   from '@fortawesome/pro-duotone-svg-icons';
import { faExplosion as falExplosion }   from '@fortawesome/pro-light-svg-icons';
import { faExplosion as farExplosion }   from '@fortawesome/pro-regular-svg-icons';
import { faExplosion as fasExplosion }   from '@fortawesome/pro-solid-svg-icons';
import { faExternalLink as fadExternalLink }   from '@fortawesome/pro-duotone-svg-icons';
import { faExternalLink as falExternalLink }   from '@fortawesome/pro-light-svg-icons';
import { faExternalLink as farExternalLink }   from '@fortawesome/pro-regular-svg-icons';
import { faExternalLink as fasExternalLink }   from '@fortawesome/pro-solid-svg-icons';
import { faExternalLinkAlt as fadExternalLinkAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faExternalLinkAlt as falExternalLinkAlt }   from '@fortawesome/pro-light-svg-icons';
import { faExternalLinkAlt as farExternalLinkAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faExternalLinkAlt as fasExternalLinkAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faExternalLinkSquare as fadExternalLinkSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faExternalLinkSquare as falExternalLinkSquare }   from '@fortawesome/pro-light-svg-icons';
import { faExternalLinkSquare as farExternalLinkSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faExternalLinkSquare as fasExternalLinkSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faExternalLinkSquareAlt as fadExternalLinkSquareAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faExternalLinkSquareAlt as falExternalLinkSquareAlt }   from '@fortawesome/pro-light-svg-icons';
import { faExternalLinkSquareAlt as farExternalLinkSquareAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faExternalLinkSquareAlt as fasExternalLinkSquareAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faEye as fadEye }   from '@fortawesome/pro-duotone-svg-icons';
import { faEye as falEye }   from '@fortawesome/pro-light-svg-icons';
import { faEye as farEye }   from '@fortawesome/pro-regular-svg-icons';
import { faEye as fasEye }   from '@fortawesome/pro-solid-svg-icons';
import { faEyeDropper as fadEyeDropper }   from '@fortawesome/pro-duotone-svg-icons';
import { faEyedropper as fadEyedropper }   from '@fortawesome/pro-duotone-svg-icons';
import { faEyeDropper as falEyeDropper }   from '@fortawesome/pro-light-svg-icons';
import { faEyedropper as falEyedropper }   from '@fortawesome/pro-light-svg-icons';
import { faEyeDropper as farEyeDropper }   from '@fortawesome/pro-regular-svg-icons';
import { faEyedropper as farEyedropper }   from '@fortawesome/pro-regular-svg-icons';
import { faEyeDropper as fasEyeDropper }   from '@fortawesome/pro-solid-svg-icons';
import { faEyedropper as fasEyedropper }   from '@fortawesome/pro-solid-svg-icons';
import { faEyeDropperEmpty as fadEyeDropperEmpty }   from '@fortawesome/pro-duotone-svg-icons';
import { faEyeDropperEmpty as falEyeDropperEmpty }   from '@fortawesome/pro-light-svg-icons';
import { faEyeDropperEmpty as farEyeDropperEmpty }   from '@fortawesome/pro-regular-svg-icons';
import { faEyeDropperEmpty as fasEyeDropperEmpty }   from '@fortawesome/pro-solid-svg-icons';
import { faEyeDropperFull as fadEyeDropperFull }   from '@fortawesome/pro-duotone-svg-icons';
import { faEyeDropperFull as falEyeDropperFull }   from '@fortawesome/pro-light-svg-icons';
import { faEyeDropperFull as farEyeDropperFull }   from '@fortawesome/pro-regular-svg-icons';
import { faEyeDropperFull as fasEyeDropperFull }   from '@fortawesome/pro-solid-svg-icons';
import { faEyeDropperHalf as fadEyeDropperHalf }   from '@fortawesome/pro-duotone-svg-icons';
import { faEyeDropperHalf as falEyeDropperHalf }   from '@fortawesome/pro-light-svg-icons';
import { faEyeDropperHalf as farEyeDropperHalf }   from '@fortawesome/pro-regular-svg-icons';
import { faEyeDropperHalf as fasEyeDropperHalf }   from '@fortawesome/pro-solid-svg-icons';
import { faEyeEvil as fadEyeEvil }   from '@fortawesome/pro-duotone-svg-icons';
import { faEyeEvil as falEyeEvil }   from '@fortawesome/pro-light-svg-icons';
import { faEyeEvil as farEyeEvil }   from '@fortawesome/pro-regular-svg-icons';
import { faEyeEvil as fasEyeEvil }   from '@fortawesome/pro-solid-svg-icons';
import { faEyeLowVision as fadEyeLowVision }   from '@fortawesome/pro-duotone-svg-icons';
import { faEyeLowVision as falEyeLowVision }   from '@fortawesome/pro-light-svg-icons';
import { faEyeLowVision as farEyeLowVision }   from '@fortawesome/pro-regular-svg-icons';
import { faEyeLowVision as fasEyeLowVision }   from '@fortawesome/pro-solid-svg-icons';
import { faEyes as fadEyes }   from '@fortawesome/pro-duotone-svg-icons';
import { faEyes as falEyes }   from '@fortawesome/pro-light-svg-icons';
import { faEyes as farEyes }   from '@fortawesome/pro-regular-svg-icons';
import { faEyes as fasEyes }   from '@fortawesome/pro-solid-svg-icons';
import { faEyeSlash as fadEyeSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faEyeSlash as falEyeSlash }   from '@fortawesome/pro-light-svg-icons';
import { faEyeSlash as farEyeSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faEyeSlash as fasEyeSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faF as fadF }   from '@fortawesome/pro-duotone-svg-icons';
import { faF as falF }   from '@fortawesome/pro-light-svg-icons';
import { faF as farF }   from '@fortawesome/pro-regular-svg-icons';
import { faF as fasF }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceAngry as fadFaceAngry }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceAngry as falFaceAngry }   from '@fortawesome/pro-light-svg-icons';
import { faFaceAngry as farFaceAngry }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceAngry as fasFaceAngry }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceAngryHorns as fadFaceAngryHorns }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceAngryHorns as falFaceAngryHorns }   from '@fortawesome/pro-light-svg-icons';
import { faFaceAngryHorns as farFaceAngryHorns }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceAngryHorns as fasFaceAngryHorns }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceAnguished as fadFaceAnguished }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceAnguished as falFaceAnguished }   from '@fortawesome/pro-light-svg-icons';
import { faFaceAnguished as farFaceAnguished }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceAnguished as fasFaceAnguished }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceAnxiousSweat as fadFaceAnxiousSweat }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceAnxiousSweat as falFaceAnxiousSweat }   from '@fortawesome/pro-light-svg-icons';
import { faFaceAnxiousSweat as farFaceAnxiousSweat }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceAnxiousSweat as fasFaceAnxiousSweat }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceAstonished as fadFaceAstonished }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceAstonished as falFaceAstonished }   from '@fortawesome/pro-light-svg-icons';
import { faFaceAstonished as farFaceAstonished }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceAstonished as fasFaceAstonished }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceAwesome as fadFaceAwesome }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceAwesome as falFaceAwesome }   from '@fortawesome/pro-light-svg-icons';
import { faFaceAwesome as farFaceAwesome }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceAwesome as fasFaceAwesome }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceBeamHandOverMouth as fadFaceBeamHandOverMouth }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceBeamHandOverMouth as falFaceBeamHandOverMouth }   from '@fortawesome/pro-light-svg-icons';
import { faFaceBeamHandOverMouth as farFaceBeamHandOverMouth }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceBeamHandOverMouth as fasFaceBeamHandOverMouth }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceClouds as fadFaceClouds }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceClouds as falFaceClouds }   from '@fortawesome/pro-light-svg-icons';
import { faFaceClouds as farFaceClouds }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceClouds as fasFaceClouds }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceConfounded as fadFaceConfounded }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceConfounded as falFaceConfounded }   from '@fortawesome/pro-light-svg-icons';
import { faFaceConfounded as farFaceConfounded }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceConfounded as fasFaceConfounded }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceConfused as fadFaceConfused }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceConfused as falFaceConfused }   from '@fortawesome/pro-light-svg-icons';
import { faFaceConfused as farFaceConfused }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceConfused as fasFaceConfused }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceCowboyHat as fadFaceCowboyHat }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceCowboyHat as falFaceCowboyHat }   from '@fortawesome/pro-light-svg-icons';
import { faFaceCowboyHat as farFaceCowboyHat }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceCowboyHat as fasFaceCowboyHat }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceDiagonalMouth as fadFaceDiagonalMouth }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceDiagonalMouth as falFaceDiagonalMouth }   from '@fortawesome/pro-light-svg-icons';
import { faFaceDiagonalMouth as farFaceDiagonalMouth }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceDiagonalMouth as fasFaceDiagonalMouth }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceDisappointed as fadFaceDisappointed }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceDisappointed as falFaceDisappointed }   from '@fortawesome/pro-light-svg-icons';
import { faFaceDisappointed as farFaceDisappointed }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceDisappointed as fasFaceDisappointed }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceDisguise as fadFaceDisguise }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceDisguise as falFaceDisguise }   from '@fortawesome/pro-light-svg-icons';
import { faFaceDisguise as farFaceDisguise }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceDisguise as fasFaceDisguise }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceDizzy as fadFaceDizzy }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceDizzy as falFaceDizzy }   from '@fortawesome/pro-light-svg-icons';
import { faFaceDizzy as farFaceDizzy }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceDizzy as fasFaceDizzy }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceDotted as fadFaceDotted }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceDotted as falFaceDotted }   from '@fortawesome/pro-light-svg-icons';
import { faFaceDotted as farFaceDotted }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceDotted as fasFaceDotted }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceDowncastSweat as fadFaceDowncastSweat }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceDowncastSweat as falFaceDowncastSweat }   from '@fortawesome/pro-light-svg-icons';
import { faFaceDowncastSweat as farFaceDowncastSweat }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceDowncastSweat as fasFaceDowncastSweat }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceDrooling as fadFaceDrooling }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceDrooling as falFaceDrooling }   from '@fortawesome/pro-light-svg-icons';
import { faFaceDrooling as farFaceDrooling }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceDrooling as fasFaceDrooling }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceExhaling as fadFaceExhaling }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceExhaling as falFaceExhaling }   from '@fortawesome/pro-light-svg-icons';
import { faFaceExhaling as farFaceExhaling }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceExhaling as fasFaceExhaling }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceExplode as fadFaceExplode }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceExplode as falFaceExplode }   from '@fortawesome/pro-light-svg-icons';
import { faFaceExplode as farFaceExplode }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceExplode as fasFaceExplode }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceExpressionless as fadFaceExpressionless }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceExpressionless as falFaceExpressionless }   from '@fortawesome/pro-light-svg-icons';
import { faFaceExpressionless as farFaceExpressionless }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceExpressionless as fasFaceExpressionless }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceEyesXmarks as fadFaceEyesXmarks }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceEyesXmarks as falFaceEyesXmarks }   from '@fortawesome/pro-light-svg-icons';
import { faFaceEyesXmarks as farFaceEyesXmarks }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceEyesXmarks as fasFaceEyesXmarks }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceFearful as fadFaceFearful }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceFearful as falFaceFearful }   from '@fortawesome/pro-light-svg-icons';
import { faFaceFearful as farFaceFearful }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceFearful as fasFaceFearful }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceFlushed as fadFaceFlushed }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceFlushed as falFaceFlushed }   from '@fortawesome/pro-light-svg-icons';
import { faFaceFlushed as farFaceFlushed }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceFlushed as fasFaceFlushed }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceFrown as fadFaceFrown }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceFrown as falFaceFrown }   from '@fortawesome/pro-light-svg-icons';
import { faFaceFrown as farFaceFrown }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceFrown as fasFaceFrown }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceFrownOpen as fadFaceFrownOpen }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceFrownOpen as falFaceFrownOpen }   from '@fortawesome/pro-light-svg-icons';
import { faFaceFrownOpen as farFaceFrownOpen }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceFrownOpen as fasFaceFrownOpen }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceFrownSlight as fadFaceFrownSlight }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceFrownSlight as falFaceFrownSlight }   from '@fortawesome/pro-light-svg-icons';
import { faFaceFrownSlight as farFaceFrownSlight }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceFrownSlight as fasFaceFrownSlight }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceGlasses as fadFaceGlasses }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceGlasses as falFaceGlasses }   from '@fortawesome/pro-light-svg-icons';
import { faFaceGlasses as farFaceGlasses }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceGlasses as fasFaceGlasses }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceGrimace as fadFaceGrimace }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceGrimace as falFaceGrimace }   from '@fortawesome/pro-light-svg-icons';
import { faFaceGrimace as farFaceGrimace }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceGrimace as fasFaceGrimace }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceGrin as fadFaceGrin }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceGrin as falFaceGrin }   from '@fortawesome/pro-light-svg-icons';
import { faFaceGrin as farFaceGrin }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceGrin as fasFaceGrin }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceGrinBeam as fadFaceGrinBeam }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceGrinBeam as falFaceGrinBeam }   from '@fortawesome/pro-light-svg-icons';
import { faFaceGrinBeam as farFaceGrinBeam }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceGrinBeam as fasFaceGrinBeam }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceGrinBeamSweat as fadFaceGrinBeamSweat }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceGrinBeamSweat as falFaceGrinBeamSweat }   from '@fortawesome/pro-light-svg-icons';
import { faFaceGrinBeamSweat as farFaceGrinBeamSweat }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceGrinBeamSweat as fasFaceGrinBeamSweat }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceGrinHearts as fadFaceGrinHearts }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceGrinHearts as falFaceGrinHearts }   from '@fortawesome/pro-light-svg-icons';
import { faFaceGrinHearts as farFaceGrinHearts }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceGrinHearts as fasFaceGrinHearts }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceGrinSquint as fadFaceGrinSquint }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceGrinSquint as falFaceGrinSquint }   from '@fortawesome/pro-light-svg-icons';
import { faFaceGrinSquint as farFaceGrinSquint }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceGrinSquint as fasFaceGrinSquint }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceGrinSquintTears as fadFaceGrinSquintTears }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceGrinSquintTears as falFaceGrinSquintTears }   from '@fortawesome/pro-light-svg-icons';
import { faFaceGrinSquintTears as farFaceGrinSquintTears }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceGrinSquintTears as fasFaceGrinSquintTears }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceGrinStars as fadFaceGrinStars }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceGrinStars as falFaceGrinStars }   from '@fortawesome/pro-light-svg-icons';
import { faFaceGrinStars as farFaceGrinStars }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceGrinStars as fasFaceGrinStars }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceGrinTears as fadFaceGrinTears }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceGrinTears as falFaceGrinTears }   from '@fortawesome/pro-light-svg-icons';
import { faFaceGrinTears as farFaceGrinTears }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceGrinTears as fasFaceGrinTears }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceGrinTongue as fadFaceGrinTongue }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceGrinTongue as falFaceGrinTongue }   from '@fortawesome/pro-light-svg-icons';
import { faFaceGrinTongue as farFaceGrinTongue }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceGrinTongue as fasFaceGrinTongue }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceGrinTongueSquint as fadFaceGrinTongueSquint }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceGrinTongueSquint as falFaceGrinTongueSquint }   from '@fortawesome/pro-light-svg-icons';
import { faFaceGrinTongueSquint as farFaceGrinTongueSquint }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceGrinTongueSquint as fasFaceGrinTongueSquint }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceGrinTongueWink as fadFaceGrinTongueWink }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceGrinTongueWink as falFaceGrinTongueWink }   from '@fortawesome/pro-light-svg-icons';
import { faFaceGrinTongueWink as farFaceGrinTongueWink }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceGrinTongueWink as fasFaceGrinTongueWink }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceGrinWide as fadFaceGrinWide }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceGrinWide as falFaceGrinWide }   from '@fortawesome/pro-light-svg-icons';
import { faFaceGrinWide as farFaceGrinWide }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceGrinWide as fasFaceGrinWide }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceGrinWink as fadFaceGrinWink }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceGrinWink as falFaceGrinWink }   from '@fortawesome/pro-light-svg-icons';
import { faFaceGrinWink as farFaceGrinWink }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceGrinWink as fasFaceGrinWink }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceHandOverMouth as fadFaceHandOverMouth }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceHandOverMouth as falFaceHandOverMouth }   from '@fortawesome/pro-light-svg-icons';
import { faFaceHandOverMouth as farFaceHandOverMouth }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceHandOverMouth as fasFaceHandOverMouth }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceHandPeeking as fadFaceHandPeeking }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceHandPeeking as falFaceHandPeeking }   from '@fortawesome/pro-light-svg-icons';
import { faFaceHandPeeking as farFaceHandPeeking }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceHandPeeking as fasFaceHandPeeking }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceHandYawn as fadFaceHandYawn }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceHandYawn as falFaceHandYawn }   from '@fortawesome/pro-light-svg-icons';
import { faFaceHandYawn as farFaceHandYawn }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceHandYawn as fasFaceHandYawn }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceHeadBandage as fadFaceHeadBandage }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceHeadBandage as falFaceHeadBandage }   from '@fortawesome/pro-light-svg-icons';
import { faFaceHeadBandage as farFaceHeadBandage }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceHeadBandage as fasFaceHeadBandage }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceHoldingBackTears as fadFaceHoldingBackTears }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceHoldingBackTears as falFaceHoldingBackTears }   from '@fortawesome/pro-light-svg-icons';
import { faFaceHoldingBackTears as farFaceHoldingBackTears }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceHoldingBackTears as fasFaceHoldingBackTears }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceHushed as fadFaceHushed }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceHushed as falFaceHushed }   from '@fortawesome/pro-light-svg-icons';
import { faFaceHushed as farFaceHushed }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceHushed as fasFaceHushed }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceIcicles as fadFaceIcicles }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceIcicles as falFaceIcicles }   from '@fortawesome/pro-light-svg-icons';
import { faFaceIcicles as farFaceIcicles }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceIcicles as fasFaceIcicles }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceKiss as fadFaceKiss }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceKiss as falFaceKiss }   from '@fortawesome/pro-light-svg-icons';
import { faFaceKiss as farFaceKiss }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceKiss as fasFaceKiss }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceKissBeam as fadFaceKissBeam }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceKissBeam as falFaceKissBeam }   from '@fortawesome/pro-light-svg-icons';
import { faFaceKissBeam as farFaceKissBeam }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceKissBeam as fasFaceKissBeam }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceKissClosedEyes as fadFaceKissClosedEyes }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceKissClosedEyes as falFaceKissClosedEyes }   from '@fortawesome/pro-light-svg-icons';
import { faFaceKissClosedEyes as farFaceKissClosedEyes }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceKissClosedEyes as fasFaceKissClosedEyes }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceKissWinkHeart as fadFaceKissWinkHeart }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceKissWinkHeart as falFaceKissWinkHeart }   from '@fortawesome/pro-light-svg-icons';
import { faFaceKissWinkHeart as farFaceKissWinkHeart }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceKissWinkHeart as fasFaceKissWinkHeart }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceLaugh as fadFaceLaugh }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceLaugh as falFaceLaugh }   from '@fortawesome/pro-light-svg-icons';
import { faFaceLaugh as farFaceLaugh }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceLaugh as fasFaceLaugh }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceLaughBeam as fadFaceLaughBeam }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceLaughBeam as falFaceLaughBeam }   from '@fortawesome/pro-light-svg-icons';
import { faFaceLaughBeam as farFaceLaughBeam }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceLaughBeam as fasFaceLaughBeam }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceLaughSquint as fadFaceLaughSquint }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceLaughSquint as falFaceLaughSquint }   from '@fortawesome/pro-light-svg-icons';
import { faFaceLaughSquint as farFaceLaughSquint }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceLaughSquint as fasFaceLaughSquint }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceLaughWink as fadFaceLaughWink }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceLaughWink as falFaceLaughWink }   from '@fortawesome/pro-light-svg-icons';
import { faFaceLaughWink as farFaceLaughWink }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceLaughWink as fasFaceLaughWink }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceLying as fadFaceLying }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceLying as falFaceLying }   from '@fortawesome/pro-light-svg-icons';
import { faFaceLying as farFaceLying }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceLying as fasFaceLying }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceMask as fadFaceMask }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceMask as falFaceMask }   from '@fortawesome/pro-light-svg-icons';
import { faFaceMask as farFaceMask }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceMask as fasFaceMask }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceMeh as fadFaceMeh }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceMeh as falFaceMeh }   from '@fortawesome/pro-light-svg-icons';
import { faFaceMeh as farFaceMeh }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceMeh as fasFaceMeh }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceMehBlank as fadFaceMehBlank }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceMehBlank as falFaceMehBlank }   from '@fortawesome/pro-light-svg-icons';
import { faFaceMehBlank as farFaceMehBlank }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceMehBlank as fasFaceMehBlank }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceMelting as fadFaceMelting }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceMelting as falFaceMelting }   from '@fortawesome/pro-light-svg-icons';
import { faFaceMelting as farFaceMelting }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceMelting as fasFaceMelting }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceMonocle as fadFaceMonocle }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceMonocle as falFaceMonocle }   from '@fortawesome/pro-light-svg-icons';
import { faFaceMonocle as farFaceMonocle }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceMonocle as fasFaceMonocle }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceNauseated as fadFaceNauseated }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceNauseated as falFaceNauseated }   from '@fortawesome/pro-light-svg-icons';
import { faFaceNauseated as farFaceNauseated }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceNauseated as fasFaceNauseated }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceNoseSteam as fadFaceNoseSteam }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceNoseSteam as falFaceNoseSteam }   from '@fortawesome/pro-light-svg-icons';
import { faFaceNoseSteam as farFaceNoseSteam }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceNoseSteam as fasFaceNoseSteam }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceParty as fadFaceParty }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceParty as falFaceParty }   from '@fortawesome/pro-light-svg-icons';
import { faFaceParty as farFaceParty }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceParty as fasFaceParty }   from '@fortawesome/pro-solid-svg-icons';
import { faFacePensive as fadFacePensive }   from '@fortawesome/pro-duotone-svg-icons';
import { faFacePensive as falFacePensive }   from '@fortawesome/pro-light-svg-icons';
import { faFacePensive as farFacePensive }   from '@fortawesome/pro-regular-svg-icons';
import { faFacePensive as fasFacePensive }   from '@fortawesome/pro-solid-svg-icons';
import { faFacePersevering as fadFacePersevering }   from '@fortawesome/pro-duotone-svg-icons';
import { faFacePersevering as falFacePersevering }   from '@fortawesome/pro-light-svg-icons';
import { faFacePersevering as farFacePersevering }   from '@fortawesome/pro-regular-svg-icons';
import { faFacePersevering as fasFacePersevering }   from '@fortawesome/pro-solid-svg-icons';
import { faFacePleading as fadFacePleading }   from '@fortawesome/pro-duotone-svg-icons';
import { faFacePleading as falFacePleading }   from '@fortawesome/pro-light-svg-icons';
import { faFacePleading as farFacePleading }   from '@fortawesome/pro-regular-svg-icons';
import { faFacePleading as fasFacePleading }   from '@fortawesome/pro-solid-svg-icons';
import { faFacePouting as fadFacePouting }   from '@fortawesome/pro-duotone-svg-icons';
import { faFacePouting as falFacePouting }   from '@fortawesome/pro-light-svg-icons';
import { faFacePouting as farFacePouting }   from '@fortawesome/pro-regular-svg-icons';
import { faFacePouting as fasFacePouting }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceRaisedEyebrow as fadFaceRaisedEyebrow }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceRaisedEyebrow as falFaceRaisedEyebrow }   from '@fortawesome/pro-light-svg-icons';
import { faFaceRaisedEyebrow as farFaceRaisedEyebrow }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceRaisedEyebrow as fasFaceRaisedEyebrow }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceRelieved as fadFaceRelieved }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceRelieved as falFaceRelieved }   from '@fortawesome/pro-light-svg-icons';
import { faFaceRelieved as farFaceRelieved }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceRelieved as fasFaceRelieved }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceRollingEyes as fadFaceRollingEyes }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceRollingEyes as falFaceRollingEyes }   from '@fortawesome/pro-light-svg-icons';
import { faFaceRollingEyes as farFaceRollingEyes }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceRollingEyes as fasFaceRollingEyes }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceSadCry as fadFaceSadCry }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceSadCry as falFaceSadCry }   from '@fortawesome/pro-light-svg-icons';
import { faFaceSadCry as farFaceSadCry }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceSadCry as fasFaceSadCry }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceSadSweat as fadFaceSadSweat }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceSadSweat as falFaceSadSweat }   from '@fortawesome/pro-light-svg-icons';
import { faFaceSadSweat as farFaceSadSweat }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceSadSweat as fasFaceSadSweat }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceSadTear as fadFaceSadTear }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceSadTear as falFaceSadTear }   from '@fortawesome/pro-light-svg-icons';
import { faFaceSadTear as farFaceSadTear }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceSadTear as fasFaceSadTear }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceSaluting as fadFaceSaluting }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceSaluting as falFaceSaluting }   from '@fortawesome/pro-light-svg-icons';
import { faFaceSaluting as farFaceSaluting }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceSaluting as fasFaceSaluting }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceScream as fadFaceScream }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceScream as falFaceScream }   from '@fortawesome/pro-light-svg-icons';
import { faFaceScream as farFaceScream }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceScream as fasFaceScream }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceShush as fadFaceShush }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceShush as falFaceShush }   from '@fortawesome/pro-light-svg-icons';
import { faFaceShush as farFaceShush }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceShush as fasFaceShush }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceSleeping as fadFaceSleeping }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceSleeping as falFaceSleeping }   from '@fortawesome/pro-light-svg-icons';
import { faFaceSleeping as farFaceSleeping }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceSleeping as fasFaceSleeping }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceSleepy as fadFaceSleepy }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceSleepy as falFaceSleepy }   from '@fortawesome/pro-light-svg-icons';
import { faFaceSleepy as farFaceSleepy }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceSleepy as fasFaceSleepy }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceSmile as fadFaceSmile }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceSmile as falFaceSmile }   from '@fortawesome/pro-light-svg-icons';
import { faFaceSmile as farFaceSmile }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceSmile as fasFaceSmile }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceSmileBeam as fadFaceSmileBeam }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceSmileBeam as falFaceSmileBeam }   from '@fortawesome/pro-light-svg-icons';
import { faFaceSmileBeam as farFaceSmileBeam }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceSmileBeam as fasFaceSmileBeam }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceSmileHalo as fadFaceSmileHalo }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceSmileHalo as falFaceSmileHalo }   from '@fortawesome/pro-light-svg-icons';
import { faFaceSmileHalo as farFaceSmileHalo }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceSmileHalo as fasFaceSmileHalo }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceSmileHearts as fadFaceSmileHearts }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceSmileHearts as falFaceSmileHearts }   from '@fortawesome/pro-light-svg-icons';
import { faFaceSmileHearts as farFaceSmileHearts }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceSmileHearts as fasFaceSmileHearts }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceSmileHorns as fadFaceSmileHorns }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceSmileHorns as falFaceSmileHorns }   from '@fortawesome/pro-light-svg-icons';
import { faFaceSmileHorns as farFaceSmileHorns }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceSmileHorns as fasFaceSmileHorns }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceSmilePlus as fadFaceSmilePlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceSmilePlus as falFaceSmilePlus }   from '@fortawesome/pro-light-svg-icons';
import { faFaceSmilePlus as farFaceSmilePlus }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceSmilePlus as fasFaceSmilePlus }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceSmileRelaxed as fadFaceSmileRelaxed }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceSmileRelaxed as falFaceSmileRelaxed }   from '@fortawesome/pro-light-svg-icons';
import { faFaceSmileRelaxed as farFaceSmileRelaxed }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceSmileRelaxed as fasFaceSmileRelaxed }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceSmileTear as fadFaceSmileTear }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceSmileTear as falFaceSmileTear }   from '@fortawesome/pro-light-svg-icons';
import { faFaceSmileTear as farFaceSmileTear }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceSmileTear as fasFaceSmileTear }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceSmileTongue as fadFaceSmileTongue }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceSmileTongue as falFaceSmileTongue }   from '@fortawesome/pro-light-svg-icons';
import { faFaceSmileTongue as farFaceSmileTongue }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceSmileTongue as fasFaceSmileTongue }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceSmileUpsideDown as fadFaceSmileUpsideDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceSmileUpsideDown as falFaceSmileUpsideDown }   from '@fortawesome/pro-light-svg-icons';
import { faFaceSmileUpsideDown as farFaceSmileUpsideDown }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceSmileUpsideDown as fasFaceSmileUpsideDown }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceSmileWink as fadFaceSmileWink }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceSmileWink as falFaceSmileWink }   from '@fortawesome/pro-light-svg-icons';
import { faFaceSmileWink as farFaceSmileWink }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceSmileWink as fasFaceSmileWink }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceSmilingHands as fadFaceSmilingHands }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceSmilingHands as falFaceSmilingHands }   from '@fortawesome/pro-light-svg-icons';
import { faFaceSmilingHands as farFaceSmilingHands }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceSmilingHands as fasFaceSmilingHands }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceSmirking as fadFaceSmirking }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceSmirking as falFaceSmirking }   from '@fortawesome/pro-light-svg-icons';
import { faFaceSmirking as farFaceSmirking }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceSmirking as fasFaceSmirking }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceSpiralEyes as fadFaceSpiralEyes }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceSpiralEyes as falFaceSpiralEyes }   from '@fortawesome/pro-light-svg-icons';
import { faFaceSpiralEyes as farFaceSpiralEyes }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceSpiralEyes as fasFaceSpiralEyes }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceSunglasses as fadFaceSunglasses }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceSunglasses as falFaceSunglasses }   from '@fortawesome/pro-light-svg-icons';
import { faFaceSunglasses as farFaceSunglasses }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceSunglasses as fasFaceSunglasses }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceSurprise as fadFaceSurprise }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceSurprise as falFaceSurprise }   from '@fortawesome/pro-light-svg-icons';
import { faFaceSurprise as farFaceSurprise }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceSurprise as fasFaceSurprise }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceSwear as fadFaceSwear }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceSwear as falFaceSwear }   from '@fortawesome/pro-light-svg-icons';
import { faFaceSwear as farFaceSwear }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceSwear as fasFaceSwear }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceThermometer as fadFaceThermometer }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceThermometer as falFaceThermometer }   from '@fortawesome/pro-light-svg-icons';
import { faFaceThermometer as farFaceThermometer }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceThermometer as fasFaceThermometer }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceThinking as fadFaceThinking }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceThinking as falFaceThinking }   from '@fortawesome/pro-light-svg-icons';
import { faFaceThinking as farFaceThinking }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceThinking as fasFaceThinking }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceTired as fadFaceTired }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceTired as falFaceTired }   from '@fortawesome/pro-light-svg-icons';
import { faFaceTired as farFaceTired }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceTired as fasFaceTired }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceTissue as fadFaceTissue }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceTissue as falFaceTissue }   from '@fortawesome/pro-light-svg-icons';
import { faFaceTissue as farFaceTissue }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceTissue as fasFaceTissue }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceTongueMoney as fadFaceTongueMoney }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceTongueMoney as falFaceTongueMoney }   from '@fortawesome/pro-light-svg-icons';
import { faFaceTongueMoney as farFaceTongueMoney }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceTongueMoney as fasFaceTongueMoney }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceTongueSweat as fadFaceTongueSweat }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceTongueSweat as falFaceTongueSweat }   from '@fortawesome/pro-light-svg-icons';
import { faFaceTongueSweat as farFaceTongueSweat }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceTongueSweat as fasFaceTongueSweat }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceUnamused as fadFaceUnamused }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceUnamused as falFaceUnamused }   from '@fortawesome/pro-light-svg-icons';
import { faFaceUnamused as farFaceUnamused }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceUnamused as fasFaceUnamused }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceViewfinder as fadFaceViewfinder }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceViewfinder as falFaceViewfinder }   from '@fortawesome/pro-light-svg-icons';
import { faFaceViewfinder as farFaceViewfinder }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceViewfinder as fasFaceViewfinder }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceVomit as fadFaceVomit }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceVomit as falFaceVomit }   from '@fortawesome/pro-light-svg-icons';
import { faFaceVomit as farFaceVomit }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceVomit as fasFaceVomit }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceWeary as fadFaceWeary }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceWeary as falFaceWeary }   from '@fortawesome/pro-light-svg-icons';
import { faFaceWeary as farFaceWeary }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceWeary as fasFaceWeary }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceWoozy as fadFaceWoozy }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceWoozy as falFaceWoozy }   from '@fortawesome/pro-light-svg-icons';
import { faFaceWoozy as farFaceWoozy }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceWoozy as fasFaceWoozy }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceWorried as fadFaceWorried }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceWorried as falFaceWorried }   from '@fortawesome/pro-light-svg-icons';
import { faFaceWorried as farFaceWorried }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceWorried as fasFaceWorried }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceZany as fadFaceZany }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceZany as falFaceZany }   from '@fortawesome/pro-light-svg-icons';
import { faFaceZany as farFaceZany }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceZany as fasFaceZany }   from '@fortawesome/pro-solid-svg-icons';
import { faFaceZipper as fadFaceZipper }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaceZipper as falFaceZipper }   from '@fortawesome/pro-light-svg-icons';
import { faFaceZipper as farFaceZipper }   from '@fortawesome/pro-regular-svg-icons';
import { faFaceZipper as fasFaceZipper }   from '@fortawesome/pro-solid-svg-icons';
import { faFalafel as fadFalafel }   from '@fortawesome/pro-duotone-svg-icons';
import { faFalafel as falFalafel }   from '@fortawesome/pro-light-svg-icons';
import { faFalafel as farFalafel }   from '@fortawesome/pro-regular-svg-icons';
import { faFalafel as fasFalafel }   from '@fortawesome/pro-solid-svg-icons';
import { faFamily as fadFamily }   from '@fortawesome/pro-duotone-svg-icons';
import { faFamily as falFamily }   from '@fortawesome/pro-light-svg-icons';
import { faFamily as farFamily }   from '@fortawesome/pro-regular-svg-icons';
import { faFamily as fasFamily }   from '@fortawesome/pro-solid-svg-icons';
import { faFamilyDress as fadFamilyDress }   from '@fortawesome/pro-duotone-svg-icons';
import { faFamilyDress as falFamilyDress }   from '@fortawesome/pro-light-svg-icons';
import { faFamilyDress as farFamilyDress }   from '@fortawesome/pro-regular-svg-icons';
import { faFamilyDress as fasFamilyDress }   from '@fortawesome/pro-solid-svg-icons';
import { faFamilyPants as fadFamilyPants }   from '@fortawesome/pro-duotone-svg-icons';
import { faFamilyPants as falFamilyPants }   from '@fortawesome/pro-light-svg-icons';
import { faFamilyPants as farFamilyPants }   from '@fortawesome/pro-regular-svg-icons';
import { faFamilyPants as fasFamilyPants }   from '@fortawesome/pro-solid-svg-icons';
import { faFan as fadFan }   from '@fortawesome/pro-duotone-svg-icons';
import { faFan as falFan }   from '@fortawesome/pro-light-svg-icons';
import { faFan as farFan }   from '@fortawesome/pro-regular-svg-icons';
import { faFan as fasFan }   from '@fortawesome/pro-solid-svg-icons';
import { faFanTable as fadFanTable }   from '@fortawesome/pro-duotone-svg-icons';
import { faFanTable as falFanTable }   from '@fortawesome/pro-light-svg-icons';
import { faFanTable as farFanTable }   from '@fortawesome/pro-regular-svg-icons';
import { faFanTable as fasFanTable }   from '@fortawesome/pro-solid-svg-icons';
import { faFarm as fadFarm }   from '@fortawesome/pro-duotone-svg-icons';
import { faFarm as falFarm }   from '@fortawesome/pro-light-svg-icons';
import { faFarm as farFarm }   from '@fortawesome/pro-regular-svg-icons';
import { faFarm as fasFarm }   from '@fortawesome/pro-solid-svg-icons';
import { faFastBackward as fadFastBackward }   from '@fortawesome/pro-duotone-svg-icons';
import { faFastBackward as falFastBackward }   from '@fortawesome/pro-light-svg-icons';
import { faFastBackward as farFastBackward }   from '@fortawesome/pro-regular-svg-icons';
import { faFastBackward as fasFastBackward }   from '@fortawesome/pro-solid-svg-icons';
import { faFastForward as fadFastForward }   from '@fortawesome/pro-duotone-svg-icons';
import { faFastForward as falFastForward }   from '@fortawesome/pro-light-svg-icons';
import { faFastForward as farFastForward }   from '@fortawesome/pro-regular-svg-icons';
import { faFastForward as fasFastForward }   from '@fortawesome/pro-solid-svg-icons';
import { faFaucet as fadFaucet }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaucet as falFaucet }   from '@fortawesome/pro-light-svg-icons';
import { faFaucet as farFaucet }   from '@fortawesome/pro-regular-svg-icons';
import { faFaucet as fasFaucet }   from '@fortawesome/pro-solid-svg-icons';
import { faFaucetDrip as fadFaucetDrip }   from '@fortawesome/pro-duotone-svg-icons';
import { faFaucetDrip as falFaucetDrip }   from '@fortawesome/pro-light-svg-icons';
import { faFaucetDrip as farFaucetDrip }   from '@fortawesome/pro-regular-svg-icons';
import { faFaucetDrip as fasFaucetDrip }   from '@fortawesome/pro-solid-svg-icons';
import { faFax as fadFax }   from '@fortawesome/pro-duotone-svg-icons';
import { faFax as falFax }   from '@fortawesome/pro-light-svg-icons';
import { faFax as farFax }   from '@fortawesome/pro-regular-svg-icons';
import { faFax as fasFax }   from '@fortawesome/pro-solid-svg-icons';
import { faFeather as fadFeather }   from '@fortawesome/pro-duotone-svg-icons';
import { faFeather as falFeather }   from '@fortawesome/pro-light-svg-icons';
import { faFeather as farFeather }   from '@fortawesome/pro-regular-svg-icons';
import { faFeather as fasFeather }   from '@fortawesome/pro-solid-svg-icons';
import { faFeatherAlt as fadFeatherAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faFeatherAlt as falFeatherAlt }   from '@fortawesome/pro-light-svg-icons';
import { faFeatherAlt as farFeatherAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faFeatherAlt as fasFeatherAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faFeatherPointed as fadFeatherPointed }   from '@fortawesome/pro-duotone-svg-icons';
import { faFeatherPointed as falFeatherPointed }   from '@fortawesome/pro-light-svg-icons';
import { faFeatherPointed as farFeatherPointed }   from '@fortawesome/pro-regular-svg-icons';
import { faFeatherPointed as fasFeatherPointed }   from '@fortawesome/pro-solid-svg-icons';
import { faFeed as fadFeed }   from '@fortawesome/pro-duotone-svg-icons';
import { faFeed as falFeed }   from '@fortawesome/pro-light-svg-icons';
import { faFeed as farFeed }   from '@fortawesome/pro-regular-svg-icons';
import { faFeed as fasFeed }   from '@fortawesome/pro-solid-svg-icons';
import { faFemale as fadFemale }   from '@fortawesome/pro-duotone-svg-icons';
import { faFemale as falFemale }   from '@fortawesome/pro-light-svg-icons';
import { faFemale as farFemale }   from '@fortawesome/pro-regular-svg-icons';
import { faFemale as fasFemale }   from '@fortawesome/pro-solid-svg-icons';
import { faFence as fadFence }   from '@fortawesome/pro-duotone-svg-icons';
import { faFence as falFence }   from '@fortawesome/pro-light-svg-icons';
import { faFence as farFence }   from '@fortawesome/pro-regular-svg-icons';
import { faFence as fasFence }   from '@fortawesome/pro-solid-svg-icons';
import { faFerrisWheel as fadFerrisWheel }   from '@fortawesome/pro-duotone-svg-icons';
import { faFerrisWheel as falFerrisWheel }   from '@fortawesome/pro-light-svg-icons';
import { faFerrisWheel as farFerrisWheel }   from '@fortawesome/pro-regular-svg-icons';
import { faFerrisWheel as fasFerrisWheel }   from '@fortawesome/pro-solid-svg-icons';
import { faFerry as fadFerry }   from '@fortawesome/pro-duotone-svg-icons';
import { faFerry as falFerry }   from '@fortawesome/pro-light-svg-icons';
import { faFerry as farFerry }   from '@fortawesome/pro-regular-svg-icons';
import { faFerry as fasFerry }   from '@fortawesome/pro-solid-svg-icons';
import { faFieldHockey as fadFieldHockey }   from '@fortawesome/pro-duotone-svg-icons';
import { faFieldHockey as falFieldHockey }   from '@fortawesome/pro-light-svg-icons';
import { faFieldHockey as farFieldHockey }   from '@fortawesome/pro-regular-svg-icons';
import { faFieldHockey as fasFieldHockey }   from '@fortawesome/pro-solid-svg-icons';
import { faFieldHockeyStickBall as fadFieldHockeyStickBall }   from '@fortawesome/pro-duotone-svg-icons';
import { faFieldHockeyStickBall as falFieldHockeyStickBall }   from '@fortawesome/pro-light-svg-icons';
import { faFieldHockeyStickBall as farFieldHockeyStickBall }   from '@fortawesome/pro-regular-svg-icons';
import { faFieldHockeyStickBall as fasFieldHockeyStickBall }   from '@fortawesome/pro-solid-svg-icons';
import { faFighterJet as fadFighterJet }   from '@fortawesome/pro-duotone-svg-icons';
import { faFighterJet as falFighterJet }   from '@fortawesome/pro-light-svg-icons';
import { faFighterJet as farFighterJet }   from '@fortawesome/pro-regular-svg-icons';
import { faFighterJet as fasFighterJet }   from '@fortawesome/pro-solid-svg-icons';
import { faFile as fadFile }   from '@fortawesome/pro-duotone-svg-icons';
import { faFile as falFile }   from '@fortawesome/pro-light-svg-icons';
import { faFile as farFile }   from '@fortawesome/pro-regular-svg-icons';
import { faFile as fasFile }   from '@fortawesome/pro-solid-svg-icons';
import { faFileAlt as fadFileAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileAlt as falFileAlt }   from '@fortawesome/pro-light-svg-icons';
import { faFileAlt as farFileAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faFileAlt as fasFileAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faFileArchive as fadFileArchive }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileArchive as falFileArchive }   from '@fortawesome/pro-light-svg-icons';
import { faFileArchive as farFileArchive }   from '@fortawesome/pro-regular-svg-icons';
import { faFileArchive as fasFileArchive }   from '@fortawesome/pro-solid-svg-icons';
import { faFileArrowDown as fadFileArrowDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileArrowDown as falFileArrowDown }   from '@fortawesome/pro-light-svg-icons';
import { faFileArrowDown as farFileArrowDown }   from '@fortawesome/pro-regular-svg-icons';
import { faFileArrowDown as fasFileArrowDown }   from '@fortawesome/pro-solid-svg-icons';
import { faFileArrowUp as fadFileArrowUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileArrowUp as falFileArrowUp }   from '@fortawesome/pro-light-svg-icons';
import { faFileArrowUp as farFileArrowUp }   from '@fortawesome/pro-regular-svg-icons';
import { faFileArrowUp as fasFileArrowUp }   from '@fortawesome/pro-solid-svg-icons';
import { faFileAudio as fadFileAudio }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileAudio as falFileAudio }   from '@fortawesome/pro-light-svg-icons';
import { faFileAudio as farFileAudio }   from '@fortawesome/pro-regular-svg-icons';
import { faFileAudio as fasFileAudio }   from '@fortawesome/pro-solid-svg-icons';
import { faFileAward as fadFileAward }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileAward as falFileAward }   from '@fortawesome/pro-light-svg-icons';
import { faFileAward as farFileAward }   from '@fortawesome/pro-regular-svg-icons';
import { faFileAward as fasFileAward }   from '@fortawesome/pro-solid-svg-icons';
import { faFileBinary as fadFileBinary }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileBinary as falFileBinary }   from '@fortawesome/pro-light-svg-icons';
import { faFileBinary as farFileBinary }   from '@fortawesome/pro-regular-svg-icons';
import { faFileBinary as fasFileBinary }   from '@fortawesome/pro-solid-svg-icons';
import { faFileCaretDown as fadFileCaretDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileCaretDown as falFileCaretDown }   from '@fortawesome/pro-light-svg-icons';
import { faFileCaretDown as farFileCaretDown }   from '@fortawesome/pro-regular-svg-icons';
import { faFileCaretDown as fasFileCaretDown }   from '@fortawesome/pro-solid-svg-icons';
import { faFileCaretUp as fadFileCaretUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileCaretUp as falFileCaretUp }   from '@fortawesome/pro-light-svg-icons';
import { faFileCaretUp as farFileCaretUp }   from '@fortawesome/pro-regular-svg-icons';
import { faFileCaretUp as fasFileCaretUp }   from '@fortawesome/pro-solid-svg-icons';
import { faFileCertificate as fadFileCertificate }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileCertificate as falFileCertificate }   from '@fortawesome/pro-light-svg-icons';
import { faFileCertificate as farFileCertificate }   from '@fortawesome/pro-regular-svg-icons';
import { faFileCertificate as fasFileCertificate }   from '@fortawesome/pro-solid-svg-icons';
import { faFileChartColumn as fadFileChartColumn }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileChartColumn as falFileChartColumn }   from '@fortawesome/pro-light-svg-icons';
import { faFileChartColumn as farFileChartColumn }   from '@fortawesome/pro-regular-svg-icons';
import { faFileChartColumn as fasFileChartColumn }   from '@fortawesome/pro-solid-svg-icons';
import { faFileChartLine as fadFileChartLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileChartLine as falFileChartLine }   from '@fortawesome/pro-light-svg-icons';
import { faFileChartLine as farFileChartLine }   from '@fortawesome/pro-regular-svg-icons';
import { faFileChartLine as fasFileChartLine }   from '@fortawesome/pro-solid-svg-icons';
import { faFileChartPie as fadFileChartPie }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileChartPie as falFileChartPie }   from '@fortawesome/pro-light-svg-icons';
import { faFileChartPie as farFileChartPie }   from '@fortawesome/pro-regular-svg-icons';
import { faFileChartPie as fasFileChartPie }   from '@fortawesome/pro-solid-svg-icons';
import { faFileCheck as fadFileCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileCheck as falFileCheck }   from '@fortawesome/pro-light-svg-icons';
import { faFileCheck as farFileCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faFileCheck as fasFileCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faFileCircleCheck as fadFileCircleCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileCircleCheck as falFileCircleCheck }   from '@fortawesome/pro-light-svg-icons';
import { faFileCircleCheck as farFileCircleCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faFileCircleCheck as fasFileCircleCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faFileCircleExclamation as fadFileCircleExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileCircleExclamation as falFileCircleExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faFileCircleExclamation as farFileCircleExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faFileCircleExclamation as fasFileCircleExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faFileCircleInfo as fadFileCircleInfo }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileCircleInfo as falFileCircleInfo }   from '@fortawesome/pro-light-svg-icons';
import { faFileCircleInfo as farFileCircleInfo }   from '@fortawesome/pro-regular-svg-icons';
import { faFileCircleInfo as fasFileCircleInfo }   from '@fortawesome/pro-solid-svg-icons';
import { faFileCircleMinus as fadFileCircleMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileCircleMinus as falFileCircleMinus }   from '@fortawesome/pro-light-svg-icons';
import { faFileCircleMinus as farFileCircleMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faFileCircleMinus as fasFileCircleMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faFileCirclePlus as fadFileCirclePlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileCirclePlus as falFileCirclePlus }   from '@fortawesome/pro-light-svg-icons';
import { faFileCirclePlus as farFileCirclePlus }   from '@fortawesome/pro-regular-svg-icons';
import { faFileCirclePlus as fasFileCirclePlus }   from '@fortawesome/pro-solid-svg-icons';
import { faFileCircleQuestion as fadFileCircleQuestion }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileCircleQuestion as falFileCircleQuestion }   from '@fortawesome/pro-light-svg-icons';
import { faFileCircleQuestion as farFileCircleQuestion }   from '@fortawesome/pro-regular-svg-icons';
import { faFileCircleQuestion as fasFileCircleQuestion }   from '@fortawesome/pro-solid-svg-icons';
import { faFileCircleXmark as fadFileCircleXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileCircleXmark as falFileCircleXmark }   from '@fortawesome/pro-light-svg-icons';
import { faFileCircleXmark as farFileCircleXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faFileCircleXmark as fasFileCircleXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faFileClipboard as fadFileClipboard }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileClipboard as falFileClipboard }   from '@fortawesome/pro-light-svg-icons';
import { faFileClipboard as farFileClipboard }   from '@fortawesome/pro-regular-svg-icons';
import { faFileClipboard as fasFileClipboard }   from '@fortawesome/pro-solid-svg-icons';
import { faFileCode as fadFileCode }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileCode as falFileCode }   from '@fortawesome/pro-light-svg-icons';
import { faFileCode as farFileCode }   from '@fortawesome/pro-regular-svg-icons';
import { faFileCode as fasFileCode }   from '@fortawesome/pro-solid-svg-icons';
import { faFileContract as fadFileContract }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileContract as falFileContract }   from '@fortawesome/pro-light-svg-icons';
import { faFileContract as farFileContract }   from '@fortawesome/pro-regular-svg-icons';
import { faFileContract as fasFileContract }   from '@fortawesome/pro-solid-svg-icons';
import { faFileCsv as fadFileCsv }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileCsv as falFileCsv }   from '@fortawesome/pro-light-svg-icons';
import { faFileCsv as farFileCsv }   from '@fortawesome/pro-regular-svg-icons';
import { faFileCsv as fasFileCsv }   from '@fortawesome/pro-solid-svg-icons';
import { faFileDashedLine as fadFileDashedLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileDashedLine as falFileDashedLine }   from '@fortawesome/pro-light-svg-icons';
import { faFileDashedLine as farFileDashedLine }   from '@fortawesome/pro-regular-svg-icons';
import { faFileDashedLine as fasFileDashedLine }   from '@fortawesome/pro-solid-svg-icons';
import { faFileDownload as fadFileDownload }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileDownload as falFileDownload }   from '@fortawesome/pro-light-svg-icons';
import { faFileDownload as farFileDownload }   from '@fortawesome/pro-regular-svg-icons';
import { faFileDownload as fasFileDownload }   from '@fortawesome/pro-solid-svg-icons';
import { faFileEdit as fadFileEdit }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileEdit as falFileEdit }   from '@fortawesome/pro-light-svg-icons';
import { faFileEdit as farFileEdit }   from '@fortawesome/pro-regular-svg-icons';
import { faFileEdit as fasFileEdit }   from '@fortawesome/pro-solid-svg-icons';
import { faFileExcel as fadFileExcel }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileExcel as falFileExcel }   from '@fortawesome/pro-light-svg-icons';
import { faFileExcel as farFileExcel }   from '@fortawesome/pro-regular-svg-icons';
import { faFileExcel as fasFileExcel }   from '@fortawesome/pro-solid-svg-icons';
import { faFileExclamation as fadFileExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileExclamation as falFileExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faFileExclamation as farFileExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faFileExclamation as fasFileExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faFileExport as fadFileExport }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileExport as falFileExport }   from '@fortawesome/pro-light-svg-icons';
import { faFileExport as farFileExport }   from '@fortawesome/pro-regular-svg-icons';
import { faFileExport as fasFileExport }   from '@fortawesome/pro-solid-svg-icons';
import { faFileHeart as fadFileHeart }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileHeart as falFileHeart }   from '@fortawesome/pro-light-svg-icons';
import { faFileHeart as farFileHeart }   from '@fortawesome/pro-regular-svg-icons';
import { faFileHeart as fasFileHeart }   from '@fortawesome/pro-solid-svg-icons';
import { faFileImage as fadFileImage }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileImage as falFileImage }   from '@fortawesome/pro-light-svg-icons';
import { faFileImage as farFileImage }   from '@fortawesome/pro-regular-svg-icons';
import { faFileImage as fasFileImage }   from '@fortawesome/pro-solid-svg-icons';
import { faFileImport as fadFileImport }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileImport as falFileImport }   from '@fortawesome/pro-light-svg-icons';
import { faFileImport as farFileImport }   from '@fortawesome/pro-regular-svg-icons';
import { faFileImport as fasFileImport }   from '@fortawesome/pro-solid-svg-icons';
import { faFileInvoice as fadFileInvoice }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileInvoice as falFileInvoice }   from '@fortawesome/pro-light-svg-icons';
import { faFileInvoice as farFileInvoice }   from '@fortawesome/pro-regular-svg-icons';
import { faFileInvoice as fasFileInvoice }   from '@fortawesome/pro-solid-svg-icons';
import { faFileInvoiceDollar as fadFileInvoiceDollar }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileInvoiceDollar as falFileInvoiceDollar }   from '@fortawesome/pro-light-svg-icons';
import { faFileInvoiceDollar as farFileInvoiceDollar }   from '@fortawesome/pro-regular-svg-icons';
import { faFileInvoiceDollar as fasFileInvoiceDollar }   from '@fortawesome/pro-solid-svg-icons';
import { faFileLines as fadFileLines }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileLines as falFileLines }   from '@fortawesome/pro-light-svg-icons';
import { faFileLines as farFileLines }   from '@fortawesome/pro-regular-svg-icons';
import { faFileLines as fasFileLines }   from '@fortawesome/pro-solid-svg-icons';
import { faFileLock as fadFileLock }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileLock as falFileLock }   from '@fortawesome/pro-light-svg-icons';
import { faFileLock as farFileLock }   from '@fortawesome/pro-regular-svg-icons';
import { faFileLock as fasFileLock }   from '@fortawesome/pro-solid-svg-icons';
import { faFileMagnifyingGlass as fadFileMagnifyingGlass }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileMagnifyingGlass as falFileMagnifyingGlass }   from '@fortawesome/pro-light-svg-icons';
import { faFileMagnifyingGlass as farFileMagnifyingGlass }   from '@fortawesome/pro-regular-svg-icons';
import { faFileMagnifyingGlass as fasFileMagnifyingGlass }   from '@fortawesome/pro-solid-svg-icons';
import { faFileMedical as fadFileMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileMedical as falFileMedical }   from '@fortawesome/pro-light-svg-icons';
import { faFileMedical as farFileMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faFileMedical as fasFileMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faFileMedicalAlt as fadFileMedicalAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileMedicalAlt as falFileMedicalAlt }   from '@fortawesome/pro-light-svg-icons';
import { faFileMedicalAlt as farFileMedicalAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faFileMedicalAlt as fasFileMedicalAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faFileMinus as fadFileMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileMinus as falFileMinus }   from '@fortawesome/pro-light-svg-icons';
import { faFileMinus as farFileMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faFileMinus as fasFileMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faFileMusic as fadFileMusic }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileMusic as falFileMusic }   from '@fortawesome/pro-light-svg-icons';
import { faFileMusic as farFileMusic }   from '@fortawesome/pro-regular-svg-icons';
import { faFileMusic as fasFileMusic }   from '@fortawesome/pro-solid-svg-icons';
import { faFilePdf as fadFilePdf }   from '@fortawesome/pro-duotone-svg-icons';
import { faFilePdf as falFilePdf }   from '@fortawesome/pro-light-svg-icons';
import { faFilePdf as farFilePdf }   from '@fortawesome/pro-regular-svg-icons';
import { faFilePdf as fasFilePdf }   from '@fortawesome/pro-solid-svg-icons';
import { faFilePen as fadFilePen }   from '@fortawesome/pro-duotone-svg-icons';
import { faFilePen as falFilePen }   from '@fortawesome/pro-light-svg-icons';
import { faFilePen as farFilePen }   from '@fortawesome/pro-regular-svg-icons';
import { faFilePen as fasFilePen }   from '@fortawesome/pro-solid-svg-icons';
import { faFilePlus as fadFilePlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faFilePlus as falFilePlus }   from '@fortawesome/pro-light-svg-icons';
import { faFilePlus as farFilePlus }   from '@fortawesome/pro-regular-svg-icons';
import { faFilePlus as fasFilePlus }   from '@fortawesome/pro-solid-svg-icons';
import { faFilePlusMinus as fadFilePlusMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faFilePlusMinus as falFilePlusMinus }   from '@fortawesome/pro-light-svg-icons';
import { faFilePlusMinus as farFilePlusMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faFilePlusMinus as fasFilePlusMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faFilePowerpoint as fadFilePowerpoint }   from '@fortawesome/pro-duotone-svg-icons';
import { faFilePowerpoint as falFilePowerpoint }   from '@fortawesome/pro-light-svg-icons';
import { faFilePowerpoint as farFilePowerpoint }   from '@fortawesome/pro-regular-svg-icons';
import { faFilePowerpoint as fasFilePowerpoint }   from '@fortawesome/pro-solid-svg-icons';
import { faFilePrescription as fadFilePrescription }   from '@fortawesome/pro-duotone-svg-icons';
import { faFilePrescription as falFilePrescription }   from '@fortawesome/pro-light-svg-icons';
import { faFilePrescription as farFilePrescription }   from '@fortawesome/pro-regular-svg-icons';
import { faFilePrescription as fasFilePrescription }   from '@fortawesome/pro-solid-svg-icons';
import { faFiles as fadFiles }   from '@fortawesome/pro-duotone-svg-icons';
import { faFiles as falFiles }   from '@fortawesome/pro-light-svg-icons';
import { faFiles as farFiles }   from '@fortawesome/pro-regular-svg-icons';
import { faFiles as fasFiles }   from '@fortawesome/pro-solid-svg-icons';
import { faFileSearch as fadFileSearch }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileSearch as falFileSearch }   from '@fortawesome/pro-light-svg-icons';
import { faFileSearch as farFileSearch }   from '@fortawesome/pro-regular-svg-icons';
import { faFileSearch as fasFileSearch }   from '@fortawesome/pro-solid-svg-icons';
import { faFileShield as fadFileShield }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileShield as falFileShield }   from '@fortawesome/pro-light-svg-icons';
import { faFileShield as farFileShield }   from '@fortawesome/pro-regular-svg-icons';
import { faFileShield as fasFileShield }   from '@fortawesome/pro-solid-svg-icons';
import { faFileSignature as fadFileSignature }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileSignature as falFileSignature }   from '@fortawesome/pro-light-svg-icons';
import { faFileSignature as farFileSignature }   from '@fortawesome/pro-regular-svg-icons';
import { faFileSignature as fasFileSignature }   from '@fortawesome/pro-solid-svg-icons';
import { faFileSlash as fadFileSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileSlash as falFileSlash }   from '@fortawesome/pro-light-svg-icons';
import { faFileSlash as farFileSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faFileSlash as fasFileSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faFilesMedical as fadFilesMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faFilesMedical as falFilesMedical }   from '@fortawesome/pro-light-svg-icons';
import { faFilesMedical as farFilesMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faFilesMedical as fasFilesMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faFileSpreadsheet as fadFileSpreadsheet }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileSpreadsheet as falFileSpreadsheet }   from '@fortawesome/pro-light-svg-icons';
import { faFileSpreadsheet as farFileSpreadsheet }   from '@fortawesome/pro-regular-svg-icons';
import { faFileSpreadsheet as fasFileSpreadsheet }   from '@fortawesome/pro-solid-svg-icons';
import { faFileText as fadFileText }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileText as falFileText }   from '@fortawesome/pro-light-svg-icons';
import { faFileText as farFileText }   from '@fortawesome/pro-regular-svg-icons';
import { faFileText as fasFileText }   from '@fortawesome/pro-solid-svg-icons';
import { faFileTimes as fadFileTimes }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileTimes as falFileTimes }   from '@fortawesome/pro-light-svg-icons';
import { faFileTimes as farFileTimes }   from '@fortawesome/pro-regular-svg-icons';
import { faFileTimes as fasFileTimes }   from '@fortawesome/pro-solid-svg-icons';
import { faFileUpload as fadFileUpload }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileUpload as falFileUpload }   from '@fortawesome/pro-light-svg-icons';
import { faFileUpload as farFileUpload }   from '@fortawesome/pro-regular-svg-icons';
import { faFileUpload as fasFileUpload }   from '@fortawesome/pro-solid-svg-icons';
import { faFileUser as fadFileUser }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileUser as falFileUser }   from '@fortawesome/pro-light-svg-icons';
import { faFileUser as farFileUser }   from '@fortawesome/pro-regular-svg-icons';
import { faFileUser as fasFileUser }   from '@fortawesome/pro-solid-svg-icons';
import { faFileVideo as fadFileVideo }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileVideo as falFileVideo }   from '@fortawesome/pro-light-svg-icons';
import { faFileVideo as farFileVideo }   from '@fortawesome/pro-regular-svg-icons';
import { faFileVideo as fasFileVideo }   from '@fortawesome/pro-solid-svg-icons';
import { faFileWaveform as fadFileWaveform }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileWaveform as falFileWaveform }   from '@fortawesome/pro-light-svg-icons';
import { faFileWaveform as farFileWaveform }   from '@fortawesome/pro-regular-svg-icons';
import { faFileWaveform as fasFileWaveform }   from '@fortawesome/pro-solid-svg-icons';
import { faFileWord as fadFileWord }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileWord as falFileWord }   from '@fortawesome/pro-light-svg-icons';
import { faFileWord as farFileWord }   from '@fortawesome/pro-regular-svg-icons';
import { faFileWord as fasFileWord }   from '@fortawesome/pro-solid-svg-icons';
import { faFileXmark as fadFileXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileXmark as falFileXmark }   from '@fortawesome/pro-light-svg-icons';
import { faFileXmark as farFileXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faFileXmark as fasFileXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faFileZipper as fadFileZipper }   from '@fortawesome/pro-duotone-svg-icons';
import { faFileZipper as falFileZipper }   from '@fortawesome/pro-light-svg-icons';
import { faFileZipper as farFileZipper }   from '@fortawesome/pro-regular-svg-icons';
import { faFileZipper as fasFileZipper }   from '@fortawesome/pro-solid-svg-icons';
import { faFill as fadFill }   from '@fortawesome/pro-duotone-svg-icons';
import { faFill as falFill }   from '@fortawesome/pro-light-svg-icons';
import { faFill as farFill }   from '@fortawesome/pro-regular-svg-icons';
import { faFill as fasFill }   from '@fortawesome/pro-solid-svg-icons';
import { faFillDrip as fadFillDrip }   from '@fortawesome/pro-duotone-svg-icons';
import { faFillDrip as falFillDrip }   from '@fortawesome/pro-light-svg-icons';
import { faFillDrip as farFillDrip }   from '@fortawesome/pro-regular-svg-icons';
import { faFillDrip as fasFillDrip }   from '@fortawesome/pro-solid-svg-icons';
import { faFilm as fadFilm }   from '@fortawesome/pro-duotone-svg-icons';
import { faFilm as falFilm }   from '@fortawesome/pro-light-svg-icons';
import { faFilm as farFilm }   from '@fortawesome/pro-regular-svg-icons';
import { faFilm as fasFilm }   from '@fortawesome/pro-solid-svg-icons';
import { faFilmAlt as fadFilmAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faFilmAlt as falFilmAlt }   from '@fortawesome/pro-light-svg-icons';
import { faFilmAlt as farFilmAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faFilmAlt as fasFilmAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faFilmCanister as fadFilmCanister }   from '@fortawesome/pro-duotone-svg-icons';
import { faFilmCanister as falFilmCanister }   from '@fortawesome/pro-light-svg-icons';
import { faFilmCanister as farFilmCanister }   from '@fortawesome/pro-regular-svg-icons';
import { faFilmCanister as fasFilmCanister }   from '@fortawesome/pro-solid-svg-icons';
import { faFilms as fadFilms }   from '@fortawesome/pro-duotone-svg-icons';
import { faFilms as falFilms }   from '@fortawesome/pro-light-svg-icons';
import { faFilms as farFilms }   from '@fortawesome/pro-regular-svg-icons';
import { faFilms as fasFilms }   from '@fortawesome/pro-solid-svg-icons';
import { faFilmSimple as fadFilmSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faFilmSimple as falFilmSimple }   from '@fortawesome/pro-light-svg-icons';
import { faFilmSimple as farFilmSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faFilmSimple as fasFilmSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faFilmSlash as fadFilmSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faFilmSlash as falFilmSlash }   from '@fortawesome/pro-light-svg-icons';
import { faFilmSlash as farFilmSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faFilmSlash as fasFilmSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faFilter as fadFilter }   from '@fortawesome/pro-duotone-svg-icons';
import { faFilter as falFilter }   from '@fortawesome/pro-light-svg-icons';
import { faFilter as farFilter }   from '@fortawesome/pro-regular-svg-icons';
import { faFilter as fasFilter }   from '@fortawesome/pro-solid-svg-icons';
import { faFilterCircleDollar as fadFilterCircleDollar }   from '@fortawesome/pro-duotone-svg-icons';
import { faFilterCircleDollar as falFilterCircleDollar }   from '@fortawesome/pro-light-svg-icons';
import { faFilterCircleDollar as farFilterCircleDollar }   from '@fortawesome/pro-regular-svg-icons';
import { faFilterCircleDollar as fasFilterCircleDollar }   from '@fortawesome/pro-solid-svg-icons';
import { faFilterCircleXmark as fadFilterCircleXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faFilterCircleXmark as falFilterCircleXmark }   from '@fortawesome/pro-light-svg-icons';
import { faFilterCircleXmark as farFilterCircleXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faFilterCircleXmark as fasFilterCircleXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faFilterList as fadFilterList }   from '@fortawesome/pro-duotone-svg-icons';
import { faFilterList as falFilterList }   from '@fortawesome/pro-light-svg-icons';
import { faFilterList as farFilterList }   from '@fortawesome/pro-regular-svg-icons';
import { faFilterList as fasFilterList }   from '@fortawesome/pro-solid-svg-icons';
import { faFilters as fadFilters }   from '@fortawesome/pro-duotone-svg-icons';
import { faFilters as falFilters }   from '@fortawesome/pro-light-svg-icons';
import { faFilters as farFilters }   from '@fortawesome/pro-regular-svg-icons';
import { faFilters as fasFilters }   from '@fortawesome/pro-solid-svg-icons';
import { faFilterSlash as fadFilterSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faFilterSlash as falFilterSlash }   from '@fortawesome/pro-light-svg-icons';
import { faFilterSlash as farFilterSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faFilterSlash as fasFilterSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faFingerprint as fadFingerprint }   from '@fortawesome/pro-duotone-svg-icons';
import { faFingerprint as falFingerprint }   from '@fortawesome/pro-light-svg-icons';
import { faFingerprint as farFingerprint }   from '@fortawesome/pro-regular-svg-icons';
import { faFingerprint as fasFingerprint }   from '@fortawesome/pro-solid-svg-icons';
import { faFire as fadFire }   from '@fortawesome/pro-duotone-svg-icons';
import { faFire as falFire }   from '@fortawesome/pro-light-svg-icons';
import { faFire as farFire }   from '@fortawesome/pro-regular-svg-icons';
import { faFire as fasFire }   from '@fortawesome/pro-solid-svg-icons';
import { faFireAlt as fadFireAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faFireAlt as falFireAlt }   from '@fortawesome/pro-light-svg-icons';
import { faFireAlt as farFireAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faFireAlt as fasFireAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faFireBurner as fadFireBurner }   from '@fortawesome/pro-duotone-svg-icons';
import { faFireBurner as falFireBurner }   from '@fortawesome/pro-light-svg-icons';
import { faFireBurner as farFireBurner }   from '@fortawesome/pro-regular-svg-icons';
import { faFireBurner as fasFireBurner }   from '@fortawesome/pro-solid-svg-icons';
import { faFireExtinguisher as fadFireExtinguisher }   from '@fortawesome/pro-duotone-svg-icons';
import { faFireExtinguisher as falFireExtinguisher }   from '@fortawesome/pro-light-svg-icons';
import { faFireExtinguisher as farFireExtinguisher }   from '@fortawesome/pro-regular-svg-icons';
import { faFireExtinguisher as fasFireExtinguisher }   from '@fortawesome/pro-solid-svg-icons';
import { faFireFlame as fadFireFlame }   from '@fortawesome/pro-duotone-svg-icons';
import { faFireFlame as falFireFlame }   from '@fortawesome/pro-light-svg-icons';
import { faFireFlame as farFireFlame }   from '@fortawesome/pro-regular-svg-icons';
import { faFireFlame as fasFireFlame }   from '@fortawesome/pro-solid-svg-icons';
import { faFireFlameCurved as fadFireFlameCurved }   from '@fortawesome/pro-duotone-svg-icons';
import { faFireFlameCurved as falFireFlameCurved }   from '@fortawesome/pro-light-svg-icons';
import { faFireFlameCurved as farFireFlameCurved }   from '@fortawesome/pro-regular-svg-icons';
import { faFireFlameCurved as fasFireFlameCurved }   from '@fortawesome/pro-solid-svg-icons';
import { faFireFlameSimple as fadFireFlameSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faFireFlameSimple as falFireFlameSimple }   from '@fortawesome/pro-light-svg-icons';
import { faFireFlameSimple as farFireFlameSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faFireFlameSimple as fasFireFlameSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faFireHydrant as fadFireHydrant }   from '@fortawesome/pro-duotone-svg-icons';
import { faFireHydrant as falFireHydrant }   from '@fortawesome/pro-light-svg-icons';
import { faFireHydrant as farFireHydrant }   from '@fortawesome/pro-regular-svg-icons';
import { faFireHydrant as fasFireHydrant }   from '@fortawesome/pro-solid-svg-icons';
import { faFireplace as fadFireplace }   from '@fortawesome/pro-duotone-svg-icons';
import { faFireplace as falFireplace }   from '@fortawesome/pro-light-svg-icons';
import { faFireplace as farFireplace }   from '@fortawesome/pro-regular-svg-icons';
import { faFireplace as fasFireplace }   from '@fortawesome/pro-solid-svg-icons';
import { faFireSmoke as fadFireSmoke }   from '@fortawesome/pro-duotone-svg-icons';
import { faFireSmoke as falFireSmoke }   from '@fortawesome/pro-light-svg-icons';
import { faFireSmoke as farFireSmoke }   from '@fortawesome/pro-regular-svg-icons';
import { faFireSmoke as fasFireSmoke }   from '@fortawesome/pro-solid-svg-icons';
import { faFirewall as fadFirewall }   from '@fortawesome/pro-duotone-svg-icons';
import { faFirewall as falFirewall }   from '@fortawesome/pro-light-svg-icons';
import { faFirewall as farFirewall }   from '@fortawesome/pro-regular-svg-icons';
import { faFirewall as fasFirewall }   from '@fortawesome/pro-solid-svg-icons';
import { faFirstAid as fadFirstAid }   from '@fortawesome/pro-duotone-svg-icons';
import { faFirstAid as falFirstAid }   from '@fortawesome/pro-light-svg-icons';
import { faFirstAid as farFirstAid }   from '@fortawesome/pro-regular-svg-icons';
import { faFirstAid as fasFirstAid }   from '@fortawesome/pro-solid-svg-icons';
import { faFish as fadFish }   from '@fortawesome/pro-duotone-svg-icons';
import { faFish as falFish }   from '@fortawesome/pro-light-svg-icons';
import { faFish as farFish }   from '@fortawesome/pro-regular-svg-icons';
import { faFish as fasFish }   from '@fortawesome/pro-solid-svg-icons';
import { faFishBones as fadFishBones }   from '@fortawesome/pro-duotone-svg-icons';
import { faFishBones as falFishBones }   from '@fortawesome/pro-light-svg-icons';
import { faFishBones as farFishBones }   from '@fortawesome/pro-regular-svg-icons';
import { faFishBones as fasFishBones }   from '@fortawesome/pro-solid-svg-icons';
import { faFishCooked as fadFishCooked }   from '@fortawesome/pro-duotone-svg-icons';
import { faFishCooked as falFishCooked }   from '@fortawesome/pro-light-svg-icons';
import { faFishCooked as farFishCooked }   from '@fortawesome/pro-regular-svg-icons';
import { faFishCooked as fasFishCooked }   from '@fortawesome/pro-solid-svg-icons';
import { faFishFins as fadFishFins }   from '@fortawesome/pro-duotone-svg-icons';
import { faFishFins as falFishFins }   from '@fortawesome/pro-light-svg-icons';
import { faFishFins as farFishFins }   from '@fortawesome/pro-regular-svg-icons';
import { faFishFins as fasFishFins }   from '@fortawesome/pro-solid-svg-icons';
import { faFishingRod as fadFishingRod }   from '@fortawesome/pro-duotone-svg-icons';
import { faFishingRod as falFishingRod }   from '@fortawesome/pro-light-svg-icons';
import { faFishingRod as farFishingRod }   from '@fortawesome/pro-regular-svg-icons';
import { faFishingRod as fasFishingRod }   from '@fortawesome/pro-solid-svg-icons';
import { faFistRaised as fadFistRaised }   from '@fortawesome/pro-duotone-svg-icons';
import { faFistRaised as falFistRaised }   from '@fortawesome/pro-light-svg-icons';
import { faFistRaised as farFistRaised }   from '@fortawesome/pro-regular-svg-icons';
import { faFistRaised as fasFistRaised }   from '@fortawesome/pro-solid-svg-icons';
import { faFlag as fadFlag }   from '@fortawesome/pro-duotone-svg-icons';
import { faFlag as falFlag }   from '@fortawesome/pro-light-svg-icons';
import { faFlag as farFlag }   from '@fortawesome/pro-regular-svg-icons';
import { faFlag as fasFlag }   from '@fortawesome/pro-solid-svg-icons';
import { faFlagAlt as fadFlagAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faFlagAlt as falFlagAlt }   from '@fortawesome/pro-light-svg-icons';
import { faFlagAlt as farFlagAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faFlagAlt as fasFlagAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faFlagCheckered as fadFlagCheckered }   from '@fortawesome/pro-duotone-svg-icons';
import { faFlagCheckered as falFlagCheckered }   from '@fortawesome/pro-light-svg-icons';
import { faFlagCheckered as farFlagCheckered }   from '@fortawesome/pro-regular-svg-icons';
import { faFlagCheckered as fasFlagCheckered }   from '@fortawesome/pro-solid-svg-icons';
import { faFlagPennant as fadFlagPennant }   from '@fortawesome/pro-duotone-svg-icons';
import { faFlagPennant as falFlagPennant }   from '@fortawesome/pro-light-svg-icons';
import { faFlagPennant as farFlagPennant }   from '@fortawesome/pro-regular-svg-icons';
import { faFlagPennant as fasFlagPennant }   from '@fortawesome/pro-solid-svg-icons';
import { faFlagSwallowtail as fadFlagSwallowtail }   from '@fortawesome/pro-duotone-svg-icons';
import { faFlagSwallowtail as falFlagSwallowtail }   from '@fortawesome/pro-light-svg-icons';
import { faFlagSwallowtail as farFlagSwallowtail }   from '@fortawesome/pro-regular-svg-icons';
import { faFlagSwallowtail as fasFlagSwallowtail }   from '@fortawesome/pro-solid-svg-icons';
import { faFlagUsa as fadFlagUsa }   from '@fortawesome/pro-duotone-svg-icons';
import { faFlagUsa as falFlagUsa }   from '@fortawesome/pro-light-svg-icons';
import { faFlagUsa as farFlagUsa }   from '@fortawesome/pro-regular-svg-icons';
import { faFlagUsa as fasFlagUsa }   from '@fortawesome/pro-solid-svg-icons';
import { faFlame as fadFlame }   from '@fortawesome/pro-duotone-svg-icons';
import { faFlame as falFlame }   from '@fortawesome/pro-light-svg-icons';
import { faFlame as farFlame }   from '@fortawesome/pro-regular-svg-icons';
import { faFlame as fasFlame }   from '@fortawesome/pro-solid-svg-icons';
import { faFlashlight as fadFlashlight }   from '@fortawesome/pro-duotone-svg-icons';
import { faFlashlight as falFlashlight }   from '@fortawesome/pro-light-svg-icons';
import { faFlashlight as farFlashlight }   from '@fortawesome/pro-regular-svg-icons';
import { faFlashlight as fasFlashlight }   from '@fortawesome/pro-solid-svg-icons';
import { faFlask as fadFlask }   from '@fortawesome/pro-duotone-svg-icons';
import { faFlask as falFlask }   from '@fortawesome/pro-light-svg-icons';
import { faFlask as farFlask }   from '@fortawesome/pro-regular-svg-icons';
import { faFlask as fasFlask }   from '@fortawesome/pro-solid-svg-icons';
import { faFlaskPoison as fadFlaskPoison }   from '@fortawesome/pro-duotone-svg-icons';
import { faFlaskPoison as falFlaskPoison }   from '@fortawesome/pro-light-svg-icons';
import { faFlaskPoison as farFlaskPoison }   from '@fortawesome/pro-regular-svg-icons';
import { faFlaskPoison as fasFlaskPoison }   from '@fortawesome/pro-solid-svg-icons';
import { faFlaskPotion as fadFlaskPotion }   from '@fortawesome/pro-duotone-svg-icons';
import { faFlaskPotion as falFlaskPotion }   from '@fortawesome/pro-light-svg-icons';
import { faFlaskPotion as farFlaskPotion }   from '@fortawesome/pro-regular-svg-icons';
import { faFlaskPotion as fasFlaskPotion }   from '@fortawesome/pro-solid-svg-icons';
import { faFlaskRoundPoison as fadFlaskRoundPoison }   from '@fortawesome/pro-duotone-svg-icons';
import { faFlaskRoundPoison as falFlaskRoundPoison }   from '@fortawesome/pro-light-svg-icons';
import { faFlaskRoundPoison as farFlaskRoundPoison }   from '@fortawesome/pro-regular-svg-icons';
import { faFlaskRoundPoison as fasFlaskRoundPoison }   from '@fortawesome/pro-solid-svg-icons';
import { faFlaskRoundPotion as fadFlaskRoundPotion }   from '@fortawesome/pro-duotone-svg-icons';
import { faFlaskRoundPotion as falFlaskRoundPotion }   from '@fortawesome/pro-light-svg-icons';
import { faFlaskRoundPotion as farFlaskRoundPotion }   from '@fortawesome/pro-regular-svg-icons';
import { faFlaskRoundPotion as fasFlaskRoundPotion }   from '@fortawesome/pro-solid-svg-icons';
import { faFlaskVial as fadFlaskVial }   from '@fortawesome/pro-duotone-svg-icons';
import { faFlaskVial as falFlaskVial }   from '@fortawesome/pro-light-svg-icons';
import { faFlaskVial as farFlaskVial }   from '@fortawesome/pro-regular-svg-icons';
import { faFlaskVial as fasFlaskVial }   from '@fortawesome/pro-solid-svg-icons';
import { faFlatbread as fadFlatbread }   from '@fortawesome/pro-duotone-svg-icons';
import { faFlatbread as falFlatbread }   from '@fortawesome/pro-light-svg-icons';
import { faFlatbread as farFlatbread }   from '@fortawesome/pro-regular-svg-icons';
import { faFlatbread as fasFlatbread }   from '@fortawesome/pro-solid-svg-icons';
import { faFlatbreadStuffed as fadFlatbreadStuffed }   from '@fortawesome/pro-duotone-svg-icons';
import { faFlatbreadStuffed as falFlatbreadStuffed }   from '@fortawesome/pro-light-svg-icons';
import { faFlatbreadStuffed as farFlatbreadStuffed }   from '@fortawesome/pro-regular-svg-icons';
import { faFlatbreadStuffed as fasFlatbreadStuffed }   from '@fortawesome/pro-solid-svg-icons';
import { faFloppyDisk as fadFloppyDisk }   from '@fortawesome/pro-duotone-svg-icons';
import { faFloppyDisk as falFloppyDisk }   from '@fortawesome/pro-light-svg-icons';
import { faFloppyDisk as farFloppyDisk }   from '@fortawesome/pro-regular-svg-icons';
import { faFloppyDisk as fasFloppyDisk }   from '@fortawesome/pro-solid-svg-icons';
import { faFloppyDiskCircleArrowRight as fadFloppyDiskCircleArrowRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faFloppyDiskCircleArrowRight as falFloppyDiskCircleArrowRight }   from '@fortawesome/pro-light-svg-icons';
import { faFloppyDiskCircleArrowRight as farFloppyDiskCircleArrowRight }   from '@fortawesome/pro-regular-svg-icons';
import { faFloppyDiskCircleArrowRight as fasFloppyDiskCircleArrowRight }   from '@fortawesome/pro-solid-svg-icons';
import { faFloppyDiskCircleXmark as fadFloppyDiskCircleXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faFloppyDiskCircleXmark as falFloppyDiskCircleXmark }   from '@fortawesome/pro-light-svg-icons';
import { faFloppyDiskCircleXmark as farFloppyDiskCircleXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faFloppyDiskCircleXmark as fasFloppyDiskCircleXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faFloppyDiskPen as fadFloppyDiskPen }   from '@fortawesome/pro-duotone-svg-icons';
import { faFloppyDiskPen as falFloppyDiskPen }   from '@fortawesome/pro-light-svg-icons';
import { faFloppyDiskPen as farFloppyDiskPen }   from '@fortawesome/pro-regular-svg-icons';
import { faFloppyDiskPen as fasFloppyDiskPen }   from '@fortawesome/pro-solid-svg-icons';
import { faFloppyDisks as fadFloppyDisks }   from '@fortawesome/pro-duotone-svg-icons';
import { faFloppyDisks as falFloppyDisks }   from '@fortawesome/pro-light-svg-icons';
import { faFloppyDisks as farFloppyDisks }   from '@fortawesome/pro-regular-svg-icons';
import { faFloppyDisks as fasFloppyDisks }   from '@fortawesome/pro-solid-svg-icons';
import { faFloppyDiskTimes as fadFloppyDiskTimes }   from '@fortawesome/pro-duotone-svg-icons';
import { faFloppyDiskTimes as falFloppyDiskTimes }   from '@fortawesome/pro-light-svg-icons';
import { faFloppyDiskTimes as farFloppyDiskTimes }   from '@fortawesome/pro-regular-svg-icons';
import { faFloppyDiskTimes as fasFloppyDiskTimes }   from '@fortawesome/pro-solid-svg-icons';
import { faFlorinSign as fadFlorinSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faFlorinSign as falFlorinSign }   from '@fortawesome/pro-light-svg-icons';
import { faFlorinSign as farFlorinSign }   from '@fortawesome/pro-regular-svg-icons';
import { faFlorinSign as fasFlorinSign }   from '@fortawesome/pro-solid-svg-icons';
import { faFlower as fadFlower }   from '@fortawesome/pro-duotone-svg-icons';
import { faFlower as falFlower }   from '@fortawesome/pro-light-svg-icons';
import { faFlower as farFlower }   from '@fortawesome/pro-regular-svg-icons';
import { faFlower as fasFlower }   from '@fortawesome/pro-solid-svg-icons';
import { faFlowerDaffodil as fadFlowerDaffodil }   from '@fortawesome/pro-duotone-svg-icons';
import { faFlowerDaffodil as falFlowerDaffodil }   from '@fortawesome/pro-light-svg-icons';
import { faFlowerDaffodil as farFlowerDaffodil }   from '@fortawesome/pro-regular-svg-icons';
import { faFlowerDaffodil as fasFlowerDaffodil }   from '@fortawesome/pro-solid-svg-icons';
import { faFlowerTulip as fadFlowerTulip }   from '@fortawesome/pro-duotone-svg-icons';
import { faFlowerTulip as falFlowerTulip }   from '@fortawesome/pro-light-svg-icons';
import { faFlowerTulip as farFlowerTulip }   from '@fortawesome/pro-regular-svg-icons';
import { faFlowerTulip as fasFlowerTulip }   from '@fortawesome/pro-solid-svg-icons';
import { faFlushed as fadFlushed }   from '@fortawesome/pro-duotone-svg-icons';
import { faFlushed as falFlushed }   from '@fortawesome/pro-light-svg-icons';
import { faFlushed as farFlushed }   from '@fortawesome/pro-regular-svg-icons';
import { faFlushed as fasFlushed }   from '@fortawesome/pro-solid-svg-icons';
import { faFlute as fadFlute }   from '@fortawesome/pro-duotone-svg-icons';
import { faFlute as falFlute }   from '@fortawesome/pro-light-svg-icons';
import { faFlute as farFlute }   from '@fortawesome/pro-regular-svg-icons';
import { faFlute as fasFlute }   from '@fortawesome/pro-solid-svg-icons';
import { faFluxCapacitor as fadFluxCapacitor }   from '@fortawesome/pro-duotone-svg-icons';
import { faFluxCapacitor as falFluxCapacitor }   from '@fortawesome/pro-light-svg-icons';
import { faFluxCapacitor as farFluxCapacitor }   from '@fortawesome/pro-regular-svg-icons';
import { faFluxCapacitor as fasFluxCapacitor }   from '@fortawesome/pro-solid-svg-icons';
import { faFlyingDisc as fadFlyingDisc }   from '@fortawesome/pro-duotone-svg-icons';
import { faFlyingDisc as falFlyingDisc }   from '@fortawesome/pro-light-svg-icons';
import { faFlyingDisc as farFlyingDisc }   from '@fortawesome/pro-regular-svg-icons';
import { faFlyingDisc as fasFlyingDisc }   from '@fortawesome/pro-solid-svg-icons';
import { faFog as fadFog }   from '@fortawesome/pro-duotone-svg-icons';
import { faFog as falFog }   from '@fortawesome/pro-light-svg-icons';
import { faFog as farFog }   from '@fortawesome/pro-regular-svg-icons';
import { faFog as fasFog }   from '@fortawesome/pro-solid-svg-icons';
import { faFolder as fadFolder }   from '@fortawesome/pro-duotone-svg-icons';
import { faFolder as falFolder }   from '@fortawesome/pro-light-svg-icons';
import { faFolder as farFolder }   from '@fortawesome/pro-regular-svg-icons';
import { faFolder as fasFolder }   from '@fortawesome/pro-solid-svg-icons';
import { faFolderArrowDown as fadFolderArrowDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faFolderArrowDown as falFolderArrowDown }   from '@fortawesome/pro-light-svg-icons';
import { faFolderArrowDown as farFolderArrowDown }   from '@fortawesome/pro-regular-svg-icons';
import { faFolderArrowDown as fasFolderArrowDown }   from '@fortawesome/pro-solid-svg-icons';
import { faFolderArrowUp as fadFolderArrowUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faFolderArrowUp as falFolderArrowUp }   from '@fortawesome/pro-light-svg-icons';
import { faFolderArrowUp as farFolderArrowUp }   from '@fortawesome/pro-regular-svg-icons';
import { faFolderArrowUp as fasFolderArrowUp }   from '@fortawesome/pro-solid-svg-icons';
import { faFolderBlank as fadFolderBlank }   from '@fortawesome/pro-duotone-svg-icons';
import { faFolderBlank as falFolderBlank }   from '@fortawesome/pro-light-svg-icons';
import { faFolderBlank as farFolderBlank }   from '@fortawesome/pro-regular-svg-icons';
import { faFolderBlank as fasFolderBlank }   from '@fortawesome/pro-solid-svg-icons';
import { faFolderBookmark as fadFolderBookmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faFolderBookmark as falFolderBookmark }   from '@fortawesome/pro-light-svg-icons';
import { faFolderBookmark as farFolderBookmark }   from '@fortawesome/pro-regular-svg-icons';
import { faFolderBookmark as fasFolderBookmark }   from '@fortawesome/pro-solid-svg-icons';
import { faFolderClosed as fadFolderClosed }   from '@fortawesome/pro-duotone-svg-icons';
import { faFolderClosed as falFolderClosed }   from '@fortawesome/pro-light-svg-icons';
import { faFolderClosed as farFolderClosed }   from '@fortawesome/pro-regular-svg-icons';
import { faFolderClosed as fasFolderClosed }   from '@fortawesome/pro-solid-svg-icons';
import { faFolderCog as fadFolderCog }   from '@fortawesome/pro-duotone-svg-icons';
import { faFolderCog as falFolderCog }   from '@fortawesome/pro-light-svg-icons';
import { faFolderCog as farFolderCog }   from '@fortawesome/pro-regular-svg-icons';
import { faFolderCog as fasFolderCog }   from '@fortawesome/pro-solid-svg-icons';
import { faFolderDownload as fadFolderDownload }   from '@fortawesome/pro-duotone-svg-icons';
import { faFolderDownload as falFolderDownload }   from '@fortawesome/pro-light-svg-icons';
import { faFolderDownload as farFolderDownload }   from '@fortawesome/pro-regular-svg-icons';
import { faFolderDownload as fasFolderDownload }   from '@fortawesome/pro-solid-svg-icons';
import { faFolderGear as fadFolderGear }   from '@fortawesome/pro-duotone-svg-icons';
import { faFolderGear as falFolderGear }   from '@fortawesome/pro-light-svg-icons';
import { faFolderGear as farFolderGear }   from '@fortawesome/pro-regular-svg-icons';
import { faFolderGear as fasFolderGear }   from '@fortawesome/pro-solid-svg-icons';
import { faFolderGrid as fadFolderGrid }   from '@fortawesome/pro-duotone-svg-icons';
import { faFolderGrid as falFolderGrid }   from '@fortawesome/pro-light-svg-icons';
import { faFolderGrid as farFolderGrid }   from '@fortawesome/pro-regular-svg-icons';
import { faFolderGrid as fasFolderGrid }   from '@fortawesome/pro-solid-svg-icons';
import { faFolderHeart as fadFolderHeart }   from '@fortawesome/pro-duotone-svg-icons';
import { faFolderHeart as falFolderHeart }   from '@fortawesome/pro-light-svg-icons';
import { faFolderHeart as farFolderHeart }   from '@fortawesome/pro-regular-svg-icons';
import { faFolderHeart as fasFolderHeart }   from '@fortawesome/pro-solid-svg-icons';
import { faFolderImage as fadFolderImage }   from '@fortawesome/pro-duotone-svg-icons';
import { faFolderImage as falFolderImage }   from '@fortawesome/pro-light-svg-icons';
import { faFolderImage as farFolderImage }   from '@fortawesome/pro-regular-svg-icons';
import { faFolderImage as fasFolderImage }   from '@fortawesome/pro-solid-svg-icons';
import { faFolderMagnifyingGlass as fadFolderMagnifyingGlass }   from '@fortawesome/pro-duotone-svg-icons';
import { faFolderMagnifyingGlass as falFolderMagnifyingGlass }   from '@fortawesome/pro-light-svg-icons';
import { faFolderMagnifyingGlass as farFolderMagnifyingGlass }   from '@fortawesome/pro-regular-svg-icons';
import { faFolderMagnifyingGlass as fasFolderMagnifyingGlass }   from '@fortawesome/pro-solid-svg-icons';
import { faFolderMedical as fadFolderMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faFolderMedical as falFolderMedical }   from '@fortawesome/pro-light-svg-icons';
import { faFolderMedical as farFolderMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faFolderMedical as fasFolderMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faFolderMinus as fadFolderMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faFolderMinus as falFolderMinus }   from '@fortawesome/pro-light-svg-icons';
import { faFolderMinus as farFolderMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faFolderMinus as fasFolderMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faFolderMusic as fadFolderMusic }   from '@fortawesome/pro-duotone-svg-icons';
import { faFolderMusic as falFolderMusic }   from '@fortawesome/pro-light-svg-icons';
import { faFolderMusic as farFolderMusic }   from '@fortawesome/pro-regular-svg-icons';
import { faFolderMusic as fasFolderMusic }   from '@fortawesome/pro-solid-svg-icons';
import { faFolderOpen as fadFolderOpen }   from '@fortawesome/pro-duotone-svg-icons';
import { faFolderOpen as falFolderOpen }   from '@fortawesome/pro-light-svg-icons';
import { faFolderOpen as farFolderOpen }   from '@fortawesome/pro-regular-svg-icons';
import { faFolderOpen as fasFolderOpen }   from '@fortawesome/pro-solid-svg-icons';
import { faFolderPlus as fadFolderPlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faFolderPlus as falFolderPlus }   from '@fortawesome/pro-light-svg-icons';
import { faFolderPlus as farFolderPlus }   from '@fortawesome/pro-regular-svg-icons';
import { faFolderPlus as fasFolderPlus }   from '@fortawesome/pro-solid-svg-icons';
import { faFolders as fadFolders }   from '@fortawesome/pro-duotone-svg-icons';
import { faFolders as falFolders }   from '@fortawesome/pro-light-svg-icons';
import { faFolders as farFolders }   from '@fortawesome/pro-regular-svg-icons';
import { faFolders as fasFolders }   from '@fortawesome/pro-solid-svg-icons';
import { faFolderSearch as fadFolderSearch }   from '@fortawesome/pro-duotone-svg-icons';
import { faFolderSearch as falFolderSearch }   from '@fortawesome/pro-light-svg-icons';
import { faFolderSearch as farFolderSearch }   from '@fortawesome/pro-regular-svg-icons';
import { faFolderSearch as fasFolderSearch }   from '@fortawesome/pro-solid-svg-icons';
import { faFolderTimes as fadFolderTimes }   from '@fortawesome/pro-duotone-svg-icons';
import { faFolderTimes as falFolderTimes }   from '@fortawesome/pro-light-svg-icons';
import { faFolderTimes as farFolderTimes }   from '@fortawesome/pro-regular-svg-icons';
import { faFolderTimes as fasFolderTimes }   from '@fortawesome/pro-solid-svg-icons';
import { faFolderTree as fadFolderTree }   from '@fortawesome/pro-duotone-svg-icons';
import { faFolderTree as falFolderTree }   from '@fortawesome/pro-light-svg-icons';
import { faFolderTree as farFolderTree }   from '@fortawesome/pro-regular-svg-icons';
import { faFolderTree as fasFolderTree }   from '@fortawesome/pro-solid-svg-icons';
import { faFolderUpload as fadFolderUpload }   from '@fortawesome/pro-duotone-svg-icons';
import { faFolderUpload as falFolderUpload }   from '@fortawesome/pro-light-svg-icons';
import { faFolderUpload as farFolderUpload }   from '@fortawesome/pro-regular-svg-icons';
import { faFolderUpload as fasFolderUpload }   from '@fortawesome/pro-solid-svg-icons';
import { faFolderUser as fadFolderUser }   from '@fortawesome/pro-duotone-svg-icons';
import { faFolderUser as falFolderUser }   from '@fortawesome/pro-light-svg-icons';
import { faFolderUser as farFolderUser }   from '@fortawesome/pro-regular-svg-icons';
import { faFolderUser as fasFolderUser }   from '@fortawesome/pro-solid-svg-icons';
import { faFolderXmark as fadFolderXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faFolderXmark as falFolderXmark }   from '@fortawesome/pro-light-svg-icons';
import { faFolderXmark as farFolderXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faFolderXmark as fasFolderXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faFonduePot as fadFonduePot }   from '@fortawesome/pro-duotone-svg-icons';
import { faFonduePot as falFonduePot }   from '@fortawesome/pro-light-svg-icons';
import { faFonduePot as farFonduePot }   from '@fortawesome/pro-regular-svg-icons';
import { faFonduePot as fasFonduePot }   from '@fortawesome/pro-solid-svg-icons';
import { faFont as fadFont }   from '@fortawesome/pro-duotone-svg-icons';
import { faFont as falFont }   from '@fortawesome/pro-light-svg-icons';
import { faFont as farFont }   from '@fortawesome/pro-regular-svg-icons';
import { faFont as fasFont }   from '@fortawesome/pro-solid-svg-icons';
import { faFontAwesome as fadFontAwesome }   from '@fortawesome/pro-duotone-svg-icons';
import { faFontAwesome as falFontAwesome }   from '@fortawesome/pro-light-svg-icons';
import { faFontAwesome as farFontAwesome }   from '@fortawesome/pro-regular-svg-icons';
import { faFontAwesome as fasFontAwesome }   from '@fortawesome/pro-solid-svg-icons';
import { faFontAwesomeFlag as fadFontAwesomeFlag }   from '@fortawesome/pro-duotone-svg-icons';
import { faFontAwesomeFlag as falFontAwesomeFlag }   from '@fortawesome/pro-light-svg-icons';
import { faFontAwesomeFlag as farFontAwesomeFlag }   from '@fortawesome/pro-regular-svg-icons';
import { faFontAwesomeFlag as fasFontAwesomeFlag }   from '@fortawesome/pro-solid-svg-icons';
import { faFontAwesomeLogoFull as fadFontAwesomeLogoFull }   from '@fortawesome/pro-duotone-svg-icons';
import { faFontAwesomeLogoFull as falFontAwesomeLogoFull }   from '@fortawesome/pro-light-svg-icons';
import { faFontAwesomeLogoFull as farFontAwesomeLogoFull }   from '@fortawesome/pro-regular-svg-icons';
import { faFontAwesomeLogoFull as fasFontAwesomeLogoFull }   from '@fortawesome/pro-solid-svg-icons';
import { faFontCase as fadFontCase }   from '@fortawesome/pro-duotone-svg-icons';
import { faFontCase as falFontCase }   from '@fortawesome/pro-light-svg-icons';
import { faFontCase as farFontCase }   from '@fortawesome/pro-regular-svg-icons';
import { faFontCase as fasFontCase }   from '@fortawesome/pro-solid-svg-icons';
import { faFootball as fadFootball }   from '@fortawesome/pro-duotone-svg-icons';
import { faFootball as falFootball }   from '@fortawesome/pro-light-svg-icons';
import { faFootball as farFootball }   from '@fortawesome/pro-regular-svg-icons';
import { faFootball as fasFootball }   from '@fortawesome/pro-solid-svg-icons';
import { faFootballBall as fadFootballBall }   from '@fortawesome/pro-duotone-svg-icons';
import { faFootballBall as falFootballBall }   from '@fortawesome/pro-light-svg-icons';
import { faFootballBall as farFootballBall }   from '@fortawesome/pro-regular-svg-icons';
import { faFootballBall as fasFootballBall }   from '@fortawesome/pro-solid-svg-icons';
import { faFootballHelmet as fadFootballHelmet }   from '@fortawesome/pro-duotone-svg-icons';
import { faFootballHelmet as falFootballHelmet }   from '@fortawesome/pro-light-svg-icons';
import { faFootballHelmet as farFootballHelmet }   from '@fortawesome/pro-regular-svg-icons';
import { faFootballHelmet as fasFootballHelmet }   from '@fortawesome/pro-solid-svg-icons';
import { faFork as fadFork }   from '@fortawesome/pro-duotone-svg-icons';
import { faFork as falFork }   from '@fortawesome/pro-light-svg-icons';
import { faFork as farFork }   from '@fortawesome/pro-regular-svg-icons';
import { faFork as fasFork }   from '@fortawesome/pro-solid-svg-icons';
import { faForkKnife as fadForkKnife }   from '@fortawesome/pro-duotone-svg-icons';
import { faForkKnife as falForkKnife }   from '@fortawesome/pro-light-svg-icons';
import { faForkKnife as farForkKnife }   from '@fortawesome/pro-regular-svg-icons';
import { faForkKnife as fasForkKnife }   from '@fortawesome/pro-solid-svg-icons';
import { faForklift as fadForklift }   from '@fortawesome/pro-duotone-svg-icons';
import { faForklift as falForklift }   from '@fortawesome/pro-light-svg-icons';
import { faForklift as farForklift }   from '@fortawesome/pro-regular-svg-icons';
import { faForklift as fasForklift }   from '@fortawesome/pro-solid-svg-icons';
import { faFort as fadFort }   from '@fortawesome/pro-duotone-svg-icons';
import { faFort as falFort }   from '@fortawesome/pro-light-svg-icons';
import { faFort as farFort }   from '@fortawesome/pro-regular-svg-icons';
import { faFort as fasFort }   from '@fortawesome/pro-solid-svg-icons';
import { faForward as fadForward }   from '@fortawesome/pro-duotone-svg-icons';
import { faForward as falForward }   from '@fortawesome/pro-light-svg-icons';
import { faForward as farForward }   from '@fortawesome/pro-regular-svg-icons';
import { faForward as fasForward }   from '@fortawesome/pro-solid-svg-icons';
import { faForwardFast as fadForwardFast }   from '@fortawesome/pro-duotone-svg-icons';
import { faForwardFast as falForwardFast }   from '@fortawesome/pro-light-svg-icons';
import { faForwardFast as farForwardFast }   from '@fortawesome/pro-regular-svg-icons';
import { faForwardFast as fasForwardFast }   from '@fortawesome/pro-solid-svg-icons';
import { faForwardStep as fadForwardStep }   from '@fortawesome/pro-duotone-svg-icons';
import { faForwardStep as falForwardStep }   from '@fortawesome/pro-light-svg-icons';
import { faForwardStep as farForwardStep }   from '@fortawesome/pro-regular-svg-icons';
import { faForwardStep as fasForwardStep }   from '@fortawesome/pro-solid-svg-icons';
import { faFragile as fadFragile }   from '@fortawesome/pro-duotone-svg-icons';
import { faFragile as falFragile }   from '@fortawesome/pro-light-svg-icons';
import { faFragile as farFragile }   from '@fortawesome/pro-regular-svg-icons';
import { faFragile as fasFragile }   from '@fortawesome/pro-solid-svg-icons';
import { faFrame as fadFrame }   from '@fortawesome/pro-duotone-svg-icons';
import { faFrame as falFrame }   from '@fortawesome/pro-light-svg-icons';
import { faFrame as farFrame }   from '@fortawesome/pro-regular-svg-icons';
import { faFrame as fasFrame }   from '@fortawesome/pro-solid-svg-icons';
import { faFrancSign as fadFrancSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faFrancSign as falFrancSign }   from '@fortawesome/pro-light-svg-icons';
import { faFrancSign as farFrancSign }   from '@fortawesome/pro-regular-svg-icons';
import { faFrancSign as fasFrancSign }   from '@fortawesome/pro-solid-svg-icons';
import { faFrenchFries as fadFrenchFries }   from '@fortawesome/pro-duotone-svg-icons';
import { faFrenchFries as falFrenchFries }   from '@fortawesome/pro-light-svg-icons';
import { faFrenchFries as farFrenchFries }   from '@fortawesome/pro-regular-svg-icons';
import { faFrenchFries as fasFrenchFries }   from '@fortawesome/pro-solid-svg-icons';
import { faFrog as fadFrog }   from '@fortawesome/pro-duotone-svg-icons';
import { faFrog as falFrog }   from '@fortawesome/pro-light-svg-icons';
import { faFrog as farFrog }   from '@fortawesome/pro-regular-svg-icons';
import { faFrog as fasFrog }   from '@fortawesome/pro-solid-svg-icons';
import { faFrostyHead as fadFrostyHead }   from '@fortawesome/pro-duotone-svg-icons';
import { faFrostyHead as falFrostyHead }   from '@fortawesome/pro-light-svg-icons';
import { faFrostyHead as farFrostyHead }   from '@fortawesome/pro-regular-svg-icons';
import { faFrostyHead as fasFrostyHead }   from '@fortawesome/pro-solid-svg-icons';
import { faFrown as fadFrown }   from '@fortawesome/pro-duotone-svg-icons';
import { faFrown as falFrown }   from '@fortawesome/pro-light-svg-icons';
import { faFrown as farFrown }   from '@fortawesome/pro-regular-svg-icons';
import { faFrown as fasFrown }   from '@fortawesome/pro-solid-svg-icons';
import { faFrownOpen as fadFrownOpen }   from '@fortawesome/pro-duotone-svg-icons';
import { faFrownOpen as falFrownOpen }   from '@fortawesome/pro-light-svg-icons';
import { faFrownOpen as farFrownOpen }   from '@fortawesome/pro-regular-svg-icons';
import { faFrownOpen as fasFrownOpen }   from '@fortawesome/pro-solid-svg-icons';
import { faFunction as fadFunction }   from '@fortawesome/pro-duotone-svg-icons';
import { faFunction as falFunction }   from '@fortawesome/pro-light-svg-icons';
import { faFunction as farFunction }   from '@fortawesome/pro-regular-svg-icons';
import { faFunction as fasFunction }   from '@fortawesome/pro-solid-svg-icons';
import { faFunnelDollar as fadFunnelDollar }   from '@fortawesome/pro-duotone-svg-icons';
import { faFunnelDollar as falFunnelDollar }   from '@fortawesome/pro-light-svg-icons';
import { faFunnelDollar as farFunnelDollar }   from '@fortawesome/pro-regular-svg-icons';
import { faFunnelDollar as fasFunnelDollar }   from '@fortawesome/pro-solid-svg-icons';
import { faFutbol as fadFutbol }   from '@fortawesome/pro-duotone-svg-icons';
import { faFutbol as falFutbol }   from '@fortawesome/pro-light-svg-icons';
import { faFutbol as farFutbol }   from '@fortawesome/pro-regular-svg-icons';
import { faFutbol as fasFutbol }   from '@fortawesome/pro-solid-svg-icons';
import { faFutbolBall as fadFutbolBall }   from '@fortawesome/pro-duotone-svg-icons';
import { faFutbolBall as falFutbolBall }   from '@fortawesome/pro-light-svg-icons';
import { faFutbolBall as farFutbolBall }   from '@fortawesome/pro-regular-svg-icons';
import { faFutbolBall as fasFutbolBall }   from '@fortawesome/pro-solid-svg-icons';
import { faG as fadG }   from '@fortawesome/pro-duotone-svg-icons';
import { faG as falG }   from '@fortawesome/pro-light-svg-icons';
import { faG as farG }   from '@fortawesome/pro-regular-svg-icons';
import { faG as fasG }   from '@fortawesome/pro-solid-svg-icons';
import { faGalaxy as fadGalaxy }   from '@fortawesome/pro-duotone-svg-icons';
import { faGalaxy as falGalaxy }   from '@fortawesome/pro-light-svg-icons';
import { faGalaxy as farGalaxy }   from '@fortawesome/pro-regular-svg-icons';
import { faGalaxy as fasGalaxy }   from '@fortawesome/pro-solid-svg-icons';
import { faGalleryThumbnails as fadGalleryThumbnails }   from '@fortawesome/pro-duotone-svg-icons';
import { faGalleryThumbnails as falGalleryThumbnails }   from '@fortawesome/pro-light-svg-icons';
import { faGalleryThumbnails as farGalleryThumbnails }   from '@fortawesome/pro-regular-svg-icons';
import { faGalleryThumbnails as fasGalleryThumbnails }   from '@fortawesome/pro-solid-svg-icons';
import { faGameBoard as fadGameBoard }   from '@fortawesome/pro-duotone-svg-icons';
import { faGameBoard as falGameBoard }   from '@fortawesome/pro-light-svg-icons';
import { faGameBoard as farGameBoard }   from '@fortawesome/pro-regular-svg-icons';
import { faGameBoard as fasGameBoard }   from '@fortawesome/pro-solid-svg-icons';
import { faGameBoardAlt as fadGameBoardAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faGameBoardAlt as falGameBoardAlt }   from '@fortawesome/pro-light-svg-icons';
import { faGameBoardAlt as farGameBoardAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faGameBoardAlt as fasGameBoardAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faGameBoardSimple as fadGameBoardSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faGameBoardSimple as falGameBoardSimple }   from '@fortawesome/pro-light-svg-icons';
import { faGameBoardSimple as farGameBoardSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faGameBoardSimple as fasGameBoardSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faGameConsoleHandheld as fadGameConsoleHandheld }   from '@fortawesome/pro-duotone-svg-icons';
import { faGameConsoleHandheld as falGameConsoleHandheld }   from '@fortawesome/pro-light-svg-icons';
import { faGameConsoleHandheld as farGameConsoleHandheld }   from '@fortawesome/pro-regular-svg-icons';
import { faGameConsoleHandheld as fasGameConsoleHandheld }   from '@fortawesome/pro-solid-svg-icons';
import { faGamepad as fadGamepad }   from '@fortawesome/pro-duotone-svg-icons';
import { faGamepad as falGamepad }   from '@fortawesome/pro-light-svg-icons';
import { faGamepad as farGamepad }   from '@fortawesome/pro-regular-svg-icons';
import { faGamepad as fasGamepad }   from '@fortawesome/pro-solid-svg-icons';
import { faGamepadAlt as fadGamepadAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faGamepadAlt as falGamepadAlt }   from '@fortawesome/pro-light-svg-icons';
import { faGamepadAlt as farGamepadAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faGamepadAlt as fasGamepadAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faGamepadModern as fadGamepadModern }   from '@fortawesome/pro-duotone-svg-icons';
import { faGamepadModern as falGamepadModern }   from '@fortawesome/pro-light-svg-icons';
import { faGamepadModern as farGamepadModern }   from '@fortawesome/pro-regular-svg-icons';
import { faGamepadModern as fasGamepadModern }   from '@fortawesome/pro-solid-svg-icons';
import { faGarage as fadGarage }   from '@fortawesome/pro-duotone-svg-icons';
import { faGarage as falGarage }   from '@fortawesome/pro-light-svg-icons';
import { faGarage as farGarage }   from '@fortawesome/pro-regular-svg-icons';
import { faGarage as fasGarage }   from '@fortawesome/pro-solid-svg-icons';
import { faGarageCar as fadGarageCar }   from '@fortawesome/pro-duotone-svg-icons';
import { faGarageCar as falGarageCar }   from '@fortawesome/pro-light-svg-icons';
import { faGarageCar as farGarageCar }   from '@fortawesome/pro-regular-svg-icons';
import { faGarageCar as fasGarageCar }   from '@fortawesome/pro-solid-svg-icons';
import { faGarageOpen as fadGarageOpen }   from '@fortawesome/pro-duotone-svg-icons';
import { faGarageOpen as falGarageOpen }   from '@fortawesome/pro-light-svg-icons';
import { faGarageOpen as farGarageOpen }   from '@fortawesome/pro-regular-svg-icons';
import { faGarageOpen as fasGarageOpen }   from '@fortawesome/pro-solid-svg-icons';
import { faGarlic as fadGarlic }   from '@fortawesome/pro-duotone-svg-icons';
import { faGarlic as falGarlic }   from '@fortawesome/pro-light-svg-icons';
import { faGarlic as farGarlic }   from '@fortawesome/pro-regular-svg-icons';
import { faGarlic as fasGarlic }   from '@fortawesome/pro-solid-svg-icons';
import { faGasPump as fadGasPump }   from '@fortawesome/pro-duotone-svg-icons';
import { faGasPump as falGasPump }   from '@fortawesome/pro-light-svg-icons';
import { faGasPump as farGasPump }   from '@fortawesome/pro-regular-svg-icons';
import { faGasPump as fasGasPump }   from '@fortawesome/pro-solid-svg-icons';
import { faGasPumpSlash as fadGasPumpSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faGasPumpSlash as falGasPumpSlash }   from '@fortawesome/pro-light-svg-icons';
import { faGasPumpSlash as farGasPumpSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faGasPumpSlash as fasGasPumpSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faGauge as fadGauge }   from '@fortawesome/pro-duotone-svg-icons';
import { faGauge as falGauge }   from '@fortawesome/pro-light-svg-icons';
import { faGauge as farGauge }   from '@fortawesome/pro-regular-svg-icons';
import { faGauge as fasGauge }   from '@fortawesome/pro-solid-svg-icons';
import { faGaugeCircleBolt as fadGaugeCircleBolt }   from '@fortawesome/pro-duotone-svg-icons';
import { faGaugeCircleBolt as falGaugeCircleBolt }   from '@fortawesome/pro-light-svg-icons';
import { faGaugeCircleBolt as farGaugeCircleBolt }   from '@fortawesome/pro-regular-svg-icons';
import { faGaugeCircleBolt as fasGaugeCircleBolt }   from '@fortawesome/pro-solid-svg-icons';
import { faGaugeCircleMinus as fadGaugeCircleMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faGaugeCircleMinus as falGaugeCircleMinus }   from '@fortawesome/pro-light-svg-icons';
import { faGaugeCircleMinus as farGaugeCircleMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faGaugeCircleMinus as fasGaugeCircleMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faGaugeCirclePlus as fadGaugeCirclePlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faGaugeCirclePlus as falGaugeCirclePlus }   from '@fortawesome/pro-light-svg-icons';
import { faGaugeCirclePlus as farGaugeCirclePlus }   from '@fortawesome/pro-regular-svg-icons';
import { faGaugeCirclePlus as fasGaugeCirclePlus }   from '@fortawesome/pro-solid-svg-icons';
import { faGaugeHigh as fadGaugeHigh }   from '@fortawesome/pro-duotone-svg-icons';
import { faGaugeHigh as falGaugeHigh }   from '@fortawesome/pro-light-svg-icons';
import { faGaugeHigh as farGaugeHigh }   from '@fortawesome/pro-regular-svg-icons';
import { faGaugeHigh as fasGaugeHigh }   from '@fortawesome/pro-solid-svg-icons';
import { faGaugeLow as fadGaugeLow }   from '@fortawesome/pro-duotone-svg-icons';
import { faGaugeLow as falGaugeLow }   from '@fortawesome/pro-light-svg-icons';
import { faGaugeLow as farGaugeLow }   from '@fortawesome/pro-regular-svg-icons';
import { faGaugeLow as fasGaugeLow }   from '@fortawesome/pro-solid-svg-icons';
import { faGaugeMax as fadGaugeMax }   from '@fortawesome/pro-duotone-svg-icons';
import { faGaugeMax as falGaugeMax }   from '@fortawesome/pro-light-svg-icons';
import { faGaugeMax as farGaugeMax }   from '@fortawesome/pro-regular-svg-icons';
import { faGaugeMax as fasGaugeMax }   from '@fortawesome/pro-solid-svg-icons';
import { faGaugeMed as fadGaugeMed }   from '@fortawesome/pro-duotone-svg-icons';
import { faGaugeMed as falGaugeMed }   from '@fortawesome/pro-light-svg-icons';
import { faGaugeMed as farGaugeMed }   from '@fortawesome/pro-regular-svg-icons';
import { faGaugeMed as fasGaugeMed }   from '@fortawesome/pro-solid-svg-icons';
import { faGaugeMin as fadGaugeMin }   from '@fortawesome/pro-duotone-svg-icons';
import { faGaugeMin as falGaugeMin }   from '@fortawesome/pro-light-svg-icons';
import { faGaugeMin as farGaugeMin }   from '@fortawesome/pro-regular-svg-icons';
import { faGaugeMin as fasGaugeMin }   from '@fortawesome/pro-solid-svg-icons';
import { faGaugeSimple as fadGaugeSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faGaugeSimple as falGaugeSimple }   from '@fortawesome/pro-light-svg-icons';
import { faGaugeSimple as farGaugeSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faGaugeSimple as fasGaugeSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faGaugeSimpleHigh as fadGaugeSimpleHigh }   from '@fortawesome/pro-duotone-svg-icons';
import { faGaugeSimpleHigh as falGaugeSimpleHigh }   from '@fortawesome/pro-light-svg-icons';
import { faGaugeSimpleHigh as farGaugeSimpleHigh }   from '@fortawesome/pro-regular-svg-icons';
import { faGaugeSimpleHigh as fasGaugeSimpleHigh }   from '@fortawesome/pro-solid-svg-icons';
import { faGaugeSimpleLow as fadGaugeSimpleLow }   from '@fortawesome/pro-duotone-svg-icons';
import { faGaugeSimpleLow as falGaugeSimpleLow }   from '@fortawesome/pro-light-svg-icons';
import { faGaugeSimpleLow as farGaugeSimpleLow }   from '@fortawesome/pro-regular-svg-icons';
import { faGaugeSimpleLow as fasGaugeSimpleLow }   from '@fortawesome/pro-solid-svg-icons';
import { faGaugeSimpleMax as fadGaugeSimpleMax }   from '@fortawesome/pro-duotone-svg-icons';
import { faGaugeSimpleMax as falGaugeSimpleMax }   from '@fortawesome/pro-light-svg-icons';
import { faGaugeSimpleMax as farGaugeSimpleMax }   from '@fortawesome/pro-regular-svg-icons';
import { faGaugeSimpleMax as fasGaugeSimpleMax }   from '@fortawesome/pro-solid-svg-icons';
import { faGaugeSimpleMed as fadGaugeSimpleMed }   from '@fortawesome/pro-duotone-svg-icons';
import { faGaugeSimpleMed as falGaugeSimpleMed }   from '@fortawesome/pro-light-svg-icons';
import { faGaugeSimpleMed as farGaugeSimpleMed }   from '@fortawesome/pro-regular-svg-icons';
import { faGaugeSimpleMed as fasGaugeSimpleMed }   from '@fortawesome/pro-solid-svg-icons';
import { faGaugeSimpleMin as fadGaugeSimpleMin }   from '@fortawesome/pro-duotone-svg-icons';
import { faGaugeSimpleMin as falGaugeSimpleMin }   from '@fortawesome/pro-light-svg-icons';
import { faGaugeSimpleMin as farGaugeSimpleMin }   from '@fortawesome/pro-regular-svg-icons';
import { faGaugeSimpleMin as fasGaugeSimpleMin }   from '@fortawesome/pro-solid-svg-icons';
import { faGaveDandy as fadGaveDandy }   from '@fortawesome/pro-duotone-svg-icons';
import { faGaveDandy as falGaveDandy }   from '@fortawesome/pro-light-svg-icons';
import { faGaveDandy as farGaveDandy }   from '@fortawesome/pro-regular-svg-icons';
import { faGaveDandy as fasGaveDandy }   from '@fortawesome/pro-solid-svg-icons';
import { faGavel as fadGavel }   from '@fortawesome/pro-duotone-svg-icons';
import { faGavel as falGavel }   from '@fortawesome/pro-light-svg-icons';
import { faGavel as farGavel }   from '@fortawesome/pro-regular-svg-icons';
import { faGavel as fasGavel }   from '@fortawesome/pro-solid-svg-icons';
import { faGbp as fadGbp }   from '@fortawesome/pro-duotone-svg-icons';
import { faGbp as falGbp }   from '@fortawesome/pro-light-svg-icons';
import { faGbp as farGbp }   from '@fortawesome/pro-regular-svg-icons';
import { faGbp as fasGbp }   from '@fortawesome/pro-solid-svg-icons';
import { faGear as fadGear }   from '@fortawesome/pro-duotone-svg-icons';
import { faGear as falGear }   from '@fortawesome/pro-light-svg-icons';
import { faGear as farGear }   from '@fortawesome/pro-regular-svg-icons';
import { faGear as fasGear }   from '@fortawesome/pro-solid-svg-icons';
import { faGears as fadGears }   from '@fortawesome/pro-duotone-svg-icons';
import { faGears as falGears }   from '@fortawesome/pro-light-svg-icons';
import { faGears as farGears }   from '@fortawesome/pro-regular-svg-icons';
import { faGears as fasGears }   from '@fortawesome/pro-solid-svg-icons';
import { faGem as fadGem }   from '@fortawesome/pro-duotone-svg-icons';
import { faGem as falGem }   from '@fortawesome/pro-light-svg-icons';
import { faGem as farGem }   from '@fortawesome/pro-regular-svg-icons';
import { faGem as fasGem }   from '@fortawesome/pro-solid-svg-icons';
import { faGenderless as fadGenderless }   from '@fortawesome/pro-duotone-svg-icons';
import { faGenderless as falGenderless }   from '@fortawesome/pro-light-svg-icons';
import { faGenderless as farGenderless }   from '@fortawesome/pro-regular-svg-icons';
import { faGenderless as fasGenderless }   from '@fortawesome/pro-solid-svg-icons';
import { faGhost as fadGhost }   from '@fortawesome/pro-duotone-svg-icons';
import { faGhost as falGhost }   from '@fortawesome/pro-light-svg-icons';
import { faGhost as farGhost }   from '@fortawesome/pro-regular-svg-icons';
import { faGhost as fasGhost }   from '@fortawesome/pro-solid-svg-icons';
import { faGif as fadGif }   from '@fortawesome/pro-duotone-svg-icons';
import { faGif as falGif }   from '@fortawesome/pro-light-svg-icons';
import { faGif as farGif }   from '@fortawesome/pro-regular-svg-icons';
import { faGif as fasGif }   from '@fortawesome/pro-solid-svg-icons';
import { faGift as fadGift }   from '@fortawesome/pro-duotone-svg-icons';
import { faGift as falGift }   from '@fortawesome/pro-light-svg-icons';
import { faGift as farGift }   from '@fortawesome/pro-regular-svg-icons';
import { faGift as fasGift }   from '@fortawesome/pro-solid-svg-icons';
import { faGiftCard as fadGiftCard }   from '@fortawesome/pro-duotone-svg-icons';
import { faGiftCard as falGiftCard }   from '@fortawesome/pro-light-svg-icons';
import { faGiftCard as farGiftCard }   from '@fortawesome/pro-regular-svg-icons';
import { faGiftCard as fasGiftCard }   from '@fortawesome/pro-solid-svg-icons';
import { faGifts as fadGifts }   from '@fortawesome/pro-duotone-svg-icons';
import { faGifts as falGifts }   from '@fortawesome/pro-light-svg-icons';
import { faGifts as farGifts }   from '@fortawesome/pro-regular-svg-icons';
import { faGifts as fasGifts }   from '@fortawesome/pro-solid-svg-icons';
import { faGingerbreadMan as fadGingerbreadMan }   from '@fortawesome/pro-duotone-svg-icons';
import { faGingerbreadMan as falGingerbreadMan }   from '@fortawesome/pro-light-svg-icons';
import { faGingerbreadMan as farGingerbreadMan }   from '@fortawesome/pro-regular-svg-icons';
import { faGingerbreadMan as fasGingerbreadMan }   from '@fortawesome/pro-solid-svg-icons';
import { faGlass as fadGlass }   from '@fortawesome/pro-duotone-svg-icons';
import { faGlass as falGlass }   from '@fortawesome/pro-light-svg-icons';
import { faGlass as farGlass }   from '@fortawesome/pro-regular-svg-icons';
import { faGlass as fasGlass }   from '@fortawesome/pro-solid-svg-icons';
import { faGlassChampagne as fadGlassChampagne }   from '@fortawesome/pro-duotone-svg-icons';
import { faGlassChampagne as falGlassChampagne }   from '@fortawesome/pro-light-svg-icons';
import { faGlassChampagne as farGlassChampagne }   from '@fortawesome/pro-regular-svg-icons';
import { faGlassChampagne as fasGlassChampagne }   from '@fortawesome/pro-solid-svg-icons';
import { faGlassCheers as fadGlassCheers }   from '@fortawesome/pro-duotone-svg-icons';
import { faGlassCheers as falGlassCheers }   from '@fortawesome/pro-light-svg-icons';
import { faGlassCheers as farGlassCheers }   from '@fortawesome/pro-regular-svg-icons';
import { faGlassCheers as fasGlassCheers }   from '@fortawesome/pro-solid-svg-icons';
import { faGlassCitrus as fadGlassCitrus }   from '@fortawesome/pro-duotone-svg-icons';
import { faGlassCitrus as falGlassCitrus }   from '@fortawesome/pro-light-svg-icons';
import { faGlassCitrus as farGlassCitrus }   from '@fortawesome/pro-regular-svg-icons';
import { faGlassCitrus as fasGlassCitrus }   from '@fortawesome/pro-solid-svg-icons';
import { faGlassEmpty as fadGlassEmpty }   from '@fortawesome/pro-duotone-svg-icons';
import { faGlassEmpty as falGlassEmpty }   from '@fortawesome/pro-light-svg-icons';
import { faGlassEmpty as farGlassEmpty }   from '@fortawesome/pro-regular-svg-icons';
import { faGlassEmpty as fasGlassEmpty }   from '@fortawesome/pro-solid-svg-icons';
import { faGlasses as fadGlasses }   from '@fortawesome/pro-duotone-svg-icons';
import { faGlasses as falGlasses }   from '@fortawesome/pro-light-svg-icons';
import { faGlasses as farGlasses }   from '@fortawesome/pro-regular-svg-icons';
import { faGlasses as fasGlasses }   from '@fortawesome/pro-solid-svg-icons';
import { faGlassesAlt as fadGlassesAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faGlassesAlt as falGlassesAlt }   from '@fortawesome/pro-light-svg-icons';
import { faGlassesAlt as farGlassesAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faGlassesAlt as fasGlassesAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faGlassesRound as fadGlassesRound }   from '@fortawesome/pro-duotone-svg-icons';
import { faGlassesRound as falGlassesRound }   from '@fortawesome/pro-light-svg-icons';
import { faGlassesRound as farGlassesRound }   from '@fortawesome/pro-regular-svg-icons';
import { faGlassesRound as fasGlassesRound }   from '@fortawesome/pro-solid-svg-icons';
import { faGlassHalf as fadGlassHalf }   from '@fortawesome/pro-duotone-svg-icons';
import { faGlassHalf as falGlassHalf }   from '@fortawesome/pro-light-svg-icons';
import { faGlassHalf as farGlassHalf }   from '@fortawesome/pro-regular-svg-icons';
import { faGlassHalf as fasGlassHalf }   from '@fortawesome/pro-solid-svg-icons';
import { faGlassHalfEmpty as fadGlassHalfEmpty }   from '@fortawesome/pro-duotone-svg-icons';
import { faGlassHalfEmpty as falGlassHalfEmpty }   from '@fortawesome/pro-light-svg-icons';
import { faGlassHalfEmpty as farGlassHalfEmpty }   from '@fortawesome/pro-regular-svg-icons';
import { faGlassHalfEmpty as fasGlassHalfEmpty }   from '@fortawesome/pro-solid-svg-icons';
import { faGlassHalfFull as fadGlassHalfFull }   from '@fortawesome/pro-duotone-svg-icons';
import { faGlassHalfFull as falGlassHalfFull }   from '@fortawesome/pro-light-svg-icons';
import { faGlassHalfFull as farGlassHalfFull }   from '@fortawesome/pro-regular-svg-icons';
import { faGlassHalfFull as fasGlassHalfFull }   from '@fortawesome/pro-solid-svg-icons';
import { faGlassMartini as fadGlassMartini }   from '@fortawesome/pro-duotone-svg-icons';
import { faGlassMartini as falGlassMartini }   from '@fortawesome/pro-light-svg-icons';
import { faGlassMartini as farGlassMartini }   from '@fortawesome/pro-regular-svg-icons';
import { faGlassMartini as fasGlassMartini }   from '@fortawesome/pro-solid-svg-icons';
import { faGlassMartiniAlt as fadGlassMartiniAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faGlassMartiniAlt as falGlassMartiniAlt }   from '@fortawesome/pro-light-svg-icons';
import { faGlassMartiniAlt as farGlassMartiniAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faGlassMartiniAlt as fasGlassMartiniAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faGlassWater as fadGlassWater }   from '@fortawesome/pro-duotone-svg-icons';
import { faGlassWater as falGlassWater }   from '@fortawesome/pro-light-svg-icons';
import { faGlassWater as farGlassWater }   from '@fortawesome/pro-regular-svg-icons';
import { faGlassWater as fasGlassWater }   from '@fortawesome/pro-solid-svg-icons';
import { faGlassWaterDroplet as fadGlassWaterDroplet }   from '@fortawesome/pro-duotone-svg-icons';
import { faGlassWaterDroplet as falGlassWaterDroplet }   from '@fortawesome/pro-light-svg-icons';
import { faGlassWaterDroplet as farGlassWaterDroplet }   from '@fortawesome/pro-regular-svg-icons';
import { faGlassWaterDroplet as fasGlassWaterDroplet }   from '@fortawesome/pro-solid-svg-icons';
import { faGlassWhiskey as fadGlassWhiskey }   from '@fortawesome/pro-duotone-svg-icons';
import { faGlassWhiskey as falGlassWhiskey }   from '@fortawesome/pro-light-svg-icons';
import { faGlassWhiskey as farGlassWhiskey }   from '@fortawesome/pro-regular-svg-icons';
import { faGlassWhiskey as fasGlassWhiskey }   from '@fortawesome/pro-solid-svg-icons';
import { faGlassWhiskeyRocks as fadGlassWhiskeyRocks }   from '@fortawesome/pro-duotone-svg-icons';
import { faGlassWhiskeyRocks as falGlassWhiskeyRocks }   from '@fortawesome/pro-light-svg-icons';
import { faGlassWhiskeyRocks as farGlassWhiskeyRocks }   from '@fortawesome/pro-regular-svg-icons';
import { faGlassWhiskeyRocks as fasGlassWhiskeyRocks }   from '@fortawesome/pro-solid-svg-icons';
import { faGlobe as fadGlobe }   from '@fortawesome/pro-duotone-svg-icons';
import { faGlobe as falGlobe }   from '@fortawesome/pro-light-svg-icons';
import { faGlobe as farGlobe }   from '@fortawesome/pro-regular-svg-icons';
import { faGlobe as fasGlobe }   from '@fortawesome/pro-solid-svg-icons';
import { faGlobeAfrica as fadGlobeAfrica }   from '@fortawesome/pro-duotone-svg-icons';
import { faGlobeAfrica as falGlobeAfrica }   from '@fortawesome/pro-light-svg-icons';
import { faGlobeAfrica as farGlobeAfrica }   from '@fortawesome/pro-regular-svg-icons';
import { faGlobeAfrica as fasGlobeAfrica }   from '@fortawesome/pro-solid-svg-icons';
import { faGlobeAmericas as fadGlobeAmericas }   from '@fortawesome/pro-duotone-svg-icons';
import { faGlobeAmericas as falGlobeAmericas }   from '@fortawesome/pro-light-svg-icons';
import { faGlobeAmericas as farGlobeAmericas }   from '@fortawesome/pro-regular-svg-icons';
import { faGlobeAmericas as fasGlobeAmericas }   from '@fortawesome/pro-solid-svg-icons';
import { faGlobeAsia as fadGlobeAsia }   from '@fortawesome/pro-duotone-svg-icons';
import { faGlobeAsia as falGlobeAsia }   from '@fortawesome/pro-light-svg-icons';
import { faGlobeAsia as farGlobeAsia }   from '@fortawesome/pro-regular-svg-icons';
import { faGlobeAsia as fasGlobeAsia }   from '@fortawesome/pro-solid-svg-icons';
import { faGlobeEurope as fadGlobeEurope }   from '@fortawesome/pro-duotone-svg-icons';
import { faGlobeEurope as falGlobeEurope }   from '@fortawesome/pro-light-svg-icons';
import { faGlobeEurope as farGlobeEurope }   from '@fortawesome/pro-regular-svg-icons';
import { faGlobeEurope as fasGlobeEurope }   from '@fortawesome/pro-solid-svg-icons';
import { faGlobeOceania as fadGlobeOceania }   from '@fortawesome/pro-duotone-svg-icons';
import { faGlobeOceania as falGlobeOceania }   from '@fortawesome/pro-light-svg-icons';
import { faGlobeOceania as farGlobeOceania }   from '@fortawesome/pro-regular-svg-icons';
import { faGlobeOceania as fasGlobeOceania }   from '@fortawesome/pro-solid-svg-icons';
import { faGlobeSnow as fadGlobeSnow }   from '@fortawesome/pro-duotone-svg-icons';
import { faGlobeSnow as falGlobeSnow }   from '@fortawesome/pro-light-svg-icons';
import { faGlobeSnow as farGlobeSnow }   from '@fortawesome/pro-regular-svg-icons';
import { faGlobeSnow as fasGlobeSnow }   from '@fortawesome/pro-solid-svg-icons';
import { faGlobeStand as fadGlobeStand }   from '@fortawesome/pro-duotone-svg-icons';
import { faGlobeStand as falGlobeStand }   from '@fortawesome/pro-light-svg-icons';
import { faGlobeStand as farGlobeStand }   from '@fortawesome/pro-regular-svg-icons';
import { faGlobeStand as fasGlobeStand }   from '@fortawesome/pro-solid-svg-icons';
import { faGloveBoxing as fadGloveBoxing }   from '@fortawesome/pro-duotone-svg-icons';
import { faGloveBoxing as falGloveBoxing }   from '@fortawesome/pro-light-svg-icons';
import { faGloveBoxing as farGloveBoxing }   from '@fortawesome/pro-regular-svg-icons';
import { faGloveBoxing as fasGloveBoxing }   from '@fortawesome/pro-solid-svg-icons';
import { faGoalNet as fadGoalNet }   from '@fortawesome/pro-duotone-svg-icons';
import { faGoalNet as falGoalNet }   from '@fortawesome/pro-light-svg-icons';
import { faGoalNet as farGoalNet }   from '@fortawesome/pro-regular-svg-icons';
import { faGoalNet as fasGoalNet }   from '@fortawesome/pro-solid-svg-icons';
import { faGolfBall as fadGolfBall }   from '@fortawesome/pro-duotone-svg-icons';
import { faGolfBall as falGolfBall }   from '@fortawesome/pro-light-svg-icons';
import { faGolfBall as farGolfBall }   from '@fortawesome/pro-regular-svg-icons';
import { faGolfBall as fasGolfBall }   from '@fortawesome/pro-solid-svg-icons';
import { faGolfBallTee as fadGolfBallTee }   from '@fortawesome/pro-duotone-svg-icons';
import { faGolfBallTee as falGolfBallTee }   from '@fortawesome/pro-light-svg-icons';
import { faGolfBallTee as farGolfBallTee }   from '@fortawesome/pro-regular-svg-icons';
import { faGolfBallTee as fasGolfBallTee }   from '@fortawesome/pro-solid-svg-icons';
import { faGolfClub as fadGolfClub }   from '@fortawesome/pro-duotone-svg-icons';
import { faGolfClub as falGolfClub }   from '@fortawesome/pro-light-svg-icons';
import { faGolfClub as farGolfClub }   from '@fortawesome/pro-regular-svg-icons';
import { faGolfClub as fasGolfClub }   from '@fortawesome/pro-solid-svg-icons';
import { faGolfFlagHole as fadGolfFlagHole }   from '@fortawesome/pro-duotone-svg-icons';
import { faGolfFlagHole as falGolfFlagHole }   from '@fortawesome/pro-light-svg-icons';
import { faGolfFlagHole as farGolfFlagHole }   from '@fortawesome/pro-regular-svg-icons';
import { faGolfFlagHole as fasGolfFlagHole }   from '@fortawesome/pro-solid-svg-icons';
import { faGopuram as fadGopuram }   from '@fortawesome/pro-duotone-svg-icons';
import { faGopuram as falGopuram }   from '@fortawesome/pro-light-svg-icons';
import { faGopuram as farGopuram }   from '@fortawesome/pro-regular-svg-icons';
import { faGopuram as fasGopuram }   from '@fortawesome/pro-solid-svg-icons';
import { faGraduationCap as fadGraduationCap }   from '@fortawesome/pro-duotone-svg-icons';
import { faGraduationCap as falGraduationCap }   from '@fortawesome/pro-light-svg-icons';
import { faGraduationCap as farGraduationCap }   from '@fortawesome/pro-regular-svg-icons';
import { faGraduationCap as fasGraduationCap }   from '@fortawesome/pro-solid-svg-icons';
import { faGramophone as fadGramophone }   from '@fortawesome/pro-duotone-svg-icons';
import { faGramophone as falGramophone }   from '@fortawesome/pro-light-svg-icons';
import { faGramophone as farGramophone }   from '@fortawesome/pro-regular-svg-icons';
import { faGramophone as fasGramophone }   from '@fortawesome/pro-solid-svg-icons';
import { faGrapes as fadGrapes }   from '@fortawesome/pro-duotone-svg-icons';
import { faGrapes as falGrapes }   from '@fortawesome/pro-light-svg-icons';
import { faGrapes as farGrapes }   from '@fortawesome/pro-regular-svg-icons';
import { faGrapes as fasGrapes }   from '@fortawesome/pro-solid-svg-icons';
import { faGrate as fadGrate }   from '@fortawesome/pro-duotone-svg-icons';
import { faGrate as falGrate }   from '@fortawesome/pro-light-svg-icons';
import { faGrate as farGrate }   from '@fortawesome/pro-regular-svg-icons';
import { faGrate as fasGrate }   from '@fortawesome/pro-solid-svg-icons';
import { faGrateDroplet as fadGrateDroplet }   from '@fortawesome/pro-duotone-svg-icons';
import { faGrateDroplet as falGrateDroplet }   from '@fortawesome/pro-light-svg-icons';
import { faGrateDroplet as farGrateDroplet }   from '@fortawesome/pro-regular-svg-icons';
import { faGrateDroplet as fasGrateDroplet }   from '@fortawesome/pro-solid-svg-icons';
import { faGreaterThan as fadGreaterThan }   from '@fortawesome/pro-duotone-svg-icons';
import { faGreaterThan as falGreaterThan }   from '@fortawesome/pro-light-svg-icons';
import { faGreaterThan as farGreaterThan }   from '@fortawesome/pro-regular-svg-icons';
import { faGreaterThan as fasGreaterThan }   from '@fortawesome/pro-solid-svg-icons';
import { faGreaterThanEqual as fadGreaterThanEqual }   from '@fortawesome/pro-duotone-svg-icons';
import { faGreaterThanEqual as falGreaterThanEqual }   from '@fortawesome/pro-light-svg-icons';
import { faGreaterThanEqual as farGreaterThanEqual }   from '@fortawesome/pro-regular-svg-icons';
import { faGreaterThanEqual as fasGreaterThanEqual }   from '@fortawesome/pro-solid-svg-icons';
import { faGrid as fadGrid }   from '@fortawesome/pro-duotone-svg-icons';
import { faGrid as falGrid }   from '@fortawesome/pro-light-svg-icons';
import { faGrid as farGrid }   from '@fortawesome/pro-regular-svg-icons';
import { faGrid as fasGrid }   from '@fortawesome/pro-solid-svg-icons';
import { faGrid2 as fadGrid2 }   from '@fortawesome/pro-duotone-svg-icons';
import { faGrid2 as falGrid2 }   from '@fortawesome/pro-light-svg-icons';
import { faGrid2 as farGrid2 }   from '@fortawesome/pro-regular-svg-icons';
import { faGrid2 as fasGrid2 }   from '@fortawesome/pro-solid-svg-icons';
import { faGrid2Plus as fadGrid2Plus }   from '@fortawesome/pro-duotone-svg-icons';
import { faGrid2Plus as falGrid2Plus }   from '@fortawesome/pro-light-svg-icons';
import { faGrid2Plus as farGrid2Plus }   from '@fortawesome/pro-regular-svg-icons';
import { faGrid2Plus as fasGrid2Plus }   from '@fortawesome/pro-solid-svg-icons';
import { faGrid3 as fadGrid3 }   from '@fortawesome/pro-duotone-svg-icons';
import { faGrid3 as falGrid3 }   from '@fortawesome/pro-light-svg-icons';
import { faGrid3 as farGrid3 }   from '@fortawesome/pro-regular-svg-icons';
import { faGrid3 as fasGrid3 }   from '@fortawesome/pro-solid-svg-icons';
import { faGrid4 as fadGrid4 }   from '@fortawesome/pro-duotone-svg-icons';
import { faGrid4 as falGrid4 }   from '@fortawesome/pro-light-svg-icons';
import { faGrid4 as farGrid4 }   from '@fortawesome/pro-regular-svg-icons';
import { faGrid4 as fasGrid4 }   from '@fortawesome/pro-solid-svg-icons';
import { faGrid5 as fadGrid5 }   from '@fortawesome/pro-duotone-svg-icons';
import { faGrid5 as falGrid5 }   from '@fortawesome/pro-light-svg-icons';
import { faGrid5 as farGrid5 }   from '@fortawesome/pro-regular-svg-icons';
import { faGrid5 as fasGrid5 }   from '@fortawesome/pro-solid-svg-icons';
import { faGridDividers as fadGridDividers }   from '@fortawesome/pro-duotone-svg-icons';
import { faGridDividers as falGridDividers }   from '@fortawesome/pro-light-svg-icons';
import { faGridDividers as farGridDividers }   from '@fortawesome/pro-regular-svg-icons';
import { faGridDividers as fasGridDividers }   from '@fortawesome/pro-solid-svg-icons';
import { faGridHorizontal as fadGridHorizontal }   from '@fortawesome/pro-duotone-svg-icons';
import { faGridHorizontal as falGridHorizontal }   from '@fortawesome/pro-light-svg-icons';
import { faGridHorizontal as farGridHorizontal }   from '@fortawesome/pro-regular-svg-icons';
import { faGridHorizontal as fasGridHorizontal }   from '@fortawesome/pro-solid-svg-icons';
import { faGrimace as fadGrimace }   from '@fortawesome/pro-duotone-svg-icons';
import { faGrimace as falGrimace }   from '@fortawesome/pro-light-svg-icons';
import { faGrimace as farGrimace }   from '@fortawesome/pro-regular-svg-icons';
import { faGrimace as fasGrimace }   from '@fortawesome/pro-solid-svg-icons';
import { faGrin as fadGrin }   from '@fortawesome/pro-duotone-svg-icons';
import { faGrin as falGrin }   from '@fortawesome/pro-light-svg-icons';
import { faGrin as farGrin }   from '@fortawesome/pro-regular-svg-icons';
import { faGrin as fasGrin }   from '@fortawesome/pro-solid-svg-icons';
import { faGrinAlt as fadGrinAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faGrinAlt as falGrinAlt }   from '@fortawesome/pro-light-svg-icons';
import { faGrinAlt as farGrinAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faGrinAlt as fasGrinAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faGrinBeam as fadGrinBeam }   from '@fortawesome/pro-duotone-svg-icons';
import { faGrinBeam as falGrinBeam }   from '@fortawesome/pro-light-svg-icons';
import { faGrinBeam as farGrinBeam }   from '@fortawesome/pro-regular-svg-icons';
import { faGrinBeam as fasGrinBeam }   from '@fortawesome/pro-solid-svg-icons';
import { faGrinBeamSweat as fadGrinBeamSweat }   from '@fortawesome/pro-duotone-svg-icons';
import { faGrinBeamSweat as falGrinBeamSweat }   from '@fortawesome/pro-light-svg-icons';
import { faGrinBeamSweat as farGrinBeamSweat }   from '@fortawesome/pro-regular-svg-icons';
import { faGrinBeamSweat as fasGrinBeamSweat }   from '@fortawesome/pro-solid-svg-icons';
import { faGrinHearts as fadGrinHearts }   from '@fortawesome/pro-duotone-svg-icons';
import { faGrinHearts as falGrinHearts }   from '@fortawesome/pro-light-svg-icons';
import { faGrinHearts as farGrinHearts }   from '@fortawesome/pro-regular-svg-icons';
import { faGrinHearts as fasGrinHearts }   from '@fortawesome/pro-solid-svg-icons';
import { faGrinSquint as fadGrinSquint }   from '@fortawesome/pro-duotone-svg-icons';
import { faGrinSquint as falGrinSquint }   from '@fortawesome/pro-light-svg-icons';
import { faGrinSquint as farGrinSquint }   from '@fortawesome/pro-regular-svg-icons';
import { faGrinSquint as fasGrinSquint }   from '@fortawesome/pro-solid-svg-icons';
import { faGrinSquintTears as fadGrinSquintTears }   from '@fortawesome/pro-duotone-svg-icons';
import { faGrinSquintTears as falGrinSquintTears }   from '@fortawesome/pro-light-svg-icons';
import { faGrinSquintTears as farGrinSquintTears }   from '@fortawesome/pro-regular-svg-icons';
import { faGrinSquintTears as fasGrinSquintTears }   from '@fortawesome/pro-solid-svg-icons';
import { faGrinStars as fadGrinStars }   from '@fortawesome/pro-duotone-svg-icons';
import { faGrinStars as falGrinStars }   from '@fortawesome/pro-light-svg-icons';
import { faGrinStars as farGrinStars }   from '@fortawesome/pro-regular-svg-icons';
import { faGrinStars as fasGrinStars }   from '@fortawesome/pro-solid-svg-icons';
import { faGrinTears as fadGrinTears }   from '@fortawesome/pro-duotone-svg-icons';
import { faGrinTears as falGrinTears }   from '@fortawesome/pro-light-svg-icons';
import { faGrinTears as farGrinTears }   from '@fortawesome/pro-regular-svg-icons';
import { faGrinTears as fasGrinTears }   from '@fortawesome/pro-solid-svg-icons';
import { faGrinTongue as fadGrinTongue }   from '@fortawesome/pro-duotone-svg-icons';
import { faGrinTongue as falGrinTongue }   from '@fortawesome/pro-light-svg-icons';
import { faGrinTongue as farGrinTongue }   from '@fortawesome/pro-regular-svg-icons';
import { faGrinTongue as fasGrinTongue }   from '@fortawesome/pro-solid-svg-icons';
import { faGrinTongueSquint as fadGrinTongueSquint }   from '@fortawesome/pro-duotone-svg-icons';
import { faGrinTongueSquint as falGrinTongueSquint }   from '@fortawesome/pro-light-svg-icons';
import { faGrinTongueSquint as farGrinTongueSquint }   from '@fortawesome/pro-regular-svg-icons';
import { faGrinTongueSquint as fasGrinTongueSquint }   from '@fortawesome/pro-solid-svg-icons';
import { faGrinTongueWink as fadGrinTongueWink }   from '@fortawesome/pro-duotone-svg-icons';
import { faGrinTongueWink as falGrinTongueWink }   from '@fortawesome/pro-light-svg-icons';
import { faGrinTongueWink as farGrinTongueWink }   from '@fortawesome/pro-regular-svg-icons';
import { faGrinTongueWink as fasGrinTongueWink }   from '@fortawesome/pro-solid-svg-icons';
import { faGrinWink as fadGrinWink }   from '@fortawesome/pro-duotone-svg-icons';
import { faGrinWink as falGrinWink }   from '@fortawesome/pro-light-svg-icons';
import { faGrinWink as farGrinWink }   from '@fortawesome/pro-regular-svg-icons';
import { faGrinWink as fasGrinWink }   from '@fortawesome/pro-solid-svg-icons';
import { faGrip as fadGrip }   from '@fortawesome/pro-duotone-svg-icons';
import { faGrip as falGrip }   from '@fortawesome/pro-light-svg-icons';
import { faGrip as farGrip }   from '@fortawesome/pro-regular-svg-icons';
import { faGrip as fasGrip }   from '@fortawesome/pro-solid-svg-icons';
import { faGripDots as fadGripDots }   from '@fortawesome/pro-duotone-svg-icons';
import { faGripDots as falGripDots }   from '@fortawesome/pro-light-svg-icons';
import { faGripDots as farGripDots }   from '@fortawesome/pro-regular-svg-icons';
import { faGripDots as fasGripDots }   from '@fortawesome/pro-solid-svg-icons';
import { faGripDotsVertical as fadGripDotsVertical }   from '@fortawesome/pro-duotone-svg-icons';
import { faGripDotsVertical as falGripDotsVertical }   from '@fortawesome/pro-light-svg-icons';
import { faGripDotsVertical as farGripDotsVertical }   from '@fortawesome/pro-regular-svg-icons';
import { faGripDotsVertical as fasGripDotsVertical }   from '@fortawesome/pro-solid-svg-icons';
import { faGripHorizontal as fadGripHorizontal }   from '@fortawesome/pro-duotone-svg-icons';
import { faGripHorizontal as falGripHorizontal }   from '@fortawesome/pro-light-svg-icons';
import { faGripHorizontal as farGripHorizontal }   from '@fortawesome/pro-regular-svg-icons';
import { faGripHorizontal as fasGripHorizontal }   from '@fortawesome/pro-solid-svg-icons';
import { faGripLines as fadGripLines }   from '@fortawesome/pro-duotone-svg-icons';
import { faGripLines as falGripLines }   from '@fortawesome/pro-light-svg-icons';
import { faGripLines as farGripLines }   from '@fortawesome/pro-regular-svg-icons';
import { faGripLines as fasGripLines }   from '@fortawesome/pro-solid-svg-icons';
import { faGripLinesVertical as fadGripLinesVertical }   from '@fortawesome/pro-duotone-svg-icons';
import { faGripLinesVertical as falGripLinesVertical }   from '@fortawesome/pro-light-svg-icons';
import { faGripLinesVertical as farGripLinesVertical }   from '@fortawesome/pro-regular-svg-icons';
import { faGripLinesVertical as fasGripLinesVertical }   from '@fortawesome/pro-solid-svg-icons';
import { faGripVertical as fadGripVertical }   from '@fortawesome/pro-duotone-svg-icons';
import { faGripVertical as falGripVertical }   from '@fortawesome/pro-light-svg-icons';
import { faGripVertical as farGripVertical }   from '@fortawesome/pro-regular-svg-icons';
import { faGripVertical as fasGripVertical }   from '@fortawesome/pro-solid-svg-icons';
import { faGroupArrowsRotate as fadGroupArrowsRotate }   from '@fortawesome/pro-duotone-svg-icons';
import { faGroupArrowsRotate as falGroupArrowsRotate }   from '@fortawesome/pro-light-svg-icons';
import { faGroupArrowsRotate as farGroupArrowsRotate }   from '@fortawesome/pro-regular-svg-icons';
import { faGroupArrowsRotate as fasGroupArrowsRotate }   from '@fortawesome/pro-solid-svg-icons';
import { faGuaraniSign as fadGuaraniSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faGuaraniSign as falGuaraniSign }   from '@fortawesome/pro-light-svg-icons';
import { faGuaraniSign as farGuaraniSign }   from '@fortawesome/pro-regular-svg-icons';
import { faGuaraniSign as fasGuaraniSign }   from '@fortawesome/pro-solid-svg-icons';
import { faGuitar as fadGuitar }   from '@fortawesome/pro-duotone-svg-icons';
import { faGuitar as falGuitar }   from '@fortawesome/pro-light-svg-icons';
import { faGuitar as farGuitar }   from '@fortawesome/pro-regular-svg-icons';
import { faGuitar as fasGuitar }   from '@fortawesome/pro-solid-svg-icons';
import { faGuitarElectric as fadGuitarElectric }   from '@fortawesome/pro-duotone-svg-icons';
import { faGuitarElectric as falGuitarElectric }   from '@fortawesome/pro-light-svg-icons';
import { faGuitarElectric as farGuitarElectric }   from '@fortawesome/pro-regular-svg-icons';
import { faGuitarElectric as fasGuitarElectric }   from '@fortawesome/pro-solid-svg-icons';
import { faGuitars as fadGuitars }   from '@fortawesome/pro-duotone-svg-icons';
import { faGuitars as falGuitars }   from '@fortawesome/pro-light-svg-icons';
import { faGuitars as farGuitars }   from '@fortawesome/pro-regular-svg-icons';
import { faGuitars as fasGuitars }   from '@fortawesome/pro-solid-svg-icons';
import { faGun as fadGun }   from '@fortawesome/pro-duotone-svg-icons';
import { faGun as falGun }   from '@fortawesome/pro-light-svg-icons';
import { faGun as farGun }   from '@fortawesome/pro-regular-svg-icons';
import { faGun as fasGun }   from '@fortawesome/pro-solid-svg-icons';
import { faGunSlash as fadGunSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faGunSlash as falGunSlash }   from '@fortawesome/pro-light-svg-icons';
import { faGunSlash as farGunSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faGunSlash as fasGunSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faGunSquirt as fadGunSquirt }   from '@fortawesome/pro-duotone-svg-icons';
import { faGunSquirt as falGunSquirt }   from '@fortawesome/pro-light-svg-icons';
import { faGunSquirt as farGunSquirt }   from '@fortawesome/pro-regular-svg-icons';
import { faGunSquirt as fasGunSquirt }   from '@fortawesome/pro-solid-svg-icons';
import { faH as fadH }   from '@fortawesome/pro-duotone-svg-icons';
import { faH as falH }   from '@fortawesome/pro-light-svg-icons';
import { faH as farH }   from '@fortawesome/pro-regular-svg-icons';
import { faH as fasH }   from '@fortawesome/pro-solid-svg-icons';
import { faH1 as fadH1 }   from '@fortawesome/pro-duotone-svg-icons';
import { faH1 as falH1 }   from '@fortawesome/pro-light-svg-icons';
import { faH1 as farH1 }   from '@fortawesome/pro-regular-svg-icons';
import { faH1 as fasH1 }   from '@fortawesome/pro-solid-svg-icons';
import { faH2 as fadH2 }   from '@fortawesome/pro-duotone-svg-icons';
import { faH2 as falH2 }   from '@fortawesome/pro-light-svg-icons';
import { faH2 as farH2 }   from '@fortawesome/pro-regular-svg-icons';
import { faH2 as fasH2 }   from '@fortawesome/pro-solid-svg-icons';
import { faH3 as fadH3 }   from '@fortawesome/pro-duotone-svg-icons';
import { faH3 as falH3 }   from '@fortawesome/pro-light-svg-icons';
import { faH3 as farH3 }   from '@fortawesome/pro-regular-svg-icons';
import { faH3 as fasH3 }   from '@fortawesome/pro-solid-svg-icons';
import { faH4 as fadH4 }   from '@fortawesome/pro-duotone-svg-icons';
import { faH4 as falH4 }   from '@fortawesome/pro-light-svg-icons';
import { faH4 as farH4 }   from '@fortawesome/pro-regular-svg-icons';
import { faH4 as fasH4 }   from '@fortawesome/pro-solid-svg-icons';
import { faH5 as fadH5 }   from '@fortawesome/pro-duotone-svg-icons';
import { faH5 as falH5 }   from '@fortawesome/pro-light-svg-icons';
import { faH5 as farH5 }   from '@fortawesome/pro-regular-svg-icons';
import { faH5 as fasH5 }   from '@fortawesome/pro-solid-svg-icons';
import { faH6 as fadH6 }   from '@fortawesome/pro-duotone-svg-icons';
import { faH6 as falH6 }   from '@fortawesome/pro-light-svg-icons';
import { faH6 as farH6 }   from '@fortawesome/pro-regular-svg-icons';
import { faH6 as fasH6 }   from '@fortawesome/pro-solid-svg-icons';
import { faHamburger as fadHamburger }   from '@fortawesome/pro-duotone-svg-icons';
import { faHamburger as falHamburger }   from '@fortawesome/pro-light-svg-icons';
import { faHamburger as farHamburger }   from '@fortawesome/pro-regular-svg-icons';
import { faHamburger as fasHamburger }   from '@fortawesome/pro-solid-svg-icons';
import { faHammer as fadHammer }   from '@fortawesome/pro-duotone-svg-icons';
import { faHammer as falHammer }   from '@fortawesome/pro-light-svg-icons';
import { faHammer as farHammer }   from '@fortawesome/pro-regular-svg-icons';
import { faHammer as fasHammer }   from '@fortawesome/pro-solid-svg-icons';
import { faHammerCrash as fadHammerCrash }   from '@fortawesome/pro-duotone-svg-icons';
import { faHammerCrash as falHammerCrash }   from '@fortawesome/pro-light-svg-icons';
import { faHammerCrash as farHammerCrash }   from '@fortawesome/pro-regular-svg-icons';
import { faHammerCrash as fasHammerCrash }   from '@fortawesome/pro-solid-svg-icons';
import { faHammerWar as fadHammerWar }   from '@fortawesome/pro-duotone-svg-icons';
import { faHammerWar as falHammerWar }   from '@fortawesome/pro-light-svg-icons';
import { faHammerWar as farHammerWar }   from '@fortawesome/pro-regular-svg-icons';
import { faHammerWar as fasHammerWar }   from '@fortawesome/pro-solid-svg-icons';
import { faHamsa as fadHamsa }   from '@fortawesome/pro-duotone-svg-icons';
import { faHamsa as falHamsa }   from '@fortawesome/pro-light-svg-icons';
import { faHamsa as farHamsa }   from '@fortawesome/pro-regular-svg-icons';
import { faHamsa as fasHamsa }   from '@fortawesome/pro-solid-svg-icons';
import { faHand as fadHand }   from '@fortawesome/pro-duotone-svg-icons';
import { faHand as falHand }   from '@fortawesome/pro-light-svg-icons';
import { faHand as farHand }   from '@fortawesome/pro-regular-svg-icons';
import { faHand as fasHand }   from '@fortawesome/pro-solid-svg-icons';
import { faHandBackFist as fadHandBackFist }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandBackFist as falHandBackFist }   from '@fortawesome/pro-light-svg-icons';
import { faHandBackFist as farHandBackFist }   from '@fortawesome/pro-regular-svg-icons';
import { faHandBackFist as fasHandBackFist }   from '@fortawesome/pro-solid-svg-icons';
import { faHandBackPointDown as fadHandBackPointDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandBackPointDown as falHandBackPointDown }   from '@fortawesome/pro-light-svg-icons';
import { faHandBackPointDown as farHandBackPointDown }   from '@fortawesome/pro-regular-svg-icons';
import { faHandBackPointDown as fasHandBackPointDown }   from '@fortawesome/pro-solid-svg-icons';
import { faHandBackPointLeft as fadHandBackPointLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandBackPointLeft as falHandBackPointLeft }   from '@fortawesome/pro-light-svg-icons';
import { faHandBackPointLeft as farHandBackPointLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faHandBackPointLeft as fasHandBackPointLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faHandBackPointRibbon as fadHandBackPointRibbon }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandBackPointRibbon as falHandBackPointRibbon }   from '@fortawesome/pro-light-svg-icons';
import { faHandBackPointRibbon as farHandBackPointRibbon }   from '@fortawesome/pro-regular-svg-icons';
import { faHandBackPointRibbon as fasHandBackPointRibbon }   from '@fortawesome/pro-solid-svg-icons';
import { faHandBackPointRight as fadHandBackPointRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandBackPointRight as falHandBackPointRight }   from '@fortawesome/pro-light-svg-icons';
import { faHandBackPointRight as farHandBackPointRight }   from '@fortawesome/pro-regular-svg-icons';
import { faHandBackPointRight as fasHandBackPointRight }   from '@fortawesome/pro-solid-svg-icons';
import { faHandBackPointUp as fadHandBackPointUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandBackPointUp as falHandBackPointUp }   from '@fortawesome/pro-light-svg-icons';
import { faHandBackPointUp as farHandBackPointUp }   from '@fortawesome/pro-regular-svg-icons';
import { faHandBackPointUp as fasHandBackPointUp }   from '@fortawesome/pro-solid-svg-icons';
import { faHandcuffs as fadHandcuffs }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandcuffs as falHandcuffs }   from '@fortawesome/pro-light-svg-icons';
import { faHandcuffs as farHandcuffs }   from '@fortawesome/pro-regular-svg-icons';
import { faHandcuffs as fasHandcuffs }   from '@fortawesome/pro-solid-svg-icons';
import { faHandDots as fadHandDots }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandDots as falHandDots }   from '@fortawesome/pro-light-svg-icons';
import { faHandDots as farHandDots }   from '@fortawesome/pro-regular-svg-icons';
import { faHandDots as fasHandDots }   from '@fortawesome/pro-solid-svg-icons';
import { faHandFingersCrossed as fadHandFingersCrossed }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandFingersCrossed as falHandFingersCrossed }   from '@fortawesome/pro-light-svg-icons';
import { faHandFingersCrossed as farHandFingersCrossed }   from '@fortawesome/pro-regular-svg-icons';
import { faHandFingersCrossed as fasHandFingersCrossed }   from '@fortawesome/pro-solid-svg-icons';
import { faHandFist as fadHandFist }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandFist as falHandFist }   from '@fortawesome/pro-light-svg-icons';
import { faHandFist as farHandFist }   from '@fortawesome/pro-regular-svg-icons';
import { faHandFist as fasHandFist }   from '@fortawesome/pro-solid-svg-icons';
import { faHandHeart as fadHandHeart }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandHeart as falHandHeart }   from '@fortawesome/pro-light-svg-icons';
import { faHandHeart as farHandHeart }   from '@fortawesome/pro-regular-svg-icons';
import { faHandHeart as fasHandHeart }   from '@fortawesome/pro-solid-svg-icons';
import { faHandHolding as fadHandHolding }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandHolding as falHandHolding }   from '@fortawesome/pro-light-svg-icons';
import { faHandHolding as farHandHolding }   from '@fortawesome/pro-regular-svg-icons';
import { faHandHolding as fasHandHolding }   from '@fortawesome/pro-solid-svg-icons';
import { faHandHoldingBox as fadHandHoldingBox }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandHoldingBox as falHandHoldingBox }   from '@fortawesome/pro-light-svg-icons';
import { faHandHoldingBox as farHandHoldingBox }   from '@fortawesome/pro-regular-svg-icons';
import { faHandHoldingBox as fasHandHoldingBox }   from '@fortawesome/pro-solid-svg-icons';
import { faHandHoldingDollar as fadHandHoldingDollar }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandHoldingDollar as falHandHoldingDollar }   from '@fortawesome/pro-light-svg-icons';
import { faHandHoldingDollar as farHandHoldingDollar }   from '@fortawesome/pro-regular-svg-icons';
import { faHandHoldingDollar as fasHandHoldingDollar }   from '@fortawesome/pro-solid-svg-icons';
import { faHandHoldingDroplet as fadHandHoldingDroplet }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandHoldingDroplet as falHandHoldingDroplet }   from '@fortawesome/pro-light-svg-icons';
import { faHandHoldingDroplet as farHandHoldingDroplet }   from '@fortawesome/pro-regular-svg-icons';
import { faHandHoldingDroplet as fasHandHoldingDroplet }   from '@fortawesome/pro-solid-svg-icons';
import { faHandHoldingHand as fadHandHoldingHand }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandHoldingHand as falHandHoldingHand }   from '@fortawesome/pro-light-svg-icons';
import { faHandHoldingHand as farHandHoldingHand }   from '@fortawesome/pro-regular-svg-icons';
import { faHandHoldingHand as fasHandHoldingHand }   from '@fortawesome/pro-solid-svg-icons';
import { faHandHoldingHeart as fadHandHoldingHeart }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandHoldingHeart as falHandHoldingHeart }   from '@fortawesome/pro-light-svg-icons';
import { faHandHoldingHeart as farHandHoldingHeart }   from '@fortawesome/pro-regular-svg-icons';
import { faHandHoldingHeart as fasHandHoldingHeart }   from '@fortawesome/pro-solid-svg-icons';
import { faHandHoldingMagic as fadHandHoldingMagic }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandHoldingMagic as falHandHoldingMagic }   from '@fortawesome/pro-light-svg-icons';
import { faHandHoldingMagic as farHandHoldingMagic }   from '@fortawesome/pro-regular-svg-icons';
import { faHandHoldingMagic as fasHandHoldingMagic }   from '@fortawesome/pro-solid-svg-icons';
import { faHandHoldingMedical as fadHandHoldingMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandHoldingMedical as falHandHoldingMedical }   from '@fortawesome/pro-light-svg-icons';
import { faHandHoldingMedical as farHandHoldingMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faHandHoldingMedical as fasHandHoldingMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faHandHoldingSeedling as fadHandHoldingSeedling }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandHoldingSeedling as falHandHoldingSeedling }   from '@fortawesome/pro-light-svg-icons';
import { faHandHoldingSeedling as farHandHoldingSeedling }   from '@fortawesome/pro-regular-svg-icons';
import { faHandHoldingSeedling as fasHandHoldingSeedling }   from '@fortawesome/pro-solid-svg-icons';
import { faHandHoldingSkull as fadHandHoldingSkull }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandHoldingSkull as falHandHoldingSkull }   from '@fortawesome/pro-light-svg-icons';
import { faHandHoldingSkull as farHandHoldingSkull }   from '@fortawesome/pro-regular-svg-icons';
import { faHandHoldingSkull as fasHandHoldingSkull }   from '@fortawesome/pro-solid-svg-icons';
import { faHandHoldingUsd as fadHandHoldingUsd }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandHoldingUsd as falHandHoldingUsd }   from '@fortawesome/pro-light-svg-icons';
import { faHandHoldingUsd as farHandHoldingUsd }   from '@fortawesome/pro-regular-svg-icons';
import { faHandHoldingUsd as fasHandHoldingUsd }   from '@fortawesome/pro-solid-svg-icons';
import { faHandHoldingWater as fadHandHoldingWater }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandHoldingWater as falHandHoldingWater }   from '@fortawesome/pro-light-svg-icons';
import { faHandHoldingWater as farHandHoldingWater }   from '@fortawesome/pro-regular-svg-icons';
import { faHandHoldingWater as fasHandHoldingWater }   from '@fortawesome/pro-solid-svg-icons';
import { faHandHorns as fadHandHorns }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandHorns as falHandHorns }   from '@fortawesome/pro-light-svg-icons';
import { faHandHorns as farHandHorns }   from '@fortawesome/pro-regular-svg-icons';
import { faHandHorns as fasHandHorns }   from '@fortawesome/pro-solid-svg-icons';
import { faHandLizard as fadHandLizard }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandLizard as falHandLizard }   from '@fortawesome/pro-light-svg-icons';
import { faHandLizard as farHandLizard }   from '@fortawesome/pro-regular-svg-icons';
import { faHandLizard as fasHandLizard }   from '@fortawesome/pro-solid-svg-icons';
import { faHandLove as fadHandLove }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandLove as falHandLove }   from '@fortawesome/pro-light-svg-icons';
import { faHandLove as farHandLove }   from '@fortawesome/pro-regular-svg-icons';
import { faHandLove as fasHandLove }   from '@fortawesome/pro-solid-svg-icons';
import { faHandMiddleFinger as fadHandMiddleFinger }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandMiddleFinger as falHandMiddleFinger }   from '@fortawesome/pro-light-svg-icons';
import { faHandMiddleFinger as farHandMiddleFinger }   from '@fortawesome/pro-regular-svg-icons';
import { faHandMiddleFinger as fasHandMiddleFinger }   from '@fortawesome/pro-solid-svg-icons';
import { faHandPaper as fadHandPaper }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandPaper as falHandPaper }   from '@fortawesome/pro-light-svg-icons';
import { faHandPaper as farHandPaper }   from '@fortawesome/pro-regular-svg-icons';
import { faHandPaper as fasHandPaper }   from '@fortawesome/pro-solid-svg-icons';
import { faHandPeace as fadHandPeace }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandPeace as falHandPeace }   from '@fortawesome/pro-light-svg-icons';
import { faHandPeace as farHandPeace }   from '@fortawesome/pro-regular-svg-icons';
import { faHandPeace as fasHandPeace }   from '@fortawesome/pro-solid-svg-icons';
import { faHandPointDown as fadHandPointDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandPointDown as falHandPointDown }   from '@fortawesome/pro-light-svg-icons';
import { faHandPointDown as farHandPointDown }   from '@fortawesome/pro-regular-svg-icons';
import { faHandPointDown as fasHandPointDown }   from '@fortawesome/pro-solid-svg-icons';
import { faHandPointer as fadHandPointer }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandPointer as falHandPointer }   from '@fortawesome/pro-light-svg-icons';
import { faHandPointer as farHandPointer }   from '@fortawesome/pro-regular-svg-icons';
import { faHandPointer as fasHandPointer }   from '@fortawesome/pro-solid-svg-icons';
import { faHandPointLeft as fadHandPointLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandPointLeft as falHandPointLeft }   from '@fortawesome/pro-light-svg-icons';
import { faHandPointLeft as farHandPointLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faHandPointLeft as fasHandPointLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faHandPointRibbon as fadHandPointRibbon }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandPointRibbon as falHandPointRibbon }   from '@fortawesome/pro-light-svg-icons';
import { faHandPointRibbon as farHandPointRibbon }   from '@fortawesome/pro-regular-svg-icons';
import { faHandPointRibbon as fasHandPointRibbon }   from '@fortawesome/pro-solid-svg-icons';
import { faHandPointRight as fadHandPointRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandPointRight as falHandPointRight }   from '@fortawesome/pro-light-svg-icons';
import { faHandPointRight as farHandPointRight }   from '@fortawesome/pro-regular-svg-icons';
import { faHandPointRight as fasHandPointRight }   from '@fortawesome/pro-solid-svg-icons';
import { faHandPointUp as fadHandPointUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandPointUp as falHandPointUp }   from '@fortawesome/pro-light-svg-icons';
import { faHandPointUp as farHandPointUp }   from '@fortawesome/pro-regular-svg-icons';
import { faHandPointUp as fasHandPointUp }   from '@fortawesome/pro-solid-svg-icons';
import { faHandReceiving as fadHandReceiving }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandReceiving as falHandReceiving }   from '@fortawesome/pro-light-svg-icons';
import { faHandReceiving as farHandReceiving }   from '@fortawesome/pro-regular-svg-icons';
import { faHandReceiving as fasHandReceiving }   from '@fortawesome/pro-solid-svg-icons';
import { faHandRock as fadHandRock }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandRock as falHandRock }   from '@fortawesome/pro-light-svg-icons';
import { faHandRock as farHandRock }   from '@fortawesome/pro-regular-svg-icons';
import { faHandRock as fasHandRock }   from '@fortawesome/pro-solid-svg-icons';
import { faHands as fadHands }   from '@fortawesome/pro-duotone-svg-icons';
import { faHands as falHands }   from '@fortawesome/pro-light-svg-icons';
import { faHands as farHands }   from '@fortawesome/pro-regular-svg-icons';
import { faHands as fasHands }   from '@fortawesome/pro-solid-svg-icons';
import { faHandsAmericanSignLanguageInterpreting as fadHandsAmericanSignLanguageInterpreting }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandsAmericanSignLanguageInterpreting as falHandsAmericanSignLanguageInterpreting }   from '@fortawesome/pro-light-svg-icons';
import { faHandsAmericanSignLanguageInterpreting as farHandsAmericanSignLanguageInterpreting }   from '@fortawesome/pro-regular-svg-icons';
import { faHandsAmericanSignLanguageInterpreting as fasHandsAmericanSignLanguageInterpreting }   from '@fortawesome/pro-solid-svg-icons';
import { faHandsAslInterpreting as fadHandsAslInterpreting }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandsAslInterpreting as falHandsAslInterpreting }   from '@fortawesome/pro-light-svg-icons';
import { faHandsAslInterpreting as farHandsAslInterpreting }   from '@fortawesome/pro-regular-svg-icons';
import { faHandsAslInterpreting as fasHandsAslInterpreting }   from '@fortawesome/pro-solid-svg-icons';
import { faHandsBound as fadHandsBound }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandsBound as falHandsBound }   from '@fortawesome/pro-light-svg-icons';
import { faHandsBound as farHandsBound }   from '@fortawesome/pro-regular-svg-icons';
import { faHandsBound as fasHandsBound }   from '@fortawesome/pro-solid-svg-icons';
import { faHandsBubbles as fadHandsBubbles }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandsBubbles as falHandsBubbles }   from '@fortawesome/pro-light-svg-icons';
import { faHandsBubbles as farHandsBubbles }   from '@fortawesome/pro-regular-svg-icons';
import { faHandsBubbles as fasHandsBubbles }   from '@fortawesome/pro-solid-svg-icons';
import { faHandScissors as fadHandScissors }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandScissors as falHandScissors }   from '@fortawesome/pro-light-svg-icons';
import { faHandScissors as farHandScissors }   from '@fortawesome/pro-regular-svg-icons';
import { faHandScissors as fasHandScissors }   from '@fortawesome/pro-solid-svg-icons';
import { faHandsClapping as fadHandsClapping }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandsClapping as falHandsClapping }   from '@fortawesome/pro-light-svg-icons';
import { faHandsClapping as farHandsClapping }   from '@fortawesome/pro-regular-svg-icons';
import { faHandsClapping as fasHandsClapping }   from '@fortawesome/pro-solid-svg-icons';
import { faHandshake as fadHandshake }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandshake as falHandshake }   from '@fortawesome/pro-light-svg-icons';
import { faHandshake as farHandshake }   from '@fortawesome/pro-regular-svg-icons';
import { faHandshake as fasHandshake }   from '@fortawesome/pro-solid-svg-icons';
import { faHandshakeAlt as fadHandshakeAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandshakeAlt as falHandshakeAlt }   from '@fortawesome/pro-light-svg-icons';
import { faHandshakeAlt as farHandshakeAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faHandshakeAlt as fasHandshakeAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faHandshakeAltSlash as fadHandshakeAltSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandshakeAltSlash as falHandshakeAltSlash }   from '@fortawesome/pro-light-svg-icons';
import { faHandshakeAltSlash as farHandshakeAltSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faHandshakeAltSlash as fasHandshakeAltSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faHandshakeAngle as fadHandshakeAngle }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandshakeAngle as falHandshakeAngle }   from '@fortawesome/pro-light-svg-icons';
import { faHandshakeAngle as farHandshakeAngle }   from '@fortawesome/pro-regular-svg-icons';
import { faHandshakeAngle as fasHandshakeAngle }   from '@fortawesome/pro-solid-svg-icons';
import { faHandshakeSimple as fadHandshakeSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandshakeSimple as falHandshakeSimple }   from '@fortawesome/pro-light-svg-icons';
import { faHandshakeSimple as farHandshakeSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faHandshakeSimple as fasHandshakeSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faHandshakeSimpleSlash as fadHandshakeSimpleSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandshakeSimpleSlash as falHandshakeSimpleSlash }   from '@fortawesome/pro-light-svg-icons';
import { faHandshakeSimpleSlash as farHandshakeSimpleSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faHandshakeSimpleSlash as fasHandshakeSimpleSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faHandshakeSlash as fadHandshakeSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandshakeSlash as falHandshakeSlash }   from '@fortawesome/pro-light-svg-icons';
import { faHandshakeSlash as farHandshakeSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faHandshakeSlash as fasHandshakeSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faHandsHeart as fadHandsHeart }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandsHeart as falHandsHeart }   from '@fortawesome/pro-light-svg-icons';
import { faHandsHeart as farHandsHeart }   from '@fortawesome/pro-regular-svg-icons';
import { faHandsHeart as fasHandsHeart }   from '@fortawesome/pro-solid-svg-icons';
import { faHandsHelping as fadHandsHelping }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandsHelping as falHandsHelping }   from '@fortawesome/pro-light-svg-icons';
import { faHandsHelping as farHandsHelping }   from '@fortawesome/pro-regular-svg-icons';
import { faHandsHelping as fasHandsHelping }   from '@fortawesome/pro-solid-svg-icons';
import { faHandsHolding as fadHandsHolding }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandsHolding as falHandsHolding }   from '@fortawesome/pro-light-svg-icons';
import { faHandsHolding as farHandsHolding }   from '@fortawesome/pro-regular-svg-icons';
import { faHandsHolding as fasHandsHolding }   from '@fortawesome/pro-solid-svg-icons';
import { faHandsHoldingChild as fadHandsHoldingChild }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandsHoldingChild as falHandsHoldingChild }   from '@fortawesome/pro-light-svg-icons';
import { faHandsHoldingChild as farHandsHoldingChild }   from '@fortawesome/pro-regular-svg-icons';
import { faHandsHoldingChild as fasHandsHoldingChild }   from '@fortawesome/pro-solid-svg-icons';
import { faHandsHoldingCircle as fadHandsHoldingCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandsHoldingCircle as falHandsHoldingCircle }   from '@fortawesome/pro-light-svg-icons';
import { faHandsHoldingCircle as farHandsHoldingCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faHandsHoldingCircle as fasHandsHoldingCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faHandsHoldingDiamond as fadHandsHoldingDiamond }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandsHoldingDiamond as falHandsHoldingDiamond }   from '@fortawesome/pro-light-svg-icons';
import { faHandsHoldingDiamond as farHandsHoldingDiamond }   from '@fortawesome/pro-regular-svg-icons';
import { faHandsHoldingDiamond as fasHandsHoldingDiamond }   from '@fortawesome/pro-solid-svg-icons';
import { faHandsHoldingDollar as fadHandsHoldingDollar }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandsHoldingDollar as falHandsHoldingDollar }   from '@fortawesome/pro-light-svg-icons';
import { faHandsHoldingDollar as farHandsHoldingDollar }   from '@fortawesome/pro-regular-svg-icons';
import { faHandsHoldingDollar as fasHandsHoldingDollar }   from '@fortawesome/pro-solid-svg-icons';
import { faHandsHoldingHeart as fadHandsHoldingHeart }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandsHoldingHeart as falHandsHoldingHeart }   from '@fortawesome/pro-light-svg-icons';
import { faHandsHoldingHeart as farHandsHoldingHeart }   from '@fortawesome/pro-regular-svg-icons';
import { faHandsHoldingHeart as fasHandsHoldingHeart }   from '@fortawesome/pro-solid-svg-icons';
import { faHandSparkles as fadHandSparkles }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandSparkles as falHandSparkles }   from '@fortawesome/pro-light-svg-icons';
import { faHandSparkles as farHandSparkles }   from '@fortawesome/pro-regular-svg-icons';
import { faHandSparkles as fasHandSparkles }   from '@fortawesome/pro-solid-svg-icons';
import { faHandSpock as fadHandSpock }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandSpock as falHandSpock }   from '@fortawesome/pro-light-svg-icons';
import { faHandSpock as farHandSpock }   from '@fortawesome/pro-regular-svg-icons';
import { faHandSpock as fasHandSpock }   from '@fortawesome/pro-solid-svg-icons';
import { faHandsPraying as fadHandsPraying }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandsPraying as falHandsPraying }   from '@fortawesome/pro-light-svg-icons';
import { faHandsPraying as farHandsPraying }   from '@fortawesome/pro-regular-svg-icons';
import { faHandsPraying as fasHandsPraying }   from '@fortawesome/pro-solid-svg-icons';
import { faHandsUsd as fadHandsUsd }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandsUsd as falHandsUsd }   from '@fortawesome/pro-light-svg-icons';
import { faHandsUsd as farHandsUsd }   from '@fortawesome/pro-regular-svg-icons';
import { faHandsUsd as fasHandsUsd }   from '@fortawesome/pro-solid-svg-icons';
import { faHandsWash as fadHandsWash }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandsWash as falHandsWash }   from '@fortawesome/pro-light-svg-icons';
import { faHandsWash as farHandsWash }   from '@fortawesome/pro-regular-svg-icons';
import { faHandsWash as fasHandsWash }   from '@fortawesome/pro-solid-svg-icons';
import { faHandWave as fadHandWave }   from '@fortawesome/pro-duotone-svg-icons';
import { faHandWave as falHandWave }   from '@fortawesome/pro-light-svg-icons';
import { faHandWave as farHandWave }   from '@fortawesome/pro-regular-svg-icons';
import { faHandWave as fasHandWave }   from '@fortawesome/pro-solid-svg-icons';
import { faHanukiah as fadHanukiah }   from '@fortawesome/pro-duotone-svg-icons';
import { faHanukiah as falHanukiah }   from '@fortawesome/pro-light-svg-icons';
import { faHanukiah as farHanukiah }   from '@fortawesome/pro-regular-svg-icons';
import { faHanukiah as fasHanukiah }   from '@fortawesome/pro-solid-svg-icons';
import { faHardDrive as fadHardDrive }   from '@fortawesome/pro-duotone-svg-icons';
import { faHardDrive as falHardDrive }   from '@fortawesome/pro-light-svg-icons';
import { faHardDrive as farHardDrive }   from '@fortawesome/pro-regular-svg-icons';
import { faHardDrive as fasHardDrive }   from '@fortawesome/pro-solid-svg-icons';
import { faHardHat as fadHardHat }   from '@fortawesome/pro-duotone-svg-icons';
import { faHardHat as falHardHat }   from '@fortawesome/pro-light-svg-icons';
import { faHardHat as farHardHat }   from '@fortawesome/pro-regular-svg-icons';
import { faHardHat as fasHardHat }   from '@fortawesome/pro-solid-svg-icons';
import { faHardOfHearing as fadHardOfHearing }   from '@fortawesome/pro-duotone-svg-icons';
import { faHardOfHearing as falHardOfHearing }   from '@fortawesome/pro-light-svg-icons';
import { faHardOfHearing as farHardOfHearing }   from '@fortawesome/pro-regular-svg-icons';
import { faHardOfHearing as fasHardOfHearing }   from '@fortawesome/pro-solid-svg-icons';
import { faHashtag as fadHashtag }   from '@fortawesome/pro-duotone-svg-icons';
import { faHashtag as falHashtag }   from '@fortawesome/pro-light-svg-icons';
import { faHashtag as farHashtag }   from '@fortawesome/pro-regular-svg-icons';
import { faHashtag as fasHashtag }   from '@fortawesome/pro-solid-svg-icons';
import { faHashtagLock as fadHashtagLock }   from '@fortawesome/pro-duotone-svg-icons';
import { faHashtagLock as falHashtagLock }   from '@fortawesome/pro-light-svg-icons';
import { faHashtagLock as farHashtagLock }   from '@fortawesome/pro-regular-svg-icons';
import { faHashtagLock as fasHashtagLock }   from '@fortawesome/pro-solid-svg-icons';
import { faHatChef as fadHatChef }   from '@fortawesome/pro-duotone-svg-icons';
import { faHatChef as falHatChef }   from '@fortawesome/pro-light-svg-icons';
import { faHatChef as farHatChef }   from '@fortawesome/pro-regular-svg-icons';
import { faHatChef as fasHatChef }   from '@fortawesome/pro-solid-svg-icons';
import { faHatCowboy as fadHatCowboy }   from '@fortawesome/pro-duotone-svg-icons';
import { faHatCowboy as falHatCowboy }   from '@fortawesome/pro-light-svg-icons';
import { faHatCowboy as farHatCowboy }   from '@fortawesome/pro-regular-svg-icons';
import { faHatCowboy as fasHatCowboy }   from '@fortawesome/pro-solid-svg-icons';
import { faHatCowboySide as fadHatCowboySide }   from '@fortawesome/pro-duotone-svg-icons';
import { faHatCowboySide as falHatCowboySide }   from '@fortawesome/pro-light-svg-icons';
import { faHatCowboySide as farHatCowboySide }   from '@fortawesome/pro-regular-svg-icons';
import { faHatCowboySide as fasHatCowboySide }   from '@fortawesome/pro-solid-svg-icons';
import { faHatHard as fadHatHard }   from '@fortawesome/pro-duotone-svg-icons';
import { faHatHard as falHatHard }   from '@fortawesome/pro-light-svg-icons';
import { faHatHard as farHatHard }   from '@fortawesome/pro-regular-svg-icons';
import { faHatHard as fasHatHard }   from '@fortawesome/pro-solid-svg-icons';
import { faHatSanta as fadHatSanta }   from '@fortawesome/pro-duotone-svg-icons';
import { faHatSanta as falHatSanta }   from '@fortawesome/pro-light-svg-icons';
import { faHatSanta as farHatSanta }   from '@fortawesome/pro-regular-svg-icons';
import { faHatSanta as fasHatSanta }   from '@fortawesome/pro-solid-svg-icons';
import { faHatWinter as fadHatWinter }   from '@fortawesome/pro-duotone-svg-icons';
import { faHatWinter as falHatWinter }   from '@fortawesome/pro-light-svg-icons';
import { faHatWinter as farHatWinter }   from '@fortawesome/pro-regular-svg-icons';
import { faHatWinter as fasHatWinter }   from '@fortawesome/pro-solid-svg-icons';
import { faHatWitch as fadHatWitch }   from '@fortawesome/pro-duotone-svg-icons';
import { faHatWitch as falHatWitch }   from '@fortawesome/pro-light-svg-icons';
import { faHatWitch as farHatWitch }   from '@fortawesome/pro-regular-svg-icons';
import { faHatWitch as fasHatWitch }   from '@fortawesome/pro-solid-svg-icons';
import { faHatWizard as fadHatWizard }   from '@fortawesome/pro-duotone-svg-icons';
import { faHatWizard as falHatWizard }   from '@fortawesome/pro-light-svg-icons';
import { faHatWizard as farHatWizard }   from '@fortawesome/pro-regular-svg-icons';
import { faHatWizard as fasHatWizard }   from '@fortawesome/pro-solid-svg-icons';
import { faHdd as fadHdd }   from '@fortawesome/pro-duotone-svg-icons';
import { faHdd as falHdd }   from '@fortawesome/pro-light-svg-icons';
import { faHdd as farHdd }   from '@fortawesome/pro-regular-svg-icons';
import { faHdd as fasHdd }   from '@fortawesome/pro-solid-svg-icons';
import { faHeader as fadHeader }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeader as falHeader }   from '@fortawesome/pro-light-svg-icons';
import { faHeader as farHeader }   from '@fortawesome/pro-regular-svg-icons';
import { faHeader as fasHeader }   from '@fortawesome/pro-solid-svg-icons';
import { faHeading as fadHeading }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeading as falHeading }   from '@fortawesome/pro-light-svg-icons';
import { faHeading as farHeading }   from '@fortawesome/pro-regular-svg-icons';
import { faHeading as fasHeading }   from '@fortawesome/pro-solid-svg-icons';
import { faHeadphones as fadHeadphones }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeadphones as falHeadphones }   from '@fortawesome/pro-light-svg-icons';
import { faHeadphones as farHeadphones }   from '@fortawesome/pro-regular-svg-icons';
import { faHeadphones as fasHeadphones }   from '@fortawesome/pro-solid-svg-icons';
import { faHeadphonesAlt as fadHeadphonesAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeadphonesAlt as falHeadphonesAlt }   from '@fortawesome/pro-light-svg-icons';
import { faHeadphonesAlt as farHeadphonesAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faHeadphonesAlt as fasHeadphonesAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faHeadphonesSimple as fadHeadphonesSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeadphonesSimple as falHeadphonesSimple }   from '@fortawesome/pro-light-svg-icons';
import { faHeadphonesSimple as farHeadphonesSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faHeadphonesSimple as fasHeadphonesSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faHeadset as fadHeadset }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeadset as falHeadset }   from '@fortawesome/pro-light-svg-icons';
import { faHeadset as farHeadset }   from '@fortawesome/pro-regular-svg-icons';
import { faHeadset as fasHeadset }   from '@fortawesome/pro-solid-svg-icons';
import { faHeadSide as fadHeadSide }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeadSide as falHeadSide }   from '@fortawesome/pro-light-svg-icons';
import { faHeadSide as farHeadSide }   from '@fortawesome/pro-regular-svg-icons';
import { faHeadSide as fasHeadSide }   from '@fortawesome/pro-solid-svg-icons';
import { faHeadSideBrain as fadHeadSideBrain }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeadSideBrain as falHeadSideBrain }   from '@fortawesome/pro-light-svg-icons';
import { faHeadSideBrain as farHeadSideBrain }   from '@fortawesome/pro-regular-svg-icons';
import { faHeadSideBrain as fasHeadSideBrain }   from '@fortawesome/pro-solid-svg-icons';
import { faHeadSideCough as fadHeadSideCough }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeadSideCough as falHeadSideCough }   from '@fortawesome/pro-light-svg-icons';
import { faHeadSideCough as farHeadSideCough }   from '@fortawesome/pro-regular-svg-icons';
import { faHeadSideCough as fasHeadSideCough }   from '@fortawesome/pro-solid-svg-icons';
import { faHeadSideCoughSlash as fadHeadSideCoughSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeadSideCoughSlash as falHeadSideCoughSlash }   from '@fortawesome/pro-light-svg-icons';
import { faHeadSideCoughSlash as farHeadSideCoughSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faHeadSideCoughSlash as fasHeadSideCoughSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faHeadSideGoggles as fadHeadSideGoggles }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeadSideGoggles as falHeadSideGoggles }   from '@fortawesome/pro-light-svg-icons';
import { faHeadSideGoggles as farHeadSideGoggles }   from '@fortawesome/pro-regular-svg-icons';
import { faHeadSideGoggles as fasHeadSideGoggles }   from '@fortawesome/pro-solid-svg-icons';
import { faHeadSideHeadphones as fadHeadSideHeadphones }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeadSideHeadphones as falHeadSideHeadphones }   from '@fortawesome/pro-light-svg-icons';
import { faHeadSideHeadphones as farHeadSideHeadphones }   from '@fortawesome/pro-regular-svg-icons';
import { faHeadSideHeadphones as fasHeadSideHeadphones }   from '@fortawesome/pro-solid-svg-icons';
import { faHeadSideHeart as fadHeadSideHeart }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeadSideHeart as falHeadSideHeart }   from '@fortawesome/pro-light-svg-icons';
import { faHeadSideHeart as farHeadSideHeart }   from '@fortawesome/pro-regular-svg-icons';
import { faHeadSideHeart as fasHeadSideHeart }   from '@fortawesome/pro-solid-svg-icons';
import { faHeadSideMask as fadHeadSideMask }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeadSideMask as falHeadSideMask }   from '@fortawesome/pro-light-svg-icons';
import { faHeadSideMask as farHeadSideMask }   from '@fortawesome/pro-regular-svg-icons';
import { faHeadSideMask as fasHeadSideMask }   from '@fortawesome/pro-solid-svg-icons';
import { faHeadSideMedical as fadHeadSideMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeadSideMedical as falHeadSideMedical }   from '@fortawesome/pro-light-svg-icons';
import { faHeadSideMedical as farHeadSideMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faHeadSideMedical as fasHeadSideMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faHeadSideVirus as fadHeadSideVirus }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeadSideVirus as falHeadSideVirus }   from '@fortawesome/pro-light-svg-icons';
import { faHeadSideVirus as farHeadSideVirus }   from '@fortawesome/pro-regular-svg-icons';
import { faHeadSideVirus as fasHeadSideVirus }   from '@fortawesome/pro-solid-svg-icons';
import { faHeadVr as fadHeadVr }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeadVr as falHeadVr }   from '@fortawesome/pro-light-svg-icons';
import { faHeadVr as farHeadVr }   from '@fortawesome/pro-regular-svg-icons';
import { faHeadVr as fasHeadVr }   from '@fortawesome/pro-solid-svg-icons';
import { faHeart as fadHeart }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeart as falHeart }   from '@fortawesome/pro-light-svg-icons';
import { faHeart as farHeart }   from '@fortawesome/pro-regular-svg-icons';
import { faHeart as fasHeart }   from '@fortawesome/pro-solid-svg-icons';
import { faHeartbeat as fadHeartbeat }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeartbeat as falHeartbeat }   from '@fortawesome/pro-light-svg-icons';
import { faHeartbeat as farHeartbeat }   from '@fortawesome/pro-regular-svg-icons';
import { faHeartbeat as fasHeartbeat }   from '@fortawesome/pro-solid-svg-icons';
import { faHeartBroken as fadHeartBroken }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeartBroken as falHeartBroken }   from '@fortawesome/pro-light-svg-icons';
import { faHeartBroken as farHeartBroken }   from '@fortawesome/pro-regular-svg-icons';
import { faHeartBroken as fasHeartBroken }   from '@fortawesome/pro-solid-svg-icons';
import { faHeartCircle as fadHeartCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeartCircle as falHeartCircle }   from '@fortawesome/pro-light-svg-icons';
import { faHeartCircle as farHeartCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faHeartCircle as fasHeartCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faHeartCircleBolt as fadHeartCircleBolt }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeartCircleBolt as falHeartCircleBolt }   from '@fortawesome/pro-light-svg-icons';
import { faHeartCircleBolt as farHeartCircleBolt }   from '@fortawesome/pro-regular-svg-icons';
import { faHeartCircleBolt as fasHeartCircleBolt }   from '@fortawesome/pro-solid-svg-icons';
import { faHeartCircleCheck as fadHeartCircleCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeartCircleCheck as falHeartCircleCheck }   from '@fortawesome/pro-light-svg-icons';
import { faHeartCircleCheck as farHeartCircleCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faHeartCircleCheck as fasHeartCircleCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faHeartCircleExclamation as fadHeartCircleExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeartCircleExclamation as falHeartCircleExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faHeartCircleExclamation as farHeartCircleExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faHeartCircleExclamation as fasHeartCircleExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faHeartCircleMinus as fadHeartCircleMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeartCircleMinus as falHeartCircleMinus }   from '@fortawesome/pro-light-svg-icons';
import { faHeartCircleMinus as farHeartCircleMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faHeartCircleMinus as fasHeartCircleMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faHeartCirclePlus as fadHeartCirclePlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeartCirclePlus as falHeartCirclePlus }   from '@fortawesome/pro-light-svg-icons';
import { faHeartCirclePlus as farHeartCirclePlus }   from '@fortawesome/pro-regular-svg-icons';
import { faHeartCirclePlus as fasHeartCirclePlus }   from '@fortawesome/pro-solid-svg-icons';
import { faHeartCircleXmark as fadHeartCircleXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeartCircleXmark as falHeartCircleXmark }   from '@fortawesome/pro-light-svg-icons';
import { faHeartCircleXmark as farHeartCircleXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faHeartCircleXmark as fasHeartCircleXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faHeartCrack as fadHeartCrack }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeartCrack as falHeartCrack }   from '@fortawesome/pro-light-svg-icons';
import { faHeartCrack as farHeartCrack }   from '@fortawesome/pro-regular-svg-icons';
import { faHeartCrack as fasHeartCrack }   from '@fortawesome/pro-solid-svg-icons';
import { faHeartHalf as fadHeartHalf }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeartHalf as falHeartHalf }   from '@fortawesome/pro-light-svg-icons';
import { faHeartHalf as farHeartHalf }   from '@fortawesome/pro-regular-svg-icons';
import { faHeartHalf as fasHeartHalf }   from '@fortawesome/pro-solid-svg-icons';
import { faHeartHalfAlt as fadHeartHalfAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeartHalfAlt as falHeartHalfAlt }   from '@fortawesome/pro-light-svg-icons';
import { faHeartHalfAlt as farHeartHalfAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faHeartHalfAlt as fasHeartHalfAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faHeartHalfStroke as fadHeartHalfStroke }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeartHalfStroke as falHeartHalfStroke }   from '@fortawesome/pro-light-svg-icons';
import { faHeartHalfStroke as farHeartHalfStroke }   from '@fortawesome/pro-regular-svg-icons';
import { faHeartHalfStroke as fasHeartHalfStroke }   from '@fortawesome/pro-solid-svg-icons';
import { faHeartMusicCameraBolt as fadHeartMusicCameraBolt }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeartMusicCameraBolt as falHeartMusicCameraBolt }   from '@fortawesome/pro-light-svg-icons';
import { faHeartMusicCameraBolt as farHeartMusicCameraBolt }   from '@fortawesome/pro-regular-svg-icons';
import { faHeartMusicCameraBolt as fasHeartMusicCameraBolt }   from '@fortawesome/pro-solid-svg-icons';
import { faHeartPulse as fadHeartPulse }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeartPulse as falHeartPulse }   from '@fortawesome/pro-light-svg-icons';
import { faHeartPulse as farHeartPulse }   from '@fortawesome/pro-regular-svg-icons';
import { faHeartPulse as fasHeartPulse }   from '@fortawesome/pro-solid-svg-icons';
import { faHeartRate as fadHeartRate }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeartRate as falHeartRate }   from '@fortawesome/pro-light-svg-icons';
import { faHeartRate as farHeartRate }   from '@fortawesome/pro-regular-svg-icons';
import { faHeartRate as fasHeartRate }   from '@fortawesome/pro-solid-svg-icons';
import { faHeartSquare as fadHeartSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeartSquare as falHeartSquare }   from '@fortawesome/pro-light-svg-icons';
import { faHeartSquare as farHeartSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faHeartSquare as fasHeartSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faHeat as fadHeat }   from '@fortawesome/pro-duotone-svg-icons';
import { faHeat as falHeat }   from '@fortawesome/pro-light-svg-icons';
import { faHeat as farHeat }   from '@fortawesome/pro-regular-svg-icons';
import { faHeat as fasHeat }   from '@fortawesome/pro-solid-svg-icons';
import { faHelicopter as fadHelicopter }   from '@fortawesome/pro-duotone-svg-icons';
import { faHelicopter as falHelicopter }   from '@fortawesome/pro-light-svg-icons';
import { faHelicopter as farHelicopter }   from '@fortawesome/pro-regular-svg-icons';
import { faHelicopter as fasHelicopter }   from '@fortawesome/pro-solid-svg-icons';
import { faHelicopterSymbol as fadHelicopterSymbol }   from '@fortawesome/pro-duotone-svg-icons';
import { faHelicopterSymbol as falHelicopterSymbol }   from '@fortawesome/pro-light-svg-icons';
import { faHelicopterSymbol as farHelicopterSymbol }   from '@fortawesome/pro-regular-svg-icons';
import { faHelicopterSymbol as fasHelicopterSymbol }   from '@fortawesome/pro-solid-svg-icons';
import { faHelmetBattle as fadHelmetBattle }   from '@fortawesome/pro-duotone-svg-icons';
import { faHelmetBattle as falHelmetBattle }   from '@fortawesome/pro-light-svg-icons';
import { faHelmetBattle as farHelmetBattle }   from '@fortawesome/pro-regular-svg-icons';
import { faHelmetBattle as fasHelmetBattle }   from '@fortawesome/pro-solid-svg-icons';
import { faHelmetSafety as fadHelmetSafety }   from '@fortawesome/pro-duotone-svg-icons';
import { faHelmetSafety as falHelmetSafety }   from '@fortawesome/pro-light-svg-icons';
import { faHelmetSafety as farHelmetSafety }   from '@fortawesome/pro-regular-svg-icons';
import { faHelmetSafety as fasHelmetSafety }   from '@fortawesome/pro-solid-svg-icons';
import { faHelmetUn as fadHelmetUn }   from '@fortawesome/pro-duotone-svg-icons';
import { faHelmetUn as falHelmetUn }   from '@fortawesome/pro-light-svg-icons';
import { faHelmetUn as farHelmetUn }   from '@fortawesome/pro-regular-svg-icons';
import { faHelmetUn as fasHelmetUn }   from '@fortawesome/pro-solid-svg-icons';
import { faHexagon as fadHexagon }   from '@fortawesome/pro-duotone-svg-icons';
import { faHexagon as falHexagon }   from '@fortawesome/pro-light-svg-icons';
import { faHexagon as farHexagon }   from '@fortawesome/pro-regular-svg-icons';
import { faHexagon as fasHexagon }   from '@fortawesome/pro-solid-svg-icons';
import { faHexagonCheck as fadHexagonCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faHexagonCheck as falHexagonCheck }   from '@fortawesome/pro-light-svg-icons';
import { faHexagonCheck as farHexagonCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faHexagonCheck as fasHexagonCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faHexagonDivide as fadHexagonDivide }   from '@fortawesome/pro-duotone-svg-icons';
import { faHexagonDivide as falHexagonDivide }   from '@fortawesome/pro-light-svg-icons';
import { faHexagonDivide as farHexagonDivide }   from '@fortawesome/pro-regular-svg-icons';
import { faHexagonDivide as fasHexagonDivide }   from '@fortawesome/pro-solid-svg-icons';
import { faHexagonExclamation as fadHexagonExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faHexagonExclamation as falHexagonExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faHexagonExclamation as farHexagonExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faHexagonExclamation as fasHexagonExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faHexagonImage as fadHexagonImage }   from '@fortawesome/pro-duotone-svg-icons';
import { faHexagonImage as falHexagonImage }   from '@fortawesome/pro-light-svg-icons';
import { faHexagonImage as farHexagonImage }   from '@fortawesome/pro-regular-svg-icons';
import { faHexagonImage as fasHexagonImage }   from '@fortawesome/pro-solid-svg-icons';
import { faHexagonMinus as fadHexagonMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faHexagonMinus as falHexagonMinus }   from '@fortawesome/pro-light-svg-icons';
import { faHexagonMinus as farHexagonMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faHexagonMinus as fasHexagonMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faHexagonPlus as fadHexagonPlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faHexagonPlus as falHexagonPlus }   from '@fortawesome/pro-light-svg-icons';
import { faHexagonPlus as farHexagonPlus }   from '@fortawesome/pro-regular-svg-icons';
import { faHexagonPlus as fasHexagonPlus }   from '@fortawesome/pro-solid-svg-icons';
import { faHexagonVerticalNft as fadHexagonVerticalNft }   from '@fortawesome/pro-duotone-svg-icons';
import { faHexagonVerticalNft as falHexagonVerticalNft }   from '@fortawesome/pro-light-svg-icons';
import { faHexagonVerticalNft as farHexagonVerticalNft }   from '@fortawesome/pro-regular-svg-icons';
import { faHexagonVerticalNft as fasHexagonVerticalNft }   from '@fortawesome/pro-solid-svg-icons';
import { faHexagonVerticalNftSlanted as fadHexagonVerticalNftSlanted }   from '@fortawesome/pro-duotone-svg-icons';
import { faHexagonVerticalNftSlanted as falHexagonVerticalNftSlanted }   from '@fortawesome/pro-light-svg-icons';
import { faHexagonVerticalNftSlanted as farHexagonVerticalNftSlanted }   from '@fortawesome/pro-regular-svg-icons';
import { faHexagonVerticalNftSlanted as fasHexagonVerticalNftSlanted }   from '@fortawesome/pro-solid-svg-icons';
import { faHexagonXmark as fadHexagonXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faHexagonXmark as falHexagonXmark }   from '@fortawesome/pro-light-svg-icons';
import { faHexagonXmark as farHexagonXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faHexagonXmark as fasHexagonXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faHighDefinition as fadHighDefinition }   from '@fortawesome/pro-duotone-svg-icons';
import { faHighDefinition as falHighDefinition }   from '@fortawesome/pro-light-svg-icons';
import { faHighDefinition as farHighDefinition }   from '@fortawesome/pro-regular-svg-icons';
import { faHighDefinition as fasHighDefinition }   from '@fortawesome/pro-solid-svg-icons';
import { faHighlighter as fadHighlighter }   from '@fortawesome/pro-duotone-svg-icons';
import { faHighlighter as falHighlighter }   from '@fortawesome/pro-light-svg-icons';
import { faHighlighter as farHighlighter }   from '@fortawesome/pro-regular-svg-icons';
import { faHighlighter as fasHighlighter }   from '@fortawesome/pro-solid-svg-icons';
import { faHighlighterLine as fadHighlighterLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faHighlighterLine as falHighlighterLine }   from '@fortawesome/pro-light-svg-icons';
import { faHighlighterLine as farHighlighterLine }   from '@fortawesome/pro-regular-svg-icons';
import { faHighlighterLine as fasHighlighterLine }   from '@fortawesome/pro-solid-svg-icons';
import { faHiking as fadHiking }   from '@fortawesome/pro-duotone-svg-icons';
import { faHiking as falHiking }   from '@fortawesome/pro-light-svg-icons';
import { faHiking as farHiking }   from '@fortawesome/pro-regular-svg-icons';
import { faHiking as fasHiking }   from '@fortawesome/pro-solid-svg-icons';
import { faHillAvalanche as fadHillAvalanche }   from '@fortawesome/pro-duotone-svg-icons';
import { faHillAvalanche as falHillAvalanche }   from '@fortawesome/pro-light-svg-icons';
import { faHillAvalanche as farHillAvalanche }   from '@fortawesome/pro-regular-svg-icons';
import { faHillAvalanche as fasHillAvalanche }   from '@fortawesome/pro-solid-svg-icons';
import { faHillRockslide as fadHillRockslide }   from '@fortawesome/pro-duotone-svg-icons';
import { faHillRockslide as falHillRockslide }   from '@fortawesome/pro-light-svg-icons';
import { faHillRockslide as farHillRockslide }   from '@fortawesome/pro-regular-svg-icons';
import { faHillRockslide as fasHillRockslide }   from '@fortawesome/pro-solid-svg-icons';
import { faHippo as fadHippo }   from '@fortawesome/pro-duotone-svg-icons';
import { faHippo as falHippo }   from '@fortawesome/pro-light-svg-icons';
import { faHippo as farHippo }   from '@fortawesome/pro-regular-svg-icons';
import { faHippo as fasHippo }   from '@fortawesome/pro-solid-svg-icons';
import { faHistory as fadHistory }   from '@fortawesome/pro-duotone-svg-icons';
import { faHistory as falHistory }   from '@fortawesome/pro-light-svg-icons';
import { faHistory as farHistory }   from '@fortawesome/pro-regular-svg-icons';
import { faHistory as fasHistory }   from '@fortawesome/pro-solid-svg-icons';
import { faHockeyMask as fadHockeyMask }   from '@fortawesome/pro-duotone-svg-icons';
import { faHockeyMask as falHockeyMask }   from '@fortawesome/pro-light-svg-icons';
import { faHockeyMask as farHockeyMask }   from '@fortawesome/pro-regular-svg-icons';
import { faHockeyMask as fasHockeyMask }   from '@fortawesome/pro-solid-svg-icons';
import { faHockeyPuck as fadHockeyPuck }   from '@fortawesome/pro-duotone-svg-icons';
import { faHockeyPuck as falHockeyPuck }   from '@fortawesome/pro-light-svg-icons';
import { faHockeyPuck as farHockeyPuck }   from '@fortawesome/pro-regular-svg-icons';
import { faHockeyPuck as fasHockeyPuck }   from '@fortawesome/pro-solid-svg-icons';
import { faHockeyStickPuck as fadHockeyStickPuck }   from '@fortawesome/pro-duotone-svg-icons';
import { faHockeyStickPuck as falHockeyStickPuck }   from '@fortawesome/pro-light-svg-icons';
import { faHockeyStickPuck as farHockeyStickPuck }   from '@fortawesome/pro-regular-svg-icons';
import { faHockeyStickPuck as fasHockeyStickPuck }   from '@fortawesome/pro-solid-svg-icons';
import { faHockeySticks as fadHockeySticks }   from '@fortawesome/pro-duotone-svg-icons';
import { faHockeySticks as falHockeySticks }   from '@fortawesome/pro-light-svg-icons';
import { faHockeySticks as farHockeySticks }   from '@fortawesome/pro-regular-svg-icons';
import { faHockeySticks as fasHockeySticks }   from '@fortawesome/pro-solid-svg-icons';
import { faHollyBerry as fadHollyBerry }   from '@fortawesome/pro-duotone-svg-icons';
import { faHollyBerry as falHollyBerry }   from '@fortawesome/pro-light-svg-icons';
import { faHollyBerry as farHollyBerry }   from '@fortawesome/pro-regular-svg-icons';
import { faHollyBerry as fasHollyBerry }   from '@fortawesome/pro-solid-svg-icons';
import { faHome as fadHome }   from '@fortawesome/pro-duotone-svg-icons';
import { faHome as falHome }   from '@fortawesome/pro-light-svg-icons';
import { faHome as farHome }   from '@fortawesome/pro-regular-svg-icons';
import { faHome as fasHome }   from '@fortawesome/pro-solid-svg-icons';
import { faHomeAlt as fadHomeAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faHomeAlt as falHomeAlt }   from '@fortawesome/pro-light-svg-icons';
import { faHomeAlt as farHomeAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faHomeAlt as fasHomeAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faHomeBlank as fadHomeBlank }   from '@fortawesome/pro-duotone-svg-icons';
import { faHomeBlank as falHomeBlank }   from '@fortawesome/pro-light-svg-icons';
import { faHomeBlank as farHomeBlank }   from '@fortawesome/pro-regular-svg-icons';
import { faHomeBlank as fasHomeBlank }   from '@fortawesome/pro-solid-svg-icons';
import { faHomeHeart as fadHomeHeart }   from '@fortawesome/pro-duotone-svg-icons';
import { faHomeHeart as falHomeHeart }   from '@fortawesome/pro-light-svg-icons';
import { faHomeHeart as farHomeHeart }   from '@fortawesome/pro-regular-svg-icons';
import { faHomeHeart as fasHomeHeart }   from '@fortawesome/pro-solid-svg-icons';
import { faHomeLg as fadHomeLg }   from '@fortawesome/pro-duotone-svg-icons';
import { faHomeLg as falHomeLg }   from '@fortawesome/pro-light-svg-icons';
import { faHomeLg as farHomeLg }   from '@fortawesome/pro-regular-svg-icons';
import { faHomeLg as fasHomeLg }   from '@fortawesome/pro-solid-svg-icons';
import { faHomeLgAlt as fadHomeLgAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faHomeLgAlt as falHomeLgAlt }   from '@fortawesome/pro-light-svg-icons';
import { faHomeLgAlt as farHomeLgAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faHomeLgAlt as fasHomeLgAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faHomeUser as fadHomeUser }   from '@fortawesome/pro-duotone-svg-icons';
import { faHomeUser as falHomeUser }   from '@fortawesome/pro-light-svg-icons';
import { faHomeUser as farHomeUser }   from '@fortawesome/pro-regular-svg-icons';
import { faHomeUser as fasHomeUser }   from '@fortawesome/pro-solid-svg-icons';
import { faHoneyPot as fadHoneyPot }   from '@fortawesome/pro-duotone-svg-icons';
import { faHoneyPot as falHoneyPot }   from '@fortawesome/pro-light-svg-icons';
import { faHoneyPot as farHoneyPot }   from '@fortawesome/pro-regular-svg-icons';
import { faHoneyPot as fasHoneyPot }   from '@fortawesome/pro-solid-svg-icons';
import { faHoodCloak as fadHoodCloak }   from '@fortawesome/pro-duotone-svg-icons';
import { faHoodCloak as falHoodCloak }   from '@fortawesome/pro-light-svg-icons';
import { faHoodCloak as farHoodCloak }   from '@fortawesome/pro-regular-svg-icons';
import { faHoodCloak as fasHoodCloak }   from '@fortawesome/pro-solid-svg-icons';
import { faHorizontalRule as fadHorizontalRule }   from '@fortawesome/pro-duotone-svg-icons';
import { faHorizontalRule as falHorizontalRule }   from '@fortawesome/pro-light-svg-icons';
import { faHorizontalRule as farHorizontalRule }   from '@fortawesome/pro-regular-svg-icons';
import { faHorizontalRule as fasHorizontalRule }   from '@fortawesome/pro-solid-svg-icons';
import { faHorse as fadHorse }   from '@fortawesome/pro-duotone-svg-icons';
import { faHorse as falHorse }   from '@fortawesome/pro-light-svg-icons';
import { faHorse as farHorse }   from '@fortawesome/pro-regular-svg-icons';
import { faHorse as fasHorse }   from '@fortawesome/pro-solid-svg-icons';
import { faHorseHead as fadHorseHead }   from '@fortawesome/pro-duotone-svg-icons';
import { faHorseHead as falHorseHead }   from '@fortawesome/pro-light-svg-icons';
import { faHorseHead as farHorseHead }   from '@fortawesome/pro-regular-svg-icons';
import { faHorseHead as fasHorseHead }   from '@fortawesome/pro-solid-svg-icons';
import { faHorseSaddle as fadHorseSaddle }   from '@fortawesome/pro-duotone-svg-icons';
import { faHorseSaddle as falHorseSaddle }   from '@fortawesome/pro-light-svg-icons';
import { faHorseSaddle as farHorseSaddle }   from '@fortawesome/pro-regular-svg-icons';
import { faHorseSaddle as fasHorseSaddle }   from '@fortawesome/pro-solid-svg-icons';
import { faHose as fadHose }   from '@fortawesome/pro-duotone-svg-icons';
import { faHose as falHose }   from '@fortawesome/pro-light-svg-icons';
import { faHose as farHose }   from '@fortawesome/pro-regular-svg-icons';
import { faHose as fasHose }   from '@fortawesome/pro-solid-svg-icons';
import { faHoseReel as fadHoseReel }   from '@fortawesome/pro-duotone-svg-icons';
import { faHoseReel as falHoseReel }   from '@fortawesome/pro-light-svg-icons';
import { faHoseReel as farHoseReel }   from '@fortawesome/pro-regular-svg-icons';
import { faHoseReel as fasHoseReel }   from '@fortawesome/pro-solid-svg-icons';
import { faHospital as fadHospital }   from '@fortawesome/pro-duotone-svg-icons';
import { faHospital as falHospital }   from '@fortawesome/pro-light-svg-icons';
import { faHospital as farHospital }   from '@fortawesome/pro-regular-svg-icons';
import { faHospital as fasHospital }   from '@fortawesome/pro-solid-svg-icons';
import { faHospitalAlt as fadHospitalAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faHospitalAlt as falHospitalAlt }   from '@fortawesome/pro-light-svg-icons';
import { faHospitalAlt as farHospitalAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faHospitalAlt as fasHospitalAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faHospitals as fadHospitals }   from '@fortawesome/pro-duotone-svg-icons';
import { faHospitals as falHospitals }   from '@fortawesome/pro-light-svg-icons';
import { faHospitals as farHospitals }   from '@fortawesome/pro-regular-svg-icons';
import { faHospitals as fasHospitals }   from '@fortawesome/pro-solid-svg-icons';
import { faHospitalSymbol as fadHospitalSymbol }   from '@fortawesome/pro-duotone-svg-icons';
import { faHospitalSymbol as falHospitalSymbol }   from '@fortawesome/pro-light-svg-icons';
import { faHospitalSymbol as farHospitalSymbol }   from '@fortawesome/pro-regular-svg-icons';
import { faHospitalSymbol as fasHospitalSymbol }   from '@fortawesome/pro-solid-svg-icons';
import { faHospitalUser as fadHospitalUser }   from '@fortawesome/pro-duotone-svg-icons';
import { faHospitalUser as falHospitalUser }   from '@fortawesome/pro-light-svg-icons';
import { faHospitalUser as farHospitalUser }   from '@fortawesome/pro-regular-svg-icons';
import { faHospitalUser as fasHospitalUser }   from '@fortawesome/pro-solid-svg-icons';
import { faHospitalWide as fadHospitalWide }   from '@fortawesome/pro-duotone-svg-icons';
import { faHospitalWide as falHospitalWide }   from '@fortawesome/pro-light-svg-icons';
import { faHospitalWide as farHospitalWide }   from '@fortawesome/pro-regular-svg-icons';
import { faHospitalWide as fasHospitalWide }   from '@fortawesome/pro-solid-svg-icons';
import { faHotdog as fadHotdog }   from '@fortawesome/pro-duotone-svg-icons';
import { faHotdog as falHotdog }   from '@fortawesome/pro-light-svg-icons';
import { faHotdog as farHotdog }   from '@fortawesome/pro-regular-svg-icons';
import { faHotdog as fasHotdog }   from '@fortawesome/pro-solid-svg-icons';
import { faHotel as fadHotel }   from '@fortawesome/pro-duotone-svg-icons';
import { faHotel as falHotel }   from '@fortawesome/pro-light-svg-icons';
import { faHotel as farHotel }   from '@fortawesome/pro-regular-svg-icons';
import { faHotel as fasHotel }   from '@fortawesome/pro-solid-svg-icons';
import { faHotTub as fadHotTub }   from '@fortawesome/pro-duotone-svg-icons';
import { faHotTub as falHotTub }   from '@fortawesome/pro-light-svg-icons';
import { faHotTub as farHotTub }   from '@fortawesome/pro-regular-svg-icons';
import { faHotTub as fasHotTub }   from '@fortawesome/pro-solid-svg-icons';
import { faHotTubPerson as fadHotTubPerson }   from '@fortawesome/pro-duotone-svg-icons';
import { faHotTubPerson as falHotTubPerson }   from '@fortawesome/pro-light-svg-icons';
import { faHotTubPerson as farHotTubPerson }   from '@fortawesome/pro-regular-svg-icons';
import { faHotTubPerson as fasHotTubPerson }   from '@fortawesome/pro-solid-svg-icons';
import { faHourglass as fadHourglass }   from '@fortawesome/pro-duotone-svg-icons';
import { faHourglass as falHourglass }   from '@fortawesome/pro-light-svg-icons';
import { faHourglass as farHourglass }   from '@fortawesome/pro-regular-svg-icons';
import { faHourglass as fasHourglass }   from '@fortawesome/pro-solid-svg-icons';
import { faHourglass1 as fadHourglass1 }   from '@fortawesome/pro-duotone-svg-icons';
import { faHourglass1 as falHourglass1 }   from '@fortawesome/pro-light-svg-icons';
import { faHourglass1 as farHourglass1 }   from '@fortawesome/pro-regular-svg-icons';
import { faHourglass1 as fasHourglass1 }   from '@fortawesome/pro-solid-svg-icons';
import { faHourglass2 as fadHourglass2 }   from '@fortawesome/pro-duotone-svg-icons';
import { faHourglass2 as falHourglass2 }   from '@fortawesome/pro-light-svg-icons';
import { faHourglass2 as farHourglass2 }   from '@fortawesome/pro-regular-svg-icons';
import { faHourglass2 as fasHourglass2 }   from '@fortawesome/pro-solid-svg-icons';
import { faHourglass3 as fadHourglass3 }   from '@fortawesome/pro-duotone-svg-icons';
import { faHourglass3 as falHourglass3 }   from '@fortawesome/pro-light-svg-icons';
import { faHourglass3 as farHourglass3 }   from '@fortawesome/pro-regular-svg-icons';
import { faHourglass3 as fasHourglass3 }   from '@fortawesome/pro-solid-svg-icons';
import { faHourglassClock as fadHourglassClock }   from '@fortawesome/pro-duotone-svg-icons';
import { faHourglassClock as falHourglassClock }   from '@fortawesome/pro-light-svg-icons';
import { faHourglassClock as farHourglassClock }   from '@fortawesome/pro-regular-svg-icons';
import { faHourglassClock as fasHourglassClock }   from '@fortawesome/pro-solid-svg-icons';
import { faHourglassEmpty as fadHourglassEmpty }   from '@fortawesome/pro-duotone-svg-icons';
import { faHourglassEmpty as falHourglassEmpty }   from '@fortawesome/pro-light-svg-icons';
import { faHourglassEmpty as farHourglassEmpty }   from '@fortawesome/pro-regular-svg-icons';
import { faHourglassEmpty as fasHourglassEmpty }   from '@fortawesome/pro-solid-svg-icons';
import { faHourglassEnd as fadHourglassEnd }   from '@fortawesome/pro-duotone-svg-icons';
import { faHourglassEnd as falHourglassEnd }   from '@fortawesome/pro-light-svg-icons';
import { faHourglassEnd as farHourglassEnd }   from '@fortawesome/pro-regular-svg-icons';
import { faHourglassEnd as fasHourglassEnd }   from '@fortawesome/pro-solid-svg-icons';
import { faHourglassHalf as fadHourglassHalf }   from '@fortawesome/pro-duotone-svg-icons';
import { faHourglassHalf as falHourglassHalf }   from '@fortawesome/pro-light-svg-icons';
import { faHourglassHalf as farHourglassHalf }   from '@fortawesome/pro-regular-svg-icons';
import { faHourglassHalf as fasHourglassHalf }   from '@fortawesome/pro-solid-svg-icons';
import { faHourglassStart as fadHourglassStart }   from '@fortawesome/pro-duotone-svg-icons';
import { faHourglassStart as falHourglassStart }   from '@fortawesome/pro-light-svg-icons';
import { faHourglassStart as farHourglassStart }   from '@fortawesome/pro-regular-svg-icons';
import { faHourglassStart as fasHourglassStart }   from '@fortawesome/pro-solid-svg-icons';
import { faHouse as fadHouse }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouse as falHouse }   from '@fortawesome/pro-light-svg-icons';
import { faHouse as farHouse }   from '@fortawesome/pro-regular-svg-icons';
import { faHouse as fasHouse }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseBlank as fadHouseBlank }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseBlank as falHouseBlank }   from '@fortawesome/pro-light-svg-icons';
import { faHouseBlank as farHouseBlank }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseBlank as fasHouseBlank }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseBuilding as fadHouseBuilding }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseBuilding as falHouseBuilding }   from '@fortawesome/pro-light-svg-icons';
import { faHouseBuilding as farHouseBuilding }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseBuilding as fasHouseBuilding }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseChimney as fadHouseChimney }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseChimney as falHouseChimney }   from '@fortawesome/pro-light-svg-icons';
import { faHouseChimney as farHouseChimney }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseChimney as fasHouseChimney }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseChimneyBlank as fadHouseChimneyBlank }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseChimneyBlank as falHouseChimneyBlank }   from '@fortawesome/pro-light-svg-icons';
import { faHouseChimneyBlank as farHouseChimneyBlank }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseChimneyBlank as fasHouseChimneyBlank }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseChimneyCrack as fadHouseChimneyCrack }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseChimneyCrack as falHouseChimneyCrack }   from '@fortawesome/pro-light-svg-icons';
import { faHouseChimneyCrack as farHouseChimneyCrack }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseChimneyCrack as fasHouseChimneyCrack }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseChimneyHeart as fadHouseChimneyHeart }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseChimneyHeart as falHouseChimneyHeart }   from '@fortawesome/pro-light-svg-icons';
import { faHouseChimneyHeart as farHouseChimneyHeart }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseChimneyHeart as fasHouseChimneyHeart }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseChimneyMedical as fadHouseChimneyMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseChimneyMedical as falHouseChimneyMedical }   from '@fortawesome/pro-light-svg-icons';
import { faHouseChimneyMedical as farHouseChimneyMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseChimneyMedical as fasHouseChimneyMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseChimneyUser as fadHouseChimneyUser }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseChimneyUser as falHouseChimneyUser }   from '@fortawesome/pro-light-svg-icons';
import { faHouseChimneyUser as farHouseChimneyUser }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseChimneyUser as fasHouseChimneyUser }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseChimneyWindow as fadHouseChimneyWindow }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseChimneyWindow as falHouseChimneyWindow }   from '@fortawesome/pro-light-svg-icons';
import { faHouseChimneyWindow as farHouseChimneyWindow }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseChimneyWindow as fasHouseChimneyWindow }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseCircleCheck as fadHouseCircleCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseCircleCheck as falHouseCircleCheck }   from '@fortawesome/pro-light-svg-icons';
import { faHouseCircleCheck as farHouseCircleCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseCircleCheck as fasHouseCircleCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseCircleExclamation as fadHouseCircleExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseCircleExclamation as falHouseCircleExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faHouseCircleExclamation as farHouseCircleExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseCircleExclamation as fasHouseCircleExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseCircleXmark as fadHouseCircleXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseCircleXmark as falHouseCircleXmark }   from '@fortawesome/pro-light-svg-icons';
import { faHouseCircleXmark as farHouseCircleXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseCircleXmark as fasHouseCircleXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseCrack as fadHouseCrack }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseCrack as falHouseCrack }   from '@fortawesome/pro-light-svg-icons';
import { faHouseCrack as farHouseCrack }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseCrack as fasHouseCrack }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseDamage as fadHouseDamage }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseDamage as falHouseDamage }   from '@fortawesome/pro-light-svg-icons';
import { faHouseDamage as farHouseDamage }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseDamage as fasHouseDamage }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseDay as fadHouseDay }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseDay as falHouseDay }   from '@fortawesome/pro-light-svg-icons';
import { faHouseDay as farHouseDay }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseDay as fasHouseDay }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseFire as fadHouseFire }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseFire as falHouseFire }   from '@fortawesome/pro-light-svg-icons';
import { faHouseFire as farHouseFire }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseFire as fasHouseFire }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseFlag as fadHouseFlag }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseFlag as falHouseFlag }   from '@fortawesome/pro-light-svg-icons';
import { faHouseFlag as farHouseFlag }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseFlag as fasHouseFlag }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseFlood as fadHouseFlood }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseFlood as falHouseFlood }   from '@fortawesome/pro-light-svg-icons';
import { faHouseFlood as farHouseFlood }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseFlood as fasHouseFlood }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseFloodWater as fadHouseFloodWater }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseFloodWater as falHouseFloodWater }   from '@fortawesome/pro-light-svg-icons';
import { faHouseFloodWater as farHouseFloodWater }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseFloodWater as fasHouseFloodWater }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseFloodWaterCircleArrowRight as fadHouseFloodWaterCircleArrowRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseFloodWaterCircleArrowRight as falHouseFloodWaterCircleArrowRight }   from '@fortawesome/pro-light-svg-icons';
import { faHouseFloodWaterCircleArrowRight as farHouseFloodWaterCircleArrowRight }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseFloodWaterCircleArrowRight as fasHouseFloodWaterCircleArrowRight }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseHeart as fadHouseHeart }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseHeart as falHouseHeart }   from '@fortawesome/pro-light-svg-icons';
import { faHouseHeart as farHouseHeart }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseHeart as fasHouseHeart }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseLaptop as fadHouseLaptop }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseLaptop as falHouseLaptop }   from '@fortawesome/pro-light-svg-icons';
import { faHouseLaptop as farHouseLaptop }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseLaptop as fasHouseLaptop }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseLeave as fadHouseLeave }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseLeave as falHouseLeave }   from '@fortawesome/pro-light-svg-icons';
import { faHouseLeave as farHouseLeave }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseLeave as fasHouseLeave }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseLock as fadHouseLock }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseLock as falHouseLock }   from '@fortawesome/pro-light-svg-icons';
import { faHouseLock as farHouseLock }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseLock as fasHouseLock }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseMedical as fadHouseMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseMedical as falHouseMedical }   from '@fortawesome/pro-light-svg-icons';
import { faHouseMedical as farHouseMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseMedical as fasHouseMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseMedicalCircleCheck as fadHouseMedicalCircleCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseMedicalCircleCheck as falHouseMedicalCircleCheck }   from '@fortawesome/pro-light-svg-icons';
import { faHouseMedicalCircleCheck as farHouseMedicalCircleCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseMedicalCircleCheck as fasHouseMedicalCircleCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseMedicalCircleExclamation as fadHouseMedicalCircleExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseMedicalCircleExclamation as falHouseMedicalCircleExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faHouseMedicalCircleExclamation as farHouseMedicalCircleExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseMedicalCircleExclamation as fasHouseMedicalCircleExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseMedicalCircleXmark as fadHouseMedicalCircleXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseMedicalCircleXmark as falHouseMedicalCircleXmark }   from '@fortawesome/pro-light-svg-icons';
import { faHouseMedicalCircleXmark as farHouseMedicalCircleXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseMedicalCircleXmark as fasHouseMedicalCircleXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseMedicalFlag as fadHouseMedicalFlag }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseMedicalFlag as falHouseMedicalFlag }   from '@fortawesome/pro-light-svg-icons';
import { faHouseMedicalFlag as farHouseMedicalFlag }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseMedicalFlag as fasHouseMedicalFlag }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseNight as fadHouseNight }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseNight as falHouseNight }   from '@fortawesome/pro-light-svg-icons';
import { faHouseNight as farHouseNight }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseNight as fasHouseNight }   from '@fortawesome/pro-solid-svg-icons';
import { faHousePersonArrive as fadHousePersonArrive }   from '@fortawesome/pro-duotone-svg-icons';
import { faHousePersonArrive as falHousePersonArrive }   from '@fortawesome/pro-light-svg-icons';
import { faHousePersonArrive as farHousePersonArrive }   from '@fortawesome/pro-regular-svg-icons';
import { faHousePersonArrive as fasHousePersonArrive }   from '@fortawesome/pro-solid-svg-icons';
import { faHousePersonDepart as fadHousePersonDepart }   from '@fortawesome/pro-duotone-svg-icons';
import { faHousePersonDepart as falHousePersonDepart }   from '@fortawesome/pro-light-svg-icons';
import { faHousePersonDepart as farHousePersonDepart }   from '@fortawesome/pro-regular-svg-icons';
import { faHousePersonDepart as fasHousePersonDepart }   from '@fortawesome/pro-solid-svg-icons';
import { faHousePersonLeave as fadHousePersonLeave }   from '@fortawesome/pro-duotone-svg-icons';
import { faHousePersonLeave as falHousePersonLeave }   from '@fortawesome/pro-light-svg-icons';
import { faHousePersonLeave as farHousePersonLeave }   from '@fortawesome/pro-regular-svg-icons';
import { faHousePersonLeave as fasHousePersonLeave }   from '@fortawesome/pro-solid-svg-icons';
import { faHousePersonReturn as fadHousePersonReturn }   from '@fortawesome/pro-duotone-svg-icons';
import { faHousePersonReturn as falHousePersonReturn }   from '@fortawesome/pro-light-svg-icons';
import { faHousePersonReturn as farHousePersonReturn }   from '@fortawesome/pro-regular-svg-icons';
import { faHousePersonReturn as fasHousePersonReturn }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseReturn as fadHouseReturn }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseReturn as falHouseReturn }   from '@fortawesome/pro-light-svg-icons';
import { faHouseReturn as farHouseReturn }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseReturn as fasHouseReturn }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseSignal as fadHouseSignal }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseSignal as falHouseSignal }   from '@fortawesome/pro-light-svg-icons';
import { faHouseSignal as farHouseSignal }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseSignal as fasHouseSignal }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseTree as fadHouseTree }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseTree as falHouseTree }   from '@fortawesome/pro-light-svg-icons';
import { faHouseTree as farHouseTree }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseTree as fasHouseTree }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseTsunami as fadHouseTsunami }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseTsunami as falHouseTsunami }   from '@fortawesome/pro-light-svg-icons';
import { faHouseTsunami as farHouseTsunami }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseTsunami as fasHouseTsunami }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseTurret as fadHouseTurret }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseTurret as falHouseTurret }   from '@fortawesome/pro-light-svg-icons';
import { faHouseTurret as farHouseTurret }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseTurret as fasHouseTurret }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseUser as fadHouseUser }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseUser as falHouseUser }   from '@fortawesome/pro-light-svg-icons';
import { faHouseUser as farHouseUser }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseUser as fasHouseUser }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseWater as fadHouseWater }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseWater as falHouseWater }   from '@fortawesome/pro-light-svg-icons';
import { faHouseWater as farHouseWater }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseWater as fasHouseWater }   from '@fortawesome/pro-solid-svg-icons';
import { faHouseWindow as fadHouseWindow }   from '@fortawesome/pro-duotone-svg-icons';
import { faHouseWindow as falHouseWindow }   from '@fortawesome/pro-light-svg-icons';
import { faHouseWindow as farHouseWindow }   from '@fortawesome/pro-regular-svg-icons';
import { faHouseWindow as fasHouseWindow }   from '@fortawesome/pro-solid-svg-icons';
import { faHryvnia as fadHryvnia }   from '@fortawesome/pro-duotone-svg-icons';
import { faHryvnia as falHryvnia }   from '@fortawesome/pro-light-svg-icons';
import { faHryvnia as farHryvnia }   from '@fortawesome/pro-regular-svg-icons';
import { faHryvnia as fasHryvnia }   from '@fortawesome/pro-solid-svg-icons';
import { faHryvniaSign as fadHryvniaSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faHryvniaSign as falHryvniaSign }   from '@fortawesome/pro-light-svg-icons';
import { faHryvniaSign as farHryvniaSign }   from '@fortawesome/pro-regular-svg-icons';
import { faHryvniaSign as fasHryvniaSign }   from '@fortawesome/pro-solid-svg-icons';
import { faHSquare as fadHSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faHSquare as falHSquare }   from '@fortawesome/pro-light-svg-icons';
import { faHSquare as farHSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faHSquare as fasHSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faHumidity as fadHumidity }   from '@fortawesome/pro-duotone-svg-icons';
import { faHumidity as falHumidity }   from '@fortawesome/pro-light-svg-icons';
import { faHumidity as farHumidity }   from '@fortawesome/pro-regular-svg-icons';
import { faHumidity as fasHumidity }   from '@fortawesome/pro-solid-svg-icons';
import { faHundredPoints as fadHundredPoints }   from '@fortawesome/pro-duotone-svg-icons';
import { faHundredPoints as falHundredPoints }   from '@fortawesome/pro-light-svg-icons';
import { faHundredPoints as farHundredPoints }   from '@fortawesome/pro-regular-svg-icons';
import { faHundredPoints as fasHundredPoints }   from '@fortawesome/pro-solid-svg-icons';
import { faHurricane as fadHurricane }   from '@fortawesome/pro-duotone-svg-icons';
import { faHurricane as falHurricane }   from '@fortawesome/pro-light-svg-icons';
import { faHurricane as farHurricane }   from '@fortawesome/pro-regular-svg-icons';
import { faHurricane as fasHurricane }   from '@fortawesome/pro-solid-svg-icons';
import { faHyphen as fadHyphen }   from '@fortawesome/pro-duotone-svg-icons';
import { faHyphen as falHyphen }   from '@fortawesome/pro-light-svg-icons';
import { faHyphen as farHyphen }   from '@fortawesome/pro-regular-svg-icons';
import { faHyphen as fasHyphen }   from '@fortawesome/pro-solid-svg-icons';
import { faI as fadI }   from '@fortawesome/pro-duotone-svg-icons';
import { faI as falI }   from '@fortawesome/pro-light-svg-icons';
import { faI as farI }   from '@fortawesome/pro-regular-svg-icons';
import { faI as fasI }   from '@fortawesome/pro-solid-svg-icons';
import { faIceCream as fadIceCream }   from '@fortawesome/pro-duotone-svg-icons';
import { faIceCream as falIceCream }   from '@fortawesome/pro-light-svg-icons';
import { faIceCream as farIceCream }   from '@fortawesome/pro-regular-svg-icons';
import { faIceCream as fasIceCream }   from '@fortawesome/pro-solid-svg-icons';
import { faIceSkate as fadIceSkate }   from '@fortawesome/pro-duotone-svg-icons';
import { faIceSkate as falIceSkate }   from '@fortawesome/pro-light-svg-icons';
import { faIceSkate as farIceSkate }   from '@fortawesome/pro-regular-svg-icons';
import { faIceSkate as fasIceSkate }   from '@fortawesome/pro-solid-svg-icons';
import { faIcicles as fadIcicles }   from '@fortawesome/pro-duotone-svg-icons';
import { faIcicles as falIcicles }   from '@fortawesome/pro-light-svg-icons';
import { faIcicles as farIcicles }   from '@fortawesome/pro-regular-svg-icons';
import { faIcicles as fasIcicles }   from '@fortawesome/pro-solid-svg-icons';
import { faIcons as fadIcons }   from '@fortawesome/pro-duotone-svg-icons';
import { faIcons as falIcons }   from '@fortawesome/pro-light-svg-icons';
import { faIcons as farIcons }   from '@fortawesome/pro-regular-svg-icons';
import { faIcons as fasIcons }   from '@fortawesome/pro-solid-svg-icons';
import { faIconsAlt as fadIconsAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faIconsAlt as falIconsAlt }   from '@fortawesome/pro-light-svg-icons';
import { faIconsAlt as farIconsAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faIconsAlt as fasIconsAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faICursor as fadICursor }   from '@fortawesome/pro-duotone-svg-icons';
import { faICursor as falICursor }   from '@fortawesome/pro-light-svg-icons';
import { faICursor as farICursor }   from '@fortawesome/pro-regular-svg-icons';
import { faICursor as fasICursor }   from '@fortawesome/pro-solid-svg-icons';
import { faIdBadge as fadIdBadge }   from '@fortawesome/pro-duotone-svg-icons';
import { faIdBadge as falIdBadge }   from '@fortawesome/pro-light-svg-icons';
import { faIdBadge as farIdBadge }   from '@fortawesome/pro-regular-svg-icons';
import { faIdBadge as fasIdBadge }   from '@fortawesome/pro-solid-svg-icons';
import { faIdCard as fadIdCard }   from '@fortawesome/pro-duotone-svg-icons';
import { faIdCard as falIdCard }   from '@fortawesome/pro-light-svg-icons';
import { faIdCard as farIdCard }   from '@fortawesome/pro-regular-svg-icons';
import { faIdCard as fasIdCard }   from '@fortawesome/pro-solid-svg-icons';
import { faIdCardAlt as fadIdCardAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faIdCardAlt as falIdCardAlt }   from '@fortawesome/pro-light-svg-icons';
import { faIdCardAlt as farIdCardAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faIdCardAlt as fasIdCardAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faIdCardClip as fadIdCardClip }   from '@fortawesome/pro-duotone-svg-icons';
import { faIdCardClip as falIdCardClip }   from '@fortawesome/pro-light-svg-icons';
import { faIdCardClip as farIdCardClip }   from '@fortawesome/pro-regular-svg-icons';
import { faIdCardClip as fasIdCardClip }   from '@fortawesome/pro-solid-svg-icons';
import { faIgloo as fadIgloo }   from '@fortawesome/pro-duotone-svg-icons';
import { faIgloo as falIgloo }   from '@fortawesome/pro-light-svg-icons';
import { faIgloo as farIgloo }   from '@fortawesome/pro-regular-svg-icons';
import { faIgloo as fasIgloo }   from '@fortawesome/pro-solid-svg-icons';
import { faIls as fadIls }   from '@fortawesome/pro-duotone-svg-icons';
import { faIls as falIls }   from '@fortawesome/pro-light-svg-icons';
import { faIls as farIls }   from '@fortawesome/pro-regular-svg-icons';
import { faIls as fasIls }   from '@fortawesome/pro-solid-svg-icons';
import { faImage as fadImage }   from '@fortawesome/pro-duotone-svg-icons';
import { faImage as falImage }   from '@fortawesome/pro-light-svg-icons';
import { faImage as farImage }   from '@fortawesome/pro-regular-svg-icons';
import { faImage as fasImage }   from '@fortawesome/pro-solid-svg-icons';
import { faImageLandscape as fadImageLandscape }   from '@fortawesome/pro-duotone-svg-icons';
import { faImageLandscape as falImageLandscape }   from '@fortawesome/pro-light-svg-icons';
import { faImageLandscape as farImageLandscape }   from '@fortawesome/pro-regular-svg-icons';
import { faImageLandscape as fasImageLandscape }   from '@fortawesome/pro-solid-svg-icons';
import { faImagePolaroid as fadImagePolaroid }   from '@fortawesome/pro-duotone-svg-icons';
import { faImagePolaroid as falImagePolaroid }   from '@fortawesome/pro-light-svg-icons';
import { faImagePolaroid as farImagePolaroid }   from '@fortawesome/pro-regular-svg-icons';
import { faImagePolaroid as fasImagePolaroid }   from '@fortawesome/pro-solid-svg-icons';
import { faImagePolaroidUser as fadImagePolaroidUser }   from '@fortawesome/pro-duotone-svg-icons';
import { faImagePolaroidUser as falImagePolaroidUser }   from '@fortawesome/pro-light-svg-icons';
import { faImagePolaroidUser as farImagePolaroidUser }   from '@fortawesome/pro-regular-svg-icons';
import { faImagePolaroidUser as fasImagePolaroidUser }   from '@fortawesome/pro-solid-svg-icons';
import { faImagePortrait as fadImagePortrait }   from '@fortawesome/pro-duotone-svg-icons';
import { faImagePortrait as falImagePortrait }   from '@fortawesome/pro-light-svg-icons';
import { faImagePortrait as farImagePortrait }   from '@fortawesome/pro-regular-svg-icons';
import { faImagePortrait as fasImagePortrait }   from '@fortawesome/pro-solid-svg-icons';
import { faImages as fadImages }   from '@fortawesome/pro-duotone-svg-icons';
import { faImages as falImages }   from '@fortawesome/pro-light-svg-icons';
import { faImages as farImages }   from '@fortawesome/pro-regular-svg-icons';
import { faImages as fasImages }   from '@fortawesome/pro-solid-svg-icons';
import { faImageSlash as fadImageSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faImageSlash as falImageSlash }   from '@fortawesome/pro-light-svg-icons';
import { faImageSlash as farImageSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faImageSlash as fasImageSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faImagesUser as fadImagesUser }   from '@fortawesome/pro-duotone-svg-icons';
import { faImagesUser as falImagesUser }   from '@fortawesome/pro-light-svg-icons';
import { faImagesUser as farImagesUser }   from '@fortawesome/pro-regular-svg-icons';
import { faImagesUser as fasImagesUser }   from '@fortawesome/pro-solid-svg-icons';
import { faImageUser as fadImageUser }   from '@fortawesome/pro-duotone-svg-icons';
import { faImageUser as falImageUser }   from '@fortawesome/pro-light-svg-icons';
import { faImageUser as farImageUser }   from '@fortawesome/pro-regular-svg-icons';
import { faImageUser as fasImageUser }   from '@fortawesome/pro-solid-svg-icons';
import { faInbox as fadInbox }   from '@fortawesome/pro-duotone-svg-icons';
import { faInbox as falInbox }   from '@fortawesome/pro-light-svg-icons';
import { faInbox as farInbox }   from '@fortawesome/pro-regular-svg-icons';
import { faInbox as fasInbox }   from '@fortawesome/pro-solid-svg-icons';
import { faInboxArrowDown as fadInboxArrowDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faInboxArrowDown as falInboxArrowDown }   from '@fortawesome/pro-light-svg-icons';
import { faInboxArrowDown as farInboxArrowDown }   from '@fortawesome/pro-regular-svg-icons';
import { faInboxArrowDown as fasInboxArrowDown }   from '@fortawesome/pro-solid-svg-icons';
import { faInboxArrowUp as fadInboxArrowUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faInboxArrowUp as falInboxArrowUp }   from '@fortawesome/pro-light-svg-icons';
import { faInboxArrowUp as farInboxArrowUp }   from '@fortawesome/pro-regular-svg-icons';
import { faInboxArrowUp as fasInboxArrowUp }   from '@fortawesome/pro-solid-svg-icons';
import { faInboxes as fadInboxes }   from '@fortawesome/pro-duotone-svg-icons';
import { faInboxes as falInboxes }   from '@fortawesome/pro-light-svg-icons';
import { faInboxes as farInboxes }   from '@fortawesome/pro-regular-svg-icons';
import { faInboxes as fasInboxes }   from '@fortawesome/pro-solid-svg-icons';
import { faInboxFull as fadInboxFull }   from '@fortawesome/pro-duotone-svg-icons';
import { faInboxFull as falInboxFull }   from '@fortawesome/pro-light-svg-icons';
import { faInboxFull as farInboxFull }   from '@fortawesome/pro-regular-svg-icons';
import { faInboxFull as fasInboxFull }   from '@fortawesome/pro-solid-svg-icons';
import { faInboxIn as fadInboxIn }   from '@fortawesome/pro-duotone-svg-icons';
import { faInboxIn as falInboxIn }   from '@fortawesome/pro-light-svg-icons';
import { faInboxIn as farInboxIn }   from '@fortawesome/pro-regular-svg-icons';
import { faInboxIn as fasInboxIn }   from '@fortawesome/pro-solid-svg-icons';
import { faInboxOut as fadInboxOut }   from '@fortawesome/pro-duotone-svg-icons';
import { faInboxOut as falInboxOut }   from '@fortawesome/pro-light-svg-icons';
import { faInboxOut as farInboxOut }   from '@fortawesome/pro-regular-svg-icons';
import { faInboxOut as fasInboxOut }   from '@fortawesome/pro-solid-svg-icons';
import { faIndent as fadIndent }   from '@fortawesome/pro-duotone-svg-icons';
import { faIndent as falIndent }   from '@fortawesome/pro-light-svg-icons';
import { faIndent as farIndent }   from '@fortawesome/pro-regular-svg-icons';
import { faIndent as fasIndent }   from '@fortawesome/pro-solid-svg-icons';
import { faIndianRupee as fadIndianRupee }   from '@fortawesome/pro-duotone-svg-icons';
import { faIndianRupee as falIndianRupee }   from '@fortawesome/pro-light-svg-icons';
import { faIndianRupee as farIndianRupee }   from '@fortawesome/pro-regular-svg-icons';
import { faIndianRupee as fasIndianRupee }   from '@fortawesome/pro-solid-svg-icons';
import { faIndianRupeeSign as fadIndianRupeeSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faIndianRupeeSign as falIndianRupeeSign }   from '@fortawesome/pro-light-svg-icons';
import { faIndianRupeeSign as farIndianRupeeSign }   from '@fortawesome/pro-regular-svg-icons';
import { faIndianRupeeSign as fasIndianRupeeSign }   from '@fortawesome/pro-solid-svg-icons';
import { faIndustry as fadIndustry }   from '@fortawesome/pro-duotone-svg-icons';
import { faIndustry as falIndustry }   from '@fortawesome/pro-light-svg-icons';
import { faIndustry as farIndustry }   from '@fortawesome/pro-regular-svg-icons';
import { faIndustry as fasIndustry }   from '@fortawesome/pro-solid-svg-icons';
import { faIndustryAlt as fadIndustryAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faIndustryAlt as falIndustryAlt }   from '@fortawesome/pro-light-svg-icons';
import { faIndustryAlt as farIndustryAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faIndustryAlt as fasIndustryAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faIndustryWindows as fadIndustryWindows }   from '@fortawesome/pro-duotone-svg-icons';
import { faIndustryWindows as falIndustryWindows }   from '@fortawesome/pro-light-svg-icons';
import { faIndustryWindows as farIndustryWindows }   from '@fortawesome/pro-regular-svg-icons';
import { faIndustryWindows as fasIndustryWindows }   from '@fortawesome/pro-solid-svg-icons';
import { faInfinity as fadInfinity }   from '@fortawesome/pro-duotone-svg-icons';
import { faInfinity as falInfinity }   from '@fortawesome/pro-light-svg-icons';
import { faInfinity as farInfinity }   from '@fortawesome/pro-regular-svg-icons';
import { faInfinity as fasInfinity }   from '@fortawesome/pro-solid-svg-icons';
import { faInfo as fadInfo }   from '@fortawesome/pro-duotone-svg-icons';
import { faInfo as falInfo }   from '@fortawesome/pro-light-svg-icons';
import { faInfo as farInfo }   from '@fortawesome/pro-regular-svg-icons';
import { faInfo as fasInfo }   from '@fortawesome/pro-solid-svg-icons';
import { faInfoCircle as fadInfoCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faInfoCircle as falInfoCircle }   from '@fortawesome/pro-light-svg-icons';
import { faInfoCircle as farInfoCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faInfoCircle as fasInfoCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faInfoSquare as fadInfoSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faInfoSquare as falInfoSquare }   from '@fortawesome/pro-light-svg-icons';
import { faInfoSquare as farInfoSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faInfoSquare as fasInfoSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faInhaler as fadInhaler }   from '@fortawesome/pro-duotone-svg-icons';
import { faInhaler as falInhaler }   from '@fortawesome/pro-light-svg-icons';
import { faInhaler as farInhaler }   from '@fortawesome/pro-regular-svg-icons';
import { faInhaler as fasInhaler }   from '@fortawesome/pro-solid-svg-icons';
import { faInputNumeric as fadInputNumeric }   from '@fortawesome/pro-duotone-svg-icons';
import { faInputNumeric as falInputNumeric }   from '@fortawesome/pro-light-svg-icons';
import { faInputNumeric as farInputNumeric }   from '@fortawesome/pro-regular-svg-icons';
import { faInputNumeric as fasInputNumeric }   from '@fortawesome/pro-solid-svg-icons';
import { faInputPipe as fadInputPipe }   from '@fortawesome/pro-duotone-svg-icons';
import { faInputPipe as falInputPipe }   from '@fortawesome/pro-light-svg-icons';
import { faInputPipe as farInputPipe }   from '@fortawesome/pro-regular-svg-icons';
import { faInputPipe as fasInputPipe }   from '@fortawesome/pro-solid-svg-icons';
import { faInputText as fadInputText }   from '@fortawesome/pro-duotone-svg-icons';
import { faInputText as falInputText }   from '@fortawesome/pro-light-svg-icons';
import { faInputText as farInputText }   from '@fortawesome/pro-regular-svg-icons';
import { faInputText as fasInputText }   from '@fortawesome/pro-solid-svg-icons';
import { faInr as fadInr }   from '@fortawesome/pro-duotone-svg-icons';
import { faInr as falInr }   from '@fortawesome/pro-light-svg-icons';
import { faInr as farInr }   from '@fortawesome/pro-regular-svg-icons';
import { faInr as fasInr }   from '@fortawesome/pro-solid-svg-icons';
import { faInstitution as fadInstitution }   from '@fortawesome/pro-duotone-svg-icons';
import { faInstitution as falInstitution }   from '@fortawesome/pro-light-svg-icons';
import { faInstitution as farInstitution }   from '@fortawesome/pro-regular-svg-icons';
import { faInstitution as fasInstitution }   from '@fortawesome/pro-solid-svg-icons';
import { faIntegral as fadIntegral }   from '@fortawesome/pro-duotone-svg-icons';
import { faIntegral as falIntegral }   from '@fortawesome/pro-light-svg-icons';
import { faIntegral as farIntegral }   from '@fortawesome/pro-regular-svg-icons';
import { faIntegral as fasIntegral }   from '@fortawesome/pro-solid-svg-icons';
import { faIntersection as fadIntersection }   from '@fortawesome/pro-duotone-svg-icons';
import { faIntersection as falIntersection }   from '@fortawesome/pro-light-svg-icons';
import { faIntersection as farIntersection }   from '@fortawesome/pro-regular-svg-icons';
import { faIntersection as fasIntersection }   from '@fortawesome/pro-solid-svg-icons';
import { faInventory as fadInventory }   from '@fortawesome/pro-duotone-svg-icons';
import { faInventory as falInventory }   from '@fortawesome/pro-light-svg-icons';
import { faInventory as farInventory }   from '@fortawesome/pro-regular-svg-icons';
import { faInventory as fasInventory }   from '@fortawesome/pro-solid-svg-icons';
import { faIslandTreePalm as fadIslandTreePalm }   from '@fortawesome/pro-duotone-svg-icons';
import { faIslandTreePalm as falIslandTreePalm }   from '@fortawesome/pro-light-svg-icons';
import { faIslandTreePalm as farIslandTreePalm }   from '@fortawesome/pro-regular-svg-icons';
import { faIslandTreePalm as fasIslandTreePalm }   from '@fortawesome/pro-solid-svg-icons';
import { faIslandTropical as fadIslandTropical }   from '@fortawesome/pro-duotone-svg-icons';
import { faIslandTropical as falIslandTropical }   from '@fortawesome/pro-light-svg-icons';
import { faIslandTropical as farIslandTropical }   from '@fortawesome/pro-regular-svg-icons';
import { faIslandTropical as fasIslandTropical }   from '@fortawesome/pro-solid-svg-icons';
import { faItalic as fadItalic }   from '@fortawesome/pro-duotone-svg-icons';
import { faItalic as falItalic }   from '@fortawesome/pro-light-svg-icons';
import { faItalic as farItalic }   from '@fortawesome/pro-regular-svg-icons';
import { faItalic as fasItalic }   from '@fortawesome/pro-solid-svg-icons';
import { faJ as fadJ }   from '@fortawesome/pro-duotone-svg-icons';
import { faJ as falJ }   from '@fortawesome/pro-light-svg-icons';
import { faJ as farJ }   from '@fortawesome/pro-regular-svg-icons';
import { faJ as fasJ }   from '@fortawesome/pro-solid-svg-icons';
import { faJackOLantern as fadJackOLantern }   from '@fortawesome/pro-duotone-svg-icons';
import { faJackOLantern as falJackOLantern }   from '@fortawesome/pro-light-svg-icons';
import { faJackOLantern as farJackOLantern }   from '@fortawesome/pro-regular-svg-icons';
import { faJackOLantern as fasJackOLantern }   from '@fortawesome/pro-solid-svg-icons';
import { faJar as fadJar }   from '@fortawesome/pro-duotone-svg-icons';
import { faJar as falJar }   from '@fortawesome/pro-light-svg-icons';
import { faJar as farJar }   from '@fortawesome/pro-regular-svg-icons';
import { faJar as fasJar }   from '@fortawesome/pro-solid-svg-icons';
import { faJarWheat as fadJarWheat }   from '@fortawesome/pro-duotone-svg-icons';
import { faJarWheat as falJarWheat }   from '@fortawesome/pro-light-svg-icons';
import { faJarWheat as farJarWheat }   from '@fortawesome/pro-regular-svg-icons';
import { faJarWheat as fasJarWheat }   from '@fortawesome/pro-solid-svg-icons';
import { faJedi as fadJedi }   from '@fortawesome/pro-duotone-svg-icons';
import { faJedi as falJedi }   from '@fortawesome/pro-light-svg-icons';
import { faJedi as farJedi }   from '@fortawesome/pro-regular-svg-icons';
import { faJedi as fasJedi }   from '@fortawesome/pro-solid-svg-icons';
import { faJetFighter as fadJetFighter }   from '@fortawesome/pro-duotone-svg-icons';
import { faJetFighter as falJetFighter }   from '@fortawesome/pro-light-svg-icons';
import { faJetFighter as farJetFighter }   from '@fortawesome/pro-regular-svg-icons';
import { faJetFighter as fasJetFighter }   from '@fortawesome/pro-solid-svg-icons';
import { faJetFighterUp as fadJetFighterUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faJetFighterUp as falJetFighterUp }   from '@fortawesome/pro-light-svg-icons';
import { faJetFighterUp as farJetFighterUp }   from '@fortawesome/pro-regular-svg-icons';
import { faJetFighterUp as fasJetFighterUp }   from '@fortawesome/pro-solid-svg-icons';
import { faJoint as fadJoint }   from '@fortawesome/pro-duotone-svg-icons';
import { faJoint as falJoint }   from '@fortawesome/pro-light-svg-icons';
import { faJoint as farJoint }   from '@fortawesome/pro-regular-svg-icons';
import { faJoint as fasJoint }   from '@fortawesome/pro-solid-svg-icons';
import { faJournalWhills as fadJournalWhills }   from '@fortawesome/pro-duotone-svg-icons';
import { faJournalWhills as falJournalWhills }   from '@fortawesome/pro-light-svg-icons';
import { faJournalWhills as farJournalWhills }   from '@fortawesome/pro-regular-svg-icons';
import { faJournalWhills as fasJournalWhills }   from '@fortawesome/pro-solid-svg-icons';
import { faJoystick as fadJoystick }   from '@fortawesome/pro-duotone-svg-icons';
import { faJoystick as falJoystick }   from '@fortawesome/pro-light-svg-icons';
import { faJoystick as farJoystick }   from '@fortawesome/pro-regular-svg-icons';
import { faJoystick as fasJoystick }   from '@fortawesome/pro-solid-svg-icons';
import { faJpy as fadJpy }   from '@fortawesome/pro-duotone-svg-icons';
import { faJpy as falJpy }   from '@fortawesome/pro-light-svg-icons';
import { faJpy as farJpy }   from '@fortawesome/pro-regular-svg-icons';
import { faJpy as fasJpy }   from '@fortawesome/pro-solid-svg-icons';
import { faJug as fadJug }   from '@fortawesome/pro-duotone-svg-icons';
import { faJug as falJug }   from '@fortawesome/pro-light-svg-icons';
import { faJug as farJug }   from '@fortawesome/pro-regular-svg-icons';
import { faJug as fasJug }   from '@fortawesome/pro-solid-svg-icons';
import { faJugDetergent as fadJugDetergent }   from '@fortawesome/pro-duotone-svg-icons';
import { faJugDetergent as falJugDetergent }   from '@fortawesome/pro-light-svg-icons';
import { faJugDetergent as farJugDetergent }   from '@fortawesome/pro-regular-svg-icons';
import { faJugDetergent as fasJugDetergent }   from '@fortawesome/pro-solid-svg-icons';
import { faK as fadK }   from '@fortawesome/pro-duotone-svg-icons';
import { faK as falK }   from '@fortawesome/pro-light-svg-icons';
import { faK as farK }   from '@fortawesome/pro-regular-svg-icons';
import { faK as fasK }   from '@fortawesome/pro-solid-svg-icons';
import { faKaaba as fadKaaba }   from '@fortawesome/pro-duotone-svg-icons';
import { faKaaba as falKaaba }   from '@fortawesome/pro-light-svg-icons';
import { faKaaba as farKaaba }   from '@fortawesome/pro-regular-svg-icons';
import { faKaaba as fasKaaba }   from '@fortawesome/pro-solid-svg-icons';
import { faKazoo as fadKazoo }   from '@fortawesome/pro-duotone-svg-icons';
import { faKazoo as falKazoo }   from '@fortawesome/pro-light-svg-icons';
import { faKazoo as farKazoo }   from '@fortawesome/pro-regular-svg-icons';
import { faKazoo as fasKazoo }   from '@fortawesome/pro-solid-svg-icons';
import { faKerning as fadKerning }   from '@fortawesome/pro-duotone-svg-icons';
import { faKerning as falKerning }   from '@fortawesome/pro-light-svg-icons';
import { faKerning as farKerning }   from '@fortawesome/pro-regular-svg-icons';
import { faKerning as fasKerning }   from '@fortawesome/pro-solid-svg-icons';
import { faKey as fadKey }   from '@fortawesome/pro-duotone-svg-icons';
import { faKey as falKey }   from '@fortawesome/pro-light-svg-icons';
import { faKey as farKey }   from '@fortawesome/pro-regular-svg-icons';
import { faKey as fasKey }   from '@fortawesome/pro-solid-svg-icons';
import { faKeyboard as fadKeyboard }   from '@fortawesome/pro-duotone-svg-icons';
import { faKeyboard as falKeyboard }   from '@fortawesome/pro-light-svg-icons';
import { faKeyboard as farKeyboard }   from '@fortawesome/pro-regular-svg-icons';
import { faKeyboard as fasKeyboard }   from '@fortawesome/pro-solid-svg-icons';
import { faKeyboardBrightness as fadKeyboardBrightness }   from '@fortawesome/pro-duotone-svg-icons';
import { faKeyboardBrightness as falKeyboardBrightness }   from '@fortawesome/pro-light-svg-icons';
import { faKeyboardBrightness as farKeyboardBrightness }   from '@fortawesome/pro-regular-svg-icons';
import { faKeyboardBrightness as fasKeyboardBrightness }   from '@fortawesome/pro-solid-svg-icons';
import { faKeyboardBrightnessLow as fadKeyboardBrightnessLow }   from '@fortawesome/pro-duotone-svg-icons';
import { faKeyboardBrightnessLow as falKeyboardBrightnessLow }   from '@fortawesome/pro-light-svg-icons';
import { faKeyboardBrightnessLow as farKeyboardBrightnessLow }   from '@fortawesome/pro-regular-svg-icons';
import { faKeyboardBrightnessLow as fasKeyboardBrightnessLow }   from '@fortawesome/pro-solid-svg-icons';
import { faKeyboardDown as fadKeyboardDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faKeyboardDown as falKeyboardDown }   from '@fortawesome/pro-light-svg-icons';
import { faKeyboardDown as farKeyboardDown }   from '@fortawesome/pro-regular-svg-icons';
import { faKeyboardDown as fasKeyboardDown }   from '@fortawesome/pro-solid-svg-icons';
import { faKeyboardLeft as fadKeyboardLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faKeyboardLeft as falKeyboardLeft }   from '@fortawesome/pro-light-svg-icons';
import { faKeyboardLeft as farKeyboardLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faKeyboardLeft as fasKeyboardLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faKeynote as fadKeynote }   from '@fortawesome/pro-duotone-svg-icons';
import { faKeynote as falKeynote }   from '@fortawesome/pro-light-svg-icons';
import { faKeynote as farKeynote }   from '@fortawesome/pro-regular-svg-icons';
import { faKeynote as fasKeynote }   from '@fortawesome/pro-solid-svg-icons';
import { faKeySkeleton as fadKeySkeleton }   from '@fortawesome/pro-duotone-svg-icons';
import { faKeySkeleton as falKeySkeleton }   from '@fortawesome/pro-light-svg-icons';
import { faKeySkeleton as farKeySkeleton }   from '@fortawesome/pro-regular-svg-icons';
import { faKeySkeleton as fasKeySkeleton }   from '@fortawesome/pro-solid-svg-icons';
import { faKeySkeletonLeftRight as fadKeySkeletonLeftRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faKeySkeletonLeftRight as falKeySkeletonLeftRight }   from '@fortawesome/pro-light-svg-icons';
import { faKeySkeletonLeftRight as farKeySkeletonLeftRight }   from '@fortawesome/pro-regular-svg-icons';
import { faKeySkeletonLeftRight as fasKeySkeletonLeftRight }   from '@fortawesome/pro-solid-svg-icons';
import { faKhanda as fadKhanda }   from '@fortawesome/pro-duotone-svg-icons';
import { faKhanda as falKhanda }   from '@fortawesome/pro-light-svg-icons';
import { faKhanda as farKhanda }   from '@fortawesome/pro-regular-svg-icons';
import { faKhanda as fasKhanda }   from '@fortawesome/pro-solid-svg-icons';
import { faKidneys as fadKidneys }   from '@fortawesome/pro-duotone-svg-icons';
import { faKidneys as falKidneys }   from '@fortawesome/pro-light-svg-icons';
import { faKidneys as farKidneys }   from '@fortawesome/pro-regular-svg-icons';
import { faKidneys as fasKidneys }   from '@fortawesome/pro-solid-svg-icons';
import { faKipSign as fadKipSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faKipSign as falKipSign }   from '@fortawesome/pro-light-svg-icons';
import { faKipSign as farKipSign }   from '@fortawesome/pro-regular-svg-icons';
import { faKipSign as fasKipSign }   from '@fortawesome/pro-solid-svg-icons';
import { faKiss as fadKiss }   from '@fortawesome/pro-duotone-svg-icons';
import { faKiss as falKiss }   from '@fortawesome/pro-light-svg-icons';
import { faKiss as farKiss }   from '@fortawesome/pro-regular-svg-icons';
import { faKiss as fasKiss }   from '@fortawesome/pro-solid-svg-icons';
import { faKissBeam as fadKissBeam }   from '@fortawesome/pro-duotone-svg-icons';
import { faKissBeam as falKissBeam }   from '@fortawesome/pro-light-svg-icons';
import { faKissBeam as farKissBeam }   from '@fortawesome/pro-regular-svg-icons';
import { faKissBeam as fasKissBeam }   from '@fortawesome/pro-solid-svg-icons';
import { faKissWinkHeart as fadKissWinkHeart }   from '@fortawesome/pro-duotone-svg-icons';
import { faKissWinkHeart as falKissWinkHeart }   from '@fortawesome/pro-light-svg-icons';
import { faKissWinkHeart as farKissWinkHeart }   from '@fortawesome/pro-regular-svg-icons';
import { faKissWinkHeart as fasKissWinkHeart }   from '@fortawesome/pro-solid-svg-icons';
import { faKitchenSet as fadKitchenSet }   from '@fortawesome/pro-duotone-svg-icons';
import { faKitchenSet as falKitchenSet }   from '@fortawesome/pro-light-svg-icons';
import { faKitchenSet as farKitchenSet }   from '@fortawesome/pro-regular-svg-icons';
import { faKitchenSet as fasKitchenSet }   from '@fortawesome/pro-solid-svg-icons';
import { faKite as fadKite }   from '@fortawesome/pro-duotone-svg-icons';
import { faKite as falKite }   from '@fortawesome/pro-light-svg-icons';
import { faKite as farKite }   from '@fortawesome/pro-regular-svg-icons';
import { faKite as fasKite }   from '@fortawesome/pro-solid-svg-icons';
import { faKitMedical as fadKitMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faKitMedical as falKitMedical }   from '@fortawesome/pro-light-svg-icons';
import { faKitMedical as farKitMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faKitMedical as fasKitMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faKiwiBird as fadKiwiBird }   from '@fortawesome/pro-duotone-svg-icons';
import { faKiwiBird as falKiwiBird }   from '@fortawesome/pro-light-svg-icons';
import { faKiwiBird as farKiwiBird }   from '@fortawesome/pro-regular-svg-icons';
import { faKiwiBird as fasKiwiBird }   from '@fortawesome/pro-solid-svg-icons';
import { faKiwiFruit as fadKiwiFruit }   from '@fortawesome/pro-duotone-svg-icons';
import { faKiwiFruit as falKiwiFruit }   from '@fortawesome/pro-light-svg-icons';
import { faKiwiFruit as farKiwiFruit }   from '@fortawesome/pro-regular-svg-icons';
import { faKiwiFruit as fasKiwiFruit }   from '@fortawesome/pro-solid-svg-icons';
import { faKnife as fadKnife }   from '@fortawesome/pro-duotone-svg-icons';
import { faKnife as falKnife }   from '@fortawesome/pro-light-svg-icons';
import { faKnife as farKnife }   from '@fortawesome/pro-regular-svg-icons';
import { faKnife as fasKnife }   from '@fortawesome/pro-solid-svg-icons';
import { faKnifeKitchen as fadKnifeKitchen }   from '@fortawesome/pro-duotone-svg-icons';
import { faKnifeKitchen as falKnifeKitchen }   from '@fortawesome/pro-light-svg-icons';
import { faKnifeKitchen as farKnifeKitchen }   from '@fortawesome/pro-regular-svg-icons';
import { faKnifeKitchen as fasKnifeKitchen }   from '@fortawesome/pro-solid-svg-icons';
import { faKrw as fadKrw }   from '@fortawesome/pro-duotone-svg-icons';
import { faKrw as falKrw }   from '@fortawesome/pro-light-svg-icons';
import { faKrw as farKrw }   from '@fortawesome/pro-regular-svg-icons';
import { faKrw as fasKrw }   from '@fortawesome/pro-solid-svg-icons';
import { faL as fadL }   from '@fortawesome/pro-duotone-svg-icons';
import { faL as falL }   from '@fortawesome/pro-light-svg-icons';
import { faL as farL }   from '@fortawesome/pro-regular-svg-icons';
import { faL as fasL }   from '@fortawesome/pro-solid-svg-icons';
import { faLacrosseStick as fadLacrosseStick }   from '@fortawesome/pro-duotone-svg-icons';
import { faLacrosseStick as falLacrosseStick }   from '@fortawesome/pro-light-svg-icons';
import { faLacrosseStick as farLacrosseStick }   from '@fortawesome/pro-regular-svg-icons';
import { faLacrosseStick as fasLacrosseStick }   from '@fortawesome/pro-solid-svg-icons';
import { faLacrosseStickBall as fadLacrosseStickBall }   from '@fortawesome/pro-duotone-svg-icons';
import { faLacrosseStickBall as falLacrosseStickBall }   from '@fortawesome/pro-light-svg-icons';
import { faLacrosseStickBall as farLacrosseStickBall }   from '@fortawesome/pro-regular-svg-icons';
import { faLacrosseStickBall as fasLacrosseStickBall }   from '@fortawesome/pro-solid-svg-icons';
import { faLadderWater as fadLadderWater }   from '@fortawesome/pro-duotone-svg-icons';
import { faLadderWater as falLadderWater }   from '@fortawesome/pro-light-svg-icons';
import { faLadderWater as farLadderWater }   from '@fortawesome/pro-regular-svg-icons';
import { faLadderWater as fasLadderWater }   from '@fortawesome/pro-solid-svg-icons';
import { faLambda as fadLambda }   from '@fortawesome/pro-duotone-svg-icons';
import { faLambda as falLambda }   from '@fortawesome/pro-light-svg-icons';
import { faLambda as farLambda }   from '@fortawesome/pro-regular-svg-icons';
import { faLambda as fasLambda }   from '@fortawesome/pro-solid-svg-icons';
import { faLamp as fadLamp }   from '@fortawesome/pro-duotone-svg-icons';
import { faLamp as falLamp }   from '@fortawesome/pro-light-svg-icons';
import { faLamp as farLamp }   from '@fortawesome/pro-regular-svg-icons';
import { faLamp as fasLamp }   from '@fortawesome/pro-solid-svg-icons';
import { faLampDesk as fadLampDesk }   from '@fortawesome/pro-duotone-svg-icons';
import { faLampDesk as falLampDesk }   from '@fortawesome/pro-light-svg-icons';
import { faLampDesk as farLampDesk }   from '@fortawesome/pro-regular-svg-icons';
import { faLampDesk as fasLampDesk }   from '@fortawesome/pro-solid-svg-icons';
import { faLampFloor as fadLampFloor }   from '@fortawesome/pro-duotone-svg-icons';
import { faLampFloor as falLampFloor }   from '@fortawesome/pro-light-svg-icons';
import { faLampFloor as farLampFloor }   from '@fortawesome/pro-regular-svg-icons';
import { faLampFloor as fasLampFloor }   from '@fortawesome/pro-solid-svg-icons';
import { faLampStreet as fadLampStreet }   from '@fortawesome/pro-duotone-svg-icons';
import { faLampStreet as falLampStreet }   from '@fortawesome/pro-light-svg-icons';
import { faLampStreet as farLampStreet }   from '@fortawesome/pro-regular-svg-icons';
import { faLampStreet as fasLampStreet }   from '@fortawesome/pro-solid-svg-icons';
import { faLandmark as fadLandmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faLandmark as falLandmark }   from '@fortawesome/pro-light-svg-icons';
import { faLandmark as farLandmark }   from '@fortawesome/pro-regular-svg-icons';
import { faLandmark as fasLandmark }   from '@fortawesome/pro-solid-svg-icons';
import { faLandmarkAlt as fadLandmarkAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faLandmarkAlt as falLandmarkAlt }   from '@fortawesome/pro-light-svg-icons';
import { faLandmarkAlt as farLandmarkAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faLandmarkAlt as fasLandmarkAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faLandmarkDome as fadLandmarkDome }   from '@fortawesome/pro-duotone-svg-icons';
import { faLandmarkDome as falLandmarkDome }   from '@fortawesome/pro-light-svg-icons';
import { faLandmarkDome as farLandmarkDome }   from '@fortawesome/pro-regular-svg-icons';
import { faLandmarkDome as fasLandmarkDome }   from '@fortawesome/pro-solid-svg-icons';
import { faLandmarkFlag as fadLandmarkFlag }   from '@fortawesome/pro-duotone-svg-icons';
import { faLandmarkFlag as falLandmarkFlag }   from '@fortawesome/pro-light-svg-icons';
import { faLandmarkFlag as farLandmarkFlag }   from '@fortawesome/pro-regular-svg-icons';
import { faLandmarkFlag as fasLandmarkFlag }   from '@fortawesome/pro-solid-svg-icons';
import { faLandMineOn as fadLandMineOn }   from '@fortawesome/pro-duotone-svg-icons';
import { faLandMineOn as falLandMineOn }   from '@fortawesome/pro-light-svg-icons';
import { faLandMineOn as farLandMineOn }   from '@fortawesome/pro-regular-svg-icons';
import { faLandMineOn as fasLandMineOn }   from '@fortawesome/pro-solid-svg-icons';
import { faLandscape as fadLandscape }   from '@fortawesome/pro-duotone-svg-icons';
import { faLandscape as falLandscape }   from '@fortawesome/pro-light-svg-icons';
import { faLandscape as farLandscape }   from '@fortawesome/pro-regular-svg-icons';
import { faLandscape as fasLandscape }   from '@fortawesome/pro-solid-svg-icons';
import { faLanguage as fadLanguage }   from '@fortawesome/pro-duotone-svg-icons';
import { faLanguage as falLanguage }   from '@fortawesome/pro-light-svg-icons';
import { faLanguage as farLanguage }   from '@fortawesome/pro-regular-svg-icons';
import { faLanguage as fasLanguage }   from '@fortawesome/pro-solid-svg-icons';
import { faLaptop as fadLaptop }   from '@fortawesome/pro-duotone-svg-icons';
import { faLaptop as falLaptop }   from '@fortawesome/pro-light-svg-icons';
import { faLaptop as farLaptop }   from '@fortawesome/pro-regular-svg-icons';
import { faLaptop as fasLaptop }   from '@fortawesome/pro-solid-svg-icons';
import { faLaptopArrowDown as fadLaptopArrowDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faLaptopArrowDown as falLaptopArrowDown }   from '@fortawesome/pro-light-svg-icons';
import { faLaptopArrowDown as farLaptopArrowDown }   from '@fortawesome/pro-regular-svg-icons';
import { faLaptopArrowDown as fasLaptopArrowDown }   from '@fortawesome/pro-solid-svg-icons';
import { faLaptopCode as fadLaptopCode }   from '@fortawesome/pro-duotone-svg-icons';
import { faLaptopCode as falLaptopCode }   from '@fortawesome/pro-light-svg-icons';
import { faLaptopCode as farLaptopCode }   from '@fortawesome/pro-regular-svg-icons';
import { faLaptopCode as fasLaptopCode }   from '@fortawesome/pro-solid-svg-icons';
import { faLaptopFile as fadLaptopFile }   from '@fortawesome/pro-duotone-svg-icons';
import { faLaptopFile as falLaptopFile }   from '@fortawesome/pro-light-svg-icons';
import { faLaptopFile as farLaptopFile }   from '@fortawesome/pro-regular-svg-icons';
import { faLaptopFile as fasLaptopFile }   from '@fortawesome/pro-solid-svg-icons';
import { faLaptopHouse as fadLaptopHouse }   from '@fortawesome/pro-duotone-svg-icons';
import { faLaptopHouse as falLaptopHouse }   from '@fortawesome/pro-light-svg-icons';
import { faLaptopHouse as farLaptopHouse }   from '@fortawesome/pro-regular-svg-icons';
import { faLaptopHouse as fasLaptopHouse }   from '@fortawesome/pro-solid-svg-icons';
import { faLaptopMedical as fadLaptopMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faLaptopMedical as falLaptopMedical }   from '@fortawesome/pro-light-svg-icons';
import { faLaptopMedical as farLaptopMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faLaptopMedical as fasLaptopMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faLaptopMobile as fadLaptopMobile }   from '@fortawesome/pro-duotone-svg-icons';
import { faLaptopMobile as falLaptopMobile }   from '@fortawesome/pro-light-svg-icons';
import { faLaptopMobile as farLaptopMobile }   from '@fortawesome/pro-regular-svg-icons';
import { faLaptopMobile as fasLaptopMobile }   from '@fortawesome/pro-solid-svg-icons';
import { faLaptopSlash as fadLaptopSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faLaptopSlash as falLaptopSlash }   from '@fortawesome/pro-light-svg-icons';
import { faLaptopSlash as farLaptopSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faLaptopSlash as fasLaptopSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faLariSign as fadLariSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faLariSign as falLariSign }   from '@fortawesome/pro-light-svg-icons';
import { faLariSign as farLariSign }   from '@fortawesome/pro-regular-svg-icons';
import { faLariSign as fasLariSign }   from '@fortawesome/pro-solid-svg-icons';
import { faLasso as fadLasso }   from '@fortawesome/pro-duotone-svg-icons';
import { faLasso as falLasso }   from '@fortawesome/pro-light-svg-icons';
import { faLasso as farLasso }   from '@fortawesome/pro-regular-svg-icons';
import { faLasso as fasLasso }   from '@fortawesome/pro-solid-svg-icons';
import { faLassoSparkles as fadLassoSparkles }   from '@fortawesome/pro-duotone-svg-icons';
import { faLassoSparkles as falLassoSparkles }   from '@fortawesome/pro-light-svg-icons';
import { faLassoSparkles as farLassoSparkles }   from '@fortawesome/pro-regular-svg-icons';
import { faLassoSparkles as fasLassoSparkles }   from '@fortawesome/pro-solid-svg-icons';
import { faLaugh as fadLaugh }   from '@fortawesome/pro-duotone-svg-icons';
import { faLaugh as falLaugh }   from '@fortawesome/pro-light-svg-icons';
import { faLaugh as farLaugh }   from '@fortawesome/pro-regular-svg-icons';
import { faLaugh as fasLaugh }   from '@fortawesome/pro-solid-svg-icons';
import { faLaughBeam as fadLaughBeam }   from '@fortawesome/pro-duotone-svg-icons';
import { faLaughBeam as falLaughBeam }   from '@fortawesome/pro-light-svg-icons';
import { faLaughBeam as farLaughBeam }   from '@fortawesome/pro-regular-svg-icons';
import { faLaughBeam as fasLaughBeam }   from '@fortawesome/pro-solid-svg-icons';
import { faLaughSquint as fadLaughSquint }   from '@fortawesome/pro-duotone-svg-icons';
import { faLaughSquint as falLaughSquint }   from '@fortawesome/pro-light-svg-icons';
import { faLaughSquint as farLaughSquint }   from '@fortawesome/pro-regular-svg-icons';
import { faLaughSquint as fasLaughSquint }   from '@fortawesome/pro-solid-svg-icons';
import { faLaughWink as fadLaughWink }   from '@fortawesome/pro-duotone-svg-icons';
import { faLaughWink as falLaughWink }   from '@fortawesome/pro-light-svg-icons';
import { faLaughWink as farLaughWink }   from '@fortawesome/pro-regular-svg-icons';
import { faLaughWink as fasLaughWink }   from '@fortawesome/pro-solid-svg-icons';
import { faLayerGroup as fadLayerGroup }   from '@fortawesome/pro-duotone-svg-icons';
import { faLayerGroup as falLayerGroup }   from '@fortawesome/pro-light-svg-icons';
import { faLayerGroup as farLayerGroup }   from '@fortawesome/pro-regular-svg-icons';
import { faLayerGroup as fasLayerGroup }   from '@fortawesome/pro-solid-svg-icons';
import { faLayerGroupMinus as fadLayerGroupMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faLayerGroupMinus as falLayerGroupMinus }   from '@fortawesome/pro-light-svg-icons';
import { faLayerGroupMinus as farLayerGroupMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faLayerGroupMinus as fasLayerGroupMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faLayerGroupPlus as fadLayerGroupPlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faLayerGroupPlus as falLayerGroupPlus }   from '@fortawesome/pro-light-svg-icons';
import { faLayerGroupPlus as farLayerGroupPlus }   from '@fortawesome/pro-regular-svg-icons';
import { faLayerGroupPlus as fasLayerGroupPlus }   from '@fortawesome/pro-solid-svg-icons';
import { faLayerMinus as fadLayerMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faLayerMinus as falLayerMinus }   from '@fortawesome/pro-light-svg-icons';
import { faLayerMinus as farLayerMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faLayerMinus as fasLayerMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faLayerPlus as fadLayerPlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faLayerPlus as falLayerPlus }   from '@fortawesome/pro-light-svg-icons';
import { faLayerPlus as farLayerPlus }   from '@fortawesome/pro-regular-svg-icons';
import { faLayerPlus as fasLayerPlus }   from '@fortawesome/pro-solid-svg-icons';
import { faLeaf as fadLeaf }   from '@fortawesome/pro-duotone-svg-icons';
import { faLeaf as falLeaf }   from '@fortawesome/pro-light-svg-icons';
import { faLeaf as farLeaf }   from '@fortawesome/pro-regular-svg-icons';
import { faLeaf as fasLeaf }   from '@fortawesome/pro-solid-svg-icons';
import { faLeafHeart as fadLeafHeart }   from '@fortawesome/pro-duotone-svg-icons';
import { faLeafHeart as falLeafHeart }   from '@fortawesome/pro-light-svg-icons';
import { faLeafHeart as farLeafHeart }   from '@fortawesome/pro-regular-svg-icons';
import { faLeafHeart as fasLeafHeart }   from '@fortawesome/pro-solid-svg-icons';
import { faLeafMaple as fadLeafMaple }   from '@fortawesome/pro-duotone-svg-icons';
import { faLeafMaple as falLeafMaple }   from '@fortawesome/pro-light-svg-icons';
import { faLeafMaple as farLeafMaple }   from '@fortawesome/pro-regular-svg-icons';
import { faLeafMaple as fasLeafMaple }   from '@fortawesome/pro-solid-svg-icons';
import { faLeafOak as fadLeafOak }   from '@fortawesome/pro-duotone-svg-icons';
import { faLeafOak as falLeafOak }   from '@fortawesome/pro-light-svg-icons';
import { faLeafOak as farLeafOak }   from '@fortawesome/pro-regular-svg-icons';
import { faLeafOak as fasLeafOak }   from '@fortawesome/pro-solid-svg-icons';
import { faLeafyGreen as fadLeafyGreen }   from '@fortawesome/pro-duotone-svg-icons';
import { faLeafyGreen as falLeafyGreen }   from '@fortawesome/pro-light-svg-icons';
import { faLeafyGreen as farLeafyGreen }   from '@fortawesome/pro-regular-svg-icons';
import { faLeafyGreen as fasLeafyGreen }   from '@fortawesome/pro-solid-svg-icons';
import { faLeft as fadLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faLeft as falLeft }   from '@fortawesome/pro-light-svg-icons';
import { faLeft as farLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faLeft as fasLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faLeftFromLine as fadLeftFromLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faLeftFromLine as falLeftFromLine }   from '@fortawesome/pro-light-svg-icons';
import { faLeftFromLine as farLeftFromLine }   from '@fortawesome/pro-regular-svg-icons';
import { faLeftFromLine as fasLeftFromLine }   from '@fortawesome/pro-solid-svg-icons';
import { faLeftLong as fadLeftLong }   from '@fortawesome/pro-duotone-svg-icons';
import { faLeftLong as falLeftLong }   from '@fortawesome/pro-light-svg-icons';
import { faLeftLong as farLeftLong }   from '@fortawesome/pro-regular-svg-icons';
import { faLeftLong as fasLeftLong }   from '@fortawesome/pro-solid-svg-icons';
import { faLeftLongToLine as fadLeftLongToLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faLeftLongToLine as falLeftLongToLine }   from '@fortawesome/pro-light-svg-icons';
import { faLeftLongToLine as farLeftLongToLine }   from '@fortawesome/pro-regular-svg-icons';
import { faLeftLongToLine as fasLeftLongToLine }   from '@fortawesome/pro-solid-svg-icons';
import { faLeftRight as fadLeftRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faLeftRight as falLeftRight }   from '@fortawesome/pro-light-svg-icons';
import { faLeftRight as farLeftRight }   from '@fortawesome/pro-regular-svg-icons';
import { faLeftRight as fasLeftRight }   from '@fortawesome/pro-solid-svg-icons';
import { faLeftToLine as fadLeftToLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faLeftToLine as falLeftToLine }   from '@fortawesome/pro-light-svg-icons';
import { faLeftToLine as farLeftToLine }   from '@fortawesome/pro-regular-svg-icons';
import { faLeftToLine as fasLeftToLine }   from '@fortawesome/pro-solid-svg-icons';
import { faLegal as fadLegal }   from '@fortawesome/pro-duotone-svg-icons';
import { faLegal as falLegal }   from '@fortawesome/pro-light-svg-icons';
import { faLegal as farLegal }   from '@fortawesome/pro-regular-svg-icons';
import { faLegal as fasLegal }   from '@fortawesome/pro-solid-svg-icons';
import { faLemon as fadLemon }   from '@fortawesome/pro-duotone-svg-icons';
import { faLemon as falLemon }   from '@fortawesome/pro-light-svg-icons';
import { faLemon as farLemon }   from '@fortawesome/pro-regular-svg-icons';
import { faLemon as fasLemon }   from '@fortawesome/pro-solid-svg-icons';
import { faLessThan as fadLessThan }   from '@fortawesome/pro-duotone-svg-icons';
import { faLessThan as falLessThan }   from '@fortawesome/pro-light-svg-icons';
import { faLessThan as farLessThan }   from '@fortawesome/pro-regular-svg-icons';
import { faLessThan as fasLessThan }   from '@fortawesome/pro-solid-svg-icons';
import { faLessThanEqual as fadLessThanEqual }   from '@fortawesome/pro-duotone-svg-icons';
import { faLessThanEqual as falLessThanEqual }   from '@fortawesome/pro-light-svg-icons';
import { faLessThanEqual as farLessThanEqual }   from '@fortawesome/pro-regular-svg-icons';
import { faLessThanEqual as fasLessThanEqual }   from '@fortawesome/pro-solid-svg-icons';
import { faLevelDown as fadLevelDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faLevelDown as falLevelDown }   from '@fortawesome/pro-light-svg-icons';
import { faLevelDown as farLevelDown }   from '@fortawesome/pro-regular-svg-icons';
import { faLevelDown as fasLevelDown }   from '@fortawesome/pro-solid-svg-icons';
import { faLevelDownAlt as fadLevelDownAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faLevelDownAlt as falLevelDownAlt }   from '@fortawesome/pro-light-svg-icons';
import { faLevelDownAlt as farLevelDownAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faLevelDownAlt as fasLevelDownAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faLevelUp as fadLevelUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faLevelUp as falLevelUp }   from '@fortawesome/pro-light-svg-icons';
import { faLevelUp as farLevelUp }   from '@fortawesome/pro-regular-svg-icons';
import { faLevelUp as fasLevelUp }   from '@fortawesome/pro-solid-svg-icons';
import { faLevelUpAlt as fadLevelUpAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faLevelUpAlt as falLevelUpAlt }   from '@fortawesome/pro-light-svg-icons';
import { faLevelUpAlt as farLevelUpAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faLevelUpAlt as fasLevelUpAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faLifeRing as fadLifeRing }   from '@fortawesome/pro-duotone-svg-icons';
import { faLifeRing as falLifeRing }   from '@fortawesome/pro-light-svg-icons';
import { faLifeRing as farLifeRing }   from '@fortawesome/pro-regular-svg-icons';
import { faLifeRing as fasLifeRing }   from '@fortawesome/pro-solid-svg-icons';
import { faLightbulb as fadLightbulb }   from '@fortawesome/pro-duotone-svg-icons';
import { faLightbulb as falLightbulb }   from '@fortawesome/pro-light-svg-icons';
import { faLightbulb as farLightbulb }   from '@fortawesome/pro-regular-svg-icons';
import { faLightbulb as fasLightbulb }   from '@fortawesome/pro-solid-svg-icons';
import { faLightbulbDollar as fadLightbulbDollar }   from '@fortawesome/pro-duotone-svg-icons';
import { faLightbulbDollar as falLightbulbDollar }   from '@fortawesome/pro-light-svg-icons';
import { faLightbulbDollar as farLightbulbDollar }   from '@fortawesome/pro-regular-svg-icons';
import { faLightbulbDollar as fasLightbulbDollar }   from '@fortawesome/pro-solid-svg-icons';
import { faLightbulbExclamation as fadLightbulbExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faLightbulbExclamation as falLightbulbExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faLightbulbExclamation as farLightbulbExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faLightbulbExclamation as fasLightbulbExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faLightbulbExclamationOn as fadLightbulbExclamationOn }   from '@fortawesome/pro-duotone-svg-icons';
import { faLightbulbExclamationOn as falLightbulbExclamationOn }   from '@fortawesome/pro-light-svg-icons';
import { faLightbulbExclamationOn as farLightbulbExclamationOn }   from '@fortawesome/pro-regular-svg-icons';
import { faLightbulbExclamationOn as fasLightbulbExclamationOn }   from '@fortawesome/pro-solid-svg-icons';
import { faLightbulbOn as fadLightbulbOn }   from '@fortawesome/pro-duotone-svg-icons';
import { faLightbulbOn as falLightbulbOn }   from '@fortawesome/pro-light-svg-icons';
import { faLightbulbOn as farLightbulbOn }   from '@fortawesome/pro-regular-svg-icons';
import { faLightbulbOn as fasLightbulbOn }   from '@fortawesome/pro-solid-svg-icons';
import { faLightbulbSlash as fadLightbulbSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faLightbulbSlash as falLightbulbSlash }   from '@fortawesome/pro-light-svg-icons';
import { faLightbulbSlash as farLightbulbSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faLightbulbSlash as fasLightbulbSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faLightCeiling as fadLightCeiling }   from '@fortawesome/pro-duotone-svg-icons';
import { faLightCeiling as falLightCeiling }   from '@fortawesome/pro-light-svg-icons';
import { faLightCeiling as farLightCeiling }   from '@fortawesome/pro-regular-svg-icons';
import { faLightCeiling as fasLightCeiling }   from '@fortawesome/pro-solid-svg-icons';
import { faLightEmergency as fadLightEmergency }   from '@fortawesome/pro-duotone-svg-icons';
import { faLightEmergency as falLightEmergency }   from '@fortawesome/pro-light-svg-icons';
import { faLightEmergency as farLightEmergency }   from '@fortawesome/pro-regular-svg-icons';
import { faLightEmergency as fasLightEmergency }   from '@fortawesome/pro-solid-svg-icons';
import { faLightEmergencyOn as fadLightEmergencyOn }   from '@fortawesome/pro-duotone-svg-icons';
import { faLightEmergencyOn as falLightEmergencyOn }   from '@fortawesome/pro-light-svg-icons';
import { faLightEmergencyOn as farLightEmergencyOn }   from '@fortawesome/pro-regular-svg-icons';
import { faLightEmergencyOn as fasLightEmergencyOn }   from '@fortawesome/pro-solid-svg-icons';
import { faLightsHoliday as fadLightsHoliday }   from '@fortawesome/pro-duotone-svg-icons';
import { faLightsHoliday as falLightsHoliday }   from '@fortawesome/pro-light-svg-icons';
import { faLightsHoliday as farLightsHoliday }   from '@fortawesome/pro-regular-svg-icons';
import { faLightsHoliday as fasLightsHoliday }   from '@fortawesome/pro-solid-svg-icons';
import { faLightSwitch as fadLightSwitch }   from '@fortawesome/pro-duotone-svg-icons';
import { faLightSwitch as falLightSwitch }   from '@fortawesome/pro-light-svg-icons';
import { faLightSwitch as farLightSwitch }   from '@fortawesome/pro-regular-svg-icons';
import { faLightSwitch as fasLightSwitch }   from '@fortawesome/pro-solid-svg-icons';
import { faLightSwitchOff as fadLightSwitchOff }   from '@fortawesome/pro-duotone-svg-icons';
import { faLightSwitchOff as falLightSwitchOff }   from '@fortawesome/pro-light-svg-icons';
import { faLightSwitchOff as farLightSwitchOff }   from '@fortawesome/pro-regular-svg-icons';
import { faLightSwitchOff as fasLightSwitchOff }   from '@fortawesome/pro-solid-svg-icons';
import { faLightSwitchOn as fadLightSwitchOn }   from '@fortawesome/pro-duotone-svg-icons';
import { faLightSwitchOn as falLightSwitchOn }   from '@fortawesome/pro-light-svg-icons';
import { faLightSwitchOn as farLightSwitchOn }   from '@fortawesome/pro-regular-svg-icons';
import { faLightSwitchOn as fasLightSwitchOn }   from '@fortawesome/pro-solid-svg-icons';
import { faLineChart as fadLineChart }   from '@fortawesome/pro-duotone-svg-icons';
import { faLineChart as falLineChart }   from '@fortawesome/pro-light-svg-icons';
import { faLineChart as farLineChart }   from '@fortawesome/pro-regular-svg-icons';
import { faLineChart as fasLineChart }   from '@fortawesome/pro-solid-svg-icons';
import { faLineColumns as fadLineColumns }   from '@fortawesome/pro-duotone-svg-icons';
import { faLineColumns as falLineColumns }   from '@fortawesome/pro-light-svg-icons';
import { faLineColumns as farLineColumns }   from '@fortawesome/pro-regular-svg-icons';
import { faLineColumns as fasLineColumns }   from '@fortawesome/pro-solid-svg-icons';
import { faLineHeight as fadLineHeight }   from '@fortawesome/pro-duotone-svg-icons';
import { faLineHeight as falLineHeight }   from '@fortawesome/pro-light-svg-icons';
import { faLineHeight as farLineHeight }   from '@fortawesome/pro-regular-svg-icons';
import { faLineHeight as fasLineHeight }   from '@fortawesome/pro-solid-svg-icons';
import { faLinesLeaning as fadLinesLeaning }   from '@fortawesome/pro-duotone-svg-icons';
import { faLinesLeaning as falLinesLeaning }   from '@fortawesome/pro-light-svg-icons';
import { faLinesLeaning as farLinesLeaning }   from '@fortawesome/pro-regular-svg-icons';
import { faLinesLeaning as fasLinesLeaning }   from '@fortawesome/pro-solid-svg-icons';
import { faLink as fadLink }   from '@fortawesome/pro-duotone-svg-icons';
import { faLink as falLink }   from '@fortawesome/pro-light-svg-icons';
import { faLink as farLink }   from '@fortawesome/pro-regular-svg-icons';
import { faLink as fasLink }   from '@fortawesome/pro-solid-svg-icons';
import { faLinkHorizontal as fadLinkHorizontal }   from '@fortawesome/pro-duotone-svg-icons';
import { faLinkHorizontal as falLinkHorizontal }   from '@fortawesome/pro-light-svg-icons';
import { faLinkHorizontal as farLinkHorizontal }   from '@fortawesome/pro-regular-svg-icons';
import { faLinkHorizontal as fasLinkHorizontal }   from '@fortawesome/pro-solid-svg-icons';
import { faLinkHorizontalSlash as fadLinkHorizontalSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faLinkHorizontalSlash as falLinkHorizontalSlash }   from '@fortawesome/pro-light-svg-icons';
import { faLinkHorizontalSlash as farLinkHorizontalSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faLinkHorizontalSlash as fasLinkHorizontalSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faLinkSimple as fadLinkSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faLinkSimple as falLinkSimple }   from '@fortawesome/pro-light-svg-icons';
import { faLinkSimple as farLinkSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faLinkSimple as fasLinkSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faLinkSimpleSlash as fadLinkSimpleSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faLinkSimpleSlash as falLinkSimpleSlash }   from '@fortawesome/pro-light-svg-icons';
import { faLinkSimpleSlash as farLinkSimpleSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faLinkSimpleSlash as fasLinkSimpleSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faLinkSlash as fadLinkSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faLinkSlash as falLinkSlash }   from '@fortawesome/pro-light-svg-icons';
import { faLinkSlash as farLinkSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faLinkSlash as fasLinkSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faLips as fadLips }   from '@fortawesome/pro-duotone-svg-icons';
import { faLips as falLips }   from '@fortawesome/pro-light-svg-icons';
import { faLips as farLips }   from '@fortawesome/pro-regular-svg-icons';
import { faLips as fasLips }   from '@fortawesome/pro-solid-svg-icons';
import { faLiraSign as fadLiraSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faLiraSign as falLiraSign }   from '@fortawesome/pro-light-svg-icons';
import { faLiraSign as farLiraSign }   from '@fortawesome/pro-regular-svg-icons';
import { faLiraSign as fasLiraSign }   from '@fortawesome/pro-solid-svg-icons';
import { faList as fadList }   from '@fortawesome/pro-duotone-svg-icons';
import { faList as falList }   from '@fortawesome/pro-light-svg-icons';
import { faList as farList }   from '@fortawesome/pro-regular-svg-icons';
import { faList as fasList }   from '@fortawesome/pro-solid-svg-icons';
import { faList12 as fadList12 }   from '@fortawesome/pro-duotone-svg-icons';
import { faList12 as falList12 }   from '@fortawesome/pro-light-svg-icons';
import { faList12 as farList12 }   from '@fortawesome/pro-regular-svg-icons';
import { faList12 as fasList12 }   from '@fortawesome/pro-solid-svg-icons';
import { faListAlt as fadListAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faListAlt as falListAlt }   from '@fortawesome/pro-light-svg-icons';
import { faListAlt as farListAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faListAlt as fasListAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faListCheck as fadListCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faListCheck as falListCheck }   from '@fortawesome/pro-light-svg-icons';
import { faListCheck as farListCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faListCheck as fasListCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faListDots as fadListDots }   from '@fortawesome/pro-duotone-svg-icons';
import { faListDots as falListDots }   from '@fortawesome/pro-light-svg-icons';
import { faListDots as farListDots }   from '@fortawesome/pro-regular-svg-icons';
import { faListDots as fasListDots }   from '@fortawesome/pro-solid-svg-icons';
import { faListDropdown as fadListDropdown }   from '@fortawesome/pro-duotone-svg-icons';
import { faListDropdown as falListDropdown }   from '@fortawesome/pro-light-svg-icons';
import { faListDropdown as farListDropdown }   from '@fortawesome/pro-regular-svg-icons';
import { faListDropdown as fasListDropdown }   from '@fortawesome/pro-solid-svg-icons';
import { faListMusic as fadListMusic }   from '@fortawesome/pro-duotone-svg-icons';
import { faListMusic as falListMusic }   from '@fortawesome/pro-light-svg-icons';
import { faListMusic as farListMusic }   from '@fortawesome/pro-regular-svg-icons';
import { faListMusic as fasListMusic }   from '@fortawesome/pro-solid-svg-icons';
import { faListNumeric as fadListNumeric }   from '@fortawesome/pro-duotone-svg-icons';
import { faListNumeric as falListNumeric }   from '@fortawesome/pro-light-svg-icons';
import { faListNumeric as farListNumeric }   from '@fortawesome/pro-regular-svg-icons';
import { faListNumeric as fasListNumeric }   from '@fortawesome/pro-solid-svg-icons';
import { faListOl as fadListOl }   from '@fortawesome/pro-duotone-svg-icons';
import { faListOl as falListOl }   from '@fortawesome/pro-light-svg-icons';
import { faListOl as farListOl }   from '@fortawesome/pro-regular-svg-icons';
import { faListOl as fasListOl }   from '@fortawesome/pro-solid-svg-icons';
import { faListRadio as fadListRadio }   from '@fortawesome/pro-duotone-svg-icons';
import { faListRadio as falListRadio }   from '@fortawesome/pro-light-svg-icons';
import { faListRadio as farListRadio }   from '@fortawesome/pro-regular-svg-icons';
import { faListRadio as fasListRadio }   from '@fortawesome/pro-solid-svg-icons';
import { faListSquares as fadListSquares }   from '@fortawesome/pro-duotone-svg-icons';
import { faListSquares as falListSquares }   from '@fortawesome/pro-light-svg-icons';
import { faListSquares as farListSquares }   from '@fortawesome/pro-regular-svg-icons';
import { faListSquares as fasListSquares }   from '@fortawesome/pro-solid-svg-icons';
import { faListTimeline as fadListTimeline }   from '@fortawesome/pro-duotone-svg-icons';
import { faListTimeline as falListTimeline }   from '@fortawesome/pro-light-svg-icons';
import { faListTimeline as farListTimeline }   from '@fortawesome/pro-regular-svg-icons';
import { faListTimeline as fasListTimeline }   from '@fortawesome/pro-solid-svg-icons';
import { faListTree as fadListTree }   from '@fortawesome/pro-duotone-svg-icons';
import { faListTree as falListTree }   from '@fortawesome/pro-light-svg-icons';
import { faListTree as farListTree }   from '@fortawesome/pro-regular-svg-icons';
import { faListTree as fasListTree }   from '@fortawesome/pro-solid-svg-icons';
import { faListUl as fadListUl }   from '@fortawesome/pro-duotone-svg-icons';
import { faListUl as falListUl }   from '@fortawesome/pro-light-svg-icons';
import { faListUl as farListUl }   from '@fortawesome/pro-regular-svg-icons';
import { faListUl as fasListUl }   from '@fortawesome/pro-solid-svg-icons';
import { faLitecoinSign as fadLitecoinSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faLitecoinSign as falLitecoinSign }   from '@fortawesome/pro-light-svg-icons';
import { faLitecoinSign as farLitecoinSign }   from '@fortawesome/pro-regular-svg-icons';
import { faLitecoinSign as fasLitecoinSign }   from '@fortawesome/pro-solid-svg-icons';
import { faLoader as fadLoader }   from '@fortawesome/pro-duotone-svg-icons';
import { faLoader as falLoader }   from '@fortawesome/pro-light-svg-icons';
import { faLoader as farLoader }   from '@fortawesome/pro-regular-svg-icons';
import { faLoader as fasLoader }   from '@fortawesome/pro-solid-svg-icons';
import { faLobster as fadLobster }   from '@fortawesome/pro-duotone-svg-icons';
import { faLobster as falLobster }   from '@fortawesome/pro-light-svg-icons';
import { faLobster as farLobster }   from '@fortawesome/pro-regular-svg-icons';
import { faLobster as fasLobster }   from '@fortawesome/pro-solid-svg-icons';
import { faLocation as fadLocation }   from '@fortawesome/pro-duotone-svg-icons';
import { faLocation as falLocation }   from '@fortawesome/pro-light-svg-icons';
import { faLocation as farLocation }   from '@fortawesome/pro-regular-svg-icons';
import { faLocation as fasLocation }   from '@fortawesome/pro-solid-svg-icons';
import { faLocationArrow as fadLocationArrow }   from '@fortawesome/pro-duotone-svg-icons';
import { faLocationArrow as falLocationArrow }   from '@fortawesome/pro-light-svg-icons';
import { faLocationArrow as farLocationArrow }   from '@fortawesome/pro-regular-svg-icons';
import { faLocationArrow as fasLocationArrow }   from '@fortawesome/pro-solid-svg-icons';
import { faLocationCheck as fadLocationCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faLocationCheck as falLocationCheck }   from '@fortawesome/pro-light-svg-icons';
import { faLocationCheck as farLocationCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faLocationCheck as fasLocationCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faLocationCircle as fadLocationCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faLocationCircle as falLocationCircle }   from '@fortawesome/pro-light-svg-icons';
import { faLocationCircle as farLocationCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faLocationCircle as fasLocationCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faLocationCrosshairs as fadLocationCrosshairs }   from '@fortawesome/pro-duotone-svg-icons';
import { faLocationCrosshairs as falLocationCrosshairs }   from '@fortawesome/pro-light-svg-icons';
import { faLocationCrosshairs as farLocationCrosshairs }   from '@fortawesome/pro-regular-svg-icons';
import { faLocationCrosshairs as fasLocationCrosshairs }   from '@fortawesome/pro-solid-svg-icons';
import { faLocationCrosshairsSlash as fadLocationCrosshairsSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faLocationCrosshairsSlash as falLocationCrosshairsSlash }   from '@fortawesome/pro-light-svg-icons';
import { faLocationCrosshairsSlash as farLocationCrosshairsSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faLocationCrosshairsSlash as fasLocationCrosshairsSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faLocationDot as fadLocationDot }   from '@fortawesome/pro-duotone-svg-icons';
import { faLocationDot as falLocationDot }   from '@fortawesome/pro-light-svg-icons';
import { faLocationDot as farLocationDot }   from '@fortawesome/pro-regular-svg-icons';
import { faLocationDot as fasLocationDot }   from '@fortawesome/pro-solid-svg-icons';
import { faLocationDotSlash as fadLocationDotSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faLocationDotSlash as falLocationDotSlash }   from '@fortawesome/pro-light-svg-icons';
import { faLocationDotSlash as farLocationDotSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faLocationDotSlash as fasLocationDotSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faLocationExclamation as fadLocationExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faLocationExclamation as falLocationExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faLocationExclamation as farLocationExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faLocationExclamation as fasLocationExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faLocationMinus as fadLocationMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faLocationMinus as falLocationMinus }   from '@fortawesome/pro-light-svg-icons';
import { faLocationMinus as farLocationMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faLocationMinus as fasLocationMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faLocationPen as fadLocationPen }   from '@fortawesome/pro-duotone-svg-icons';
import { faLocationPen as falLocationPen }   from '@fortawesome/pro-light-svg-icons';
import { faLocationPen as farLocationPen }   from '@fortawesome/pro-regular-svg-icons';
import { faLocationPen as fasLocationPen }   from '@fortawesome/pro-solid-svg-icons';
import { faLocationPin as fadLocationPin }   from '@fortawesome/pro-duotone-svg-icons';
import { faLocationPin as falLocationPin }   from '@fortawesome/pro-light-svg-icons';
import { faLocationPin as farLocationPin }   from '@fortawesome/pro-regular-svg-icons';
import { faLocationPin as fasLocationPin }   from '@fortawesome/pro-solid-svg-icons';
import { faLocationPinLock as fadLocationPinLock }   from '@fortawesome/pro-duotone-svg-icons';
import { faLocationPinLock as falLocationPinLock }   from '@fortawesome/pro-light-svg-icons';
import { faLocationPinLock as farLocationPinLock }   from '@fortawesome/pro-regular-svg-icons';
import { faLocationPinLock as fasLocationPinLock }   from '@fortawesome/pro-solid-svg-icons';
import { faLocationPinSlash as fadLocationPinSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faLocationPinSlash as falLocationPinSlash }   from '@fortawesome/pro-light-svg-icons';
import { faLocationPinSlash as farLocationPinSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faLocationPinSlash as fasLocationPinSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faLocationPlus as fadLocationPlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faLocationPlus as falLocationPlus }   from '@fortawesome/pro-light-svg-icons';
import { faLocationPlus as farLocationPlus }   from '@fortawesome/pro-regular-svg-icons';
import { faLocationPlus as fasLocationPlus }   from '@fortawesome/pro-solid-svg-icons';
import { faLocationQuestion as fadLocationQuestion }   from '@fortawesome/pro-duotone-svg-icons';
import { faLocationQuestion as falLocationQuestion }   from '@fortawesome/pro-light-svg-icons';
import { faLocationQuestion as farLocationQuestion }   from '@fortawesome/pro-regular-svg-icons';
import { faLocationQuestion as fasLocationQuestion }   from '@fortawesome/pro-solid-svg-icons';
import { faLocationSlash as fadLocationSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faLocationSlash as falLocationSlash }   from '@fortawesome/pro-light-svg-icons';
import { faLocationSlash as farLocationSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faLocationSlash as fasLocationSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faLocationSmile as fadLocationSmile }   from '@fortawesome/pro-duotone-svg-icons';
import { faLocationSmile as falLocationSmile }   from '@fortawesome/pro-light-svg-icons';
import { faLocationSmile as farLocationSmile }   from '@fortawesome/pro-regular-svg-icons';
import { faLocationSmile as fasLocationSmile }   from '@fortawesome/pro-solid-svg-icons';
import { faLocationXmark as fadLocationXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faLocationXmark as falLocationXmark }   from '@fortawesome/pro-light-svg-icons';
import { faLocationXmark as farLocationXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faLocationXmark as fasLocationXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faLock as fadLock }   from '@fortawesome/pro-duotone-svg-icons';
import { faLock as falLock }   from '@fortawesome/pro-light-svg-icons';
import { faLock as farLock }   from '@fortawesome/pro-regular-svg-icons';
import { faLock as fasLock }   from '@fortawesome/pro-solid-svg-icons';
import { faLockA as fadLockA }   from '@fortawesome/pro-duotone-svg-icons';
import { faLockA as falLockA }   from '@fortawesome/pro-light-svg-icons';
import { faLockA as farLockA }   from '@fortawesome/pro-regular-svg-icons';
import { faLockA as fasLockA }   from '@fortawesome/pro-solid-svg-icons';
import { faLockAlt as fadLockAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faLockAlt as falLockAlt }   from '@fortawesome/pro-light-svg-icons';
import { faLockAlt as farLockAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faLockAlt as fasLockAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faLockHashtag as fadLockHashtag }   from '@fortawesome/pro-duotone-svg-icons';
import { faLockHashtag as falLockHashtag }   from '@fortawesome/pro-light-svg-icons';
import { faLockHashtag as farLockHashtag }   from '@fortawesome/pro-regular-svg-icons';
import { faLockHashtag as fasLockHashtag }   from '@fortawesome/pro-solid-svg-icons';
import { faLockKeyhole as fadLockKeyhole }   from '@fortawesome/pro-duotone-svg-icons';
import { faLockKeyhole as falLockKeyhole }   from '@fortawesome/pro-light-svg-icons';
import { faLockKeyhole as farLockKeyhole }   from '@fortawesome/pro-regular-svg-icons';
import { faLockKeyhole as fasLockKeyhole }   from '@fortawesome/pro-solid-svg-icons';
import { faLockKeyholeOpen as fadLockKeyholeOpen }   from '@fortawesome/pro-duotone-svg-icons';
import { faLockKeyholeOpen as falLockKeyholeOpen }   from '@fortawesome/pro-light-svg-icons';
import { faLockKeyholeOpen as farLockKeyholeOpen }   from '@fortawesome/pro-regular-svg-icons';
import { faLockKeyholeOpen as fasLockKeyholeOpen }   from '@fortawesome/pro-solid-svg-icons';
import { faLockOpen as fadLockOpen }   from '@fortawesome/pro-duotone-svg-icons';
import { faLockOpen as falLockOpen }   from '@fortawesome/pro-light-svg-icons';
import { faLockOpen as farLockOpen }   from '@fortawesome/pro-regular-svg-icons';
import { faLockOpen as fasLockOpen }   from '@fortawesome/pro-solid-svg-icons';
import { faLockOpenAlt as fadLockOpenAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faLockOpenAlt as falLockOpenAlt }   from '@fortawesome/pro-light-svg-icons';
import { faLockOpenAlt as farLockOpenAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faLockOpenAlt as fasLockOpenAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faLocust as fadLocust }   from '@fortawesome/pro-duotone-svg-icons';
import { faLocust as falLocust }   from '@fortawesome/pro-light-svg-icons';
import { faLocust as farLocust }   from '@fortawesome/pro-regular-svg-icons';
import { faLocust as fasLocust }   from '@fortawesome/pro-solid-svg-icons';
import { faLollipop as fadLollipop }   from '@fortawesome/pro-duotone-svg-icons';
import { faLollipop as falLollipop }   from '@fortawesome/pro-light-svg-icons';
import { faLollipop as farLollipop }   from '@fortawesome/pro-regular-svg-icons';
import { faLollipop as fasLollipop }   from '@fortawesome/pro-solid-svg-icons';
import { faLollypop as fadLollypop }   from '@fortawesome/pro-duotone-svg-icons';
import { faLollypop as falLollypop }   from '@fortawesome/pro-light-svg-icons';
import { faLollypop as farLollypop }   from '@fortawesome/pro-regular-svg-icons';
import { faLollypop as fasLollypop }   from '@fortawesome/pro-solid-svg-icons';
import { faLongArrowAltDown as fadLongArrowAltDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faLongArrowAltDown as falLongArrowAltDown }   from '@fortawesome/pro-light-svg-icons';
import { faLongArrowAltDown as farLongArrowAltDown }   from '@fortawesome/pro-regular-svg-icons';
import { faLongArrowAltDown as fasLongArrowAltDown }   from '@fortawesome/pro-solid-svg-icons';
import { faLongArrowAltLeft as fadLongArrowAltLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faLongArrowAltLeft as falLongArrowAltLeft }   from '@fortawesome/pro-light-svg-icons';
import { faLongArrowAltLeft as farLongArrowAltLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faLongArrowAltLeft as fasLongArrowAltLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faLongArrowAltRight as fadLongArrowAltRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faLongArrowAltRight as falLongArrowAltRight }   from '@fortawesome/pro-light-svg-icons';
import { faLongArrowAltRight as farLongArrowAltRight }   from '@fortawesome/pro-regular-svg-icons';
import { faLongArrowAltRight as fasLongArrowAltRight }   from '@fortawesome/pro-solid-svg-icons';
import { faLongArrowAltUp as fadLongArrowAltUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faLongArrowAltUp as falLongArrowAltUp }   from '@fortawesome/pro-light-svg-icons';
import { faLongArrowAltUp as farLongArrowAltUp }   from '@fortawesome/pro-regular-svg-icons';
import { faLongArrowAltUp as fasLongArrowAltUp }   from '@fortawesome/pro-solid-svg-icons';
import { faLongArrowDown as fadLongArrowDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faLongArrowDown as falLongArrowDown }   from '@fortawesome/pro-light-svg-icons';
import { faLongArrowDown as farLongArrowDown }   from '@fortawesome/pro-regular-svg-icons';
import { faLongArrowDown as fasLongArrowDown }   from '@fortawesome/pro-solid-svg-icons';
import { faLongArrowLeft as fadLongArrowLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faLongArrowLeft as falLongArrowLeft }   from '@fortawesome/pro-light-svg-icons';
import { faLongArrowLeft as farLongArrowLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faLongArrowLeft as fasLongArrowLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faLongArrowRight as fadLongArrowRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faLongArrowRight as falLongArrowRight }   from '@fortawesome/pro-light-svg-icons';
import { faLongArrowRight as farLongArrowRight }   from '@fortawesome/pro-regular-svg-icons';
import { faLongArrowRight as fasLongArrowRight }   from '@fortawesome/pro-solid-svg-icons';
import { faLongArrowUp as fadLongArrowUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faLongArrowUp as falLongArrowUp }   from '@fortawesome/pro-light-svg-icons';
import { faLongArrowUp as farLongArrowUp }   from '@fortawesome/pro-regular-svg-icons';
import { faLongArrowUp as fasLongArrowUp }   from '@fortawesome/pro-solid-svg-icons';
import { faLoveseat as fadLoveseat }   from '@fortawesome/pro-duotone-svg-icons';
import { faLoveseat as falLoveseat }   from '@fortawesome/pro-light-svg-icons';
import { faLoveseat as farLoveseat }   from '@fortawesome/pro-regular-svg-icons';
import { faLoveseat as fasLoveseat }   from '@fortawesome/pro-solid-svg-icons';
import { faLowVision as fadLowVision }   from '@fortawesome/pro-duotone-svg-icons';
import { faLowVision as falLowVision }   from '@fortawesome/pro-light-svg-icons';
import { faLowVision as farLowVision }   from '@fortawesome/pro-regular-svg-icons';
import { faLowVision as fasLowVision }   from '@fortawesome/pro-solid-svg-icons';
import { faLuchador as fadLuchador }   from '@fortawesome/pro-duotone-svg-icons';
import { faLuchador as falLuchador }   from '@fortawesome/pro-light-svg-icons';
import { faLuchador as farLuchador }   from '@fortawesome/pro-regular-svg-icons';
import { faLuchador as fasLuchador }   from '@fortawesome/pro-solid-svg-icons';
import { faLuchadorMask as fadLuchadorMask }   from '@fortawesome/pro-duotone-svg-icons';
import { faLuchadorMask as falLuchadorMask }   from '@fortawesome/pro-light-svg-icons';
import { faLuchadorMask as farLuchadorMask }   from '@fortawesome/pro-regular-svg-icons';
import { faLuchadorMask as fasLuchadorMask }   from '@fortawesome/pro-solid-svg-icons';
import { faLuggageCart as fadLuggageCart }   from '@fortawesome/pro-duotone-svg-icons';
import { faLuggageCart as falLuggageCart }   from '@fortawesome/pro-light-svg-icons';
import { faLuggageCart as farLuggageCart }   from '@fortawesome/pro-regular-svg-icons';
import { faLuggageCart as fasLuggageCart }   from '@fortawesome/pro-solid-svg-icons';
import { faLungs as fadLungs }   from '@fortawesome/pro-duotone-svg-icons';
import { faLungs as falLungs }   from '@fortawesome/pro-light-svg-icons';
import { faLungs as farLungs }   from '@fortawesome/pro-regular-svg-icons';
import { faLungs as fasLungs }   from '@fortawesome/pro-solid-svg-icons';
import { faLungsVirus as fadLungsVirus }   from '@fortawesome/pro-duotone-svg-icons';
import { faLungsVirus as falLungsVirus }   from '@fortawesome/pro-light-svg-icons';
import { faLungsVirus as farLungsVirus }   from '@fortawesome/pro-regular-svg-icons';
import { faLungsVirus as fasLungsVirus }   from '@fortawesome/pro-solid-svg-icons';
import { faM as fadM }   from '@fortawesome/pro-duotone-svg-icons';
import { faM as falM }   from '@fortawesome/pro-light-svg-icons';
import { faM as farM }   from '@fortawesome/pro-regular-svg-icons';
import { faM as fasM }   from '@fortawesome/pro-solid-svg-icons';
import { faMace as fadMace }   from '@fortawesome/pro-duotone-svg-icons';
import { faMace as falMace }   from '@fortawesome/pro-light-svg-icons';
import { faMace as farMace }   from '@fortawesome/pro-regular-svg-icons';
import { faMace as fasMace }   from '@fortawesome/pro-solid-svg-icons';
import { faMagic as fadMagic }   from '@fortawesome/pro-duotone-svg-icons';
import { faMagic as falMagic }   from '@fortawesome/pro-light-svg-icons';
import { faMagic as farMagic }   from '@fortawesome/pro-regular-svg-icons';
import { faMagic as fasMagic }   from '@fortawesome/pro-solid-svg-icons';
import { faMagicWandSparkles as fadMagicWandSparkles }   from '@fortawesome/pro-duotone-svg-icons';
import { faMagicWandSparkles as falMagicWandSparkles }   from '@fortawesome/pro-light-svg-icons';
import { faMagicWandSparkles as farMagicWandSparkles }   from '@fortawesome/pro-regular-svg-icons';
import { faMagicWandSparkles as fasMagicWandSparkles }   from '@fortawesome/pro-solid-svg-icons';
import { faMagnet as fadMagnet }   from '@fortawesome/pro-duotone-svg-icons';
import { faMagnet as falMagnet }   from '@fortawesome/pro-light-svg-icons';
import { faMagnet as farMagnet }   from '@fortawesome/pro-regular-svg-icons';
import { faMagnet as fasMagnet }   from '@fortawesome/pro-solid-svg-icons';
import { faMagnifyingGlass as fadMagnifyingGlass }   from '@fortawesome/pro-duotone-svg-icons';
import { faMagnifyingGlass as falMagnifyingGlass }   from '@fortawesome/pro-light-svg-icons';
import { faMagnifyingGlass as farMagnifyingGlass }   from '@fortawesome/pro-regular-svg-icons';
import { faMagnifyingGlass as fasMagnifyingGlass }   from '@fortawesome/pro-solid-svg-icons';
import { faMagnifyingGlassArrowRight as fadMagnifyingGlassArrowRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faMagnifyingGlassArrowRight as falMagnifyingGlassArrowRight }   from '@fortawesome/pro-light-svg-icons';
import { faMagnifyingGlassArrowRight as farMagnifyingGlassArrowRight }   from '@fortawesome/pro-regular-svg-icons';
import { faMagnifyingGlassArrowRight as fasMagnifyingGlassArrowRight }   from '@fortawesome/pro-solid-svg-icons';
import { faMagnifyingGlassChart as fadMagnifyingGlassChart }   from '@fortawesome/pro-duotone-svg-icons';
import { faMagnifyingGlassChart as falMagnifyingGlassChart }   from '@fortawesome/pro-light-svg-icons';
import { faMagnifyingGlassChart as farMagnifyingGlassChart }   from '@fortawesome/pro-regular-svg-icons';
import { faMagnifyingGlassChart as fasMagnifyingGlassChart }   from '@fortawesome/pro-solid-svg-icons';
import { faMagnifyingGlassDollar as fadMagnifyingGlassDollar }   from '@fortawesome/pro-duotone-svg-icons';
import { faMagnifyingGlassDollar as falMagnifyingGlassDollar }   from '@fortawesome/pro-light-svg-icons';
import { faMagnifyingGlassDollar as farMagnifyingGlassDollar }   from '@fortawesome/pro-regular-svg-icons';
import { faMagnifyingGlassDollar as fasMagnifyingGlassDollar }   from '@fortawesome/pro-solid-svg-icons';
import { faMagnifyingGlassLocation as fadMagnifyingGlassLocation }   from '@fortawesome/pro-duotone-svg-icons';
import { faMagnifyingGlassLocation as falMagnifyingGlassLocation }   from '@fortawesome/pro-light-svg-icons';
import { faMagnifyingGlassLocation as farMagnifyingGlassLocation }   from '@fortawesome/pro-regular-svg-icons';
import { faMagnifyingGlassLocation as fasMagnifyingGlassLocation }   from '@fortawesome/pro-solid-svg-icons';
import { faMagnifyingGlassMinus as fadMagnifyingGlassMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faMagnifyingGlassMinus as falMagnifyingGlassMinus }   from '@fortawesome/pro-light-svg-icons';
import { faMagnifyingGlassMinus as farMagnifyingGlassMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faMagnifyingGlassMinus as fasMagnifyingGlassMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faMagnifyingGlassPlus as fadMagnifyingGlassPlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faMagnifyingGlassPlus as falMagnifyingGlassPlus }   from '@fortawesome/pro-light-svg-icons';
import { faMagnifyingGlassPlus as farMagnifyingGlassPlus }   from '@fortawesome/pro-regular-svg-icons';
import { faMagnifyingGlassPlus as fasMagnifyingGlassPlus }   from '@fortawesome/pro-solid-svg-icons';
import { faMailbox as fadMailbox }   from '@fortawesome/pro-duotone-svg-icons';
import { faMailbox as falMailbox }   from '@fortawesome/pro-light-svg-icons';
import { faMailbox as farMailbox }   from '@fortawesome/pro-regular-svg-icons';
import { faMailbox as fasMailbox }   from '@fortawesome/pro-solid-svg-icons';
import { faMailBulk as fadMailBulk }   from '@fortawesome/pro-duotone-svg-icons';
import { faMailBulk as falMailBulk }   from '@fortawesome/pro-light-svg-icons';
import { faMailBulk as farMailBulk }   from '@fortawesome/pro-regular-svg-icons';
import { faMailBulk as fasMailBulk }   from '@fortawesome/pro-solid-svg-icons';
import { faMailForward as fadMailForward }   from '@fortawesome/pro-duotone-svg-icons';
import { faMailForward as falMailForward }   from '@fortawesome/pro-light-svg-icons';
import { faMailForward as farMailForward }   from '@fortawesome/pro-regular-svg-icons';
import { faMailForward as fasMailForward }   from '@fortawesome/pro-solid-svg-icons';
import { faMailReply as fadMailReply }   from '@fortawesome/pro-duotone-svg-icons';
import { faMailReply as falMailReply }   from '@fortawesome/pro-light-svg-icons';
import { faMailReply as farMailReply }   from '@fortawesome/pro-regular-svg-icons';
import { faMailReply as fasMailReply }   from '@fortawesome/pro-solid-svg-icons';
import { faMailReplyAll as fadMailReplyAll }   from '@fortawesome/pro-duotone-svg-icons';
import { faMailReplyAll as falMailReplyAll }   from '@fortawesome/pro-light-svg-icons';
import { faMailReplyAll as farMailReplyAll }   from '@fortawesome/pro-regular-svg-icons';
import { faMailReplyAll as fasMailReplyAll }   from '@fortawesome/pro-solid-svg-icons';
import { faMakiRoll as fadMakiRoll }   from '@fortawesome/pro-duotone-svg-icons';
import { faMakiRoll as falMakiRoll }   from '@fortawesome/pro-light-svg-icons';
import { faMakiRoll as farMakiRoll }   from '@fortawesome/pro-regular-svg-icons';
import { faMakiRoll as fasMakiRoll }   from '@fortawesome/pro-solid-svg-icons';
import { faMakizushi as fadMakizushi }   from '@fortawesome/pro-duotone-svg-icons';
import { faMakizushi as falMakizushi }   from '@fortawesome/pro-light-svg-icons';
import { faMakizushi as farMakizushi }   from '@fortawesome/pro-regular-svg-icons';
import { faMakizushi as fasMakizushi }   from '@fortawesome/pro-solid-svg-icons';
import { faMale as fadMale }   from '@fortawesome/pro-duotone-svg-icons';
import { faMale as falMale }   from '@fortawesome/pro-light-svg-icons';
import { faMale as farMale }   from '@fortawesome/pro-regular-svg-icons';
import { faMale as fasMale }   from '@fortawesome/pro-solid-svg-icons';
import { faManatSign as fadManatSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faManatSign as falManatSign }   from '@fortawesome/pro-light-svg-icons';
import { faManatSign as farManatSign }   from '@fortawesome/pro-regular-svg-icons';
import { faManatSign as fasManatSign }   from '@fortawesome/pro-solid-svg-icons';
import { faMandolin as fadMandolin }   from '@fortawesome/pro-duotone-svg-icons';
import { faMandolin as falMandolin }   from '@fortawesome/pro-light-svg-icons';
import { faMandolin as farMandolin }   from '@fortawesome/pro-regular-svg-icons';
import { faMandolin as fasMandolin }   from '@fortawesome/pro-solid-svg-icons';
import { faMango as fadMango }   from '@fortawesome/pro-duotone-svg-icons';
import { faMango as falMango }   from '@fortawesome/pro-light-svg-icons';
import { faMango as farMango }   from '@fortawesome/pro-regular-svg-icons';
import { faMango as fasMango }   from '@fortawesome/pro-solid-svg-icons';
import { faManhole as fadManhole }   from '@fortawesome/pro-duotone-svg-icons';
import { faManhole as falManhole }   from '@fortawesome/pro-light-svg-icons';
import { faManhole as farManhole }   from '@fortawesome/pro-regular-svg-icons';
import { faManhole as fasManhole }   from '@fortawesome/pro-solid-svg-icons';
import { faMap as fadMap }   from '@fortawesome/pro-duotone-svg-icons';
import { faMap as falMap }   from '@fortawesome/pro-light-svg-icons';
import { faMap as farMap }   from '@fortawesome/pro-regular-svg-icons';
import { faMap as fasMap }   from '@fortawesome/pro-solid-svg-icons';
import { faMapLocation as fadMapLocation }   from '@fortawesome/pro-duotone-svg-icons';
import { faMapLocation as falMapLocation }   from '@fortawesome/pro-light-svg-icons';
import { faMapLocation as farMapLocation }   from '@fortawesome/pro-regular-svg-icons';
import { faMapLocation as fasMapLocation }   from '@fortawesome/pro-solid-svg-icons';
import { faMapLocationDot as fadMapLocationDot }   from '@fortawesome/pro-duotone-svg-icons';
import { faMapLocationDot as falMapLocationDot }   from '@fortawesome/pro-light-svg-icons';
import { faMapLocationDot as farMapLocationDot }   from '@fortawesome/pro-regular-svg-icons';
import { faMapLocationDot as fasMapLocationDot }   from '@fortawesome/pro-solid-svg-icons';
import { faMapMarked as fadMapMarked }   from '@fortawesome/pro-duotone-svg-icons';
import { faMapMarked as falMapMarked }   from '@fortawesome/pro-light-svg-icons';
import { faMapMarked as farMapMarked }   from '@fortawesome/pro-regular-svg-icons';
import { faMapMarked as fasMapMarked }   from '@fortawesome/pro-solid-svg-icons';
import { faMapMarkedAlt as fadMapMarkedAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faMapMarkedAlt as falMapMarkedAlt }   from '@fortawesome/pro-light-svg-icons';
import { faMapMarkedAlt as farMapMarkedAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faMapMarkedAlt as fasMapMarkedAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faMapMarker as fadMapMarker }   from '@fortawesome/pro-duotone-svg-icons';
import { faMapMarker as falMapMarker }   from '@fortawesome/pro-light-svg-icons';
import { faMapMarker as farMapMarker }   from '@fortawesome/pro-regular-svg-icons';
import { faMapMarker as fasMapMarker }   from '@fortawesome/pro-solid-svg-icons';
import { faMapMarkerAlt as fadMapMarkerAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faMapMarkerAlt as falMapMarkerAlt }   from '@fortawesome/pro-light-svg-icons';
import { faMapMarkerAlt as farMapMarkerAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faMapMarkerAlt as fasMapMarkerAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faMapMarkerAltSlash as fadMapMarkerAltSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faMapMarkerAltSlash as falMapMarkerAltSlash }   from '@fortawesome/pro-light-svg-icons';
import { faMapMarkerAltSlash as farMapMarkerAltSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faMapMarkerAltSlash as fasMapMarkerAltSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faMapMarkerCheck as fadMapMarkerCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faMapMarkerCheck as falMapMarkerCheck }   from '@fortawesome/pro-light-svg-icons';
import { faMapMarkerCheck as farMapMarkerCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faMapMarkerCheck as fasMapMarkerCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faMapMarkerEdit as fadMapMarkerEdit }   from '@fortawesome/pro-duotone-svg-icons';
import { faMapMarkerEdit as falMapMarkerEdit }   from '@fortawesome/pro-light-svg-icons';
import { faMapMarkerEdit as farMapMarkerEdit }   from '@fortawesome/pro-regular-svg-icons';
import { faMapMarkerEdit as fasMapMarkerEdit }   from '@fortawesome/pro-solid-svg-icons';
import { faMapMarkerExclamation as fadMapMarkerExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faMapMarkerExclamation as falMapMarkerExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faMapMarkerExclamation as farMapMarkerExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faMapMarkerExclamation as fasMapMarkerExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faMapMarkerMinus as fadMapMarkerMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faMapMarkerMinus as falMapMarkerMinus }   from '@fortawesome/pro-light-svg-icons';
import { faMapMarkerMinus as farMapMarkerMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faMapMarkerMinus as fasMapMarkerMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faMapMarkerPlus as fadMapMarkerPlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faMapMarkerPlus as falMapMarkerPlus }   from '@fortawesome/pro-light-svg-icons';
import { faMapMarkerPlus as farMapMarkerPlus }   from '@fortawesome/pro-regular-svg-icons';
import { faMapMarkerPlus as fasMapMarkerPlus }   from '@fortawesome/pro-solid-svg-icons';
import { faMapMarkerQuestion as fadMapMarkerQuestion }   from '@fortawesome/pro-duotone-svg-icons';
import { faMapMarkerQuestion as falMapMarkerQuestion }   from '@fortawesome/pro-light-svg-icons';
import { faMapMarkerQuestion as farMapMarkerQuestion }   from '@fortawesome/pro-regular-svg-icons';
import { faMapMarkerQuestion as fasMapMarkerQuestion }   from '@fortawesome/pro-solid-svg-icons';
import { faMapMarkerSlash as fadMapMarkerSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faMapMarkerSlash as falMapMarkerSlash }   from '@fortawesome/pro-light-svg-icons';
import { faMapMarkerSlash as farMapMarkerSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faMapMarkerSlash as fasMapMarkerSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faMapMarkerSmile as fadMapMarkerSmile }   from '@fortawesome/pro-duotone-svg-icons';
import { faMapMarkerSmile as falMapMarkerSmile }   from '@fortawesome/pro-light-svg-icons';
import { faMapMarkerSmile as farMapMarkerSmile }   from '@fortawesome/pro-regular-svg-icons';
import { faMapMarkerSmile as fasMapMarkerSmile }   from '@fortawesome/pro-solid-svg-icons';
import { faMapMarkerTimes as fadMapMarkerTimes }   from '@fortawesome/pro-duotone-svg-icons';
import { faMapMarkerTimes as falMapMarkerTimes }   from '@fortawesome/pro-light-svg-icons';
import { faMapMarkerTimes as farMapMarkerTimes }   from '@fortawesome/pro-regular-svg-icons';
import { faMapMarkerTimes as fasMapMarkerTimes }   from '@fortawesome/pro-solid-svg-icons';
import { faMapMarkerXmark as fadMapMarkerXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faMapMarkerXmark as falMapMarkerXmark }   from '@fortawesome/pro-light-svg-icons';
import { faMapMarkerXmark as farMapMarkerXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faMapMarkerXmark as fasMapMarkerXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faMapPin as fadMapPin }   from '@fortawesome/pro-duotone-svg-icons';
import { faMapPin as falMapPin }   from '@fortawesome/pro-light-svg-icons';
import { faMapPin as farMapPin }   from '@fortawesome/pro-regular-svg-icons';
import { faMapPin as fasMapPin }   from '@fortawesome/pro-solid-svg-icons';
import { faMapSigns as fadMapSigns }   from '@fortawesome/pro-duotone-svg-icons';
import { faMapSigns as falMapSigns }   from '@fortawesome/pro-light-svg-icons';
import { faMapSigns as farMapSigns }   from '@fortawesome/pro-regular-svg-icons';
import { faMapSigns as fasMapSigns }   from '@fortawesome/pro-solid-svg-icons';
import { faMarker as fadMarker }   from '@fortawesome/pro-duotone-svg-icons';
import { faMarker as falMarker }   from '@fortawesome/pro-light-svg-icons';
import { faMarker as farMarker }   from '@fortawesome/pro-regular-svg-icons';
import { faMarker as fasMarker }   from '@fortawesome/pro-solid-svg-icons';
import { faMars as fadMars }   from '@fortawesome/pro-duotone-svg-icons';
import { faMars as falMars }   from '@fortawesome/pro-light-svg-icons';
import { faMars as farMars }   from '@fortawesome/pro-regular-svg-icons';
import { faMars as fasMars }   from '@fortawesome/pro-solid-svg-icons';
import { faMarsAndVenus as fadMarsAndVenus }   from '@fortawesome/pro-duotone-svg-icons';
import { faMarsAndVenus as falMarsAndVenus }   from '@fortawesome/pro-light-svg-icons';
import { faMarsAndVenus as farMarsAndVenus }   from '@fortawesome/pro-regular-svg-icons';
import { faMarsAndVenus as fasMarsAndVenus }   from '@fortawesome/pro-solid-svg-icons';
import { faMarsAndVenusBurst as fadMarsAndVenusBurst }   from '@fortawesome/pro-duotone-svg-icons';
import { faMarsAndVenusBurst as falMarsAndVenusBurst }   from '@fortawesome/pro-light-svg-icons';
import { faMarsAndVenusBurst as farMarsAndVenusBurst }   from '@fortawesome/pro-regular-svg-icons';
import { faMarsAndVenusBurst as fasMarsAndVenusBurst }   from '@fortawesome/pro-solid-svg-icons';
import { faMarsDouble as fadMarsDouble }   from '@fortawesome/pro-duotone-svg-icons';
import { faMarsDouble as falMarsDouble }   from '@fortawesome/pro-light-svg-icons';
import { faMarsDouble as farMarsDouble }   from '@fortawesome/pro-regular-svg-icons';
import { faMarsDouble as fasMarsDouble }   from '@fortawesome/pro-solid-svg-icons';
import { faMarsStroke as fadMarsStroke }   from '@fortawesome/pro-duotone-svg-icons';
import { faMarsStroke as falMarsStroke }   from '@fortawesome/pro-light-svg-icons';
import { faMarsStroke as farMarsStroke }   from '@fortawesome/pro-regular-svg-icons';
import { faMarsStroke as fasMarsStroke }   from '@fortawesome/pro-solid-svg-icons';
import { faMarsStrokeH as fadMarsStrokeH }   from '@fortawesome/pro-duotone-svg-icons';
import { faMarsStrokeH as falMarsStrokeH }   from '@fortawesome/pro-light-svg-icons';
import { faMarsStrokeH as farMarsStrokeH }   from '@fortawesome/pro-regular-svg-icons';
import { faMarsStrokeH as fasMarsStrokeH }   from '@fortawesome/pro-solid-svg-icons';
import { faMarsStrokeRight as fadMarsStrokeRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faMarsStrokeRight as falMarsStrokeRight }   from '@fortawesome/pro-light-svg-icons';
import { faMarsStrokeRight as farMarsStrokeRight }   from '@fortawesome/pro-regular-svg-icons';
import { faMarsStrokeRight as fasMarsStrokeRight }   from '@fortawesome/pro-solid-svg-icons';
import { faMarsStrokeUp as fadMarsStrokeUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faMarsStrokeUp as falMarsStrokeUp }   from '@fortawesome/pro-light-svg-icons';
import { faMarsStrokeUp as farMarsStrokeUp }   from '@fortawesome/pro-regular-svg-icons';
import { faMarsStrokeUp as fasMarsStrokeUp }   from '@fortawesome/pro-solid-svg-icons';
import { faMarsStrokeV as fadMarsStrokeV }   from '@fortawesome/pro-duotone-svg-icons';
import { faMarsStrokeV as falMarsStrokeV }   from '@fortawesome/pro-light-svg-icons';
import { faMarsStrokeV as farMarsStrokeV }   from '@fortawesome/pro-regular-svg-icons';
import { faMarsStrokeV as fasMarsStrokeV }   from '@fortawesome/pro-solid-svg-icons';
import { faMartiniGlass as fadMartiniGlass }   from '@fortawesome/pro-duotone-svg-icons';
import { faMartiniGlass as falMartiniGlass }   from '@fortawesome/pro-light-svg-icons';
import { faMartiniGlass as farMartiniGlass }   from '@fortawesome/pro-regular-svg-icons';
import { faMartiniGlass as fasMartiniGlass }   from '@fortawesome/pro-solid-svg-icons';
import { faMartiniGlassCitrus as fadMartiniGlassCitrus }   from '@fortawesome/pro-duotone-svg-icons';
import { faMartiniGlassCitrus as falMartiniGlassCitrus }   from '@fortawesome/pro-light-svg-icons';
import { faMartiniGlassCitrus as farMartiniGlassCitrus }   from '@fortawesome/pro-regular-svg-icons';
import { faMartiniGlassCitrus as fasMartiniGlassCitrus }   from '@fortawesome/pro-solid-svg-icons';
import { faMartiniGlassEmpty as fadMartiniGlassEmpty }   from '@fortawesome/pro-duotone-svg-icons';
import { faMartiniGlassEmpty as falMartiniGlassEmpty }   from '@fortawesome/pro-light-svg-icons';
import { faMartiniGlassEmpty as farMartiniGlassEmpty }   from '@fortawesome/pro-regular-svg-icons';
import { faMartiniGlassEmpty as fasMartiniGlassEmpty }   from '@fortawesome/pro-solid-svg-icons';
import { faMask as fadMask }   from '@fortawesome/pro-duotone-svg-icons';
import { faMask as falMask }   from '@fortawesome/pro-light-svg-icons';
import { faMask as farMask }   from '@fortawesome/pro-regular-svg-icons';
import { faMask as fasMask }   from '@fortawesome/pro-solid-svg-icons';
import { faMaskFace as fadMaskFace }   from '@fortawesome/pro-duotone-svg-icons';
import { faMaskFace as falMaskFace }   from '@fortawesome/pro-light-svg-icons';
import { faMaskFace as farMaskFace }   from '@fortawesome/pro-regular-svg-icons';
import { faMaskFace as fasMaskFace }   from '@fortawesome/pro-solid-svg-icons';
import { faMaskLuchador as fadMaskLuchador }   from '@fortawesome/pro-duotone-svg-icons';
import { faMaskLuchador as falMaskLuchador }   from '@fortawesome/pro-light-svg-icons';
import { faMaskLuchador as farMaskLuchador }   from '@fortawesome/pro-regular-svg-icons';
import { faMaskLuchador as fasMaskLuchador }   from '@fortawesome/pro-solid-svg-icons';
import { faMaskSnorkel as fadMaskSnorkel }   from '@fortawesome/pro-duotone-svg-icons';
import { faMaskSnorkel as falMaskSnorkel }   from '@fortawesome/pro-light-svg-icons';
import { faMaskSnorkel as farMaskSnorkel }   from '@fortawesome/pro-regular-svg-icons';
import { faMaskSnorkel as fasMaskSnorkel }   from '@fortawesome/pro-solid-svg-icons';
import { faMasksTheater as fadMasksTheater }   from '@fortawesome/pro-duotone-svg-icons';
import { faMasksTheater as falMasksTheater }   from '@fortawesome/pro-light-svg-icons';
import { faMasksTheater as farMasksTheater }   from '@fortawesome/pro-regular-svg-icons';
import { faMasksTheater as fasMasksTheater }   from '@fortawesome/pro-solid-svg-icons';
import { faMaskVentilator as fadMaskVentilator }   from '@fortawesome/pro-duotone-svg-icons';
import { faMaskVentilator as falMaskVentilator }   from '@fortawesome/pro-light-svg-icons';
import { faMaskVentilator as farMaskVentilator }   from '@fortawesome/pro-regular-svg-icons';
import { faMaskVentilator as fasMaskVentilator }   from '@fortawesome/pro-solid-svg-icons';
import { faMattressPillow as fadMattressPillow }   from '@fortawesome/pro-duotone-svg-icons';
import { faMattressPillow as falMattressPillow }   from '@fortawesome/pro-light-svg-icons';
import { faMattressPillow as farMattressPillow }   from '@fortawesome/pro-regular-svg-icons';
import { faMattressPillow as fasMattressPillow }   from '@fortawesome/pro-solid-svg-icons';
import { faMaximize as fadMaximize }   from '@fortawesome/pro-duotone-svg-icons';
import { faMaximize as falMaximize }   from '@fortawesome/pro-light-svg-icons';
import { faMaximize as farMaximize }   from '@fortawesome/pro-regular-svg-icons';
import { faMaximize as fasMaximize }   from '@fortawesome/pro-solid-svg-icons';
import { faMeat as fadMeat }   from '@fortawesome/pro-duotone-svg-icons';
import { faMeat as falMeat }   from '@fortawesome/pro-light-svg-icons';
import { faMeat as farMeat }   from '@fortawesome/pro-regular-svg-icons';
import { faMeat as fasMeat }   from '@fortawesome/pro-solid-svg-icons';
import { faMedal as fadMedal }   from '@fortawesome/pro-duotone-svg-icons';
import { faMedal as falMedal }   from '@fortawesome/pro-light-svg-icons';
import { faMedal as farMedal }   from '@fortawesome/pro-regular-svg-icons';
import { faMedal as fasMedal }   from '@fortawesome/pro-solid-svg-icons';
import { faMedkit as fadMedkit }   from '@fortawesome/pro-duotone-svg-icons';
import { faMedkit as falMedkit }   from '@fortawesome/pro-light-svg-icons';
import { faMedkit as farMedkit }   from '@fortawesome/pro-regular-svg-icons';
import { faMedkit as fasMedkit }   from '@fortawesome/pro-solid-svg-icons';
import { faMegaphone as fadMegaphone }   from '@fortawesome/pro-duotone-svg-icons';
import { faMegaphone as falMegaphone }   from '@fortawesome/pro-light-svg-icons';
import { faMegaphone as farMegaphone }   from '@fortawesome/pro-regular-svg-icons';
import { faMegaphone as fasMegaphone }   from '@fortawesome/pro-solid-svg-icons';
import { faMeh as fadMeh }   from '@fortawesome/pro-duotone-svg-icons';
import { faMeh as falMeh }   from '@fortawesome/pro-light-svg-icons';
import { faMeh as farMeh }   from '@fortawesome/pro-regular-svg-icons';
import { faMeh as fasMeh }   from '@fortawesome/pro-solid-svg-icons';
import { faMehBlank as fadMehBlank }   from '@fortawesome/pro-duotone-svg-icons';
import { faMehBlank as falMehBlank }   from '@fortawesome/pro-light-svg-icons';
import { faMehBlank as farMehBlank }   from '@fortawesome/pro-regular-svg-icons';
import { faMehBlank as fasMehBlank }   from '@fortawesome/pro-solid-svg-icons';
import { faMehRollingEyes as fadMehRollingEyes }   from '@fortawesome/pro-duotone-svg-icons';
import { faMehRollingEyes as falMehRollingEyes }   from '@fortawesome/pro-light-svg-icons';
import { faMehRollingEyes as farMehRollingEyes }   from '@fortawesome/pro-regular-svg-icons';
import { faMehRollingEyes as fasMehRollingEyes }   from '@fortawesome/pro-solid-svg-icons';
import { faMelon as fadMelon }   from '@fortawesome/pro-duotone-svg-icons';
import { faMelon as falMelon }   from '@fortawesome/pro-light-svg-icons';
import { faMelon as farMelon }   from '@fortawesome/pro-regular-svg-icons';
import { faMelon as fasMelon }   from '@fortawesome/pro-solid-svg-icons';
import { faMelonSlice as fadMelonSlice }   from '@fortawesome/pro-duotone-svg-icons';
import { faMelonSlice as falMelonSlice }   from '@fortawesome/pro-light-svg-icons';
import { faMelonSlice as farMelonSlice }   from '@fortawesome/pro-regular-svg-icons';
import { faMelonSlice as fasMelonSlice }   from '@fortawesome/pro-solid-svg-icons';
import { faMemo as fadMemo }   from '@fortawesome/pro-duotone-svg-icons';
import { faMemo as falMemo }   from '@fortawesome/pro-light-svg-icons';
import { faMemo as farMemo }   from '@fortawesome/pro-regular-svg-icons';
import { faMemo as fasMemo }   from '@fortawesome/pro-solid-svg-icons';
import { faMemoCircleCheck as fadMemoCircleCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faMemoCircleCheck as falMemoCircleCheck }   from '@fortawesome/pro-light-svg-icons';
import { faMemoCircleCheck as farMemoCircleCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faMemoCircleCheck as fasMemoCircleCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faMemoCircleInfo as fadMemoCircleInfo }   from '@fortawesome/pro-duotone-svg-icons';
import { faMemoCircleInfo as falMemoCircleInfo }   from '@fortawesome/pro-light-svg-icons';
import { faMemoCircleInfo as farMemoCircleInfo }   from '@fortawesome/pro-regular-svg-icons';
import { faMemoCircleInfo as fasMemoCircleInfo }   from '@fortawesome/pro-solid-svg-icons';
import { faMemoPad as fadMemoPad }   from '@fortawesome/pro-duotone-svg-icons';
import { faMemoPad as falMemoPad }   from '@fortawesome/pro-light-svg-icons';
import { faMemoPad as farMemoPad }   from '@fortawesome/pro-regular-svg-icons';
import { faMemoPad as fasMemoPad }   from '@fortawesome/pro-solid-svg-icons';
import { faMemory as fadMemory }   from '@fortawesome/pro-duotone-svg-icons';
import { faMemory as falMemory }   from '@fortawesome/pro-light-svg-icons';
import { faMemory as farMemory }   from '@fortawesome/pro-regular-svg-icons';
import { faMemory as fasMemory }   from '@fortawesome/pro-solid-svg-icons';
import { faMenorah as fadMenorah }   from '@fortawesome/pro-duotone-svg-icons';
import { faMenorah as falMenorah }   from '@fortawesome/pro-light-svg-icons';
import { faMenorah as farMenorah }   from '@fortawesome/pro-regular-svg-icons';
import { faMenorah as fasMenorah }   from '@fortawesome/pro-solid-svg-icons';
import { faMercury as fadMercury }   from '@fortawesome/pro-duotone-svg-icons';
import { faMercury as falMercury }   from '@fortawesome/pro-light-svg-icons';
import { faMercury as farMercury }   from '@fortawesome/pro-regular-svg-icons';
import { faMercury as fasMercury }   from '@fortawesome/pro-solid-svg-icons';
import { faMerge as fadMerge }   from '@fortawesome/pro-duotone-svg-icons';
import { faMerge as falMerge }   from '@fortawesome/pro-light-svg-icons';
import { faMerge as farMerge }   from '@fortawesome/pro-regular-svg-icons';
import { faMerge as fasMerge }   from '@fortawesome/pro-solid-svg-icons';
import { faMessage as fadMessage }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessage as falMessage }   from '@fortawesome/pro-light-svg-icons';
import { faMessage as farMessage }   from '@fortawesome/pro-regular-svg-icons';
import { faMessage as fasMessage }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageArrowDown as fadMessageArrowDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageArrowDown as falMessageArrowDown }   from '@fortawesome/pro-light-svg-icons';
import { faMessageArrowDown as farMessageArrowDown }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageArrowDown as fasMessageArrowDown }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageArrowUp as fadMessageArrowUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageArrowUp as falMessageArrowUp }   from '@fortawesome/pro-light-svg-icons';
import { faMessageArrowUp as farMessageArrowUp }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageArrowUp as fasMessageArrowUp }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageArrowUpRight as fadMessageArrowUpRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageArrowUpRight as falMessageArrowUpRight }   from '@fortawesome/pro-light-svg-icons';
import { faMessageArrowUpRight as farMessageArrowUpRight }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageArrowUpRight as fasMessageArrowUpRight }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageBot as fadMessageBot }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageBot as falMessageBot }   from '@fortawesome/pro-light-svg-icons';
import { faMessageBot as farMessageBot }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageBot as fasMessageBot }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageCaptions as fadMessageCaptions }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageCaptions as falMessageCaptions }   from '@fortawesome/pro-light-svg-icons';
import { faMessageCaptions as farMessageCaptions }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageCaptions as fasMessageCaptions }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageCheck as fadMessageCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageCheck as falMessageCheck }   from '@fortawesome/pro-light-svg-icons';
import { faMessageCheck as farMessageCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageCheck as fasMessageCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageCode as fadMessageCode }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageCode as falMessageCode }   from '@fortawesome/pro-light-svg-icons';
import { faMessageCode as farMessageCode }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageCode as fasMessageCode }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageDollar as fadMessageDollar }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageDollar as falMessageDollar }   from '@fortawesome/pro-light-svg-icons';
import { faMessageDollar as farMessageDollar }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageDollar as fasMessageDollar }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageDots as fadMessageDots }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageDots as falMessageDots }   from '@fortawesome/pro-light-svg-icons';
import { faMessageDots as farMessageDots }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageDots as fasMessageDots }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageEdit as fadMessageEdit }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageEdit as falMessageEdit }   from '@fortawesome/pro-light-svg-icons';
import { faMessageEdit as farMessageEdit }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageEdit as fasMessageEdit }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageExclamation as fadMessageExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageExclamation as falMessageExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faMessageExclamation as farMessageExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageExclamation as fasMessageExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageImage as fadMessageImage }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageImage as falMessageImage }   from '@fortawesome/pro-light-svg-icons';
import { faMessageImage as farMessageImage }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageImage as fasMessageImage }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageLines as fadMessageLines }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageLines as falMessageLines }   from '@fortawesome/pro-light-svg-icons';
import { faMessageLines as farMessageLines }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageLines as fasMessageLines }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageMedical as fadMessageMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageMedical as falMessageMedical }   from '@fortawesome/pro-light-svg-icons';
import { faMessageMedical as farMessageMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageMedical as fasMessageMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageMiddle as fadMessageMiddle }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageMiddle as falMessageMiddle }   from '@fortawesome/pro-light-svg-icons';
import { faMessageMiddle as farMessageMiddle }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageMiddle as fasMessageMiddle }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageMiddleTop as fadMessageMiddleTop }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageMiddleTop as falMessageMiddleTop }   from '@fortawesome/pro-light-svg-icons';
import { faMessageMiddleTop as farMessageMiddleTop }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageMiddleTop as fasMessageMiddleTop }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageMinus as fadMessageMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageMinus as falMessageMinus }   from '@fortawesome/pro-light-svg-icons';
import { faMessageMinus as farMessageMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageMinus as fasMessageMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageMusic as fadMessageMusic }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageMusic as falMessageMusic }   from '@fortawesome/pro-light-svg-icons';
import { faMessageMusic as farMessageMusic }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageMusic as fasMessageMusic }   from '@fortawesome/pro-solid-svg-icons';
import { faMessagePen as fadMessagePen }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessagePen as falMessagePen }   from '@fortawesome/pro-light-svg-icons';
import { faMessagePen as farMessagePen }   from '@fortawesome/pro-regular-svg-icons';
import { faMessagePen as fasMessagePen }   from '@fortawesome/pro-solid-svg-icons';
import { faMessagePlus as fadMessagePlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessagePlus as falMessagePlus }   from '@fortawesome/pro-light-svg-icons';
import { faMessagePlus as farMessagePlus }   from '@fortawesome/pro-regular-svg-icons';
import { faMessagePlus as fasMessagePlus }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageQuestion as fadMessageQuestion }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageQuestion as falMessageQuestion }   from '@fortawesome/pro-light-svg-icons';
import { faMessageQuestion as farMessageQuestion }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageQuestion as fasMessageQuestion }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageQuote as fadMessageQuote }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageQuote as falMessageQuote }   from '@fortawesome/pro-light-svg-icons';
import { faMessageQuote as farMessageQuote }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageQuote as fasMessageQuote }   from '@fortawesome/pro-solid-svg-icons';
import { faMessages as fadMessages }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessages as falMessages }   from '@fortawesome/pro-light-svg-icons';
import { faMessages as farMessages }   from '@fortawesome/pro-regular-svg-icons';
import { faMessages as fasMessages }   from '@fortawesome/pro-solid-svg-icons';
import { faMessagesDollar as fadMessagesDollar }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessagesDollar as falMessagesDollar }   from '@fortawesome/pro-light-svg-icons';
import { faMessagesDollar as farMessagesDollar }   from '@fortawesome/pro-regular-svg-icons';
import { faMessagesDollar as fasMessagesDollar }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageSlash as fadMessageSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageSlash as falMessageSlash }   from '@fortawesome/pro-light-svg-icons';
import { faMessageSlash as farMessageSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageSlash as fasMessageSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageSmile as fadMessageSmile }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageSmile as falMessageSmile }   from '@fortawesome/pro-light-svg-icons';
import { faMessageSmile as farMessageSmile }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageSmile as fasMessageSmile }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageSms as fadMessageSms }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageSms as falMessageSms }   from '@fortawesome/pro-light-svg-icons';
import { faMessageSms as farMessageSms }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageSms as fasMessageSms }   from '@fortawesome/pro-solid-svg-icons';
import { faMessagesQuestion as fadMessagesQuestion }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessagesQuestion as falMessagesQuestion }   from '@fortawesome/pro-light-svg-icons';
import { faMessagesQuestion as farMessagesQuestion }   from '@fortawesome/pro-regular-svg-icons';
import { faMessagesQuestion as fasMessagesQuestion }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageText as fadMessageText }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageText as falMessageText }   from '@fortawesome/pro-light-svg-icons';
import { faMessageText as farMessageText }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageText as fasMessageText }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageTimes as fadMessageTimes }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageTimes as falMessageTimes }   from '@fortawesome/pro-light-svg-icons';
import { faMessageTimes as farMessageTimes }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageTimes as fasMessageTimes }   from '@fortawesome/pro-solid-svg-icons';
import { faMessageXmark as fadMessageXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessageXmark as falMessageXmark }   from '@fortawesome/pro-light-svg-icons';
import { faMessageXmark as farMessageXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faMessageXmark as fasMessageXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faMessaging as fadMessaging }   from '@fortawesome/pro-duotone-svg-icons';
import { faMessaging as falMessaging }   from '@fortawesome/pro-light-svg-icons';
import { faMessaging as farMessaging }   from '@fortawesome/pro-regular-svg-icons';
import { faMessaging as fasMessaging }   from '@fortawesome/pro-solid-svg-icons';
import { faMeteor as fadMeteor }   from '@fortawesome/pro-duotone-svg-icons';
import { faMeteor as falMeteor }   from '@fortawesome/pro-light-svg-icons';
import { faMeteor as farMeteor }   from '@fortawesome/pro-regular-svg-icons';
import { faMeteor as fasMeteor }   from '@fortawesome/pro-solid-svg-icons';
import { faMeter as fadMeter }   from '@fortawesome/pro-duotone-svg-icons';
import { faMeter as falMeter }   from '@fortawesome/pro-light-svg-icons';
import { faMeter as farMeter }   from '@fortawesome/pro-regular-svg-icons';
import { faMeter as fasMeter }   from '@fortawesome/pro-solid-svg-icons';
import { faMeterBolt as fadMeterBolt }   from '@fortawesome/pro-duotone-svg-icons';
import { faMeterBolt as falMeterBolt }   from '@fortawesome/pro-light-svg-icons';
import { faMeterBolt as farMeterBolt }   from '@fortawesome/pro-regular-svg-icons';
import { faMeterBolt as fasMeterBolt }   from '@fortawesome/pro-solid-svg-icons';
import { faMeterDroplet as fadMeterDroplet }   from '@fortawesome/pro-duotone-svg-icons';
import { faMeterDroplet as falMeterDroplet }   from '@fortawesome/pro-light-svg-icons';
import { faMeterDroplet as farMeterDroplet }   from '@fortawesome/pro-regular-svg-icons';
import { faMeterDroplet as fasMeterDroplet }   from '@fortawesome/pro-solid-svg-icons';
import { faMeterFire as fadMeterFire }   from '@fortawesome/pro-duotone-svg-icons';
import { faMeterFire as falMeterFire }   from '@fortawesome/pro-light-svg-icons';
import { faMeterFire as farMeterFire }   from '@fortawesome/pro-regular-svg-icons';
import { faMeterFire as fasMeterFire }   from '@fortawesome/pro-solid-svg-icons';
import { faMicrochip as fadMicrochip }   from '@fortawesome/pro-duotone-svg-icons';
import { faMicrochip as falMicrochip }   from '@fortawesome/pro-light-svg-icons';
import { faMicrochip as farMicrochip }   from '@fortawesome/pro-regular-svg-icons';
import { faMicrochip as fasMicrochip }   from '@fortawesome/pro-solid-svg-icons';
import { faMicrochipAi as fadMicrochipAi }   from '@fortawesome/pro-duotone-svg-icons';
import { faMicrochipAi as falMicrochipAi }   from '@fortawesome/pro-light-svg-icons';
import { faMicrochipAi as farMicrochipAi }   from '@fortawesome/pro-regular-svg-icons';
import { faMicrochipAi as fasMicrochipAi }   from '@fortawesome/pro-solid-svg-icons';
import { faMicrophone as fadMicrophone }   from '@fortawesome/pro-duotone-svg-icons';
import { faMicrophone as falMicrophone }   from '@fortawesome/pro-light-svg-icons';
import { faMicrophone as farMicrophone }   from '@fortawesome/pro-regular-svg-icons';
import { faMicrophone as fasMicrophone }   from '@fortawesome/pro-solid-svg-icons';
import { faMicrophoneAlt as fadMicrophoneAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faMicrophoneAlt as falMicrophoneAlt }   from '@fortawesome/pro-light-svg-icons';
import { faMicrophoneAlt as farMicrophoneAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faMicrophoneAlt as fasMicrophoneAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faMicrophoneAltSlash as fadMicrophoneAltSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faMicrophoneAltSlash as falMicrophoneAltSlash }   from '@fortawesome/pro-light-svg-icons';
import { faMicrophoneAltSlash as farMicrophoneAltSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faMicrophoneAltSlash as fasMicrophoneAltSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faMicrophoneCircle as fadMicrophoneCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faMicrophoneCircle as falMicrophoneCircle }   from '@fortawesome/pro-light-svg-icons';
import { faMicrophoneCircle as farMicrophoneCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faMicrophoneCircle as fasMicrophoneCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faMicrophoneCircleAlt as fadMicrophoneCircleAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faMicrophoneCircleAlt as falMicrophoneCircleAlt }   from '@fortawesome/pro-light-svg-icons';
import { faMicrophoneCircleAlt as farMicrophoneCircleAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faMicrophoneCircleAlt as fasMicrophoneCircleAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faMicrophoneLines as fadMicrophoneLines }   from '@fortawesome/pro-duotone-svg-icons';
import { faMicrophoneLines as falMicrophoneLines }   from '@fortawesome/pro-light-svg-icons';
import { faMicrophoneLines as farMicrophoneLines }   from '@fortawesome/pro-regular-svg-icons';
import { faMicrophoneLines as fasMicrophoneLines }   from '@fortawesome/pro-solid-svg-icons';
import { faMicrophoneLinesSlash as fadMicrophoneLinesSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faMicrophoneLinesSlash as falMicrophoneLinesSlash }   from '@fortawesome/pro-light-svg-icons';
import { faMicrophoneLinesSlash as farMicrophoneLinesSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faMicrophoneLinesSlash as fasMicrophoneLinesSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faMicrophoneSlash as fadMicrophoneSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faMicrophoneSlash as falMicrophoneSlash }   from '@fortawesome/pro-light-svg-icons';
import { faMicrophoneSlash as farMicrophoneSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faMicrophoneSlash as fasMicrophoneSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faMicrophoneStand as fadMicrophoneStand }   from '@fortawesome/pro-duotone-svg-icons';
import { faMicrophoneStand as falMicrophoneStand }   from '@fortawesome/pro-light-svg-icons';
import { faMicrophoneStand as farMicrophoneStand }   from '@fortawesome/pro-regular-svg-icons';
import { faMicrophoneStand as fasMicrophoneStand }   from '@fortawesome/pro-solid-svg-icons';
import { faMicroscope as fadMicroscope }   from '@fortawesome/pro-duotone-svg-icons';
import { faMicroscope as falMicroscope }   from '@fortawesome/pro-light-svg-icons';
import { faMicroscope as farMicroscope }   from '@fortawesome/pro-regular-svg-icons';
import { faMicroscope as fasMicroscope }   from '@fortawesome/pro-solid-svg-icons';
import { faMicrowave as fadMicrowave }   from '@fortawesome/pro-duotone-svg-icons';
import { faMicrowave as falMicrowave }   from '@fortawesome/pro-light-svg-icons';
import { faMicrowave as farMicrowave }   from '@fortawesome/pro-regular-svg-icons';
import { faMicrowave as fasMicrowave }   from '@fortawesome/pro-solid-svg-icons';
import { faMillSign as fadMillSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faMillSign as falMillSign }   from '@fortawesome/pro-light-svg-icons';
import { faMillSign as farMillSign }   from '@fortawesome/pro-regular-svg-icons';
import { faMillSign as fasMillSign }   from '@fortawesome/pro-solid-svg-icons';
import { faMindShare as fadMindShare }   from '@fortawesome/pro-duotone-svg-icons';
import { faMindShare as falMindShare }   from '@fortawesome/pro-light-svg-icons';
import { faMindShare as farMindShare }   from '@fortawesome/pro-regular-svg-icons';
import { faMindShare as fasMindShare }   from '@fortawesome/pro-solid-svg-icons';
import { faMinimize as fadMinimize }   from '@fortawesome/pro-duotone-svg-icons';
import { faMinimize as falMinimize }   from '@fortawesome/pro-light-svg-icons';
import { faMinimize as farMinimize }   from '@fortawesome/pro-regular-svg-icons';
import { faMinimize as fasMinimize }   from '@fortawesome/pro-solid-svg-icons';
import { faMinus as fadMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faMinus as falMinus }   from '@fortawesome/pro-light-svg-icons';
import { faMinus as farMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faMinus as fasMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faMinusCircle as fadMinusCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faMinusCircle as falMinusCircle }   from '@fortawesome/pro-light-svg-icons';
import { faMinusCircle as farMinusCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faMinusCircle as fasMinusCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faMinusHexagon as fadMinusHexagon }   from '@fortawesome/pro-duotone-svg-icons';
import { faMinusHexagon as falMinusHexagon }   from '@fortawesome/pro-light-svg-icons';
import { faMinusHexagon as farMinusHexagon }   from '@fortawesome/pro-regular-svg-icons';
import { faMinusHexagon as fasMinusHexagon }   from '@fortawesome/pro-solid-svg-icons';
import { faMinusOctagon as fadMinusOctagon }   from '@fortawesome/pro-duotone-svg-icons';
import { faMinusOctagon as falMinusOctagon }   from '@fortawesome/pro-light-svg-icons';
import { faMinusOctagon as farMinusOctagon }   from '@fortawesome/pro-regular-svg-icons';
import { faMinusOctagon as fasMinusOctagon }   from '@fortawesome/pro-solid-svg-icons';
import { faMinusSquare as fadMinusSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faMinusSquare as falMinusSquare }   from '@fortawesome/pro-light-svg-icons';
import { faMinusSquare as farMinusSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faMinusSquare as fasMinusSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faMistletoe as fadMistletoe }   from '@fortawesome/pro-duotone-svg-icons';
import { faMistletoe as falMistletoe }   from '@fortawesome/pro-light-svg-icons';
import { faMistletoe as farMistletoe }   from '@fortawesome/pro-regular-svg-icons';
import { faMistletoe as fasMistletoe }   from '@fortawesome/pro-solid-svg-icons';
import { faMitten as fadMitten }   from '@fortawesome/pro-duotone-svg-icons';
import { faMitten as falMitten }   from '@fortawesome/pro-light-svg-icons';
import { faMitten as farMitten }   from '@fortawesome/pro-regular-svg-icons';
import { faMitten as fasMitten }   from '@fortawesome/pro-solid-svg-icons';
import { faMobile as fadMobile }   from '@fortawesome/pro-duotone-svg-icons';
import { faMobile as falMobile }   from '@fortawesome/pro-light-svg-icons';
import { faMobile as farMobile }   from '@fortawesome/pro-regular-svg-icons';
import { faMobile as fasMobile }   from '@fortawesome/pro-solid-svg-icons';
import { faMobileAlt as fadMobileAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faMobileAlt as falMobileAlt }   from '@fortawesome/pro-light-svg-icons';
import { faMobileAlt as farMobileAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faMobileAlt as fasMobileAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faMobileAndroid as fadMobileAndroid }   from '@fortawesome/pro-duotone-svg-icons';
import { faMobileAndroid as falMobileAndroid }   from '@fortawesome/pro-light-svg-icons';
import { faMobileAndroid as farMobileAndroid }   from '@fortawesome/pro-regular-svg-icons';
import { faMobileAndroid as fasMobileAndroid }   from '@fortawesome/pro-solid-svg-icons';
import { faMobileAndroidAlt as fadMobileAndroidAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faMobileAndroidAlt as falMobileAndroidAlt }   from '@fortawesome/pro-light-svg-icons';
import { faMobileAndroidAlt as farMobileAndroidAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faMobileAndroidAlt as fasMobileAndroidAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faMobileButton as fadMobileButton }   from '@fortawesome/pro-duotone-svg-icons';
import { faMobileButton as falMobileButton }   from '@fortawesome/pro-light-svg-icons';
import { faMobileButton as farMobileButton }   from '@fortawesome/pro-regular-svg-icons';
import { faMobileButton as fasMobileButton }   from '@fortawesome/pro-solid-svg-icons';
import { faMobileIphone as fadMobileIphone }   from '@fortawesome/pro-duotone-svg-icons';
import { faMobileIphone as falMobileIphone }   from '@fortawesome/pro-light-svg-icons';
import { faMobileIphone as farMobileIphone }   from '@fortawesome/pro-regular-svg-icons';
import { faMobileIphone as fasMobileIphone }   from '@fortawesome/pro-solid-svg-icons';
import { faMobileNotch as fadMobileNotch }   from '@fortawesome/pro-duotone-svg-icons';
import { faMobileNotch as falMobileNotch }   from '@fortawesome/pro-light-svg-icons';
import { faMobileNotch as farMobileNotch }   from '@fortawesome/pro-regular-svg-icons';
import { faMobileNotch as fasMobileNotch }   from '@fortawesome/pro-solid-svg-icons';
import { faMobilePhone as fadMobilePhone }   from '@fortawesome/pro-duotone-svg-icons';
import { faMobilePhone as falMobilePhone }   from '@fortawesome/pro-light-svg-icons';
import { faMobilePhone as farMobilePhone }   from '@fortawesome/pro-regular-svg-icons';
import { faMobilePhone as fasMobilePhone }   from '@fortawesome/pro-solid-svg-icons';
import { faMobileRetro as fadMobileRetro }   from '@fortawesome/pro-duotone-svg-icons';
import { faMobileRetro as falMobileRetro }   from '@fortawesome/pro-light-svg-icons';
import { faMobileRetro as farMobileRetro }   from '@fortawesome/pro-regular-svg-icons';
import { faMobileRetro as fasMobileRetro }   from '@fortawesome/pro-solid-svg-icons';
import { faMobileScreen as fadMobileScreen }   from '@fortawesome/pro-duotone-svg-icons';
import { faMobileScreen as falMobileScreen }   from '@fortawesome/pro-light-svg-icons';
import { faMobileScreen as farMobileScreen }   from '@fortawesome/pro-regular-svg-icons';
import { faMobileScreen as fasMobileScreen }   from '@fortawesome/pro-solid-svg-icons';
import { faMobileScreenButton as fadMobileScreenButton }   from '@fortawesome/pro-duotone-svg-icons';
import { faMobileScreenButton as falMobileScreenButton }   from '@fortawesome/pro-light-svg-icons';
import { faMobileScreenButton as farMobileScreenButton }   from '@fortawesome/pro-regular-svg-icons';
import { faMobileScreenButton as fasMobileScreenButton }   from '@fortawesome/pro-solid-svg-icons';
import { faMobileSignal as fadMobileSignal }   from '@fortawesome/pro-duotone-svg-icons';
import { faMobileSignal as falMobileSignal }   from '@fortawesome/pro-light-svg-icons';
import { faMobileSignal as farMobileSignal }   from '@fortawesome/pro-regular-svg-icons';
import { faMobileSignal as fasMobileSignal }   from '@fortawesome/pro-solid-svg-icons';
import { faMobileSignalOut as fadMobileSignalOut }   from '@fortawesome/pro-duotone-svg-icons';
import { faMobileSignalOut as falMobileSignalOut }   from '@fortawesome/pro-light-svg-icons';
import { faMobileSignalOut as farMobileSignalOut }   from '@fortawesome/pro-regular-svg-icons';
import { faMobileSignalOut as fasMobileSignalOut }   from '@fortawesome/pro-solid-svg-icons';
import { faMoneyBill as fadMoneyBill }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoneyBill as falMoneyBill }   from '@fortawesome/pro-light-svg-icons';
import { faMoneyBill as farMoneyBill }   from '@fortawesome/pro-regular-svg-icons';
import { faMoneyBill as fasMoneyBill }   from '@fortawesome/pro-solid-svg-icons';
import { faMoneyBill1 as fadMoneyBill1 }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoneyBill1 as falMoneyBill1 }   from '@fortawesome/pro-light-svg-icons';
import { faMoneyBill1 as farMoneyBill1 }   from '@fortawesome/pro-regular-svg-icons';
import { faMoneyBill1 as fasMoneyBill1 }   from '@fortawesome/pro-solid-svg-icons';
import { faMoneyBill1Wave as fadMoneyBill1Wave }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoneyBill1Wave as falMoneyBill1Wave }   from '@fortawesome/pro-light-svg-icons';
import { faMoneyBill1Wave as farMoneyBill1Wave }   from '@fortawesome/pro-regular-svg-icons';
import { faMoneyBill1Wave as fasMoneyBill1Wave }   from '@fortawesome/pro-solid-svg-icons';
import { faMoneyBillAlt as fadMoneyBillAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoneyBillAlt as falMoneyBillAlt }   from '@fortawesome/pro-light-svg-icons';
import { faMoneyBillAlt as farMoneyBillAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faMoneyBillAlt as fasMoneyBillAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faMoneyBills as fadMoneyBills }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoneyBills as falMoneyBills }   from '@fortawesome/pro-light-svg-icons';
import { faMoneyBills as farMoneyBills }   from '@fortawesome/pro-regular-svg-icons';
import { faMoneyBills as fasMoneyBills }   from '@fortawesome/pro-solid-svg-icons';
import { faMoneyBillsAlt as fadMoneyBillsAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoneyBillsAlt as falMoneyBillsAlt }   from '@fortawesome/pro-light-svg-icons';
import { faMoneyBillsAlt as farMoneyBillsAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faMoneyBillsAlt as fasMoneyBillsAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faMoneyBillSimple as fadMoneyBillSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoneyBillSimple as falMoneyBillSimple }   from '@fortawesome/pro-light-svg-icons';
import { faMoneyBillSimple as farMoneyBillSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faMoneyBillSimple as fasMoneyBillSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faMoneyBillSimpleWave as fadMoneyBillSimpleWave }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoneyBillSimpleWave as falMoneyBillSimpleWave }   from '@fortawesome/pro-light-svg-icons';
import { faMoneyBillSimpleWave as farMoneyBillSimpleWave }   from '@fortawesome/pro-regular-svg-icons';
import { faMoneyBillSimpleWave as fasMoneyBillSimpleWave }   from '@fortawesome/pro-solid-svg-icons';
import { faMoneyBillsSimple as fadMoneyBillsSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoneyBillsSimple as falMoneyBillsSimple }   from '@fortawesome/pro-light-svg-icons';
import { faMoneyBillsSimple as farMoneyBillsSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faMoneyBillsSimple as fasMoneyBillsSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faMoneyBillTransfer as fadMoneyBillTransfer }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoneyBillTransfer as falMoneyBillTransfer }   from '@fortawesome/pro-light-svg-icons';
import { faMoneyBillTransfer as farMoneyBillTransfer }   from '@fortawesome/pro-regular-svg-icons';
import { faMoneyBillTransfer as fasMoneyBillTransfer }   from '@fortawesome/pro-solid-svg-icons';
import { faMoneyBillTrendUp as fadMoneyBillTrendUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoneyBillTrendUp as falMoneyBillTrendUp }   from '@fortawesome/pro-light-svg-icons';
import { faMoneyBillTrendUp as farMoneyBillTrendUp }   from '@fortawesome/pro-regular-svg-icons';
import { faMoneyBillTrendUp as fasMoneyBillTrendUp }   from '@fortawesome/pro-solid-svg-icons';
import { faMoneyBillWave as fadMoneyBillWave }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoneyBillWave as falMoneyBillWave }   from '@fortawesome/pro-light-svg-icons';
import { faMoneyBillWave as farMoneyBillWave }   from '@fortawesome/pro-regular-svg-icons';
import { faMoneyBillWave as fasMoneyBillWave }   from '@fortawesome/pro-solid-svg-icons';
import { faMoneyBillWaveAlt as fadMoneyBillWaveAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoneyBillWaveAlt as falMoneyBillWaveAlt }   from '@fortawesome/pro-light-svg-icons';
import { faMoneyBillWaveAlt as farMoneyBillWaveAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faMoneyBillWaveAlt as fasMoneyBillWaveAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faMoneyBillWheat as fadMoneyBillWheat }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoneyBillWheat as falMoneyBillWheat }   from '@fortawesome/pro-light-svg-icons';
import { faMoneyBillWheat as farMoneyBillWheat }   from '@fortawesome/pro-regular-svg-icons';
import { faMoneyBillWheat as fasMoneyBillWheat }   from '@fortawesome/pro-solid-svg-icons';
import { faMoneyCheck as fadMoneyCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoneyCheck as falMoneyCheck }   from '@fortawesome/pro-light-svg-icons';
import { faMoneyCheck as farMoneyCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faMoneyCheck as fasMoneyCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faMoneyCheckAlt as fadMoneyCheckAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoneyCheckAlt as falMoneyCheckAlt }   from '@fortawesome/pro-light-svg-icons';
import { faMoneyCheckAlt as farMoneyCheckAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faMoneyCheckAlt as fasMoneyCheckAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faMoneyCheckDollar as fadMoneyCheckDollar }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoneyCheckDollar as falMoneyCheckDollar }   from '@fortawesome/pro-light-svg-icons';
import { faMoneyCheckDollar as farMoneyCheckDollar }   from '@fortawesome/pro-regular-svg-icons';
import { faMoneyCheckDollar as fasMoneyCheckDollar }   from '@fortawesome/pro-solid-svg-icons';
import { faMoneyCheckDollarPen as fadMoneyCheckDollarPen }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoneyCheckDollarPen as falMoneyCheckDollarPen }   from '@fortawesome/pro-light-svg-icons';
import { faMoneyCheckDollarPen as farMoneyCheckDollarPen }   from '@fortawesome/pro-regular-svg-icons';
import { faMoneyCheckDollarPen as fasMoneyCheckDollarPen }   from '@fortawesome/pro-solid-svg-icons';
import { faMoneyCheckEdit as fadMoneyCheckEdit }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoneyCheckEdit as falMoneyCheckEdit }   from '@fortawesome/pro-light-svg-icons';
import { faMoneyCheckEdit as farMoneyCheckEdit }   from '@fortawesome/pro-regular-svg-icons';
import { faMoneyCheckEdit as fasMoneyCheckEdit }   from '@fortawesome/pro-solid-svg-icons';
import { faMoneyCheckEditAlt as fadMoneyCheckEditAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoneyCheckEditAlt as falMoneyCheckEditAlt }   from '@fortawesome/pro-light-svg-icons';
import { faMoneyCheckEditAlt as farMoneyCheckEditAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faMoneyCheckEditAlt as fasMoneyCheckEditAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faMoneyCheckPen as fadMoneyCheckPen }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoneyCheckPen as falMoneyCheckPen }   from '@fortawesome/pro-light-svg-icons';
import { faMoneyCheckPen as farMoneyCheckPen }   from '@fortawesome/pro-regular-svg-icons';
import { faMoneyCheckPen as fasMoneyCheckPen }   from '@fortawesome/pro-solid-svg-icons';
import { faMoneyFromBracket as fadMoneyFromBracket }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoneyFromBracket as falMoneyFromBracket }   from '@fortawesome/pro-light-svg-icons';
import { faMoneyFromBracket as farMoneyFromBracket }   from '@fortawesome/pro-regular-svg-icons';
import { faMoneyFromBracket as fasMoneyFromBracket }   from '@fortawesome/pro-solid-svg-icons';
import { faMoneySimpleFromBracket as fadMoneySimpleFromBracket }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoneySimpleFromBracket as falMoneySimpleFromBracket }   from '@fortawesome/pro-light-svg-icons';
import { faMoneySimpleFromBracket as farMoneySimpleFromBracket }   from '@fortawesome/pro-regular-svg-icons';
import { faMoneySimpleFromBracket as fasMoneySimpleFromBracket }   from '@fortawesome/pro-solid-svg-icons';
import { faMonitorHeartRate as fadMonitorHeartRate }   from '@fortawesome/pro-duotone-svg-icons';
import { faMonitorHeartRate as falMonitorHeartRate }   from '@fortawesome/pro-light-svg-icons';
import { faMonitorHeartRate as farMonitorHeartRate }   from '@fortawesome/pro-regular-svg-icons';
import { faMonitorHeartRate as fasMonitorHeartRate }   from '@fortawesome/pro-solid-svg-icons';
import { faMonitorWaveform as fadMonitorWaveform }   from '@fortawesome/pro-duotone-svg-icons';
import { faMonitorWaveform as falMonitorWaveform }   from '@fortawesome/pro-light-svg-icons';
import { faMonitorWaveform as farMonitorWaveform }   from '@fortawesome/pro-regular-svg-icons';
import { faMonitorWaveform as fasMonitorWaveform }   from '@fortawesome/pro-solid-svg-icons';
import { faMonkey as fadMonkey }   from '@fortawesome/pro-duotone-svg-icons';
import { faMonkey as falMonkey }   from '@fortawesome/pro-light-svg-icons';
import { faMonkey as farMonkey }   from '@fortawesome/pro-regular-svg-icons';
import { faMonkey as fasMonkey }   from '@fortawesome/pro-solid-svg-icons';
import { faMonument as fadMonument }   from '@fortawesome/pro-duotone-svg-icons';
import { faMonument as falMonument }   from '@fortawesome/pro-light-svg-icons';
import { faMonument as farMonument }   from '@fortawesome/pro-regular-svg-icons';
import { faMonument as fasMonument }   from '@fortawesome/pro-solid-svg-icons';
import { faMoon as fadMoon }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoon as falMoon }   from '@fortawesome/pro-light-svg-icons';
import { faMoon as farMoon }   from '@fortawesome/pro-regular-svg-icons';
import { faMoon as fasMoon }   from '@fortawesome/pro-solid-svg-icons';
import { faMoonCloud as fadMoonCloud }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoonCloud as falMoonCloud }   from '@fortawesome/pro-light-svg-icons';
import { faMoonCloud as farMoonCloud }   from '@fortawesome/pro-regular-svg-icons';
import { faMoonCloud as fasMoonCloud }   from '@fortawesome/pro-solid-svg-icons';
import { faMoonOverSun as fadMoonOverSun }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoonOverSun as falMoonOverSun }   from '@fortawesome/pro-light-svg-icons';
import { faMoonOverSun as farMoonOverSun }   from '@fortawesome/pro-regular-svg-icons';
import { faMoonOverSun as fasMoonOverSun }   from '@fortawesome/pro-solid-svg-icons';
import { faMoonStars as fadMoonStars }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoonStars as falMoonStars }   from '@fortawesome/pro-light-svg-icons';
import { faMoonStars as farMoonStars }   from '@fortawesome/pro-regular-svg-icons';
import { faMoonStars as fasMoonStars }   from '@fortawesome/pro-solid-svg-icons';
import { faMoped as fadMoped }   from '@fortawesome/pro-duotone-svg-icons';
import { faMoped as falMoped }   from '@fortawesome/pro-light-svg-icons';
import { faMoped as farMoped }   from '@fortawesome/pro-regular-svg-icons';
import { faMoped as fasMoped }   from '@fortawesome/pro-solid-svg-icons';
import { faMortarBoard as fadMortarBoard }   from '@fortawesome/pro-duotone-svg-icons';
import { faMortarBoard as falMortarBoard }   from '@fortawesome/pro-light-svg-icons';
import { faMortarBoard as farMortarBoard }   from '@fortawesome/pro-regular-svg-icons';
import { faMortarBoard as fasMortarBoard }   from '@fortawesome/pro-solid-svg-icons';
import { faMortarPestle as fadMortarPestle }   from '@fortawesome/pro-duotone-svg-icons';
import { faMortarPestle as falMortarPestle }   from '@fortawesome/pro-light-svg-icons';
import { faMortarPestle as farMortarPestle }   from '@fortawesome/pro-regular-svg-icons';
import { faMortarPestle as fasMortarPestle }   from '@fortawesome/pro-solid-svg-icons';
import { faMosque as fadMosque }   from '@fortawesome/pro-duotone-svg-icons';
import { faMosque as falMosque }   from '@fortawesome/pro-light-svg-icons';
import { faMosque as farMosque }   from '@fortawesome/pro-regular-svg-icons';
import { faMosque as fasMosque }   from '@fortawesome/pro-solid-svg-icons';
import { faMosquito as fadMosquito }   from '@fortawesome/pro-duotone-svg-icons';
import { faMosquito as falMosquito }   from '@fortawesome/pro-light-svg-icons';
import { faMosquito as farMosquito }   from '@fortawesome/pro-regular-svg-icons';
import { faMosquito as fasMosquito }   from '@fortawesome/pro-solid-svg-icons';
import { faMosquitoNet as fadMosquitoNet }   from '@fortawesome/pro-duotone-svg-icons';
import { faMosquitoNet as falMosquitoNet }   from '@fortawesome/pro-light-svg-icons';
import { faMosquitoNet as farMosquitoNet }   from '@fortawesome/pro-regular-svg-icons';
import { faMosquitoNet as fasMosquitoNet }   from '@fortawesome/pro-solid-svg-icons';
import { faMotorcycle as fadMotorcycle }   from '@fortawesome/pro-duotone-svg-icons';
import { faMotorcycle as falMotorcycle }   from '@fortawesome/pro-light-svg-icons';
import { faMotorcycle as farMotorcycle }   from '@fortawesome/pro-regular-svg-icons';
import { faMotorcycle as fasMotorcycle }   from '@fortawesome/pro-solid-svg-icons';
import { faMound as fadMound }   from '@fortawesome/pro-duotone-svg-icons';
import { faMound as falMound }   from '@fortawesome/pro-light-svg-icons';
import { faMound as farMound }   from '@fortawesome/pro-regular-svg-icons';
import { faMound as fasMound }   from '@fortawesome/pro-solid-svg-icons';
import { faMountain as fadMountain }   from '@fortawesome/pro-duotone-svg-icons';
import { faMountain as falMountain }   from '@fortawesome/pro-light-svg-icons';
import { faMountain as farMountain }   from '@fortawesome/pro-regular-svg-icons';
import { faMountain as fasMountain }   from '@fortawesome/pro-solid-svg-icons';
import { faMountainCity as fadMountainCity }   from '@fortawesome/pro-duotone-svg-icons';
import { faMountainCity as falMountainCity }   from '@fortawesome/pro-light-svg-icons';
import { faMountainCity as farMountainCity }   from '@fortawesome/pro-regular-svg-icons';
import { faMountainCity as fasMountainCity }   from '@fortawesome/pro-solid-svg-icons';
import { faMountains as fadMountains }   from '@fortawesome/pro-duotone-svg-icons';
import { faMountains as falMountains }   from '@fortawesome/pro-light-svg-icons';
import { faMountains as farMountains }   from '@fortawesome/pro-regular-svg-icons';
import { faMountains as fasMountains }   from '@fortawesome/pro-solid-svg-icons';
import { faMountainSun as fadMountainSun }   from '@fortawesome/pro-duotone-svg-icons';
import { faMountainSun as falMountainSun }   from '@fortawesome/pro-light-svg-icons';
import { faMountainSun as farMountainSun }   from '@fortawesome/pro-regular-svg-icons';
import { faMountainSun as fasMountainSun }   from '@fortawesome/pro-solid-svg-icons';
import { faMouse as fadMouse }   from '@fortawesome/pro-duotone-svg-icons';
import { faMouse as falMouse }   from '@fortawesome/pro-light-svg-icons';
import { faMouse as farMouse }   from '@fortawesome/pro-regular-svg-icons';
import { faMouse as fasMouse }   from '@fortawesome/pro-solid-svg-icons';
import { faMouseAlt as fadMouseAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faMouseAlt as falMouseAlt }   from '@fortawesome/pro-light-svg-icons';
import { faMouseAlt as farMouseAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faMouseAlt as fasMouseAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faMousePointer as fadMousePointer }   from '@fortawesome/pro-duotone-svg-icons';
import { faMousePointer as falMousePointer }   from '@fortawesome/pro-light-svg-icons';
import { faMousePointer as farMousePointer }   from '@fortawesome/pro-regular-svg-icons';
import { faMousePointer as fasMousePointer }   from '@fortawesome/pro-solid-svg-icons';
import { faMp3Player as fadMp3Player }   from '@fortawesome/pro-duotone-svg-icons';
import { faMp3Player as falMp3Player }   from '@fortawesome/pro-light-svg-icons';
import { faMp3Player as farMp3Player }   from '@fortawesome/pro-regular-svg-icons';
import { faMp3Player as fasMp3Player }   from '@fortawesome/pro-solid-svg-icons';
import { faMug as fadMug }   from '@fortawesome/pro-duotone-svg-icons';
import { faMug as falMug }   from '@fortawesome/pro-light-svg-icons';
import { faMug as farMug }   from '@fortawesome/pro-regular-svg-icons';
import { faMug as fasMug }   from '@fortawesome/pro-solid-svg-icons';
import { faMugHot as fadMugHot }   from '@fortawesome/pro-duotone-svg-icons';
import { faMugHot as falMugHot }   from '@fortawesome/pro-light-svg-icons';
import { faMugHot as farMugHot }   from '@fortawesome/pro-regular-svg-icons';
import { faMugHot as fasMugHot }   from '@fortawesome/pro-solid-svg-icons';
import { faMugMarshmallows as fadMugMarshmallows }   from '@fortawesome/pro-duotone-svg-icons';
import { faMugMarshmallows as falMugMarshmallows }   from '@fortawesome/pro-light-svg-icons';
import { faMugMarshmallows as farMugMarshmallows }   from '@fortawesome/pro-regular-svg-icons';
import { faMugMarshmallows as fasMugMarshmallows }   from '@fortawesome/pro-solid-svg-icons';
import { faMugSaucer as fadMugSaucer }   from '@fortawesome/pro-duotone-svg-icons';
import { faMugSaucer as falMugSaucer }   from '@fortawesome/pro-light-svg-icons';
import { faMugSaucer as farMugSaucer }   from '@fortawesome/pro-regular-svg-icons';
import { faMugSaucer as fasMugSaucer }   from '@fortawesome/pro-solid-svg-icons';
import { faMugTea as fadMugTea }   from '@fortawesome/pro-duotone-svg-icons';
import { faMugTea as falMugTea }   from '@fortawesome/pro-light-svg-icons';
import { faMugTea as farMugTea }   from '@fortawesome/pro-regular-svg-icons';
import { faMugTea as fasMugTea }   from '@fortawesome/pro-solid-svg-icons';
import { faMugTeaSaucer as fadMugTeaSaucer }   from '@fortawesome/pro-duotone-svg-icons';
import { faMugTeaSaucer as falMugTeaSaucer }   from '@fortawesome/pro-light-svg-icons';
import { faMugTeaSaucer as farMugTeaSaucer }   from '@fortawesome/pro-regular-svg-icons';
import { faMugTeaSaucer as fasMugTeaSaucer }   from '@fortawesome/pro-solid-svg-icons';
import { faMultiply as fadMultiply }   from '@fortawesome/pro-duotone-svg-icons';
import { faMultiply as falMultiply }   from '@fortawesome/pro-light-svg-icons';
import { faMultiply as farMultiply }   from '@fortawesome/pro-regular-svg-icons';
import { faMultiply as fasMultiply }   from '@fortawesome/pro-solid-svg-icons';
import { faMuseum as fadMuseum }   from '@fortawesome/pro-duotone-svg-icons';
import { faMuseum as falMuseum }   from '@fortawesome/pro-light-svg-icons';
import { faMuseum as farMuseum }   from '@fortawesome/pro-regular-svg-icons';
import { faMuseum as fasMuseum }   from '@fortawesome/pro-solid-svg-icons';
import { faMushroom as fadMushroom }   from '@fortawesome/pro-duotone-svg-icons';
import { faMushroom as falMushroom }   from '@fortawesome/pro-light-svg-icons';
import { faMushroom as farMushroom }   from '@fortawesome/pro-regular-svg-icons';
import { faMushroom as fasMushroom }   from '@fortawesome/pro-solid-svg-icons';
import { faMusic as fadMusic }   from '@fortawesome/pro-duotone-svg-icons';
import { faMusic as falMusic }   from '@fortawesome/pro-light-svg-icons';
import { faMusic as farMusic }   from '@fortawesome/pro-regular-svg-icons';
import { faMusic as fasMusic }   from '@fortawesome/pro-solid-svg-icons';
import { faMusicAlt as fadMusicAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faMusicAlt as falMusicAlt }   from '@fortawesome/pro-light-svg-icons';
import { faMusicAlt as farMusicAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faMusicAlt as fasMusicAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faMusicAltSlash as fadMusicAltSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faMusicAltSlash as falMusicAltSlash }   from '@fortawesome/pro-light-svg-icons';
import { faMusicAltSlash as farMusicAltSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faMusicAltSlash as fasMusicAltSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faMusicNote as fadMusicNote }   from '@fortawesome/pro-duotone-svg-icons';
import { faMusicNote as falMusicNote }   from '@fortawesome/pro-light-svg-icons';
import { faMusicNote as farMusicNote }   from '@fortawesome/pro-regular-svg-icons';
import { faMusicNote as fasMusicNote }   from '@fortawesome/pro-solid-svg-icons';
import { faMusicNoteSlash as fadMusicNoteSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faMusicNoteSlash as falMusicNoteSlash }   from '@fortawesome/pro-light-svg-icons';
import { faMusicNoteSlash as farMusicNoteSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faMusicNoteSlash as fasMusicNoteSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faMusicSlash as fadMusicSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faMusicSlash as falMusicSlash }   from '@fortawesome/pro-light-svg-icons';
import { faMusicSlash as farMusicSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faMusicSlash as fasMusicSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faN as fadN }   from '@fortawesome/pro-duotone-svg-icons';
import { faN as falN }   from '@fortawesome/pro-light-svg-icons';
import { faN as farN }   from '@fortawesome/pro-regular-svg-icons';
import { faN as fasN }   from '@fortawesome/pro-solid-svg-icons';
import { faNairaSign as fadNairaSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faNairaSign as falNairaSign }   from '@fortawesome/pro-light-svg-icons';
import { faNairaSign as farNairaSign }   from '@fortawesome/pro-regular-svg-icons';
import { faNairaSign as fasNairaSign }   from '@fortawesome/pro-solid-svg-icons';
import { faNarwhal as fadNarwhal }   from '@fortawesome/pro-duotone-svg-icons';
import { faNarwhal as falNarwhal }   from '@fortawesome/pro-light-svg-icons';
import { faNarwhal as farNarwhal }   from '@fortawesome/pro-regular-svg-icons';
import { faNarwhal as fasNarwhal }   from '@fortawesome/pro-solid-svg-icons';
import { faNavicon as fadNavicon }   from '@fortawesome/pro-duotone-svg-icons';
import { faNavicon as falNavicon }   from '@fortawesome/pro-light-svg-icons';
import { faNavicon as farNavicon }   from '@fortawesome/pro-regular-svg-icons';
import { faNavicon as fasNavicon }   from '@fortawesome/pro-solid-svg-icons';
import { faNestingDolls as fadNestingDolls }   from '@fortawesome/pro-duotone-svg-icons';
import { faNestingDolls as falNestingDolls }   from '@fortawesome/pro-light-svg-icons';
import { faNestingDolls as farNestingDolls }   from '@fortawesome/pro-regular-svg-icons';
import { faNestingDolls as fasNestingDolls }   from '@fortawesome/pro-solid-svg-icons';
import { faNetworkWired as fadNetworkWired }   from '@fortawesome/pro-duotone-svg-icons';
import { faNetworkWired as falNetworkWired }   from '@fortawesome/pro-light-svg-icons';
import { faNetworkWired as farNetworkWired }   from '@fortawesome/pro-regular-svg-icons';
import { faNetworkWired as fasNetworkWired }   from '@fortawesome/pro-solid-svg-icons';
import { faNeuter as fadNeuter }   from '@fortawesome/pro-duotone-svg-icons';
import { faNeuter as falNeuter }   from '@fortawesome/pro-light-svg-icons';
import { faNeuter as farNeuter }   from '@fortawesome/pro-regular-svg-icons';
import { faNeuter as fasNeuter }   from '@fortawesome/pro-solid-svg-icons';
import { faNewspaper as fadNewspaper }   from '@fortawesome/pro-duotone-svg-icons';
import { faNewspaper as falNewspaper }   from '@fortawesome/pro-light-svg-icons';
import { faNewspaper as farNewspaper }   from '@fortawesome/pro-regular-svg-icons';
import { faNewspaper as fasNewspaper }   from '@fortawesome/pro-solid-svg-icons';
import { faNfc as fadNfc }   from '@fortawesome/pro-duotone-svg-icons';
import { faNfc as falNfc }   from '@fortawesome/pro-light-svg-icons';
import { faNfc as farNfc }   from '@fortawesome/pro-regular-svg-icons';
import { faNfc as fasNfc }   from '@fortawesome/pro-solid-svg-icons';
import { faNfcLock as fadNfcLock }   from '@fortawesome/pro-duotone-svg-icons';
import { faNfcLock as falNfcLock }   from '@fortawesome/pro-light-svg-icons';
import { faNfcLock as farNfcLock }   from '@fortawesome/pro-regular-svg-icons';
import { faNfcLock as fasNfcLock }   from '@fortawesome/pro-solid-svg-icons';
import { faNfcMagnifyingGlass as fadNfcMagnifyingGlass }   from '@fortawesome/pro-duotone-svg-icons';
import { faNfcMagnifyingGlass as falNfcMagnifyingGlass }   from '@fortawesome/pro-light-svg-icons';
import { faNfcMagnifyingGlass as farNfcMagnifyingGlass }   from '@fortawesome/pro-regular-svg-icons';
import { faNfcMagnifyingGlass as fasNfcMagnifyingGlass }   from '@fortawesome/pro-solid-svg-icons';
import { faNfcPen as fadNfcPen }   from '@fortawesome/pro-duotone-svg-icons';
import { faNfcPen as falNfcPen }   from '@fortawesome/pro-light-svg-icons';
import { faNfcPen as farNfcPen }   from '@fortawesome/pro-regular-svg-icons';
import { faNfcPen as fasNfcPen }   from '@fortawesome/pro-solid-svg-icons';
import { faNfcSignal as fadNfcSignal }   from '@fortawesome/pro-duotone-svg-icons';
import { faNfcSignal as falNfcSignal }   from '@fortawesome/pro-light-svg-icons';
import { faNfcSignal as farNfcSignal }   from '@fortawesome/pro-regular-svg-icons';
import { faNfcSignal as fasNfcSignal }   from '@fortawesome/pro-solid-svg-icons';
import { faNfcSlash as fadNfcSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faNfcSlash as falNfcSlash }   from '@fortawesome/pro-light-svg-icons';
import { faNfcSlash as farNfcSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faNfcSlash as fasNfcSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faNfcSymbol as fadNfcSymbol }   from '@fortawesome/pro-duotone-svg-icons';
import { faNfcSymbol as falNfcSymbol }   from '@fortawesome/pro-light-svg-icons';
import { faNfcSymbol as farNfcSymbol }   from '@fortawesome/pro-regular-svg-icons';
import { faNfcSymbol as fasNfcSymbol }   from '@fortawesome/pro-solid-svg-icons';
import { faNfcTrash as fadNfcTrash }   from '@fortawesome/pro-duotone-svg-icons';
import { faNfcTrash as falNfcTrash }   from '@fortawesome/pro-light-svg-icons';
import { faNfcTrash as farNfcTrash }   from '@fortawesome/pro-regular-svg-icons';
import { faNfcTrash as fasNfcTrash }   from '@fortawesome/pro-solid-svg-icons';
import { faNigiri as fadNigiri }   from '@fortawesome/pro-duotone-svg-icons';
import { faNigiri as falNigiri }   from '@fortawesome/pro-light-svg-icons';
import { faNigiri as farNigiri }   from '@fortawesome/pro-regular-svg-icons';
import { faNigiri as fasNigiri }   from '@fortawesome/pro-solid-svg-icons';
import { faNotdef as fadNotdef }   from '@fortawesome/pro-duotone-svg-icons';
import { faNotdef as falNotdef }   from '@fortawesome/pro-light-svg-icons';
import { faNotdef as farNotdef }   from '@fortawesome/pro-regular-svg-icons';
import { faNotdef as fasNotdef }   from '@fortawesome/pro-solid-svg-icons';
import { faNote as fadNote }   from '@fortawesome/pro-duotone-svg-icons';
import { faNote as falNote }   from '@fortawesome/pro-light-svg-icons';
import { faNote as farNote }   from '@fortawesome/pro-regular-svg-icons';
import { faNote as fasNote }   from '@fortawesome/pro-solid-svg-icons';
import { faNotebook as fadNotebook }   from '@fortawesome/pro-duotone-svg-icons';
import { faNotebook as falNotebook }   from '@fortawesome/pro-light-svg-icons';
import { faNotebook as farNotebook }   from '@fortawesome/pro-regular-svg-icons';
import { faNotebook as fasNotebook }   from '@fortawesome/pro-solid-svg-icons';
import { faNoteMedical as fadNoteMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faNoteMedical as falNoteMedical }   from '@fortawesome/pro-light-svg-icons';
import { faNoteMedical as farNoteMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faNoteMedical as fasNoteMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faNotEqual as fadNotEqual }   from '@fortawesome/pro-duotone-svg-icons';
import { faNotEqual as falNotEqual }   from '@fortawesome/pro-light-svg-icons';
import { faNotEqual as farNotEqual }   from '@fortawesome/pro-regular-svg-icons';
import { faNotEqual as fasNotEqual }   from '@fortawesome/pro-solid-svg-icons';
import { faNotes as fadNotes }   from '@fortawesome/pro-duotone-svg-icons';
import { faNotes as falNotes }   from '@fortawesome/pro-light-svg-icons';
import { faNotes as farNotes }   from '@fortawesome/pro-regular-svg-icons';
import { faNotes as fasNotes }   from '@fortawesome/pro-solid-svg-icons';
import { faNotesMedical as fadNotesMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faNotesMedical as falNotesMedical }   from '@fortawesome/pro-light-svg-icons';
import { faNotesMedical as farNotesMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faNotesMedical as fasNotesMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faNoteSticky as fadNoteSticky }   from '@fortawesome/pro-duotone-svg-icons';
import { faNoteSticky as falNoteSticky }   from '@fortawesome/pro-light-svg-icons';
import { faNoteSticky as farNoteSticky }   from '@fortawesome/pro-regular-svg-icons';
import { faNoteSticky as fasNoteSticky }   from '@fortawesome/pro-solid-svg-icons';
import { faO as fadO }   from '@fortawesome/pro-duotone-svg-icons';
import { faO as falO }   from '@fortawesome/pro-light-svg-icons';
import { faO as farO }   from '@fortawesome/pro-regular-svg-icons';
import { faO as fasO }   from '@fortawesome/pro-solid-svg-icons';
import { faObjectExclude as fadObjectExclude }   from '@fortawesome/pro-duotone-svg-icons';
import { faObjectExclude as falObjectExclude }   from '@fortawesome/pro-light-svg-icons';
import { faObjectExclude as farObjectExclude }   from '@fortawesome/pro-regular-svg-icons';
import { faObjectExclude as fasObjectExclude }   from '@fortawesome/pro-solid-svg-icons';
import { faObjectGroup as fadObjectGroup }   from '@fortawesome/pro-duotone-svg-icons';
import { faObjectGroup as falObjectGroup }   from '@fortawesome/pro-light-svg-icons';
import { faObjectGroup as farObjectGroup }   from '@fortawesome/pro-regular-svg-icons';
import { faObjectGroup as fasObjectGroup }   from '@fortawesome/pro-solid-svg-icons';
import { faObjectIntersect as fadObjectIntersect }   from '@fortawesome/pro-duotone-svg-icons';
import { faObjectIntersect as falObjectIntersect }   from '@fortawesome/pro-light-svg-icons';
import { faObjectIntersect as farObjectIntersect }   from '@fortawesome/pro-regular-svg-icons';
import { faObjectIntersect as fasObjectIntersect }   from '@fortawesome/pro-solid-svg-icons';
import { faObjectsAlignBottom as fadObjectsAlignBottom }   from '@fortawesome/pro-duotone-svg-icons';
import { faObjectsAlignBottom as falObjectsAlignBottom }   from '@fortawesome/pro-light-svg-icons';
import { faObjectsAlignBottom as farObjectsAlignBottom }   from '@fortawesome/pro-regular-svg-icons';
import { faObjectsAlignBottom as fasObjectsAlignBottom }   from '@fortawesome/pro-solid-svg-icons';
import { faObjectsAlignCenterHorizontal as fadObjectsAlignCenterHorizontal }   from '@fortawesome/pro-duotone-svg-icons';
import { faObjectsAlignCenterHorizontal as falObjectsAlignCenterHorizontal }   from '@fortawesome/pro-light-svg-icons';
import { faObjectsAlignCenterHorizontal as farObjectsAlignCenterHorizontal }   from '@fortawesome/pro-regular-svg-icons';
import { faObjectsAlignCenterHorizontal as fasObjectsAlignCenterHorizontal }   from '@fortawesome/pro-solid-svg-icons';
import { faObjectsAlignCenterVertical as fadObjectsAlignCenterVertical }   from '@fortawesome/pro-duotone-svg-icons';
import { faObjectsAlignCenterVertical as falObjectsAlignCenterVertical }   from '@fortawesome/pro-light-svg-icons';
import { faObjectsAlignCenterVertical as farObjectsAlignCenterVertical }   from '@fortawesome/pro-regular-svg-icons';
import { faObjectsAlignCenterVertical as fasObjectsAlignCenterVertical }   from '@fortawesome/pro-solid-svg-icons';
import { faObjectsAlignLeft as fadObjectsAlignLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faObjectsAlignLeft as falObjectsAlignLeft }   from '@fortawesome/pro-light-svg-icons';
import { faObjectsAlignLeft as farObjectsAlignLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faObjectsAlignLeft as fasObjectsAlignLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faObjectsAlignRight as fadObjectsAlignRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faObjectsAlignRight as falObjectsAlignRight }   from '@fortawesome/pro-light-svg-icons';
import { faObjectsAlignRight as farObjectsAlignRight }   from '@fortawesome/pro-regular-svg-icons';
import { faObjectsAlignRight as fasObjectsAlignRight }   from '@fortawesome/pro-solid-svg-icons';
import { faObjectsAlignTop as fadObjectsAlignTop }   from '@fortawesome/pro-duotone-svg-icons';
import { faObjectsAlignTop as falObjectsAlignTop }   from '@fortawesome/pro-light-svg-icons';
import { faObjectsAlignTop as farObjectsAlignTop }   from '@fortawesome/pro-regular-svg-icons';
import { faObjectsAlignTop as fasObjectsAlignTop }   from '@fortawesome/pro-solid-svg-icons';
import { faObjectsColumn as fadObjectsColumn }   from '@fortawesome/pro-duotone-svg-icons';
import { faObjectsColumn as falObjectsColumn }   from '@fortawesome/pro-light-svg-icons';
import { faObjectsColumn as farObjectsColumn }   from '@fortawesome/pro-regular-svg-icons';
import { faObjectsColumn as fasObjectsColumn }   from '@fortawesome/pro-solid-svg-icons';
import { faObjectSubtract as fadObjectSubtract }   from '@fortawesome/pro-duotone-svg-icons';
import { faObjectSubtract as falObjectSubtract }   from '@fortawesome/pro-light-svg-icons';
import { faObjectSubtract as farObjectSubtract }   from '@fortawesome/pro-regular-svg-icons';
import { faObjectSubtract as fasObjectSubtract }   from '@fortawesome/pro-solid-svg-icons';
import { faObjectUngroup as fadObjectUngroup }   from '@fortawesome/pro-duotone-svg-icons';
import { faObjectUngroup as falObjectUngroup }   from '@fortawesome/pro-light-svg-icons';
import { faObjectUngroup as farObjectUngroup }   from '@fortawesome/pro-regular-svg-icons';
import { faObjectUngroup as fasObjectUngroup }   from '@fortawesome/pro-solid-svg-icons';
import { faObjectUnion as fadObjectUnion }   from '@fortawesome/pro-duotone-svg-icons';
import { faObjectUnion as falObjectUnion }   from '@fortawesome/pro-light-svg-icons';
import { faObjectUnion as farObjectUnion }   from '@fortawesome/pro-regular-svg-icons';
import { faObjectUnion as fasObjectUnion }   from '@fortawesome/pro-solid-svg-icons';
import { faOctagon as fadOctagon }   from '@fortawesome/pro-duotone-svg-icons';
import { faOctagon as falOctagon }   from '@fortawesome/pro-light-svg-icons';
import { faOctagon as farOctagon }   from '@fortawesome/pro-regular-svg-icons';
import { faOctagon as fasOctagon }   from '@fortawesome/pro-solid-svg-icons';
import { faOctagonCheck as fadOctagonCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faOctagonCheck as falOctagonCheck }   from '@fortawesome/pro-light-svg-icons';
import { faOctagonCheck as farOctagonCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faOctagonCheck as fasOctagonCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faOctagonDivide as fadOctagonDivide }   from '@fortawesome/pro-duotone-svg-icons';
import { faOctagonDivide as falOctagonDivide }   from '@fortawesome/pro-light-svg-icons';
import { faOctagonDivide as farOctagonDivide }   from '@fortawesome/pro-regular-svg-icons';
import { faOctagonDivide as fasOctagonDivide }   from '@fortawesome/pro-solid-svg-icons';
import { faOctagonExclamation as fadOctagonExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faOctagonExclamation as falOctagonExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faOctagonExclamation as farOctagonExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faOctagonExclamation as fasOctagonExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faOctagonMinus as fadOctagonMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faOctagonMinus as falOctagonMinus }   from '@fortawesome/pro-light-svg-icons';
import { faOctagonMinus as farOctagonMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faOctagonMinus as fasOctagonMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faOctagonPlus as fadOctagonPlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faOctagonPlus as falOctagonPlus }   from '@fortawesome/pro-light-svg-icons';
import { faOctagonPlus as farOctagonPlus }   from '@fortawesome/pro-regular-svg-icons';
import { faOctagonPlus as fasOctagonPlus }   from '@fortawesome/pro-solid-svg-icons';
import { faOctagonXmark as fadOctagonXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faOctagonXmark as falOctagonXmark }   from '@fortawesome/pro-light-svg-icons';
import { faOctagonXmark as farOctagonXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faOctagonXmark as fasOctagonXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faOilCan as fadOilCan }   from '@fortawesome/pro-duotone-svg-icons';
import { faOilCan as falOilCan }   from '@fortawesome/pro-light-svg-icons';
import { faOilCan as farOilCan }   from '@fortawesome/pro-regular-svg-icons';
import { faOilCan as fasOilCan }   from '@fortawesome/pro-solid-svg-icons';
import { faOilCanDrip as fadOilCanDrip }   from '@fortawesome/pro-duotone-svg-icons';
import { faOilCanDrip as falOilCanDrip }   from '@fortawesome/pro-light-svg-icons';
import { faOilCanDrip as farOilCanDrip }   from '@fortawesome/pro-regular-svg-icons';
import { faOilCanDrip as fasOilCanDrip }   from '@fortawesome/pro-solid-svg-icons';
import { faOilTemp as fadOilTemp }   from '@fortawesome/pro-duotone-svg-icons';
import { faOilTemp as falOilTemp }   from '@fortawesome/pro-light-svg-icons';
import { faOilTemp as farOilTemp }   from '@fortawesome/pro-regular-svg-icons';
import { faOilTemp as fasOilTemp }   from '@fortawesome/pro-solid-svg-icons';
import { faOilTemperature as fadOilTemperature }   from '@fortawesome/pro-duotone-svg-icons';
import { faOilTemperature as falOilTemperature }   from '@fortawesome/pro-light-svg-icons';
import { faOilTemperature as farOilTemperature }   from '@fortawesome/pro-regular-svg-icons';
import { faOilTemperature as fasOilTemperature }   from '@fortawesome/pro-solid-svg-icons';
import { faOilWell as fadOilWell }   from '@fortawesome/pro-duotone-svg-icons';
import { faOilWell as falOilWell }   from '@fortawesome/pro-light-svg-icons';
import { faOilWell as farOilWell }   from '@fortawesome/pro-regular-svg-icons';
import { faOilWell as fasOilWell }   from '@fortawesome/pro-solid-svg-icons';
import { faOlive as fadOlive }   from '@fortawesome/pro-duotone-svg-icons';
import { faOlive as falOlive }   from '@fortawesome/pro-light-svg-icons';
import { faOlive as farOlive }   from '@fortawesome/pro-regular-svg-icons';
import { faOlive as fasOlive }   from '@fortawesome/pro-solid-svg-icons';
import { faOliveBranch as fadOliveBranch }   from '@fortawesome/pro-duotone-svg-icons';
import { faOliveBranch as falOliveBranch }   from '@fortawesome/pro-light-svg-icons';
import { faOliveBranch as farOliveBranch }   from '@fortawesome/pro-regular-svg-icons';
import { faOliveBranch as fasOliveBranch }   from '@fortawesome/pro-solid-svg-icons';
import { faOm as fadOm }   from '@fortawesome/pro-duotone-svg-icons';
import { faOm as falOm }   from '@fortawesome/pro-light-svg-icons';
import { faOm as farOm }   from '@fortawesome/pro-regular-svg-icons';
import { faOm as fasOm }   from '@fortawesome/pro-solid-svg-icons';
import { faOmega as fadOmega }   from '@fortawesome/pro-duotone-svg-icons';
import { faOmega as falOmega }   from '@fortawesome/pro-light-svg-icons';
import { faOmega as farOmega }   from '@fortawesome/pro-regular-svg-icons';
import { faOmega as fasOmega }   from '@fortawesome/pro-solid-svg-icons';
import { faOnion as fadOnion }   from '@fortawesome/pro-duotone-svg-icons';
import { faOnion as falOnion }   from '@fortawesome/pro-light-svg-icons';
import { faOnion as farOnion }   from '@fortawesome/pro-regular-svg-icons';
import { faOnion as fasOnion }   from '@fortawesome/pro-solid-svg-icons';
import { faOption as fadOption }   from '@fortawesome/pro-duotone-svg-icons';
import { faOption as falOption }   from '@fortawesome/pro-light-svg-icons';
import { faOption as farOption }   from '@fortawesome/pro-regular-svg-icons';
import { faOption as fasOption }   from '@fortawesome/pro-solid-svg-icons';
import { faOrnament as fadOrnament }   from '@fortawesome/pro-duotone-svg-icons';
import { faOrnament as falOrnament }   from '@fortawesome/pro-light-svg-icons';
import { faOrnament as farOrnament }   from '@fortawesome/pro-regular-svg-icons';
import { faOrnament as fasOrnament }   from '@fortawesome/pro-solid-svg-icons';
import { faOtter as fadOtter }   from '@fortawesome/pro-duotone-svg-icons';
import { faOtter as falOtter }   from '@fortawesome/pro-light-svg-icons';
import { faOtter as farOtter }   from '@fortawesome/pro-regular-svg-icons';
import { faOtter as fasOtter }   from '@fortawesome/pro-solid-svg-icons';
import { faOutdent as fadOutdent }   from '@fortawesome/pro-duotone-svg-icons';
import { faOutdent as falOutdent }   from '@fortawesome/pro-light-svg-icons';
import { faOutdent as farOutdent }   from '@fortawesome/pro-regular-svg-icons';
import { faOutdent as fasOutdent }   from '@fortawesome/pro-solid-svg-icons';
import { faOutlet as fadOutlet }   from '@fortawesome/pro-duotone-svg-icons';
import { faOutlet as falOutlet }   from '@fortawesome/pro-light-svg-icons';
import { faOutlet as farOutlet }   from '@fortawesome/pro-regular-svg-icons';
import { faOutlet as fasOutlet }   from '@fortawesome/pro-solid-svg-icons';
import { faOven as fadOven }   from '@fortawesome/pro-duotone-svg-icons';
import { faOven as falOven }   from '@fortawesome/pro-light-svg-icons';
import { faOven as farOven }   from '@fortawesome/pro-regular-svg-icons';
import { faOven as fasOven }   from '@fortawesome/pro-solid-svg-icons';
import { faOverline as fadOverline }   from '@fortawesome/pro-duotone-svg-icons';
import { faOverline as falOverline }   from '@fortawesome/pro-light-svg-icons';
import { faOverline as farOverline }   from '@fortawesome/pro-regular-svg-icons';
import { faOverline as fasOverline }   from '@fortawesome/pro-solid-svg-icons';
import { faP as fadP }   from '@fortawesome/pro-duotone-svg-icons';
import { faP as falP }   from '@fortawesome/pro-light-svg-icons';
import { faP as farP }   from '@fortawesome/pro-regular-svg-icons';
import { faP as fasP }   from '@fortawesome/pro-solid-svg-icons';
import { faPage as fadPage }   from '@fortawesome/pro-duotone-svg-icons';
import { faPage as falPage }   from '@fortawesome/pro-light-svg-icons';
import { faPage as farPage }   from '@fortawesome/pro-regular-svg-icons';
import { faPage as fasPage }   from '@fortawesome/pro-solid-svg-icons';
import { faPageBreak as fadPageBreak }   from '@fortawesome/pro-duotone-svg-icons';
import { faPageBreak as falPageBreak }   from '@fortawesome/pro-light-svg-icons';
import { faPageBreak as farPageBreak }   from '@fortawesome/pro-regular-svg-icons';
import { faPageBreak as fasPageBreak }   from '@fortawesome/pro-solid-svg-icons';
import { faPageCaretDown as fadPageCaretDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faPageCaretDown as falPageCaretDown }   from '@fortawesome/pro-light-svg-icons';
import { faPageCaretDown as farPageCaretDown }   from '@fortawesome/pro-regular-svg-icons';
import { faPageCaretDown as fasPageCaretDown }   from '@fortawesome/pro-solid-svg-icons';
import { faPageCaretUp as fadPageCaretUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faPageCaretUp as falPageCaretUp }   from '@fortawesome/pro-light-svg-icons';
import { faPageCaretUp as farPageCaretUp }   from '@fortawesome/pro-regular-svg-icons';
import { faPageCaretUp as fasPageCaretUp }   from '@fortawesome/pro-solid-svg-icons';
import { faPager as fadPager }   from '@fortawesome/pro-duotone-svg-icons';
import { faPager as falPager }   from '@fortawesome/pro-light-svg-icons';
import { faPager as farPager }   from '@fortawesome/pro-regular-svg-icons';
import { faPager as fasPager }   from '@fortawesome/pro-solid-svg-icons';
import { faPaintbrush as fadPaintbrush }   from '@fortawesome/pro-duotone-svg-icons';
import { faPaintBrush as fadPaintBrush }   from '@fortawesome/pro-duotone-svg-icons';
import { faPaintbrush as falPaintbrush }   from '@fortawesome/pro-light-svg-icons';
import { faPaintBrush as falPaintBrush }   from '@fortawesome/pro-light-svg-icons';
import { faPaintbrush as farPaintbrush }   from '@fortawesome/pro-regular-svg-icons';
import { faPaintBrush as farPaintBrush }   from '@fortawesome/pro-regular-svg-icons';
import { faPaintbrush as fasPaintbrush }   from '@fortawesome/pro-solid-svg-icons';
import { faPaintBrush as fasPaintBrush }   from '@fortawesome/pro-solid-svg-icons';
import { faPaintBrushAlt as fadPaintBrushAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faPaintbrushAlt as fadPaintbrushAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faPaintBrushAlt as falPaintBrushAlt }   from '@fortawesome/pro-light-svg-icons';
import { faPaintbrushAlt as falPaintbrushAlt }   from '@fortawesome/pro-light-svg-icons';
import { faPaintBrushAlt as farPaintBrushAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faPaintbrushAlt as farPaintbrushAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faPaintBrushAlt as fasPaintBrushAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faPaintbrushAlt as fasPaintbrushAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faPaintbrushFine as fadPaintbrushFine }   from '@fortawesome/pro-duotone-svg-icons';
import { faPaintBrushFine as fadPaintBrushFine }   from '@fortawesome/pro-duotone-svg-icons';
import { faPaintbrushFine as falPaintbrushFine }   from '@fortawesome/pro-light-svg-icons';
import { faPaintBrushFine as falPaintBrushFine }   from '@fortawesome/pro-light-svg-icons';
import { faPaintbrushFine as farPaintbrushFine }   from '@fortawesome/pro-regular-svg-icons';
import { faPaintBrushFine as farPaintBrushFine }   from '@fortawesome/pro-regular-svg-icons';
import { faPaintbrushFine as fasPaintbrushFine }   from '@fortawesome/pro-solid-svg-icons';
import { faPaintBrushFine as fasPaintBrushFine }   from '@fortawesome/pro-solid-svg-icons';
import { faPaintbrushPencil as fadPaintbrushPencil }   from '@fortawesome/pro-duotone-svg-icons';
import { faPaintbrushPencil as falPaintbrushPencil }   from '@fortawesome/pro-light-svg-icons';
import { faPaintbrushPencil as farPaintbrushPencil }   from '@fortawesome/pro-regular-svg-icons';
import { faPaintbrushPencil as fasPaintbrushPencil }   from '@fortawesome/pro-solid-svg-icons';
import { faPaintRoller as fadPaintRoller }   from '@fortawesome/pro-duotone-svg-icons';
import { faPaintRoller as falPaintRoller }   from '@fortawesome/pro-light-svg-icons';
import { faPaintRoller as farPaintRoller }   from '@fortawesome/pro-regular-svg-icons';
import { faPaintRoller as fasPaintRoller }   from '@fortawesome/pro-solid-svg-icons';
import { faPalette as fadPalette }   from '@fortawesome/pro-duotone-svg-icons';
import { faPalette as falPalette }   from '@fortawesome/pro-light-svg-icons';
import { faPalette as farPalette }   from '@fortawesome/pro-regular-svg-icons';
import { faPalette as fasPalette }   from '@fortawesome/pro-solid-svg-icons';
import { faPaletteBoxes as fadPaletteBoxes }   from '@fortawesome/pro-duotone-svg-icons';
import { faPaletteBoxes as falPaletteBoxes }   from '@fortawesome/pro-light-svg-icons';
import { faPaletteBoxes as farPaletteBoxes }   from '@fortawesome/pro-regular-svg-icons';
import { faPaletteBoxes as fasPaletteBoxes }   from '@fortawesome/pro-solid-svg-icons';
import { faPallet as fadPallet }   from '@fortawesome/pro-duotone-svg-icons';
import { faPallet as falPallet }   from '@fortawesome/pro-light-svg-icons';
import { faPallet as farPallet }   from '@fortawesome/pro-regular-svg-icons';
import { faPallet as fasPallet }   from '@fortawesome/pro-solid-svg-icons';
import { faPalletAlt as fadPalletAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faPalletAlt as falPalletAlt }   from '@fortawesome/pro-light-svg-icons';
import { faPalletAlt as farPalletAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faPalletAlt as fasPalletAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faPalletBox as fadPalletBox }   from '@fortawesome/pro-duotone-svg-icons';
import { faPalletBox as falPalletBox }   from '@fortawesome/pro-light-svg-icons';
import { faPalletBox as farPalletBox }   from '@fortawesome/pro-regular-svg-icons';
import { faPalletBox as fasPalletBox }   from '@fortawesome/pro-solid-svg-icons';
import { faPalletBoxes as fadPalletBoxes }   from '@fortawesome/pro-duotone-svg-icons';
import { faPalletBoxes as falPalletBoxes }   from '@fortawesome/pro-light-svg-icons';
import { faPalletBoxes as farPalletBoxes }   from '@fortawesome/pro-regular-svg-icons';
import { faPalletBoxes as fasPalletBoxes }   from '@fortawesome/pro-solid-svg-icons';
import { faPancakes as fadPancakes }   from '@fortawesome/pro-duotone-svg-icons';
import { faPancakes as falPancakes }   from '@fortawesome/pro-light-svg-icons';
import { faPancakes as farPancakes }   from '@fortawesome/pro-regular-svg-icons';
import { faPancakes as fasPancakes }   from '@fortawesome/pro-solid-svg-icons';
import { faPanelEws as fadPanelEws }   from '@fortawesome/pro-duotone-svg-icons';
import { faPanelEws as falPanelEws }   from '@fortawesome/pro-light-svg-icons';
import { faPanelEws as farPanelEws }   from '@fortawesome/pro-regular-svg-icons';
import { faPanelEws as fasPanelEws }   from '@fortawesome/pro-solid-svg-icons';
import { faPanelFire as fadPanelFire }   from '@fortawesome/pro-duotone-svg-icons';
import { faPanelFire as falPanelFire }   from '@fortawesome/pro-light-svg-icons';
import { faPanelFire as farPanelFire }   from '@fortawesome/pro-regular-svg-icons';
import { faPanelFire as fasPanelFire }   from '@fortawesome/pro-solid-svg-icons';
import { faPanFood as fadPanFood }   from '@fortawesome/pro-duotone-svg-icons';
import { faPanFood as falPanFood }   from '@fortawesome/pro-light-svg-icons';
import { faPanFood as farPanFood }   from '@fortawesome/pro-regular-svg-icons';
import { faPanFood as fasPanFood }   from '@fortawesome/pro-solid-svg-icons';
import { faPanFrying as fadPanFrying }   from '@fortawesome/pro-duotone-svg-icons';
import { faPanFrying as falPanFrying }   from '@fortawesome/pro-light-svg-icons';
import { faPanFrying as farPanFrying }   from '@fortawesome/pro-regular-svg-icons';
import { faPanFrying as fasPanFrying }   from '@fortawesome/pro-solid-svg-icons';
import { faPanorama as fadPanorama }   from '@fortawesome/pro-duotone-svg-icons';
import { faPanorama as falPanorama }   from '@fortawesome/pro-light-svg-icons';
import { faPanorama as farPanorama }   from '@fortawesome/pro-regular-svg-icons';
import { faPanorama as fasPanorama }   from '@fortawesome/pro-solid-svg-icons';
import { faPaperclip as fadPaperclip }   from '@fortawesome/pro-duotone-svg-icons';
import { faPaperclip as falPaperclip }   from '@fortawesome/pro-light-svg-icons';
import { faPaperclip as farPaperclip }   from '@fortawesome/pro-regular-svg-icons';
import { faPaperclip as fasPaperclip }   from '@fortawesome/pro-solid-svg-icons';
import { faPaperclipVertical as fadPaperclipVertical }   from '@fortawesome/pro-duotone-svg-icons';
import { faPaperclipVertical as falPaperclipVertical }   from '@fortawesome/pro-light-svg-icons';
import { faPaperclipVertical as farPaperclipVertical }   from '@fortawesome/pro-regular-svg-icons';
import { faPaperclipVertical as fasPaperclipVertical }   from '@fortawesome/pro-solid-svg-icons';
import { faPaperPlane as fadPaperPlane }   from '@fortawesome/pro-duotone-svg-icons';
import { faPaperPlane as falPaperPlane }   from '@fortawesome/pro-light-svg-icons';
import { faPaperPlane as farPaperPlane }   from '@fortawesome/pro-regular-svg-icons';
import { faPaperPlane as fasPaperPlane }   from '@fortawesome/pro-solid-svg-icons';
import { faPaperPlaneAlt as fadPaperPlaneAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faPaperPlaneAlt as falPaperPlaneAlt }   from '@fortawesome/pro-light-svg-icons';
import { faPaperPlaneAlt as farPaperPlaneAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faPaperPlaneAlt as fasPaperPlaneAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faPaperPlaneTop as fadPaperPlaneTop }   from '@fortawesome/pro-duotone-svg-icons';
import { faPaperPlaneTop as falPaperPlaneTop }   from '@fortawesome/pro-light-svg-icons';
import { faPaperPlaneTop as farPaperPlaneTop }   from '@fortawesome/pro-regular-svg-icons';
import { faPaperPlaneTop as fasPaperPlaneTop }   from '@fortawesome/pro-solid-svg-icons';
import { faParachuteBox as fadParachuteBox }   from '@fortawesome/pro-duotone-svg-icons';
import { faParachuteBox as falParachuteBox }   from '@fortawesome/pro-light-svg-icons';
import { faParachuteBox as farParachuteBox }   from '@fortawesome/pro-regular-svg-icons';
import { faParachuteBox as fasParachuteBox }   from '@fortawesome/pro-solid-svg-icons';
import { faParagraph as fadParagraph }   from '@fortawesome/pro-duotone-svg-icons';
import { faParagraph as falParagraph }   from '@fortawesome/pro-light-svg-icons';
import { faParagraph as farParagraph }   from '@fortawesome/pro-regular-svg-icons';
import { faParagraph as fasParagraph }   from '@fortawesome/pro-solid-svg-icons';
import { faParagraphLeft as fadParagraphLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faParagraphLeft as falParagraphLeft }   from '@fortawesome/pro-light-svg-icons';
import { faParagraphLeft as farParagraphLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faParagraphLeft as fasParagraphLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faParagraphRtl as fadParagraphRtl }   from '@fortawesome/pro-duotone-svg-icons';
import { faParagraphRtl as falParagraphRtl }   from '@fortawesome/pro-light-svg-icons';
import { faParagraphRtl as farParagraphRtl }   from '@fortawesome/pro-regular-svg-icons';
import { faParagraphRtl as fasParagraphRtl }   from '@fortawesome/pro-solid-svg-icons';
import { faParentheses as fadParentheses }   from '@fortawesome/pro-duotone-svg-icons';
import { faParentheses as falParentheses }   from '@fortawesome/pro-light-svg-icons';
import { faParentheses as farParentheses }   from '@fortawesome/pro-regular-svg-icons';
import { faParentheses as fasParentheses }   from '@fortawesome/pro-solid-svg-icons';
import { faParenthesis as fadParenthesis }   from '@fortawesome/pro-duotone-svg-icons';
import { faParenthesis as falParenthesis }   from '@fortawesome/pro-light-svg-icons';
import { faParenthesis as farParenthesis }   from '@fortawesome/pro-regular-svg-icons';
import { faParenthesis as fasParenthesis }   from '@fortawesome/pro-solid-svg-icons';
import { faParking as fadParking }   from '@fortawesome/pro-duotone-svg-icons';
import { faParking as falParking }   from '@fortawesome/pro-light-svg-icons';
import { faParking as farParking }   from '@fortawesome/pro-regular-svg-icons';
import { faParking as fasParking }   from '@fortawesome/pro-solid-svg-icons';
import { faParkingCircle as fadParkingCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faParkingCircle as falParkingCircle }   from '@fortawesome/pro-light-svg-icons';
import { faParkingCircle as farParkingCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faParkingCircle as fasParkingCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faParkingCircleSlash as fadParkingCircleSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faParkingCircleSlash as falParkingCircleSlash }   from '@fortawesome/pro-light-svg-icons';
import { faParkingCircleSlash as farParkingCircleSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faParkingCircleSlash as fasParkingCircleSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faParkingSlash as fadParkingSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faParkingSlash as falParkingSlash }   from '@fortawesome/pro-light-svg-icons';
import { faParkingSlash as farParkingSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faParkingSlash as fasParkingSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faPartyBack as fadPartyBack }   from '@fortawesome/pro-duotone-svg-icons';
import { faPartyBack as falPartyBack }   from '@fortawesome/pro-light-svg-icons';
import { faPartyBack as farPartyBack }   from '@fortawesome/pro-regular-svg-icons';
import { faPartyBack as fasPartyBack }   from '@fortawesome/pro-solid-svg-icons';
import { faPartyBell as fadPartyBell }   from '@fortawesome/pro-duotone-svg-icons';
import { faPartyBell as falPartyBell }   from '@fortawesome/pro-light-svg-icons';
import { faPartyBell as farPartyBell }   from '@fortawesome/pro-regular-svg-icons';
import { faPartyBell as fasPartyBell }   from '@fortawesome/pro-solid-svg-icons';
import { faPartyHorn as fadPartyHorn }   from '@fortawesome/pro-duotone-svg-icons';
import { faPartyHorn as falPartyHorn }   from '@fortawesome/pro-light-svg-icons';
import { faPartyHorn as farPartyHorn }   from '@fortawesome/pro-regular-svg-icons';
import { faPartyHorn as fasPartyHorn }   from '@fortawesome/pro-solid-svg-icons';
import { faPassport as fadPassport }   from '@fortawesome/pro-duotone-svg-icons';
import { faPassport as falPassport }   from '@fortawesome/pro-light-svg-icons';
import { faPassport as farPassport }   from '@fortawesome/pro-regular-svg-icons';
import { faPassport as fasPassport }   from '@fortawesome/pro-solid-svg-icons';
import { faPastafarianism as fadPastafadrianism }   from '@fortawesome/pro-duotone-svg-icons';
import { faPastafarianism as falPastafalrianism }   from '@fortawesome/pro-light-svg-icons';
import { faPastafarianism as farPastafarrianism }   from '@fortawesome/pro-regular-svg-icons';
import { faPastafarianism as fasPastafasrianism }   from '@fortawesome/pro-solid-svg-icons';
import { faPaste as fadPaste }   from '@fortawesome/pro-duotone-svg-icons';
import { faPaste as falPaste }   from '@fortawesome/pro-light-svg-icons';
import { faPaste as farPaste }   from '@fortawesome/pro-regular-svg-icons';
import { faPaste as fasPaste }   from '@fortawesome/pro-solid-svg-icons';
import { faPause as fadPause }   from '@fortawesome/pro-duotone-svg-icons';
import { faPause as falPause }   from '@fortawesome/pro-light-svg-icons';
import { faPause as farPause }   from '@fortawesome/pro-regular-svg-icons';
import { faPause as fasPause }   from '@fortawesome/pro-solid-svg-icons';
import { faPauseCircle as fadPauseCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faPauseCircle as falPauseCircle }   from '@fortawesome/pro-light-svg-icons';
import { faPauseCircle as farPauseCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faPauseCircle as fasPauseCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faPaw as fadPaw }   from '@fortawesome/pro-duotone-svg-icons';
import { faPaw as falPaw }   from '@fortawesome/pro-light-svg-icons';
import { faPaw as farPaw }   from '@fortawesome/pro-regular-svg-icons';
import { faPaw as fasPaw }   from '@fortawesome/pro-solid-svg-icons';
import { faPawAlt as fadPawAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faPawAlt as falPawAlt }   from '@fortawesome/pro-light-svg-icons';
import { faPawAlt as farPawAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faPawAlt as fasPawAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faPawClaws as fadPawClaws }   from '@fortawesome/pro-duotone-svg-icons';
import { faPawClaws as falPawClaws }   from '@fortawesome/pro-light-svg-icons';
import { faPawClaws as farPawClaws }   from '@fortawesome/pro-regular-svg-icons';
import { faPawClaws as fasPawClaws }   from '@fortawesome/pro-solid-svg-icons';
import { faPawSimple as fadPawSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faPawSimple as falPawSimple }   from '@fortawesome/pro-light-svg-icons';
import { faPawSimple as farPawSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faPawSimple as fasPawSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faPeace as fadPeace }   from '@fortawesome/pro-duotone-svg-icons';
import { faPeace as falPeace }   from '@fortawesome/pro-light-svg-icons';
import { faPeace as farPeace }   from '@fortawesome/pro-regular-svg-icons';
import { faPeace as fasPeace }   from '@fortawesome/pro-solid-svg-icons';
import { faPeach as fadPeach }   from '@fortawesome/pro-duotone-svg-icons';
import { faPeach as falPeach }   from '@fortawesome/pro-light-svg-icons';
import { faPeach as farPeach }   from '@fortawesome/pro-regular-svg-icons';
import { faPeach as fasPeach }   from '@fortawesome/pro-solid-svg-icons';
import { faPeanut as fadPeanut }   from '@fortawesome/pro-duotone-svg-icons';
import { faPeanut as falPeanut }   from '@fortawesome/pro-light-svg-icons';
import { faPeanut as farPeanut }   from '@fortawesome/pro-regular-svg-icons';
import { faPeanut as fasPeanut }   from '@fortawesome/pro-solid-svg-icons';
import { faPeanuts as fadPeanuts }   from '@fortawesome/pro-duotone-svg-icons';
import { faPeanuts as falPeanuts }   from '@fortawesome/pro-light-svg-icons';
import { faPeanuts as farPeanuts }   from '@fortawesome/pro-regular-svg-icons';
import { faPeanuts as fasPeanuts }   from '@fortawesome/pro-solid-svg-icons';
import { faPeapod as fadPeapod }   from '@fortawesome/pro-duotone-svg-icons';
import { faPeapod as falPeapod }   from '@fortawesome/pro-light-svg-icons';
import { faPeapod as farPeapod }   from '@fortawesome/pro-regular-svg-icons';
import { faPeapod as fasPeapod }   from '@fortawesome/pro-solid-svg-icons';
import { faPear as fadPear }   from '@fortawesome/pro-duotone-svg-icons';
import { faPear as falPear }   from '@fortawesome/pro-light-svg-icons';
import { faPear as farPear }   from '@fortawesome/pro-regular-svg-icons';
import { faPear as fasPear }   from '@fortawesome/pro-solid-svg-icons';
import { faPedestal as fadPedestal }   from '@fortawesome/pro-duotone-svg-icons';
import { faPedestal as falPedestal }   from '@fortawesome/pro-light-svg-icons';
import { faPedestal as farPedestal }   from '@fortawesome/pro-regular-svg-icons';
import { faPedestal as fasPedestal }   from '@fortawesome/pro-solid-svg-icons';
import { faPegasus as fadPegasus }   from '@fortawesome/pro-duotone-svg-icons';
import { faPegasus as falPegasus }   from '@fortawesome/pro-light-svg-icons';
import { faPegasus as farPegasus }   from '@fortawesome/pro-regular-svg-icons';
import { faPegasus as fasPegasus }   from '@fortawesome/pro-solid-svg-icons';
import { faPen as fadPen }   from '@fortawesome/pro-duotone-svg-icons';
import { faPen as falPen }   from '@fortawesome/pro-light-svg-icons';
import { faPen as farPen }   from '@fortawesome/pro-regular-svg-icons';
import { faPen as fasPen }   from '@fortawesome/pro-solid-svg-icons';
import { faPenAlt as fadPenAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faPenAlt as falPenAlt }   from '@fortawesome/pro-light-svg-icons';
import { faPenAlt as farPenAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faPenAlt as fasPenAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faPenAltSlash as fadPenAltSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faPenAltSlash as falPenAltSlash }   from '@fortawesome/pro-light-svg-icons';
import { faPenAltSlash as farPenAltSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faPenAltSlash as fasPenAltSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faPencil as fadPencil }   from '@fortawesome/pro-duotone-svg-icons';
import { faPencil as falPencil }   from '@fortawesome/pro-light-svg-icons';
import { faPencil as farPencil }   from '@fortawesome/pro-regular-svg-icons';
import { faPencil as fasPencil }   from '@fortawesome/pro-solid-svg-icons';
import { faPencilAlt as fadPencilAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faPencilAlt as falPencilAlt }   from '@fortawesome/pro-light-svg-icons';
import { faPencilAlt as farPencilAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faPencilAlt as fasPencilAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faPencilPaintbrush as fadPencilPaintbrush }   from '@fortawesome/pro-duotone-svg-icons';
import { faPencilPaintbrush as falPencilPaintbrush }   from '@fortawesome/pro-light-svg-icons';
import { faPencilPaintbrush as farPencilPaintbrush }   from '@fortawesome/pro-regular-svg-icons';
import { faPencilPaintbrush as fasPencilPaintbrush }   from '@fortawesome/pro-solid-svg-icons';
import { faPencilRuler as fadPencilRuler }   from '@fortawesome/pro-duotone-svg-icons';
import { faPencilRuler as falPencilRuler }   from '@fortawesome/pro-light-svg-icons';
import { faPencilRuler as farPencilRuler }   from '@fortawesome/pro-regular-svg-icons';
import { faPencilRuler as fasPencilRuler }   from '@fortawesome/pro-solid-svg-icons';
import { faPencilSlash as fadPencilSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faPencilSlash as falPencilSlash }   from '@fortawesome/pro-light-svg-icons';
import { faPencilSlash as farPencilSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faPencilSlash as fasPencilSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faPencilSquare as fadPencilSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faPencilSquare as falPencilSquare }   from '@fortawesome/pro-light-svg-icons';
import { faPencilSquare as farPencilSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faPencilSquare as fasPencilSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faPenCircle as fadPenCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faPenCircle as falPenCircle }   from '@fortawesome/pro-light-svg-icons';
import { faPenCircle as farPenCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faPenCircle as fasPenCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faPenClip as fadPenClip }   from '@fortawesome/pro-duotone-svg-icons';
import { faPenClip as falPenClip }   from '@fortawesome/pro-light-svg-icons';
import { faPenClip as farPenClip }   from '@fortawesome/pro-regular-svg-icons';
import { faPenClip as fasPenClip }   from '@fortawesome/pro-solid-svg-icons';
import { faPenClipSlash as fadPenClipSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faPenClipSlash as falPenClipSlash }   from '@fortawesome/pro-light-svg-icons';
import { faPenClipSlash as farPenClipSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faPenClipSlash as fasPenClipSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faPenFancy as fadPenFancy }   from '@fortawesome/pro-duotone-svg-icons';
import { faPenFancy as falPenFancy }   from '@fortawesome/pro-light-svg-icons';
import { faPenFancy as farPenFancy }   from '@fortawesome/pro-regular-svg-icons';
import { faPenFancy as fasPenFancy }   from '@fortawesome/pro-solid-svg-icons';
import { faPenFancySlash as fadPenFancySlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faPenFancySlash as falPenFancySlash }   from '@fortawesome/pro-light-svg-icons';
import { faPenFancySlash as farPenFancySlash }   from '@fortawesome/pro-regular-svg-icons';
import { faPenFancySlash as fasPenFancySlash }   from '@fortawesome/pro-solid-svg-icons';
import { faPenField as fadPenField }   from '@fortawesome/pro-duotone-svg-icons';
import { faPenField as falPenField }   from '@fortawesome/pro-light-svg-icons';
import { faPenField as farPenField }   from '@fortawesome/pro-regular-svg-icons';
import { faPenField as fasPenField }   from '@fortawesome/pro-solid-svg-icons';
import { faPenLine as fadPenLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faPenLine as falPenLine }   from '@fortawesome/pro-light-svg-icons';
import { faPenLine as farPenLine }   from '@fortawesome/pro-regular-svg-icons';
import { faPenLine as fasPenLine }   from '@fortawesome/pro-solid-svg-icons';
import { faPennant as fadPennant }   from '@fortawesome/pro-duotone-svg-icons';
import { faPennant as falPennant }   from '@fortawesome/pro-light-svg-icons';
import { faPennant as farPennant }   from '@fortawesome/pro-regular-svg-icons';
import { faPennant as fasPennant }   from '@fortawesome/pro-solid-svg-icons';
import { faPenNib as fadPenNib }   from '@fortawesome/pro-duotone-svg-icons';
import { faPenNib as falPenNib }   from '@fortawesome/pro-light-svg-icons';
import { faPenNib as farPenNib }   from '@fortawesome/pro-regular-svg-icons';
import { faPenNib as fasPenNib }   from '@fortawesome/pro-solid-svg-icons';
import { faPenNibSlash as fadPenNibSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faPenNibSlash as falPenNibSlash }   from '@fortawesome/pro-light-svg-icons';
import { faPenNibSlash as farPenNibSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faPenNibSlash as fasPenNibSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faPenPaintbrush as fadPenPaintbrush }   from '@fortawesome/pro-duotone-svg-icons';
import { faPenPaintbrush as falPenPaintbrush }   from '@fortawesome/pro-light-svg-icons';
import { faPenPaintbrush as farPenPaintbrush }   from '@fortawesome/pro-regular-svg-icons';
import { faPenPaintbrush as fasPenPaintbrush }   from '@fortawesome/pro-solid-svg-icons';
import { faPenRuler as fadPenRuler }   from '@fortawesome/pro-duotone-svg-icons';
import { faPenRuler as falPenRuler }   from '@fortawesome/pro-light-svg-icons';
import { faPenRuler as farPenRuler }   from '@fortawesome/pro-regular-svg-icons';
import { faPenRuler as fasPenRuler }   from '@fortawesome/pro-solid-svg-icons';
import { faPenSlash as fadPenSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faPenSlash as falPenSlash }   from '@fortawesome/pro-light-svg-icons';
import { faPenSlash as farPenSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faPenSlash as fasPenSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faPenSquare as fadPenSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faPenSquare as falPenSquare }   from '@fortawesome/pro-light-svg-icons';
import { faPenSquare as farPenSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faPenSquare as fasPenSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faPenSwirl as fadPenSwirl }   from '@fortawesome/pro-duotone-svg-icons';
import { faPenSwirl as falPenSwirl }   from '@fortawesome/pro-light-svg-icons';
import { faPenSwirl as farPenSwirl }   from '@fortawesome/pro-regular-svg-icons';
import { faPenSwirl as fasPenSwirl }   from '@fortawesome/pro-solid-svg-icons';
import { faPenToSquare as fadPenToSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faPenToSquare as falPenToSquare }   from '@fortawesome/pro-light-svg-icons';
import { faPenToSquare as farPenToSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faPenToSquare as fasPenToSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faPeople as fadPeople }   from '@fortawesome/pro-duotone-svg-icons';
import { faPeople as falPeople }   from '@fortawesome/pro-light-svg-icons';
import { faPeople as farPeople }   from '@fortawesome/pro-regular-svg-icons';
import { faPeople as fasPeople }   from '@fortawesome/pro-solid-svg-icons';
import { faPeopleArrows as fadPeopleArrows }   from '@fortawesome/pro-duotone-svg-icons';
import { faPeopleArrows as falPeopleArrows }   from '@fortawesome/pro-light-svg-icons';
import { faPeopleArrows as farPeopleArrows }   from '@fortawesome/pro-regular-svg-icons';
import { faPeopleArrows as fasPeopleArrows }   from '@fortawesome/pro-solid-svg-icons';
import { faPeopleArrowsLeftRight as fadPeopleArrowsLeftRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faPeopleArrowsLeftRight as falPeopleArrowsLeftRight }   from '@fortawesome/pro-light-svg-icons';
import { faPeopleArrowsLeftRight as farPeopleArrowsLeftRight }   from '@fortawesome/pro-regular-svg-icons';
import { faPeopleArrowsLeftRight as fasPeopleArrowsLeftRight }   from '@fortawesome/pro-solid-svg-icons';
import { faPeopleCarry as fadPeopleCarry }   from '@fortawesome/pro-duotone-svg-icons';
import { faPeopleCarry as falPeopleCarry }   from '@fortawesome/pro-light-svg-icons';
import { faPeopleCarry as farPeopleCarry }   from '@fortawesome/pro-regular-svg-icons';
import { faPeopleCarry as fasPeopleCarry }   from '@fortawesome/pro-solid-svg-icons';
import { faPeopleCarryBox as fadPeopleCarryBox }   from '@fortawesome/pro-duotone-svg-icons';
import { faPeopleCarryBox as falPeopleCarryBox }   from '@fortawesome/pro-light-svg-icons';
import { faPeopleCarryBox as farPeopleCarryBox }   from '@fortawesome/pro-regular-svg-icons';
import { faPeopleCarryBox as fasPeopleCarryBox }   from '@fortawesome/pro-solid-svg-icons';
import { faPeopleDress as fadPeopleDress }   from '@fortawesome/pro-duotone-svg-icons';
import { faPeopleDress as falPeopleDress }   from '@fortawesome/pro-light-svg-icons';
import { faPeopleDress as farPeopleDress }   from '@fortawesome/pro-regular-svg-icons';
import { faPeopleDress as fasPeopleDress }   from '@fortawesome/pro-solid-svg-icons';
import { faPeopleDressSimple as fadPeopleDressSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faPeopleDressSimple as falPeopleDressSimple }   from '@fortawesome/pro-light-svg-icons';
import { faPeopleDressSimple as farPeopleDressSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faPeopleDressSimple as fasPeopleDressSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faPeopleGroup as fadPeopleGroup }   from '@fortawesome/pro-duotone-svg-icons';
import { faPeopleGroup as falPeopleGroup }   from '@fortawesome/pro-light-svg-icons';
import { faPeopleGroup as farPeopleGroup }   from '@fortawesome/pro-regular-svg-icons';
import { faPeopleGroup as fasPeopleGroup }   from '@fortawesome/pro-solid-svg-icons';
import { faPeopleLine as fadPeopleLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faPeopleLine as falPeopleLine }   from '@fortawesome/pro-light-svg-icons';
import { faPeopleLine as farPeopleLine }   from '@fortawesome/pro-regular-svg-icons';
import { faPeopleLine as fasPeopleLine }   from '@fortawesome/pro-solid-svg-icons';
import { faPeoplePants as fadPeoplePants }   from '@fortawesome/pro-duotone-svg-icons';
import { faPeoplePants as falPeoplePants }   from '@fortawesome/pro-light-svg-icons';
import { faPeoplePants as farPeoplePants }   from '@fortawesome/pro-regular-svg-icons';
import { faPeoplePants as fasPeoplePants }   from '@fortawesome/pro-solid-svg-icons';
import { faPeoplePantsSimple as fadPeoplePantsSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faPeoplePantsSimple as falPeoplePantsSimple }   from '@fortawesome/pro-light-svg-icons';
import { faPeoplePantsSimple as farPeoplePantsSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faPeoplePantsSimple as fasPeoplePantsSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faPeoplePulling as fadPeoplePulling }   from '@fortawesome/pro-duotone-svg-icons';
import { faPeoplePulling as falPeoplePulling }   from '@fortawesome/pro-light-svg-icons';
import { faPeoplePulling as farPeoplePulling }   from '@fortawesome/pro-regular-svg-icons';
import { faPeoplePulling as fasPeoplePulling }   from '@fortawesome/pro-solid-svg-icons';
import { faPeopleRobbery as fadPeopleRobbery }   from '@fortawesome/pro-duotone-svg-icons';
import { faPeopleRobbery as falPeopleRobbery }   from '@fortawesome/pro-light-svg-icons';
import { faPeopleRobbery as farPeopleRobbery }   from '@fortawesome/pro-regular-svg-icons';
import { faPeopleRobbery as fasPeopleRobbery }   from '@fortawesome/pro-solid-svg-icons';
import { faPeopleRoof as fadPeopleRoof }   from '@fortawesome/pro-duotone-svg-icons';
import { faPeopleRoof as falPeopleRoof }   from '@fortawesome/pro-light-svg-icons';
import { faPeopleRoof as farPeopleRoof }   from '@fortawesome/pro-regular-svg-icons';
import { faPeopleRoof as fasPeopleRoof }   from '@fortawesome/pro-solid-svg-icons';
import { faPeopleSimple as fadPeopleSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faPeopleSimple as falPeopleSimple }   from '@fortawesome/pro-light-svg-icons';
import { faPeopleSimple as farPeopleSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faPeopleSimple as fasPeopleSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faPepper as fadPepper }   from '@fortawesome/pro-duotone-svg-icons';
import { faPepper as falPepper }   from '@fortawesome/pro-light-svg-icons';
import { faPepper as farPepper }   from '@fortawesome/pro-regular-svg-icons';
import { faPepper as fasPepper }   from '@fortawesome/pro-solid-svg-icons';
import { faPepperHot as fadPepperHot }   from '@fortawesome/pro-duotone-svg-icons';
import { faPepperHot as falPepperHot }   from '@fortawesome/pro-light-svg-icons';
import { faPepperHot as farPepperHot }   from '@fortawesome/pro-regular-svg-icons';
import { faPepperHot as fasPepperHot }   from '@fortawesome/pro-solid-svg-icons';
import { faPercent as fadPercent }   from '@fortawesome/pro-duotone-svg-icons';
import { faPercent as falPercent }   from '@fortawesome/pro-light-svg-icons';
import { faPercent as farPercent }   from '@fortawesome/pro-regular-svg-icons';
import { faPercent as fasPercent }   from '@fortawesome/pro-solid-svg-icons';
import { faPercentage as fadPercentage }   from '@fortawesome/pro-duotone-svg-icons';
import { faPercentage as falPercentage }   from '@fortawesome/pro-light-svg-icons';
import { faPercentage as farPercentage }   from '@fortawesome/pro-regular-svg-icons';
import { faPercentage as fasPercentage }   from '@fortawesome/pro-solid-svg-icons';
import { faPeriod as fadPeriod }   from '@fortawesome/pro-duotone-svg-icons';
import { faPeriod as falPeriod }   from '@fortawesome/pro-light-svg-icons';
import { faPeriod as farPeriod }   from '@fortawesome/pro-regular-svg-icons';
import { faPeriod as fasPeriod }   from '@fortawesome/pro-solid-svg-icons';
import { faPerson as fadPerson }   from '@fortawesome/pro-duotone-svg-icons';
import { faPerson as falPerson }   from '@fortawesome/pro-light-svg-icons';
import { faPerson as farPerson }   from '@fortawesome/pro-regular-svg-icons';
import { faPerson as fasPerson }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonArrowDownToLine as fadPersonArrowDownToLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonArrowDownToLine as falPersonArrowDownToLine }   from '@fortawesome/pro-light-svg-icons';
import { faPersonArrowDownToLine as farPersonArrowDownToLine }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonArrowDownToLine as fasPersonArrowDownToLine }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonArrowUpFromLine as fadPersonArrowUpFromLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonArrowUpFromLine as falPersonArrowUpFromLine }   from '@fortawesome/pro-light-svg-icons';
import { faPersonArrowUpFromLine as farPersonArrowUpFromLine }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonArrowUpFromLine as fasPersonArrowUpFromLine }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonBiking as fadPersonBiking }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonBiking as falPersonBiking }   from '@fortawesome/pro-light-svg-icons';
import { faPersonBiking as farPersonBiking }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonBiking as fasPersonBiking }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonBikingMountain as fadPersonBikingMountain }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonBikingMountain as falPersonBikingMountain }   from '@fortawesome/pro-light-svg-icons';
import { faPersonBikingMountain as farPersonBikingMountain }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonBikingMountain as fasPersonBikingMountain }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonBooth as fadPersonBooth }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonBooth as falPersonBooth }   from '@fortawesome/pro-light-svg-icons';
import { faPersonBooth as farPersonBooth }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonBooth as fasPersonBooth }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonBreastfeeding as fadPersonBreastfeeding }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonBreastfeeding as falPersonBreastfeeding }   from '@fortawesome/pro-light-svg-icons';
import { faPersonBreastfeeding as farPersonBreastfeeding }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonBreastfeeding as fasPersonBreastfeeding }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonBurst as fadPersonBurst }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonBurst as falPersonBurst }   from '@fortawesome/pro-light-svg-icons';
import { faPersonBurst as farPersonBurst }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonBurst as fasPersonBurst }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonCane as fadPersonCane }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonCane as falPersonCane }   from '@fortawesome/pro-light-svg-icons';
import { faPersonCane as farPersonCane }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonCane as fasPersonCane }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonCarry as fadPersonCarry }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonCarry as falPersonCarry }   from '@fortawesome/pro-light-svg-icons';
import { faPersonCarry as farPersonCarry }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonCarry as fasPersonCarry }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonCarryBox as fadPersonCarryBox }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonCarryBox as falPersonCarryBox }   from '@fortawesome/pro-light-svg-icons';
import { faPersonCarryBox as farPersonCarryBox }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonCarryBox as fasPersonCarryBox }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonChalkboard as fadPersonChalkboard }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonChalkboard as falPersonChalkboard }   from '@fortawesome/pro-light-svg-icons';
import { faPersonChalkboard as farPersonChalkboard }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonChalkboard as fasPersonChalkboard }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonCircleCheck as fadPersonCircleCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonCircleCheck as falPersonCircleCheck }   from '@fortawesome/pro-light-svg-icons';
import { faPersonCircleCheck as farPersonCircleCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonCircleCheck as fasPersonCircleCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonCircleExclamation as fadPersonCircleExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonCircleExclamation as falPersonCircleExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faPersonCircleExclamation as farPersonCircleExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonCircleExclamation as fasPersonCircleExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonCircleMinus as fadPersonCircleMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonCircleMinus as falPersonCircleMinus }   from '@fortawesome/pro-light-svg-icons';
import { faPersonCircleMinus as farPersonCircleMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonCircleMinus as fasPersonCircleMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonCirclePlus as fadPersonCirclePlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonCirclePlus as falPersonCirclePlus }   from '@fortawesome/pro-light-svg-icons';
import { faPersonCirclePlus as farPersonCirclePlus }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonCirclePlus as fasPersonCirclePlus }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonCircleQuestion as fadPersonCircleQuestion }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonCircleQuestion as falPersonCircleQuestion }   from '@fortawesome/pro-light-svg-icons';
import { faPersonCircleQuestion as farPersonCircleQuestion }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonCircleQuestion as fasPersonCircleQuestion }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonCircleXmark as fadPersonCircleXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonCircleXmark as falPersonCircleXmark }   from '@fortawesome/pro-light-svg-icons';
import { faPersonCircleXmark as farPersonCircleXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonCircleXmark as fasPersonCircleXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonDigging as fadPersonDigging }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonDigging as falPersonDigging }   from '@fortawesome/pro-light-svg-icons';
import { faPersonDigging as farPersonDigging }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonDigging as fasPersonDigging }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonDolly as fadPersonDolly }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonDolly as falPersonDolly }   from '@fortawesome/pro-light-svg-icons';
import { faPersonDolly as farPersonDolly }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonDolly as fasPersonDolly }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonDollyEmpty as fadPersonDollyEmpty }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonDollyEmpty as falPersonDollyEmpty }   from '@fortawesome/pro-light-svg-icons';
import { faPersonDollyEmpty as farPersonDollyEmpty }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonDollyEmpty as fasPersonDollyEmpty }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonDotsFromLine as fadPersonDotsFromLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonDotsFromLine as falPersonDotsFromLine }   from '@fortawesome/pro-light-svg-icons';
import { faPersonDotsFromLine as farPersonDotsFromLine }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonDotsFromLine as fasPersonDotsFromLine }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonDress as fadPersonDress }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonDress as falPersonDress }   from '@fortawesome/pro-light-svg-icons';
import { faPersonDress as farPersonDress }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonDress as fasPersonDress }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonDressBurst as fadPersonDressBurst }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonDressBurst as falPersonDressBurst }   from '@fortawesome/pro-light-svg-icons';
import { faPersonDressBurst as farPersonDressBurst }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonDressBurst as fasPersonDressBurst }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonDressSimple as fadPersonDressSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonDressSimple as falPersonDressSimple }   from '@fortawesome/pro-light-svg-icons';
import { faPersonDressSimple as farPersonDressSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonDressSimple as fasPersonDressSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonDrowning as fadPersonDrowning }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonDrowning as falPersonDrowning }   from '@fortawesome/pro-light-svg-icons';
import { faPersonDrowning as farPersonDrowning }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonDrowning as fasPersonDrowning }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonFalling as fadPersonFalling }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonFalling as falPersonFalling }   from '@fortawesome/pro-light-svg-icons';
import { faPersonFalling as farPersonFalling }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonFalling as fasPersonFalling }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonFallingBurst as fadPersonFallingBurst }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonFallingBurst as falPersonFallingBurst }   from '@fortawesome/pro-light-svg-icons';
import { faPersonFallingBurst as farPersonFallingBurst }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonFallingBurst as fasPersonFallingBurst }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonFromPortal as fadPersonFromPortal }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonFromPortal as falPersonFromPortal }   from '@fortawesome/pro-light-svg-icons';
import { faPersonFromPortal as farPersonFromPortal }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonFromPortal as fasPersonFromPortal }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonHalfDress as fadPersonHalfDress }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonHalfDress as falPersonHalfDress }   from '@fortawesome/pro-light-svg-icons';
import { faPersonHalfDress as farPersonHalfDress }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonHalfDress as fasPersonHalfDress }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonHarassing as fadPersonHarassing }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonHarassing as falPersonHarassing }   from '@fortawesome/pro-light-svg-icons';
import { faPersonHarassing as farPersonHarassing }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonHarassing as fasPersonHarassing }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonHiking as fadPersonHiking }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonHiking as falPersonHiking }   from '@fortawesome/pro-light-svg-icons';
import { faPersonHiking as farPersonHiking }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonHiking as fasPersonHiking }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonMilitaryPointing as fadPersonMilitaryPointing }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonMilitaryPointing as falPersonMilitaryPointing }   from '@fortawesome/pro-light-svg-icons';
import { faPersonMilitaryPointing as farPersonMilitaryPointing }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonMilitaryPointing as fasPersonMilitaryPointing }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonMilitaryRifle as fadPersonMilitaryRifle }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonMilitaryRifle as falPersonMilitaryRifle }   from '@fortawesome/pro-light-svg-icons';
import { faPersonMilitaryRifle as farPersonMilitaryRifle }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonMilitaryRifle as fasPersonMilitaryRifle }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonMilitaryToPerson as fadPersonMilitaryToPerson }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonMilitaryToPerson as falPersonMilitaryToPerson }   from '@fortawesome/pro-light-svg-icons';
import { faPersonMilitaryToPerson as farPersonMilitaryToPerson }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonMilitaryToPerson as fasPersonMilitaryToPerson }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonPinball as fadPersonPinball }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonPinball as falPersonPinball }   from '@fortawesome/pro-light-svg-icons';
import { faPersonPinball as farPersonPinball }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonPinball as fasPersonPinball }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonPraying as fadPersonPraying }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonPraying as falPersonPraying }   from '@fortawesome/pro-light-svg-icons';
import { faPersonPraying as farPersonPraying }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonPraying as fasPersonPraying }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonPregnant as fadPersonPregnant }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonPregnant as falPersonPregnant }   from '@fortawesome/pro-light-svg-icons';
import { faPersonPregnant as farPersonPregnant }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonPregnant as fasPersonPregnant }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonRays as fadPersonRays }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonRays as falPersonRays }   from '@fortawesome/pro-light-svg-icons';
import { faPersonRays as farPersonRays }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonRays as fasPersonRays }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonRifle as fadPersonRifle }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonRifle as falPersonRifle }   from '@fortawesome/pro-light-svg-icons';
import { faPersonRifle as farPersonRifle }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonRifle as fasPersonRifle }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonRunning as fadPersonRunning }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonRunning as falPersonRunning }   from '@fortawesome/pro-light-svg-icons';
import { faPersonRunning as farPersonRunning }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonRunning as fasPersonRunning }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonSeat as fadPersonSeat }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonSeat as falPersonSeat }   from '@fortawesome/pro-light-svg-icons';
import { faPersonSeat as farPersonSeat }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonSeat as fasPersonSeat }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonSeatReclined as fadPersonSeatReclined }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonSeatReclined as falPersonSeatReclined }   from '@fortawesome/pro-light-svg-icons';
import { faPersonSeatReclined as farPersonSeatReclined }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonSeatReclined as fasPersonSeatReclined }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonShelter as fadPersonShelter }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonShelter as falPersonShelter }   from '@fortawesome/pro-light-svg-icons';
import { faPersonShelter as farPersonShelter }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonShelter as fasPersonShelter }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonSign as fadPersonSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonSign as falPersonSign }   from '@fortawesome/pro-light-svg-icons';
import { faPersonSign as farPersonSign }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonSign as fasPersonSign }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonSimple as fadPersonSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonSimple as falPersonSimple }   from '@fortawesome/pro-light-svg-icons';
import { faPersonSimple as farPersonSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonSimple as fasPersonSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonSkating as fadPersonSkating }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonSkating as falPersonSkating }   from '@fortawesome/pro-light-svg-icons';
import { faPersonSkating as farPersonSkating }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonSkating as fasPersonSkating }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonSkiing as fadPersonSkiing }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonSkiing as falPersonSkiing }   from '@fortawesome/pro-light-svg-icons';
import { faPersonSkiing as farPersonSkiing }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonSkiing as fasPersonSkiing }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonSkiingNordic as fadPersonSkiingNordic }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonSkiingNordic as falPersonSkiingNordic }   from '@fortawesome/pro-light-svg-icons';
import { faPersonSkiingNordic as farPersonSkiingNordic }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonSkiingNordic as fasPersonSkiingNordic }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonSkiJumping as fadPersonSkiJumping }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonSkiJumping as falPersonSkiJumping }   from '@fortawesome/pro-light-svg-icons';
import { faPersonSkiJumping as farPersonSkiJumping }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonSkiJumping as fasPersonSkiJumping }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonSkiLift as fadPersonSkiLift }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonSkiLift as falPersonSkiLift }   from '@fortawesome/pro-light-svg-icons';
import { faPersonSkiLift as farPersonSkiLift }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonSkiLift as fasPersonSkiLift }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonSledding as fadPersonSledding }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonSledding as falPersonSledding }   from '@fortawesome/pro-light-svg-icons';
import { faPersonSledding as farPersonSledding }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonSledding as fasPersonSledding }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonSnowboarding as fadPersonSnowboarding }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonSnowboarding as falPersonSnowboarding }   from '@fortawesome/pro-light-svg-icons';
import { faPersonSnowboarding as farPersonSnowboarding }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonSnowboarding as fasPersonSnowboarding }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonSnowmobiling as fadPersonSnowmobiling }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonSnowmobiling as falPersonSnowmobiling }   from '@fortawesome/pro-light-svg-icons';
import { faPersonSnowmobiling as farPersonSnowmobiling }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonSnowmobiling as fasPersonSnowmobiling }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonSwimming as fadPersonSwimming }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonSwimming as falPersonSwimming }   from '@fortawesome/pro-light-svg-icons';
import { faPersonSwimming as farPersonSwimming }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonSwimming as fasPersonSwimming }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonThroughWindow as fadPersonThroughWindow }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonThroughWindow as falPersonThroughWindow }   from '@fortawesome/pro-light-svg-icons';
import { faPersonThroughWindow as farPersonThroughWindow }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonThroughWindow as fasPersonThroughWindow }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonToDoor as fadPersonToDoor }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonToDoor as falPersonToDoor }   from '@fortawesome/pro-light-svg-icons';
import { faPersonToDoor as farPersonToDoor }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonToDoor as fasPersonToDoor }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonToPortal as fadPersonToPortal }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonToPortal as falPersonToPortal }   from '@fortawesome/pro-light-svg-icons';
import { faPersonToPortal as farPersonToPortal }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonToPortal as fasPersonToPortal }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonWalking as fadPersonWalking }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonWalking as falPersonWalking }   from '@fortawesome/pro-light-svg-icons';
import { faPersonWalking as farPersonWalking }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonWalking as fasPersonWalking }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonWalkingArrowLoopLeft as fadPersonWalkingArrowLoopLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonWalkingArrowLoopLeft as falPersonWalkingArrowLoopLeft }   from '@fortawesome/pro-light-svg-icons';
import { faPersonWalkingArrowLoopLeft as farPersonWalkingArrowLoopLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonWalkingArrowLoopLeft as fasPersonWalkingArrowLoopLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonWalkingArrowRight as fadPersonWalkingArrowRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonWalkingArrowRight as falPersonWalkingArrowRight }   from '@fortawesome/pro-light-svg-icons';
import { faPersonWalkingArrowRight as farPersonWalkingArrowRight }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonWalkingArrowRight as fasPersonWalkingArrowRight }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonWalkingDashedLineArrowRight as fadPersonWalkingDashedLineArrowRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonWalkingDashedLineArrowRight as falPersonWalkingDashedLineArrowRight }   from '@fortawesome/pro-light-svg-icons';
import { faPersonWalkingDashedLineArrowRight as farPersonWalkingDashedLineArrowRight }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonWalkingDashedLineArrowRight as fasPersonWalkingDashedLineArrowRight }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonWalkingLuggage as fadPersonWalkingLuggage }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonWalkingLuggage as falPersonWalkingLuggage }   from '@fortawesome/pro-light-svg-icons';
import { faPersonWalkingLuggage as farPersonWalkingLuggage }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonWalkingLuggage as fasPersonWalkingLuggage }   from '@fortawesome/pro-solid-svg-icons';
import { faPersonWalkingWithCane as fadPersonWalkingWithCane }   from '@fortawesome/pro-duotone-svg-icons';
import { faPersonWalkingWithCane as falPersonWalkingWithCane }   from '@fortawesome/pro-light-svg-icons';
import { faPersonWalkingWithCane as farPersonWalkingWithCane }   from '@fortawesome/pro-regular-svg-icons';
import { faPersonWalkingWithCane as fasPersonWalkingWithCane }   from '@fortawesome/pro-solid-svg-icons';
import { faPesetaSign as fadPesetaSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faPesetaSign as falPesetaSign }   from '@fortawesome/pro-light-svg-icons';
import { faPesetaSign as farPesetaSign }   from '@fortawesome/pro-regular-svg-icons';
import { faPesetaSign as fasPesetaSign }   from '@fortawesome/pro-solid-svg-icons';
import { faPesoSign as fadPesoSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faPesoSign as falPesoSign }   from '@fortawesome/pro-light-svg-icons';
import { faPesoSign as farPesoSign }   from '@fortawesome/pro-regular-svg-icons';
import { faPesoSign as fasPesoSign }   from '@fortawesome/pro-solid-svg-icons';
import { faPhone as fadPhone }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhone as falPhone }   from '@fortawesome/pro-light-svg-icons';
import { faPhone as farPhone }   from '@fortawesome/pro-regular-svg-icons';
import { faPhone as fasPhone }   from '@fortawesome/pro-solid-svg-icons';
import { faPhoneAlt as fadPhoneAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhoneAlt as falPhoneAlt }   from '@fortawesome/pro-light-svg-icons';
import { faPhoneAlt as farPhoneAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faPhoneAlt as fasPhoneAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faPhoneArrowDown as fadPhoneArrowDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhoneArrowDown as falPhoneArrowDown }   from '@fortawesome/pro-light-svg-icons';
import { faPhoneArrowDown as farPhoneArrowDown }   from '@fortawesome/pro-regular-svg-icons';
import { faPhoneArrowDown as fasPhoneArrowDown }   from '@fortawesome/pro-solid-svg-icons';
import { faPhoneArrowDownLeft as fadPhoneArrowDownLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhoneArrowDownLeft as falPhoneArrowDownLeft }   from '@fortawesome/pro-light-svg-icons';
import { faPhoneArrowDownLeft as farPhoneArrowDownLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faPhoneArrowDownLeft as fasPhoneArrowDownLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faPhoneArrowUp as fadPhoneArrowUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhoneArrowUp as falPhoneArrowUp }   from '@fortawesome/pro-light-svg-icons';
import { faPhoneArrowUp as farPhoneArrowUp }   from '@fortawesome/pro-regular-svg-icons';
import { faPhoneArrowUp as fasPhoneArrowUp }   from '@fortawesome/pro-solid-svg-icons';
import { faPhoneArrowUpRight as fadPhoneArrowUpRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhoneArrowUpRight as falPhoneArrowUpRight }   from '@fortawesome/pro-light-svg-icons';
import { faPhoneArrowUpRight as farPhoneArrowUpRight }   from '@fortawesome/pro-regular-svg-icons';
import { faPhoneArrowUpRight as fasPhoneArrowUpRight }   from '@fortawesome/pro-solid-svg-icons';
import { faPhoneCircle as fadPhoneCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhoneCircle as falPhoneCircle }   from '@fortawesome/pro-light-svg-icons';
import { faPhoneCircle as farPhoneCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faPhoneCircle as fasPhoneCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faPhoneCircleAlt as fadPhoneCircleAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhoneCircleAlt as falPhoneCircleAlt }   from '@fortawesome/pro-light-svg-icons';
import { faPhoneCircleAlt as farPhoneCircleAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faPhoneCircleAlt as fasPhoneCircleAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faPhoneCircleDown as fadPhoneCircleDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhoneCircleDown as falPhoneCircleDown }   from '@fortawesome/pro-light-svg-icons';
import { faPhoneCircleDown as farPhoneCircleDown }   from '@fortawesome/pro-regular-svg-icons';
import { faPhoneCircleDown as fasPhoneCircleDown }   from '@fortawesome/pro-solid-svg-icons';
import { faPhoneFlip as fadPhoneFlip }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhoneFlip as falPhoneFlip }   from '@fortawesome/pro-light-svg-icons';
import { faPhoneFlip as farPhoneFlip }   from '@fortawesome/pro-regular-svg-icons';
import { faPhoneFlip as fasPhoneFlip }   from '@fortawesome/pro-solid-svg-icons';
import { faPhoneHangup as fadPhoneHangup }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhoneHangup as falPhoneHangup }   from '@fortawesome/pro-light-svg-icons';
import { faPhoneHangup as farPhoneHangup }   from '@fortawesome/pro-regular-svg-icons';
import { faPhoneHangup as fasPhoneHangup }   from '@fortawesome/pro-solid-svg-icons';
import { faPhoneIncoming as fadPhoneIncoming }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhoneIncoming as falPhoneIncoming }   from '@fortawesome/pro-light-svg-icons';
import { faPhoneIncoming as farPhoneIncoming }   from '@fortawesome/pro-regular-svg-icons';
import { faPhoneIncoming as fasPhoneIncoming }   from '@fortawesome/pro-solid-svg-icons';
import { faPhoneIntercom as fadPhoneIntercom }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhoneIntercom as falPhoneIntercom }   from '@fortawesome/pro-light-svg-icons';
import { faPhoneIntercom as farPhoneIntercom }   from '@fortawesome/pro-regular-svg-icons';
import { faPhoneIntercom as fasPhoneIntercom }   from '@fortawesome/pro-solid-svg-icons';
import { faPhoneLaptop as fadPhoneLaptop }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhoneLaptop as falPhoneLaptop }   from '@fortawesome/pro-light-svg-icons';
import { faPhoneLaptop as farPhoneLaptop }   from '@fortawesome/pro-regular-svg-icons';
import { faPhoneLaptop as fasPhoneLaptop }   from '@fortawesome/pro-solid-svg-icons';
import { faPhoneMissed as fadPhoneMissed }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhoneMissed as falPhoneMissed }   from '@fortawesome/pro-light-svg-icons';
import { faPhoneMissed as farPhoneMissed }   from '@fortawesome/pro-regular-svg-icons';
import { faPhoneMissed as fasPhoneMissed }   from '@fortawesome/pro-solid-svg-icons';
import { faPhoneOffice as fadPhoneOffice }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhoneOffice as falPhoneOffice }   from '@fortawesome/pro-light-svg-icons';
import { faPhoneOffice as farPhoneOffice }   from '@fortawesome/pro-regular-svg-icons';
import { faPhoneOffice as fasPhoneOffice }   from '@fortawesome/pro-solid-svg-icons';
import { faPhoneOutgoing as fadPhoneOutgoing }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhoneOutgoing as falPhoneOutgoing }   from '@fortawesome/pro-light-svg-icons';
import { faPhoneOutgoing as farPhoneOutgoing }   from '@fortawesome/pro-regular-svg-icons';
import { faPhoneOutgoing as fasPhoneOutgoing }   from '@fortawesome/pro-solid-svg-icons';
import { faPhonePlus as fadPhonePlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhonePlus as falPhonePlus }   from '@fortawesome/pro-light-svg-icons';
import { faPhonePlus as farPhonePlus }   from '@fortawesome/pro-regular-svg-icons';
import { faPhonePlus as fasPhonePlus }   from '@fortawesome/pro-solid-svg-icons';
import { faPhoneRotary as fadPhoneRotary }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhoneRotary as falPhoneRotary }   from '@fortawesome/pro-light-svg-icons';
import { faPhoneRotary as farPhoneRotary }   from '@fortawesome/pro-regular-svg-icons';
import { faPhoneRotary as fasPhoneRotary }   from '@fortawesome/pro-solid-svg-icons';
import { faPhoneSlash as fadPhoneSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhoneSlash as falPhoneSlash }   from '@fortawesome/pro-light-svg-icons';
import { faPhoneSlash as farPhoneSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faPhoneSlash as fasPhoneSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faPhoneSquare as fadPhoneSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhoneSquare as falPhoneSquare }   from '@fortawesome/pro-light-svg-icons';
import { faPhoneSquare as farPhoneSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faPhoneSquare as fasPhoneSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faPhoneSquareAlt as fadPhoneSquareAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhoneSquareAlt as falPhoneSquareAlt }   from '@fortawesome/pro-light-svg-icons';
import { faPhoneSquareAlt as farPhoneSquareAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faPhoneSquareAlt as fasPhoneSquareAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faPhoneSquareDown as fadPhoneSquareDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhoneSquareDown as falPhoneSquareDown }   from '@fortawesome/pro-light-svg-icons';
import { faPhoneSquareDown as farPhoneSquareDown }   from '@fortawesome/pro-regular-svg-icons';
import { faPhoneSquareDown as fasPhoneSquareDown }   from '@fortawesome/pro-solid-svg-icons';
import { faPhoneVolume as fadPhoneVolume }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhoneVolume as falPhoneVolume }   from '@fortawesome/pro-light-svg-icons';
import { faPhoneVolume as farPhoneVolume }   from '@fortawesome/pro-regular-svg-icons';
import { faPhoneVolume as fasPhoneVolume }   from '@fortawesome/pro-solid-svg-icons';
import { faPhoneXmark as fadPhoneXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhoneXmark as falPhoneXmark }   from '@fortawesome/pro-light-svg-icons';
import { faPhoneXmark as farPhoneXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faPhoneXmark as fasPhoneXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faPhotoFilm as fadPhotoFilm }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhotoFilm as falPhotoFilm }   from '@fortawesome/pro-light-svg-icons';
import { faPhotoFilm as farPhotoFilm }   from '@fortawesome/pro-regular-svg-icons';
import { faPhotoFilm as fasPhotoFilm }   from '@fortawesome/pro-solid-svg-icons';
import { faPhotoFilmMusic as fadPhotoFilmMusic }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhotoFilmMusic as falPhotoFilmMusic }   from '@fortawesome/pro-light-svg-icons';
import { faPhotoFilmMusic as farPhotoFilmMusic }   from '@fortawesome/pro-regular-svg-icons';
import { faPhotoFilmMusic as fasPhotoFilmMusic }   from '@fortawesome/pro-solid-svg-icons';
import { faPhotoVideo as fadPhotoVideo }   from '@fortawesome/pro-duotone-svg-icons';
import { faPhotoVideo as falPhotoVideo }   from '@fortawesome/pro-light-svg-icons';
import { faPhotoVideo as farPhotoVideo }   from '@fortawesome/pro-regular-svg-icons';
import { faPhotoVideo as fasPhotoVideo }   from '@fortawesome/pro-solid-svg-icons';
import { faPi as fadPi }   from '@fortawesome/pro-duotone-svg-icons';
import { faPi as falPi }   from '@fortawesome/pro-light-svg-icons';
import { faPi as farPi }   from '@fortawesome/pro-regular-svg-icons';
import { faPi as fasPi }   from '@fortawesome/pro-solid-svg-icons';
import { faPiano as fadPiano }   from '@fortawesome/pro-duotone-svg-icons';
import { faPiano as falPiano }   from '@fortawesome/pro-light-svg-icons';
import { faPiano as farPiano }   from '@fortawesome/pro-regular-svg-icons';
import { faPiano as fasPiano }   from '@fortawesome/pro-solid-svg-icons';
import { faPianoKeyboard as fadPianoKeyboard }   from '@fortawesome/pro-duotone-svg-icons';
import { faPianoKeyboard as falPianoKeyboard }   from '@fortawesome/pro-light-svg-icons';
import { faPianoKeyboard as farPianoKeyboard }   from '@fortawesome/pro-regular-svg-icons';
import { faPianoKeyboard as fasPianoKeyboard }   from '@fortawesome/pro-solid-svg-icons';
import { faPickleball as fadPickleball }   from '@fortawesome/pro-duotone-svg-icons';
import { faPickleball as falPickleball }   from '@fortawesome/pro-light-svg-icons';
import { faPickleball as farPickleball }   from '@fortawesome/pro-regular-svg-icons';
import { faPickleball as fasPickleball }   from '@fortawesome/pro-solid-svg-icons';
import { faPie as fadPie }   from '@fortawesome/pro-duotone-svg-icons';
import { faPie as falPie }   from '@fortawesome/pro-light-svg-icons';
import { faPie as farPie }   from '@fortawesome/pro-regular-svg-icons';
import { faPie as fasPie }   from '@fortawesome/pro-solid-svg-icons';
import { faPieChart as fadPieChart }   from '@fortawesome/pro-duotone-svg-icons';
import { faPieChart as falPieChart }   from '@fortawesome/pro-light-svg-icons';
import { faPieChart as farPieChart }   from '@fortawesome/pro-regular-svg-icons';
import { faPieChart as fasPieChart }   from '@fortawesome/pro-solid-svg-icons';
import { faPig as fadPig }   from '@fortawesome/pro-duotone-svg-icons';
import { faPig as falPig }   from '@fortawesome/pro-light-svg-icons';
import { faPig as farPig }   from '@fortawesome/pro-regular-svg-icons';
import { faPig as fasPig }   from '@fortawesome/pro-solid-svg-icons';
import { faPiggyBank as fadPiggyBank }   from '@fortawesome/pro-duotone-svg-icons';
import { faPiggyBank as falPiggyBank }   from '@fortawesome/pro-light-svg-icons';
import { faPiggyBank as farPiggyBank }   from '@fortawesome/pro-regular-svg-icons';
import { faPiggyBank as fasPiggyBank }   from '@fortawesome/pro-solid-svg-icons';
import { faPills as fadPills }   from '@fortawesome/pro-duotone-svg-icons';
import { faPills as falPills }   from '@fortawesome/pro-light-svg-icons';
import { faPills as farPills }   from '@fortawesome/pro-regular-svg-icons';
import { faPills as fasPills }   from '@fortawesome/pro-solid-svg-icons';
import { faPinata as fadPinata }   from '@fortawesome/pro-duotone-svg-icons';
import { faPinata as falPinata }   from '@fortawesome/pro-light-svg-icons';
import { faPinata as farPinata }   from '@fortawesome/pro-regular-svg-icons';
import { faPinata as fasPinata }   from '@fortawesome/pro-solid-svg-icons';
import { faPinball as fadPinball }   from '@fortawesome/pro-duotone-svg-icons';
import { faPinball as falPinball }   from '@fortawesome/pro-light-svg-icons';
import { faPinball as farPinball }   from '@fortawesome/pro-regular-svg-icons';
import { faPinball as fasPinball }   from '@fortawesome/pro-solid-svg-icons';
import { faPineapple as fadPineapple }   from '@fortawesome/pro-duotone-svg-icons';
import { faPineapple as falPineapple }   from '@fortawesome/pro-light-svg-icons';
import { faPineapple as farPineapple }   from '@fortawesome/pro-regular-svg-icons';
import { faPineapple as fasPineapple }   from '@fortawesome/pro-solid-svg-icons';
import { faPingPongPaddleBall as fadPingPongPaddleBall }   from '@fortawesome/pro-duotone-svg-icons';
import { faPingPongPaddleBall as falPingPongPaddleBall }   from '@fortawesome/pro-light-svg-icons';
import { faPingPongPaddleBall as farPingPongPaddleBall }   from '@fortawesome/pro-regular-svg-icons';
import { faPingPongPaddleBall as fasPingPongPaddleBall }   from '@fortawesome/pro-solid-svg-icons';
import { faPipe as fadPipe }   from '@fortawesome/pro-duotone-svg-icons';
import { faPipe as falPipe }   from '@fortawesome/pro-light-svg-icons';
import { faPipe as farPipe }   from '@fortawesome/pro-regular-svg-icons';
import { faPipe as fasPipe }   from '@fortawesome/pro-solid-svg-icons';
import { faPipeCircleCheck as fadPipeCircleCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faPipeCircleCheck as falPipeCircleCheck }   from '@fortawesome/pro-light-svg-icons';
import { faPipeCircleCheck as farPipeCircleCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faPipeCircleCheck as fasPipeCircleCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faPipeCollar as fadPipeCollar }   from '@fortawesome/pro-duotone-svg-icons';
import { faPipeCollar as falPipeCollar }   from '@fortawesome/pro-light-svg-icons';
import { faPipeCollar as farPipeCollar }   from '@fortawesome/pro-regular-svg-icons';
import { faPipeCollar as fasPipeCollar }   from '@fortawesome/pro-solid-svg-icons';
import { faPipeSection as fadPipeSection }   from '@fortawesome/pro-duotone-svg-icons';
import { faPipeSection as falPipeSection }   from '@fortawesome/pro-light-svg-icons';
import { faPipeSection as farPipeSection }   from '@fortawesome/pro-regular-svg-icons';
import { faPipeSection as fasPipeSection }   from '@fortawesome/pro-solid-svg-icons';
import { faPipeSmoking as fadPipeSmoking }   from '@fortawesome/pro-duotone-svg-icons';
import { faPipeSmoking as falPipeSmoking }   from '@fortawesome/pro-light-svg-icons';
import { faPipeSmoking as farPipeSmoking }   from '@fortawesome/pro-regular-svg-icons';
import { faPipeSmoking as fasPipeSmoking }   from '@fortawesome/pro-solid-svg-icons';
import { faPipeValve as fadPipeValve }   from '@fortawesome/pro-duotone-svg-icons';
import { faPipeValve as falPipeValve }   from '@fortawesome/pro-light-svg-icons';
import { faPipeValve as farPipeValve }   from '@fortawesome/pro-regular-svg-icons';
import { faPipeValve as fasPipeValve }   from '@fortawesome/pro-solid-svg-icons';
import { faPizza as fadPizza }   from '@fortawesome/pro-duotone-svg-icons';
import { faPizza as falPizza }   from '@fortawesome/pro-light-svg-icons';
import { faPizza as farPizza }   from '@fortawesome/pro-regular-svg-icons';
import { faPizza as fasPizza }   from '@fortawesome/pro-solid-svg-icons';
import { faPizzaSlice as fadPizzaSlice }   from '@fortawesome/pro-duotone-svg-icons';
import { faPizzaSlice as falPizzaSlice }   from '@fortawesome/pro-light-svg-icons';
import { faPizzaSlice as farPizzaSlice }   from '@fortawesome/pro-regular-svg-icons';
import { faPizzaSlice as fasPizzaSlice }   from '@fortawesome/pro-solid-svg-icons';
import { faPlaceOfWorship as fadPlaceOfWorship }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlaceOfWorship as falPlaceOfWorship }   from '@fortawesome/pro-light-svg-icons';
import { faPlaceOfWorship as farPlaceOfWorship }   from '@fortawesome/pro-regular-svg-icons';
import { faPlaceOfWorship as fasPlaceOfWorship }   from '@fortawesome/pro-solid-svg-icons';
import { faPlane as fadPlane }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlane as falPlane }   from '@fortawesome/pro-light-svg-icons';
import { faPlane as farPlane }   from '@fortawesome/pro-regular-svg-icons';
import { faPlane as fasPlane }   from '@fortawesome/pro-solid-svg-icons';
import { faPlaneAlt as fadPlaneAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlaneAlt as falPlaneAlt }   from '@fortawesome/pro-light-svg-icons';
import { faPlaneAlt as farPlaneAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faPlaneAlt as fasPlaneAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faPlaneArrival as fadPlaneArrival }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlaneArrival as falPlaneArrival }   from '@fortawesome/pro-light-svg-icons';
import { faPlaneArrival as farPlaneArrival }   from '@fortawesome/pro-regular-svg-icons';
import { faPlaneArrival as fasPlaneArrival }   from '@fortawesome/pro-solid-svg-icons';
import { faPlaneCircleCheck as fadPlaneCircleCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlaneCircleCheck as falPlaneCircleCheck }   from '@fortawesome/pro-light-svg-icons';
import { faPlaneCircleCheck as farPlaneCircleCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faPlaneCircleCheck as fasPlaneCircleCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faPlaneCircleExclamation as fadPlaneCircleExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlaneCircleExclamation as falPlaneCircleExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faPlaneCircleExclamation as farPlaneCircleExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faPlaneCircleExclamation as fasPlaneCircleExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faPlaneCircleXmark as fadPlaneCircleXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlaneCircleXmark as falPlaneCircleXmark }   from '@fortawesome/pro-light-svg-icons';
import { faPlaneCircleXmark as farPlaneCircleXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faPlaneCircleXmark as fasPlaneCircleXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faPlaneDeparture as fadPlaneDeparture }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlaneDeparture as falPlaneDeparture }   from '@fortawesome/pro-light-svg-icons';
import { faPlaneDeparture as farPlaneDeparture }   from '@fortawesome/pro-regular-svg-icons';
import { faPlaneDeparture as fasPlaneDeparture }   from '@fortawesome/pro-solid-svg-icons';
import { faPlaneEngines as fadPlaneEngines }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlaneEngines as falPlaneEngines }   from '@fortawesome/pro-light-svg-icons';
import { faPlaneEngines as farPlaneEngines }   from '@fortawesome/pro-regular-svg-icons';
import { faPlaneEngines as fasPlaneEngines }   from '@fortawesome/pro-solid-svg-icons';
import { faPlaneLock as fadPlaneLock }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlaneLock as falPlaneLock }   from '@fortawesome/pro-light-svg-icons';
import { faPlaneLock as farPlaneLock }   from '@fortawesome/pro-regular-svg-icons';
import { faPlaneLock as fasPlaneLock }   from '@fortawesome/pro-solid-svg-icons';
import { faPlaneProp as fadPlaneProp }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlaneProp as falPlaneProp }   from '@fortawesome/pro-light-svg-icons';
import { faPlaneProp as farPlaneProp }   from '@fortawesome/pro-regular-svg-icons';
import { faPlaneProp as fasPlaneProp }   from '@fortawesome/pro-solid-svg-icons';
import { faPlaneSlash as fadPlaneSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlaneSlash as falPlaneSlash }   from '@fortawesome/pro-light-svg-icons';
import { faPlaneSlash as farPlaneSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faPlaneSlash as fasPlaneSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faPlaneTail as fadPlaneTail }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlaneTail as falPlaneTail }   from '@fortawesome/pro-light-svg-icons';
import { faPlaneTail as farPlaneTail }   from '@fortawesome/pro-regular-svg-icons';
import { faPlaneTail as fasPlaneTail }   from '@fortawesome/pro-solid-svg-icons';
import { faPlanetMoon as fadPlanetMoon }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlanetMoon as falPlanetMoon }   from '@fortawesome/pro-light-svg-icons';
import { faPlanetMoon as farPlanetMoon }   from '@fortawesome/pro-regular-svg-icons';
import { faPlanetMoon as fasPlanetMoon }   from '@fortawesome/pro-solid-svg-icons';
import { faPlanetRinged as fadPlanetRinged }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlanetRinged as falPlanetRinged }   from '@fortawesome/pro-light-svg-icons';
import { faPlanetRinged as farPlanetRinged }   from '@fortawesome/pro-regular-svg-icons';
import { faPlanetRinged as fasPlanetRinged }   from '@fortawesome/pro-solid-svg-icons';
import { faPlaneUp as fadPlaneUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlaneUp as falPlaneUp }   from '@fortawesome/pro-light-svg-icons';
import { faPlaneUp as farPlaneUp }   from '@fortawesome/pro-regular-svg-icons';
import { faPlaneUp as fasPlaneUp }   from '@fortawesome/pro-solid-svg-icons';
import { faPlaneUpSlash as fadPlaneUpSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlaneUpSlash as falPlaneUpSlash }   from '@fortawesome/pro-light-svg-icons';
import { faPlaneUpSlash as farPlaneUpSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faPlaneUpSlash as fasPlaneUpSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faPlantWilt as fadPlantWilt }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlantWilt as falPlantWilt }   from '@fortawesome/pro-light-svg-icons';
import { faPlantWilt as farPlantWilt }   from '@fortawesome/pro-regular-svg-icons';
import { faPlantWilt as fasPlantWilt }   from '@fortawesome/pro-solid-svg-icons';
import { faPlateUtensils as fadPlateUtensils }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlateUtensils as falPlateUtensils }   from '@fortawesome/pro-light-svg-icons';
import { faPlateUtensils as farPlateUtensils }   from '@fortawesome/pro-regular-svg-icons';
import { faPlateUtensils as fasPlateUtensils }   from '@fortawesome/pro-solid-svg-icons';
import { faPlateWheat as fadPlateWheat }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlateWheat as falPlateWheat }   from '@fortawesome/pro-light-svg-icons';
import { faPlateWheat as farPlateWheat }   from '@fortawesome/pro-regular-svg-icons';
import { faPlateWheat as fasPlateWheat }   from '@fortawesome/pro-solid-svg-icons';
import { faPlay as fadPlay }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlay as falPlay }   from '@fortawesome/pro-light-svg-icons';
import { faPlay as farPlay }   from '@fortawesome/pro-regular-svg-icons';
import { faPlay as fasPlay }   from '@fortawesome/pro-solid-svg-icons';
import { faPlayCircle as fadPlayCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlayCircle as falPlayCircle }   from '@fortawesome/pro-light-svg-icons';
import { faPlayCircle as farPlayCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faPlayCircle as fasPlayCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faPlayPause as fadPlayPause }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlayPause as falPlayPause }   from '@fortawesome/pro-light-svg-icons';
import { faPlayPause as farPlayPause }   from '@fortawesome/pro-regular-svg-icons';
import { faPlayPause as fasPlayPause }   from '@fortawesome/pro-solid-svg-icons';
import { faPlug as fadPlug }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlug as falPlug }   from '@fortawesome/pro-light-svg-icons';
import { faPlug as farPlug }   from '@fortawesome/pro-regular-svg-icons';
import { faPlug as fasPlug }   from '@fortawesome/pro-solid-svg-icons';
import { faPlugCircleBolt as fadPlugCircleBolt }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlugCircleBolt as falPlugCircleBolt }   from '@fortawesome/pro-light-svg-icons';
import { faPlugCircleBolt as farPlugCircleBolt }   from '@fortawesome/pro-regular-svg-icons';
import { faPlugCircleBolt as fasPlugCircleBolt }   from '@fortawesome/pro-solid-svg-icons';
import { faPlugCircleCheck as fadPlugCircleCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlugCircleCheck as falPlugCircleCheck }   from '@fortawesome/pro-light-svg-icons';
import { faPlugCircleCheck as farPlugCircleCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faPlugCircleCheck as fasPlugCircleCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faPlugCircleExclamation as fadPlugCircleExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlugCircleExclamation as falPlugCircleExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faPlugCircleExclamation as farPlugCircleExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faPlugCircleExclamation as fasPlugCircleExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faPlugCircleMinus as fadPlugCircleMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlugCircleMinus as falPlugCircleMinus }   from '@fortawesome/pro-light-svg-icons';
import { faPlugCircleMinus as farPlugCircleMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faPlugCircleMinus as fasPlugCircleMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faPlugCirclePlus as fadPlugCirclePlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlugCirclePlus as falPlugCirclePlus }   from '@fortawesome/pro-light-svg-icons';
import { faPlugCirclePlus as farPlugCirclePlus }   from '@fortawesome/pro-regular-svg-icons';
import { faPlugCirclePlus as fasPlugCirclePlus }   from '@fortawesome/pro-solid-svg-icons';
import { faPlugCircleXmark as fadPlugCircleXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlugCircleXmark as falPlugCircleXmark }   from '@fortawesome/pro-light-svg-icons';
import { faPlugCircleXmark as farPlugCircleXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faPlugCircleXmark as fasPlugCircleXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faPlus as fadPlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlus as falPlus }   from '@fortawesome/pro-light-svg-icons';
import { faPlus as farPlus }   from '@fortawesome/pro-regular-svg-icons';
import { faPlus as fasPlus }   from '@fortawesome/pro-solid-svg-icons';
import { faPlusCircle as fadPlusCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlusCircle as falPlusCircle }   from '@fortawesome/pro-light-svg-icons';
import { faPlusCircle as farPlusCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faPlusCircle as fasPlusCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faPlusHexagon as fadPlusHexagon }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlusHexagon as falPlusHexagon }   from '@fortawesome/pro-light-svg-icons';
import { faPlusHexagon as farPlusHexagon }   from '@fortawesome/pro-regular-svg-icons';
import { faPlusHexagon as fasPlusHexagon }   from '@fortawesome/pro-solid-svg-icons';
import { faPlusMinus as fadPlusMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlusMinus as falPlusMinus }   from '@fortawesome/pro-light-svg-icons';
import { faPlusMinus as farPlusMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faPlusMinus as fasPlusMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faPlusOctagon as fadPlusOctagon }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlusOctagon as falPlusOctagon }   from '@fortawesome/pro-light-svg-icons';
import { faPlusOctagon as farPlusOctagon }   from '@fortawesome/pro-regular-svg-icons';
import { faPlusOctagon as fasPlusOctagon }   from '@fortawesome/pro-solid-svg-icons';
import { faPlusSquare as fadPlusSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faPlusSquare as falPlusSquare }   from '@fortawesome/pro-light-svg-icons';
import { faPlusSquare as farPlusSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faPlusSquare as fasPlusSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faPodcast as fadPodcast }   from '@fortawesome/pro-duotone-svg-icons';
import { faPodcast as falPodcast }   from '@fortawesome/pro-light-svg-icons';
import { faPodcast as farPodcast }   from '@fortawesome/pro-regular-svg-icons';
import { faPodcast as fasPodcast }   from '@fortawesome/pro-solid-svg-icons';
import { faPodium as fadPodium }   from '@fortawesome/pro-duotone-svg-icons';
import { faPodium as falPodium }   from '@fortawesome/pro-light-svg-icons';
import { faPodium as farPodium }   from '@fortawesome/pro-regular-svg-icons';
import { faPodium as fasPodium }   from '@fortawesome/pro-solid-svg-icons';
import { faPodiumStar as fadPodiumStar }   from '@fortawesome/pro-duotone-svg-icons';
import { faPodiumStar as falPodiumStar }   from '@fortawesome/pro-light-svg-icons';
import { faPodiumStar as farPodiumStar }   from '@fortawesome/pro-regular-svg-icons';
import { faPodiumStar as fasPodiumStar }   from '@fortawesome/pro-solid-svg-icons';
import { faPoliceBox as fadPoliceBox }   from '@fortawesome/pro-duotone-svg-icons';
import { faPoliceBox as falPoliceBox }   from '@fortawesome/pro-light-svg-icons';
import { faPoliceBox as farPoliceBox }   from '@fortawesome/pro-regular-svg-icons';
import { faPoliceBox as fasPoliceBox }   from '@fortawesome/pro-solid-svg-icons';
import { faPoll as fadPoll }   from '@fortawesome/pro-duotone-svg-icons';
import { faPoll as falPoll }   from '@fortawesome/pro-light-svg-icons';
import { faPoll as farPoll }   from '@fortawesome/pro-regular-svg-icons';
import { faPoll as fasPoll }   from '@fortawesome/pro-solid-svg-icons';
import { faPollH as fadPollH }   from '@fortawesome/pro-duotone-svg-icons';
import { faPollH as falPollH }   from '@fortawesome/pro-light-svg-icons';
import { faPollH as farPollH }   from '@fortawesome/pro-regular-svg-icons';
import { faPollH as fasPollH }   from '@fortawesome/pro-solid-svg-icons';
import { faPollPeople as fadPollPeople }   from '@fortawesome/pro-duotone-svg-icons';
import { faPollPeople as falPollPeople }   from '@fortawesome/pro-light-svg-icons';
import { faPollPeople as farPollPeople }   from '@fortawesome/pro-regular-svg-icons';
import { faPollPeople as fasPollPeople }   from '@fortawesome/pro-solid-svg-icons';
import { faPompebled as fadPompebled }   from '@fortawesome/pro-duotone-svg-icons';
import { faPompebled as falPompebled }   from '@fortawesome/pro-light-svg-icons';
import { faPompebled as farPompebled }   from '@fortawesome/pro-regular-svg-icons';
import { faPompebled as fasPompebled }   from '@fortawesome/pro-solid-svg-icons';
import { faPoo as fadPoo }   from '@fortawesome/pro-duotone-svg-icons';
import { faPoo as falPoo }   from '@fortawesome/pro-light-svg-icons';
import { faPoo as farPoo }   from '@fortawesome/pro-regular-svg-icons';
import { faPoo as fasPoo }   from '@fortawesome/pro-solid-svg-icons';
import { faPooBolt as fadPooBolt }   from '@fortawesome/pro-duotone-svg-icons';
import { faPooBolt as falPooBolt }   from '@fortawesome/pro-light-svg-icons';
import { faPooBolt as farPooBolt }   from '@fortawesome/pro-regular-svg-icons';
import { faPooBolt as fasPooBolt }   from '@fortawesome/pro-solid-svg-icons';
import { faPool8Ball as fadPool8Ball }   from '@fortawesome/pro-duotone-svg-icons';
import { faPool8Ball as falPool8Ball }   from '@fortawesome/pro-light-svg-icons';
import { faPool8Ball as farPool8Ball }   from '@fortawesome/pro-regular-svg-icons';
import { faPool8Ball as fasPool8Ball }   from '@fortawesome/pro-solid-svg-icons';
import { faPoop as fadPoop }   from '@fortawesome/pro-duotone-svg-icons';
import { faPoop as falPoop }   from '@fortawesome/pro-light-svg-icons';
import { faPoop as farPoop }   from '@fortawesome/pro-regular-svg-icons';
import { faPoop as fasPoop }   from '@fortawesome/pro-solid-svg-icons';
import { faPooStorm as fadPooStorm }   from '@fortawesome/pro-duotone-svg-icons';
import { faPooStorm as falPooStorm }   from '@fortawesome/pro-light-svg-icons';
import { faPooStorm as farPooStorm }   from '@fortawesome/pro-regular-svg-icons';
import { faPooStorm as fasPooStorm }   from '@fortawesome/pro-solid-svg-icons';
import { faPopcorn as fadPopcorn }   from '@fortawesome/pro-duotone-svg-icons';
import { faPopcorn as falPopcorn }   from '@fortawesome/pro-light-svg-icons';
import { faPopcorn as farPopcorn }   from '@fortawesome/pro-regular-svg-icons';
import { faPopcorn as fasPopcorn }   from '@fortawesome/pro-solid-svg-icons';
import { faPopsicle as fadPopsicle }   from '@fortawesome/pro-duotone-svg-icons';
import { faPopsicle as falPopsicle }   from '@fortawesome/pro-light-svg-icons';
import { faPopsicle as farPopsicle }   from '@fortawesome/pro-regular-svg-icons';
import { faPopsicle as fasPopsicle }   from '@fortawesome/pro-solid-svg-icons';
import { faPortalEnter as fadPortalEnter }   from '@fortawesome/pro-duotone-svg-icons';
import { faPortalEnter as falPortalEnter }   from '@fortawesome/pro-light-svg-icons';
import { faPortalEnter as farPortalEnter }   from '@fortawesome/pro-regular-svg-icons';
import { faPortalEnter as fasPortalEnter }   from '@fortawesome/pro-solid-svg-icons';
import { faPortalExit as fadPortalExit }   from '@fortawesome/pro-duotone-svg-icons';
import { faPortalExit as falPortalExit }   from '@fortawesome/pro-light-svg-icons';
import { faPortalExit as farPortalExit }   from '@fortawesome/pro-regular-svg-icons';
import { faPortalExit as fasPortalExit }   from '@fortawesome/pro-solid-svg-icons';
import { faPortrait as fadPortrait }   from '@fortawesome/pro-duotone-svg-icons';
import { faPortrait as falPortrait }   from '@fortawesome/pro-light-svg-icons';
import { faPortrait as farPortrait }   from '@fortawesome/pro-regular-svg-icons';
import { faPortrait as fasPortrait }   from '@fortawesome/pro-solid-svg-icons';
import { faPotato as fadPotato }   from '@fortawesome/pro-duotone-svg-icons';
import { faPotato as falPotato }   from '@fortawesome/pro-light-svg-icons';
import { faPotato as farPotato }   from '@fortawesome/pro-regular-svg-icons';
import { faPotato as fasPotato }   from '@fortawesome/pro-solid-svg-icons';
import { faPotFood as fadPotFood }   from '@fortawesome/pro-duotone-svg-icons';
import { faPotFood as falPotFood }   from '@fortawesome/pro-light-svg-icons';
import { faPotFood as farPotFood }   from '@fortawesome/pro-regular-svg-icons';
import { faPotFood as fasPotFood }   from '@fortawesome/pro-solid-svg-icons';
import { faPoundSign as fadPoundSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faPoundSign as falPoundSign }   from '@fortawesome/pro-light-svg-icons';
import { faPoundSign as farPoundSign }   from '@fortawesome/pro-regular-svg-icons';
import { faPoundSign as fasPoundSign }   from '@fortawesome/pro-solid-svg-icons';
import { faPowerOff as fadPowerOff }   from '@fortawesome/pro-duotone-svg-icons';
import { faPowerOff as falPowerOff }   from '@fortawesome/pro-light-svg-icons';
import { faPowerOff as farPowerOff }   from '@fortawesome/pro-regular-svg-icons';
import { faPowerOff as fasPowerOff }   from '@fortawesome/pro-solid-svg-icons';
import { faPray as fadPray }   from '@fortawesome/pro-duotone-svg-icons';
import { faPray as falPray }   from '@fortawesome/pro-light-svg-icons';
import { faPray as farPray }   from '@fortawesome/pro-regular-svg-icons';
import { faPray as fasPray }   from '@fortawesome/pro-solid-svg-icons';
import { faPrayingHands as fadPrayingHands }   from '@fortawesome/pro-duotone-svg-icons';
import { faPrayingHands as falPrayingHands }   from '@fortawesome/pro-light-svg-icons';
import { faPrayingHands as farPrayingHands }   from '@fortawesome/pro-regular-svg-icons';
import { faPrayingHands as fasPrayingHands }   from '@fortawesome/pro-solid-svg-icons';
import { faPrescription as fadPrescription }   from '@fortawesome/pro-duotone-svg-icons';
import { faPrescription as falPrescription }   from '@fortawesome/pro-light-svg-icons';
import { faPrescription as farPrescription }   from '@fortawesome/pro-regular-svg-icons';
import { faPrescription as fasPrescription }   from '@fortawesome/pro-solid-svg-icons';
import { faPrescriptionBottle as fadPrescriptionBottle }   from '@fortawesome/pro-duotone-svg-icons';
import { faPrescriptionBottle as falPrescriptionBottle }   from '@fortawesome/pro-light-svg-icons';
import { faPrescriptionBottle as farPrescriptionBottle }   from '@fortawesome/pro-regular-svg-icons';
import { faPrescriptionBottle as fasPrescriptionBottle }   from '@fortawesome/pro-solid-svg-icons';
import { faPrescriptionBottleAlt as fadPrescriptionBottleAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faPrescriptionBottleAlt as falPrescriptionBottleAlt }   from '@fortawesome/pro-light-svg-icons';
import { faPrescriptionBottleAlt as farPrescriptionBottleAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faPrescriptionBottleAlt as fasPrescriptionBottleAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faPrescriptionBottleMedical as fadPrescriptionBottleMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faPrescriptionBottleMedical as falPrescriptionBottleMedical }   from '@fortawesome/pro-light-svg-icons';
import { faPrescriptionBottleMedical as farPrescriptionBottleMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faPrescriptionBottleMedical as fasPrescriptionBottleMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faPresentation as fadPresentation }   from '@fortawesome/pro-duotone-svg-icons';
import { faPresentation as falPresentation }   from '@fortawesome/pro-light-svg-icons';
import { faPresentation as farPresentation }   from '@fortawesome/pro-regular-svg-icons';
import { faPresentation as fasPresentation }   from '@fortawesome/pro-solid-svg-icons';
import { faPresentationScreen as fadPresentationScreen }   from '@fortawesome/pro-duotone-svg-icons';
import { faPresentationScreen as falPresentationScreen }   from '@fortawesome/pro-light-svg-icons';
import { faPresentationScreen as farPresentationScreen }   from '@fortawesome/pro-regular-svg-icons';
import { faPresentationScreen as fasPresentationScreen }   from '@fortawesome/pro-solid-svg-icons';
import { faPretzel as fadPretzel }   from '@fortawesome/pro-duotone-svg-icons';
import { faPretzel as falPretzel }   from '@fortawesome/pro-light-svg-icons';
import { faPretzel as farPretzel }   from '@fortawesome/pro-regular-svg-icons';
import { faPretzel as fasPretzel }   from '@fortawesome/pro-solid-svg-icons';
import { faPrint as fadPrint }   from '@fortawesome/pro-duotone-svg-icons';
import { faPrint as falPrint }   from '@fortawesome/pro-light-svg-icons';
import { faPrint as farPrint }   from '@fortawesome/pro-regular-svg-icons';
import { faPrint as fasPrint }   from '@fortawesome/pro-solid-svg-icons';
import { faPrintMagnifyingGlass as fadPrintMagnifyingGlass }   from '@fortawesome/pro-duotone-svg-icons';
import { faPrintMagnifyingGlass as falPrintMagnifyingGlass }   from '@fortawesome/pro-light-svg-icons';
import { faPrintMagnifyingGlass as farPrintMagnifyingGlass }   from '@fortawesome/pro-regular-svg-icons';
import { faPrintMagnifyingGlass as fasPrintMagnifyingGlass }   from '@fortawesome/pro-solid-svg-icons';
import { faPrintSearch as fadPrintSearch }   from '@fortawesome/pro-duotone-svg-icons';
import { faPrintSearch as falPrintSearch }   from '@fortawesome/pro-light-svg-icons';
import { faPrintSearch as farPrintSearch }   from '@fortawesome/pro-regular-svg-icons';
import { faPrintSearch as fasPrintSearch }   from '@fortawesome/pro-solid-svg-icons';
import { faPrintSlash as fadPrintSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faPrintSlash as falPrintSlash }   from '@fortawesome/pro-light-svg-icons';
import { faPrintSlash as farPrintSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faPrintSlash as fasPrintSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faPro as fadPro }   from '@fortawesome/pro-duotone-svg-icons';
import { faPro as falPro }   from '@fortawesome/pro-light-svg-icons';
import { faPro as farPro }   from '@fortawesome/pro-regular-svg-icons';
import { faPro as fasPro }   from '@fortawesome/pro-solid-svg-icons';
import { faProcedures as fadProcedures }   from '@fortawesome/pro-duotone-svg-icons';
import { faProcedures as falProcedures }   from '@fortawesome/pro-light-svg-icons';
import { faProcedures as farProcedures }   from '@fortawesome/pro-regular-svg-icons';
import { faProcedures as fasProcedures }   from '@fortawesome/pro-solid-svg-icons';
import { faProjectDiagram as fadProjectDiagram }   from '@fortawesome/pro-duotone-svg-icons';
import { faProjectDiagram as falProjectDiagram }   from '@fortawesome/pro-light-svg-icons';
import { faProjectDiagram as farProjectDiagram }   from '@fortawesome/pro-regular-svg-icons';
import { faProjectDiagram as fasProjectDiagram }   from '@fortawesome/pro-solid-svg-icons';
import { faProjector as fadProjector }   from '@fortawesome/pro-duotone-svg-icons';
import { faProjector as falProjector }   from '@fortawesome/pro-light-svg-icons';
import { faProjector as farProjector }   from '@fortawesome/pro-regular-svg-icons';
import { faProjector as fasProjector }   from '@fortawesome/pro-solid-svg-icons';
import { faPump as fadPump }   from '@fortawesome/pro-duotone-svg-icons';
import { faPump as falPump }   from '@fortawesome/pro-light-svg-icons';
import { faPump as farPump }   from '@fortawesome/pro-regular-svg-icons';
import { faPump as fasPump }   from '@fortawesome/pro-solid-svg-icons';
import { faPumpkin as fadPumpkin }   from '@fortawesome/pro-duotone-svg-icons';
import { faPumpkin as falPumpkin }   from '@fortawesome/pro-light-svg-icons';
import { faPumpkin as farPumpkin }   from '@fortawesome/pro-regular-svg-icons';
import { faPumpkin as fasPumpkin }   from '@fortawesome/pro-solid-svg-icons';
import { faPumpMedical as fadPumpMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faPumpMedical as falPumpMedical }   from '@fortawesome/pro-light-svg-icons';
import { faPumpMedical as farPumpMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faPumpMedical as fasPumpMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faPumpSoap as fadPumpSoap }   from '@fortawesome/pro-duotone-svg-icons';
import { faPumpSoap as falPumpSoap }   from '@fortawesome/pro-light-svg-icons';
import { faPumpSoap as farPumpSoap }   from '@fortawesome/pro-regular-svg-icons';
import { faPumpSoap as fasPumpSoap }   from '@fortawesome/pro-solid-svg-icons';
import { faPuzzle as fadPuzzle }   from '@fortawesome/pro-duotone-svg-icons';
import { faPuzzle as falPuzzle }   from '@fortawesome/pro-light-svg-icons';
import { faPuzzle as farPuzzle }   from '@fortawesome/pro-regular-svg-icons';
import { faPuzzle as fasPuzzle }   from '@fortawesome/pro-solid-svg-icons';
import { faPuzzlePiece as fadPuzzlePiece }   from '@fortawesome/pro-duotone-svg-icons';
import { faPuzzlePiece as falPuzzlePiece }   from '@fortawesome/pro-light-svg-icons';
import { faPuzzlePiece as farPuzzlePiece }   from '@fortawesome/pro-regular-svg-icons';
import { faPuzzlePiece as fasPuzzlePiece }   from '@fortawesome/pro-solid-svg-icons';
import { faPuzzlePieceAlt as fadPuzzlePieceAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faPuzzlePieceAlt as falPuzzlePieceAlt }   from '@fortawesome/pro-light-svg-icons';
import { faPuzzlePieceAlt as farPuzzlePieceAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faPuzzlePieceAlt as fasPuzzlePieceAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faPuzzlePieceSimple as fadPuzzlePieceSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faPuzzlePieceSimple as falPuzzlePieceSimple }   from '@fortawesome/pro-light-svg-icons';
import { faPuzzlePieceSimple as farPuzzlePieceSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faPuzzlePieceSimple as fasPuzzlePieceSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faQ as fadQ }   from '@fortawesome/pro-duotone-svg-icons';
import { faQ as falQ }   from '@fortawesome/pro-light-svg-icons';
import { faQ as farQ }   from '@fortawesome/pro-regular-svg-icons';
import { faQ as fasQ }   from '@fortawesome/pro-solid-svg-icons';
import { faQrcode as fadQrcode }   from '@fortawesome/pro-duotone-svg-icons';
import { faQrcode as falQrcode }   from '@fortawesome/pro-light-svg-icons';
import { faQrcode as farQrcode }   from '@fortawesome/pro-regular-svg-icons';
import { faQrcode as fasQrcode }   from '@fortawesome/pro-solid-svg-icons';
import { faQuestion as fadQuestion }   from '@fortawesome/pro-duotone-svg-icons';
import { faQuestion as falQuestion }   from '@fortawesome/pro-light-svg-icons';
import { faQuestion as farQuestion }   from '@fortawesome/pro-regular-svg-icons';
import { faQuestion as fasQuestion }   from '@fortawesome/pro-solid-svg-icons';
import { faQuestionCircle as fadQuestionCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faQuestionCircle as falQuestionCircle }   from '@fortawesome/pro-light-svg-icons';
import { faQuestionCircle as farQuestionCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faQuestionCircle as fasQuestionCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faQuestionSquare as fadQuestionSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faQuestionSquare as falQuestionSquare }   from '@fortawesome/pro-light-svg-icons';
import { faQuestionSquare as farQuestionSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faQuestionSquare as fasQuestionSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faQuidditch as fadQuidditch }   from '@fortawesome/pro-duotone-svg-icons';
import { faQuidditch as falQuidditch }   from '@fortawesome/pro-light-svg-icons';
import { faQuidditch as farQuidditch }   from '@fortawesome/pro-regular-svg-icons';
import { faQuidditch as fasQuidditch }   from '@fortawesome/pro-solid-svg-icons';
import { faQuidditchBroomBall as fadQuidditchBroomBall }   from '@fortawesome/pro-duotone-svg-icons';
import { faQuidditchBroomBall as falQuidditchBroomBall }   from '@fortawesome/pro-light-svg-icons';
import { faQuidditchBroomBall as farQuidditchBroomBall }   from '@fortawesome/pro-regular-svg-icons';
import { faQuidditchBroomBall as fasQuidditchBroomBall }   from '@fortawesome/pro-solid-svg-icons';
import { faQuoteLeft as fadQuoteLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faQuoteLeft as falQuoteLeft }   from '@fortawesome/pro-light-svg-icons';
import { faQuoteLeft as farQuoteLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faQuoteLeft as fasQuoteLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faQuoteLeftAlt as fadQuoteLeftAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faQuoteLeftAlt as falQuoteLeftAlt }   from '@fortawesome/pro-light-svg-icons';
import { faQuoteLeftAlt as farQuoteLeftAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faQuoteLeftAlt as fasQuoteLeftAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faQuoteRight as fadQuoteRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faQuoteRight as falQuoteRight }   from '@fortawesome/pro-light-svg-icons';
import { faQuoteRight as farQuoteRight }   from '@fortawesome/pro-regular-svg-icons';
import { faQuoteRight as fasQuoteRight }   from '@fortawesome/pro-solid-svg-icons';
import { faQuoteRightAlt as fadQuoteRightAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faQuoteRightAlt as falQuoteRightAlt }   from '@fortawesome/pro-light-svg-icons';
import { faQuoteRightAlt as farQuoteRightAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faQuoteRightAlt as fasQuoteRightAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faQuotes as fadQuotes }   from '@fortawesome/pro-duotone-svg-icons';
import { faQuotes as falQuotes }   from '@fortawesome/pro-light-svg-icons';
import { faQuotes as farQuotes }   from '@fortawesome/pro-regular-svg-icons';
import { faQuotes as fasQuotes }   from '@fortawesome/pro-solid-svg-icons';
import { faQuran as fadQuran }   from '@fortawesome/pro-duotone-svg-icons';
import { faQuran as falQuran }   from '@fortawesome/pro-light-svg-icons';
import { faQuran as farQuran }   from '@fortawesome/pro-regular-svg-icons';
import { faQuran as fasQuran }   from '@fortawesome/pro-solid-svg-icons';
import { faR as fadR }   from '@fortawesome/pro-duotone-svg-icons';
import { faR as falR }   from '@fortawesome/pro-light-svg-icons';
import { faR as farR }   from '@fortawesome/pro-regular-svg-icons';
import { faR as fasR }   from '@fortawesome/pro-solid-svg-icons';
import { faRabbit as fadRabbit }   from '@fortawesome/pro-duotone-svg-icons';
import { faRabbit as falRabbit }   from '@fortawesome/pro-light-svg-icons';
import { faRabbit as farRabbit }   from '@fortawesome/pro-regular-svg-icons';
import { faRabbit as fasRabbit }   from '@fortawesome/pro-solid-svg-icons';
import { faRabbitFast as fadRabbitFast }   from '@fortawesome/pro-duotone-svg-icons';
import { faRabbitFast as falRabbitFast }   from '@fortawesome/pro-light-svg-icons';
import { faRabbitFast as farRabbitFast }   from '@fortawesome/pro-regular-svg-icons';
import { faRabbitFast as fasRabbitFast }   from '@fortawesome/pro-solid-svg-icons';
import { faRabbitRunning as fadRabbitRunning }   from '@fortawesome/pro-duotone-svg-icons';
import { faRabbitRunning as falRabbitRunning }   from '@fortawesome/pro-light-svg-icons';
import { faRabbitRunning as farRabbitRunning }   from '@fortawesome/pro-regular-svg-icons';
import { faRabbitRunning as fasRabbitRunning }   from '@fortawesome/pro-solid-svg-icons';
import { faRacquet as fadRacquet }   from '@fortawesome/pro-duotone-svg-icons';
import { faRacquet as falRacquet }   from '@fortawesome/pro-light-svg-icons';
import { faRacquet as farRacquet }   from '@fortawesome/pro-regular-svg-icons';
import { faRacquet as fasRacquet }   from '@fortawesome/pro-solid-svg-icons';
import { faRadar as fadRadar }   from '@fortawesome/pro-duotone-svg-icons';
import { faRadar as falRadar }   from '@fortawesome/pro-light-svg-icons';
import { faRadar as farRadar }   from '@fortawesome/pro-regular-svg-icons';
import { faRadar as fasRadar }   from '@fortawesome/pro-solid-svg-icons';
import { faRadiation as fadRadiation }   from '@fortawesome/pro-duotone-svg-icons';
import { faRadiation as falRadiation }   from '@fortawesome/pro-light-svg-icons';
import { faRadiation as farRadiation }   from '@fortawesome/pro-regular-svg-icons';
import { faRadiation as fasRadiation }   from '@fortawesome/pro-solid-svg-icons';
import { faRadiationAlt as fadRadiationAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faRadiationAlt as falRadiationAlt }   from '@fortawesome/pro-light-svg-icons';
import { faRadiationAlt as farRadiationAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faRadiationAlt as fasRadiationAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faRadio as fadRadio }   from '@fortawesome/pro-duotone-svg-icons';
import { faRadio as falRadio }   from '@fortawesome/pro-light-svg-icons';
import { faRadio as farRadio }   from '@fortawesome/pro-regular-svg-icons';
import { faRadio as fasRadio }   from '@fortawesome/pro-solid-svg-icons';
import { faRadioAlt as fadRadioAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faRadioAlt as falRadioAlt }   from '@fortawesome/pro-light-svg-icons';
import { faRadioAlt as farRadioAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faRadioAlt as fasRadioAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faRadioTuner as fadRadioTuner }   from '@fortawesome/pro-duotone-svg-icons';
import { faRadioTuner as falRadioTuner }   from '@fortawesome/pro-light-svg-icons';
import { faRadioTuner as farRadioTuner }   from '@fortawesome/pro-regular-svg-icons';
import { faRadioTuner as fasRadioTuner }   from '@fortawesome/pro-solid-svg-icons';
import { faRainbow as fadRainbow }   from '@fortawesome/pro-duotone-svg-icons';
import { faRainbow as falRainbow }   from '@fortawesome/pro-light-svg-icons';
import { faRainbow as farRainbow }   from '@fortawesome/pro-regular-svg-icons';
import { faRainbow as fasRainbow }   from '@fortawesome/pro-solid-svg-icons';
import { faRaindrops as fadRaindrops }   from '@fortawesome/pro-duotone-svg-icons';
import { faRaindrops as falRaindrops }   from '@fortawesome/pro-light-svg-icons';
import { faRaindrops as farRaindrops }   from '@fortawesome/pro-regular-svg-icons';
import { faRaindrops as fasRaindrops }   from '@fortawesome/pro-solid-svg-icons';
import { faRam as fadRam }   from '@fortawesome/pro-duotone-svg-icons';
import { faRam as falRam }   from '@fortawesome/pro-light-svg-icons';
import { faRam as farRam }   from '@fortawesome/pro-regular-svg-icons';
import { faRam as fasRam }   from '@fortawesome/pro-solid-svg-icons';
import { faRampLoading as fadRampLoading }   from '@fortawesome/pro-duotone-svg-icons';
import { faRampLoading as falRampLoading }   from '@fortawesome/pro-light-svg-icons';
import { faRampLoading as farRampLoading }   from '@fortawesome/pro-regular-svg-icons';
import { faRampLoading as fasRampLoading }   from '@fortawesome/pro-solid-svg-icons';
import { faRandom as fadRandom }   from '@fortawesome/pro-duotone-svg-icons';
import { faRandom as falRandom }   from '@fortawesome/pro-light-svg-icons';
import { faRandom as farRandom }   from '@fortawesome/pro-regular-svg-icons';
import { faRandom as fasRandom }   from '@fortawesome/pro-solid-svg-icons';
import { faRankingStar as fadRankingStar }   from '@fortawesome/pro-duotone-svg-icons';
import { faRankingStar as falRankingStar }   from '@fortawesome/pro-light-svg-icons';
import { faRankingStar as farRankingStar }   from '@fortawesome/pro-regular-svg-icons';
import { faRankingStar as fasRankingStar }   from '@fortawesome/pro-solid-svg-icons';
import { faRaygun as fadRaygun }   from '@fortawesome/pro-duotone-svg-icons';
import { faRaygun as falRaygun }   from '@fortawesome/pro-light-svg-icons';
import { faRaygun as farRaygun }   from '@fortawesome/pro-regular-svg-icons';
import { faRaygun as fasRaygun }   from '@fortawesome/pro-solid-svg-icons';
import { faReceipt as fadReceipt }   from '@fortawesome/pro-duotone-svg-icons';
import { faReceipt as falReceipt }   from '@fortawesome/pro-light-svg-icons';
import { faReceipt as farReceipt }   from '@fortawesome/pro-regular-svg-icons';
import { faReceipt as fasReceipt }   from '@fortawesome/pro-solid-svg-icons';
import { faRecordVinyl as fadRecordVinyl }   from '@fortawesome/pro-duotone-svg-icons';
import { faRecordVinyl as falRecordVinyl }   from '@fortawesome/pro-light-svg-icons';
import { faRecordVinyl as farRecordVinyl }   from '@fortawesome/pro-regular-svg-icons';
import { faRecordVinyl as fasRecordVinyl }   from '@fortawesome/pro-solid-svg-icons';
import { faRectangle as fadRectangle }   from '@fortawesome/pro-duotone-svg-icons';
import { faRectangle as falRectangle }   from '@fortawesome/pro-light-svg-icons';
import { faRectangle as farRectangle }   from '@fortawesome/pro-regular-svg-icons';
import { faRectangle as fasRectangle }   from '@fortawesome/pro-solid-svg-icons';
import { faRectangleAd as fadRectangleAd }   from '@fortawesome/pro-duotone-svg-icons';
import { faRectangleAd as falRectangleAd }   from '@fortawesome/pro-light-svg-icons';
import { faRectangleAd as farRectangleAd }   from '@fortawesome/pro-regular-svg-icons';
import { faRectangleAd as fasRectangleAd }   from '@fortawesome/pro-solid-svg-icons';
import { faRectangleBarcode as fadRectangleBarcode }   from '@fortawesome/pro-duotone-svg-icons';
import { faRectangleBarcode as falRectangleBarcode }   from '@fortawesome/pro-light-svg-icons';
import { faRectangleBarcode as farRectangleBarcode }   from '@fortawesome/pro-regular-svg-icons';
import { faRectangleBarcode as fasRectangleBarcode }   from '@fortawesome/pro-solid-svg-icons';
import { faRectangleCode as fadRectangleCode }   from '@fortawesome/pro-duotone-svg-icons';
import { faRectangleCode as falRectangleCode }   from '@fortawesome/pro-light-svg-icons';
import { faRectangleCode as farRectangleCode }   from '@fortawesome/pro-regular-svg-icons';
import { faRectangleCode as fasRectangleCode }   from '@fortawesome/pro-solid-svg-icons';
import { faRectangleHd as fadRectangleHd }   from '@fortawesome/pro-duotone-svg-icons';
import { faRectangleHd as falRectangleHd }   from '@fortawesome/pro-light-svg-icons';
import { faRectangleHd as farRectangleHd }   from '@fortawesome/pro-regular-svg-icons';
import { faRectangleHd as fasRectangleHd }   from '@fortawesome/pro-solid-svg-icons';
import { faRectangleHistory as fadRectangleHistory }   from '@fortawesome/pro-duotone-svg-icons';
import { faRectangleHistory as falRectangleHistory }   from '@fortawesome/pro-light-svg-icons';
import { faRectangleHistory as farRectangleHistory }   from '@fortawesome/pro-regular-svg-icons';
import { faRectangleHistory as fasRectangleHistory }   from '@fortawesome/pro-solid-svg-icons';
import { faRectangleHistoryCirclePlus as fadRectangleHistoryCirclePlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faRectangleHistoryCirclePlus as falRectangleHistoryCirclePlus }   from '@fortawesome/pro-light-svg-icons';
import { faRectangleHistoryCirclePlus as farRectangleHistoryCirclePlus }   from '@fortawesome/pro-regular-svg-icons';
import { faRectangleHistoryCirclePlus as fasRectangleHistoryCirclePlus }   from '@fortawesome/pro-solid-svg-icons';
import { faRectangleHistoryCircleUser as fadRectangleHistoryCircleUser }   from '@fortawesome/pro-duotone-svg-icons';
import { faRectangleHistoryCircleUser as falRectangleHistoryCircleUser }   from '@fortawesome/pro-light-svg-icons';
import { faRectangleHistoryCircleUser as farRectangleHistoryCircleUser }   from '@fortawesome/pro-regular-svg-icons';
import { faRectangleHistoryCircleUser as fasRectangleHistoryCircleUser }   from '@fortawesome/pro-solid-svg-icons';
import { faRectangleLandscape as fadRectangleLandscape }   from '@fortawesome/pro-duotone-svg-icons';
import { faRectangleLandscape as falRectangleLandscape }   from '@fortawesome/pro-light-svg-icons';
import { faRectangleLandscape as farRectangleLandscape }   from '@fortawesome/pro-regular-svg-icons';
import { faRectangleLandscape as fasRectangleLandscape }   from '@fortawesome/pro-solid-svg-icons';
import { faRectangleList as fadRectangleList }   from '@fortawesome/pro-duotone-svg-icons';
import { faRectangleList as falRectangleList }   from '@fortawesome/pro-light-svg-icons';
import { faRectangleList as farRectangleList }   from '@fortawesome/pro-regular-svg-icons';
import { faRectangleList as fasRectangleList }   from '@fortawesome/pro-solid-svg-icons';
import { faRectanglePortrait as fadRectanglePortrait }   from '@fortawesome/pro-duotone-svg-icons';
import { faRectanglePortrait as falRectanglePortrait }   from '@fortawesome/pro-light-svg-icons';
import { faRectanglePortrait as farRectanglePortrait }   from '@fortawesome/pro-regular-svg-icons';
import { faRectanglePortrait as fasRectanglePortrait }   from '@fortawesome/pro-solid-svg-icons';
import { faRectanglePro as fadRectanglePro }   from '@fortawesome/pro-duotone-svg-icons';
import { faRectanglePro as falRectanglePro }   from '@fortawesome/pro-light-svg-icons';
import { faRectanglePro as farRectanglePro }   from '@fortawesome/pro-regular-svg-icons';
import { faRectanglePro as fasRectanglePro }   from '@fortawesome/pro-solid-svg-icons';
import { faRectangleSd as fadRectangleSd }   from '@fortawesome/pro-duotone-svg-icons';
import { faRectangleSd as falRectangleSd }   from '@fortawesome/pro-light-svg-icons';
import { faRectangleSd as farRectangleSd }   from '@fortawesome/pro-regular-svg-icons';
import { faRectangleSd as fasRectangleSd }   from '@fortawesome/pro-solid-svg-icons';
import { faRectanglesMixed as fadRectanglesMixed }   from '@fortawesome/pro-duotone-svg-icons';
import { faRectanglesMixed as falRectanglesMixed }   from '@fortawesome/pro-light-svg-icons';
import { faRectanglesMixed as farRectanglesMixed }   from '@fortawesome/pro-regular-svg-icons';
import { faRectanglesMixed as fasRectanglesMixed }   from '@fortawesome/pro-solid-svg-icons';
import { faRectangleTerminal as fadRectangleTerminal }   from '@fortawesome/pro-duotone-svg-icons';
import { faRectangleTerminal as falRectangleTerminal }   from '@fortawesome/pro-light-svg-icons';
import { faRectangleTerminal as farRectangleTerminal }   from '@fortawesome/pro-regular-svg-icons';
import { faRectangleTerminal as fasRectangleTerminal }   from '@fortawesome/pro-solid-svg-icons';
import { faRectangleTimes as fadRectangleTimes }   from '@fortawesome/pro-duotone-svg-icons';
import { faRectangleTimes as falRectangleTimes }   from '@fortawesome/pro-light-svg-icons';
import { faRectangleTimes as farRectangleTimes }   from '@fortawesome/pro-regular-svg-icons';
import { faRectangleTimes as fasRectangleTimes }   from '@fortawesome/pro-solid-svg-icons';
import { faRectangleVertical as fadRectangleVertical }   from '@fortawesome/pro-duotone-svg-icons';
import { faRectangleVertical as falRectangleVertical }   from '@fortawesome/pro-light-svg-icons';
import { faRectangleVertical as farRectangleVertical }   from '@fortawesome/pro-regular-svg-icons';
import { faRectangleVertical as fasRectangleVertical }   from '@fortawesome/pro-solid-svg-icons';
import { faRectangleVerticalHistory as fadRectangleVerticalHistory }   from '@fortawesome/pro-duotone-svg-icons';
import { faRectangleVerticalHistory as falRectangleVerticalHistory }   from '@fortawesome/pro-light-svg-icons';
import { faRectangleVerticalHistory as farRectangleVerticalHistory }   from '@fortawesome/pro-regular-svg-icons';
import { faRectangleVerticalHistory as fasRectangleVerticalHistory }   from '@fortawesome/pro-solid-svg-icons';
import { faRectangleWide as fadRectangleWide }   from '@fortawesome/pro-duotone-svg-icons';
import { faRectangleWide as falRectangleWide }   from '@fortawesome/pro-light-svg-icons';
import { faRectangleWide as farRectangleWide }   from '@fortawesome/pro-regular-svg-icons';
import { faRectangleWide as fasRectangleWide }   from '@fortawesome/pro-solid-svg-icons';
import { faRectangleXmark as fadRectangleXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faRectangleXmark as falRectangleXmark }   from '@fortawesome/pro-light-svg-icons';
import { faRectangleXmark as farRectangleXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faRectangleXmark as fasRectangleXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faRecycle as fadRecycle }   from '@fortawesome/pro-duotone-svg-icons';
import { faRecycle as falRecycle }   from '@fortawesome/pro-light-svg-icons';
import { faRecycle as farRecycle }   from '@fortawesome/pro-regular-svg-icons';
import { faRecycle as fasRecycle }   from '@fortawesome/pro-solid-svg-icons';
import { faRedo as fadRedo }   from '@fortawesome/pro-duotone-svg-icons';
import { faRedo as falRedo }   from '@fortawesome/pro-light-svg-icons';
import { faRedo as farRedo }   from '@fortawesome/pro-regular-svg-icons';
import { faRedo as fasRedo }   from '@fortawesome/pro-solid-svg-icons';
import { faRedoAlt as fadRedoAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faRedoAlt as falRedoAlt }   from '@fortawesome/pro-light-svg-icons';
import { faRedoAlt as farRedoAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faRedoAlt as fasRedoAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faReel as fadReel }   from '@fortawesome/pro-duotone-svg-icons';
import { faReel as falReel }   from '@fortawesome/pro-light-svg-icons';
import { faReel as farReel }   from '@fortawesome/pro-regular-svg-icons';
import { faReel as fasReel }   from '@fortawesome/pro-solid-svg-icons';
import { faRefresh as fadRefresh }   from '@fortawesome/pro-duotone-svg-icons';
import { faRefresh as falRefresh }   from '@fortawesome/pro-light-svg-icons';
import { faRefresh as farRefresh }   from '@fortawesome/pro-regular-svg-icons';
import { faRefresh as fasRefresh }   from '@fortawesome/pro-solid-svg-icons';
import { faRefrigerator as fadRefrigerator }   from '@fortawesome/pro-duotone-svg-icons';
import { faRefrigerator as falRefrigerator }   from '@fortawesome/pro-light-svg-icons';
import { faRefrigerator as farRefrigerator }   from '@fortawesome/pro-regular-svg-icons';
import { faRefrigerator as fasRefrigerator }   from '@fortawesome/pro-solid-svg-icons';
import { faRegistered as fadRegistered }   from '@fortawesome/pro-duotone-svg-icons';
import { faRegistered as falRegistered }   from '@fortawesome/pro-light-svg-icons';
import { faRegistered as farRegistered }   from '@fortawesome/pro-regular-svg-icons';
import { faRegistered as fasRegistered }   from '@fortawesome/pro-solid-svg-icons';
import { faRemove as fadRemove }   from '@fortawesome/pro-duotone-svg-icons';
import { faRemove as falRemove }   from '@fortawesome/pro-light-svg-icons';
import { faRemove as farRemove }   from '@fortawesome/pro-regular-svg-icons';
import { faRemove as fasRemove }   from '@fortawesome/pro-solid-svg-icons';
import { faRemoveFormat as fadRemoveFormat }   from '@fortawesome/pro-duotone-svg-icons';
import { faRemoveFormat as falRemoveFormat }   from '@fortawesome/pro-light-svg-icons';
import { faRemoveFormat as farRemoveFormat }   from '@fortawesome/pro-regular-svg-icons';
import { faRemoveFormat as fasRemoveFormat }   from '@fortawesome/pro-solid-svg-icons';
import { faReorder as fadReorder }   from '@fortawesome/pro-duotone-svg-icons';
import { faReorder as falReorder }   from '@fortawesome/pro-light-svg-icons';
import { faReorder as farReorder }   from '@fortawesome/pro-regular-svg-icons';
import { faReorder as fasReorder }   from '@fortawesome/pro-solid-svg-icons';
import { faRepeat as fadRepeat }   from '@fortawesome/pro-duotone-svg-icons';
import { faRepeat as falRepeat }   from '@fortawesome/pro-light-svg-icons';
import { faRepeat as farRepeat }   from '@fortawesome/pro-regular-svg-icons';
import { faRepeat as fasRepeat }   from '@fortawesome/pro-solid-svg-icons';
import { faRepeat1 as fadRepeat1 }   from '@fortawesome/pro-duotone-svg-icons';
import { faRepeat1 as falRepeat1 }   from '@fortawesome/pro-light-svg-icons';
import { faRepeat1 as farRepeat1 }   from '@fortawesome/pro-regular-svg-icons';
import { faRepeat1 as fasRepeat1 }   from '@fortawesome/pro-solid-svg-icons';
import { faRepeat1Alt as fadRepeat1Alt }   from '@fortawesome/pro-duotone-svg-icons';
import { faRepeat1Alt as falRepeat1Alt }   from '@fortawesome/pro-light-svg-icons';
import { faRepeat1Alt as farRepeat1Alt }   from '@fortawesome/pro-regular-svg-icons';
import { faRepeat1Alt as fasRepeat1Alt }   from '@fortawesome/pro-solid-svg-icons';
import { faRepeatAlt as fadRepeatAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faRepeatAlt as falRepeatAlt }   from '@fortawesome/pro-light-svg-icons';
import { faRepeatAlt as farRepeatAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faRepeatAlt as fasRepeatAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faReply as fadReply }   from '@fortawesome/pro-duotone-svg-icons';
import { faReply as falReply }   from '@fortawesome/pro-light-svg-icons';
import { faReply as farReply }   from '@fortawesome/pro-regular-svg-icons';
import { faReply as fasReply }   from '@fortawesome/pro-solid-svg-icons';
import { faReplyAll as fadReplyAll }   from '@fortawesome/pro-duotone-svg-icons';
import { faReplyAll as falReplyAll }   from '@fortawesome/pro-light-svg-icons';
import { faReplyAll as farReplyAll }   from '@fortawesome/pro-regular-svg-icons';
import { faReplyAll as fasReplyAll }   from '@fortawesome/pro-solid-svg-icons';
import { faReplyClock as fadReplyClock }   from '@fortawesome/pro-duotone-svg-icons';
import { faReplyClock as falReplyClock }   from '@fortawesome/pro-light-svg-icons';
import { faReplyClock as farReplyClock }   from '@fortawesome/pro-regular-svg-icons';
import { faReplyClock as fasReplyClock }   from '@fortawesome/pro-solid-svg-icons';
import { faReplyTime as fadReplyTime }   from '@fortawesome/pro-duotone-svg-icons';
import { faReplyTime as falReplyTime }   from '@fortawesome/pro-light-svg-icons';
import { faReplyTime as farReplyTime }   from '@fortawesome/pro-regular-svg-icons';
import { faReplyTime as fasReplyTime }   from '@fortawesome/pro-solid-svg-icons';
import { faRepublican as fadRepublican }   from '@fortawesome/pro-duotone-svg-icons';
import { faRepublican as falRepublican }   from '@fortawesome/pro-light-svg-icons';
import { faRepublican as farRepublican }   from '@fortawesome/pro-regular-svg-icons';
import { faRepublican as fasRepublican }   from '@fortawesome/pro-solid-svg-icons';
import { faRestroom as fadRestroom }   from '@fortawesome/pro-duotone-svg-icons';
import { faRestroom as falRestroom }   from '@fortawesome/pro-light-svg-icons';
import { faRestroom as farRestroom }   from '@fortawesome/pro-regular-svg-icons';
import { faRestroom as fasRestroom }   from '@fortawesome/pro-solid-svg-icons';
import { faRestroomSimple as fadRestroomSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faRestroomSimple as falRestroomSimple }   from '@fortawesome/pro-light-svg-icons';
import { faRestroomSimple as farRestroomSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faRestroomSimple as fasRestroomSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faRetweet as fadRetweet }   from '@fortawesome/pro-duotone-svg-icons';
import { faRetweet as falRetweet }   from '@fortawesome/pro-light-svg-icons';
import { faRetweet as farRetweet }   from '@fortawesome/pro-regular-svg-icons';
import { faRetweet as fasRetweet }   from '@fortawesome/pro-solid-svg-icons';
import { faRetweetAlt as fadRetweetAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faRetweetAlt as falRetweetAlt }   from '@fortawesome/pro-light-svg-icons';
import { faRetweetAlt as farRetweetAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faRetweetAlt as fasRetweetAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faRhombus as fadRhombus }   from '@fortawesome/pro-duotone-svg-icons';
import { faRhombus as falRhombus }   from '@fortawesome/pro-light-svg-icons';
import { faRhombus as farRhombus }   from '@fortawesome/pro-regular-svg-icons';
import { faRhombus as fasRhombus }   from '@fortawesome/pro-solid-svg-icons';
import { faRibbon as fadRibbon }   from '@fortawesome/pro-duotone-svg-icons';
import { faRibbon as falRibbon }   from '@fortawesome/pro-light-svg-icons';
import { faRibbon as farRibbon }   from '@fortawesome/pro-regular-svg-icons';
import { faRibbon as fasRibbon }   from '@fortawesome/pro-solid-svg-icons';
import { faRight as fadRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faRight as falRight }   from '@fortawesome/pro-light-svg-icons';
import { faRight as farRight }   from '@fortawesome/pro-regular-svg-icons';
import { faRight as fasRight }   from '@fortawesome/pro-solid-svg-icons';
import { faRightFromBracket as fadRightFromBracket }   from '@fortawesome/pro-duotone-svg-icons';
import { faRightFromBracket as falRightFromBracket }   from '@fortawesome/pro-light-svg-icons';
import { faRightFromBracket as farRightFromBracket }   from '@fortawesome/pro-regular-svg-icons';
import { faRightFromBracket as fasRightFromBracket }   from '@fortawesome/pro-solid-svg-icons';
import { faRightFromLine as fadRightFromLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faRightFromLine as falRightFromLine }   from '@fortawesome/pro-light-svg-icons';
import { faRightFromLine as farRightFromLine }   from '@fortawesome/pro-regular-svg-icons';
import { faRightFromLine as fasRightFromLine }   from '@fortawesome/pro-solid-svg-icons';
import { faRightLeft as fadRightLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faRightLeft as falRightLeft }   from '@fortawesome/pro-light-svg-icons';
import { faRightLeft as farRightLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faRightLeft as fasRightLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faRightLong as fadRightLong }   from '@fortawesome/pro-duotone-svg-icons';
import { faRightLong as falRightLong }   from '@fortawesome/pro-light-svg-icons';
import { faRightLong as farRightLong }   from '@fortawesome/pro-regular-svg-icons';
import { faRightLong as fasRightLong }   from '@fortawesome/pro-solid-svg-icons';
import { faRightLongToLine as fadRightLongToLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faRightLongToLine as falRightLongToLine }   from '@fortawesome/pro-light-svg-icons';
import { faRightLongToLine as farRightLongToLine }   from '@fortawesome/pro-regular-svg-icons';
import { faRightLongToLine as fasRightLongToLine }   from '@fortawesome/pro-solid-svg-icons';
import { faRightToBracket as fadRightToBracket }   from '@fortawesome/pro-duotone-svg-icons';
import { faRightToBracket as falRightToBracket }   from '@fortawesome/pro-light-svg-icons';
import { faRightToBracket as farRightToBracket }   from '@fortawesome/pro-regular-svg-icons';
import { faRightToBracket as fasRightToBracket }   from '@fortawesome/pro-solid-svg-icons';
import { faRightToLine as fadRightToLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faRightToLine as falRightToLine }   from '@fortawesome/pro-light-svg-icons';
import { faRightToLine as farRightToLine }   from '@fortawesome/pro-regular-svg-icons';
import { faRightToLine as fasRightToLine }   from '@fortawesome/pro-solid-svg-icons';
import { faRing as fadRing }   from '@fortawesome/pro-duotone-svg-icons';
import { faRing as falRing }   from '@fortawesome/pro-light-svg-icons';
import { faRing as farRing }   from '@fortawesome/pro-regular-svg-icons';
import { faRing as fasRing }   from '@fortawesome/pro-solid-svg-icons';
import { faRingsWedding as fadRingsWedding }   from '@fortawesome/pro-duotone-svg-icons';
import { faRingsWedding as falRingsWedding }   from '@fortawesome/pro-light-svg-icons';
import { faRingsWedding as farRingsWedding }   from '@fortawesome/pro-regular-svg-icons';
import { faRingsWedding as fasRingsWedding }   from '@fortawesome/pro-solid-svg-icons';
import { faRmb as fadRmb }   from '@fortawesome/pro-duotone-svg-icons';
import { faRmb as falRmb }   from '@fortawesome/pro-light-svg-icons';
import { faRmb as farRmb }   from '@fortawesome/pro-regular-svg-icons';
import { faRmb as fasRmb }   from '@fortawesome/pro-solid-svg-icons';
import { faRoad as fadRoad }   from '@fortawesome/pro-duotone-svg-icons';
import { faRoad as falRoad }   from '@fortawesome/pro-light-svg-icons';
import { faRoad as farRoad }   from '@fortawesome/pro-regular-svg-icons';
import { faRoad as fasRoad }   from '@fortawesome/pro-solid-svg-icons';
import { faRoadBarrier as fadRoadBarrier }   from '@fortawesome/pro-duotone-svg-icons';
import { faRoadBarrier as falRoadBarrier }   from '@fortawesome/pro-light-svg-icons';
import { faRoadBarrier as farRoadBarrier }   from '@fortawesome/pro-regular-svg-icons';
import { faRoadBarrier as fasRoadBarrier }   from '@fortawesome/pro-solid-svg-icons';
import { faRoadBridge as fadRoadBridge }   from '@fortawesome/pro-duotone-svg-icons';
import { faRoadBridge as falRoadBridge }   from '@fortawesome/pro-light-svg-icons';
import { faRoadBridge as farRoadBridge }   from '@fortawesome/pro-regular-svg-icons';
import { faRoadBridge as fasRoadBridge }   from '@fortawesome/pro-solid-svg-icons';
import { faRoadCircleCheck as fadRoadCircleCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faRoadCircleCheck as falRoadCircleCheck }   from '@fortawesome/pro-light-svg-icons';
import { faRoadCircleCheck as farRoadCircleCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faRoadCircleCheck as fasRoadCircleCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faRoadCircleExclamation as fadRoadCircleExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faRoadCircleExclamation as falRoadCircleExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faRoadCircleExclamation as farRoadCircleExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faRoadCircleExclamation as fasRoadCircleExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faRoadCircleXmark as fadRoadCircleXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faRoadCircleXmark as falRoadCircleXmark }   from '@fortawesome/pro-light-svg-icons';
import { faRoadCircleXmark as farRoadCircleXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faRoadCircleXmark as fasRoadCircleXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faRoadLock as fadRoadLock }   from '@fortawesome/pro-duotone-svg-icons';
import { faRoadLock as falRoadLock }   from '@fortawesome/pro-light-svg-icons';
import { faRoadLock as farRoadLock }   from '@fortawesome/pro-regular-svg-icons';
import { faRoadLock as fasRoadLock }   from '@fortawesome/pro-solid-svg-icons';
import { faRoadSpikes as fadRoadSpikes }   from '@fortawesome/pro-duotone-svg-icons';
import { faRoadSpikes as falRoadSpikes }   from '@fortawesome/pro-light-svg-icons';
import { faRoadSpikes as farRoadSpikes }   from '@fortawesome/pro-regular-svg-icons';
import { faRoadSpikes as fasRoadSpikes }   from '@fortawesome/pro-solid-svg-icons';
import { faRobot as fadRobot }   from '@fortawesome/pro-duotone-svg-icons';
import { faRobot as falRobot }   from '@fortawesome/pro-light-svg-icons';
import { faRobot as farRobot }   from '@fortawesome/pro-regular-svg-icons';
import { faRobot as fasRobot }   from '@fortawesome/pro-solid-svg-icons';
import { faRobotAstromech as fadRobotAstromech }   from '@fortawesome/pro-duotone-svg-icons';
import { faRobotAstromech as falRobotAstromech }   from '@fortawesome/pro-light-svg-icons';
import { faRobotAstromech as farRobotAstromech }   from '@fortawesome/pro-regular-svg-icons';
import { faRobotAstromech as fasRobotAstromech }   from '@fortawesome/pro-solid-svg-icons';
import { faRocket as fadRocket }   from '@fortawesome/pro-duotone-svg-icons';
import { faRocket as falRocket }   from '@fortawesome/pro-light-svg-icons';
import { faRocket as farRocket }   from '@fortawesome/pro-regular-svg-icons';
import { faRocket as fasRocket }   from '@fortawesome/pro-solid-svg-icons';
import { faRocketLaunch as fadRocketLaunch }   from '@fortawesome/pro-duotone-svg-icons';
import { faRocketLaunch as falRocketLaunch }   from '@fortawesome/pro-light-svg-icons';
import { faRocketLaunch as farRocketLaunch }   from '@fortawesome/pro-regular-svg-icons';
import { faRocketLaunch as fasRocketLaunch }   from '@fortawesome/pro-solid-svg-icons';
import { faRodAsclepius as fadRodAsclepius }   from '@fortawesome/pro-duotone-svg-icons';
import { faRodAsclepius as falRodAsclepius }   from '@fortawesome/pro-light-svg-icons';
import { faRodAsclepius as farRodAsclepius }   from '@fortawesome/pro-regular-svg-icons';
import { faRodAsclepius as fasRodAsclepius }   from '@fortawesome/pro-solid-svg-icons';
import { faRodSnake as fadRodSnake }   from '@fortawesome/pro-duotone-svg-icons';
import { faRodSnake as falRodSnake }   from '@fortawesome/pro-light-svg-icons';
import { faRodSnake as farRodSnake }   from '@fortawesome/pro-regular-svg-icons';
import { faRodSnake as fasRodSnake }   from '@fortawesome/pro-solid-svg-icons';
import { faRollerCoaster as fadRollerCoaster }   from '@fortawesome/pro-duotone-svg-icons';
import { faRollerCoaster as falRollerCoaster }   from '@fortawesome/pro-light-svg-icons';
import { faRollerCoaster as farRollerCoaster }   from '@fortawesome/pro-regular-svg-icons';
import { faRollerCoaster as fasRollerCoaster }   from '@fortawesome/pro-solid-svg-icons';
import { faRotate as fadRotate }   from '@fortawesome/pro-duotone-svg-icons';
import { faRotate as falRotate }   from '@fortawesome/pro-light-svg-icons';
import { faRotate as farRotate }   from '@fortawesome/pro-regular-svg-icons';
import { faRotate as fasRotate }   from '@fortawesome/pro-solid-svg-icons';
import { faRotateBack as fadRotateBack }   from '@fortawesome/pro-duotone-svg-icons';
import { faRotateBack as falRotateBack }   from '@fortawesome/pro-light-svg-icons';
import { faRotateBack as farRotateBack }   from '@fortawesome/pro-regular-svg-icons';
import { faRotateBack as fasRotateBack }   from '@fortawesome/pro-solid-svg-icons';
import { faRotateBackward as fadRotateBackward }   from '@fortawesome/pro-duotone-svg-icons';
import { faRotateBackward as falRotateBackward }   from '@fortawesome/pro-light-svg-icons';
import { faRotateBackward as farRotateBackward }   from '@fortawesome/pro-regular-svg-icons';
import { faRotateBackward as fasRotateBackward }   from '@fortawesome/pro-solid-svg-icons';
import { faRotateExclamation as fadRotateExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faRotateExclamation as falRotateExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faRotateExclamation as farRotateExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faRotateExclamation as fasRotateExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faRotateForward as fadRotateForward }   from '@fortawesome/pro-duotone-svg-icons';
import { faRotateForward as falRotateForward }   from '@fortawesome/pro-light-svg-icons';
import { faRotateForward as farRotateForward }   from '@fortawesome/pro-regular-svg-icons';
import { faRotateForward as fasRotateForward }   from '@fortawesome/pro-solid-svg-icons';
import { faRotateLeft as fadRotateLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faRotateLeft as falRotateLeft }   from '@fortawesome/pro-light-svg-icons';
import { faRotateLeft as farRotateLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faRotateLeft as fasRotateLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faRotateRight as fadRotateRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faRotateRight as falRotateRight }   from '@fortawesome/pro-light-svg-icons';
import { faRotateRight as farRotateRight }   from '@fortawesome/pro-regular-svg-icons';
import { faRotateRight as fasRotateRight }   from '@fortawesome/pro-solid-svg-icons';
import { faRouble as fadRouble }   from '@fortawesome/pro-duotone-svg-icons';
import { faRouble as falRouble }   from '@fortawesome/pro-light-svg-icons';
import { faRouble as farRouble }   from '@fortawesome/pro-regular-svg-icons';
import { faRouble as fasRouble }   from '@fortawesome/pro-solid-svg-icons';
import { faRoute as fadRoute }   from '@fortawesome/pro-duotone-svg-icons';
import { faRoute as falRoute }   from '@fortawesome/pro-light-svg-icons';
import { faRoute as farRoute }   from '@fortawesome/pro-regular-svg-icons';
import { faRoute as fasRoute }   from '@fortawesome/pro-solid-svg-icons';
import { faRouteHighway as fadRouteHighway }   from '@fortawesome/pro-duotone-svg-icons';
import { faRouteHighway as falRouteHighway }   from '@fortawesome/pro-light-svg-icons';
import { faRouteHighway as farRouteHighway }   from '@fortawesome/pro-regular-svg-icons';
import { faRouteHighway as fasRouteHighway }   from '@fortawesome/pro-solid-svg-icons';
import { faRouteInterstate as fadRouteInterstate }   from '@fortawesome/pro-duotone-svg-icons';
import { faRouteInterstate as falRouteInterstate }   from '@fortawesome/pro-light-svg-icons';
import { faRouteInterstate as farRouteInterstate }   from '@fortawesome/pro-regular-svg-icons';
import { faRouteInterstate as fasRouteInterstate }   from '@fortawesome/pro-solid-svg-icons';
import { faRouter as fadRouter }   from '@fortawesome/pro-duotone-svg-icons';
import { faRouter as falRouter }   from '@fortawesome/pro-light-svg-icons';
import { faRouter as farRouter }   from '@fortawesome/pro-regular-svg-icons';
import { faRouter as fasRouter }   from '@fortawesome/pro-solid-svg-icons';
import { faRows as fadRows }   from '@fortawesome/pro-duotone-svg-icons';
import { faRows as falRows }   from '@fortawesome/pro-light-svg-icons';
import { faRows as farRows }   from '@fortawesome/pro-regular-svg-icons';
import { faRows as fasRows }   from '@fortawesome/pro-solid-svg-icons';
import { faRss as fadRss }   from '@fortawesome/pro-duotone-svg-icons';
import { faRss as falRss }   from '@fortawesome/pro-light-svg-icons';
import { faRss as farRss }   from '@fortawesome/pro-regular-svg-icons';
import { faRss as fasRss }   from '@fortawesome/pro-solid-svg-icons';
import { faRssSquare as fadRssSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faRssSquare as falRssSquare }   from '@fortawesome/pro-light-svg-icons';
import { faRssSquare as farRssSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faRssSquare as fasRssSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faRub as fadRub }   from '@fortawesome/pro-duotone-svg-icons';
import { faRub as falRub }   from '@fortawesome/pro-light-svg-icons';
import { faRub as farRub }   from '@fortawesome/pro-regular-svg-icons';
import { faRub as fasRub }   from '@fortawesome/pro-solid-svg-icons';
import { faRuble as fadRuble }   from '@fortawesome/pro-duotone-svg-icons';
import { faRuble as falRuble }   from '@fortawesome/pro-light-svg-icons';
import { faRuble as farRuble }   from '@fortawesome/pro-regular-svg-icons';
import { faRuble as fasRuble }   from '@fortawesome/pro-solid-svg-icons';
import { faRubleSign as fadRubleSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faRubleSign as falRubleSign }   from '@fortawesome/pro-light-svg-icons';
import { faRubleSign as farRubleSign }   from '@fortawesome/pro-regular-svg-icons';
import { faRubleSign as fasRubleSign }   from '@fortawesome/pro-solid-svg-icons';
import { faRug as fadRug }   from '@fortawesome/pro-duotone-svg-icons';
import { faRug as falRug }   from '@fortawesome/pro-light-svg-icons';
import { faRug as farRug }   from '@fortawesome/pro-regular-svg-icons';
import { faRug as fasRug }   from '@fortawesome/pro-solid-svg-icons';
import { faRugbyBall as fadRugbyBall }   from '@fortawesome/pro-duotone-svg-icons';
import { faRugbyBall as falRugbyBall }   from '@fortawesome/pro-light-svg-icons';
import { faRugbyBall as farRugbyBall }   from '@fortawesome/pro-regular-svg-icons';
import { faRugbyBall as fasRugbyBall }   from '@fortawesome/pro-solid-svg-icons';
import { faRuler as fadRuler }   from '@fortawesome/pro-duotone-svg-icons';
import { faRuler as falRuler }   from '@fortawesome/pro-light-svg-icons';
import { faRuler as farRuler }   from '@fortawesome/pro-regular-svg-icons';
import { faRuler as fasRuler }   from '@fortawesome/pro-solid-svg-icons';
import { faRulerCombined as fadRulerCombined }   from '@fortawesome/pro-duotone-svg-icons';
import { faRulerCombined as falRulerCombined }   from '@fortawesome/pro-light-svg-icons';
import { faRulerCombined as farRulerCombined }   from '@fortawesome/pro-regular-svg-icons';
import { faRulerCombined as fasRulerCombined }   from '@fortawesome/pro-solid-svg-icons';
import { faRulerHorizontal as fadRulerHorizontal }   from '@fortawesome/pro-duotone-svg-icons';
import { faRulerHorizontal as falRulerHorizontal }   from '@fortawesome/pro-light-svg-icons';
import { faRulerHorizontal as farRulerHorizontal }   from '@fortawesome/pro-regular-svg-icons';
import { faRulerHorizontal as fasRulerHorizontal }   from '@fortawesome/pro-solid-svg-icons';
import { faRulerTriangle as fadRulerTriangle }   from '@fortawesome/pro-duotone-svg-icons';
import { faRulerTriangle as falRulerTriangle }   from '@fortawesome/pro-light-svg-icons';
import { faRulerTriangle as farRulerTriangle }   from '@fortawesome/pro-regular-svg-icons';
import { faRulerTriangle as fasRulerTriangle }   from '@fortawesome/pro-solid-svg-icons';
import { faRulerVertical as fadRulerVertical }   from '@fortawesome/pro-duotone-svg-icons';
import { faRulerVertical as falRulerVertical }   from '@fortawesome/pro-light-svg-icons';
import { faRulerVertical as farRulerVertical }   from '@fortawesome/pro-regular-svg-icons';
import { faRulerVertical as fasRulerVertical }   from '@fortawesome/pro-solid-svg-icons';
import { faRunning as fadRunning }   from '@fortawesome/pro-duotone-svg-icons';
import { faRunning as falRunning }   from '@fortawesome/pro-light-svg-icons';
import { faRunning as farRunning }   from '@fortawesome/pro-regular-svg-icons';
import { faRunning as fasRunning }   from '@fortawesome/pro-solid-svg-icons';
import { faRupee as fadRupee }   from '@fortawesome/pro-duotone-svg-icons';
import { faRupee as falRupee }   from '@fortawesome/pro-light-svg-icons';
import { faRupee as farRupee }   from '@fortawesome/pro-regular-svg-icons';
import { faRupee as fasRupee }   from '@fortawesome/pro-solid-svg-icons';
import { faRupeeSign as fadRupeeSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faRupeeSign as falRupeeSign }   from '@fortawesome/pro-light-svg-icons';
import { faRupeeSign as farRupeeSign }   from '@fortawesome/pro-regular-svg-icons';
import { faRupeeSign as fasRupeeSign }   from '@fortawesome/pro-solid-svg-icons';
import { faRupiahSign as fadRupiahSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faRupiahSign as falRupiahSign }   from '@fortawesome/pro-light-svg-icons';
import { faRupiahSign as farRupiahSign }   from '@fortawesome/pro-regular-svg-icons';
import { faRupiahSign as fasRupiahSign }   from '@fortawesome/pro-solid-svg-icons';
import { faRv as fadRv }   from '@fortawesome/pro-duotone-svg-icons';
import { faRv as falRv }   from '@fortawesome/pro-light-svg-icons';
import { faRv as farRv }   from '@fortawesome/pro-regular-svg-icons';
import { faRv as fasRv }   from '@fortawesome/pro-solid-svg-icons';
import { faS as fadS }   from '@fortawesome/pro-duotone-svg-icons';
import { faS as falS }   from '@fortawesome/pro-light-svg-icons';
import { faS as farS }   from '@fortawesome/pro-regular-svg-icons';
import { faS as fasS }   from '@fortawesome/pro-solid-svg-icons';
import { faSack as fadSack }   from '@fortawesome/pro-duotone-svg-icons';
import { faSack as falSack }   from '@fortawesome/pro-light-svg-icons';
import { faSack as farSack }   from '@fortawesome/pro-regular-svg-icons';
import { faSack as fasSack }   from '@fortawesome/pro-solid-svg-icons';
import { faSackDollar as fadSackDollar }   from '@fortawesome/pro-duotone-svg-icons';
import { faSackDollar as falSackDollar }   from '@fortawesome/pro-light-svg-icons';
import { faSackDollar as farSackDollar }   from '@fortawesome/pro-regular-svg-icons';
import { faSackDollar as fasSackDollar }   from '@fortawesome/pro-solid-svg-icons';
import { faSackXmark as fadSackXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faSackXmark as falSackXmark }   from '@fortawesome/pro-light-svg-icons';
import { faSackXmark as farSackXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faSackXmark as fasSackXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faSadCry as fadSadCry }   from '@fortawesome/pro-duotone-svg-icons';
import { faSadCry as falSadCry }   from '@fortawesome/pro-light-svg-icons';
import { faSadCry as farSadCry }   from '@fortawesome/pro-regular-svg-icons';
import { faSadCry as fasSadCry }   from '@fortawesome/pro-solid-svg-icons';
import { faSadTear as fadSadTear }   from '@fortawesome/pro-duotone-svg-icons';
import { faSadTear as falSadTear }   from '@fortawesome/pro-light-svg-icons';
import { faSadTear as farSadTear }   from '@fortawesome/pro-regular-svg-icons';
import { faSadTear as fasSadTear }   from '@fortawesome/pro-solid-svg-icons';
import { faSailboat as fadSailboat }   from '@fortawesome/pro-duotone-svg-icons';
import { faSailboat as falSailboat }   from '@fortawesome/pro-light-svg-icons';
import { faSailboat as farSailboat }   from '@fortawesome/pro-regular-svg-icons';
import { faSailboat as fasSailboat }   from '@fortawesome/pro-solid-svg-icons';
import { faSalad as fadSalad }   from '@fortawesome/pro-duotone-svg-icons';
import { faSalad as falSalad }   from '@fortawesome/pro-light-svg-icons';
import { faSalad as farSalad }   from '@fortawesome/pro-regular-svg-icons';
import { faSalad as fasSalad }   from '@fortawesome/pro-solid-svg-icons';
import { faSaltShaker as fadSaltShaker }   from '@fortawesome/pro-duotone-svg-icons';
import { faSaltShaker as falSaltShaker }   from '@fortawesome/pro-light-svg-icons';
import { faSaltShaker as farSaltShaker }   from '@fortawesome/pro-regular-svg-icons';
import { faSaltShaker as fasSaltShaker }   from '@fortawesome/pro-solid-svg-icons';
import { faSandwich as fadSandwich }   from '@fortawesome/pro-duotone-svg-icons';
import { faSandwich as falSandwich }   from '@fortawesome/pro-light-svg-icons';
import { faSandwich as farSandwich }   from '@fortawesome/pro-regular-svg-icons';
import { faSandwich as fasSandwich }   from '@fortawesome/pro-solid-svg-icons';
import { faSatellite as fadSatellite }   from '@fortawesome/pro-duotone-svg-icons';
import { faSatellite as falSatellite }   from '@fortawesome/pro-light-svg-icons';
import { faSatellite as farSatellite }   from '@fortawesome/pro-regular-svg-icons';
import { faSatellite as fasSatellite }   from '@fortawesome/pro-solid-svg-icons';
import { faSatelliteDish as fadSatelliteDish }   from '@fortawesome/pro-duotone-svg-icons';
import { faSatelliteDish as falSatelliteDish }   from '@fortawesome/pro-light-svg-icons';
import { faSatelliteDish as farSatelliteDish }   from '@fortawesome/pro-regular-svg-icons';
import { faSatelliteDish as fasSatelliteDish }   from '@fortawesome/pro-solid-svg-icons';
import { faSausage as fadSausage }   from '@fortawesome/pro-duotone-svg-icons';
import { faSausage as falSausage }   from '@fortawesome/pro-light-svg-icons';
import { faSausage as farSausage }   from '@fortawesome/pro-regular-svg-icons';
import { faSausage as fasSausage }   from '@fortawesome/pro-solid-svg-icons';
import { faSave as fadSave }   from '@fortawesome/pro-duotone-svg-icons';
import { faSave as falSave }   from '@fortawesome/pro-light-svg-icons';
import { faSave as farSave }   from '@fortawesome/pro-regular-svg-icons';
import { faSave as fasSave }   from '@fortawesome/pro-solid-svg-icons';
import { faSaveCircleArrowRight as fadSaveCircleArrowRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faSaveCircleArrowRight as falSaveCircleArrowRight }   from '@fortawesome/pro-light-svg-icons';
import { faSaveCircleArrowRight as farSaveCircleArrowRight }   from '@fortawesome/pro-regular-svg-icons';
import { faSaveCircleArrowRight as fasSaveCircleArrowRight }   from '@fortawesome/pro-solid-svg-icons';
import { faSaveCircleXmark as fadSaveCircleXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faSaveCircleXmark as falSaveCircleXmark }   from '@fortawesome/pro-light-svg-icons';
import { faSaveCircleXmark as farSaveCircleXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faSaveCircleXmark as fasSaveCircleXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faSaveTimes as fadSaveTimes }   from '@fortawesome/pro-duotone-svg-icons';
import { faSaveTimes as falSaveTimes }   from '@fortawesome/pro-light-svg-icons';
import { faSaveTimes as farSaveTimes }   from '@fortawesome/pro-regular-svg-icons';
import { faSaveTimes as fasSaveTimes }   from '@fortawesome/pro-solid-svg-icons';
import { faSaxHot as fadSaxHot }   from '@fortawesome/pro-duotone-svg-icons';
import { faSaxHot as falSaxHot }   from '@fortawesome/pro-light-svg-icons';
import { faSaxHot as farSaxHot }   from '@fortawesome/pro-regular-svg-icons';
import { faSaxHot as fasSaxHot }   from '@fortawesome/pro-solid-svg-icons';
import { faSaxophone as fadSaxophone }   from '@fortawesome/pro-duotone-svg-icons';
import { faSaxophone as falSaxophone }   from '@fortawesome/pro-light-svg-icons';
import { faSaxophone as farSaxophone }   from '@fortawesome/pro-regular-svg-icons';
import { faSaxophone as fasSaxophone }   from '@fortawesome/pro-solid-svg-icons';
import { faSaxophoneFire as fadSaxophoneFire }   from '@fortawesome/pro-duotone-svg-icons';
import { faSaxophoneFire as falSaxophoneFire }   from '@fortawesome/pro-light-svg-icons';
import { faSaxophoneFire as farSaxophoneFire }   from '@fortawesome/pro-regular-svg-icons';
import { faSaxophoneFire as fasSaxophoneFire }   from '@fortawesome/pro-solid-svg-icons';
import { faScaleBalanced as fadScaleBalanced }   from '@fortawesome/pro-duotone-svg-icons';
import { faScaleBalanced as falScaleBalanced }   from '@fortawesome/pro-light-svg-icons';
import { faScaleBalanced as farScaleBalanced }   from '@fortawesome/pro-regular-svg-icons';
import { faScaleBalanced as fasScaleBalanced }   from '@fortawesome/pro-solid-svg-icons';
import { faScaleUnbalanced as fadScaleUnbalanced }   from '@fortawesome/pro-duotone-svg-icons';
import { faScaleUnbalanced as falScaleUnbalanced }   from '@fortawesome/pro-light-svg-icons';
import { faScaleUnbalanced as farScaleUnbalanced }   from '@fortawesome/pro-regular-svg-icons';
import { faScaleUnbalanced as fasScaleUnbalanced }   from '@fortawesome/pro-solid-svg-icons';
import { faScaleUnbalancedFlip as fadScaleUnbalancedFlip }   from '@fortawesome/pro-duotone-svg-icons';
import { faScaleUnbalancedFlip as falScaleUnbalancedFlip }   from '@fortawesome/pro-light-svg-icons';
import { faScaleUnbalancedFlip as farScaleUnbalancedFlip }   from '@fortawesome/pro-regular-svg-icons';
import { faScaleUnbalancedFlip as fasScaleUnbalancedFlip }   from '@fortawesome/pro-solid-svg-icons';
import { faScalpel as fadScalpel }   from '@fortawesome/pro-duotone-svg-icons';
import { faScalpel as falScalpel }   from '@fortawesome/pro-light-svg-icons';
import { faScalpel as farScalpel }   from '@fortawesome/pro-regular-svg-icons';
import { faScalpel as fasScalpel }   from '@fortawesome/pro-solid-svg-icons';
import { faScalpelLineDashed as fadScalpelLineDashed }   from '@fortawesome/pro-duotone-svg-icons';
import { faScalpelLineDashed as falScalpelLineDashed }   from '@fortawesome/pro-light-svg-icons';
import { faScalpelLineDashed as farScalpelLineDashed }   from '@fortawesome/pro-regular-svg-icons';
import { faScalpelLineDashed as fasScalpelLineDashed }   from '@fortawesome/pro-solid-svg-icons';
import { faScalpelPath as fadScalpelPath }   from '@fortawesome/pro-duotone-svg-icons';
import { faScalpelPath as falScalpelPath }   from '@fortawesome/pro-light-svg-icons';
import { faScalpelPath as farScalpelPath }   from '@fortawesome/pro-regular-svg-icons';
import { faScalpelPath as fasScalpelPath }   from '@fortawesome/pro-solid-svg-icons';
import { faScanner as fadScanner }   from '@fortawesome/pro-duotone-svg-icons';
import { faScanner as falScanner }   from '@fortawesome/pro-light-svg-icons';
import { faScanner as farScanner }   from '@fortawesome/pro-regular-svg-icons';
import { faScanner as fasScanner }   from '@fortawesome/pro-solid-svg-icons';
import { faScannerGun as fadScannerGun }   from '@fortawesome/pro-duotone-svg-icons';
import { faScannerGun as falScannerGun }   from '@fortawesome/pro-light-svg-icons';
import { faScannerGun as farScannerGun }   from '@fortawesome/pro-regular-svg-icons';
import { faScannerGun as fasScannerGun }   from '@fortawesome/pro-solid-svg-icons';
import { faScannerImage as fadScannerImage }   from '@fortawesome/pro-duotone-svg-icons';
import { faScannerImage as falScannerImage }   from '@fortawesome/pro-light-svg-icons';
import { faScannerImage as farScannerImage }   from '@fortawesome/pro-regular-svg-icons';
import { faScannerImage as fasScannerImage }   from '@fortawesome/pro-solid-svg-icons';
import { faScannerKeyboard as fadScannerKeyboard }   from '@fortawesome/pro-duotone-svg-icons';
import { faScannerKeyboard as falScannerKeyboard }   from '@fortawesome/pro-light-svg-icons';
import { faScannerKeyboard as farScannerKeyboard }   from '@fortawesome/pro-regular-svg-icons';
import { faScannerKeyboard as fasScannerKeyboard }   from '@fortawesome/pro-solid-svg-icons';
import { faScannerTouchscreen as fadScannerTouchscreen }   from '@fortawesome/pro-duotone-svg-icons';
import { faScannerTouchscreen as falScannerTouchscreen }   from '@fortawesome/pro-light-svg-icons';
import { faScannerTouchscreen as farScannerTouchscreen }   from '@fortawesome/pro-regular-svg-icons';
import { faScannerTouchscreen as fasScannerTouchscreen }   from '@fortawesome/pro-solid-svg-icons';
import { faScarecrow as fadScarecrow }   from '@fortawesome/pro-duotone-svg-icons';
import { faScarecrow as falScarecrow }   from '@fortawesome/pro-light-svg-icons';
import { faScarecrow as farScarecrow }   from '@fortawesome/pro-regular-svg-icons';
import { faScarecrow as fasScarecrow }   from '@fortawesome/pro-solid-svg-icons';
import { faScarf as fadScarf }   from '@fortawesome/pro-duotone-svg-icons';
import { faScarf as falScarf }   from '@fortawesome/pro-light-svg-icons';
import { faScarf as farScarf }   from '@fortawesome/pro-regular-svg-icons';
import { faScarf as fasScarf }   from '@fortawesome/pro-solid-svg-icons';
import { faSchool as fadSchool }   from '@fortawesome/pro-duotone-svg-icons';
import { faSchool as falSchool }   from '@fortawesome/pro-light-svg-icons';
import { faSchool as farSchool }   from '@fortawesome/pro-regular-svg-icons';
import { faSchool as fasSchool }   from '@fortawesome/pro-solid-svg-icons';
import { faSchoolCircleCheck as fadSchoolCircleCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faSchoolCircleCheck as falSchoolCircleCheck }   from '@fortawesome/pro-light-svg-icons';
import { faSchoolCircleCheck as farSchoolCircleCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faSchoolCircleCheck as fasSchoolCircleCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faSchoolCircleExclamation as fadSchoolCircleExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faSchoolCircleExclamation as falSchoolCircleExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faSchoolCircleExclamation as farSchoolCircleExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faSchoolCircleExclamation as fasSchoolCircleExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faSchoolCircleXmark as fadSchoolCircleXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faSchoolCircleXmark as falSchoolCircleXmark }   from '@fortawesome/pro-light-svg-icons';
import { faSchoolCircleXmark as farSchoolCircleXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faSchoolCircleXmark as fasSchoolCircleXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faSchoolFlag as fadSchoolFlag }   from '@fortawesome/pro-duotone-svg-icons';
import { faSchoolFlag as falSchoolFlag }   from '@fortawesome/pro-light-svg-icons';
import { faSchoolFlag as farSchoolFlag }   from '@fortawesome/pro-regular-svg-icons';
import { faSchoolFlag as fasSchoolFlag }   from '@fortawesome/pro-solid-svg-icons';
import { faSchoolLock as fadSchoolLock }   from '@fortawesome/pro-duotone-svg-icons';
import { faSchoolLock as falSchoolLock }   from '@fortawesome/pro-light-svg-icons';
import { faSchoolLock as farSchoolLock }   from '@fortawesome/pro-regular-svg-icons';
import { faSchoolLock as fasSchoolLock }   from '@fortawesome/pro-solid-svg-icons';
import { faScissors as fadScissors }   from '@fortawesome/pro-duotone-svg-icons';
import { faScissors as falScissors }   from '@fortawesome/pro-light-svg-icons';
import { faScissors as farScissors }   from '@fortawesome/pro-regular-svg-icons';
import { faScissors as fasScissors }   from '@fortawesome/pro-solid-svg-icons';
import { faScreencast as fadScreencast }   from '@fortawesome/pro-duotone-svg-icons';
import { faScreencast as falScreencast }   from '@fortawesome/pro-light-svg-icons';
import { faScreencast as farScreencast }   from '@fortawesome/pro-regular-svg-icons';
import { faScreencast as fasScreencast }   from '@fortawesome/pro-solid-svg-icons';
import { faScreenshot as fadScreenshot }   from '@fortawesome/pro-duotone-svg-icons';
import { faScreenshot as falScreenshot }   from '@fortawesome/pro-light-svg-icons';
import { faScreenshot as farScreenshot }   from '@fortawesome/pro-regular-svg-icons';
import { faScreenshot as fasScreenshot }   from '@fortawesome/pro-solid-svg-icons';
import { faScreenUsers as fadScreenUsers }   from '@fortawesome/pro-duotone-svg-icons';
import { faScreenUsers as falScreenUsers }   from '@fortawesome/pro-light-svg-icons';
import { faScreenUsers as farScreenUsers }   from '@fortawesome/pro-regular-svg-icons';
import { faScreenUsers as fasScreenUsers }   from '@fortawesome/pro-solid-svg-icons';
import { faScrewdriver as fadScrewdriver }   from '@fortawesome/pro-duotone-svg-icons';
import { faScrewdriver as falScrewdriver }   from '@fortawesome/pro-light-svg-icons';
import { faScrewdriver as farScrewdriver }   from '@fortawesome/pro-regular-svg-icons';
import { faScrewdriver as fasScrewdriver }   from '@fortawesome/pro-solid-svg-icons';
import { faScrewdriverWrench as fadScrewdriverWrench }   from '@fortawesome/pro-duotone-svg-icons';
import { faScrewdriverWrench as falScrewdriverWrench }   from '@fortawesome/pro-light-svg-icons';
import { faScrewdriverWrench as farScrewdriverWrench }   from '@fortawesome/pro-regular-svg-icons';
import { faScrewdriverWrench as fasScrewdriverWrench }   from '@fortawesome/pro-solid-svg-icons';
import { faScribble as fadScribble }   from '@fortawesome/pro-duotone-svg-icons';
import { faScribble as falScribble }   from '@fortawesome/pro-light-svg-icons';
import { faScribble as farScribble }   from '@fortawesome/pro-regular-svg-icons';
import { faScribble as fasScribble }   from '@fortawesome/pro-solid-svg-icons';
import { faScroll as fadScroll }   from '@fortawesome/pro-duotone-svg-icons';
import { faScroll as falScroll }   from '@fortawesome/pro-light-svg-icons';
import { faScroll as farScroll }   from '@fortawesome/pro-regular-svg-icons';
import { faScroll as fasScroll }   from '@fortawesome/pro-solid-svg-icons';
import { faScrollOld as fadScrollOld }   from '@fortawesome/pro-duotone-svg-icons';
import { faScrollOld as falScrollOld }   from '@fortawesome/pro-light-svg-icons';
import { faScrollOld as farScrollOld }   from '@fortawesome/pro-regular-svg-icons';
import { faScrollOld as fasScrollOld }   from '@fortawesome/pro-solid-svg-icons';
import { faScrollRibbon as fadScrollRibbon }   from '@fortawesome/pro-duotone-svg-icons';
import { faScrollRibbon as falScrollRibbon }   from '@fortawesome/pro-light-svg-icons';
import { faScrollRibbon as farScrollRibbon }   from '@fortawesome/pro-regular-svg-icons';
import { faScrollRibbon as fasScrollRibbon }   from '@fortawesome/pro-solid-svg-icons';
import { faScrollTorah as fadScrollTorah }   from '@fortawesome/pro-duotone-svg-icons';
import { faScrollTorah as falScrollTorah }   from '@fortawesome/pro-light-svg-icons';
import { faScrollTorah as farScrollTorah }   from '@fortawesome/pro-regular-svg-icons';
import { faScrollTorah as fasScrollTorah }   from '@fortawesome/pro-solid-svg-icons';
import { faScrubber as fadScrubber }   from '@fortawesome/pro-duotone-svg-icons';
import { faScrubber as falScrubber }   from '@fortawesome/pro-light-svg-icons';
import { faScrubber as farScrubber }   from '@fortawesome/pro-regular-svg-icons';
import { faScrubber as fasScrubber }   from '@fortawesome/pro-solid-svg-icons';
import { faScythe as fadScythe }   from '@fortawesome/pro-duotone-svg-icons';
import { faScythe as falScythe }   from '@fortawesome/pro-light-svg-icons';
import { faScythe as farScythe }   from '@fortawesome/pro-regular-svg-icons';
import { faScythe as fasScythe }   from '@fortawesome/pro-solid-svg-icons';
import { faSdCard as fadSdCard }   from '@fortawesome/pro-duotone-svg-icons';
import { faSdCard as falSdCard }   from '@fortawesome/pro-light-svg-icons';
import { faSdCard as farSdCard }   from '@fortawesome/pro-regular-svg-icons';
import { faSdCard as fasSdCard }   from '@fortawesome/pro-solid-svg-icons';
import { faSdCards as fadSdCards }   from '@fortawesome/pro-duotone-svg-icons';
import { faSdCards as falSdCards }   from '@fortawesome/pro-light-svg-icons';
import { faSdCards as farSdCards }   from '@fortawesome/pro-regular-svg-icons';
import { faSdCards as fasSdCards }   from '@fortawesome/pro-solid-svg-icons';
import { faSeal as fadSeal }   from '@fortawesome/pro-duotone-svg-icons';
import { faSeal as falSeal }   from '@fortawesome/pro-light-svg-icons';
import { faSeal as farSeal }   from '@fortawesome/pro-regular-svg-icons';
import { faSeal as fasSeal }   from '@fortawesome/pro-solid-svg-icons';
import { faSealExclamation as fadSealExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faSealExclamation as falSealExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faSealExclamation as farSealExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faSealExclamation as fasSealExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faSealQuestion as fadSealQuestion }   from '@fortawesome/pro-duotone-svg-icons';
import { faSealQuestion as falSealQuestion }   from '@fortawesome/pro-light-svg-icons';
import { faSealQuestion as farSealQuestion }   from '@fortawesome/pro-regular-svg-icons';
import { faSealQuestion as fasSealQuestion }   from '@fortawesome/pro-solid-svg-icons';
import { faSearch as fadSearch }   from '@fortawesome/pro-duotone-svg-icons';
import { faSearch as falSearch }   from '@fortawesome/pro-light-svg-icons';
import { faSearch as farSearch }   from '@fortawesome/pro-regular-svg-icons';
import { faSearch as fasSearch }   from '@fortawesome/pro-solid-svg-icons';
import { faSearchDollar as fadSearchDollar }   from '@fortawesome/pro-duotone-svg-icons';
import { faSearchDollar as falSearchDollar }   from '@fortawesome/pro-light-svg-icons';
import { faSearchDollar as farSearchDollar }   from '@fortawesome/pro-regular-svg-icons';
import { faSearchDollar as fasSearchDollar }   from '@fortawesome/pro-solid-svg-icons';
import { faSearchLocation as fadSearchLocation }   from '@fortawesome/pro-duotone-svg-icons';
import { faSearchLocation as falSearchLocation }   from '@fortawesome/pro-light-svg-icons';
import { faSearchLocation as farSearchLocation }   from '@fortawesome/pro-regular-svg-icons';
import { faSearchLocation as fasSearchLocation }   from '@fortawesome/pro-solid-svg-icons';
import { faSearchMinus as fadSearchMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faSearchMinus as falSearchMinus }   from '@fortawesome/pro-light-svg-icons';
import { faSearchMinus as farSearchMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faSearchMinus as fasSearchMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faSearchPlus as fadSearchPlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faSearchPlus as falSearchPlus }   from '@fortawesome/pro-light-svg-icons';
import { faSearchPlus as farSearchPlus }   from '@fortawesome/pro-regular-svg-icons';
import { faSearchPlus as fasSearchPlus }   from '@fortawesome/pro-solid-svg-icons';
import { faSeatAirline as fadSeatAirline }   from '@fortawesome/pro-duotone-svg-icons';
import { faSeatAirline as falSeatAirline }   from '@fortawesome/pro-light-svg-icons';
import { faSeatAirline as farSeatAirline }   from '@fortawesome/pro-regular-svg-icons';
import { faSeatAirline as fasSeatAirline }   from '@fortawesome/pro-solid-svg-icons';
import { faSection as fadSection }   from '@fortawesome/pro-duotone-svg-icons';
import { faSection as falSection }   from '@fortawesome/pro-light-svg-icons';
import { faSection as farSection }   from '@fortawesome/pro-regular-svg-icons';
import { faSection as fasSection }   from '@fortawesome/pro-solid-svg-icons';
import { faSeedling as fadSeedling }   from '@fortawesome/pro-duotone-svg-icons';
import { faSeedling as falSeedling }   from '@fortawesome/pro-light-svg-icons';
import { faSeedling as farSeedling }   from '@fortawesome/pro-regular-svg-icons';
import { faSeedling as fasSeedling }   from '@fortawesome/pro-solid-svg-icons';
import { faSemicolon as fadSemicolon }   from '@fortawesome/pro-duotone-svg-icons';
import { faSemicolon as falSemicolon }   from '@fortawesome/pro-light-svg-icons';
import { faSemicolon as farSemicolon }   from '@fortawesome/pro-regular-svg-icons';
import { faSemicolon as fasSemicolon }   from '@fortawesome/pro-solid-svg-icons';
import { faSend as fadSend }   from '@fortawesome/pro-duotone-svg-icons';
import { faSend as falSend }   from '@fortawesome/pro-light-svg-icons';
import { faSend as farSend }   from '@fortawesome/pro-regular-svg-icons';
import { faSend as fasSend }   from '@fortawesome/pro-solid-svg-icons';
import { faSendBack as fadSendBack }   from '@fortawesome/pro-duotone-svg-icons';
import { faSendBack as falSendBack }   from '@fortawesome/pro-light-svg-icons';
import { faSendBack as farSendBack }   from '@fortawesome/pro-regular-svg-icons';
import { faSendBack as fasSendBack }   from '@fortawesome/pro-solid-svg-icons';
import { faSendBackward as fadSendBackward }   from '@fortawesome/pro-duotone-svg-icons';
import { faSendBackward as falSendBackward }   from '@fortawesome/pro-light-svg-icons';
import { faSendBackward as farSendBackward }   from '@fortawesome/pro-regular-svg-icons';
import { faSendBackward as fasSendBackward }   from '@fortawesome/pro-solid-svg-icons';
import { faSensor as fadSensor }   from '@fortawesome/pro-duotone-svg-icons';
import { faSensor as falSensor }   from '@fortawesome/pro-light-svg-icons';
import { faSensor as farSensor }   from '@fortawesome/pro-regular-svg-icons';
import { faSensor as fasSensor }   from '@fortawesome/pro-solid-svg-icons';
import { faSensorAlert as fadSensorAlert }   from '@fortawesome/pro-duotone-svg-icons';
import { faSensorAlert as falSensorAlert }   from '@fortawesome/pro-light-svg-icons';
import { faSensorAlert as farSensorAlert }   from '@fortawesome/pro-regular-svg-icons';
import { faSensorAlert as fasSensorAlert }   from '@fortawesome/pro-solid-svg-icons';
import { faSensorCloud as fadSensorCloud }   from '@fortawesome/pro-duotone-svg-icons';
import { faSensorCloud as falSensorCloud }   from '@fortawesome/pro-light-svg-icons';
import { faSensorCloud as farSensorCloud }   from '@fortawesome/pro-regular-svg-icons';
import { faSensorCloud as fasSensorCloud }   from '@fortawesome/pro-solid-svg-icons';
import { faSensorFire as fadSensorFire }   from '@fortawesome/pro-duotone-svg-icons';
import { faSensorFire as falSensorFire }   from '@fortawesome/pro-light-svg-icons';
import { faSensorFire as farSensorFire }   from '@fortawesome/pro-regular-svg-icons';
import { faSensorFire as fasSensorFire }   from '@fortawesome/pro-solid-svg-icons';
import { faSensorOn as fadSensorOn }   from '@fortawesome/pro-duotone-svg-icons';
import { faSensorOn as falSensorOn }   from '@fortawesome/pro-light-svg-icons';
import { faSensorOn as farSensorOn }   from '@fortawesome/pro-regular-svg-icons';
import { faSensorOn as fasSensorOn }   from '@fortawesome/pro-solid-svg-icons';
import { faSensorSmoke as fadSensorSmoke }   from '@fortawesome/pro-duotone-svg-icons';
import { faSensorSmoke as falSensorSmoke }   from '@fortawesome/pro-light-svg-icons';
import { faSensorSmoke as farSensorSmoke }   from '@fortawesome/pro-regular-svg-icons';
import { faSensorSmoke as fasSensorSmoke }   from '@fortawesome/pro-solid-svg-icons';
import { faSensorTriangleExclamation as fadSensorTriangleExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faSensorTriangleExclamation as falSensorTriangleExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faSensorTriangleExclamation as farSensorTriangleExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faSensorTriangleExclamation as fasSensorTriangleExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faServer as fadServer }   from '@fortawesome/pro-duotone-svg-icons';
import { faServer as falServer }   from '@fortawesome/pro-light-svg-icons';
import { faServer as farServer }   from '@fortawesome/pro-regular-svg-icons';
import { faServer as fasServer }   from '@fortawesome/pro-solid-svg-icons';
import { faShapes as fadShapes }   from '@fortawesome/pro-duotone-svg-icons';
import { faShapes as falShapes }   from '@fortawesome/pro-light-svg-icons';
import { faShapes as farShapes }   from '@fortawesome/pro-regular-svg-icons';
import { faShapes as fasShapes }   from '@fortawesome/pro-solid-svg-icons';
import { faShare as fadShare }   from '@fortawesome/pro-duotone-svg-icons';
import { faShare as falShare }   from '@fortawesome/pro-light-svg-icons';
import { faShare as farShare }   from '@fortawesome/pro-regular-svg-icons';
import { faShare as fasShare }   from '@fortawesome/pro-solid-svg-icons';
import { faShareAll as fadShareAll }   from '@fortawesome/pro-duotone-svg-icons';
import { faShareAll as falShareAll }   from '@fortawesome/pro-light-svg-icons';
import { faShareAll as farShareAll }   from '@fortawesome/pro-regular-svg-icons';
import { faShareAll as fasShareAll }   from '@fortawesome/pro-solid-svg-icons';
import { faShareAlt as fadShareAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faShareAlt as falShareAlt }   from '@fortawesome/pro-light-svg-icons';
import { faShareAlt as farShareAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faShareAlt as fasShareAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faShareAltSquare as fadShareAltSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faShareAltSquare as falShareAltSquare }   from '@fortawesome/pro-light-svg-icons';
import { faShareAltSquare as farShareAltSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faShareAltSquare as fasShareAltSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faShareFromSquare as fadShareFromSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faShareFromSquare as falShareFromSquare }   from '@fortawesome/pro-light-svg-icons';
import { faShareFromSquare as farShareFromSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faShareFromSquare as fasShareFromSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faShareNodes as fadShareNodes }   from '@fortawesome/pro-duotone-svg-icons';
import { faShareNodes as falShareNodes }   from '@fortawesome/pro-light-svg-icons';
import { faShareNodes as farShareNodes }   from '@fortawesome/pro-regular-svg-icons';
import { faShareNodes as fasShareNodes }   from '@fortawesome/pro-solid-svg-icons';
import { faShareSquare as fadShareSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faShareSquare as falShareSquare }   from '@fortawesome/pro-light-svg-icons';
import { faShareSquare as farShareSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faShareSquare as fasShareSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faSheep as fadSheep }   from '@fortawesome/pro-duotone-svg-icons';
import { faSheep as falSheep }   from '@fortawesome/pro-light-svg-icons';
import { faSheep as farSheep }   from '@fortawesome/pro-regular-svg-icons';
import { faSheep as fasSheep }   from '@fortawesome/pro-solid-svg-icons';
import { faSheetPlastic as fadSheetPlastic }   from '@fortawesome/pro-duotone-svg-icons';
import { faSheetPlastic as falSheetPlastic }   from '@fortawesome/pro-light-svg-icons';
import { faSheetPlastic as farSheetPlastic }   from '@fortawesome/pro-regular-svg-icons';
import { faSheetPlastic as fasSheetPlastic }   from '@fortawesome/pro-solid-svg-icons';
import { faShekel as fadShekel }   from '@fortawesome/pro-duotone-svg-icons';
import { faShekel as falShekel }   from '@fortawesome/pro-light-svg-icons';
import { faShekel as farShekel }   from '@fortawesome/pro-regular-svg-icons';
import { faShekel as fasShekel }   from '@fortawesome/pro-solid-svg-icons';
import { faShekelSign as fadShekelSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faShekelSign as falShekelSign }   from '@fortawesome/pro-light-svg-icons';
import { faShekelSign as farShekelSign }   from '@fortawesome/pro-regular-svg-icons';
import { faShekelSign as fasShekelSign }   from '@fortawesome/pro-solid-svg-icons';
import { faShelves as fadShelves }   from '@fortawesome/pro-duotone-svg-icons';
import { faShelves as falShelves }   from '@fortawesome/pro-light-svg-icons';
import { faShelves as farShelves }   from '@fortawesome/pro-regular-svg-icons';
import { faShelves as fasShelves }   from '@fortawesome/pro-solid-svg-icons';
import { faShelvesEmpty as fadShelvesEmpty }   from '@fortawesome/pro-duotone-svg-icons';
import { faShelvesEmpty as falShelvesEmpty }   from '@fortawesome/pro-light-svg-icons';
import { faShelvesEmpty as farShelvesEmpty }   from '@fortawesome/pro-regular-svg-icons';
import { faShelvesEmpty as fasShelvesEmpty }   from '@fortawesome/pro-solid-svg-icons';
import { faSheqel as fadSheqel }   from '@fortawesome/pro-duotone-svg-icons';
import { faSheqel as falSheqel }   from '@fortawesome/pro-light-svg-icons';
import { faSheqel as farSheqel }   from '@fortawesome/pro-regular-svg-icons';
import { faSheqel as fasSheqel }   from '@fortawesome/pro-solid-svg-icons';
import { faSheqelSign as fadSheqelSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faSheqelSign as falSheqelSign }   from '@fortawesome/pro-light-svg-icons';
import { faSheqelSign as farSheqelSign }   from '@fortawesome/pro-regular-svg-icons';
import { faSheqelSign as fasSheqelSign }   from '@fortawesome/pro-solid-svg-icons';
import { faShield as fadShield }   from '@fortawesome/pro-duotone-svg-icons';
import { faShield as falShield }   from '@fortawesome/pro-light-svg-icons';
import { faShield as farShield }   from '@fortawesome/pro-regular-svg-icons';
import { faShield as fasShield }   from '@fortawesome/pro-solid-svg-icons';
import { faShieldAlt as fadShieldAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faShieldAlt as falShieldAlt }   from '@fortawesome/pro-light-svg-icons';
import { faShieldAlt as farShieldAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faShieldAlt as fasShieldAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faShieldBlank as fadShieldBlank }   from '@fortawesome/pro-duotone-svg-icons';
import { faShieldBlank as falShieldBlank }   from '@fortawesome/pro-light-svg-icons';
import { faShieldBlank as farShieldBlank }   from '@fortawesome/pro-regular-svg-icons';
import { faShieldBlank as fasShieldBlank }   from '@fortawesome/pro-solid-svg-icons';
import { faShieldCat as fadShieldCat }   from '@fortawesome/pro-duotone-svg-icons';
import { faShieldCat as falShieldCat }   from '@fortawesome/pro-light-svg-icons';
import { faShieldCat as farShieldCat }   from '@fortawesome/pro-regular-svg-icons';
import { faShieldCat as fasShieldCat }   from '@fortawesome/pro-solid-svg-icons';
import { faShieldCheck as fadShieldCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faShieldCheck as falShieldCheck }   from '@fortawesome/pro-light-svg-icons';
import { faShieldCheck as farShieldCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faShieldCheck as fasShieldCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faShieldCross as fadShieldCross }   from '@fortawesome/pro-duotone-svg-icons';
import { faShieldCross as falShieldCross }   from '@fortawesome/pro-light-svg-icons';
import { faShieldCross as farShieldCross }   from '@fortawesome/pro-regular-svg-icons';
import { faShieldCross as fasShieldCross }   from '@fortawesome/pro-solid-svg-icons';
import { faShieldDog as fadShieldDog }   from '@fortawesome/pro-duotone-svg-icons';
import { faShieldDog as falShieldDog }   from '@fortawesome/pro-light-svg-icons';
import { faShieldDog as farShieldDog }   from '@fortawesome/pro-regular-svg-icons';
import { faShieldDog as fasShieldDog }   from '@fortawesome/pro-solid-svg-icons';
import { faShieldExclamation as fadShieldExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faShieldExclamation as falShieldExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faShieldExclamation as farShieldExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faShieldExclamation as fasShieldExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faShieldHalved as fadShieldHalved }   from '@fortawesome/pro-duotone-svg-icons';
import { faShieldHalved as falShieldHalved }   from '@fortawesome/pro-light-svg-icons';
import { faShieldHalved as farShieldHalved }   from '@fortawesome/pro-regular-svg-icons';
import { faShieldHalved as fasShieldHalved }   from '@fortawesome/pro-solid-svg-icons';
import { faShieldHeart as fadShieldHeart }   from '@fortawesome/pro-duotone-svg-icons';
import { faShieldHeart as falShieldHeart }   from '@fortawesome/pro-light-svg-icons';
import { faShieldHeart as farShieldHeart }   from '@fortawesome/pro-regular-svg-icons';
import { faShieldHeart as fasShieldHeart }   from '@fortawesome/pro-solid-svg-icons';
import { faShieldKeyhole as fadShieldKeyhole }   from '@fortawesome/pro-duotone-svg-icons';
import { faShieldKeyhole as falShieldKeyhole }   from '@fortawesome/pro-light-svg-icons';
import { faShieldKeyhole as farShieldKeyhole }   from '@fortawesome/pro-regular-svg-icons';
import { faShieldKeyhole as fasShieldKeyhole }   from '@fortawesome/pro-solid-svg-icons';
import { faShieldMinus as fadShieldMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faShieldMinus as falShieldMinus }   from '@fortawesome/pro-light-svg-icons';
import { faShieldMinus as farShieldMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faShieldMinus as fasShieldMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faShieldPlus as fadShieldPlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faShieldPlus as falShieldPlus }   from '@fortawesome/pro-light-svg-icons';
import { faShieldPlus as farShieldPlus }   from '@fortawesome/pro-regular-svg-icons';
import { faShieldPlus as fasShieldPlus }   from '@fortawesome/pro-solid-svg-icons';
import { faShieldQuartered as fadShieldQuartered }   from '@fortawesome/pro-duotone-svg-icons';
import { faShieldQuartered as falShieldQuartered }   from '@fortawesome/pro-light-svg-icons';
import { faShieldQuartered as farShieldQuartered }   from '@fortawesome/pro-regular-svg-icons';
import { faShieldQuartered as fasShieldQuartered }   from '@fortawesome/pro-solid-svg-icons';
import { faShieldSlash as fadShieldSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faShieldSlash as falShieldSlash }   from '@fortawesome/pro-light-svg-icons';
import { faShieldSlash as farShieldSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faShieldSlash as fasShieldSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faShieldTimes as fadShieldTimes }   from '@fortawesome/pro-duotone-svg-icons';
import { faShieldTimes as falShieldTimes }   from '@fortawesome/pro-light-svg-icons';
import { faShieldTimes as farShieldTimes }   from '@fortawesome/pro-regular-svg-icons';
import { faShieldTimes as fasShieldTimes }   from '@fortawesome/pro-solid-svg-icons';
import { faShieldVirus as fadShieldVirus }   from '@fortawesome/pro-duotone-svg-icons';
import { faShieldVirus as falShieldVirus }   from '@fortawesome/pro-light-svg-icons';
import { faShieldVirus as farShieldVirus }   from '@fortawesome/pro-regular-svg-icons';
import { faShieldVirus as fasShieldVirus }   from '@fortawesome/pro-solid-svg-icons';
import { faShieldXmark as fadShieldXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faShieldXmark as falShieldXmark }   from '@fortawesome/pro-light-svg-icons';
import { faShieldXmark as farShieldXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faShieldXmark as fasShieldXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faShip as fadShip }   from '@fortawesome/pro-duotone-svg-icons';
import { faShip as falShip }   from '@fortawesome/pro-light-svg-icons';
import { faShip as farShip }   from '@fortawesome/pro-regular-svg-icons';
import { faShip as fasShip }   from '@fortawesome/pro-solid-svg-icons';
import { faShippingFast as fadShippingFast }   from '@fortawesome/pro-duotone-svg-icons';
import { faShippingFast as falShippingFast }   from '@fortawesome/pro-light-svg-icons';
import { faShippingFast as farShippingFast }   from '@fortawesome/pro-regular-svg-icons';
import { faShippingFast as fasShippingFast }   from '@fortawesome/pro-solid-svg-icons';
import { faShippingTimed as fadShippingTimed }   from '@fortawesome/pro-duotone-svg-icons';
import { faShippingTimed as falShippingTimed }   from '@fortawesome/pro-light-svg-icons';
import { faShippingTimed as farShippingTimed }   from '@fortawesome/pro-regular-svg-icons';
import { faShippingTimed as fasShippingTimed }   from '@fortawesome/pro-solid-svg-icons';
import { faShirt as fadShirt }   from '@fortawesome/pro-duotone-svg-icons';
import { faShirt as falShirt }   from '@fortawesome/pro-light-svg-icons';
import { faShirt as farShirt }   from '@fortawesome/pro-regular-svg-icons';
import { faShirt as fasShirt }   from '@fortawesome/pro-solid-svg-icons';
import { faShirtLongSleeve as fadShirtLongSleeve }   from '@fortawesome/pro-duotone-svg-icons';
import { faShirtLongSleeve as falShirtLongSleeve }   from '@fortawesome/pro-light-svg-icons';
import { faShirtLongSleeve as farShirtLongSleeve }   from '@fortawesome/pro-regular-svg-icons';
import { faShirtLongSleeve as fasShirtLongSleeve }   from '@fortawesome/pro-solid-svg-icons';
import { faShirtRunning as fadShirtRunning }   from '@fortawesome/pro-duotone-svg-icons';
import { faShirtRunning as falShirtRunning }   from '@fortawesome/pro-light-svg-icons';
import { faShirtRunning as farShirtRunning }   from '@fortawesome/pro-regular-svg-icons';
import { faShirtRunning as fasShirtRunning }   from '@fortawesome/pro-solid-svg-icons';
import { faShirtTankTop as fadShirtTankTop }   from '@fortawesome/pro-duotone-svg-icons';
import { faShirtTankTop as falShirtTankTop }   from '@fortawesome/pro-light-svg-icons';
import { faShirtTankTop as farShirtTankTop }   from '@fortawesome/pro-regular-svg-icons';
import { faShirtTankTop as fasShirtTankTop }   from '@fortawesome/pro-solid-svg-icons';
import { faShishKebab as fadShishKebab }   from '@fortawesome/pro-duotone-svg-icons';
import { faShishKebab as falShishKebab }   from '@fortawesome/pro-light-svg-icons';
import { faShishKebab as farShishKebab }   from '@fortawesome/pro-regular-svg-icons';
import { faShishKebab as fasShishKebab }   from '@fortawesome/pro-solid-svg-icons';
import { faShoePrints as fadShoePrints }   from '@fortawesome/pro-duotone-svg-icons';
import { faShoePrints as falShoePrints }   from '@fortawesome/pro-light-svg-icons';
import { faShoePrints as farShoePrints }   from '@fortawesome/pro-regular-svg-icons';
import { faShoePrints as fasShoePrints }   from '@fortawesome/pro-solid-svg-icons';
import { faShop as fadShop }   from '@fortawesome/pro-duotone-svg-icons';
import { faShop as falShop }   from '@fortawesome/pro-light-svg-icons';
import { faShop as farShop }   from '@fortawesome/pro-regular-svg-icons';
import { faShop as fasShop }   from '@fortawesome/pro-solid-svg-icons';
import { faShopLock as fadShopLock }   from '@fortawesome/pro-duotone-svg-icons';
import { faShopLock as falShopLock }   from '@fortawesome/pro-light-svg-icons';
import { faShopLock as farShopLock }   from '@fortawesome/pro-regular-svg-icons';
import { faShopLock as fasShopLock }   from '@fortawesome/pro-solid-svg-icons';
import { faShoppingBag as fadShoppingBag }   from '@fortawesome/pro-duotone-svg-icons';
import { faShoppingBag as falShoppingBag }   from '@fortawesome/pro-light-svg-icons';
import { faShoppingBag as farShoppingBag }   from '@fortawesome/pro-regular-svg-icons';
import { faShoppingBag as fasShoppingBag }   from '@fortawesome/pro-solid-svg-icons';
import { faShoppingBasket as fadShoppingBasket }   from '@fortawesome/pro-duotone-svg-icons';
import { faShoppingBasket as falShoppingBasket }   from '@fortawesome/pro-light-svg-icons';
import { faShoppingBasket as farShoppingBasket }   from '@fortawesome/pro-regular-svg-icons';
import { faShoppingBasket as fasShoppingBasket }   from '@fortawesome/pro-solid-svg-icons';
import { faShoppingBasketAlt as fadShoppingBasketAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faShoppingBasketAlt as falShoppingBasketAlt }   from '@fortawesome/pro-light-svg-icons';
import { faShoppingBasketAlt as farShoppingBasketAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faShoppingBasketAlt as fasShoppingBasketAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faShoppingCart as fadShoppingCart }   from '@fortawesome/pro-duotone-svg-icons';
import { faShoppingCart as falShoppingCart }   from '@fortawesome/pro-light-svg-icons';
import { faShoppingCart as farShoppingCart }   from '@fortawesome/pro-regular-svg-icons';
import { faShoppingCart as fasShoppingCart }   from '@fortawesome/pro-solid-svg-icons';
import { faShopSlash as fadShopSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faShopSlash as falShopSlash }   from '@fortawesome/pro-light-svg-icons';
import { faShopSlash as farShopSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faShopSlash as fasShopSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faShortcake as fadShortcake }   from '@fortawesome/pro-duotone-svg-icons';
import { faShortcake as falShortcake }   from '@fortawesome/pro-light-svg-icons';
import { faShortcake as farShortcake }   from '@fortawesome/pro-regular-svg-icons';
import { faShortcake as fasShortcake }   from '@fortawesome/pro-solid-svg-icons';
import { faShovel as fadShovel }   from '@fortawesome/pro-duotone-svg-icons';
import { faShovel as falShovel }   from '@fortawesome/pro-light-svg-icons';
import { faShovel as farShovel }   from '@fortawesome/pro-regular-svg-icons';
import { faShovel as fasShovel }   from '@fortawesome/pro-solid-svg-icons';
import { faShovelSnow as fadShovelSnow }   from '@fortawesome/pro-duotone-svg-icons';
import { faShovelSnow as falShovelSnow }   from '@fortawesome/pro-light-svg-icons';
import { faShovelSnow as farShovelSnow }   from '@fortawesome/pro-regular-svg-icons';
import { faShovelSnow as fasShovelSnow }   from '@fortawesome/pro-solid-svg-icons';
import { faShower as fadShower }   from '@fortawesome/pro-duotone-svg-icons';
import { faShower as falShower }   from '@fortawesome/pro-light-svg-icons';
import { faShower as farShower }   from '@fortawesome/pro-regular-svg-icons';
import { faShower as fasShower }   from '@fortawesome/pro-solid-svg-icons';
import { faShowerAlt as fadShowerAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faShowerAlt as falShowerAlt }   from '@fortawesome/pro-light-svg-icons';
import { faShowerAlt as farShowerAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faShowerAlt as fasShowerAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faShowerDown as fadShowerDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faShowerDown as falShowerDown }   from '@fortawesome/pro-light-svg-icons';
import { faShowerDown as farShowerDown }   from '@fortawesome/pro-regular-svg-icons';
import { faShowerDown as fasShowerDown }   from '@fortawesome/pro-solid-svg-icons';
import { faShredder as fadShredder }   from '@fortawesome/pro-duotone-svg-icons';
import { faShredder as falShredder }   from '@fortawesome/pro-light-svg-icons';
import { faShredder as farShredder }   from '@fortawesome/pro-regular-svg-icons';
import { faShredder as fasShredder }   from '@fortawesome/pro-solid-svg-icons';
import { faShrimp as fadShrimp }   from '@fortawesome/pro-duotone-svg-icons';
import { faShrimp as falShrimp }   from '@fortawesome/pro-light-svg-icons';
import { faShrimp as farShrimp }   from '@fortawesome/pro-regular-svg-icons';
import { faShrimp as fasShrimp }   from '@fortawesome/pro-solid-svg-icons';
import { faShuffle as fadShuffle }   from '@fortawesome/pro-duotone-svg-icons';
import { faShuffle as falShuffle }   from '@fortawesome/pro-light-svg-icons';
import { faShuffle as farShuffle }   from '@fortawesome/pro-regular-svg-icons';
import { faShuffle as fasShuffle }   from '@fortawesome/pro-solid-svg-icons';
import { faShutters as fadShutters }   from '@fortawesome/pro-duotone-svg-icons';
import { faShutters as falShutters }   from '@fortawesome/pro-light-svg-icons';
import { faShutters as farShutters }   from '@fortawesome/pro-regular-svg-icons';
import { faShutters as fasShutters }   from '@fortawesome/pro-solid-svg-icons';
import { faShuttlecock as fadShuttlecock }   from '@fortawesome/pro-duotone-svg-icons';
import { faShuttlecock as falShuttlecock }   from '@fortawesome/pro-light-svg-icons';
import { faShuttlecock as farShuttlecock }   from '@fortawesome/pro-regular-svg-icons';
import { faShuttlecock as fasShuttlecock }   from '@fortawesome/pro-solid-svg-icons';
import { faShuttleSpace as fadShuttleSpace }   from '@fortawesome/pro-duotone-svg-icons';
import { faShuttleSpace as falShuttleSpace }   from '@fortawesome/pro-light-svg-icons';
import { faShuttleSpace as farShuttleSpace }   from '@fortawesome/pro-regular-svg-icons';
import { faShuttleSpace as fasShuttleSpace }   from '@fortawesome/pro-solid-svg-icons';
import { faShuttleVan as fadShuttleVan }   from '@fortawesome/pro-duotone-svg-icons';
import { faShuttleVan as falShuttleVan }   from '@fortawesome/pro-light-svg-icons';
import { faShuttleVan as farShuttleVan }   from '@fortawesome/pro-regular-svg-icons';
import { faShuttleVan as fasShuttleVan }   from '@fortawesome/pro-solid-svg-icons';
import { faSickle as fadSickle }   from '@fortawesome/pro-duotone-svg-icons';
import { faSickle as falSickle }   from '@fortawesome/pro-light-svg-icons';
import { faSickle as farSickle }   from '@fortawesome/pro-regular-svg-icons';
import { faSickle as fasSickle }   from '@fortawesome/pro-solid-svg-icons';
import { faSidebar as fadSidebar }   from '@fortawesome/pro-duotone-svg-icons';
import { faSidebar as falSidebar }   from '@fortawesome/pro-light-svg-icons';
import { faSidebar as farSidebar }   from '@fortawesome/pro-regular-svg-icons';
import { faSidebar as fasSidebar }   from '@fortawesome/pro-solid-svg-icons';
import { faSidebarFlip as fadSidebarFlip }   from '@fortawesome/pro-duotone-svg-icons';
import { faSidebarFlip as falSidebarFlip }   from '@fortawesome/pro-light-svg-icons';
import { faSidebarFlip as farSidebarFlip }   from '@fortawesome/pro-regular-svg-icons';
import { faSidebarFlip as fasSidebarFlip }   from '@fortawesome/pro-solid-svg-icons';
import { faSigma as fadSigma }   from '@fortawesome/pro-duotone-svg-icons';
import { faSigma as falSigma }   from '@fortawesome/pro-light-svg-icons';
import { faSigma as farSigma }   from '@fortawesome/pro-regular-svg-icons';
import { faSigma as fasSigma }   from '@fortawesome/pro-solid-svg-icons';
import { faSign as fadSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faSign as falSign }   from '@fortawesome/pro-light-svg-icons';
import { faSign as farSign }   from '@fortawesome/pro-regular-svg-icons';
import { faSign as fasSign }   from '@fortawesome/pro-solid-svg-icons';
import { faSignal as fadSignal }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignal as falSignal }   from '@fortawesome/pro-light-svg-icons';
import { faSignal as farSignal }   from '@fortawesome/pro-regular-svg-icons';
import { faSignal as fasSignal }   from '@fortawesome/pro-solid-svg-icons';
import { faSignal1 as fadSignal1 }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignal1 as falSignal1 }   from '@fortawesome/pro-light-svg-icons';
import { faSignal1 as farSignal1 }   from '@fortawesome/pro-regular-svg-icons';
import { faSignal1 as fasSignal1 }   from '@fortawesome/pro-solid-svg-icons';
import { faSignal2 as fadSignal2 }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignal2 as falSignal2 }   from '@fortawesome/pro-light-svg-icons';
import { faSignal2 as farSignal2 }   from '@fortawesome/pro-regular-svg-icons';
import { faSignal2 as fasSignal2 }   from '@fortawesome/pro-solid-svg-icons';
import { faSignal3 as fadSignal3 }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignal3 as falSignal3 }   from '@fortawesome/pro-light-svg-icons';
import { faSignal3 as farSignal3 }   from '@fortawesome/pro-regular-svg-icons';
import { faSignal3 as fasSignal3 }   from '@fortawesome/pro-solid-svg-icons';
import { faSignal4 as fadSignal4 }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignal4 as falSignal4 }   from '@fortawesome/pro-light-svg-icons';
import { faSignal4 as farSignal4 }   from '@fortawesome/pro-regular-svg-icons';
import { faSignal4 as fasSignal4 }   from '@fortawesome/pro-solid-svg-icons';
import { faSignal5 as fadSignal5 }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignal5 as falSignal5 }   from '@fortawesome/pro-light-svg-icons';
import { faSignal5 as farSignal5 }   from '@fortawesome/pro-regular-svg-icons';
import { faSignal5 as fasSignal5 }   from '@fortawesome/pro-solid-svg-icons';
import { faSignalAlt as fadSignalAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignalAlt as falSignalAlt }   from '@fortawesome/pro-light-svg-icons';
import { faSignalAlt as farSignalAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faSignalAlt as fasSignalAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faSignalAlt1 as fadSignalAlt1 }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignalAlt1 as falSignalAlt1 }   from '@fortawesome/pro-light-svg-icons';
import { faSignalAlt1 as farSignalAlt1 }   from '@fortawesome/pro-regular-svg-icons';
import { faSignalAlt1 as fasSignalAlt1 }   from '@fortawesome/pro-solid-svg-icons';
import { faSignalAlt2 as fadSignalAlt2 }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignalAlt2 as falSignalAlt2 }   from '@fortawesome/pro-light-svg-icons';
import { faSignalAlt2 as farSignalAlt2 }   from '@fortawesome/pro-regular-svg-icons';
import { faSignalAlt2 as fasSignalAlt2 }   from '@fortawesome/pro-solid-svg-icons';
import { faSignalAlt3 as fadSignalAlt3 }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignalAlt3 as falSignalAlt3 }   from '@fortawesome/pro-light-svg-icons';
import { faSignalAlt3 as farSignalAlt3 }   from '@fortawesome/pro-regular-svg-icons';
import { faSignalAlt3 as fasSignalAlt3 }   from '@fortawesome/pro-solid-svg-icons';
import { faSignalAlt4 as fadSignalAlt4 }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignalAlt4 as falSignalAlt4 }   from '@fortawesome/pro-light-svg-icons';
import { faSignalAlt4 as farSignalAlt4 }   from '@fortawesome/pro-regular-svg-icons';
import { faSignalAlt4 as fasSignalAlt4 }   from '@fortawesome/pro-solid-svg-icons';
import { faSignalAltSlash as fadSignalAltSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignalAltSlash as falSignalAltSlash }   from '@fortawesome/pro-light-svg-icons';
import { faSignalAltSlash as farSignalAltSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faSignalAltSlash as fasSignalAltSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faSignalBars as fadSignalBars }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignalBars as falSignalBars }   from '@fortawesome/pro-light-svg-icons';
import { faSignalBars as farSignalBars }   from '@fortawesome/pro-regular-svg-icons';
import { faSignalBars as fasSignalBars }   from '@fortawesome/pro-solid-svg-icons';
import { faSignalBarsFair as fadSignalBarsFair }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignalBarsFair as falSignalBarsFair }   from '@fortawesome/pro-light-svg-icons';
import { faSignalBarsFair as farSignalBarsFair }   from '@fortawesome/pro-regular-svg-icons';
import { faSignalBarsFair as fasSignalBarsFair }   from '@fortawesome/pro-solid-svg-icons';
import { faSignalBarsGood as fadSignalBarsGood }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignalBarsGood as falSignalBarsGood }   from '@fortawesome/pro-light-svg-icons';
import { faSignalBarsGood as farSignalBarsGood }   from '@fortawesome/pro-regular-svg-icons';
import { faSignalBarsGood as fasSignalBarsGood }   from '@fortawesome/pro-solid-svg-icons';
import { faSignalBarsSlash as fadSignalBarsSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignalBarsSlash as falSignalBarsSlash }   from '@fortawesome/pro-light-svg-icons';
import { faSignalBarsSlash as farSignalBarsSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faSignalBarsSlash as fasSignalBarsSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faSignalBarsStrong as fadSignalBarsStrong }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignalBarsStrong as falSignalBarsStrong }   from '@fortawesome/pro-light-svg-icons';
import { faSignalBarsStrong as farSignalBarsStrong }   from '@fortawesome/pro-regular-svg-icons';
import { faSignalBarsStrong as fasSignalBarsStrong }   from '@fortawesome/pro-solid-svg-icons';
import { faSignalBarsWeak as fadSignalBarsWeak }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignalBarsWeak as falSignalBarsWeak }   from '@fortawesome/pro-light-svg-icons';
import { faSignalBarsWeak as farSignalBarsWeak }   from '@fortawesome/pro-regular-svg-icons';
import { faSignalBarsWeak as fasSignalBarsWeak }   from '@fortawesome/pro-solid-svg-icons';
import { faSignalFair as fadSignalFair }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignalFair as falSignalFair }   from '@fortawesome/pro-light-svg-icons';
import { faSignalFair as farSignalFair }   from '@fortawesome/pro-regular-svg-icons';
import { faSignalFair as fasSignalFair }   from '@fortawesome/pro-solid-svg-icons';
import { faSignalGood as fadSignalGood }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignalGood as falSignalGood }   from '@fortawesome/pro-light-svg-icons';
import { faSignalGood as farSignalGood }   from '@fortawesome/pro-regular-svg-icons';
import { faSignalGood as fasSignalGood }   from '@fortawesome/pro-solid-svg-icons';
import { faSignalPerfect as fadSignalPerfect }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignalPerfect as falSignalPerfect }   from '@fortawesome/pro-light-svg-icons';
import { faSignalPerfect as farSignalPerfect }   from '@fortawesome/pro-regular-svg-icons';
import { faSignalPerfect as fasSignalPerfect }   from '@fortawesome/pro-solid-svg-icons';
import { faSignalSlash as fadSignalSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignalSlash as falSignalSlash }   from '@fortawesome/pro-light-svg-icons';
import { faSignalSlash as farSignalSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faSignalSlash as fasSignalSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faSignalStream as fadSignalStream }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignalStream as falSignalStream }   from '@fortawesome/pro-light-svg-icons';
import { faSignalStream as farSignalStream }   from '@fortawesome/pro-regular-svg-icons';
import { faSignalStream as fasSignalStream }   from '@fortawesome/pro-solid-svg-icons';
import { faSignalStreamSlash as fadSignalStreamSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignalStreamSlash as falSignalStreamSlash }   from '@fortawesome/pro-light-svg-icons';
import { faSignalStreamSlash as farSignalStreamSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faSignalStreamSlash as fasSignalStreamSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faSignalStrong as fadSignalStrong }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignalStrong as falSignalStrong }   from '@fortawesome/pro-light-svg-icons';
import { faSignalStrong as farSignalStrong }   from '@fortawesome/pro-regular-svg-icons';
import { faSignalStrong as fasSignalStrong }   from '@fortawesome/pro-solid-svg-icons';
import { faSignalWeak as fadSignalWeak }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignalWeak as falSignalWeak }   from '@fortawesome/pro-light-svg-icons';
import { faSignalWeak as farSignalWeak }   from '@fortawesome/pro-regular-svg-icons';
import { faSignalWeak as fasSignalWeak }   from '@fortawesome/pro-solid-svg-icons';
import { faSignature as fadSignature }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignature as falSignature }   from '@fortawesome/pro-light-svg-icons';
import { faSignature as farSignature }   from '@fortawesome/pro-regular-svg-icons';
import { faSignature as fasSignature }   from '@fortawesome/pro-solid-svg-icons';
import { faSignatureLock as fadSignatureLock }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignatureLock as falSignatureLock }   from '@fortawesome/pro-light-svg-icons';
import { faSignatureLock as farSignatureLock }   from '@fortawesome/pro-regular-svg-icons';
import { faSignatureLock as fasSignatureLock }   from '@fortawesome/pro-solid-svg-icons';
import { faSignatureSlash as fadSignatureSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignatureSlash as falSignatureSlash }   from '@fortawesome/pro-light-svg-icons';
import { faSignatureSlash as farSignatureSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faSignatureSlash as fasSignatureSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faSignHanging as fadSignHanging }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignHanging as falSignHanging }   from '@fortawesome/pro-light-svg-icons';
import { faSignHanging as farSignHanging }   from '@fortawesome/pro-regular-svg-icons';
import { faSignHanging as fasSignHanging }   from '@fortawesome/pro-solid-svg-icons';
import { faSignIn as fadSignIn }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignIn as falSignIn }   from '@fortawesome/pro-light-svg-icons';
import { faSignIn as farSignIn }   from '@fortawesome/pro-regular-svg-icons';
import { faSignIn as fasSignIn }   from '@fortawesome/pro-solid-svg-icons';
import { faSignInAlt as fadSignInAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignInAlt as falSignInAlt }   from '@fortawesome/pro-light-svg-icons';
import { faSignInAlt as farSignInAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faSignInAlt as fasSignInAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faSigning as fadSigning }   from '@fortawesome/pro-duotone-svg-icons';
import { faSigning as falSigning }   from '@fortawesome/pro-light-svg-icons';
import { faSigning as farSigning }   from '@fortawesome/pro-regular-svg-icons';
import { faSigning as fasSigning }   from '@fortawesome/pro-solid-svg-icons';
import { faSignLanguage as fadSignLanguage }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignLanguage as falSignLanguage }   from '@fortawesome/pro-light-svg-icons';
import { faSignLanguage as farSignLanguage }   from '@fortawesome/pro-regular-svg-icons';
import { faSignLanguage as fasSignLanguage }   from '@fortawesome/pro-solid-svg-icons';
import { faSignOut as fadSignOut }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignOut as falSignOut }   from '@fortawesome/pro-light-svg-icons';
import { faSignOut as farSignOut }   from '@fortawesome/pro-regular-svg-icons';
import { faSignOut as fasSignOut }   from '@fortawesome/pro-solid-svg-icons';
import { faSignOutAlt as fadSignOutAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignOutAlt as falSignOutAlt }   from '@fortawesome/pro-light-svg-icons';
import { faSignOutAlt as farSignOutAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faSignOutAlt as fasSignOutAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faSignsPost as fadSignsPost }   from '@fortawesome/pro-duotone-svg-icons';
import { faSignsPost as falSignsPost }   from '@fortawesome/pro-light-svg-icons';
import { faSignsPost as farSignsPost }   from '@fortawesome/pro-regular-svg-icons';
import { faSignsPost as fasSignsPost }   from '@fortawesome/pro-solid-svg-icons';
import { faSimCard as fadSimCard }   from '@fortawesome/pro-duotone-svg-icons';
import { faSimCard as falSimCard }   from '@fortawesome/pro-light-svg-icons';
import { faSimCard as farSimCard }   from '@fortawesome/pro-regular-svg-icons';
import { faSimCard as fasSimCard }   from '@fortawesome/pro-solid-svg-icons';
import { faSimCards as fadSimCards }   from '@fortawesome/pro-duotone-svg-icons';
import { faSimCards as falSimCards }   from '@fortawesome/pro-light-svg-icons';
import { faSimCards as farSimCards }   from '@fortawesome/pro-regular-svg-icons';
import { faSimCards as fasSimCards }   from '@fortawesome/pro-solid-svg-icons';
import { faSink as fadSink }   from '@fortawesome/pro-duotone-svg-icons';
import { faSink as falSink }   from '@fortawesome/pro-light-svg-icons';
import { faSink as farSink }   from '@fortawesome/pro-regular-svg-icons';
import { faSink as fasSink }   from '@fortawesome/pro-solid-svg-icons';
import { faSiren as fadSiren }   from '@fortawesome/pro-duotone-svg-icons';
import { faSiren as falSiren }   from '@fortawesome/pro-light-svg-icons';
import { faSiren as farSiren }   from '@fortawesome/pro-regular-svg-icons';
import { faSiren as fasSiren }   from '@fortawesome/pro-solid-svg-icons';
import { faSirenOn as fadSirenOn }   from '@fortawesome/pro-duotone-svg-icons';
import { faSirenOn as falSirenOn }   from '@fortawesome/pro-light-svg-icons';
import { faSirenOn as farSirenOn }   from '@fortawesome/pro-regular-svg-icons';
import { faSirenOn as fasSirenOn }   from '@fortawesome/pro-solid-svg-icons';
import { faSitemap as fadSitemap }   from '@fortawesome/pro-duotone-svg-icons';
import { faSitemap as falSitemap }   from '@fortawesome/pro-light-svg-icons';
import { faSitemap as farSitemap }   from '@fortawesome/pro-regular-svg-icons';
import { faSitemap as fasSitemap }   from '@fortawesome/pro-solid-svg-icons';
import { faSkating as fadSkating }   from '@fortawesome/pro-duotone-svg-icons';
import { faSkating as falSkating }   from '@fortawesome/pro-light-svg-icons';
import { faSkating as farSkating }   from '@fortawesome/pro-regular-svg-icons';
import { faSkating as fasSkating }   from '@fortawesome/pro-solid-svg-icons';
import { faSkeleton as fadSkeleton }   from '@fortawesome/pro-duotone-svg-icons';
import { faSkeleton as falSkeleton }   from '@fortawesome/pro-light-svg-icons';
import { faSkeleton as farSkeleton }   from '@fortawesome/pro-regular-svg-icons';
import { faSkeleton as fasSkeleton }   from '@fortawesome/pro-solid-svg-icons';
import { faSkiBoot as fadSkiBoot }   from '@fortawesome/pro-duotone-svg-icons';
import { faSkiBoot as falSkiBoot }   from '@fortawesome/pro-light-svg-icons';
import { faSkiBoot as farSkiBoot }   from '@fortawesome/pro-regular-svg-icons';
import { faSkiBoot as fasSkiBoot }   from '@fortawesome/pro-solid-svg-icons';
import { faSkiBootSki as fadSkiBootSki }   from '@fortawesome/pro-duotone-svg-icons';
import { faSkiBootSki as falSkiBootSki }   from '@fortawesome/pro-light-svg-icons';
import { faSkiBootSki as farSkiBootSki }   from '@fortawesome/pro-regular-svg-icons';
import { faSkiBootSki as fasSkiBootSki }   from '@fortawesome/pro-solid-svg-icons';
import { faSkiing as fadSkiing }   from '@fortawesome/pro-duotone-svg-icons';
import { faSkiing as falSkiing }   from '@fortawesome/pro-light-svg-icons';
import { faSkiing as farSkiing }   from '@fortawesome/pro-regular-svg-icons';
import { faSkiing as fasSkiing }   from '@fortawesome/pro-solid-svg-icons';
import { faSkiingNordic as fadSkiingNordic }   from '@fortawesome/pro-duotone-svg-icons';
import { faSkiingNordic as falSkiingNordic }   from '@fortawesome/pro-light-svg-icons';
import { faSkiingNordic as farSkiingNordic }   from '@fortawesome/pro-regular-svg-icons';
import { faSkiingNordic as fasSkiingNordic }   from '@fortawesome/pro-solid-svg-icons';
import { faSkiJump as fadSkiJump }   from '@fortawesome/pro-duotone-svg-icons';
import { faSkiJump as falSkiJump }   from '@fortawesome/pro-light-svg-icons';
import { faSkiJump as farSkiJump }   from '@fortawesome/pro-regular-svg-icons';
import { faSkiJump as fasSkiJump }   from '@fortawesome/pro-solid-svg-icons';
import { faSkiLift as fadSkiLift }   from '@fortawesome/pro-duotone-svg-icons';
import { faSkiLift as falSkiLift }   from '@fortawesome/pro-light-svg-icons';
import { faSkiLift as farSkiLift }   from '@fortawesome/pro-regular-svg-icons';
import { faSkiLift as fasSkiLift }   from '@fortawesome/pro-solid-svg-icons';
import { faSkull as fadSkull }   from '@fortawesome/pro-duotone-svg-icons';
import { faSkull as falSkull }   from '@fortawesome/pro-light-svg-icons';
import { faSkull as farSkull }   from '@fortawesome/pro-regular-svg-icons';
import { faSkull as fasSkull }   from '@fortawesome/pro-solid-svg-icons';
import { faSkullCow as fadSkullCow }   from '@fortawesome/pro-duotone-svg-icons';
import { faSkullCow as falSkullCow }   from '@fortawesome/pro-light-svg-icons';
import { faSkullCow as farSkullCow }   from '@fortawesome/pro-regular-svg-icons';
import { faSkullCow as fasSkullCow }   from '@fortawesome/pro-solid-svg-icons';
import { faSkullCrossbones as fadSkullCrossbones }   from '@fortawesome/pro-duotone-svg-icons';
import { faSkullCrossbones as falSkullCrossbones }   from '@fortawesome/pro-light-svg-icons';
import { faSkullCrossbones as farSkullCrossbones }   from '@fortawesome/pro-regular-svg-icons';
import { faSkullCrossbones as fasSkullCrossbones }   from '@fortawesome/pro-solid-svg-icons';
import { faSlash as fadSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faSlash as falSlash }   from '@fortawesome/pro-light-svg-icons';
import { faSlash as farSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faSlash as fasSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faSlashBack as fadSlashBack }   from '@fortawesome/pro-duotone-svg-icons';
import { faSlashBack as falSlashBack }   from '@fortawesome/pro-light-svg-icons';
import { faSlashBack as farSlashBack }   from '@fortawesome/pro-regular-svg-icons';
import { faSlashBack as fasSlashBack }   from '@fortawesome/pro-solid-svg-icons';
import { faSlashForward as fadSlashForward }   from '@fortawesome/pro-duotone-svg-icons';
import { faSlashForward as falSlashForward }   from '@fortawesome/pro-light-svg-icons';
import { faSlashForward as farSlashForward }   from '@fortawesome/pro-regular-svg-icons';
import { faSlashForward as fasSlashForward }   from '@fortawesome/pro-solid-svg-icons';
import { faSledding as fadSledding }   from '@fortawesome/pro-duotone-svg-icons';
import { faSledding as falSledding }   from '@fortawesome/pro-light-svg-icons';
import { faSledding as farSledding }   from '@fortawesome/pro-regular-svg-icons';
import { faSledding as fasSledding }   from '@fortawesome/pro-solid-svg-icons';
import { faSleigh as fadSleigh }   from '@fortawesome/pro-duotone-svg-icons';
import { faSleigh as falSleigh }   from '@fortawesome/pro-light-svg-icons';
import { faSleigh as farSleigh }   from '@fortawesome/pro-regular-svg-icons';
import { faSleigh as fasSleigh }   from '@fortawesome/pro-solid-svg-icons';
import { faSlider as fadSlider }   from '@fortawesome/pro-duotone-svg-icons';
import { faSlider as falSlider }   from '@fortawesome/pro-light-svg-icons';
import { faSlider as farSlider }   from '@fortawesome/pro-regular-svg-icons';
import { faSlider as fasSlider }   from '@fortawesome/pro-solid-svg-icons';
import { faSliders as fadSliders }   from '@fortawesome/pro-duotone-svg-icons';
import { faSliders as falSliders }   from '@fortawesome/pro-light-svg-icons';
import { faSliders as farSliders }   from '@fortawesome/pro-regular-svg-icons';
import { faSliders as fasSliders }   from '@fortawesome/pro-solid-svg-icons';
import { faSlidersH as fadSlidersH }   from '@fortawesome/pro-duotone-svg-icons';
import { faSlidersH as falSlidersH }   from '@fortawesome/pro-light-svg-icons';
import { faSlidersH as farSlidersH }   from '@fortawesome/pro-regular-svg-icons';
import { faSlidersH as fasSlidersH }   from '@fortawesome/pro-solid-svg-icons';
import { faSlidersHSquare as fadSlidersHSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faSlidersHSquare as falSlidersHSquare }   from '@fortawesome/pro-light-svg-icons';
import { faSlidersHSquare as farSlidersHSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faSlidersHSquare as fasSlidersHSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faSlidersSimple as fadSlidersSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faSlidersSimple as falSlidersSimple }   from '@fortawesome/pro-light-svg-icons';
import { faSlidersSimple as farSlidersSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faSlidersSimple as fasSlidersSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faSlidersUp as fadSlidersUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faSlidersUp as falSlidersUp }   from '@fortawesome/pro-light-svg-icons';
import { faSlidersUp as farSlidersUp }   from '@fortawesome/pro-regular-svg-icons';
import { faSlidersUp as fasSlidersUp }   from '@fortawesome/pro-solid-svg-icons';
import { faSlidersV as fadSlidersV }   from '@fortawesome/pro-duotone-svg-icons';
import { faSlidersV as falSlidersV }   from '@fortawesome/pro-light-svg-icons';
import { faSlidersV as farSlidersV }   from '@fortawesome/pro-regular-svg-icons';
import { faSlidersV as fasSlidersV }   from '@fortawesome/pro-solid-svg-icons';
import { faSlidersVSquare as fadSlidersVSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faSlidersVSquare as falSlidersVSquare }   from '@fortawesome/pro-light-svg-icons';
import { faSlidersVSquare as farSlidersVSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faSlidersVSquare as fasSlidersVSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faSlotMachine as fadSlotMachine }   from '@fortawesome/pro-duotone-svg-icons';
import { faSlotMachine as falSlotMachine }   from '@fortawesome/pro-light-svg-icons';
import { faSlotMachine as farSlotMachine }   from '@fortawesome/pro-regular-svg-icons';
import { faSlotMachine as fasSlotMachine }   from '@fortawesome/pro-solid-svg-icons';
import { faSmile as fadSmile }   from '@fortawesome/pro-duotone-svg-icons';
import { faSmile as falSmile }   from '@fortawesome/pro-light-svg-icons';
import { faSmile as farSmile }   from '@fortawesome/pro-regular-svg-icons';
import { faSmile as fasSmile }   from '@fortawesome/pro-solid-svg-icons';
import { faSmileBeam as fadSmileBeam }   from '@fortawesome/pro-duotone-svg-icons';
import { faSmileBeam as falSmileBeam }   from '@fortawesome/pro-light-svg-icons';
import { faSmileBeam as farSmileBeam }   from '@fortawesome/pro-regular-svg-icons';
import { faSmileBeam as fasSmileBeam }   from '@fortawesome/pro-solid-svg-icons';
import { faSmilePlus as fadSmilePlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faSmilePlus as falSmilePlus }   from '@fortawesome/pro-light-svg-icons';
import { faSmilePlus as farSmilePlus }   from '@fortawesome/pro-regular-svg-icons';
import { faSmilePlus as fasSmilePlus }   from '@fortawesome/pro-solid-svg-icons';
import { faSmileWink as fadSmileWink }   from '@fortawesome/pro-duotone-svg-icons';
import { faSmileWink as falSmileWink }   from '@fortawesome/pro-light-svg-icons';
import { faSmileWink as farSmileWink }   from '@fortawesome/pro-regular-svg-icons';
import { faSmileWink as fasSmileWink }   from '@fortawesome/pro-solid-svg-icons';
import { faSmog as fadSmog }   from '@fortawesome/pro-duotone-svg-icons';
import { faSmog as falSmog }   from '@fortawesome/pro-light-svg-icons';
import { faSmog as farSmog }   from '@fortawesome/pro-regular-svg-icons';
import { faSmog as fasSmog }   from '@fortawesome/pro-solid-svg-icons';
import { faSmoke as fadSmoke }   from '@fortawesome/pro-duotone-svg-icons';
import { faSmoke as falSmoke }   from '@fortawesome/pro-light-svg-icons';
import { faSmoke as farSmoke }   from '@fortawesome/pro-regular-svg-icons';
import { faSmoke as fasSmoke }   from '@fortawesome/pro-solid-svg-icons';
import { faSmoking as fadSmoking }   from '@fortawesome/pro-duotone-svg-icons';
import { faSmoking as falSmoking }   from '@fortawesome/pro-light-svg-icons';
import { faSmoking as farSmoking }   from '@fortawesome/pro-regular-svg-icons';
import { faSmoking as fasSmoking }   from '@fortawesome/pro-solid-svg-icons';
import { faSmokingBan as fadSmokingBan }   from '@fortawesome/pro-duotone-svg-icons';
import { faSmokingBan as falSmokingBan }   from '@fortawesome/pro-light-svg-icons';
import { faSmokingBan as farSmokingBan }   from '@fortawesome/pro-regular-svg-icons';
import { faSmokingBan as fasSmokingBan }   from '@fortawesome/pro-solid-svg-icons';
import { faSms as fadSms }   from '@fortawesome/pro-duotone-svg-icons';
import { faSms as falSms }   from '@fortawesome/pro-light-svg-icons';
import { faSms as farSms }   from '@fortawesome/pro-regular-svg-icons';
import { faSms as fasSms }   from '@fortawesome/pro-solid-svg-icons';
import { faSnake as fadSnake }   from '@fortawesome/pro-duotone-svg-icons';
import { faSnake as falSnake }   from '@fortawesome/pro-light-svg-icons';
import { faSnake as farSnake }   from '@fortawesome/pro-regular-svg-icons';
import { faSnake as fasSnake }   from '@fortawesome/pro-solid-svg-icons';
import { faSnooze as fadSnooze }   from '@fortawesome/pro-duotone-svg-icons';
import { faSnooze as falSnooze }   from '@fortawesome/pro-light-svg-icons';
import { faSnooze as farSnooze }   from '@fortawesome/pro-regular-svg-icons';
import { faSnooze as fasSnooze }   from '@fortawesome/pro-solid-svg-icons';
import { faSnowBlowing as fadSnowBlowing }   from '@fortawesome/pro-duotone-svg-icons';
import { faSnowBlowing as falSnowBlowing }   from '@fortawesome/pro-light-svg-icons';
import { faSnowBlowing as farSnowBlowing }   from '@fortawesome/pro-regular-svg-icons';
import { faSnowBlowing as fasSnowBlowing }   from '@fortawesome/pro-solid-svg-icons';
import { faSnowboarding as fadSnowboarding }   from '@fortawesome/pro-duotone-svg-icons';
import { faSnowboarding as falSnowboarding }   from '@fortawesome/pro-light-svg-icons';
import { faSnowboarding as farSnowboarding }   from '@fortawesome/pro-regular-svg-icons';
import { faSnowboarding as fasSnowboarding }   from '@fortawesome/pro-solid-svg-icons';
import { faSnowflake as fadSnowflake }   from '@fortawesome/pro-duotone-svg-icons';
import { faSnowflake as falSnowflake }   from '@fortawesome/pro-light-svg-icons';
import { faSnowflake as farSnowflake }   from '@fortawesome/pro-regular-svg-icons';
import { faSnowflake as fasSnowflake }   from '@fortawesome/pro-solid-svg-icons';
import { faSnowflakes as fadSnowflakes }   from '@fortawesome/pro-duotone-svg-icons';
import { faSnowflakes as falSnowflakes }   from '@fortawesome/pro-light-svg-icons';
import { faSnowflakes as farSnowflakes }   from '@fortawesome/pro-regular-svg-icons';
import { faSnowflakes as fasSnowflakes }   from '@fortawesome/pro-solid-svg-icons';
import { faSnowman as fadSnowman }   from '@fortawesome/pro-duotone-svg-icons';
import { faSnowman as falSnowman }   from '@fortawesome/pro-light-svg-icons';
import { faSnowman as farSnowman }   from '@fortawesome/pro-regular-svg-icons';
import { faSnowman as fasSnowman }   from '@fortawesome/pro-solid-svg-icons';
import { faSnowmanHead as fadSnowmanHead }   from '@fortawesome/pro-duotone-svg-icons';
import { faSnowmanHead as falSnowmanHead }   from '@fortawesome/pro-light-svg-icons';
import { faSnowmanHead as farSnowmanHead }   from '@fortawesome/pro-regular-svg-icons';
import { faSnowmanHead as fasSnowmanHead }   from '@fortawesome/pro-solid-svg-icons';
import { faSnowmobile as fadSnowmobile }   from '@fortawesome/pro-duotone-svg-icons';
import { faSnowmobile as falSnowmobile }   from '@fortawesome/pro-light-svg-icons';
import { faSnowmobile as farSnowmobile }   from '@fortawesome/pro-regular-svg-icons';
import { faSnowmobile as fasSnowmobile }   from '@fortawesome/pro-solid-svg-icons';
import { faSnowplow as fadSnowplow }   from '@fortawesome/pro-duotone-svg-icons';
import { faSnowplow as falSnowplow }   from '@fortawesome/pro-light-svg-icons';
import { faSnowplow as farSnowplow }   from '@fortawesome/pro-regular-svg-icons';
import { faSnowplow as fasSnowplow }   from '@fortawesome/pro-solid-svg-icons';
import { faSoap as fadSoap }   from '@fortawesome/pro-duotone-svg-icons';
import { faSoap as falSoap }   from '@fortawesome/pro-light-svg-icons';
import { faSoap as farSoap }   from '@fortawesome/pro-regular-svg-icons';
import { faSoap as fasSoap }   from '@fortawesome/pro-solid-svg-icons';
import { faSoccerBall as fadSoccerBall }   from '@fortawesome/pro-duotone-svg-icons';
import { faSoccerBall as falSoccerBall }   from '@fortawesome/pro-light-svg-icons';
import { faSoccerBall as farSoccerBall }   from '@fortawesome/pro-regular-svg-icons';
import { faSoccerBall as fasSoccerBall }   from '@fortawesome/pro-solid-svg-icons';
import { faSocks as fadSocks }   from '@fortawesome/pro-duotone-svg-icons';
import { faSocks as falSocks }   from '@fortawesome/pro-light-svg-icons';
import { faSocks as farSocks }   from '@fortawesome/pro-regular-svg-icons';
import { faSocks as fasSocks }   from '@fortawesome/pro-solid-svg-icons';
import { faSoftServe as fadSoftServe }   from '@fortawesome/pro-duotone-svg-icons';
import { faSoftServe as falSoftServe }   from '@fortawesome/pro-light-svg-icons';
import { faSoftServe as farSoftServe }   from '@fortawesome/pro-regular-svg-icons';
import { faSoftServe as fasSoftServe }   from '@fortawesome/pro-solid-svg-icons';
import { faSolarPanel as fadSolarPanel }   from '@fortawesome/pro-duotone-svg-icons';
import { faSolarPanel as falSolarPanel }   from '@fortawesome/pro-light-svg-icons';
import { faSolarPanel as farSolarPanel }   from '@fortawesome/pro-regular-svg-icons';
import { faSolarPanel as fasSolarPanel }   from '@fortawesome/pro-solid-svg-icons';
import { faSolarSystem as fadSolarSystem }   from '@fortawesome/pro-duotone-svg-icons';
import { faSolarSystem as falSolarSystem }   from '@fortawesome/pro-light-svg-icons';
import { faSolarSystem as farSolarSystem }   from '@fortawesome/pro-regular-svg-icons';
import { faSolarSystem as fasSolarSystem }   from '@fortawesome/pro-solid-svg-icons';
import { faSort as fadSort }   from '@fortawesome/pro-duotone-svg-icons';
import { faSort as falSort }   from '@fortawesome/pro-light-svg-icons';
import { faSort as farSort }   from '@fortawesome/pro-regular-svg-icons';
import { faSort as fasSort }   from '@fortawesome/pro-solid-svg-icons';
import { faSortAlphaAsc as fadSortAlphaAsc }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortAlphaAsc as falSortAlphaAsc }   from '@fortawesome/pro-light-svg-icons';
import { faSortAlphaAsc as farSortAlphaAsc }   from '@fortawesome/pro-regular-svg-icons';
import { faSortAlphaAsc as fasSortAlphaAsc }   from '@fortawesome/pro-solid-svg-icons';
import { faSortAlphaDesc as fadSortAlphaDesc }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortAlphaDesc as falSortAlphaDesc }   from '@fortawesome/pro-light-svg-icons';
import { faSortAlphaDesc as farSortAlphaDesc }   from '@fortawesome/pro-regular-svg-icons';
import { faSortAlphaDesc as fasSortAlphaDesc }   from '@fortawesome/pro-solid-svg-icons';
import { faSortAlphaDown as fadSortAlphaDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortAlphaDown as falSortAlphaDown }   from '@fortawesome/pro-light-svg-icons';
import { faSortAlphaDown as farSortAlphaDown }   from '@fortawesome/pro-regular-svg-icons';
import { faSortAlphaDown as fasSortAlphaDown }   from '@fortawesome/pro-solid-svg-icons';
import { faSortAlphaDownAlt as fadSortAlphaDownAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortAlphaDownAlt as falSortAlphaDownAlt }   from '@fortawesome/pro-light-svg-icons';
import { faSortAlphaDownAlt as farSortAlphaDownAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faSortAlphaDownAlt as fasSortAlphaDownAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faSortAlphaUp as fadSortAlphaUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortAlphaUp as falSortAlphaUp }   from '@fortawesome/pro-light-svg-icons';
import { faSortAlphaUp as farSortAlphaUp }   from '@fortawesome/pro-regular-svg-icons';
import { faSortAlphaUp as fasSortAlphaUp }   from '@fortawesome/pro-solid-svg-icons';
import { faSortAlphaUpAlt as fadSortAlphaUpAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortAlphaUpAlt as falSortAlphaUpAlt }   from '@fortawesome/pro-light-svg-icons';
import { faSortAlphaUpAlt as farSortAlphaUpAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faSortAlphaUpAlt as fasSortAlphaUpAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faSortAlt as fadSortAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortAlt as falSortAlt }   from '@fortawesome/pro-light-svg-icons';
import { faSortAlt as farSortAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faSortAlt as fasSortAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faSortAmountAsc as fadSortAmountAsc }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortAmountAsc as falSortAmountAsc }   from '@fortawesome/pro-light-svg-icons';
import { faSortAmountAsc as farSortAmountAsc }   from '@fortawesome/pro-regular-svg-icons';
import { faSortAmountAsc as fasSortAmountAsc }   from '@fortawesome/pro-solid-svg-icons';
import { faSortAmountDesc as fadSortAmountDesc }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortAmountDesc as falSortAmountDesc }   from '@fortawesome/pro-light-svg-icons';
import { faSortAmountDesc as farSortAmountDesc }   from '@fortawesome/pro-regular-svg-icons';
import { faSortAmountDesc as fasSortAmountDesc }   from '@fortawesome/pro-solid-svg-icons';
import { faSortAmountDown as fadSortAmountDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortAmountDown as falSortAmountDown }   from '@fortawesome/pro-light-svg-icons';
import { faSortAmountDown as farSortAmountDown }   from '@fortawesome/pro-regular-svg-icons';
import { faSortAmountDown as fasSortAmountDown }   from '@fortawesome/pro-solid-svg-icons';
import { faSortAmountDownAlt as fadSortAmountDownAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortAmountDownAlt as falSortAmountDownAlt }   from '@fortawesome/pro-light-svg-icons';
import { faSortAmountDownAlt as farSortAmountDownAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faSortAmountDownAlt as fasSortAmountDownAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faSortAmountUp as fadSortAmountUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortAmountUp as falSortAmountUp }   from '@fortawesome/pro-light-svg-icons';
import { faSortAmountUp as farSortAmountUp }   from '@fortawesome/pro-regular-svg-icons';
import { faSortAmountUp as fasSortAmountUp }   from '@fortawesome/pro-solid-svg-icons';
import { faSortAmountUpAlt as fadSortAmountUpAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortAmountUpAlt as falSortAmountUpAlt }   from '@fortawesome/pro-light-svg-icons';
import { faSortAmountUpAlt as farSortAmountUpAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faSortAmountUpAlt as fasSortAmountUpAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faSortAsc as fadSortAsc }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortAsc as falSortAsc }   from '@fortawesome/pro-light-svg-icons';
import { faSortAsc as farSortAsc }   from '@fortawesome/pro-regular-svg-icons';
import { faSortAsc as fasSortAsc }   from '@fortawesome/pro-solid-svg-icons';
import { faSortCircle as fadSortCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortCircle as falSortCircle }   from '@fortawesome/pro-light-svg-icons';
import { faSortCircle as farSortCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faSortCircle as fasSortCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faSortCircleDown as fadSortCircleDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortCircleDown as falSortCircleDown }   from '@fortawesome/pro-light-svg-icons';
import { faSortCircleDown as farSortCircleDown }   from '@fortawesome/pro-regular-svg-icons';
import { faSortCircleDown as fasSortCircleDown }   from '@fortawesome/pro-solid-svg-icons';
import { faSortCircleUp as fadSortCircleUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortCircleUp as falSortCircleUp }   from '@fortawesome/pro-light-svg-icons';
import { faSortCircleUp as farSortCircleUp }   from '@fortawesome/pro-regular-svg-icons';
import { faSortCircleUp as fasSortCircleUp }   from '@fortawesome/pro-solid-svg-icons';
import { faSortDesc as fadSortDesc }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortDesc as falSortDesc }   from '@fortawesome/pro-light-svg-icons';
import { faSortDesc as farSortDesc }   from '@fortawesome/pro-regular-svg-icons';
import { faSortDesc as fasSortDesc }   from '@fortawesome/pro-solid-svg-icons';
import { faSortDown as fadSortDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortDown as falSortDown }   from '@fortawesome/pro-light-svg-icons';
import { faSortDown as farSortDown }   from '@fortawesome/pro-regular-svg-icons';
import { faSortDown as fasSortDown }   from '@fortawesome/pro-solid-svg-icons';
import { faSortNumericAsc as fadSortNumericAsc }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortNumericAsc as falSortNumericAsc }   from '@fortawesome/pro-light-svg-icons';
import { faSortNumericAsc as farSortNumericAsc }   from '@fortawesome/pro-regular-svg-icons';
import { faSortNumericAsc as fasSortNumericAsc }   from '@fortawesome/pro-solid-svg-icons';
import { faSortNumericDesc as fadSortNumericDesc }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortNumericDesc as falSortNumericDesc }   from '@fortawesome/pro-light-svg-icons';
import { faSortNumericDesc as farSortNumericDesc }   from '@fortawesome/pro-regular-svg-icons';
import { faSortNumericDesc as fasSortNumericDesc }   from '@fortawesome/pro-solid-svg-icons';
import { faSortNumericDown as fadSortNumericDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortNumericDown as falSortNumericDown }   from '@fortawesome/pro-light-svg-icons';
import { faSortNumericDown as farSortNumericDown }   from '@fortawesome/pro-regular-svg-icons';
import { faSortNumericDown as fasSortNumericDown }   from '@fortawesome/pro-solid-svg-icons';
import { faSortNumericDownAlt as fadSortNumericDownAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortNumericDownAlt as falSortNumericDownAlt }   from '@fortawesome/pro-light-svg-icons';
import { faSortNumericDownAlt as farSortNumericDownAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faSortNumericDownAlt as fasSortNumericDownAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faSortNumericUp as fadSortNumericUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortNumericUp as falSortNumericUp }   from '@fortawesome/pro-light-svg-icons';
import { faSortNumericUp as farSortNumericUp }   from '@fortawesome/pro-regular-svg-icons';
import { faSortNumericUp as fasSortNumericUp }   from '@fortawesome/pro-solid-svg-icons';
import { faSortNumericUpAlt as fadSortNumericUpAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortNumericUpAlt as falSortNumericUpAlt }   from '@fortawesome/pro-light-svg-icons';
import { faSortNumericUpAlt as farSortNumericUpAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faSortNumericUpAlt as fasSortNumericUpAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faSortShapesDown as fadSortShapesDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortShapesDown as falSortShapesDown }   from '@fortawesome/pro-light-svg-icons';
import { faSortShapesDown as farSortShapesDown }   from '@fortawesome/pro-regular-svg-icons';
import { faSortShapesDown as fasSortShapesDown }   from '@fortawesome/pro-solid-svg-icons';
import { faSortShapesDownAlt as fadSortShapesDownAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortShapesDownAlt as falSortShapesDownAlt }   from '@fortawesome/pro-light-svg-icons';
import { faSortShapesDownAlt as farSortShapesDownAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faSortShapesDownAlt as fasSortShapesDownAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faSortShapesUp as fadSortShapesUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortShapesUp as falSortShapesUp }   from '@fortawesome/pro-light-svg-icons';
import { faSortShapesUp as farSortShapesUp }   from '@fortawesome/pro-regular-svg-icons';
import { faSortShapesUp as fasSortShapesUp }   from '@fortawesome/pro-solid-svg-icons';
import { faSortShapesUpAlt as fadSortShapesUpAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortShapesUpAlt as falSortShapesUpAlt }   from '@fortawesome/pro-light-svg-icons';
import { faSortShapesUpAlt as farSortShapesUpAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faSortShapesUpAlt as fasSortShapesUpAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faSortSizeDown as fadSortSizeDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortSizeDown as falSortSizeDown }   from '@fortawesome/pro-light-svg-icons';
import { faSortSizeDown as farSortSizeDown }   from '@fortawesome/pro-regular-svg-icons';
import { faSortSizeDown as fasSortSizeDown }   from '@fortawesome/pro-solid-svg-icons';
import { faSortSizeDownAlt as fadSortSizeDownAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortSizeDownAlt as falSortSizeDownAlt }   from '@fortawesome/pro-light-svg-icons';
import { faSortSizeDownAlt as farSortSizeDownAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faSortSizeDownAlt as fasSortSizeDownAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faSortSizeUp as fadSortSizeUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortSizeUp as falSortSizeUp }   from '@fortawesome/pro-light-svg-icons';
import { faSortSizeUp as farSortSizeUp }   from '@fortawesome/pro-regular-svg-icons';
import { faSortSizeUp as fasSortSizeUp }   from '@fortawesome/pro-solid-svg-icons';
import { faSortSizeUpAlt as fadSortSizeUpAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortSizeUpAlt as falSortSizeUpAlt }   from '@fortawesome/pro-light-svg-icons';
import { faSortSizeUpAlt as farSortSizeUpAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faSortSizeUpAlt as fasSortSizeUpAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faSortUp as fadSortUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortUp as falSortUp }   from '@fortawesome/pro-light-svg-icons';
import { faSortUp as farSortUp }   from '@fortawesome/pro-regular-svg-icons';
import { faSortUp as fasSortUp }   from '@fortawesome/pro-solid-svg-icons';
import { faSortUpDown as fadSortUpDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faSortUpDown as falSortUpDown }   from '@fortawesome/pro-light-svg-icons';
import { faSortUpDown as farSortUpDown }   from '@fortawesome/pro-regular-svg-icons';
import { faSortUpDown as fasSortUpDown }   from '@fortawesome/pro-solid-svg-icons';
import { faSoup as fadSoup }   from '@fortawesome/pro-duotone-svg-icons';
import { faSoup as falSoup }   from '@fortawesome/pro-light-svg-icons';
import { faSoup as farSoup }   from '@fortawesome/pro-regular-svg-icons';
import { faSoup as fasSoup }   from '@fortawesome/pro-solid-svg-icons';
import { faSpa as fadSpa }   from '@fortawesome/pro-duotone-svg-icons';
import { faSpa as falSpa }   from '@fortawesome/pro-light-svg-icons';
import { faSpa as farSpa }   from '@fortawesome/pro-regular-svg-icons';
import { faSpa as fasSpa }   from '@fortawesome/pro-solid-svg-icons';
import { faSpaceShuttle as fadSpaceShuttle }   from '@fortawesome/pro-duotone-svg-icons';
import { faSpaceShuttle as falSpaceShuttle }   from '@fortawesome/pro-light-svg-icons';
import { faSpaceShuttle as farSpaceShuttle }   from '@fortawesome/pro-regular-svg-icons';
import { faSpaceShuttle as fasSpaceShuttle }   from '@fortawesome/pro-solid-svg-icons';
import { faSpaceStationMoon as fadSpaceStationMoon }   from '@fortawesome/pro-duotone-svg-icons';
import { faSpaceStationMoon as falSpaceStationMoon }   from '@fortawesome/pro-light-svg-icons';
import { faSpaceStationMoon as farSpaceStationMoon }   from '@fortawesome/pro-regular-svg-icons';
import { faSpaceStationMoon as fasSpaceStationMoon }   from '@fortawesome/pro-solid-svg-icons';
import { faSpaceStationMoonAlt as fadSpaceStationMoonAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faSpaceStationMoonAlt as falSpaceStationMoonAlt }   from '@fortawesome/pro-light-svg-icons';
import { faSpaceStationMoonAlt as farSpaceStationMoonAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faSpaceStationMoonAlt as fasSpaceStationMoonAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faSpaceStationMoonConstruction as fadSpaceStationMoonConstruction }   from '@fortawesome/pro-duotone-svg-icons';
import { faSpaceStationMoonConstruction as falSpaceStationMoonConstruction }   from '@fortawesome/pro-light-svg-icons';
import { faSpaceStationMoonConstruction as farSpaceStationMoonConstruction }   from '@fortawesome/pro-regular-svg-icons';
import { faSpaceStationMoonConstruction as fasSpaceStationMoonConstruction }   from '@fortawesome/pro-solid-svg-icons';
import { faSpade as fadSpade }   from '@fortawesome/pro-duotone-svg-icons';
import { faSpade as falSpade }   from '@fortawesome/pro-light-svg-icons';
import { faSpade as farSpade }   from '@fortawesome/pro-regular-svg-icons';
import { faSpade as fasSpade }   from '@fortawesome/pro-solid-svg-icons';
import { faSpaghettiMonsterFlying as fadSpaghettiMonsterFlying }   from '@fortawesome/pro-duotone-svg-icons';
import { faSpaghettiMonsterFlying as falSpaghettiMonsterFlying }   from '@fortawesome/pro-light-svg-icons';
import { faSpaghettiMonsterFlying as farSpaghettiMonsterFlying }   from '@fortawesome/pro-regular-svg-icons';
import { faSpaghettiMonsterFlying as fasSpaghettiMonsterFlying }   from '@fortawesome/pro-solid-svg-icons';
import { faSparkles as fadSparkles }   from '@fortawesome/pro-duotone-svg-icons';
import { faSparkles as falSparkles }   from '@fortawesome/pro-light-svg-icons';
import { faSparkles as farSparkles }   from '@fortawesome/pro-regular-svg-icons';
import { faSparkles as fasSparkles }   from '@fortawesome/pro-solid-svg-icons';
import { faSpeaker as fadSpeaker }   from '@fortawesome/pro-duotone-svg-icons';
import { faSpeaker as falSpeaker }   from '@fortawesome/pro-light-svg-icons';
import { faSpeaker as farSpeaker }   from '@fortawesome/pro-regular-svg-icons';
import { faSpeaker as fasSpeaker }   from '@fortawesome/pro-solid-svg-icons';
import { faSpeakers as fadSpeakers }   from '@fortawesome/pro-duotone-svg-icons';
import { faSpeakers as falSpeakers }   from '@fortawesome/pro-light-svg-icons';
import { faSpeakers as farSpeakers }   from '@fortawesome/pro-regular-svg-icons';
import { faSpeakers as fasSpeakers }   from '@fortawesome/pro-solid-svg-icons';
import { faSpellCheck as fadSpellCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faSpellCheck as falSpellCheck }   from '@fortawesome/pro-light-svg-icons';
import { faSpellCheck as farSpellCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faSpellCheck as fasSpellCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faSpider as fadSpider }   from '@fortawesome/pro-duotone-svg-icons';
import { faSpider as falSpider }   from '@fortawesome/pro-light-svg-icons';
import { faSpider as farSpider }   from '@fortawesome/pro-regular-svg-icons';
import { faSpider as fasSpider }   from '@fortawesome/pro-solid-svg-icons';
import { faSpiderBlackWidow as fadSpiderBlackWidow }   from '@fortawesome/pro-duotone-svg-icons';
import { faSpiderBlackWidow as falSpiderBlackWidow }   from '@fortawesome/pro-light-svg-icons';
import { faSpiderBlackWidow as farSpiderBlackWidow }   from '@fortawesome/pro-regular-svg-icons';
import { faSpiderBlackWidow as fasSpiderBlackWidow }   from '@fortawesome/pro-solid-svg-icons';
import { faSpiderWeb as fadSpiderWeb }   from '@fortawesome/pro-duotone-svg-icons';
import { faSpiderWeb as falSpiderWeb }   from '@fortawesome/pro-light-svg-icons';
import { faSpiderWeb as farSpiderWeb }   from '@fortawesome/pro-regular-svg-icons';
import { faSpiderWeb as fasSpiderWeb }   from '@fortawesome/pro-solid-svg-icons';
import { faSpinner as fadSpinner }   from '@fortawesome/pro-duotone-svg-icons';
import { faSpinner as falSpinner }   from '@fortawesome/pro-light-svg-icons';
import { faSpinner as farSpinner }   from '@fortawesome/pro-regular-svg-icons';
import { faSpinner as fasSpinner }   from '@fortawesome/pro-solid-svg-icons';
import { faSpinnerThird as fadSpinnerThird }   from '@fortawesome/pro-duotone-svg-icons';
import { faSpinnerThird as falSpinnerThird }   from '@fortawesome/pro-light-svg-icons';
import { faSpinnerThird as farSpinnerThird }   from '@fortawesome/pro-regular-svg-icons';
import { faSpinnerThird as fasSpinnerThird }   from '@fortawesome/pro-solid-svg-icons';
import { faSplit as fadSplit }   from '@fortawesome/pro-duotone-svg-icons';
import { faSplit as falSplit }   from '@fortawesome/pro-light-svg-icons';
import { faSplit as farSplit }   from '@fortawesome/pro-regular-svg-icons';
import { faSplit as fasSplit }   from '@fortawesome/pro-solid-svg-icons';
import { faSplotch as fadSplotch }   from '@fortawesome/pro-duotone-svg-icons';
import { faSplotch as falSplotch }   from '@fortawesome/pro-light-svg-icons';
import { faSplotch as farSplotch }   from '@fortawesome/pro-regular-svg-icons';
import { faSplotch as fasSplotch }   from '@fortawesome/pro-solid-svg-icons';
import { faSpoon as fadSpoon }   from '@fortawesome/pro-duotone-svg-icons';
import { faSpoon as falSpoon }   from '@fortawesome/pro-light-svg-icons';
import { faSpoon as farSpoon }   from '@fortawesome/pro-regular-svg-icons';
import { faSpoon as fasSpoon }   from '@fortawesome/pro-solid-svg-icons';
import { faSportsball as fadSportsball }   from '@fortawesome/pro-duotone-svg-icons';
import { faSportsball as falSportsball }   from '@fortawesome/pro-light-svg-icons';
import { faSportsball as farSportsball }   from '@fortawesome/pro-regular-svg-icons';
import { faSportsball as fasSportsball }   from '@fortawesome/pro-solid-svg-icons';
import { faSprayCan as fadSprayCan }   from '@fortawesome/pro-duotone-svg-icons';
import { faSprayCan as falSprayCan }   from '@fortawesome/pro-light-svg-icons';
import { faSprayCan as farSprayCan }   from '@fortawesome/pro-regular-svg-icons';
import { faSprayCan as fasSprayCan }   from '@fortawesome/pro-solid-svg-icons';
import { faSprayCanSparkles as fadSprayCanSparkles }   from '@fortawesome/pro-duotone-svg-icons';
import { faSprayCanSparkles as falSprayCanSparkles }   from '@fortawesome/pro-light-svg-icons';
import { faSprayCanSparkles as farSprayCanSparkles }   from '@fortawesome/pro-regular-svg-icons';
import { faSprayCanSparkles as fasSprayCanSparkles }   from '@fortawesome/pro-solid-svg-icons';
import { faSprinkler as fadSprinkler }   from '@fortawesome/pro-duotone-svg-icons';
import { faSprinkler as falSprinkler }   from '@fortawesome/pro-light-svg-icons';
import { faSprinkler as farSprinkler }   from '@fortawesome/pro-regular-svg-icons';
import { faSprinkler as fasSprinkler }   from '@fortawesome/pro-solid-svg-icons';
import { faSprinklerCeiling as fadSprinklerCeiling }   from '@fortawesome/pro-duotone-svg-icons';
import { faSprinklerCeiling as falSprinklerCeiling }   from '@fortawesome/pro-light-svg-icons';
import { faSprinklerCeiling as farSprinklerCeiling }   from '@fortawesome/pro-regular-svg-icons';
import { faSprinklerCeiling as fasSprinklerCeiling }   from '@fortawesome/pro-solid-svg-icons';
import { faSprout as fadSprout }   from '@fortawesome/pro-duotone-svg-icons';
import { faSprout as falSprout }   from '@fortawesome/pro-light-svg-icons';
import { faSprout as farSprout }   from '@fortawesome/pro-regular-svg-icons';
import { faSprout as fasSprout }   from '@fortawesome/pro-solid-svg-icons';
import { faSquare as fadSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquare as falSquare }   from '@fortawesome/pro-light-svg-icons';
import { faSquare as farSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faSquare as fasSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faSquare0 as fadSquare0 }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquare0 as falSquare0 }   from '@fortawesome/pro-light-svg-icons';
import { faSquare0 as farSquare0 }   from '@fortawesome/pro-regular-svg-icons';
import { faSquare0 as fasSquare0 }   from '@fortawesome/pro-solid-svg-icons';
import { faSquare1 as fadSquare1 }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquare1 as falSquare1 }   from '@fortawesome/pro-light-svg-icons';
import { faSquare1 as farSquare1 }   from '@fortawesome/pro-regular-svg-icons';
import { faSquare1 as fasSquare1 }   from '@fortawesome/pro-solid-svg-icons';
import { faSquare2 as fadSquare2 }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquare2 as falSquare2 }   from '@fortawesome/pro-light-svg-icons';
import { faSquare2 as farSquare2 }   from '@fortawesome/pro-regular-svg-icons';
import { faSquare2 as fasSquare2 }   from '@fortawesome/pro-solid-svg-icons';
import { faSquare3 as fadSquare3 }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquare3 as falSquare3 }   from '@fortawesome/pro-light-svg-icons';
import { faSquare3 as farSquare3 }   from '@fortawesome/pro-regular-svg-icons';
import { faSquare3 as fasSquare3 }   from '@fortawesome/pro-solid-svg-icons';
import { faSquare4 as fadSquare4 }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquare4 as falSquare4 }   from '@fortawesome/pro-light-svg-icons';
import { faSquare4 as farSquare4 }   from '@fortawesome/pro-regular-svg-icons';
import { faSquare4 as fasSquare4 }   from '@fortawesome/pro-solid-svg-icons';
import { faSquare5 as fadSquare5 }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquare5 as falSquare5 }   from '@fortawesome/pro-light-svg-icons';
import { faSquare5 as farSquare5 }   from '@fortawesome/pro-regular-svg-icons';
import { faSquare5 as fasSquare5 }   from '@fortawesome/pro-solid-svg-icons';
import { faSquare6 as fadSquare6 }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquare6 as falSquare6 }   from '@fortawesome/pro-light-svg-icons';
import { faSquare6 as farSquare6 }   from '@fortawesome/pro-regular-svg-icons';
import { faSquare6 as fasSquare6 }   from '@fortawesome/pro-solid-svg-icons';
import { faSquare7 as fadSquare7 }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquare7 as falSquare7 }   from '@fortawesome/pro-light-svg-icons';
import { faSquare7 as farSquare7 }   from '@fortawesome/pro-regular-svg-icons';
import { faSquare7 as fasSquare7 }   from '@fortawesome/pro-solid-svg-icons';
import { faSquare8 as fadSquare8 }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquare8 as falSquare8 }   from '@fortawesome/pro-light-svg-icons';
import { faSquare8 as farSquare8 }   from '@fortawesome/pro-regular-svg-icons';
import { faSquare8 as fasSquare8 }   from '@fortawesome/pro-solid-svg-icons';
import { faSquare9 as fadSquare9 }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquare9 as falSquare9 }   from '@fortawesome/pro-light-svg-icons';
import { faSquare9 as farSquare9 }   from '@fortawesome/pro-regular-svg-icons';
import { faSquare9 as fasSquare9 }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareA as fadSquareA }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareA as falSquareA }   from '@fortawesome/pro-light-svg-icons';
import { faSquareA as farSquareA }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareA as fasSquareA }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareALock as fadSquareALock }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareALock as falSquareALock }   from '@fortawesome/pro-light-svg-icons';
import { faSquareALock as farSquareALock }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareALock as fasSquareALock }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareAmpersand as fadSquareAmpersand }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareAmpersand as falSquareAmpersand }   from '@fortawesome/pro-light-svg-icons';
import { faSquareAmpersand as farSquareAmpersand }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareAmpersand as fasSquareAmpersand }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareArrowDown as fadSquareArrowDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareArrowDown as falSquareArrowDown }   from '@fortawesome/pro-light-svg-icons';
import { faSquareArrowDown as farSquareArrowDown }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareArrowDown as fasSquareArrowDown }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareArrowDownLeft as fadSquareArrowDownLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareArrowDownLeft as falSquareArrowDownLeft }   from '@fortawesome/pro-light-svg-icons';
import { faSquareArrowDownLeft as farSquareArrowDownLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareArrowDownLeft as fasSquareArrowDownLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareArrowDownRight as fadSquareArrowDownRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareArrowDownRight as falSquareArrowDownRight }   from '@fortawesome/pro-light-svg-icons';
import { faSquareArrowDownRight as farSquareArrowDownRight }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareArrowDownRight as fasSquareArrowDownRight }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareArrowLeft as fadSquareArrowLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareArrowLeft as falSquareArrowLeft }   from '@fortawesome/pro-light-svg-icons';
import { faSquareArrowLeft as farSquareArrowLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareArrowLeft as fasSquareArrowLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareArrowRight as fadSquareArrowRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareArrowRight as falSquareArrowRight }   from '@fortawesome/pro-light-svg-icons';
import { faSquareArrowRight as farSquareArrowRight }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareArrowRight as fasSquareArrowRight }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareArrowUp as fadSquareArrowUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareArrowUp as falSquareArrowUp }   from '@fortawesome/pro-light-svg-icons';
import { faSquareArrowUp as farSquareArrowUp }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareArrowUp as fasSquareArrowUp }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareArrowUpLeft as fadSquareArrowUpLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareArrowUpLeft as falSquareArrowUpLeft }   from '@fortawesome/pro-light-svg-icons';
import { faSquareArrowUpLeft as farSquareArrowUpLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareArrowUpLeft as fasSquareArrowUpLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareArrowUpRight as fadSquareArrowUpRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareArrowUpRight as falSquareArrowUpRight }   from '@fortawesome/pro-light-svg-icons';
import { faSquareArrowUpRight as farSquareArrowUpRight }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareArrowUpRight as fasSquareArrowUpRight }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareB as fadSquareB }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareB as falSquareB }   from '@fortawesome/pro-light-svg-icons';
import { faSquareB as farSquareB }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareB as fasSquareB }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareBolt as fadSquareBolt }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareBolt as falSquareBolt }   from '@fortawesome/pro-light-svg-icons';
import { faSquareBolt as farSquareBolt }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareBolt as fasSquareBolt }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareC as fadSquareC }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareC as falSquareC }   from '@fortawesome/pro-light-svg-icons';
import { faSquareC as farSquareC }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareC as fasSquareC }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareCaretDown as fadSquareCaretDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareCaretDown as falSquareCaretDown }   from '@fortawesome/pro-light-svg-icons';
import { faSquareCaretDown as farSquareCaretDown }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareCaretDown as fasSquareCaretDown }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareCaretLeft as fadSquareCaretLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareCaretLeft as falSquareCaretLeft }   from '@fortawesome/pro-light-svg-icons';
import { faSquareCaretLeft as farSquareCaretLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareCaretLeft as fasSquareCaretLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareCaretRight as fadSquareCaretRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareCaretRight as falSquareCaretRight }   from '@fortawesome/pro-light-svg-icons';
import { faSquareCaretRight as farSquareCaretRight }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareCaretRight as fasSquareCaretRight }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareCaretUp as fadSquareCaretUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareCaretUp as falSquareCaretUp }   from '@fortawesome/pro-light-svg-icons';
import { faSquareCaretUp as farSquareCaretUp }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareCaretUp as fasSquareCaretUp }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareCheck as fadSquareCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareCheck as falSquareCheck }   from '@fortawesome/pro-light-svg-icons';
import { faSquareCheck as farSquareCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareCheck as fasSquareCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareChevronDown as fadSquareChevronDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareChevronDown as falSquareChevronDown }   from '@fortawesome/pro-light-svg-icons';
import { faSquareChevronDown as farSquareChevronDown }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareChevronDown as fasSquareChevronDown }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareChevronLeft as fadSquareChevronLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareChevronLeft as falSquareChevronLeft }   from '@fortawesome/pro-light-svg-icons';
import { faSquareChevronLeft as farSquareChevronLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareChevronLeft as fasSquareChevronLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareChevronRight as fadSquareChevronRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareChevronRight as falSquareChevronRight }   from '@fortawesome/pro-light-svg-icons';
import { faSquareChevronRight as farSquareChevronRight }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareChevronRight as fasSquareChevronRight }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareChevronUp as fadSquareChevronUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareChevronUp as falSquareChevronUp }   from '@fortawesome/pro-light-svg-icons';
import { faSquareChevronUp as farSquareChevronUp }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareChevronUp as fasSquareChevronUp }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareCode as fadSquareCode }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareCode as falSquareCode }   from '@fortawesome/pro-light-svg-icons';
import { faSquareCode as farSquareCode }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareCode as fasSquareCode }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareD as fadSquareD }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareD as falSquareD }   from '@fortawesome/pro-light-svg-icons';
import { faSquareD as farSquareD }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareD as fasSquareD }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareDashed as fadSquareDashed }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareDashed as falSquareDashed }   from '@fortawesome/pro-light-svg-icons';
import { faSquareDashed as farSquareDashed }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareDashed as fasSquareDashed }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareDivide as fadSquareDivide }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareDivide as falSquareDivide }   from '@fortawesome/pro-light-svg-icons';
import { faSquareDivide as farSquareDivide }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareDivide as fasSquareDivide }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareDollar as fadSquareDollar }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareDollar as falSquareDollar }   from '@fortawesome/pro-light-svg-icons';
import { faSquareDollar as farSquareDollar }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareDollar as fasSquareDollar }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareDown as fadSquareDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareDown as falSquareDown }   from '@fortawesome/pro-light-svg-icons';
import { faSquareDown as farSquareDown }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareDown as fasSquareDown }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareDownLeft as fadSquareDownLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareDownLeft as falSquareDownLeft }   from '@fortawesome/pro-light-svg-icons';
import { faSquareDownLeft as farSquareDownLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareDownLeft as fasSquareDownLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareDownRight as fadSquareDownRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareDownRight as falSquareDownRight }   from '@fortawesome/pro-light-svg-icons';
import { faSquareDownRight as farSquareDownRight }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareDownRight as fasSquareDownRight }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareE as fadSquareE }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareE as falSquareE }   from '@fortawesome/pro-light-svg-icons';
import { faSquareE as farSquareE }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareE as fasSquareE }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareEllipsis as fadSquareEllipsis }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareEllipsis as falSquareEllipsis }   from '@fortawesome/pro-light-svg-icons';
import { faSquareEllipsis as farSquareEllipsis }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareEllipsis as fasSquareEllipsis }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareEllipsisVertical as fadSquareEllipsisVertical }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareEllipsisVertical as falSquareEllipsisVertical }   from '@fortawesome/pro-light-svg-icons';
import { faSquareEllipsisVertical as farSquareEllipsisVertical }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareEllipsisVertical as fasSquareEllipsisVertical }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareEnvelope as fadSquareEnvelope }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareEnvelope as falSquareEnvelope }   from '@fortawesome/pro-light-svg-icons';
import { faSquareEnvelope as farSquareEnvelope }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareEnvelope as fasSquareEnvelope }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareExclamation as fadSquareExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareExclamation as falSquareExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faSquareExclamation as farSquareExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareExclamation as fasSquareExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareF as fadSquareF }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareF as falSquareF }   from '@fortawesome/pro-light-svg-icons';
import { faSquareF as farSquareF }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareF as fasSquareF }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareFragile as fadSquareFragile }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareFragile as falSquareFragile }   from '@fortawesome/pro-light-svg-icons';
import { faSquareFragile as farSquareFragile }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareFragile as fasSquareFragile }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareFull as fadSquareFull }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareFull as falSquareFull }   from '@fortawesome/pro-light-svg-icons';
import { faSquareFull as farSquareFull }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareFull as fasSquareFull }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareG as fadSquareG }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareG as falSquareG }   from '@fortawesome/pro-light-svg-icons';
import { faSquareG as farSquareG }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareG as fasSquareG }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareH as fadSquareH }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareH as falSquareH }   from '@fortawesome/pro-light-svg-icons';
import { faSquareH as farSquareH }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareH as fasSquareH }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareHeart as fadSquareHeart }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareHeart as falSquareHeart }   from '@fortawesome/pro-light-svg-icons';
import { faSquareHeart as farSquareHeart }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareHeart as fasSquareHeart }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareI as fadSquareI }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareI as falSquareI }   from '@fortawesome/pro-light-svg-icons';
import { faSquareI as farSquareI }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareI as fasSquareI }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareInfo as fadSquareInfo }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareInfo as falSquareInfo }   from '@fortawesome/pro-light-svg-icons';
import { faSquareInfo as farSquareInfo }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareInfo as fasSquareInfo }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareJ as fadSquareJ }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareJ as falSquareJ }   from '@fortawesome/pro-light-svg-icons';
import { faSquareJ as farSquareJ }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareJ as fasSquareJ }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareK as fadSquareK }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareK as falSquareK }   from '@fortawesome/pro-light-svg-icons';
import { faSquareK as farSquareK }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareK as fasSquareK }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareKanban as fadSquareKanban }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareKanban as falSquareKanban }   from '@fortawesome/pro-light-svg-icons';
import { faSquareKanban as farSquareKanban }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareKanban as fasSquareKanban }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareL as fadSquareL }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareL as falSquareL }   from '@fortawesome/pro-light-svg-icons';
import { faSquareL as farSquareL }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareL as fasSquareL }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareLeft as fadSquareLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareLeft as falSquareLeft }   from '@fortawesome/pro-light-svg-icons';
import { faSquareLeft as farSquareLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareLeft as fasSquareLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareList as fadSquareList }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareList as falSquareList }   from '@fortawesome/pro-light-svg-icons';
import { faSquareList as farSquareList }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareList as fasSquareList }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareM as fadSquareM }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareM as falSquareM }   from '@fortawesome/pro-light-svg-icons';
import { faSquareM as farSquareM }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareM as fasSquareM }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareMinus as fadSquareMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareMinus as falSquareMinus }   from '@fortawesome/pro-light-svg-icons';
import { faSquareMinus as farSquareMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareMinus as fasSquareMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareN as fadSquareN }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareN as falSquareN }   from '@fortawesome/pro-light-svg-icons';
import { faSquareN as farSquareN }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareN as fasSquareN }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareNfi as fadSquareNfi }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareNfi as falSquareNfi }   from '@fortawesome/pro-light-svg-icons';
import { faSquareNfi as farSquareNfi }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareNfi as fasSquareNfi }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareO as fadSquareO }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareO as falSquareO }   from '@fortawesome/pro-light-svg-icons';
import { faSquareO as farSquareO }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareO as fasSquareO }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareP as fadSquareP }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareP as falSquareP }   from '@fortawesome/pro-light-svg-icons';
import { faSquareP as farSquareP }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareP as fasSquareP }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareParking as fadSquareParking }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareParking as falSquareParking }   from '@fortawesome/pro-light-svg-icons';
import { faSquareParking as farSquareParking }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareParking as fasSquareParking }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareParkingSlash as fadSquareParkingSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareParkingSlash as falSquareParkingSlash }   from '@fortawesome/pro-light-svg-icons';
import { faSquareParkingSlash as farSquareParkingSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareParkingSlash as fasSquareParkingSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faSquarePen as fadSquarePen }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquarePen as falSquarePen }   from '@fortawesome/pro-light-svg-icons';
import { faSquarePen as farSquarePen }   from '@fortawesome/pro-regular-svg-icons';
import { faSquarePen as fasSquarePen }   from '@fortawesome/pro-solid-svg-icons';
import { faSquarePersonConfined as fadSquarePersonConfined }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquarePersonConfined as falSquarePersonConfined }   from '@fortawesome/pro-light-svg-icons';
import { faSquarePersonConfined as farSquarePersonConfined }   from '@fortawesome/pro-regular-svg-icons';
import { faSquarePersonConfined as fasSquarePersonConfined }   from '@fortawesome/pro-solid-svg-icons';
import { faSquarePhone as fadSquarePhone }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquarePhone as falSquarePhone }   from '@fortawesome/pro-light-svg-icons';
import { faSquarePhone as farSquarePhone }   from '@fortawesome/pro-regular-svg-icons';
import { faSquarePhone as fasSquarePhone }   from '@fortawesome/pro-solid-svg-icons';
import { faSquarePhoneFlip as fadSquarePhoneFlip }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquarePhoneFlip as falSquarePhoneFlip }   from '@fortawesome/pro-light-svg-icons';
import { faSquarePhoneFlip as farSquarePhoneFlip }   from '@fortawesome/pro-regular-svg-icons';
import { faSquarePhoneFlip as fasSquarePhoneFlip }   from '@fortawesome/pro-solid-svg-icons';
import { faSquarePhoneHangup as fadSquarePhoneHangup }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquarePhoneHangup as falSquarePhoneHangup }   from '@fortawesome/pro-light-svg-icons';
import { faSquarePhoneHangup as farSquarePhoneHangup }   from '@fortawesome/pro-regular-svg-icons';
import { faSquarePhoneHangup as fasSquarePhoneHangup }   from '@fortawesome/pro-solid-svg-icons';
import { faSquarePlus as fadSquarePlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquarePlus as falSquarePlus }   from '@fortawesome/pro-light-svg-icons';
import { faSquarePlus as farSquarePlus }   from '@fortawesome/pro-regular-svg-icons';
import { faSquarePlus as fasSquarePlus }   from '@fortawesome/pro-solid-svg-icons';
import { faSquarePollHorizontal as fadSquarePollHorizontal }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquarePollHorizontal as falSquarePollHorizontal }   from '@fortawesome/pro-light-svg-icons';
import { faSquarePollHorizontal as farSquarePollHorizontal }   from '@fortawesome/pro-regular-svg-icons';
import { faSquarePollHorizontal as fasSquarePollHorizontal }   from '@fortawesome/pro-solid-svg-icons';
import { faSquarePollVertical as fadSquarePollVertical }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquarePollVertical as falSquarePollVertical }   from '@fortawesome/pro-light-svg-icons';
import { faSquarePollVertical as farSquarePollVertical }   from '@fortawesome/pro-regular-svg-icons';
import { faSquarePollVertical as fasSquarePollVertical }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareQ as fadSquareQ }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareQ as falSquareQ }   from '@fortawesome/pro-light-svg-icons';
import { faSquareQ as farSquareQ }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareQ as fasSquareQ }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareQuarters as fadSquareQuarters }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareQuarters as falSquareQuarters }   from '@fortawesome/pro-light-svg-icons';
import { faSquareQuarters as farSquareQuarters }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareQuarters as fasSquareQuarters }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareQuestion as fadSquareQuestion }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareQuestion as falSquareQuestion }   from '@fortawesome/pro-light-svg-icons';
import { faSquareQuestion as farSquareQuestion }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareQuestion as fasSquareQuestion }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareQuote as fadSquareQuote }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareQuote as falSquareQuote }   from '@fortawesome/pro-light-svg-icons';
import { faSquareQuote as farSquareQuote }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareQuote as fasSquareQuote }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareR as fadSquareR }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareR as falSquareR }   from '@fortawesome/pro-light-svg-icons';
import { faSquareR as farSquareR }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareR as fasSquareR }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareRight as fadSquareRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareRight as falSquareRight }   from '@fortawesome/pro-light-svg-icons';
import { faSquareRight as farSquareRight }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareRight as fasSquareRight }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareRing as fadSquareRing }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareRing as falSquareRing }   from '@fortawesome/pro-light-svg-icons';
import { faSquareRing as farSquareRing }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareRing as fasSquareRing }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareRoot as fadSquareRoot }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareRoot as falSquareRoot }   from '@fortawesome/pro-light-svg-icons';
import { faSquareRoot as farSquareRoot }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareRoot as fasSquareRoot }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareRootAlt as fadSquareRootAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareRootAlt as falSquareRootAlt }   from '@fortawesome/pro-light-svg-icons';
import { faSquareRootAlt as farSquareRootAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareRootAlt as fasSquareRootAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareRootVariable as fadSquareRootVariable }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareRootVariable as falSquareRootVariable }   from '@fortawesome/pro-light-svg-icons';
import { faSquareRootVariable as farSquareRootVariable }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareRootVariable as fasSquareRootVariable }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareRss as fadSquareRss }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareRss as falSquareRss }   from '@fortawesome/pro-light-svg-icons';
import { faSquareRss as farSquareRss }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareRss as fasSquareRss }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareS as fadSquareS }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareS as falSquareS }   from '@fortawesome/pro-light-svg-icons';
import { faSquareS as farSquareS }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareS as fasSquareS }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareShareNodes as fadSquareShareNodes }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareShareNodes as falSquareShareNodes }   from '@fortawesome/pro-light-svg-icons';
import { faSquareShareNodes as farSquareShareNodes }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareShareNodes as fasSquareShareNodes }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareSliders as fadSquareSliders }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareSliders as falSquareSliders }   from '@fortawesome/pro-light-svg-icons';
import { faSquareSliders as farSquareSliders }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareSliders as fasSquareSliders }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareSlidersVertical as fadSquareSlidersVertical }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareSlidersVertical as falSquareSlidersVertical }   from '@fortawesome/pro-light-svg-icons';
import { faSquareSlidersVertical as farSquareSlidersVertical }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareSlidersVertical as fasSquareSlidersVertical }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareSmall as fadSquareSmall }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareSmall as falSquareSmall }   from '@fortawesome/pro-light-svg-icons';
import { faSquareSmall as farSquareSmall }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareSmall as fasSquareSmall }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareStar as fadSquareStar }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareStar as falSquareStar }   from '@fortawesome/pro-light-svg-icons';
import { faSquareStar as farSquareStar }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareStar as fasSquareStar }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareT as fadSquareT }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareT as falSquareT }   from '@fortawesome/pro-light-svg-icons';
import { faSquareT as farSquareT }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareT as fasSquareT }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareTerminal as fadSquareTerminal }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareTerminal as falSquareTerminal }   from '@fortawesome/pro-light-svg-icons';
import { faSquareTerminal as farSquareTerminal }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareTerminal as fasSquareTerminal }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareThisWayUp as fadSquareThisWayUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareThisWayUp as falSquareThisWayUp }   from '@fortawesome/pro-light-svg-icons';
import { faSquareThisWayUp as farSquareThisWayUp }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareThisWayUp as fasSquareThisWayUp }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareU as fadSquareU }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareU as falSquareU }   from '@fortawesome/pro-light-svg-icons';
import { faSquareU as farSquareU }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareU as fasSquareU }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareUp as fadSquareUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareUp as falSquareUp }   from '@fortawesome/pro-light-svg-icons';
import { faSquareUp as farSquareUp }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareUp as fasSquareUp }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareUpLeft as fadSquareUpLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareUpLeft as falSquareUpLeft }   from '@fortawesome/pro-light-svg-icons';
import { faSquareUpLeft as farSquareUpLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareUpLeft as fasSquareUpLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareUpRight as fadSquareUpRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareUpRight as falSquareUpRight }   from '@fortawesome/pro-light-svg-icons';
import { faSquareUpRight as farSquareUpRight }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareUpRight as fasSquareUpRight }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareUser as fadSquareUser }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareUser as falSquareUser }   from '@fortawesome/pro-light-svg-icons';
import { faSquareUser as farSquareUser }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareUser as fasSquareUser }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareV as fadSquareV }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareV as falSquareV }   from '@fortawesome/pro-light-svg-icons';
import { faSquareV as farSquareV }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareV as fasSquareV }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareVirus as fadSquareVirus }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareVirus as falSquareVirus }   from '@fortawesome/pro-light-svg-icons';
import { faSquareVirus as farSquareVirus }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareVirus as fasSquareVirus }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareW as fadSquareW }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareW as falSquareW }   from '@fortawesome/pro-light-svg-icons';
import { faSquareW as farSquareW }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareW as fasSquareW }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareWineGlassCrack as fadSquareWineGlassCrack }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareWineGlassCrack as falSquareWineGlassCrack }   from '@fortawesome/pro-light-svg-icons';
import { faSquareWineGlassCrack as farSquareWineGlassCrack }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareWineGlassCrack as fasSquareWineGlassCrack }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareX as fadSquareX }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareX as falSquareX }   from '@fortawesome/pro-light-svg-icons';
import { faSquareX as farSquareX }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareX as fasSquareX }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareXmark as fadSquareXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareXmark as falSquareXmark }   from '@fortawesome/pro-light-svg-icons';
import { faSquareXmark as farSquareXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareXmark as fasSquareXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareY as fadSquareY }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareY as falSquareY }   from '@fortawesome/pro-light-svg-icons';
import { faSquareY as farSquareY }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareY as fasSquareY }   from '@fortawesome/pro-solid-svg-icons';
import { faSquareZ as fadSquareZ }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquareZ as falSquareZ }   from '@fortawesome/pro-light-svg-icons';
import { faSquareZ as farSquareZ }   from '@fortawesome/pro-regular-svg-icons';
import { faSquareZ as fasSquareZ }   from '@fortawesome/pro-solid-svg-icons';
import { faSquid as fadSquid }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquid as falSquid }   from '@fortawesome/pro-light-svg-icons';
import { faSquid as farSquid }   from '@fortawesome/pro-regular-svg-icons';
import { faSquid as fasSquid }   from '@fortawesome/pro-solid-svg-icons';
import { faSquirrel as fadSquirrel }   from '@fortawesome/pro-duotone-svg-icons';
import { faSquirrel as falSquirrel }   from '@fortawesome/pro-light-svg-icons';
import { faSquirrel as farSquirrel }   from '@fortawesome/pro-regular-svg-icons';
import { faSquirrel as fasSquirrel }   from '@fortawesome/pro-solid-svg-icons';
import { faStaff as fadStaff }   from '@fortawesome/pro-duotone-svg-icons';
import { faStaff as falStaff }   from '@fortawesome/pro-light-svg-icons';
import { faStaff as farStaff }   from '@fortawesome/pro-regular-svg-icons';
import { faStaff as fasStaff }   from '@fortawesome/pro-solid-svg-icons';
import { faStaffAesculapius as fadStaffAesculapius }   from '@fortawesome/pro-duotone-svg-icons';
import { faStaffAesculapius as falStaffAesculapius }   from '@fortawesome/pro-light-svg-icons';
import { faStaffAesculapius as farStaffAesculapius }   from '@fortawesome/pro-regular-svg-icons';
import { faStaffAesculapius as fasStaffAesculapius }   from '@fortawesome/pro-solid-svg-icons';
import { faStaffSnake as fadStaffSnake }   from '@fortawesome/pro-duotone-svg-icons';
import { faStaffSnake as falStaffSnake }   from '@fortawesome/pro-light-svg-icons';
import { faStaffSnake as farStaffSnake }   from '@fortawesome/pro-regular-svg-icons';
import { faStaffSnake as fasStaffSnake }   from '@fortawesome/pro-solid-svg-icons';
import { faStairs as fadStairs }   from '@fortawesome/pro-duotone-svg-icons';
import { faStairs as falStairs }   from '@fortawesome/pro-light-svg-icons';
import { faStairs as farStairs }   from '@fortawesome/pro-regular-svg-icons';
import { faStairs as fasStairs }   from '@fortawesome/pro-solid-svg-icons';
import { faStamp as fadStamp }   from '@fortawesome/pro-duotone-svg-icons';
import { faStamp as falStamp }   from '@fortawesome/pro-light-svg-icons';
import { faStamp as farStamp }   from '@fortawesome/pro-regular-svg-icons';
import { faStamp as fasStamp }   from '@fortawesome/pro-solid-svg-icons';
import { faStandardDefinition as fadStandardDefinition }   from '@fortawesome/pro-duotone-svg-icons';
import { faStandardDefinition as falStandardDefinition }   from '@fortawesome/pro-light-svg-icons';
import { faStandardDefinition as farStandardDefinition }   from '@fortawesome/pro-regular-svg-icons';
import { faStandardDefinition as fasStandardDefinition }   from '@fortawesome/pro-solid-svg-icons';
import { faStar as fadStar }   from '@fortawesome/pro-duotone-svg-icons';
import { faStar as falStar }   from '@fortawesome/pro-light-svg-icons';
import { faStar as farStar }   from '@fortawesome/pro-regular-svg-icons';
import { faStar as fasStar }   from '@fortawesome/pro-solid-svg-icons';
import { faStarAndCrescent as fadStarAndCrescent }   from '@fortawesome/pro-duotone-svg-icons';
import { faStarAndCrescent as falStarAndCrescent }   from '@fortawesome/pro-light-svg-icons';
import { faStarAndCrescent as farStarAndCrescent }   from '@fortawesome/pro-regular-svg-icons';
import { faStarAndCrescent as fasStarAndCrescent }   from '@fortawesome/pro-solid-svg-icons';
import { faStarChristmas as fadStarChristmas }   from '@fortawesome/pro-duotone-svg-icons';
import { faStarChristmas as falStarChristmas }   from '@fortawesome/pro-light-svg-icons';
import { faStarChristmas as farStarChristmas }   from '@fortawesome/pro-regular-svg-icons';
import { faStarChristmas as fasStarChristmas }   from '@fortawesome/pro-solid-svg-icons';
import { faStarCircle as fadStarCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faStarCircle as falStarCircle }   from '@fortawesome/pro-light-svg-icons';
import { faStarCircle as farStarCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faStarCircle as fasStarCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faStarExclamation as fadStarExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faStarExclamation as falStarExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faStarExclamation as farStarExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faStarExclamation as fasStarExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faStarfighter as fadStarfighter }   from '@fortawesome/pro-duotone-svg-icons';
import { faStarfighter as falStarfighter }   from '@fortawesome/pro-light-svg-icons';
import { faStarfighter as farStarfighter }   from '@fortawesome/pro-regular-svg-icons';
import { faStarfighter as fasStarfighter }   from '@fortawesome/pro-solid-svg-icons';
import { faStarfighterAlt as fadStarfighterAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faStarfighterAlt as falStarfighterAlt }   from '@fortawesome/pro-light-svg-icons';
import { faStarfighterAlt as farStarfighterAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faStarfighterAlt as fasStarfighterAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faStarfighterAltAdvanced as fadStarfighterAltAdvanced }   from '@fortawesome/pro-duotone-svg-icons';
import { faStarfighterAltAdvanced as falStarfighterAltAdvanced }   from '@fortawesome/pro-light-svg-icons';
import { faStarfighterAltAdvanced as farStarfighterAltAdvanced }   from '@fortawesome/pro-regular-svg-icons';
import { faStarfighterAltAdvanced as fasStarfighterAltAdvanced }   from '@fortawesome/pro-solid-svg-icons';
import { faStarfighterTwinIonEngine as fadStarfighterTwinIonEngine }   from '@fortawesome/pro-duotone-svg-icons';
import { faStarfighterTwinIonEngine as falStarfighterTwinIonEngine }   from '@fortawesome/pro-light-svg-icons';
import { faStarfighterTwinIonEngine as farStarfighterTwinIonEngine }   from '@fortawesome/pro-regular-svg-icons';
import { faStarfighterTwinIonEngine as fasStarfighterTwinIonEngine }   from '@fortawesome/pro-solid-svg-icons';
import { faStarfighterTwinIonEngineAdvanced as fadStarfighterTwinIonEngineAdvanced }   from '@fortawesome/pro-duotone-svg-icons';
import { faStarfighterTwinIonEngineAdvanced as falStarfighterTwinIonEngineAdvanced }   from '@fortawesome/pro-light-svg-icons';
import { faStarfighterTwinIonEngineAdvanced as farStarfighterTwinIonEngineAdvanced }   from '@fortawesome/pro-regular-svg-icons';
import { faStarfighterTwinIonEngineAdvanced as fasStarfighterTwinIonEngineAdvanced }   from '@fortawesome/pro-solid-svg-icons';
import { faStarHalf as fadStarHalf }   from '@fortawesome/pro-duotone-svg-icons';
import { faStarHalf as falStarHalf }   from '@fortawesome/pro-light-svg-icons';
import { faStarHalf as farStarHalf }   from '@fortawesome/pro-regular-svg-icons';
import { faStarHalf as fasStarHalf }   from '@fortawesome/pro-solid-svg-icons';
import { faStarHalfAlt as fadStarHalfAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faStarHalfAlt as falStarHalfAlt }   from '@fortawesome/pro-light-svg-icons';
import { faStarHalfAlt as farStarHalfAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faStarHalfAlt as fasStarHalfAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faStarHalfStroke as fadStarHalfStroke }   from '@fortawesome/pro-duotone-svg-icons';
import { faStarHalfStroke as falStarHalfStroke }   from '@fortawesome/pro-light-svg-icons';
import { faStarHalfStroke as farStarHalfStroke }   from '@fortawesome/pro-regular-svg-icons';
import { faStarHalfStroke as fasStarHalfStroke }   from '@fortawesome/pro-solid-svg-icons';
import { faStarOfDavid as fadStarOfDavid }   from '@fortawesome/pro-duotone-svg-icons';
import { faStarOfDavid as falStarOfDavid }   from '@fortawesome/pro-light-svg-icons';
import { faStarOfDavid as farStarOfDavid }   from '@fortawesome/pro-regular-svg-icons';
import { faStarOfDavid as fasStarOfDavid }   from '@fortawesome/pro-solid-svg-icons';
import { faStarOfLife as fadStarOfLife }   from '@fortawesome/pro-duotone-svg-icons';
import { faStarOfLife as falStarOfLife }   from '@fortawesome/pro-light-svg-icons';
import { faStarOfLife as farStarOfLife }   from '@fortawesome/pro-regular-svg-icons';
import { faStarOfLife as fasStarOfLife }   from '@fortawesome/pro-solid-svg-icons';
import { faStars as fadStars }   from '@fortawesome/pro-duotone-svg-icons';
import { faStars as falStars }   from '@fortawesome/pro-light-svg-icons';
import { faStars as farStars }   from '@fortawesome/pro-regular-svg-icons';
import { faStars as fasStars }   from '@fortawesome/pro-solid-svg-icons';
import { faStarSharp as fadStarSharp }   from '@fortawesome/pro-duotone-svg-icons';
import { faStarSharp as falStarSharp }   from '@fortawesome/pro-light-svg-icons';
import { faStarSharp as farStarSharp }   from '@fortawesome/pro-regular-svg-icons';
import { faStarSharp as fasStarSharp }   from '@fortawesome/pro-solid-svg-icons';
import { faStarSharpHalf as fadStarSharpHalf }   from '@fortawesome/pro-duotone-svg-icons';
import { faStarSharpHalf as falStarSharpHalf }   from '@fortawesome/pro-light-svg-icons';
import { faStarSharpHalf as farStarSharpHalf }   from '@fortawesome/pro-regular-svg-icons';
import { faStarSharpHalf as fasStarSharpHalf }   from '@fortawesome/pro-solid-svg-icons';
import { faStarSharpHalfAlt as fadStarSharpHalfAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faStarSharpHalfAlt as falStarSharpHalfAlt }   from '@fortawesome/pro-light-svg-icons';
import { faStarSharpHalfAlt as farStarSharpHalfAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faStarSharpHalfAlt as fasStarSharpHalfAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faStarSharpHalfStroke as fadStarSharpHalfStroke }   from '@fortawesome/pro-duotone-svg-icons';
import { faStarSharpHalfStroke as falStarSharpHalfStroke }   from '@fortawesome/pro-light-svg-icons';
import { faStarSharpHalfStroke as farStarSharpHalfStroke }   from '@fortawesome/pro-regular-svg-icons';
import { faStarSharpHalfStroke as fasStarSharpHalfStroke }   from '@fortawesome/pro-solid-svg-icons';
import { faStarship as fadStarship }   from '@fortawesome/pro-duotone-svg-icons';
import { faStarship as falStarship }   from '@fortawesome/pro-light-svg-icons';
import { faStarship as farStarship }   from '@fortawesome/pro-regular-svg-icons';
import { faStarship as fasStarship }   from '@fortawesome/pro-solid-svg-icons';
import { faStarshipFreighter as fadStarshipFreighter }   from '@fortawesome/pro-duotone-svg-icons';
import { faStarshipFreighter as falStarshipFreighter }   from '@fortawesome/pro-light-svg-icons';
import { faStarshipFreighter as farStarshipFreighter }   from '@fortawesome/pro-regular-svg-icons';
import { faStarshipFreighter as fasStarshipFreighter }   from '@fortawesome/pro-solid-svg-icons';
import { faStarShooting as fadStarShooting }   from '@fortawesome/pro-duotone-svg-icons';
import { faStarShooting as falStarShooting }   from '@fortawesome/pro-light-svg-icons';
import { faStarShooting as farStarShooting }   from '@fortawesome/pro-regular-svg-icons';
import { faStarShooting as fasStarShooting }   from '@fortawesome/pro-solid-svg-icons';
import { faSteak as fadSteak }   from '@fortawesome/pro-duotone-svg-icons';
import { faSteak as falSteak }   from '@fortawesome/pro-light-svg-icons';
import { faSteak as farSteak }   from '@fortawesome/pro-regular-svg-icons';
import { faSteak as fasSteak }   from '@fortawesome/pro-solid-svg-icons';
import { faSteeringWheel as fadSteeringWheel }   from '@fortawesome/pro-duotone-svg-icons';
import { faSteeringWheel as falSteeringWheel }   from '@fortawesome/pro-light-svg-icons';
import { faSteeringWheel as farSteeringWheel }   from '@fortawesome/pro-regular-svg-icons';
import { faSteeringWheel as fasSteeringWheel }   from '@fortawesome/pro-solid-svg-icons';
import { faStepBackward as fadStepBackward }   from '@fortawesome/pro-duotone-svg-icons';
import { faStepBackward as falStepBackward }   from '@fortawesome/pro-light-svg-icons';
import { faStepBackward as farStepBackward }   from '@fortawesome/pro-regular-svg-icons';
import { faStepBackward as fasStepBackward }   from '@fortawesome/pro-solid-svg-icons';
import { faStepForward as fadStepForward }   from '@fortawesome/pro-duotone-svg-icons';
import { faStepForward as falStepForward }   from '@fortawesome/pro-light-svg-icons';
import { faStepForward as farStepForward }   from '@fortawesome/pro-regular-svg-icons';
import { faStepForward as fasStepForward }   from '@fortawesome/pro-solid-svg-icons';
import { faSterlingSign as fadSterlingSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faSterlingSign as falSterlingSign }   from '@fortawesome/pro-light-svg-icons';
import { faSterlingSign as farSterlingSign }   from '@fortawesome/pro-regular-svg-icons';
import { faSterlingSign as fasSterlingSign }   from '@fortawesome/pro-solid-svg-icons';
import { faStethoscope as fadStethoscope }   from '@fortawesome/pro-duotone-svg-icons';
import { faStethoscope as falStethoscope }   from '@fortawesome/pro-light-svg-icons';
import { faStethoscope as farStethoscope }   from '@fortawesome/pro-regular-svg-icons';
import { faStethoscope as fasStethoscope }   from '@fortawesome/pro-solid-svg-icons';
import { faStickyNote as fadStickyNote }   from '@fortawesome/pro-duotone-svg-icons';
import { faStickyNote as falStickyNote }   from '@fortawesome/pro-light-svg-icons';
import { faStickyNote as farStickyNote }   from '@fortawesome/pro-regular-svg-icons';
import { faStickyNote as fasStickyNote }   from '@fortawesome/pro-solid-svg-icons';
import { faStocking as fadStocking }   from '@fortawesome/pro-duotone-svg-icons';
import { faStocking as falStocking }   from '@fortawesome/pro-light-svg-icons';
import { faStocking as farStocking }   from '@fortawesome/pro-regular-svg-icons';
import { faStocking as fasStocking }   from '@fortawesome/pro-solid-svg-icons';
import { faStomach as fadStomach }   from '@fortawesome/pro-duotone-svg-icons';
import { faStomach as falStomach }   from '@fortawesome/pro-light-svg-icons';
import { faStomach as farStomach }   from '@fortawesome/pro-regular-svg-icons';
import { faStomach as fasStomach }   from '@fortawesome/pro-solid-svg-icons';
import { faStop as fadStop }   from '@fortawesome/pro-duotone-svg-icons';
import { faStop as falStop }   from '@fortawesome/pro-light-svg-icons';
import { faStop as farStop }   from '@fortawesome/pro-regular-svg-icons';
import { faStop as fasStop }   from '@fortawesome/pro-solid-svg-icons';
import { faStopCircle as fadStopCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faStopCircle as falStopCircle }   from '@fortawesome/pro-light-svg-icons';
import { faStopCircle as farStopCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faStopCircle as fasStopCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faStopwatch as fadStopwatch }   from '@fortawesome/pro-duotone-svg-icons';
import { faStopwatch as falStopwatch }   from '@fortawesome/pro-light-svg-icons';
import { faStopwatch as farStopwatch }   from '@fortawesome/pro-regular-svg-icons';
import { faStopwatch as fasStopwatch }   from '@fortawesome/pro-solid-svg-icons';
import { faStopwatch20 as fadStopwatch20 }   from '@fortawesome/pro-duotone-svg-icons';
import { faStopwatch20 as falStopwatch20 }   from '@fortawesome/pro-light-svg-icons';
import { faStopwatch20 as farStopwatch20 }   from '@fortawesome/pro-regular-svg-icons';
import { faStopwatch20 as fasStopwatch20 }   from '@fortawesome/pro-solid-svg-icons';
import { faStore as fadStore }   from '@fortawesome/pro-duotone-svg-icons';
import { faStore as falStore }   from '@fortawesome/pro-light-svg-icons';
import { faStore as farStore }   from '@fortawesome/pro-regular-svg-icons';
import { faStore as fasStore }   from '@fortawesome/pro-solid-svg-icons';
import { faStoreAlt as fadStoreAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faStoreAlt as falStoreAlt }   from '@fortawesome/pro-light-svg-icons';
import { faStoreAlt as farStoreAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faStoreAlt as fasStoreAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faStoreAltSlash as fadStoreAltSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faStoreAltSlash as falStoreAltSlash }   from '@fortawesome/pro-light-svg-icons';
import { faStoreAltSlash as farStoreAltSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faStoreAltSlash as fasStoreAltSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faStoreLock as fadStoreLock }   from '@fortawesome/pro-duotone-svg-icons';
import { faStoreLock as falStoreLock }   from '@fortawesome/pro-light-svg-icons';
import { faStoreLock as farStoreLock }   from '@fortawesome/pro-regular-svg-icons';
import { faStoreLock as fasStoreLock }   from '@fortawesome/pro-solid-svg-icons';
import { faStoreSlash as fadStoreSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faStoreSlash as falStoreSlash }   from '@fortawesome/pro-light-svg-icons';
import { faStoreSlash as farStoreSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faStoreSlash as fasStoreSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faStrawberry as fadStrawberry }   from '@fortawesome/pro-duotone-svg-icons';
import { faStrawberry as falStrawberry }   from '@fortawesome/pro-light-svg-icons';
import { faStrawberry as farStrawberry }   from '@fortawesome/pro-regular-svg-icons';
import { faStrawberry as fasStrawberry }   from '@fortawesome/pro-solid-svg-icons';
import { faStream as fadStream }   from '@fortawesome/pro-duotone-svg-icons';
import { faStream as falStream }   from '@fortawesome/pro-light-svg-icons';
import { faStream as farStream }   from '@fortawesome/pro-regular-svg-icons';
import { faStream as fasStream }   from '@fortawesome/pro-solid-svg-icons';
import { faStreetView as fadStreetView }   from '@fortawesome/pro-duotone-svg-icons';
import { faStreetView as falStreetView }   from '@fortawesome/pro-light-svg-icons';
import { faStreetView as farStreetView }   from '@fortawesome/pro-regular-svg-icons';
import { faStreetView as fasStreetView }   from '@fortawesome/pro-solid-svg-icons';
import { faStretcher as fadStretcher }   from '@fortawesome/pro-duotone-svg-icons';
import { faStretcher as falStretcher }   from '@fortawesome/pro-light-svg-icons';
import { faStretcher as farStretcher }   from '@fortawesome/pro-regular-svg-icons';
import { faStretcher as fasStretcher }   from '@fortawesome/pro-solid-svg-icons';
import { faStrikethrough as fadStrikethrough }   from '@fortawesome/pro-duotone-svg-icons';
import { faStrikethrough as falStrikethrough }   from '@fortawesome/pro-light-svg-icons';
import { faStrikethrough as farStrikethrough }   from '@fortawesome/pro-regular-svg-icons';
import { faStrikethrough as fasStrikethrough }   from '@fortawesome/pro-solid-svg-icons';
import { faStroopwafel as fadStroopwafel }   from '@fortawesome/pro-duotone-svg-icons';
import { faStroopwafel as falStroopwafel }   from '@fortawesome/pro-light-svg-icons';
import { faStroopwafel as farStroopwafel }   from '@fortawesome/pro-regular-svg-icons';
import { faStroopwafel as fasStroopwafel }   from '@fortawesome/pro-solid-svg-icons';
import { faSubscript as fadSubscript }   from '@fortawesome/pro-duotone-svg-icons';
import { faSubscript as falSubscript }   from '@fortawesome/pro-light-svg-icons';
import { faSubscript as farSubscript }   from '@fortawesome/pro-regular-svg-icons';
import { faSubscript as fasSubscript }   from '@fortawesome/pro-solid-svg-icons';
import { faSubtract as fadSubtract }   from '@fortawesome/pro-duotone-svg-icons';
import { faSubtract as falSubtract }   from '@fortawesome/pro-light-svg-icons';
import { faSubtract as farSubtract }   from '@fortawesome/pro-regular-svg-icons';
import { faSubtract as fasSubtract }   from '@fortawesome/pro-solid-svg-icons';
import { faSubway as fadSubway }   from '@fortawesome/pro-duotone-svg-icons';
import { faSubway as falSubway }   from '@fortawesome/pro-light-svg-icons';
import { faSubway as farSubway }   from '@fortawesome/pro-regular-svg-icons';
import { faSubway as fasSubway }   from '@fortawesome/pro-solid-svg-icons';
import { faSubwayTunnel as fadSubwayTunnel }   from '@fortawesome/pro-duotone-svg-icons';
import { faSubwayTunnel as falSubwayTunnel }   from '@fortawesome/pro-light-svg-icons';
import { faSubwayTunnel as farSubwayTunnel }   from '@fortawesome/pro-regular-svg-icons';
import { faSubwayTunnel as fasSubwayTunnel }   from '@fortawesome/pro-solid-svg-icons';
import { faSuitcase as fadSuitcase }   from '@fortawesome/pro-duotone-svg-icons';
import { faSuitcase as falSuitcase }   from '@fortawesome/pro-light-svg-icons';
import { faSuitcase as farSuitcase }   from '@fortawesome/pro-regular-svg-icons';
import { faSuitcase as fasSuitcase }   from '@fortawesome/pro-solid-svg-icons';
import { faSuitcaseMedical as fadSuitcaseMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faSuitcaseMedical as falSuitcaseMedical }   from '@fortawesome/pro-light-svg-icons';
import { faSuitcaseMedical as farSuitcaseMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faSuitcaseMedical as fasSuitcaseMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faSuitcaseRolling as fadSuitcaseRolling }   from '@fortawesome/pro-duotone-svg-icons';
import { faSuitcaseRolling as falSuitcaseRolling }   from '@fortawesome/pro-light-svg-icons';
import { faSuitcaseRolling as farSuitcaseRolling }   from '@fortawesome/pro-regular-svg-icons';
import { faSuitcaseRolling as fasSuitcaseRolling }   from '@fortawesome/pro-solid-svg-icons';
import { faSun as fadSun }   from '@fortawesome/pro-duotone-svg-icons';
import { faSun as falSun }   from '@fortawesome/pro-light-svg-icons';
import { faSun as farSun }   from '@fortawesome/pro-regular-svg-icons';
import { faSun as fasSun }   from '@fortawesome/pro-solid-svg-icons';
import { faSunAlt as fadSunAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faSunAlt as falSunAlt }   from '@fortawesome/pro-light-svg-icons';
import { faSunAlt as farSunAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faSunAlt as fasSunAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faSunBright as fadSunBright }   from '@fortawesome/pro-duotone-svg-icons';
import { faSunBright as falSunBright }   from '@fortawesome/pro-light-svg-icons';
import { faSunBright as farSunBright }   from '@fortawesome/pro-regular-svg-icons';
import { faSunBright as fasSunBright }   from '@fortawesome/pro-solid-svg-icons';
import { faSunCloud as fadSunCloud }   from '@fortawesome/pro-duotone-svg-icons';
import { faSunCloud as falSunCloud }   from '@fortawesome/pro-light-svg-icons';
import { faSunCloud as farSunCloud }   from '@fortawesome/pro-regular-svg-icons';
import { faSunCloud as fasSunCloud }   from '@fortawesome/pro-solid-svg-icons';
import { faSunDust as fadSunDust }   from '@fortawesome/pro-duotone-svg-icons';
import { faSunDust as falSunDust }   from '@fortawesome/pro-light-svg-icons';
import { faSunDust as farSunDust }   from '@fortawesome/pro-regular-svg-icons';
import { faSunDust as fasSunDust }   from '@fortawesome/pro-solid-svg-icons';
import { faSunglasses as fadSunglasses }   from '@fortawesome/pro-duotone-svg-icons';
import { faSunglasses as falSunglasses }   from '@fortawesome/pro-light-svg-icons';
import { faSunglasses as farSunglasses }   from '@fortawesome/pro-regular-svg-icons';
import { faSunglasses as fasSunglasses }   from '@fortawesome/pro-solid-svg-icons';
import { faSunHaze as fadSunHaze }   from '@fortawesome/pro-duotone-svg-icons';
import { faSunHaze as falSunHaze }   from '@fortawesome/pro-light-svg-icons';
import { faSunHaze as farSunHaze }   from '@fortawesome/pro-regular-svg-icons';
import { faSunHaze as fasSunHaze }   from '@fortawesome/pro-solid-svg-icons';
import { faSunPlantWilt as fadSunPlantWilt }   from '@fortawesome/pro-duotone-svg-icons';
import { faSunPlantWilt as falSunPlantWilt }   from '@fortawesome/pro-light-svg-icons';
import { faSunPlantWilt as farSunPlantWilt }   from '@fortawesome/pro-regular-svg-icons';
import { faSunPlantWilt as fasSunPlantWilt }   from '@fortawesome/pro-solid-svg-icons';
import { faSunrise as fadSunrise }   from '@fortawesome/pro-duotone-svg-icons';
import { faSunrise as falSunrise }   from '@fortawesome/pro-light-svg-icons';
import { faSunrise as farSunrise }   from '@fortawesome/pro-regular-svg-icons';
import { faSunrise as fasSunrise }   from '@fortawesome/pro-solid-svg-icons';
import { faSunset as fadSunset }   from '@fortawesome/pro-duotone-svg-icons';
import { faSunset as falSunset }   from '@fortawesome/pro-light-svg-icons';
import { faSunset as farSunset }   from '@fortawesome/pro-regular-svg-icons';
import { faSunset as fasSunset }   from '@fortawesome/pro-solid-svg-icons';
import { faSuperscript as fadSuperscript }   from '@fortawesome/pro-duotone-svg-icons';
import { faSuperscript as falSuperscript }   from '@fortawesome/pro-light-svg-icons';
import { faSuperscript as farSuperscript }   from '@fortawesome/pro-regular-svg-icons';
import { faSuperscript as fasSuperscript }   from '@fortawesome/pro-solid-svg-icons';
import { faSurprise as fadSurprise }   from '@fortawesome/pro-duotone-svg-icons';
import { faSurprise as falSurprise }   from '@fortawesome/pro-light-svg-icons';
import { faSurprise as farSurprise }   from '@fortawesome/pro-regular-svg-icons';
import { faSurprise as fasSurprise }   from '@fortawesome/pro-solid-svg-icons';
import { faSushi as fadSushi }   from '@fortawesome/pro-duotone-svg-icons';
import { faSushi as falSushi }   from '@fortawesome/pro-light-svg-icons';
import { faSushi as farSushi }   from '@fortawesome/pro-regular-svg-icons';
import { faSushi as fasSushi }   from '@fortawesome/pro-solid-svg-icons';
import { faSushiRoll as fadSushiRoll }   from '@fortawesome/pro-duotone-svg-icons';
import { faSushiRoll as falSushiRoll }   from '@fortawesome/pro-light-svg-icons';
import { faSushiRoll as farSushiRoll }   from '@fortawesome/pro-regular-svg-icons';
import { faSushiRoll as fasSushiRoll }   from '@fortawesome/pro-solid-svg-icons';
import { faSwatchbook as fadSwatchbook }   from '@fortawesome/pro-duotone-svg-icons';
import { faSwatchbook as falSwatchbook }   from '@fortawesome/pro-light-svg-icons';
import { faSwatchbook as farSwatchbook }   from '@fortawesome/pro-regular-svg-icons';
import { faSwatchbook as fasSwatchbook }   from '@fortawesome/pro-solid-svg-icons';
import { faSwimmer as fadSwimmer }   from '@fortawesome/pro-duotone-svg-icons';
import { faSwimmer as falSwimmer }   from '@fortawesome/pro-light-svg-icons';
import { faSwimmer as farSwimmer }   from '@fortawesome/pro-regular-svg-icons';
import { faSwimmer as fasSwimmer }   from '@fortawesome/pro-solid-svg-icons';
import { faSwimmingPool as fadSwimmingPool }   from '@fortawesome/pro-duotone-svg-icons';
import { faSwimmingPool as falSwimmingPool }   from '@fortawesome/pro-light-svg-icons';
import { faSwimmingPool as farSwimmingPool }   from '@fortawesome/pro-regular-svg-icons';
import { faSwimmingPool as fasSwimmingPool }   from '@fortawesome/pro-solid-svg-icons';
import { faSword as fadSword }   from '@fortawesome/pro-duotone-svg-icons';
import { faSword as falSword }   from '@fortawesome/pro-light-svg-icons';
import { faSword as farSword }   from '@fortawesome/pro-regular-svg-icons';
import { faSword as fasSword }   from '@fortawesome/pro-solid-svg-icons';
import { faSwordLaser as fadSwordLaser }   from '@fortawesome/pro-duotone-svg-icons';
import { faSwordLaser as falSwordLaser }   from '@fortawesome/pro-light-svg-icons';
import { faSwordLaser as farSwordLaser }   from '@fortawesome/pro-regular-svg-icons';
import { faSwordLaser as fasSwordLaser }   from '@fortawesome/pro-solid-svg-icons';
import { faSwordLaserAlt as fadSwordLaserAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faSwordLaserAlt as falSwordLaserAlt }   from '@fortawesome/pro-light-svg-icons';
import { faSwordLaserAlt as farSwordLaserAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faSwordLaserAlt as fasSwordLaserAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faSwords as fadSwords }   from '@fortawesome/pro-duotone-svg-icons';
import { faSwords as falSwords }   from '@fortawesome/pro-light-svg-icons';
import { faSwords as farSwords }   from '@fortawesome/pro-regular-svg-icons';
import { faSwords as fasSwords }   from '@fortawesome/pro-solid-svg-icons';
import { faSwordsLaser as fadSwordsLaser }   from '@fortawesome/pro-duotone-svg-icons';
import { faSwordsLaser as falSwordsLaser }   from '@fortawesome/pro-light-svg-icons';
import { faSwordsLaser as farSwordsLaser }   from '@fortawesome/pro-regular-svg-icons';
import { faSwordsLaser as fasSwordsLaser }   from '@fortawesome/pro-solid-svg-icons';
import { faSymbols as fadSymbols }   from '@fortawesome/pro-duotone-svg-icons';
import { faSymbols as falSymbols }   from '@fortawesome/pro-light-svg-icons';
import { faSymbols as farSymbols }   from '@fortawesome/pro-regular-svg-icons';
import { faSymbols as fasSymbols }   from '@fortawesome/pro-solid-svg-icons';
import { faSynagogue as fadSynagogue }   from '@fortawesome/pro-duotone-svg-icons';
import { faSynagogue as falSynagogue }   from '@fortawesome/pro-light-svg-icons';
import { faSynagogue as farSynagogue }   from '@fortawesome/pro-regular-svg-icons';
import { faSynagogue as fasSynagogue }   from '@fortawesome/pro-solid-svg-icons';
import { faSync as fadSync }   from '@fortawesome/pro-duotone-svg-icons';
import { faSync as falSync }   from '@fortawesome/pro-light-svg-icons';
import { faSync as farSync }   from '@fortawesome/pro-regular-svg-icons';
import { faSync as fasSync }   from '@fortawesome/pro-solid-svg-icons';
import { faSyncAlt as fadSyncAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faSyncAlt as falSyncAlt }   from '@fortawesome/pro-light-svg-icons';
import { faSyncAlt as farSyncAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faSyncAlt as fasSyncAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faSyringe as fadSyringe }   from '@fortawesome/pro-duotone-svg-icons';
import { faSyringe as falSyringe }   from '@fortawesome/pro-light-svg-icons';
import { faSyringe as farSyringe }   from '@fortawesome/pro-regular-svg-icons';
import { faSyringe as fasSyringe }   from '@fortawesome/pro-solid-svg-icons';
import { faT as fadT }   from '@fortawesome/pro-duotone-svg-icons';
import { faT as falT }   from '@fortawesome/pro-light-svg-icons';
import { faT as farT }   from '@fortawesome/pro-regular-svg-icons';
import { faT as fasT }   from '@fortawesome/pro-solid-svg-icons';
import { faTable as fadTable }   from '@fortawesome/pro-duotone-svg-icons';
import { faTable as falTable }   from '@fortawesome/pro-light-svg-icons';
import { faTable as farTable }   from '@fortawesome/pro-regular-svg-icons';
import { faTable as fasTable }   from '@fortawesome/pro-solid-svg-icons';
import { faTableCells as fadTableCells }   from '@fortawesome/pro-duotone-svg-icons';
import { faTableCells as falTableCells }   from '@fortawesome/pro-light-svg-icons';
import { faTableCells as farTableCells }   from '@fortawesome/pro-regular-svg-icons';
import { faTableCells as fasTableCells }   from '@fortawesome/pro-solid-svg-icons';
import { faTableCellsLarge as fadTableCellsLarge }   from '@fortawesome/pro-duotone-svg-icons';
import { faTableCellsLarge as falTableCellsLarge }   from '@fortawesome/pro-light-svg-icons';
import { faTableCellsLarge as farTableCellsLarge }   from '@fortawesome/pro-regular-svg-icons';
import { faTableCellsLarge as fasTableCellsLarge }   from '@fortawesome/pro-solid-svg-icons';
import { faTableColumns as fadTableColumns }   from '@fortawesome/pro-duotone-svg-icons';
import { faTableColumns as falTableColumns }   from '@fortawesome/pro-light-svg-icons';
import { faTableColumns as farTableColumns }   from '@fortawesome/pro-regular-svg-icons';
import { faTableColumns as fasTableColumns }   from '@fortawesome/pro-solid-svg-icons';
import { faTableLayout as fadTableLayout }   from '@fortawesome/pro-duotone-svg-icons';
import { faTableLayout as falTableLayout }   from '@fortawesome/pro-light-svg-icons';
import { faTableLayout as farTableLayout }   from '@fortawesome/pro-regular-svg-icons';
import { faTableLayout as fasTableLayout }   from '@fortawesome/pro-solid-svg-icons';
import { faTableList as fadTableList }   from '@fortawesome/pro-duotone-svg-icons';
import { faTableList as falTableList }   from '@fortawesome/pro-light-svg-icons';
import { faTableList as farTableList }   from '@fortawesome/pro-regular-svg-icons';
import { faTableList as fasTableList }   from '@fortawesome/pro-solid-svg-icons';
import { faTablePicnic as fadTablePicnic }   from '@fortawesome/pro-duotone-svg-icons';
import { faTablePicnic as falTablePicnic }   from '@fortawesome/pro-light-svg-icons';
import { faTablePicnic as farTablePicnic }   from '@fortawesome/pro-regular-svg-icons';
import { faTablePicnic as fasTablePicnic }   from '@fortawesome/pro-solid-svg-icons';
import { faTablePivot as fadTablePivot }   from '@fortawesome/pro-duotone-svg-icons';
import { faTablePivot as falTablePivot }   from '@fortawesome/pro-light-svg-icons';
import { faTablePivot as farTablePivot }   from '@fortawesome/pro-regular-svg-icons';
import { faTablePivot as fasTablePivot }   from '@fortawesome/pro-solid-svg-icons';
import { faTableRows as fadTableRows }   from '@fortawesome/pro-duotone-svg-icons';
import { faTableRows as falTableRows }   from '@fortawesome/pro-light-svg-icons';
import { faTableRows as farTableRows }   from '@fortawesome/pro-regular-svg-icons';
import { faTableRows as fasTableRows }   from '@fortawesome/pro-solid-svg-icons';
import { faTablet as fadTablet }   from '@fortawesome/pro-duotone-svg-icons';
import { faTablet as falTablet }   from '@fortawesome/pro-light-svg-icons';
import { faTablet as farTablet }   from '@fortawesome/pro-regular-svg-icons';
import { faTablet as fasTablet }   from '@fortawesome/pro-solid-svg-icons';
import { faTabletAlt as fadTabletAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faTabletAlt as falTabletAlt }   from '@fortawesome/pro-light-svg-icons';
import { faTabletAlt as farTabletAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faTabletAlt as fasTabletAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faTabletAndroid as fadTabletAndroid }   from '@fortawesome/pro-duotone-svg-icons';
import { faTabletAndroid as falTabletAndroid }   from '@fortawesome/pro-light-svg-icons';
import { faTabletAndroid as farTabletAndroid }   from '@fortawesome/pro-regular-svg-icons';
import { faTabletAndroid as fasTabletAndroid }   from '@fortawesome/pro-solid-svg-icons';
import { faTabletAndroidAlt as fadTabletAndroidAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faTabletAndroidAlt as falTabletAndroidAlt }   from '@fortawesome/pro-light-svg-icons';
import { faTabletAndroidAlt as farTabletAndroidAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faTabletAndroidAlt as fasTabletAndroidAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faTabletButton as fadTabletButton }   from '@fortawesome/pro-duotone-svg-icons';
import { faTabletButton as falTabletButton }   from '@fortawesome/pro-light-svg-icons';
import { faTabletButton as farTabletButton }   from '@fortawesome/pro-regular-svg-icons';
import { faTabletButton as fasTabletButton }   from '@fortawesome/pro-solid-svg-icons';
import { faTableTennis as fadTableTennis }   from '@fortawesome/pro-duotone-svg-icons';
import { faTableTennis as falTableTennis }   from '@fortawesome/pro-light-svg-icons';
import { faTableTennis as farTableTennis }   from '@fortawesome/pro-regular-svg-icons';
import { faTableTennis as fasTableTennis }   from '@fortawesome/pro-solid-svg-icons';
import { faTableTennisPaddleBall as fadTableTennisPaddleBall }   from '@fortawesome/pro-duotone-svg-icons';
import { faTableTennisPaddleBall as falTableTennisPaddleBall }   from '@fortawesome/pro-light-svg-icons';
import { faTableTennisPaddleBall as farTableTennisPaddleBall }   from '@fortawesome/pro-regular-svg-icons';
import { faTableTennisPaddleBall as fasTableTennisPaddleBall }   from '@fortawesome/pro-solid-svg-icons';
import { faTableTree as fadTableTree }   from '@fortawesome/pro-duotone-svg-icons';
import { faTableTree as falTableTree }   from '@fortawesome/pro-light-svg-icons';
import { faTableTree as farTableTree }   from '@fortawesome/pro-regular-svg-icons';
import { faTableTree as fasTableTree }   from '@fortawesome/pro-solid-svg-icons';
import { faTabletRugged as fadTabletRugged }   from '@fortawesome/pro-duotone-svg-icons';
import { faTabletRugged as falTabletRugged }   from '@fortawesome/pro-light-svg-icons';
import { faTabletRugged as farTabletRugged }   from '@fortawesome/pro-regular-svg-icons';
import { faTabletRugged as fasTabletRugged }   from '@fortawesome/pro-solid-svg-icons';
import { faTablets as fadTablets }   from '@fortawesome/pro-duotone-svg-icons';
import { faTablets as falTablets }   from '@fortawesome/pro-light-svg-icons';
import { faTablets as farTablets }   from '@fortawesome/pro-regular-svg-icons';
import { faTablets as fasTablets }   from '@fortawesome/pro-solid-svg-icons';
import { faTabletScreen as fadTabletScreen }   from '@fortawesome/pro-duotone-svg-icons';
import { faTabletScreen as falTabletScreen }   from '@fortawesome/pro-light-svg-icons';
import { faTabletScreen as farTabletScreen }   from '@fortawesome/pro-regular-svg-icons';
import { faTabletScreen as fasTabletScreen }   from '@fortawesome/pro-solid-svg-icons';
import { faTabletScreenButton as fadTabletScreenButton }   from '@fortawesome/pro-duotone-svg-icons';
import { faTabletScreenButton as falTabletScreenButton }   from '@fortawesome/pro-light-svg-icons';
import { faTabletScreenButton as farTabletScreenButton }   from '@fortawesome/pro-regular-svg-icons';
import { faTabletScreenButton as fasTabletScreenButton }   from '@fortawesome/pro-solid-svg-icons';
import { faTachographDigital as fadTachographDigital }   from '@fortawesome/pro-duotone-svg-icons';
import { faTachographDigital as falTachographDigital }   from '@fortawesome/pro-light-svg-icons';
import { faTachographDigital as farTachographDigital }   from '@fortawesome/pro-regular-svg-icons';
import { faTachographDigital as fasTachographDigital }   from '@fortawesome/pro-solid-svg-icons';
import { faTachometer as fadTachometer }   from '@fortawesome/pro-duotone-svg-icons';
import { faTachometer as falTachometer }   from '@fortawesome/pro-light-svg-icons';
import { faTachometer as farTachometer }   from '@fortawesome/pro-regular-svg-icons';
import { faTachometer as fasTachometer }   from '@fortawesome/pro-solid-svg-icons';
import { faTachometerAlt as fadTachometerAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faTachometerAlt as falTachometerAlt }   from '@fortawesome/pro-light-svg-icons';
import { faTachometerAlt as farTachometerAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faTachometerAlt as fasTachometerAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faTachometerAltAverage as fadTachometerAltAverage }   from '@fortawesome/pro-duotone-svg-icons';
import { faTachometerAltAverage as falTachometerAltAverage }   from '@fortawesome/pro-light-svg-icons';
import { faTachometerAltAverage as farTachometerAltAverage }   from '@fortawesome/pro-regular-svg-icons';
import { faTachometerAltAverage as fasTachometerAltAverage }   from '@fortawesome/pro-solid-svg-icons';
import { faTachometerAltFast as fadTachometerAltFast }   from '@fortawesome/pro-duotone-svg-icons';
import { faTachometerAltFast as falTachometerAltFast }   from '@fortawesome/pro-light-svg-icons';
import { faTachometerAltFast as farTachometerAltFast }   from '@fortawesome/pro-regular-svg-icons';
import { faTachometerAltFast as fasTachometerAltFast }   from '@fortawesome/pro-solid-svg-icons';
import { faTachometerAltFastest as fadTachometerAltFastest }   from '@fortawesome/pro-duotone-svg-icons';
import { faTachometerAltFastest as falTachometerAltFastest }   from '@fortawesome/pro-light-svg-icons';
import { faTachometerAltFastest as farTachometerAltFastest }   from '@fortawesome/pro-regular-svg-icons';
import { faTachometerAltFastest as fasTachometerAltFastest }   from '@fortawesome/pro-solid-svg-icons';
import { faTachometerAltSlow as fadTachometerAltSlow }   from '@fortawesome/pro-duotone-svg-icons';
import { faTachometerAltSlow as falTachometerAltSlow }   from '@fortawesome/pro-light-svg-icons';
import { faTachometerAltSlow as farTachometerAltSlow }   from '@fortawesome/pro-regular-svg-icons';
import { faTachometerAltSlow as fasTachometerAltSlow }   from '@fortawesome/pro-solid-svg-icons';
import { faTachometerAltSlowest as fadTachometerAltSlowest }   from '@fortawesome/pro-duotone-svg-icons';
import { faTachometerAltSlowest as falTachometerAltSlowest }   from '@fortawesome/pro-light-svg-icons';
import { faTachometerAltSlowest as farTachometerAltSlowest }   from '@fortawesome/pro-regular-svg-icons';
import { faTachometerAltSlowest as fasTachometerAltSlowest }   from '@fortawesome/pro-solid-svg-icons';
import { faTachometerAverage as fadTachometerAverage }   from '@fortawesome/pro-duotone-svg-icons';
import { faTachometerAverage as falTachometerAverage }   from '@fortawesome/pro-light-svg-icons';
import { faTachometerAverage as farTachometerAverage }   from '@fortawesome/pro-regular-svg-icons';
import { faTachometerAverage as fasTachometerAverage }   from '@fortawesome/pro-solid-svg-icons';
import { faTachometerFast as fadTachometerFast }   from '@fortawesome/pro-duotone-svg-icons';
import { faTachometerFast as falTachometerFast }   from '@fortawesome/pro-light-svg-icons';
import { faTachometerFast as farTachometerFast }   from '@fortawesome/pro-regular-svg-icons';
import { faTachometerFast as fasTachometerFast }   from '@fortawesome/pro-solid-svg-icons';
import { faTachometerFastest as fadTachometerFastest }   from '@fortawesome/pro-duotone-svg-icons';
import { faTachometerFastest as falTachometerFastest }   from '@fortawesome/pro-light-svg-icons';
import { faTachometerFastest as farTachometerFastest }   from '@fortawesome/pro-regular-svg-icons';
import { faTachometerFastest as fasTachometerFastest }   from '@fortawesome/pro-solid-svg-icons';
import { faTachometerSlow as fadTachometerSlow }   from '@fortawesome/pro-duotone-svg-icons';
import { faTachometerSlow as falTachometerSlow }   from '@fortawesome/pro-light-svg-icons';
import { faTachometerSlow as farTachometerSlow }   from '@fortawesome/pro-regular-svg-icons';
import { faTachometerSlow as fasTachometerSlow }   from '@fortawesome/pro-solid-svg-icons';
import { faTachometerSlowest as fadTachometerSlowest }   from '@fortawesome/pro-duotone-svg-icons';
import { faTachometerSlowest as falTachometerSlowest }   from '@fortawesome/pro-light-svg-icons';
import { faTachometerSlowest as farTachometerSlowest }   from '@fortawesome/pro-regular-svg-icons';
import { faTachometerSlowest as fasTachometerSlowest }   from '@fortawesome/pro-solid-svg-icons';
import { faTaco as fadTaco }   from '@fortawesome/pro-duotone-svg-icons';
import { faTaco as falTaco }   from '@fortawesome/pro-light-svg-icons';
import { faTaco as farTaco }   from '@fortawesome/pro-regular-svg-icons';
import { faTaco as fasTaco }   from '@fortawesome/pro-solid-svg-icons';
import { faTag as fadTag }   from '@fortawesome/pro-duotone-svg-icons';
import { faTag as falTag }   from '@fortawesome/pro-light-svg-icons';
import { faTag as farTag }   from '@fortawesome/pro-regular-svg-icons';
import { faTag as fasTag }   from '@fortawesome/pro-solid-svg-icons';
import { faTags as fadTags }   from '@fortawesome/pro-duotone-svg-icons';
import { faTags as falTags }   from '@fortawesome/pro-light-svg-icons';
import { faTags as farTags }   from '@fortawesome/pro-regular-svg-icons';
import { faTags as fasTags }   from '@fortawesome/pro-solid-svg-icons';
import { faTally as fadTally }   from '@fortawesome/pro-duotone-svg-icons';
import { faTally as falTally }   from '@fortawesome/pro-light-svg-icons';
import { faTally as farTally }   from '@fortawesome/pro-regular-svg-icons';
import { faTally as fasTally }   from '@fortawesome/pro-solid-svg-icons';
import { faTally1 as fadTally1 }   from '@fortawesome/pro-duotone-svg-icons';
import { faTally1 as falTally1 }   from '@fortawesome/pro-light-svg-icons';
import { faTally1 as farTally1 }   from '@fortawesome/pro-regular-svg-icons';
import { faTally1 as fasTally1 }   from '@fortawesome/pro-solid-svg-icons';
import { faTally2 as fadTally2 }   from '@fortawesome/pro-duotone-svg-icons';
import { faTally2 as falTally2 }   from '@fortawesome/pro-light-svg-icons';
import { faTally2 as farTally2 }   from '@fortawesome/pro-regular-svg-icons';
import { faTally2 as fasTally2 }   from '@fortawesome/pro-solid-svg-icons';
import { faTally3 as fadTally3 }   from '@fortawesome/pro-duotone-svg-icons';
import { faTally3 as falTally3 }   from '@fortawesome/pro-light-svg-icons';
import { faTally3 as farTally3 }   from '@fortawesome/pro-regular-svg-icons';
import { faTally3 as fasTally3 }   from '@fortawesome/pro-solid-svg-icons';
import { faTally4 as fadTally4 }   from '@fortawesome/pro-duotone-svg-icons';
import { faTally4 as falTally4 }   from '@fortawesome/pro-light-svg-icons';
import { faTally4 as farTally4 }   from '@fortawesome/pro-regular-svg-icons';
import { faTally4 as fasTally4 }   from '@fortawesome/pro-solid-svg-icons';
import { faTally5 as fadTally5 }   from '@fortawesome/pro-duotone-svg-icons';
import { faTally5 as falTally5 }   from '@fortawesome/pro-light-svg-icons';
import { faTally5 as farTally5 }   from '@fortawesome/pro-regular-svg-icons';
import { faTally5 as fasTally5 }   from '@fortawesome/pro-solid-svg-icons';
import { faTamale as fadTamale }   from '@fortawesome/pro-duotone-svg-icons';
import { faTamale as falTamale }   from '@fortawesome/pro-light-svg-icons';
import { faTamale as farTamale }   from '@fortawesome/pro-regular-svg-icons';
import { faTamale as fasTamale }   from '@fortawesome/pro-solid-svg-icons';
import { faTanakh as fadTanakh }   from '@fortawesome/pro-duotone-svg-icons';
import { faTanakh as falTanakh }   from '@fortawesome/pro-light-svg-icons';
import { faTanakh as farTanakh }   from '@fortawesome/pro-regular-svg-icons';
import { faTanakh as fasTanakh }   from '@fortawesome/pro-solid-svg-icons';
import { faTankWater as fadTankWater }   from '@fortawesome/pro-duotone-svg-icons';
import { faTankWater as falTankWater }   from '@fortawesome/pro-light-svg-icons';
import { faTankWater as farTankWater }   from '@fortawesome/pro-regular-svg-icons';
import { faTankWater as fasTankWater }   from '@fortawesome/pro-solid-svg-icons';
import { faTape as fadTape }   from '@fortawesome/pro-duotone-svg-icons';
import { faTape as falTape }   from '@fortawesome/pro-light-svg-icons';
import { faTape as farTape }   from '@fortawesome/pro-regular-svg-icons';
import { faTape as fasTape }   from '@fortawesome/pro-solid-svg-icons';
import { faTarp as fadTarp }   from '@fortawesome/pro-duotone-svg-icons';
import { faTarp as falTarp }   from '@fortawesome/pro-light-svg-icons';
import { faTarp as farTarp }   from '@fortawesome/pro-regular-svg-icons';
import { faTarp as fasTarp }   from '@fortawesome/pro-solid-svg-icons';
import { faTarpDroplet as fadTarpDroplet }   from '@fortawesome/pro-duotone-svg-icons';
import { faTarpDroplet as falTarpDroplet }   from '@fortawesome/pro-light-svg-icons';
import { faTarpDroplet as farTarpDroplet }   from '@fortawesome/pro-regular-svg-icons';
import { faTarpDroplet as fasTarpDroplet }   from '@fortawesome/pro-solid-svg-icons';
import { faTasks as fadTasks }   from '@fortawesome/pro-duotone-svg-icons';
import { faTasks as falTasks }   from '@fortawesome/pro-light-svg-icons';
import { faTasks as farTasks }   from '@fortawesome/pro-regular-svg-icons';
import { faTasks as fasTasks }   from '@fortawesome/pro-solid-svg-icons';
import { faTasksAlt as fadTasksAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faTasksAlt as falTasksAlt }   from '@fortawesome/pro-light-svg-icons';
import { faTasksAlt as farTasksAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faTasksAlt as fasTasksAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faTaxi as fadTaxi }   from '@fortawesome/pro-duotone-svg-icons';
import { faTaxi as falTaxi }   from '@fortawesome/pro-light-svg-icons';
import { faTaxi as farTaxi }   from '@fortawesome/pro-regular-svg-icons';
import { faTaxi as fasTaxi }   from '@fortawesome/pro-solid-svg-icons';
import { faTaxiBus as fadTaxiBus }   from '@fortawesome/pro-duotone-svg-icons';
import { faTaxiBus as falTaxiBus }   from '@fortawesome/pro-light-svg-icons';
import { faTaxiBus as farTaxiBus }   from '@fortawesome/pro-regular-svg-icons';
import { faTaxiBus as fasTaxiBus }   from '@fortawesome/pro-solid-svg-icons';
import { faTeddyBear as fadTeddyBear }   from '@fortawesome/pro-duotone-svg-icons';
import { faTeddyBear as falTeddyBear }   from '@fortawesome/pro-light-svg-icons';
import { faTeddyBear as farTeddyBear }   from '@fortawesome/pro-regular-svg-icons';
import { faTeddyBear as fasTeddyBear }   from '@fortawesome/pro-solid-svg-icons';
import { faTeeth as fadTeeth }   from '@fortawesome/pro-duotone-svg-icons';
import { faTeeth as falTeeth }   from '@fortawesome/pro-light-svg-icons';
import { faTeeth as farTeeth }   from '@fortawesome/pro-regular-svg-icons';
import { faTeeth as fasTeeth }   from '@fortawesome/pro-solid-svg-icons';
import { faTeethOpen as fadTeethOpen }   from '@fortawesome/pro-duotone-svg-icons';
import { faTeethOpen as falTeethOpen }   from '@fortawesome/pro-light-svg-icons';
import { faTeethOpen as farTeethOpen }   from '@fortawesome/pro-regular-svg-icons';
import { faTeethOpen as fasTeethOpen }   from '@fortawesome/pro-solid-svg-icons';
import { faTelescope as fadTelescope }   from '@fortawesome/pro-duotone-svg-icons';
import { faTelescope as falTelescope }   from '@fortawesome/pro-light-svg-icons';
import { faTelescope as farTelescope }   from '@fortawesome/pro-regular-svg-icons';
import { faTelescope as fasTelescope }   from '@fortawesome/pro-solid-svg-icons';
import { faTeletype as fadTeletype }   from '@fortawesome/pro-duotone-svg-icons';
import { faTeletype as falTeletype }   from '@fortawesome/pro-light-svg-icons';
import { faTeletype as farTeletype }   from '@fortawesome/pro-regular-svg-icons';
import { faTeletype as fasTeletype }   from '@fortawesome/pro-solid-svg-icons';
import { faTeletypeAnswer as fadTeletypeAnswer }   from '@fortawesome/pro-duotone-svg-icons';
import { faTeletypeAnswer as falTeletypeAnswer }   from '@fortawesome/pro-light-svg-icons';
import { faTeletypeAnswer as farTeletypeAnswer }   from '@fortawesome/pro-regular-svg-icons';
import { faTeletypeAnswer as fasTeletypeAnswer }   from '@fortawesome/pro-solid-svg-icons';
import { faTelevision as fadTelevision }   from '@fortawesome/pro-duotone-svg-icons';
import { faTelevision as falTelevision }   from '@fortawesome/pro-light-svg-icons';
import { faTelevision as farTelevision }   from '@fortawesome/pro-regular-svg-icons';
import { faTelevision as fasTelevision }   from '@fortawesome/pro-solid-svg-icons';
import { faTemperature0 as fadTemperature0 }   from '@fortawesome/pro-duotone-svg-icons';
import { faTemperature0 as falTemperature0 }   from '@fortawesome/pro-light-svg-icons';
import { faTemperature0 as farTemperature0 }   from '@fortawesome/pro-regular-svg-icons';
import { faTemperature0 as fasTemperature0 }   from '@fortawesome/pro-solid-svg-icons';
import { faTemperature1 as fadTemperature1 }   from '@fortawesome/pro-duotone-svg-icons';
import { faTemperature1 as falTemperature1 }   from '@fortawesome/pro-light-svg-icons';
import { faTemperature1 as farTemperature1 }   from '@fortawesome/pro-regular-svg-icons';
import { faTemperature1 as fasTemperature1 }   from '@fortawesome/pro-solid-svg-icons';
import { faTemperature2 as fadTemperature2 }   from '@fortawesome/pro-duotone-svg-icons';
import { faTemperature2 as falTemperature2 }   from '@fortawesome/pro-light-svg-icons';
import { faTemperature2 as farTemperature2 }   from '@fortawesome/pro-regular-svg-icons';
import { faTemperature2 as fasTemperature2 }   from '@fortawesome/pro-solid-svg-icons';
import { faTemperature3 as fadTemperature3 }   from '@fortawesome/pro-duotone-svg-icons';
import { faTemperature3 as falTemperature3 }   from '@fortawesome/pro-light-svg-icons';
import { faTemperature3 as farTemperature3 }   from '@fortawesome/pro-regular-svg-icons';
import { faTemperature3 as fasTemperature3 }   from '@fortawesome/pro-solid-svg-icons';
import { faTemperature4 as fadTemperature4 }   from '@fortawesome/pro-duotone-svg-icons';
import { faTemperature4 as falTemperature4 }   from '@fortawesome/pro-light-svg-icons';
import { faTemperature4 as farTemperature4 }   from '@fortawesome/pro-regular-svg-icons';
import { faTemperature4 as fasTemperature4 }   from '@fortawesome/pro-solid-svg-icons';
import { faTemperatureArrowDown as fadTemperatureArrowDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faTemperatureArrowDown as falTemperatureArrowDown }   from '@fortawesome/pro-light-svg-icons';
import { faTemperatureArrowDown as farTemperatureArrowDown }   from '@fortawesome/pro-regular-svg-icons';
import { faTemperatureArrowDown as fasTemperatureArrowDown }   from '@fortawesome/pro-solid-svg-icons';
import { faTemperatureArrowUp as fadTemperatureArrowUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faTemperatureArrowUp as falTemperatureArrowUp }   from '@fortawesome/pro-light-svg-icons';
import { faTemperatureArrowUp as farTemperatureArrowUp }   from '@fortawesome/pro-regular-svg-icons';
import { faTemperatureArrowUp as fasTemperatureArrowUp }   from '@fortawesome/pro-solid-svg-icons';
import { faTemperatureDown as fadTemperatureDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faTemperatureDown as falTemperatureDown }   from '@fortawesome/pro-light-svg-icons';
import { faTemperatureDown as farTemperatureDown }   from '@fortawesome/pro-regular-svg-icons';
import { faTemperatureDown as fasTemperatureDown }   from '@fortawesome/pro-solid-svg-icons';
import { faTemperatureEmpty as fadTemperatureEmpty }   from '@fortawesome/pro-duotone-svg-icons';
import { faTemperatureEmpty as falTemperatureEmpty }   from '@fortawesome/pro-light-svg-icons';
import { faTemperatureEmpty as farTemperatureEmpty }   from '@fortawesome/pro-regular-svg-icons';
import { faTemperatureEmpty as fasTemperatureEmpty }   from '@fortawesome/pro-solid-svg-icons';
import { faTemperatureFrigid as fadTemperatureFrigid }   from '@fortawesome/pro-duotone-svg-icons';
import { faTemperatureFrigid as falTemperatureFrigid }   from '@fortawesome/pro-light-svg-icons';
import { faTemperatureFrigid as farTemperatureFrigid }   from '@fortawesome/pro-regular-svg-icons';
import { faTemperatureFrigid as fasTemperatureFrigid }   from '@fortawesome/pro-solid-svg-icons';
import { faTemperatureFull as fadTemperatureFull }   from '@fortawesome/pro-duotone-svg-icons';
import { faTemperatureFull as falTemperatureFull }   from '@fortawesome/pro-light-svg-icons';
import { faTemperatureFull as farTemperatureFull }   from '@fortawesome/pro-regular-svg-icons';
import { faTemperatureFull as fasTemperatureFull }   from '@fortawesome/pro-solid-svg-icons';
import { faTemperatureHalf as fadTemperatureHalf }   from '@fortawesome/pro-duotone-svg-icons';
import { faTemperatureHalf as falTemperatureHalf }   from '@fortawesome/pro-light-svg-icons';
import { faTemperatureHalf as farTemperatureHalf }   from '@fortawesome/pro-regular-svg-icons';
import { faTemperatureHalf as fasTemperatureHalf }   from '@fortawesome/pro-solid-svg-icons';
import { faTemperatureHigh as fadTemperatureHigh }   from '@fortawesome/pro-duotone-svg-icons';
import { faTemperatureHigh as falTemperatureHigh }   from '@fortawesome/pro-light-svg-icons';
import { faTemperatureHigh as farTemperatureHigh }   from '@fortawesome/pro-regular-svg-icons';
import { faTemperatureHigh as fasTemperatureHigh }   from '@fortawesome/pro-solid-svg-icons';
import { faTemperatureHot as fadTemperatureHot }   from '@fortawesome/pro-duotone-svg-icons';
import { faTemperatureHot as falTemperatureHot }   from '@fortawesome/pro-light-svg-icons';
import { faTemperatureHot as farTemperatureHot }   from '@fortawesome/pro-regular-svg-icons';
import { faTemperatureHot as fasTemperatureHot }   from '@fortawesome/pro-solid-svg-icons';
import { faTemperatureList as fadTemperatureList }   from '@fortawesome/pro-duotone-svg-icons';
import { faTemperatureList as falTemperatureList }   from '@fortawesome/pro-light-svg-icons';
import { faTemperatureList as farTemperatureList }   from '@fortawesome/pro-regular-svg-icons';
import { faTemperatureList as fasTemperatureList }   from '@fortawesome/pro-solid-svg-icons';
import { faTemperatureLow as fadTemperatureLow }   from '@fortawesome/pro-duotone-svg-icons';
import { faTemperatureLow as falTemperatureLow }   from '@fortawesome/pro-light-svg-icons';
import { faTemperatureLow as farTemperatureLow }   from '@fortawesome/pro-regular-svg-icons';
import { faTemperatureLow as fasTemperatureLow }   from '@fortawesome/pro-solid-svg-icons';
import { faTemperatureQuarter as fadTemperatureQuarter }   from '@fortawesome/pro-duotone-svg-icons';
import { faTemperatureQuarter as falTemperatureQuarter }   from '@fortawesome/pro-light-svg-icons';
import { faTemperatureQuarter as farTemperatureQuarter }   from '@fortawesome/pro-regular-svg-icons';
import { faTemperatureQuarter as fasTemperatureQuarter }   from '@fortawesome/pro-solid-svg-icons';
import { faTemperatureSnow as fadTemperatureSnow }   from '@fortawesome/pro-duotone-svg-icons';
import { faTemperatureSnow as falTemperatureSnow }   from '@fortawesome/pro-light-svg-icons';
import { faTemperatureSnow as farTemperatureSnow }   from '@fortawesome/pro-regular-svg-icons';
import { faTemperatureSnow as fasTemperatureSnow }   from '@fortawesome/pro-solid-svg-icons';
import { faTemperatureSun as fadTemperatureSun }   from '@fortawesome/pro-duotone-svg-icons';
import { faTemperatureSun as falTemperatureSun }   from '@fortawesome/pro-light-svg-icons';
import { faTemperatureSun as farTemperatureSun }   from '@fortawesome/pro-regular-svg-icons';
import { faTemperatureSun as fasTemperatureSun }   from '@fortawesome/pro-solid-svg-icons';
import { faTemperatureThreeQuarters as fadTemperatureThreeQuarters }   from '@fortawesome/pro-duotone-svg-icons';
import { faTemperatureThreeQuarters as falTemperatureThreeQuarters }   from '@fortawesome/pro-light-svg-icons';
import { faTemperatureThreeQuarters as farTemperatureThreeQuarters }   from '@fortawesome/pro-regular-svg-icons';
import { faTemperatureThreeQuarters as fasTemperatureThreeQuarters }   from '@fortawesome/pro-solid-svg-icons';
import { faTemperatureUp as fadTemperatureUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faTemperatureUp as falTemperatureUp }   from '@fortawesome/pro-light-svg-icons';
import { faTemperatureUp as farTemperatureUp }   from '@fortawesome/pro-regular-svg-icons';
import { faTemperatureUp as fasTemperatureUp }   from '@fortawesome/pro-solid-svg-icons';
import { faTenge as fadTenge }   from '@fortawesome/pro-duotone-svg-icons';
import { faTenge as falTenge }   from '@fortawesome/pro-light-svg-icons';
import { faTenge as farTenge }   from '@fortawesome/pro-regular-svg-icons';
import { faTenge as fasTenge }   from '@fortawesome/pro-solid-svg-icons';
import { faTengeSign as fadTengeSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faTengeSign as falTengeSign }   from '@fortawesome/pro-light-svg-icons';
import { faTengeSign as farTengeSign }   from '@fortawesome/pro-regular-svg-icons';
import { faTengeSign as fasTengeSign }   from '@fortawesome/pro-solid-svg-icons';
import { faTennisBall as fadTennisBall }   from '@fortawesome/pro-duotone-svg-icons';
import { faTennisBall as falTennisBall }   from '@fortawesome/pro-light-svg-icons';
import { faTennisBall as farTennisBall }   from '@fortawesome/pro-regular-svg-icons';
import { faTennisBall as fasTennisBall }   from '@fortawesome/pro-solid-svg-icons';
import { faTent as fadTent }   from '@fortawesome/pro-duotone-svg-icons';
import { faTent as falTent }   from '@fortawesome/pro-light-svg-icons';
import { faTent as farTent }   from '@fortawesome/pro-regular-svg-icons';
import { faTent as fasTent }   from '@fortawesome/pro-solid-svg-icons';
import { faTentArrowDownToLine as fadTentArrowDownToLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faTentArrowDownToLine as falTentArrowDownToLine }   from '@fortawesome/pro-light-svg-icons';
import { faTentArrowDownToLine as farTentArrowDownToLine }   from '@fortawesome/pro-regular-svg-icons';
import { faTentArrowDownToLine as fasTentArrowDownToLine }   from '@fortawesome/pro-solid-svg-icons';
import { faTentArrowLeftRight as fadTentArrowLeftRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faTentArrowLeftRight as falTentArrowLeftRight }   from '@fortawesome/pro-light-svg-icons';
import { faTentArrowLeftRight as farTentArrowLeftRight }   from '@fortawesome/pro-regular-svg-icons';
import { faTentArrowLeftRight as fasTentArrowLeftRight }   from '@fortawesome/pro-solid-svg-icons';
import { faTentArrowsDown as fadTentArrowsDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faTentArrowsDown as falTentArrowsDown }   from '@fortawesome/pro-light-svg-icons';
import { faTentArrowsDown as farTentArrowsDown }   from '@fortawesome/pro-regular-svg-icons';
import { faTentArrowsDown as fasTentArrowsDown }   from '@fortawesome/pro-solid-svg-icons';
import { faTentArrowTurnLeft as fadTentArrowTurnLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faTentArrowTurnLeft as falTentArrowTurnLeft }   from '@fortawesome/pro-light-svg-icons';
import { faTentArrowTurnLeft as farTentArrowTurnLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faTentArrowTurnLeft as fasTentArrowTurnLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faTents as fadTents }   from '@fortawesome/pro-duotone-svg-icons';
import { faTents as falTents }   from '@fortawesome/pro-light-svg-icons';
import { faTents as farTents }   from '@fortawesome/pro-regular-svg-icons';
import { faTents as fasTents }   from '@fortawesome/pro-solid-svg-icons';
import { faTerminal as fadTerminal }   from '@fortawesome/pro-duotone-svg-icons';
import { faTerminal as falTerminal }   from '@fortawesome/pro-light-svg-icons';
import { faTerminal as farTerminal }   from '@fortawesome/pro-regular-svg-icons';
import { faTerminal as fasTerminal }   from '@fortawesome/pro-solid-svg-icons';
import { faText as fadText }   from '@fortawesome/pro-duotone-svg-icons';
import { faText as falText }   from '@fortawesome/pro-light-svg-icons';
import { faText as farText }   from '@fortawesome/pro-regular-svg-icons';
import { faText as fasText }   from '@fortawesome/pro-solid-svg-icons';
import { faTextHeight as fadTextHeight }   from '@fortawesome/pro-duotone-svg-icons';
import { faTextHeight as falTextHeight }   from '@fortawesome/pro-light-svg-icons';
import { faTextHeight as farTextHeight }   from '@fortawesome/pro-regular-svg-icons';
import { faTextHeight as fasTextHeight }   from '@fortawesome/pro-solid-svg-icons';
import { faTextSize as fadTextSize }   from '@fortawesome/pro-duotone-svg-icons';
import { faTextSize as falTextSize }   from '@fortawesome/pro-light-svg-icons';
import { faTextSize as farTextSize }   from '@fortawesome/pro-regular-svg-icons';
import { faTextSize as fasTextSize }   from '@fortawesome/pro-solid-svg-icons';
import { faTextSlash as fadTextSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faTextSlash as falTextSlash }   from '@fortawesome/pro-light-svg-icons';
import { faTextSlash as farTextSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faTextSlash as fasTextSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faTextWidth as fadTextWidth }   from '@fortawesome/pro-duotone-svg-icons';
import { faTextWidth as falTextWidth }   from '@fortawesome/pro-light-svg-icons';
import { faTextWidth as farTextWidth }   from '@fortawesome/pro-regular-svg-icons';
import { faTextWidth as fasTextWidth }   from '@fortawesome/pro-solid-svg-icons';
import { faTh as fadTh }   from '@fortawesome/pro-duotone-svg-icons';
import { faTh as falTh }   from '@fortawesome/pro-light-svg-icons';
import { faTh as farTh }   from '@fortawesome/pro-regular-svg-icons';
import { faTh as fasTh }   from '@fortawesome/pro-solid-svg-icons';
import { faTheaterMasks as fadTheaterMasks }   from '@fortawesome/pro-duotone-svg-icons';
import { faTheaterMasks as falTheaterMasks }   from '@fortawesome/pro-light-svg-icons';
import { faTheaterMasks as farTheaterMasks }   from '@fortawesome/pro-regular-svg-icons';
import { faTheaterMasks as fasTheaterMasks }   from '@fortawesome/pro-solid-svg-icons';
import { faThermometer as fadThermometer }   from '@fortawesome/pro-duotone-svg-icons';
import { faThermometer as falThermometer }   from '@fortawesome/pro-light-svg-icons';
import { faThermometer as farThermometer }   from '@fortawesome/pro-regular-svg-icons';
import { faThermometer as fasThermometer }   from '@fortawesome/pro-solid-svg-icons';
import { faThermometer0 as fadThermometer0 }   from '@fortawesome/pro-duotone-svg-icons';
import { faThermometer0 as falThermometer0 }   from '@fortawesome/pro-light-svg-icons';
import { faThermometer0 as farThermometer0 }   from '@fortawesome/pro-regular-svg-icons';
import { faThermometer0 as fasThermometer0 }   from '@fortawesome/pro-solid-svg-icons';
import { faThermometer1 as fadThermometer1 }   from '@fortawesome/pro-duotone-svg-icons';
import { faThermometer1 as falThermometer1 }   from '@fortawesome/pro-light-svg-icons';
import { faThermometer1 as farThermometer1 }   from '@fortawesome/pro-regular-svg-icons';
import { faThermometer1 as fasThermometer1 }   from '@fortawesome/pro-solid-svg-icons';
import { faThermometer2 as fadThermometer2 }   from '@fortawesome/pro-duotone-svg-icons';
import { faThermometer2 as falThermometer2 }   from '@fortawesome/pro-light-svg-icons';
import { faThermometer2 as farThermometer2 }   from '@fortawesome/pro-regular-svg-icons';
import { faThermometer2 as fasThermometer2 }   from '@fortawesome/pro-solid-svg-icons';
import { faThermometer3 as fadThermometer3 }   from '@fortawesome/pro-duotone-svg-icons';
import { faThermometer3 as falThermometer3 }   from '@fortawesome/pro-light-svg-icons';
import { faThermometer3 as farThermometer3 }   from '@fortawesome/pro-regular-svg-icons';
import { faThermometer3 as fasThermometer3 }   from '@fortawesome/pro-solid-svg-icons';
import { faThermometer4 as fadThermometer4 }   from '@fortawesome/pro-duotone-svg-icons';
import { faThermometer4 as falThermometer4 }   from '@fortawesome/pro-light-svg-icons';
import { faThermometer4 as farThermometer4 }   from '@fortawesome/pro-regular-svg-icons';
import { faThermometer4 as fasThermometer4 }   from '@fortawesome/pro-solid-svg-icons';
import { faThermometerEmpty as fadThermometerEmpty }   from '@fortawesome/pro-duotone-svg-icons';
import { faThermometerEmpty as falThermometerEmpty }   from '@fortawesome/pro-light-svg-icons';
import { faThermometerEmpty as farThermometerEmpty }   from '@fortawesome/pro-regular-svg-icons';
import { faThermometerEmpty as fasThermometerEmpty }   from '@fortawesome/pro-solid-svg-icons';
import { faThermometerFull as fadThermometerFull }   from '@fortawesome/pro-duotone-svg-icons';
import { faThermometerFull as falThermometerFull }   from '@fortawesome/pro-light-svg-icons';
import { faThermometerFull as farThermometerFull }   from '@fortawesome/pro-regular-svg-icons';
import { faThermometerFull as fasThermometerFull }   from '@fortawesome/pro-solid-svg-icons';
import { faThermometerHalf as fadThermometerHalf }   from '@fortawesome/pro-duotone-svg-icons';
import { faThermometerHalf as falThermometerHalf }   from '@fortawesome/pro-light-svg-icons';
import { faThermometerHalf as farThermometerHalf }   from '@fortawesome/pro-regular-svg-icons';
import { faThermometerHalf as fasThermometerHalf }   from '@fortawesome/pro-solid-svg-icons';
import { faThermometerQuarter as fadThermometerQuarter }   from '@fortawesome/pro-duotone-svg-icons';
import { faThermometerQuarter as falThermometerQuarter }   from '@fortawesome/pro-light-svg-icons';
import { faThermometerQuarter as farThermometerQuarter }   from '@fortawesome/pro-regular-svg-icons';
import { faThermometerQuarter as fasThermometerQuarter }   from '@fortawesome/pro-solid-svg-icons';
import { faThermometerThreeQuarters as fadThermometerThreeQuarters }   from '@fortawesome/pro-duotone-svg-icons';
import { faThermometerThreeQuarters as falThermometerThreeQuarters }   from '@fortawesome/pro-light-svg-icons';
import { faThermometerThreeQuarters as farThermometerThreeQuarters }   from '@fortawesome/pro-regular-svg-icons';
import { faThermometerThreeQuarters as fasThermometerThreeQuarters }   from '@fortawesome/pro-solid-svg-icons';
import { faTheta as fadTheta }   from '@fortawesome/pro-duotone-svg-icons';
import { faTheta as falTheta }   from '@fortawesome/pro-light-svg-icons';
import { faTheta as farTheta }   from '@fortawesome/pro-regular-svg-icons';
import { faTheta as fasTheta }   from '@fortawesome/pro-solid-svg-icons';
import { faThLarge as fadThLarge }   from '@fortawesome/pro-duotone-svg-icons';
import { faThLarge as falThLarge }   from '@fortawesome/pro-light-svg-icons';
import { faThLarge as farThLarge }   from '@fortawesome/pro-regular-svg-icons';
import { faThLarge as fasThLarge }   from '@fortawesome/pro-solid-svg-icons';
import { faThList as fadThList }   from '@fortawesome/pro-duotone-svg-icons';
import { faThList as falThList }   from '@fortawesome/pro-light-svg-icons';
import { faThList as farThList }   from '@fortawesome/pro-regular-svg-icons';
import { faThList as fasThList }   from '@fortawesome/pro-solid-svg-icons';
import { faThoughtBubble as fadThoughtBubble }   from '@fortawesome/pro-duotone-svg-icons';
import { faThoughtBubble as falThoughtBubble }   from '@fortawesome/pro-light-svg-icons';
import { faThoughtBubble as farThoughtBubble }   from '@fortawesome/pro-regular-svg-icons';
import { faThoughtBubble as fasThoughtBubble }   from '@fortawesome/pro-solid-svg-icons';
import { faThumbsDown as fadThumbsDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faThumbsDown as falThumbsDown }   from '@fortawesome/pro-light-svg-icons';
import { faThumbsDown as farThumbsDown }   from '@fortawesome/pro-regular-svg-icons';
import { faThumbsDown as fasThumbsDown }   from '@fortawesome/pro-solid-svg-icons';
import { faThumbsUp as fadThumbsUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faThumbsUp as falThumbsUp }   from '@fortawesome/pro-light-svg-icons';
import { faThumbsUp as farThumbsUp }   from '@fortawesome/pro-regular-svg-icons';
import { faThumbsUp as fasThumbsUp }   from '@fortawesome/pro-solid-svg-icons';
import { faThumbtack as fadThumbtack }   from '@fortawesome/pro-duotone-svg-icons';
import { faThumbTack as fadThumbTack }   from '@fortawesome/pro-duotone-svg-icons';
import { faThumbtack as falThumbtack }   from '@fortawesome/pro-light-svg-icons';
import { faThumbTack as falThumbTack }   from '@fortawesome/pro-light-svg-icons';
import { faThumbtack as farThumbtack }   from '@fortawesome/pro-regular-svg-icons';
import { faThumbTack as farThumbTack }   from '@fortawesome/pro-regular-svg-icons';
import { faThumbtack as fasThumbtack }   from '@fortawesome/pro-solid-svg-icons';
import { faThumbTack as fasThumbTack }   from '@fortawesome/pro-solid-svg-icons';
import { faThunderstorm as fadThunderstorm }   from '@fortawesome/pro-duotone-svg-icons';
import { faThunderstorm as falThunderstorm }   from '@fortawesome/pro-light-svg-icons';
import { faThunderstorm as farThunderstorm }   from '@fortawesome/pro-regular-svg-icons';
import { faThunderstorm as fasThunderstorm }   from '@fortawesome/pro-solid-svg-icons';
import { faThunderstormMoon as fadThunderstormMoon }   from '@fortawesome/pro-duotone-svg-icons';
import { faThunderstormMoon as falThunderstormMoon }   from '@fortawesome/pro-light-svg-icons';
import { faThunderstormMoon as farThunderstormMoon }   from '@fortawesome/pro-regular-svg-icons';
import { faThunderstormMoon as fasThunderstormMoon }   from '@fortawesome/pro-solid-svg-icons';
import { faThunderstormSun as fadThunderstormSun }   from '@fortawesome/pro-duotone-svg-icons';
import { faThunderstormSun as falThunderstormSun }   from '@fortawesome/pro-light-svg-icons';
import { faThunderstormSun as farThunderstormSun }   from '@fortawesome/pro-regular-svg-icons';
import { faThunderstormSun as fasThunderstormSun }   from '@fortawesome/pro-solid-svg-icons';
import { faTick as fadTick }   from '@fortawesome/pro-duotone-svg-icons';
import { faTick as falTick }   from '@fortawesome/pro-light-svg-icons';
import { faTick as farTick }   from '@fortawesome/pro-regular-svg-icons';
import { faTick as fasTick }   from '@fortawesome/pro-solid-svg-icons';
import { faTicket as fadTicket }   from '@fortawesome/pro-duotone-svg-icons';
import { faTicket as falTicket }   from '@fortawesome/pro-light-svg-icons';
import { faTicket as farTicket }   from '@fortawesome/pro-regular-svg-icons';
import { faTicket as fasTicket }   from '@fortawesome/pro-solid-svg-icons';
import { faTicketAirline as fadTicketAirline }   from '@fortawesome/pro-duotone-svg-icons';
import { faTicketAirline as falTicketAirline }   from '@fortawesome/pro-light-svg-icons';
import { faTicketAirline as farTicketAirline }   from '@fortawesome/pro-regular-svg-icons';
import { faTicketAirline as fasTicketAirline }   from '@fortawesome/pro-solid-svg-icons';
import { faTicketAlt as fadTicketAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faTicketAlt as falTicketAlt }   from '@fortawesome/pro-light-svg-icons';
import { faTicketAlt as farTicketAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faTicketAlt as fasTicketAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faTicketsAirline as fadTicketsAirline }   from '@fortawesome/pro-duotone-svg-icons';
import { faTicketsAirline as falTicketsAirline }   from '@fortawesome/pro-light-svg-icons';
import { faTicketsAirline as farTicketsAirline }   from '@fortawesome/pro-regular-svg-icons';
import { faTicketsAirline as fasTicketsAirline }   from '@fortawesome/pro-solid-svg-icons';
import { faTicketSimple as fadTicketSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faTicketSimple as falTicketSimple }   from '@fortawesome/pro-light-svg-icons';
import { faTicketSimple as farTicketSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faTicketSimple as fasTicketSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faTilde as fadTilde }   from '@fortawesome/pro-duotone-svg-icons';
import { faTilde as falTilde }   from '@fortawesome/pro-light-svg-icons';
import { faTilde as farTilde }   from '@fortawesome/pro-regular-svg-icons';
import { faTilde as fasTilde }   from '@fortawesome/pro-solid-svg-icons';
import { faTimeline as fadTimeline }   from '@fortawesome/pro-duotone-svg-icons';
import { faTimeline as falTimeline }   from '@fortawesome/pro-light-svg-icons';
import { faTimeline as farTimeline }   from '@fortawesome/pro-regular-svg-icons';
import { faTimeline as fasTimeline }   from '@fortawesome/pro-solid-svg-icons';
import { faTimelineArrow as fadTimelineArrow }   from '@fortawesome/pro-duotone-svg-icons';
import { faTimelineArrow as falTimelineArrow }   from '@fortawesome/pro-light-svg-icons';
import { faTimelineArrow as farTimelineArrow }   from '@fortawesome/pro-regular-svg-icons';
import { faTimelineArrow as fasTimelineArrow }   from '@fortawesome/pro-solid-svg-icons';
import { faTimer as fadTimer }   from '@fortawesome/pro-duotone-svg-icons';
import { faTimer as falTimer }   from '@fortawesome/pro-light-svg-icons';
import { faTimer as farTimer }   from '@fortawesome/pro-regular-svg-icons';
import { faTimer as fasTimer }   from '@fortawesome/pro-solid-svg-icons';
import { faTimes as fadTimes }   from '@fortawesome/pro-duotone-svg-icons';
import { faTimes as falTimes }   from '@fortawesome/pro-light-svg-icons';
import { faTimes as farTimes }   from '@fortawesome/pro-regular-svg-icons';
import { faTimes as fasTimes }   from '@fortawesome/pro-solid-svg-icons';
import { faTimesCircle as fadTimesCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faTimesCircle as falTimesCircle }   from '@fortawesome/pro-light-svg-icons';
import { faTimesCircle as farTimesCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faTimesCircle as fasTimesCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faTimesHexagon as fadTimesHexagon }   from '@fortawesome/pro-duotone-svg-icons';
import { faTimesHexagon as falTimesHexagon }   from '@fortawesome/pro-light-svg-icons';
import { faTimesHexagon as farTimesHexagon }   from '@fortawesome/pro-regular-svg-icons';
import { faTimesHexagon as fasTimesHexagon }   from '@fortawesome/pro-solid-svg-icons';
import { faTimesOctagon as fadTimesOctagon }   from '@fortawesome/pro-duotone-svg-icons';
import { faTimesOctagon as falTimesOctagon }   from '@fortawesome/pro-light-svg-icons';
import { faTimesOctagon as farTimesOctagon }   from '@fortawesome/pro-regular-svg-icons';
import { faTimesOctagon as fasTimesOctagon }   from '@fortawesome/pro-solid-svg-icons';
import { faTimesRectangle as fadTimesRectangle }   from '@fortawesome/pro-duotone-svg-icons';
import { faTimesRectangle as falTimesRectangle }   from '@fortawesome/pro-light-svg-icons';
import { faTimesRectangle as farTimesRectangle }   from '@fortawesome/pro-regular-svg-icons';
import { faTimesRectangle as fasTimesRectangle }   from '@fortawesome/pro-solid-svg-icons';
import { faTimesSquare as fadTimesSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faTimesSquare as falTimesSquare }   from '@fortawesome/pro-light-svg-icons';
import { faTimesSquare as farTimesSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faTimesSquare as fasTimesSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faTimesToSlot as fadTimesToSlot }   from '@fortawesome/pro-duotone-svg-icons';
import { faTimesToSlot as falTimesToSlot }   from '@fortawesome/pro-light-svg-icons';
import { faTimesToSlot as farTimesToSlot }   from '@fortawesome/pro-regular-svg-icons';
import { faTimesToSlot as fasTimesToSlot }   from '@fortawesome/pro-solid-svg-icons';
import { faTint as fadTint }   from '@fortawesome/pro-duotone-svg-icons';
import { faTint as falTint }   from '@fortawesome/pro-light-svg-icons';
import { faTint as farTint }   from '@fortawesome/pro-regular-svg-icons';
import { faTint as fasTint }   from '@fortawesome/pro-solid-svg-icons';
import { faTintSlash as fadTintSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faTintSlash as falTintSlash }   from '@fortawesome/pro-light-svg-icons';
import { faTintSlash as farTintSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faTintSlash as fasTintSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faTire as fadTire }   from '@fortawesome/pro-duotone-svg-icons';
import { faTire as falTire }   from '@fortawesome/pro-light-svg-icons';
import { faTire as farTire }   from '@fortawesome/pro-regular-svg-icons';
import { faTire as fasTire }   from '@fortawesome/pro-solid-svg-icons';
import { faTired as fadTired }   from '@fortawesome/pro-duotone-svg-icons';
import { faTired as falTired }   from '@fortawesome/pro-light-svg-icons';
import { faTired as farTired }   from '@fortawesome/pro-regular-svg-icons';
import { faTired as fasTired }   from '@fortawesome/pro-solid-svg-icons';
import { faTireFlat as fadTireFlat }   from '@fortawesome/pro-duotone-svg-icons';
import { faTireFlat as falTireFlat }   from '@fortawesome/pro-light-svg-icons';
import { faTireFlat as farTireFlat }   from '@fortawesome/pro-regular-svg-icons';
import { faTireFlat as fasTireFlat }   from '@fortawesome/pro-solid-svg-icons';
import { faTirePressureWarning as fadTirePressureWarning }   from '@fortawesome/pro-duotone-svg-icons';
import { faTirePressureWarning as falTirePressureWarning }   from '@fortawesome/pro-light-svg-icons';
import { faTirePressureWarning as farTirePressureWarning }   from '@fortawesome/pro-regular-svg-icons';
import { faTirePressureWarning as fasTirePressureWarning }   from '@fortawesome/pro-solid-svg-icons';
import { faTireRugged as fadTireRugged }   from '@fortawesome/pro-duotone-svg-icons';
import { faTireRugged as falTireRugged }   from '@fortawesome/pro-light-svg-icons';
import { faTireRugged as farTireRugged }   from '@fortawesome/pro-regular-svg-icons';
import { faTireRugged as fasTireRugged }   from '@fortawesome/pro-solid-svg-icons';
import { faToggleOff as fadToggleOff }   from '@fortawesome/pro-duotone-svg-icons';
import { faToggleOff as falToggleOff }   from '@fortawesome/pro-light-svg-icons';
import { faToggleOff as farToggleOff }   from '@fortawesome/pro-regular-svg-icons';
import { faToggleOff as fasToggleOff }   from '@fortawesome/pro-solid-svg-icons';
import { faToggleOn as fadToggleOn }   from '@fortawesome/pro-duotone-svg-icons';
import { faToggleOn as falToggleOn }   from '@fortawesome/pro-light-svg-icons';
import { faToggleOn as farToggleOn }   from '@fortawesome/pro-regular-svg-icons';
import { faToggleOn as fasToggleOn }   from '@fortawesome/pro-solid-svg-icons';
import { faToilet as fadToilet }   from '@fortawesome/pro-duotone-svg-icons';
import { faToilet as falToilet }   from '@fortawesome/pro-light-svg-icons';
import { faToilet as farToilet }   from '@fortawesome/pro-regular-svg-icons';
import { faToilet as fasToilet }   from '@fortawesome/pro-solid-svg-icons';
import { faToiletPaper as fadToiletPaper }   from '@fortawesome/pro-duotone-svg-icons';
import { faToiletPaper as falToiletPaper }   from '@fortawesome/pro-light-svg-icons';
import { faToiletPaper as farToiletPaper }   from '@fortawesome/pro-regular-svg-icons';
import { faToiletPaper as fasToiletPaper }   from '@fortawesome/pro-solid-svg-icons';
import { faToiletPaperAlt as fadToiletPaperAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faToiletPaperAlt as falToiletPaperAlt }   from '@fortawesome/pro-light-svg-icons';
import { faToiletPaperAlt as farToiletPaperAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faToiletPaperAlt as fasToiletPaperAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faToiletPaperBlank as fadToiletPaperBlank }   from '@fortawesome/pro-duotone-svg-icons';
import { faToiletPaperBlank as falToiletPaperBlank }   from '@fortawesome/pro-light-svg-icons';
import { faToiletPaperBlank as farToiletPaperBlank }   from '@fortawesome/pro-regular-svg-icons';
import { faToiletPaperBlank as fasToiletPaperBlank }   from '@fortawesome/pro-solid-svg-icons';
import { faToiletPaperBlankUnder as fadToiletPaperBlankUnder }   from '@fortawesome/pro-duotone-svg-icons';
import { faToiletPaperBlankUnder as falToiletPaperBlankUnder }   from '@fortawesome/pro-light-svg-icons';
import { faToiletPaperBlankUnder as farToiletPaperBlankUnder }   from '@fortawesome/pro-regular-svg-icons';
import { faToiletPaperBlankUnder as fasToiletPaperBlankUnder }   from '@fortawesome/pro-solid-svg-icons';
import { faToiletPaperReverse as fadToiletPaperReverse }   from '@fortawesome/pro-duotone-svg-icons';
import { faToiletPaperReverse as falToiletPaperReverse }   from '@fortawesome/pro-light-svg-icons';
import { faToiletPaperReverse as farToiletPaperReverse }   from '@fortawesome/pro-regular-svg-icons';
import { faToiletPaperReverse as fasToiletPaperReverse }   from '@fortawesome/pro-solid-svg-icons';
import { faToiletPaperReverseAlt as fadToiletPaperReverseAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faToiletPaperReverseAlt as falToiletPaperReverseAlt }   from '@fortawesome/pro-light-svg-icons';
import { faToiletPaperReverseAlt as farToiletPaperReverseAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faToiletPaperReverseAlt as fasToiletPaperReverseAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faToiletPaperReverseSlash as fadToiletPaperReverseSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faToiletPaperReverseSlash as falToiletPaperReverseSlash }   from '@fortawesome/pro-light-svg-icons';
import { faToiletPaperReverseSlash as farToiletPaperReverseSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faToiletPaperReverseSlash as fasToiletPaperReverseSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faToiletPaperSlash as fadToiletPaperSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faToiletPaperSlash as falToiletPaperSlash }   from '@fortawesome/pro-light-svg-icons';
import { faToiletPaperSlash as farToiletPaperSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faToiletPaperSlash as fasToiletPaperSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faToiletPaperUnder as fadToiletPaperUnder }   from '@fortawesome/pro-duotone-svg-icons';
import { faToiletPaperUnder as falToiletPaperUnder }   from '@fortawesome/pro-light-svg-icons';
import { faToiletPaperUnder as farToiletPaperUnder }   from '@fortawesome/pro-regular-svg-icons';
import { faToiletPaperUnder as fasToiletPaperUnder }   from '@fortawesome/pro-solid-svg-icons';
import { faToiletPaperUnderSlash as fadToiletPaperUnderSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faToiletPaperUnderSlash as falToiletPaperUnderSlash }   from '@fortawesome/pro-light-svg-icons';
import { faToiletPaperUnderSlash as farToiletPaperUnderSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faToiletPaperUnderSlash as fasToiletPaperUnderSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faToiletPortable as fadToiletPortable }   from '@fortawesome/pro-duotone-svg-icons';
import { faToiletPortable as falToiletPortable }   from '@fortawesome/pro-light-svg-icons';
import { faToiletPortable as farToiletPortable }   from '@fortawesome/pro-regular-svg-icons';
import { faToiletPortable as fasToiletPortable }   from '@fortawesome/pro-solid-svg-icons';
import { faToiletsPortable as fadToiletsPortable }   from '@fortawesome/pro-duotone-svg-icons';
import { faToiletsPortable as falToiletsPortable }   from '@fortawesome/pro-light-svg-icons';
import { faToiletsPortable as farToiletsPortable }   from '@fortawesome/pro-regular-svg-icons';
import { faToiletsPortable as fasToiletsPortable }   from '@fortawesome/pro-solid-svg-icons';
import { faTomato as fadTomato }   from '@fortawesome/pro-duotone-svg-icons';
import { faTomato as falTomato }   from '@fortawesome/pro-light-svg-icons';
import { faTomato as farTomato }   from '@fortawesome/pro-regular-svg-icons';
import { faTomato as fasTomato }   from '@fortawesome/pro-solid-svg-icons';
import { faTombstone as fadTombstone }   from '@fortawesome/pro-duotone-svg-icons';
import { faTombstone as falTombstone }   from '@fortawesome/pro-light-svg-icons';
import { faTombstone as farTombstone }   from '@fortawesome/pro-regular-svg-icons';
import { faTombstone as fasTombstone }   from '@fortawesome/pro-solid-svg-icons';
import { faTombstoneAlt as fadTombstoneAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faTombstoneAlt as falTombstoneAlt }   from '@fortawesome/pro-light-svg-icons';
import { faTombstoneAlt as farTombstoneAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faTombstoneAlt as fasTombstoneAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faTombstoneBlank as fadTombstoneBlank }   from '@fortawesome/pro-duotone-svg-icons';
import { faTombstoneBlank as falTombstoneBlank }   from '@fortawesome/pro-light-svg-icons';
import { faTombstoneBlank as farTombstoneBlank }   from '@fortawesome/pro-regular-svg-icons';
import { faTombstoneBlank as fasTombstoneBlank }   from '@fortawesome/pro-solid-svg-icons';
import { faToolbox as fadToolbox }   from '@fortawesome/pro-duotone-svg-icons';
import { faToolbox as falToolbox }   from '@fortawesome/pro-light-svg-icons';
import { faToolbox as farToolbox }   from '@fortawesome/pro-regular-svg-icons';
import { faToolbox as fasToolbox }   from '@fortawesome/pro-solid-svg-icons';
import { faTools as fadTools }   from '@fortawesome/pro-duotone-svg-icons';
import { faTools as falTools }   from '@fortawesome/pro-light-svg-icons';
import { faTools as farTools }   from '@fortawesome/pro-regular-svg-icons';
import { faTools as fasTools }   from '@fortawesome/pro-solid-svg-icons';
import { faTooth as fadTooth }   from '@fortawesome/pro-duotone-svg-icons';
import { faTooth as falTooth }   from '@fortawesome/pro-light-svg-icons';
import { faTooth as farTooth }   from '@fortawesome/pro-regular-svg-icons';
import { faTooth as fasTooth }   from '@fortawesome/pro-solid-svg-icons';
import { faToothbrush as fadToothbrush }   from '@fortawesome/pro-duotone-svg-icons';
import { faToothbrush as falToothbrush }   from '@fortawesome/pro-light-svg-icons';
import { faToothbrush as farToothbrush }   from '@fortawesome/pro-regular-svg-icons';
import { faToothbrush as fasToothbrush }   from '@fortawesome/pro-solid-svg-icons';
import { faTorah as fadTorah }   from '@fortawesome/pro-duotone-svg-icons';
import { faTorah as falTorah }   from '@fortawesome/pro-light-svg-icons';
import { faTorah as farTorah }   from '@fortawesome/pro-regular-svg-icons';
import { faTorah as fasTorah }   from '@fortawesome/pro-solid-svg-icons';
import { faToriiGate as fadToriiGate }   from '@fortawesome/pro-duotone-svg-icons';
import { faToriiGate as falToriiGate }   from '@fortawesome/pro-light-svg-icons';
import { faToriiGate as farToriiGate }   from '@fortawesome/pro-regular-svg-icons';
import { faToriiGate as fasToriiGate }   from '@fortawesome/pro-solid-svg-icons';
import { faTornado as fadTornado }   from '@fortawesome/pro-duotone-svg-icons';
import { faTornado as falTornado }   from '@fortawesome/pro-light-svg-icons';
import { faTornado as farTornado }   from '@fortawesome/pro-regular-svg-icons';
import { faTornado as fasTornado }   from '@fortawesome/pro-solid-svg-icons';
import { faTowerBroadcast as fadTowerBroadcast }   from '@fortawesome/pro-duotone-svg-icons';
import { faTowerBroadcast as falTowerBroadcast }   from '@fortawesome/pro-light-svg-icons';
import { faTowerBroadcast as farTowerBroadcast }   from '@fortawesome/pro-regular-svg-icons';
import { faTowerBroadcast as fasTowerBroadcast }   from '@fortawesome/pro-solid-svg-icons';
import { faTowerCell as fadTowerCell }   from '@fortawesome/pro-duotone-svg-icons';
import { faTowerCell as falTowerCell }   from '@fortawesome/pro-light-svg-icons';
import { faTowerCell as farTowerCell }   from '@fortawesome/pro-regular-svg-icons';
import { faTowerCell as fasTowerCell }   from '@fortawesome/pro-solid-svg-icons';
import { faTowerControl as fadTowerControl }   from '@fortawesome/pro-duotone-svg-icons';
import { faTowerControl as falTowerControl }   from '@fortawesome/pro-light-svg-icons';
import { faTowerControl as farTowerControl }   from '@fortawesome/pro-regular-svg-icons';
import { faTowerControl as fasTowerControl }   from '@fortawesome/pro-solid-svg-icons';
import { faTowerObservation as fadTowerObservation }   from '@fortawesome/pro-duotone-svg-icons';
import { faTowerObservation as falTowerObservation }   from '@fortawesome/pro-light-svg-icons';
import { faTowerObservation as farTowerObservation }   from '@fortawesome/pro-regular-svg-icons';
import { faTowerObservation as fasTowerObservation }   from '@fortawesome/pro-solid-svg-icons';
import { faTractor as fadTractor }   from '@fortawesome/pro-duotone-svg-icons';
import { faTractor as falTractor }   from '@fortawesome/pro-light-svg-icons';
import { faTractor as farTractor }   from '@fortawesome/pro-regular-svg-icons';
import { faTractor as fasTractor }   from '@fortawesome/pro-solid-svg-icons';
import { faTrademark as fadTrademark }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrademark as falTrademark }   from '@fortawesome/pro-light-svg-icons';
import { faTrademark as farTrademark }   from '@fortawesome/pro-regular-svg-icons';
import { faTrademark as fasTrademark }   from '@fortawesome/pro-solid-svg-icons';
import { faTrafficCone as fadTrafficCone }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrafficCone as falTrafficCone }   from '@fortawesome/pro-light-svg-icons';
import { faTrafficCone as farTrafficCone }   from '@fortawesome/pro-regular-svg-icons';
import { faTrafficCone as fasTrafficCone }   from '@fortawesome/pro-solid-svg-icons';
import { faTrafficLight as fadTrafficLight }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrafficLight as falTrafficLight }   from '@fortawesome/pro-light-svg-icons';
import { faTrafficLight as farTrafficLight }   from '@fortawesome/pro-regular-svg-icons';
import { faTrafficLight as fasTrafficLight }   from '@fortawesome/pro-solid-svg-icons';
import { faTrafficLightGo as fadTrafficLightGo }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrafficLightGo as falTrafficLightGo }   from '@fortawesome/pro-light-svg-icons';
import { faTrafficLightGo as farTrafficLightGo }   from '@fortawesome/pro-regular-svg-icons';
import { faTrafficLightGo as fasTrafficLightGo }   from '@fortawesome/pro-solid-svg-icons';
import { faTrafficLightSlow as fadTrafficLightSlow }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrafficLightSlow as falTrafficLightSlow }   from '@fortawesome/pro-light-svg-icons';
import { faTrafficLightSlow as farTrafficLightSlow }   from '@fortawesome/pro-regular-svg-icons';
import { faTrafficLightSlow as fasTrafficLightSlow }   from '@fortawesome/pro-solid-svg-icons';
import { faTrafficLightStop as fadTrafficLightStop }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrafficLightStop as falTrafficLightStop }   from '@fortawesome/pro-light-svg-icons';
import { faTrafficLightStop as farTrafficLightStop }   from '@fortawesome/pro-regular-svg-icons';
import { faTrafficLightStop as fasTrafficLightStop }   from '@fortawesome/pro-solid-svg-icons';
import { faTrailer as fadTrailer }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrailer as falTrailer }   from '@fortawesome/pro-light-svg-icons';
import { faTrailer as farTrailer }   from '@fortawesome/pro-regular-svg-icons';
import { faTrailer as fasTrailer }   from '@fortawesome/pro-solid-svg-icons';
import { faTrain as fadTrain }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrain as falTrain }   from '@fortawesome/pro-light-svg-icons';
import { faTrain as farTrain }   from '@fortawesome/pro-regular-svg-icons';
import { faTrain as fasTrain }   from '@fortawesome/pro-solid-svg-icons';
import { faTrainSubway as fadTrainSubway }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrainSubway as falTrainSubway }   from '@fortawesome/pro-light-svg-icons';
import { faTrainSubway as farTrainSubway }   from '@fortawesome/pro-regular-svg-icons';
import { faTrainSubway as fasTrainSubway }   from '@fortawesome/pro-solid-svg-icons';
import { faTrainSubwayTunnel as fadTrainSubwayTunnel }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrainSubwayTunnel as falTrainSubwayTunnel }   from '@fortawesome/pro-light-svg-icons';
import { faTrainSubwayTunnel as farTrainSubwayTunnel }   from '@fortawesome/pro-regular-svg-icons';
import { faTrainSubwayTunnel as fasTrainSubwayTunnel }   from '@fortawesome/pro-solid-svg-icons';
import { faTrainTrack as fadTrainTrack }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrainTrack as falTrainTrack }   from '@fortawesome/pro-light-svg-icons';
import { faTrainTrack as farTrainTrack }   from '@fortawesome/pro-regular-svg-icons';
import { faTrainTrack as fasTrainTrack }   from '@fortawesome/pro-solid-svg-icons';
import { faTrainTram as fadTrainTram }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrainTram as falTrainTram }   from '@fortawesome/pro-light-svg-icons';
import { faTrainTram as farTrainTram }   from '@fortawesome/pro-regular-svg-icons';
import { faTrainTram as fasTrainTram }   from '@fortawesome/pro-solid-svg-icons';
import { faTrainTunnel as fadTrainTunnel }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrainTunnel as falTrainTunnel }   from '@fortawesome/pro-light-svg-icons';
import { faTrainTunnel as farTrainTunnel }   from '@fortawesome/pro-regular-svg-icons';
import { faTrainTunnel as fasTrainTunnel }   from '@fortawesome/pro-solid-svg-icons';
import { faTram as fadTram }   from '@fortawesome/pro-duotone-svg-icons';
import { faTram as falTram }   from '@fortawesome/pro-light-svg-icons';
import { faTram as farTram }   from '@fortawesome/pro-regular-svg-icons';
import { faTram as fasTram }   from '@fortawesome/pro-solid-svg-icons';
import { faTransformerBolt as fadTransformerBolt }   from '@fortawesome/pro-duotone-svg-icons';
import { faTransformerBolt as falTransformerBolt }   from '@fortawesome/pro-light-svg-icons';
import { faTransformerBolt as farTransformerBolt }   from '@fortawesome/pro-regular-svg-icons';
import { faTransformerBolt as fasTransformerBolt }   from '@fortawesome/pro-solid-svg-icons';
import { faTransgender as fadTransgender }   from '@fortawesome/pro-duotone-svg-icons';
import { faTransgender as falTransgender }   from '@fortawesome/pro-light-svg-icons';
import { faTransgender as farTransgender }   from '@fortawesome/pro-regular-svg-icons';
import { faTransgender as fasTransgender }   from '@fortawesome/pro-solid-svg-icons';
import { faTransgenderAlt as fadTransgenderAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faTransgenderAlt as falTransgenderAlt }   from '@fortawesome/pro-light-svg-icons';
import { faTransgenderAlt as farTransgenderAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faTransgenderAlt as fasTransgenderAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faTransporter as fadTransporter }   from '@fortawesome/pro-duotone-svg-icons';
import { faTransporter as falTransporter }   from '@fortawesome/pro-light-svg-icons';
import { faTransporter as farTransporter }   from '@fortawesome/pro-regular-svg-icons';
import { faTransporter as fasTransporter }   from '@fortawesome/pro-solid-svg-icons';
import { faTransporter1 as fadTransporter1 }   from '@fortawesome/pro-duotone-svg-icons';
import { faTransporter1 as falTransporter1 }   from '@fortawesome/pro-light-svg-icons';
import { faTransporter1 as farTransporter1 }   from '@fortawesome/pro-regular-svg-icons';
import { faTransporter1 as fasTransporter1 }   from '@fortawesome/pro-solid-svg-icons';
import { faTransporter2 as fadTransporter2 }   from '@fortawesome/pro-duotone-svg-icons';
import { faTransporter2 as falTransporter2 }   from '@fortawesome/pro-light-svg-icons';
import { faTransporter2 as farTransporter2 }   from '@fortawesome/pro-regular-svg-icons';
import { faTransporter2 as fasTransporter2 }   from '@fortawesome/pro-solid-svg-icons';
import { faTransporter3 as fadTransporter3 }   from '@fortawesome/pro-duotone-svg-icons';
import { faTransporter3 as falTransporter3 }   from '@fortawesome/pro-light-svg-icons';
import { faTransporter3 as farTransporter3 }   from '@fortawesome/pro-regular-svg-icons';
import { faTransporter3 as fasTransporter3 }   from '@fortawesome/pro-solid-svg-icons';
import { faTransporter4 as fadTransporter4 }   from '@fortawesome/pro-duotone-svg-icons';
import { faTransporter4 as falTransporter4 }   from '@fortawesome/pro-light-svg-icons';
import { faTransporter4 as farTransporter4 }   from '@fortawesome/pro-regular-svg-icons';
import { faTransporter4 as fasTransporter4 }   from '@fortawesome/pro-solid-svg-icons';
import { faTransporter5 as fadTransporter5 }   from '@fortawesome/pro-duotone-svg-icons';
import { faTransporter5 as falTransporter5 }   from '@fortawesome/pro-light-svg-icons';
import { faTransporter5 as farTransporter5 }   from '@fortawesome/pro-regular-svg-icons';
import { faTransporter5 as fasTransporter5 }   from '@fortawesome/pro-solid-svg-icons';
import { faTransporter6 as fadTransporter6 }   from '@fortawesome/pro-duotone-svg-icons';
import { faTransporter6 as falTransporter6 }   from '@fortawesome/pro-light-svg-icons';
import { faTransporter6 as farTransporter6 }   from '@fortawesome/pro-regular-svg-icons';
import { faTransporter6 as fasTransporter6 }   from '@fortawesome/pro-solid-svg-icons';
import { faTransporter7 as fadTransporter7 }   from '@fortawesome/pro-duotone-svg-icons';
import { faTransporter7 as falTransporter7 }   from '@fortawesome/pro-light-svg-icons';
import { faTransporter7 as farTransporter7 }   from '@fortawesome/pro-regular-svg-icons';
import { faTransporter7 as fasTransporter7 }   from '@fortawesome/pro-solid-svg-icons';
import { faTransporterEmpty as fadTransporterEmpty }   from '@fortawesome/pro-duotone-svg-icons';
import { faTransporterEmpty as falTransporterEmpty }   from '@fortawesome/pro-light-svg-icons';
import { faTransporterEmpty as farTransporterEmpty }   from '@fortawesome/pro-regular-svg-icons';
import { faTransporterEmpty as fasTransporterEmpty }   from '@fortawesome/pro-solid-svg-icons';
import { faTrash as fadTrash }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrash as falTrash }   from '@fortawesome/pro-light-svg-icons';
import { faTrash as farTrash }   from '@fortawesome/pro-regular-svg-icons';
import { faTrash as fasTrash }   from '@fortawesome/pro-solid-svg-icons';
import { faTrashAlt as fadTrashAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrashAlt as falTrashAlt }   from '@fortawesome/pro-light-svg-icons';
import { faTrashAlt as farTrashAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faTrashAlt as fasTrashAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faTrashAltSlash as fadTrashAltSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrashAltSlash as falTrashAltSlash }   from '@fortawesome/pro-light-svg-icons';
import { faTrashAltSlash as farTrashAltSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faTrashAltSlash as fasTrashAltSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faTrashArrowTurnLeft as fadTrashArrowTurnLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrashArrowTurnLeft as falTrashArrowTurnLeft }   from '@fortawesome/pro-light-svg-icons';
import { faTrashArrowTurnLeft as farTrashArrowTurnLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faTrashArrowTurnLeft as fasTrashArrowTurnLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faTrashArrowUp as fadTrashArrowUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrashArrowUp as falTrashArrowUp }   from '@fortawesome/pro-light-svg-icons';
import { faTrashArrowUp as farTrashArrowUp }   from '@fortawesome/pro-regular-svg-icons';
import { faTrashArrowUp as fasTrashArrowUp }   from '@fortawesome/pro-solid-svg-icons';
import { faTrashCan as fadTrashCan }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrashCan as falTrashCan }   from '@fortawesome/pro-light-svg-icons';
import { faTrashCan as farTrashCan }   from '@fortawesome/pro-regular-svg-icons';
import { faTrashCan as fasTrashCan }   from '@fortawesome/pro-solid-svg-icons';
import { faTrashCanArrowTurnLeft as fadTrashCanArrowTurnLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrashCanArrowTurnLeft as falTrashCanArrowTurnLeft }   from '@fortawesome/pro-light-svg-icons';
import { faTrashCanArrowTurnLeft as farTrashCanArrowTurnLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faTrashCanArrowTurnLeft as fasTrashCanArrowTurnLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faTrashCanArrowUp as fadTrashCanArrowUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrashCanArrowUp as falTrashCanArrowUp }   from '@fortawesome/pro-light-svg-icons';
import { faTrashCanArrowUp as farTrashCanArrowUp }   from '@fortawesome/pro-regular-svg-icons';
import { faTrashCanArrowUp as fasTrashCanArrowUp }   from '@fortawesome/pro-solid-svg-icons';
import { faTrashCanCheck as fadTrashCanCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrashCanCheck as falTrashCanCheck }   from '@fortawesome/pro-light-svg-icons';
import { faTrashCanCheck as farTrashCanCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faTrashCanCheck as fasTrashCanCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faTrashCanClock as fadTrashCanClock }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrashCanClock as falTrashCanClock }   from '@fortawesome/pro-light-svg-icons';
import { faTrashCanClock as farTrashCanClock }   from '@fortawesome/pro-regular-svg-icons';
import { faTrashCanClock as fasTrashCanClock }   from '@fortawesome/pro-solid-svg-icons';
import { faTrashCanList as fadTrashCanList }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrashCanList as falTrashCanList }   from '@fortawesome/pro-light-svg-icons';
import { faTrashCanList as farTrashCanList }   from '@fortawesome/pro-regular-svg-icons';
import { faTrashCanList as fasTrashCanList }   from '@fortawesome/pro-solid-svg-icons';
import { faTrashCanPlus as fadTrashCanPlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrashCanPlus as falTrashCanPlus }   from '@fortawesome/pro-light-svg-icons';
import { faTrashCanPlus as farTrashCanPlus }   from '@fortawesome/pro-regular-svg-icons';
import { faTrashCanPlus as fasTrashCanPlus }   from '@fortawesome/pro-solid-svg-icons';
import { faTrashCanSlash as fadTrashCanSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrashCanSlash as falTrashCanSlash }   from '@fortawesome/pro-light-svg-icons';
import { faTrashCanSlash as farTrashCanSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faTrashCanSlash as fasTrashCanSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faTrashCanUndo as fadTrashCanUndo }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrashCanUndo as falTrashCanUndo }   from '@fortawesome/pro-light-svg-icons';
import { faTrashCanUndo as farTrashCanUndo }   from '@fortawesome/pro-regular-svg-icons';
import { faTrashCanUndo as fasTrashCanUndo }   from '@fortawesome/pro-solid-svg-icons';
import { faTrashCanXmark as fadTrashCanXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrashCanXmark as falTrashCanXmark }   from '@fortawesome/pro-light-svg-icons';
import { faTrashCanXmark as farTrashCanXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faTrashCanXmark as fasTrashCanXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faTrashCheck as fadTrashCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrashCheck as falTrashCheck }   from '@fortawesome/pro-light-svg-icons';
import { faTrashCheck as farTrashCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faTrashCheck as fasTrashCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faTrashCircle as fadTrashCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrashCircle as falTrashCircle }   from '@fortawesome/pro-light-svg-icons';
import { faTrashCircle as farTrashCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faTrashCircle as fasTrashCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faTrashClock as fadTrashClock }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrashClock as falTrashClock }   from '@fortawesome/pro-light-svg-icons';
import { faTrashClock as farTrashClock }   from '@fortawesome/pro-regular-svg-icons';
import { faTrashClock as fasTrashClock }   from '@fortawesome/pro-solid-svg-icons';
import { faTrashList as fadTrashList }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrashList as falTrashList }   from '@fortawesome/pro-light-svg-icons';
import { faTrashList as farTrashList }   from '@fortawesome/pro-regular-svg-icons';
import { faTrashList as fasTrashList }   from '@fortawesome/pro-solid-svg-icons';
import { faTrashPlus as fadTrashPlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrashPlus as falTrashPlus }   from '@fortawesome/pro-light-svg-icons';
import { faTrashPlus as farTrashPlus }   from '@fortawesome/pro-regular-svg-icons';
import { faTrashPlus as fasTrashPlus }   from '@fortawesome/pro-solid-svg-icons';
import { faTrashRestore as fadTrashRestore }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrashRestore as falTrashRestore }   from '@fortawesome/pro-light-svg-icons';
import { faTrashRestore as farTrashRestore }   from '@fortawesome/pro-regular-svg-icons';
import { faTrashRestore as fasTrashRestore }   from '@fortawesome/pro-solid-svg-icons';
import { faTrashRestoreAlt as fadTrashRestoreAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrashRestoreAlt as falTrashRestoreAlt }   from '@fortawesome/pro-light-svg-icons';
import { faTrashRestoreAlt as farTrashRestoreAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faTrashRestoreAlt as fasTrashRestoreAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faTrashSlash as fadTrashSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrashSlash as falTrashSlash }   from '@fortawesome/pro-light-svg-icons';
import { faTrashSlash as farTrashSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faTrashSlash as fasTrashSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faTrashUndo as fadTrashUndo }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrashUndo as falTrashUndo }   from '@fortawesome/pro-light-svg-icons';
import { faTrashUndo as farTrashUndo }   from '@fortawesome/pro-regular-svg-icons';
import { faTrashUndo as fasTrashUndo }   from '@fortawesome/pro-solid-svg-icons';
import { faTrashUndoAlt as fadTrashUndoAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrashUndoAlt as falTrashUndoAlt }   from '@fortawesome/pro-light-svg-icons';
import { faTrashUndoAlt as farTrashUndoAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faTrashUndoAlt as fasTrashUndoAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faTrashXmark as fadTrashXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrashXmark as falTrashXmark }   from '@fortawesome/pro-light-svg-icons';
import { faTrashXmark as farTrashXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faTrashXmark as fasTrashXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faTreasureChest as fadTreasureChest }   from '@fortawesome/pro-duotone-svg-icons';
import { faTreasureChest as falTreasureChest }   from '@fortawesome/pro-light-svg-icons';
import { faTreasureChest as farTreasureChest }   from '@fortawesome/pro-regular-svg-icons';
import { faTreasureChest as fasTreasureChest }   from '@fortawesome/pro-solid-svg-icons';
import { faTree as fadTree }   from '@fortawesome/pro-duotone-svg-icons';
import { faTree as falTree }   from '@fortawesome/pro-light-svg-icons';
import { faTree as farTree }   from '@fortawesome/pro-regular-svg-icons';
import { faTree as fasTree }   from '@fortawesome/pro-solid-svg-icons';
import { faTreeAlt as fadTreeAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faTreeAlt as falTreeAlt }   from '@fortawesome/pro-light-svg-icons';
import { faTreeAlt as farTreeAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faTreeAlt as fasTreeAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faTreeChristmas as fadTreeChristmas }   from '@fortawesome/pro-duotone-svg-icons';
import { faTreeChristmas as falTreeChristmas }   from '@fortawesome/pro-light-svg-icons';
import { faTreeChristmas as farTreeChristmas }   from '@fortawesome/pro-regular-svg-icons';
import { faTreeChristmas as fasTreeChristmas }   from '@fortawesome/pro-solid-svg-icons';
import { faTreeCity as fadTreeCity }   from '@fortawesome/pro-duotone-svg-icons';
import { faTreeCity as falTreeCity }   from '@fortawesome/pro-light-svg-icons';
import { faTreeCity as farTreeCity }   from '@fortawesome/pro-regular-svg-icons';
import { faTreeCity as fasTreeCity }   from '@fortawesome/pro-solid-svg-icons';
import { faTreeDeciduous as fadTreeDeciduous }   from '@fortawesome/pro-duotone-svg-icons';
import { faTreeDeciduous as falTreeDeciduous }   from '@fortawesome/pro-light-svg-icons';
import { faTreeDeciduous as farTreeDeciduous }   from '@fortawesome/pro-regular-svg-icons';
import { faTreeDeciduous as fasTreeDeciduous }   from '@fortawesome/pro-solid-svg-icons';
import { faTreeDecorated as fadTreeDecorated }   from '@fortawesome/pro-duotone-svg-icons';
import { faTreeDecorated as falTreeDecorated }   from '@fortawesome/pro-light-svg-icons';
import { faTreeDecorated as farTreeDecorated }   from '@fortawesome/pro-regular-svg-icons';
import { faTreeDecorated as fasTreeDecorated }   from '@fortawesome/pro-solid-svg-icons';
import { faTreeLarge as fadTreeLarge }   from '@fortawesome/pro-duotone-svg-icons';
import { faTreeLarge as falTreeLarge }   from '@fortawesome/pro-light-svg-icons';
import { faTreeLarge as farTreeLarge }   from '@fortawesome/pro-regular-svg-icons';
import { faTreeLarge as fasTreeLarge }   from '@fortawesome/pro-solid-svg-icons';
import { faTreePalm as fadTreePalm }   from '@fortawesome/pro-duotone-svg-icons';
import { faTreePalm as falTreePalm }   from '@fortawesome/pro-light-svg-icons';
import { faTreePalm as farTreePalm }   from '@fortawesome/pro-regular-svg-icons';
import { faTreePalm as fasTreePalm }   from '@fortawesome/pro-solid-svg-icons';
import { faTrees as fadTrees }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrees as falTrees }   from '@fortawesome/pro-light-svg-icons';
import { faTrees as farTrees }   from '@fortawesome/pro-regular-svg-icons';
import { faTrees as fasTrees }   from '@fortawesome/pro-solid-svg-icons';
import { faTrianBalbot as fadTrianBalbot }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrianBalbot as falTrianBalbot }   from '@fortawesome/pro-light-svg-icons';
import { faTrianBalbot as farTrianBalbot }   from '@fortawesome/pro-regular-svg-icons';
import { faTrianBalbot as fasTrianBalbot }   from '@fortawesome/pro-solid-svg-icons';
import { faTriangle as fadTriangle }   from '@fortawesome/pro-duotone-svg-icons';
import { faTriangle as falTriangle }   from '@fortawesome/pro-light-svg-icons';
import { faTriangle as farTriangle }   from '@fortawesome/pro-regular-svg-icons';
import { faTriangle as fasTriangle }   from '@fortawesome/pro-solid-svg-icons';
import { faTriangleCircleSquare as fadTriangleCircleSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faTriangleCircleSquare as falTriangleCircleSquare }   from '@fortawesome/pro-light-svg-icons';
import { faTriangleCircleSquare as farTriangleCircleSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faTriangleCircleSquare as fasTriangleCircleSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faTriangleExclamation as fadTriangleExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faTriangleExclamation as falTriangleExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faTriangleExclamation as farTriangleExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faTriangleExclamation as fasTriangleExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faTriangleInstrument as fadTriangleInstrument }   from '@fortawesome/pro-duotone-svg-icons';
import { faTriangleInstrument as falTriangleInstrument }   from '@fortawesome/pro-light-svg-icons';
import { faTriangleInstrument as farTriangleInstrument }   from '@fortawesome/pro-regular-svg-icons';
import { faTriangleInstrument as fasTriangleInstrument }   from '@fortawesome/pro-solid-svg-icons';
import { faTriangleMusic as fadTriangleMusic }   from '@fortawesome/pro-duotone-svg-icons';
import { faTriangleMusic as falTriangleMusic }   from '@fortawesome/pro-light-svg-icons';
import { faTriangleMusic as farTriangleMusic }   from '@fortawesome/pro-regular-svg-icons';
import { faTriangleMusic as fasTriangleMusic }   from '@fortawesome/pro-solid-svg-icons';
import { faTrianglePersonDigging as fadTrianglePersonDigging }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrianglePersonDigging as falTrianglePersonDigging }   from '@fortawesome/pro-light-svg-icons';
import { faTrianglePersonDigging as farTrianglePersonDigging }   from '@fortawesome/pro-regular-svg-icons';
import { faTrianglePersonDigging as fasTrianglePersonDigging }   from '@fortawesome/pro-solid-svg-icons';
import { faTrillium as fadTrillium }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrillium as falTrillium }   from '@fortawesome/pro-light-svg-icons';
import { faTrillium as farTrillium }   from '@fortawesome/pro-regular-svg-icons';
import { faTrillium as fasTrillium }   from '@fortawesome/pro-solid-svg-icons';
import { faTrophy as fadTrophy }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrophy as falTrophy }   from '@fortawesome/pro-light-svg-icons';
import { faTrophy as farTrophy }   from '@fortawesome/pro-regular-svg-icons';
import { faTrophy as fasTrophy }   from '@fortawesome/pro-solid-svg-icons';
import { faTrophyAlt as fadTrophyAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrophyAlt as falTrophyAlt }   from '@fortawesome/pro-light-svg-icons';
import { faTrophyAlt as farTrophyAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faTrophyAlt as fasTrophyAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faTrophyStar as fadTrophyStar }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrophyStar as falTrophyStar }   from '@fortawesome/pro-light-svg-icons';
import { faTrophyStar as farTrophyStar }   from '@fortawesome/pro-regular-svg-icons';
import { faTrophyStar as fasTrophyStar }   from '@fortawesome/pro-solid-svg-icons';
import { faTrowel as fadTrowel }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrowel as falTrowel }   from '@fortawesome/pro-light-svg-icons';
import { faTrowel as farTrowel }   from '@fortawesome/pro-regular-svg-icons';
import { faTrowel as fasTrowel }   from '@fortawesome/pro-solid-svg-icons';
import { faTrowelBricks as fadTrowelBricks }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrowelBricks as falTrowelBricks }   from '@fortawesome/pro-light-svg-icons';
import { faTrowelBricks as farTrowelBricks }   from '@fortawesome/pro-regular-svg-icons';
import { faTrowelBricks as fasTrowelBricks }   from '@fortawesome/pro-solid-svg-icons';
import { faTruck as fadTruck }   from '@fortawesome/pro-duotone-svg-icons';
import { faTruck as falTruck }   from '@fortawesome/pro-light-svg-icons';
import { faTruck as farTruck }   from '@fortawesome/pro-regular-svg-icons';
import { faTruck as fasTruck }   from '@fortawesome/pro-solid-svg-icons';
import { faTruckArrowRight as fadTruckArrowRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faTruckArrowRight as falTruckArrowRight }   from '@fortawesome/pro-light-svg-icons';
import { faTruckArrowRight as farTruckArrowRight }   from '@fortawesome/pro-regular-svg-icons';
import { faTruckArrowRight as fasTruckArrowRight }   from '@fortawesome/pro-solid-svg-icons';
import { faTruckBolt as fadTruckBolt }   from '@fortawesome/pro-duotone-svg-icons';
import { faTruckBolt as falTruckBolt }   from '@fortawesome/pro-light-svg-icons';
import { faTruckBolt as farTruckBolt }   from '@fortawesome/pro-regular-svg-icons';
import { faTruckBolt as fasTruckBolt }   from '@fortawesome/pro-solid-svg-icons';
import { faTruckClock as fadTruckClock }   from '@fortawesome/pro-duotone-svg-icons';
import { faTruckClock as falTruckClock }   from '@fortawesome/pro-light-svg-icons';
import { faTruckClock as farTruckClock }   from '@fortawesome/pro-regular-svg-icons';
import { faTruckClock as fasTruckClock }   from '@fortawesome/pro-solid-svg-icons';
import { faTruckContainer as fadTruckContainer }   from '@fortawesome/pro-duotone-svg-icons';
import { faTruckContainer as falTruckContainer }   from '@fortawesome/pro-light-svg-icons';
import { faTruckContainer as farTruckContainer }   from '@fortawesome/pro-regular-svg-icons';
import { faTruckContainer as fasTruckContainer }   from '@fortawesome/pro-solid-svg-icons';
import { faTruckContainerEmpty as fadTruckContainerEmpty }   from '@fortawesome/pro-duotone-svg-icons';
import { faTruckContainerEmpty as falTruckContainerEmpty }   from '@fortawesome/pro-light-svg-icons';
import { faTruckContainerEmpty as farTruckContainerEmpty }   from '@fortawesome/pro-regular-svg-icons';
import { faTruckContainerEmpty as fasTruckContainerEmpty }   from '@fortawesome/pro-solid-svg-icons';
import { faTruckCouch as fadTruckCouch }   from '@fortawesome/pro-duotone-svg-icons';
import { faTruckCouch as falTruckCouch }   from '@fortawesome/pro-light-svg-icons';
import { faTruckCouch as farTruckCouch }   from '@fortawesome/pro-regular-svg-icons';
import { faTruckCouch as fasTruckCouch }   from '@fortawesome/pro-solid-svg-icons';
import { faTruckDroplet as fadTruckDroplet }   from '@fortawesome/pro-duotone-svg-icons';
import { faTruckDroplet as falTruckDroplet }   from '@fortawesome/pro-light-svg-icons';
import { faTruckDroplet as farTruckDroplet }   from '@fortawesome/pro-regular-svg-icons';
import { faTruckDroplet as fasTruckDroplet }   from '@fortawesome/pro-solid-svg-icons';
import { faTruckFast as fadTruckFast }   from '@fortawesome/pro-duotone-svg-icons';
import { faTruckFast as falTruckFast }   from '@fortawesome/pro-light-svg-icons';
import { faTruckFast as farTruckFast }   from '@fortawesome/pro-regular-svg-icons';
import { faTruckFast as fasTruckFast }   from '@fortawesome/pro-solid-svg-icons';
import { faTruckField as fadTruckField }   from '@fortawesome/pro-duotone-svg-icons';
import { faTruckField as falTruckField }   from '@fortawesome/pro-light-svg-icons';
import { faTruckField as farTruckField }   from '@fortawesome/pro-regular-svg-icons';
import { faTruckField as fasTruckField }   from '@fortawesome/pro-solid-svg-icons';
import { faTruckFieldUn as fadTruckFieldUn }   from '@fortawesome/pro-duotone-svg-icons';
import { faTruckFieldUn as falTruckFieldUn }   from '@fortawesome/pro-light-svg-icons';
import { faTruckFieldUn as farTruckFieldUn }   from '@fortawesome/pro-regular-svg-icons';
import { faTruckFieldUn as fasTruckFieldUn }   from '@fortawesome/pro-solid-svg-icons';
import { faTruckFlatbed as fadTruckFlatbed }   from '@fortawesome/pro-duotone-svg-icons';
import { faTruckFlatbed as falTruckFlatbed }   from '@fortawesome/pro-light-svg-icons';
import { faTruckFlatbed as farTruckFlatbed }   from '@fortawesome/pro-regular-svg-icons';
import { faTruckFlatbed as fasTruckFlatbed }   from '@fortawesome/pro-solid-svg-icons';
import { faTruckFront as fadTruckFront }   from '@fortawesome/pro-duotone-svg-icons';
import { faTruckFront as falTruckFront }   from '@fortawesome/pro-light-svg-icons';
import { faTruckFront as farTruckFront }   from '@fortawesome/pro-regular-svg-icons';
import { faTruckFront as fasTruckFront }   from '@fortawesome/pro-solid-svg-icons';
import { faTruckLoading as fadTruckLoading }   from '@fortawesome/pro-duotone-svg-icons';
import { faTruckLoading as falTruckLoading }   from '@fortawesome/pro-light-svg-icons';
import { faTruckLoading as farTruckLoading }   from '@fortawesome/pro-regular-svg-icons';
import { faTruckLoading as fasTruckLoading }   from '@fortawesome/pro-solid-svg-icons';
import { faTruckMedical as fadTruckMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faTruckMedical as falTruckMedical }   from '@fortawesome/pro-light-svg-icons';
import { faTruckMedical as farTruckMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faTruckMedical as fasTruckMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faTruckMonster as fadTruckMonster }   from '@fortawesome/pro-duotone-svg-icons';
import { faTruckMonster as falTruckMonster }   from '@fortawesome/pro-light-svg-icons';
import { faTruckMonster as farTruckMonster }   from '@fortawesome/pro-regular-svg-icons';
import { faTruckMonster as fasTruckMonster }   from '@fortawesome/pro-solid-svg-icons';
import { faTruckMoving as fadTruckMoving }   from '@fortawesome/pro-duotone-svg-icons';
import { faTruckMoving as falTruckMoving }   from '@fortawesome/pro-light-svg-icons';
import { faTruckMoving as farTruckMoving }   from '@fortawesome/pro-regular-svg-icons';
import { faTruckMoving as fasTruckMoving }   from '@fortawesome/pro-solid-svg-icons';
import { faTruckPickup as fadTruckPickup }   from '@fortawesome/pro-duotone-svg-icons';
import { faTruckPickup as falTruckPickup }   from '@fortawesome/pro-light-svg-icons';
import { faTruckPickup as farTruckPickup }   from '@fortawesome/pro-regular-svg-icons';
import { faTruckPickup as fasTruckPickup }   from '@fortawesome/pro-solid-svg-icons';
import { faTruckPlane as fadTruckPlane }   from '@fortawesome/pro-duotone-svg-icons';
import { faTruckPlane as falTruckPlane }   from '@fortawesome/pro-light-svg-icons';
import { faTruckPlane as farTruckPlane }   from '@fortawesome/pro-regular-svg-icons';
import { faTruckPlane as fasTruckPlane }   from '@fortawesome/pro-solid-svg-icons';
import { faTruckPlow as fadTruckPlow }   from '@fortawesome/pro-duotone-svg-icons';
import { faTruckPlow as falTruckPlow }   from '@fortawesome/pro-light-svg-icons';
import { faTruckPlow as farTruckPlow }   from '@fortawesome/pro-regular-svg-icons';
import { faTruckPlow as fasTruckPlow }   from '@fortawesome/pro-solid-svg-icons';
import { faTruckRamp as fadTruckRamp }   from '@fortawesome/pro-duotone-svg-icons';
import { faTruckRamp as falTruckRamp }   from '@fortawesome/pro-light-svg-icons';
import { faTruckRamp as farTruckRamp }   from '@fortawesome/pro-regular-svg-icons';
import { faTruckRamp as fasTruckRamp }   from '@fortawesome/pro-solid-svg-icons';
import { faTruckRampBox as fadTruckRampBox }   from '@fortawesome/pro-duotone-svg-icons';
import { faTruckRampBox as falTruckRampBox }   from '@fortawesome/pro-light-svg-icons';
import { faTruckRampBox as farTruckRampBox }   from '@fortawesome/pro-regular-svg-icons';
import { faTruckRampBox as fasTruckRampBox }   from '@fortawesome/pro-solid-svg-icons';
import { faTruckRampCouch as fadTruckRampCouch }   from '@fortawesome/pro-duotone-svg-icons';
import { faTruckRampCouch as falTruckRampCouch }   from '@fortawesome/pro-light-svg-icons';
import { faTruckRampCouch as farTruckRampCouch }   from '@fortawesome/pro-regular-svg-icons';
import { faTruckRampCouch as fasTruckRampCouch }   from '@fortawesome/pro-solid-svg-icons';
import { faTruckTow as fadTruckTow }   from '@fortawesome/pro-duotone-svg-icons';
import { faTruckTow as falTruckTow }   from '@fortawesome/pro-light-svg-icons';
import { faTruckTow as farTruckTow }   from '@fortawesome/pro-regular-svg-icons';
import { faTruckTow as fasTruckTow }   from '@fortawesome/pro-solid-svg-icons';
import { faTrumpet as fadTrumpet }   from '@fortawesome/pro-duotone-svg-icons';
import { faTrumpet as falTrumpet }   from '@fortawesome/pro-light-svg-icons';
import { faTrumpet as farTrumpet }   from '@fortawesome/pro-regular-svg-icons';
import { faTrumpet as fasTrumpet }   from '@fortawesome/pro-solid-svg-icons';
import { faTry as fadTry }   from '@fortawesome/pro-duotone-svg-icons';
import { faTry as falTry }   from '@fortawesome/pro-light-svg-icons';
import { faTry as farTry }   from '@fortawesome/pro-regular-svg-icons';
import { faTry as fasTry }   from '@fortawesome/pro-solid-svg-icons';
import { faTShirt as fadTShirt }   from '@fortawesome/pro-duotone-svg-icons';
import { faTshirt as fadTshirt }   from '@fortawesome/pro-duotone-svg-icons';
import { faTShirt as falTShirt }   from '@fortawesome/pro-light-svg-icons';
import { faTshirt as falTshirt }   from '@fortawesome/pro-light-svg-icons';
import { faTShirt as farTShirt }   from '@fortawesome/pro-regular-svg-icons';
import { faTshirt as farTshirt }   from '@fortawesome/pro-regular-svg-icons';
import { faTShirt as fasTShirt }   from '@fortawesome/pro-solid-svg-icons';
import { faTshirt as fasTshirt }   from '@fortawesome/pro-solid-svg-icons';
import { faTty as fadTty }   from '@fortawesome/pro-duotone-svg-icons';
import { faTty as falTty }   from '@fortawesome/pro-light-svg-icons';
import { faTty as farTty }   from '@fortawesome/pro-regular-svg-icons';
import { faTty as fasTty }   from '@fortawesome/pro-solid-svg-icons';
import { faTtyAnswer as fadTtyAnswer }   from '@fortawesome/pro-duotone-svg-icons';
import { faTtyAnswer as falTtyAnswer }   from '@fortawesome/pro-light-svg-icons';
import { faTtyAnswer as farTtyAnswer }   from '@fortawesome/pro-regular-svg-icons';
import { faTtyAnswer as fasTtyAnswer }   from '@fortawesome/pro-solid-svg-icons';
import { faTugrikSign as fadTugrikSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faTugrikSign as falTugrikSign }   from '@fortawesome/pro-light-svg-icons';
import { faTugrikSign as farTugrikSign }   from '@fortawesome/pro-regular-svg-icons';
import { faTugrikSign as fasTugrikSign }   from '@fortawesome/pro-solid-svg-icons';
import { faTurkey as fadTurkey }   from '@fortawesome/pro-duotone-svg-icons';
import { faTurkey as falTurkey }   from '@fortawesome/pro-light-svg-icons';
import { faTurkey as farTurkey }   from '@fortawesome/pro-regular-svg-icons';
import { faTurkey as fasTurkey }   from '@fortawesome/pro-solid-svg-icons';
import { faTurkishLira as fadTurkishLira }   from '@fortawesome/pro-duotone-svg-icons';
import { faTurkishLira as falTurkishLira }   from '@fortawesome/pro-light-svg-icons';
import { faTurkishLira as farTurkishLira }   from '@fortawesome/pro-regular-svg-icons';
import { faTurkishLira as fasTurkishLira }   from '@fortawesome/pro-solid-svg-icons';
import { faTurkishLiraSign as fadTurkishLiraSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faTurkishLiraSign as falTurkishLiraSign }   from '@fortawesome/pro-light-svg-icons';
import { faTurkishLiraSign as farTurkishLiraSign }   from '@fortawesome/pro-regular-svg-icons';
import { faTurkishLiraSign as fasTurkishLiraSign }   from '@fortawesome/pro-solid-svg-icons';
import { faTurnDown as fadTurnDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faTurnDown as falTurnDown }   from '@fortawesome/pro-light-svg-icons';
import { faTurnDown as farTurnDown }   from '@fortawesome/pro-regular-svg-icons';
import { faTurnDown as fasTurnDown }   from '@fortawesome/pro-solid-svg-icons';
import { faTurnDownLeft as fadTurnDownLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faTurnDownLeft as falTurnDownLeft }   from '@fortawesome/pro-light-svg-icons';
import { faTurnDownLeft as farTurnDownLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faTurnDownLeft as fasTurnDownLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faTurnDownRight as fadTurnDownRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faTurnDownRight as falTurnDownRight }   from '@fortawesome/pro-light-svg-icons';
import { faTurnDownRight as farTurnDownRight }   from '@fortawesome/pro-regular-svg-icons';
import { faTurnDownRight as fasTurnDownRight }   from '@fortawesome/pro-solid-svg-icons';
import { faTurntable as fadTurntable }   from '@fortawesome/pro-duotone-svg-icons';
import { faTurntable as falTurntable }   from '@fortawesome/pro-light-svg-icons';
import { faTurntable as farTurntable }   from '@fortawesome/pro-regular-svg-icons';
import { faTurntable as fasTurntable }   from '@fortawesome/pro-solid-svg-icons';
import { faTurnUp as fadTurnUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faTurnUp as falTurnUp }   from '@fortawesome/pro-light-svg-icons';
import { faTurnUp as farTurnUp }   from '@fortawesome/pro-regular-svg-icons';
import { faTurnUp as fasTurnUp }   from '@fortawesome/pro-solid-svg-icons';
import { faTurtle as fadTurtle }   from '@fortawesome/pro-duotone-svg-icons';
import { faTurtle as falTurtle }   from '@fortawesome/pro-light-svg-icons';
import { faTurtle as farTurtle }   from '@fortawesome/pro-regular-svg-icons';
import { faTurtle as fasTurtle }   from '@fortawesome/pro-solid-svg-icons';
import { faTv as fadTv }   from '@fortawesome/pro-duotone-svg-icons';
import { faTv as falTv }   from '@fortawesome/pro-light-svg-icons';
import { faTv as farTv }   from '@fortawesome/pro-regular-svg-icons';
import { faTv as fasTv }   from '@fortawesome/pro-solid-svg-icons';
import { faTvAlt as fadTvAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faTvAlt as falTvAlt }   from '@fortawesome/pro-light-svg-icons';
import { faTvAlt as farTvAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faTvAlt as fasTvAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faTvMusic as fadTvMusic }   from '@fortawesome/pro-duotone-svg-icons';
import { faTvMusic as falTvMusic }   from '@fortawesome/pro-light-svg-icons';
import { faTvMusic as farTvMusic }   from '@fortawesome/pro-regular-svg-icons';
import { faTvMusic as fasTvMusic }   from '@fortawesome/pro-solid-svg-icons';
import { faTvRetro as fadTvRetro }   from '@fortawesome/pro-duotone-svg-icons';
import { faTvRetro as falTvRetro }   from '@fortawesome/pro-light-svg-icons';
import { faTvRetro as farTvRetro }   from '@fortawesome/pro-regular-svg-icons';
import { faTvRetro as fasTvRetro }   from '@fortawesome/pro-solid-svg-icons';
import { faTypewriter as fadTypewriter }   from '@fortawesome/pro-duotone-svg-icons';
import { faTypewriter as falTypewriter }   from '@fortawesome/pro-light-svg-icons';
import { faTypewriter as farTypewriter }   from '@fortawesome/pro-regular-svg-icons';
import { faTypewriter as fasTypewriter }   from '@fortawesome/pro-solid-svg-icons';
import { faU as fadU }   from '@fortawesome/pro-duotone-svg-icons';
import { faU as falU }   from '@fortawesome/pro-light-svg-icons';
import { faU as farU }   from '@fortawesome/pro-regular-svg-icons';
import { faU as fasU }   from '@fortawesome/pro-solid-svg-icons';
import { faUfo as fadUfo }   from '@fortawesome/pro-duotone-svg-icons';
import { faUfo as falUfo }   from '@fortawesome/pro-light-svg-icons';
import { faUfo as farUfo }   from '@fortawesome/pro-regular-svg-icons';
import { faUfo as fasUfo }   from '@fortawesome/pro-solid-svg-icons';
import { faUfoBeam as fadUfoBeam }   from '@fortawesome/pro-duotone-svg-icons';
import { faUfoBeam as falUfoBeam }   from '@fortawesome/pro-light-svg-icons';
import { faUfoBeam as farUfoBeam }   from '@fortawesome/pro-regular-svg-icons';
import { faUfoBeam as fasUfoBeam }   from '@fortawesome/pro-solid-svg-icons';
import { faUmbrella as fadUmbrella }   from '@fortawesome/pro-duotone-svg-icons';
import { faUmbrella as falUmbrella }   from '@fortawesome/pro-light-svg-icons';
import { faUmbrella as farUmbrella }   from '@fortawesome/pro-regular-svg-icons';
import { faUmbrella as fasUmbrella }   from '@fortawesome/pro-solid-svg-icons';
import { faUmbrellaAlt as fadUmbrellaAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faUmbrellaAlt as falUmbrellaAlt }   from '@fortawesome/pro-light-svg-icons';
import { faUmbrellaAlt as farUmbrellaAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faUmbrellaAlt as fasUmbrellaAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faUmbrellaBeach as fadUmbrellaBeach }   from '@fortawesome/pro-duotone-svg-icons';
import { faUmbrellaBeach as falUmbrellaBeach }   from '@fortawesome/pro-light-svg-icons';
import { faUmbrellaBeach as farUmbrellaBeach }   from '@fortawesome/pro-regular-svg-icons';
import { faUmbrellaBeach as fasUmbrellaBeach }   from '@fortawesome/pro-solid-svg-icons';
import { faUmbrellaSimple as fadUmbrellaSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faUmbrellaSimple as falUmbrellaSimple }   from '@fortawesome/pro-light-svg-icons';
import { faUmbrellaSimple as farUmbrellaSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faUmbrellaSimple as fasUmbrellaSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faUnderline as fadUnderline }   from '@fortawesome/pro-duotone-svg-icons';
import { faUnderline as falUnderline }   from '@fortawesome/pro-light-svg-icons';
import { faUnderline as farUnderline }   from '@fortawesome/pro-regular-svg-icons';
import { faUnderline as fasUnderline }   from '@fortawesome/pro-solid-svg-icons';
import { faUndo as fadUndo }   from '@fortawesome/pro-duotone-svg-icons';
import { faUndo as falUndo }   from '@fortawesome/pro-light-svg-icons';
import { faUndo as farUndo }   from '@fortawesome/pro-regular-svg-icons';
import { faUndo as fasUndo }   from '@fortawesome/pro-solid-svg-icons';
import { faUndoAlt as fadUndoAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faUndoAlt as falUndoAlt }   from '@fortawesome/pro-light-svg-icons';
import { faUndoAlt as farUndoAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faUndoAlt as fasUndoAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faUnicorn as fadUnicorn }   from '@fortawesome/pro-duotone-svg-icons';
import { faUnicorn as falUnicorn }   from '@fortawesome/pro-light-svg-icons';
import { faUnicorn as farUnicorn }   from '@fortawesome/pro-regular-svg-icons';
import { faUnicorn as fasUnicorn }   from '@fortawesome/pro-solid-svg-icons';
import { faUniformMartialArts as fadUniformMartialArts }   from '@fortawesome/pro-duotone-svg-icons';
import { faUniformMartialArts as falUniformMartialArts }   from '@fortawesome/pro-light-svg-icons';
import { faUniformMartialArts as farUniformMartialArts }   from '@fortawesome/pro-regular-svg-icons';
import { faUniformMartialArts as fasUniformMartialArts }   from '@fortawesome/pro-solid-svg-icons';
import { faUnion as fadUnion }   from '@fortawesome/pro-duotone-svg-icons';
import { faUnion as falUnion }   from '@fortawesome/pro-light-svg-icons';
import { faUnion as farUnion }   from '@fortawesome/pro-regular-svg-icons';
import { faUnion as fasUnion }   from '@fortawesome/pro-solid-svg-icons';
import { faUniversalAccess as fadUniversalAccess }   from '@fortawesome/pro-duotone-svg-icons';
import { faUniversalAccess as falUniversalAccess }   from '@fortawesome/pro-light-svg-icons';
import { faUniversalAccess as farUniversalAccess }   from '@fortawesome/pro-regular-svg-icons';
import { faUniversalAccess as fasUniversalAccess }   from '@fortawesome/pro-solid-svg-icons';
import { faUniversity as fadUniversity }   from '@fortawesome/pro-duotone-svg-icons';
import { faUniversity as falUniversity }   from '@fortawesome/pro-light-svg-icons';
import { faUniversity as farUniversity }   from '@fortawesome/pro-regular-svg-icons';
import { faUniversity as fasUniversity }   from '@fortawesome/pro-solid-svg-icons';
import { faUnlink as fadUnlink }   from '@fortawesome/pro-duotone-svg-icons';
import { faUnlink as falUnlink }   from '@fortawesome/pro-light-svg-icons';
import { faUnlink as farUnlink }   from '@fortawesome/pro-regular-svg-icons';
import { faUnlink as fasUnlink }   from '@fortawesome/pro-solid-svg-icons';
import { faUnlock as fadUnlock }   from '@fortawesome/pro-duotone-svg-icons';
import { faUnlock as falUnlock }   from '@fortawesome/pro-light-svg-icons';
import { faUnlock as farUnlock }   from '@fortawesome/pro-regular-svg-icons';
import { faUnlock as fasUnlock }   from '@fortawesome/pro-solid-svg-icons';
import { faUnlockAlt as fadUnlockAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faUnlockAlt as falUnlockAlt }   from '@fortawesome/pro-light-svg-icons';
import { faUnlockAlt as farUnlockAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faUnlockAlt as fasUnlockAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faUnlockKeyhole as fadUnlockKeyhole }   from '@fortawesome/pro-duotone-svg-icons';
import { faUnlockKeyhole as falUnlockKeyhole }   from '@fortawesome/pro-light-svg-icons';
import { faUnlockKeyhole as farUnlockKeyhole }   from '@fortawesome/pro-regular-svg-icons';
import { faUnlockKeyhole as fasUnlockKeyhole }   from '@fortawesome/pro-solid-svg-icons';
import { faUnsorted as fadUnsorted }   from '@fortawesome/pro-duotone-svg-icons';
import { faUnsorted as falUnsorted }   from '@fortawesome/pro-light-svg-icons';
import { faUnsorted as farUnsorted }   from '@fortawesome/pro-regular-svg-icons';
import { faUnsorted as fasUnsorted }   from '@fortawesome/pro-solid-svg-icons';
import { faUp as fadUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faUp as falUp }   from '@fortawesome/pro-light-svg-icons';
import { faUp as farUp }   from '@fortawesome/pro-regular-svg-icons';
import { faUp as fasUp }   from '@fortawesome/pro-solid-svg-icons';
import { faUpDown as fadUpDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faUpDown as falUpDown }   from '@fortawesome/pro-light-svg-icons';
import { faUpDown as farUpDown }   from '@fortawesome/pro-regular-svg-icons';
import { faUpDown as fasUpDown }   from '@fortawesome/pro-solid-svg-icons';
import { faUpDownLeftRight as fadUpDownLeftRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faUpDownLeftRight as falUpDownLeftRight }   from '@fortawesome/pro-light-svg-icons';
import { faUpDownLeftRight as farUpDownLeftRight }   from '@fortawesome/pro-regular-svg-icons';
import { faUpDownLeftRight as fasUpDownLeftRight }   from '@fortawesome/pro-solid-svg-icons';
import { faUpFromBracket as fadUpFromBracket }   from '@fortawesome/pro-duotone-svg-icons';
import { faUpFromBracket as falUpFromBracket }   from '@fortawesome/pro-light-svg-icons';
import { faUpFromBracket as farUpFromBracket }   from '@fortawesome/pro-regular-svg-icons';
import { faUpFromBracket as fasUpFromBracket }   from '@fortawesome/pro-solid-svg-icons';
import { faUpFromDottedLine as fadUpFromDottedLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faUpFromDottedLine as falUpFromDottedLine }   from '@fortawesome/pro-light-svg-icons';
import { faUpFromDottedLine as farUpFromDottedLine }   from '@fortawesome/pro-regular-svg-icons';
import { faUpFromDottedLine as fasUpFromDottedLine }   from '@fortawesome/pro-solid-svg-icons';
import { faUpFromLine as fadUpFromLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faUpFromLine as falUpFromLine }   from '@fortawesome/pro-light-svg-icons';
import { faUpFromLine as farUpFromLine }   from '@fortawesome/pro-regular-svg-icons';
import { faUpFromLine as fasUpFromLine }   from '@fortawesome/pro-solid-svg-icons';
import { faUpLeft as fadUpLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faUpLeft as falUpLeft }   from '@fortawesome/pro-light-svg-icons';
import { faUpLeft as farUpLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faUpLeft as fasUpLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faUpload as fadUpload }   from '@fortawesome/pro-duotone-svg-icons';
import { faUpload as falUpload }   from '@fortawesome/pro-light-svg-icons';
import { faUpload as farUpload }   from '@fortawesome/pro-regular-svg-icons';
import { faUpload as fasUpload }   from '@fortawesome/pro-solid-svg-icons';
import { faUpLong as fadUpLong }   from '@fortawesome/pro-duotone-svg-icons';
import { faUpLong as falUpLong }   from '@fortawesome/pro-light-svg-icons';
import { faUpLong as farUpLong }   from '@fortawesome/pro-regular-svg-icons';
import { faUpLong as fasUpLong }   from '@fortawesome/pro-solid-svg-icons';
import { faUpRight as fadUpRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faUpRight as falUpRight }   from '@fortawesome/pro-light-svg-icons';
import { faUpRight as farUpRight }   from '@fortawesome/pro-regular-svg-icons';
import { faUpRight as fasUpRight }   from '@fortawesome/pro-solid-svg-icons';
import { faUpRightAndDownLeftFromCenter as fadUpRightAndDownLeftFromCenter }   from '@fortawesome/pro-duotone-svg-icons';
import { faUpRightAndDownLeftFromCenter as falUpRightAndDownLeftFromCenter }   from '@fortawesome/pro-light-svg-icons';
import { faUpRightAndDownLeftFromCenter as farUpRightAndDownLeftFromCenter }   from '@fortawesome/pro-regular-svg-icons';
import { faUpRightAndDownLeftFromCenter as fasUpRightAndDownLeftFromCenter }   from '@fortawesome/pro-solid-svg-icons';
import { faUpRightFromSquare as fadUpRightFromSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faUpRightFromSquare as falUpRightFromSquare }   from '@fortawesome/pro-light-svg-icons';
import { faUpRightFromSquare as farUpRightFromSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faUpRightFromSquare as fasUpRightFromSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faUpToDottedLine as fadUpToDottedLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faUpToDottedLine as falUpToDottedLine }   from '@fortawesome/pro-light-svg-icons';
import { faUpToDottedLine as farUpToDottedLine }   from '@fortawesome/pro-regular-svg-icons';
import { faUpToDottedLine as fasUpToDottedLine }   from '@fortawesome/pro-solid-svg-icons';
import { faUpToLine as fadUpToLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faUpToLine as falUpToLine }   from '@fortawesome/pro-light-svg-icons';
import { faUpToLine as farUpToLine }   from '@fortawesome/pro-regular-svg-icons';
import { faUpToLine as fasUpToLine }   from '@fortawesome/pro-solid-svg-icons';
import { faUsbDrive as fadUsbDrive }   from '@fortawesome/pro-duotone-svg-icons';
import { faUsbDrive as falUsbDrive }   from '@fortawesome/pro-light-svg-icons';
import { faUsbDrive as farUsbDrive }   from '@fortawesome/pro-regular-svg-icons';
import { faUsbDrive as fasUsbDrive }   from '@fortawesome/pro-solid-svg-icons';
import { faUsd as fadUsd }   from '@fortawesome/pro-duotone-svg-icons';
import { faUsd as falUsd }   from '@fortawesome/pro-light-svg-icons';
import { faUsd as farUsd }   from '@fortawesome/pro-regular-svg-icons';
import { faUsd as fasUsd }   from '@fortawesome/pro-solid-svg-icons';
import { faUsdCircle as fadUsdCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faUsdCircle as falUsdCircle }   from '@fortawesome/pro-light-svg-icons';
import { faUsdCircle as farUsdCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faUsdCircle as fasUsdCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faUsdSquare as fadUsdSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faUsdSquare as falUsdSquare }   from '@fortawesome/pro-light-svg-icons';
import { faUsdSquare as farUsdSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faUsdSquare as fasUsdSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faUser as fadUser }   from '@fortawesome/pro-duotone-svg-icons';
import { faUser as falUser }   from '@fortawesome/pro-light-svg-icons';
import { faUser as farUser }   from '@fortawesome/pro-regular-svg-icons';
import { faUser as fasUser }   from '@fortawesome/pro-solid-svg-icons';
import { faUserAlien as fadUserAlien }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserAlien as falUserAlien }   from '@fortawesome/pro-light-svg-icons';
import { faUserAlien as farUserAlien }   from '@fortawesome/pro-regular-svg-icons';
import { faUserAlien as fasUserAlien }   from '@fortawesome/pro-solid-svg-icons';
import { faUserAlt as fadUserAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserAlt as falUserAlt }   from '@fortawesome/pro-light-svg-icons';
import { faUserAlt as farUserAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faUserAlt as fasUserAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faUserAltSlash as fadUserAltSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserAltSlash as falUserAltSlash }   from '@fortawesome/pro-light-svg-icons';
import { faUserAltSlash as farUserAltSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faUserAltSlash as fasUserAltSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faUserAstronaut as fadUserAstronaut }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserAstronaut as falUserAstronaut }   from '@fortawesome/pro-light-svg-icons';
import { faUserAstronaut as farUserAstronaut }   from '@fortawesome/pro-regular-svg-icons';
import { faUserAstronaut as fasUserAstronaut }   from '@fortawesome/pro-solid-svg-icons';
import { faUserBountyHunter as fadUserBountyHunter }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserBountyHunter as falUserBountyHunter }   from '@fortawesome/pro-light-svg-icons';
import { faUserBountyHunter as farUserBountyHunter }   from '@fortawesome/pro-regular-svg-icons';
import { faUserBountyHunter as fasUserBountyHunter }   from '@fortawesome/pro-solid-svg-icons';
import { faUserChart as fadUserChart }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserChart as falUserChart }   from '@fortawesome/pro-light-svg-icons';
import { faUserChart as farUserChart }   from '@fortawesome/pro-regular-svg-icons';
import { faUserChart as fasUserChart }   from '@fortawesome/pro-solid-svg-icons';
import { faUserCheck as fadUserCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserCheck as falUserCheck }   from '@fortawesome/pro-light-svg-icons';
import { faUserCheck as farUserCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faUserCheck as fasUserCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faUserChef as fadUserChef }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserChef as falUserChef }   from '@fortawesome/pro-light-svg-icons';
import { faUserChef as farUserChef }   from '@fortawesome/pro-regular-svg-icons';
import { faUserChef as fasUserChef }   from '@fortawesome/pro-solid-svg-icons';
import { faUserCircle as fadUserCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserCircle as falUserCircle }   from '@fortawesome/pro-light-svg-icons';
import { faUserCircle as farUserCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faUserCircle as fasUserCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faUserClock as fadUserClock }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserClock as falUserClock }   from '@fortawesome/pro-light-svg-icons';
import { faUserClock as farUserClock }   from '@fortawesome/pro-regular-svg-icons';
import { faUserClock as fasUserClock }   from '@fortawesome/pro-solid-svg-icons';
import { faUserCog as fadUserCog }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserCog as falUserCog }   from '@fortawesome/pro-light-svg-icons';
import { faUserCog as farUserCog }   from '@fortawesome/pro-regular-svg-icons';
import { faUserCog as fasUserCog }   from '@fortawesome/pro-solid-svg-icons';
import { faUserConstruction as fadUserConstruction }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserConstruction as falUserConstruction }   from '@fortawesome/pro-light-svg-icons';
import { faUserConstruction as farUserConstruction }   from '@fortawesome/pro-regular-svg-icons';
import { faUserConstruction as fasUserConstruction }   from '@fortawesome/pro-solid-svg-icons';
import { faUserCowboy as fadUserCowboy }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserCowboy as falUserCowboy }   from '@fortawesome/pro-light-svg-icons';
import { faUserCowboy as farUserCowboy }   from '@fortawesome/pro-regular-svg-icons';
import { faUserCowboy as fasUserCowboy }   from '@fortawesome/pro-solid-svg-icons';
import { faUserCrown as fadUserCrown }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserCrown as falUserCrown }   from '@fortawesome/pro-light-svg-icons';
import { faUserCrown as farUserCrown }   from '@fortawesome/pro-regular-svg-icons';
import { faUserCrown as fasUserCrown }   from '@fortawesome/pro-solid-svg-icons';
import { faUserDoctor as fadUserDoctor }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserDoctor as falUserDoctor }   from '@fortawesome/pro-light-svg-icons';
import { faUserDoctor as farUserDoctor }   from '@fortawesome/pro-regular-svg-icons';
import { faUserDoctor as fasUserDoctor }   from '@fortawesome/pro-solid-svg-icons';
import { faUserDoctorHair as fadUserDoctorHair }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserDoctorHair as falUserDoctorHair }   from '@fortawesome/pro-light-svg-icons';
import { faUserDoctorHair as farUserDoctorHair }   from '@fortawesome/pro-regular-svg-icons';
import { faUserDoctorHair as fasUserDoctorHair }   from '@fortawesome/pro-solid-svg-icons';
import { faUserDoctorHairLong as fadUserDoctorHairLong }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserDoctorHairLong as falUserDoctorHairLong }   from '@fortawesome/pro-light-svg-icons';
import { faUserDoctorHairLong as farUserDoctorHairLong }   from '@fortawesome/pro-regular-svg-icons';
import { faUserDoctorHairLong as fasUserDoctorHairLong }   from '@fortawesome/pro-solid-svg-icons';
import { faUserDoctorMessage as fadUserDoctorMessage }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserDoctorMessage as falUserDoctorMessage }   from '@fortawesome/pro-light-svg-icons';
import { faUserDoctorMessage as farUserDoctorMessage }   from '@fortawesome/pro-regular-svg-icons';
import { faUserDoctorMessage as fasUserDoctorMessage }   from '@fortawesome/pro-solid-svg-icons';
import { faUserEdit as fadUserEdit }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserEdit as falUserEdit }   from '@fortawesome/pro-light-svg-icons';
import { faUserEdit as farUserEdit }   from '@fortawesome/pro-regular-svg-icons';
import { faUserEdit as fasUserEdit }   from '@fortawesome/pro-solid-svg-icons';
import { faUserFriends as fadUserFriends }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserFriends as falUserFriends }   from '@fortawesome/pro-light-svg-icons';
import { faUserFriends as farUserFriends }   from '@fortawesome/pro-regular-svg-icons';
import { faUserFriends as fasUserFriends }   from '@fortawesome/pro-solid-svg-icons';
import { faUserGear as fadUserGear }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserGear as falUserGear }   from '@fortawesome/pro-light-svg-icons';
import { faUserGear as farUserGear }   from '@fortawesome/pro-regular-svg-icons';
import { faUserGear as fasUserGear }   from '@fortawesome/pro-solid-svg-icons';
import { faUserGraduate as fadUserGraduate }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserGraduate as falUserGraduate }   from '@fortawesome/pro-light-svg-icons';
import { faUserGraduate as farUserGraduate }   from '@fortawesome/pro-regular-svg-icons';
import { faUserGraduate as fasUserGraduate }   from '@fortawesome/pro-solid-svg-icons';
import { faUserGroup as fadUserGroup }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserGroup as falUserGroup }   from '@fortawesome/pro-light-svg-icons';
import { faUserGroup as farUserGroup }   from '@fortawesome/pro-regular-svg-icons';
import { faUserGroup as fasUserGroup }   from '@fortawesome/pro-solid-svg-icons';
import { faUserGroupCrown as fadUserGroupCrown }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserGroupCrown as falUserGroupCrown }   from '@fortawesome/pro-light-svg-icons';
import { faUserGroupCrown as farUserGroupCrown }   from '@fortawesome/pro-regular-svg-icons';
import { faUserGroupCrown as fasUserGroupCrown }   from '@fortawesome/pro-solid-svg-icons';
import { faUserHair as fadUserHair }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserHair as falUserHair }   from '@fortawesome/pro-light-svg-icons';
import { faUserHair as farUserHair }   from '@fortawesome/pro-regular-svg-icons';
import { faUserHair as fasUserHair }   from '@fortawesome/pro-solid-svg-icons';
import { faUserHairBuns as fadUserHairBuns }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserHairBuns as falUserHairBuns }   from '@fortawesome/pro-light-svg-icons';
import { faUserHairBuns as farUserHairBuns }   from '@fortawesome/pro-regular-svg-icons';
import { faUserHairBuns as fasUserHairBuns }   from '@fortawesome/pro-solid-svg-icons';
import { faUserHairLong as fadUserHairLong }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserHairLong as falUserHairLong }   from '@fortawesome/pro-light-svg-icons';
import { faUserHairLong as farUserHairLong }   from '@fortawesome/pro-regular-svg-icons';
import { faUserHairLong as fasUserHairLong }   from '@fortawesome/pro-solid-svg-icons';
import { faUserHairMullet as fadUserHairMullet }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserHairMullet as falUserHairMullet }   from '@fortawesome/pro-light-svg-icons';
import { faUserHairMullet as farUserHairMullet }   from '@fortawesome/pro-regular-svg-icons';
import { faUserHairMullet as fasUserHairMullet }   from '@fortawesome/pro-solid-svg-icons';
import { faUserHardHat as fadUserHardHat }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserHardHat as falUserHardHat }   from '@fortawesome/pro-light-svg-icons';
import { faUserHardHat as farUserHardHat }   from '@fortawesome/pro-regular-svg-icons';
import { faUserHardHat as fasUserHardHat }   from '@fortawesome/pro-solid-svg-icons';
import { faUserHeadset as fadUserHeadset }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserHeadset as falUserHeadset }   from '@fortawesome/pro-light-svg-icons';
import { faUserHeadset as farUserHeadset }   from '@fortawesome/pro-regular-svg-icons';
import { faUserHeadset as fasUserHeadset }   from '@fortawesome/pro-solid-svg-icons';
import { faUserHelmetSafety as fadUserHelmetSafety }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserHelmetSafety as falUserHelmetSafety }   from '@fortawesome/pro-light-svg-icons';
import { faUserHelmetSafety as farUserHelmetSafety }   from '@fortawesome/pro-regular-svg-icons';
import { faUserHelmetSafety as fasUserHelmetSafety }   from '@fortawesome/pro-solid-svg-icons';
import { faUserInjured as fadUserInjured }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserInjured as falUserInjured }   from '@fortawesome/pro-light-svg-icons';
import { faUserInjured as farUserInjured }   from '@fortawesome/pro-regular-svg-icons';
import { faUserInjured as fasUserInjured }   from '@fortawesome/pro-solid-svg-icons';
import { faUserLarge as fadUserLarge }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserLarge as falUserLarge }   from '@fortawesome/pro-light-svg-icons';
import { faUserLarge as farUserLarge }   from '@fortawesome/pro-regular-svg-icons';
import { faUserLarge as fasUserLarge }   from '@fortawesome/pro-solid-svg-icons';
import { faUserLargeSlash as fadUserLargeSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserLargeSlash as falUserLargeSlash }   from '@fortawesome/pro-light-svg-icons';
import { faUserLargeSlash as farUserLargeSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faUserLargeSlash as fasUserLargeSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faUserLock as fadUserLock }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserLock as falUserLock }   from '@fortawesome/pro-light-svg-icons';
import { faUserLock as farUserLock }   from '@fortawesome/pro-regular-svg-icons';
import { faUserLock as fasUserLock }   from '@fortawesome/pro-solid-svg-icons';
import { faUserMd as fadUserMd }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserMd as falUserMd }   from '@fortawesome/pro-light-svg-icons';
import { faUserMd as farUserMd }   from '@fortawesome/pro-regular-svg-icons';
import { faUserMd as fasUserMd }   from '@fortawesome/pro-solid-svg-icons';
import { faUserMdChat as fadUserMdChat }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserMdChat as falUserMdChat }   from '@fortawesome/pro-light-svg-icons';
import { faUserMdChat as farUserMdChat }   from '@fortawesome/pro-regular-svg-icons';
import { faUserMdChat as fasUserMdChat }   from '@fortawesome/pro-solid-svg-icons';
import { faUserMinus as fadUserMinus }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserMinus as falUserMinus }   from '@fortawesome/pro-light-svg-icons';
import { faUserMinus as farUserMinus }   from '@fortawesome/pro-regular-svg-icons';
import { faUserMinus as fasUserMinus }   from '@fortawesome/pro-solid-svg-icons';
import { faUserMusic as fadUserMusic }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserMusic as falUserMusic }   from '@fortawesome/pro-light-svg-icons';
import { faUserMusic as farUserMusic }   from '@fortawesome/pro-regular-svg-icons';
import { faUserMusic as fasUserMusic }   from '@fortawesome/pro-solid-svg-icons';
import { faUserNinja as fadUserNinja }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserNinja as falUserNinja }   from '@fortawesome/pro-light-svg-icons';
import { faUserNinja as farUserNinja }   from '@fortawesome/pro-regular-svg-icons';
import { faUserNinja as fasUserNinja }   from '@fortawesome/pro-solid-svg-icons';
import { faUserNurse as fadUserNurse }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserNurse as falUserNurse }   from '@fortawesome/pro-light-svg-icons';
import { faUserNurse as farUserNurse }   from '@fortawesome/pro-regular-svg-icons';
import { faUserNurse as fasUserNurse }   from '@fortawesome/pro-solid-svg-icons';
import { faUserNurseHair as fadUserNurseHair }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserNurseHair as falUserNurseHair }   from '@fortawesome/pro-light-svg-icons';
import { faUserNurseHair as farUserNurseHair }   from '@fortawesome/pro-regular-svg-icons';
import { faUserNurseHair as fasUserNurseHair }   from '@fortawesome/pro-solid-svg-icons';
import { faUserNurseHairLong as fadUserNurseHairLong }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserNurseHairLong as falUserNurseHairLong }   from '@fortawesome/pro-light-svg-icons';
import { faUserNurseHairLong as farUserNurseHairLong }   from '@fortawesome/pro-regular-svg-icons';
import { faUserNurseHairLong as fasUserNurseHairLong }   from '@fortawesome/pro-solid-svg-icons';
import { faUserPen as fadUserPen }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserPen as falUserPen }   from '@fortawesome/pro-light-svg-icons';
import { faUserPen as farUserPen }   from '@fortawesome/pro-regular-svg-icons';
import { faUserPen as fasUserPen }   from '@fortawesome/pro-solid-svg-icons';
import { faUserPilot as fadUserPilot }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserPilot as falUserPilot }   from '@fortawesome/pro-light-svg-icons';
import { faUserPilot as farUserPilot }   from '@fortawesome/pro-regular-svg-icons';
import { faUserPilot as fasUserPilot }   from '@fortawesome/pro-solid-svg-icons';
import { faUserPilotTie as fadUserPilotTie }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserPilotTie as falUserPilotTie }   from '@fortawesome/pro-light-svg-icons';
import { faUserPilotTie as farUserPilotTie }   from '@fortawesome/pro-regular-svg-icons';
import { faUserPilotTie as fasUserPilotTie }   from '@fortawesome/pro-solid-svg-icons';
import { faUserPlus as fadUserPlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserPlus as falUserPlus }   from '@fortawesome/pro-light-svg-icons';
import { faUserPlus as farUserPlus }   from '@fortawesome/pro-regular-svg-icons';
import { faUserPlus as fasUserPlus }   from '@fortawesome/pro-solid-svg-icons';
import { faUserPolice as fadUserPolice }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserPolice as falUserPolice }   from '@fortawesome/pro-light-svg-icons';
import { faUserPolice as farUserPolice }   from '@fortawesome/pro-regular-svg-icons';
import { faUserPolice as fasUserPolice }   from '@fortawesome/pro-solid-svg-icons';
import { faUserPoliceTie as fadUserPoliceTie }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserPoliceTie as falUserPoliceTie }   from '@fortawesome/pro-light-svg-icons';
import { faUserPoliceTie as farUserPoliceTie }   from '@fortawesome/pro-regular-svg-icons';
import { faUserPoliceTie as fasUserPoliceTie }   from '@fortawesome/pro-solid-svg-icons';
import { faUserRobot as fadUserRobot }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserRobot as falUserRobot }   from '@fortawesome/pro-light-svg-icons';
import { faUserRobot as farUserRobot }   from '@fortawesome/pro-regular-svg-icons';
import { faUserRobot as fasUserRobot }   from '@fortawesome/pro-solid-svg-icons';
import { faUserRobotXmarks as fadUserRobotXmarks }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserRobotXmarks as falUserRobotXmarks }   from '@fortawesome/pro-light-svg-icons';
import { faUserRobotXmarks as farUserRobotXmarks }   from '@fortawesome/pro-regular-svg-icons';
import { faUserRobotXmarks as fasUserRobotXmarks }   from '@fortawesome/pro-solid-svg-icons';
import { faUsers as fadUsers }   from '@fortawesome/pro-duotone-svg-icons';
import { faUsers as falUsers }   from '@fortawesome/pro-light-svg-icons';
import { faUsers as farUsers }   from '@fortawesome/pro-regular-svg-icons';
import { faUsers as fasUsers }   from '@fortawesome/pro-solid-svg-icons';
import { faUsersBetweenLines as fadUsersBetweenLines }   from '@fortawesome/pro-duotone-svg-icons';
import { faUsersBetweenLines as falUsersBetweenLines }   from '@fortawesome/pro-light-svg-icons';
import { faUsersBetweenLines as farUsersBetweenLines }   from '@fortawesome/pro-regular-svg-icons';
import { faUsersBetweenLines as fasUsersBetweenLines }   from '@fortawesome/pro-solid-svg-icons';
import { faUsersClass as fadUsersClass }   from '@fortawesome/pro-duotone-svg-icons';
import { faUsersClass as falUsersClass }   from '@fortawesome/pro-light-svg-icons';
import { faUsersClass as farUsersClass }   from '@fortawesome/pro-regular-svg-icons';
import { faUsersClass as fasUsersClass }   from '@fortawesome/pro-solid-svg-icons';
import { faUsersCog as fadUsersCog }   from '@fortawesome/pro-duotone-svg-icons';
import { faUsersCog as falUsersCog }   from '@fortawesome/pro-light-svg-icons';
import { faUsersCog as farUsersCog }   from '@fortawesome/pro-regular-svg-icons';
import { faUsersCog as fasUsersCog }   from '@fortawesome/pro-solid-svg-icons';
import { faUsersCrown as fadUsersCrown }   from '@fortawesome/pro-duotone-svg-icons';
import { faUsersCrown as falUsersCrown }   from '@fortawesome/pro-light-svg-icons';
import { faUsersCrown as farUsersCrown }   from '@fortawesome/pro-regular-svg-icons';
import { faUsersCrown as fasUsersCrown }   from '@fortawesome/pro-solid-svg-icons';
import { faUserSecret as fadUserSecret }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserSecret as falUserSecret }   from '@fortawesome/pro-light-svg-icons';
import { faUserSecret as farUserSecret }   from '@fortawesome/pro-regular-svg-icons';
import { faUserSecret as fasUserSecret }   from '@fortawesome/pro-solid-svg-icons';
import { faUsersGear as fadUsersGear }   from '@fortawesome/pro-duotone-svg-icons';
import { faUsersGear as falUsersGear }   from '@fortawesome/pro-light-svg-icons';
import { faUsersGear as farUsersGear }   from '@fortawesome/pro-regular-svg-icons';
import { faUsersGear as fasUsersGear }   from '@fortawesome/pro-solid-svg-icons';
import { faUserShakespeare as fadUserShakespeare }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserShakespeare as falUserShakespeare }   from '@fortawesome/pro-light-svg-icons';
import { faUserShakespeare as farUserShakespeare }   from '@fortawesome/pro-regular-svg-icons';
import { faUserShakespeare as fasUserShakespeare }   from '@fortawesome/pro-solid-svg-icons';
import { faUserShield as fadUserShield }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserShield as falUserShield }   from '@fortawesome/pro-light-svg-icons';
import { faUserShield as farUserShield }   from '@fortawesome/pro-regular-svg-icons';
import { faUserShield as fasUserShield }   from '@fortawesome/pro-solid-svg-icons';
import { faUserSlash as fadUserSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserSlash as falUserSlash }   from '@fortawesome/pro-light-svg-icons';
import { faUserSlash as farUserSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faUserSlash as fasUserSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faUsersLine as fadUsersLine }   from '@fortawesome/pro-duotone-svg-icons';
import { faUsersLine as falUsersLine }   from '@fortawesome/pro-light-svg-icons';
import { faUsersLine as farUsersLine }   from '@fortawesome/pro-regular-svg-icons';
import { faUsersLine as fasUsersLine }   from '@fortawesome/pro-solid-svg-icons';
import { faUsersMedical as fadUsersMedical }   from '@fortawesome/pro-duotone-svg-icons';
import { faUsersMedical as falUsersMedical }   from '@fortawesome/pro-light-svg-icons';
import { faUsersMedical as farUsersMedical }   from '@fortawesome/pro-regular-svg-icons';
import { faUsersMedical as fasUsersMedical }   from '@fortawesome/pro-solid-svg-icons';
import { faUsersRays as fadUsersRays }   from '@fortawesome/pro-duotone-svg-icons';
import { faUsersRays as falUsersRays }   from '@fortawesome/pro-light-svg-icons';
import { faUsersRays as farUsersRays }   from '@fortawesome/pro-regular-svg-icons';
import { faUsersRays as fasUsersRays }   from '@fortawesome/pro-solid-svg-icons';
import { faUsersRectangle as fadUsersRectangle }   from '@fortawesome/pro-duotone-svg-icons';
import { faUsersRectangle as falUsersRectangle }   from '@fortawesome/pro-light-svg-icons';
import { faUsersRectangle as farUsersRectangle }   from '@fortawesome/pro-regular-svg-icons';
import { faUsersRectangle as fasUsersRectangle }   from '@fortawesome/pro-solid-svg-icons';
import { faUsersSlash as fadUsersSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faUsersSlash as falUsersSlash }   from '@fortawesome/pro-light-svg-icons';
import { faUsersSlash as farUsersSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faUsersSlash as fasUsersSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faUsersViewfinder as fadUsersViewfinder }   from '@fortawesome/pro-duotone-svg-icons';
import { faUsersViewfinder as falUsersViewfinder }   from '@fortawesome/pro-light-svg-icons';
import { faUsersViewfinder as farUsersViewfinder }   from '@fortawesome/pro-regular-svg-icons';
import { faUsersViewfinder as fasUsersViewfinder }   from '@fortawesome/pro-solid-svg-icons';
import { faUserTag as fadUserTag }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserTag as falUserTag }   from '@fortawesome/pro-light-svg-icons';
import { faUserTag as farUserTag }   from '@fortawesome/pro-regular-svg-icons';
import { faUserTag as fasUserTag }   from '@fortawesome/pro-solid-svg-icons';
import { faUserTie as fadUserTie }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserTie as falUserTie }   from '@fortawesome/pro-light-svg-icons';
import { faUserTie as farUserTie }   from '@fortawesome/pro-regular-svg-icons';
import { faUserTie as fasUserTie }   from '@fortawesome/pro-solid-svg-icons';
import { faUserTieHair as fadUserTieHair }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserTieHair as falUserTieHair }   from '@fortawesome/pro-light-svg-icons';
import { faUserTieHair as farUserTieHair }   from '@fortawesome/pro-regular-svg-icons';
import { faUserTieHair as fasUserTieHair }   from '@fortawesome/pro-solid-svg-icons';
import { faUserTieHairLong as fadUserTieHairLong }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserTieHairLong as falUserTieHairLong }   from '@fortawesome/pro-light-svg-icons';
import { faUserTieHairLong as farUserTieHairLong }   from '@fortawesome/pro-regular-svg-icons';
import { faUserTieHairLong as fasUserTieHairLong }   from '@fortawesome/pro-solid-svg-icons';
import { faUserTimes as fadUserTimes }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserTimes as falUserTimes }   from '@fortawesome/pro-light-svg-icons';
import { faUserTimes as farUserTimes }   from '@fortawesome/pro-regular-svg-icons';
import { faUserTimes as fasUserTimes }   from '@fortawesome/pro-solid-svg-icons';
import { faUserUnlock as fadUserUnlock }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserUnlock as falUserUnlock }   from '@fortawesome/pro-light-svg-icons';
import { faUserUnlock as farUserUnlock }   from '@fortawesome/pro-regular-svg-icons';
import { faUserUnlock as fasUserUnlock }   from '@fortawesome/pro-solid-svg-icons';
import { faUserVisor as fadUserVisor }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserVisor as falUserVisor }   from '@fortawesome/pro-light-svg-icons';
import { faUserVisor as farUserVisor }   from '@fortawesome/pro-regular-svg-icons';
import { faUserVisor as fasUserVisor }   from '@fortawesome/pro-solid-svg-icons';
import { faUserVneck as fadUserVneck }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserVneck as falUserVneck }   from '@fortawesome/pro-light-svg-icons';
import { faUserVneck as farUserVneck }   from '@fortawesome/pro-regular-svg-icons';
import { faUserVneck as fasUserVneck }   from '@fortawesome/pro-solid-svg-icons';
import { faUserVneckHair as fadUserVneckHair }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserVneckHair as falUserVneckHair }   from '@fortawesome/pro-light-svg-icons';
import { faUserVneckHair as farUserVneckHair }   from '@fortawesome/pro-regular-svg-icons';
import { faUserVneckHair as fasUserVneckHair }   from '@fortawesome/pro-solid-svg-icons';
import { faUserVneckHairLong as fadUserVneckHairLong }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserVneckHairLong as falUserVneckHairLong }   from '@fortawesome/pro-light-svg-icons';
import { faUserVneckHairLong as farUserVneckHairLong }   from '@fortawesome/pro-regular-svg-icons';
import { faUserVneckHairLong as fasUserVneckHairLong }   from '@fortawesome/pro-solid-svg-icons';
import { faUserXmark as fadUserXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faUserXmark as falUserXmark }   from '@fortawesome/pro-light-svg-icons';
import { faUserXmark as farUserXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faUserXmark as fasUserXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faUtensilFork as fadUtensilFork }   from '@fortawesome/pro-duotone-svg-icons';
import { faUtensilFork as falUtensilFork }   from '@fortawesome/pro-light-svg-icons';
import { faUtensilFork as farUtensilFork }   from '@fortawesome/pro-regular-svg-icons';
import { faUtensilFork as fasUtensilFork }   from '@fortawesome/pro-solid-svg-icons';
import { faUtensilKnife as fadUtensilKnife }   from '@fortawesome/pro-duotone-svg-icons';
import { faUtensilKnife as falUtensilKnife }   from '@fortawesome/pro-light-svg-icons';
import { faUtensilKnife as farUtensilKnife }   from '@fortawesome/pro-regular-svg-icons';
import { faUtensilKnife as fasUtensilKnife }   from '@fortawesome/pro-solid-svg-icons';
import { faUtensils as fadUtensils }   from '@fortawesome/pro-duotone-svg-icons';
import { faUtensils as falUtensils }   from '@fortawesome/pro-light-svg-icons';
import { faUtensils as farUtensils }   from '@fortawesome/pro-regular-svg-icons';
import { faUtensils as fasUtensils }   from '@fortawesome/pro-solid-svg-icons';
import { faUtensilsAlt as fadUtensilsAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faUtensilsAlt as falUtensilsAlt }   from '@fortawesome/pro-light-svg-icons';
import { faUtensilsAlt as farUtensilsAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faUtensilsAlt as fasUtensilsAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faUtensilSpoon as fadUtensilSpoon }   from '@fortawesome/pro-duotone-svg-icons';
import { faUtensilSpoon as falUtensilSpoon }   from '@fortawesome/pro-light-svg-icons';
import { faUtensilSpoon as farUtensilSpoon }   from '@fortawesome/pro-regular-svg-icons';
import { faUtensilSpoon as fasUtensilSpoon }   from '@fortawesome/pro-solid-svg-icons';
import { faUtensilsSlash as fadUtensilsSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faUtensilsSlash as falUtensilsSlash }   from '@fortawesome/pro-light-svg-icons';
import { faUtensilsSlash as farUtensilsSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faUtensilsSlash as fasUtensilsSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faUtilityPole as fadUtilityPole }   from '@fortawesome/pro-duotone-svg-icons';
import { faUtilityPole as falUtilityPole }   from '@fortawesome/pro-light-svg-icons';
import { faUtilityPole as farUtilityPole }   from '@fortawesome/pro-regular-svg-icons';
import { faUtilityPole as fasUtilityPole }   from '@fortawesome/pro-solid-svg-icons';
import { faUtilityPoleDouble as fadUtilityPoleDouble }   from '@fortawesome/pro-duotone-svg-icons';
import { faUtilityPoleDouble as falUtilityPoleDouble }   from '@fortawesome/pro-light-svg-icons';
import { faUtilityPoleDouble as farUtilityPoleDouble }   from '@fortawesome/pro-regular-svg-icons';
import { faUtilityPoleDouble as fasUtilityPoleDouble }   from '@fortawesome/pro-solid-svg-icons';
import { faV as fadV }   from '@fortawesome/pro-duotone-svg-icons';
import { faV as falV }   from '@fortawesome/pro-light-svg-icons';
import { faV as farV }   from '@fortawesome/pro-regular-svg-icons';
import { faV as fasV }   from '@fortawesome/pro-solid-svg-icons';
import { faVacuum as fadVacuum }   from '@fortawesome/pro-duotone-svg-icons';
import { faVacuum as falVacuum }   from '@fortawesome/pro-light-svg-icons';
import { faVacuum as farVacuum }   from '@fortawesome/pro-regular-svg-icons';
import { faVacuum as fasVacuum }   from '@fortawesome/pro-solid-svg-icons';
import { faVacuumRobot as fadVacuumRobot }   from '@fortawesome/pro-duotone-svg-icons';
import { faVacuumRobot as falVacuumRobot }   from '@fortawesome/pro-light-svg-icons';
import { faVacuumRobot as farVacuumRobot }   from '@fortawesome/pro-regular-svg-icons';
import { faVacuumRobot as fasVacuumRobot }   from '@fortawesome/pro-solid-svg-icons';
import { faValueAbsolute as fadValueAbsolute }   from '@fortawesome/pro-duotone-svg-icons';
import { faValueAbsolute as falValueAbsolute }   from '@fortawesome/pro-light-svg-icons';
import { faValueAbsolute as farValueAbsolute }   from '@fortawesome/pro-regular-svg-icons';
import { faValueAbsolute as fasValueAbsolute }   from '@fortawesome/pro-solid-svg-icons';
import { faVanShuttle as fadVanShuttle }   from '@fortawesome/pro-duotone-svg-icons';
import { faVanShuttle as falVanShuttle }   from '@fortawesome/pro-light-svg-icons';
import { faVanShuttle as farVanShuttle }   from '@fortawesome/pro-regular-svg-icons';
import { faVanShuttle as fasVanShuttle }   from '@fortawesome/pro-solid-svg-icons';
import { faVault as fadVault }   from '@fortawesome/pro-duotone-svg-icons';
import { faVault as falVault }   from '@fortawesome/pro-light-svg-icons';
import { faVault as farVault }   from '@fortawesome/pro-regular-svg-icons';
import { faVault as fasVault }   from '@fortawesome/pro-solid-svg-icons';
import { faVcard as fadVcard }   from '@fortawesome/pro-duotone-svg-icons';
import { faVcard as falVcard }   from '@fortawesome/pro-light-svg-icons';
import { faVcard as farVcard }   from '@fortawesome/pro-regular-svg-icons';
import { faVcard as fasVcard }   from '@fortawesome/pro-solid-svg-icons';
import { faVectorCircle as fadVectorCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faVectorCircle as falVectorCircle }   from '@fortawesome/pro-light-svg-icons';
import { faVectorCircle as farVectorCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faVectorCircle as fasVectorCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faVectorPolygon as fadVectorPolygon }   from '@fortawesome/pro-duotone-svg-icons';
import { faVectorPolygon as falVectorPolygon }   from '@fortawesome/pro-light-svg-icons';
import { faVectorPolygon as farVectorPolygon }   from '@fortawesome/pro-regular-svg-icons';
import { faVectorPolygon as fasVectorPolygon }   from '@fortawesome/pro-solid-svg-icons';
import { faVectorSquare as fadVectorSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faVectorSquare as falVectorSquare }   from '@fortawesome/pro-light-svg-icons';
import { faVectorSquare as farVectorSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faVectorSquare as fasVectorSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faVentDamper as fadVentDamper }   from '@fortawesome/pro-duotone-svg-icons';
import { faVentDamper as falVentDamper }   from '@fortawesome/pro-light-svg-icons';
import { faVentDamper as farVentDamper }   from '@fortawesome/pro-regular-svg-icons';
import { faVentDamper as fasVentDamper }   from '@fortawesome/pro-solid-svg-icons';
import { faVenus as fadVenus }   from '@fortawesome/pro-duotone-svg-icons';
import { faVenus as falVenus }   from '@fortawesome/pro-light-svg-icons';
import { faVenus as farVenus }   from '@fortawesome/pro-regular-svg-icons';
import { faVenus as fasVenus }   from '@fortawesome/pro-solid-svg-icons';
import { faVenusDouble as fadVenusDouble }   from '@fortawesome/pro-duotone-svg-icons';
import { faVenusDouble as falVenusDouble }   from '@fortawesome/pro-light-svg-icons';
import { faVenusDouble as farVenusDouble }   from '@fortawesome/pro-regular-svg-icons';
import { faVenusDouble as fasVenusDouble }   from '@fortawesome/pro-solid-svg-icons';
import { faVenusMars as fadVenusMars }   from '@fortawesome/pro-duotone-svg-icons';
import { faVenusMars as falVenusMars }   from '@fortawesome/pro-light-svg-icons';
import { faVenusMars as farVenusMars }   from '@fortawesome/pro-regular-svg-icons';
import { faVenusMars as fasVenusMars }   from '@fortawesome/pro-solid-svg-icons';
import { faVest as fadVest }   from '@fortawesome/pro-duotone-svg-icons';
import { faVest as falVest }   from '@fortawesome/pro-light-svg-icons';
import { faVest as farVest }   from '@fortawesome/pro-regular-svg-icons';
import { faVest as fasVest }   from '@fortawesome/pro-solid-svg-icons';
import { faVestPatches as fadVestPatches }   from '@fortawesome/pro-duotone-svg-icons';
import { faVestPatches as falVestPatches }   from '@fortawesome/pro-light-svg-icons';
import { faVestPatches as farVestPatches }   from '@fortawesome/pro-regular-svg-icons';
import { faVestPatches as fasVestPatches }   from '@fortawesome/pro-solid-svg-icons';
import { faVhs as fadVhs }   from '@fortawesome/pro-duotone-svg-icons';
import { faVhs as falVhs }   from '@fortawesome/pro-light-svg-icons';
import { faVhs as farVhs }   from '@fortawesome/pro-regular-svg-icons';
import { faVhs as fasVhs }   from '@fortawesome/pro-solid-svg-icons';
import { faVial as fadVial }   from '@fortawesome/pro-duotone-svg-icons';
import { faVial as falVial }   from '@fortawesome/pro-light-svg-icons';
import { faVial as farVial }   from '@fortawesome/pro-regular-svg-icons';
import { faVial as fasVial }   from '@fortawesome/pro-solid-svg-icons';
import { faVialCircleCheck as fadVialCircleCheck }   from '@fortawesome/pro-duotone-svg-icons';
import { faVialCircleCheck as falVialCircleCheck }   from '@fortawesome/pro-light-svg-icons';
import { faVialCircleCheck as farVialCircleCheck }   from '@fortawesome/pro-regular-svg-icons';
import { faVialCircleCheck as fasVialCircleCheck }   from '@fortawesome/pro-solid-svg-icons';
import { faVials as fadVials }   from '@fortawesome/pro-duotone-svg-icons';
import { faVials as falVials }   from '@fortawesome/pro-light-svg-icons';
import { faVials as farVials }   from '@fortawesome/pro-regular-svg-icons';
import { faVials as fasVials }   from '@fortawesome/pro-solid-svg-icons';
import { faVialVirus as fadVialVirus }   from '@fortawesome/pro-duotone-svg-icons';
import { faVialVirus as falVialVirus }   from '@fortawesome/pro-light-svg-icons';
import { faVialVirus as farVialVirus }   from '@fortawesome/pro-regular-svg-icons';
import { faVialVirus as fasVialVirus }   from '@fortawesome/pro-solid-svg-icons';
import { faVideo as fadVideo }   from '@fortawesome/pro-duotone-svg-icons';
import { faVideo as falVideo }   from '@fortawesome/pro-light-svg-icons';
import { faVideo as farVideo }   from '@fortawesome/pro-regular-svg-icons';
import { faVideo as fasVideo }   from '@fortawesome/pro-solid-svg-icons';
import { faVideoArrowDownLeft as fadVideoArrowDownLeft }   from '@fortawesome/pro-duotone-svg-icons';
import { faVideoArrowDownLeft as falVideoArrowDownLeft }   from '@fortawesome/pro-light-svg-icons';
import { faVideoArrowDownLeft as farVideoArrowDownLeft }   from '@fortawesome/pro-regular-svg-icons';
import { faVideoArrowDownLeft as fasVideoArrowDownLeft }   from '@fortawesome/pro-solid-svg-icons';
import { faVideoArrowUpRight as fadVideoArrowUpRight }   from '@fortawesome/pro-duotone-svg-icons';
import { faVideoArrowUpRight as falVideoArrowUpRight }   from '@fortawesome/pro-light-svg-icons';
import { faVideoArrowUpRight as farVideoArrowUpRight }   from '@fortawesome/pro-regular-svg-icons';
import { faVideoArrowUpRight as fasVideoArrowUpRight }   from '@fortawesome/pro-solid-svg-icons';
import { faVideoCamera as fadVideoCamera }   from '@fortawesome/pro-duotone-svg-icons';
import { faVideoCamera as falVideoCamera }   from '@fortawesome/pro-light-svg-icons';
import { faVideoCamera as farVideoCamera }   from '@fortawesome/pro-regular-svg-icons';
import { faVideoCamera as fasVideoCamera }   from '@fortawesome/pro-solid-svg-icons';
import { faVideoCircle as fadVideoCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faVideoCircle as falVideoCircle }   from '@fortawesome/pro-light-svg-icons';
import { faVideoCircle as farVideoCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faVideoCircle as fasVideoCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faVideoHandheld as fadVideoHandheld }   from '@fortawesome/pro-duotone-svg-icons';
import { faVideoHandheld as falVideoHandheld }   from '@fortawesome/pro-light-svg-icons';
import { faVideoHandheld as farVideoHandheld }   from '@fortawesome/pro-regular-svg-icons';
import { faVideoHandheld as fasVideoHandheld }   from '@fortawesome/pro-solid-svg-icons';
import { faVideoPlus as fadVideoPlus }   from '@fortawesome/pro-duotone-svg-icons';
import { faVideoPlus as falVideoPlus }   from '@fortawesome/pro-light-svg-icons';
import { faVideoPlus as farVideoPlus }   from '@fortawesome/pro-regular-svg-icons';
import { faVideoPlus as fasVideoPlus }   from '@fortawesome/pro-solid-svg-icons';
import { faVideoSlash as fadVideoSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faVideoSlash as falVideoSlash }   from '@fortawesome/pro-light-svg-icons';
import { faVideoSlash as farVideoSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faVideoSlash as fasVideoSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faVihara as fadVihara }   from '@fortawesome/pro-duotone-svg-icons';
import { faVihara as falVihara }   from '@fortawesome/pro-light-svg-icons';
import { faVihara as farVihara }   from '@fortawesome/pro-regular-svg-icons';
import { faVihara as fasVihara }   from '@fortawesome/pro-solid-svg-icons';
import { faViolin as fadViolin }   from '@fortawesome/pro-duotone-svg-icons';
import { faViolin as falViolin }   from '@fortawesome/pro-light-svg-icons';
import { faViolin as farViolin }   from '@fortawesome/pro-regular-svg-icons';
import { faViolin as fasViolin }   from '@fortawesome/pro-solid-svg-icons';
import { faVirus as fadVirus }   from '@fortawesome/pro-duotone-svg-icons';
import { faVirus as falVirus }   from '@fortawesome/pro-light-svg-icons';
import { faVirus as farVirus }   from '@fortawesome/pro-regular-svg-icons';
import { faVirus as fasVirus }   from '@fortawesome/pro-solid-svg-icons';
import { faVirusCovid as fadVirusCovid }   from '@fortawesome/pro-duotone-svg-icons';
import { faVirusCovid as falVirusCovid }   from '@fortawesome/pro-light-svg-icons';
import { faVirusCovid as farVirusCovid }   from '@fortawesome/pro-regular-svg-icons';
import { faVirusCovid as fasVirusCovid }   from '@fortawesome/pro-solid-svg-icons';
import { faVirusCovidSlash as fadVirusCovidSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faVirusCovidSlash as falVirusCovidSlash }   from '@fortawesome/pro-light-svg-icons';
import { faVirusCovidSlash as farVirusCovidSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faVirusCovidSlash as fasVirusCovidSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faViruses as fadViruses }   from '@fortawesome/pro-duotone-svg-icons';
import { faViruses as falViruses }   from '@fortawesome/pro-light-svg-icons';
import { faViruses as farViruses }   from '@fortawesome/pro-regular-svg-icons';
import { faViruses as fasViruses }   from '@fortawesome/pro-solid-svg-icons';
import { faVirusSlash as fadVirusSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faVirusSlash as falVirusSlash }   from '@fortawesome/pro-light-svg-icons';
import { faVirusSlash as farVirusSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faVirusSlash as fasVirusSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faVoicemail as fadVoicemail }   from '@fortawesome/pro-duotone-svg-icons';
import { faVoicemail as falVoicemail }   from '@fortawesome/pro-light-svg-icons';
import { faVoicemail as farVoicemail }   from '@fortawesome/pro-regular-svg-icons';
import { faVoicemail as fasVoicemail }   from '@fortawesome/pro-solid-svg-icons';
import { faVolcano as fadVolcano }   from '@fortawesome/pro-duotone-svg-icons';
import { faVolcano as falVolcano }   from '@fortawesome/pro-light-svg-icons';
import { faVolcano as farVolcano }   from '@fortawesome/pro-regular-svg-icons';
import { faVolcano as fasVolcano }   from '@fortawesome/pro-solid-svg-icons';
import { faVolleyball as fadVolleyball }   from '@fortawesome/pro-duotone-svg-icons';
import { faVolleyball as falVolleyball }   from '@fortawesome/pro-light-svg-icons';
import { faVolleyball as farVolleyball }   from '@fortawesome/pro-regular-svg-icons';
import { faVolleyball as fasVolleyball }   from '@fortawesome/pro-solid-svg-icons';
import { faVolleyballBall as fadVolleyballBall }   from '@fortawesome/pro-duotone-svg-icons';
import { faVolleyballBall as falVolleyballBall }   from '@fortawesome/pro-light-svg-icons';
import { faVolleyballBall as farVolleyballBall }   from '@fortawesome/pro-regular-svg-icons';
import { faVolleyballBall as fasVolleyballBall }   from '@fortawesome/pro-solid-svg-icons';
import { faVolume as fadVolume }   from '@fortawesome/pro-duotone-svg-icons';
import { faVolume as falVolume }   from '@fortawesome/pro-light-svg-icons';
import { faVolume as farVolume }   from '@fortawesome/pro-regular-svg-icons';
import { faVolume as fasVolume }   from '@fortawesome/pro-solid-svg-icons';
import { faVolumeControlPhone as fadVolumeControlPhone }   from '@fortawesome/pro-duotone-svg-icons';
import { faVolumeControlPhone as falVolumeControlPhone }   from '@fortawesome/pro-light-svg-icons';
import { faVolumeControlPhone as farVolumeControlPhone }   from '@fortawesome/pro-regular-svg-icons';
import { faVolumeControlPhone as fasVolumeControlPhone }   from '@fortawesome/pro-solid-svg-icons';
import { faVolumeDown as fadVolumeDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faVolumeDown as falVolumeDown }   from '@fortawesome/pro-light-svg-icons';
import { faVolumeDown as farVolumeDown }   from '@fortawesome/pro-regular-svg-icons';
import { faVolumeDown as fasVolumeDown }   from '@fortawesome/pro-solid-svg-icons';
import { faVolumeHigh as fadVolumeHigh }   from '@fortawesome/pro-duotone-svg-icons';
import { faVolumeHigh as falVolumeHigh }   from '@fortawesome/pro-light-svg-icons';
import { faVolumeHigh as farVolumeHigh }   from '@fortawesome/pro-regular-svg-icons';
import { faVolumeHigh as fasVolumeHigh }   from '@fortawesome/pro-solid-svg-icons';
import { faVolumeLow as fadVolumeLow }   from '@fortawesome/pro-duotone-svg-icons';
import { faVolumeLow as falVolumeLow }   from '@fortawesome/pro-light-svg-icons';
import { faVolumeLow as farVolumeLow }   from '@fortawesome/pro-regular-svg-icons';
import { faVolumeLow as fasVolumeLow }   from '@fortawesome/pro-solid-svg-icons';
import { faVolumeMedium as fadVolumeMedium }   from '@fortawesome/pro-duotone-svg-icons';
import { faVolumeMedium as falVolumeMedium }   from '@fortawesome/pro-light-svg-icons';
import { faVolumeMedium as farVolumeMedium }   from '@fortawesome/pro-regular-svg-icons';
import { faVolumeMedium as fasVolumeMedium }   from '@fortawesome/pro-solid-svg-icons';
import { faVolumeMute as fadVolumeMute }   from '@fortawesome/pro-duotone-svg-icons';
import { faVolumeMute as falVolumeMute }   from '@fortawesome/pro-light-svg-icons';
import { faVolumeMute as farVolumeMute }   from '@fortawesome/pro-regular-svg-icons';
import { faVolumeMute as fasVolumeMute }   from '@fortawesome/pro-solid-svg-icons';
import { faVolumeOff as fadVolumeOff }   from '@fortawesome/pro-duotone-svg-icons';
import { faVolumeOff as falVolumeOff }   from '@fortawesome/pro-light-svg-icons';
import { faVolumeOff as farVolumeOff }   from '@fortawesome/pro-regular-svg-icons';
import { faVolumeOff as fasVolumeOff }   from '@fortawesome/pro-solid-svg-icons';
import { faVolumeSlash as fadVolumeSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faVolumeSlash as falVolumeSlash }   from '@fortawesome/pro-light-svg-icons';
import { faVolumeSlash as farVolumeSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faVolumeSlash as fasVolumeSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faVolumeTimes as fadVolumeTimes }   from '@fortawesome/pro-duotone-svg-icons';
import { faVolumeTimes as falVolumeTimes }   from '@fortawesome/pro-light-svg-icons';
import { faVolumeTimes as farVolumeTimes }   from '@fortawesome/pro-regular-svg-icons';
import { faVolumeTimes as fasVolumeTimes }   from '@fortawesome/pro-solid-svg-icons';
import { faVolumeUp as fadVolumeUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faVolumeUp as falVolumeUp }   from '@fortawesome/pro-light-svg-icons';
import { faVolumeUp as farVolumeUp }   from '@fortawesome/pro-regular-svg-icons';
import { faVolumeUp as fasVolumeUp }   from '@fortawesome/pro-solid-svg-icons';
import { faVolumeXmark as fadVolumeXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faVolumeXmark as falVolumeXmark }   from '@fortawesome/pro-light-svg-icons';
import { faVolumeXmark as farVolumeXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faVolumeXmark as fasVolumeXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faVoteNay as fadVoteNay }   from '@fortawesome/pro-duotone-svg-icons';
import { faVoteNay as falVoteNay }   from '@fortawesome/pro-light-svg-icons';
import { faVoteNay as farVoteNay }   from '@fortawesome/pro-regular-svg-icons';
import { faVoteNay as fasVoteNay }   from '@fortawesome/pro-solid-svg-icons';
import { faVoteYea as fadVoteYea }   from '@fortawesome/pro-duotone-svg-icons';
import { faVoteYea as falVoteYea }   from '@fortawesome/pro-light-svg-icons';
import { faVoteYea as farVoteYea }   from '@fortawesome/pro-regular-svg-icons';
import { faVoteYea as fasVoteYea }   from '@fortawesome/pro-solid-svg-icons';
import { faVrCardboard as fadVrCardboard }   from '@fortawesome/pro-duotone-svg-icons';
import { faVrCardboard as falVrCardboard }   from '@fortawesome/pro-light-svg-icons';
import { faVrCardboard as farVrCardboard }   from '@fortawesome/pro-regular-svg-icons';
import { faVrCardboard as fasVrCardboard }   from '@fortawesome/pro-solid-svg-icons';
import { faW as fadW }   from '@fortawesome/pro-duotone-svg-icons';
import { faW as falW }   from '@fortawesome/pro-light-svg-icons';
import { faW as farW }   from '@fortawesome/pro-regular-svg-icons';
import { faW as fasW }   from '@fortawesome/pro-solid-svg-icons';
import { faWaffle as fadWaffle }   from '@fortawesome/pro-duotone-svg-icons';
import { faWaffle as falWaffle }   from '@fortawesome/pro-light-svg-icons';
import { faWaffle as farWaffle }   from '@fortawesome/pro-regular-svg-icons';
import { faWaffle as fasWaffle }   from '@fortawesome/pro-solid-svg-icons';
import { faWagonCovered as fadWagonCovered }   from '@fortawesome/pro-duotone-svg-icons';
import { faWagonCovered as falWagonCovered }   from '@fortawesome/pro-light-svg-icons';
import { faWagonCovered as farWagonCovered }   from '@fortawesome/pro-regular-svg-icons';
import { faWagonCovered as fasWagonCovered }   from '@fortawesome/pro-solid-svg-icons';
import { faWalker as fadWalker }   from '@fortawesome/pro-duotone-svg-icons';
import { faWalker as falWalker }   from '@fortawesome/pro-light-svg-icons';
import { faWalker as farWalker }   from '@fortawesome/pro-regular-svg-icons';
import { faWalker as fasWalker }   from '@fortawesome/pro-solid-svg-icons';
import { faWalkieTalkie as fadWalkieTalkie }   from '@fortawesome/pro-duotone-svg-icons';
import { faWalkieTalkie as falWalkieTalkie }   from '@fortawesome/pro-light-svg-icons';
import { faWalkieTalkie as farWalkieTalkie }   from '@fortawesome/pro-regular-svg-icons';
import { faWalkieTalkie as fasWalkieTalkie }   from '@fortawesome/pro-solid-svg-icons';
import { faWalking as fadWalking }   from '@fortawesome/pro-duotone-svg-icons';
import { faWalking as falWalking }   from '@fortawesome/pro-light-svg-icons';
import { faWalking as farWalking }   from '@fortawesome/pro-regular-svg-icons';
import { faWalking as fasWalking }   from '@fortawesome/pro-solid-svg-icons';
import { faWallBrick as fadWallBrick }   from '@fortawesome/pro-duotone-svg-icons';
import { faWallBrick as falWallBrick }   from '@fortawesome/pro-light-svg-icons';
import { faWallBrick as farWallBrick }   from '@fortawesome/pro-regular-svg-icons';
import { faWallBrick as fasWallBrick }   from '@fortawesome/pro-solid-svg-icons';
import { faWallet as fadWallet }   from '@fortawesome/pro-duotone-svg-icons';
import { faWallet as falWallet }   from '@fortawesome/pro-light-svg-icons';
import { faWallet as farWallet }   from '@fortawesome/pro-regular-svg-icons';
import { faWallet as fasWallet }   from '@fortawesome/pro-solid-svg-icons';
import { faWand as fadWand }   from '@fortawesome/pro-duotone-svg-icons';
import { faWand as falWand }   from '@fortawesome/pro-light-svg-icons';
import { faWand as farWand }   from '@fortawesome/pro-regular-svg-icons';
import { faWand as fasWand }   from '@fortawesome/pro-solid-svg-icons';
import { faWandMagic as fadWandMagic }   from '@fortawesome/pro-duotone-svg-icons';
import { faWandMagic as falWandMagic }   from '@fortawesome/pro-light-svg-icons';
import { faWandMagic as farWandMagic }   from '@fortawesome/pro-regular-svg-icons';
import { faWandMagic as fasWandMagic }   from '@fortawesome/pro-solid-svg-icons';
import { faWandMagicSparkles as fadWandMagicSparkles }   from '@fortawesome/pro-duotone-svg-icons';
import { faWandMagicSparkles as falWandMagicSparkles }   from '@fortawesome/pro-light-svg-icons';
import { faWandMagicSparkles as farWandMagicSparkles }   from '@fortawesome/pro-regular-svg-icons';
import { faWandMagicSparkles as fasWandMagicSparkles }   from '@fortawesome/pro-solid-svg-icons';
import { faWandSparkles as fadWandSparkles }   from '@fortawesome/pro-duotone-svg-icons';
import { faWandSparkles as falWandSparkles }   from '@fortawesome/pro-light-svg-icons';
import { faWandSparkles as farWandSparkles }   from '@fortawesome/pro-regular-svg-icons';
import { faWandSparkles as fasWandSparkles }   from '@fortawesome/pro-solid-svg-icons';
import { faWarehouse as fadWarehouse }   from '@fortawesome/pro-duotone-svg-icons';
import { faWarehouse as falWarehouse }   from '@fortawesome/pro-light-svg-icons';
import { faWarehouse as farWarehouse }   from '@fortawesome/pro-regular-svg-icons';
import { faWarehouse as fasWarehouse }   from '@fortawesome/pro-solid-svg-icons';
import { faWarehouseAlt as fadWarehouseAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faWarehouseAlt as falWarehouseAlt }   from '@fortawesome/pro-light-svg-icons';
import { faWarehouseAlt as farWarehouseAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faWarehouseAlt as fasWarehouseAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faWarehouseFull as fadWarehouseFull }   from '@fortawesome/pro-duotone-svg-icons';
import { faWarehouseFull as falWarehouseFull }   from '@fortawesome/pro-light-svg-icons';
import { faWarehouseFull as farWarehouseFull }   from '@fortawesome/pro-regular-svg-icons';
import { faWarehouseFull as fasWarehouseFull }   from '@fortawesome/pro-solid-svg-icons';
import { faWarning as fadWarning }   from '@fortawesome/pro-duotone-svg-icons';
import { faWarning as falWarning }   from '@fortawesome/pro-light-svg-icons';
import { faWarning as farWarning }   from '@fortawesome/pro-regular-svg-icons';
import { faWarning as fasWarning }   from '@fortawesome/pro-solid-svg-icons';
import { faWasher as fadWasher }   from '@fortawesome/pro-duotone-svg-icons';
import { faWasher as falWasher }   from '@fortawesome/pro-light-svg-icons';
import { faWasher as farWasher }   from '@fortawesome/pro-regular-svg-icons';
import { faWasher as fasWasher }   from '@fortawesome/pro-solid-svg-icons';
import { faWashingMachine as fadWashingMachine }   from '@fortawesome/pro-duotone-svg-icons';
import { faWashingMachine as falWashingMachine }   from '@fortawesome/pro-light-svg-icons';
import { faWashingMachine as farWashingMachine }   from '@fortawesome/pro-regular-svg-icons';
import { faWashingMachine as fasWashingMachine }   from '@fortawesome/pro-solid-svg-icons';
import { faWatch as fadWatch }   from '@fortawesome/pro-duotone-svg-icons';
import { faWatch as falWatch }   from '@fortawesome/pro-light-svg-icons';
import { faWatch as farWatch }   from '@fortawesome/pro-regular-svg-icons';
import { faWatch as fasWatch }   from '@fortawesome/pro-solid-svg-icons';
import { faWatchApple as fadWatchApple }   from '@fortawesome/pro-duotone-svg-icons';
import { faWatchApple as falWatchApple }   from '@fortawesome/pro-light-svg-icons';
import { faWatchApple as farWatchApple }   from '@fortawesome/pro-regular-svg-icons';
import { faWatchApple as fasWatchApple }   from '@fortawesome/pro-solid-svg-icons';
import { faWatchCalculator as fadWatchCalculator }   from '@fortawesome/pro-duotone-svg-icons';
import { faWatchCalculator as falWatchCalculator }   from '@fortawesome/pro-light-svg-icons';
import { faWatchCalculator as farWatchCalculator }   from '@fortawesome/pro-regular-svg-icons';
import { faWatchCalculator as fasWatchCalculator }   from '@fortawesome/pro-solid-svg-icons';
import { faWatchFitness as fadWatchFitness }   from '@fortawesome/pro-duotone-svg-icons';
import { faWatchFitness as falWatchFitness }   from '@fortawesome/pro-light-svg-icons';
import { faWatchFitness as farWatchFitness }   from '@fortawesome/pro-regular-svg-icons';
import { faWatchFitness as fasWatchFitness }   from '@fortawesome/pro-solid-svg-icons';
import { faWatchSmart as fadWatchSmart }   from '@fortawesome/pro-duotone-svg-icons';
import { faWatchSmart as falWatchSmart }   from '@fortawesome/pro-light-svg-icons';
import { faWatchSmart as farWatchSmart }   from '@fortawesome/pro-regular-svg-icons';
import { faWatchSmart as fasWatchSmart }   from '@fortawesome/pro-solid-svg-icons';
import { faWater as fadWater }   from '@fortawesome/pro-duotone-svg-icons';
import { faWater as falWater }   from '@fortawesome/pro-light-svg-icons';
import { faWater as farWater }   from '@fortawesome/pro-regular-svg-icons';
import { faWater as fasWater }   from '@fortawesome/pro-solid-svg-icons';
import { faWaterArrowDown as fadWaterArrowDown }   from '@fortawesome/pro-duotone-svg-icons';
import { faWaterArrowDown as falWaterArrowDown }   from '@fortawesome/pro-light-svg-icons';
import { faWaterArrowDown as farWaterArrowDown }   from '@fortawesome/pro-regular-svg-icons';
import { faWaterArrowDown as fasWaterArrowDown }   from '@fortawesome/pro-solid-svg-icons';
import { faWaterArrowUp as fadWaterArrowUp }   from '@fortawesome/pro-duotone-svg-icons';
import { faWaterArrowUp as falWaterArrowUp }   from '@fortawesome/pro-light-svg-icons';
import { faWaterArrowUp as farWaterArrowUp }   from '@fortawesome/pro-regular-svg-icons';
import { faWaterArrowUp as fasWaterArrowUp }   from '@fortawesome/pro-solid-svg-icons';
import { faWaterLadder as fadWaterLadder }   from '@fortawesome/pro-duotone-svg-icons';
import { faWaterLadder as falWaterLadder }   from '@fortawesome/pro-light-svg-icons';
import { faWaterLadder as farWaterLadder }   from '@fortawesome/pro-regular-svg-icons';
import { faWaterLadder as fasWaterLadder }   from '@fortawesome/pro-solid-svg-icons';
import { faWaterLower as fadWaterLower }   from '@fortawesome/pro-duotone-svg-icons';
import { faWaterLower as falWaterLower }   from '@fortawesome/pro-light-svg-icons';
import { faWaterLower as farWaterLower }   from '@fortawesome/pro-regular-svg-icons';
import { faWaterLower as fasWaterLower }   from '@fortawesome/pro-solid-svg-icons';
import { faWatermelonSlice as fadWatermelonSlice }   from '@fortawesome/pro-duotone-svg-icons';
import { faWatermelonSlice as falWatermelonSlice }   from '@fortawesome/pro-light-svg-icons';
import { faWatermelonSlice as farWatermelonSlice }   from '@fortawesome/pro-regular-svg-icons';
import { faWatermelonSlice as fasWatermelonSlice }   from '@fortawesome/pro-solid-svg-icons';
import { faWaterRise as fadWaterRise }   from '@fortawesome/pro-duotone-svg-icons';
import { faWaterRise as falWaterRise }   from '@fortawesome/pro-light-svg-icons';
import { faWaterRise as farWaterRise }   from '@fortawesome/pro-regular-svg-icons';
import { faWaterRise as fasWaterRise }   from '@fortawesome/pro-solid-svg-icons';
import { faWaveform as fadWaveform }   from '@fortawesome/pro-duotone-svg-icons';
import { faWaveform as falWaveform }   from '@fortawesome/pro-light-svg-icons';
import { faWaveform as farWaveform }   from '@fortawesome/pro-regular-svg-icons';
import { faWaveform as fasWaveform }   from '@fortawesome/pro-solid-svg-icons';
import { faWaveformCircle as fadWaveformCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faWaveformCircle as falWaveformCircle }   from '@fortawesome/pro-light-svg-icons';
import { faWaveformCircle as farWaveformCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faWaveformCircle as fasWaveformCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faWaveformLines as fadWaveformLines }   from '@fortawesome/pro-duotone-svg-icons';
import { faWaveformLines as falWaveformLines }   from '@fortawesome/pro-light-svg-icons';
import { faWaveformLines as farWaveformLines }   from '@fortawesome/pro-regular-svg-icons';
import { faWaveformLines as fasWaveformLines }   from '@fortawesome/pro-solid-svg-icons';
import { faWaveformPath as fadWaveformPath }   from '@fortawesome/pro-duotone-svg-icons';
import { faWaveformPath as falWaveformPath }   from '@fortawesome/pro-light-svg-icons';
import { faWaveformPath as farWaveformPath }   from '@fortawesome/pro-regular-svg-icons';
import { faWaveformPath as fasWaveformPath }   from '@fortawesome/pro-solid-svg-icons';
import { faWavePulse as fadWavePulse }   from '@fortawesome/pro-duotone-svg-icons';
import { faWavePulse as falWavePulse }   from '@fortawesome/pro-light-svg-icons';
import { faWavePulse as farWavePulse }   from '@fortawesome/pro-regular-svg-icons';
import { faWavePulse as fasWavePulse }   from '@fortawesome/pro-solid-svg-icons';
import { faWaveSine as fadWaveSine }   from '@fortawesome/pro-duotone-svg-icons';
import { faWaveSine as falWaveSine }   from '@fortawesome/pro-light-svg-icons';
import { faWaveSine as farWaveSine }   from '@fortawesome/pro-regular-svg-icons';
import { faWaveSine as fasWaveSine }   from '@fortawesome/pro-solid-svg-icons';
import { faWaveSquare as fadWaveSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faWaveSquare as falWaveSquare }   from '@fortawesome/pro-light-svg-icons';
import { faWaveSquare as farWaveSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faWaveSquare as fasWaveSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faWaveTriangle as fadWaveTriangle }   from '@fortawesome/pro-duotone-svg-icons';
import { faWaveTriangle as falWaveTriangle }   from '@fortawesome/pro-light-svg-icons';
import { faWaveTriangle as farWaveTriangle }   from '@fortawesome/pro-regular-svg-icons';
import { faWaveTriangle as fasWaveTriangle }   from '@fortawesome/pro-solid-svg-icons';
import { faWebcam as fadWebcam }   from '@fortawesome/pro-duotone-svg-icons';
import { faWebcam as falWebcam }   from '@fortawesome/pro-light-svg-icons';
import { faWebcam as farWebcam }   from '@fortawesome/pro-regular-svg-icons';
import { faWebcam as fasWebcam }   from '@fortawesome/pro-solid-svg-icons';
import { faWebcamSlash as fadWebcamSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faWebcamSlash as falWebcamSlash }   from '@fortawesome/pro-light-svg-icons';
import { faWebcamSlash as farWebcamSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faWebcamSlash as fasWebcamSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faWeight as fadWeight }   from '@fortawesome/pro-duotone-svg-icons';
import { faWeight as falWeight }   from '@fortawesome/pro-light-svg-icons';
import { faWeight as farWeight }   from '@fortawesome/pro-regular-svg-icons';
import { faWeight as fasWeight }   from '@fortawesome/pro-solid-svg-icons';
import { faWeightHanging as fadWeightHanging }   from '@fortawesome/pro-duotone-svg-icons';
import { faWeightHanging as falWeightHanging }   from '@fortawesome/pro-light-svg-icons';
import { faWeightHanging as farWeightHanging }   from '@fortawesome/pro-regular-svg-icons';
import { faWeightHanging as fasWeightHanging }   from '@fortawesome/pro-solid-svg-icons';
import { faWeightScale as fadWeightScale }   from '@fortawesome/pro-duotone-svg-icons';
import { faWeightScale as falWeightScale }   from '@fortawesome/pro-light-svg-icons';
import { faWeightScale as farWeightScale }   from '@fortawesome/pro-regular-svg-icons';
import { faWeightScale as fasWeightScale }   from '@fortawesome/pro-solid-svg-icons';
import { faWhale as fadWhale }   from '@fortawesome/pro-duotone-svg-icons';
import { faWhale as falWhale }   from '@fortawesome/pro-light-svg-icons';
import { faWhale as farWhale }   from '@fortawesome/pro-regular-svg-icons';
import { faWhale as fasWhale }   from '@fortawesome/pro-solid-svg-icons';
import { faWheat as fadWheat }   from '@fortawesome/pro-duotone-svg-icons';
import { faWheat as falWheat }   from '@fortawesome/pro-light-svg-icons';
import { faWheat as farWheat }   from '@fortawesome/pro-regular-svg-icons';
import { faWheat as fasWheat }   from '@fortawesome/pro-solid-svg-icons';
import { faWheatAlt as fadWheatAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faWheatAlt as falWheatAlt }   from '@fortawesome/pro-light-svg-icons';
import { faWheatAlt as farWheatAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faWheatAlt as fasWheatAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faWheatAwn as fadWheatAwn }   from '@fortawesome/pro-duotone-svg-icons';
import { faWheatAwn as falWheatAwn }   from '@fortawesome/pro-light-svg-icons';
import { faWheatAwn as farWheatAwn }   from '@fortawesome/pro-regular-svg-icons';
import { faWheatAwn as fasWheatAwn }   from '@fortawesome/pro-solid-svg-icons';
import { faWheatAwnCircleExclamation as fadWheatAwnCircleExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faWheatAwnCircleExclamation as falWheatAwnCircleExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faWheatAwnCircleExclamation as farWheatAwnCircleExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faWheatAwnCircleExclamation as fasWheatAwnCircleExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faWheatAwnSlash as fadWheatAwnSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faWheatAwnSlash as falWheatAwnSlash }   from '@fortawesome/pro-light-svg-icons';
import { faWheatAwnSlash as farWheatAwnSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faWheatAwnSlash as fasWheatAwnSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faWheatSlash as fadWheatSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faWheatSlash as falWheatSlash }   from '@fortawesome/pro-light-svg-icons';
import { faWheatSlash as farWheatSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faWheatSlash as fasWheatSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faWheelchair as fadWheelchair }   from '@fortawesome/pro-duotone-svg-icons';
import { faWheelchair as falWheelchair }   from '@fortawesome/pro-light-svg-icons';
import { faWheelchair as farWheelchair }   from '@fortawesome/pro-regular-svg-icons';
import { faWheelchair as fasWheelchair }   from '@fortawesome/pro-solid-svg-icons';
import { faWheelchairAlt as fadWheelchairAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faWheelchairAlt as falWheelchairAlt }   from '@fortawesome/pro-light-svg-icons';
import { faWheelchairAlt as farWheelchairAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faWheelchairAlt as fasWheelchairAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faWheelchairMove as fadWheelchairMove }   from '@fortawesome/pro-duotone-svg-icons';
import { faWheelchairMove as falWheelchairMove }   from '@fortawesome/pro-light-svg-icons';
import { faWheelchairMove as farWheelchairMove }   from '@fortawesome/pro-regular-svg-icons';
import { faWheelchairMove as fasWheelchairMove }   from '@fortawesome/pro-solid-svg-icons';
import { faWhiskeyGlass as fadWhiskeyGlass }   from '@fortawesome/pro-duotone-svg-icons';
import { faWhiskeyGlass as falWhiskeyGlass }   from '@fortawesome/pro-light-svg-icons';
import { faWhiskeyGlass as farWhiskeyGlass }   from '@fortawesome/pro-regular-svg-icons';
import { faWhiskeyGlass as fasWhiskeyGlass }   from '@fortawesome/pro-solid-svg-icons';
import { faWhiskeyGlassIce as fadWhiskeyGlassIce }   from '@fortawesome/pro-duotone-svg-icons';
import { faWhiskeyGlassIce as falWhiskeyGlassIce }   from '@fortawesome/pro-light-svg-icons';
import { faWhiskeyGlassIce as farWhiskeyGlassIce }   from '@fortawesome/pro-regular-svg-icons';
import { faWhiskeyGlassIce as fasWhiskeyGlassIce }   from '@fortawesome/pro-solid-svg-icons';
import { faWhistle as fadWhistle }   from '@fortawesome/pro-duotone-svg-icons';
import { faWhistle as falWhistle }   from '@fortawesome/pro-light-svg-icons';
import { faWhistle as farWhistle }   from '@fortawesome/pro-regular-svg-icons';
import { faWhistle as fasWhistle }   from '@fortawesome/pro-solid-svg-icons';
import { faWifi as fadWifi }   from '@fortawesome/pro-duotone-svg-icons';
import { faWifi as falWifi }   from '@fortawesome/pro-light-svg-icons';
import { faWifi as farWifi }   from '@fortawesome/pro-regular-svg-icons';
import { faWifi as fasWifi }   from '@fortawesome/pro-solid-svg-icons';
import { faWifi1 as fadWifi1 }   from '@fortawesome/pro-duotone-svg-icons';
import { faWifi1 as falWifi1 }   from '@fortawesome/pro-light-svg-icons';
import { faWifi1 as farWifi1 }   from '@fortawesome/pro-regular-svg-icons';
import { faWifi1 as fasWifi1 }   from '@fortawesome/pro-solid-svg-icons';
import { faWifi2 as fadWifi2 }   from '@fortawesome/pro-duotone-svg-icons';
import { faWifi2 as falWifi2 }   from '@fortawesome/pro-light-svg-icons';
import { faWifi2 as farWifi2 }   from '@fortawesome/pro-regular-svg-icons';
import { faWifi2 as fasWifi2 }   from '@fortawesome/pro-solid-svg-icons';
import { faWifi3 as fadWifi3 }   from '@fortawesome/pro-duotone-svg-icons';
import { faWifi3 as falWifi3 }   from '@fortawesome/pro-light-svg-icons';
import { faWifi3 as farWifi3 }   from '@fortawesome/pro-regular-svg-icons';
import { faWifi3 as fasWifi3 }   from '@fortawesome/pro-solid-svg-icons';
import { faWifiExclamation as fadWifiExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faWifiExclamation as falWifiExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faWifiExclamation as farWifiExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faWifiExclamation as fasWifiExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faWifiFair as fadWifiFair }   from '@fortawesome/pro-duotone-svg-icons';
import { faWifiFair as falWifiFair }   from '@fortawesome/pro-light-svg-icons';
import { faWifiFair as farWifiFair }   from '@fortawesome/pro-regular-svg-icons';
import { faWifiFair as fasWifiFair }   from '@fortawesome/pro-solid-svg-icons';
import { faWifiSlash as fadWifiSlash }   from '@fortawesome/pro-duotone-svg-icons';
import { faWifiSlash as falWifiSlash }   from '@fortawesome/pro-light-svg-icons';
import { faWifiSlash as farWifiSlash }   from '@fortawesome/pro-regular-svg-icons';
import { faWifiSlash as fasWifiSlash }   from '@fortawesome/pro-solid-svg-icons';
import { faWifiStrong as fadWifiStrong }   from '@fortawesome/pro-duotone-svg-icons';
import { faWifiStrong as falWifiStrong }   from '@fortawesome/pro-light-svg-icons';
import { faWifiStrong as farWifiStrong }   from '@fortawesome/pro-regular-svg-icons';
import { faWifiStrong as fasWifiStrong }   from '@fortawesome/pro-solid-svg-icons';
import { faWifiWeak as fadWifiWeak }   from '@fortawesome/pro-duotone-svg-icons';
import { faWifiWeak as falWifiWeak }   from '@fortawesome/pro-light-svg-icons';
import { faWifiWeak as farWifiWeak }   from '@fortawesome/pro-regular-svg-icons';
import { faWifiWeak as fasWifiWeak }   from '@fortawesome/pro-solid-svg-icons';
import { faWind as fadWind }   from '@fortawesome/pro-duotone-svg-icons';
import { faWind as falWind }   from '@fortawesome/pro-light-svg-icons';
import { faWind as farWind }   from '@fortawesome/pro-regular-svg-icons';
import { faWind as fasWind }   from '@fortawesome/pro-solid-svg-icons';
import { faWindCircleExclamation as fadWindCircleExclamation }   from '@fortawesome/pro-duotone-svg-icons';
import { faWindCircleExclamation as falWindCircleExclamation }   from '@fortawesome/pro-light-svg-icons';
import { faWindCircleExclamation as farWindCircleExclamation }   from '@fortawesome/pro-regular-svg-icons';
import { faWindCircleExclamation as fasWindCircleExclamation }   from '@fortawesome/pro-solid-svg-icons';
import { faWindow as fadWindow }   from '@fortawesome/pro-duotone-svg-icons';
import { faWindow as falWindow }   from '@fortawesome/pro-light-svg-icons';
import { faWindow as farWindow }   from '@fortawesome/pro-regular-svg-icons';
import { faWindow as fasWindow }   from '@fortawesome/pro-solid-svg-icons';
import { faWindowAlt as fadWindowAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faWindowAlt as falWindowAlt }   from '@fortawesome/pro-light-svg-icons';
import { faWindowAlt as farWindowAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faWindowAlt as fasWindowAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faWindowClose as fadWindowClose }   from '@fortawesome/pro-duotone-svg-icons';
import { faWindowClose as falWindowClose }   from '@fortawesome/pro-light-svg-icons';
import { faWindowClose as farWindowClose }   from '@fortawesome/pro-regular-svg-icons';
import { faWindowClose as fasWindowClose }   from '@fortawesome/pro-solid-svg-icons';
import { faWindowFlip as fadWindowFlip }   from '@fortawesome/pro-duotone-svg-icons';
import { faWindowFlip as falWindowFlip }   from '@fortawesome/pro-light-svg-icons';
import { faWindowFlip as farWindowFlip }   from '@fortawesome/pro-regular-svg-icons';
import { faWindowFlip as fasWindowFlip }   from '@fortawesome/pro-solid-svg-icons';
import { faWindowFrame as fadWindowFrame }   from '@fortawesome/pro-duotone-svg-icons';
import { faWindowFrame as falWindowFrame }   from '@fortawesome/pro-light-svg-icons';
import { faWindowFrame as farWindowFrame }   from '@fortawesome/pro-regular-svg-icons';
import { faWindowFrame as fasWindowFrame }   from '@fortawesome/pro-solid-svg-icons';
import { faWindowFrameOpen as fadWindowFrameOpen }   from '@fortawesome/pro-duotone-svg-icons';
import { faWindowFrameOpen as falWindowFrameOpen }   from '@fortawesome/pro-light-svg-icons';
import { faWindowFrameOpen as farWindowFrameOpen }   from '@fortawesome/pro-regular-svg-icons';
import { faWindowFrameOpen as fasWindowFrameOpen }   from '@fortawesome/pro-solid-svg-icons';
import { faWindowMaximize as fadWindowMaximize }   from '@fortawesome/pro-duotone-svg-icons';
import { faWindowMaximize as falWindowMaximize }   from '@fortawesome/pro-light-svg-icons';
import { faWindowMaximize as farWindowMaximize }   from '@fortawesome/pro-regular-svg-icons';
import { faWindowMaximize as fasWindowMaximize }   from '@fortawesome/pro-solid-svg-icons';
import { faWindowMinimize as fadWindowMinimize }   from '@fortawesome/pro-duotone-svg-icons';
import { faWindowMinimize as falWindowMinimize }   from '@fortawesome/pro-light-svg-icons';
import { faWindowMinimize as farWindowMinimize }   from '@fortawesome/pro-regular-svg-icons';
import { faWindowMinimize as fasWindowMinimize }   from '@fortawesome/pro-solid-svg-icons';
import { faWindowRestore as fadWindowRestore }   from '@fortawesome/pro-duotone-svg-icons';
import { faWindowRestore as falWindowRestore }   from '@fortawesome/pro-light-svg-icons';
import { faWindowRestore as farWindowRestore }   from '@fortawesome/pro-regular-svg-icons';
import { faWindowRestore as fasWindowRestore }   from '@fortawesome/pro-solid-svg-icons';
import { faWindsock as fadWindsock }   from '@fortawesome/pro-duotone-svg-icons';
import { faWindsock as falWindsock }   from '@fortawesome/pro-light-svg-icons';
import { faWindsock as farWindsock }   from '@fortawesome/pro-regular-svg-icons';
import { faWindsock as fasWindsock }   from '@fortawesome/pro-solid-svg-icons';
import { faWindTurbine as fadWindTurbine }   from '@fortawesome/pro-duotone-svg-icons';
import { faWindTurbine as falWindTurbine }   from '@fortawesome/pro-light-svg-icons';
import { faWindTurbine as farWindTurbine }   from '@fortawesome/pro-regular-svg-icons';
import { faWindTurbine as fasWindTurbine }   from '@fortawesome/pro-solid-svg-icons';
import { faWindWarning as fadWindWarning }   from '@fortawesome/pro-duotone-svg-icons';
import { faWindWarning as falWindWarning }   from '@fortawesome/pro-light-svg-icons';
import { faWindWarning as farWindWarning }   from '@fortawesome/pro-regular-svg-icons';
import { faWindWarning as fasWindWarning }   from '@fortawesome/pro-solid-svg-icons';
import { faWineBottle as fadWineBottle }   from '@fortawesome/pro-duotone-svg-icons';
import { faWineBottle as falWineBottle }   from '@fortawesome/pro-light-svg-icons';
import { faWineBottle as farWineBottle }   from '@fortawesome/pro-regular-svg-icons';
import { faWineBottle as fasWineBottle }   from '@fortawesome/pro-solid-svg-icons';
import { faWineGlass as fadWineGlass }   from '@fortawesome/pro-duotone-svg-icons';
import { faWineGlass as falWineGlass }   from '@fortawesome/pro-light-svg-icons';
import { faWineGlass as farWineGlass }   from '@fortawesome/pro-regular-svg-icons';
import { faWineGlass as fasWineGlass }   from '@fortawesome/pro-solid-svg-icons';
import { faWineGlassAlt as fadWineGlassAlt }   from '@fortawesome/pro-duotone-svg-icons';
import { faWineGlassAlt as falWineGlassAlt }   from '@fortawesome/pro-light-svg-icons';
import { faWineGlassAlt as farWineGlassAlt }   from '@fortawesome/pro-regular-svg-icons';
import { faWineGlassAlt as fasWineGlassAlt }   from '@fortawesome/pro-solid-svg-icons';
import { faWineGlassCrack as fadWineGlassCrack }   from '@fortawesome/pro-duotone-svg-icons';
import { faWineGlassCrack as falWineGlassCrack }   from '@fortawesome/pro-light-svg-icons';
import { faWineGlassCrack as farWineGlassCrack }   from '@fortawesome/pro-regular-svg-icons';
import { faWineGlassCrack as fasWineGlassCrack }   from '@fortawesome/pro-solid-svg-icons';
import { faWineGlassEmpty as fadWineGlassEmpty }   from '@fortawesome/pro-duotone-svg-icons';
import { faWineGlassEmpty as falWineGlassEmpty }   from '@fortawesome/pro-light-svg-icons';
import { faWineGlassEmpty as farWineGlassEmpty }   from '@fortawesome/pro-regular-svg-icons';
import { faWineGlassEmpty as fasWineGlassEmpty }   from '@fortawesome/pro-solid-svg-icons';
import { faWon as fadWon }   from '@fortawesome/pro-duotone-svg-icons';
import { faWon as falWon }   from '@fortawesome/pro-light-svg-icons';
import { faWon as farWon }   from '@fortawesome/pro-regular-svg-icons';
import { faWon as fasWon }   from '@fortawesome/pro-solid-svg-icons';
import { faWonSign as fadWonSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faWonSign as falWonSign }   from '@fortawesome/pro-light-svg-icons';
import { faWonSign as farWonSign }   from '@fortawesome/pro-regular-svg-icons';
import { faWonSign as fasWonSign }   from '@fortawesome/pro-solid-svg-icons';
import { faWorm as fadWorm }   from '@fortawesome/pro-duotone-svg-icons';
import { faWorm as falWorm }   from '@fortawesome/pro-light-svg-icons';
import { faWorm as farWorm }   from '@fortawesome/pro-regular-svg-icons';
import { faWorm as fasWorm }   from '@fortawesome/pro-solid-svg-icons';
import { faWreath as fadWreath }   from '@fortawesome/pro-duotone-svg-icons';
import { faWreath as falWreath }   from '@fortawesome/pro-light-svg-icons';
import { faWreath as farWreath }   from '@fortawesome/pro-regular-svg-icons';
import { faWreath as fasWreath }   from '@fortawesome/pro-solid-svg-icons';
import { faWrench as fadWrench }   from '@fortawesome/pro-duotone-svg-icons';
import { faWrench as falWrench }   from '@fortawesome/pro-light-svg-icons';
import { faWrench as farWrench }   from '@fortawesome/pro-regular-svg-icons';
import { faWrench as fasWrench }   from '@fortawesome/pro-solid-svg-icons';
import { faWrenchSimple as fadWrenchSimple }   from '@fortawesome/pro-duotone-svg-icons';
import { faWrenchSimple as falWrenchSimple }   from '@fortawesome/pro-light-svg-icons';
import { faWrenchSimple as farWrenchSimple }   from '@fortawesome/pro-regular-svg-icons';
import { faWrenchSimple as fasWrenchSimple }   from '@fortawesome/pro-solid-svg-icons';
import { faX as fadX }   from '@fortawesome/pro-duotone-svg-icons';
import { faX as falX }   from '@fortawesome/pro-light-svg-icons';
import { faX as farX }   from '@fortawesome/pro-regular-svg-icons';
import { faX as fasX }   from '@fortawesome/pro-solid-svg-icons';
import { faXmark as fadXmark }   from '@fortawesome/pro-duotone-svg-icons';
import { faXmark as falXmark }   from '@fortawesome/pro-light-svg-icons';
import { faXmark as farXmark }   from '@fortawesome/pro-regular-svg-icons';
import { faXmark as fasXmark }   from '@fortawesome/pro-solid-svg-icons';
import { faXmarkCircle as fadXmarkCircle }   from '@fortawesome/pro-duotone-svg-icons';
import { faXmarkCircle as falXmarkCircle }   from '@fortawesome/pro-light-svg-icons';
import { faXmarkCircle as farXmarkCircle }   from '@fortawesome/pro-regular-svg-icons';
import { faXmarkCircle as fasXmarkCircle }   from '@fortawesome/pro-solid-svg-icons';
import { faXmarkHexagon as fadXmarkHexagon }   from '@fortawesome/pro-duotone-svg-icons';
import { faXmarkHexagon as falXmarkHexagon }   from '@fortawesome/pro-light-svg-icons';
import { faXmarkHexagon as farXmarkHexagon }   from '@fortawesome/pro-regular-svg-icons';
import { faXmarkHexagon as fasXmarkHexagon }   from '@fortawesome/pro-solid-svg-icons';
import { faXmarkLarge as fadXmarkLarge }   from '@fortawesome/pro-duotone-svg-icons';
import { faXmarkLarge as falXmarkLarge }   from '@fortawesome/pro-light-svg-icons';
import { faXmarkLarge as farXmarkLarge }   from '@fortawesome/pro-regular-svg-icons';
import { faXmarkLarge as fasXmarkLarge }   from '@fortawesome/pro-solid-svg-icons';
import { faXmarkOctagon as fadXmarkOctagon }   from '@fortawesome/pro-duotone-svg-icons';
import { faXmarkOctagon as falXmarkOctagon }   from '@fortawesome/pro-light-svg-icons';
import { faXmarkOctagon as farXmarkOctagon }   from '@fortawesome/pro-regular-svg-icons';
import { faXmarkOctagon as fasXmarkOctagon }   from '@fortawesome/pro-solid-svg-icons';
import { faXmarksLines as fadXmarksLines }   from '@fortawesome/pro-duotone-svg-icons';
import { faXmarksLines as falXmarksLines }   from '@fortawesome/pro-light-svg-icons';
import { faXmarksLines as farXmarksLines }   from '@fortawesome/pro-regular-svg-icons';
import { faXmarksLines as fasXmarksLines }   from '@fortawesome/pro-solid-svg-icons';
import { faXmarkSquare as fadXmarkSquare }   from '@fortawesome/pro-duotone-svg-icons';
import { faXmarkSquare as falXmarkSquare }   from '@fortawesome/pro-light-svg-icons';
import { faXmarkSquare as farXmarkSquare }   from '@fortawesome/pro-regular-svg-icons';
import { faXmarkSquare as fasXmarkSquare }   from '@fortawesome/pro-solid-svg-icons';
import { faXmarkToSlot as fadXmarkToSlot }   from '@fortawesome/pro-duotone-svg-icons';
import { faXmarkToSlot as falXmarkToSlot }   from '@fortawesome/pro-light-svg-icons';
import { faXmarkToSlot as farXmarkToSlot }   from '@fortawesome/pro-regular-svg-icons';
import { faXmarkToSlot as fasXmarkToSlot }   from '@fortawesome/pro-solid-svg-icons';
import { faXRay as fadXRay }   from '@fortawesome/pro-duotone-svg-icons';
import { faXRay as falXRay }   from '@fortawesome/pro-light-svg-icons';
import { faXRay as farXRay }   from '@fortawesome/pro-regular-svg-icons';
import { faXRay as fasXRay }   from '@fortawesome/pro-solid-svg-icons';
import { faY as fadY }   from '@fortawesome/pro-duotone-svg-icons';
import { faY as falY }   from '@fortawesome/pro-light-svg-icons';
import { faY as farY }   from '@fortawesome/pro-regular-svg-icons';
import { faY as fasY }   from '@fortawesome/pro-solid-svg-icons';
import { faYen as fadYen }   from '@fortawesome/pro-duotone-svg-icons';
import { faYen as falYen }   from '@fortawesome/pro-light-svg-icons';
import { faYen as farYen }   from '@fortawesome/pro-regular-svg-icons';
import { faYen as fasYen }   from '@fortawesome/pro-solid-svg-icons';
import { faYenSign as fadYenSign }   from '@fortawesome/pro-duotone-svg-icons';
import { faYenSign as falYenSign }   from '@fortawesome/pro-light-svg-icons';
import { faYenSign as farYenSign }   from '@fortawesome/pro-regular-svg-icons';
import { faYenSign as fasYenSign }   from '@fortawesome/pro-solid-svg-icons';
import { faYinYang as fadYinYang }   from '@fortawesome/pro-duotone-svg-icons';
import { faYinYang as falYinYang }   from '@fortawesome/pro-light-svg-icons';
import { faYinYang as farYinYang }   from '@fortawesome/pro-regular-svg-icons';
import { faYinYang as fasYinYang }   from '@fortawesome/pro-solid-svg-icons';
import { faZ as fadZ }   from '@fortawesome/pro-duotone-svg-icons';
import { faZ as falZ }   from '@fortawesome/pro-light-svg-icons';
import { faZ as farZ }   from '@fortawesome/pro-regular-svg-icons';
import { faZ as fasZ }   from '@fortawesome/pro-solid-svg-icons';
import { faZap as fadZap }   from '@fortawesome/pro-duotone-svg-icons';
import { faZap as falZap }   from '@fortawesome/pro-light-svg-icons';
import { faZap as farZap }   from '@fortawesome/pro-regular-svg-icons';
import { faZap as fasZap }   from '@fortawesome/pro-solid-svg-icons';
import { faZzz as fadZzz }   from '@fortawesome/pro-duotone-svg-icons';
import { faZzz as falZzz }   from '@fortawesome/pro-light-svg-icons';
import { faZzz as farZzz }   from '@fortawesome/pro-regular-svg-icons';
import { faZzz as fasZzz }   from '@fortawesome/pro-solid-svg-icons';

@Injectable({
    providedIn: 'root'
  })
  
  export class IconService implements FaIconLibraryInterface {

    addIcons(...icons: IconDefinition[]): void {
        
    }

    addIconPacks(...packs: IconPack[]): void {
        
    }

    getIconDefinition(prefix: IconPrefix, name: IconName): IconDefinition {
        return undefined;
    }


fad0 = fad0;
fad00 = fad00;
fad1 = fad1;
fad100 = fad100;
fad2 = fad2;
fad3 = fad3;
fad360Degrees = fad360Degrees;
fad4 = fad4;
fad5 = fad5;
fad6 = fad6;
fad7 = fad7;
fad8 = fad8;
fad9 = fad9;
fadA = fadA;
fadAbacus = fadAbacus;
fadAccentGrave = fadAccentGrave;
fadAcorn = fadAcorn;
fadAd = fadAd;
fadAdd = fadAdd;
fadAddressBook = fadAddressBook;
fadAddressCard = fadAddressCard;
fadAdjust = fadAdjust;
fadAirConditioner = fadAirConditioner;
fadAirFreshener = fadAirFreshener;
fadAirplay = fadAirplay;
fadAlarmClock = fadAlarmClock;
fadAlarmExclamation = fadAlarmExclamation;
fadAlarmPlus = fadAlarmPlus;
fadAlarmSnooze = fadAlarmSnooze;
fadAlbum = fadAlbum;
fadAlbumCirclePlus = fadAlbumCirclePlus;
fadAlbumCircleUser = fadAlbumCircleUser;
fadAlbumCollection = fadAlbumCollection;
fadAlbumCollectionCirclePlus = fadAlbumCollectionCirclePlus;
fadAlbumCollectionCircleUser = fadAlbumCollectionCircleUser;
fadAlicorn = fadAlicorn;
fadAlien = fadAlien;
fadAlien8bit = fadAlien8bit;
fadAlienMonster = fadAlienMonster;
fadAlignCenter = fadAlignCenter;
fadAlignJustify = fadAlignJustify;
fadAlignLeft = fadAlignLeft;
fadAlignRight = fadAlignRight;
fadAlignSlash = fadAlignSlash;
fadAllergies = fadAllergies;
fadAlt = fadAlt;
fadAmbulance = fadAmbulance;
fadAmericanSignLanguageInterpreting = fadAmericanSignLanguageInterpreting;
fadAmpersand = fadAmpersand;
fadAmpGuitar = fadAmpGuitar;
fadAnalytics = fadAnalytics;
fadAnchor = fadAnchor;
fadAnchorCircleCheck = fadAnchorCircleCheck;
fadAnchorCircleExclamation = fadAnchorCircleExclamation;
fadAnchorCircleXmark = fadAnchorCircleXmark;
fadAnchorLock = fadAnchorLock;
fadAngel = fadAngel;
fadAngle = fadAngle;
fadAngle90 = fadAngle90;
fadAngleDoubleDown = fadAngleDoubleDown;
fadAngleDoubleLeft = fadAngleDoubleLeft;
fadAngleDoubleRight = fadAngleDoubleRight;
fadAngleDoubleUp = fadAngleDoubleUp;
fadAngleDown = fadAngleDown;
fadAngleLeft = fadAngleLeft;
fadAngleRight = fadAngleRight;
fadAnglesDown = fadAnglesDown;
fadAnglesLeft = fadAnglesLeft;
fadAnglesRight = fadAnglesRight;
fadAnglesUp = fadAnglesUp;
fadAngleUp = fadAngleUp;
fadAngry = fadAngry;
fadAnkh = fadAnkh;
fadApartment = fadApartment;
fadAperture = fadAperture;
fadApostrophe = fadApostrophe;
fadAppleAlt = fadAppleAlt;
fadAppleCore = fadAppleCore;
fadAppleCrate = fadAppleCrate;
fadAppleWhole = fadAppleWhole;
fadArchive = fadArchive;
fadArchway = fadArchway;
fadAreaChart = fadAreaChart;
fadArrowAltCircleDown = fadArrowAltCircleDown;
fadArrowAltCircleLeft = fadArrowAltCircleLeft;
fadArrowAltCircleRight = fadArrowAltCircleRight;
fadArrowAltCircleUp = fadArrowAltCircleUp;
fadArrowAltDown = fadArrowAltDown;
fadArrowAltFromBottom = fadArrowAltFromBottom;
fadArrowAltFromLeft = fadArrowAltFromLeft;
fadArrowAltFromRight = fadArrowAltFromRight;
fadArrowAltFromTop = fadArrowAltFromTop;
fadArrowAltLeft = fadArrowAltLeft;
fadArrowAltRight = fadArrowAltRight;
fadArrowAltSquareDown = fadArrowAltSquareDown;
fadArrowAltSquareLeft = fadArrowAltSquareLeft;
fadArrowAltSquareRight = fadArrowAltSquareRight;
fadArrowAltSquareUp = fadArrowAltSquareUp;
fadArrowAltToBottom = fadArrowAltToBottom;
fadArrowAltToLeft = fadArrowAltToLeft;
fadArrowAltToRight = fadArrowAltToRight;
fadArrowAltToTop = fadArrowAltToTop;
fadArrowAltUp = fadArrowAltUp;
fadArrowCircleDown = fadArrowCircleDown;
fadArrowCircleLeft = fadArrowCircleLeft;
fadArrowCircleRight = fadArrowCircleRight;
fadArrowCircleUp = fadArrowCircleUp;
fadArrowDown = fadArrowDown;
fadArrowDown19 = fadArrowDown19;
fadArrowDown91 = fadArrowDown91;
fadArrowDownArrowUp = fadArrowDownArrowUp;
fadArrowDownAZ = fadArrowDownAZ;
fadArrowDownBigSmall = fadArrowDownBigSmall;
fadArrowDownFromDottedLine = fadArrowDownFromDottedLine;
fadArrowDownFromLine = fadArrowDownFromLine;
fadArrowDownLeft = fadArrowDownLeft;
fadArrowDownLeftAndArrowUpRightToCenter = fadArrowDownLeftAndArrowUpRightToCenter;
fadArrowDownLong = fadArrowDownLong;
fadArrowDownRight = fadArrowDownRight;
fadArrowDownShortWide = fadArrowDownShortWide;
fadArrowDownSmallBig = fadArrowDownSmallBig;
fadArrowDownSquareTriangle = fadArrowDownSquareTriangle;
fadArrowDownToArc = fadArrowDownToArc;
fadArrowDownToBracket = fadArrowDownToBracket;
fadArrowDownToDottedLine = fadArrowDownToDottedLine;
fadArrowDownToLine = fadArrowDownToLine;
fadArrowDownToSquare = fadArrowDownToSquare;
fadArrowDownTriangleSquare = fadArrowDownTriangleSquare;
fadArrowDownUpAcrossLine = fadArrowDownUpAcrossLine;
fadArrowDownUpLock = fadArrowDownUpLock;
fadArrowDownWideShort = fadArrowDownWideShort;
fadArrowDownZA = fadArrowDownZA;
fadArrowFromBottom = fadArrowFromBottom;
fadArrowFromLeft = fadArrowFromLeft;
fadArrowFromRight = fadArrowFromRight;
fadArrowFromTop = fadArrowFromTop;
fadArrowLeft = fadArrowLeft;
fadArrowLeftFromLine = fadArrowLeftFromLine;
fadArrowLeftLong = fadArrowLeftLong;
fadArrowLeftLongToLine = fadArrowLeftLongToLine;
fadArrowLeftRotate = fadArrowLeftRotate;
fadArrowLeftToLine = fadArrowLeftToLine;
fadArrowPointer = fadArrowPointer;
fadArrowRight = fadArrowRight;
fadArrowRightArrowLeft = fadArrowRightArrowLeft;
fadArrowRightFromArc = fadArrowRightFromArc;
fadArrowRightFromBracket = fadArrowRightFromBracket;
fadArrowRightFromFile = fadArrowRightFromFile;
fadArrowRightFromLine = fadArrowRightFromLine;
fadArrowRightLong = fadArrowRightLong;
fadArrowRightLongToLine = fadArrowRightLongToLine;
fadArrowRightRotate = fadArrowRightRotate;
fadArrowRightToArc = fadArrowRightToArc;
fadArrowRightToBracket = fadArrowRightToBracket;
fadArrowRightToCity = fadArrowRightToCity;
fadArrowRightToFile = fadArrowRightToFile;
fadArrowRightToLine = fadArrowRightToLine;
fadArrowRotateBack = fadArrowRotateBack;
fadArrowRotateBackward = fadArrowRotateBackward;
fadArrowRotateForward = fadArrowRotateForward;
fadArrowRotateLeft = fadArrowRotateLeft;
fadArrowRotateRight = fadArrowRotateRight;
fadArrows = fadArrows;
fadArrowsAlt = fadArrowsAlt;
fadArrowsAltH = fadArrowsAltH;
fadArrowsAltV = fadArrowsAltV;
fadArrowsCross = fadArrowsCross;
fadArrowsDownToLine = fadArrowsDownToLine;
fadArrowsDownToPeople = fadArrowsDownToPeople;
fadArrowsFromDottedLine = fadArrowsFromDottedLine;
fadArrowsFromLine = fadArrowsFromLine;
fadArrowsH = fadArrowsH;
fadArrowsLeftRight = fadArrowsLeftRight;
fadArrowsLeftRightToLine = fadArrowsLeftRightToLine;
fadArrowsMaximize = fadArrowsMaximize;
fadArrowsMinimize = fadArrowsMinimize;
fadArrowSquareDown = fadArrowSquareDown;
fadArrowSquareLeft = fadArrowSquareLeft;
fadArrowSquareRight = fadArrowSquareRight;
fadArrowSquareUp = fadArrowSquareUp;
fadArrowsRepeat = fadArrowsRepeat;
fadArrowsRepeat1 = fadArrowsRepeat1;
fadArrowsRetweet = fadArrowsRetweet;
fadArrowsRotate = fadArrowsRotate;
fadArrowsSpin = fadArrowsSpin;
fadArrowsSplitUpAndLeft = fadArrowsSplitUpAndLeft;
fadArrowsToCircle = fadArrowsToCircle;
fadArrowsToDot = fadArrowsToDot;
fadArrowsToDottedLine = fadArrowsToDottedLine;
fadArrowsToEye = fadArrowsToEye;
fadArrowsToLine = fadArrowsToLine;
fadArrowsTurnRight = fadArrowsTurnRight;
fadArrowsTurnToDots = fadArrowsTurnToDots;
fadArrowsUpDown = fadArrowsUpDown;
fadArrowsUpDownLeftRight = fadArrowsUpDownLeftRight;
fadArrowsUpToLine = fadArrowsUpToLine;
fadArrowsV = fadArrowsV;
fadArrowToBottom = fadArrowToBottom;
fadArrowToLeft = fadArrowToLeft;
fadArrowToRight = fadArrowToRight;
fadArrowToTop = fadArrowToTop;
fadArrowTrendDown = fadArrowTrendDown;
fadArrowTrendUp = fadArrowTrendUp;
fadArrowTurnDown = fadArrowTurnDown;
fadArrowTurnDownLeft = fadArrowTurnDownLeft;
fadArrowTurnDownRight = fadArrowTurnDownRight;
fadArrowTurnRight = fadArrowTurnRight;
fadArrowTurnUp = fadArrowTurnUp;
fadArrowUp = fadArrowUp;
fadArrowUp19 = fadArrowUp19;
fadArrowUp91 = fadArrowUp91;
fadArrowUpArrowDown = fadArrowUpArrowDown;
fadArrowUpAZ = fadArrowUpAZ;
fadArrowUpBigSmall = fadArrowUpBigSmall;
fadArrowUpFromArc = fadArrowUpFromArc;
fadArrowUpFromBracket = fadArrowUpFromBracket;
fadArrowUpFromDottedLine = fadArrowUpFromDottedLine;
fadArrowUpFromGroundWater = fadArrowUpFromGroundWater;
fadArrowUpFromLine = fadArrowUpFromLine;
fadArrowUpFromSquare = fadArrowUpFromSquare;
fadArrowUpFromWaterPump = fadArrowUpFromWaterPump;
fadArrowUpLeft = fadArrowUpLeft;
fadArrowUpLeftFromCircle = fadArrowUpLeftFromCircle;
fadArrowUpLong = fadArrowUpLong;
fadArrowUpRight = fadArrowUpRight;
fadArrowUpRightAndArrowDownLeftFromCenter = fadArrowUpRightAndArrowDownLeftFromCenter;
fadArrowUpRightDots = fadArrowUpRightDots;
fadArrowUpRightFromSquare = fadArrowUpRightFromSquare;
fadArrowUpShortWide = fadArrowUpShortWide;
fadArrowUpSmallBig = fadArrowUpSmallBig;
fadArrowUpSquareTriangle = fadArrowUpSquareTriangle;
fadArrowUpToDottedLine = fadArrowUpToDottedLine;
fadArrowUpToLine = fadArrowUpToLine;
fadArrowUpTriangleSquare = fadArrowUpTriangleSquare;
fadArrowUpWideShort = fadArrowUpWideShort;
fadArrowUpZA = fadArrowUpZA;
fadAslInterpreting = fadAslInterpreting;
fadAssistiveListeningSystems = fadAssistiveListeningSystems;
fadAsterisk = fadAsterisk;
fadAt = fadAt;
fadAtlas = fadAtlas;
fadAtom = fadAtom;
fadAtomAlt = fadAtomAlt;
fadAtomSimple = fadAtomSimple;
fadAudioDescription = fadAudioDescription;
fadAudioDescriptionSlash = fadAudioDescriptionSlash;
fadAustralSign = fadAustralSign;
fadAutomobile = fadAutomobile;
fadAvocado = fadAvocado;
fadAward = fadAward;
fadAwardSimple = fadAwardSimple;
fadAxe = fadAxe;
fadAxeBattle = fadAxeBattle;
fadB = fadB;
fadBaby = fadBaby;
fadBabyCarriage = fadBabyCarriage;
fadBackpack = fadBackpack;
fadBackspace = fadBackspace;
fadBackward = fadBackward;
fadBackwardFast = fadBackwardFast;
fadBackwardStep = fadBackwardStep;
fadBacon = fadBacon;
fadBacteria = fadBacteria;
fadBacterium = fadBacterium;
fadBadge = fadBadge;
fadBadgeCheck = fadBadgeCheck;
fadBadgeDollar = fadBadgeDollar;
fadBadgePercent = fadBadgePercent;
fadBadgerHoney = fadBadgerHoney;
fadBadgeSheriff = fadBadgeSheriff;
fadBadminton = fadBadminton;
fadBagel = fadBagel;
fadBagShopping = fadBagShopping;
fadBagsShopping = fadBagsShopping;
fadBaguette = fadBaguette;
fadBahai = fadBahai;
fadBahtSign = fadBahtSign;
fadBalanceScale = fadBalanceScale;
fadBalanceScaleLeft = fadBalanceScaleLeft;
fadBalanceScaleRight = fadBalanceScaleRight;
fadBalloon = fadBalloon;
fadBalloons = fadBalloons;
fadBallot = fadBallot;
fadBallotCheck = fadBallotCheck;
fadBallPile = fadBallPile;
fadBan = fadBan;
fadBanana = fadBanana;
fadBanBug = fadBanBug;
fadBandage = fadBandage;
fadBandAid = fadBandAid;
fadBangladeshiTakaSign = fadBangladeshiTakaSign;
fadBanjo = fadBanjo;
fadBank = fadBank;
fadBanParking = fadBanParking;
fadBanSmoking = fadBanSmoking;
fadBarChart = fadBarChart;
fadBarcode = fadBarcode;
fadBarcodeAlt = fadBarcodeAlt;
fadBarcodeRead = fadBarcodeRead;
fadBarcodeScan = fadBarcodeScan;
fadBarnSilo = fadBarnSilo;
fadBars = fadBars;
fadBarsFilter = fadBarsFilter;
fadBarsProgress = fadBarsProgress;
fadBarsSort = fadBarsSort;
fadBarsStaggered = fadBarsStaggered;
fadBaseball = fadBaseball;
fadBaseballBall = fadBaseballBall;
fadBaseballBatBall = fadBaseballBatBall;
fadBasketball = fadBasketball;
fadBasketballBall = fadBasketballBall;
fadBasketballHoop = fadBasketballHoop;
fadBasketShopping = fadBasketShopping;
fadBasketShoppingSimple = fadBasketShoppingSimple;
fadBat = fadBat;
fadBath = fadBath;
fadBathtub = fadBathtub;
fadBattery = fadBattery;
fadBattery0 = fadBattery0;
fadBattery1 = fadBattery1;
fadBattery2 = fadBattery2;
fadBattery3 = fadBattery3;
fadBattery4 = fadBattery4;
fadBattery5 = fadBattery5;
fadBatteryBolt = fadBatteryBolt;
fadBatteryCar = fadBatteryCar;
fadBatteryEmpty = fadBatteryEmpty;
fadBatteryExclamation = fadBatteryExclamation;
fadBatteryFull = fadBatteryFull;
fadBatteryHalf = fadBatteryHalf;
fadBatteryLow = fadBatteryLow;
fadBatteryQuarter = fadBatteryQuarter;
fadBatterySlash = fadBatterySlash;
fadBatteryThreeQuarters = fadBatteryThreeQuarters;
fadBed = fadBed;
fadBedAlt = fadBedAlt;
fadBedBunk = fadBedBunk;
fadBedEmpty = fadBedEmpty;
fadBedFront = fadBedFront;
fadBedPulse = fadBedPulse;
fadBee = fadBee;
fadBeer = fadBeer;
fadBeerFoam = fadBeerFoam;
fadBeerMug = fadBeerMug;
fadBeerMugEmpty = fadBeerMugEmpty;
fadBell = fadBell;
fadBellConcierge = fadBellConcierge;
fadBellExclamation = fadBellExclamation;
fadBellOn = fadBellOn;
fadBellPlus = fadBellPlus;
fadBells = fadBells;
fadBellSchool = fadBellSchool;
fadBellSchoolSlash = fadBellSchoolSlash;
fadBellSlash = fadBellSlash;
fadBenchTree = fadBenchTree;
fadBetamax = fadBetamax;
fadBezierCurve = fadBezierCurve;
fadBible = fadBible;
fadBicycle = fadBicycle;
fadBiking = fadBiking;
fadBikingMountain = fadBikingMountain;
fadBinary = fadBinary;
fadBinaryCircleCheck = fadBinaryCircleCheck;
fadBinaryLock = fadBinaryLock;
fadBinarySlash = fadBinarySlash;
fadBinoculars = fadBinoculars;
fadBiohazard = fadBiohazard;
fadBird = fadBird;
fadBirthdayCake = fadBirthdayCake;
fadBitcoinSign = fadBitcoinSign;
fadBlackboard = fadBlackboard;
fadBlanket = fadBlanket;
fadBlanketFire = fadBlanketFire;
fadBlender = fadBlender;
fadBlenderPhone = fadBlenderPhone;
fadBlind = fadBlind;
fadBlinds = fadBlinds;
fadBlindsOpen = fadBlindsOpen;
fadBlindsRaised = fadBlindsRaised;
fadBlock = fadBlock;
fadBlockBrick = fadBlockBrick;
fadBlockBrickFire = fadBlockBrickFire;
fadBlockQuestion = fadBlockQuestion;
fadBlockQuote = fadBlockQuote;
fadBlog = fadBlog;
fadBlueberries = fadBlueberries;
fadBluetooth = fadBluetooth;
fadBold = fadBold;
fadBolt = fadBolt;
fadBoltAuto = fadBoltAuto;
fadBoltLightning = fadBoltLightning;
fadBoltSlash = fadBoltSlash;
fadBomb = fadBomb;
fadBone = fadBone;
fadBoneBreak = fadBoneBreak;
fadBong = fadBong;
fadBook = fadBook;
fadBookAlt = fadBookAlt;
fadBookArrowRight = fadBookArrowRight;
fadBookArrowUp = fadBookArrowUp;
fadBookAtlas = fadBookAtlas;
fadBookBible = fadBookBible;
fadBookBlank = fadBookBlank;
fadBookBookmark = fadBookBookmark;
fadBookCircle = fadBookCircle;
fadBookCircleArrowRight = fadBookCircleArrowRight;
fadBookCircleArrowUp = fadBookCircleArrowUp;
fadBookCopy = fadBookCopy;
fadBookDead = fadBookDead;
fadBookFont = fadBookFont;
fadBookHeart = fadBookHeart;
fadBookJournalWhills = fadBookJournalWhills;
fadBookLaw = fadBookLaw;
fadBookmark = fadBookmark;
fadBookmarkCircle = fadBookmarkCircle;
fadBookmarkSlash = fadBookmarkSlash;
fadBookMedical = fadBookMedical;
fadBookOpen = fadBookOpen;
fadBookOpenAlt = fadBookOpenAlt;
fadBookOpenCover = fadBookOpenCover;
fadBookOpenReader = fadBookOpenReader;
fadBookQuran = fadBookQuran;
fadBookReader = fadBookReader;
fadBooks = fadBooks;
fadBookSection = fadBookSection;
fadBookSkull = fadBookSkull;
fadBooksMedical = fadBooksMedical;
fadBookSparkles = fadBookSparkles;
fadBookSpells = fadBookSpells;
fadBookTanakh = fadBookTanakh;
fadBookUser = fadBookUser;
fadBoombox = fadBoombox;
fadBoot = fadBoot;
fadBoothCurtain = fadBoothCurtain;
fadBootHeeled = fadBootHeeled;
fadBorderAll = fadBorderAll;
fadBorderBottom = fadBorderBottom;
fadBorderBottomRight = fadBorderBottomRight;
fadBorderCenterH = fadBorderCenterH;
fadBorderCenterV = fadBorderCenterV;
fadBorderInner = fadBorderInner;
fadBorderLeft = fadBorderLeft;
fadBorderNone = fadBorderNone;
fadBorderOuter = fadBorderOuter;
fadBorderRight = fadBorderRight;
fadBorderStyle = fadBorderStyle;
fadBorderStyleAlt = fadBorderStyleAlt;
fadBorderTop = fadBorderTop;
fadBorderTopLeft = fadBorderTopLeft;
fadBoreHole = fadBoreHole;
fadBottleDroplet = fadBottleDroplet;
fadBottleWater = fadBottleWater;
fadBowArrow = fadBowArrow;
fadBowlChopsticks = fadBowlChopsticks;
fadBowlChopsticksNoodles = fadBowlChopsticksNoodles;
fadBowlFood = fadBowlFood;
fadBowlHot = fadBowlHot;
fadBowlingBall = fadBowlingBall;
fadBowlingBallPin = fadBowlingBallPin;
fadBowlingPins = fadBowlingPins;
fadBowlRice = fadBowlRice;
fadBowlSalad = fadBowlSalad;
fadBowlScoop = fadBowlScoop;
fadBowlScoops = fadBowlScoops;
fadBowlShavedIce = fadBowlShavedIce;
fadBowlSoftServe = fadBowlSoftServe;
fadBowlSpoon = fadBowlSpoon;
fadBox = fadBox;
fadBoxAlt = fadBoxAlt;
fadBoxArchive = fadBoxArchive;
fadBoxBallot = fadBoxBallot;
fadBoxCheck = fadBoxCheck;
fadBoxCircleCheck = fadBoxCircleCheck;
fadBoxDollar = fadBoxDollar;
fadBoxes = fadBoxes;
fadBoxesAlt = fadBoxesAlt;
fadBoxesPacking = fadBoxesPacking;
fadBoxesStacked = fadBoxesStacked;
fadBoxFragile = fadBoxFragile;
fadBoxFull = fadBoxFull;
fadBoxHeart = fadBoxHeart;
fadBoxingGlove = fadBoxingGlove;
fadBoxOpen = fadBoxOpen;
fadBoxOpenFull = fadBoxOpenFull;
fadBoxTaped = fadBoxTaped;
fadBoxTissue = fadBoxTissue;
fadBoxUp = fadBoxUp;
fadBoxUsd = fadBoxUsd;
fadBracket = fadBracket;
fadBracketCurly = fadBracketCurly;
fadBracketCurlyLeft = fadBracketCurlyLeft;
fadBracketCurlyRight = fadBracketCurlyRight;
fadBracketLeft = fadBracketLeft;
fadBracketRound = fadBracketRound;
fadBracketRoundRight = fadBracketRoundRight;
fadBrackets = fadBrackets;
fadBracketsCurly = fadBracketsCurly;
fadBracketSquare = fadBracketSquare;
fadBracketSquareRight = fadBracketSquareRight;
fadBracketsRound = fadBracketsRound;
fadBracketsSquare = fadBracketsSquare;
fadBraille = fadBraille;
fadBrain = fadBrain;
fadBrainArrowCurvedRight = fadBrainArrowCurvedRight;
fadBrainCircuit = fadBrainCircuit;
fadBrakeWarning = fadBrakeWarning;
fadBrazilianRealSign = fadBrazilianRealSign;
fadBreadLoaf = fadBreadLoaf;
fadBreadSlice = fadBreadSlice;
fadBreadSliceButter = fadBreadSliceButter;
fadBridge = fadBridge;
fadBridgeCircleCheck = fadBridgeCircleCheck;
fadBridgeCircleExclamation = fadBridgeCircleExclamation;
fadBridgeCircleXmark = fadBridgeCircleXmark;
fadBridgeLock = fadBridgeLock;
fadBridgeSuspension = fadBridgeSuspension;
fadBridgeWater = fadBridgeWater;
fadBriefcase = fadBriefcase;
fadBriefcaseArrowRight = fadBriefcaseArrowRight;
fadBriefcaseBlank = fadBriefcaseBlank;
fadBriefcaseClock = fadBriefcaseClock;
fadBriefcaseMedical = fadBriefcaseMedical;
fadBrightness = fadBrightness;
fadBrightnessLow = fadBrightnessLow;
fadBringForward = fadBringForward;
fadBringFront = fadBringFront;
fadBroadcastTower = fadBroadcastTower;
fadBroccoli = fadBroccoli;
fadBroom = fadBroom;
fadBroomBall = fadBroomBall;
fadBrowser = fadBrowser;
fadBrowsers = fadBrowsers;
fadBrush = fadBrush;
fadBucket = fadBucket;
fadBug = fadBug;
fadBugs = fadBugs;
fadBugSlash = fadBugSlash;
fadBuilding = fadBuilding;
fadBuildingCircleArrowRight = fadBuildingCircleArrowRight;
fadBuildingCircleCheck = fadBuildingCircleCheck;
fadBuildingCircleExclamation = fadBuildingCircleExclamation;
fadBuildingCircleXmark = fadBuildingCircleXmark;
fadBuildingColumns = fadBuildingColumns;
fadBuildingFlag = fadBuildingFlag;
fadBuildingLock = fadBuildingLock;
fadBuildingNgo = fadBuildingNgo;
fadBuildings = fadBuildings;
fadBuildingShield = fadBuildingShield;
fadBuildingUn = fadBuildingUn;
fadBuildingUser = fadBuildingUser;
fadBuildingWheat = fadBuildingWheat;
fadBullhorn = fadBullhorn;
fadBullseye = fadBullseye;
fadBullseyeArrow = fadBullseyeArrow;
fadBullseyePointer = fadBullseyePointer;
fadBurger = fadBurger;
fadBurgerCheese = fadBurgerCheese;
fadBurgerFries = fadBurgerFries;
fadBurgerGlass = fadBurgerGlass;
fadBurgerLettuce = fadBurgerLettuce;
fadBurgerSoda = fadBurgerSoda;
fadBurn = fadBurn;
fadBurrito = fadBurrito;
fadBurst = fadBurst;
fadBus = fadBus;
fadBusAlt = fadBusAlt;
fadBusinessFront = fadBusinessFront;
fadBusinessTime = fadBusinessTime;
fadBusSchool = fadBusSchool;
fadBusSimple = fadBusSimple;
fadButter = fadButter;
fadC = fadC;
fadCab = fadCab;
fadCabin = fadCabin;
fadCabinetFiling = fadCabinetFiling;
fadCableCar = fadCableCar;
fadCactus = fadCactus;
fadCake = fadCake;
fadCakeCandles = fadCakeCandles;
fadCakeSlice = fadCakeSlice;
fadCalculator = fadCalculator;
fadCalculatorAlt = fadCalculatorAlt;
fadCalculatorSimple = fadCalculatorSimple;
fadCalendar = fadCalendar;
fadCalendarAlt = fadCalendarAlt;
fadCalendarArrowDown = fadCalendarArrowDown;
fadCalendarArrowUp = fadCalendarArrowUp;
fadCalendarCheck = fadCalendarCheck;
fadCalendarCircle = fadCalendarCircle;
fadCalendarCircleExclamation = fadCalendarCircleExclamation;
fadCalendarCircleMinus = fadCalendarCircleMinus;
fadCalendarCirclePlus = fadCalendarCirclePlus;
fadCalendarCircleUser = fadCalendarCircleUser;
fadCalendarClock = fadCalendarClock;
fadCalendarDay = fadCalendarDay;
fadCalendarDays = fadCalendarDays;
fadCalendarDownload = fadCalendarDownload;
fadCalendarEdit = fadCalendarEdit;
fadCalendarExclamation = fadCalendarExclamation;
fadCalendarHeart = fadCalendarHeart;
fadCalendarImage = fadCalendarImage;
fadCalendarLines = fadCalendarLines;
fadCalendarLinesPen = fadCalendarLinesPen;
fadCalendarMinus = fadCalendarMinus;
fadCalendarNote = fadCalendarNote;
fadCalendarPen = fadCalendarPen;
fadCalendarPlus = fadCalendarPlus;
fadCalendarRange = fadCalendarRange;
fadCalendars = fadCalendars;
fadCalendarStar = fadCalendarStar;
fadCalendarTime = fadCalendarTime;
fadCalendarTimes = fadCalendarTimes;
fadCalendarUpload = fadCalendarUpload;
fadCalendarWeek = fadCalendarWeek;
fadCalendarXmark = fadCalendarXmark;
fadCamcorder = fadCamcorder;
fadCamera = fadCamera;
fadCameraAlt = fadCameraAlt;
fadCameraCctv = fadCameraCctv;
fadCameraCircle = fadCameraCircle;
fadCameraHome = fadCameraHome;
fadCameraMovie = fadCameraMovie;
fadCameraPolaroid = fadCameraPolaroid;
fadCameraRetro = fadCameraRetro;
fadCameraRotate = fadCameraRotate;
fadCameraSecurity = fadCameraSecurity;
fadCameraSlash = fadCameraSlash;
fadCameraViewfinder = fadCameraViewfinder;
fadCameraWeb = fadCameraWeb;
fadCameraWebSlash = fadCameraWebSlash;
fadCampfire = fadCampfire;
fadCampground = fadCampground;
fadCancel = fadCancel;
fadCandleHolder = fadCandleHolder;
fadCandy = fadCandy;
fadCandyBar = fadCandyBar;
fadCandyCane = fadCandyCane;
fadCandyCorn = fadCandyCorn;
fadCanFood = fadCanFood;
fadCannabis = fadCannabis;
fadCapsules = fadCapsules;
fadCar = fadCar;
fadCarAlt = fadCarAlt;
fadCaravan = fadCaravan;
fadCaravanAlt = fadCaravanAlt;
fadCaravanSimple = fadCaravanSimple;
fadCarBattery = fadCarBattery;
fadCarBolt = fadCarBolt;
fadCarBuilding = fadCarBuilding;
fadCarBump = fadCarBump;
fadCarBurst = fadCarBurst;
fadCarBus = fadCarBus;
fadCarCircleBolt = fadCarCircleBolt;
fadCarCrash = fadCarCrash;
fadCardClub = fadCardClub;
fadCardDiamond = fadCardDiamond;
fadCardHeart = fadCardHeart;
fadCards = fadCards;
fadCardsBlank = fadCardsBlank;
fadCardSpade = fadCardSpade;
fadCaretCircleDown = fadCaretCircleDown;
fadCaretCircleLeft = fadCaretCircleLeft;
fadCaretCircleRight = fadCaretCircleRight;
fadCaretCircleUp = fadCaretCircleUp;
fadCaretDown = fadCaretDown;
fadCaretLeft = fadCaretLeft;
fadCaretRight = fadCaretRight;
fadCaretSquareDown = fadCaretSquareDown;
fadCaretSquareLeft = fadCaretSquareLeft;
fadCaretSquareRight = fadCaretSquareRight;
fadCaretSquareUp = fadCaretSquareUp;
fadCaretUp = fadCaretUp;
fadCarGarage = fadCarGarage;
fadCarMechanic = fadCarMechanic;
fadCarMirrors = fadCarMirrors;
fadCarOn = fadCarOn;
fadCarRear = fadCarRear;
fadCarriageBaby = fadCarriageBaby;
fadCarrot = fadCarrot;
fadCars = fadCars;
fadCarSide = fadCarSide;
fadCarSideBolt = fadCarSideBolt;
fadCartArrowDown = fadCartArrowDown;
fadCartArrowUp = fadCartArrowUp;
fadCartCircleArrowDown = fadCartCircleArrowDown;
fadCartCircleArrowUp = fadCartCircleArrowUp;
fadCartCircleCheck = fadCartCircleCheck;
fadCartCircleExclamation = fadCartCircleExclamation;
fadCartCirclePlus = fadCartCirclePlus;
fadCartCircleXmark = fadCartCircleXmark;
fadCartFlatbed = fadCartFlatbed;
fadCartFlatbedBoxes = fadCartFlatbedBoxes;
fadCartFlatbedEmpty = fadCartFlatbedEmpty;
fadCartFlatbedSuitcase = fadCartFlatbedSuitcase;
fadCarTilt = fadCarTilt;
fadCartMinus = fadCartMinus;
fadCartPlus = fadCartPlus;
fadCartShopping = fadCartShopping;
fadCartShoppingFast = fadCartShoppingFast;
fadCarTunnel = fadCarTunnel;
fadCartXmark = fadCartXmark;
fadCarWash = fadCarWash;
fadCarWrench = fadCarWrench;
fadCashRegister = fadCashRegister;
fadCassetteBetamax = fadCassetteBetamax;
fadCassetteTape = fadCassetteTape;
fadCassetteVhs = fadCassetteVhs;
fadCastle = fadCastle;
fadCat = fadCat;
fadCatSpace = fadCatSpace;
fadCauldron = fadCauldron;
fadCctv = fadCctv;
fadCediSign = fadCediSign;
fadCentSign = fadCentSign;
fadCertificate = fadCertificate;
fadChain = fadChain;
fadChainBroken = fadChainBroken;
fadChainHorizontal = fadChainHorizontal;
fadChainHorizontalSlash = fadChainHorizontalSlash;
fadChainSlash = fadChainSlash;
fadChair = fadChair;
fadChairOffice = fadChairOffice;
fadChalkboard = fadChalkboard;
fadChalkboardTeacher = fadChalkboardTeacher;
fadChalkboardUser = fadChalkboardUser;
fadChampagneGlass = fadChampagneGlass;
fadChampagneGlasses = fadChampagneGlasses;
fadChargingStation = fadChargingStation;
fadChartArea = fadChartArea;
fadChartBar = fadChartBar;
fadChartBullet = fadChartBullet;
fadChartCandlestick = fadChartCandlestick;
fadChartColumn = fadChartColumn;
fadChartGantt = fadChartGantt;
fadChartLine = fadChartLine;
fadChartLineDown = fadChartLineDown;
fadChartLineUp = fadChartLineUp;
fadChartMixed = fadChartMixed;
fadChartNetwork = fadChartNetwork;
fadChartPie = fadChartPie;
fadChartPieAlt = fadChartPieAlt;
fadChartPieSimple = fadChartPieSimple;
fadChartPyramid = fadChartPyramid;
fadChartRadar = fadChartRadar;
fadChartScatter = fadChartScatter;
fadChartScatter3d = fadChartScatter3d;
fadChartScatterBubble = fadChartScatterBubble;
fadChartSimple = fadChartSimple;
fadChartSimpleHorizontal = fadChartSimpleHorizontal;
fadChartTreeMap = fadChartTreeMap;
fadChartUser = fadChartUser;
fadChartWaterfadll = fadChartWaterfadll;
fadCheck = fadCheck;
fadCheckCircle = fadCheckCircle;
fadCheckDouble = fadCheckDouble;
fadCheckSquare = fadCheckSquare;
fadCheckToSlot = fadCheckToSlot;
fadCheese = fadCheese;
fadCheeseburger = fadCheeseburger;
fadCheeseSwiss = fadCheeseSwiss;
fadCherries = fadCherries;
fadChess = fadChess;
fadChessBishop = fadChessBishop;
fadChessBishopAlt = fadChessBishopAlt;
fadChessBishopPiece = fadChessBishopPiece;
fadChessBoard = fadChessBoard;
fadChessClock = fadChessClock;
fadChessClockAlt = fadChessClockAlt;
fadChessClockFlip = fadChessClockFlip;
fadChessKing = fadChessKing;
fadChessKingAlt = fadChessKingAlt;
fadChessKingPiece = fadChessKingPiece;
fadChessKnight = fadChessKnight;
fadChessKnightAlt = fadChessKnightAlt;
fadChessKnightPiece = fadChessKnightPiece;
fadChessPawn = fadChessPawn;
fadChessPawnAlt = fadChessPawnAlt;
fadChessPawnPiece = fadChessPawnPiece;
fadChessQueen = fadChessQueen;
fadChessQueenAlt = fadChessQueenAlt;
fadChessQueenPiece = fadChessQueenPiece;
fadChessRook = fadChessRook;
fadChessRookAlt = fadChessRookAlt;
fadChessRookPiece = fadChessRookPiece;
fadChestnut = fadChestnut;
fadChevronCircleDown = fadChevronCircleDown;
fadChevronCircleLeft = fadChevronCircleLeft;
fadChevronCircleRight = fadChevronCircleRight;
fadChevronCircleUp = fadChevronCircleUp;
fadChevronDoubleDown = fadChevronDoubleDown;
fadChevronDoubleLeft = fadChevronDoubleLeft;
fadChevronDoubleRight = fadChevronDoubleRight;
fadChevronDoubleUp = fadChevronDoubleUp;
fadChevronDown = fadChevronDown;
fadChevronLeft = fadChevronLeft;
fadChevronRight = fadChevronRight;
fadChevronsDown = fadChevronsDown;
fadChevronsLeft = fadChevronsLeft;
fadChevronSquareDown = fadChevronSquareDown;
fadChevronSquareLeft = fadChevronSquareLeft;
fadChevronSquareRight = fadChevronSquareRight;
fadChevronSquareUp = fadChevronSquareUp;
fadChevronsRight = fadChevronsRight;
fadChevronsUp = fadChevronsUp;
fadChevronUp = fadChevronUp;
fadChild = fadChild;
fadChildren = fadChildren;
fadChildRifle = fadChildRifle;
fadChimney = fadChimney;
fadChocolateBar = fadChocolateBar;
fadChopsticks = fadChopsticks;
fadChurch = fadChurch;
fadCircle = fadCircle;
fadCircle0 = fadCircle0;
fadCircle1 = fadCircle1;
fadCircle2 = fadCircle2;
fadCircle3 = fadCircle3;
fadCircle4 = fadCircle4;
fadCircle5 = fadCircle5;
fadCircle6 = fadCircle6;
fadCircle7 = fadCircle7;
fadCircle8 = fadCircle8;
fadCircle9 = fadCircle9;
fadCircleA = fadCircleA;
fadCircleAmpersand = fadCircleAmpersand;
fadCircleArrowDown = fadCircleArrowDown;
fadCircleArrowDownLeft = fadCircleArrowDownLeft;
fadCircleArrowDownRight = fadCircleArrowDownRight;
fadCircleArrowLeft = fadCircleArrowLeft;
fadCircleArrowRight = fadCircleArrowRight;
fadCircleArrowUp = fadCircleArrowUp;
fadCircleArrowUpLeft = fadCircleArrowUpLeft;
fadCircleArrowUpRight = fadCircleArrowUpRight;
fadCircleB = fadCircleB;
fadCircleBolt = fadCircleBolt;
fadCircleBookmark = fadCircleBookmark;
fadCircleBookOpen = fadCircleBookOpen;
fadCircleC = fadCircleC;
fadCircleCalendar = fadCircleCalendar;
fadCircleCamera = fadCircleCamera;
fadCircleCaretDown = fadCircleCaretDown;
fadCircleCaretLeft = fadCircleCaretLeft;
fadCircleCaretRight = fadCircleCaretRight;
fadCircleCaretUp = fadCircleCaretUp;
fadCircleCheck = fadCircleCheck;
fadCircleChevronDown = fadCircleChevronDown;
fadCircleChevronLeft = fadCircleChevronLeft;
fadCircleChevronRight = fadCircleChevronRight;
fadCircleChevronUp = fadCircleChevronUp;
fadCircleD = fadCircleD;
fadCircleDashed = fadCircleDashed;
fadCircleDivide = fadCircleDivide;
fadCircleDollar = fadCircleDollar;
fadCircleDollarToSlot = fadCircleDollarToSlot;
fadCircleDot = fadCircleDot;
fadCircleDown = fadCircleDown;
fadCircleDownLeft = fadCircleDownLeft;
fadCircleDownRight = fadCircleDownRight;
fadCircleE = fadCircleE;
fadCircleEllipsis = fadCircleEllipsis;
fadCircleEllipsisVertical = fadCircleEllipsisVertical;
fadCircleEnvelope = fadCircleEnvelope;
fadCircleExclamation = fadCircleExclamation;
fadCircleExclamationCheck = fadCircleExclamationCheck;
fadCircleF = fadCircleF;
fadCircleG = fadCircleG;
fadCircleH = fadCircleH;
fadCircleHalf = fadCircleHalf;
fadCircleHalfStroke = fadCircleHalfStroke;
fadCircleHeart = fadCircleHeart;
fadCircleI = fadCircleI;
fadCircleInfo = fadCircleInfo;
fadCircleJ = fadCircleJ;
fadCircleK = fadCircleK;
fadCircleL = fadCircleL;
fadCircleLeft = fadCircleLeft;
fadCircleLocationArrow = fadCircleLocationArrow;
fadCircleM = fadCircleM;
fadCircleMicrophone = fadCircleMicrophone;
fadCircleMicrophoneLines = fadCircleMicrophoneLines;
fadCircleMinus = fadCircleMinus;
fadCircleN = fadCircleN;
fadCircleNodes = fadCircleNodes;
fadCircleNotch = fadCircleNotch;
fadCircleO = fadCircleO;
fadCircleP = fadCircleP;
fadCircleParking = fadCircleParking;
fadCirclePause = fadCirclePause;
fadCirclePhone = fadCirclePhone;
fadCirclePhoneFlip = fadCirclePhoneFlip;
fadCirclePhoneHangup = fadCirclePhoneHangup;
fadCirclePlay = fadCirclePlay;
fadCirclePlus = fadCirclePlus;
fadCircleQ = fadCircleQ;
fadCircleQuarter = fadCircleQuarter;
fadCircleQuarters = fadCircleQuarters;
fadCircleQuestion = fadCircleQuestion;
fadCircleR = fadCircleR;
fadCircleRadiation = fadCircleRadiation;
fadCircleRight = fadCircleRight;
fadCircleS = fadCircleS;
fadCircleSmall = fadCircleSmall;
fadCircleSort = fadCircleSort;
fadCircleSortDown = fadCircleSortDown;
fadCircleSortUp = fadCircleSortUp;
fadCircleStar = fadCircleStar;
fadCircleStop = fadCircleStop;
fadCircleT = fadCircleT;
fadCircleThreeQuarters = fadCircleThreeQuarters;
fadCircleTrash = fadCircleTrash;
fadCircleU = fadCircleU;
fadCircleUp = fadCircleUp;
fadCircleUpLeft = fadCircleUpLeft;
fadCircleUpRight = fadCircleUpRight;
fadCircleUser = fadCircleUser;
fadCircleV = fadCircleV;
fadCircleVideo = fadCircleVideo;
fadCircleW = fadCircleW;
fadCircleWaveformLines = fadCircleWaveformLines;
fadCircleX = fadCircleX;
fadCircleXmark = fadCircleXmark;
fadCircleY = fadCircleY;
fadCircleZ = fadCircleZ;
fadCitrus = fadCitrus;
fadCitrusSlice = fadCitrusSlice;
fadCity = fadCity;
fadClapperboard = fadClapperboard;
fadClapperboardPlay = fadClapperboardPlay;
fadClarinet = fadClarinet;
fadClawMarks = fadClawMarks;
fadClinicMedical = fadClinicMedical;
fadClipboard = fadClipboard;
fadClipboardCheck = fadClipboardCheck;
fadClipboardList = fadClipboardList;
fadClipboardListCheck = fadClipboardListCheck;
fadClipboardMedical = fadClipboardMedical;
fadClipboardPrescription = fadClipboardPrescription;
fadClipboardQuestion = fadClipboardQuestion;
fadClipboardUser = fadClipboardUser;
fadClock = fadClock;
fadClockDesk = fadClockDesk;
fadClockEight = fadClockEight;
fadClockEightThirty = fadClockEightThirty;
fadClockEleven = fadClockEleven;
fadClockElevenThirty = fadClockElevenThirty;
fadClockFive = fadClockFive;
fadClockFiveThirty = fadClockFiveThirty;
fadClockFour = fadClockFour;
fadClockFourThirty = fadClockFourThirty;
fadClockNine = fadClockNine;
fadClockNineThirty = fadClockNineThirty;
fadClockOne = fadClockOne;
fadClockOneThirty = fadClockOneThirty;
fadClockRotateLeft = fadClockRotateLeft;
fadClockSeven = fadClockSeven;
fadClockSevenThirty = fadClockSevenThirty;
fadClockSix = fadClockSix;
fadClockSixThirty = fadClockSixThirty;
fadClockTen = fadClockTen;
fadClockTenThirty = fadClockTenThirty;
fadClockThree = fadClockThree;
fadClockThreeThirty = fadClockThreeThirty;
fadClockTwelve = fadClockTwelve;
fadClockTwelveThirty = fadClockTwelveThirty;
fadClockTwo = fadClockTwo;
fadClockTwoThirty = fadClockTwoThirty;
fadClone = fadClone;
fadClose = fadClose;
fadClosedCaptioning = fadClosedCaptioning;
fadClosedCaptioningSlash = fadClosedCaptioningSlash;
fadClothesHanger = fadClothesHanger;
fadCloud = fadCloud;
fadCloudArrowDown = fadCloudArrowDown;
fadCloudArrowUp = fadCloudArrowUp;
fadCloudBolt = fadCloudBolt;
fadCloudBoltMoon = fadCloudBoltMoon;
fadCloudBoltSun = fadCloudBoltSun;
fadCloudCheck = fadCloudCheck;
fadCloudDownload = fadCloudDownload;
fadCloudDownloadAlt = fadCloudDownloadAlt;
fadCloudDrizzle = fadCloudDrizzle;
fadCloudExclamation = fadCloudExclamation;
fadCloudFog = fadCloudFog;
fadCloudHail = fadCloudHail;
fadCloudHailMixed = fadCloudHailMixed;
fadCloudMeatball = fadCloudMeatball;
fadCloudMinus = fadCloudMinus;
fadCloudMoon = fadCloudMoon;
fadCloudMoonRain = fadCloudMoonRain;
fadCloudMusic = fadCloudMusic;
fadCloudPlus = fadCloudPlus;
fadCloudQuestion = fadCloudQuestion;
fadCloudRain = fadCloudRain;
fadCloudRainbow = fadCloudRainbow;
fadClouds = fadClouds;
fadCloudShowers = fadCloudShowers;
fadCloudShowersHeavy = fadCloudShowersHeavy;
fadCloudShowersWater = fadCloudShowersWater;
fadCloudSlash = fadCloudSlash;
fadCloudSleet = fadCloudSleet;
fadCloudsMoon = fadCloudsMoon;
fadCloudSnow = fadCloudSnow;
fadCloudsSun = fadCloudsSun;
fadCloudSun = fadCloudSun;
fadCloudSunRain = fadCloudSunRain;
fadCloudUpload = fadCloudUpload;
fadCloudUploadAlt = fadCloudUploadAlt;
fadCloudWord = fadCloudWord;
fadCloudXmark = fadCloudXmark;
fadClover = fadClover;
fadClub = fadClub;
fadCny = fadCny;
fadCocktail = fadCocktail;
fadCoconut = fadCoconut;
fadCode = fadCode;
fadCodeBranch = fadCodeBranch;
fadCodeCommit = fadCodeCommit;
fadCodeCompare = fadCodeCompare;
fadCodeFork = fadCodeFork;
fadCodeMerge = fadCodeMerge;
fadCodePullRequest = fadCodePullRequest;
fadCodePullRequestClosed = fadCodePullRequestClosed;
fadCodePullRequestDraft = fadCodePullRequestDraft;
fadCodeSimple = fadCodeSimple;
fadCoffee = fadCoffee;
fadCoffeeBean = fadCoffeeBean;
fadCoffeeBeans = fadCoffeeBeans;
fadCoffeePot = fadCoffeePot;
fadCoffeeTogo = fadCoffeeTogo;
fadCoffin = fadCoffin;
fadCoffinCross = fadCoffinCross;
fadCog = fadCog;
fadCogs = fadCogs;
fadCoin = fadCoin;
fadCoinBlank = fadCoinBlank;
fadCoinFront = fadCoinFront;
fadCoins = fadCoins;
fadCoinVertical = fadCoinVertical;
fadColon = fadColon;
fadColonSign = fadColonSign;
fadColumns = fadColumns;
fadColumns3 = fadColumns3;
fadComet = fadComet;
fadComma = fadComma;
fadCommand = fadCommand;
fadComment = fadComment;
fadCommentAlt = fadCommentAlt;
fadCommentAltArrowDown = fadCommentAltArrowDown;
fadCommentAltArrowUp = fadCommentAltArrowUp;
fadCommentAltCaptions = fadCommentAltCaptions;
fadCommentAltCheck = fadCommentAltCheck;
fadCommentAltDollar = fadCommentAltDollar;
fadCommentAltDots = fadCommentAltDots;
fadCommentAltEdit = fadCommentAltEdit;
fadCommentAltExclamation = fadCommentAltExclamation;
fadCommentAltImage = fadCommentAltImage;
fadCommentAltLines = fadCommentAltLines;
fadCommentAltMedical = fadCommentAltMedical;
fadCommentAltMinus = fadCommentAltMinus;
fadCommentAltMusic = fadCommentAltMusic;
fadCommentAltPlus = fadCommentAltPlus;
fadCommentAltQuote = fadCommentAltQuote;
fadCommentAltSlash = fadCommentAltSlash;
fadCommentAltSmile = fadCommentAltSmile;
fadCommentAltText = fadCommentAltText;
fadCommentAltTimes = fadCommentAltTimes;
fadCommentArrowDown = fadCommentArrowDown;
fadCommentArrowUp = fadCommentArrowUp;
fadCommentArrowUpRight = fadCommentArrowUpRight;
fadCommentCaptions = fadCommentCaptions;
fadCommentCheck = fadCommentCheck;
fadCommentCode = fadCommentCode;
fadCommentDollar = fadCommentDollar;
fadCommentDots = fadCommentDots;
fadCommentEdit = fadCommentEdit;
fadCommentExclamation = fadCommentExclamation;
fadCommentImage = fadCommentImage;
fadCommenting = fadCommenting;
fadCommentLines = fadCommentLines;
fadCommentMedical = fadCommentMedical;
fadCommentMiddle = fadCommentMiddle;
fadCommentMiddleAlt = fadCommentMiddleAlt;
fadCommentMiddleTop = fadCommentMiddleTop;
fadCommentMiddleTopAlt = fadCommentMiddleTopAlt;
fadCommentMinus = fadCommentMinus;
fadCommentMusic = fadCommentMusic;
fadCommentPen = fadCommentPen;
fadCommentPlus = fadCommentPlus;
fadCommentQuestion = fadCommentQuestion;
fadCommentQuote = fadCommentQuote;
fadComments = fadComments;
fadCommentsAlt = fadCommentsAlt;
fadCommentsAltDollar = fadCommentsAltDollar;
fadCommentsDollar = fadCommentsDollar;
fadCommentSlash = fadCommentSlash;
fadCommentSmile = fadCommentSmile;
fadCommentSms = fadCommentSms;
fadCommentsQuestion = fadCommentsQuestion;
fadCommentsQuestionCheck = fadCommentsQuestionCheck;
fadCommentText = fadCommentText;
fadCommentTimes = fadCommentTimes;
fadCommentXmark = fadCommentXmark;
fadCompactDisc = fadCompactDisc;
fadCompass = fadCompass;
fadCompassDrafting = fadCompassDrafting;
fadCompassSlash = fadCompassSlash;
fadCompress = fadCompress;
fadCompressAlt = fadCompressAlt;
fadCompressArrows = fadCompressArrows;
fadCompressArrowsAlt = fadCompressArrowsAlt;
fadCompressWide = fadCompressWide;
fadComputer = fadComputer;
fadComputerClassic = fadComputerClassic;
fadComputerMouse = fadComputerMouse;
fadComputerMouseScrollwheel = fadComputerMouseScrollwheel;
fadComputerSpeaker = fadComputerSpeaker;
fadConciergeBell = fadConciergeBell;
fadConstruction = fadConstruction;
fadContactBook = fadContactBook;
fadContactCard = fadContactCard;
fadContainerStorage = fadContainerStorage;
fadConveyorBelt = fadConveyorBelt;
fadConveyorBeltAlt = fadConveyorBeltAlt;
fadConveyorBeltBoxes = fadConveyorBeltBoxes;
fadConveyorBeltEmpty = fadConveyorBeltEmpty;
fadCookie = fadCookie;
fadCookieBite = fadCookieBite;
fadCopy = fadCopy;
fadCopyright = fadCopyright;
fadCorn = fadCorn;
fadCorner = fadCorner;
fadCouch = fadCouch;
fadCouchSmall = fadCouchSmall;
fadCow = fadCow;
fadCowbell = fadCowbell;
fadCowbellCirclePlus = fadCowbellCirclePlus;
fadCowbellMore = fadCowbellMore;
fadCrab = fadCrab;
fadCrateApple = fadCrateApple;
fadCrateEmpty = fadCrateEmpty;
fadCreditCard = fadCreditCard;
fadCreditCardAlt = fadCreditCardAlt;
fadCreditCardBlank = fadCreditCardBlank;
fadCreditCardFront = fadCreditCardFront;
fadCreemee = fadCreemee;
fadCricket = fadCricket;
fadCricketBatBall = fadCricketBatBall;
fadCroissant = fadCroissant;
fadCrop = fadCrop;
fadCropAlt = fadCropAlt;
fadCropSimple = fadCropSimple;
fadCross = fadCross;
fadCrosshairs = fadCrosshairs;
fadCrow = fadCrow;
fadCrown = fadCrown;
fadCrutch = fadCrutch;
fadCrutches = fadCrutches;
fadCruzeiroSign = fadCruzeiroSign;
fadCrystalBall = fadCrystalBall;
fadCube = fadCube;
fadCubes = fadCubes;
fadCubesStacked = fadCubesStacked;
fadCucumber = fadCucumber;
fadCupcake = fadCupcake;
fadCupStraw = fadCupStraw;
fadCupStrawSwoosh = fadCupStrawSwoosh;
fadCupTogo = fadCupTogo;
fadCurling = fadCurling;
fadCurlingStone = fadCurlingStone;
fadCustard = fadCustard;
fadCut = fadCut;
fadCutlery = fadCutlery;
fadD = fadD;
fadDagger = fadDagger;
fadDash = fadDash;
fadDashboard = fadDashboard;
fadDatabase = fadDatabase;
fadDeaf = fadDeaf;
fadDeafness = fadDeafness;
fadDebug = fadDebug;
fadDedent = fadDedent;
fadDeer = fadDeer;
fadDeerRudolph = fadDeerRudolph;
fadDeleteLeft = fadDeleteLeft;
fadDeleteRight = fadDeleteRight;
fadDemocrat = fadDemocrat;
fadDesktop = fadDesktop;
fadDesktopAlt = fadDesktopAlt;
fadDesktopArrowDown = fadDesktopArrowDown;
fadDesktopCode = fadDesktopCode;
fadDesktopMedical = fadDesktopMedical;
fadDesktopSlash = fadDesktopSlash;
fadDewpoint = fadDewpoint;
fadDharmachakra = fadDharmachakra;
fadDiagnoses = fadDiagnoses;
fadDiagramCells = fadDiagramCells;
fadDiagramLeanCanvas = fadDiagramLeanCanvas;
fadDiagramNested = fadDiagramNested;
fadDiagramNext = fadDiagramNext;
fadDiagramPredecessor = fadDiagramPredecessor;
fadDiagramPrevious = fadDiagramPrevious;
fadDiagramProject = fadDiagramProject;
fadDiagramSankey = fadDiagramSankey;
fadDiagramSubtask = fadDiagramSubtask;
fadDiagramSuccessor = fadDiagramSuccessor;
fadDiagramVenn = fadDiagramVenn;
fadDial = fadDial;
fadDialHigh = fadDialHigh;
fadDialLow = fadDialLow;
fadDialMax = fadDialMax;
fadDialMed = fadDialMed;
fadDialMedHigh = fadDialMedHigh;
fadDialMedLow = fadDialMedLow;
fadDialMin = fadDialMin;
fadDialOff = fadDialOff;
fadDiamond = fadDiamond;
fadDiamondExclamation = fadDiamondExclamation;
fadDiamondTurnRight = fadDiamondTurnRight;
fadDice = fadDice;
fadDiceD10 = fadDiceD10;
fadDiceD12 = fadDiceD12;
fadDiceD20 = fadDiceD20;
fadDiceD4 = fadDiceD4;
fadDiceD6 = fadDiceD6;
fadDiceD8 = fadDiceD8;
fadDiceFive = fadDiceFive;
fadDiceFour = fadDiceFour;
fadDiceOne = fadDiceOne;
fadDiceSix = fadDiceSix;
fadDiceThree = fadDiceThree;
fadDiceTwo = fadDiceTwo;
fadDigging = fadDigging;
fadDigitalTachograph = fadDigitalTachograph;
fadDiploma = fadDiploma;
fadDirections = fadDirections;
fadDiscDrive = fadDiscDrive;
fadDisease = fadDisease;
fadDisplay = fadDisplay;
fadDisplayArrowDown = fadDisplayArrowDown;
fadDisplayCode = fadDisplayCode;
fadDisplayMedical = fadDisplayMedical;
fadDisplaySlash = fadDisplaySlash;
fadDistributeSpacingHorizontal = fadDistributeSpacingHorizontal;
fadDistributeSpacingVertical = fadDistributeSpacingVertical;
fadDitto = fadDitto;
fadDivide = fadDivide;
fadDizzy = fadDizzy;
fadDna = fadDna;
fadDog = fadDog;
fadDogLeashed = fadDogLeashed;
fadDollar = fadDollar;
fadDollarCircle = fadDollarCircle;
fadDollarSign = fadDollarSign;
fadDollarSquare = fadDollarSquare;
fadDolly = fadDolly;
fadDollyBox = fadDollyBox;
fadDollyEmpty = fadDollyEmpty;
fadDollyFlatbed = fadDollyFlatbed;
fadDollyFlatbedAlt = fadDollyFlatbedAlt;
fadDollyFlatbedEmpty = fadDollyFlatbedEmpty;
fadDolphin = fadDolphin;
fadDonate = fadDonate;
fadDongSign = fadDongSign;
fadDoNotEnter = fadDoNotEnter;
fadDonut = fadDonut;
fadDoorClosed = fadDoorClosed;
fadDoorOpen = fadDoorOpen;
fadDotCircle = fadDotCircle;
fadDoughnut = fadDoughnut;
fadDove = fadDove;
fadDown = fadDown;
fadDownFromDottedLine = fadDownFromDottedLine;
fadDownFromLine = fadDownFromLine;
fadDownLeft = fadDownLeft;
fadDownLeftAndUpRightToCenter = fadDownLeftAndUpRightToCenter;
fadDownload = fadDownload;
fadDownLong = fadDownLong;
fadDownRight = fadDownRight;
fadDownToBracket = fadDownToBracket;
fadDownToDottedLine = fadDownToDottedLine;
fadDownToLine = fadDownToLine;
fadDraftingCompass = fadDraftingCompass;
fadDragon = fadDragon;
fadDrawCircle = fadDrawCircle;
fadDrawPolygon = fadDrawPolygon;
fadDrawSquare = fadDrawSquare;
fadDreidel = fadDreidel;
fadDriversLicense = fadDriversLicense;
fadDrone = fadDrone;
fadDroneAlt = fadDroneAlt;
fadDroneFront = fadDroneFront;
fadDroplet = fadDroplet;
fadDropletDegree = fadDropletDegree;
fadDropletPercent = fadDropletPercent;
fadDropletSlash = fadDropletSlash;
fadDrum = fadDrum;
fadDrumSteelpan = fadDrumSteelpan;
fadDrumstick = fadDrumstick;
fadDrumstickBite = fadDrumstickBite;
fadDryer = fadDryer;
fadDryerAlt = fadDryerAlt;
fadDryerHeat = fadDryerHeat;
fadDuck = fadDuck;
fadDumbbell = fadDumbbell;
fadDumpster = fadDumpster;
fadDumpsterFire = fadDumpsterFire;
fadDungeon = fadDungeon;
fadE = fadE;
fadEar = fadEar;
fadEarDeaf = fadEarDeaf;
fadEarListen = fadEarListen;
fadEarMuffs = fadEarMuffs;
fadEarth = fadEarth;
fadEarthAfrica = fadEarthAfrica;
fadEarthAmerica = fadEarthAmerica;
fadEarthAmericas = fadEarthAmericas;
fadEarthAsia = fadEarthAsia;
fadEarthEurope = fadEarthEurope;
fadEarthOceania = fadEarthOceania;
fadEclipse = fadEclipse;
fadEclipseAlt = fadEclipseAlt;
fadEdit = fadEdit;
fadEgg = fadEgg;
fadEggFried = fadEggFried;
fadEggplant = fadEggplant;
fadEject = fadEject;
fadElephant = fadElephant;
fadElevator = fadElevator;
fadEllipsis = fadEllipsis;
fadEllipsisH = fadEllipsisH;
fadEllipsisHAlt = fadEllipsisHAlt;
fadEllipsisStroke = fadEllipsisStroke;
fadEllipsisStrokeVertical = fadEllipsisStrokeVertical;
fadEllipsisV = fadEllipsisV;
fadEllipsisVAlt = fadEllipsisVAlt;
fadEllipsisVertical = fadEllipsisVertical;
fadEmptySet = fadEmptySet;
fadEngine = fadEngine;
fadEngineExclamation = fadEngineExclamation;
fadEngineWarning = fadEngineWarning;
fadEnvelope = fadEnvelope;
fadEnvelopeBadge = fadEnvelopeBadge;
fadEnvelopeCircle = fadEnvelopeCircle;
fadEnvelopeCircleCheck = fadEnvelopeCircleCheck;
fadEnvelopeDot = fadEnvelopeDot;
fadEnvelopeOpen = fadEnvelopeOpen;
fadEnvelopeOpenDollar = fadEnvelopeOpenDollar;
fadEnvelopeOpenText = fadEnvelopeOpenText;
fadEnvelopes = fadEnvelopes;
fadEnvelopesBulk = fadEnvelopesBulk;
fadEnvelopeSquare = fadEnvelopeSquare;
fadEquals = fadEquals;
fadEraser = fadEraser;
fadEscalator = fadEscalator;
fadEthernet = fadEthernet;
fadEur = fadEur;
fadEuro = fadEuro;
fadEuroSign = fadEuroSign;
fadExchange = fadExchange;
fadExchangeAlt = fadExchangeAlt;
fadExclamation = fadExclamation;
fadExclamationCircle = fadExclamationCircle;
fadExclamationSquare = fadExclamationSquare;
fadExclamationTriangle = fadExclamationTriangle;
fadExpand = fadExpand;
fadExpandAlt = fadExpandAlt;
fadExpandArrows = fadExpandArrows;
fadExpandArrowsAlt = fadExpandArrowsAlt;
fadExpandWide = fadExpandWide;
fadExplodingHead = fadExplodingHead;
fadExplosion = fadExplosion;
fadExternalLink = fadExternalLink;
fadExternalLinkAlt = fadExternalLinkAlt;
fadExternalLinkSquare = fadExternalLinkSquare;
fadExternalLinkSquareAlt = fadExternalLinkSquareAlt;
fadEye = fadEye;
fadEyedropper = fadEyedropper;
fadEyeDropper = fadEyeDropper;
fadEyeDropperEmpty = fadEyeDropperEmpty;
fadEyeDropperFull = fadEyeDropperFull;
fadEyeDropperHalf = fadEyeDropperHalf;
fadEyeEvil = fadEyeEvil;
fadEyeLowVision = fadEyeLowVision;
fadEyes = fadEyes;
fadEyeSlash = fadEyeSlash;
fadF = fadF;
fadFaceAngry = fadFaceAngry;
fadFaceAngryHorns = fadFaceAngryHorns;
fadFaceAnguished = fadFaceAnguished;
fadFaceAnxiousSweat = fadFaceAnxiousSweat;
fadFaceAstonished = fadFaceAstonished;
fadFaceAwesome = fadFaceAwesome;
fadFaceBeamHandOverMouth = fadFaceBeamHandOverMouth;
fadFaceClouds = fadFaceClouds;
fadFaceConfounded = fadFaceConfounded;
fadFaceConfused = fadFaceConfused;
fadFaceCowboyHat = fadFaceCowboyHat;
fadFaceDiagonalMouth = fadFaceDiagonalMouth;
fadFaceDisappointed = fadFaceDisappointed;
fadFaceDisguise = fadFaceDisguise;
fadFaceDizzy = fadFaceDizzy;
fadFaceDotted = fadFaceDotted;
fadFaceDowncastSweat = fadFaceDowncastSweat;
fadFaceDrooling = fadFaceDrooling;
fadFaceExhaling = fadFaceExhaling;
fadFaceExplode = fadFaceExplode;
fadFaceExpressionless = fadFaceExpressionless;
fadFaceEyesXmarks = fadFaceEyesXmarks;
fadFaceFearful = fadFaceFearful;
fadFaceFlushed = fadFaceFlushed;
fadFaceFrown = fadFaceFrown;
fadFaceFrownOpen = fadFaceFrownOpen;
fadFaceFrownSlight = fadFaceFrownSlight;
fadFaceGlasses = fadFaceGlasses;
fadFaceGrimace = fadFaceGrimace;
fadFaceGrin = fadFaceGrin;
fadFaceGrinBeam = fadFaceGrinBeam;
fadFaceGrinBeamSweat = fadFaceGrinBeamSweat;
fadFaceGrinHearts = fadFaceGrinHearts;
fadFaceGrinSquint = fadFaceGrinSquint;
fadFaceGrinSquintTears = fadFaceGrinSquintTears;
fadFaceGrinStars = fadFaceGrinStars;
fadFaceGrinTears = fadFaceGrinTears;
fadFaceGrinTongue = fadFaceGrinTongue;
fadFaceGrinTongueSquint = fadFaceGrinTongueSquint;
fadFaceGrinTongueWink = fadFaceGrinTongueWink;
fadFaceGrinWide = fadFaceGrinWide;
fadFaceGrinWink = fadFaceGrinWink;
fadFaceHandOverMouth = fadFaceHandOverMouth;
fadFaceHandPeeking = fadFaceHandPeeking;
fadFaceHandYawn = fadFaceHandYawn;
fadFaceHeadBandage = fadFaceHeadBandage;
fadFaceHoldingBackTears = fadFaceHoldingBackTears;
fadFaceHushed = fadFaceHushed;
fadFaceIcicles = fadFaceIcicles;
fadFaceKiss = fadFaceKiss;
fadFaceKissBeam = fadFaceKissBeam;
fadFaceKissClosedEyes = fadFaceKissClosedEyes;
fadFaceKissWinkHeart = fadFaceKissWinkHeart;
fadFaceLaugh = fadFaceLaugh;
fadFaceLaughBeam = fadFaceLaughBeam;
fadFaceLaughSquint = fadFaceLaughSquint;
fadFaceLaughWink = fadFaceLaughWink;
fadFaceLying = fadFaceLying;
fadFaceMask = fadFaceMask;
fadFaceMeh = fadFaceMeh;
fadFaceMehBlank = fadFaceMehBlank;
fadFaceMelting = fadFaceMelting;
fadFaceMonocle = fadFaceMonocle;
fadFaceNauseated = fadFaceNauseated;
fadFaceNoseSteam = fadFaceNoseSteam;
fadFaceParty = fadFaceParty;
fadFacePensive = fadFacePensive;
fadFacePersevering = fadFacePersevering;
fadFacePleading = fadFacePleading;
fadFacePouting = fadFacePouting;
fadFaceRaisedEyebrow = fadFaceRaisedEyebrow;
fadFaceRelieved = fadFaceRelieved;
fadFaceRollingEyes = fadFaceRollingEyes;
fadFaceSadCry = fadFaceSadCry;
fadFaceSadSweat = fadFaceSadSweat;
fadFaceSadTear = fadFaceSadTear;
fadFaceSaluting = fadFaceSaluting;
fadFaceScream = fadFaceScream;
fadFaceShush = fadFaceShush;
fadFaceSleeping = fadFaceSleeping;
fadFaceSleepy = fadFaceSleepy;
fadFaceSmile = fadFaceSmile;
fadFaceSmileBeam = fadFaceSmileBeam;
fadFaceSmileHalo = fadFaceSmileHalo;
fadFaceSmileHearts = fadFaceSmileHearts;
fadFaceSmileHorns = fadFaceSmileHorns;
fadFaceSmilePlus = fadFaceSmilePlus;
fadFaceSmileRelaxed = fadFaceSmileRelaxed;
fadFaceSmileTear = fadFaceSmileTear;
fadFaceSmileTongue = fadFaceSmileTongue;
fadFaceSmileUpsideDown = fadFaceSmileUpsideDown;
fadFaceSmileWink = fadFaceSmileWink;
fadFaceSmilingHands = fadFaceSmilingHands;
fadFaceSmirking = fadFaceSmirking;
fadFaceSpiralEyes = fadFaceSpiralEyes;
fadFaceSunglasses = fadFaceSunglasses;
fadFaceSurprise = fadFaceSurprise;
fadFaceSwear = fadFaceSwear;
fadFaceThermometer = fadFaceThermometer;
fadFaceThinking = fadFaceThinking;
fadFaceTired = fadFaceTired;
fadFaceTissue = fadFaceTissue;
fadFaceTongueMoney = fadFaceTongueMoney;
fadFaceTongueSweat = fadFaceTongueSweat;
fadFaceUnamused = fadFaceUnamused;
fadFaceViewfinder = fadFaceViewfinder;
fadFaceVomit = fadFaceVomit;
fadFaceWeary = fadFaceWeary;
fadFaceWoozy = fadFaceWoozy;
fadFaceWorried = fadFaceWorried;
fadFaceZany = fadFaceZany;
fadFaceZipper = fadFaceZipper;
fadFalafel = fadFalafel;
fadFamily = fadFamily;
fadFamilyDress = fadFamilyDress;
fadFamilyPants = fadFamilyPants;
fadFan = fadFan;
fadFanTable = fadFanTable;
fadFarm = fadFarm;
fadFastBackward = fadFastBackward;
fadFastForward = fadFastForward;
fadFaucet = fadFaucet;
fadFaucetDrip = fadFaucetDrip;
fadFax = fadFax;
fadFeather = fadFeather;
fadFeatherAlt = fadFeatherAlt;
fadFeatherPointed = fadFeatherPointed;
fadFeed = fadFeed;
fadFemale = fadFemale;
fadFence = fadFence;
fadFerrisWheel = fadFerrisWheel;
fadFerry = fadFerry;
fadFieldHockey = fadFieldHockey;
fadFieldHockeyStickBall = fadFieldHockeyStickBall;
fadFighterJet = fadFighterJet;
fadFile = fadFile;
fadFileAlt = fadFileAlt;
fadFileArchive = fadFileArchive;
fadFileArrowDown = fadFileArrowDown;
fadFileArrowUp = fadFileArrowUp;
fadFileAudio = fadFileAudio;
fadFileAward = fadFileAward;
fadFileBinary = fadFileBinary;
fadFileCaretDown = fadFileCaretDown;
fadFileCaretUp = fadFileCaretUp;
fadFileCertificate = fadFileCertificate;
fadFileChartColumn = fadFileChartColumn;
fadFileChartLine = fadFileChartLine;
fadFileChartPie = fadFileChartPie;
fadFileCheck = fadFileCheck;
fadFileCircleCheck = fadFileCircleCheck;
fadFileCircleExclamation = fadFileCircleExclamation;
fadFileCircleInfo = fadFileCircleInfo;
fadFileCircleMinus = fadFileCircleMinus;
fadFileCirclePlus = fadFileCirclePlus;
fadFileCircleQuestion = fadFileCircleQuestion;
fadFileCircleXmark = fadFileCircleXmark;
fadFileClipboard = fadFileClipboard;
fadFileCode = fadFileCode;
fadFileContract = fadFileContract;
fadFileCsv = fadFileCsv;
fadFileDashedLine = fadFileDashedLine;
fadFileDownload = fadFileDownload;
fadFileEdit = fadFileEdit;
fadFileExcel = fadFileExcel;
fadFileExclamation = fadFileExclamation;
fadFileExport = fadFileExport;
fadFileHeart = fadFileHeart;
fadFileImage = fadFileImage;
fadFileImport = fadFileImport;
fadFileInvoice = fadFileInvoice;
fadFileInvoiceDollar = fadFileInvoiceDollar;
fadFileLines = fadFileLines;
fadFileLock = fadFileLock;
fadFileMagnifyingGlass = fadFileMagnifyingGlass;
fadFileMedical = fadFileMedical;
fadFileMedicalAlt = fadFileMedicalAlt;
fadFileMinus = fadFileMinus;
fadFileMusic = fadFileMusic;
fadFilePdf = fadFilePdf;
fadFilePen = fadFilePen;
fadFilePlus = fadFilePlus;
fadFilePlusMinus = fadFilePlusMinus;
fadFilePowerpoint = fadFilePowerpoint;
fadFilePrescription = fadFilePrescription;
fadFiles = fadFiles;
fadFileSearch = fadFileSearch;
fadFileShield = fadFileShield;
fadFileSignature = fadFileSignature;
fadFileSlash = fadFileSlash;
fadFilesMedical = fadFilesMedical;
fadFileSpreadsheet = fadFileSpreadsheet;
fadFileText = fadFileText;
fadFileTimes = fadFileTimes;
fadFileUpload = fadFileUpload;
fadFileUser = fadFileUser;
fadFileVideo = fadFileVideo;
fadFileWaveform = fadFileWaveform;
fadFileWord = fadFileWord;
fadFileXmark = fadFileXmark;
fadFileZipper = fadFileZipper;
fadFill = fadFill;
fadFillDrip = fadFillDrip;
fadFilm = fadFilm;
fadFilmAlt = fadFilmAlt;
fadFilmCanister = fadFilmCanister;
fadFilms = fadFilms;
fadFilmSimple = fadFilmSimple;
fadFilmSlash = fadFilmSlash;
fadFilter = fadFilter;
fadFilterCircleDollar = fadFilterCircleDollar;
fadFilterCircleXmark = fadFilterCircleXmark;
fadFilterList = fadFilterList;
fadFilters = fadFilters;
fadFilterSlash = fadFilterSlash;
fadFingerprint = fadFingerprint;
fadFire = fadFire;
fadFireAlt = fadFireAlt;
fadFireBurner = fadFireBurner;
fadFireExtinguisher = fadFireExtinguisher;
fadFireFlame = fadFireFlame;
fadFireFlameCurved = fadFireFlameCurved;
fadFireFlameSimple = fadFireFlameSimple;
fadFireHydrant = fadFireHydrant;
fadFireplace = fadFireplace;
fadFireSmoke = fadFireSmoke;
fadFirewall = fadFirewall;
fadFirstAid = fadFirstAid;
fadFish = fadFish;
fadFishBones = fadFishBones;
fadFishCooked = fadFishCooked;
fadFishFins = fadFishFins;
fadFishingRod = fadFishingRod;
fadFistRaised = fadFistRaised;
fadFlag = fadFlag;
fadFlagAlt = fadFlagAlt;
fadFlagCheckered = fadFlagCheckered;
fadFlagPennant = fadFlagPennant;
fadFlagSwallowtail = fadFlagSwallowtail;
fadFlagUsa = fadFlagUsa;
fadFlame = fadFlame;
fadFlashlight = fadFlashlight;
fadFlask = fadFlask;
fadFlaskPoison = fadFlaskPoison;
fadFlaskPotion = fadFlaskPotion;
fadFlaskRoundPoison = fadFlaskRoundPoison;
fadFlaskRoundPotion = fadFlaskRoundPotion;
fadFlaskVial = fadFlaskVial;
fadFlatbread = fadFlatbread;
fadFlatbreadStuffed = fadFlatbreadStuffed;
fadFloppyDisk = fadFloppyDisk;
fadFloppyDiskCircleArrowRight = fadFloppyDiskCircleArrowRight;
fadFloppyDiskCircleXmark = fadFloppyDiskCircleXmark;
fadFloppyDiskPen = fadFloppyDiskPen;
fadFloppyDisks = fadFloppyDisks;
fadFloppyDiskTimes = fadFloppyDiskTimes;
fadFlorinSign = fadFlorinSign;
fadFlower = fadFlower;
fadFlowerDaffodil = fadFlowerDaffodil;
fadFlowerTulip = fadFlowerTulip;
fadFlushed = fadFlushed;
fadFlute = fadFlute;
fadFluxCapacitor = fadFluxCapacitor;
fadFlyingDisc = fadFlyingDisc;
fadFog = fadFog;
fadFolder = fadFolder;
fadFolderArrowDown = fadFolderArrowDown;
fadFolderArrowUp = fadFolderArrowUp;
fadFolderBlank = fadFolderBlank;
fadFolderBookmark = fadFolderBookmark;
fadFolderClosed = fadFolderClosed;
fadFolderCog = fadFolderCog;
fadFolderDownload = fadFolderDownload;
fadFolderGear = fadFolderGear;
fadFolderGrid = fadFolderGrid;
fadFolderHeart = fadFolderHeart;
fadFolderImage = fadFolderImage;
fadFolderMagnifyingGlass = fadFolderMagnifyingGlass;
fadFolderMedical = fadFolderMedical;
fadFolderMinus = fadFolderMinus;
fadFolderMusic = fadFolderMusic;
fadFolderOpen = fadFolderOpen;
fadFolderPlus = fadFolderPlus;
fadFolders = fadFolders;
fadFolderSearch = fadFolderSearch;
fadFolderTimes = fadFolderTimes;
fadFolderTree = fadFolderTree;
fadFolderUpload = fadFolderUpload;
fadFolderUser = fadFolderUser;
fadFolderXmark = fadFolderXmark;
fadFonduePot = fadFonduePot;
fadFont = fadFont;
fadFontAwesome = fadFontAwesome;
fadFontAwesomeFlag = fadFontAwesomeFlag;
fadFontAwesomeLogoFull = fadFontAwesomeLogoFull;
fadFontCase = fadFontCase;
fadFootball = fadFootball;
fadFootballBall = fadFootballBall;
fadFootballHelmet = fadFootballHelmet;
fadFork = fadFork;
fadForkKnife = fadForkKnife;
fadForklift = fadForklift;
fadFort = fadFort;
fadForward = fadForward;
fadForwardFast = fadForwardFast;
fadForwardStep = fadForwardStep;
fadFragile = fadFragile;
fadFrame = fadFrame;
fadFrancSign = fadFrancSign;
fadFrenchFries = fadFrenchFries;
fadFrog = fadFrog;
fadFrostyHead = fadFrostyHead;
fadFrown = fadFrown;
fadFrownOpen = fadFrownOpen;
fadFunction = fadFunction;
fadFunnelDollar = fadFunnelDollar;
fadFutbol = fadFutbol;
fadFutbolBall = fadFutbolBall;
fadG = fadG;
fadGalaxy = fadGalaxy;
fadGalleryThumbnails = fadGalleryThumbnails;
fadGameBoard = fadGameBoard;
fadGameBoardAlt = fadGameBoardAlt;
fadGameBoardSimple = fadGameBoardSimple;
fadGameConsoleHandheld = fadGameConsoleHandheld;
fadGamepad = fadGamepad;
fadGamepadAlt = fadGamepadAlt;
fadGamepadModern = fadGamepadModern;
fadGarage = fadGarage;
fadGarageCar = fadGarageCar;
fadGarageOpen = fadGarageOpen;
fadGarlic = fadGarlic;
fadGasPump = fadGasPump;
fadGasPumpSlash = fadGasPumpSlash;
fadGauge = fadGauge;
fadGaugeCircleBolt = fadGaugeCircleBolt;
fadGaugeCircleMinus = fadGaugeCircleMinus;
fadGaugeCirclePlus = fadGaugeCirclePlus;
fadGaugeHigh = fadGaugeHigh;
fadGaugeLow = fadGaugeLow;
fadGaugeMax = fadGaugeMax;
fadGaugeMed = fadGaugeMed;
fadGaugeMin = fadGaugeMin;
fadGaugeSimple = fadGaugeSimple;
fadGaugeSimpleHigh = fadGaugeSimpleHigh;
fadGaugeSimpleLow = fadGaugeSimpleLow;
fadGaugeSimpleMax = fadGaugeSimpleMax;
fadGaugeSimpleMed = fadGaugeSimpleMed;
fadGaugeSimpleMin = fadGaugeSimpleMin;
fadGaveDandy = fadGaveDandy;
fadGavel = fadGavel;
fadGbp = fadGbp;
fadGear = fadGear;
fadGears = fadGears;
fadGem = fadGem;
fadGenderless = fadGenderless;
fadGhost = fadGhost;
fadGif = fadGif;
fadGift = fadGift;
fadGiftCard = fadGiftCard;
fadGifts = fadGifts;
fadGingerbreadMan = fadGingerbreadMan;
fadGlass = fadGlass;
fadGlassChampagne = fadGlassChampagne;
fadGlassCheers = fadGlassCheers;
fadGlassCitrus = fadGlassCitrus;
fadGlassEmpty = fadGlassEmpty;
fadGlasses = fadGlasses;
fadGlassesAlt = fadGlassesAlt;
fadGlassesRound = fadGlassesRound;
fadGlassHalf = fadGlassHalf;
fadGlassHalfEmpty = fadGlassHalfEmpty;
fadGlassHalfFull = fadGlassHalfFull;
fadGlassMartini = fadGlassMartini;
fadGlassMartiniAlt = fadGlassMartiniAlt;
fadGlassWater = fadGlassWater;
fadGlassWaterDroplet = fadGlassWaterDroplet;
fadGlassWhiskey = fadGlassWhiskey;
fadGlassWhiskeyRocks = fadGlassWhiskeyRocks;
fadGlobe = fadGlobe;
fadGlobeAfrica = fadGlobeAfrica;
fadGlobeAmericas = fadGlobeAmericas;
fadGlobeAsia = fadGlobeAsia;
fadGlobeEurope = fadGlobeEurope;
fadGlobeOceania = fadGlobeOceania;
fadGlobeSnow = fadGlobeSnow;
fadGlobeStand = fadGlobeStand;
fadGloveBoxing = fadGloveBoxing;
fadGoalNet = fadGoalNet;
fadGolfBall = fadGolfBall;
fadGolfBallTee = fadGolfBallTee;
fadGolfClub = fadGolfClub;
fadGolfFlagHole = fadGolfFlagHole;
fadGopuram = fadGopuram;
fadGraduationCap = fadGraduationCap;
fadGramophone = fadGramophone;
fadGrapes = fadGrapes;
fadGrate = fadGrate;
fadGrateDroplet = fadGrateDroplet;
fadGreaterThan = fadGreaterThan;
fadGreaterThanEqual = fadGreaterThanEqual;
fadGrid = fadGrid;
fadGrid2 = fadGrid2;
fadGrid2Plus = fadGrid2Plus;
fadGrid3 = fadGrid3;
fadGrid4 = fadGrid4;
fadGrid5 = fadGrid5;
fadGridDividers = fadGridDividers;
fadGridHorizontal = fadGridHorizontal;
fadGrimace = fadGrimace;
fadGrin = fadGrin;
fadGrinAlt = fadGrinAlt;
fadGrinBeam = fadGrinBeam;
fadGrinBeamSweat = fadGrinBeamSweat;
fadGrinHearts = fadGrinHearts;
fadGrinSquint = fadGrinSquint;
fadGrinSquintTears = fadGrinSquintTears;
fadGrinStars = fadGrinStars;
fadGrinTears = fadGrinTears;
fadGrinTongue = fadGrinTongue;
fadGrinTongueSquint = fadGrinTongueSquint;
fadGrinTongueWink = fadGrinTongueWink;
fadGrinWink = fadGrinWink;
fadGrip = fadGrip;
fadGripDots = fadGripDots;
fadGripDotsVertical = fadGripDotsVertical;
fadGripHorizontal = fadGripHorizontal;
fadGripLines = fadGripLines;
fadGripLinesVertical = fadGripLinesVertical;
fadGripVertical = fadGripVertical;
fadGroupArrowsRotate = fadGroupArrowsRotate;
fadGuaraniSign = fadGuaraniSign;
fadGuitar = fadGuitar;
fadGuitarElectric = fadGuitarElectric;
fadGuitars = fadGuitars;
fadGun = fadGun;
fadGunSlash = fadGunSlash;
fadGunSquirt = fadGunSquirt;
fadH = fadH;
fadH1 = fadH1;
fadH2 = fadH2;
fadH3 = fadH3;
fadH4 = fadH4;
fadH5 = fadH5;
fadH6 = fadH6;
fadHamburger = fadHamburger;
fadHammer = fadHammer;
fadHammerCrash = fadHammerCrash;
fadHammerWar = fadHammerWar;
fadHamsa = fadHamsa;
fadHand = fadHand;
fadHandBackFist = fadHandBackFist;
fadHandBackPointDown = fadHandBackPointDown;
fadHandBackPointLeft = fadHandBackPointLeft;
fadHandBackPointRibbon = fadHandBackPointRibbon;
fadHandBackPointRight = fadHandBackPointRight;
fadHandBackPointUp = fadHandBackPointUp;
fadHandcuffs = fadHandcuffs;
fadHandDots = fadHandDots;
fadHandFingersCrossed = fadHandFingersCrossed;
fadHandFist = fadHandFist;
fadHandHeart = fadHandHeart;
fadHandHolding = fadHandHolding;
fadHandHoldingBox = fadHandHoldingBox;
fadHandHoldingDollar = fadHandHoldingDollar;
fadHandHoldingDroplet = fadHandHoldingDroplet;
fadHandHoldingHand = fadHandHoldingHand;
fadHandHoldingHeart = fadHandHoldingHeart;
fadHandHoldingMagic = fadHandHoldingMagic;
fadHandHoldingMedical = fadHandHoldingMedical;
fadHandHoldingSeedling = fadHandHoldingSeedling;
fadHandHoldingSkull = fadHandHoldingSkull;
fadHandHoldingUsd = fadHandHoldingUsd;
fadHandHoldingWater = fadHandHoldingWater;
fadHandHorns = fadHandHorns;
fadHandLizard = fadHandLizard;
fadHandLove = fadHandLove;
fadHandMiddleFinger = fadHandMiddleFinger;
fadHandPaper = fadHandPaper;
fadHandPeace = fadHandPeace;
fadHandPointDown = fadHandPointDown;
fadHandPointer = fadHandPointer;
fadHandPointLeft = fadHandPointLeft;
fadHandPointRibbon = fadHandPointRibbon;
fadHandPointRight = fadHandPointRight;
fadHandPointUp = fadHandPointUp;
fadHandReceiving = fadHandReceiving;
fadHandRock = fadHandRock;
fadHands = fadHands;
fadHandsAmericanSignLanguageInterpreting = fadHandsAmericanSignLanguageInterpreting;
fadHandsAslInterpreting = fadHandsAslInterpreting;
fadHandsBound = fadHandsBound;
fadHandsBubbles = fadHandsBubbles;
fadHandScissors = fadHandScissors;
fadHandsClapping = fadHandsClapping;
fadHandshake = fadHandshake;
fadHandshakeAlt = fadHandshakeAlt;
fadHandshakeAltSlash = fadHandshakeAltSlash;
fadHandshakeAngle = fadHandshakeAngle;
fadHandshakeSimple = fadHandshakeSimple;
fadHandshakeSimpleSlash = fadHandshakeSimpleSlash;
fadHandshakeSlash = fadHandshakeSlash;
fadHandsHeart = fadHandsHeart;
fadHandsHelping = fadHandsHelping;
fadHandsHolding = fadHandsHolding;
fadHandsHoldingChild = fadHandsHoldingChild;
fadHandsHoldingCircle = fadHandsHoldingCircle;
fadHandsHoldingDiamond = fadHandsHoldingDiamond;
fadHandsHoldingDollar = fadHandsHoldingDollar;
fadHandsHoldingHeart = fadHandsHoldingHeart;
fadHandSparkles = fadHandSparkles;
fadHandSpock = fadHandSpock;
fadHandsPraying = fadHandsPraying;
fadHandsUsd = fadHandsUsd;
fadHandsWash = fadHandsWash;
fadHandWave = fadHandWave;
fadHanukiah = fadHanukiah;
fadHardDrive = fadHardDrive;
fadHardHat = fadHardHat;
fadHardOfHearing = fadHardOfHearing;
fadHashtag = fadHashtag;
fadHashtagLock = fadHashtagLock;
fadHatChef = fadHatChef;
fadHatCowboy = fadHatCowboy;
fadHatCowboySide = fadHatCowboySide;
fadHatHard = fadHatHard;
fadHatSanta = fadHatSanta;
fadHatWinter = fadHatWinter;
fadHatWitch = fadHatWitch;
fadHatWizard = fadHatWizard;
fadHdd = fadHdd;
fadHeader = fadHeader;
fadHeading = fadHeading;
fadHeadphones = fadHeadphones;
fadHeadphonesAlt = fadHeadphonesAlt;
fadHeadphonesSimple = fadHeadphonesSimple;
fadHeadset = fadHeadset;
fadHeadSide = fadHeadSide;
fadHeadSideBrain = fadHeadSideBrain;
fadHeadSideCough = fadHeadSideCough;
fadHeadSideCoughSlash = fadHeadSideCoughSlash;
fadHeadSideGoggles = fadHeadSideGoggles;
fadHeadSideHeadphones = fadHeadSideHeadphones;
fadHeadSideHeart = fadHeadSideHeart;
fadHeadSideMask = fadHeadSideMask;
fadHeadSideMedical = fadHeadSideMedical;
fadHeadSideVirus = fadHeadSideVirus;
fadHeadVr = fadHeadVr;
fadHeart = fadHeart;
fadHeartbeat = fadHeartbeat;
fadHeartBroken = fadHeartBroken;
fadHeartCircle = fadHeartCircle;
fadHeartCircleBolt = fadHeartCircleBolt;
fadHeartCircleCheck = fadHeartCircleCheck;
fadHeartCircleExclamation = fadHeartCircleExclamation;
fadHeartCircleMinus = fadHeartCircleMinus;
fadHeartCirclePlus = fadHeartCirclePlus;
fadHeartCircleXmark = fadHeartCircleXmark;
fadHeartCrack = fadHeartCrack;
fadHeartHalf = fadHeartHalf;
fadHeartHalfAlt = fadHeartHalfAlt;
fadHeartHalfStroke = fadHeartHalfStroke;
fadHeartMusicCameraBolt = fadHeartMusicCameraBolt;
fadHeartPulse = fadHeartPulse;
fadHeartRate = fadHeartRate;
fadHeartSquare = fadHeartSquare;
fadHeat = fadHeat;
fadHelicopter = fadHelicopter;
fadHelicopterSymbol = fadHelicopterSymbol;
fadHelmetBattle = fadHelmetBattle;
fadHelmetSafety = fadHelmetSafety;
fadHelmetUn = fadHelmetUn;
fadHexagon = fadHexagon;
fadHexagonCheck = fadHexagonCheck;
fadHexagonDivide = fadHexagonDivide;
fadHexagonExclamation = fadHexagonExclamation;
fadHexagonImage = fadHexagonImage;
fadHexagonMinus = fadHexagonMinus;
fadHexagonPlus = fadHexagonPlus;
fadHexagonVerticalNft = fadHexagonVerticalNft;
fadHexagonVerticalNftSlanted = fadHexagonVerticalNftSlanted;
fadHexagonXmark = fadHexagonXmark;
fadHighDefinition = fadHighDefinition;
fadHighlighter = fadHighlighter;
fadHighlighterLine = fadHighlighterLine;
fadHiking = fadHiking;
fadHillAvalanche = fadHillAvalanche;
fadHillRockslide = fadHillRockslide;
fadHippo = fadHippo;
fadHistory = fadHistory;
fadHockeyMask = fadHockeyMask;
fadHockeyPuck = fadHockeyPuck;
fadHockeyStickPuck = fadHockeyStickPuck;
fadHockeySticks = fadHockeySticks;
fadHollyBerry = fadHollyBerry;
fadHome = fadHome;
fadHomeAlt = fadHomeAlt;
fadHomeBlank = fadHomeBlank;
fadHomeHeart = fadHomeHeart;
fadHomeLg = fadHomeLg;
fadHomeLgAlt = fadHomeLgAlt;
fadHomeUser = fadHomeUser;
fadHoneyPot = fadHoneyPot;
fadHoodCloak = fadHoodCloak;
fadHorizontalRule = fadHorizontalRule;
fadHorse = fadHorse;
fadHorseHead = fadHorseHead;
fadHorseSaddle = fadHorseSaddle;
fadHose = fadHose;
fadHoseReel = fadHoseReel;
fadHospital = fadHospital;
fadHospitalAlt = fadHospitalAlt;
fadHospitals = fadHospitals;
fadHospitalSymbol = fadHospitalSymbol;
fadHospitalUser = fadHospitalUser;
fadHospitalWide = fadHospitalWide;
fadHotdog = fadHotdog;
fadHotel = fadHotel;
fadHotTub = fadHotTub;
fadHotTubPerson = fadHotTubPerson;
fadHourglass = fadHourglass;
fadHourglass1 = fadHourglass1;
fadHourglass2 = fadHourglass2;
fadHourglass3 = fadHourglass3;
fadHourglassClock = fadHourglassClock;
fadHourglassEmpty = fadHourglassEmpty;
fadHourglassEnd = fadHourglassEnd;
fadHourglassHalf = fadHourglassHalf;
fadHourglassStart = fadHourglassStart;
fadHouse = fadHouse;
fadHouseBlank = fadHouseBlank;
fadHouseBuilding = fadHouseBuilding;
fadHouseChimney = fadHouseChimney;
fadHouseChimneyBlank = fadHouseChimneyBlank;
fadHouseChimneyCrack = fadHouseChimneyCrack;
fadHouseChimneyHeart = fadHouseChimneyHeart;
fadHouseChimneyMedical = fadHouseChimneyMedical;
fadHouseChimneyUser = fadHouseChimneyUser;
fadHouseChimneyWindow = fadHouseChimneyWindow;
fadHouseCircleCheck = fadHouseCircleCheck;
fadHouseCircleExclamation = fadHouseCircleExclamation;
fadHouseCircleXmark = fadHouseCircleXmark;
fadHouseCrack = fadHouseCrack;
fadHouseDamage = fadHouseDamage;
fadHouseDay = fadHouseDay;
fadHouseFire = fadHouseFire;
fadHouseFlag = fadHouseFlag;
fadHouseFlood = fadHouseFlood;
fadHouseFloodWater = fadHouseFloodWater;
fadHouseFloodWaterCircleArrowRight = fadHouseFloodWaterCircleArrowRight;
fadHouseHeart = fadHouseHeart;
fadHouseLaptop = fadHouseLaptop;
fadHouseLeave = fadHouseLeave;
fadHouseLock = fadHouseLock;
fadHouseMedical = fadHouseMedical;
fadHouseMedicalCircleCheck = fadHouseMedicalCircleCheck;
fadHouseMedicalCircleExclamation = fadHouseMedicalCircleExclamation;
fadHouseMedicalCircleXmark = fadHouseMedicalCircleXmark;
fadHouseMedicalFlag = fadHouseMedicalFlag;
fadHouseNight = fadHouseNight;
fadHousePersonArrive = fadHousePersonArrive;
fadHousePersonDepart = fadHousePersonDepart;
fadHousePersonLeave = fadHousePersonLeave;
fadHousePersonReturn = fadHousePersonReturn;
fadHouseReturn = fadHouseReturn;
fadHouseSignal = fadHouseSignal;
fadHouseTree = fadHouseTree;
fadHouseTsunami = fadHouseTsunami;
fadHouseTurret = fadHouseTurret;
fadHouseUser = fadHouseUser;
fadHouseWater = fadHouseWater;
fadHouseWindow = fadHouseWindow;
fadHryvnia = fadHryvnia;
fadHryvniaSign = fadHryvniaSign;
fadHSquare = fadHSquare;
fadHumidity = fadHumidity;
fadHundredPoints = fadHundredPoints;
fadHurricane = fadHurricane;
fadHyphen = fadHyphen;
fadI = fadI;
fadIceCream = fadIceCream;
fadIceSkate = fadIceSkate;
fadIcicles = fadIcicles;
fadIcons = fadIcons;
fadIconsAlt = fadIconsAlt;
fadICursor = fadICursor;
fadIdBadge = fadIdBadge;
fadIdCard = fadIdCard;
fadIdCardAlt = fadIdCardAlt;
fadIdCardClip = fadIdCardClip;
fadIgloo = fadIgloo;
fadIls = fadIls;
fadImage = fadImage;
fadImageLandscape = fadImageLandscape;
fadImagePolaroid = fadImagePolaroid;
fadImagePolaroidUser = fadImagePolaroidUser;
fadImagePortrait = fadImagePortrait;
fadImages = fadImages;
fadImageSlash = fadImageSlash;
fadImagesUser = fadImagesUser;
fadImageUser = fadImageUser;
fadInbox = fadInbox;
fadInboxArrowDown = fadInboxArrowDown;
fadInboxArrowUp = fadInboxArrowUp;
fadInboxes = fadInboxes;
fadInboxFull = fadInboxFull;
fadInboxIn = fadInboxIn;
fadInboxOut = fadInboxOut;
fadIndent = fadIndent;
fadIndianRupee = fadIndianRupee;
fadIndianRupeeSign = fadIndianRupeeSign;
fadIndustry = fadIndustry;
fadIndustryAlt = fadIndustryAlt;
fadIndustryWindows = fadIndustryWindows;
fadInfinity = fadInfinity;
fadInfo = fadInfo;
fadInfoCircle = fadInfoCircle;
fadInfoSquare = fadInfoSquare;
fadInhaler = fadInhaler;
fadInputNumeric = fadInputNumeric;
fadInputPipe = fadInputPipe;
fadInputText = fadInputText;
fadInr = fadInr;
fadInstitution = fadInstitution;
fadIntegral = fadIntegral;
fadIntersection = fadIntersection;
fadInventory = fadInventory;
fadIslandTreePalm = fadIslandTreePalm;
fadIslandTropical = fadIslandTropical;
fadItalic = fadItalic;
fadJ = fadJ;
fadJackOLantern = fadJackOLantern;
fadJar = fadJar;
fadJarWheat = fadJarWheat;
fadJedi = fadJedi;
fadJetFighter = fadJetFighter;
fadJetFighterUp = fadJetFighterUp;
fadJoint = fadJoint;
fadJournalWhills = fadJournalWhills;
fadJoystick = fadJoystick;
fadJpy = fadJpy;
fadJug = fadJug;
fadJugDetergent = fadJugDetergent;
fadK = fadK;
fadKaaba = fadKaaba;
fadKazoo = fadKazoo;
fadKerning = fadKerning;
fadKey = fadKey;
fadKeyboard = fadKeyboard;
fadKeyboardBrightness = fadKeyboardBrightness;
fadKeyboardBrightnessLow = fadKeyboardBrightnessLow;
fadKeyboardDown = fadKeyboardDown;
fadKeyboardLeft = fadKeyboardLeft;
fadKeynote = fadKeynote;
fadKeySkeleton = fadKeySkeleton;
fadKeySkeletonLeftRight = fadKeySkeletonLeftRight;
fadKhanda = fadKhanda;
fadKidneys = fadKidneys;
fadKipSign = fadKipSign;
fadKiss = fadKiss;
fadKissBeam = fadKissBeam;
fadKissWinkHeart = fadKissWinkHeart;
fadKitchenSet = fadKitchenSet;
fadKite = fadKite;
fadKitMedical = fadKitMedical;
fadKiwiBird = fadKiwiBird;
fadKiwiFruit = fadKiwiFruit;
fadKnife = fadKnife;
fadKnifeKitchen = fadKnifeKitchen;
fadKrw = fadKrw;
fadL = fadL;
fadLacrosseStick = fadLacrosseStick;
fadLacrosseStickBall = fadLacrosseStickBall;
fadLadderWater = fadLadderWater;
fadLambda = fadLambda;
fadLamp = fadLamp;
fadLampDesk = fadLampDesk;
fadLampFloor = fadLampFloor;
fadLampStreet = fadLampStreet;
fadLandmark = fadLandmark;
fadLandmarkAlt = fadLandmarkAlt;
fadLandmarkDome = fadLandmarkDome;
fadLandmarkFlag = fadLandmarkFlag;
fadLandMineOn = fadLandMineOn;
fadLandscape = fadLandscape;
fadLanguage = fadLanguage;
fadLaptop = fadLaptop;
fadLaptopArrowDown = fadLaptopArrowDown;
fadLaptopCode = fadLaptopCode;
fadLaptopFile = fadLaptopFile;
fadLaptopHouse = fadLaptopHouse;
fadLaptopMedical = fadLaptopMedical;
fadLaptopMobile = fadLaptopMobile;
fadLaptopSlash = fadLaptopSlash;
fadLariSign = fadLariSign;
fadLasso = fadLasso;
fadLassoSparkles = fadLassoSparkles;
fadLaugh = fadLaugh;
fadLaughBeam = fadLaughBeam;
fadLaughSquint = fadLaughSquint;
fadLaughWink = fadLaughWink;
fadLayerGroup = fadLayerGroup;
fadLayerGroupMinus = fadLayerGroupMinus;
fadLayerGroupPlus = fadLayerGroupPlus;
fadLayerMinus = fadLayerMinus;
fadLayerPlus = fadLayerPlus;
fadLeaf = fadLeaf;
fadLeafHeart = fadLeafHeart;
fadLeafMaple = fadLeafMaple;
fadLeafOak = fadLeafOak;
fadLeafyGreen = fadLeafyGreen;
fadLeft = fadLeft;
fadLeftFromLine = fadLeftFromLine;
fadLeftLong = fadLeftLong;
fadLeftLongToLine = fadLeftLongToLine;
fadLeftRight = fadLeftRight;
fadLeftToLine = fadLeftToLine;
fadLegal = fadLegal;
fadLemon = fadLemon;
fadLessThan = fadLessThan;
fadLessThanEqual = fadLessThanEqual;
fadLevelDown = fadLevelDown;
fadLevelDownAlt = fadLevelDownAlt;
fadLevelUp = fadLevelUp;
fadLevelUpAlt = fadLevelUpAlt;
fadLifeRing = fadLifeRing;
fadLightbulb = fadLightbulb;
fadLightbulbDollar = fadLightbulbDollar;
fadLightbulbExclamation = fadLightbulbExclamation;
fadLightbulbExclamationOn = fadLightbulbExclamationOn;
fadLightbulbOn = fadLightbulbOn;
fadLightbulbSlash = fadLightbulbSlash;
fadLightCeiling = fadLightCeiling;
fadLightEmergency = fadLightEmergency;
fadLightEmergencyOn = fadLightEmergencyOn;
fadLightsHoliday = fadLightsHoliday;
fadLightSwitch = fadLightSwitch;
fadLightSwitchOff = fadLightSwitchOff;
fadLightSwitchOn = fadLightSwitchOn;
fadLineChart = fadLineChart;
fadLineColumns = fadLineColumns;
fadLineHeight = fadLineHeight;
fadLinesLeaning = fadLinesLeaning;
fadLink = fadLink;
fadLinkHorizontal = fadLinkHorizontal;
fadLinkHorizontalSlash = fadLinkHorizontalSlash;
fadLinkSimple = fadLinkSimple;
fadLinkSimpleSlash = fadLinkSimpleSlash;
fadLinkSlash = fadLinkSlash;
fadLips = fadLips;
fadLiraSign = fadLiraSign;
fadList = fadList;
fadList12 = fadList12;
fadListAlt = fadListAlt;
fadListCheck = fadListCheck;
fadListDots = fadListDots;
fadListDropdown = fadListDropdown;
fadListMusic = fadListMusic;
fadListNumeric = fadListNumeric;
fadListOl = fadListOl;
fadListRadio = fadListRadio;
fadListSquares = fadListSquares;
fadListTimeline = fadListTimeline;
fadListTree = fadListTree;
fadListUl = fadListUl;
fadLitecoinSign = fadLitecoinSign;
fadLoader = fadLoader;
fadLobster = fadLobster;
fadLocation = fadLocation;
fadLocationArrow = fadLocationArrow;
fadLocationCheck = fadLocationCheck;
fadLocationCircle = fadLocationCircle;
fadLocationCrosshairs = fadLocationCrosshairs;
fadLocationCrosshairsSlash = fadLocationCrosshairsSlash;
fadLocationDot = fadLocationDot;
fadLocationDotSlash = fadLocationDotSlash;
fadLocationExclamation = fadLocationExclamation;
fadLocationMinus = fadLocationMinus;
fadLocationPen = fadLocationPen;
fadLocationPin = fadLocationPin;
fadLocationPinLock = fadLocationPinLock;
fadLocationPinSlash = fadLocationPinSlash;
fadLocationPlus = fadLocationPlus;
fadLocationQuestion = fadLocationQuestion;
fadLocationSlash = fadLocationSlash;
fadLocationSmile = fadLocationSmile;
fadLocationXmark = fadLocationXmark;
fadLock = fadLock;
fadLockA = fadLockA;
fadLockAlt = fadLockAlt;
fadLockHashtag = fadLockHashtag;
fadLockKeyhole = fadLockKeyhole;
fadLockKeyholeOpen = fadLockKeyholeOpen;
fadLockOpen = fadLockOpen;
fadLockOpenAlt = fadLockOpenAlt;
fadLocust = fadLocust;
fadLollipop = fadLollipop;
fadLollypop = fadLollypop;
fadLongArrowAltDown = fadLongArrowAltDown;
fadLongArrowAltLeft = fadLongArrowAltLeft;
fadLongArrowAltRight = fadLongArrowAltRight;
fadLongArrowAltUp = fadLongArrowAltUp;
fadLongArrowDown = fadLongArrowDown;
fadLongArrowLeft = fadLongArrowLeft;
fadLongArrowRight = fadLongArrowRight;
fadLongArrowUp = fadLongArrowUp;
fadLoveseat = fadLoveseat;
fadLowVision = fadLowVision;
fadLuchador = fadLuchador;
fadLuchadorMask = fadLuchadorMask;
fadLuggageCart = fadLuggageCart;
fadLungs = fadLungs;
fadLungsVirus = fadLungsVirus;
fadM = fadM;
fadMace = fadMace;
fadMagic = fadMagic;
fadMagicWandSparkles = fadMagicWandSparkles;
fadMagnet = fadMagnet;
fadMagnifyingGlass = fadMagnifyingGlass;
fadMagnifyingGlassArrowRight = fadMagnifyingGlassArrowRight;
fadMagnifyingGlassChart = fadMagnifyingGlassChart;
fadMagnifyingGlassDollar = fadMagnifyingGlassDollar;
fadMagnifyingGlassLocation = fadMagnifyingGlassLocation;
fadMagnifyingGlassMinus = fadMagnifyingGlassMinus;
fadMagnifyingGlassPlus = fadMagnifyingGlassPlus;
fadMailbox = fadMailbox;
fadMailBulk = fadMailBulk;
fadMailForward = fadMailForward;
fadMailReply = fadMailReply;
fadMailReplyAll = fadMailReplyAll;
fadMakiRoll = fadMakiRoll;
fadMakizushi = fadMakizushi;
fadMale = fadMale;
fadManatSign = fadManatSign;
fadMandolin = fadMandolin;
fadMango = fadMango;
fadManhole = fadManhole;
fadMap = fadMap;
fadMapLocation = fadMapLocation;
fadMapLocationDot = fadMapLocationDot;
fadMapMarked = fadMapMarked;
fadMapMarkedAlt = fadMapMarkedAlt;
fadMapMarker = fadMapMarker;
fadMapMarkerAlt = fadMapMarkerAlt;
fadMapMarkerAltSlash = fadMapMarkerAltSlash;
fadMapMarkerCheck = fadMapMarkerCheck;
fadMapMarkerEdit = fadMapMarkerEdit;
fadMapMarkerExclamation = fadMapMarkerExclamation;
fadMapMarkerMinus = fadMapMarkerMinus;
fadMapMarkerPlus = fadMapMarkerPlus;
fadMapMarkerQuestion = fadMapMarkerQuestion;
fadMapMarkerSlash = fadMapMarkerSlash;
fadMapMarkerSmile = fadMapMarkerSmile;
fadMapMarkerTimes = fadMapMarkerTimes;
fadMapMarkerXmark = fadMapMarkerXmark;
fadMapPin = fadMapPin;
fadMapSigns = fadMapSigns;
fadMarker = fadMarker;
fadMars = fadMars;
fadMarsAndVenus = fadMarsAndVenus;
fadMarsAndVenusBurst = fadMarsAndVenusBurst;
fadMarsDouble = fadMarsDouble;
fadMarsStroke = fadMarsStroke;
fadMarsStrokeH = fadMarsStrokeH;
fadMarsStrokeRight = fadMarsStrokeRight;
fadMarsStrokeUp = fadMarsStrokeUp;
fadMarsStrokeV = fadMarsStrokeV;
fadMartiniGlass = fadMartiniGlass;
fadMartiniGlassCitrus = fadMartiniGlassCitrus;
fadMartiniGlassEmpty = fadMartiniGlassEmpty;
fadMask = fadMask;
fadMaskFace = fadMaskFace;
fadMaskLuchador = fadMaskLuchador;
fadMaskSnorkel = fadMaskSnorkel;
fadMasksTheater = fadMasksTheater;
fadMaskVentilator = fadMaskVentilator;
fadMattressPillow = fadMattressPillow;
fadMaximize = fadMaximize;
fadMeat = fadMeat;
fadMedal = fadMedal;
fadMedkit = fadMedkit;
fadMegaphone = fadMegaphone;
fadMeh = fadMeh;
fadMehBlank = fadMehBlank;
fadMehRollingEyes = fadMehRollingEyes;
fadMelon = fadMelon;
fadMelonSlice = fadMelonSlice;
fadMemo = fadMemo;
fadMemoCircleCheck = fadMemoCircleCheck;
fadMemoCircleInfo = fadMemoCircleInfo;
fadMemoPad = fadMemoPad;
fadMemory = fadMemory;
fadMenorah = fadMenorah;
fadMercury = fadMercury;
fadMerge = fadMerge;
fadMessage = fadMessage;
fadMessageArrowDown = fadMessageArrowDown;
fadMessageArrowUp = fadMessageArrowUp;
fadMessageArrowUpRight = fadMessageArrowUpRight;
fadMessageBot = fadMessageBot;
fadMessageCaptions = fadMessageCaptions;
fadMessageCheck = fadMessageCheck;
fadMessageCode = fadMessageCode;
fadMessageDollar = fadMessageDollar;
fadMessageDots = fadMessageDots;
fadMessageEdit = fadMessageEdit;
fadMessageExclamation = fadMessageExclamation;
fadMessageImage = fadMessageImage;
fadMessageLines = fadMessageLines;
fadMessageMedical = fadMessageMedical;
fadMessageMiddle = fadMessageMiddle;
fadMessageMiddleTop = fadMessageMiddleTop;
fadMessageMinus = fadMessageMinus;
fadMessageMusic = fadMessageMusic;
fadMessagePen = fadMessagePen;
fadMessagePlus = fadMessagePlus;
fadMessageQuestion = fadMessageQuestion;
fadMessageQuote = fadMessageQuote;
fadMessages = fadMessages;
fadMessagesDollar = fadMessagesDollar;
fadMessageSlash = fadMessageSlash;
fadMessageSmile = fadMessageSmile;
fadMessageSms = fadMessageSms;
fadMessagesQuestion = fadMessagesQuestion;
fadMessageText = fadMessageText;
fadMessageTimes = fadMessageTimes;
fadMessageXmark = fadMessageXmark;
fadMessaging = fadMessaging;
fadMeteor = fadMeteor;
fadMeter = fadMeter;
fadMeterBolt = fadMeterBolt;
fadMeterDroplet = fadMeterDroplet;
fadMeterFire = fadMeterFire;
fadMicrochip = fadMicrochip;
fadMicrochipAi = fadMicrochipAi;
fadMicrophone = fadMicrophone;
fadMicrophoneAlt = fadMicrophoneAlt;
fadMicrophoneAltSlash = fadMicrophoneAltSlash;
fadMicrophoneCircle = fadMicrophoneCircle;
fadMicrophoneCircleAlt = fadMicrophoneCircleAlt;
fadMicrophoneLines = fadMicrophoneLines;
fadMicrophoneLinesSlash = fadMicrophoneLinesSlash;
fadMicrophoneSlash = fadMicrophoneSlash;
fadMicrophoneStand = fadMicrophoneStand;
fadMicroscope = fadMicroscope;
fadMicrowave = fadMicrowave;
fadMillSign = fadMillSign;
fadMindShare = fadMindShare;
fadMinimize = fadMinimize;
fadMinus = fadMinus;
fadMinusCircle = fadMinusCircle;
fadMinusHexagon = fadMinusHexagon;
fadMinusOctagon = fadMinusOctagon;
fadMinusSquare = fadMinusSquare;
fadMistletoe = fadMistletoe;
fadMitten = fadMitten;
fadMobile = fadMobile;
fadMobileAlt = fadMobileAlt;
fadMobileAndroid = fadMobileAndroid;
fadMobileAndroidAlt = fadMobileAndroidAlt;
fadMobileButton = fadMobileButton;
fadMobileIphone = fadMobileIphone;
fadMobileNotch = fadMobileNotch;
fadMobilePhone = fadMobilePhone;
fadMobileRetro = fadMobileRetro;
fadMobileScreen = fadMobileScreen;
fadMobileScreenButton = fadMobileScreenButton;
fadMobileSignal = fadMobileSignal;
fadMobileSignalOut = fadMobileSignalOut;
fadMoneyBill = fadMoneyBill;
fadMoneyBill1 = fadMoneyBill1;
fadMoneyBill1Wave = fadMoneyBill1Wave;
fadMoneyBillAlt = fadMoneyBillAlt;
fadMoneyBills = fadMoneyBills;
fadMoneyBillsAlt = fadMoneyBillsAlt;
fadMoneyBillSimple = fadMoneyBillSimple;
fadMoneyBillSimpleWave = fadMoneyBillSimpleWave;
fadMoneyBillsSimple = fadMoneyBillsSimple;
fadMoneyBillTransfer = fadMoneyBillTransfer;
fadMoneyBillTrendUp = fadMoneyBillTrendUp;
fadMoneyBillWave = fadMoneyBillWave;
fadMoneyBillWaveAlt = fadMoneyBillWaveAlt;
fadMoneyBillWheat = fadMoneyBillWheat;
fadMoneyCheck = fadMoneyCheck;
fadMoneyCheckAlt = fadMoneyCheckAlt;
fadMoneyCheckDollar = fadMoneyCheckDollar;
fadMoneyCheckDollarPen = fadMoneyCheckDollarPen;
fadMoneyCheckEdit = fadMoneyCheckEdit;
fadMoneyCheckEditAlt = fadMoneyCheckEditAlt;
fadMoneyCheckPen = fadMoneyCheckPen;
fadMoneyFromBracket = fadMoneyFromBracket;
fadMoneySimpleFromBracket = fadMoneySimpleFromBracket;
fadMonitorHeartRate = fadMonitorHeartRate;
fadMonitorWaveform = fadMonitorWaveform;
fadMonkey = fadMonkey;
fadMonument = fadMonument;
fadMoon = fadMoon;
fadMoonCloud = fadMoonCloud;
fadMoonOverSun = fadMoonOverSun;
fadMoonStars = fadMoonStars;
fadMoped = fadMoped;
fadMortarBoard = fadMortarBoard;
fadMortarPestle = fadMortarPestle;
fadMosque = fadMosque;
fadMosquito = fadMosquito;
fadMosquitoNet = fadMosquitoNet;
fadMotorcycle = fadMotorcycle;
fadMound = fadMound;
fadMountain = fadMountain;
fadMountainCity = fadMountainCity;
fadMountains = fadMountains;
fadMountainSun = fadMountainSun;
fadMouse = fadMouse;
fadMouseAlt = fadMouseAlt;
fadMousePointer = fadMousePointer;
fadMp3Player = fadMp3Player;
fadMug = fadMug;
fadMugHot = fadMugHot;
fadMugMarshmallows = fadMugMarshmallows;
fadMugSaucer = fadMugSaucer;
fadMugTea = fadMugTea;
fadMugTeaSaucer = fadMugTeaSaucer;
fadMultiply = fadMultiply;
fadMuseum = fadMuseum;
fadMushroom = fadMushroom;
fadMusic = fadMusic;
fadMusicAlt = fadMusicAlt;
fadMusicAltSlash = fadMusicAltSlash;
fadMusicNote = fadMusicNote;
fadMusicNoteSlash = fadMusicNoteSlash;
fadMusicSlash = fadMusicSlash;
fadN = fadN;
fadNairaSign = fadNairaSign;
fadNarwhal = fadNarwhal;
fadNavicon = fadNavicon;
fadNestingDolls = fadNestingDolls;
fadNetworkWired = fadNetworkWired;
fadNeuter = fadNeuter;
fadNewspaper = fadNewspaper;
fadNfc = fadNfc;
fadNfcLock = fadNfcLock;
fadNfcMagnifyingGlass = fadNfcMagnifyingGlass;
fadNfcPen = fadNfcPen;
fadNfcSignal = fadNfcSignal;
fadNfcSlash = fadNfcSlash;
fadNfcSymbol = fadNfcSymbol;
fadNfcTrash = fadNfcTrash;
fadNigiri = fadNigiri;
fadNotdef = fadNotdef;
fadNote = fadNote;
fadNotebook = fadNotebook;
fadNoteMedical = fadNoteMedical;
fadNotEqual = fadNotEqual;
fadNotes = fadNotes;
fadNotesMedical = fadNotesMedical;
fadNoteSticky = fadNoteSticky;
fadO = fadO;
fadObjectExclude = fadObjectExclude;
fadObjectGroup = fadObjectGroup;
fadObjectIntersect = fadObjectIntersect;
fadObjectsAlignBottom = fadObjectsAlignBottom;
fadObjectsAlignCenterHorizontal = fadObjectsAlignCenterHorizontal;
fadObjectsAlignCenterVertical = fadObjectsAlignCenterVertical;
fadObjectsAlignLeft = fadObjectsAlignLeft;
fadObjectsAlignRight = fadObjectsAlignRight;
fadObjectsAlignTop = fadObjectsAlignTop;
fadObjectsColumn = fadObjectsColumn;
fadObjectSubtract = fadObjectSubtract;
fadObjectUngroup = fadObjectUngroup;
fadObjectUnion = fadObjectUnion;
fadOctagon = fadOctagon;
fadOctagonCheck = fadOctagonCheck;
fadOctagonDivide = fadOctagonDivide;
fadOctagonExclamation = fadOctagonExclamation;
fadOctagonMinus = fadOctagonMinus;
fadOctagonPlus = fadOctagonPlus;
fadOctagonXmark = fadOctagonXmark;
fadOilCan = fadOilCan;
fadOilCanDrip = fadOilCanDrip;
fadOilTemp = fadOilTemp;
fadOilTemperature = fadOilTemperature;
fadOilWell = fadOilWell;
fadOlive = fadOlive;
fadOliveBranch = fadOliveBranch;
fadOm = fadOm;
fadOmega = fadOmega;
fadOnion = fadOnion;
fadOption = fadOption;
fadOrnament = fadOrnament;
fadOtter = fadOtter;
fadOutdent = fadOutdent;
fadOutlet = fadOutlet;
fadOven = fadOven;
fadOverline = fadOverline;
fadP = fadP;
fadPage = fadPage;
fadPageBreak = fadPageBreak;
fadPageCaretDown = fadPageCaretDown;
fadPageCaretUp = fadPageCaretUp;
fadPager = fadPager;
fadPaintbrush = fadPaintbrush;
fadPaintBrush = fadPaintBrush;
fadPaintbrushAlt = fadPaintbrushAlt;
fadPaintBrushAlt = fadPaintBrushAlt;
fadPaintbrushFine = fadPaintbrushFine;
fadPaintBrushFine = fadPaintBrushFine;
fadPaintbrushPencil = fadPaintbrushPencil;
fadPaintRoller = fadPaintRoller;
fadPalette = fadPalette;
fadPaletteBoxes = fadPaletteBoxes;
fadPallet = fadPallet;
fadPalletAlt = fadPalletAlt;
fadPalletBox = fadPalletBox;
fadPalletBoxes = fadPalletBoxes;
fadPancakes = fadPancakes;
fadPanelEws = fadPanelEws;
fadPanelFire = fadPanelFire;
fadPanFood = fadPanFood;
fadPanFrying = fadPanFrying;
fadPanorama = fadPanorama;
fadPaperclip = fadPaperclip;
fadPaperclipVertical = fadPaperclipVertical;
fadPaperPlane = fadPaperPlane;
fadPaperPlaneAlt = fadPaperPlaneAlt;
fadPaperPlaneTop = fadPaperPlaneTop;
fadParachuteBox = fadParachuteBox;
fadParagraph = fadParagraph;
fadParagraphLeft = fadParagraphLeft;
fadParagraphRtl = fadParagraphRtl;
fadParentheses = fadParentheses;
fadParenthesis = fadParenthesis;
fadParking = fadParking;
fadParkingCircle = fadParkingCircle;
fadParkingCircleSlash = fadParkingCircleSlash;
fadParkingSlash = fadParkingSlash;
fadPartyBack = fadPartyBack;
fadPartyBell = fadPartyBell;
fadPartyHorn = fadPartyHorn;
fadPassport = fadPassport;
fadPastafadrianism = fadPastafadrianism;
fadPaste = fadPaste;
fadPause = fadPause;
fadPauseCircle = fadPauseCircle;
fadPaw = fadPaw;
fadPawAlt = fadPawAlt;
fadPawClaws = fadPawClaws;
fadPawSimple = fadPawSimple;
fadPeace = fadPeace;
fadPeach = fadPeach;
fadPeanut = fadPeanut;
fadPeanuts = fadPeanuts;
fadPeapod = fadPeapod;
fadPear = fadPear;
fadPedestal = fadPedestal;
fadPegasus = fadPegasus;
fadPen = fadPen;
fadPenAlt = fadPenAlt;
fadPenAltSlash = fadPenAltSlash;
fadPencil = fadPencil;
fadPencilAlt = fadPencilAlt;
fadPencilPaintbrush = fadPencilPaintbrush;
fadPencilRuler = fadPencilRuler;
fadPencilSlash = fadPencilSlash;
fadPencilSquare = fadPencilSquare;
fadPenCircle = fadPenCircle;
fadPenClip = fadPenClip;
fadPenClipSlash = fadPenClipSlash;
fadPenFancy = fadPenFancy;
fadPenFancySlash = fadPenFancySlash;
fadPenField = fadPenField;
fadPenLine = fadPenLine;
fadPennant = fadPennant;
fadPenNib = fadPenNib;
fadPenNibSlash = fadPenNibSlash;
fadPenPaintbrush = fadPenPaintbrush;
fadPenRuler = fadPenRuler;
fadPenSlash = fadPenSlash;
fadPenSquare = fadPenSquare;
fadPenSwirl = fadPenSwirl;
fadPenToSquare = fadPenToSquare;
fadPeople = fadPeople;
fadPeopleArrows = fadPeopleArrows;
fadPeopleArrowsLeftRight = fadPeopleArrowsLeftRight;
fadPeopleCarry = fadPeopleCarry;
fadPeopleCarryBox = fadPeopleCarryBox;
fadPeopleDress = fadPeopleDress;
fadPeopleDressSimple = fadPeopleDressSimple;
fadPeopleGroup = fadPeopleGroup;
fadPeopleLine = fadPeopleLine;
fadPeoplePants = fadPeoplePants;
fadPeoplePantsSimple = fadPeoplePantsSimple;
fadPeoplePulling = fadPeoplePulling;
fadPeopleRobbery = fadPeopleRobbery;
fadPeopleRoof = fadPeopleRoof;
fadPeopleSimple = fadPeopleSimple;
fadPepper = fadPepper;
fadPepperHot = fadPepperHot;
fadPercent = fadPercent;
fadPercentage = fadPercentage;
fadPeriod = fadPeriod;
fadPerson = fadPerson;
fadPersonArrowDownToLine = fadPersonArrowDownToLine;
fadPersonArrowUpFromLine = fadPersonArrowUpFromLine;
fadPersonBiking = fadPersonBiking;
fadPersonBikingMountain = fadPersonBikingMountain;
fadPersonBooth = fadPersonBooth;
fadPersonBreastfeeding = fadPersonBreastfeeding;
fadPersonBurst = fadPersonBurst;
fadPersonCane = fadPersonCane;
fadPersonCarry = fadPersonCarry;
fadPersonCarryBox = fadPersonCarryBox;
fadPersonChalkboard = fadPersonChalkboard;
fadPersonCircleCheck = fadPersonCircleCheck;
fadPersonCircleExclamation = fadPersonCircleExclamation;
fadPersonCircleMinus = fadPersonCircleMinus;
fadPersonCirclePlus = fadPersonCirclePlus;
fadPersonCircleQuestion = fadPersonCircleQuestion;
fadPersonCircleXmark = fadPersonCircleXmark;
fadPersonDigging = fadPersonDigging;
fadPersonDolly = fadPersonDolly;
fadPersonDollyEmpty = fadPersonDollyEmpty;
fadPersonDotsFromLine = fadPersonDotsFromLine;
fadPersonDress = fadPersonDress;
fadPersonDressBurst = fadPersonDressBurst;
fadPersonDressSimple = fadPersonDressSimple;
fadPersonDrowning = fadPersonDrowning;
fadPersonFalling = fadPersonFalling;
fadPersonFallingBurst = fadPersonFallingBurst;
fadPersonFromPortal = fadPersonFromPortal;
fadPersonHalfDress = fadPersonHalfDress;
fadPersonHarassing = fadPersonHarassing;
fadPersonHiking = fadPersonHiking;
fadPersonMilitaryPointing = fadPersonMilitaryPointing;
fadPersonMilitaryRifle = fadPersonMilitaryRifle;
fadPersonMilitaryToPerson = fadPersonMilitaryToPerson;
fadPersonPinball = fadPersonPinball;
fadPersonPraying = fadPersonPraying;
fadPersonPregnant = fadPersonPregnant;
fadPersonRays = fadPersonRays;
fadPersonRifle = fadPersonRifle;
fadPersonRunning = fadPersonRunning;
fadPersonSeat = fadPersonSeat;
fadPersonSeatReclined = fadPersonSeatReclined;
fadPersonShelter = fadPersonShelter;
fadPersonSign = fadPersonSign;
fadPersonSimple = fadPersonSimple;
fadPersonSkating = fadPersonSkating;
fadPersonSkiing = fadPersonSkiing;
fadPersonSkiingNordic = fadPersonSkiingNordic;
fadPersonSkiJumping = fadPersonSkiJumping;
fadPersonSkiLift = fadPersonSkiLift;
fadPersonSledding = fadPersonSledding;
fadPersonSnowboarding = fadPersonSnowboarding;
fadPersonSnowmobiling = fadPersonSnowmobiling;
fadPersonSwimming = fadPersonSwimming;
fadPersonThroughWindow = fadPersonThroughWindow;
fadPersonToDoor = fadPersonToDoor;
fadPersonToPortal = fadPersonToPortal;
fadPersonWalking = fadPersonWalking;
fadPersonWalkingArrowLoopLeft = fadPersonWalkingArrowLoopLeft;
fadPersonWalkingArrowRight = fadPersonWalkingArrowRight;
fadPersonWalkingDashedLineArrowRight = fadPersonWalkingDashedLineArrowRight;
fadPersonWalkingLuggage = fadPersonWalkingLuggage;
fadPersonWalkingWithCane = fadPersonWalkingWithCane;
fadPesetaSign = fadPesetaSign;
fadPesoSign = fadPesoSign;
fadPhone = fadPhone;
fadPhoneAlt = fadPhoneAlt;
fadPhoneArrowDown = fadPhoneArrowDown;
fadPhoneArrowDownLeft = fadPhoneArrowDownLeft;
fadPhoneArrowUp = fadPhoneArrowUp;
fadPhoneArrowUpRight = fadPhoneArrowUpRight;
fadPhoneCircle = fadPhoneCircle;
fadPhoneCircleAlt = fadPhoneCircleAlt;
fadPhoneCircleDown = fadPhoneCircleDown;
fadPhoneFlip = fadPhoneFlip;
fadPhoneHangup = fadPhoneHangup;
fadPhoneIncoming = fadPhoneIncoming;
fadPhoneIntercom = fadPhoneIntercom;
fadPhoneLaptop = fadPhoneLaptop;
fadPhoneMissed = fadPhoneMissed;
fadPhoneOffice = fadPhoneOffice;
fadPhoneOutgoing = fadPhoneOutgoing;
fadPhonePlus = fadPhonePlus;
fadPhoneRotary = fadPhoneRotary;
fadPhoneSlash = fadPhoneSlash;
fadPhoneSquare = fadPhoneSquare;
fadPhoneSquareAlt = fadPhoneSquareAlt;
fadPhoneSquareDown = fadPhoneSquareDown;
fadPhoneVolume = fadPhoneVolume;
fadPhoneXmark = fadPhoneXmark;
fadPhotoFilm = fadPhotoFilm;
fadPhotoFilmMusic = fadPhotoFilmMusic;
fadPhotoVideo = fadPhotoVideo;
fadPi = fadPi;
fadPiano = fadPiano;
fadPianoKeyboard = fadPianoKeyboard;
fadPickleball = fadPickleball;
fadPie = fadPie;
fadPieChart = fadPieChart;
fadPig = fadPig;
fadPiggyBank = fadPiggyBank;
fadPills = fadPills;
fadPinata = fadPinata;
fadPinball = fadPinball;
fadPineapple = fadPineapple;
fadPingPongPaddleBall = fadPingPongPaddleBall;
fadPipe = fadPipe;
fadPipeCircleCheck = fadPipeCircleCheck;
fadPipeCollar = fadPipeCollar;
fadPipeSection = fadPipeSection;
fadPipeSmoking = fadPipeSmoking;
fadPipeValve = fadPipeValve;
fadPizza = fadPizza;
fadPizzaSlice = fadPizzaSlice;
fadPlaceOfWorship = fadPlaceOfWorship;
fadPlane = fadPlane;
fadPlaneAlt = fadPlaneAlt;
fadPlaneArrival = fadPlaneArrival;
fadPlaneCircleCheck = fadPlaneCircleCheck;
fadPlaneCircleExclamation = fadPlaneCircleExclamation;
fadPlaneCircleXmark = fadPlaneCircleXmark;
fadPlaneDeparture = fadPlaneDeparture;
fadPlaneEngines = fadPlaneEngines;
fadPlaneLock = fadPlaneLock;
fadPlaneProp = fadPlaneProp;
fadPlaneSlash = fadPlaneSlash;
fadPlaneTail = fadPlaneTail;
fadPlanetMoon = fadPlanetMoon;
fadPlanetRinged = fadPlanetRinged;
fadPlaneUp = fadPlaneUp;
fadPlaneUpSlash = fadPlaneUpSlash;
fadPlantWilt = fadPlantWilt;
fadPlateUtensils = fadPlateUtensils;
fadPlateWheat = fadPlateWheat;
fadPlay = fadPlay;
fadPlayCircle = fadPlayCircle;
fadPlayPause = fadPlayPause;
fadPlug = fadPlug;
fadPlugCircleBolt = fadPlugCircleBolt;
fadPlugCircleCheck = fadPlugCircleCheck;
fadPlugCircleExclamation = fadPlugCircleExclamation;
fadPlugCircleMinus = fadPlugCircleMinus;
fadPlugCirclePlus = fadPlugCirclePlus;
fadPlugCircleXmark = fadPlugCircleXmark;
fadPlus = fadPlus;
fadPlusCircle = fadPlusCircle;
fadPlusHexagon = fadPlusHexagon;
fadPlusMinus = fadPlusMinus;
fadPlusOctagon = fadPlusOctagon;
fadPlusSquare = fadPlusSquare;
fadPodcast = fadPodcast;
fadPodium = fadPodium;
fadPodiumStar = fadPodiumStar;
fadPoliceBox = fadPoliceBox;
fadPoll = fadPoll;
fadPollH = fadPollH;
fadPollPeople = fadPollPeople;
fadPompebled = fadPompebled;
fadPoo = fadPoo;
fadPooBolt = fadPooBolt;
fadPool8Ball = fadPool8Ball;
fadPoop = fadPoop;
fadPooStorm = fadPooStorm;
fadPopcorn = fadPopcorn;
fadPopsicle = fadPopsicle;
fadPortalEnter = fadPortalEnter;
fadPortalExit = fadPortalExit;
fadPortrait = fadPortrait;
fadPotato = fadPotato;
fadPotFood = fadPotFood;
fadPoundSign = fadPoundSign;
fadPowerOff = fadPowerOff;
fadPray = fadPray;
fadPrayingHands = fadPrayingHands;
fadPrescription = fadPrescription;
fadPrescriptionBottle = fadPrescriptionBottle;
fadPrescriptionBottleAlt = fadPrescriptionBottleAlt;
fadPrescriptionBottleMedical = fadPrescriptionBottleMedical;
fadPresentation = fadPresentation;
fadPresentationScreen = fadPresentationScreen;
fadPretzel = fadPretzel;
fadPrint = fadPrint;
fadPrintMagnifyingGlass = fadPrintMagnifyingGlass;
fadPrintSearch = fadPrintSearch;
fadPrintSlash = fadPrintSlash;
fadPro = fadPro;
fadProcedures = fadProcedures;
fadProjectDiagram = fadProjectDiagram;
fadProjector = fadProjector;
fadPump = fadPump;
fadPumpkin = fadPumpkin;
fadPumpMedical = fadPumpMedical;
fadPumpSoap = fadPumpSoap;
fadPuzzle = fadPuzzle;
fadPuzzlePiece = fadPuzzlePiece;
fadPuzzlePieceAlt = fadPuzzlePieceAlt;
fadPuzzlePieceSimple = fadPuzzlePieceSimple;
fadQ = fadQ;
fadQrcode = fadQrcode;
fadQuestion = fadQuestion;
fadQuestionCircle = fadQuestionCircle;
fadQuestionSquare = fadQuestionSquare;
fadQuidditch = fadQuidditch;
fadQuidditchBroomBall = fadQuidditchBroomBall;
fadQuoteLeft = fadQuoteLeft;
fadQuoteLeftAlt = fadQuoteLeftAlt;
fadQuoteRight = fadQuoteRight;
fadQuoteRightAlt = fadQuoteRightAlt;
fadQuotes = fadQuotes;
fadQuran = fadQuran;
fadR = fadR;
fadRabbit = fadRabbit;
fadRabbitFast = fadRabbitFast;
fadRabbitRunning = fadRabbitRunning;
fadRacquet = fadRacquet;
fadRadar = fadRadar;
fadRadiation = fadRadiation;
fadRadiationAlt = fadRadiationAlt;
fadRadio = fadRadio;
fadRadioAlt = fadRadioAlt;
fadRadioTuner = fadRadioTuner;
fadRainbow = fadRainbow;
fadRaindrops = fadRaindrops;
fadRam = fadRam;
fadRampLoading = fadRampLoading;
fadRandom = fadRandom;
fadRankingStar = fadRankingStar;
fadRaygun = fadRaygun;
fadReceipt = fadReceipt;
fadRecordVinyl = fadRecordVinyl;
fadRectangle = fadRectangle;
fadRectangleAd = fadRectangleAd;
fadRectangleBarcode = fadRectangleBarcode;
fadRectangleCode = fadRectangleCode;
fadRectangleHd = fadRectangleHd;
fadRectangleHistory = fadRectangleHistory;
fadRectangleHistoryCirclePlus = fadRectangleHistoryCirclePlus;
fadRectangleHistoryCircleUser = fadRectangleHistoryCircleUser;
fadRectangleLandscape = fadRectangleLandscape;
fadRectangleList = fadRectangleList;
fadRectanglePortrait = fadRectanglePortrait;
fadRectanglePro = fadRectanglePro;
fadRectangleSd = fadRectangleSd;
fadRectanglesMixed = fadRectanglesMixed;
fadRectangleTerminal = fadRectangleTerminal;
fadRectangleTimes = fadRectangleTimes;
fadRectangleVertical = fadRectangleVertical;
fadRectangleVerticalHistory = fadRectangleVerticalHistory;
fadRectangleWide = fadRectangleWide;
fadRectangleXmark = fadRectangleXmark;
fadRecycle = fadRecycle;
fadRedo = fadRedo;
fadRedoAlt = fadRedoAlt;
fadReel = fadReel;
fadRefresh = fadRefresh;
fadRefrigerator = fadRefrigerator;
fadRegistered = fadRegistered;
fadRemove = fadRemove;
fadRemoveFormat = fadRemoveFormat;
fadReorder = fadReorder;
fadRepeat = fadRepeat;
fadRepeat1 = fadRepeat1;
fadRepeat1Alt = fadRepeat1Alt;
fadRepeatAlt = fadRepeatAlt;
fadReply = fadReply;
fadReplyAll = fadReplyAll;
fadReplyClock = fadReplyClock;
fadReplyTime = fadReplyTime;
fadRepublican = fadRepublican;
fadRestroom = fadRestroom;
fadRestroomSimple = fadRestroomSimple;
fadRetweet = fadRetweet;
fadRetweetAlt = fadRetweetAlt;
fadRhombus = fadRhombus;
fadRibbon = fadRibbon;
fadRight = fadRight;
fadRightFromBracket = fadRightFromBracket;
fadRightFromLine = fadRightFromLine;
fadRightLeft = fadRightLeft;
fadRightLong = fadRightLong;
fadRightLongToLine = fadRightLongToLine;
fadRightToBracket = fadRightToBracket;
fadRightToLine = fadRightToLine;
fadRing = fadRing;
fadRingsWedding = fadRingsWedding;
fadRmb = fadRmb;
fadRoad = fadRoad;
fadRoadBarrier = fadRoadBarrier;
fadRoadBridge = fadRoadBridge;
fadRoadCircleCheck = fadRoadCircleCheck;
fadRoadCircleExclamation = fadRoadCircleExclamation;
fadRoadCircleXmark = fadRoadCircleXmark;
fadRoadLock = fadRoadLock;
fadRoadSpikes = fadRoadSpikes;
fadRobot = fadRobot;
fadRobotAstromech = fadRobotAstromech;
fadRocket = fadRocket;
fadRocketLaunch = fadRocketLaunch;
fadRodAsclepius = fadRodAsclepius;
fadRodSnake = fadRodSnake;
fadRollerCoaster = fadRollerCoaster;
fadRotate = fadRotate;
fadRotateBack = fadRotateBack;
fadRotateBackward = fadRotateBackward;
fadRotateExclamation = fadRotateExclamation;
fadRotateForward = fadRotateForward;
fadRotateLeft = fadRotateLeft;
fadRotateRight = fadRotateRight;
fadRouble = fadRouble;
fadRoute = fadRoute;
fadRouteHighway = fadRouteHighway;
fadRouteInterstate = fadRouteInterstate;
fadRouter = fadRouter;
fadRows = fadRows;
fadRss = fadRss;
fadRssSquare = fadRssSquare;
fadRub = fadRub;
fadRuble = fadRuble;
fadRubleSign = fadRubleSign;
fadRug = fadRug;
fadRugbyBall = fadRugbyBall;
fadRuler = fadRuler;
fadRulerCombined = fadRulerCombined;
fadRulerHorizontal = fadRulerHorizontal;
fadRulerTriangle = fadRulerTriangle;
fadRulerVertical = fadRulerVertical;
fadRunning = fadRunning;
fadRupee = fadRupee;
fadRupeeSign = fadRupeeSign;
fadRupiahSign = fadRupiahSign;
fadRv = fadRv;
fadS = fadS;
fadSack = fadSack;
fadSackDollar = fadSackDollar;
fadSackXmark = fadSackXmark;
fadSadCry = fadSadCry;
fadSadTear = fadSadTear;
fadSailboat = fadSailboat;
fadSalad = fadSalad;
fadSaltShaker = fadSaltShaker;
fadSandwich = fadSandwich;
fadSatellite = fadSatellite;
fadSatelliteDish = fadSatelliteDish;
fadSausage = fadSausage;
fadSave = fadSave;
fadSaveCircleArrowRight = fadSaveCircleArrowRight;
fadSaveCircleXmark = fadSaveCircleXmark;
fadSaveTimes = fadSaveTimes;
fadSaxHot = fadSaxHot;
fadSaxophone = fadSaxophone;
fadSaxophoneFire = fadSaxophoneFire;
fadScaleBalanced = fadScaleBalanced;
fadScaleUnbalanced = fadScaleUnbalanced;
fadScaleUnbalancedFlip = fadScaleUnbalancedFlip;
fadScalpel = fadScalpel;
fadScalpelLineDashed = fadScalpelLineDashed;
fadScalpelPath = fadScalpelPath;
fadScanner = fadScanner;
fadScannerGun = fadScannerGun;
fadScannerImage = fadScannerImage;
fadScannerKeyboard = fadScannerKeyboard;
fadScannerTouchscreen = fadScannerTouchscreen;
fadScarecrow = fadScarecrow;
fadScarf = fadScarf;
fadSchool = fadSchool;
fadSchoolCircleCheck = fadSchoolCircleCheck;
fadSchoolCircleExclamation = fadSchoolCircleExclamation;
fadSchoolCircleXmark = fadSchoolCircleXmark;
fadSchoolFlag = fadSchoolFlag;
fadSchoolLock = fadSchoolLock;
fadScissors = fadScissors;
fadScreencast = fadScreencast;
fadScreenshot = fadScreenshot;
fadScreenUsers = fadScreenUsers;
fadScrewdriver = fadScrewdriver;
fadScrewdriverWrench = fadScrewdriverWrench;
fadScribble = fadScribble;
fadScroll = fadScroll;
fadScrollOld = fadScrollOld;
fadScrollRibbon = fadScrollRibbon;
fadScrollTorah = fadScrollTorah;
fadScrubber = fadScrubber;
fadScythe = fadScythe;
fadSdCard = fadSdCard;
fadSdCards = fadSdCards;
fadSeal = fadSeal;
fadSealExclamation = fadSealExclamation;
fadSealQuestion = fadSealQuestion;
fadSearch = fadSearch;
fadSearchDollar = fadSearchDollar;
fadSearchLocation = fadSearchLocation;
fadSearchMinus = fadSearchMinus;
fadSearchPlus = fadSearchPlus;
fadSeatAirline = fadSeatAirline;
fadSection = fadSection;
fadSeedling = fadSeedling;
fadSemicolon = fadSemicolon;
fadSend = fadSend;
fadSendBack = fadSendBack;
fadSendBackward = fadSendBackward;
fadSensor = fadSensor;
fadSensorAlert = fadSensorAlert;
fadSensorCloud = fadSensorCloud;
fadSensorFire = fadSensorFire;
fadSensorOn = fadSensorOn;
fadSensorSmoke = fadSensorSmoke;
fadSensorTriangleExclamation = fadSensorTriangleExclamation;
fadServer = fadServer;
fadShapes = fadShapes;
fadShare = fadShare;
fadShareAll = fadShareAll;
fadShareAlt = fadShareAlt;
fadShareAltSquare = fadShareAltSquare;
fadShareFromSquare = fadShareFromSquare;
fadShareNodes = fadShareNodes;
fadShareSquare = fadShareSquare;
fadSheep = fadSheep;
fadSheetPlastic = fadSheetPlastic;
fadShekel = fadShekel;
fadShekelSign = fadShekelSign;
fadShelves = fadShelves;
fadShelvesEmpty = fadShelvesEmpty;
fadSheqel = fadSheqel;
fadSheqelSign = fadSheqelSign;
fadShield = fadShield;
fadShieldAlt = fadShieldAlt;
fadShieldBlank = fadShieldBlank;
fadShieldCat = fadShieldCat;
fadShieldCheck = fadShieldCheck;
fadShieldCross = fadShieldCross;
fadShieldDog = fadShieldDog;
fadShieldExclamation = fadShieldExclamation;
fadShieldHalved = fadShieldHalved;
fadShieldHeart = fadShieldHeart;
fadShieldKeyhole = fadShieldKeyhole;
fadShieldMinus = fadShieldMinus;
fadShieldPlus = fadShieldPlus;
fadShieldQuartered = fadShieldQuartered;
fadShieldSlash = fadShieldSlash;
fadShieldTimes = fadShieldTimes;
fadShieldVirus = fadShieldVirus;
fadShieldXmark = fadShieldXmark;
fadShip = fadShip;
fadShippingFast = fadShippingFast;
fadShippingTimed = fadShippingTimed;
fadShirt = fadShirt;
fadShirtLongSleeve = fadShirtLongSleeve;
fadShirtRunning = fadShirtRunning;
fadShirtTankTop = fadShirtTankTop;
fadShishKebab = fadShishKebab;
fadShoePrints = fadShoePrints;
fadShop = fadShop;
fadShopLock = fadShopLock;
fadShoppingBag = fadShoppingBag;
fadShoppingBasket = fadShoppingBasket;
fadShoppingBasketAlt = fadShoppingBasketAlt;
fadShoppingCart = fadShoppingCart;
fadShopSlash = fadShopSlash;
fadShortcake = fadShortcake;
fadShovel = fadShovel;
fadShovelSnow = fadShovelSnow;
fadShower = fadShower;
fadShowerAlt = fadShowerAlt;
fadShowerDown = fadShowerDown;
fadShredder = fadShredder;
fadShrimp = fadShrimp;
fadShuffle = fadShuffle;
fadShutters = fadShutters;
fadShuttlecock = fadShuttlecock;
fadShuttleSpace = fadShuttleSpace;
fadShuttleVan = fadShuttleVan;
fadSickle = fadSickle;
fadSidebar = fadSidebar;
fadSidebarFlip = fadSidebarFlip;
fadSigma = fadSigma;
fadSign = fadSign;
fadSignal = fadSignal;
fadSignal1 = fadSignal1;
fadSignal2 = fadSignal2;
fadSignal3 = fadSignal3;
fadSignal4 = fadSignal4;
fadSignal5 = fadSignal5;
fadSignalAlt = fadSignalAlt;
fadSignalAlt1 = fadSignalAlt1;
fadSignalAlt2 = fadSignalAlt2;
fadSignalAlt3 = fadSignalAlt3;
fadSignalAlt4 = fadSignalAlt4;
fadSignalAltSlash = fadSignalAltSlash;
fadSignalBars = fadSignalBars;
fadSignalBarsFair = fadSignalBarsFair;
fadSignalBarsGood = fadSignalBarsGood;
fadSignalBarsSlash = fadSignalBarsSlash;
fadSignalBarsStrong = fadSignalBarsStrong;
fadSignalBarsWeak = fadSignalBarsWeak;
fadSignalFair = fadSignalFair;
fadSignalGood = fadSignalGood;
fadSignalPerfect = fadSignalPerfect;
fadSignalSlash = fadSignalSlash;
fadSignalStream = fadSignalStream;
fadSignalStreamSlash = fadSignalStreamSlash;
fadSignalStrong = fadSignalStrong;
fadSignalWeak = fadSignalWeak;
fadSignature = fadSignature;
fadSignatureLock = fadSignatureLock;
fadSignatureSlash = fadSignatureSlash;
fadSignHanging = fadSignHanging;
fadSignIn = fadSignIn;
fadSignInAlt = fadSignInAlt;
fadSigning = fadSigning;
fadSignLanguage = fadSignLanguage;
fadSignOut = fadSignOut;
fadSignOutAlt = fadSignOutAlt;
fadSignsPost = fadSignsPost;
fadSimCard = fadSimCard;
fadSimCards = fadSimCards;
fadSink = fadSink;
fadSiren = fadSiren;
fadSirenOn = fadSirenOn;
fadSitemap = fadSitemap;
fadSkating = fadSkating;
fadSkeleton = fadSkeleton;
fadSkiBoot = fadSkiBoot;
fadSkiBootSki = fadSkiBootSki;
fadSkiing = fadSkiing;
fadSkiingNordic = fadSkiingNordic;
fadSkiJump = fadSkiJump;
fadSkiLift = fadSkiLift;
fadSkull = fadSkull;
fadSkullCow = fadSkullCow;
fadSkullCrossbones = fadSkullCrossbones;
fadSlash = fadSlash;
fadSlashBack = fadSlashBack;
fadSlashForward = fadSlashForward;
fadSledding = fadSledding;
fadSleigh = fadSleigh;
fadSlider = fadSlider;
fadSliders = fadSliders;
fadSlidersH = fadSlidersH;
fadSlidersHSquare = fadSlidersHSquare;
fadSlidersSimple = fadSlidersSimple;
fadSlidersUp = fadSlidersUp;
fadSlidersV = fadSlidersV;
fadSlidersVSquare = fadSlidersVSquare;
fadSlotMachine = fadSlotMachine;
fadSmile = fadSmile;
fadSmileBeam = fadSmileBeam;
fadSmilePlus = fadSmilePlus;
fadSmileWink = fadSmileWink;
fadSmog = fadSmog;
fadSmoke = fadSmoke;
fadSmoking = fadSmoking;
fadSmokingBan = fadSmokingBan;
fadSms = fadSms;
fadSnake = fadSnake;
fadSnooze = fadSnooze;
fadSnowBlowing = fadSnowBlowing;
fadSnowboarding = fadSnowboarding;
fadSnowflake = fadSnowflake;
fadSnowflakes = fadSnowflakes;
fadSnowman = fadSnowman;
fadSnowmanHead = fadSnowmanHead;
fadSnowmobile = fadSnowmobile;
fadSnowplow = fadSnowplow;
fadSoap = fadSoap;
fadSoccerBall = fadSoccerBall;
fadSocks = fadSocks;
fadSoftServe = fadSoftServe;
fadSolarPanel = fadSolarPanel;
fadSolarSystem = fadSolarSystem;
fadSort = fadSort;
fadSortAlphaAsc = fadSortAlphaAsc;
fadSortAlphaDesc = fadSortAlphaDesc;
fadSortAlphaDown = fadSortAlphaDown;
fadSortAlphaDownAlt = fadSortAlphaDownAlt;
fadSortAlphaUp = fadSortAlphaUp;
fadSortAlphaUpAlt = fadSortAlphaUpAlt;
fadSortAlt = fadSortAlt;
fadSortAmountAsc = fadSortAmountAsc;
fadSortAmountDesc = fadSortAmountDesc;
fadSortAmountDown = fadSortAmountDown;
fadSortAmountDownAlt = fadSortAmountDownAlt;
fadSortAmountUp = fadSortAmountUp;
fadSortAmountUpAlt = fadSortAmountUpAlt;
fadSortAsc = fadSortAsc;
fadSortCircle = fadSortCircle;
fadSortCircleDown = fadSortCircleDown;
fadSortCircleUp = fadSortCircleUp;
fadSortDesc = fadSortDesc;
fadSortDown = fadSortDown;
fadSortNumericAsc = fadSortNumericAsc;
fadSortNumericDesc = fadSortNumericDesc;
fadSortNumericDown = fadSortNumericDown;
fadSortNumericDownAlt = fadSortNumericDownAlt;
fadSortNumericUp = fadSortNumericUp;
fadSortNumericUpAlt = fadSortNumericUpAlt;
fadSortShapesDown = fadSortShapesDown;
fadSortShapesDownAlt = fadSortShapesDownAlt;
fadSortShapesUp = fadSortShapesUp;
fadSortShapesUpAlt = fadSortShapesUpAlt;
fadSortSizeDown = fadSortSizeDown;
fadSortSizeDownAlt = fadSortSizeDownAlt;
fadSortSizeUp = fadSortSizeUp;
fadSortSizeUpAlt = fadSortSizeUpAlt;
fadSortUp = fadSortUp;
fadSortUpDown = fadSortUpDown;
fadSoup = fadSoup;
fadSpa = fadSpa;
fadSpaceShuttle = fadSpaceShuttle;
fadSpaceStationMoon = fadSpaceStationMoon;
fadSpaceStationMoonAlt = fadSpaceStationMoonAlt;
fadSpaceStationMoonConstruction = fadSpaceStationMoonConstruction;
fadSpade = fadSpade;
fadSpaghettiMonsterFlying = fadSpaghettiMonsterFlying;
fadSparkles = fadSparkles;
fadSpeaker = fadSpeaker;
fadSpeakers = fadSpeakers;
fadSpellCheck = fadSpellCheck;
fadSpider = fadSpider;
fadSpiderBlackWidow = fadSpiderBlackWidow;
fadSpiderWeb = fadSpiderWeb;
fadSpinner = fadSpinner;
fadSpinnerThird = fadSpinnerThird;
fadSplit = fadSplit;
fadSplotch = fadSplotch;
fadSpoon = fadSpoon;
fadSportsball = fadSportsball;
fadSprayCan = fadSprayCan;
fadSprayCanSparkles = fadSprayCanSparkles;
fadSprinkler = fadSprinkler;
fadSprinklerCeiling = fadSprinklerCeiling;
fadSprout = fadSprout;
fadSquare = fadSquare;
fadSquare0 = fadSquare0;
fadSquare1 = fadSquare1;
fadSquare2 = fadSquare2;
fadSquare3 = fadSquare3;
fadSquare4 = fadSquare4;
fadSquare5 = fadSquare5;
fadSquare6 = fadSquare6;
fadSquare7 = fadSquare7;
fadSquare8 = fadSquare8;
fadSquare9 = fadSquare9;
fadSquareA = fadSquareA;
fadSquareALock = fadSquareALock;
fadSquareAmpersand = fadSquareAmpersand;
fadSquareArrowDown = fadSquareArrowDown;
fadSquareArrowDownLeft = fadSquareArrowDownLeft;
fadSquareArrowDownRight = fadSquareArrowDownRight;
fadSquareArrowLeft = fadSquareArrowLeft;
fadSquareArrowRight = fadSquareArrowRight;
fadSquareArrowUp = fadSquareArrowUp;
fadSquareArrowUpLeft = fadSquareArrowUpLeft;
fadSquareArrowUpRight = fadSquareArrowUpRight;
fadSquareB = fadSquareB;
fadSquareBolt = fadSquareBolt;
fadSquareC = fadSquareC;
fadSquareCaretDown = fadSquareCaretDown;
fadSquareCaretLeft = fadSquareCaretLeft;
fadSquareCaretRight = fadSquareCaretRight;
fadSquareCaretUp = fadSquareCaretUp;
fadSquareCheck = fadSquareCheck;
fadSquareChevronDown = fadSquareChevronDown;
fadSquareChevronLeft = fadSquareChevronLeft;
fadSquareChevronRight = fadSquareChevronRight;
fadSquareChevronUp = fadSquareChevronUp;
fadSquareCode = fadSquareCode;
fadSquareD = fadSquareD;
fadSquareDashed = fadSquareDashed;
fadSquareDivide = fadSquareDivide;
fadSquareDollar = fadSquareDollar;
fadSquareDown = fadSquareDown;
fadSquareDownLeft = fadSquareDownLeft;
fadSquareDownRight = fadSquareDownRight;
fadSquareE = fadSquareE;
fadSquareEllipsis = fadSquareEllipsis;
fadSquareEllipsisVertical = fadSquareEllipsisVertical;
fadSquareEnvelope = fadSquareEnvelope;
fadSquareExclamation = fadSquareExclamation;
fadSquareF = fadSquareF;
fadSquareFragile = fadSquareFragile;
fadSquareFull = fadSquareFull;
fadSquareG = fadSquareG;
fadSquareH = fadSquareH;
fadSquareHeart = fadSquareHeart;
fadSquareI = fadSquareI;
fadSquareInfo = fadSquareInfo;
fadSquareJ = fadSquareJ;
fadSquareK = fadSquareK;
fadSquareKanban = fadSquareKanban;
fadSquareL = fadSquareL;
fadSquareLeft = fadSquareLeft;
fadSquareList = fadSquareList;
fadSquareM = fadSquareM;
fadSquareMinus = fadSquareMinus;
fadSquareN = fadSquareN;
fadSquareNfi = fadSquareNfi;
fadSquareO = fadSquareO;
fadSquareP = fadSquareP;
fadSquareParking = fadSquareParking;
fadSquareParkingSlash = fadSquareParkingSlash;
fadSquarePen = fadSquarePen;
fadSquarePersonConfined = fadSquarePersonConfined;
fadSquarePhone = fadSquarePhone;
fadSquarePhoneFlip = fadSquarePhoneFlip;
fadSquarePhoneHangup = fadSquarePhoneHangup;
fadSquarePlus = fadSquarePlus;
fadSquarePollHorizontal = fadSquarePollHorizontal;
fadSquarePollVertical = fadSquarePollVertical;
fadSquareQ = fadSquareQ;
fadSquareQuarters = fadSquareQuarters;
fadSquareQuestion = fadSquareQuestion;
fadSquareQuote = fadSquareQuote;
fadSquareR = fadSquareR;
fadSquareRight = fadSquareRight;
fadSquareRing = fadSquareRing;
fadSquareRoot = fadSquareRoot;
fadSquareRootAlt = fadSquareRootAlt;
fadSquareRootVariable = fadSquareRootVariable;
fadSquareRss = fadSquareRss;
fadSquareS = fadSquareS;
fadSquareShareNodes = fadSquareShareNodes;
fadSquareSliders = fadSquareSliders;
fadSquareSlidersVertical = fadSquareSlidersVertical;
fadSquareSmall = fadSquareSmall;
fadSquareStar = fadSquareStar;
fadSquareT = fadSquareT;
fadSquareTerminal = fadSquareTerminal;
fadSquareThisWayUp = fadSquareThisWayUp;
fadSquareU = fadSquareU;
fadSquareUp = fadSquareUp;
fadSquareUpLeft = fadSquareUpLeft;
fadSquareUpRight = fadSquareUpRight;
fadSquareUser = fadSquareUser;
fadSquareV = fadSquareV;
fadSquareVirus = fadSquareVirus;
fadSquareW = fadSquareW;
fadSquareWineGlassCrack = fadSquareWineGlassCrack;
fadSquareX = fadSquareX;
fadSquareXmark = fadSquareXmark;
fadSquareY = fadSquareY;
fadSquareZ = fadSquareZ;
fadSquid = fadSquid;
fadSquirrel = fadSquirrel;
fadStaff = fadStaff;
fadStaffAesculapius = fadStaffAesculapius;
fadStaffSnake = fadStaffSnake;
fadStairs = fadStairs;
fadStamp = fadStamp;
fadStandardDefinition = fadStandardDefinition;
fadStar = fadStar;
fadStarAndCrescent = fadStarAndCrescent;
fadStarChristmas = fadStarChristmas;
fadStarCircle = fadStarCircle;
fadStarExclamation = fadStarExclamation;
fadStarfighter = fadStarfighter;
fadStarfighterAlt = fadStarfighterAlt;
fadStarfighterAltAdvanced = fadStarfighterAltAdvanced;
fadStarfighterTwinIonEngine = fadStarfighterTwinIonEngine;
fadStarfighterTwinIonEngineAdvanced = fadStarfighterTwinIonEngineAdvanced;
fadStarHalf = fadStarHalf;
fadStarHalfAlt = fadStarHalfAlt;
fadStarHalfStroke = fadStarHalfStroke;
fadStarOfDavid = fadStarOfDavid;
fadStarOfLife = fadStarOfLife;
fadStars = fadStars;
fadStarSharp = fadStarSharp;
fadStarSharpHalf = fadStarSharpHalf;
fadStarSharpHalfAlt = fadStarSharpHalfAlt;
fadStarSharpHalfStroke = fadStarSharpHalfStroke;
fadStarship = fadStarship;
fadStarshipFreighter = fadStarshipFreighter;
fadStarShooting = fadStarShooting;
fadSteak = fadSteak;
fadSteeringWheel = fadSteeringWheel;
fadStepBackward = fadStepBackward;
fadStepForward = fadStepForward;
fadSterlingSign = fadSterlingSign;
fadStethoscope = fadStethoscope;
fadStickyNote = fadStickyNote;
fadStocking = fadStocking;
fadStomach = fadStomach;
fadStop = fadStop;
fadStopCircle = fadStopCircle;
fadStopwatch = fadStopwatch;
fadStopwatch20 = fadStopwatch20;
fadStore = fadStore;
fadStoreAlt = fadStoreAlt;
fadStoreAltSlash = fadStoreAltSlash;
fadStoreLock = fadStoreLock;
fadStoreSlash = fadStoreSlash;
fadStrawberry = fadStrawberry;
fadStream = fadStream;
fadStreetView = fadStreetView;
fadStretcher = fadStretcher;
fadStrikethrough = fadStrikethrough;
fadStroopwafel = fadStroopwafel;
fadSubscript = fadSubscript;
fadSubtract = fadSubtract;
fadSubway = fadSubway;
fadSubwayTunnel = fadSubwayTunnel;
fadSuitcase = fadSuitcase;
fadSuitcaseMedical = fadSuitcaseMedical;
fadSuitcaseRolling = fadSuitcaseRolling;
fadSun = fadSun;
fadSunAlt = fadSunAlt;
fadSunBright = fadSunBright;
fadSunCloud = fadSunCloud;
fadSunDust = fadSunDust;
fadSunglasses = fadSunglasses;
fadSunHaze = fadSunHaze;
fadSunPlantWilt = fadSunPlantWilt;
fadSunrise = fadSunrise;
fadSunset = fadSunset;
fadSuperscript = fadSuperscript;
fadSurprise = fadSurprise;
fadSushi = fadSushi;
fadSushiRoll = fadSushiRoll;
fadSwatchbook = fadSwatchbook;
fadSwimmer = fadSwimmer;
fadSwimmingPool = fadSwimmingPool;
fadSword = fadSword;
fadSwordLaser = fadSwordLaser;
fadSwordLaserAlt = fadSwordLaserAlt;
fadSwords = fadSwords;
fadSwordsLaser = fadSwordsLaser;
fadSymbols = fadSymbols;
fadSynagogue = fadSynagogue;
fadSync = fadSync;
fadSyncAlt = fadSyncAlt;
fadSyringe = fadSyringe;
fadT = fadT;
fadTable = fadTable;
fadTableCells = fadTableCells;
fadTableCellsLarge = fadTableCellsLarge;
fadTableColumns = fadTableColumns;
fadTableLayout = fadTableLayout;
fadTableList = fadTableList;
fadTablePicnic = fadTablePicnic;
fadTablePivot = fadTablePivot;
fadTableRows = fadTableRows;
fadTablet = fadTablet;
fadTabletAlt = fadTabletAlt;
fadTabletAndroid = fadTabletAndroid;
fadTabletAndroidAlt = fadTabletAndroidAlt;
fadTabletButton = fadTabletButton;
fadTableTennis = fadTableTennis;
fadTableTennisPaddleBall = fadTableTennisPaddleBall;
fadTableTree = fadTableTree;
fadTabletRugged = fadTabletRugged;
fadTablets = fadTablets;
fadTabletScreen = fadTabletScreen;
fadTabletScreenButton = fadTabletScreenButton;
fadTachographDigital = fadTachographDigital;
fadTachometer = fadTachometer;
fadTachometerAlt = fadTachometerAlt;
fadTachometerAltAverage = fadTachometerAltAverage;
fadTachometerAltFast = fadTachometerAltFast;
fadTachometerAltFastest = fadTachometerAltFastest;
fadTachometerAltSlow = fadTachometerAltSlow;
fadTachometerAltSlowest = fadTachometerAltSlowest;
fadTachometerAverage = fadTachometerAverage;
fadTachometerFast = fadTachometerFast;
fadTachometerFastest = fadTachometerFastest;
fadTachometerSlow = fadTachometerSlow;
fadTachometerSlowest = fadTachometerSlowest;
fadTaco = fadTaco;
fadTag = fadTag;
fadTags = fadTags;
fadTally = fadTally;
fadTally1 = fadTally1;
fadTally2 = fadTally2;
fadTally3 = fadTally3;
fadTally4 = fadTally4;
fadTally5 = fadTally5;
fadTamale = fadTamale;
fadTanakh = fadTanakh;
fadTankWater = fadTankWater;
fadTape = fadTape;
fadTarp = fadTarp;
fadTarpDroplet = fadTarpDroplet;
fadTasks = fadTasks;
fadTasksAlt = fadTasksAlt;
fadTaxi = fadTaxi;
fadTaxiBus = fadTaxiBus;
fadTeddyBear = fadTeddyBear;
fadTeeth = fadTeeth;
fadTeethOpen = fadTeethOpen;
fadTelescope = fadTelescope;
fadTeletype = fadTeletype;
fadTeletypeAnswer = fadTeletypeAnswer;
fadTelevision = fadTelevision;
fadTemperature0 = fadTemperature0;
fadTemperature1 = fadTemperature1;
fadTemperature2 = fadTemperature2;
fadTemperature3 = fadTemperature3;
fadTemperature4 = fadTemperature4;
fadTemperatureArrowDown = fadTemperatureArrowDown;
fadTemperatureArrowUp = fadTemperatureArrowUp;
fadTemperatureDown = fadTemperatureDown;
fadTemperatureEmpty = fadTemperatureEmpty;
fadTemperatureFrigid = fadTemperatureFrigid;
fadTemperatureFull = fadTemperatureFull;
fadTemperatureHalf = fadTemperatureHalf;
fadTemperatureHigh = fadTemperatureHigh;
fadTemperatureHot = fadTemperatureHot;
fadTemperatureList = fadTemperatureList;
fadTemperatureLow = fadTemperatureLow;
fadTemperatureQuarter = fadTemperatureQuarter;
fadTemperatureSnow = fadTemperatureSnow;
fadTemperatureSun = fadTemperatureSun;
fadTemperatureThreeQuarters = fadTemperatureThreeQuarters;
fadTemperatureUp = fadTemperatureUp;
fadTenge = fadTenge;
fadTengeSign = fadTengeSign;
fadTennisBall = fadTennisBall;
fadTent = fadTent;
fadTentArrowDownToLine = fadTentArrowDownToLine;
fadTentArrowLeftRight = fadTentArrowLeftRight;
fadTentArrowsDown = fadTentArrowsDown;
fadTentArrowTurnLeft = fadTentArrowTurnLeft;
fadTents = fadTents;
fadTerminal = fadTerminal;
fadText = fadText;
fadTextHeight = fadTextHeight;
fadTextSize = fadTextSize;
fadTextSlash = fadTextSlash;
fadTextWidth = fadTextWidth;
fadTh = fadTh;
fadTheaterMasks = fadTheaterMasks;
fadThermometer = fadThermometer;
fadThermometer0 = fadThermometer0;
fadThermometer1 = fadThermometer1;
fadThermometer2 = fadThermometer2;
fadThermometer3 = fadThermometer3;
fadThermometer4 = fadThermometer4;
fadThermometerEmpty = fadThermometerEmpty;
fadThermometerFull = fadThermometerFull;
fadThermometerHalf = fadThermometerHalf;
fadThermometerQuarter = fadThermometerQuarter;
fadThermometerThreeQuarters = fadThermometerThreeQuarters;
fadTheta = fadTheta;
fadThLarge = fadThLarge;
fadThList = fadThList;
fadThoughtBubble = fadThoughtBubble;
fadThumbsDown = fadThumbsDown;
fadThumbsUp = fadThumbsUp;
fadThumbtack = fadThumbtack;
fadThumbTack = fadThumbTack;
fadThunderstorm = fadThunderstorm;
fadThunderstormMoon = fadThunderstormMoon;
fadThunderstormSun = fadThunderstormSun;
fadTick = fadTick;
fadTicket = fadTicket;
fadTicketAirline = fadTicketAirline;
fadTicketAlt = fadTicketAlt;
fadTicketsAirline = fadTicketsAirline;
fadTicketSimple = fadTicketSimple;
fadTilde = fadTilde;
fadTimeline = fadTimeline;
fadTimelineArrow = fadTimelineArrow;
fadTimer = fadTimer;
fadTimes = fadTimes;
fadTimesCircle = fadTimesCircle;
fadTimesHexagon = fadTimesHexagon;
fadTimesOctagon = fadTimesOctagon;
fadTimesRectangle = fadTimesRectangle;
fadTimesSquare = fadTimesSquare;
fadTimesToSlot = fadTimesToSlot;
fadTint = fadTint;
fadTintSlash = fadTintSlash;
fadTire = fadTire;
fadTired = fadTired;
fadTireFlat = fadTireFlat;
fadTirePressureWarning = fadTirePressureWarning;
fadTireRugged = fadTireRugged;
fadToggleOff = fadToggleOff;
fadToggleOn = fadToggleOn;
fadToilet = fadToilet;
fadToiletPaper = fadToiletPaper;
fadToiletPaperAlt = fadToiletPaperAlt;
fadToiletPaperBlank = fadToiletPaperBlank;
fadToiletPaperBlankUnder = fadToiletPaperBlankUnder;
fadToiletPaperReverse = fadToiletPaperReverse;
fadToiletPaperReverseAlt = fadToiletPaperReverseAlt;
fadToiletPaperReverseSlash = fadToiletPaperReverseSlash;
fadToiletPaperSlash = fadToiletPaperSlash;
fadToiletPaperUnder = fadToiletPaperUnder;
fadToiletPaperUnderSlash = fadToiletPaperUnderSlash;
fadToiletPortable = fadToiletPortable;
fadToiletsPortable = fadToiletsPortable;
fadTomato = fadTomato;
fadTombstone = fadTombstone;
fadTombstoneAlt = fadTombstoneAlt;
fadTombstoneBlank = fadTombstoneBlank;
fadToolbox = fadToolbox;
fadTools = fadTools;
fadTooth = fadTooth;
fadToothbrush = fadToothbrush;
fadTorah = fadTorah;
fadToriiGate = fadToriiGate;
fadTornado = fadTornado;
fadTowerBroadcast = fadTowerBroadcast;
fadTowerCell = fadTowerCell;
fadTowerControl = fadTowerControl;
fadTowerObservation = fadTowerObservation;
fadTractor = fadTractor;
fadTrademark = fadTrademark;
fadTrafficCone = fadTrafficCone;
fadTrafficLight = fadTrafficLight;
fadTrafficLightGo = fadTrafficLightGo;
fadTrafficLightSlow = fadTrafficLightSlow;
fadTrafficLightStop = fadTrafficLightStop;
fadTrailer = fadTrailer;
fadTrain = fadTrain;
fadTrainSubway = fadTrainSubway;
fadTrainSubwayTunnel = fadTrainSubwayTunnel;
fadTrainTrack = fadTrainTrack;
fadTrainTram = fadTrainTram;
fadTrainTunnel = fadTrainTunnel;
fadTram = fadTram;
fadTransformerBolt = fadTransformerBolt;
fadTransgender = fadTransgender;
fadTransgenderAlt = fadTransgenderAlt;
fadTransporter = fadTransporter;
fadTransporter1 = fadTransporter1;
fadTransporter2 = fadTransporter2;
fadTransporter3 = fadTransporter3;
fadTransporter4 = fadTransporter4;
fadTransporter5 = fadTransporter5;
fadTransporter6 = fadTransporter6;
fadTransporter7 = fadTransporter7;
fadTransporterEmpty = fadTransporterEmpty;
fadTrash = fadTrash;
fadTrashAlt = fadTrashAlt;
fadTrashAltSlash = fadTrashAltSlash;
fadTrashArrowTurnLeft = fadTrashArrowTurnLeft;
fadTrashArrowUp = fadTrashArrowUp;
fadTrashCan = fadTrashCan;
fadTrashCanArrowTurnLeft = fadTrashCanArrowTurnLeft;
fadTrashCanArrowUp = fadTrashCanArrowUp;
fadTrashCanCheck = fadTrashCanCheck;
fadTrashCanClock = fadTrashCanClock;
fadTrashCanList = fadTrashCanList;
fadTrashCanPlus = fadTrashCanPlus;
fadTrashCanSlash = fadTrashCanSlash;
fadTrashCanUndo = fadTrashCanUndo;
fadTrashCanXmark = fadTrashCanXmark;
fadTrashCheck = fadTrashCheck;
fadTrashCircle = fadTrashCircle;
fadTrashClock = fadTrashClock;
fadTrashList = fadTrashList;
fadTrashPlus = fadTrashPlus;
fadTrashRestore = fadTrashRestore;
fadTrashRestoreAlt = fadTrashRestoreAlt;
fadTrashSlash = fadTrashSlash;
fadTrashUndo = fadTrashUndo;
fadTrashUndoAlt = fadTrashUndoAlt;
fadTrashXmark = fadTrashXmark;
fadTreasureChest = fadTreasureChest;
fadTree = fadTree;
fadTreeAlt = fadTreeAlt;
fadTreeChristmas = fadTreeChristmas;
fadTreeCity = fadTreeCity;
fadTreeDeciduous = fadTreeDeciduous;
fadTreeDecorated = fadTreeDecorated;
fadTreeLarge = fadTreeLarge;
fadTreePalm = fadTreePalm;
fadTrees = fadTrees;
fadTrianBalbot = fadTrianBalbot;
fadTriangle = fadTriangle;
fadTriangleCircleSquare = fadTriangleCircleSquare;
fadTriangleExclamation = fadTriangleExclamation;
fadTriangleInstrument = fadTriangleInstrument;
fadTriangleMusic = fadTriangleMusic;
fadTrianglePersonDigging = fadTrianglePersonDigging;
fadTrillium = fadTrillium;
fadTrophy = fadTrophy;
fadTrophyAlt = fadTrophyAlt;
fadTrophyStar = fadTrophyStar;
fadTrowel = fadTrowel;
fadTrowelBricks = fadTrowelBricks;
fadTruck = fadTruck;
fadTruckArrowRight = fadTruckArrowRight;
fadTruckBolt = fadTruckBolt;
fadTruckClock = fadTruckClock;
fadTruckContainer = fadTruckContainer;
fadTruckContainerEmpty = fadTruckContainerEmpty;
fadTruckCouch = fadTruckCouch;
fadTruckDroplet = fadTruckDroplet;
fadTruckFast = fadTruckFast;
fadTruckField = fadTruckField;
fadTruckFieldUn = fadTruckFieldUn;
fadTruckFlatbed = fadTruckFlatbed;
fadTruckFront = fadTruckFront;
fadTruckLoading = fadTruckLoading;
fadTruckMedical = fadTruckMedical;
fadTruckMonster = fadTruckMonster;
fadTruckMoving = fadTruckMoving;
fadTruckPickup = fadTruckPickup;
fadTruckPlane = fadTruckPlane;
fadTruckPlow = fadTruckPlow;
fadTruckRamp = fadTruckRamp;
fadTruckRampBox = fadTruckRampBox;
fadTruckRampCouch = fadTruckRampCouch;
fadTruckTow = fadTruckTow;
fadTrumpet = fadTrumpet;
fadTry = fadTry;
fadTshirt = fadTshirt;
fadTShirt = fadTShirt;
fadTty = fadTty;
fadTtyAnswer = fadTtyAnswer;
fadTugrikSign = fadTugrikSign;
fadTurkey = fadTurkey;
fadTurkishLira = fadTurkishLira;
fadTurkishLiraSign = fadTurkishLiraSign;
fadTurnDown = fadTurnDown;
fadTurnDownLeft = fadTurnDownLeft;
fadTurnDownRight = fadTurnDownRight;
fadTurntable = fadTurntable;
fadTurnUp = fadTurnUp;
fadTurtle = fadTurtle;
fadTv = fadTv;
fadTvAlt = fadTvAlt;
fadTvMusic = fadTvMusic;
fadTvRetro = fadTvRetro;
fadTypewriter = fadTypewriter;
fadU = fadU;
fadUfo = fadUfo;
fadUfoBeam = fadUfoBeam;
fadUmbrella = fadUmbrella;
fadUmbrellaAlt = fadUmbrellaAlt;
fadUmbrellaBeach = fadUmbrellaBeach;
fadUmbrellaSimple = fadUmbrellaSimple;
fadUnderline = fadUnderline;
fadUndo = fadUndo;
fadUndoAlt = fadUndoAlt;
fadUnicorn = fadUnicorn;
fadUniformMartialArts = fadUniformMartialArts;
fadUnion = fadUnion;
fadUniversalAccess = fadUniversalAccess;
fadUniversity = fadUniversity;
fadUnlink = fadUnlink;
fadUnlock = fadUnlock;
fadUnlockAlt = fadUnlockAlt;
fadUnlockKeyhole = fadUnlockKeyhole;
fadUnsorted = fadUnsorted;
fadUp = fadUp;
fadUpDown = fadUpDown;
fadUpDownLeftRight = fadUpDownLeftRight;
fadUpFromBracket = fadUpFromBracket;
fadUpFromDottedLine = fadUpFromDottedLine;
fadUpFromLine = fadUpFromLine;
fadUpLeft = fadUpLeft;
fadUpload = fadUpload;
fadUpLong = fadUpLong;
fadUpRight = fadUpRight;
fadUpRightAndDownLeftFromCenter = fadUpRightAndDownLeftFromCenter;
fadUpRightFromSquare = fadUpRightFromSquare;
fadUpToDottedLine = fadUpToDottedLine;
fadUpToLine = fadUpToLine;
fadUsbDrive = fadUsbDrive;
fadUsd = fadUsd;
fadUsdCircle = fadUsdCircle;
fadUsdSquare = fadUsdSquare;
fadUser = fadUser;
fadUserAlien = fadUserAlien;
fadUserAlt = fadUserAlt;
fadUserAltSlash = fadUserAltSlash;
fadUserAstronaut = fadUserAstronaut;
fadUserBountyHunter = fadUserBountyHunter;
fadUserChart = fadUserChart;
fadUserCheck = fadUserCheck;
fadUserChef = fadUserChef;
fadUserCircle = fadUserCircle;
fadUserClock = fadUserClock;
fadUserCog = fadUserCog;
fadUserConstruction = fadUserConstruction;
fadUserCowboy = fadUserCowboy;
fadUserCrown = fadUserCrown;
fadUserDoctor = fadUserDoctor;
fadUserDoctorHair = fadUserDoctorHair;
fadUserDoctorHairLong = fadUserDoctorHairLong;
fadUserDoctorMessage = fadUserDoctorMessage;
fadUserEdit = fadUserEdit;
fadUserFriends = fadUserFriends;
fadUserGear = fadUserGear;
fadUserGraduate = fadUserGraduate;
fadUserGroup = fadUserGroup;
fadUserGroupCrown = fadUserGroupCrown;
fadUserHair = fadUserHair;
fadUserHairBuns = fadUserHairBuns;
fadUserHairLong = fadUserHairLong;
fadUserHairMullet = fadUserHairMullet;
fadUserHardHat = fadUserHardHat;
fadUserHeadset = fadUserHeadset;
fadUserHelmetSafety = fadUserHelmetSafety;
fadUserInjured = fadUserInjured;
fadUserLarge = fadUserLarge;
fadUserLargeSlash = fadUserLargeSlash;
fadUserLock = fadUserLock;
fadUserMd = fadUserMd;
fadUserMdChat = fadUserMdChat;
fadUserMinus = fadUserMinus;
fadUserMusic = fadUserMusic;
fadUserNinja = fadUserNinja;
fadUserNurse = fadUserNurse;
fadUserNurseHair = fadUserNurseHair;
fadUserNurseHairLong = fadUserNurseHairLong;
fadUserPen = fadUserPen;
fadUserPilot = fadUserPilot;
fadUserPilotTie = fadUserPilotTie;
fadUserPlus = fadUserPlus;
fadUserPolice = fadUserPolice;
fadUserPoliceTie = fadUserPoliceTie;
fadUserRobot = fadUserRobot;
fadUserRobotXmarks = fadUserRobotXmarks;
fadUsers = fadUsers;
fadUsersBetweenLines = fadUsersBetweenLines;
fadUsersClass = fadUsersClass;
fadUsersCog = fadUsersCog;
fadUsersCrown = fadUsersCrown;
fadUserSecret = fadUserSecret;
fadUsersGear = fadUsersGear;
fadUserShakespeare = fadUserShakespeare;
fadUserShield = fadUserShield;
fadUserSlash = fadUserSlash;
fadUsersLine = fadUsersLine;
fadUsersMedical = fadUsersMedical;
fadUsersRays = fadUsersRays;
fadUsersRectangle = fadUsersRectangle;
fadUsersSlash = fadUsersSlash;
fadUsersViewfinder = fadUsersViewfinder;
fadUserTag = fadUserTag;
fadUserTie = fadUserTie;
fadUserTieHair = fadUserTieHair;
fadUserTieHairLong = fadUserTieHairLong;
fadUserTimes = fadUserTimes;
fadUserUnlock = fadUserUnlock;
fadUserVisor = fadUserVisor;
fadUserVneck = fadUserVneck;
fadUserVneckHair = fadUserVneckHair;
fadUserVneckHairLong = fadUserVneckHairLong;
fadUserXmark = fadUserXmark;
fadUtensilFork = fadUtensilFork;
fadUtensilKnife = fadUtensilKnife;
fadUtensils = fadUtensils;
fadUtensilsAlt = fadUtensilsAlt;
fadUtensilSpoon = fadUtensilSpoon;
fadUtensilsSlash = fadUtensilsSlash;
fadUtilityPole = fadUtilityPole;
fadUtilityPoleDouble = fadUtilityPoleDouble;
fadV = fadV;
fadVacuum = fadVacuum;
fadVacuumRobot = fadVacuumRobot;
fadValueAbsolute = fadValueAbsolute;
fadVanShuttle = fadVanShuttle;
fadVault = fadVault;
fadVcard = fadVcard;
fadVectorCircle = fadVectorCircle;
fadVectorPolygon = fadVectorPolygon;
fadVectorSquare = fadVectorSquare;
fadVentDamper = fadVentDamper;
fadVenus = fadVenus;
fadVenusDouble = fadVenusDouble;
fadVenusMars = fadVenusMars;
fadVest = fadVest;
fadVestPatches = fadVestPatches;
fadVhs = fadVhs;
fadVial = fadVial;
fadVialCircleCheck = fadVialCircleCheck;
fadVials = fadVials;
fadVialVirus = fadVialVirus;
fadVideo = fadVideo;
fadVideoArrowDownLeft = fadVideoArrowDownLeft;
fadVideoArrowUpRight = fadVideoArrowUpRight;
fadVideoCamera = fadVideoCamera;
fadVideoCircle = fadVideoCircle;
fadVideoHandheld = fadVideoHandheld;
fadVideoPlus = fadVideoPlus;
fadVideoSlash = fadVideoSlash;
fadVihara = fadVihara;
fadViolin = fadViolin;
fadVirus = fadVirus;
fadVirusCovid = fadVirusCovid;
fadVirusCovidSlash = fadVirusCovidSlash;
fadViruses = fadViruses;
fadVirusSlash = fadVirusSlash;
fadVoicemail = fadVoicemail;
fadVolcano = fadVolcano;
fadVolleyball = fadVolleyball;
fadVolleyballBall = fadVolleyballBall;
fadVolume = fadVolume;
fadVolumeControlPhone = fadVolumeControlPhone;
fadVolumeDown = fadVolumeDown;
fadVolumeHigh = fadVolumeHigh;
fadVolumeLow = fadVolumeLow;
fadVolumeMedium = fadVolumeMedium;
fadVolumeMute = fadVolumeMute;
fadVolumeOff = fadVolumeOff;
fadVolumeSlash = fadVolumeSlash;
fadVolumeTimes = fadVolumeTimes;
fadVolumeUp = fadVolumeUp;
fadVolumeXmark = fadVolumeXmark;
fadVoteNay = fadVoteNay;
fadVoteYea = fadVoteYea;
fadVrCardboard = fadVrCardboard;
fadW = fadW;
fadWaffle = fadWaffle;
fadWagonCovered = fadWagonCovered;
fadWalker = fadWalker;
fadWalkieTalkie = fadWalkieTalkie;
fadWalking = fadWalking;
fadWallBrick = fadWallBrick;
fadWallet = fadWallet;
fadWand = fadWand;
fadWandMagic = fadWandMagic;
fadWandMagicSparkles = fadWandMagicSparkles;
fadWandSparkles = fadWandSparkles;
fadWarehouse = fadWarehouse;
fadWarehouseAlt = fadWarehouseAlt;
fadWarehouseFull = fadWarehouseFull;
fadWarning = fadWarning;
fadWasher = fadWasher;
fadWashingMachine = fadWashingMachine;
fadWatch = fadWatch;
fadWatchApple = fadWatchApple;
fadWatchCalculator = fadWatchCalculator;
fadWatchFitness = fadWatchFitness;
fadWatchSmart = fadWatchSmart;
fadWater = fadWater;
fadWaterArrowDown = fadWaterArrowDown;
fadWaterArrowUp = fadWaterArrowUp;
fadWaterLadder = fadWaterLadder;
fadWaterLower = fadWaterLower;
fadWatermelonSlice = fadWatermelonSlice;
fadWaterRise = fadWaterRise;
fadWaveform = fadWaveform;
fadWaveformCircle = fadWaveformCircle;
fadWaveformLines = fadWaveformLines;
fadWaveformPath = fadWaveformPath;
fadWavePulse = fadWavePulse;
fadWaveSine = fadWaveSine;
fadWaveSquare = fadWaveSquare;
fadWaveTriangle = fadWaveTriangle;
fadWebcam = fadWebcam;
fadWebcamSlash = fadWebcamSlash;
fadWeight = fadWeight;
fadWeightHanging = fadWeightHanging;
fadWeightScale = fadWeightScale;
fadWhale = fadWhale;
fadWheat = fadWheat;
fadWheatAlt = fadWheatAlt;
fadWheatAwn = fadWheatAwn;
fadWheatAwnCircleExclamation = fadWheatAwnCircleExclamation;
fadWheatAwnSlash = fadWheatAwnSlash;
fadWheatSlash = fadWheatSlash;
fadWheelchair = fadWheelchair;
fadWheelchairAlt = fadWheelchairAlt;
fadWheelchairMove = fadWheelchairMove;
fadWhiskeyGlass = fadWhiskeyGlass;
fadWhiskeyGlassIce = fadWhiskeyGlassIce;
fadWhistle = fadWhistle;
fadWifi = fadWifi;
fadWifi1 = fadWifi1;
fadWifi2 = fadWifi2;
fadWifi3 = fadWifi3;
fadWifiExclamation = fadWifiExclamation;
fadWifiFair = fadWifiFair;
fadWifiSlash = fadWifiSlash;
fadWifiStrong = fadWifiStrong;
fadWifiWeak = fadWifiWeak;
fadWind = fadWind;
fadWindCircleExclamation = fadWindCircleExclamation;
fadWindow = fadWindow;
fadWindowAlt = fadWindowAlt;
fadWindowClose = fadWindowClose;
fadWindowFlip = fadWindowFlip;
fadWindowFrame = fadWindowFrame;
fadWindowFrameOpen = fadWindowFrameOpen;
fadWindowMaximize = fadWindowMaximize;
fadWindowMinimize = fadWindowMinimize;
fadWindowRestore = fadWindowRestore;
fadWindsock = fadWindsock;
fadWindTurbine = fadWindTurbine;
fadWindWarning = fadWindWarning;
fadWineBottle = fadWineBottle;
fadWineGlass = fadWineGlass;
fadWineGlassAlt = fadWineGlassAlt;
fadWineGlassCrack = fadWineGlassCrack;
fadWineGlassEmpty = fadWineGlassEmpty;
fadWon = fadWon;
fadWonSign = fadWonSign;
fadWorm = fadWorm;
fadWreath = fadWreath;
fadWrench = fadWrench;
fadWrenchSimple = fadWrenchSimple;
fadX = fadX;
fadXmark = fadXmark;
fadXmarkCircle = fadXmarkCircle;
fadXmarkHexagon = fadXmarkHexagon;
fadXmarkLarge = fadXmarkLarge;
fadXmarkOctagon = fadXmarkOctagon;
fadXmarksLines = fadXmarksLines;
fadXmarkSquare = fadXmarkSquare;
fadXmarkToSlot = fadXmarkToSlot;
fadXRay = fadXRay;
fadY = fadY;
fadYen = fadYen;
fadYenSign = fadYenSign;
fadYinYang = fadYinYang;
fadZ = fadZ;
fadZap = fadZap;
fadZzz = fadZzz;
fal0 = fal0;
fal00 = fal00;
fal1 = fal1;
fal100 = fal100;
fal2 = fal2;
fal3 = fal3;
fal360Degrees = fal360Degrees;
fal4 = fal4;
fal5 = fal5;
fal6 = fal6;
fal7 = fal7;
fal8 = fal8;
fal9 = fal9;
falA = falA;
falAbacus = falAbacus;
falAccentGrave = falAccentGrave;
falAcorn = falAcorn;
falAd = falAd;
falAdd = falAdd;
falAddressBook = falAddressBook;
falAddressCard = falAddressCard;
falAdjust = falAdjust;
falAirConditioner = falAirConditioner;
falAirFreshener = falAirFreshener;
falAirplay = falAirplay;
falAlarmClock = falAlarmClock;
falAlarmExclamation = falAlarmExclamation;
falAlarmPlus = falAlarmPlus;
falAlarmSnooze = falAlarmSnooze;
falAlbum = falAlbum;
falAlbumCirclePlus = falAlbumCirclePlus;
falAlbumCircleUser = falAlbumCircleUser;
falAlbumCollection = falAlbumCollection;
falAlbumCollectionCirclePlus = falAlbumCollectionCirclePlus;
falAlbumCollectionCircleUser = falAlbumCollectionCircleUser;
falAlicorn = falAlicorn;
falAlien = falAlien;
falAlien8bit = falAlien8bit;
falAlienMonster = falAlienMonster;
falAlignCenter = falAlignCenter;
falAlignJustify = falAlignJustify;
falAlignLeft = falAlignLeft;
falAlignRight = falAlignRight;
falAlignSlash = falAlignSlash;
falAllergies = falAllergies;
falAlt = falAlt;
falAmbulance = falAmbulance;
falAmericanSignLanguageInterpreting = falAmericanSignLanguageInterpreting;
falAmpersand = falAmpersand;
falAmpGuitar = falAmpGuitar;
falAnalytics = falAnalytics;
falAnchor = falAnchor;
falAnchorCircleCheck = falAnchorCircleCheck;
falAnchorCircleExclamation = falAnchorCircleExclamation;
falAnchorCircleXmark = falAnchorCircleXmark;
falAnchorLock = falAnchorLock;
falAngel = falAngel;
falAngle = falAngle;
falAngle90 = falAngle90;
falAngleDoubleDown = falAngleDoubleDown;
falAngleDoubleLeft = falAngleDoubleLeft;
falAngleDoubleRight = falAngleDoubleRight;
falAngleDoubleUp = falAngleDoubleUp;
falAngleDown = falAngleDown;
falAngleLeft = falAngleLeft;
falAngleRight = falAngleRight;
falAnglesDown = falAnglesDown;
falAnglesLeft = falAnglesLeft;
falAnglesRight = falAnglesRight;
falAnglesUp = falAnglesUp;
falAngleUp = falAngleUp;
falAngry = falAngry;
falAnkh = falAnkh;
falApartment = falApartment;
falAperture = falAperture;
falApostrophe = falApostrophe;
falAppleAlt = falAppleAlt;
falAppleCore = falAppleCore;
falAppleCrate = falAppleCrate;
falAppleWhole = falAppleWhole;
falArchive = falArchive;
falArchway = falArchway;
falAreaChart = falAreaChart;
falArrowAltCircleDown = falArrowAltCircleDown;
falArrowAltCircleLeft = falArrowAltCircleLeft;
falArrowAltCircleRight = falArrowAltCircleRight;
falArrowAltCircleUp = falArrowAltCircleUp;
falArrowAltDown = falArrowAltDown;
falArrowAltFromBottom = falArrowAltFromBottom;
falArrowAltFromLeft = falArrowAltFromLeft;
falArrowAltFromRight = falArrowAltFromRight;
falArrowAltFromTop = falArrowAltFromTop;
falArrowAltLeft = falArrowAltLeft;
falArrowAltRight = falArrowAltRight;
falArrowAltSquareDown = falArrowAltSquareDown;
falArrowAltSquareLeft = falArrowAltSquareLeft;
falArrowAltSquareRight = falArrowAltSquareRight;
falArrowAltSquareUp = falArrowAltSquareUp;
falArrowAltToBottom = falArrowAltToBottom;
falArrowAltToLeft = falArrowAltToLeft;
falArrowAltToRight = falArrowAltToRight;
falArrowAltToTop = falArrowAltToTop;
falArrowAltUp = falArrowAltUp;
falArrowCircleDown = falArrowCircleDown;
falArrowCircleLeft = falArrowCircleLeft;
falArrowCircleRight = falArrowCircleRight;
falArrowCircleUp = falArrowCircleUp;
falArrowDown = falArrowDown;
falArrowDown19 = falArrowDown19;
falArrowDown91 = falArrowDown91;
falArrowDownArrowUp = falArrowDownArrowUp;
falArrowDownAZ = falArrowDownAZ;
falArrowDownBigSmall = falArrowDownBigSmall;
falArrowDownFromDottedLine = falArrowDownFromDottedLine;
falArrowDownFromLine = falArrowDownFromLine;
falArrowDownLeft = falArrowDownLeft;
falArrowDownLeftAndArrowUpRightToCenter = falArrowDownLeftAndArrowUpRightToCenter;
falArrowDownLong = falArrowDownLong;
falArrowDownRight = falArrowDownRight;
falArrowDownShortWide = falArrowDownShortWide;
falArrowDownSmallBig = falArrowDownSmallBig;
falArrowDownSquareTriangle = falArrowDownSquareTriangle;
falArrowDownToArc = falArrowDownToArc;
falArrowDownToBracket = falArrowDownToBracket;
falArrowDownToDottedLine = falArrowDownToDottedLine;
falArrowDownToLine = falArrowDownToLine;
falArrowDownToSquare = falArrowDownToSquare;
falArrowDownTriangleSquare = falArrowDownTriangleSquare;
falArrowDownUpAcrossLine = falArrowDownUpAcrossLine;
falArrowDownUpLock = falArrowDownUpLock;
falArrowDownWideShort = falArrowDownWideShort;
falArrowDownZA = falArrowDownZA;
falArrowFromBottom = falArrowFromBottom;
falArrowFromLeft = falArrowFromLeft;
falArrowFromRight = falArrowFromRight;
falArrowFromTop = falArrowFromTop;
falArrowLeft = falArrowLeft;
falArrowLeftFromLine = falArrowLeftFromLine;
falArrowLeftLong = falArrowLeftLong;
falArrowLeftLongToLine = falArrowLeftLongToLine;
falArrowLeftRotate = falArrowLeftRotate;
falArrowLeftToLine = falArrowLeftToLine;
falArrowPointer = falArrowPointer;
falArrowRight = falArrowRight;
falArrowRightArrowLeft = falArrowRightArrowLeft;
falArrowRightFromArc = falArrowRightFromArc;
falArrowRightFromBracket = falArrowRightFromBracket;
falArrowRightFromFile = falArrowRightFromFile;
falArrowRightFromLine = falArrowRightFromLine;
falArrowRightLong = falArrowRightLong;
falArrowRightLongToLine = falArrowRightLongToLine;
falArrowRightRotate = falArrowRightRotate;
falArrowRightToArc = falArrowRightToArc;
falArrowRightToBracket = falArrowRightToBracket;
falArrowRightToCity = falArrowRightToCity;
falArrowRightToFile = falArrowRightToFile;
falArrowRightToLine = falArrowRightToLine;
falArrowRotateBack = falArrowRotateBack;
falArrowRotateBackward = falArrowRotateBackward;
falArrowRotateForward = falArrowRotateForward;
falArrowRotateLeft = falArrowRotateLeft;
falArrowRotateRight = falArrowRotateRight;
falArrows = falArrows;
falArrowsAlt = falArrowsAlt;
falArrowsAltH = falArrowsAltH;
falArrowsAltV = falArrowsAltV;
falArrowsCross = falArrowsCross;
falArrowsDownToLine = falArrowsDownToLine;
falArrowsDownToPeople = falArrowsDownToPeople;
falArrowsFromDottedLine = falArrowsFromDottedLine;
falArrowsFromLine = falArrowsFromLine;
falArrowsH = falArrowsH;
falArrowsLeftRight = falArrowsLeftRight;
falArrowsLeftRightToLine = falArrowsLeftRightToLine;
falArrowsMaximize = falArrowsMaximize;
falArrowsMinimize = falArrowsMinimize;
falArrowSquareDown = falArrowSquareDown;
falArrowSquareLeft = falArrowSquareLeft;
falArrowSquareRight = falArrowSquareRight;
falArrowSquareUp = falArrowSquareUp;
falArrowsRepeat = falArrowsRepeat;
falArrowsRepeat1 = falArrowsRepeat1;
falArrowsRetweet = falArrowsRetweet;
falArrowsRotate = falArrowsRotate;
falArrowsSpin = falArrowsSpin;
falArrowsSplitUpAndLeft = falArrowsSplitUpAndLeft;
falArrowsToCircle = falArrowsToCircle;
falArrowsToDot = falArrowsToDot;
falArrowsToDottedLine = falArrowsToDottedLine;
falArrowsToEye = falArrowsToEye;
falArrowsToLine = falArrowsToLine;
falArrowsTurnRight = falArrowsTurnRight;
falArrowsTurnToDots = falArrowsTurnToDots;
falArrowsUpDown = falArrowsUpDown;
falArrowsUpDownLeftRight = falArrowsUpDownLeftRight;
falArrowsUpToLine = falArrowsUpToLine;
falArrowsV = falArrowsV;
falArrowToBottom = falArrowToBottom;
falArrowToLeft = falArrowToLeft;
falArrowToRight = falArrowToRight;
falArrowToTop = falArrowToTop;
falArrowTrendDown = falArrowTrendDown;
falArrowTrendUp = falArrowTrendUp;
falArrowTurnDown = falArrowTurnDown;
falArrowTurnDownLeft = falArrowTurnDownLeft;
falArrowTurnDownRight = falArrowTurnDownRight;
falArrowTurnRight = falArrowTurnRight;
falArrowTurnUp = falArrowTurnUp;
falArrowUp = falArrowUp;
falArrowUp19 = falArrowUp19;
falArrowUp91 = falArrowUp91;
falArrowUpArrowDown = falArrowUpArrowDown;
falArrowUpAZ = falArrowUpAZ;
falArrowUpBigSmall = falArrowUpBigSmall;
falArrowUpFromArc = falArrowUpFromArc;
falArrowUpFromBracket = falArrowUpFromBracket;
falArrowUpFromDottedLine = falArrowUpFromDottedLine;
falArrowUpFromGroundWater = falArrowUpFromGroundWater;
falArrowUpFromLine = falArrowUpFromLine;
falArrowUpFromSquare = falArrowUpFromSquare;
falArrowUpFromWaterPump = falArrowUpFromWaterPump;
falArrowUpLeft = falArrowUpLeft;
falArrowUpLeftFromCircle = falArrowUpLeftFromCircle;
falArrowUpLong = falArrowUpLong;
falArrowUpRight = falArrowUpRight;
falArrowUpRightAndArrowDownLeftFromCenter = falArrowUpRightAndArrowDownLeftFromCenter;
falArrowUpRightDots = falArrowUpRightDots;
falArrowUpRightFromSquare = falArrowUpRightFromSquare;
falArrowUpShortWide = falArrowUpShortWide;
falArrowUpSmallBig = falArrowUpSmallBig;
falArrowUpSquareTriangle = falArrowUpSquareTriangle;
falArrowUpToDottedLine = falArrowUpToDottedLine;
falArrowUpToLine = falArrowUpToLine;
falArrowUpTriangleSquare = falArrowUpTriangleSquare;
falArrowUpWideShort = falArrowUpWideShort;
falArrowUpZA = falArrowUpZA;
falAslInterpreting = falAslInterpreting;
falAssistiveListeningSystems = falAssistiveListeningSystems;
falAsterisk = falAsterisk;
falAt = falAt;
falAtlas = falAtlas;
falAtom = falAtom;
falAtomAlt = falAtomAlt;
falAtomSimple = falAtomSimple;
falAudioDescription = falAudioDescription;
falAudioDescriptionSlash = falAudioDescriptionSlash;
falAustralSign = falAustralSign;
falAutomobile = falAutomobile;
falAvocado = falAvocado;
falAward = falAward;
falAwardSimple = falAwardSimple;
falAxe = falAxe;
falAxeBattle = falAxeBattle;
falB = falB;
falBaby = falBaby;
falBabyCarriage = falBabyCarriage;
falBackpack = falBackpack;
falBackspace = falBackspace;
falBackward = falBackward;
falBackwardFast = falBackwardFast;
falBackwardStep = falBackwardStep;
falBacon = falBacon;
falBacteria = falBacteria;
falBacterium = falBacterium;
falBadge = falBadge;
falBadgeCheck = falBadgeCheck;
falBadgeDollar = falBadgeDollar;
falBadgePercent = falBadgePercent;
falBadgerHoney = falBadgerHoney;
falBadgeSheriff = falBadgeSheriff;
falBadminton = falBadminton;
falBagel = falBagel;
falBagShopping = falBagShopping;
falBagsShopping = falBagsShopping;
falBaguette = falBaguette;
falBahai = falBahai;
falBahtSign = falBahtSign;
falBalanceScale = falBalanceScale;
falBalanceScaleLeft = falBalanceScaleLeft;
falBalanceScaleRight = falBalanceScaleRight;
falBalloon = falBalloon;
falBalloons = falBalloons;
falBallot = falBallot;
falBallotCheck = falBallotCheck;
falBallPile = falBallPile;
falBan = falBan;
falBanana = falBanana;
falBanBug = falBanBug;
falBandage = falBandage;
falBandAid = falBandAid;
falBangladeshiTakaSign = falBangladeshiTakaSign;
falBanjo = falBanjo;
falBank = falBank;
falBanParking = falBanParking;
falBanSmoking = falBanSmoking;
falBarChart = falBarChart;
falBarcode = falBarcode;
falBarcodeAlt = falBarcodeAlt;
falBarcodeRead = falBarcodeRead;
falBarcodeScan = falBarcodeScan;
falBarnSilo = falBarnSilo;
falBars = falBars;
falBarsFilter = falBarsFilter;
falBarsProgress = falBarsProgress;
falBarsSort = falBarsSort;
falBarsStaggered = falBarsStaggered;
falBaseball = falBaseball;
falBaseballBall = falBaseballBall;
falBaseballBatBall = falBaseballBatBall;
falBasketball = falBasketball;
falBasketballBall = falBasketballBall;
falBasketballHoop = falBasketballHoop;
falBasketShopping = falBasketShopping;
falBasketShoppingSimple = falBasketShoppingSimple;
falBat = falBat;
falBath = falBath;
falBathtub = falBathtub;
falBattery = falBattery;
falBattery0 = falBattery0;
falBattery1 = falBattery1;
falBattery2 = falBattery2;
falBattery3 = falBattery3;
falBattery4 = falBattery4;
falBattery5 = falBattery5;
falBatteryBolt = falBatteryBolt;
falBatteryCar = falBatteryCar;
falBatteryEmpty = falBatteryEmpty;
falBatteryExclamation = falBatteryExclamation;
falBatteryFull = falBatteryFull;
falBatteryHalf = falBatteryHalf;
falBatteryLow = falBatteryLow;
falBatteryQuarter = falBatteryQuarter;
falBatterySlash = falBatterySlash;
falBatteryThreeQuarters = falBatteryThreeQuarters;
falBed = falBed;
falBedAlt = falBedAlt;
falBedBunk = falBedBunk;
falBedEmpty = falBedEmpty;
falBedFront = falBedFront;
falBedPulse = falBedPulse;
falBee = falBee;
falBeer = falBeer;
falBeerFoam = falBeerFoam;
falBeerMug = falBeerMug;
falBeerMugEmpty = falBeerMugEmpty;
falBell = falBell;
falBellConcierge = falBellConcierge;
falBellExclamation = falBellExclamation;
falBellOn = falBellOn;
falBellPlus = falBellPlus;
falBells = falBells;
falBellSchool = falBellSchool;
falBellSchoolSlash = falBellSchoolSlash;
falBellSlash = falBellSlash;
falBenchTree = falBenchTree;
falBetamax = falBetamax;
falBezierCurve = falBezierCurve;
falBible = falBible;
falBicycle = falBicycle;
falBiking = falBiking;
falBikingMountain = falBikingMountain;
falBinary = falBinary;
falBinaryCircleCheck = falBinaryCircleCheck;
falBinaryLock = falBinaryLock;
falBinarySlash = falBinarySlash;
falBinoculars = falBinoculars;
falBiohazard = falBiohazard;
falBird = falBird;
falBirthdayCake = falBirthdayCake;
falBitcoinSign = falBitcoinSign;
falBlackboard = falBlackboard;
falBlanket = falBlanket;
falBlanketFire = falBlanketFire;
falBlender = falBlender;
falBlenderPhone = falBlenderPhone;
falBlind = falBlind;
falBlinds = falBlinds;
falBlindsOpen = falBlindsOpen;
falBlindsRaised = falBlindsRaised;
falBlock = falBlock;
falBlockBrick = falBlockBrick;
falBlockBrickFire = falBlockBrickFire;
falBlockQuestion = falBlockQuestion;
falBlockQuote = falBlockQuote;
falBlog = falBlog;
falBlueberries = falBlueberries;
falBluetooth = falBluetooth;
falBold = falBold;
falBolt = falBolt;
falBoltAuto = falBoltAuto;
falBoltLightning = falBoltLightning;
falBoltSlash = falBoltSlash;
falBomb = falBomb;
falBone = falBone;
falBoneBreak = falBoneBreak;
falBong = falBong;
falBook = falBook;
falBookAlt = falBookAlt;
falBookArrowRight = falBookArrowRight;
falBookArrowUp = falBookArrowUp;
falBookAtlas = falBookAtlas;
falBookBible = falBookBible;
falBookBlank = falBookBlank;
falBookBookmark = falBookBookmark;
falBookCircle = falBookCircle;
falBookCircleArrowRight = falBookCircleArrowRight;
falBookCircleArrowUp = falBookCircleArrowUp;
falBookCopy = falBookCopy;
falBookDead = falBookDead;
falBookFont = falBookFont;
falBookHeart = falBookHeart;
falBookJournalWhills = falBookJournalWhills;
falBookLaw = falBookLaw;
falBookmark = falBookmark;
falBookmarkCircle = falBookmarkCircle;
falBookmarkSlash = falBookmarkSlash;
falBookMedical = falBookMedical;
falBookOpen = falBookOpen;
falBookOpenAlt = falBookOpenAlt;
falBookOpenCover = falBookOpenCover;
falBookOpenReader = falBookOpenReader;
falBookQuran = falBookQuran;
falBookReader = falBookReader;
falBooks = falBooks;
falBookSection = falBookSection;
falBookSkull = falBookSkull;
falBooksMedical = falBooksMedical;
falBookSparkles = falBookSparkles;
falBookSpells = falBookSpells;
falBookTanakh = falBookTanakh;
falBookUser = falBookUser;
falBoombox = falBoombox;
falBoot = falBoot;
falBoothCurtain = falBoothCurtain;
falBootHeeled = falBootHeeled;
falBorderAll = falBorderAll;
falBorderBottom = falBorderBottom;
falBorderBottomRight = falBorderBottomRight;
falBorderCenterH = falBorderCenterH;
falBorderCenterV = falBorderCenterV;
falBorderInner = falBorderInner;
falBorderLeft = falBorderLeft;
falBorderNone = falBorderNone;
falBorderOuter = falBorderOuter;
falBorderRight = falBorderRight;
falBorderStyle = falBorderStyle;
falBorderStyleAlt = falBorderStyleAlt;
falBorderTop = falBorderTop;
falBorderTopLeft = falBorderTopLeft;
falBoreHole = falBoreHole;
falBottleDroplet = falBottleDroplet;
falBottleWater = falBottleWater;
falBowArrow = falBowArrow;
falBowlChopsticks = falBowlChopsticks;
falBowlChopsticksNoodles = falBowlChopsticksNoodles;
falBowlFood = falBowlFood;
falBowlHot = falBowlHot;
falBowlingBall = falBowlingBall;
falBowlingBallPin = falBowlingBallPin;
falBowlingPins = falBowlingPins;
falBowlRice = falBowlRice;
falBowlSalad = falBowlSalad;
falBowlScoop = falBowlScoop;
falBowlScoops = falBowlScoops;
falBowlShavedIce = falBowlShavedIce;
falBowlSoftServe = falBowlSoftServe;
falBowlSpoon = falBowlSpoon;
falBox = falBox;
falBoxAlt = falBoxAlt;
falBoxArchive = falBoxArchive;
falBoxBallot = falBoxBallot;
falBoxCheck = falBoxCheck;
falBoxCircleCheck = falBoxCircleCheck;
falBoxDollar = falBoxDollar;
falBoxes = falBoxes;
falBoxesAlt = falBoxesAlt;
falBoxesPacking = falBoxesPacking;
falBoxesStacked = falBoxesStacked;
falBoxFragile = falBoxFragile;
falBoxFull = falBoxFull;
falBoxHeart = falBoxHeart;
falBoxingGlove = falBoxingGlove;
falBoxOpen = falBoxOpen;
falBoxOpenFull = falBoxOpenFull;
falBoxTaped = falBoxTaped;
falBoxTissue = falBoxTissue;
falBoxUp = falBoxUp;
falBoxUsd = falBoxUsd;
falBracket = falBracket;
falBracketCurly = falBracketCurly;
falBracketCurlyLeft = falBracketCurlyLeft;
falBracketCurlyRight = falBracketCurlyRight;
falBracketLeft = falBracketLeft;
falBracketRound = falBracketRound;
falBracketRoundRight = falBracketRoundRight;
falBrackets = falBrackets;
falBracketsCurly = falBracketsCurly;
falBracketSquare = falBracketSquare;
falBracketSquareRight = falBracketSquareRight;
falBracketsRound = falBracketsRound;
falBracketsSquare = falBracketsSquare;
falBraille = falBraille;
falBrain = falBrain;
falBrainArrowCurvedRight = falBrainArrowCurvedRight;
falBrainCircuit = falBrainCircuit;
falBrakeWarning = falBrakeWarning;
falBrazilianRealSign = falBrazilianRealSign;
falBreadLoaf = falBreadLoaf;
falBreadSlice = falBreadSlice;
falBreadSliceButter = falBreadSliceButter;
falBridge = falBridge;
falBridgeCircleCheck = falBridgeCircleCheck;
falBridgeCircleExclamation = falBridgeCircleExclamation;
falBridgeCircleXmark = falBridgeCircleXmark;
falBridgeLock = falBridgeLock;
falBridgeSuspension = falBridgeSuspension;
falBridgeWater = falBridgeWater;
falBriefcase = falBriefcase;
falBriefcaseArrowRight = falBriefcaseArrowRight;
falBriefcaseBlank = falBriefcaseBlank;
falBriefcaseClock = falBriefcaseClock;
falBriefcaseMedical = falBriefcaseMedical;
falBrightness = falBrightness;
falBrightnessLow = falBrightnessLow;
falBringForward = falBringForward;
falBringFront = falBringFront;
falBroadcastTower = falBroadcastTower;
falBroccoli = falBroccoli;
falBroom = falBroom;
falBroomBall = falBroomBall;
falBrowser = falBrowser;
falBrowsers = falBrowsers;
falBrush = falBrush;
falBucket = falBucket;
falBug = falBug;
falBugs = falBugs;
falBugSlash = falBugSlash;
falBuilding = falBuilding;
falBuildingCircleArrowRight = falBuildingCircleArrowRight;
falBuildingCircleCheck = falBuildingCircleCheck;
falBuildingCircleExclamation = falBuildingCircleExclamation;
falBuildingCircleXmark = falBuildingCircleXmark;
falBuildingColumns = falBuildingColumns;
falBuildingFlag = falBuildingFlag;
falBuildingLock = falBuildingLock;
falBuildingNgo = falBuildingNgo;
falBuildings = falBuildings;
falBuildingShield = falBuildingShield;
falBuildingUn = falBuildingUn;
falBuildingUser = falBuildingUser;
falBuildingWheat = falBuildingWheat;
falBullhorn = falBullhorn;
falBullseye = falBullseye;
falBullseyeArrow = falBullseyeArrow;
falBullseyePointer = falBullseyePointer;
falBurger = falBurger;
falBurgerCheese = falBurgerCheese;
falBurgerFries = falBurgerFries;
falBurgerGlass = falBurgerGlass;
falBurgerLettuce = falBurgerLettuce;
falBurgerSoda = falBurgerSoda;
falBurn = falBurn;
falBurrito = falBurrito;
falBurst = falBurst;
falBus = falBus;
falBusAlt = falBusAlt;
falBusinessFront = falBusinessFront;
falBusinessTime = falBusinessTime;
falBusSchool = falBusSchool;
falBusSimple = falBusSimple;
falButter = falButter;
falC = falC;
falCab = falCab;
falCabin = falCabin;
falCabinetFiling = falCabinetFiling;
falCableCar = falCableCar;
falCactus = falCactus;
falCake = falCake;
falCakeCandles = falCakeCandles;
falCakeSlice = falCakeSlice;
falCalculator = falCalculator;
falCalculatorAlt = falCalculatorAlt;
falCalculatorSimple = falCalculatorSimple;
falCalendar = falCalendar;
falCalendarAlt = falCalendarAlt;
falCalendarArrowDown = falCalendarArrowDown;
falCalendarArrowUp = falCalendarArrowUp;
falCalendarCheck = falCalendarCheck;
falCalendarCircle = falCalendarCircle;
falCalendarCircleExclamation = falCalendarCircleExclamation;
falCalendarCircleMinus = falCalendarCircleMinus;
falCalendarCirclePlus = falCalendarCirclePlus;
falCalendarCircleUser = falCalendarCircleUser;
falCalendarClock = falCalendarClock;
falCalendarDay = falCalendarDay;
falCalendarDays = falCalendarDays;
falCalendarDownload = falCalendarDownload;
falCalendarEdit = falCalendarEdit;
falCalendarExclamation = falCalendarExclamation;
falCalendarHeart = falCalendarHeart;
falCalendarImage = falCalendarImage;
falCalendarLines = falCalendarLines;
falCalendarLinesPen = falCalendarLinesPen;
falCalendarMinus = falCalendarMinus;
falCalendarNote = falCalendarNote;
falCalendarPen = falCalendarPen;
falCalendarPlus = falCalendarPlus;
falCalendarRange = falCalendarRange;
falCalendars = falCalendars;
falCalendarStar = falCalendarStar;
falCalendarTime = falCalendarTime;
falCalendarTimes = falCalendarTimes;
falCalendarUpload = falCalendarUpload;
falCalendarWeek = falCalendarWeek;
falCalendarXmark = falCalendarXmark;
falCamcorder = falCamcorder;
falCamera = falCamera;
falCameraAlt = falCameraAlt;
falCameraCctv = falCameraCctv;
falCameraCircle = falCameraCircle;
falCameraHome = falCameraHome;
falCameraMovie = falCameraMovie;
falCameraPolaroid = falCameraPolaroid;
falCameraRetro = falCameraRetro;
falCameraRotate = falCameraRotate;
falCameraSecurity = falCameraSecurity;
falCameraSlash = falCameraSlash;
falCameraViewfinder = falCameraViewfinder;
falCameraWeb = falCameraWeb;
falCameraWebSlash = falCameraWebSlash;
falCampfire = falCampfire;
falCampground = falCampground;
falCancel = falCancel;
falCandleHolder = falCandleHolder;
falCandy = falCandy;
falCandyBar = falCandyBar;
falCandyCane = falCandyCane;
falCandyCorn = falCandyCorn;
falCanFood = falCanFood;
falCannabis = falCannabis;
falCapsules = falCapsules;
falCar = falCar;
falCarAlt = falCarAlt;
falCaravan = falCaravan;
falCaravanAlt = falCaravanAlt;
falCaravanSimple = falCaravanSimple;
falCarBattery = falCarBattery;
falCarBolt = falCarBolt;
falCarBuilding = falCarBuilding;
falCarBump = falCarBump;
falCarBurst = falCarBurst;
falCarBus = falCarBus;
falCarCircleBolt = falCarCircleBolt;
falCarCrash = falCarCrash;
falCardClub = falCardClub;
falCardDiamond = falCardDiamond;
falCardHeart = falCardHeart;
falCards = falCards;
falCardsBlank = falCardsBlank;
falCardSpade = falCardSpade;
falCaretCircleDown = falCaretCircleDown;
falCaretCircleLeft = falCaretCircleLeft;
falCaretCircleRight = falCaretCircleRight;
falCaretCircleUp = falCaretCircleUp;
falCaretDown = falCaretDown;
falCaretLeft = falCaretLeft;
falCaretRight = falCaretRight;
falCaretSquareDown = falCaretSquareDown;
falCaretSquareLeft = falCaretSquareLeft;
falCaretSquareRight = falCaretSquareRight;
falCaretSquareUp = falCaretSquareUp;
falCaretUp = falCaretUp;
falCarGarage = falCarGarage;
falCarMechanic = falCarMechanic;
falCarMirrors = falCarMirrors;
falCarOn = falCarOn;
falCarRear = falCarRear;
falCarriageBaby = falCarriageBaby;
falCarrot = falCarrot;
falCars = falCars;
falCarSide = falCarSide;
falCarSideBolt = falCarSideBolt;
falCartArrowDown = falCartArrowDown;
falCartArrowUp = falCartArrowUp;
falCartCircleArrowDown = falCartCircleArrowDown;
falCartCircleArrowUp = falCartCircleArrowUp;
falCartCircleCheck = falCartCircleCheck;
falCartCircleExclamation = falCartCircleExclamation;
falCartCirclePlus = falCartCirclePlus;
falCartCircleXmark = falCartCircleXmark;
falCartFlatbed = falCartFlatbed;
falCartFlatbedBoxes = falCartFlatbedBoxes;
falCartFlatbedEmpty = falCartFlatbedEmpty;
falCartFlatbedSuitcase = falCartFlatbedSuitcase;
falCarTilt = falCarTilt;
falCartMinus = falCartMinus;
falCartPlus = falCartPlus;
falCartShopping = falCartShopping;
falCartShoppingFast = falCartShoppingFast;
falCarTunnel = falCarTunnel;
falCartXmark = falCartXmark;
falCarWash = falCarWash;
falCarWrench = falCarWrench;
falCashRegister = falCashRegister;
falCassetteBetamax = falCassetteBetamax;
falCassetteTape = falCassetteTape;
falCassetteVhs = falCassetteVhs;
falCastle = falCastle;
falCat = falCat;
falCatSpace = falCatSpace;
falCauldron = falCauldron;
falCctv = falCctv;
falCediSign = falCediSign;
falCentSign = falCentSign;
falCertificate = falCertificate;
falChain = falChain;
falChainBroken = falChainBroken;
falChainHorizontal = falChainHorizontal;
falChainHorizontalSlash = falChainHorizontalSlash;
falChainSlash = falChainSlash;
falChair = falChair;
falChairOffice = falChairOffice;
falChalkboard = falChalkboard;
falChalkboardTeacher = falChalkboardTeacher;
falChalkboardUser = falChalkboardUser;
falChampagneGlass = falChampagneGlass;
falChampagneGlasses = falChampagneGlasses;
falChargingStation = falChargingStation;
falChartArea = falChartArea;
falChartBar = falChartBar;
falChartBullet = falChartBullet;
falChartCandlestick = falChartCandlestick;
falChartColumn = falChartColumn;
falChartGantt = falChartGantt;
falChartLine = falChartLine;
falChartLineDown = falChartLineDown;
falChartLineUp = falChartLineUp;
falChartMixed = falChartMixed;
falChartNetwork = falChartNetwork;
falChartPie = falChartPie;
falChartPieAlt = falChartPieAlt;
falChartPieSimple = falChartPieSimple;
falChartPyramid = falChartPyramid;
falChartRadar = falChartRadar;
falChartScatter = falChartScatter;
falChartScatter3d = falChartScatter3d;
falChartScatterBubble = falChartScatterBubble;
falChartSimple = falChartSimple;
falChartSimpleHorizontal = falChartSimpleHorizontal;
falChartTreeMap = falChartTreeMap;
falChartUser = falChartUser;
falChartWaterfalll = falChartWaterfalll;
falCheck = falCheck;
falCheckCircle = falCheckCircle;
falCheckDouble = falCheckDouble;
falCheckSquare = falCheckSquare;
falCheckToSlot = falCheckToSlot;
falCheese = falCheese;
falCheeseburger = falCheeseburger;
falCheeseSwiss = falCheeseSwiss;
falCherries = falCherries;
falChess = falChess;
falChessBishop = falChessBishop;
falChessBishopAlt = falChessBishopAlt;
falChessBishopPiece = falChessBishopPiece;
falChessBoard = falChessBoard;
falChessClock = falChessClock;
falChessClockAlt = falChessClockAlt;
falChessClockFlip = falChessClockFlip;
falChessKing = falChessKing;
falChessKingAlt = falChessKingAlt;
falChessKingPiece = falChessKingPiece;
falChessKnight = falChessKnight;
falChessKnightAlt = falChessKnightAlt;
falChessKnightPiece = falChessKnightPiece;
falChessPawn = falChessPawn;
falChessPawnAlt = falChessPawnAlt;
falChessPawnPiece = falChessPawnPiece;
falChessQueen = falChessQueen;
falChessQueenAlt = falChessQueenAlt;
falChessQueenPiece = falChessQueenPiece;
falChessRook = falChessRook;
falChessRookAlt = falChessRookAlt;
falChessRookPiece = falChessRookPiece;
falChestnut = falChestnut;
falChevronCircleDown = falChevronCircleDown;
falChevronCircleLeft = falChevronCircleLeft;
falChevronCircleRight = falChevronCircleRight;
falChevronCircleUp = falChevronCircleUp;
falChevronDoubleDown = falChevronDoubleDown;
falChevronDoubleLeft = falChevronDoubleLeft;
falChevronDoubleRight = falChevronDoubleRight;
falChevronDoubleUp = falChevronDoubleUp;
falChevronDown = falChevronDown;
falChevronLeft = falChevronLeft;
falChevronRight = falChevronRight;
falChevronsDown = falChevronsDown;
falChevronsLeft = falChevronsLeft;
falChevronSquareDown = falChevronSquareDown;
falChevronSquareLeft = falChevronSquareLeft;
falChevronSquareRight = falChevronSquareRight;
falChevronSquareUp = falChevronSquareUp;
falChevronsRight = falChevronsRight;
falChevronsUp = falChevronsUp;
falChevronUp = falChevronUp;
falChild = falChild;
falChildren = falChildren;
falChildRifle = falChildRifle;
falChimney = falChimney;
falChocolateBar = falChocolateBar;
falChopsticks = falChopsticks;
falChurch = falChurch;
falCircle = falCircle;
falCircle0 = falCircle0;
falCircle1 = falCircle1;
falCircle2 = falCircle2;
falCircle3 = falCircle3;
falCircle4 = falCircle4;
falCircle5 = falCircle5;
falCircle6 = falCircle6;
falCircle7 = falCircle7;
falCircle8 = falCircle8;
falCircle9 = falCircle9;
falCircleA = falCircleA;
falCircleAmpersand = falCircleAmpersand;
falCircleArrowDown = falCircleArrowDown;
falCircleArrowDownLeft = falCircleArrowDownLeft;
falCircleArrowDownRight = falCircleArrowDownRight;
falCircleArrowLeft = falCircleArrowLeft;
falCircleArrowRight = falCircleArrowRight;
falCircleArrowUp = falCircleArrowUp;
falCircleArrowUpLeft = falCircleArrowUpLeft;
falCircleArrowUpRight = falCircleArrowUpRight;
falCircleB = falCircleB;
falCircleBolt = falCircleBolt;
falCircleBookmark = falCircleBookmark;
falCircleBookOpen = falCircleBookOpen;
falCircleC = falCircleC;
falCircleCalendar = falCircleCalendar;
falCircleCamera = falCircleCamera;
falCircleCaretDown = falCircleCaretDown;
falCircleCaretLeft = falCircleCaretLeft;
falCircleCaretRight = falCircleCaretRight;
falCircleCaretUp = falCircleCaretUp;
falCircleCheck = falCircleCheck;
falCircleChevronDown = falCircleChevronDown;
falCircleChevronLeft = falCircleChevronLeft;
falCircleChevronRight = falCircleChevronRight;
falCircleChevronUp = falCircleChevronUp;
falCircleD = falCircleD;
falCircleDashed = falCircleDashed;
falCircleDivide = falCircleDivide;
falCircleDollar = falCircleDollar;
falCircleDollarToSlot = falCircleDollarToSlot;
falCircleDot = falCircleDot;
falCircleDown = falCircleDown;
falCircleDownLeft = falCircleDownLeft;
falCircleDownRight = falCircleDownRight;
falCircleE = falCircleE;
falCircleEllipsis = falCircleEllipsis;
falCircleEllipsisVertical = falCircleEllipsisVertical;
falCircleEnvelope = falCircleEnvelope;
falCircleExclamation = falCircleExclamation;
falCircleExclamationCheck = falCircleExclamationCheck;
falCircleF = falCircleF;
falCircleG = falCircleG;
falCircleH = falCircleH;
falCircleHalf = falCircleHalf;
falCircleHalfStroke = falCircleHalfStroke;
falCircleHeart = falCircleHeart;
falCircleI = falCircleI;
falCircleInfo = falCircleInfo;
falCircleJ = falCircleJ;
falCircleK = falCircleK;
falCircleL = falCircleL;
falCircleLeft = falCircleLeft;
falCircleLocationArrow = falCircleLocationArrow;
falCircleM = falCircleM;
falCircleMicrophone = falCircleMicrophone;
falCircleMicrophoneLines = falCircleMicrophoneLines;
falCircleMinus = falCircleMinus;
falCircleN = falCircleN;
falCircleNodes = falCircleNodes;
falCircleNotch = falCircleNotch;
falCircleO = falCircleO;
falCircleP = falCircleP;
falCircleParking = falCircleParking;
falCirclePause = falCirclePause;
falCirclePhone = falCirclePhone;
falCirclePhoneFlip = falCirclePhoneFlip;
falCirclePhoneHangup = falCirclePhoneHangup;
falCirclePlay = falCirclePlay;
falCirclePlus = falCirclePlus;
falCircleQ = falCircleQ;
falCircleQuarter = falCircleQuarter;
falCircleQuarters = falCircleQuarters;
falCircleQuestion = falCircleQuestion;
falCircleR = falCircleR;
falCircleRadiation = falCircleRadiation;
falCircleRight = falCircleRight;
falCircleS = falCircleS;
falCircleSmall = falCircleSmall;
falCircleSort = falCircleSort;
falCircleSortDown = falCircleSortDown;
falCircleSortUp = falCircleSortUp;
falCircleStar = falCircleStar;
falCircleStop = falCircleStop;
falCircleT = falCircleT;
falCircleThreeQuarters = falCircleThreeQuarters;
falCircleTrash = falCircleTrash;
falCircleU = falCircleU;
falCircleUp = falCircleUp;
falCircleUpLeft = falCircleUpLeft;
falCircleUpRight = falCircleUpRight;
falCircleUser = falCircleUser;
falCircleV = falCircleV;
falCircleVideo = falCircleVideo;
falCircleW = falCircleW;
falCircleWaveformLines = falCircleWaveformLines;
falCircleX = falCircleX;
falCircleXmark = falCircleXmark;
falCircleY = falCircleY;
falCircleZ = falCircleZ;
falCitrus = falCitrus;
falCitrusSlice = falCitrusSlice;
falCity = falCity;
falClapperboard = falClapperboard;
falClapperboardPlay = falClapperboardPlay;
falClarinet = falClarinet;
falClawMarks = falClawMarks;
falClinicMedical = falClinicMedical;
falClipboard = falClipboard;
falClipboardCheck = falClipboardCheck;
falClipboardList = falClipboardList;
falClipboardListCheck = falClipboardListCheck;
falClipboardMedical = falClipboardMedical;
falClipboardPrescription = falClipboardPrescription;
falClipboardQuestion = falClipboardQuestion;
falClipboardUser = falClipboardUser;
falClock = falClock;
falClockDesk = falClockDesk;
falClockEight = falClockEight;
falClockEightThirty = falClockEightThirty;
falClockEleven = falClockEleven;
falClockElevenThirty = falClockElevenThirty;
falClockFive = falClockFive;
falClockFiveThirty = falClockFiveThirty;
falClockFour = falClockFour;
falClockFourThirty = falClockFourThirty;
falClockNine = falClockNine;
falClockNineThirty = falClockNineThirty;
falClockOne = falClockOne;
falClockOneThirty = falClockOneThirty;
falClockRotateLeft = falClockRotateLeft;
falClockSeven = falClockSeven;
falClockSevenThirty = falClockSevenThirty;
falClockSix = falClockSix;
falClockSixThirty = falClockSixThirty;
falClockTen = falClockTen;
falClockTenThirty = falClockTenThirty;
falClockThree = falClockThree;
falClockThreeThirty = falClockThreeThirty;
falClockTwelve = falClockTwelve;
falClockTwelveThirty = falClockTwelveThirty;
falClockTwo = falClockTwo;
falClockTwoThirty = falClockTwoThirty;
falClone = falClone;
falClose = falClose;
falClosedCaptioning = falClosedCaptioning;
falClosedCaptioningSlash = falClosedCaptioningSlash;
falClothesHanger = falClothesHanger;
falCloud = falCloud;
falCloudArrowDown = falCloudArrowDown;
falCloudArrowUp = falCloudArrowUp;
falCloudBolt = falCloudBolt;
falCloudBoltMoon = falCloudBoltMoon;
falCloudBoltSun = falCloudBoltSun;
falCloudCheck = falCloudCheck;
falCloudDownload = falCloudDownload;
falCloudDownloadAlt = falCloudDownloadAlt;
falCloudDrizzle = falCloudDrizzle;
falCloudExclamation = falCloudExclamation;
falCloudFog = falCloudFog;
falCloudHail = falCloudHail;
falCloudHailMixed = falCloudHailMixed;
falCloudMeatball = falCloudMeatball;
falCloudMinus = falCloudMinus;
falCloudMoon = falCloudMoon;
falCloudMoonRain = falCloudMoonRain;
falCloudMusic = falCloudMusic;
falCloudPlus = falCloudPlus;
falCloudQuestion = falCloudQuestion;
falCloudRain = falCloudRain;
falCloudRainbow = falCloudRainbow;
falClouds = falClouds;
falCloudShowers = falCloudShowers;
falCloudShowersHeavy = falCloudShowersHeavy;
falCloudShowersWater = falCloudShowersWater;
falCloudSlash = falCloudSlash;
falCloudSleet = falCloudSleet;
falCloudsMoon = falCloudsMoon;
falCloudSnow = falCloudSnow;
falCloudsSun = falCloudsSun;
falCloudSun = falCloudSun;
falCloudSunRain = falCloudSunRain;
falCloudUpload = falCloudUpload;
falCloudUploadAlt = falCloudUploadAlt;
falCloudWord = falCloudWord;
falCloudXmark = falCloudXmark;
falClover = falClover;
falClub = falClub;
falCny = falCny;
falCocktail = falCocktail;
falCoconut = falCoconut;
falCode = falCode;
falCodeBranch = falCodeBranch;
falCodeCommit = falCodeCommit;
falCodeCompare = falCodeCompare;
falCodeFork = falCodeFork;
falCodeMerge = falCodeMerge;
falCodePullRequest = falCodePullRequest;
falCodePullRequestClosed = falCodePullRequestClosed;
falCodePullRequestDraft = falCodePullRequestDraft;
falCodeSimple = falCodeSimple;
falCoffee = falCoffee;
falCoffeeBean = falCoffeeBean;
falCoffeeBeans = falCoffeeBeans;
falCoffeePot = falCoffeePot;
falCoffeeTogo = falCoffeeTogo;
falCoffin = falCoffin;
falCoffinCross = falCoffinCross;
falCog = falCog;
falCogs = falCogs;
falCoin = falCoin;
falCoinBlank = falCoinBlank;
falCoinFront = falCoinFront;
falCoins = falCoins;
falCoinVertical = falCoinVertical;
falColon = falColon;
falColonSign = falColonSign;
falColumns = falColumns;
falColumns3 = falColumns3;
falComet = falComet;
falComma = falComma;
falCommand = falCommand;
falComment = falComment;
falCommentAlt = falCommentAlt;
falCommentAltArrowDown = falCommentAltArrowDown;
falCommentAltArrowUp = falCommentAltArrowUp;
falCommentAltCaptions = falCommentAltCaptions;
falCommentAltCheck = falCommentAltCheck;
falCommentAltDollar = falCommentAltDollar;
falCommentAltDots = falCommentAltDots;
falCommentAltEdit = falCommentAltEdit;
falCommentAltExclamation = falCommentAltExclamation;
falCommentAltImage = falCommentAltImage;
falCommentAltLines = falCommentAltLines;
falCommentAltMedical = falCommentAltMedical;
falCommentAltMinus = falCommentAltMinus;
falCommentAltMusic = falCommentAltMusic;
falCommentAltPlus = falCommentAltPlus;
falCommentAltQuote = falCommentAltQuote;
falCommentAltSlash = falCommentAltSlash;
falCommentAltSmile = falCommentAltSmile;
falCommentAltText = falCommentAltText;
falCommentAltTimes = falCommentAltTimes;
falCommentArrowDown = falCommentArrowDown;
falCommentArrowUp = falCommentArrowUp;
falCommentArrowUpRight = falCommentArrowUpRight;
falCommentCaptions = falCommentCaptions;
falCommentCheck = falCommentCheck;
falCommentCode = falCommentCode;
falCommentDollar = falCommentDollar;
falCommentDots = falCommentDots;
falCommentEdit = falCommentEdit;
falCommentExclamation = falCommentExclamation;
falCommentImage = falCommentImage;
falCommenting = falCommenting;
falCommentLines = falCommentLines;
falCommentMedical = falCommentMedical;
falCommentMiddle = falCommentMiddle;
falCommentMiddleAlt = falCommentMiddleAlt;
falCommentMiddleTop = falCommentMiddleTop;
falCommentMiddleTopAlt = falCommentMiddleTopAlt;
falCommentMinus = falCommentMinus;
falCommentMusic = falCommentMusic;
falCommentPen = falCommentPen;
falCommentPlus = falCommentPlus;
falCommentQuestion = falCommentQuestion;
falCommentQuote = falCommentQuote;
falComments = falComments;
falCommentsAlt = falCommentsAlt;
falCommentsAltDollar = falCommentsAltDollar;
falCommentsDollar = falCommentsDollar;
falCommentSlash = falCommentSlash;
falCommentSmile = falCommentSmile;
falCommentSms = falCommentSms;
falCommentsQuestion = falCommentsQuestion;
falCommentsQuestionCheck = falCommentsQuestionCheck;
falCommentText = falCommentText;
falCommentTimes = falCommentTimes;
falCommentXmark = falCommentXmark;
falCompactDisc = falCompactDisc;
falCompass = falCompass;
falCompassDrafting = falCompassDrafting;
falCompassSlash = falCompassSlash;
falCompress = falCompress;
falCompressAlt = falCompressAlt;
falCompressArrows = falCompressArrows;
falCompressArrowsAlt = falCompressArrowsAlt;
falCompressWide = falCompressWide;
falComputer = falComputer;
falComputerClassic = falComputerClassic;
falComputerMouse = falComputerMouse;
falComputerMouseScrollwheel = falComputerMouseScrollwheel;
falComputerSpeaker = falComputerSpeaker;
falConciergeBell = falConciergeBell;
falConstruction = falConstruction;
falContactBook = falContactBook;
falContactCard = falContactCard;
falContainerStorage = falContainerStorage;
falConveyorBelt = falConveyorBelt;
falConveyorBeltAlt = falConveyorBeltAlt;
falConveyorBeltBoxes = falConveyorBeltBoxes;
falConveyorBeltEmpty = falConveyorBeltEmpty;
falCookie = falCookie;
falCookieBite = falCookieBite;
falCopy = falCopy;
falCopyright = falCopyright;
falCorn = falCorn;
falCorner = falCorner;
falCouch = falCouch;
falCouchSmall = falCouchSmall;
falCow = falCow;
falCowbell = falCowbell;
falCowbellCirclePlus = falCowbellCirclePlus;
falCowbellMore = falCowbellMore;
falCrab = falCrab;
falCrateApple = falCrateApple;
falCrateEmpty = falCrateEmpty;
falCreditCard = falCreditCard;
falCreditCardAlt = falCreditCardAlt;
falCreditCardBlank = falCreditCardBlank;
falCreditCardFront = falCreditCardFront;
falCreemee = falCreemee;
falCricket = falCricket;
falCricketBatBall = falCricketBatBall;
falCroissant = falCroissant;
falCrop = falCrop;
falCropAlt = falCropAlt;
falCropSimple = falCropSimple;
falCross = falCross;
falCrosshairs = falCrosshairs;
falCrow = falCrow;
falCrown = falCrown;
falCrutch = falCrutch;
falCrutches = falCrutches;
falCruzeiroSign = falCruzeiroSign;
falCrystalBall = falCrystalBall;
falCube = falCube;
falCubes = falCubes;
falCubesStacked = falCubesStacked;
falCucumber = falCucumber;
falCupcake = falCupcake;
falCupStraw = falCupStraw;
falCupStrawSwoosh = falCupStrawSwoosh;
falCupTogo = falCupTogo;
falCurling = falCurling;
falCurlingStone = falCurlingStone;
falCustard = falCustard;
falCut = falCut;
falCutlery = falCutlery;
falD = falD;
falDagger = falDagger;
falDash = falDash;
falDashboard = falDashboard;
falDatabase = falDatabase;
falDeaf = falDeaf;
falDeafness = falDeafness;
falDebug = falDebug;
falDedent = falDedent;
falDeer = falDeer;
falDeerRudolph = falDeerRudolph;
falDeleteLeft = falDeleteLeft;
falDeleteRight = falDeleteRight;
falDemocrat = falDemocrat;
falDesktop = falDesktop;
falDesktopAlt = falDesktopAlt;
falDesktopArrowDown = falDesktopArrowDown;
falDesktopCode = falDesktopCode;
falDesktopMedical = falDesktopMedical;
falDesktopSlash = falDesktopSlash;
falDewpoint = falDewpoint;
falDharmachakra = falDharmachakra;
falDiagnoses = falDiagnoses;
falDiagramCells = falDiagramCells;
falDiagramLeanCanvas = falDiagramLeanCanvas;
falDiagramNested = falDiagramNested;
falDiagramNext = falDiagramNext;
falDiagramPredecessor = falDiagramPredecessor;
falDiagramPrevious = falDiagramPrevious;
falDiagramProject = falDiagramProject;
falDiagramSankey = falDiagramSankey;
falDiagramSubtask = falDiagramSubtask;
falDiagramSuccessor = falDiagramSuccessor;
falDiagramVenn = falDiagramVenn;
falDial = falDial;
falDialHigh = falDialHigh;
falDialLow = falDialLow;
falDialMax = falDialMax;
falDialMed = falDialMed;
falDialMedHigh = falDialMedHigh;
falDialMedLow = falDialMedLow;
falDialMin = falDialMin;
falDialOff = falDialOff;
falDiamond = falDiamond;
falDiamondExclamation = falDiamondExclamation;
falDiamondTurnRight = falDiamondTurnRight;
falDice = falDice;
falDiceD10 = falDiceD10;
falDiceD12 = falDiceD12;
falDiceD20 = falDiceD20;
falDiceD4 = falDiceD4;
falDiceD6 = falDiceD6;
falDiceD8 = falDiceD8;
falDiceFive = falDiceFive;
falDiceFour = falDiceFour;
falDiceOne = falDiceOne;
falDiceSix = falDiceSix;
falDiceThree = falDiceThree;
falDiceTwo = falDiceTwo;
falDigging = falDigging;
falDigitalTachograph = falDigitalTachograph;
falDiploma = falDiploma;
falDirections = falDirections;
falDiscDrive = falDiscDrive;
falDisease = falDisease;
falDisplay = falDisplay;
falDisplayArrowDown = falDisplayArrowDown;
falDisplayCode = falDisplayCode;
falDisplayMedical = falDisplayMedical;
falDisplaySlash = falDisplaySlash;
falDistributeSpacingHorizontal = falDistributeSpacingHorizontal;
falDistributeSpacingVertical = falDistributeSpacingVertical;
falDitto = falDitto;
falDivide = falDivide;
falDizzy = falDizzy;
falDna = falDna;
falDog = falDog;
falDogLeashed = falDogLeashed;
falDollar = falDollar;
falDollarCircle = falDollarCircle;
falDollarSign = falDollarSign;
falDollarSquare = falDollarSquare;
falDolly = falDolly;
falDollyBox = falDollyBox;
falDollyEmpty = falDollyEmpty;
falDollyFlatbed = falDollyFlatbed;
falDollyFlatbedAlt = falDollyFlatbedAlt;
falDollyFlatbedEmpty = falDollyFlatbedEmpty;
falDolphin = falDolphin;
falDonate = falDonate;
falDongSign = falDongSign;
falDoNotEnter = falDoNotEnter;
falDonut = falDonut;
falDoorClosed = falDoorClosed;
falDoorOpen = falDoorOpen;
falDotCircle = falDotCircle;
falDoughnut = falDoughnut;
falDove = falDove;
falDown = falDown;
falDownFromDottedLine = falDownFromDottedLine;
falDownFromLine = falDownFromLine;
falDownLeft = falDownLeft;
falDownLeftAndUpRightToCenter = falDownLeftAndUpRightToCenter;
falDownload = falDownload;
falDownLong = falDownLong;
falDownRight = falDownRight;
falDownToBracket = falDownToBracket;
falDownToDottedLine = falDownToDottedLine;
falDownToLine = falDownToLine;
falDraftingCompass = falDraftingCompass;
falDragon = falDragon;
falDrawCircle = falDrawCircle;
falDrawPolygon = falDrawPolygon;
falDrawSquare = falDrawSquare;
falDreidel = falDreidel;
falDriversLicense = falDriversLicense;
falDrone = falDrone;
falDroneAlt = falDroneAlt;
falDroneFront = falDroneFront;
falDroplet = falDroplet;
falDropletDegree = falDropletDegree;
falDropletPercent = falDropletPercent;
falDropletSlash = falDropletSlash;
falDrum = falDrum;
falDrumSteelpan = falDrumSteelpan;
falDrumstick = falDrumstick;
falDrumstickBite = falDrumstickBite;
falDryer = falDryer;
falDryerAlt = falDryerAlt;
falDryerHeat = falDryerHeat;
falDuck = falDuck;
falDumbbell = falDumbbell;
falDumpster = falDumpster;
falDumpsterFire = falDumpsterFire;
falDungeon = falDungeon;
falE = falE;
falEar = falEar;
falEarDeaf = falEarDeaf;
falEarListen = falEarListen;
falEarMuffs = falEarMuffs;
falEarth = falEarth;
falEarthAfrica = falEarthAfrica;
falEarthAmerica = falEarthAmerica;
falEarthAmericas = falEarthAmericas;
falEarthAsia = falEarthAsia;
falEarthEurope = falEarthEurope;
falEarthOceania = falEarthOceania;
falEclipse = falEclipse;
falEclipseAlt = falEclipseAlt;
falEdit = falEdit;
falEgg = falEgg;
falEggFried = falEggFried;
falEggplant = falEggplant;
falEject = falEject;
falElephant = falElephant;
falElevator = falElevator;
falEllipsis = falEllipsis;
falEllipsisH = falEllipsisH;
falEllipsisHAlt = falEllipsisHAlt;
falEllipsisStroke = falEllipsisStroke;
falEllipsisStrokeVertical = falEllipsisStrokeVertical;
falEllipsisV = falEllipsisV;
falEllipsisVAlt = falEllipsisVAlt;
falEllipsisVertical = falEllipsisVertical;
falEmptySet = falEmptySet;
falEngine = falEngine;
falEngineExclamation = falEngineExclamation;
falEngineWarning = falEngineWarning;
falEnvelope = falEnvelope;
falEnvelopeBadge = falEnvelopeBadge;
falEnvelopeCircle = falEnvelopeCircle;
falEnvelopeCircleCheck = falEnvelopeCircleCheck;
falEnvelopeDot = falEnvelopeDot;
falEnvelopeOpen = falEnvelopeOpen;
falEnvelopeOpenDollar = falEnvelopeOpenDollar;
falEnvelopeOpenText = falEnvelopeOpenText;
falEnvelopes = falEnvelopes;
falEnvelopesBulk = falEnvelopesBulk;
falEnvelopeSquare = falEnvelopeSquare;
falEquals = falEquals;
falEraser = falEraser;
falEscalator = falEscalator;
falEthernet = falEthernet;
falEur = falEur;
falEuro = falEuro;
falEuroSign = falEuroSign;
falExchange = falExchange;
falExchangeAlt = falExchangeAlt;
falExclamation = falExclamation;
falExclamationCircle = falExclamationCircle;
falExclamationSquare = falExclamationSquare;
falExclamationTriangle = falExclamationTriangle;
falExpand = falExpand;
falExpandAlt = falExpandAlt;
falExpandArrows = falExpandArrows;
falExpandArrowsAlt = falExpandArrowsAlt;
falExpandWide = falExpandWide;
falExplodingHead = falExplodingHead;
falExplosion = falExplosion;
falExternalLink = falExternalLink;
falExternalLinkAlt = falExternalLinkAlt;
falExternalLinkSquare = falExternalLinkSquare;
falExternalLinkSquareAlt = falExternalLinkSquareAlt;
falEye = falEye;
falEyedropper = falEyedropper;
falEyeDropper = falEyeDropper;
falEyeDropperEmpty = falEyeDropperEmpty;
falEyeDropperFull = falEyeDropperFull;
falEyeDropperHalf = falEyeDropperHalf;
falEyeEvil = falEyeEvil;
falEyeLowVision = falEyeLowVision;
falEyes = falEyes;
falEyeSlash = falEyeSlash;
falF = falF;
falFaceAngry = falFaceAngry;
falFaceAngryHorns = falFaceAngryHorns;
falFaceAnguished = falFaceAnguished;
falFaceAnxiousSweat = falFaceAnxiousSweat;
falFaceAstonished = falFaceAstonished;
falFaceAwesome = falFaceAwesome;
falFaceBeamHandOverMouth = falFaceBeamHandOverMouth;
falFaceClouds = falFaceClouds;
falFaceConfounded = falFaceConfounded;
falFaceConfused = falFaceConfused;
falFaceCowboyHat = falFaceCowboyHat;
falFaceDiagonalMouth = falFaceDiagonalMouth;
falFaceDisappointed = falFaceDisappointed;
falFaceDisguise = falFaceDisguise;
falFaceDizzy = falFaceDizzy;
falFaceDotted = falFaceDotted;
falFaceDowncastSweat = falFaceDowncastSweat;
falFaceDrooling = falFaceDrooling;
falFaceExhaling = falFaceExhaling;
falFaceExplode = falFaceExplode;
falFaceExpressionless = falFaceExpressionless;
falFaceEyesXmarks = falFaceEyesXmarks;
falFaceFearful = falFaceFearful;
falFaceFlushed = falFaceFlushed;
falFaceFrown = falFaceFrown;
falFaceFrownOpen = falFaceFrownOpen;
falFaceFrownSlight = falFaceFrownSlight;
falFaceGlasses = falFaceGlasses;
falFaceGrimace = falFaceGrimace;
falFaceGrin = falFaceGrin;
falFaceGrinBeam = falFaceGrinBeam;
falFaceGrinBeamSweat = falFaceGrinBeamSweat;
falFaceGrinHearts = falFaceGrinHearts;
falFaceGrinSquint = falFaceGrinSquint;
falFaceGrinSquintTears = falFaceGrinSquintTears;
falFaceGrinStars = falFaceGrinStars;
falFaceGrinTears = falFaceGrinTears;
falFaceGrinTongue = falFaceGrinTongue;
falFaceGrinTongueSquint = falFaceGrinTongueSquint;
falFaceGrinTongueWink = falFaceGrinTongueWink;
falFaceGrinWide = falFaceGrinWide;
falFaceGrinWink = falFaceGrinWink;
falFaceHandOverMouth = falFaceHandOverMouth;
falFaceHandPeeking = falFaceHandPeeking;
falFaceHandYawn = falFaceHandYawn;
falFaceHeadBandage = falFaceHeadBandage;
falFaceHoldingBackTears = falFaceHoldingBackTears;
falFaceHushed = falFaceHushed;
falFaceIcicles = falFaceIcicles;
falFaceKiss = falFaceKiss;
falFaceKissBeam = falFaceKissBeam;
falFaceKissClosedEyes = falFaceKissClosedEyes;
falFaceKissWinkHeart = falFaceKissWinkHeart;
falFaceLaugh = falFaceLaugh;
falFaceLaughBeam = falFaceLaughBeam;
falFaceLaughSquint = falFaceLaughSquint;
falFaceLaughWink = falFaceLaughWink;
falFaceLying = falFaceLying;
falFaceMask = falFaceMask;
falFaceMeh = falFaceMeh;
falFaceMehBlank = falFaceMehBlank;
falFaceMelting = falFaceMelting;
falFaceMonocle = falFaceMonocle;
falFaceNauseated = falFaceNauseated;
falFaceNoseSteam = falFaceNoseSteam;
falFaceParty = falFaceParty;
falFacePensive = falFacePensive;
falFacePersevering = falFacePersevering;
falFacePleading = falFacePleading;
falFacePouting = falFacePouting;
falFaceRaisedEyebrow = falFaceRaisedEyebrow;
falFaceRelieved = falFaceRelieved;
falFaceRollingEyes = falFaceRollingEyes;
falFaceSadCry = falFaceSadCry;
falFaceSadSweat = falFaceSadSweat;
falFaceSadTear = falFaceSadTear;
falFaceSaluting = falFaceSaluting;
falFaceScream = falFaceScream;
falFaceShush = falFaceShush;
falFaceSleeping = falFaceSleeping;
falFaceSleepy = falFaceSleepy;
falFaceSmile = falFaceSmile;
falFaceSmileBeam = falFaceSmileBeam;
falFaceSmileHalo = falFaceSmileHalo;
falFaceSmileHearts = falFaceSmileHearts;
falFaceSmileHorns = falFaceSmileHorns;
falFaceSmilePlus = falFaceSmilePlus;
falFaceSmileRelaxed = falFaceSmileRelaxed;
falFaceSmileTear = falFaceSmileTear;
falFaceSmileTongue = falFaceSmileTongue;
falFaceSmileUpsideDown = falFaceSmileUpsideDown;
falFaceSmileWink = falFaceSmileWink;
falFaceSmilingHands = falFaceSmilingHands;
falFaceSmirking = falFaceSmirking;
falFaceSpiralEyes = falFaceSpiralEyes;
falFaceSunglasses = falFaceSunglasses;
falFaceSurprise = falFaceSurprise;
falFaceSwear = falFaceSwear;
falFaceThermometer = falFaceThermometer;
falFaceThinking = falFaceThinking;
falFaceTired = falFaceTired;
falFaceTissue = falFaceTissue;
falFaceTongueMoney = falFaceTongueMoney;
falFaceTongueSweat = falFaceTongueSweat;
falFaceUnamused = falFaceUnamused;
falFaceViewfinder = falFaceViewfinder;
falFaceVomit = falFaceVomit;
falFaceWeary = falFaceWeary;
falFaceWoozy = falFaceWoozy;
falFaceWorried = falFaceWorried;
falFaceZany = falFaceZany;
falFaceZipper = falFaceZipper;
falFalafel = falFalafel;
falFamily = falFamily;
falFamilyDress = falFamilyDress;
falFamilyPants = falFamilyPants;
falFan = falFan;
falFanTable = falFanTable;
falFarm = falFarm;
falFastBackward = falFastBackward;
falFastForward = falFastForward;
falFaucet = falFaucet;
falFaucetDrip = falFaucetDrip;
falFax = falFax;
falFeather = falFeather;
falFeatherAlt = falFeatherAlt;
falFeatherPointed = falFeatherPointed;
falFeed = falFeed;
falFemale = falFemale;
falFence = falFence;
falFerrisWheel = falFerrisWheel;
falFerry = falFerry;
falFieldHockey = falFieldHockey;
falFieldHockeyStickBall = falFieldHockeyStickBall;
falFighterJet = falFighterJet;
falFile = falFile;
falFileAlt = falFileAlt;
falFileArchive = falFileArchive;
falFileArrowDown = falFileArrowDown;
falFileArrowUp = falFileArrowUp;
falFileAudio = falFileAudio;
falFileAward = falFileAward;
falFileBinary = falFileBinary;
falFileCaretDown = falFileCaretDown;
falFileCaretUp = falFileCaretUp;
falFileCertificate = falFileCertificate;
falFileChartColumn = falFileChartColumn;
falFileChartLine = falFileChartLine;
falFileChartPie = falFileChartPie;
falFileCheck = falFileCheck;
falFileCircleCheck = falFileCircleCheck;
falFileCircleExclamation = falFileCircleExclamation;
falFileCircleInfo = falFileCircleInfo;
falFileCircleMinus = falFileCircleMinus;
falFileCirclePlus = falFileCirclePlus;
falFileCircleQuestion = falFileCircleQuestion;
falFileCircleXmark = falFileCircleXmark;
falFileClipboard = falFileClipboard;
falFileCode = falFileCode;
falFileContract = falFileContract;
falFileCsv = falFileCsv;
falFileDashedLine = falFileDashedLine;
falFileDownload = falFileDownload;
falFileEdit = falFileEdit;
falFileExcel = falFileExcel;
falFileExclamation = falFileExclamation;
falFileExport = falFileExport;
falFileHeart = falFileHeart;
falFileImage = falFileImage;
falFileImport = falFileImport;
falFileInvoice = falFileInvoice;
falFileInvoiceDollar = falFileInvoiceDollar;
falFileLines = falFileLines;
falFileLock = falFileLock;
falFileMagnifyingGlass = falFileMagnifyingGlass;
falFileMedical = falFileMedical;
falFileMedicalAlt = falFileMedicalAlt;
falFileMinus = falFileMinus;
falFileMusic = falFileMusic;
falFilePdf = falFilePdf;
falFilePen = falFilePen;
falFilePlus = falFilePlus;
falFilePlusMinus = falFilePlusMinus;
falFilePowerpoint = falFilePowerpoint;
falFilePrescription = falFilePrescription;
falFiles = falFiles;
falFileSearch = falFileSearch;
falFileShield = falFileShield;
falFileSignature = falFileSignature;
falFileSlash = falFileSlash;
falFilesMedical = falFilesMedical;
falFileSpreadsheet = falFileSpreadsheet;
falFileText = falFileText;
falFileTimes = falFileTimes;
falFileUpload = falFileUpload;
falFileUser = falFileUser;
falFileVideo = falFileVideo;
falFileWaveform = falFileWaveform;
falFileWord = falFileWord;
falFileXmark = falFileXmark;
falFileZipper = falFileZipper;
falFill = falFill;
falFillDrip = falFillDrip;
falFilm = falFilm;
falFilmAlt = falFilmAlt;
falFilmCanister = falFilmCanister;
falFilms = falFilms;
falFilmSimple = falFilmSimple;
falFilmSlash = falFilmSlash;
falFilter = falFilter;
falFilterCircleDollar = falFilterCircleDollar;
falFilterCircleXmark = falFilterCircleXmark;
falFilterList = falFilterList;
falFilters = falFilters;
falFilterSlash = falFilterSlash;
falFingerprint = falFingerprint;
falFire = falFire;
falFireAlt = falFireAlt;
falFireBurner = falFireBurner;
falFireExtinguisher = falFireExtinguisher;
falFireFlame = falFireFlame;
falFireFlameCurved = falFireFlameCurved;
falFireFlameSimple = falFireFlameSimple;
falFireHydrant = falFireHydrant;
falFireplace = falFireplace;
falFireSmoke = falFireSmoke;
falFirewall = falFirewall;
falFirstAid = falFirstAid;
falFish = falFish;
falFishBones = falFishBones;
falFishCooked = falFishCooked;
falFishFins = falFishFins;
falFishingRod = falFishingRod;
falFistRaised = falFistRaised;
falFlag = falFlag;
falFlagAlt = falFlagAlt;
falFlagCheckered = falFlagCheckered;
falFlagPennant = falFlagPennant;
falFlagSwallowtail = falFlagSwallowtail;
falFlagUsa = falFlagUsa;
falFlame = falFlame;
falFlashlight = falFlashlight;
falFlask = falFlask;
falFlaskPoison = falFlaskPoison;
falFlaskPotion = falFlaskPotion;
falFlaskRoundPoison = falFlaskRoundPoison;
falFlaskRoundPotion = falFlaskRoundPotion;
falFlaskVial = falFlaskVial;
falFlatbread = falFlatbread;
falFlatbreadStuffed = falFlatbreadStuffed;
falFloppyDisk = falFloppyDisk;
falFloppyDiskCircleArrowRight = falFloppyDiskCircleArrowRight;
falFloppyDiskCircleXmark = falFloppyDiskCircleXmark;
falFloppyDiskPen = falFloppyDiskPen;
falFloppyDisks = falFloppyDisks;
falFloppyDiskTimes = falFloppyDiskTimes;
falFlorinSign = falFlorinSign;
falFlower = falFlower;
falFlowerDaffodil = falFlowerDaffodil;
falFlowerTulip = falFlowerTulip;
falFlushed = falFlushed;
falFlute = falFlute;
falFluxCapacitor = falFluxCapacitor;
falFlyingDisc = falFlyingDisc;
falFog = falFog;
falFolder = falFolder;
falFolderArrowDown = falFolderArrowDown;
falFolderArrowUp = falFolderArrowUp;
falFolderBlank = falFolderBlank;
falFolderBookmark = falFolderBookmark;
falFolderClosed = falFolderClosed;
falFolderCog = falFolderCog;
falFolderDownload = falFolderDownload;
falFolderGear = falFolderGear;
falFolderGrid = falFolderGrid;
falFolderHeart = falFolderHeart;
falFolderImage = falFolderImage;
falFolderMagnifyingGlass = falFolderMagnifyingGlass;
falFolderMedical = falFolderMedical;
falFolderMinus = falFolderMinus;
falFolderMusic = falFolderMusic;
falFolderOpen = falFolderOpen;
falFolderPlus = falFolderPlus;
falFolders = falFolders;
falFolderSearch = falFolderSearch;
falFolderTimes = falFolderTimes;
falFolderTree = falFolderTree;
falFolderUpload = falFolderUpload;
falFolderUser = falFolderUser;
falFolderXmark = falFolderXmark;
falFonduePot = falFonduePot;
falFont = falFont;
falFontAwesome = falFontAwesome;
falFontAwesomeFlag = falFontAwesomeFlag;
falFontAwesomeLogoFull = falFontAwesomeLogoFull;
falFontCase = falFontCase;
falFootball = falFootball;
falFootballBall = falFootballBall;
falFootballHelmet = falFootballHelmet;
falFork = falFork;
falForkKnife = falForkKnife;
falForklift = falForklift;
falFort = falFort;
falForward = falForward;
falForwardFast = falForwardFast;
falForwardStep = falForwardStep;
falFragile = falFragile;
falFrame = falFrame;
falFrancSign = falFrancSign;
falFrenchFries = falFrenchFries;
falFrog = falFrog;
falFrostyHead = falFrostyHead;
falFrown = falFrown;
falFrownOpen = falFrownOpen;
falFunction = falFunction;
falFunnelDollar = falFunnelDollar;
falFutbol = falFutbol;
falFutbolBall = falFutbolBall;
falG = falG;
falGalaxy = falGalaxy;
falGalleryThumbnails = falGalleryThumbnails;
falGameBoard = falGameBoard;
falGameBoardAlt = falGameBoardAlt;
falGameBoardSimple = falGameBoardSimple;
falGameConsoleHandheld = falGameConsoleHandheld;
falGamepad = falGamepad;
falGamepadAlt = falGamepadAlt;
falGamepadModern = falGamepadModern;
falGarage = falGarage;
falGarageCar = falGarageCar;
falGarageOpen = falGarageOpen;
falGarlic = falGarlic;
falGasPump = falGasPump;
falGasPumpSlash = falGasPumpSlash;
falGauge = falGauge;
falGaugeCircleBolt = falGaugeCircleBolt;
falGaugeCircleMinus = falGaugeCircleMinus;
falGaugeCirclePlus = falGaugeCirclePlus;
falGaugeHigh = falGaugeHigh;
falGaugeLow = falGaugeLow;
falGaugeMax = falGaugeMax;
falGaugeMed = falGaugeMed;
falGaugeMin = falGaugeMin;
falGaugeSimple = falGaugeSimple;
falGaugeSimpleHigh = falGaugeSimpleHigh;
falGaugeSimpleLow = falGaugeSimpleLow;
falGaugeSimpleMax = falGaugeSimpleMax;
falGaugeSimpleMed = falGaugeSimpleMed;
falGaugeSimpleMin = falGaugeSimpleMin;
falGaveDandy = falGaveDandy;
falGavel = falGavel;
falGbp = falGbp;
falGear = falGear;
falGears = falGears;
falGem = falGem;
falGenderless = falGenderless;
falGhost = falGhost;
falGif = falGif;
falGift = falGift;
falGiftCard = falGiftCard;
falGifts = falGifts;
falGingerbreadMan = falGingerbreadMan;
falGlass = falGlass;
falGlassChampagne = falGlassChampagne;
falGlassCheers = falGlassCheers;
falGlassCitrus = falGlassCitrus;
falGlassEmpty = falGlassEmpty;
falGlasses = falGlasses;
falGlassesAlt = falGlassesAlt;
falGlassesRound = falGlassesRound;
falGlassHalf = falGlassHalf;
falGlassHalfEmpty = falGlassHalfEmpty;
falGlassHalfFull = falGlassHalfFull;
falGlassMartini = falGlassMartini;
falGlassMartiniAlt = falGlassMartiniAlt;
falGlassWater = falGlassWater;
falGlassWaterDroplet = falGlassWaterDroplet;
falGlassWhiskey = falGlassWhiskey;
falGlassWhiskeyRocks = falGlassWhiskeyRocks;
falGlobe = falGlobe;
falGlobeAfrica = falGlobeAfrica;
falGlobeAmericas = falGlobeAmericas;
falGlobeAsia = falGlobeAsia;
falGlobeEurope = falGlobeEurope;
falGlobeOceania = falGlobeOceania;
falGlobeSnow = falGlobeSnow;
falGlobeStand = falGlobeStand;
falGloveBoxing = falGloveBoxing;
falGoalNet = falGoalNet;
falGolfBall = falGolfBall;
falGolfBallTee = falGolfBallTee;
falGolfClub = falGolfClub;
falGolfFlagHole = falGolfFlagHole;
falGopuram = falGopuram;
falGraduationCap = falGraduationCap;
falGramophone = falGramophone;
falGrapes = falGrapes;
falGrate = falGrate;
falGrateDroplet = falGrateDroplet;
falGreaterThan = falGreaterThan;
falGreaterThanEqual = falGreaterThanEqual;
falGrid = falGrid;
falGrid2 = falGrid2;
falGrid2Plus = falGrid2Plus;
falGrid3 = falGrid3;
falGrid4 = falGrid4;
falGrid5 = falGrid5;
falGridDividers = falGridDividers;
falGridHorizontal = falGridHorizontal;
falGrimace = falGrimace;
falGrin = falGrin;
falGrinAlt = falGrinAlt;
falGrinBeam = falGrinBeam;
falGrinBeamSweat = falGrinBeamSweat;
falGrinHearts = falGrinHearts;
falGrinSquint = falGrinSquint;
falGrinSquintTears = falGrinSquintTears;
falGrinStars = falGrinStars;
falGrinTears = falGrinTears;
falGrinTongue = falGrinTongue;
falGrinTongueSquint = falGrinTongueSquint;
falGrinTongueWink = falGrinTongueWink;
falGrinWink = falGrinWink;
falGrip = falGrip;
falGripDots = falGripDots;
falGripDotsVertical = falGripDotsVertical;
falGripHorizontal = falGripHorizontal;
falGripLines = falGripLines;
falGripLinesVertical = falGripLinesVertical;
falGripVertical = falGripVertical;
falGroupArrowsRotate = falGroupArrowsRotate;
falGuaraniSign = falGuaraniSign;
falGuitar = falGuitar;
falGuitarElectric = falGuitarElectric;
falGuitars = falGuitars;
falGun = falGun;
falGunSlash = falGunSlash;
falGunSquirt = falGunSquirt;
falH = falH;
falH1 = falH1;
falH2 = falH2;
falH3 = falH3;
falH4 = falH4;
falH5 = falH5;
falH6 = falH6;
falHamburger = falHamburger;
falHammer = falHammer;
falHammerCrash = falHammerCrash;
falHammerWar = falHammerWar;
falHamsa = falHamsa;
falHand = falHand;
falHandBackFist = falHandBackFist;
falHandBackPointDown = falHandBackPointDown;
falHandBackPointLeft = falHandBackPointLeft;
falHandBackPointRibbon = falHandBackPointRibbon;
falHandBackPointRight = falHandBackPointRight;
falHandBackPointUp = falHandBackPointUp;
falHandcuffs = falHandcuffs;
falHandDots = falHandDots;
falHandFingersCrossed = falHandFingersCrossed;
falHandFist = falHandFist;
falHandHeart = falHandHeart;
falHandHolding = falHandHolding;
falHandHoldingBox = falHandHoldingBox;
falHandHoldingDollar = falHandHoldingDollar;
falHandHoldingDroplet = falHandHoldingDroplet;
falHandHoldingHand = falHandHoldingHand;
falHandHoldingHeart = falHandHoldingHeart;
falHandHoldingMagic = falHandHoldingMagic;
falHandHoldingMedical = falHandHoldingMedical;
falHandHoldingSeedling = falHandHoldingSeedling;
falHandHoldingSkull = falHandHoldingSkull;
falHandHoldingUsd = falHandHoldingUsd;
falHandHoldingWater = falHandHoldingWater;
falHandHorns = falHandHorns;
falHandLizard = falHandLizard;
falHandLove = falHandLove;
falHandMiddleFinger = falHandMiddleFinger;
falHandPaper = falHandPaper;
falHandPeace = falHandPeace;
falHandPointDown = falHandPointDown;
falHandPointer = falHandPointer;
falHandPointLeft = falHandPointLeft;
falHandPointRibbon = falHandPointRibbon;
falHandPointRight = falHandPointRight;
falHandPointUp = falHandPointUp;
falHandReceiving = falHandReceiving;
falHandRock = falHandRock;
falHands = falHands;
falHandsAmericanSignLanguageInterpreting = falHandsAmericanSignLanguageInterpreting;
falHandsAslInterpreting = falHandsAslInterpreting;
falHandsBound = falHandsBound;
falHandsBubbles = falHandsBubbles;
falHandScissors = falHandScissors;
falHandsClapping = falHandsClapping;
falHandshake = falHandshake;
falHandshakeAlt = falHandshakeAlt;
falHandshakeAltSlash = falHandshakeAltSlash;
falHandshakeAngle = falHandshakeAngle;
falHandshakeSimple = falHandshakeSimple;
falHandshakeSimpleSlash = falHandshakeSimpleSlash;
falHandshakeSlash = falHandshakeSlash;
falHandsHeart = falHandsHeart;
falHandsHelping = falHandsHelping;
falHandsHolding = falHandsHolding;
falHandsHoldingChild = falHandsHoldingChild;
falHandsHoldingCircle = falHandsHoldingCircle;
falHandsHoldingDiamond = falHandsHoldingDiamond;
falHandsHoldingDollar = falHandsHoldingDollar;
falHandsHoldingHeart = falHandsHoldingHeart;
falHandSparkles = falHandSparkles;
falHandSpock = falHandSpock;
falHandsPraying = falHandsPraying;
falHandsUsd = falHandsUsd;
falHandsWash = falHandsWash;
falHandWave = falHandWave;
falHanukiah = falHanukiah;
falHardDrive = falHardDrive;
falHardHat = falHardHat;
falHardOfHearing = falHardOfHearing;
falHashtag = falHashtag;
falHashtagLock = falHashtagLock;
falHatChef = falHatChef;
falHatCowboy = falHatCowboy;
falHatCowboySide = falHatCowboySide;
falHatHard = falHatHard;
falHatSanta = falHatSanta;
falHatWinter = falHatWinter;
falHatWitch = falHatWitch;
falHatWizard = falHatWizard;
falHdd = falHdd;
falHeader = falHeader;
falHeading = falHeading;
falHeadphones = falHeadphones;
falHeadphonesAlt = falHeadphonesAlt;
falHeadphonesSimple = falHeadphonesSimple;
falHeadset = falHeadset;
falHeadSide = falHeadSide;
falHeadSideBrain = falHeadSideBrain;
falHeadSideCough = falHeadSideCough;
falHeadSideCoughSlash = falHeadSideCoughSlash;
falHeadSideGoggles = falHeadSideGoggles;
falHeadSideHeadphones = falHeadSideHeadphones;
falHeadSideHeart = falHeadSideHeart;
falHeadSideMask = falHeadSideMask;
falHeadSideMedical = falHeadSideMedical;
falHeadSideVirus = falHeadSideVirus;
falHeadVr = falHeadVr;
falHeart = falHeart;
falHeartbeat = falHeartbeat;
falHeartBroken = falHeartBroken;
falHeartCircle = falHeartCircle;
falHeartCircleBolt = falHeartCircleBolt;
falHeartCircleCheck = falHeartCircleCheck;
falHeartCircleExclamation = falHeartCircleExclamation;
falHeartCircleMinus = falHeartCircleMinus;
falHeartCirclePlus = falHeartCirclePlus;
falHeartCircleXmark = falHeartCircleXmark;
falHeartCrack = falHeartCrack;
falHeartHalf = falHeartHalf;
falHeartHalfAlt = falHeartHalfAlt;
falHeartHalfStroke = falHeartHalfStroke;
falHeartMusicCameraBolt = falHeartMusicCameraBolt;
falHeartPulse = falHeartPulse;
falHeartRate = falHeartRate;
falHeartSquare = falHeartSquare;
falHeat = falHeat;
falHelicopter = falHelicopter;
falHelicopterSymbol = falHelicopterSymbol;
falHelmetBattle = falHelmetBattle;
falHelmetSafety = falHelmetSafety;
falHelmetUn = falHelmetUn;
falHexagon = falHexagon;
falHexagonCheck = falHexagonCheck;
falHexagonDivide = falHexagonDivide;
falHexagonExclamation = falHexagonExclamation;
falHexagonImage = falHexagonImage;
falHexagonMinus = falHexagonMinus;
falHexagonPlus = falHexagonPlus;
falHexagonVerticalNft = falHexagonVerticalNft;
falHexagonVerticalNftSlanted = falHexagonVerticalNftSlanted;
falHexagonXmark = falHexagonXmark;
falHighDefinition = falHighDefinition;
falHighlighter = falHighlighter;
falHighlighterLine = falHighlighterLine;
falHiking = falHiking;
falHillAvalanche = falHillAvalanche;
falHillRockslide = falHillRockslide;
falHippo = falHippo;
falHistory = falHistory;
falHockeyMask = falHockeyMask;
falHockeyPuck = falHockeyPuck;
falHockeyStickPuck = falHockeyStickPuck;
falHockeySticks = falHockeySticks;
falHollyBerry = falHollyBerry;
falHome = falHome;
falHomeAlt = falHomeAlt;
falHomeBlank = falHomeBlank;
falHomeHeart = falHomeHeart;
falHomeLg = falHomeLg;
falHomeLgAlt = falHomeLgAlt;
falHomeUser = falHomeUser;
falHoneyPot = falHoneyPot;
falHoodCloak = falHoodCloak;
falHorizontalRule = falHorizontalRule;
falHorse = falHorse;
falHorseHead = falHorseHead;
falHorseSaddle = falHorseSaddle;
falHose = falHose;
falHoseReel = falHoseReel;
falHospital = falHospital;
falHospitalAlt = falHospitalAlt;
falHospitals = falHospitals;
falHospitalSymbol = falHospitalSymbol;
falHospitalUser = falHospitalUser;
falHospitalWide = falHospitalWide;
falHotdog = falHotdog;
falHotel = falHotel;
falHotTub = falHotTub;
falHotTubPerson = falHotTubPerson;
falHourglass = falHourglass;
falHourglass1 = falHourglass1;
falHourglass2 = falHourglass2;
falHourglass3 = falHourglass3;
falHourglassClock = falHourglassClock;
falHourglassEmpty = falHourglassEmpty;
falHourglassEnd = falHourglassEnd;
falHourglassHalf = falHourglassHalf;
falHourglassStart = falHourglassStart;
falHouse = falHouse;
falHouseBlank = falHouseBlank;
falHouseBuilding = falHouseBuilding;
falHouseChimney = falHouseChimney;
falHouseChimneyBlank = falHouseChimneyBlank;
falHouseChimneyCrack = falHouseChimneyCrack;
falHouseChimneyHeart = falHouseChimneyHeart;
falHouseChimneyMedical = falHouseChimneyMedical;
falHouseChimneyUser = falHouseChimneyUser;
falHouseChimneyWindow = falHouseChimneyWindow;
falHouseCircleCheck = falHouseCircleCheck;
falHouseCircleExclamation = falHouseCircleExclamation;
falHouseCircleXmark = falHouseCircleXmark;
falHouseCrack = falHouseCrack;
falHouseDamage = falHouseDamage;
falHouseDay = falHouseDay;
falHouseFire = falHouseFire;
falHouseFlag = falHouseFlag;
falHouseFlood = falHouseFlood;
falHouseFloodWater = falHouseFloodWater;
falHouseFloodWaterCircleArrowRight = falHouseFloodWaterCircleArrowRight;
falHouseHeart = falHouseHeart;
falHouseLaptop = falHouseLaptop;
falHouseLeave = falHouseLeave;
falHouseLock = falHouseLock;
falHouseMedical = falHouseMedical;
falHouseMedicalCircleCheck = falHouseMedicalCircleCheck;
falHouseMedicalCircleExclamation = falHouseMedicalCircleExclamation;
falHouseMedicalCircleXmark = falHouseMedicalCircleXmark;
falHouseMedicalFlag = falHouseMedicalFlag;
falHouseNight = falHouseNight;
falHousePersonArrive = falHousePersonArrive;
falHousePersonDepart = falHousePersonDepart;
falHousePersonLeave = falHousePersonLeave;
falHousePersonReturn = falHousePersonReturn;
falHouseReturn = falHouseReturn;
falHouseSignal = falHouseSignal;
falHouseTree = falHouseTree;
falHouseTsunami = falHouseTsunami;
falHouseTurret = falHouseTurret;
falHouseUser = falHouseUser;
falHouseWater = falHouseWater;
falHouseWindow = falHouseWindow;
falHryvnia = falHryvnia;
falHryvniaSign = falHryvniaSign;
falHSquare = falHSquare;
falHumidity = falHumidity;
falHundredPoints = falHundredPoints;
falHurricane = falHurricane;
falHyphen = falHyphen;
falI = falI;
falIceCream = falIceCream;
falIceSkate = falIceSkate;
falIcicles = falIcicles;
falIcons = falIcons;
falIconsAlt = falIconsAlt;
falICursor = falICursor;
falIdBadge = falIdBadge;
falIdCard = falIdCard;
falIdCardAlt = falIdCardAlt;
falIdCardClip = falIdCardClip;
falIgloo = falIgloo;
falIls = falIls;
falImage = falImage;
falImageLandscape = falImageLandscape;
falImagePolaroid = falImagePolaroid;
falImagePolaroidUser = falImagePolaroidUser;
falImagePortrait = falImagePortrait;
falImages = falImages;
falImageSlash = falImageSlash;
falImagesUser = falImagesUser;
falImageUser = falImageUser;
falInbox = falInbox;
falInboxArrowDown = falInboxArrowDown;
falInboxArrowUp = falInboxArrowUp;
falInboxes = falInboxes;
falInboxFull = falInboxFull;
falInboxIn = falInboxIn;
falInboxOut = falInboxOut;
falIndent = falIndent;
falIndianRupee = falIndianRupee;
falIndianRupeeSign = falIndianRupeeSign;
falIndustry = falIndustry;
falIndustryAlt = falIndustryAlt;
falIndustryWindows = falIndustryWindows;
falInfinity = falInfinity;
falInfo = falInfo;
falInfoCircle = falInfoCircle;
falInfoSquare = falInfoSquare;
falInhaler = falInhaler;
falInputNumeric = falInputNumeric;
falInputPipe = falInputPipe;
falInputText = falInputText;
falInr = falInr;
falInstitution = falInstitution;
falIntegral = falIntegral;
falIntersection = falIntersection;
falInventory = falInventory;
falIslandTreePalm = falIslandTreePalm;
falIslandTropical = falIslandTropical;
falItalic = falItalic;
falJ = falJ;
falJackOLantern = falJackOLantern;
falJar = falJar;
falJarWheat = falJarWheat;
falJedi = falJedi;
falJetFighter = falJetFighter;
falJetFighterUp = falJetFighterUp;
falJoint = falJoint;
falJournalWhills = falJournalWhills;
falJoystick = falJoystick;
falJpy = falJpy;
falJug = falJug;
falJugDetergent = falJugDetergent;
falK = falK;
falKaaba = falKaaba;
falKazoo = falKazoo;
falKerning = falKerning;
falKey = falKey;
falKeyboard = falKeyboard;
falKeyboardBrightness = falKeyboardBrightness;
falKeyboardBrightnessLow = falKeyboardBrightnessLow;
falKeyboardDown = falKeyboardDown;
falKeyboardLeft = falKeyboardLeft;
falKeynote = falKeynote;
falKeySkeleton = falKeySkeleton;
falKeySkeletonLeftRight = falKeySkeletonLeftRight;
falKhanda = falKhanda;
falKidneys = falKidneys;
falKipSign = falKipSign;
falKiss = falKiss;
falKissBeam = falKissBeam;
falKissWinkHeart = falKissWinkHeart;
falKitchenSet = falKitchenSet;
falKite = falKite;
falKitMedical = falKitMedical;
falKiwiBird = falKiwiBird;
falKiwiFruit = falKiwiFruit;
falKnife = falKnife;
falKnifeKitchen = falKnifeKitchen;
falKrw = falKrw;
falL = falL;
falLacrosseStick = falLacrosseStick;
falLacrosseStickBall = falLacrosseStickBall;
falLadderWater = falLadderWater;
falLambda = falLambda;
falLamp = falLamp;
falLampDesk = falLampDesk;
falLampFloor = falLampFloor;
falLampStreet = falLampStreet;
falLandmark = falLandmark;
falLandmarkAlt = falLandmarkAlt;
falLandmarkDome = falLandmarkDome;
falLandmarkFlag = falLandmarkFlag;
falLandMineOn = falLandMineOn;
falLandscape = falLandscape;
falLanguage = falLanguage;
falLaptop = falLaptop;
falLaptopArrowDown = falLaptopArrowDown;
falLaptopCode = falLaptopCode;
falLaptopFile = falLaptopFile;
falLaptopHouse = falLaptopHouse;
falLaptopMedical = falLaptopMedical;
falLaptopMobile = falLaptopMobile;
falLaptopSlash = falLaptopSlash;
falLariSign = falLariSign;
falLasso = falLasso;
falLassoSparkles = falLassoSparkles;
falLaugh = falLaugh;
falLaughBeam = falLaughBeam;
falLaughSquint = falLaughSquint;
falLaughWink = falLaughWink;
falLayerGroup = falLayerGroup;
falLayerGroupMinus = falLayerGroupMinus;
falLayerGroupPlus = falLayerGroupPlus;
falLayerMinus = falLayerMinus;
falLayerPlus = falLayerPlus;
falLeaf = falLeaf;
falLeafHeart = falLeafHeart;
falLeafMaple = falLeafMaple;
falLeafOak = falLeafOak;
falLeafyGreen = falLeafyGreen;
falLeft = falLeft;
falLeftFromLine = falLeftFromLine;
falLeftLong = falLeftLong;
falLeftLongToLine = falLeftLongToLine;
falLeftRight = falLeftRight;
falLeftToLine = falLeftToLine;
falLegal = falLegal;
falLemon = falLemon;
falLessThan = falLessThan;
falLessThanEqual = falLessThanEqual;
falLevelDown = falLevelDown;
falLevelDownAlt = falLevelDownAlt;
falLevelUp = falLevelUp;
falLevelUpAlt = falLevelUpAlt;
falLifeRing = falLifeRing;
falLightbulb = falLightbulb;
falLightbulbDollar = falLightbulbDollar;
falLightbulbExclamation = falLightbulbExclamation;
falLightbulbExclamationOn = falLightbulbExclamationOn;
falLightbulbOn = falLightbulbOn;
falLightbulbSlash = falLightbulbSlash;
falLightCeiling = falLightCeiling;
falLightEmergency = falLightEmergency;
falLightEmergencyOn = falLightEmergencyOn;
falLightsHoliday = falLightsHoliday;
falLightSwitch = falLightSwitch;
falLightSwitchOff = falLightSwitchOff;
falLightSwitchOn = falLightSwitchOn;
falLineChart = falLineChart;
falLineColumns = falLineColumns;
falLineHeight = falLineHeight;
falLinesLeaning = falLinesLeaning;
falLink = falLink;
falLinkHorizontal = falLinkHorizontal;
falLinkHorizontalSlash = falLinkHorizontalSlash;
falLinkSimple = falLinkSimple;
falLinkSimpleSlash = falLinkSimpleSlash;
falLinkSlash = falLinkSlash;
falLips = falLips;
falLiraSign = falLiraSign;
falList = falList;
falList12 = falList12;
falListAlt = falListAlt;
falListCheck = falListCheck;
falListDots = falListDots;
falListDropdown = falListDropdown;
falListMusic = falListMusic;
falListNumeric = falListNumeric;
falListOl = falListOl;
falListRadio = falListRadio;
falListSquares = falListSquares;
falListTimeline = falListTimeline;
falListTree = falListTree;
falListUl = falListUl;
falLitecoinSign = falLitecoinSign;
falLoader = falLoader;
falLobster = falLobster;
falLocation = falLocation;
falLocationArrow = falLocationArrow;
falLocationCheck = falLocationCheck;
falLocationCircle = falLocationCircle;
falLocationCrosshairs = falLocationCrosshairs;
falLocationCrosshairsSlash = falLocationCrosshairsSlash;
falLocationDot = falLocationDot;
falLocationDotSlash = falLocationDotSlash;
falLocationExclamation = falLocationExclamation;
falLocationMinus = falLocationMinus;
falLocationPen = falLocationPen;
falLocationPin = falLocationPin;
falLocationPinLock = falLocationPinLock;
falLocationPinSlash = falLocationPinSlash;
falLocationPlus = falLocationPlus;
falLocationQuestion = falLocationQuestion;
falLocationSlash = falLocationSlash;
falLocationSmile = falLocationSmile;
falLocationXmark = falLocationXmark;
falLock = falLock;
falLockA = falLockA;
falLockAlt = falLockAlt;
falLockHashtag = falLockHashtag;
falLockKeyhole = falLockKeyhole;
falLockKeyholeOpen = falLockKeyholeOpen;
falLockOpen = falLockOpen;
falLockOpenAlt = falLockOpenAlt;
falLocust = falLocust;
falLollipop = falLollipop;
falLollypop = falLollypop;
falLongArrowAltDown = falLongArrowAltDown;
falLongArrowAltLeft = falLongArrowAltLeft;
falLongArrowAltRight = falLongArrowAltRight;
falLongArrowAltUp = falLongArrowAltUp;
falLongArrowDown = falLongArrowDown;
falLongArrowLeft = falLongArrowLeft;
falLongArrowRight = falLongArrowRight;
falLongArrowUp = falLongArrowUp;
falLoveseat = falLoveseat;
falLowVision = falLowVision;
falLuchador = falLuchador;
falLuchadorMask = falLuchadorMask;
falLuggageCart = falLuggageCart;
falLungs = falLungs;
falLungsVirus = falLungsVirus;
falM = falM;
falMace = falMace;
falMagic = falMagic;
falMagicWandSparkles = falMagicWandSparkles;
falMagnet = falMagnet;
falMagnifyingGlass = falMagnifyingGlass;
falMagnifyingGlassArrowRight = falMagnifyingGlassArrowRight;
falMagnifyingGlassChart = falMagnifyingGlassChart;
falMagnifyingGlassDollar = falMagnifyingGlassDollar;
falMagnifyingGlassLocation = falMagnifyingGlassLocation;
falMagnifyingGlassMinus = falMagnifyingGlassMinus;
falMagnifyingGlassPlus = falMagnifyingGlassPlus;
falMailbox = falMailbox;
falMailBulk = falMailBulk;
falMailForward = falMailForward;
falMailReply = falMailReply;
falMailReplyAll = falMailReplyAll;
falMakiRoll = falMakiRoll;
falMakizushi = falMakizushi;
falMale = falMale;
falManatSign = falManatSign;
falMandolin = falMandolin;
falMango = falMango;
falManhole = falManhole;
falMap = falMap;
falMapLocation = falMapLocation;
falMapLocationDot = falMapLocationDot;
falMapMarked = falMapMarked;
falMapMarkedAlt = falMapMarkedAlt;
falMapMarker = falMapMarker;
falMapMarkerAlt = falMapMarkerAlt;
falMapMarkerAltSlash = falMapMarkerAltSlash;
falMapMarkerCheck = falMapMarkerCheck;
falMapMarkerEdit = falMapMarkerEdit;
falMapMarkerExclamation = falMapMarkerExclamation;
falMapMarkerMinus = falMapMarkerMinus;
falMapMarkerPlus = falMapMarkerPlus;
falMapMarkerQuestion = falMapMarkerQuestion;
falMapMarkerSlash = falMapMarkerSlash;
falMapMarkerSmile = falMapMarkerSmile;
falMapMarkerTimes = falMapMarkerTimes;
falMapMarkerXmark = falMapMarkerXmark;
falMapPin = falMapPin;
falMapSigns = falMapSigns;
falMarker = falMarker;
falMars = falMars;
falMarsAndVenus = falMarsAndVenus;
falMarsAndVenusBurst = falMarsAndVenusBurst;
falMarsDouble = falMarsDouble;
falMarsStroke = falMarsStroke;
falMarsStrokeH = falMarsStrokeH;
falMarsStrokeRight = falMarsStrokeRight;
falMarsStrokeUp = falMarsStrokeUp;
falMarsStrokeV = falMarsStrokeV;
falMartiniGlass = falMartiniGlass;
falMartiniGlassCitrus = falMartiniGlassCitrus;
falMartiniGlassEmpty = falMartiniGlassEmpty;
falMask = falMask;
falMaskFace = falMaskFace;
falMaskLuchador = falMaskLuchador;
falMaskSnorkel = falMaskSnorkel;
falMasksTheater = falMasksTheater;
falMaskVentilator = falMaskVentilator;
falMattressPillow = falMattressPillow;
falMaximize = falMaximize;
falMeat = falMeat;
falMedal = falMedal;
falMedkit = falMedkit;
falMegaphone = falMegaphone;
falMeh = falMeh;
falMehBlank = falMehBlank;
falMehRollingEyes = falMehRollingEyes;
falMelon = falMelon;
falMelonSlice = falMelonSlice;
falMemo = falMemo;
falMemoCircleCheck = falMemoCircleCheck;
falMemoCircleInfo = falMemoCircleInfo;
falMemoPad = falMemoPad;
falMemory = falMemory;
falMenorah = falMenorah;
falMercury = falMercury;
falMerge = falMerge;
falMessage = falMessage;
falMessageArrowDown = falMessageArrowDown;
falMessageArrowUp = falMessageArrowUp;
falMessageArrowUpRight = falMessageArrowUpRight;
falMessageBot = falMessageBot;
falMessageCaptions = falMessageCaptions;
falMessageCheck = falMessageCheck;
falMessageCode = falMessageCode;
falMessageDollar = falMessageDollar;
falMessageDots = falMessageDots;
falMessageEdit = falMessageEdit;
falMessageExclamation = falMessageExclamation;
falMessageImage = falMessageImage;
falMessageLines = falMessageLines;
falMessageMedical = falMessageMedical;
falMessageMiddle = falMessageMiddle;
falMessageMiddleTop = falMessageMiddleTop;
falMessageMinus = falMessageMinus;
falMessageMusic = falMessageMusic;
falMessagePen = falMessagePen;
falMessagePlus = falMessagePlus;
falMessageQuestion = falMessageQuestion;
falMessageQuote = falMessageQuote;
falMessages = falMessages;
falMessagesDollar = falMessagesDollar;
falMessageSlash = falMessageSlash;
falMessageSmile = falMessageSmile;
falMessageSms = falMessageSms;
falMessagesQuestion = falMessagesQuestion;
falMessageText = falMessageText;
falMessageTimes = falMessageTimes;
falMessageXmark = falMessageXmark;
falMessaging = falMessaging;
falMeteor = falMeteor;
falMeter = falMeter;
falMeterBolt = falMeterBolt;
falMeterDroplet = falMeterDroplet;
falMeterFire = falMeterFire;
falMicrochip = falMicrochip;
falMicrochipAi = falMicrochipAi;
falMicrophone = falMicrophone;
falMicrophoneAlt = falMicrophoneAlt;
falMicrophoneAltSlash = falMicrophoneAltSlash;
falMicrophoneCircle = falMicrophoneCircle;
falMicrophoneCircleAlt = falMicrophoneCircleAlt;
falMicrophoneLines = falMicrophoneLines;
falMicrophoneLinesSlash = falMicrophoneLinesSlash;
falMicrophoneSlash = falMicrophoneSlash;
falMicrophoneStand = falMicrophoneStand;
falMicroscope = falMicroscope;
falMicrowave = falMicrowave;
falMillSign = falMillSign;
falMindShare = falMindShare;
falMinimize = falMinimize;
falMinus = falMinus;
falMinusCircle = falMinusCircle;
falMinusHexagon = falMinusHexagon;
falMinusOctagon = falMinusOctagon;
falMinusSquare = falMinusSquare;
falMistletoe = falMistletoe;
falMitten = falMitten;
falMobile = falMobile;
falMobileAlt = falMobileAlt;
falMobileAndroid = falMobileAndroid;
falMobileAndroidAlt = falMobileAndroidAlt;
falMobileButton = falMobileButton;
falMobileIphone = falMobileIphone;
falMobileNotch = falMobileNotch;
falMobilePhone = falMobilePhone;
falMobileRetro = falMobileRetro;
falMobileScreen = falMobileScreen;
falMobileScreenButton = falMobileScreenButton;
falMobileSignal = falMobileSignal;
falMobileSignalOut = falMobileSignalOut;
falMoneyBill = falMoneyBill;
falMoneyBill1 = falMoneyBill1;
falMoneyBill1Wave = falMoneyBill1Wave;
falMoneyBillAlt = falMoneyBillAlt;
falMoneyBills = falMoneyBills;
falMoneyBillsAlt = falMoneyBillsAlt;
falMoneyBillSimple = falMoneyBillSimple;
falMoneyBillSimpleWave = falMoneyBillSimpleWave;
falMoneyBillsSimple = falMoneyBillsSimple;
falMoneyBillTransfer = falMoneyBillTransfer;
falMoneyBillTrendUp = falMoneyBillTrendUp;
falMoneyBillWave = falMoneyBillWave;
falMoneyBillWaveAlt = falMoneyBillWaveAlt;
falMoneyBillWheat = falMoneyBillWheat;
falMoneyCheck = falMoneyCheck;
falMoneyCheckAlt = falMoneyCheckAlt;
falMoneyCheckDollar = falMoneyCheckDollar;
falMoneyCheckDollarPen = falMoneyCheckDollarPen;
falMoneyCheckEdit = falMoneyCheckEdit;
falMoneyCheckEditAlt = falMoneyCheckEditAlt;
falMoneyCheckPen = falMoneyCheckPen;
falMoneyFromBracket = falMoneyFromBracket;
falMoneySimpleFromBracket = falMoneySimpleFromBracket;
falMonitorHeartRate = falMonitorHeartRate;
falMonitorWaveform = falMonitorWaveform;
falMonkey = falMonkey;
falMonument = falMonument;
falMoon = falMoon;
falMoonCloud = falMoonCloud;
falMoonOverSun = falMoonOverSun;
falMoonStars = falMoonStars;
falMoped = falMoped;
falMortarBoard = falMortarBoard;
falMortarPestle = falMortarPestle;
falMosque = falMosque;
falMosquito = falMosquito;
falMosquitoNet = falMosquitoNet;
falMotorcycle = falMotorcycle;
falMound = falMound;
falMountain = falMountain;
falMountainCity = falMountainCity;
falMountains = falMountains;
falMountainSun = falMountainSun;
falMouse = falMouse;
falMouseAlt = falMouseAlt;
falMousePointer = falMousePointer;
falMp3Player = falMp3Player;
falMug = falMug;
falMugHot = falMugHot;
falMugMarshmallows = falMugMarshmallows;
falMugSaucer = falMugSaucer;
falMugTea = falMugTea;
falMugTeaSaucer = falMugTeaSaucer;
falMultiply = falMultiply;
falMuseum = falMuseum;
falMushroom = falMushroom;
falMusic = falMusic;
falMusicAlt = falMusicAlt;
falMusicAltSlash = falMusicAltSlash;
falMusicNote = falMusicNote;
falMusicNoteSlash = falMusicNoteSlash;
falMusicSlash = falMusicSlash;
falN = falN;
falNairaSign = falNairaSign;
falNarwhal = falNarwhal;
falNavicon = falNavicon;
falNestingDolls = falNestingDolls;
falNetworkWired = falNetworkWired;
falNeuter = falNeuter;
falNewspaper = falNewspaper;
falNfc = falNfc;
falNfcLock = falNfcLock;
falNfcMagnifyingGlass = falNfcMagnifyingGlass;
falNfcPen = falNfcPen;
falNfcSignal = falNfcSignal;
falNfcSlash = falNfcSlash;
falNfcSymbol = falNfcSymbol;
falNfcTrash = falNfcTrash;
falNigiri = falNigiri;
falNotdef = falNotdef;
falNote = falNote;
falNotebook = falNotebook;
falNoteMedical = falNoteMedical;
falNotEqual = falNotEqual;
falNotes = falNotes;
falNotesMedical = falNotesMedical;
falNoteSticky = falNoteSticky;
falO = falO;
falObjectExclude = falObjectExclude;
falObjectGroup = falObjectGroup;
falObjectIntersect = falObjectIntersect;
falObjectsAlignBottom = falObjectsAlignBottom;
falObjectsAlignCenterHorizontal = falObjectsAlignCenterHorizontal;
falObjectsAlignCenterVertical = falObjectsAlignCenterVertical;
falObjectsAlignLeft = falObjectsAlignLeft;
falObjectsAlignRight = falObjectsAlignRight;
falObjectsAlignTop = falObjectsAlignTop;
falObjectsColumn = falObjectsColumn;
falObjectSubtract = falObjectSubtract;
falObjectUngroup = falObjectUngroup;
falObjectUnion = falObjectUnion;
falOctagon = falOctagon;
falOctagonCheck = falOctagonCheck;
falOctagonDivide = falOctagonDivide;
falOctagonExclamation = falOctagonExclamation;
falOctagonMinus = falOctagonMinus;
falOctagonPlus = falOctagonPlus;
falOctagonXmark = falOctagonXmark;
falOilCan = falOilCan;
falOilCanDrip = falOilCanDrip;
falOilTemp = falOilTemp;
falOilTemperature = falOilTemperature;
falOilWell = falOilWell;
falOlive = falOlive;
falOliveBranch = falOliveBranch;
falOm = falOm;
falOmega = falOmega;
falOnion = falOnion;
falOption = falOption;
falOrnament = falOrnament;
falOtter = falOtter;
falOutdent = falOutdent;
falOutlet = falOutlet;
falOven = falOven;
falOverline = falOverline;
falP = falP;
falPage = falPage;
falPageBreak = falPageBreak;
falPageCaretDown = falPageCaretDown;
falPageCaretUp = falPageCaretUp;
falPager = falPager;
falPaintbrush = falPaintbrush;
falPaintBrush = falPaintBrush;
falPaintbrushAlt = falPaintbrushAlt;
falPaintBrushAlt = falPaintBrushAlt;
falPaintbrushFine = falPaintbrushFine;
falPaintBrushFine = falPaintBrushFine;
falPaintbrushPencil = falPaintbrushPencil;
falPaintRoller = falPaintRoller;
falPalette = falPalette;
falPaletteBoxes = falPaletteBoxes;
falPallet = falPallet;
falPalletAlt = falPalletAlt;
falPalletBox = falPalletBox;
falPalletBoxes = falPalletBoxes;
falPancakes = falPancakes;
falPanelEws = falPanelEws;
falPanelFire = falPanelFire;
falPanFood = falPanFood;
falPanFrying = falPanFrying;
falPanorama = falPanorama;
falPaperclip = falPaperclip;
falPaperclipVertical = falPaperclipVertical;
falPaperPlane = falPaperPlane;
falPaperPlaneAlt = falPaperPlaneAlt;
falPaperPlaneTop = falPaperPlaneTop;
falParachuteBox = falParachuteBox;
falParagraph = falParagraph;
falParagraphLeft = falParagraphLeft;
falParagraphRtl = falParagraphRtl;
falParentheses = falParentheses;
falParenthesis = falParenthesis;
falParking = falParking;
falParkingCircle = falParkingCircle;
falParkingCircleSlash = falParkingCircleSlash;
falParkingSlash = falParkingSlash;
falPartyBack = falPartyBack;
falPartyBell = falPartyBell;
falPartyHorn = falPartyHorn;
falPassport = falPassport;
falPastafalrianism = falPastafalrianism;
falPaste = falPaste;
falPause = falPause;
falPauseCircle = falPauseCircle;
falPaw = falPaw;
falPawAlt = falPawAlt;
falPawClaws = falPawClaws;
falPawSimple = falPawSimple;
falPeace = falPeace;
falPeach = falPeach;
falPeanut = falPeanut;
falPeanuts = falPeanuts;
falPeapod = falPeapod;
falPear = falPear;
falPedestal = falPedestal;
falPegasus = falPegasus;
falPen = falPen;
falPenAlt = falPenAlt;
falPenAltSlash = falPenAltSlash;
falPencil = falPencil;
falPencilAlt = falPencilAlt;
falPencilPaintbrush = falPencilPaintbrush;
falPencilRuler = falPencilRuler;
falPencilSlash = falPencilSlash;
falPencilSquare = falPencilSquare;
falPenCircle = falPenCircle;
falPenClip = falPenClip;
falPenClipSlash = falPenClipSlash;
falPenFancy = falPenFancy;
falPenFancySlash = falPenFancySlash;
falPenField = falPenField;
falPenLine = falPenLine;
falPennant = falPennant;
falPenNib = falPenNib;
falPenNibSlash = falPenNibSlash;
falPenPaintbrush = falPenPaintbrush;
falPenRuler = falPenRuler;
falPenSlash = falPenSlash;
falPenSquare = falPenSquare;
falPenSwirl = falPenSwirl;
falPenToSquare = falPenToSquare;
falPeople = falPeople;
falPeopleArrows = falPeopleArrows;
falPeopleArrowsLeftRight = falPeopleArrowsLeftRight;
falPeopleCarry = falPeopleCarry;
falPeopleCarryBox = falPeopleCarryBox;
falPeopleDress = falPeopleDress;
falPeopleDressSimple = falPeopleDressSimple;
falPeopleGroup = falPeopleGroup;
falPeopleLine = falPeopleLine;
falPeoplePants = falPeoplePants;
falPeoplePantsSimple = falPeoplePantsSimple;
falPeoplePulling = falPeoplePulling;
falPeopleRobbery = falPeopleRobbery;
falPeopleRoof = falPeopleRoof;
falPeopleSimple = falPeopleSimple;
falPepper = falPepper;
falPepperHot = falPepperHot;
falPercent = falPercent;
falPercentage = falPercentage;
falPeriod = falPeriod;
falPerson = falPerson;
falPersonArrowDownToLine = falPersonArrowDownToLine;
falPersonArrowUpFromLine = falPersonArrowUpFromLine;
falPersonBiking = falPersonBiking;
falPersonBikingMountain = falPersonBikingMountain;
falPersonBooth = falPersonBooth;
falPersonBreastfeeding = falPersonBreastfeeding;
falPersonBurst = falPersonBurst;
falPersonCane = falPersonCane;
falPersonCarry = falPersonCarry;
falPersonCarryBox = falPersonCarryBox;
falPersonChalkboard = falPersonChalkboard;
falPersonCircleCheck = falPersonCircleCheck;
falPersonCircleExclamation = falPersonCircleExclamation;
falPersonCircleMinus = falPersonCircleMinus;
falPersonCirclePlus = falPersonCirclePlus;
falPersonCircleQuestion = falPersonCircleQuestion;
falPersonCircleXmark = falPersonCircleXmark;
falPersonDigging = falPersonDigging;
falPersonDolly = falPersonDolly;
falPersonDollyEmpty = falPersonDollyEmpty;
falPersonDotsFromLine = falPersonDotsFromLine;
falPersonDress = falPersonDress;
falPersonDressBurst = falPersonDressBurst;
falPersonDressSimple = falPersonDressSimple;
falPersonDrowning = falPersonDrowning;
falPersonFalling = falPersonFalling;
falPersonFallingBurst = falPersonFallingBurst;
falPersonFromPortal = falPersonFromPortal;
falPersonHalfDress = falPersonHalfDress;
falPersonHarassing = falPersonHarassing;
falPersonHiking = falPersonHiking;
falPersonMilitaryPointing = falPersonMilitaryPointing;
falPersonMilitaryRifle = falPersonMilitaryRifle;
falPersonMilitaryToPerson = falPersonMilitaryToPerson;
falPersonPinball = falPersonPinball;
falPersonPraying = falPersonPraying;
falPersonPregnant = falPersonPregnant;
falPersonRays = falPersonRays;
falPersonRifle = falPersonRifle;
falPersonRunning = falPersonRunning;
falPersonSeat = falPersonSeat;
falPersonSeatReclined = falPersonSeatReclined;
falPersonShelter = falPersonShelter;
falPersonSign = falPersonSign;
falPersonSimple = falPersonSimple;
falPersonSkating = falPersonSkating;
falPersonSkiing = falPersonSkiing;
falPersonSkiingNordic = falPersonSkiingNordic;
falPersonSkiJumping = falPersonSkiJumping;
falPersonSkiLift = falPersonSkiLift;
falPersonSledding = falPersonSledding;
falPersonSnowboarding = falPersonSnowboarding;
falPersonSnowmobiling = falPersonSnowmobiling;
falPersonSwimming = falPersonSwimming;
falPersonThroughWindow = falPersonThroughWindow;
falPersonToDoor = falPersonToDoor;
falPersonToPortal = falPersonToPortal;
falPersonWalking = falPersonWalking;
falPersonWalkingArrowLoopLeft = falPersonWalkingArrowLoopLeft;
falPersonWalkingArrowRight = falPersonWalkingArrowRight;
falPersonWalkingDashedLineArrowRight = falPersonWalkingDashedLineArrowRight;
falPersonWalkingLuggage = falPersonWalkingLuggage;
falPersonWalkingWithCane = falPersonWalkingWithCane;
falPesetaSign = falPesetaSign;
falPesoSign = falPesoSign;
falPhone = falPhone;
falPhoneAlt = falPhoneAlt;
falPhoneArrowDown = falPhoneArrowDown;
falPhoneArrowDownLeft = falPhoneArrowDownLeft;
falPhoneArrowUp = falPhoneArrowUp;
falPhoneArrowUpRight = falPhoneArrowUpRight;
falPhoneCircle = falPhoneCircle;
falPhoneCircleAlt = falPhoneCircleAlt;
falPhoneCircleDown = falPhoneCircleDown;
falPhoneFlip = falPhoneFlip;
falPhoneHangup = falPhoneHangup;
falPhoneIncoming = falPhoneIncoming;
falPhoneIntercom = falPhoneIntercom;
falPhoneLaptop = falPhoneLaptop;
falPhoneMissed = falPhoneMissed;
falPhoneOffice = falPhoneOffice;
falPhoneOutgoing = falPhoneOutgoing;
falPhonePlus = falPhonePlus;
falPhoneRotary = falPhoneRotary;
falPhoneSlash = falPhoneSlash;
falPhoneSquare = falPhoneSquare;
falPhoneSquareAlt = falPhoneSquareAlt;
falPhoneSquareDown = falPhoneSquareDown;
falPhoneVolume = falPhoneVolume;
falPhoneXmark = falPhoneXmark;
falPhotoFilm = falPhotoFilm;
falPhotoFilmMusic = falPhotoFilmMusic;
falPhotoVideo = falPhotoVideo;
falPi = falPi;
falPiano = falPiano;
falPianoKeyboard = falPianoKeyboard;
falPickleball = falPickleball;
falPie = falPie;
falPieChart = falPieChart;
falPig = falPig;
falPiggyBank = falPiggyBank;
falPills = falPills;
falPinata = falPinata;
falPinball = falPinball;
falPineapple = falPineapple;
falPingPongPaddleBall = falPingPongPaddleBall;
falPipe = falPipe;
falPipeCircleCheck = falPipeCircleCheck;
falPipeCollar = falPipeCollar;
falPipeSection = falPipeSection;
falPipeSmoking = falPipeSmoking;
falPipeValve = falPipeValve;
falPizza = falPizza;
falPizzaSlice = falPizzaSlice;
falPlaceOfWorship = falPlaceOfWorship;
falPlane = falPlane;
falPlaneAlt = falPlaneAlt;
falPlaneArrival = falPlaneArrival;
falPlaneCircleCheck = falPlaneCircleCheck;
falPlaneCircleExclamation = falPlaneCircleExclamation;
falPlaneCircleXmark = falPlaneCircleXmark;
falPlaneDeparture = falPlaneDeparture;
falPlaneEngines = falPlaneEngines;
falPlaneLock = falPlaneLock;
falPlaneProp = falPlaneProp;
falPlaneSlash = falPlaneSlash;
falPlaneTail = falPlaneTail;
falPlanetMoon = falPlanetMoon;
falPlanetRinged = falPlanetRinged;
falPlaneUp = falPlaneUp;
falPlaneUpSlash = falPlaneUpSlash;
falPlantWilt = falPlantWilt;
falPlateUtensils = falPlateUtensils;
falPlateWheat = falPlateWheat;
falPlay = falPlay;
falPlayCircle = falPlayCircle;
falPlayPause = falPlayPause;
falPlug = falPlug;
falPlugCircleBolt = falPlugCircleBolt;
falPlugCircleCheck = falPlugCircleCheck;
falPlugCircleExclamation = falPlugCircleExclamation;
falPlugCircleMinus = falPlugCircleMinus;
falPlugCirclePlus = falPlugCirclePlus;
falPlugCircleXmark = falPlugCircleXmark;
falPlus = falPlus;
falPlusCircle = falPlusCircle;
falPlusHexagon = falPlusHexagon;
falPlusMinus = falPlusMinus;
falPlusOctagon = falPlusOctagon;
falPlusSquare = falPlusSquare;
falPodcast = falPodcast;
falPodium = falPodium;
falPodiumStar = falPodiumStar;
falPoliceBox = falPoliceBox;
falPoll = falPoll;
falPollH = falPollH;
falPollPeople = falPollPeople;
falPompebled = falPompebled;
falPoo = falPoo;
falPooBolt = falPooBolt;
falPool8Ball = falPool8Ball;
falPoop = falPoop;
falPooStorm = falPooStorm;
falPopcorn = falPopcorn;
falPopsicle = falPopsicle;
falPortalEnter = falPortalEnter;
falPortalExit = falPortalExit;
falPortrait = falPortrait;
falPotato = falPotato;
falPotFood = falPotFood;
falPoundSign = falPoundSign;
falPowerOff = falPowerOff;
falPray = falPray;
falPrayingHands = falPrayingHands;
falPrescription = falPrescription;
falPrescriptionBottle = falPrescriptionBottle;
falPrescriptionBottleAlt = falPrescriptionBottleAlt;
falPrescriptionBottleMedical = falPrescriptionBottleMedical;
falPresentation = falPresentation;
falPresentationScreen = falPresentationScreen;
falPretzel = falPretzel;
falPrint = falPrint;
falPrintMagnifyingGlass = falPrintMagnifyingGlass;
falPrintSearch = falPrintSearch;
falPrintSlash = falPrintSlash;
falPro = falPro;
falProcedures = falProcedures;
falProjectDiagram = falProjectDiagram;
falProjector = falProjector;
falPump = falPump;
falPumpkin = falPumpkin;
falPumpMedical = falPumpMedical;
falPumpSoap = falPumpSoap;
falPuzzle = falPuzzle;
falPuzzlePiece = falPuzzlePiece;
falPuzzlePieceAlt = falPuzzlePieceAlt;
falPuzzlePieceSimple = falPuzzlePieceSimple;
falQ = falQ;
falQrcode = falQrcode;
falQuestion = falQuestion;
falQuestionCircle = falQuestionCircle;
falQuestionSquare = falQuestionSquare;
falQuidditch = falQuidditch;
falQuidditchBroomBall = falQuidditchBroomBall;
falQuoteLeft = falQuoteLeft;
falQuoteLeftAlt = falQuoteLeftAlt;
falQuoteRight = falQuoteRight;
falQuoteRightAlt = falQuoteRightAlt;
falQuotes = falQuotes;
falQuran = falQuran;
falR = falR;
falRabbit = falRabbit;
falRabbitFast = falRabbitFast;
falRabbitRunning = falRabbitRunning;
falRacquet = falRacquet;
falRadar = falRadar;
falRadiation = falRadiation;
falRadiationAlt = falRadiationAlt;
falRadio = falRadio;
falRadioAlt = falRadioAlt;
falRadioTuner = falRadioTuner;
falRainbow = falRainbow;
falRaindrops = falRaindrops;
falRam = falRam;
falRampLoading = falRampLoading;
falRandom = falRandom;
falRankingStar = falRankingStar;
falRaygun = falRaygun;
falReceipt = falReceipt;
falRecordVinyl = falRecordVinyl;
falRectangle = falRectangle;
falRectangleAd = falRectangleAd;
falRectangleBarcode = falRectangleBarcode;
falRectangleCode = falRectangleCode;
falRectangleHd = falRectangleHd;
falRectangleHistory = falRectangleHistory;
falRectangleHistoryCirclePlus = falRectangleHistoryCirclePlus;
falRectangleHistoryCircleUser = falRectangleHistoryCircleUser;
falRectangleLandscape = falRectangleLandscape;
falRectangleList = falRectangleList;
falRectanglePortrait = falRectanglePortrait;
falRectanglePro = falRectanglePro;
falRectangleSd = falRectangleSd;
falRectanglesMixed = falRectanglesMixed;
falRectangleTerminal = falRectangleTerminal;
falRectangleTimes = falRectangleTimes;
falRectangleVertical = falRectangleVertical;
falRectangleVerticalHistory = falRectangleVerticalHistory;
falRectangleWide = falRectangleWide;
falRectangleXmark = falRectangleXmark;
falRecycle = falRecycle;
falRedo = falRedo;
falRedoAlt = falRedoAlt;
falReel = falReel;
falRefresh = falRefresh;
falRefrigerator = falRefrigerator;
falRegistered = falRegistered;
falRemove = falRemove;
falRemoveFormat = falRemoveFormat;
falReorder = falReorder;
falRepeat = falRepeat;
falRepeat1 = falRepeat1;
falRepeat1Alt = falRepeat1Alt;
falRepeatAlt = falRepeatAlt;
falReply = falReply;
falReplyAll = falReplyAll;
falReplyClock = falReplyClock;
falReplyTime = falReplyTime;
falRepublican = falRepublican;
falRestroom = falRestroom;
falRestroomSimple = falRestroomSimple;
falRetweet = falRetweet;
falRetweetAlt = falRetweetAlt;
falRhombus = falRhombus;
falRibbon = falRibbon;
falRight = falRight;
falRightFromBracket = falRightFromBracket;
falRightFromLine = falRightFromLine;
falRightLeft = falRightLeft;
falRightLong = falRightLong;
falRightLongToLine = falRightLongToLine;
falRightToBracket = falRightToBracket;
falRightToLine = falRightToLine;
falRing = falRing;
falRingsWedding = falRingsWedding;
falRmb = falRmb;
falRoad = falRoad;
falRoadBarrier = falRoadBarrier;
falRoadBridge = falRoadBridge;
falRoadCircleCheck = falRoadCircleCheck;
falRoadCircleExclamation = falRoadCircleExclamation;
falRoadCircleXmark = falRoadCircleXmark;
falRoadLock = falRoadLock;
falRoadSpikes = falRoadSpikes;
falRobot = falRobot;
falRobotAstromech = falRobotAstromech;
falRocket = falRocket;
falRocketLaunch = falRocketLaunch;
falRodAsclepius = falRodAsclepius;
falRodSnake = falRodSnake;
falRollerCoaster = falRollerCoaster;
falRotate = falRotate;
falRotateBack = falRotateBack;
falRotateBackward = falRotateBackward;
falRotateExclamation = falRotateExclamation;
falRotateForward = falRotateForward;
falRotateLeft = falRotateLeft;
falRotateRight = falRotateRight;
falRouble = falRouble;
falRoute = falRoute;
falRouteHighway = falRouteHighway;
falRouteInterstate = falRouteInterstate;
falRouter = falRouter;
falRows = falRows;
falRss = falRss;
falRssSquare = falRssSquare;
falRub = falRub;
falRuble = falRuble;
falRubleSign = falRubleSign;
falRug = falRug;
falRugbyBall = falRugbyBall;
falRuler = falRuler;
falRulerCombined = falRulerCombined;
falRulerHorizontal = falRulerHorizontal;
falRulerTriangle = falRulerTriangle;
falRulerVertical = falRulerVertical;
falRunning = falRunning;
falRupee = falRupee;
falRupeeSign = falRupeeSign;
falRupiahSign = falRupiahSign;
falRv = falRv;
falS = falS;
falSack = falSack;
falSackDollar = falSackDollar;
falSackXmark = falSackXmark;
falSadCry = falSadCry;
falSadTear = falSadTear;
falSailboat = falSailboat;
falSalad = falSalad;
falSaltShaker = falSaltShaker;
falSandwich = falSandwich;
falSatellite = falSatellite;
falSatelliteDish = falSatelliteDish;
falSausage = falSausage;
falSave = falSave;
falSaveCircleArrowRight = falSaveCircleArrowRight;
falSaveCircleXmark = falSaveCircleXmark;
falSaveTimes = falSaveTimes;
falSaxHot = falSaxHot;
falSaxophone = falSaxophone;
falSaxophoneFire = falSaxophoneFire;
falScaleBalanced = falScaleBalanced;
falScaleUnbalanced = falScaleUnbalanced;
falScaleUnbalancedFlip = falScaleUnbalancedFlip;
falScalpel = falScalpel;
falScalpelLineDashed = falScalpelLineDashed;
falScalpelPath = falScalpelPath;
falScanner = falScanner;
falScannerGun = falScannerGun;
falScannerImage = falScannerImage;
falScannerKeyboard = falScannerKeyboard;
falScannerTouchscreen = falScannerTouchscreen;
falScarecrow = falScarecrow;
falScarf = falScarf;
falSchool = falSchool;
falSchoolCircleCheck = falSchoolCircleCheck;
falSchoolCircleExclamation = falSchoolCircleExclamation;
falSchoolCircleXmark = falSchoolCircleXmark;
falSchoolFlag = falSchoolFlag;
falSchoolLock = falSchoolLock;
falScissors = falScissors;
falScreencast = falScreencast;
falScreenshot = falScreenshot;
falScreenUsers = falScreenUsers;
falScrewdriver = falScrewdriver;
falScrewdriverWrench = falScrewdriverWrench;
falScribble = falScribble;
falScroll = falScroll;
falScrollOld = falScrollOld;
falScrollRibbon = falScrollRibbon;
falScrollTorah = falScrollTorah;
falScrubber = falScrubber;
falScythe = falScythe;
falSdCard = falSdCard;
falSdCards = falSdCards;
falSeal = falSeal;
falSealExclamation = falSealExclamation;
falSealQuestion = falSealQuestion;
falSearch = falSearch;
falSearchDollar = falSearchDollar;
falSearchLocation = falSearchLocation;
falSearchMinus = falSearchMinus;
falSearchPlus = falSearchPlus;
falSeatAirline = falSeatAirline;
falSection = falSection;
falSeedling = falSeedling;
falSemicolon = falSemicolon;
falSend = falSend;
falSendBack = falSendBack;
falSendBackward = falSendBackward;
falSensor = falSensor;
falSensorAlert = falSensorAlert;
falSensorCloud = falSensorCloud;
falSensorFire = falSensorFire;
falSensorOn = falSensorOn;
falSensorSmoke = falSensorSmoke;
falSensorTriangleExclamation = falSensorTriangleExclamation;
falServer = falServer;
falShapes = falShapes;
falShare = falShare;
falShareAll = falShareAll;
falShareAlt = falShareAlt;
falShareAltSquare = falShareAltSquare;
falShareFromSquare = falShareFromSquare;
falShareNodes = falShareNodes;
falShareSquare = falShareSquare;
falSheep = falSheep;
falSheetPlastic = falSheetPlastic;
falShekel = falShekel;
falShekelSign = falShekelSign;
falShelves = falShelves;
falShelvesEmpty = falShelvesEmpty;
falSheqel = falSheqel;
falSheqelSign = falSheqelSign;
falShield = falShield;
falShieldAlt = falShieldAlt;
falShieldBlank = falShieldBlank;
falShieldCat = falShieldCat;
falShieldCheck = falShieldCheck;
falShieldCross = falShieldCross;
falShieldDog = falShieldDog;
falShieldExclamation = falShieldExclamation;
falShieldHalved = falShieldHalved;
falShieldHeart = falShieldHeart;
falShieldKeyhole = falShieldKeyhole;
falShieldMinus = falShieldMinus;
falShieldPlus = falShieldPlus;
falShieldQuartered = falShieldQuartered;
falShieldSlash = falShieldSlash;
falShieldTimes = falShieldTimes;
falShieldVirus = falShieldVirus;
falShieldXmark = falShieldXmark;
falShip = falShip;
falShippingFast = falShippingFast;
falShippingTimed = falShippingTimed;
falShirt = falShirt;
falShirtLongSleeve = falShirtLongSleeve;
falShirtRunning = falShirtRunning;
falShirtTankTop = falShirtTankTop;
falShishKebab = falShishKebab;
falShoePrints = falShoePrints;
falShop = falShop;
falShopLock = falShopLock;
falShoppingBag = falShoppingBag;
falShoppingBasket = falShoppingBasket;
falShoppingBasketAlt = falShoppingBasketAlt;
falShoppingCart = falShoppingCart;
falShopSlash = falShopSlash;
falShortcake = falShortcake;
falShovel = falShovel;
falShovelSnow = falShovelSnow;
falShower = falShower;
falShowerAlt = falShowerAlt;
falShowerDown = falShowerDown;
falShredder = falShredder;
falShrimp = falShrimp;
falShuffle = falShuffle;
falShutters = falShutters;
falShuttlecock = falShuttlecock;
falShuttleSpace = falShuttleSpace;
falShuttleVan = falShuttleVan;
falSickle = falSickle;
falSidebar = falSidebar;
falSidebarFlip = falSidebarFlip;
falSigma = falSigma;
falSign = falSign;
falSignal = falSignal;
falSignal1 = falSignal1;
falSignal2 = falSignal2;
falSignal3 = falSignal3;
falSignal4 = falSignal4;
falSignal5 = falSignal5;
falSignalAlt = falSignalAlt;
falSignalAlt1 = falSignalAlt1;
falSignalAlt2 = falSignalAlt2;
falSignalAlt3 = falSignalAlt3;
falSignalAlt4 = falSignalAlt4;
falSignalAltSlash = falSignalAltSlash;
falSignalBars = falSignalBars;
falSignalBarsFair = falSignalBarsFair;
falSignalBarsGood = falSignalBarsGood;
falSignalBarsSlash = falSignalBarsSlash;
falSignalBarsStrong = falSignalBarsStrong;
falSignalBarsWeak = falSignalBarsWeak;
falSignalFair = falSignalFair;
falSignalGood = falSignalGood;
falSignalPerfect = falSignalPerfect;
falSignalSlash = falSignalSlash;
falSignalStream = falSignalStream;
falSignalStreamSlash = falSignalStreamSlash;
falSignalStrong = falSignalStrong;
falSignalWeak = falSignalWeak;
falSignature = falSignature;
falSignatureLock = falSignatureLock;
falSignatureSlash = falSignatureSlash;
falSignHanging = falSignHanging;
falSignIn = falSignIn;
falSignInAlt = falSignInAlt;
falSigning = falSigning;
falSignLanguage = falSignLanguage;
falSignOut = falSignOut;
falSignOutAlt = falSignOutAlt;
falSignsPost = falSignsPost;
falSimCard = falSimCard;
falSimCards = falSimCards;
falSink = falSink;
falSiren = falSiren;
falSirenOn = falSirenOn;
falSitemap = falSitemap;
falSkating = falSkating;
falSkeleton = falSkeleton;
falSkiBoot = falSkiBoot;
falSkiBootSki = falSkiBootSki;
falSkiing = falSkiing;
falSkiingNordic = falSkiingNordic;
falSkiJump = falSkiJump;
falSkiLift = falSkiLift;
falSkull = falSkull;
falSkullCow = falSkullCow;
falSkullCrossbones = falSkullCrossbones;
falSlash = falSlash;
falSlashBack = falSlashBack;
falSlashForward = falSlashForward;
falSledding = falSledding;
falSleigh = falSleigh;
falSlider = falSlider;
falSliders = falSliders;
falSlidersH = falSlidersH;
falSlidersHSquare = falSlidersHSquare;
falSlidersSimple = falSlidersSimple;
falSlidersUp = falSlidersUp;
falSlidersV = falSlidersV;
falSlidersVSquare = falSlidersVSquare;
falSlotMachine = falSlotMachine;
falSmile = falSmile;
falSmileBeam = falSmileBeam;
falSmilePlus = falSmilePlus;
falSmileWink = falSmileWink;
falSmog = falSmog;
falSmoke = falSmoke;
falSmoking = falSmoking;
falSmokingBan = falSmokingBan;
falSms = falSms;
falSnake = falSnake;
falSnooze = falSnooze;
falSnowBlowing = falSnowBlowing;
falSnowboarding = falSnowboarding;
falSnowflake = falSnowflake;
falSnowflakes = falSnowflakes;
falSnowman = falSnowman;
falSnowmanHead = falSnowmanHead;
falSnowmobile = falSnowmobile;
falSnowplow = falSnowplow;
falSoap = falSoap;
falSoccerBall = falSoccerBall;
falSocks = falSocks;
falSoftServe = falSoftServe;
falSolarPanel = falSolarPanel;
falSolarSystem = falSolarSystem;
falSort = falSort;
falSortAlphaAsc = falSortAlphaAsc;
falSortAlphaDesc = falSortAlphaDesc;
falSortAlphaDown = falSortAlphaDown;
falSortAlphaDownAlt = falSortAlphaDownAlt;
falSortAlphaUp = falSortAlphaUp;
falSortAlphaUpAlt = falSortAlphaUpAlt;
falSortAlt = falSortAlt;
falSortAmountAsc = falSortAmountAsc;
falSortAmountDesc = falSortAmountDesc;
falSortAmountDown = falSortAmountDown;
falSortAmountDownAlt = falSortAmountDownAlt;
falSortAmountUp = falSortAmountUp;
falSortAmountUpAlt = falSortAmountUpAlt;
falSortAsc = falSortAsc;
falSortCircle = falSortCircle;
falSortCircleDown = falSortCircleDown;
falSortCircleUp = falSortCircleUp;
falSortDesc = falSortDesc;
falSortDown = falSortDown;
falSortNumericAsc = falSortNumericAsc;
falSortNumericDesc = falSortNumericDesc;
falSortNumericDown = falSortNumericDown;
falSortNumericDownAlt = falSortNumericDownAlt;
falSortNumericUp = falSortNumericUp;
falSortNumericUpAlt = falSortNumericUpAlt;
falSortShapesDown = falSortShapesDown;
falSortShapesDownAlt = falSortShapesDownAlt;
falSortShapesUp = falSortShapesUp;
falSortShapesUpAlt = falSortShapesUpAlt;
falSortSizeDown = falSortSizeDown;
falSortSizeDownAlt = falSortSizeDownAlt;
falSortSizeUp = falSortSizeUp;
falSortSizeUpAlt = falSortSizeUpAlt;
falSortUp = falSortUp;
falSortUpDown = falSortUpDown;
falSoup = falSoup;
falSpa = falSpa;
falSpaceShuttle = falSpaceShuttle;
falSpaceStationMoon = falSpaceStationMoon;
falSpaceStationMoonAlt = falSpaceStationMoonAlt;
falSpaceStationMoonConstruction = falSpaceStationMoonConstruction;
falSpade = falSpade;
falSpaghettiMonsterFlying = falSpaghettiMonsterFlying;
falSparkles = falSparkles;
falSpeaker = falSpeaker;
falSpeakers = falSpeakers;
falSpellCheck = falSpellCheck;
falSpider = falSpider;
falSpiderBlackWidow = falSpiderBlackWidow;
falSpiderWeb = falSpiderWeb;
falSpinner = falSpinner;
falSpinnerThird = falSpinnerThird;
falSplit = falSplit;
falSplotch = falSplotch;
falSpoon = falSpoon;
falSportsball = falSportsball;
falSprayCan = falSprayCan;
falSprayCanSparkles = falSprayCanSparkles;
falSprinkler = falSprinkler;
falSprinklerCeiling = falSprinklerCeiling;
falSprout = falSprout;
falSquare = falSquare;
falSquare0 = falSquare0;
falSquare1 = falSquare1;
falSquare2 = falSquare2;
falSquare3 = falSquare3;
falSquare4 = falSquare4;
falSquare5 = falSquare5;
falSquare6 = falSquare6;
falSquare7 = falSquare7;
falSquare8 = falSquare8;
falSquare9 = falSquare9;
falSquareA = falSquareA;
falSquareALock = falSquareALock;
falSquareAmpersand = falSquareAmpersand;
falSquareArrowDown = falSquareArrowDown;
falSquareArrowDownLeft = falSquareArrowDownLeft;
falSquareArrowDownRight = falSquareArrowDownRight;
falSquareArrowLeft = falSquareArrowLeft;
falSquareArrowRight = falSquareArrowRight;
falSquareArrowUp = falSquareArrowUp;
falSquareArrowUpLeft = falSquareArrowUpLeft;
falSquareArrowUpRight = falSquareArrowUpRight;
falSquareB = falSquareB;
falSquareBolt = falSquareBolt;
falSquareC = falSquareC;
falSquareCaretDown = falSquareCaretDown;
falSquareCaretLeft = falSquareCaretLeft;
falSquareCaretRight = falSquareCaretRight;
falSquareCaretUp = falSquareCaretUp;
falSquareCheck = falSquareCheck;
falSquareChevronDown = falSquareChevronDown;
falSquareChevronLeft = falSquareChevronLeft;
falSquareChevronRight = falSquareChevronRight;
falSquareChevronUp = falSquareChevronUp;
falSquareCode = falSquareCode;
falSquareD = falSquareD;
falSquareDashed = falSquareDashed;
falSquareDivide = falSquareDivide;
falSquareDollar = falSquareDollar;
falSquareDown = falSquareDown;
falSquareDownLeft = falSquareDownLeft;
falSquareDownRight = falSquareDownRight;
falSquareE = falSquareE;
falSquareEllipsis = falSquareEllipsis;
falSquareEllipsisVertical = falSquareEllipsisVertical;
falSquareEnvelope = falSquareEnvelope;
falSquareExclamation = falSquareExclamation;
falSquareF = falSquareF;
falSquareFragile = falSquareFragile;
falSquareFull = falSquareFull;
falSquareG = falSquareG;
falSquareH = falSquareH;
falSquareHeart = falSquareHeart;
falSquareI = falSquareI;
falSquareInfo = falSquareInfo;
falSquareJ = falSquareJ;
falSquareK = falSquareK;
falSquareKanban = falSquareKanban;
falSquareL = falSquareL;
falSquareLeft = falSquareLeft;
falSquareList = falSquareList;
falSquareM = falSquareM;
falSquareMinus = falSquareMinus;
falSquareN = falSquareN;
falSquareNfi = falSquareNfi;
falSquareO = falSquareO;
falSquareP = falSquareP;
falSquareParking = falSquareParking;
falSquareParkingSlash = falSquareParkingSlash;
falSquarePen = falSquarePen;
falSquarePersonConfined = falSquarePersonConfined;
falSquarePhone = falSquarePhone;
falSquarePhoneFlip = falSquarePhoneFlip;
falSquarePhoneHangup = falSquarePhoneHangup;
falSquarePlus = falSquarePlus;
falSquarePollHorizontal = falSquarePollHorizontal;
falSquarePollVertical = falSquarePollVertical;
falSquareQ = falSquareQ;
falSquareQuarters = falSquareQuarters;
falSquareQuestion = falSquareQuestion;
falSquareQuote = falSquareQuote;
falSquareR = falSquareR;
falSquareRight = falSquareRight;
falSquareRing = falSquareRing;
falSquareRoot = falSquareRoot;
falSquareRootAlt = falSquareRootAlt;
falSquareRootVariable = falSquareRootVariable;
falSquareRss = falSquareRss;
falSquareS = falSquareS;
falSquareShareNodes = falSquareShareNodes;
falSquareSliders = falSquareSliders;
falSquareSlidersVertical = falSquareSlidersVertical;
falSquareSmall = falSquareSmall;
falSquareStar = falSquareStar;
falSquareT = falSquareT;
falSquareTerminal = falSquareTerminal;
falSquareThisWayUp = falSquareThisWayUp;
falSquareU = falSquareU;
falSquareUp = falSquareUp;
falSquareUpLeft = falSquareUpLeft;
falSquareUpRight = falSquareUpRight;
falSquareUser = falSquareUser;
falSquareV = falSquareV;
falSquareVirus = falSquareVirus;
falSquareW = falSquareW;
falSquareWineGlassCrack = falSquareWineGlassCrack;
falSquareX = falSquareX;
falSquareXmark = falSquareXmark;
falSquareY = falSquareY;
falSquareZ = falSquareZ;
falSquid = falSquid;
falSquirrel = falSquirrel;
falStaff = falStaff;
falStaffAesculapius = falStaffAesculapius;
falStaffSnake = falStaffSnake;
falStairs = falStairs;
falStamp = falStamp;
falStandardDefinition = falStandardDefinition;
falStar = falStar;
falStarAndCrescent = falStarAndCrescent;
falStarChristmas = falStarChristmas;
falStarCircle = falStarCircle;
falStarExclamation = falStarExclamation;
falStarfighter = falStarfighter;
falStarfighterAlt = falStarfighterAlt;
falStarfighterAltAdvanced = falStarfighterAltAdvanced;
falStarfighterTwinIonEngine = falStarfighterTwinIonEngine;
falStarfighterTwinIonEngineAdvanced = falStarfighterTwinIonEngineAdvanced;
falStarHalf = falStarHalf;
falStarHalfAlt = falStarHalfAlt;
falStarHalfStroke = falStarHalfStroke;
falStarOfDavid = falStarOfDavid;
falStarOfLife = falStarOfLife;
falStars = falStars;
falStarSharp = falStarSharp;
falStarSharpHalf = falStarSharpHalf;
falStarSharpHalfAlt = falStarSharpHalfAlt;
falStarSharpHalfStroke = falStarSharpHalfStroke;
falStarship = falStarship;
falStarshipFreighter = falStarshipFreighter;
falStarShooting = falStarShooting;
falSteak = falSteak;
falSteeringWheel = falSteeringWheel;
falStepBackward = falStepBackward;
falStepForward = falStepForward;
falSterlingSign = falSterlingSign;
falStethoscope = falStethoscope;
falStickyNote = falStickyNote;
falStocking = falStocking;
falStomach = falStomach;
falStop = falStop;
falStopCircle = falStopCircle;
falStopwatch = falStopwatch;
falStopwatch20 = falStopwatch20;
falStore = falStore;
falStoreAlt = falStoreAlt;
falStoreAltSlash = falStoreAltSlash;
falStoreLock = falStoreLock;
falStoreSlash = falStoreSlash;
falStrawberry = falStrawberry;
falStream = falStream;
falStreetView = falStreetView;
falStretcher = falStretcher;
falStrikethrough = falStrikethrough;
falStroopwafel = falStroopwafel;
falSubscript = falSubscript;
falSubtract = falSubtract;
falSubway = falSubway;
falSubwayTunnel = falSubwayTunnel;
falSuitcase = falSuitcase;
falSuitcaseMedical = falSuitcaseMedical;
falSuitcaseRolling = falSuitcaseRolling;
falSun = falSun;
falSunAlt = falSunAlt;
falSunBright = falSunBright;
falSunCloud = falSunCloud;
falSunDust = falSunDust;
falSunglasses = falSunglasses;
falSunHaze = falSunHaze;
falSunPlantWilt = falSunPlantWilt;
falSunrise = falSunrise;
falSunset = falSunset;
falSuperscript = falSuperscript;
falSurprise = falSurprise;
falSushi = falSushi;
falSushiRoll = falSushiRoll;
falSwatchbook = falSwatchbook;
falSwimmer = falSwimmer;
falSwimmingPool = falSwimmingPool;
falSword = falSword;
falSwordLaser = falSwordLaser;
falSwordLaserAlt = falSwordLaserAlt;
falSwords = falSwords;
falSwordsLaser = falSwordsLaser;
falSymbols = falSymbols;
falSynagogue = falSynagogue;
falSync = falSync;
falSyncAlt = falSyncAlt;
falSyringe = falSyringe;
falT = falT;
falTable = falTable;
falTableCells = falTableCells;
falTableCellsLarge = falTableCellsLarge;
falTableColumns = falTableColumns;
falTableLayout = falTableLayout;
falTableList = falTableList;
falTablePicnic = falTablePicnic;
falTablePivot = falTablePivot;
falTableRows = falTableRows;
falTablet = falTablet;
falTabletAlt = falTabletAlt;
falTabletAndroid = falTabletAndroid;
falTabletAndroidAlt = falTabletAndroidAlt;
falTabletButton = falTabletButton;
falTableTennis = falTableTennis;
falTableTennisPaddleBall = falTableTennisPaddleBall;
falTableTree = falTableTree;
falTabletRugged = falTabletRugged;
falTablets = falTablets;
falTabletScreen = falTabletScreen;
falTabletScreenButton = falTabletScreenButton;
falTachographDigital = falTachographDigital;
falTachometer = falTachometer;
falTachometerAlt = falTachometerAlt;
falTachometerAltAverage = falTachometerAltAverage;
falTachometerAltFast = falTachometerAltFast;
falTachometerAltFastest = falTachometerAltFastest;
falTachometerAltSlow = falTachometerAltSlow;
falTachometerAltSlowest = falTachometerAltSlowest;
falTachometerAverage = falTachometerAverage;
falTachometerFast = falTachometerFast;
falTachometerFastest = falTachometerFastest;
falTachometerSlow = falTachometerSlow;
falTachometerSlowest = falTachometerSlowest;
falTaco = falTaco;
falTag = falTag;
falTags = falTags;
falTally = falTally;
falTally1 = falTally1;
falTally2 = falTally2;
falTally3 = falTally3;
falTally4 = falTally4;
falTally5 = falTally5;
falTamale = falTamale;
falTanakh = falTanakh;
falTankWater = falTankWater;
falTape = falTape;
falTarp = falTarp;
falTarpDroplet = falTarpDroplet;
falTasks = falTasks;
falTasksAlt = falTasksAlt;
falTaxi = falTaxi;
falTaxiBus = falTaxiBus;
falTeddyBear = falTeddyBear;
falTeeth = falTeeth;
falTeethOpen = falTeethOpen;
falTelescope = falTelescope;
falTeletype = falTeletype;
falTeletypeAnswer = falTeletypeAnswer;
falTelevision = falTelevision;
falTemperature0 = falTemperature0;
falTemperature1 = falTemperature1;
falTemperature2 = falTemperature2;
falTemperature3 = falTemperature3;
falTemperature4 = falTemperature4;
falTemperatureArrowDown = falTemperatureArrowDown;
falTemperatureArrowUp = falTemperatureArrowUp;
falTemperatureDown = falTemperatureDown;
falTemperatureEmpty = falTemperatureEmpty;
falTemperatureFrigid = falTemperatureFrigid;
falTemperatureFull = falTemperatureFull;
falTemperatureHalf = falTemperatureHalf;
falTemperatureHigh = falTemperatureHigh;
falTemperatureHot = falTemperatureHot;
falTemperatureList = falTemperatureList;
falTemperatureLow = falTemperatureLow;
falTemperatureQuarter = falTemperatureQuarter;
falTemperatureSnow = falTemperatureSnow;
falTemperatureSun = falTemperatureSun;
falTemperatureThreeQuarters = falTemperatureThreeQuarters;
falTemperatureUp = falTemperatureUp;
falTenge = falTenge;
falTengeSign = falTengeSign;
falTennisBall = falTennisBall;
falTent = falTent;
falTentArrowDownToLine = falTentArrowDownToLine;
falTentArrowLeftRight = falTentArrowLeftRight;
falTentArrowsDown = falTentArrowsDown;
falTentArrowTurnLeft = falTentArrowTurnLeft;
falTents = falTents;
falTerminal = falTerminal;
falText = falText;
falTextHeight = falTextHeight;
falTextSize = falTextSize;
falTextSlash = falTextSlash;
falTextWidth = falTextWidth;
falTh = falTh;
falTheaterMasks = falTheaterMasks;
falThermometer = falThermometer;
falThermometer0 = falThermometer0;
falThermometer1 = falThermometer1;
falThermometer2 = falThermometer2;
falThermometer3 = falThermometer3;
falThermometer4 = falThermometer4;
falThermometerEmpty = falThermometerEmpty;
falThermometerFull = falThermometerFull;
falThermometerHalf = falThermometerHalf;
falThermometerQuarter = falThermometerQuarter;
falThermometerThreeQuarters = falThermometerThreeQuarters;
falTheta = falTheta;
falThLarge = falThLarge;
falThList = falThList;
falThoughtBubble = falThoughtBubble;
falThumbsDown = falThumbsDown;
falThumbsUp = falThumbsUp;
falThumbtack = falThumbtack;
falThumbTack = falThumbTack;
falThunderstorm = falThunderstorm;
falThunderstormMoon = falThunderstormMoon;
falThunderstormSun = falThunderstormSun;
falTick = falTick;
falTicket = falTicket;
falTicketAirline = falTicketAirline;
falTicketAlt = falTicketAlt;
falTicketsAirline = falTicketsAirline;
falTicketSimple = falTicketSimple;
falTilde = falTilde;
falTimeline = falTimeline;
falTimelineArrow = falTimelineArrow;
falTimer = falTimer;
falTimes = falTimes;
falTimesCircle = falTimesCircle;
falTimesHexagon = falTimesHexagon;
falTimesOctagon = falTimesOctagon;
falTimesRectangle = falTimesRectangle;
falTimesSquare = falTimesSquare;
falTimesToSlot = falTimesToSlot;
falTint = falTint;
falTintSlash = falTintSlash;
falTire = falTire;
falTired = falTired;
falTireFlat = falTireFlat;
falTirePressureWarning = falTirePressureWarning;
falTireRugged = falTireRugged;
falToggleOff = falToggleOff;
falToggleOn = falToggleOn;
falToilet = falToilet;
falToiletPaper = falToiletPaper;
falToiletPaperAlt = falToiletPaperAlt;
falToiletPaperBlank = falToiletPaperBlank;
falToiletPaperBlankUnder = falToiletPaperBlankUnder;
falToiletPaperReverse = falToiletPaperReverse;
falToiletPaperReverseAlt = falToiletPaperReverseAlt;
falToiletPaperReverseSlash = falToiletPaperReverseSlash;
falToiletPaperSlash = falToiletPaperSlash;
falToiletPaperUnder = falToiletPaperUnder;
falToiletPaperUnderSlash = falToiletPaperUnderSlash;
falToiletPortable = falToiletPortable;
falToiletsPortable = falToiletsPortable;
falTomato = falTomato;
falTombstone = falTombstone;
falTombstoneAlt = falTombstoneAlt;
falTombstoneBlank = falTombstoneBlank;
falToolbox = falToolbox;
falTools = falTools;
falTooth = falTooth;
falToothbrush = falToothbrush;
falTorah = falTorah;
falToriiGate = falToriiGate;
falTornado = falTornado;
falTowerBroadcast = falTowerBroadcast;
falTowerCell = falTowerCell;
falTowerControl = falTowerControl;
falTowerObservation = falTowerObservation;
falTractor = falTractor;
falTrademark = falTrademark;
falTrafficCone = falTrafficCone;
falTrafficLight = falTrafficLight;
falTrafficLightGo = falTrafficLightGo;
falTrafficLightSlow = falTrafficLightSlow;
falTrafficLightStop = falTrafficLightStop;
falTrailer = falTrailer;
falTrain = falTrain;
falTrainSubway = falTrainSubway;
falTrainSubwayTunnel = falTrainSubwayTunnel;
falTrainTrack = falTrainTrack;
falTrainTram = falTrainTram;
falTrainTunnel = falTrainTunnel;
falTram = falTram;
falTransformerBolt = falTransformerBolt;
falTransgender = falTransgender;
falTransgenderAlt = falTransgenderAlt;
falTransporter = falTransporter;
falTransporter1 = falTransporter1;
falTransporter2 = falTransporter2;
falTransporter3 = falTransporter3;
falTransporter4 = falTransporter4;
falTransporter5 = falTransporter5;
falTransporter6 = falTransporter6;
falTransporter7 = falTransporter7;
falTransporterEmpty = falTransporterEmpty;
falTrash = falTrash;
falTrashAlt = falTrashAlt;
falTrashAltSlash = falTrashAltSlash;
falTrashArrowTurnLeft = falTrashArrowTurnLeft;
falTrashArrowUp = falTrashArrowUp;
falTrashCan = falTrashCan;
falTrashCanArrowTurnLeft = falTrashCanArrowTurnLeft;
falTrashCanArrowUp = falTrashCanArrowUp;
falTrashCanCheck = falTrashCanCheck;
falTrashCanClock = falTrashCanClock;
falTrashCanList = falTrashCanList;
falTrashCanPlus = falTrashCanPlus;
falTrashCanSlash = falTrashCanSlash;
falTrashCanUndo = falTrashCanUndo;
falTrashCanXmark = falTrashCanXmark;
falTrashCheck = falTrashCheck;
falTrashCircle = falTrashCircle;
falTrashClock = falTrashClock;
falTrashList = falTrashList;
falTrashPlus = falTrashPlus;
falTrashRestore = falTrashRestore;
falTrashRestoreAlt = falTrashRestoreAlt;
falTrashSlash = falTrashSlash;
falTrashUndo = falTrashUndo;
falTrashUndoAlt = falTrashUndoAlt;
falTrashXmark = falTrashXmark;
falTreasureChest = falTreasureChest;
falTree = falTree;
falTreeAlt = falTreeAlt;
falTreeChristmas = falTreeChristmas;
falTreeCity = falTreeCity;
falTreeDeciduous = falTreeDeciduous;
falTreeDecorated = falTreeDecorated;
falTreeLarge = falTreeLarge;
falTreePalm = falTreePalm;
falTrees = falTrees;
falTrianBalbot = falTrianBalbot;
falTriangle = falTriangle;
falTriangleCircleSquare = falTriangleCircleSquare;
falTriangleExclamation = falTriangleExclamation;
falTriangleInstrument = falTriangleInstrument;
falTriangleMusic = falTriangleMusic;
falTrianglePersonDigging = falTrianglePersonDigging;
falTrillium = falTrillium;
falTrophy = falTrophy;
falTrophyAlt = falTrophyAlt;
falTrophyStar = falTrophyStar;
falTrowel = falTrowel;
falTrowelBricks = falTrowelBricks;
falTruck = falTruck;
falTruckArrowRight = falTruckArrowRight;
falTruckBolt = falTruckBolt;
falTruckClock = falTruckClock;
falTruckContainer = falTruckContainer;
falTruckContainerEmpty = falTruckContainerEmpty;
falTruckCouch = falTruckCouch;
falTruckDroplet = falTruckDroplet;
falTruckFast = falTruckFast;
falTruckField = falTruckField;
falTruckFieldUn = falTruckFieldUn;
falTruckFlatbed = falTruckFlatbed;
falTruckFront = falTruckFront;
falTruckLoading = falTruckLoading;
falTruckMedical = falTruckMedical;
falTruckMonster = falTruckMonster;
falTruckMoving = falTruckMoving;
falTruckPickup = falTruckPickup;
falTruckPlane = falTruckPlane;
falTruckPlow = falTruckPlow;
falTruckRamp = falTruckRamp;
falTruckRampBox = falTruckRampBox;
falTruckRampCouch = falTruckRampCouch;
falTruckTow = falTruckTow;
falTrumpet = falTrumpet;
falTry = falTry;
falTshirt = falTshirt;
falTShirt = falTShirt;
falTty = falTty;
falTtyAnswer = falTtyAnswer;
falTugrikSign = falTugrikSign;
falTurkey = falTurkey;
falTurkishLira = falTurkishLira;
falTurkishLiraSign = falTurkishLiraSign;
falTurnDown = falTurnDown;
falTurnDownLeft = falTurnDownLeft;
falTurnDownRight = falTurnDownRight;
falTurntable = falTurntable;
falTurnUp = falTurnUp;
falTurtle = falTurtle;
falTv = falTv;
falTvAlt = falTvAlt;
falTvMusic = falTvMusic;
falTvRetro = falTvRetro;
falTypewriter = falTypewriter;
falU = falU;
falUfo = falUfo;
falUfoBeam = falUfoBeam;
falUmbrella = falUmbrella;
falUmbrellaAlt = falUmbrellaAlt;
falUmbrellaBeach = falUmbrellaBeach;
falUmbrellaSimple = falUmbrellaSimple;
falUnderline = falUnderline;
falUndo = falUndo;
falUndoAlt = falUndoAlt;
falUnicorn = falUnicorn;
falUniformMartialArts = falUniformMartialArts;
falUnion = falUnion;
falUniversalAccess = falUniversalAccess;
falUniversity = falUniversity;
falUnlink = falUnlink;
falUnlock = falUnlock;
falUnlockAlt = falUnlockAlt;
falUnlockKeyhole = falUnlockKeyhole;
falUnsorted = falUnsorted;
falUp = falUp;
falUpDown = falUpDown;
falUpDownLeftRight = falUpDownLeftRight;
falUpFromBracket = falUpFromBracket;
falUpFromDottedLine = falUpFromDottedLine;
falUpFromLine = falUpFromLine;
falUpLeft = falUpLeft;
falUpload = falUpload;
falUpLong = falUpLong;
falUpRight = falUpRight;
falUpRightAndDownLeftFromCenter = falUpRightAndDownLeftFromCenter;
falUpRightFromSquare = falUpRightFromSquare;
falUpToDottedLine = falUpToDottedLine;
falUpToLine = falUpToLine;
falUsbDrive = falUsbDrive;
falUsd = falUsd;
falUsdCircle = falUsdCircle;
falUsdSquare = falUsdSquare;
falUser = falUser;
falUserAlien = falUserAlien;
falUserAlt = falUserAlt;
falUserAltSlash = falUserAltSlash;
falUserAstronaut = falUserAstronaut;
falUserBountyHunter = falUserBountyHunter;
falUserChart = falUserChart;
falUserCheck = falUserCheck;
falUserChef = falUserChef;
falUserCircle = falUserCircle;
falUserClock = falUserClock;
falUserCog = falUserCog;
falUserConstruction = falUserConstruction;
falUserCowboy = falUserCowboy;
falUserCrown = falUserCrown;
falUserDoctor = falUserDoctor;
falUserDoctorHair = falUserDoctorHair;
falUserDoctorHairLong = falUserDoctorHairLong;
falUserDoctorMessage = falUserDoctorMessage;
falUserEdit = falUserEdit;
falUserFriends = falUserFriends;
falUserGear = falUserGear;
falUserGraduate = falUserGraduate;
falUserGroup = falUserGroup;
falUserGroupCrown = falUserGroupCrown;
falUserHair = falUserHair;
falUserHairBuns = falUserHairBuns;
falUserHairLong = falUserHairLong;
falUserHairMullet = falUserHairMullet;
falUserHardHat = falUserHardHat;
falUserHeadset = falUserHeadset;
falUserHelmetSafety = falUserHelmetSafety;
falUserInjured = falUserInjured;
falUserLarge = falUserLarge;
falUserLargeSlash = falUserLargeSlash;
falUserLock = falUserLock;
falUserMd = falUserMd;
falUserMdChat = falUserMdChat;
falUserMinus = falUserMinus;
falUserMusic = falUserMusic;
falUserNinja = falUserNinja;
falUserNurse = falUserNurse;
falUserNurseHair = falUserNurseHair;
falUserNurseHairLong = falUserNurseHairLong;
falUserPen = falUserPen;
falUserPilot = falUserPilot;
falUserPilotTie = falUserPilotTie;
falUserPlus = falUserPlus;
falUserPolice = falUserPolice;
falUserPoliceTie = falUserPoliceTie;
falUserRobot = falUserRobot;
falUserRobotXmarks = falUserRobotXmarks;
falUsers = falUsers;
falUsersBetweenLines = falUsersBetweenLines;
falUsersClass = falUsersClass;
falUsersCog = falUsersCog;
falUsersCrown = falUsersCrown;
falUserSecret = falUserSecret;
falUsersGear = falUsersGear;
falUserShakespeare = falUserShakespeare;
falUserShield = falUserShield;
falUserSlash = falUserSlash;
falUsersLine = falUsersLine;
falUsersMedical = falUsersMedical;
falUsersRays = falUsersRays;
falUsersRectangle = falUsersRectangle;
falUsersSlash = falUsersSlash;
falUsersViewfinder = falUsersViewfinder;
falUserTag = falUserTag;
falUserTie = falUserTie;
falUserTieHair = falUserTieHair;
falUserTieHairLong = falUserTieHairLong;
falUserTimes = falUserTimes;
falUserUnlock = falUserUnlock;
falUserVisor = falUserVisor;
falUserVneck = falUserVneck;
falUserVneckHair = falUserVneckHair;
falUserVneckHairLong = falUserVneckHairLong;
falUserXmark = falUserXmark;
falUtensilFork = falUtensilFork;
falUtensilKnife = falUtensilKnife;
falUtensils = falUtensils;
falUtensilsAlt = falUtensilsAlt;
falUtensilSpoon = falUtensilSpoon;
falUtensilsSlash = falUtensilsSlash;
falUtilityPole = falUtilityPole;
falUtilityPoleDouble = falUtilityPoleDouble;
falV = falV;
falVacuum = falVacuum;
falVacuumRobot = falVacuumRobot;
falValueAbsolute = falValueAbsolute;
falVanShuttle = falVanShuttle;
falVault = falVault;
falVcard = falVcard;
falVectorCircle = falVectorCircle;
falVectorPolygon = falVectorPolygon;
falVectorSquare = falVectorSquare;
falVentDamper = falVentDamper;
falVenus = falVenus;
falVenusDouble = falVenusDouble;
falVenusMars = falVenusMars;
falVest = falVest;
falVestPatches = falVestPatches;
falVhs = falVhs;
falVial = falVial;
falVialCircleCheck = falVialCircleCheck;
falVials = falVials;
falVialVirus = falVialVirus;
falVideo = falVideo;
falVideoArrowDownLeft = falVideoArrowDownLeft;
falVideoArrowUpRight = falVideoArrowUpRight;
falVideoCamera = falVideoCamera;
falVideoCircle = falVideoCircle;
falVideoHandheld = falVideoHandheld;
falVideoPlus = falVideoPlus;
falVideoSlash = falVideoSlash;
falVihara = falVihara;
falViolin = falViolin;
falVirus = falVirus;
falVirusCovid = falVirusCovid;
falVirusCovidSlash = falVirusCovidSlash;
falViruses = falViruses;
falVirusSlash = falVirusSlash;
falVoicemail = falVoicemail;
falVolcano = falVolcano;
falVolleyball = falVolleyball;
falVolleyballBall = falVolleyballBall;
falVolume = falVolume;
falVolumeControlPhone = falVolumeControlPhone;
falVolumeDown = falVolumeDown;
falVolumeHigh = falVolumeHigh;
falVolumeLow = falVolumeLow;
falVolumeMedium = falVolumeMedium;
falVolumeMute = falVolumeMute;
falVolumeOff = falVolumeOff;
falVolumeSlash = falVolumeSlash;
falVolumeTimes = falVolumeTimes;
falVolumeUp = falVolumeUp;
falVolumeXmark = falVolumeXmark;
falVoteNay = falVoteNay;
falVoteYea = falVoteYea;
falVrCardboard = falVrCardboard;
falW = falW;
falWaffle = falWaffle;
falWagonCovered = falWagonCovered;
falWalker = falWalker;
falWalkieTalkie = falWalkieTalkie;
falWalking = falWalking;
falWallBrick = falWallBrick;
falWallet = falWallet;
falWand = falWand;
falWandMagic = falWandMagic;
falWandMagicSparkles = falWandMagicSparkles;
falWandSparkles = falWandSparkles;
falWarehouse = falWarehouse;
falWarehouseAlt = falWarehouseAlt;
falWarehouseFull = falWarehouseFull;
falWarning = falWarning;
falWasher = falWasher;
falWashingMachine = falWashingMachine;
falWatch = falWatch;
falWatchApple = falWatchApple;
falWatchCalculator = falWatchCalculator;
falWatchFitness = falWatchFitness;
falWatchSmart = falWatchSmart;
falWater = falWater;
falWaterArrowDown = falWaterArrowDown;
falWaterArrowUp = falWaterArrowUp;
falWaterLadder = falWaterLadder;
falWaterLower = falWaterLower;
falWatermelonSlice = falWatermelonSlice;
falWaterRise = falWaterRise;
falWaveform = falWaveform;
falWaveformCircle = falWaveformCircle;
falWaveformLines = falWaveformLines;
falWaveformPath = falWaveformPath;
falWavePulse = falWavePulse;
falWaveSine = falWaveSine;
falWaveSquare = falWaveSquare;
falWaveTriangle = falWaveTriangle;
falWebcam = falWebcam;
falWebcamSlash = falWebcamSlash;
falWeight = falWeight;
falWeightHanging = falWeightHanging;
falWeightScale = falWeightScale;
falWhale = falWhale;
falWheat = falWheat;
falWheatAlt = falWheatAlt;
falWheatAwn = falWheatAwn;
falWheatAwnCircleExclamation = falWheatAwnCircleExclamation;
falWheatAwnSlash = falWheatAwnSlash;
falWheatSlash = falWheatSlash;
falWheelchair = falWheelchair;
falWheelchairAlt = falWheelchairAlt;
falWheelchairMove = falWheelchairMove;
falWhiskeyGlass = falWhiskeyGlass;
falWhiskeyGlassIce = falWhiskeyGlassIce;
falWhistle = falWhistle;
falWifi = falWifi;
falWifi1 = falWifi1;
falWifi2 = falWifi2;
falWifi3 = falWifi3;
falWifiExclamation = falWifiExclamation;
falWifiFair = falWifiFair;
falWifiSlash = falWifiSlash;
falWifiStrong = falWifiStrong;
falWifiWeak = falWifiWeak;
falWind = falWind;
falWindCircleExclamation = falWindCircleExclamation;
falWindow = falWindow;
falWindowAlt = falWindowAlt;
falWindowClose = falWindowClose;
falWindowFlip = falWindowFlip;
falWindowFrame = falWindowFrame;
falWindowFrameOpen = falWindowFrameOpen;
falWindowMaximize = falWindowMaximize;
falWindowMinimize = falWindowMinimize;
falWindowRestore = falWindowRestore;
falWindsock = falWindsock;
falWindTurbine = falWindTurbine;
falWindWarning = falWindWarning;
falWineBottle = falWineBottle;
falWineGlass = falWineGlass;
falWineGlassAlt = falWineGlassAlt;
falWineGlassCrack = falWineGlassCrack;
falWineGlassEmpty = falWineGlassEmpty;
falWon = falWon;
falWonSign = falWonSign;
falWorm = falWorm;
falWreath = falWreath;
falWrench = falWrench;
falWrenchSimple = falWrenchSimple;
falX = falX;
falXmark = falXmark;
falXmarkCircle = falXmarkCircle;
falXmarkHexagon = falXmarkHexagon;
falXmarkLarge = falXmarkLarge;
falXmarkOctagon = falXmarkOctagon;
falXmarksLines = falXmarksLines;
falXmarkSquare = falXmarkSquare;
falXmarkToSlot = falXmarkToSlot;
falXRay = falXRay;
falY = falY;
falYen = falYen;
falYenSign = falYenSign;
falYinYang = falYinYang;
falZ = falZ;
falZap = falZap;
falZzz = falZzz;
far0 = far0;
far00 = far00;
far1 = far1;
far100 = far100;
far2 = far2;
far3 = far3;
far360Degrees = far360Degrees;
far4 = far4;
far5 = far5;
far6 = far6;
far7 = far7;
far8 = far8;
far9 = far9;
farA = farA;
farAbacus = farAbacus;
farAccentGrave = farAccentGrave;
farAcorn = farAcorn;
farAd = farAd;
farAdd = farAdd;
farAddressBook = farAddressBook;
farAddressCard = farAddressCard;
farAdjust = farAdjust;
farAirConditioner = farAirConditioner;
farAirFreshener = farAirFreshener;
farAirplay = farAirplay;
farAlarmClock = farAlarmClock;
farAlarmExclamation = farAlarmExclamation;
farAlarmPlus = farAlarmPlus;
farAlarmSnooze = farAlarmSnooze;
farAlbum = farAlbum;
farAlbumCirclePlus = farAlbumCirclePlus;
farAlbumCircleUser = farAlbumCircleUser;
farAlbumCollection = farAlbumCollection;
farAlbumCollectionCirclePlus = farAlbumCollectionCirclePlus;
farAlbumCollectionCircleUser = farAlbumCollectionCircleUser;
farAlicorn = farAlicorn;
farAlien = farAlien;
farAlien8bit = farAlien8bit;
farAlienMonster = farAlienMonster;
farAlignCenter = farAlignCenter;
farAlignJustify = farAlignJustify;
farAlignLeft = farAlignLeft;
farAlignRight = farAlignRight;
farAlignSlash = farAlignSlash;
farAllergies = farAllergies;
farAlt = farAlt;
farAmbulance = farAmbulance;
farAmericanSignLanguageInterpreting = farAmericanSignLanguageInterpreting;
farAmpersand = farAmpersand;
farAmpGuitar = farAmpGuitar;
farAnalytics = farAnalytics;
farAnchor = farAnchor;
farAnchorCircleCheck = farAnchorCircleCheck;
farAnchorCircleExclamation = farAnchorCircleExclamation;
farAnchorCircleXmark = farAnchorCircleXmark;
farAnchorLock = farAnchorLock;
farAngel = farAngel;
farAngle = farAngle;
farAngle90 = farAngle90;
farAngleDoubleDown = farAngleDoubleDown;
farAngleDoubleLeft = farAngleDoubleLeft;
farAngleDoubleRight = farAngleDoubleRight;
farAngleDoubleUp = farAngleDoubleUp;
farAngleDown = farAngleDown;
farAngleLeft = farAngleLeft;
farAngleRight = farAngleRight;
farAnglesDown = farAnglesDown;
farAnglesLeft = farAnglesLeft;
farAnglesRight = farAnglesRight;
farAnglesUp = farAnglesUp;
farAngleUp = farAngleUp;
farAngry = farAngry;
farAnkh = farAnkh;
farApartment = farApartment;
farAperture = farAperture;
farApostrophe = farApostrophe;
farAppleAlt = farAppleAlt;
farAppleCore = farAppleCore;
farAppleCrate = farAppleCrate;
farAppleWhole = farAppleWhole;
farArchive = farArchive;
farArchway = farArchway;
farAreaChart = farAreaChart;
farArrowAltCircleDown = farArrowAltCircleDown;
farArrowAltCircleLeft = farArrowAltCircleLeft;
farArrowAltCircleRight = farArrowAltCircleRight;
farArrowAltCircleUp = farArrowAltCircleUp;
farArrowAltDown = farArrowAltDown;
farArrowAltFromBottom = farArrowAltFromBottom;
farArrowAltFromLeft = farArrowAltFromLeft;
farArrowAltFromRight = farArrowAltFromRight;
farArrowAltFromTop = farArrowAltFromTop;
farArrowAltLeft = farArrowAltLeft;
farArrowAltRight = farArrowAltRight;
farArrowAltSquareDown = farArrowAltSquareDown;
farArrowAltSquareLeft = farArrowAltSquareLeft;
farArrowAltSquareRight = farArrowAltSquareRight;
farArrowAltSquareUp = farArrowAltSquareUp;
farArrowAltToBottom = farArrowAltToBottom;
farArrowAltToLeft = farArrowAltToLeft;
farArrowAltToRight = farArrowAltToRight;
farArrowAltToTop = farArrowAltToTop;
farArrowAltUp = farArrowAltUp;
farArrowCircleDown = farArrowCircleDown;
farArrowCircleLeft = farArrowCircleLeft;
farArrowCircleRight = farArrowCircleRight;
farArrowCircleUp = farArrowCircleUp;
farArrowDown = farArrowDown;
farArrowDown19 = farArrowDown19;
farArrowDown91 = farArrowDown91;
farArrowDownArrowUp = farArrowDownArrowUp;
farArrowDownAZ = farArrowDownAZ;
farArrowDownBigSmall = farArrowDownBigSmall;
farArrowDownFromDottedLine = farArrowDownFromDottedLine;
farArrowDownFromLine = farArrowDownFromLine;
farArrowDownLeft = farArrowDownLeft;
farArrowDownLeftAndArrowUpRightToCenter = farArrowDownLeftAndArrowUpRightToCenter;
farArrowDownLong = farArrowDownLong;
farArrowDownRight = farArrowDownRight;
farArrowDownShortWide = farArrowDownShortWide;
farArrowDownSmallBig = farArrowDownSmallBig;
farArrowDownSquareTriangle = farArrowDownSquareTriangle;
farArrowDownToArc = farArrowDownToArc;
farArrowDownToBracket = farArrowDownToBracket;
farArrowDownToDottedLine = farArrowDownToDottedLine;
farArrowDownToLine = farArrowDownToLine;
farArrowDownToSquare = farArrowDownToSquare;
farArrowDownTriangleSquare = farArrowDownTriangleSquare;
farArrowDownUpAcrossLine = farArrowDownUpAcrossLine;
farArrowDownUpLock = farArrowDownUpLock;
farArrowDownWideShort = farArrowDownWideShort;
farArrowDownZA = farArrowDownZA;
farArrowFromBottom = farArrowFromBottom;
farArrowFromLeft = farArrowFromLeft;
farArrowFromRight = farArrowFromRight;
farArrowFromTop = farArrowFromTop;
farArrowLeft = farArrowLeft;
farArrowLeftFromLine = farArrowLeftFromLine;
farArrowLeftLong = farArrowLeftLong;
farArrowLeftLongToLine = farArrowLeftLongToLine;
farArrowLeftRotate = farArrowLeftRotate;
farArrowLeftToLine = farArrowLeftToLine;
farArrowPointer = farArrowPointer;
farArrowRight = farArrowRight;
farArrowRightArrowLeft = farArrowRightArrowLeft;
farArrowRightFromArc = farArrowRightFromArc;
farArrowRightFromBracket = farArrowRightFromBracket;
farArrowRightFromFile = farArrowRightFromFile;
farArrowRightFromLine = farArrowRightFromLine;
farArrowRightLong = farArrowRightLong;
farArrowRightLongToLine = farArrowRightLongToLine;
farArrowRightRotate = farArrowRightRotate;
farArrowRightToArc = farArrowRightToArc;
farArrowRightToBracket = farArrowRightToBracket;
farArrowRightToCity = farArrowRightToCity;
farArrowRightToFile = farArrowRightToFile;
farArrowRightToLine = farArrowRightToLine;
farArrowRotateBack = farArrowRotateBack;
farArrowRotateBackward = farArrowRotateBackward;
farArrowRotateForward = farArrowRotateForward;
farArrowRotateLeft = farArrowRotateLeft;
farArrowRotateRight = farArrowRotateRight;
farArrows = farArrows;
farArrowsAlt = farArrowsAlt;
farArrowsAltH = farArrowsAltH;
farArrowsAltV = farArrowsAltV;
farArrowsCross = farArrowsCross;
farArrowsDownToLine = farArrowsDownToLine;
farArrowsDownToPeople = farArrowsDownToPeople;
farArrowsFromDottedLine = farArrowsFromDottedLine;
farArrowsFromLine = farArrowsFromLine;
farArrowsH = farArrowsH;
farArrowsLeftRight = farArrowsLeftRight;
farArrowsLeftRightToLine = farArrowsLeftRightToLine;
farArrowsMaximize = farArrowsMaximize;
farArrowsMinimize = farArrowsMinimize;
farArrowSquareDown = farArrowSquareDown;
farArrowSquareLeft = farArrowSquareLeft;
farArrowSquareRight = farArrowSquareRight;
farArrowSquareUp = farArrowSquareUp;
farArrowsRepeat = farArrowsRepeat;
farArrowsRepeat1 = farArrowsRepeat1;
farArrowsRetweet = farArrowsRetweet;
farArrowsRotate = farArrowsRotate;
farArrowsSpin = farArrowsSpin;
farArrowsSplitUpAndLeft = farArrowsSplitUpAndLeft;
farArrowsToCircle = farArrowsToCircle;
farArrowsToDot = farArrowsToDot;
farArrowsToDottedLine = farArrowsToDottedLine;
farArrowsToEye = farArrowsToEye;
farArrowsToLine = farArrowsToLine;
farArrowsTurnRight = farArrowsTurnRight;
farArrowsTurnToDots = farArrowsTurnToDots;
farArrowsUpDown = farArrowsUpDown;
farArrowsUpDownLeftRight = farArrowsUpDownLeftRight;
farArrowsUpToLine = farArrowsUpToLine;
farArrowsV = farArrowsV;
farArrowToBottom = farArrowToBottom;
farArrowToLeft = farArrowToLeft;
farArrowToRight = farArrowToRight;
farArrowToTop = farArrowToTop;
farArrowTrendDown = farArrowTrendDown;
farArrowTrendUp = farArrowTrendUp;
farArrowTurnDown = farArrowTurnDown;
farArrowTurnDownLeft = farArrowTurnDownLeft;
farArrowTurnDownRight = farArrowTurnDownRight;
farArrowTurnRight = farArrowTurnRight;
farArrowTurnUp = farArrowTurnUp;
farArrowUp = farArrowUp;
farArrowUp19 = farArrowUp19;
farArrowUp91 = farArrowUp91;
farArrowUpArrowDown = farArrowUpArrowDown;
farArrowUpAZ = farArrowUpAZ;
farArrowUpBigSmall = farArrowUpBigSmall;
farArrowUpFromArc = farArrowUpFromArc;
farArrowUpFromBracket = farArrowUpFromBracket;
farArrowUpFromDottedLine = farArrowUpFromDottedLine;
farArrowUpFromGroundWater = farArrowUpFromGroundWater;
farArrowUpFromLine = farArrowUpFromLine;
farArrowUpFromSquare = farArrowUpFromSquare;
farArrowUpFromWaterPump = farArrowUpFromWaterPump;
farArrowUpLeft = farArrowUpLeft;
farArrowUpLeftFromCircle = farArrowUpLeftFromCircle;
farArrowUpLong = farArrowUpLong;
farArrowUpRight = farArrowUpRight;
farArrowUpRightAndArrowDownLeftFromCenter = farArrowUpRightAndArrowDownLeftFromCenter;
farArrowUpRightDots = farArrowUpRightDots;
farArrowUpRightFromSquare = farArrowUpRightFromSquare;
farArrowUpShortWide = farArrowUpShortWide;
farArrowUpSmallBig = farArrowUpSmallBig;
farArrowUpSquareTriangle = farArrowUpSquareTriangle;
farArrowUpToDottedLine = farArrowUpToDottedLine;
farArrowUpToLine = farArrowUpToLine;
farArrowUpTriangleSquare = farArrowUpTriangleSquare;
farArrowUpWideShort = farArrowUpWideShort;
farArrowUpZA = farArrowUpZA;
farAslInterpreting = farAslInterpreting;
farAssistiveListeningSystems = farAssistiveListeningSystems;
farAsterisk = farAsterisk;
farAt = farAt;
farAtlas = farAtlas;
farAtom = farAtom;
farAtomAlt = farAtomAlt;
farAtomSimple = farAtomSimple;
farAudioDescription = farAudioDescription;
farAudioDescriptionSlash = farAudioDescriptionSlash;
farAustralSign = farAustralSign;
farAutomobile = farAutomobile;
farAvocado = farAvocado;
farAward = farAward;
farAwardSimple = farAwardSimple;
farAxe = farAxe;
farAxeBattle = farAxeBattle;
farB = farB;
farBaby = farBaby;
farBabyCarriage = farBabyCarriage;
farBackpack = farBackpack;
farBackspace = farBackspace;
farBackward = farBackward;
farBackwardFast = farBackwardFast;
farBackwardStep = farBackwardStep;
farBacon = farBacon;
farBacteria = farBacteria;
farBacterium = farBacterium;
farBadge = farBadge;
farBadgeCheck = farBadgeCheck;
farBadgeDollar = farBadgeDollar;
farBadgePercent = farBadgePercent;
farBadgerHoney = farBadgerHoney;
farBadgeSheriff = farBadgeSheriff;
farBadminton = farBadminton;
farBagel = farBagel;
farBagShopping = farBagShopping;
farBagsShopping = farBagsShopping;
farBaguette = farBaguette;
farBahai = farBahai;
farBahtSign = farBahtSign;
farBalanceScale = farBalanceScale;
farBalanceScaleLeft = farBalanceScaleLeft;
farBalanceScaleRight = farBalanceScaleRight;
farBalloon = farBalloon;
farBalloons = farBalloons;
farBallot = farBallot;
farBallotCheck = farBallotCheck;
farBallPile = farBallPile;
farBan = farBan;
farBanana = farBanana;
farBanBug = farBanBug;
farBandage = farBandage;
farBandAid = farBandAid;
farBangladeshiTakaSign = farBangladeshiTakaSign;
farBanjo = farBanjo;
farBank = farBank;
farBanParking = farBanParking;
farBanSmoking = farBanSmoking;
farBarChart = farBarChart;
farBarcode = farBarcode;
farBarcodeAlt = farBarcodeAlt;
farBarcodeRead = farBarcodeRead;
farBarcodeScan = farBarcodeScan;
farBarnSilo = farBarnSilo;
farBars = farBars;
farBarsFilter = farBarsFilter;
farBarsProgress = farBarsProgress;
farBarsSort = farBarsSort;
farBarsStaggered = farBarsStaggered;
farBaseball = farBaseball;
farBaseballBall = farBaseballBall;
farBaseballBatBall = farBaseballBatBall;
farBasketball = farBasketball;
farBasketballBall = farBasketballBall;
farBasketballHoop = farBasketballHoop;
farBasketShopping = farBasketShopping;
farBasketShoppingSimple = farBasketShoppingSimple;
farBat = farBat;
farBath = farBath;
farBathtub = farBathtub;
farBattery = farBattery;
farBattery0 = farBattery0;
farBattery1 = farBattery1;
farBattery2 = farBattery2;
farBattery3 = farBattery3;
farBattery4 = farBattery4;
farBattery5 = farBattery5;
farBatteryBolt = farBatteryBolt;
farBatteryCar = farBatteryCar;
farBatteryEmpty = farBatteryEmpty;
farBatteryExclamation = farBatteryExclamation;
farBatteryFull = farBatteryFull;
farBatteryHalf = farBatteryHalf;
farBatteryLow = farBatteryLow;
farBatteryQuarter = farBatteryQuarter;
farBatterySlash = farBatterySlash;
farBatteryThreeQuarters = farBatteryThreeQuarters;
farBed = farBed;
farBedAlt = farBedAlt;
farBedBunk = farBedBunk;
farBedEmpty = farBedEmpty;
farBedFront = farBedFront;
farBedPulse = farBedPulse;
farBee = farBee;
farBeer = farBeer;
farBeerFoam = farBeerFoam;
farBeerMug = farBeerMug;
farBeerMugEmpty = farBeerMugEmpty;
farBell = farBell;
farBellConcierge = farBellConcierge;
farBellExclamation = farBellExclamation;
farBellOn = farBellOn;
farBellPlus = farBellPlus;
farBells = farBells;
farBellSchool = farBellSchool;
farBellSchoolSlash = farBellSchoolSlash;
farBellSlash = farBellSlash;
farBenchTree = farBenchTree;
farBetamax = farBetamax;
farBezierCurve = farBezierCurve;
farBible = farBible;
farBicycle = farBicycle;
farBiking = farBiking;
farBikingMountain = farBikingMountain;
farBinary = farBinary;
farBinaryCircleCheck = farBinaryCircleCheck;
farBinaryLock = farBinaryLock;
farBinarySlash = farBinarySlash;
farBinoculars = farBinoculars;
farBiohazard = farBiohazard;
farBird = farBird;
farBirthdayCake = farBirthdayCake;
farBitcoinSign = farBitcoinSign;
farBlackboard = farBlackboard;
farBlanket = farBlanket;
farBlanketFire = farBlanketFire;
farBlender = farBlender;
farBlenderPhone = farBlenderPhone;
farBlind = farBlind;
farBlinds = farBlinds;
farBlindsOpen = farBlindsOpen;
farBlindsRaised = farBlindsRaised;
farBlock = farBlock;
farBlockBrick = farBlockBrick;
farBlockBrickFire = farBlockBrickFire;
farBlockQuestion = farBlockQuestion;
farBlockQuote = farBlockQuote;
farBlog = farBlog;
farBlueberries = farBlueberries;
farBluetooth = farBluetooth;
farBold = farBold;
farBolt = farBolt;
farBoltAuto = farBoltAuto;
farBoltLightning = farBoltLightning;
farBoltSlash = farBoltSlash;
farBomb = farBomb;
farBone = farBone;
farBoneBreak = farBoneBreak;
farBong = farBong;
farBook = farBook;
farBookAlt = farBookAlt;
farBookArrowRight = farBookArrowRight;
farBookArrowUp = farBookArrowUp;
farBookAtlas = farBookAtlas;
farBookBible = farBookBible;
farBookBlank = farBookBlank;
farBookBookmark = farBookBookmark;
farBookCircle = farBookCircle;
farBookCircleArrowRight = farBookCircleArrowRight;
farBookCircleArrowUp = farBookCircleArrowUp;
farBookCopy = farBookCopy;
farBookDead = farBookDead;
farBookFont = farBookFont;
farBookHeart = farBookHeart;
farBookJournalWhills = farBookJournalWhills;
farBookLaw = farBookLaw;
farBookmark = farBookmark;
farBookmarkCircle = farBookmarkCircle;
farBookmarkSlash = farBookmarkSlash;
farBookMedical = farBookMedical;
farBookOpen = farBookOpen;
farBookOpenAlt = farBookOpenAlt;
farBookOpenCover = farBookOpenCover;
farBookOpenReader = farBookOpenReader;
farBookQuran = farBookQuran;
farBookReader = farBookReader;
farBooks = farBooks;
farBookSection = farBookSection;
farBookSkull = farBookSkull;
farBooksMedical = farBooksMedical;
farBookSparkles = farBookSparkles;
farBookSpells = farBookSpells;
farBookTanakh = farBookTanakh;
farBookUser = farBookUser;
farBoombox = farBoombox;
farBoot = farBoot;
farBoothCurtain = farBoothCurtain;
farBootHeeled = farBootHeeled;
farBorderAll = farBorderAll;
farBorderBottom = farBorderBottom;
farBorderBottomRight = farBorderBottomRight;
farBorderCenterH = farBorderCenterH;
farBorderCenterV = farBorderCenterV;
farBorderInner = farBorderInner;
farBorderLeft = farBorderLeft;
farBorderNone = farBorderNone;
farBorderOuter = farBorderOuter;
farBorderRight = farBorderRight;
farBorderStyle = farBorderStyle;
farBorderStyleAlt = farBorderStyleAlt;
farBorderTop = farBorderTop;
farBorderTopLeft = farBorderTopLeft;
farBoreHole = farBoreHole;
farBottleDroplet = farBottleDroplet;
farBottleWater = farBottleWater;
farBowArrow = farBowArrow;
farBowlChopsticks = farBowlChopsticks;
farBowlChopsticksNoodles = farBowlChopsticksNoodles;
farBowlFood = farBowlFood;
farBowlHot = farBowlHot;
farBowlingBall = farBowlingBall;
farBowlingBallPin = farBowlingBallPin;
farBowlingPins = farBowlingPins;
farBowlRice = farBowlRice;
farBowlSalad = farBowlSalad;
farBowlScoop = farBowlScoop;
farBowlScoops = farBowlScoops;
farBowlShavedIce = farBowlShavedIce;
farBowlSoftServe = farBowlSoftServe;
farBowlSpoon = farBowlSpoon;
farBox = farBox;
farBoxAlt = farBoxAlt;
farBoxArchive = farBoxArchive;
farBoxBallot = farBoxBallot;
farBoxCheck = farBoxCheck;
farBoxCircleCheck = farBoxCircleCheck;
farBoxDollar = farBoxDollar;
farBoxes = farBoxes;
farBoxesAlt = farBoxesAlt;
farBoxesPacking = farBoxesPacking;
farBoxesStacked = farBoxesStacked;
farBoxFragile = farBoxFragile;
farBoxFull = farBoxFull;
farBoxHeart = farBoxHeart;
farBoxingGlove = farBoxingGlove;
farBoxOpen = farBoxOpen;
farBoxOpenFull = farBoxOpenFull;
farBoxTaped = farBoxTaped;
farBoxTissue = farBoxTissue;
farBoxUp = farBoxUp;
farBoxUsd = farBoxUsd;
farBracket = farBracket;
farBracketCurly = farBracketCurly;
farBracketCurlyLeft = farBracketCurlyLeft;
farBracketCurlyRight = farBracketCurlyRight;
farBracketLeft = farBracketLeft;
farBracketRound = farBracketRound;
farBracketRoundRight = farBracketRoundRight;
farBrackets = farBrackets;
farBracketsCurly = farBracketsCurly;
farBracketSquare = farBracketSquare;
farBracketSquareRight = farBracketSquareRight;
farBracketsRound = farBracketsRound;
farBracketsSquare = farBracketsSquare;
farBraille = farBraille;
farBrain = farBrain;
farBrainArrowCurvedRight = farBrainArrowCurvedRight;
farBrainCircuit = farBrainCircuit;
farBrakeWarning = farBrakeWarning;
farBrazilianRealSign = farBrazilianRealSign;
farBreadLoaf = farBreadLoaf;
farBreadSlice = farBreadSlice;
farBreadSliceButter = farBreadSliceButter;
farBridge = farBridge;
farBridgeCircleCheck = farBridgeCircleCheck;
farBridgeCircleExclamation = farBridgeCircleExclamation;
farBridgeCircleXmark = farBridgeCircleXmark;
farBridgeLock = farBridgeLock;
farBridgeSuspension = farBridgeSuspension;
farBridgeWater = farBridgeWater;
farBriefcase = farBriefcase;
farBriefcaseArrowRight = farBriefcaseArrowRight;
farBriefcaseBlank = farBriefcaseBlank;
farBriefcaseClock = farBriefcaseClock;
farBriefcaseMedical = farBriefcaseMedical;
farBrightness = farBrightness;
farBrightnessLow = farBrightnessLow;
farBringForward = farBringForward;
farBringFront = farBringFront;
farBroadcastTower = farBroadcastTower;
farBroccoli = farBroccoli;
farBroom = farBroom;
farBroomBall = farBroomBall;
farBrowser = farBrowser;
farBrowsers = farBrowsers;
farBrush = farBrush;
farBucket = farBucket;
farBug = farBug;
farBugs = farBugs;
farBugSlash = farBugSlash;
farBuilding = farBuilding;
farBuildingCircleArrowRight = farBuildingCircleArrowRight;
farBuildingCircleCheck = farBuildingCircleCheck;
farBuildingCircleExclamation = farBuildingCircleExclamation;
farBuildingCircleXmark = farBuildingCircleXmark;
farBuildingColumns = farBuildingColumns;
farBuildingFlag = farBuildingFlag;
farBuildingLock = farBuildingLock;
farBuildingNgo = farBuildingNgo;
farBuildings = farBuildings;
farBuildingShield = farBuildingShield;
farBuildingUn = farBuildingUn;
farBuildingUser = farBuildingUser;
farBuildingWheat = farBuildingWheat;
farBullhorn = farBullhorn;
farBullseye = farBullseye;
farBullseyeArrow = farBullseyeArrow;
farBullseyePointer = farBullseyePointer;
farBurger = farBurger;
farBurgerCheese = farBurgerCheese;
farBurgerFries = farBurgerFries;
farBurgerGlass = farBurgerGlass;
farBurgerLettuce = farBurgerLettuce;
farBurgerSoda = farBurgerSoda;
farBurn = farBurn;
farBurrito = farBurrito;
farBurst = farBurst;
farBus = farBus;
farBusAlt = farBusAlt;
farBusinessFront = farBusinessFront;
farBusinessTime = farBusinessTime;
farBusSchool = farBusSchool;
farBusSimple = farBusSimple;
farButter = farButter;
farC = farC;
farCab = farCab;
farCabin = farCabin;
farCabinetFiling = farCabinetFiling;
farCableCar = farCableCar;
farCactus = farCactus;
farCake = farCake;
farCakeCandles = farCakeCandles;
farCakeSlice = farCakeSlice;
farCalculator = farCalculator;
farCalculatorAlt = farCalculatorAlt;
farCalculatorSimple = farCalculatorSimple;
farCalendar = farCalendar;
farCalendarAlt = farCalendarAlt;
farCalendarArrowDown = farCalendarArrowDown;
farCalendarArrowUp = farCalendarArrowUp;
farCalendarCheck = farCalendarCheck;
farCalendarCircle = farCalendarCircle;
farCalendarCircleExclamation = farCalendarCircleExclamation;
farCalendarCircleMinus = farCalendarCircleMinus;
farCalendarCirclePlus = farCalendarCirclePlus;
farCalendarCircleUser = farCalendarCircleUser;
farCalendarClock = farCalendarClock;
farCalendarDay = farCalendarDay;
farCalendarDays = farCalendarDays;
farCalendarDownload = farCalendarDownload;
farCalendarEdit = farCalendarEdit;
farCalendarExclamation = farCalendarExclamation;
farCalendarHeart = farCalendarHeart;
farCalendarImage = farCalendarImage;
farCalendarLines = farCalendarLines;
farCalendarLinesPen = farCalendarLinesPen;
farCalendarMinus = farCalendarMinus;
farCalendarNote = farCalendarNote;
farCalendarPen = farCalendarPen;
farCalendarPlus = farCalendarPlus;
farCalendarRange = farCalendarRange;
farCalendars = farCalendars;
farCalendarStar = farCalendarStar;
farCalendarTime = farCalendarTime;
farCalendarTimes = farCalendarTimes;
farCalendarUpload = farCalendarUpload;
farCalendarWeek = farCalendarWeek;
farCalendarXmark = farCalendarXmark;
farCamcorder = farCamcorder;
farCamera = farCamera;
farCameraAlt = farCameraAlt;
farCameraCctv = farCameraCctv;
farCameraCircle = farCameraCircle;
farCameraHome = farCameraHome;
farCameraMovie = farCameraMovie;
farCameraPolaroid = farCameraPolaroid;
farCameraRetro = farCameraRetro;
farCameraRotate = farCameraRotate;
farCameraSecurity = farCameraSecurity;
farCameraSlash = farCameraSlash;
farCameraViewfinder = farCameraViewfinder;
farCameraWeb = farCameraWeb;
farCameraWebSlash = farCameraWebSlash;
farCampfire = farCampfire;
farCampground = farCampground;
farCancel = farCancel;
farCandleHolder = farCandleHolder;
farCandy = farCandy;
farCandyBar = farCandyBar;
farCandyCane = farCandyCane;
farCandyCorn = farCandyCorn;
farCanFood = farCanFood;
farCannabis = farCannabis;
farCapsules = farCapsules;
farCar = farCar;
farCarAlt = farCarAlt;
farCaravan = farCaravan;
farCaravanAlt = farCaravanAlt;
farCaravanSimple = farCaravanSimple;
farCarBattery = farCarBattery;
farCarBolt = farCarBolt;
farCarBuilding = farCarBuilding;
farCarBump = farCarBump;
farCarBurst = farCarBurst;
farCarBus = farCarBus;
farCarCircleBolt = farCarCircleBolt;
farCarCrash = farCarCrash;
farCardClub = farCardClub;
farCardDiamond = farCardDiamond;
farCardHeart = farCardHeart;
farCards = farCards;
farCardsBlank = farCardsBlank;
farCardSpade = farCardSpade;
farCaretCircleDown = farCaretCircleDown;
farCaretCircleLeft = farCaretCircleLeft;
farCaretCircleRight = farCaretCircleRight;
farCaretCircleUp = farCaretCircleUp;
farCaretDown = farCaretDown;
farCaretLeft = farCaretLeft;
farCaretRight = farCaretRight;
farCaretSquareDown = farCaretSquareDown;
farCaretSquareLeft = farCaretSquareLeft;
farCaretSquareRight = farCaretSquareRight;
farCaretSquareUp = farCaretSquareUp;
farCaretUp = farCaretUp;
farCarGarage = farCarGarage;
farCarMechanic = farCarMechanic;
farCarMirrors = farCarMirrors;
farCarOn = farCarOn;
farCarRear = farCarRear;
farCarriageBaby = farCarriageBaby;
farCarrot = farCarrot;
farCars = farCars;
farCarSide = farCarSide;
farCarSideBolt = farCarSideBolt;
farCartArrowDown = farCartArrowDown;
farCartArrowUp = farCartArrowUp;
farCartCircleArrowDown = farCartCircleArrowDown;
farCartCircleArrowUp = farCartCircleArrowUp;
farCartCircleCheck = farCartCircleCheck;
farCartCircleExclamation = farCartCircleExclamation;
farCartCirclePlus = farCartCirclePlus;
farCartCircleXmark = farCartCircleXmark;
farCartFlatbed = farCartFlatbed;
farCartFlatbedBoxes = farCartFlatbedBoxes;
farCartFlatbedEmpty = farCartFlatbedEmpty;
farCartFlatbedSuitcase = farCartFlatbedSuitcase;
farCarTilt = farCarTilt;
farCartMinus = farCartMinus;
farCartPlus = farCartPlus;
farCartShopping = farCartShopping;
farCartShoppingFast = farCartShoppingFast;
farCarTunnel = farCarTunnel;
farCartXmark = farCartXmark;
farCarWash = farCarWash;
farCarWrench = farCarWrench;
farCashRegister = farCashRegister;
farCassetteBetamax = farCassetteBetamax;
farCassetteTape = farCassetteTape;
farCassetteVhs = farCassetteVhs;
farCastle = farCastle;
farCat = farCat;
farCatSpace = farCatSpace;
farCauldron = farCauldron;
farCctv = farCctv;
farCediSign = farCediSign;
farCentSign = farCentSign;
farCertificate = farCertificate;
farChain = farChain;
farChainBroken = farChainBroken;
farChainHorizontal = farChainHorizontal;
farChainHorizontalSlash = farChainHorizontalSlash;
farChainSlash = farChainSlash;
farChair = farChair;
farChairOffice = farChairOffice;
farChalkboard = farChalkboard;
farChalkboardTeacher = farChalkboardTeacher;
farChalkboardUser = farChalkboardUser;
farChampagneGlass = farChampagneGlass;
farChampagneGlasses = farChampagneGlasses;
farChargingStation = farChargingStation;
farChartArea = farChartArea;
farChartBar = farChartBar;
farChartBullet = farChartBullet;
farChartCandlestick = farChartCandlestick;
farChartColumn = farChartColumn;
farChartGantt = farChartGantt;
farChartLine = farChartLine;
farChartLineDown = farChartLineDown;
farChartLineUp = farChartLineUp;
farChartMixed = farChartMixed;
farChartNetwork = farChartNetwork;
farChartPie = farChartPie;
farChartPieAlt = farChartPieAlt;
farChartPieSimple = farChartPieSimple;
farChartPyramid = farChartPyramid;
farChartRadar = farChartRadar;
farChartScatter = farChartScatter;
farChartScatter3d = farChartScatter3d;
farChartScatterBubble = farChartScatterBubble;
farChartSimple = farChartSimple;
farChartSimpleHorizontal = farChartSimpleHorizontal;
farChartTreeMap = farChartTreeMap;
farChartUser = farChartUser;
farChartWaterfarll = farChartWaterfarll;
farCheck = farCheck;
farCheckCircle = farCheckCircle;
farCheckDouble = farCheckDouble;
farCheckSquare = farCheckSquare;
farCheckToSlot = farCheckToSlot;
farCheese = farCheese;
farCheeseburger = farCheeseburger;
farCheeseSwiss = farCheeseSwiss;
farCherries = farCherries;
farChess = farChess;
farChessBishop = farChessBishop;
farChessBishopAlt = farChessBishopAlt;
farChessBishopPiece = farChessBishopPiece;
farChessBoard = farChessBoard;
farChessClock = farChessClock;
farChessClockAlt = farChessClockAlt;
farChessClockFlip = farChessClockFlip;
farChessKing = farChessKing;
farChessKingAlt = farChessKingAlt;
farChessKingPiece = farChessKingPiece;
farChessKnight = farChessKnight;
farChessKnightAlt = farChessKnightAlt;
farChessKnightPiece = farChessKnightPiece;
farChessPawn = farChessPawn;
farChessPawnAlt = farChessPawnAlt;
farChessPawnPiece = farChessPawnPiece;
farChessQueen = farChessQueen;
farChessQueenAlt = farChessQueenAlt;
farChessQueenPiece = farChessQueenPiece;
farChessRook = farChessRook;
farChessRookAlt = farChessRookAlt;
farChessRookPiece = farChessRookPiece;
farChestnut = farChestnut;
farChevronCircleDown = farChevronCircleDown;
farChevronCircleLeft = farChevronCircleLeft;
farChevronCircleRight = farChevronCircleRight;
farChevronCircleUp = farChevronCircleUp;
farChevronDoubleDown = farChevronDoubleDown;
farChevronDoubleLeft = farChevronDoubleLeft;
farChevronDoubleRight = farChevronDoubleRight;
farChevronDoubleUp = farChevronDoubleUp;
farChevronDown = farChevronDown;
farChevronLeft = farChevronLeft;
farChevronRight = farChevronRight;
farChevronsDown = farChevronsDown;
farChevronsLeft = farChevronsLeft;
farChevronSquareDown = farChevronSquareDown;
farChevronSquareLeft = farChevronSquareLeft;
farChevronSquareRight = farChevronSquareRight;
farChevronSquareUp = farChevronSquareUp;
farChevronsRight = farChevronsRight;
farChevronsUp = farChevronsUp;
farChevronUp = farChevronUp;
farChild = farChild;
farChildren = farChildren;
farChildRifle = farChildRifle;
farChimney = farChimney;
farChocolateBar = farChocolateBar;
farChopsticks = farChopsticks;
farChurch = farChurch;
farCircle = farCircle;
farCircle0 = farCircle0;
farCircle1 = farCircle1;
farCircle2 = farCircle2;
farCircle3 = farCircle3;
farCircle4 = farCircle4;
farCircle5 = farCircle5;
farCircle6 = farCircle6;
farCircle7 = farCircle7;
farCircle8 = farCircle8;
farCircle9 = farCircle9;
farCircleA = farCircleA;
farCircleAmpersand = farCircleAmpersand;
farCircleArrowDown = farCircleArrowDown;
farCircleArrowDownLeft = farCircleArrowDownLeft;
farCircleArrowDownRight = farCircleArrowDownRight;
farCircleArrowLeft = farCircleArrowLeft;
farCircleArrowRight = farCircleArrowRight;
farCircleArrowUp = farCircleArrowUp;
farCircleArrowUpLeft = farCircleArrowUpLeft;
farCircleArrowUpRight = farCircleArrowUpRight;
farCircleB = farCircleB;
farCircleBolt = farCircleBolt;
farCircleBookmark = farCircleBookmark;
farCircleBookOpen = farCircleBookOpen;
farCircleC = farCircleC;
farCircleCalendar = farCircleCalendar;
farCircleCamera = farCircleCamera;
farCircleCaretDown = farCircleCaretDown;
farCircleCaretLeft = farCircleCaretLeft;
farCircleCaretRight = farCircleCaretRight;
farCircleCaretUp = farCircleCaretUp;
farCircleCheck = farCircleCheck;
farCircleChevronDown = farCircleChevronDown;
farCircleChevronLeft = farCircleChevronLeft;
farCircleChevronRight = farCircleChevronRight;
farCircleChevronUp = farCircleChevronUp;
farCircleD = farCircleD;
farCircleDashed = farCircleDashed;
farCircleDivide = farCircleDivide;
farCircleDollar = farCircleDollar;
farCircleDollarToSlot = farCircleDollarToSlot;
farCircleDot = farCircleDot;
farCircleDown = farCircleDown;
farCircleDownLeft = farCircleDownLeft;
farCircleDownRight = farCircleDownRight;
farCircleE = farCircleE;
farCircleEllipsis = farCircleEllipsis;
farCircleEllipsisVertical = farCircleEllipsisVertical;
farCircleEnvelope = farCircleEnvelope;
farCircleExclamation = farCircleExclamation;
farCircleExclamationCheck = farCircleExclamationCheck;
farCircleF = farCircleF;
farCircleG = farCircleG;
farCircleH = farCircleH;
farCircleHalf = farCircleHalf;
farCircleHalfStroke = farCircleHalfStroke;
farCircleHeart = farCircleHeart;
farCircleI = farCircleI;
farCircleInfo = farCircleInfo;
farCircleJ = farCircleJ;
farCircleK = farCircleK;
farCircleL = farCircleL;
farCircleLeft = farCircleLeft;
farCircleLocationArrow = farCircleLocationArrow;
farCircleM = farCircleM;
farCircleMicrophone = farCircleMicrophone;
farCircleMicrophoneLines = farCircleMicrophoneLines;
farCircleMinus = farCircleMinus;
farCircleN = farCircleN;
farCircleNodes = farCircleNodes;
farCircleNotch = farCircleNotch;
farCircleO = farCircleO;
farCircleP = farCircleP;
farCircleParking = farCircleParking;
farCirclePause = farCirclePause;
farCirclePhone = farCirclePhone;
farCirclePhoneFlip = farCirclePhoneFlip;
farCirclePhoneHangup = farCirclePhoneHangup;
farCirclePlay = farCirclePlay;
farCirclePlus = farCirclePlus;
farCircleQ = farCircleQ;
farCircleQuarter = farCircleQuarter;
farCircleQuarters = farCircleQuarters;
farCircleQuestion = farCircleQuestion;
farCircleR = farCircleR;
farCircleRadiation = farCircleRadiation;
farCircleRight = farCircleRight;
farCircleS = farCircleS;
farCircleSmall = farCircleSmall;
farCircleSort = farCircleSort;
farCircleSortDown = farCircleSortDown;
farCircleSortUp = farCircleSortUp;
farCircleStar = farCircleStar;
farCircleStop = farCircleStop;
farCircleT = farCircleT;
farCircleThreeQuarters = farCircleThreeQuarters;
farCircleTrash = farCircleTrash;
farCircleU = farCircleU;
farCircleUp = farCircleUp;
farCircleUpLeft = farCircleUpLeft;
farCircleUpRight = farCircleUpRight;
farCircleUser = farCircleUser;
farCircleV = farCircleV;
farCircleVideo = farCircleVideo;
farCircleW = farCircleW;
farCircleWaveformLines = farCircleWaveformLines;
farCircleX = farCircleX;
farCircleXmark = farCircleXmark;
farCircleY = farCircleY;
farCircleZ = farCircleZ;
farCitrus = farCitrus;
farCitrusSlice = farCitrusSlice;
farCity = farCity;
farClapperboard = farClapperboard;
farClapperboardPlay = farClapperboardPlay;
farClarinet = farClarinet;
farClawMarks = farClawMarks;
farClinicMedical = farClinicMedical;
farClipboard = farClipboard;
farClipboardCheck = farClipboardCheck;
farClipboardList = farClipboardList;
farClipboardListCheck = farClipboardListCheck;
farClipboardMedical = farClipboardMedical;
farClipboardPrescription = farClipboardPrescription;
farClipboardQuestion = farClipboardQuestion;
farClipboardUser = farClipboardUser;
farClock = farClock;
farClockDesk = farClockDesk;
farClockEight = farClockEight;
farClockEightThirty = farClockEightThirty;
farClockEleven = farClockEleven;
farClockElevenThirty = farClockElevenThirty;
farClockFive = farClockFive;
farClockFiveThirty = farClockFiveThirty;
farClockFour = farClockFour;
farClockFourThirty = farClockFourThirty;
farClockNine = farClockNine;
farClockNineThirty = farClockNineThirty;
farClockOne = farClockOne;
farClockOneThirty = farClockOneThirty;
farClockRotateLeft = farClockRotateLeft;
farClockSeven = farClockSeven;
farClockSevenThirty = farClockSevenThirty;
farClockSix = farClockSix;
farClockSixThirty = farClockSixThirty;
farClockTen = farClockTen;
farClockTenThirty = farClockTenThirty;
farClockThree = farClockThree;
farClockThreeThirty = farClockThreeThirty;
farClockTwelve = farClockTwelve;
farClockTwelveThirty = farClockTwelveThirty;
farClockTwo = farClockTwo;
farClockTwoThirty = farClockTwoThirty;
farClone = farClone;
farClose = farClose;
farClosedCaptioning = farClosedCaptioning;
farClosedCaptioningSlash = farClosedCaptioningSlash;
farClothesHanger = farClothesHanger;
farCloud = farCloud;
farCloudArrowDown = farCloudArrowDown;
farCloudArrowUp = farCloudArrowUp;
farCloudBolt = farCloudBolt;
farCloudBoltMoon = farCloudBoltMoon;
farCloudBoltSun = farCloudBoltSun;
farCloudCheck = farCloudCheck;
farCloudDownload = farCloudDownload;
farCloudDownloadAlt = farCloudDownloadAlt;
farCloudDrizzle = farCloudDrizzle;
farCloudExclamation = farCloudExclamation;
farCloudFog = farCloudFog;
farCloudHail = farCloudHail;
farCloudHailMixed = farCloudHailMixed;
farCloudMeatball = farCloudMeatball;
farCloudMinus = farCloudMinus;
farCloudMoon = farCloudMoon;
farCloudMoonRain = farCloudMoonRain;
farCloudMusic = farCloudMusic;
farCloudPlus = farCloudPlus;
farCloudQuestion = farCloudQuestion;
farCloudRain = farCloudRain;
farCloudRainbow = farCloudRainbow;
farClouds = farClouds;
farCloudShowers = farCloudShowers;
farCloudShowersHeavy = farCloudShowersHeavy;
farCloudShowersWater = farCloudShowersWater;
farCloudSlash = farCloudSlash;
farCloudSleet = farCloudSleet;
farCloudsMoon = farCloudsMoon;
farCloudSnow = farCloudSnow;
farCloudsSun = farCloudsSun;
farCloudSun = farCloudSun;
farCloudSunRain = farCloudSunRain;
farCloudUpload = farCloudUpload;
farCloudUploadAlt = farCloudUploadAlt;
farCloudWord = farCloudWord;
farCloudXmark = farCloudXmark;
farClover = farClover;
farClub = farClub;
farCny = farCny;
farCocktail = farCocktail;
farCoconut = farCoconut;
farCode = farCode;
farCodeBranch = farCodeBranch;
farCodeCommit = farCodeCommit;
farCodeCompare = farCodeCompare;
farCodeFork = farCodeFork;
farCodeMerge = farCodeMerge;
farCodePullRequest = farCodePullRequest;
farCodePullRequestClosed = farCodePullRequestClosed;
farCodePullRequestDraft = farCodePullRequestDraft;
farCodeSimple = farCodeSimple;
farCoffee = farCoffee;
farCoffeeBean = farCoffeeBean;
farCoffeeBeans = farCoffeeBeans;
farCoffeePot = farCoffeePot;
farCoffeeTogo = farCoffeeTogo;
farCoffin = farCoffin;
farCoffinCross = farCoffinCross;
farCog = farCog;
farCogs = farCogs;
farCoin = farCoin;
farCoinBlank = farCoinBlank;
farCoinFront = farCoinFront;
farCoins = farCoins;
farCoinVertical = farCoinVertical;
farColon = farColon;
farColonSign = farColonSign;
farColumns = farColumns;
farColumns3 = farColumns3;
farComet = farComet;
farComma = farComma;
farCommand = farCommand;
farComment = farComment;
farCommentAlt = farCommentAlt;
farCommentAltArrowDown = farCommentAltArrowDown;
farCommentAltArrowUp = farCommentAltArrowUp;
farCommentAltCaptions = farCommentAltCaptions;
farCommentAltCheck = farCommentAltCheck;
farCommentAltDollar = farCommentAltDollar;
farCommentAltDots = farCommentAltDots;
farCommentAltEdit = farCommentAltEdit;
farCommentAltExclamation = farCommentAltExclamation;
farCommentAltImage = farCommentAltImage;
farCommentAltLines = farCommentAltLines;
farCommentAltMedical = farCommentAltMedical;
farCommentAltMinus = farCommentAltMinus;
farCommentAltMusic = farCommentAltMusic;
farCommentAltPlus = farCommentAltPlus;
farCommentAltQuote = farCommentAltQuote;
farCommentAltSlash = farCommentAltSlash;
farCommentAltSmile = farCommentAltSmile;
farCommentAltText = farCommentAltText;
farCommentAltTimes = farCommentAltTimes;
farCommentArrowDown = farCommentArrowDown;
farCommentArrowUp = farCommentArrowUp;
farCommentArrowUpRight = farCommentArrowUpRight;
farCommentCaptions = farCommentCaptions;
farCommentCheck = farCommentCheck;
farCommentCode = farCommentCode;
farCommentDollar = farCommentDollar;
farCommentDots = farCommentDots;
farCommentEdit = farCommentEdit;
farCommentExclamation = farCommentExclamation;
farCommentImage = farCommentImage;
farCommenting = farCommenting;
farCommentLines = farCommentLines;
farCommentMedical = farCommentMedical;
farCommentMiddle = farCommentMiddle;
farCommentMiddleAlt = farCommentMiddleAlt;
farCommentMiddleTop = farCommentMiddleTop;
farCommentMiddleTopAlt = farCommentMiddleTopAlt;
farCommentMinus = farCommentMinus;
farCommentMusic = farCommentMusic;
farCommentPen = farCommentPen;
farCommentPlus = farCommentPlus;
farCommentQuestion = farCommentQuestion;
farCommentQuote = farCommentQuote;
farComments = farComments;
farCommentsAlt = farCommentsAlt;
farCommentsAltDollar = farCommentsAltDollar;
farCommentsDollar = farCommentsDollar;
farCommentSlash = farCommentSlash;
farCommentSmile = farCommentSmile;
farCommentSms = farCommentSms;
farCommentsQuestion = farCommentsQuestion;
farCommentsQuestionCheck = farCommentsQuestionCheck;
farCommentText = farCommentText;
farCommentTimes = farCommentTimes;
farCommentXmark = farCommentXmark;
farCompactDisc = farCompactDisc;
farCompass = farCompass;
farCompassDrafting = farCompassDrafting;
farCompassSlash = farCompassSlash;
farCompress = farCompress;
farCompressAlt = farCompressAlt;
farCompressArrows = farCompressArrows;
farCompressArrowsAlt = farCompressArrowsAlt;
farCompressWide = farCompressWide;
farComputer = farComputer;
farComputerClassic = farComputerClassic;
farComputerMouse = farComputerMouse;
farComputerMouseScrollwheel = farComputerMouseScrollwheel;
farComputerSpeaker = farComputerSpeaker;
farConciergeBell = farConciergeBell;
farConstruction = farConstruction;
farContactBook = farContactBook;
farContactCard = farContactCard;
farContainerStorage = farContainerStorage;
farConveyorBelt = farConveyorBelt;
farConveyorBeltAlt = farConveyorBeltAlt;
farConveyorBeltBoxes = farConveyorBeltBoxes;
farConveyorBeltEmpty = farConveyorBeltEmpty;
farCookie = farCookie;
farCookieBite = farCookieBite;
farCopy = farCopy;
farCopyright = farCopyright;
farCorn = farCorn;
farCorner = farCorner;
farCouch = farCouch;
farCouchSmall = farCouchSmall;
farCow = farCow;
farCowbell = farCowbell;
farCowbellCirclePlus = farCowbellCirclePlus;
farCowbellMore = farCowbellMore;
farCrab = farCrab;
farCrateApple = farCrateApple;
farCrateEmpty = farCrateEmpty;
farCreditCard = farCreditCard;
farCreditCardAlt = farCreditCardAlt;
farCreditCardBlank = farCreditCardBlank;
farCreditCardFront = farCreditCardFront;
farCreemee = farCreemee;
farCricket = farCricket;
farCricketBatBall = farCricketBatBall;
farCroissant = farCroissant;
farCrop = farCrop;
farCropAlt = farCropAlt;
farCropSimple = farCropSimple;
farCross = farCross;
farCrosshairs = farCrosshairs;
farCrow = farCrow;
farCrown = farCrown;
farCrutch = farCrutch;
farCrutches = farCrutches;
farCruzeiroSign = farCruzeiroSign;
farCrystalBall = farCrystalBall;
farCube = farCube;
farCubes = farCubes;
farCubesStacked = farCubesStacked;
farCucumber = farCucumber;
farCupcake = farCupcake;
farCupStraw = farCupStraw;
farCupStrawSwoosh = farCupStrawSwoosh;
farCupTogo = farCupTogo;
farCurling = farCurling;
farCurlingStone = farCurlingStone;
farCustard = farCustard;
farCut = farCut;
farCutlery = farCutlery;
farD = farD;
farDagger = farDagger;
farDash = farDash;
farDashboard = farDashboard;
farDatabase = farDatabase;
farDeaf = farDeaf;
farDeafness = farDeafness;
farDebug = farDebug;
farDedent = farDedent;
farDeer = farDeer;
farDeerRudolph = farDeerRudolph;
farDeleteLeft = farDeleteLeft;
farDeleteRight = farDeleteRight;
farDemocrat = farDemocrat;
farDesktop = farDesktop;
farDesktopAlt = farDesktopAlt;
farDesktopArrowDown = farDesktopArrowDown;
farDesktopCode = farDesktopCode;
farDesktopMedical = farDesktopMedical;
farDesktopSlash = farDesktopSlash;
farDewpoint = farDewpoint;
farDharmachakra = farDharmachakra;
farDiagnoses = farDiagnoses;
farDiagramCells = farDiagramCells;
farDiagramLeanCanvas = farDiagramLeanCanvas;
farDiagramNested = farDiagramNested;
farDiagramNext = farDiagramNext;
farDiagramPredecessor = farDiagramPredecessor;
farDiagramPrevious = farDiagramPrevious;
farDiagramProject = farDiagramProject;
farDiagramSankey = farDiagramSankey;
farDiagramSubtask = farDiagramSubtask;
farDiagramSuccessor = farDiagramSuccessor;
farDiagramVenn = farDiagramVenn;
farDial = farDial;
farDialHigh = farDialHigh;
farDialLow = farDialLow;
farDialMax = farDialMax;
farDialMed = farDialMed;
farDialMedHigh = farDialMedHigh;
farDialMedLow = farDialMedLow;
farDialMin = farDialMin;
farDialOff = farDialOff;
farDiamond = farDiamond;
farDiamondExclamation = farDiamondExclamation;
farDiamondTurnRight = farDiamondTurnRight;
farDice = farDice;
farDiceD10 = farDiceD10;
farDiceD12 = farDiceD12;
farDiceD20 = farDiceD20;
farDiceD4 = farDiceD4;
farDiceD6 = farDiceD6;
farDiceD8 = farDiceD8;
farDiceFive = farDiceFive;
farDiceFour = farDiceFour;
farDiceOne = farDiceOne;
farDiceSix = farDiceSix;
farDiceThree = farDiceThree;
farDiceTwo = farDiceTwo;
farDigging = farDigging;
farDigitalTachograph = farDigitalTachograph;
farDiploma = farDiploma;
farDirections = farDirections;
farDiscDrive = farDiscDrive;
farDisease = farDisease;
farDisplay = farDisplay;
farDisplayArrowDown = farDisplayArrowDown;
farDisplayCode = farDisplayCode;
farDisplayMedical = farDisplayMedical;
farDisplaySlash = farDisplaySlash;
farDistributeSpacingHorizontal = farDistributeSpacingHorizontal;
farDistributeSpacingVertical = farDistributeSpacingVertical;
farDitto = farDitto;
farDivide = farDivide;
farDizzy = farDizzy;
farDna = farDna;
farDog = farDog;
farDogLeashed = farDogLeashed;
farDollar = farDollar;
farDollarCircle = farDollarCircle;
farDollarSign = farDollarSign;
farDollarSquare = farDollarSquare;
farDolly = farDolly;
farDollyBox = farDollyBox;
farDollyEmpty = farDollyEmpty;
farDollyFlatbed = farDollyFlatbed;
farDollyFlatbedAlt = farDollyFlatbedAlt;
farDollyFlatbedEmpty = farDollyFlatbedEmpty;
farDolphin = farDolphin;
farDonate = farDonate;
farDongSign = farDongSign;
farDoNotEnter = farDoNotEnter;
farDonut = farDonut;
farDoorClosed = farDoorClosed;
farDoorOpen = farDoorOpen;
farDotCircle = farDotCircle;
farDoughnut = farDoughnut;
farDove = farDove;
farDown = farDown;
farDownFromDottedLine = farDownFromDottedLine;
farDownFromLine = farDownFromLine;
farDownLeft = farDownLeft;
farDownLeftAndUpRightToCenter = farDownLeftAndUpRightToCenter;
farDownload = farDownload;
farDownLong = farDownLong;
farDownRight = farDownRight;
farDownToBracket = farDownToBracket;
farDownToDottedLine = farDownToDottedLine;
farDownToLine = farDownToLine;
farDraftingCompass = farDraftingCompass;
farDragon = farDragon;
farDrawCircle = farDrawCircle;
farDrawPolygon = farDrawPolygon;
farDrawSquare = farDrawSquare;
farDreidel = farDreidel;
farDriversLicense = farDriversLicense;
farDrone = farDrone;
farDroneAlt = farDroneAlt;
farDroneFront = farDroneFront;
farDroplet = farDroplet;
farDropletDegree = farDropletDegree;
farDropletPercent = farDropletPercent;
farDropletSlash = farDropletSlash;
farDrum = farDrum;
farDrumSteelpan = farDrumSteelpan;
farDrumstick = farDrumstick;
farDrumstickBite = farDrumstickBite;
farDryer = farDryer;
farDryerAlt = farDryerAlt;
farDryerHeat = farDryerHeat;
farDuck = farDuck;
farDumbbell = farDumbbell;
farDumpster = farDumpster;
farDumpsterFire = farDumpsterFire;
farDungeon = farDungeon;
farE = farE;
farEar = farEar;
farEarDeaf = farEarDeaf;
farEarListen = farEarListen;
farEarMuffs = farEarMuffs;
farEarth = farEarth;
farEarthAfrica = farEarthAfrica;
farEarthAmerica = farEarthAmerica;
farEarthAmericas = farEarthAmericas;
farEarthAsia = farEarthAsia;
farEarthEurope = farEarthEurope;
farEarthOceania = farEarthOceania;
farEclipse = farEclipse;
farEclipseAlt = farEclipseAlt;
farEdit = farEdit;
farEgg = farEgg;
farEggFried = farEggFried;
farEggplant = farEggplant;
farEject = farEject;
farElephant = farElephant;
farElevator = farElevator;
farEllipsis = farEllipsis;
farEllipsisH = farEllipsisH;
farEllipsisHAlt = farEllipsisHAlt;
farEllipsisStroke = farEllipsisStroke;
farEllipsisStrokeVertical = farEllipsisStrokeVertical;
farEllipsisV = farEllipsisV;
farEllipsisVAlt = farEllipsisVAlt;
farEllipsisVertical = farEllipsisVertical;
farEmptySet = farEmptySet;
farEngine = farEngine;
farEngineExclamation = farEngineExclamation;
farEngineWarning = farEngineWarning;
farEnvelope = farEnvelope;
farEnvelopeBadge = farEnvelopeBadge;
farEnvelopeCircle = farEnvelopeCircle;
farEnvelopeCircleCheck = farEnvelopeCircleCheck;
farEnvelopeDot = farEnvelopeDot;
farEnvelopeOpen = farEnvelopeOpen;
farEnvelopeOpenDollar = farEnvelopeOpenDollar;
farEnvelopeOpenText = farEnvelopeOpenText;
farEnvelopes = farEnvelopes;
farEnvelopesBulk = farEnvelopesBulk;
farEnvelopeSquare = farEnvelopeSquare;
farEquals = farEquals;
farEraser = farEraser;
farEscalator = farEscalator;
farEthernet = farEthernet;
farEur = farEur;
farEuro = farEuro;
farEuroSign = farEuroSign;
farExchange = farExchange;
farExchangeAlt = farExchangeAlt;
farExclamation = farExclamation;
farExclamationCircle = farExclamationCircle;
farExclamationSquare = farExclamationSquare;
farExclamationTriangle = farExclamationTriangle;
farExpand = farExpand;
farExpandAlt = farExpandAlt;
farExpandArrows = farExpandArrows;
farExpandArrowsAlt = farExpandArrowsAlt;
farExpandWide = farExpandWide;
farExplodingHead = farExplodingHead;
farExplosion = farExplosion;
farExternalLink = farExternalLink;
farExternalLinkAlt = farExternalLinkAlt;
farExternalLinkSquare = farExternalLinkSquare;
farExternalLinkSquareAlt = farExternalLinkSquareAlt;
farEye = farEye;
farEyedropper = farEyedropper;
farEyeDropper = farEyeDropper;
farEyeDropperEmpty = farEyeDropperEmpty;
farEyeDropperFull = farEyeDropperFull;
farEyeDropperHalf = farEyeDropperHalf;
farEyeEvil = farEyeEvil;
farEyeLowVision = farEyeLowVision;
farEyes = farEyes;
farEyeSlash = farEyeSlash;
farF = farF;
farFaceAngry = farFaceAngry;
farFaceAngryHorns = farFaceAngryHorns;
farFaceAnguished = farFaceAnguished;
farFaceAnxiousSweat = farFaceAnxiousSweat;
farFaceAstonished = farFaceAstonished;
farFaceAwesome = farFaceAwesome;
farFaceBeamHandOverMouth = farFaceBeamHandOverMouth;
farFaceClouds = farFaceClouds;
farFaceConfounded = farFaceConfounded;
farFaceConfused = farFaceConfused;
farFaceCowboyHat = farFaceCowboyHat;
farFaceDiagonalMouth = farFaceDiagonalMouth;
farFaceDisappointed = farFaceDisappointed;
farFaceDisguise = farFaceDisguise;
farFaceDizzy = farFaceDizzy;
farFaceDotted = farFaceDotted;
farFaceDowncastSweat = farFaceDowncastSweat;
farFaceDrooling = farFaceDrooling;
farFaceExhaling = farFaceExhaling;
farFaceExplode = farFaceExplode;
farFaceExpressionless = farFaceExpressionless;
farFaceEyesXmarks = farFaceEyesXmarks;
farFaceFearful = farFaceFearful;
farFaceFlushed = farFaceFlushed;
farFaceFrown = farFaceFrown;
farFaceFrownOpen = farFaceFrownOpen;
farFaceFrownSlight = farFaceFrownSlight;
farFaceGlasses = farFaceGlasses;
farFaceGrimace = farFaceGrimace;
farFaceGrin = farFaceGrin;
farFaceGrinBeam = farFaceGrinBeam;
farFaceGrinBeamSweat = farFaceGrinBeamSweat;
farFaceGrinHearts = farFaceGrinHearts;
farFaceGrinSquint = farFaceGrinSquint;
farFaceGrinSquintTears = farFaceGrinSquintTears;
farFaceGrinStars = farFaceGrinStars;
farFaceGrinTears = farFaceGrinTears;
farFaceGrinTongue = farFaceGrinTongue;
farFaceGrinTongueSquint = farFaceGrinTongueSquint;
farFaceGrinTongueWink = farFaceGrinTongueWink;
farFaceGrinWide = farFaceGrinWide;
farFaceGrinWink = farFaceGrinWink;
farFaceHandOverMouth = farFaceHandOverMouth;
farFaceHandPeeking = farFaceHandPeeking;
farFaceHandYawn = farFaceHandYawn;
farFaceHeadBandage = farFaceHeadBandage;
farFaceHoldingBackTears = farFaceHoldingBackTears;
farFaceHushed = farFaceHushed;
farFaceIcicles = farFaceIcicles;
farFaceKiss = farFaceKiss;
farFaceKissBeam = farFaceKissBeam;
farFaceKissClosedEyes = farFaceKissClosedEyes;
farFaceKissWinkHeart = farFaceKissWinkHeart;
farFaceLaugh = farFaceLaugh;
farFaceLaughBeam = farFaceLaughBeam;
farFaceLaughSquint = farFaceLaughSquint;
farFaceLaughWink = farFaceLaughWink;
farFaceLying = farFaceLying;
farFaceMask = farFaceMask;
farFaceMeh = farFaceMeh;
farFaceMehBlank = farFaceMehBlank;
farFaceMelting = farFaceMelting;
farFaceMonocle = farFaceMonocle;
farFaceNauseated = farFaceNauseated;
farFaceNoseSteam = farFaceNoseSteam;
farFaceParty = farFaceParty;
farFacePensive = farFacePensive;
farFacePersevering = farFacePersevering;
farFacePleading = farFacePleading;
farFacePouting = farFacePouting;
farFaceRaisedEyebrow = farFaceRaisedEyebrow;
farFaceRelieved = farFaceRelieved;
farFaceRollingEyes = farFaceRollingEyes;
farFaceSadCry = farFaceSadCry;
farFaceSadSweat = farFaceSadSweat;
farFaceSadTear = farFaceSadTear;
farFaceSaluting = farFaceSaluting;
farFaceScream = farFaceScream;
farFaceShush = farFaceShush;
farFaceSleeping = farFaceSleeping;
farFaceSleepy = farFaceSleepy;
farFaceSmile = farFaceSmile;
farFaceSmileBeam = farFaceSmileBeam;
farFaceSmileHalo = farFaceSmileHalo;
farFaceSmileHearts = farFaceSmileHearts;
farFaceSmileHorns = farFaceSmileHorns;
farFaceSmilePlus = farFaceSmilePlus;
farFaceSmileRelaxed = farFaceSmileRelaxed;
farFaceSmileTear = farFaceSmileTear;
farFaceSmileTongue = farFaceSmileTongue;
farFaceSmileUpsideDown = farFaceSmileUpsideDown;
farFaceSmileWink = farFaceSmileWink;
farFaceSmilingHands = farFaceSmilingHands;
farFaceSmirking = farFaceSmirking;
farFaceSpiralEyes = farFaceSpiralEyes;
farFaceSunglasses = farFaceSunglasses;
farFaceSurprise = farFaceSurprise;
farFaceSwear = farFaceSwear;
farFaceThermometer = farFaceThermometer;
farFaceThinking = farFaceThinking;
farFaceTired = farFaceTired;
farFaceTissue = farFaceTissue;
farFaceTongueMoney = farFaceTongueMoney;
farFaceTongueSweat = farFaceTongueSweat;
farFaceUnamused = farFaceUnamused;
farFaceViewfinder = farFaceViewfinder;
farFaceVomit = farFaceVomit;
farFaceWeary = farFaceWeary;
farFaceWoozy = farFaceWoozy;
farFaceWorried = farFaceWorried;
farFaceZany = farFaceZany;
farFaceZipper = farFaceZipper;
farFalafel = farFalafel;
farFamily = farFamily;
farFamilyDress = farFamilyDress;
farFamilyPants = farFamilyPants;
farFan = farFan;
farFanTable = farFanTable;
farFarm = farFarm;
farFastBackward = farFastBackward;
farFastForward = farFastForward;
farFaucet = farFaucet;
farFaucetDrip = farFaucetDrip;
farFax = farFax;
farFeather = farFeather;
farFeatherAlt = farFeatherAlt;
farFeatherPointed = farFeatherPointed;
farFeed = farFeed;
farFemale = farFemale;
farFence = farFence;
farFerrisWheel = farFerrisWheel;
farFerry = farFerry;
farFieldHockey = farFieldHockey;
farFieldHockeyStickBall = farFieldHockeyStickBall;
farFighterJet = farFighterJet;
farFile = farFile;
farFileAlt = farFileAlt;
farFileArchive = farFileArchive;
farFileArrowDown = farFileArrowDown;
farFileArrowUp = farFileArrowUp;
farFileAudio = farFileAudio;
farFileAward = farFileAward;
farFileBinary = farFileBinary;
farFileCaretDown = farFileCaretDown;
farFileCaretUp = farFileCaretUp;
farFileCertificate = farFileCertificate;
farFileChartColumn = farFileChartColumn;
farFileChartLine = farFileChartLine;
farFileChartPie = farFileChartPie;
farFileCheck = farFileCheck;
farFileCircleCheck = farFileCircleCheck;
farFileCircleExclamation = farFileCircleExclamation;
farFileCircleInfo = farFileCircleInfo;
farFileCircleMinus = farFileCircleMinus;
farFileCirclePlus = farFileCirclePlus;
farFileCircleQuestion = farFileCircleQuestion;
farFileCircleXmark = farFileCircleXmark;
farFileClipboard = farFileClipboard;
farFileCode = farFileCode;
farFileContract = farFileContract;
farFileCsv = farFileCsv;
farFileDashedLine = farFileDashedLine;
farFileDownload = farFileDownload;
farFileEdit = farFileEdit;
farFileExcel = farFileExcel;
farFileExclamation = farFileExclamation;
farFileExport = farFileExport;
farFileHeart = farFileHeart;
farFileImage = farFileImage;
farFileImport = farFileImport;
farFileInvoice = farFileInvoice;
farFileInvoiceDollar = farFileInvoiceDollar;
farFileLines = farFileLines;
farFileLock = farFileLock;
farFileMagnifyingGlass = farFileMagnifyingGlass;
farFileMedical = farFileMedical;
farFileMedicalAlt = farFileMedicalAlt;
farFileMinus = farFileMinus;
farFileMusic = farFileMusic;
farFilePdf = farFilePdf;
farFilePen = farFilePen;
farFilePlus = farFilePlus;
farFilePlusMinus = farFilePlusMinus;
farFilePowerpoint = farFilePowerpoint;
farFilePrescription = farFilePrescription;
farFiles = farFiles;
farFileSearch = farFileSearch;
farFileShield = farFileShield;
farFileSignature = farFileSignature;
farFileSlash = farFileSlash;
farFilesMedical = farFilesMedical;
farFileSpreadsheet = farFileSpreadsheet;
farFileText = farFileText;
farFileTimes = farFileTimes;
farFileUpload = farFileUpload;
farFileUser = farFileUser;
farFileVideo = farFileVideo;
farFileWaveform = farFileWaveform;
farFileWord = farFileWord;
farFileXmark = farFileXmark;
farFileZipper = farFileZipper;
farFill = farFill;
farFillDrip = farFillDrip;
farFilm = farFilm;
farFilmAlt = farFilmAlt;
farFilmCanister = farFilmCanister;
farFilms = farFilms;
farFilmSimple = farFilmSimple;
farFilmSlash = farFilmSlash;
farFilter = farFilter;
farFilterCircleDollar = farFilterCircleDollar;
farFilterCircleXmark = farFilterCircleXmark;
farFilterList = farFilterList;
farFilters = farFilters;
farFilterSlash = farFilterSlash;
farFingerprint = farFingerprint;
farFire = farFire;
farFireAlt = farFireAlt;
farFireBurner = farFireBurner;
farFireExtinguisher = farFireExtinguisher;
farFireFlame = farFireFlame;
farFireFlameCurved = farFireFlameCurved;
farFireFlameSimple = farFireFlameSimple;
farFireHydrant = farFireHydrant;
farFireplace = farFireplace;
farFireSmoke = farFireSmoke;
farFirewall = farFirewall;
farFirstAid = farFirstAid;
farFish = farFish;
farFishBones = farFishBones;
farFishCooked = farFishCooked;
farFishFins = farFishFins;
farFishingRod = farFishingRod;
farFistRaised = farFistRaised;
farFlag = farFlag;
farFlagAlt = farFlagAlt;
farFlagCheckered = farFlagCheckered;
farFlagPennant = farFlagPennant;
farFlagSwallowtail = farFlagSwallowtail;
farFlagUsa = farFlagUsa;
farFlame = farFlame;
farFlashlight = farFlashlight;
farFlask = farFlask;
farFlaskPoison = farFlaskPoison;
farFlaskPotion = farFlaskPotion;
farFlaskRoundPoison = farFlaskRoundPoison;
farFlaskRoundPotion = farFlaskRoundPotion;
farFlaskVial = farFlaskVial;
farFlatbread = farFlatbread;
farFlatbreadStuffed = farFlatbreadStuffed;
farFloppyDisk = farFloppyDisk;
farFloppyDiskCircleArrowRight = farFloppyDiskCircleArrowRight;
farFloppyDiskCircleXmark = farFloppyDiskCircleXmark;
farFloppyDiskPen = farFloppyDiskPen;
farFloppyDisks = farFloppyDisks;
farFloppyDiskTimes = farFloppyDiskTimes;
farFlorinSign = farFlorinSign;
farFlower = farFlower;
farFlowerDaffodil = farFlowerDaffodil;
farFlowerTulip = farFlowerTulip;
farFlushed = farFlushed;
farFlute = farFlute;
farFluxCapacitor = farFluxCapacitor;
farFlyingDisc = farFlyingDisc;
farFog = farFog;
farFolder = farFolder;
farFolderArrowDown = farFolderArrowDown;
farFolderArrowUp = farFolderArrowUp;
farFolderBlank = farFolderBlank;
farFolderBookmark = farFolderBookmark;
farFolderClosed = farFolderClosed;
farFolderCog = farFolderCog;
farFolderDownload = farFolderDownload;
farFolderGear = farFolderGear;
farFolderGrid = farFolderGrid;
farFolderHeart = farFolderHeart;
farFolderImage = farFolderImage;
farFolderMagnifyingGlass = farFolderMagnifyingGlass;
farFolderMedical = farFolderMedical;
farFolderMinus = farFolderMinus;
farFolderMusic = farFolderMusic;
farFolderOpen = farFolderOpen;
farFolderPlus = farFolderPlus;
farFolders = farFolders;
farFolderSearch = farFolderSearch;
farFolderTimes = farFolderTimes;
farFolderTree = farFolderTree;
farFolderUpload = farFolderUpload;
farFolderUser = farFolderUser;
farFolderXmark = farFolderXmark;
farFonduePot = farFonduePot;
farFont = farFont;
farFontAwesome = farFontAwesome;
farFontAwesomeFlag = farFontAwesomeFlag;
farFontAwesomeLogoFull = farFontAwesomeLogoFull;
farFontCase = farFontCase;
farFootball = farFootball;
farFootballBall = farFootballBall;
farFootballHelmet = farFootballHelmet;
farFork = farFork;
farForkKnife = farForkKnife;
farForklift = farForklift;
farFort = farFort;
farForward = farForward;
farForwardFast = farForwardFast;
farForwardStep = farForwardStep;
farFragile = farFragile;
farFrame = farFrame;
farFrancSign = farFrancSign;
farFrenchFries = farFrenchFries;
farFrog = farFrog;
farFrostyHead = farFrostyHead;
farFrown = farFrown;
farFrownOpen = farFrownOpen;
farFunction = farFunction;
farFunnelDollar = farFunnelDollar;
farFutbol = farFutbol;
farFutbolBall = farFutbolBall;
farG = farG;
farGalaxy = farGalaxy;
farGalleryThumbnails = farGalleryThumbnails;
farGameBoard = farGameBoard;
farGameBoardAlt = farGameBoardAlt;
farGameBoardSimple = farGameBoardSimple;
farGameConsoleHandheld = farGameConsoleHandheld;
farGamepad = farGamepad;
farGamepadAlt = farGamepadAlt;
farGamepadModern = farGamepadModern;
farGarage = farGarage;
farGarageCar = farGarageCar;
farGarageOpen = farGarageOpen;
farGarlic = farGarlic;
farGasPump = farGasPump;
farGasPumpSlash = farGasPumpSlash;
farGauge = farGauge;
farGaugeCircleBolt = farGaugeCircleBolt;
farGaugeCircleMinus = farGaugeCircleMinus;
farGaugeCirclePlus = farGaugeCirclePlus;
farGaugeHigh = farGaugeHigh;
farGaugeLow = farGaugeLow;
farGaugeMax = farGaugeMax;
farGaugeMed = farGaugeMed;
farGaugeMin = farGaugeMin;
farGaugeSimple = farGaugeSimple;
farGaugeSimpleHigh = farGaugeSimpleHigh;
farGaugeSimpleLow = farGaugeSimpleLow;
farGaugeSimpleMax = farGaugeSimpleMax;
farGaugeSimpleMed = farGaugeSimpleMed;
farGaugeSimpleMin = farGaugeSimpleMin;
farGaveDandy = farGaveDandy;
farGavel = farGavel;
farGbp = farGbp;
farGear = farGear;
farGears = farGears;
farGem = farGem;
farGenderless = farGenderless;
farGhost = farGhost;
farGif = farGif;
farGift = farGift;
farGiftCard = farGiftCard;
farGifts = farGifts;
farGingerbreadMan = farGingerbreadMan;
farGlass = farGlass;
farGlassChampagne = farGlassChampagne;
farGlassCheers = farGlassCheers;
farGlassCitrus = farGlassCitrus;
farGlassEmpty = farGlassEmpty;
farGlasses = farGlasses;
farGlassesAlt = farGlassesAlt;
farGlassesRound = farGlassesRound;
farGlassHalf = farGlassHalf;
farGlassHalfEmpty = farGlassHalfEmpty;
farGlassHalfFull = farGlassHalfFull;
farGlassMartini = farGlassMartini;
farGlassMartiniAlt = farGlassMartiniAlt;
farGlassWater = farGlassWater;
farGlassWaterDroplet = farGlassWaterDroplet;
farGlassWhiskey = farGlassWhiskey;
farGlassWhiskeyRocks = farGlassWhiskeyRocks;
farGlobe = farGlobe;
farGlobeAfrica = farGlobeAfrica;
farGlobeAmericas = farGlobeAmericas;
farGlobeAsia = farGlobeAsia;
farGlobeEurope = farGlobeEurope;
farGlobeOceania = farGlobeOceania;
farGlobeSnow = farGlobeSnow;
farGlobeStand = farGlobeStand;
farGloveBoxing = farGloveBoxing;
farGoalNet = farGoalNet;
farGolfBall = farGolfBall;
farGolfBallTee = farGolfBallTee;
farGolfClub = farGolfClub;
farGolfFlagHole = farGolfFlagHole;
farGopuram = farGopuram;
farGraduationCap = farGraduationCap;
farGramophone = farGramophone;
farGrapes = farGrapes;
farGrate = farGrate;
farGrateDroplet = farGrateDroplet;
farGreaterThan = farGreaterThan;
farGreaterThanEqual = farGreaterThanEqual;
farGrid = farGrid;
farGrid2 = farGrid2;
farGrid2Plus = farGrid2Plus;
farGrid3 = farGrid3;
farGrid4 = farGrid4;
farGrid5 = farGrid5;
farGridDividers = farGridDividers;
farGridHorizontal = farGridHorizontal;
farGrimace = farGrimace;
farGrin = farGrin;
farGrinAlt = farGrinAlt;
farGrinBeam = farGrinBeam;
farGrinBeamSweat = farGrinBeamSweat;
farGrinHearts = farGrinHearts;
farGrinSquint = farGrinSquint;
farGrinSquintTears = farGrinSquintTears;
farGrinStars = farGrinStars;
farGrinTears = farGrinTears;
farGrinTongue = farGrinTongue;
farGrinTongueSquint = farGrinTongueSquint;
farGrinTongueWink = farGrinTongueWink;
farGrinWink = farGrinWink;
farGrip = farGrip;
farGripDots = farGripDots;
farGripDotsVertical = farGripDotsVertical;
farGripHorizontal = farGripHorizontal;
farGripLines = farGripLines;
farGripLinesVertical = farGripLinesVertical;
farGripVertical = farGripVertical;
farGroupArrowsRotate = farGroupArrowsRotate;
farGuaraniSign = farGuaraniSign;
farGuitar = farGuitar;
farGuitarElectric = farGuitarElectric;
farGuitars = farGuitars;
farGun = farGun;
farGunSlash = farGunSlash;
farGunSquirt = farGunSquirt;
farH = farH;
farH1 = farH1;
farH2 = farH2;
farH3 = farH3;
farH4 = farH4;
farH5 = farH5;
farH6 = farH6;
farHamburger = farHamburger;
farHammer = farHammer;
farHammerCrash = farHammerCrash;
farHammerWar = farHammerWar;
farHamsa = farHamsa;
farHand = farHand;
farHandBackFist = farHandBackFist;
farHandBackPointDown = farHandBackPointDown;
farHandBackPointLeft = farHandBackPointLeft;
farHandBackPointRibbon = farHandBackPointRibbon;
farHandBackPointRight = farHandBackPointRight;
farHandBackPointUp = farHandBackPointUp;
farHandcuffs = farHandcuffs;
farHandDots = farHandDots;
farHandFingersCrossed = farHandFingersCrossed;
farHandFist = farHandFist;
farHandHeart = farHandHeart;
farHandHolding = farHandHolding;
farHandHoldingBox = farHandHoldingBox;
farHandHoldingDollar = farHandHoldingDollar;
farHandHoldingDroplet = farHandHoldingDroplet;
farHandHoldingHand = farHandHoldingHand;
farHandHoldingHeart = farHandHoldingHeart;
farHandHoldingMagic = farHandHoldingMagic;
farHandHoldingMedical = farHandHoldingMedical;
farHandHoldingSeedling = farHandHoldingSeedling;
farHandHoldingSkull = farHandHoldingSkull;
farHandHoldingUsd = farHandHoldingUsd;
farHandHoldingWater = farHandHoldingWater;
farHandHorns = farHandHorns;
farHandLizard = farHandLizard;
farHandLove = farHandLove;
farHandMiddleFinger = farHandMiddleFinger;
farHandPaper = farHandPaper;
farHandPeace = farHandPeace;
farHandPointDown = farHandPointDown;
farHandPointer = farHandPointer;
farHandPointLeft = farHandPointLeft;
farHandPointRibbon = farHandPointRibbon;
farHandPointRight = farHandPointRight;
farHandPointUp = farHandPointUp;
farHandReceiving = farHandReceiving;
farHandRock = farHandRock;
farHands = farHands;
farHandsAmericanSignLanguageInterpreting = farHandsAmericanSignLanguageInterpreting;
farHandsAslInterpreting = farHandsAslInterpreting;
farHandsBound = farHandsBound;
farHandsBubbles = farHandsBubbles;
farHandScissors = farHandScissors;
farHandsClapping = farHandsClapping;
farHandshake = farHandshake;
farHandshakeAlt = farHandshakeAlt;
farHandshakeAltSlash = farHandshakeAltSlash;
farHandshakeAngle = farHandshakeAngle;
farHandshakeSimple = farHandshakeSimple;
farHandshakeSimpleSlash = farHandshakeSimpleSlash;
farHandshakeSlash = farHandshakeSlash;
farHandsHeart = farHandsHeart;
farHandsHelping = farHandsHelping;
farHandsHolding = farHandsHolding;
farHandsHoldingChild = farHandsHoldingChild;
farHandsHoldingCircle = farHandsHoldingCircle;
farHandsHoldingDiamond = farHandsHoldingDiamond;
farHandsHoldingDollar = farHandsHoldingDollar;
farHandsHoldingHeart = farHandsHoldingHeart;
farHandSparkles = farHandSparkles;
farHandSpock = farHandSpock;
farHandsPraying = farHandsPraying;
farHandsUsd = farHandsUsd;
farHandsWash = farHandsWash;
farHandWave = farHandWave;
farHanukiah = farHanukiah;
farHardDrive = farHardDrive;
farHardHat = farHardHat;
farHardOfHearing = farHardOfHearing;
farHashtag = farHashtag;
farHashtagLock = farHashtagLock;
farHatChef = farHatChef;
farHatCowboy = farHatCowboy;
farHatCowboySide = farHatCowboySide;
farHatHard = farHatHard;
farHatSanta = farHatSanta;
farHatWinter = farHatWinter;
farHatWitch = farHatWitch;
farHatWizard = farHatWizard;
farHdd = farHdd;
farHeader = farHeader;
farHeading = farHeading;
farHeadphones = farHeadphones;
farHeadphonesAlt = farHeadphonesAlt;
farHeadphonesSimple = farHeadphonesSimple;
farHeadset = farHeadset;
farHeadSide = farHeadSide;
farHeadSideBrain = farHeadSideBrain;
farHeadSideCough = farHeadSideCough;
farHeadSideCoughSlash = farHeadSideCoughSlash;
farHeadSideGoggles = farHeadSideGoggles;
farHeadSideHeadphones = farHeadSideHeadphones;
farHeadSideHeart = farHeadSideHeart;
farHeadSideMask = farHeadSideMask;
farHeadSideMedical = farHeadSideMedical;
farHeadSideVirus = farHeadSideVirus;
farHeadVr = farHeadVr;
farHeart = farHeart;
farHeartbeat = farHeartbeat;
farHeartBroken = farHeartBroken;
farHeartCircle = farHeartCircle;
farHeartCircleBolt = farHeartCircleBolt;
farHeartCircleCheck = farHeartCircleCheck;
farHeartCircleExclamation = farHeartCircleExclamation;
farHeartCircleMinus = farHeartCircleMinus;
farHeartCirclePlus = farHeartCirclePlus;
farHeartCircleXmark = farHeartCircleXmark;
farHeartCrack = farHeartCrack;
farHeartHalf = farHeartHalf;
farHeartHalfAlt = farHeartHalfAlt;
farHeartHalfStroke = farHeartHalfStroke;
farHeartMusicCameraBolt = farHeartMusicCameraBolt;
farHeartPulse = farHeartPulse;
farHeartRate = farHeartRate;
farHeartSquare = farHeartSquare;
farHeat = farHeat;
farHelicopter = farHelicopter;
farHelicopterSymbol = farHelicopterSymbol;
farHelmetBattle = farHelmetBattle;
farHelmetSafety = farHelmetSafety;
farHelmetUn = farHelmetUn;
farHexagon = farHexagon;
farHexagonCheck = farHexagonCheck;
farHexagonDivide = farHexagonDivide;
farHexagonExclamation = farHexagonExclamation;
farHexagonImage = farHexagonImage;
farHexagonMinus = farHexagonMinus;
farHexagonPlus = farHexagonPlus;
farHexagonVerticalNft = farHexagonVerticalNft;
farHexagonVerticalNftSlanted = farHexagonVerticalNftSlanted;
farHexagonXmark = farHexagonXmark;
farHighDefinition = farHighDefinition;
farHighlighter = farHighlighter;
farHighlighterLine = farHighlighterLine;
farHiking = farHiking;
farHillAvalanche = farHillAvalanche;
farHillRockslide = farHillRockslide;
farHippo = farHippo;
farHistory = farHistory;
farHockeyMask = farHockeyMask;
farHockeyPuck = farHockeyPuck;
farHockeyStickPuck = farHockeyStickPuck;
farHockeySticks = farHockeySticks;
farHollyBerry = farHollyBerry;
farHome = farHome;
farHomeAlt = farHomeAlt;
farHomeBlank = farHomeBlank;
farHomeHeart = farHomeHeart;
farHomeLg = farHomeLg;
farHomeLgAlt = farHomeLgAlt;
farHomeUser = farHomeUser;
farHoneyPot = farHoneyPot;
farHoodCloak = farHoodCloak;
farHorizontalRule = farHorizontalRule;
farHorse = farHorse;
farHorseHead = farHorseHead;
farHorseSaddle = farHorseSaddle;
farHose = farHose;
farHoseReel = farHoseReel;
farHospital = farHospital;
farHospitalAlt = farHospitalAlt;
farHospitals = farHospitals;
farHospitalSymbol = farHospitalSymbol;
farHospitalUser = farHospitalUser;
farHospitalWide = farHospitalWide;
farHotdog = farHotdog;
farHotel = farHotel;
farHotTub = farHotTub;
farHotTubPerson = farHotTubPerson;
farHourglass = farHourglass;
farHourglass1 = farHourglass1;
farHourglass2 = farHourglass2;
farHourglass3 = farHourglass3;
farHourglassClock = farHourglassClock;
farHourglassEmpty = farHourglassEmpty;
farHourglassEnd = farHourglassEnd;
farHourglassHalf = farHourglassHalf;
farHourglassStart = farHourglassStart;
farHouse = farHouse;
farHouseBlank = farHouseBlank;
farHouseBuilding = farHouseBuilding;
farHouseChimney = farHouseChimney;
farHouseChimneyBlank = farHouseChimneyBlank;
farHouseChimneyCrack = farHouseChimneyCrack;
farHouseChimneyHeart = farHouseChimneyHeart;
farHouseChimneyMedical = farHouseChimneyMedical;
farHouseChimneyUser = farHouseChimneyUser;
farHouseChimneyWindow = farHouseChimneyWindow;
farHouseCircleCheck = farHouseCircleCheck;
farHouseCircleExclamation = farHouseCircleExclamation;
farHouseCircleXmark = farHouseCircleXmark;
farHouseCrack = farHouseCrack;
farHouseDamage = farHouseDamage;
farHouseDay = farHouseDay;
farHouseFire = farHouseFire;
farHouseFlag = farHouseFlag;
farHouseFlood = farHouseFlood;
farHouseFloodWater = farHouseFloodWater;
farHouseFloodWaterCircleArrowRight = farHouseFloodWaterCircleArrowRight;
farHouseHeart = farHouseHeart;
farHouseLaptop = farHouseLaptop;
farHouseLeave = farHouseLeave;
farHouseLock = farHouseLock;
farHouseMedical = farHouseMedical;
farHouseMedicalCircleCheck = farHouseMedicalCircleCheck;
farHouseMedicalCircleExclamation = farHouseMedicalCircleExclamation;
farHouseMedicalCircleXmark = farHouseMedicalCircleXmark;
farHouseMedicalFlag = farHouseMedicalFlag;
farHouseNight = farHouseNight;
farHousePersonArrive = farHousePersonArrive;
farHousePersonDepart = farHousePersonDepart;
farHousePersonLeave = farHousePersonLeave;
farHousePersonReturn = farHousePersonReturn;
farHouseReturn = farHouseReturn;
farHouseSignal = farHouseSignal;
farHouseTree = farHouseTree;
farHouseTsunami = farHouseTsunami;
farHouseTurret = farHouseTurret;
farHouseUser = farHouseUser;
farHouseWater = farHouseWater;
farHouseWindow = farHouseWindow;
farHryvnia = farHryvnia;
farHryvniaSign = farHryvniaSign;
farHSquare = farHSquare;
farHumidity = farHumidity;
farHundredPoints = farHundredPoints;
farHurricane = farHurricane;
farHyphen = farHyphen;
farI = farI;
farIceCream = farIceCream;
farIceSkate = farIceSkate;
farIcicles = farIcicles;
farIcons = farIcons;
farIconsAlt = farIconsAlt;
farICursor = farICursor;
farIdBadge = farIdBadge;
farIdCard = farIdCard;
farIdCardAlt = farIdCardAlt;
farIdCardClip = farIdCardClip;
farIgloo = farIgloo;
farIls = farIls;
farImage = farImage;
farImageLandscape = farImageLandscape;
farImagePolaroid = farImagePolaroid;
farImagePolaroidUser = farImagePolaroidUser;
farImagePortrait = farImagePortrait;
farImages = farImages;
farImageSlash = farImageSlash;
farImagesUser = farImagesUser;
farImageUser = farImageUser;
farInbox = farInbox;
farInboxArrowDown = farInboxArrowDown;
farInboxArrowUp = farInboxArrowUp;
farInboxes = farInboxes;
farInboxFull = farInboxFull;
farInboxIn = farInboxIn;
farInboxOut = farInboxOut;
farIndent = farIndent;
farIndianRupee = farIndianRupee;
farIndianRupeeSign = farIndianRupeeSign;
farIndustry = farIndustry;
farIndustryAlt = farIndustryAlt;
farIndustryWindows = farIndustryWindows;
farInfinity = farInfinity;
farInfo = farInfo;
farInfoCircle = farInfoCircle;
farInfoSquare = farInfoSquare;
farInhaler = farInhaler;
farInputNumeric = farInputNumeric;
farInputPipe = farInputPipe;
farInputText = farInputText;
farInr = farInr;
farInstitution = farInstitution;
farIntegral = farIntegral;
farIntersection = farIntersection;
farInventory = farInventory;
farIslandTreePalm = farIslandTreePalm;
farIslandTropical = farIslandTropical;
farItalic = farItalic;
farJ = farJ;
farJackOLantern = farJackOLantern;
farJar = farJar;
farJarWheat = farJarWheat;
farJedi = farJedi;
farJetFighter = farJetFighter;
farJetFighterUp = farJetFighterUp;
farJoint = farJoint;
farJournalWhills = farJournalWhills;
farJoystick = farJoystick;
farJpy = farJpy;
farJug = farJug;
farJugDetergent = farJugDetergent;
farK = farK;
farKaaba = farKaaba;
farKazoo = farKazoo;
farKerning = farKerning;
farKey = farKey;
farKeyboard = farKeyboard;
farKeyboardBrightness = farKeyboardBrightness;
farKeyboardBrightnessLow = farKeyboardBrightnessLow;
farKeyboardDown = farKeyboardDown;
farKeyboardLeft = farKeyboardLeft;
farKeynote = farKeynote;
farKeySkeleton = farKeySkeleton;
farKeySkeletonLeftRight = farKeySkeletonLeftRight;
farKhanda = farKhanda;
farKidneys = farKidneys;
farKipSign = farKipSign;
farKiss = farKiss;
farKissBeam = farKissBeam;
farKissWinkHeart = farKissWinkHeart;
farKitchenSet = farKitchenSet;
farKite = farKite;
farKitMedical = farKitMedical;
farKiwiBird = farKiwiBird;
farKiwiFruit = farKiwiFruit;
farKnife = farKnife;
farKnifeKitchen = farKnifeKitchen;
farKrw = farKrw;
farL = farL;
farLacrosseStick = farLacrosseStick;
farLacrosseStickBall = farLacrosseStickBall;
farLadderWater = farLadderWater;
farLambda = farLambda;
farLamp = farLamp;
farLampDesk = farLampDesk;
farLampFloor = farLampFloor;
farLampStreet = farLampStreet;
farLandmark = farLandmark;
farLandmarkAlt = farLandmarkAlt;
farLandmarkDome = farLandmarkDome;
farLandmarkFlag = farLandmarkFlag;
farLandMineOn = farLandMineOn;
farLandscape = farLandscape;
farLanguage = farLanguage;
farLaptop = farLaptop;
farLaptopArrowDown = farLaptopArrowDown;
farLaptopCode = farLaptopCode;
farLaptopFile = farLaptopFile;
farLaptopHouse = farLaptopHouse;
farLaptopMedical = farLaptopMedical;
farLaptopMobile = farLaptopMobile;
farLaptopSlash = farLaptopSlash;
farLariSign = farLariSign;
farLasso = farLasso;
farLassoSparkles = farLassoSparkles;
farLaugh = farLaugh;
farLaughBeam = farLaughBeam;
farLaughSquint = farLaughSquint;
farLaughWink = farLaughWink;
farLayerGroup = farLayerGroup;
farLayerGroupMinus = farLayerGroupMinus;
farLayerGroupPlus = farLayerGroupPlus;
farLayerMinus = farLayerMinus;
farLayerPlus = farLayerPlus;
farLeaf = farLeaf;
farLeafHeart = farLeafHeart;
farLeafMaple = farLeafMaple;
farLeafOak = farLeafOak;
farLeafyGreen = farLeafyGreen;
farLeft = farLeft;
farLeftFromLine = farLeftFromLine;
farLeftLong = farLeftLong;
farLeftLongToLine = farLeftLongToLine;
farLeftRight = farLeftRight;
farLeftToLine = farLeftToLine;
farLegal = farLegal;
farLemon = farLemon;
farLessThan = farLessThan;
farLessThanEqual = farLessThanEqual;
farLevelDown = farLevelDown;
farLevelDownAlt = farLevelDownAlt;
farLevelUp = farLevelUp;
farLevelUpAlt = farLevelUpAlt;
farLifeRing = farLifeRing;
farLightbulb = farLightbulb;
farLightbulbDollar = farLightbulbDollar;
farLightbulbExclamation = farLightbulbExclamation;
farLightbulbExclamationOn = farLightbulbExclamationOn;
farLightbulbOn = farLightbulbOn;
farLightbulbSlash = farLightbulbSlash;
farLightCeiling = farLightCeiling;
farLightEmergency = farLightEmergency;
farLightEmergencyOn = farLightEmergencyOn;
farLightsHoliday = farLightsHoliday;
farLightSwitch = farLightSwitch;
farLightSwitchOff = farLightSwitchOff;
farLightSwitchOn = farLightSwitchOn;
farLineChart = farLineChart;
farLineColumns = farLineColumns;
farLineHeight = farLineHeight;
farLinesLeaning = farLinesLeaning;
farLink = farLink;
farLinkHorizontal = farLinkHorizontal;
farLinkHorizontalSlash = farLinkHorizontalSlash;
farLinkSimple = farLinkSimple;
farLinkSimpleSlash = farLinkSimpleSlash;
farLinkSlash = farLinkSlash;
farLips = farLips;
farLiraSign = farLiraSign;
farList = farList;
farList12 = farList12;
farListAlt = farListAlt;
farListCheck = farListCheck;
farListDots = farListDots;
farListDropdown = farListDropdown;
farListMusic = farListMusic;
farListNumeric = farListNumeric;
farListOl = farListOl;
farListRadio = farListRadio;
farListSquares = farListSquares;
farListTimeline = farListTimeline;
farListTree = farListTree;
farListUl = farListUl;
farLitecoinSign = farLitecoinSign;
farLoader = farLoader;
farLobster = farLobster;
farLocation = farLocation;
farLocationArrow = farLocationArrow;
farLocationCheck = farLocationCheck;
farLocationCircle = farLocationCircle;
farLocationCrosshairs = farLocationCrosshairs;
farLocationCrosshairsSlash = farLocationCrosshairsSlash;
farLocationDot = farLocationDot;
farLocationDotSlash = farLocationDotSlash;
farLocationExclamation = farLocationExclamation;
farLocationMinus = farLocationMinus;
farLocationPen = farLocationPen;
farLocationPin = farLocationPin;
farLocationPinLock = farLocationPinLock;
farLocationPinSlash = farLocationPinSlash;
farLocationPlus = farLocationPlus;
farLocationQuestion = farLocationQuestion;
farLocationSlash = farLocationSlash;
farLocationSmile = farLocationSmile;
farLocationXmark = farLocationXmark;
farLock = farLock;
farLockA = farLockA;
farLockAlt = farLockAlt;
farLockHashtag = farLockHashtag;
farLockKeyhole = farLockKeyhole;
farLockKeyholeOpen = farLockKeyholeOpen;
farLockOpen = farLockOpen;
farLockOpenAlt = farLockOpenAlt;
farLocust = farLocust;
farLollipop = farLollipop;
farLollypop = farLollypop;
farLongArrowAltDown = farLongArrowAltDown;
farLongArrowAltLeft = farLongArrowAltLeft;
farLongArrowAltRight = farLongArrowAltRight;
farLongArrowAltUp = farLongArrowAltUp;
farLongArrowDown = farLongArrowDown;
farLongArrowLeft = farLongArrowLeft;
farLongArrowRight = farLongArrowRight;
farLongArrowUp = farLongArrowUp;
farLoveseat = farLoveseat;
farLowVision = farLowVision;
farLuchador = farLuchador;
farLuchadorMask = farLuchadorMask;
farLuggageCart = farLuggageCart;
farLungs = farLungs;
farLungsVirus = farLungsVirus;
farM = farM;
farMace = farMace;
farMagic = farMagic;
farMagicWandSparkles = farMagicWandSparkles;
farMagnet = farMagnet;
farMagnifyingGlass = farMagnifyingGlass;
farMagnifyingGlassArrowRight = farMagnifyingGlassArrowRight;
farMagnifyingGlassChart = farMagnifyingGlassChart;
farMagnifyingGlassDollar = farMagnifyingGlassDollar;
farMagnifyingGlassLocation = farMagnifyingGlassLocation;
farMagnifyingGlassMinus = farMagnifyingGlassMinus;
farMagnifyingGlassPlus = farMagnifyingGlassPlus;
farMailbox = farMailbox;
farMailBulk = farMailBulk;
farMailForward = farMailForward;
farMailReply = farMailReply;
farMailReplyAll = farMailReplyAll;
farMakiRoll = farMakiRoll;
farMakizushi = farMakizushi;
farMale = farMale;
farManatSign = farManatSign;
farMandolin = farMandolin;
farMango = farMango;
farManhole = farManhole;
farMap = farMap;
farMapLocation = farMapLocation;
farMapLocationDot = farMapLocationDot;
farMapMarked = farMapMarked;
farMapMarkedAlt = farMapMarkedAlt;
farMapMarker = farMapMarker;
farMapMarkerAlt = farMapMarkerAlt;
farMapMarkerAltSlash = farMapMarkerAltSlash;
farMapMarkerCheck = farMapMarkerCheck;
farMapMarkerEdit = farMapMarkerEdit;
farMapMarkerExclamation = farMapMarkerExclamation;
farMapMarkerMinus = farMapMarkerMinus;
farMapMarkerPlus = farMapMarkerPlus;
farMapMarkerQuestion = farMapMarkerQuestion;
farMapMarkerSlash = farMapMarkerSlash;
farMapMarkerSmile = farMapMarkerSmile;
farMapMarkerTimes = farMapMarkerTimes;
farMapMarkerXmark = farMapMarkerXmark;
farMapPin = farMapPin;
farMapSigns = farMapSigns;
farMarker = farMarker;
farMars = farMars;
farMarsAndVenus = farMarsAndVenus;
farMarsAndVenusBurst = farMarsAndVenusBurst;
farMarsDouble = farMarsDouble;
farMarsStroke = farMarsStroke;
farMarsStrokeH = farMarsStrokeH;
farMarsStrokeRight = farMarsStrokeRight;
farMarsStrokeUp = farMarsStrokeUp;
farMarsStrokeV = farMarsStrokeV;
farMartiniGlass = farMartiniGlass;
farMartiniGlassCitrus = farMartiniGlassCitrus;
farMartiniGlassEmpty = farMartiniGlassEmpty;
farMask = farMask;
farMaskFace = farMaskFace;
farMaskLuchador = farMaskLuchador;
farMaskSnorkel = farMaskSnorkel;
farMasksTheater = farMasksTheater;
farMaskVentilator = farMaskVentilator;
farMattressPillow = farMattressPillow;
farMaximize = farMaximize;
farMeat = farMeat;
farMedal = farMedal;
farMedkit = farMedkit;
farMegaphone = farMegaphone;
farMeh = farMeh;
farMehBlank = farMehBlank;
farMehRollingEyes = farMehRollingEyes;
farMelon = farMelon;
farMelonSlice = farMelonSlice;
farMemo = farMemo;
farMemoCircleCheck = farMemoCircleCheck;
farMemoCircleInfo = farMemoCircleInfo;
farMemoPad = farMemoPad;
farMemory = farMemory;
farMenorah = farMenorah;
farMercury = farMercury;
farMerge = farMerge;
farMessage = farMessage;
farMessageArrowDown = farMessageArrowDown;
farMessageArrowUp = farMessageArrowUp;
farMessageArrowUpRight = farMessageArrowUpRight;
farMessageBot = farMessageBot;
farMessageCaptions = farMessageCaptions;
farMessageCheck = farMessageCheck;
farMessageCode = farMessageCode;
farMessageDollar = farMessageDollar;
farMessageDots = farMessageDots;
farMessageEdit = farMessageEdit;
farMessageExclamation = farMessageExclamation;
farMessageImage = farMessageImage;
farMessageLines = farMessageLines;
farMessageMedical = farMessageMedical;
farMessageMiddle = farMessageMiddle;
farMessageMiddleTop = farMessageMiddleTop;
farMessageMinus = farMessageMinus;
farMessageMusic = farMessageMusic;
farMessagePen = farMessagePen;
farMessagePlus = farMessagePlus;
farMessageQuestion = farMessageQuestion;
farMessageQuote = farMessageQuote;
farMessages = farMessages;
farMessagesDollar = farMessagesDollar;
farMessageSlash = farMessageSlash;
farMessageSmile = farMessageSmile;
farMessageSms = farMessageSms;
farMessagesQuestion = farMessagesQuestion;
farMessageText = farMessageText;
farMessageTimes = farMessageTimes;
farMessageXmark = farMessageXmark;
farMessaging = farMessaging;
farMeteor = farMeteor;
farMeter = farMeter;
farMeterBolt = farMeterBolt;
farMeterDroplet = farMeterDroplet;
farMeterFire = farMeterFire;
farMicrochip = farMicrochip;
farMicrochipAi = farMicrochipAi;
farMicrophone = farMicrophone;
farMicrophoneAlt = farMicrophoneAlt;
farMicrophoneAltSlash = farMicrophoneAltSlash;
farMicrophoneCircle = farMicrophoneCircle;
farMicrophoneCircleAlt = farMicrophoneCircleAlt;
farMicrophoneLines = farMicrophoneLines;
farMicrophoneLinesSlash = farMicrophoneLinesSlash;
farMicrophoneSlash = farMicrophoneSlash;
farMicrophoneStand = farMicrophoneStand;
farMicroscope = farMicroscope;
farMicrowave = farMicrowave;
farMillSign = farMillSign;
farMindShare = farMindShare;
farMinimize = farMinimize;
farMinus = farMinus;
farMinusCircle = farMinusCircle;
farMinusHexagon = farMinusHexagon;
farMinusOctagon = farMinusOctagon;
farMinusSquare = farMinusSquare;
farMistletoe = farMistletoe;
farMitten = farMitten;
farMobile = farMobile;
farMobileAlt = farMobileAlt;
farMobileAndroid = farMobileAndroid;
farMobileAndroidAlt = farMobileAndroidAlt;
farMobileButton = farMobileButton;
farMobileIphone = farMobileIphone;
farMobileNotch = farMobileNotch;
farMobilePhone = farMobilePhone;
farMobileRetro = farMobileRetro;
farMobileScreen = farMobileScreen;
farMobileScreenButton = farMobileScreenButton;
farMobileSignal = farMobileSignal;
farMobileSignalOut = farMobileSignalOut;
farMoneyBill = farMoneyBill;
farMoneyBill1 = farMoneyBill1;
farMoneyBill1Wave = farMoneyBill1Wave;
farMoneyBillAlt = farMoneyBillAlt;
farMoneyBills = farMoneyBills;
farMoneyBillsAlt = farMoneyBillsAlt;
farMoneyBillSimple = farMoneyBillSimple;
farMoneyBillSimpleWave = farMoneyBillSimpleWave;
farMoneyBillsSimple = farMoneyBillsSimple;
farMoneyBillTransfer = farMoneyBillTransfer;
farMoneyBillTrendUp = farMoneyBillTrendUp;
farMoneyBillWave = farMoneyBillWave;
farMoneyBillWaveAlt = farMoneyBillWaveAlt;
farMoneyBillWheat = farMoneyBillWheat;
farMoneyCheck = farMoneyCheck;
farMoneyCheckAlt = farMoneyCheckAlt;
farMoneyCheckDollar = farMoneyCheckDollar;
farMoneyCheckDollarPen = farMoneyCheckDollarPen;
farMoneyCheckEdit = farMoneyCheckEdit;
farMoneyCheckEditAlt = farMoneyCheckEditAlt;
farMoneyCheckPen = farMoneyCheckPen;
farMoneyFromBracket = farMoneyFromBracket;
farMoneySimpleFromBracket = farMoneySimpleFromBracket;
farMonitorHeartRate = farMonitorHeartRate;
farMonitorWaveform = farMonitorWaveform;
farMonkey = farMonkey;
farMonument = farMonument;
farMoon = farMoon;
farMoonCloud = farMoonCloud;
farMoonOverSun = farMoonOverSun;
farMoonStars = farMoonStars;
farMoped = farMoped;
farMortarBoard = farMortarBoard;
farMortarPestle = farMortarPestle;
farMosque = farMosque;
farMosquito = farMosquito;
farMosquitoNet = farMosquitoNet;
farMotorcycle = farMotorcycle;
farMound = farMound;
farMountain = farMountain;
farMountainCity = farMountainCity;
farMountains = farMountains;
farMountainSun = farMountainSun;
farMouse = farMouse;
farMouseAlt = farMouseAlt;
farMousePointer = farMousePointer;
farMp3Player = farMp3Player;
farMug = farMug;
farMugHot = farMugHot;
farMugMarshmallows = farMugMarshmallows;
farMugSaucer = farMugSaucer;
farMugTea = farMugTea;
farMugTeaSaucer = farMugTeaSaucer;
farMultiply = farMultiply;
farMuseum = farMuseum;
farMushroom = farMushroom;
farMusic = farMusic;
farMusicAlt = farMusicAlt;
farMusicAltSlash = farMusicAltSlash;
farMusicNote = farMusicNote;
farMusicNoteSlash = farMusicNoteSlash;
farMusicSlash = farMusicSlash;
farN = farN;
farNairaSign = farNairaSign;
farNarwhal = farNarwhal;
farNavicon = farNavicon;
farNestingDolls = farNestingDolls;
farNetworkWired = farNetworkWired;
farNeuter = farNeuter;
farNewspaper = farNewspaper;
farNfc = farNfc;
farNfcLock = farNfcLock;
farNfcMagnifyingGlass = farNfcMagnifyingGlass;
farNfcPen = farNfcPen;
farNfcSignal = farNfcSignal;
farNfcSlash = farNfcSlash;
farNfcSymbol = farNfcSymbol;
farNfcTrash = farNfcTrash;
farNigiri = farNigiri;
farNotdef = farNotdef;
farNote = farNote;
farNotebook = farNotebook;
farNoteMedical = farNoteMedical;
farNotEqual = farNotEqual;
farNotes = farNotes;
farNotesMedical = farNotesMedical;
farNoteSticky = farNoteSticky;
farO = farO;
farObjectExclude = farObjectExclude;
farObjectGroup = farObjectGroup;
farObjectIntersect = farObjectIntersect;
farObjectsAlignBottom = farObjectsAlignBottom;
farObjectsAlignCenterHorizontal = farObjectsAlignCenterHorizontal;
farObjectsAlignCenterVertical = farObjectsAlignCenterVertical;
farObjectsAlignLeft = farObjectsAlignLeft;
farObjectsAlignRight = farObjectsAlignRight;
farObjectsAlignTop = farObjectsAlignTop;
farObjectsColumn = farObjectsColumn;
farObjectSubtract = farObjectSubtract;
farObjectUngroup = farObjectUngroup;
farObjectUnion = farObjectUnion;
farOctagon = farOctagon;
farOctagonCheck = farOctagonCheck;
farOctagonDivide = farOctagonDivide;
farOctagonExclamation = farOctagonExclamation;
farOctagonMinus = farOctagonMinus;
farOctagonPlus = farOctagonPlus;
farOctagonXmark = farOctagonXmark;
farOilCan = farOilCan;
farOilCanDrip = farOilCanDrip;
farOilTemp = farOilTemp;
farOilTemperature = farOilTemperature;
farOilWell = farOilWell;
farOlive = farOlive;
farOliveBranch = farOliveBranch;
farOm = farOm;
farOmega = farOmega;
farOnion = farOnion;
farOption = farOption;
farOrnament = farOrnament;
farOtter = farOtter;
farOutdent = farOutdent;
farOutlet = farOutlet;
farOven = farOven;
farOverline = farOverline;
farP = farP;
farPage = farPage;
farPageBreak = farPageBreak;
farPageCaretDown = farPageCaretDown;
farPageCaretUp = farPageCaretUp;
farPager = farPager;
farPaintbrush = farPaintbrush;
farPaintBrush = farPaintBrush;
farPaintbrushAlt = farPaintbrushAlt;
farPaintBrushAlt = farPaintBrushAlt;
farPaintbrushFine = farPaintbrushFine;
farPaintBrushFine = farPaintBrushFine;
farPaintbrushPencil = farPaintbrushPencil;
farPaintRoller = farPaintRoller;
farPalette = farPalette;
farPaletteBoxes = farPaletteBoxes;
farPallet = farPallet;
farPalletAlt = farPalletAlt;
farPalletBox = farPalletBox;
farPalletBoxes = farPalletBoxes;
farPancakes = farPancakes;
farPanelEws = farPanelEws;
farPanelFire = farPanelFire;
farPanFood = farPanFood;
farPanFrying = farPanFrying;
farPanorama = farPanorama;
farPaperclip = farPaperclip;
farPaperclipVertical = farPaperclipVertical;
farPaperPlane = farPaperPlane;
farPaperPlaneAlt = farPaperPlaneAlt;
farPaperPlaneTop = farPaperPlaneTop;
farParachuteBox = farParachuteBox;
farParagraph = farParagraph;
farParagraphLeft = farParagraphLeft;
farParagraphRtl = farParagraphRtl;
farParentheses = farParentheses;
farParenthesis = farParenthesis;
farParking = farParking;
farParkingCircle = farParkingCircle;
farParkingCircleSlash = farParkingCircleSlash;
farParkingSlash = farParkingSlash;
farPartyBack = farPartyBack;
farPartyBell = farPartyBell;
farPartyHorn = farPartyHorn;
farPassport = farPassport;
farPastafarrianism = farPastafarrianism;
farPaste = farPaste;
farPause = farPause;
farPauseCircle = farPauseCircle;
farPaw = farPaw;
farPawAlt = farPawAlt;
farPawClaws = farPawClaws;
farPawSimple = farPawSimple;
farPeace = farPeace;
farPeach = farPeach;
farPeanut = farPeanut;
farPeanuts = farPeanuts;
farPeapod = farPeapod;
farPear = farPear;
farPedestal = farPedestal;
farPegasus = farPegasus;
farPen = farPen;
farPenAlt = farPenAlt;
farPenAltSlash = farPenAltSlash;
farPencil = farPencil;
farPencilAlt = farPencilAlt;
farPencilPaintbrush = farPencilPaintbrush;
farPencilRuler = farPencilRuler;
farPencilSlash = farPencilSlash;
farPencilSquare = farPencilSquare;
farPenCircle = farPenCircle;
farPenClip = farPenClip;
farPenClipSlash = farPenClipSlash;
farPenFancy = farPenFancy;
farPenFancySlash = farPenFancySlash;
farPenField = farPenField;
farPenLine = farPenLine;
farPennant = farPennant;
farPenNib = farPenNib;
farPenNibSlash = farPenNibSlash;
farPenPaintbrush = farPenPaintbrush;
farPenRuler = farPenRuler;
farPenSlash = farPenSlash;
farPenSquare = farPenSquare;
farPenSwirl = farPenSwirl;
farPenToSquare = farPenToSquare;
farPeople = farPeople;
farPeopleArrows = farPeopleArrows;
farPeopleArrowsLeftRight = farPeopleArrowsLeftRight;
farPeopleCarry = farPeopleCarry;
farPeopleCarryBox = farPeopleCarryBox;
farPeopleDress = farPeopleDress;
farPeopleDressSimple = farPeopleDressSimple;
farPeopleGroup = farPeopleGroup;
farPeopleLine = farPeopleLine;
farPeoplePants = farPeoplePants;
farPeoplePantsSimple = farPeoplePantsSimple;
farPeoplePulling = farPeoplePulling;
farPeopleRobbery = farPeopleRobbery;
farPeopleRoof = farPeopleRoof;
farPeopleSimple = farPeopleSimple;
farPepper = farPepper;
farPepperHot = farPepperHot;
farPercent = farPercent;
farPercentage = farPercentage;
farPeriod = farPeriod;
farPerson = farPerson;
farPersonArrowDownToLine = farPersonArrowDownToLine;
farPersonArrowUpFromLine = farPersonArrowUpFromLine;
farPersonBiking = farPersonBiking;
farPersonBikingMountain = farPersonBikingMountain;
farPersonBooth = farPersonBooth;
farPersonBreastfeeding = farPersonBreastfeeding;
farPersonBurst = farPersonBurst;
farPersonCane = farPersonCane;
farPersonCarry = farPersonCarry;
farPersonCarryBox = farPersonCarryBox;
farPersonChalkboard = farPersonChalkboard;
farPersonCircleCheck = farPersonCircleCheck;
farPersonCircleExclamation = farPersonCircleExclamation;
farPersonCircleMinus = farPersonCircleMinus;
farPersonCirclePlus = farPersonCirclePlus;
farPersonCircleQuestion = farPersonCircleQuestion;
farPersonCircleXmark = farPersonCircleXmark;
farPersonDigging = farPersonDigging;
farPersonDolly = farPersonDolly;
farPersonDollyEmpty = farPersonDollyEmpty;
farPersonDotsFromLine = farPersonDotsFromLine;
farPersonDress = farPersonDress;
farPersonDressBurst = farPersonDressBurst;
farPersonDressSimple = farPersonDressSimple;
farPersonDrowning = farPersonDrowning;
farPersonFalling = farPersonFalling;
farPersonFallingBurst = farPersonFallingBurst;
farPersonFromPortal = farPersonFromPortal;
farPersonHalfDress = farPersonHalfDress;
farPersonHarassing = farPersonHarassing;
farPersonHiking = farPersonHiking;
farPersonMilitaryPointing = farPersonMilitaryPointing;
farPersonMilitaryRifle = farPersonMilitaryRifle;
farPersonMilitaryToPerson = farPersonMilitaryToPerson;
farPersonPinball = farPersonPinball;
farPersonPraying = farPersonPraying;
farPersonPregnant = farPersonPregnant;
farPersonRays = farPersonRays;
farPersonRifle = farPersonRifle;
farPersonRunning = farPersonRunning;
farPersonSeat = farPersonSeat;
farPersonSeatReclined = farPersonSeatReclined;
farPersonShelter = farPersonShelter;
farPersonSign = farPersonSign;
farPersonSimple = farPersonSimple;
farPersonSkating = farPersonSkating;
farPersonSkiing = farPersonSkiing;
farPersonSkiingNordic = farPersonSkiingNordic;
farPersonSkiJumping = farPersonSkiJumping;
farPersonSkiLift = farPersonSkiLift;
farPersonSledding = farPersonSledding;
farPersonSnowboarding = farPersonSnowboarding;
farPersonSnowmobiling = farPersonSnowmobiling;
farPersonSwimming = farPersonSwimming;
farPersonThroughWindow = farPersonThroughWindow;
farPersonToDoor = farPersonToDoor;
farPersonToPortal = farPersonToPortal;
farPersonWalking = farPersonWalking;
farPersonWalkingArrowLoopLeft = farPersonWalkingArrowLoopLeft;
farPersonWalkingArrowRight = farPersonWalkingArrowRight;
farPersonWalkingDashedLineArrowRight = farPersonWalkingDashedLineArrowRight;
farPersonWalkingLuggage = farPersonWalkingLuggage;
farPersonWalkingWithCane = farPersonWalkingWithCane;
farPesetaSign = farPesetaSign;
farPesoSign = farPesoSign;
farPhone = farPhone;
farPhoneAlt = farPhoneAlt;
farPhoneArrowDown = farPhoneArrowDown;
farPhoneArrowDownLeft = farPhoneArrowDownLeft;
farPhoneArrowUp = farPhoneArrowUp;
farPhoneArrowUpRight = farPhoneArrowUpRight;
farPhoneCircle = farPhoneCircle;
farPhoneCircleAlt = farPhoneCircleAlt;
farPhoneCircleDown = farPhoneCircleDown;
farPhoneFlip = farPhoneFlip;
farPhoneHangup = farPhoneHangup;
farPhoneIncoming = farPhoneIncoming;
farPhoneIntercom = farPhoneIntercom;
farPhoneLaptop = farPhoneLaptop;
farPhoneMissed = farPhoneMissed;
farPhoneOffice = farPhoneOffice;
farPhoneOutgoing = farPhoneOutgoing;
farPhonePlus = farPhonePlus;
farPhoneRotary = farPhoneRotary;
farPhoneSlash = farPhoneSlash;
farPhoneSquare = farPhoneSquare;
farPhoneSquareAlt = farPhoneSquareAlt;
farPhoneSquareDown = farPhoneSquareDown;
farPhoneVolume = farPhoneVolume;
farPhoneXmark = farPhoneXmark;
farPhotoFilm = farPhotoFilm;
farPhotoFilmMusic = farPhotoFilmMusic;
farPhotoVideo = farPhotoVideo;
farPi = farPi;
farPiano = farPiano;
farPianoKeyboard = farPianoKeyboard;
farPickleball = farPickleball;
farPie = farPie;
farPieChart = farPieChart;
farPig = farPig;
farPiggyBank = farPiggyBank;
farPills = farPills;
farPinata = farPinata;
farPinball = farPinball;
farPineapple = farPineapple;
farPingPongPaddleBall = farPingPongPaddleBall;
farPipe = farPipe;
farPipeCircleCheck = farPipeCircleCheck;
farPipeCollar = farPipeCollar;
farPipeSection = farPipeSection;
farPipeSmoking = farPipeSmoking;
farPipeValve = farPipeValve;
farPizza = farPizza;
farPizzaSlice = farPizzaSlice;
farPlaceOfWorship = farPlaceOfWorship;
farPlane = farPlane;
farPlaneAlt = farPlaneAlt;
farPlaneArrival = farPlaneArrival;
farPlaneCircleCheck = farPlaneCircleCheck;
farPlaneCircleExclamation = farPlaneCircleExclamation;
farPlaneCircleXmark = farPlaneCircleXmark;
farPlaneDeparture = farPlaneDeparture;
farPlaneEngines = farPlaneEngines;
farPlaneLock = farPlaneLock;
farPlaneProp = farPlaneProp;
farPlaneSlash = farPlaneSlash;
farPlaneTail = farPlaneTail;
farPlanetMoon = farPlanetMoon;
farPlanetRinged = farPlanetRinged;
farPlaneUp = farPlaneUp;
farPlaneUpSlash = farPlaneUpSlash;
farPlantWilt = farPlantWilt;
farPlateUtensils = farPlateUtensils;
farPlateWheat = farPlateWheat;
farPlay = farPlay;
farPlayCircle = farPlayCircle;
farPlayPause = farPlayPause;
farPlug = farPlug;
farPlugCircleBolt = farPlugCircleBolt;
farPlugCircleCheck = farPlugCircleCheck;
farPlugCircleExclamation = farPlugCircleExclamation;
farPlugCircleMinus = farPlugCircleMinus;
farPlugCirclePlus = farPlugCirclePlus;
farPlugCircleXmark = farPlugCircleXmark;
farPlus = farPlus;
farPlusCircle = farPlusCircle;
farPlusHexagon = farPlusHexagon;
farPlusMinus = farPlusMinus;
farPlusOctagon = farPlusOctagon;
farPlusSquare = farPlusSquare;
farPodcast = farPodcast;
farPodium = farPodium;
farPodiumStar = farPodiumStar;
farPoliceBox = farPoliceBox;
farPoll = farPoll;
farPollH = farPollH;
farPollPeople = farPollPeople;
farPompebled = farPompebled;
farPoo = farPoo;
farPooBolt = farPooBolt;
farPool8Ball = farPool8Ball;
farPoop = farPoop;
farPooStorm = farPooStorm;
farPopcorn = farPopcorn;
farPopsicle = farPopsicle;
farPortalEnter = farPortalEnter;
farPortalExit = farPortalExit;
farPortrait = farPortrait;
farPotato = farPotato;
farPotFood = farPotFood;
farPoundSign = farPoundSign;
farPowerOff = farPowerOff;
farPray = farPray;
farPrayingHands = farPrayingHands;
farPrescription = farPrescription;
farPrescriptionBottle = farPrescriptionBottle;
farPrescriptionBottleAlt = farPrescriptionBottleAlt;
farPrescriptionBottleMedical = farPrescriptionBottleMedical;
farPresentation = farPresentation;
farPresentationScreen = farPresentationScreen;
farPretzel = farPretzel;
farPrint = farPrint;
farPrintMagnifyingGlass = farPrintMagnifyingGlass;
farPrintSearch = farPrintSearch;
farPrintSlash = farPrintSlash;
farPro = farPro;
farProcedures = farProcedures;
farProjectDiagram = farProjectDiagram;
farProjector = farProjector;
farPump = farPump;
farPumpkin = farPumpkin;
farPumpMedical = farPumpMedical;
farPumpSoap = farPumpSoap;
farPuzzle = farPuzzle;
farPuzzlePiece = farPuzzlePiece;
farPuzzlePieceAlt = farPuzzlePieceAlt;
farPuzzlePieceSimple = farPuzzlePieceSimple;
farQ = farQ;
farQrcode = farQrcode;
farQuestion = farQuestion;
farQuestionCircle = farQuestionCircle;
farQuestionSquare = farQuestionSquare;
farQuidditch = farQuidditch;
farQuidditchBroomBall = farQuidditchBroomBall;
farQuoteLeft = farQuoteLeft;
farQuoteLeftAlt = farQuoteLeftAlt;
farQuoteRight = farQuoteRight;
farQuoteRightAlt = farQuoteRightAlt;
farQuotes = farQuotes;
farQuran = farQuran;
farR = farR;
farRabbit = farRabbit;
farRabbitFast = farRabbitFast;
farRabbitRunning = farRabbitRunning;
farRacquet = farRacquet;
farRadar = farRadar;
farRadiation = farRadiation;
farRadiationAlt = farRadiationAlt;
farRadio = farRadio;
farRadioAlt = farRadioAlt;
farRadioTuner = farRadioTuner;
farRainbow = farRainbow;
farRaindrops = farRaindrops;
farRam = farRam;
farRampLoading = farRampLoading;
farRandom = farRandom;
farRankingStar = farRankingStar;
farRaygun = farRaygun;
farReceipt = farReceipt;
farRecordVinyl = farRecordVinyl;
farRectangle = farRectangle;
farRectangleAd = farRectangleAd;
farRectangleBarcode = farRectangleBarcode;
farRectangleCode = farRectangleCode;
farRectangleHd = farRectangleHd;
farRectangleHistory = farRectangleHistory;
farRectangleHistoryCirclePlus = farRectangleHistoryCirclePlus;
farRectangleHistoryCircleUser = farRectangleHistoryCircleUser;
farRectangleLandscape = farRectangleLandscape;
farRectangleList = farRectangleList;
farRectanglePortrait = farRectanglePortrait;
farRectanglePro = farRectanglePro;
farRectangleSd = farRectangleSd;
farRectanglesMixed = farRectanglesMixed;
farRectangleTerminal = farRectangleTerminal;
farRectangleTimes = farRectangleTimes;
farRectangleVertical = farRectangleVertical;
farRectangleVerticalHistory = farRectangleVerticalHistory;
farRectangleWide = farRectangleWide;
farRectangleXmark = farRectangleXmark;
farRecycle = farRecycle;
farRedo = farRedo;
farRedoAlt = farRedoAlt;
farReel = farReel;
farRefresh = farRefresh;
farRefrigerator = farRefrigerator;
farRegistered = farRegistered;
farRemove = farRemove;
farRemoveFormat = farRemoveFormat;
farReorder = farReorder;
farRepeat = farRepeat;
farRepeat1 = farRepeat1;
farRepeat1Alt = farRepeat1Alt;
farRepeatAlt = farRepeatAlt;
farReply = farReply;
farReplyAll = farReplyAll;
farReplyClock = farReplyClock;
farReplyTime = farReplyTime;
farRepublican = farRepublican;
farRestroom = farRestroom;
farRestroomSimple = farRestroomSimple;
farRetweet = farRetweet;
farRetweetAlt = farRetweetAlt;
farRhombus = farRhombus;
farRibbon = farRibbon;
farRight = farRight;
farRightFromBracket = farRightFromBracket;
farRightFromLine = farRightFromLine;
farRightLeft = farRightLeft;
farRightLong = farRightLong;
farRightLongToLine = farRightLongToLine;
farRightToBracket = farRightToBracket;
farRightToLine = farRightToLine;
farRing = farRing;
farRingsWedding = farRingsWedding;
farRmb = farRmb;
farRoad = farRoad;
farRoadBarrier = farRoadBarrier;
farRoadBridge = farRoadBridge;
farRoadCircleCheck = farRoadCircleCheck;
farRoadCircleExclamation = farRoadCircleExclamation;
farRoadCircleXmark = farRoadCircleXmark;
farRoadLock = farRoadLock;
farRoadSpikes = farRoadSpikes;
farRobot = farRobot;
farRobotAstromech = farRobotAstromech;
farRocket = farRocket;
farRocketLaunch = farRocketLaunch;
farRodAsclepius = farRodAsclepius;
farRodSnake = farRodSnake;
farRollerCoaster = farRollerCoaster;
farRotate = farRotate;
farRotateBack = farRotateBack;
farRotateBackward = farRotateBackward;
farRotateExclamation = farRotateExclamation;
farRotateForward = farRotateForward;
farRotateLeft = farRotateLeft;
farRotateRight = farRotateRight;
farRouble = farRouble;
farRoute = farRoute;
farRouteHighway = farRouteHighway;
farRouteInterstate = farRouteInterstate;
farRouter = farRouter;
farRows = farRows;
farRss = farRss;
farRssSquare = farRssSquare;
farRub = farRub;
farRuble = farRuble;
farRubleSign = farRubleSign;
farRug = farRug;
farRugbyBall = farRugbyBall;
farRuler = farRuler;
farRulerCombined = farRulerCombined;
farRulerHorizontal = farRulerHorizontal;
farRulerTriangle = farRulerTriangle;
farRulerVertical = farRulerVertical;
farRunning = farRunning;
farRupee = farRupee;
farRupeeSign = farRupeeSign;
farRupiahSign = farRupiahSign;
farRv = farRv;
farS = farS;
farSack = farSack;
farSackDollar = farSackDollar;
farSackXmark = farSackXmark;
farSadCry = farSadCry;
farSadTear = farSadTear;
farSailboat = farSailboat;
farSalad = farSalad;
farSaltShaker = farSaltShaker;
farSandwich = farSandwich;
farSatellite = farSatellite;
farSatelliteDish = farSatelliteDish;
farSausage = farSausage;
farSave = farSave;
farSaveCircleArrowRight = farSaveCircleArrowRight;
farSaveCircleXmark = farSaveCircleXmark;
farSaveTimes = farSaveTimes;
farSaxHot = farSaxHot;
farSaxophone = farSaxophone;
farSaxophoneFire = farSaxophoneFire;
farScaleBalanced = farScaleBalanced;
farScaleUnbalanced = farScaleUnbalanced;
farScaleUnbalancedFlip = farScaleUnbalancedFlip;
farScalpel = farScalpel;
farScalpelLineDashed = farScalpelLineDashed;
farScalpelPath = farScalpelPath;
farScanner = farScanner;
farScannerGun = farScannerGun;
farScannerImage = farScannerImage;
farScannerKeyboard = farScannerKeyboard;
farScannerTouchscreen = farScannerTouchscreen;
farScarecrow = farScarecrow;
farScarf = farScarf;
farSchool = farSchool;
farSchoolCircleCheck = farSchoolCircleCheck;
farSchoolCircleExclamation = farSchoolCircleExclamation;
farSchoolCircleXmark = farSchoolCircleXmark;
farSchoolFlag = farSchoolFlag;
farSchoolLock = farSchoolLock;
farScissors = farScissors;
farScreencast = farScreencast;
farScreenshot = farScreenshot;
farScreenUsers = farScreenUsers;
farScrewdriver = farScrewdriver;
farScrewdriverWrench = farScrewdriverWrench;
farScribble = farScribble;
farScroll = farScroll;
farScrollOld = farScrollOld;
farScrollRibbon = farScrollRibbon;
farScrollTorah = farScrollTorah;
farScrubber = farScrubber;
farScythe = farScythe;
farSdCard = farSdCard;
farSdCards = farSdCards;
farSeal = farSeal;
farSealExclamation = farSealExclamation;
farSealQuestion = farSealQuestion;
farSearch = farSearch;
farSearchDollar = farSearchDollar;
farSearchLocation = farSearchLocation;
farSearchMinus = farSearchMinus;
farSearchPlus = farSearchPlus;
farSeatAirline = farSeatAirline;
farSection = farSection;
farSeedling = farSeedling;
farSemicolon = farSemicolon;
farSend = farSend;
farSendBack = farSendBack;
farSendBackward = farSendBackward;
farSensor = farSensor;
farSensorAlert = farSensorAlert;
farSensorCloud = farSensorCloud;
farSensorFire = farSensorFire;
farSensorOn = farSensorOn;
farSensorSmoke = farSensorSmoke;
farSensorTriangleExclamation = farSensorTriangleExclamation;
farServer = farServer;
farShapes = farShapes;
farShare = farShare;
farShareAll = farShareAll;
farShareAlt = farShareAlt;
farShareAltSquare = farShareAltSquare;
farShareFromSquare = farShareFromSquare;
farShareNodes = farShareNodes;
farShareSquare = farShareSquare;
farSheep = farSheep;
farSheetPlastic = farSheetPlastic;
farShekel = farShekel;
farShekelSign = farShekelSign;
farShelves = farShelves;
farShelvesEmpty = farShelvesEmpty;
farSheqel = farSheqel;
farSheqelSign = farSheqelSign;
farShield = farShield;
farShieldAlt = farShieldAlt;
farShieldBlank = farShieldBlank;
farShieldCat = farShieldCat;
farShieldCheck = farShieldCheck;
farShieldCross = farShieldCross;
farShieldDog = farShieldDog;
farShieldExclamation = farShieldExclamation;
farShieldHalved = farShieldHalved;
farShieldHeart = farShieldHeart;
farShieldKeyhole = farShieldKeyhole;
farShieldMinus = farShieldMinus;
farShieldPlus = farShieldPlus;
farShieldQuartered = farShieldQuartered;
farShieldSlash = farShieldSlash;
farShieldTimes = farShieldTimes;
farShieldVirus = farShieldVirus;
farShieldXmark = farShieldXmark;
farShip = farShip;
farShippingFast = farShippingFast;
farShippingTimed = farShippingTimed;
farShirt = farShirt;
farShirtLongSleeve = farShirtLongSleeve;
farShirtRunning = farShirtRunning;
farShirtTankTop = farShirtTankTop;
farShishKebab = farShishKebab;
farShoePrints = farShoePrints;
farShop = farShop;
farShopLock = farShopLock;
farShoppingBag = farShoppingBag;
farShoppingBasket = farShoppingBasket;
farShoppingBasketAlt = farShoppingBasketAlt;
farShoppingCart = farShoppingCart;
farShopSlash = farShopSlash;
farShortcake = farShortcake;
farShovel = farShovel;
farShovelSnow = farShovelSnow;
farShower = farShower;
farShowerAlt = farShowerAlt;
farShowerDown = farShowerDown;
farShredder = farShredder;
farShrimp = farShrimp;
farShuffle = farShuffle;
farShutters = farShutters;
farShuttlecock = farShuttlecock;
farShuttleSpace = farShuttleSpace;
farShuttleVan = farShuttleVan;
farSickle = farSickle;
farSidebar = farSidebar;
farSidebarFlip = farSidebarFlip;
farSigma = farSigma;
farSign = farSign;
farSignal = farSignal;
farSignal1 = farSignal1;
farSignal2 = farSignal2;
farSignal3 = farSignal3;
farSignal4 = farSignal4;
farSignal5 = farSignal5;
farSignalAlt = farSignalAlt;
farSignalAlt1 = farSignalAlt1;
farSignalAlt2 = farSignalAlt2;
farSignalAlt3 = farSignalAlt3;
farSignalAlt4 = farSignalAlt4;
farSignalAltSlash = farSignalAltSlash;
farSignalBars = farSignalBars;
farSignalBarsFair = farSignalBarsFair;
farSignalBarsGood = farSignalBarsGood;
farSignalBarsSlash = farSignalBarsSlash;
farSignalBarsStrong = farSignalBarsStrong;
farSignalBarsWeak = farSignalBarsWeak;
farSignalFair = farSignalFair;
farSignalGood = farSignalGood;
farSignalPerfect = farSignalPerfect;
farSignalSlash = farSignalSlash;
farSignalStream = farSignalStream;
farSignalStreamSlash = farSignalStreamSlash;
farSignalStrong = farSignalStrong;
farSignalWeak = farSignalWeak;
farSignature = farSignature;
farSignatureLock = farSignatureLock;
farSignatureSlash = farSignatureSlash;
farSignHanging = farSignHanging;
farSignIn = farSignIn;
farSignInAlt = farSignInAlt;
farSigning = farSigning;
farSignLanguage = farSignLanguage;
farSignOut = farSignOut;
farSignOutAlt = farSignOutAlt;
farSignsPost = farSignsPost;
farSimCard = farSimCard;
farSimCards = farSimCards;
farSink = farSink;
farSiren = farSiren;
farSirenOn = farSirenOn;
farSitemap = farSitemap;
farSkating = farSkating;
farSkeleton = farSkeleton;
farSkiBoot = farSkiBoot;
farSkiBootSki = farSkiBootSki;
farSkiing = farSkiing;
farSkiingNordic = farSkiingNordic;
farSkiJump = farSkiJump;
farSkiLift = farSkiLift;
farSkull = farSkull;
farSkullCow = farSkullCow;
farSkullCrossbones = farSkullCrossbones;
farSlash = farSlash;
farSlashBack = farSlashBack;
farSlashForward = farSlashForward;
farSledding = farSledding;
farSleigh = farSleigh;
farSlider = farSlider;
farSliders = farSliders;
farSlidersH = farSlidersH;
farSlidersHSquare = farSlidersHSquare;
farSlidersSimple = farSlidersSimple;
farSlidersUp = farSlidersUp;
farSlidersV = farSlidersV;
farSlidersVSquare = farSlidersVSquare;
farSlotMachine = farSlotMachine;
farSmile = farSmile;
farSmileBeam = farSmileBeam;
farSmilePlus = farSmilePlus;
farSmileWink = farSmileWink;
farSmog = farSmog;
farSmoke = farSmoke;
farSmoking = farSmoking;
farSmokingBan = farSmokingBan;
farSms = farSms;
farSnake = farSnake;
farSnooze = farSnooze;
farSnowBlowing = farSnowBlowing;
farSnowboarding = farSnowboarding;
farSnowflake = farSnowflake;
farSnowflakes = farSnowflakes;
farSnowman = farSnowman;
farSnowmanHead = farSnowmanHead;
farSnowmobile = farSnowmobile;
farSnowplow = farSnowplow;
farSoap = farSoap;
farSoccerBall = farSoccerBall;
farSocks = farSocks;
farSoftServe = farSoftServe;
farSolarPanel = farSolarPanel;
farSolarSystem = farSolarSystem;
farSort = farSort;
farSortAlphaAsc = farSortAlphaAsc;
farSortAlphaDesc = farSortAlphaDesc;
farSortAlphaDown = farSortAlphaDown;
farSortAlphaDownAlt = farSortAlphaDownAlt;
farSortAlphaUp = farSortAlphaUp;
farSortAlphaUpAlt = farSortAlphaUpAlt;
farSortAlt = farSortAlt;
farSortAmountAsc = farSortAmountAsc;
farSortAmountDesc = farSortAmountDesc;
farSortAmountDown = farSortAmountDown;
farSortAmountDownAlt = farSortAmountDownAlt;
farSortAmountUp = farSortAmountUp;
farSortAmountUpAlt = farSortAmountUpAlt;
farSortAsc = farSortAsc;
farSortCircle = farSortCircle;
farSortCircleDown = farSortCircleDown;
farSortCircleUp = farSortCircleUp;
farSortDesc = farSortDesc;
farSortDown = farSortDown;
farSortNumericAsc = farSortNumericAsc;
farSortNumericDesc = farSortNumericDesc;
farSortNumericDown = farSortNumericDown;
farSortNumericDownAlt = farSortNumericDownAlt;
farSortNumericUp = farSortNumericUp;
farSortNumericUpAlt = farSortNumericUpAlt;
farSortShapesDown = farSortShapesDown;
farSortShapesDownAlt = farSortShapesDownAlt;
farSortShapesUp = farSortShapesUp;
farSortShapesUpAlt = farSortShapesUpAlt;
farSortSizeDown = farSortSizeDown;
farSortSizeDownAlt = farSortSizeDownAlt;
farSortSizeUp = farSortSizeUp;
farSortSizeUpAlt = farSortSizeUpAlt;
farSortUp = farSortUp;
farSortUpDown = farSortUpDown;
farSoup = farSoup;
farSpa = farSpa;
farSpaceShuttle = farSpaceShuttle;
farSpaceStationMoon = farSpaceStationMoon;
farSpaceStationMoonAlt = farSpaceStationMoonAlt;
farSpaceStationMoonConstruction = farSpaceStationMoonConstruction;
farSpade = farSpade;
farSpaghettiMonsterFlying = farSpaghettiMonsterFlying;
farSparkles = farSparkles;
farSpeaker = farSpeaker;
farSpeakers = farSpeakers;
farSpellCheck = farSpellCheck;
farSpider = farSpider;
farSpiderBlackWidow = farSpiderBlackWidow;
farSpiderWeb = farSpiderWeb;
farSpinner = farSpinner;
farSpinnerThird = farSpinnerThird;
farSplit = farSplit;
farSplotch = farSplotch;
farSpoon = farSpoon;
farSportsball = farSportsball;
farSprayCan = farSprayCan;
farSprayCanSparkles = farSprayCanSparkles;
farSprinkler = farSprinkler;
farSprinklerCeiling = farSprinklerCeiling;
farSprout = farSprout;
farSquare = farSquare;
farSquare0 = farSquare0;
farSquare1 = farSquare1;
farSquare2 = farSquare2;
farSquare3 = farSquare3;
farSquare4 = farSquare4;
farSquare5 = farSquare5;
farSquare6 = farSquare6;
farSquare7 = farSquare7;
farSquare8 = farSquare8;
farSquare9 = farSquare9;
farSquareA = farSquareA;
farSquareALock = farSquareALock;
farSquareAmpersand = farSquareAmpersand;
farSquareArrowDown = farSquareArrowDown;
farSquareArrowDownLeft = farSquareArrowDownLeft;
farSquareArrowDownRight = farSquareArrowDownRight;
farSquareArrowLeft = farSquareArrowLeft;
farSquareArrowRight = farSquareArrowRight;
farSquareArrowUp = farSquareArrowUp;
farSquareArrowUpLeft = farSquareArrowUpLeft;
farSquareArrowUpRight = farSquareArrowUpRight;
farSquareB = farSquareB;
farSquareBolt = farSquareBolt;
farSquareC = farSquareC;
farSquareCaretDown = farSquareCaretDown;
farSquareCaretLeft = farSquareCaretLeft;
farSquareCaretRight = farSquareCaretRight;
farSquareCaretUp = farSquareCaretUp;
farSquareCheck = farSquareCheck;
farSquareChevronDown = farSquareChevronDown;
farSquareChevronLeft = farSquareChevronLeft;
farSquareChevronRight = farSquareChevronRight;
farSquareChevronUp = farSquareChevronUp;
farSquareCode = farSquareCode;
farSquareD = farSquareD;
farSquareDashed = farSquareDashed;
farSquareDivide = farSquareDivide;
farSquareDollar = farSquareDollar;
farSquareDown = farSquareDown;
farSquareDownLeft = farSquareDownLeft;
farSquareDownRight = farSquareDownRight;
farSquareE = farSquareE;
farSquareEllipsis = farSquareEllipsis;
farSquareEllipsisVertical = farSquareEllipsisVertical;
farSquareEnvelope = farSquareEnvelope;
farSquareExclamation = farSquareExclamation;
farSquareF = farSquareF;
farSquareFragile = farSquareFragile;
farSquareFull = farSquareFull;
farSquareG = farSquareG;
farSquareH = farSquareH;
farSquareHeart = farSquareHeart;
farSquareI = farSquareI;
farSquareInfo = farSquareInfo;
farSquareJ = farSquareJ;
farSquareK = farSquareK;
farSquareKanban = farSquareKanban;
farSquareL = farSquareL;
farSquareLeft = farSquareLeft;
farSquareList = farSquareList;
farSquareM = farSquareM;
farSquareMinus = farSquareMinus;
farSquareN = farSquareN;
farSquareNfi = farSquareNfi;
farSquareO = farSquareO;
farSquareP = farSquareP;
farSquareParking = farSquareParking;
farSquareParkingSlash = farSquareParkingSlash;
farSquarePen = farSquarePen;
farSquarePersonConfined = farSquarePersonConfined;
farSquarePhone = farSquarePhone;
farSquarePhoneFlip = farSquarePhoneFlip;
farSquarePhoneHangup = farSquarePhoneHangup;
farSquarePlus = farSquarePlus;
farSquarePollHorizontal = farSquarePollHorizontal;
farSquarePollVertical = farSquarePollVertical;
farSquareQ = farSquareQ;
farSquareQuarters = farSquareQuarters;
farSquareQuestion = farSquareQuestion;
farSquareQuote = farSquareQuote;
farSquareR = farSquareR;
farSquareRight = farSquareRight;
farSquareRing = farSquareRing;
farSquareRoot = farSquareRoot;
farSquareRootAlt = farSquareRootAlt;
farSquareRootVariable = farSquareRootVariable;
farSquareRss = farSquareRss;
farSquareS = farSquareS;
farSquareShareNodes = farSquareShareNodes;
farSquareSliders = farSquareSliders;
farSquareSlidersVertical = farSquareSlidersVertical;
farSquareSmall = farSquareSmall;
farSquareStar = farSquareStar;
farSquareT = farSquareT;
farSquareTerminal = farSquareTerminal;
farSquareThisWayUp = farSquareThisWayUp;
farSquareU = farSquareU;
farSquareUp = farSquareUp;
farSquareUpLeft = farSquareUpLeft;
farSquareUpRight = farSquareUpRight;
farSquareUser = farSquareUser;
farSquareV = farSquareV;
farSquareVirus = farSquareVirus;
farSquareW = farSquareW;
farSquareWineGlassCrack = farSquareWineGlassCrack;
farSquareX = farSquareX;
farSquareXmark = farSquareXmark;
farSquareY = farSquareY;
farSquareZ = farSquareZ;
farSquid = farSquid;
farSquirrel = farSquirrel;
farStaff = farStaff;
farStaffAesculapius = farStaffAesculapius;
farStaffSnake = farStaffSnake;
farStairs = farStairs;
farStamp = farStamp;
farStandardDefinition = farStandardDefinition;
farStar = farStar;
farStarAndCrescent = farStarAndCrescent;
farStarChristmas = farStarChristmas;
farStarCircle = farStarCircle;
farStarExclamation = farStarExclamation;
farStarfighter = farStarfighter;
farStarfighterAlt = farStarfighterAlt;
farStarfighterAltAdvanced = farStarfighterAltAdvanced;
farStarfighterTwinIonEngine = farStarfighterTwinIonEngine;
farStarfighterTwinIonEngineAdvanced = farStarfighterTwinIonEngineAdvanced;
farStarHalf = farStarHalf;
farStarHalfAlt = farStarHalfAlt;
farStarHalfStroke = farStarHalfStroke;
farStarOfDavid = farStarOfDavid;
farStarOfLife = farStarOfLife;
farStars = farStars;
farStarSharp = farStarSharp;
farStarSharpHalf = farStarSharpHalf;
farStarSharpHalfAlt = farStarSharpHalfAlt;
farStarSharpHalfStroke = farStarSharpHalfStroke;
farStarship = farStarship;
farStarshipFreighter = farStarshipFreighter;
farStarShooting = farStarShooting;
farSteak = farSteak;
farSteeringWheel = farSteeringWheel;
farStepBackward = farStepBackward;
farStepForward = farStepForward;
farSterlingSign = farSterlingSign;
farStethoscope = farStethoscope;
farStickyNote = farStickyNote;
farStocking = farStocking;
farStomach = farStomach;
farStop = farStop;
farStopCircle = farStopCircle;
farStopwatch = farStopwatch;
farStopwatch20 = farStopwatch20;
farStore = farStore;
farStoreAlt = farStoreAlt;
farStoreAltSlash = farStoreAltSlash;
farStoreLock = farStoreLock;
farStoreSlash = farStoreSlash;
farStrawberry = farStrawberry;
farStream = farStream;
farStreetView = farStreetView;
farStretcher = farStretcher;
farStrikethrough = farStrikethrough;
farStroopwafel = farStroopwafel;
farSubscript = farSubscript;
farSubtract = farSubtract;
farSubway = farSubway;
farSubwayTunnel = farSubwayTunnel;
farSuitcase = farSuitcase;
farSuitcaseMedical = farSuitcaseMedical;
farSuitcaseRolling = farSuitcaseRolling;
farSun = farSun;
farSunAlt = farSunAlt;
farSunBright = farSunBright;
farSunCloud = farSunCloud;
farSunDust = farSunDust;
farSunglasses = farSunglasses;
farSunHaze = farSunHaze;
farSunPlantWilt = farSunPlantWilt;
farSunrise = farSunrise;
farSunset = farSunset;
farSuperscript = farSuperscript;
farSurprise = farSurprise;
farSushi = farSushi;
farSushiRoll = farSushiRoll;
farSwatchbook = farSwatchbook;
farSwimmer = farSwimmer;
farSwimmingPool = farSwimmingPool;
farSword = farSword;
farSwordLaser = farSwordLaser;
farSwordLaserAlt = farSwordLaserAlt;
farSwords = farSwords;
farSwordsLaser = farSwordsLaser;
farSymbols = farSymbols;
farSynagogue = farSynagogue;
farSync = farSync;
farSyncAlt = farSyncAlt;
farSyringe = farSyringe;
farT = farT;
farTable = farTable;
farTableCells = farTableCells;
farTableCellsLarge = farTableCellsLarge;
farTableColumns = farTableColumns;
farTableLayout = farTableLayout;
farTableList = farTableList;
farTablePicnic = farTablePicnic;
farTablePivot = farTablePivot;
farTableRows = farTableRows;
farTablet = farTablet;
farTabletAlt = farTabletAlt;
farTabletAndroid = farTabletAndroid;
farTabletAndroidAlt = farTabletAndroidAlt;
farTabletButton = farTabletButton;
farTableTennis = farTableTennis;
farTableTennisPaddleBall = farTableTennisPaddleBall;
farTableTree = farTableTree;
farTabletRugged = farTabletRugged;
farTablets = farTablets;
farTabletScreen = farTabletScreen;
farTabletScreenButton = farTabletScreenButton;
farTachographDigital = farTachographDigital;
farTachometer = farTachometer;
farTachometerAlt = farTachometerAlt;
farTachometerAltAverage = farTachometerAltAverage;
farTachometerAltFast = farTachometerAltFast;
farTachometerAltFastest = farTachometerAltFastest;
farTachometerAltSlow = farTachometerAltSlow;
farTachometerAltSlowest = farTachometerAltSlowest;
farTachometerAverage = farTachometerAverage;
farTachometerFast = farTachometerFast;
farTachometerFastest = farTachometerFastest;
farTachometerSlow = farTachometerSlow;
farTachometerSlowest = farTachometerSlowest;
farTaco = farTaco;
farTag = farTag;
farTags = farTags;
farTally = farTally;
farTally1 = farTally1;
farTally2 = farTally2;
farTally3 = farTally3;
farTally4 = farTally4;
farTally5 = farTally5;
farTamale = farTamale;
farTanakh = farTanakh;
farTankWater = farTankWater;
farTape = farTape;
farTarp = farTarp;
farTarpDroplet = farTarpDroplet;
farTasks = farTasks;
farTasksAlt = farTasksAlt;
farTaxi = farTaxi;
farTaxiBus = farTaxiBus;
farTeddyBear = farTeddyBear;
farTeeth = farTeeth;
farTeethOpen = farTeethOpen;
farTelescope = farTelescope;
farTeletype = farTeletype;
farTeletypeAnswer = farTeletypeAnswer;
farTelevision = farTelevision;
farTemperature0 = farTemperature0;
farTemperature1 = farTemperature1;
farTemperature2 = farTemperature2;
farTemperature3 = farTemperature3;
farTemperature4 = farTemperature4;
farTemperatureArrowDown = farTemperatureArrowDown;
farTemperatureArrowUp = farTemperatureArrowUp;
farTemperatureDown = farTemperatureDown;
farTemperatureEmpty = farTemperatureEmpty;
farTemperatureFrigid = farTemperatureFrigid;
farTemperatureFull = farTemperatureFull;
farTemperatureHalf = farTemperatureHalf;
farTemperatureHigh = farTemperatureHigh;
farTemperatureHot = farTemperatureHot;
farTemperatureList = farTemperatureList;
farTemperatureLow = farTemperatureLow;
farTemperatureQuarter = farTemperatureQuarter;
farTemperatureSnow = farTemperatureSnow;
farTemperatureSun = farTemperatureSun;
farTemperatureThreeQuarters = farTemperatureThreeQuarters;
farTemperatureUp = farTemperatureUp;
farTenge = farTenge;
farTengeSign = farTengeSign;
farTennisBall = farTennisBall;
farTent = farTent;
farTentArrowDownToLine = farTentArrowDownToLine;
farTentArrowLeftRight = farTentArrowLeftRight;
farTentArrowsDown = farTentArrowsDown;
farTentArrowTurnLeft = farTentArrowTurnLeft;
farTents = farTents;
farTerminal = farTerminal;
farText = farText;
farTextHeight = farTextHeight;
farTextSize = farTextSize;
farTextSlash = farTextSlash;
farTextWidth = farTextWidth;
farTh = farTh;
farTheaterMasks = farTheaterMasks;
farThermometer = farThermometer;
farThermometer0 = farThermometer0;
farThermometer1 = farThermometer1;
farThermometer2 = farThermometer2;
farThermometer3 = farThermometer3;
farThermometer4 = farThermometer4;
farThermometerEmpty = farThermometerEmpty;
farThermometerFull = farThermometerFull;
farThermometerHalf = farThermometerHalf;
farThermometerQuarter = farThermometerQuarter;
farThermometerThreeQuarters = farThermometerThreeQuarters;
farTheta = farTheta;
farThLarge = farThLarge;
farThList = farThList;
farThoughtBubble = farThoughtBubble;
farThumbsDown = farThumbsDown;
farThumbsUp = farThumbsUp;
farThumbtack = farThumbtack;
farThumbTack = farThumbTack;
farThunderstorm = farThunderstorm;
farThunderstormMoon = farThunderstormMoon;
farThunderstormSun = farThunderstormSun;
farTick = farTick;
farTicket = farTicket;
farTicketAirline = farTicketAirline;
farTicketAlt = farTicketAlt;
farTicketsAirline = farTicketsAirline;
farTicketSimple = farTicketSimple;
farTilde = farTilde;
farTimeline = farTimeline;
farTimelineArrow = farTimelineArrow;
farTimer = farTimer;
farTimes = farTimes;
farTimesCircle = farTimesCircle;
farTimesHexagon = farTimesHexagon;
farTimesOctagon = farTimesOctagon;
farTimesRectangle = farTimesRectangle;
farTimesSquare = farTimesSquare;
farTimesToSlot = farTimesToSlot;
farTint = farTint;
farTintSlash = farTintSlash;
farTire = farTire;
farTired = farTired;
farTireFlat = farTireFlat;
farTirePressureWarning = farTirePressureWarning;
farTireRugged = farTireRugged;
farToggleOff = farToggleOff;
farToggleOn = farToggleOn;
farToilet = farToilet;
farToiletPaper = farToiletPaper;
farToiletPaperAlt = farToiletPaperAlt;
farToiletPaperBlank = farToiletPaperBlank;
farToiletPaperBlankUnder = farToiletPaperBlankUnder;
farToiletPaperReverse = farToiletPaperReverse;
farToiletPaperReverseAlt = farToiletPaperReverseAlt;
farToiletPaperReverseSlash = farToiletPaperReverseSlash;
farToiletPaperSlash = farToiletPaperSlash;
farToiletPaperUnder = farToiletPaperUnder;
farToiletPaperUnderSlash = farToiletPaperUnderSlash;
farToiletPortable = farToiletPortable;
farToiletsPortable = farToiletsPortable;
farTomato = farTomato;
farTombstone = farTombstone;
farTombstoneAlt = farTombstoneAlt;
farTombstoneBlank = farTombstoneBlank;
farToolbox = farToolbox;
farTools = farTools;
farTooth = farTooth;
farToothbrush = farToothbrush;
farTorah = farTorah;
farToriiGate = farToriiGate;
farTornado = farTornado;
farTowerBroadcast = farTowerBroadcast;
farTowerCell = farTowerCell;
farTowerControl = farTowerControl;
farTowerObservation = farTowerObservation;
farTractor = farTractor;
farTrademark = farTrademark;
farTrafficCone = farTrafficCone;
farTrafficLight = farTrafficLight;
farTrafficLightGo = farTrafficLightGo;
farTrafficLightSlow = farTrafficLightSlow;
farTrafficLightStop = farTrafficLightStop;
farTrailer = farTrailer;
farTrain = farTrain;
farTrainSubway = farTrainSubway;
farTrainSubwayTunnel = farTrainSubwayTunnel;
farTrainTrack = farTrainTrack;
farTrainTram = farTrainTram;
farTrainTunnel = farTrainTunnel;
farTram = farTram;
farTransformerBolt = farTransformerBolt;
farTransgender = farTransgender;
farTransgenderAlt = farTransgenderAlt;
farTransporter = farTransporter;
farTransporter1 = farTransporter1;
farTransporter2 = farTransporter2;
farTransporter3 = farTransporter3;
farTransporter4 = farTransporter4;
farTransporter5 = farTransporter5;
farTransporter6 = farTransporter6;
farTransporter7 = farTransporter7;
farTransporterEmpty = farTransporterEmpty;
farTrash = farTrash;
farTrashAlt = farTrashAlt;
farTrashAltSlash = farTrashAltSlash;
farTrashArrowTurnLeft = farTrashArrowTurnLeft;
farTrashArrowUp = farTrashArrowUp;
farTrashCan = farTrashCan;
farTrashCanArrowTurnLeft = farTrashCanArrowTurnLeft;
farTrashCanArrowUp = farTrashCanArrowUp;
farTrashCanCheck = farTrashCanCheck;
farTrashCanClock = farTrashCanClock;
farTrashCanList = farTrashCanList;
farTrashCanPlus = farTrashCanPlus;
farTrashCanSlash = farTrashCanSlash;
farTrashCanUndo = farTrashCanUndo;
farTrashCanXmark = farTrashCanXmark;
farTrashCheck = farTrashCheck;
farTrashCircle = farTrashCircle;
farTrashClock = farTrashClock;
farTrashList = farTrashList;
farTrashPlus = farTrashPlus;
farTrashRestore = farTrashRestore;
farTrashRestoreAlt = farTrashRestoreAlt;
farTrashSlash = farTrashSlash;
farTrashUndo = farTrashUndo;
farTrashUndoAlt = farTrashUndoAlt;
farTrashXmark = farTrashXmark;
farTreasureChest = farTreasureChest;
farTree = farTree;
farTreeAlt = farTreeAlt;
farTreeChristmas = farTreeChristmas;
farTreeCity = farTreeCity;
farTreeDeciduous = farTreeDeciduous;
farTreeDecorated = farTreeDecorated;
farTreeLarge = farTreeLarge;
farTreePalm = farTreePalm;
farTrees = farTrees;
farTrianBalbot = farTrianBalbot;
farTriangle = farTriangle;
farTriangleCircleSquare = farTriangleCircleSquare;
farTriangleExclamation = farTriangleExclamation;
farTriangleInstrument = farTriangleInstrument;
farTriangleMusic = farTriangleMusic;
farTrianglePersonDigging = farTrianglePersonDigging;
farTrillium = farTrillium;
farTrophy = farTrophy;
farTrophyAlt = farTrophyAlt;
farTrophyStar = farTrophyStar;
farTrowel = farTrowel;
farTrowelBricks = farTrowelBricks;
farTruck = farTruck;
farTruckArrowRight = farTruckArrowRight;
farTruckBolt = farTruckBolt;
farTruckClock = farTruckClock;
farTruckContainer = farTruckContainer;
farTruckContainerEmpty = farTruckContainerEmpty;
farTruckCouch = farTruckCouch;
farTruckDroplet = farTruckDroplet;
farTruckFast = farTruckFast;
farTruckField = farTruckField;
farTruckFieldUn = farTruckFieldUn;
farTruckFlatbed = farTruckFlatbed;
farTruckFront = farTruckFront;
farTruckLoading = farTruckLoading;
farTruckMedical = farTruckMedical;
farTruckMonster = farTruckMonster;
farTruckMoving = farTruckMoving;
farTruckPickup = farTruckPickup;
farTruckPlane = farTruckPlane;
farTruckPlow = farTruckPlow;
farTruckRamp = farTruckRamp;
farTruckRampBox = farTruckRampBox;
farTruckRampCouch = farTruckRampCouch;
farTruckTow = farTruckTow;
farTrumpet = farTrumpet;
farTry = farTry;
farTshirt = farTshirt;
farTShirt = farTShirt;
farTty = farTty;
farTtyAnswer = farTtyAnswer;
farTugrikSign = farTugrikSign;
farTurkey = farTurkey;
farTurkishLira = farTurkishLira;
farTurkishLiraSign = farTurkishLiraSign;
farTurnDown = farTurnDown;
farTurnDownLeft = farTurnDownLeft;
farTurnDownRight = farTurnDownRight;
farTurntable = farTurntable;
farTurnUp = farTurnUp;
farTurtle = farTurtle;
farTv = farTv;
farTvAlt = farTvAlt;
farTvMusic = farTvMusic;
farTvRetro = farTvRetro;
farTypewriter = farTypewriter;
farU = farU;
farUfo = farUfo;
farUfoBeam = farUfoBeam;
farUmbrella = farUmbrella;
farUmbrellaAlt = farUmbrellaAlt;
farUmbrellaBeach = farUmbrellaBeach;
farUmbrellaSimple = farUmbrellaSimple;
farUnderline = farUnderline;
farUndo = farUndo;
farUndoAlt = farUndoAlt;
farUnicorn = farUnicorn;
farUniformMartialArts = farUniformMartialArts;
farUnion = farUnion;
farUniversalAccess = farUniversalAccess;
farUniversity = farUniversity;
farUnlink = farUnlink;
farUnlock = farUnlock;
farUnlockAlt = farUnlockAlt;
farUnlockKeyhole = farUnlockKeyhole;
farUnsorted = farUnsorted;
farUp = farUp;
farUpDown = farUpDown;
farUpDownLeftRight = farUpDownLeftRight;
farUpFromBracket = farUpFromBracket;
farUpFromDottedLine = farUpFromDottedLine;
farUpFromLine = farUpFromLine;
farUpLeft = farUpLeft;
farUpload = farUpload;
farUpLong = farUpLong;
farUpRight = farUpRight;
farUpRightAndDownLeftFromCenter = farUpRightAndDownLeftFromCenter;
farUpRightFromSquare = farUpRightFromSquare;
farUpToDottedLine = farUpToDottedLine;
farUpToLine = farUpToLine;
farUsbDrive = farUsbDrive;
farUsd = farUsd;
farUsdCircle = farUsdCircle;
farUsdSquare = farUsdSquare;
farUser = farUser;
farUserAlien = farUserAlien;
farUserAlt = farUserAlt;
farUserAltSlash = farUserAltSlash;
farUserAstronaut = farUserAstronaut;
farUserBountyHunter = farUserBountyHunter;
farUserChart = farUserChart;
farUserCheck = farUserCheck;
farUserChef = farUserChef;
farUserCircle = farUserCircle;
farUserClock = farUserClock;
farUserCog = farUserCog;
farUserConstruction = farUserConstruction;
farUserCowboy = farUserCowboy;
farUserCrown = farUserCrown;
farUserDoctor = farUserDoctor;
farUserDoctorHair = farUserDoctorHair;
farUserDoctorHairLong = farUserDoctorHairLong;
farUserDoctorMessage = farUserDoctorMessage;
farUserEdit = farUserEdit;
farUserFriends = farUserFriends;
farUserGear = farUserGear;
farUserGraduate = farUserGraduate;
farUserGroup = farUserGroup;
farUserGroupCrown = farUserGroupCrown;
farUserHair = farUserHair;
farUserHairBuns = farUserHairBuns;
farUserHairLong = farUserHairLong;
farUserHairMullet = farUserHairMullet;
farUserHardHat = farUserHardHat;
farUserHeadset = farUserHeadset;
farUserHelmetSafety = farUserHelmetSafety;
farUserInjured = farUserInjured;
farUserLarge = farUserLarge;
farUserLargeSlash = farUserLargeSlash;
farUserLock = farUserLock;
farUserMd = farUserMd;
farUserMdChat = farUserMdChat;
farUserMinus = farUserMinus;
farUserMusic = farUserMusic;
farUserNinja = farUserNinja;
farUserNurse = farUserNurse;
farUserNurseHair = farUserNurseHair;
farUserNurseHairLong = farUserNurseHairLong;
farUserPen = farUserPen;
farUserPilot = farUserPilot;
farUserPilotTie = farUserPilotTie;
farUserPlus = farUserPlus;
farUserPolice = farUserPolice;
farUserPoliceTie = farUserPoliceTie;
farUserRobot = farUserRobot;
farUserRobotXmarks = farUserRobotXmarks;
farUsers = farUsers;
farUsersBetweenLines = farUsersBetweenLines;
farUsersClass = farUsersClass;
farUsersCog = farUsersCog;
farUsersCrown = farUsersCrown;
farUserSecret = farUserSecret;
farUsersGear = farUsersGear;
farUserShakespeare = farUserShakespeare;
farUserShield = farUserShield;
farUserSlash = farUserSlash;
farUsersLine = farUsersLine;
farUsersMedical = farUsersMedical;
farUsersRays = farUsersRays;
farUsersRectangle = farUsersRectangle;
farUsersSlash = farUsersSlash;
farUsersViewfinder = farUsersViewfinder;
farUserTag = farUserTag;
farUserTie = farUserTie;
farUserTieHair = farUserTieHair;
farUserTieHairLong = farUserTieHairLong;
farUserTimes = farUserTimes;
farUserUnlock = farUserUnlock;
farUserVisor = farUserVisor;
farUserVneck = farUserVneck;
farUserVneckHair = farUserVneckHair;
farUserVneckHairLong = farUserVneckHairLong;
farUserXmark = farUserXmark;
farUtensilFork = farUtensilFork;
farUtensilKnife = farUtensilKnife;
farUtensils = farUtensils;
farUtensilsAlt = farUtensilsAlt;
farUtensilSpoon = farUtensilSpoon;
farUtensilsSlash = farUtensilsSlash;
farUtilityPole = farUtilityPole;
farUtilityPoleDouble = farUtilityPoleDouble;
farV = farV;
farVacuum = farVacuum;
farVacuumRobot = farVacuumRobot;
farValueAbsolute = farValueAbsolute;
farVanShuttle = farVanShuttle;
farVault = farVault;
farVcard = farVcard;
farVectorCircle = farVectorCircle;
farVectorPolygon = farVectorPolygon;
farVectorSquare = farVectorSquare;
farVentDamper = farVentDamper;
farVenus = farVenus;
farVenusDouble = farVenusDouble;
farVenusMars = farVenusMars;
farVest = farVest;
farVestPatches = farVestPatches;
farVhs = farVhs;
farVial = farVial;
farVialCircleCheck = farVialCircleCheck;
farVials = farVials;
farVialVirus = farVialVirus;
farVideo = farVideo;
farVideoArrowDownLeft = farVideoArrowDownLeft;
farVideoArrowUpRight = farVideoArrowUpRight;
farVideoCamera = farVideoCamera;
farVideoCircle = farVideoCircle;
farVideoHandheld = farVideoHandheld;
farVideoPlus = farVideoPlus;
farVideoSlash = farVideoSlash;
farVihara = farVihara;
farViolin = farViolin;
farVirus = farVirus;
farVirusCovid = farVirusCovid;
farVirusCovidSlash = farVirusCovidSlash;
farViruses = farViruses;
farVirusSlash = farVirusSlash;
farVoicemail = farVoicemail;
farVolcano = farVolcano;
farVolleyball = farVolleyball;
farVolleyballBall = farVolleyballBall;
farVolume = farVolume;
farVolumeControlPhone = farVolumeControlPhone;
farVolumeDown = farVolumeDown;
farVolumeHigh = farVolumeHigh;
farVolumeLow = farVolumeLow;
farVolumeMedium = farVolumeMedium;
farVolumeMute = farVolumeMute;
farVolumeOff = farVolumeOff;
farVolumeSlash = farVolumeSlash;
farVolumeTimes = farVolumeTimes;
farVolumeUp = farVolumeUp;
farVolumeXmark = farVolumeXmark;
farVoteNay = farVoteNay;
farVoteYea = farVoteYea;
farVrCardboard = farVrCardboard;
farW = farW;
farWaffle = farWaffle;
farWagonCovered = farWagonCovered;
farWalker = farWalker;
farWalkieTalkie = farWalkieTalkie;
farWalking = farWalking;
farWallBrick = farWallBrick;
farWallet = farWallet;
farWand = farWand;
farWandMagic = farWandMagic;
farWandMagicSparkles = farWandMagicSparkles;
farWandSparkles = farWandSparkles;
farWarehouse = farWarehouse;
farWarehouseAlt = farWarehouseAlt;
farWarehouseFull = farWarehouseFull;
farWarning = farWarning;
farWasher = farWasher;
farWashingMachine = farWashingMachine;
farWatch = farWatch;
farWatchApple = farWatchApple;
farWatchCalculator = farWatchCalculator;
farWatchFitness = farWatchFitness;
farWatchSmart = farWatchSmart;
farWater = farWater;
farWaterArrowDown = farWaterArrowDown;
farWaterArrowUp = farWaterArrowUp;
farWaterLadder = farWaterLadder;
farWaterLower = farWaterLower;
farWatermelonSlice = farWatermelonSlice;
farWaterRise = farWaterRise;
farWaveform = farWaveform;
farWaveformCircle = farWaveformCircle;
farWaveformLines = farWaveformLines;
farWaveformPath = farWaveformPath;
farWavePulse = farWavePulse;
farWaveSine = farWaveSine;
farWaveSquare = farWaveSquare;
farWaveTriangle = farWaveTriangle;
farWebcam = farWebcam;
farWebcamSlash = farWebcamSlash;
farWeight = farWeight;
farWeightHanging = farWeightHanging;
farWeightScale = farWeightScale;
farWhale = farWhale;
farWheat = farWheat;
farWheatAlt = farWheatAlt;
farWheatAwn = farWheatAwn;
farWheatAwnCircleExclamation = farWheatAwnCircleExclamation;
farWheatAwnSlash = farWheatAwnSlash;
farWheatSlash = farWheatSlash;
farWheelchair = farWheelchair;
farWheelchairAlt = farWheelchairAlt;
farWheelchairMove = farWheelchairMove;
farWhiskeyGlass = farWhiskeyGlass;
farWhiskeyGlassIce = farWhiskeyGlassIce;
farWhistle = farWhistle;
farWifi = farWifi;
farWifi1 = farWifi1;
farWifi2 = farWifi2;
farWifi3 = farWifi3;
farWifiExclamation = farWifiExclamation;
farWifiFair = farWifiFair;
farWifiSlash = farWifiSlash;
farWifiStrong = farWifiStrong;
farWifiWeak = farWifiWeak;
farWind = farWind;
farWindCircleExclamation = farWindCircleExclamation;
farWindow = farWindow;
farWindowAlt = farWindowAlt;
farWindowClose = farWindowClose;
farWindowFlip = farWindowFlip;
farWindowFrame = farWindowFrame;
farWindowFrameOpen = farWindowFrameOpen;
farWindowMaximize = farWindowMaximize;
farWindowMinimize = farWindowMinimize;
farWindowRestore = farWindowRestore;
farWindsock = farWindsock;
farWindTurbine = farWindTurbine;
farWindWarning = farWindWarning;
farWineBottle = farWineBottle;
farWineGlass = farWineGlass;
farWineGlassAlt = farWineGlassAlt;
farWineGlassCrack = farWineGlassCrack;
farWineGlassEmpty = farWineGlassEmpty;
farWon = farWon;
farWonSign = farWonSign;
farWorm = farWorm;
farWreath = farWreath;
farWrench = farWrench;
farWrenchSimple = farWrenchSimple;
farX = farX;
farXmark = farXmark;
farXmarkCircle = farXmarkCircle;
farXmarkHexagon = farXmarkHexagon;
farXmarkLarge = farXmarkLarge;
farXmarkOctagon = farXmarkOctagon;
farXmarksLines = farXmarksLines;
farXmarkSquare = farXmarkSquare;
farXmarkToSlot = farXmarkToSlot;
farXRay = farXRay;
farY = farY;
farYen = farYen;
farYenSign = farYenSign;
farYinYang = farYinYang;
farZ = farZ;
farZap = farZap;
farZzz = farZzz;
fas0 = fas0;
fas00 = fas00;
fas1 = fas1;
fas100 = fas100;
fas2 = fas2;
fas3 = fas3;
fas360Degrees = fas360Degrees;
fas4 = fas4;
fas5 = fas5;
fas6 = fas6;
fas7 = fas7;
fas8 = fas8;
fas9 = fas9;
fasA = fasA;
fasAbacus = fasAbacus;
fasAccentGrave = fasAccentGrave;
fasAcorn = fasAcorn;
fasAd = fasAd;
fasAdd = fasAdd;
fasAddressBook = fasAddressBook;
fasAddressCard = fasAddressCard;
fasAdjust = fasAdjust;
fasAirConditioner = fasAirConditioner;
fasAirFreshener = fasAirFreshener;
fasAirplay = fasAirplay;
fasAlarmClock = fasAlarmClock;
fasAlarmExclamation = fasAlarmExclamation;
fasAlarmPlus = fasAlarmPlus;
fasAlarmSnooze = fasAlarmSnooze;
fasAlbum = fasAlbum;
fasAlbumCirclePlus = fasAlbumCirclePlus;
fasAlbumCircleUser = fasAlbumCircleUser;
fasAlbumCollection = fasAlbumCollection;
fasAlbumCollectionCirclePlus = fasAlbumCollectionCirclePlus;
fasAlbumCollectionCircleUser = fasAlbumCollectionCircleUser;
fasAlicorn = fasAlicorn;
fasAlien = fasAlien;
fasAlien8bit = fasAlien8bit;
fasAlienMonster = fasAlienMonster;
fasAlignCenter = fasAlignCenter;
fasAlignJustify = fasAlignJustify;
fasAlignLeft = fasAlignLeft;
fasAlignRight = fasAlignRight;
fasAlignSlash = fasAlignSlash;
fasAllergies = fasAllergies;
fasAlt = fasAlt;
fasAmbulance = fasAmbulance;
fasAmericanSignLanguageInterpreting = fasAmericanSignLanguageInterpreting;
fasAmpersand = fasAmpersand;
fasAmpGuitar = fasAmpGuitar;
fasAnalytics = fasAnalytics;
fasAnchor = fasAnchor;
fasAnchorCircleCheck = fasAnchorCircleCheck;
fasAnchorCircleExclamation = fasAnchorCircleExclamation;
fasAnchorCircleXmark = fasAnchorCircleXmark;
fasAnchorLock = fasAnchorLock;
fasAngel = fasAngel;
fasAngle = fasAngle;
fasAngle90 = fasAngle90;
fasAngleDoubleDown = fasAngleDoubleDown;
fasAngleDoubleLeft = fasAngleDoubleLeft;
fasAngleDoubleRight = fasAngleDoubleRight;
fasAngleDoubleUp = fasAngleDoubleUp;
fasAngleDown = fasAngleDown;
fasAngleLeft = fasAngleLeft;
fasAngleRight = fasAngleRight;
fasAnglesDown = fasAnglesDown;
fasAnglesLeft = fasAnglesLeft;
fasAnglesRight = fasAnglesRight;
fasAnglesUp = fasAnglesUp;
fasAngleUp = fasAngleUp;
fasAngry = fasAngry;
fasAnkh = fasAnkh;
fasApartment = fasApartment;
fasAperture = fasAperture;
fasApostrophe = fasApostrophe;
fasAppleAlt = fasAppleAlt;
fasAppleCore = fasAppleCore;
fasAppleCrate = fasAppleCrate;
fasAppleWhole = fasAppleWhole;
fasArchive = fasArchive;
fasArchway = fasArchway;
fasAreaChart = fasAreaChart;
fasArrowAltCircleDown = fasArrowAltCircleDown;
fasArrowAltCircleLeft = fasArrowAltCircleLeft;
fasArrowAltCircleRight = fasArrowAltCircleRight;
fasArrowAltCircleUp = fasArrowAltCircleUp;
fasArrowAltDown = fasArrowAltDown;
fasArrowAltFromBottom = fasArrowAltFromBottom;
fasArrowAltFromLeft = fasArrowAltFromLeft;
fasArrowAltFromRight = fasArrowAltFromRight;
fasArrowAltFromTop = fasArrowAltFromTop;
fasArrowAltLeft = fasArrowAltLeft;
fasArrowAltRight = fasArrowAltRight;
fasArrowAltSquareDown = fasArrowAltSquareDown;
fasArrowAltSquareLeft = fasArrowAltSquareLeft;
fasArrowAltSquareRight = fasArrowAltSquareRight;
fasArrowAltSquareUp = fasArrowAltSquareUp;
fasArrowAltToBottom = fasArrowAltToBottom;
fasArrowAltToLeft = fasArrowAltToLeft;
fasArrowAltToRight = fasArrowAltToRight;
fasArrowAltToTop = fasArrowAltToTop;
fasArrowAltUp = fasArrowAltUp;
fasArrowCircleDown = fasArrowCircleDown;
fasArrowCircleLeft = fasArrowCircleLeft;
fasArrowCircleRight = fasArrowCircleRight;
fasArrowCircleUp = fasArrowCircleUp;
fasArrowDown = fasArrowDown;
fasArrowDown19 = fasArrowDown19;
fasArrowDown91 = fasArrowDown91;
fasArrowDownArrowUp = fasArrowDownArrowUp;
fasArrowDownAZ = fasArrowDownAZ;
fasArrowDownBigSmall = fasArrowDownBigSmall;
fasArrowDownFromDottedLine = fasArrowDownFromDottedLine;
fasArrowDownFromLine = fasArrowDownFromLine;
fasArrowDownLeft = fasArrowDownLeft;
fasArrowDownLeftAndArrowUpRightToCenter = fasArrowDownLeftAndArrowUpRightToCenter;
fasArrowDownLong = fasArrowDownLong;
fasArrowDownRight = fasArrowDownRight;
fasArrowDownShortWide = fasArrowDownShortWide;
fasArrowDownSmallBig = fasArrowDownSmallBig;
fasArrowDownSquareTriangle = fasArrowDownSquareTriangle;
fasArrowDownToArc = fasArrowDownToArc;
fasArrowDownToBracket = fasArrowDownToBracket;
fasArrowDownToDottedLine = fasArrowDownToDottedLine;
fasArrowDownToLine = fasArrowDownToLine;
fasArrowDownToSquare = fasArrowDownToSquare;
fasArrowDownTriangleSquare = fasArrowDownTriangleSquare;
fasArrowDownUpAcrossLine = fasArrowDownUpAcrossLine;
fasArrowDownUpLock = fasArrowDownUpLock;
fasArrowDownWideShort = fasArrowDownWideShort;
fasArrowDownZA = fasArrowDownZA;
fasArrowFromBottom = fasArrowFromBottom;
fasArrowFromLeft = fasArrowFromLeft;
fasArrowFromRight = fasArrowFromRight;
fasArrowFromTop = fasArrowFromTop;
fasArrowLeft = fasArrowLeft;
fasArrowLeftFromLine = fasArrowLeftFromLine;
fasArrowLeftLong = fasArrowLeftLong;
fasArrowLeftLongToLine = fasArrowLeftLongToLine;
fasArrowLeftRotate = fasArrowLeftRotate;
fasArrowLeftToLine = fasArrowLeftToLine;
fasArrowPointer = fasArrowPointer;
fasArrowRight = fasArrowRight;
fasArrowRightArrowLeft = fasArrowRightArrowLeft;
fasArrowRightFromArc = fasArrowRightFromArc;
fasArrowRightFromBracket = fasArrowRightFromBracket;
fasArrowRightFromFile = fasArrowRightFromFile;
fasArrowRightFromLine = fasArrowRightFromLine;
fasArrowRightLong = fasArrowRightLong;
fasArrowRightLongToLine = fasArrowRightLongToLine;
fasArrowRightRotate = fasArrowRightRotate;
fasArrowRightToArc = fasArrowRightToArc;
fasArrowRightToBracket = fasArrowRightToBracket;
fasArrowRightToCity = fasArrowRightToCity;
fasArrowRightToFile = fasArrowRightToFile;
fasArrowRightToLine = fasArrowRightToLine;
fasArrowRotateBack = fasArrowRotateBack;
fasArrowRotateBackward = fasArrowRotateBackward;
fasArrowRotateForward = fasArrowRotateForward;
fasArrowRotateLeft = fasArrowRotateLeft;
fasArrowRotateRight = fasArrowRotateRight;
fasArrows = fasArrows;
fasArrowsAlt = fasArrowsAlt;
fasArrowsAltH = fasArrowsAltH;
fasArrowsAltV = fasArrowsAltV;
fasArrowsCross = fasArrowsCross;
fasArrowsDownToLine = fasArrowsDownToLine;
fasArrowsDownToPeople = fasArrowsDownToPeople;
fasArrowsFromDottedLine = fasArrowsFromDottedLine;
fasArrowsFromLine = fasArrowsFromLine;
fasArrowsH = fasArrowsH;
fasArrowsLeftRight = fasArrowsLeftRight;
fasArrowsLeftRightToLine = fasArrowsLeftRightToLine;
fasArrowsMaximize = fasArrowsMaximize;
fasArrowsMinimize = fasArrowsMinimize;
fasArrowSquareDown = fasArrowSquareDown;
fasArrowSquareLeft = fasArrowSquareLeft;
fasArrowSquareRight = fasArrowSquareRight;
fasArrowSquareUp = fasArrowSquareUp;
fasArrowsRepeat = fasArrowsRepeat;
fasArrowsRepeat1 = fasArrowsRepeat1;
fasArrowsRetweet = fasArrowsRetweet;
fasArrowsRotate = fasArrowsRotate;
fasArrowsSpin = fasArrowsSpin;
fasArrowsSplitUpAndLeft = fasArrowsSplitUpAndLeft;
fasArrowsToCircle = fasArrowsToCircle;
fasArrowsToDot = fasArrowsToDot;
fasArrowsToDottedLine = fasArrowsToDottedLine;
fasArrowsToEye = fasArrowsToEye;
fasArrowsToLine = fasArrowsToLine;
fasArrowsTurnRight = fasArrowsTurnRight;
fasArrowsTurnToDots = fasArrowsTurnToDots;
fasArrowsUpDown = fasArrowsUpDown;
fasArrowsUpDownLeftRight = fasArrowsUpDownLeftRight;
fasArrowsUpToLine = fasArrowsUpToLine;
fasArrowsV = fasArrowsV;
fasArrowToBottom = fasArrowToBottom;
fasArrowToLeft = fasArrowToLeft;
fasArrowToRight = fasArrowToRight;
fasArrowToTop = fasArrowToTop;
fasArrowTrendDown = fasArrowTrendDown;
fasArrowTrendUp = fasArrowTrendUp;
fasArrowTurnDown = fasArrowTurnDown;
fasArrowTurnDownLeft = fasArrowTurnDownLeft;
fasArrowTurnDownRight = fasArrowTurnDownRight;
fasArrowTurnRight = fasArrowTurnRight;
fasArrowTurnUp = fasArrowTurnUp;
fasArrowUp = fasArrowUp;
fasArrowUp19 = fasArrowUp19;
fasArrowUp91 = fasArrowUp91;
fasArrowUpArrowDown = fasArrowUpArrowDown;
fasArrowUpAZ = fasArrowUpAZ;
fasArrowUpBigSmall = fasArrowUpBigSmall;
fasArrowUpFromArc = fasArrowUpFromArc;
fasArrowUpFromBracket = fasArrowUpFromBracket;
fasArrowUpFromDottedLine = fasArrowUpFromDottedLine;
fasArrowUpFromGroundWater = fasArrowUpFromGroundWater;
fasArrowUpFromLine = fasArrowUpFromLine;
fasArrowUpFromSquare = fasArrowUpFromSquare;
fasArrowUpFromWaterPump = fasArrowUpFromWaterPump;
fasArrowUpLeft = fasArrowUpLeft;
fasArrowUpLeftFromCircle = fasArrowUpLeftFromCircle;
fasArrowUpLong = fasArrowUpLong;
fasArrowUpRight = fasArrowUpRight;
fasArrowUpRightAndArrowDownLeftFromCenter = fasArrowUpRightAndArrowDownLeftFromCenter;
fasArrowUpRightDots = fasArrowUpRightDots;
fasArrowUpRightFromSquare = fasArrowUpRightFromSquare;
fasArrowUpShortWide = fasArrowUpShortWide;
fasArrowUpSmallBig = fasArrowUpSmallBig;
fasArrowUpSquareTriangle = fasArrowUpSquareTriangle;
fasArrowUpToDottedLine = fasArrowUpToDottedLine;
fasArrowUpToLine = fasArrowUpToLine;
fasArrowUpTriangleSquare = fasArrowUpTriangleSquare;
fasArrowUpWideShort = fasArrowUpWideShort;
fasArrowUpZA = fasArrowUpZA;
fasAslInterpreting = fasAslInterpreting;
fasAssistiveListeningSystems = fasAssistiveListeningSystems;
fasAsterisk = fasAsterisk;
fasAt = fasAt;
fasAtlas = fasAtlas;
fasAtom = fasAtom;
fasAtomAlt = fasAtomAlt;
fasAtomSimple = fasAtomSimple;
fasAudioDescription = fasAudioDescription;
fasAudioDescriptionSlash = fasAudioDescriptionSlash;
fasAustralSign = fasAustralSign;
fasAutomobile = fasAutomobile;
fasAvocado = fasAvocado;
fasAward = fasAward;
fasAwardSimple = fasAwardSimple;
fasAxe = fasAxe;
fasAxeBattle = fasAxeBattle;
fasB = fasB;
fasBaby = fasBaby;
fasBabyCarriage = fasBabyCarriage;
fasBackpack = fasBackpack;
fasBackspace = fasBackspace;
fasBackward = fasBackward;
fasBackwardFast = fasBackwardFast;
fasBackwardStep = fasBackwardStep;
fasBacon = fasBacon;
fasBacteria = fasBacteria;
fasBacterium = fasBacterium;
fasBadge = fasBadge;
fasBadgeCheck = fasBadgeCheck;
fasBadgeDollar = fasBadgeDollar;
fasBadgePercent = fasBadgePercent;
fasBadgerHoney = fasBadgerHoney;
fasBadgeSheriff = fasBadgeSheriff;
fasBadminton = fasBadminton;
fasBagel = fasBagel;
fasBagShopping = fasBagShopping;
fasBagsShopping = fasBagsShopping;
fasBaguette = fasBaguette;
fasBahai = fasBahai;
fasBahtSign = fasBahtSign;
fasBalanceScale = fasBalanceScale;
fasBalanceScaleLeft = fasBalanceScaleLeft;
fasBalanceScaleRight = fasBalanceScaleRight;
fasBalloon = fasBalloon;
fasBalloons = fasBalloons;
fasBallot = fasBallot;
fasBallotCheck = fasBallotCheck;
fasBallPile = fasBallPile;
fasBan = fasBan;
fasBanana = fasBanana;
fasBanBug = fasBanBug;
fasBandage = fasBandage;
fasBandAid = fasBandAid;
fasBangladeshiTakaSign = fasBangladeshiTakaSign;
fasBanjo = fasBanjo;
fasBank = fasBank;
fasBanParking = fasBanParking;
fasBanSmoking = fasBanSmoking;
fasBarChart = fasBarChart;
fasBarcode = fasBarcode;
fasBarcodeAlt = fasBarcodeAlt;
fasBarcodeRead = fasBarcodeRead;
fasBarcodeScan = fasBarcodeScan;
fasBarnSilo = fasBarnSilo;
fasBars = fasBars;
fasBarsFilter = fasBarsFilter;
fasBarsProgress = fasBarsProgress;
fasBarsSort = fasBarsSort;
fasBarsStaggered = fasBarsStaggered;
fasBaseball = fasBaseball;
fasBaseballBall = fasBaseballBall;
fasBaseballBatBall = fasBaseballBatBall;
fasBasketball = fasBasketball;
fasBasketballBall = fasBasketballBall;
fasBasketballHoop = fasBasketballHoop;
fasBasketShopping = fasBasketShopping;
fasBasketShoppingSimple = fasBasketShoppingSimple;
fasBat = fasBat;
fasBath = fasBath;
fasBathtub = fasBathtub;
fasBattery = fasBattery;
fasBattery0 = fasBattery0;
fasBattery1 = fasBattery1;
fasBattery2 = fasBattery2;
fasBattery3 = fasBattery3;
fasBattery4 = fasBattery4;
fasBattery5 = fasBattery5;
fasBatteryBolt = fasBatteryBolt;
fasBatteryCar = fasBatteryCar;
fasBatteryEmpty = fasBatteryEmpty;
fasBatteryExclamation = fasBatteryExclamation;
fasBatteryFull = fasBatteryFull;
fasBatteryHalf = fasBatteryHalf;
fasBatteryLow = fasBatteryLow;
fasBatteryQuarter = fasBatteryQuarter;
fasBatterySlash = fasBatterySlash;
fasBatteryThreeQuarters = fasBatteryThreeQuarters;
fasBed = fasBed;
fasBedAlt = fasBedAlt;
fasBedBunk = fasBedBunk;
fasBedEmpty = fasBedEmpty;
fasBedFront = fasBedFront;
fasBedPulse = fasBedPulse;
fasBee = fasBee;
fasBeer = fasBeer;
fasBeerFoam = fasBeerFoam;
fasBeerMug = fasBeerMug;
fasBeerMugEmpty = fasBeerMugEmpty;
fasBell = fasBell;
fasBellConcierge = fasBellConcierge;
fasBellExclamation = fasBellExclamation;
fasBellOn = fasBellOn;
fasBellPlus = fasBellPlus;
fasBells = fasBells;
fasBellSchool = fasBellSchool;
fasBellSchoolSlash = fasBellSchoolSlash;
fasBellSlash = fasBellSlash;
fasBenchTree = fasBenchTree;
fasBetamax = fasBetamax;
fasBezierCurve = fasBezierCurve;
fasBible = fasBible;
fasBicycle = fasBicycle;
fasBiking = fasBiking;
fasBikingMountain = fasBikingMountain;
fasBinary = fasBinary;
fasBinaryCircleCheck = fasBinaryCircleCheck;
fasBinaryLock = fasBinaryLock;
fasBinarySlash = fasBinarySlash;
fasBinoculars = fasBinoculars;
fasBiohazard = fasBiohazard;
fasBird = fasBird;
fasBirthdayCake = fasBirthdayCake;
fasBitcoinSign = fasBitcoinSign;
fasBlackboard = fasBlackboard;
fasBlanket = fasBlanket;
fasBlanketFire = fasBlanketFire;
fasBlender = fasBlender;
fasBlenderPhone = fasBlenderPhone;
fasBlind = fasBlind;
fasBlinds = fasBlinds;
fasBlindsOpen = fasBlindsOpen;
fasBlindsRaised = fasBlindsRaised;
fasBlock = fasBlock;
fasBlockBrick = fasBlockBrick;
fasBlockBrickFire = fasBlockBrickFire;
fasBlockQuestion = fasBlockQuestion;
fasBlockQuote = fasBlockQuote;
fasBlog = fasBlog;
fasBlueberries = fasBlueberries;
fasBluetooth = fasBluetooth;
fasBold = fasBold;
fasBolt = fasBolt;
fasBoltAuto = fasBoltAuto;
fasBoltLightning = fasBoltLightning;
fasBoltSlash = fasBoltSlash;
fasBomb = fasBomb;
fasBone = fasBone;
fasBoneBreak = fasBoneBreak;
fasBong = fasBong;
fasBook = fasBook;
fasBookAlt = fasBookAlt;
fasBookArrowRight = fasBookArrowRight;
fasBookArrowUp = fasBookArrowUp;
fasBookAtlas = fasBookAtlas;
fasBookBible = fasBookBible;
fasBookBlank = fasBookBlank;
fasBookBookmark = fasBookBookmark;
fasBookCircle = fasBookCircle;
fasBookCircleArrowRight = fasBookCircleArrowRight;
fasBookCircleArrowUp = fasBookCircleArrowUp;
fasBookCopy = fasBookCopy;
fasBookDead = fasBookDead;
fasBookFont = fasBookFont;
fasBookHeart = fasBookHeart;
fasBookJournalWhills = fasBookJournalWhills;
fasBookLaw = fasBookLaw;
fasBookmark = fasBookmark;
fasBookmarkCircle = fasBookmarkCircle;
fasBookmarkSlash = fasBookmarkSlash;
fasBookMedical = fasBookMedical;
fasBookOpen = fasBookOpen;
fasBookOpenAlt = fasBookOpenAlt;
fasBookOpenCover = fasBookOpenCover;
fasBookOpenReader = fasBookOpenReader;
fasBookQuran = fasBookQuran;
fasBookReader = fasBookReader;
fasBooks = fasBooks;
fasBookSection = fasBookSection;
fasBookSkull = fasBookSkull;
fasBooksMedical = fasBooksMedical;
fasBookSparkles = fasBookSparkles;
fasBookSpells = fasBookSpells;
fasBookTanakh = fasBookTanakh;
fasBookUser = fasBookUser;
fasBoombox = fasBoombox;
fasBoot = fasBoot;
fasBoothCurtain = fasBoothCurtain;
fasBootHeeled = fasBootHeeled;
fasBorderAll = fasBorderAll;
fasBorderBottom = fasBorderBottom;
fasBorderBottomRight = fasBorderBottomRight;
fasBorderCenterH = fasBorderCenterH;
fasBorderCenterV = fasBorderCenterV;
fasBorderInner = fasBorderInner;
fasBorderLeft = fasBorderLeft;
fasBorderNone = fasBorderNone;
fasBorderOuter = fasBorderOuter;
fasBorderRight = fasBorderRight;
fasBorderStyle = fasBorderStyle;
fasBorderStyleAlt = fasBorderStyleAlt;
fasBorderTop = fasBorderTop;
fasBorderTopLeft = fasBorderTopLeft;
fasBoreHole = fasBoreHole;
fasBottleDroplet = fasBottleDroplet;
fasBottleWater = fasBottleWater;
fasBowArrow = fasBowArrow;
fasBowlChopsticks = fasBowlChopsticks;
fasBowlChopsticksNoodles = fasBowlChopsticksNoodles;
fasBowlFood = fasBowlFood;
fasBowlHot = fasBowlHot;
fasBowlingBall = fasBowlingBall;
fasBowlingBallPin = fasBowlingBallPin;
fasBowlingPins = fasBowlingPins;
fasBowlRice = fasBowlRice;
fasBowlSalad = fasBowlSalad;
fasBowlScoop = fasBowlScoop;
fasBowlScoops = fasBowlScoops;
fasBowlShavedIce = fasBowlShavedIce;
fasBowlSoftServe = fasBowlSoftServe;
fasBowlSpoon = fasBowlSpoon;
fasBox = fasBox;
fasBoxAlt = fasBoxAlt;
fasBoxArchive = fasBoxArchive;
fasBoxBallot = fasBoxBallot;
fasBoxCheck = fasBoxCheck;
fasBoxCircleCheck = fasBoxCircleCheck;
fasBoxDollar = fasBoxDollar;
fasBoxes = fasBoxes;
fasBoxesAlt = fasBoxesAlt;
fasBoxesPacking = fasBoxesPacking;
fasBoxesStacked = fasBoxesStacked;
fasBoxFragile = fasBoxFragile;
fasBoxFull = fasBoxFull;
fasBoxHeart = fasBoxHeart;
fasBoxingGlove = fasBoxingGlove;
fasBoxOpen = fasBoxOpen;
fasBoxOpenFull = fasBoxOpenFull;
fasBoxTaped = fasBoxTaped;
fasBoxTissue = fasBoxTissue;
fasBoxUp = fasBoxUp;
fasBoxUsd = fasBoxUsd;
fasBracket = fasBracket;
fasBracketCurly = fasBracketCurly;
fasBracketCurlyLeft = fasBracketCurlyLeft;
fasBracketCurlyRight = fasBracketCurlyRight;
fasBracketLeft = fasBracketLeft;
fasBracketRound = fasBracketRound;
fasBracketRoundRight = fasBracketRoundRight;
fasBrackets = fasBrackets;
fasBracketsCurly = fasBracketsCurly;
fasBracketSquare = fasBracketSquare;
fasBracketSquareRight = fasBracketSquareRight;
fasBracketsRound = fasBracketsRound;
fasBracketsSquare = fasBracketsSquare;
fasBraille = fasBraille;
fasBrain = fasBrain;
fasBrainArrowCurvedRight = fasBrainArrowCurvedRight;
fasBrainCircuit = fasBrainCircuit;
fasBrakeWarning = fasBrakeWarning;
fasBrazilianRealSign = fasBrazilianRealSign;
fasBreadLoaf = fasBreadLoaf;
fasBreadSlice = fasBreadSlice;
fasBreadSliceButter = fasBreadSliceButter;
fasBridge = fasBridge;
fasBridgeCircleCheck = fasBridgeCircleCheck;
fasBridgeCircleExclamation = fasBridgeCircleExclamation;
fasBridgeCircleXmark = fasBridgeCircleXmark;
fasBridgeLock = fasBridgeLock;
fasBridgeSuspension = fasBridgeSuspension;
fasBridgeWater = fasBridgeWater;
fasBriefcase = fasBriefcase;
fasBriefcaseArrowRight = fasBriefcaseArrowRight;
fasBriefcaseBlank = fasBriefcaseBlank;
fasBriefcaseClock = fasBriefcaseClock;
fasBriefcaseMedical = fasBriefcaseMedical;
fasBrightness = fasBrightness;
fasBrightnessLow = fasBrightnessLow;
fasBringForward = fasBringForward;
fasBringFront = fasBringFront;
fasBroadcastTower = fasBroadcastTower;
fasBroccoli = fasBroccoli;
fasBroom = fasBroom;
fasBroomBall = fasBroomBall;
fasBrowser = fasBrowser;
fasBrowsers = fasBrowsers;
fasBrush = fasBrush;
fasBucket = fasBucket;
fasBug = fasBug;
fasBugs = fasBugs;
fasBugSlash = fasBugSlash;
fasBuilding = fasBuilding;
fasBuildingCircleArrowRight = fasBuildingCircleArrowRight;
fasBuildingCircleCheck = fasBuildingCircleCheck;
fasBuildingCircleExclamation = fasBuildingCircleExclamation;
fasBuildingCircleXmark = fasBuildingCircleXmark;
fasBuildingColumns = fasBuildingColumns;
fasBuildingFlag = fasBuildingFlag;
fasBuildingLock = fasBuildingLock;
fasBuildingNgo = fasBuildingNgo;
fasBuildings = fasBuildings;
fasBuildingShield = fasBuildingShield;
fasBuildingUn = fasBuildingUn;
fasBuildingUser = fasBuildingUser;
fasBuildingWheat = fasBuildingWheat;
fasBullhorn = fasBullhorn;
fasBullseye = fasBullseye;
fasBullseyeArrow = fasBullseyeArrow;
fasBullseyePointer = fasBullseyePointer;
fasBurger = fasBurger;
fasBurgerCheese = fasBurgerCheese;
fasBurgerFries = fasBurgerFries;
fasBurgerGlass = fasBurgerGlass;
fasBurgerLettuce = fasBurgerLettuce;
fasBurgerSoda = fasBurgerSoda;
fasBurn = fasBurn;
fasBurrito = fasBurrito;
fasBurst = fasBurst;
fasBus = fasBus;
fasBusAlt = fasBusAlt;
fasBusinessFront = fasBusinessFront;
fasBusinessTime = fasBusinessTime;
fasBusSchool = fasBusSchool;
fasBusSimple = fasBusSimple;
fasButter = fasButter;
fasC = fasC;
fasCab = fasCab;
fasCabin = fasCabin;
fasCabinetFiling = fasCabinetFiling;
fasCableCar = fasCableCar;
fasCactus = fasCactus;
fasCake = fasCake;
fasCakeCandles = fasCakeCandles;
fasCakeSlice = fasCakeSlice;
fasCalculator = fasCalculator;
fasCalculatorAlt = fasCalculatorAlt;
fasCalculatorSimple = fasCalculatorSimple;
fasCalendar = fasCalendar;
fasCalendarAlt = fasCalendarAlt;
fasCalendarArrowDown = fasCalendarArrowDown;
fasCalendarArrowUp = fasCalendarArrowUp;
fasCalendarCheck = fasCalendarCheck;
fasCalendarCircle = fasCalendarCircle;
fasCalendarCircleExclamation = fasCalendarCircleExclamation;
fasCalendarCircleMinus = fasCalendarCircleMinus;
fasCalendarCirclePlus = fasCalendarCirclePlus;
fasCalendarCircleUser = fasCalendarCircleUser;
fasCalendarClock = fasCalendarClock;
fasCalendarDay = fasCalendarDay;
fasCalendarDays = fasCalendarDays;
fasCalendarDownload = fasCalendarDownload;
fasCalendarEdit = fasCalendarEdit;
fasCalendarExclamation = fasCalendarExclamation;
fasCalendarHeart = fasCalendarHeart;
fasCalendarImage = fasCalendarImage;
fasCalendarLines = fasCalendarLines;
fasCalendarLinesPen = fasCalendarLinesPen;
fasCalendarMinus = fasCalendarMinus;
fasCalendarNote = fasCalendarNote;
fasCalendarPen = fasCalendarPen;
fasCalendarPlus = fasCalendarPlus;
fasCalendarRange = fasCalendarRange;
fasCalendars = fasCalendars;
fasCalendarStar = fasCalendarStar;
fasCalendarTime = fasCalendarTime;
fasCalendarTimes = fasCalendarTimes;
fasCalendarUpload = fasCalendarUpload;
fasCalendarWeek = fasCalendarWeek;
fasCalendarXmark = fasCalendarXmark;
fasCamcorder = fasCamcorder;
fasCamera = fasCamera;
fasCameraAlt = fasCameraAlt;
fasCameraCctv = fasCameraCctv;
fasCameraCircle = fasCameraCircle;
fasCameraHome = fasCameraHome;
fasCameraMovie = fasCameraMovie;
fasCameraPolaroid = fasCameraPolaroid;
fasCameraRetro = fasCameraRetro;
fasCameraRotate = fasCameraRotate;
fasCameraSecurity = fasCameraSecurity;
fasCameraSlash = fasCameraSlash;
fasCameraViewfinder = fasCameraViewfinder;
fasCameraWeb = fasCameraWeb;
fasCameraWebSlash = fasCameraWebSlash;
fasCampfire = fasCampfire;
fasCampground = fasCampground;
fasCancel = fasCancel;
fasCandleHolder = fasCandleHolder;
fasCandy = fasCandy;
fasCandyBar = fasCandyBar;
fasCandyCane = fasCandyCane;
fasCandyCorn = fasCandyCorn;
fasCanFood = fasCanFood;
fasCannabis = fasCannabis;
fasCapsules = fasCapsules;
fasCar = fasCar;
fasCarAlt = fasCarAlt;
fasCaravan = fasCaravan;
fasCaravanAlt = fasCaravanAlt;
fasCaravanSimple = fasCaravanSimple;
fasCarBattery = fasCarBattery;
fasCarBolt = fasCarBolt;
fasCarBuilding = fasCarBuilding;
fasCarBump = fasCarBump;
fasCarBurst = fasCarBurst;
fasCarBus = fasCarBus;
fasCarCircleBolt = fasCarCircleBolt;
fasCarCrash = fasCarCrash;
fasCardClub = fasCardClub;
fasCardDiamond = fasCardDiamond;
fasCardHeart = fasCardHeart;
fasCards = fasCards;
fasCardsBlank = fasCardsBlank;
fasCardSpade = fasCardSpade;
fasCaretCircleDown = fasCaretCircleDown;
fasCaretCircleLeft = fasCaretCircleLeft;
fasCaretCircleRight = fasCaretCircleRight;
fasCaretCircleUp = fasCaretCircleUp;
fasCaretDown = fasCaretDown;
fasCaretLeft = fasCaretLeft;
fasCaretRight = fasCaretRight;
fasCaretSquareDown = fasCaretSquareDown;
fasCaretSquareLeft = fasCaretSquareLeft;
fasCaretSquareRight = fasCaretSquareRight;
fasCaretSquareUp = fasCaretSquareUp;
fasCaretUp = fasCaretUp;
fasCarGarage = fasCarGarage;
fasCarMechanic = fasCarMechanic;
fasCarMirrors = fasCarMirrors;
fasCarOn = fasCarOn;
fasCarRear = fasCarRear;
fasCarriageBaby = fasCarriageBaby;
fasCarrot = fasCarrot;
fasCars = fasCars;
fasCarSide = fasCarSide;
fasCarSideBolt = fasCarSideBolt;
fasCartArrowDown = fasCartArrowDown;
fasCartArrowUp = fasCartArrowUp;
fasCartCircleArrowDown = fasCartCircleArrowDown;
fasCartCircleArrowUp = fasCartCircleArrowUp;
fasCartCircleCheck = fasCartCircleCheck;
fasCartCircleExclamation = fasCartCircleExclamation;
fasCartCirclePlus = fasCartCirclePlus;
fasCartCircleXmark = fasCartCircleXmark;
fasCartFlatbed = fasCartFlatbed;
fasCartFlatbedBoxes = fasCartFlatbedBoxes;
fasCartFlatbedEmpty = fasCartFlatbedEmpty;
fasCartFlatbedSuitcase = fasCartFlatbedSuitcase;
fasCarTilt = fasCarTilt;
fasCartMinus = fasCartMinus;
fasCartPlus = fasCartPlus;
fasCartShopping = fasCartShopping;
fasCartShoppingFast = fasCartShoppingFast;
fasCarTunnel = fasCarTunnel;
fasCartXmark = fasCartXmark;
fasCarWash = fasCarWash;
fasCarWrench = fasCarWrench;
fasCashRegister = fasCashRegister;
fasCassetteBetamax = fasCassetteBetamax;
fasCassetteTape = fasCassetteTape;
fasCassetteVhs = fasCassetteVhs;
fasCastle = fasCastle;
fasCat = fasCat;
fasCatSpace = fasCatSpace;
fasCauldron = fasCauldron;
fasCctv = fasCctv;
fasCediSign = fasCediSign;
fasCentSign = fasCentSign;
fasCertificate = fasCertificate;
fasChain = fasChain;
fasChainBroken = fasChainBroken;
fasChainHorizontal = fasChainHorizontal;
fasChainHorizontalSlash = fasChainHorizontalSlash;
fasChainSlash = fasChainSlash;
fasChair = fasChair;
fasChairOffice = fasChairOffice;
fasChalkboard = fasChalkboard;
fasChalkboardTeacher = fasChalkboardTeacher;
fasChalkboardUser = fasChalkboardUser;
fasChampagneGlass = fasChampagneGlass;
fasChampagneGlasses = fasChampagneGlasses;
fasChargingStation = fasChargingStation;
fasChartArea = fasChartArea;
fasChartBar = fasChartBar;
fasChartBullet = fasChartBullet;
fasChartCandlestick = fasChartCandlestick;
fasChartColumn = fasChartColumn;
fasChartGantt = fasChartGantt;
fasChartLine = fasChartLine;
fasChartLineDown = fasChartLineDown;
fasChartLineUp = fasChartLineUp;
fasChartMixed = fasChartMixed;
fasChartNetwork = fasChartNetwork;
fasChartPie = fasChartPie;
fasChartPieAlt = fasChartPieAlt;
fasChartPieSimple = fasChartPieSimple;
fasChartPyramid = fasChartPyramid;
fasChartRadar = fasChartRadar;
fasChartScatter = fasChartScatter;
fasChartScatter3d = fasChartScatter3d;
fasChartScatterBubble = fasChartScatterBubble;
fasChartSimple = fasChartSimple;
fasChartSimpleHorizontal = fasChartSimpleHorizontal;
fasChartTreeMap = fasChartTreeMap;
fasChartUser = fasChartUser;
fasChartWaterfasll = fasChartWaterfasll;
fasCheck = fasCheck;
fasCheckCircle = fasCheckCircle;
fasCheckDouble = fasCheckDouble;
fasCheckSquare = fasCheckSquare;
fasCheckToSlot = fasCheckToSlot;
fasCheese = fasCheese;
fasCheeseburger = fasCheeseburger;
fasCheeseSwiss = fasCheeseSwiss;
fasCherries = fasCherries;
fasChess = fasChess;
fasChessBishop = fasChessBishop;
fasChessBishopAlt = fasChessBishopAlt;
fasChessBishopPiece = fasChessBishopPiece;
fasChessBoard = fasChessBoard;
fasChessClock = fasChessClock;
fasChessClockAlt = fasChessClockAlt;
fasChessClockFlip = fasChessClockFlip;
fasChessKing = fasChessKing;
fasChessKingAlt = fasChessKingAlt;
fasChessKingPiece = fasChessKingPiece;
fasChessKnight = fasChessKnight;
fasChessKnightAlt = fasChessKnightAlt;
fasChessKnightPiece = fasChessKnightPiece;
fasChessPawn = fasChessPawn;
fasChessPawnAlt = fasChessPawnAlt;
fasChessPawnPiece = fasChessPawnPiece;
fasChessQueen = fasChessQueen;
fasChessQueenAlt = fasChessQueenAlt;
fasChessQueenPiece = fasChessQueenPiece;
fasChessRook = fasChessRook;
fasChessRookAlt = fasChessRookAlt;
fasChessRookPiece = fasChessRookPiece;
fasChestnut = fasChestnut;
fasChevronCircleDown = fasChevronCircleDown;
fasChevronCircleLeft = fasChevronCircleLeft;
fasChevronCircleRight = fasChevronCircleRight;
fasChevronCircleUp = fasChevronCircleUp;
fasChevronDoubleDown = fasChevronDoubleDown;
fasChevronDoubleLeft = fasChevronDoubleLeft;
fasChevronDoubleRight = fasChevronDoubleRight;
fasChevronDoubleUp = fasChevronDoubleUp;
fasChevronDown = fasChevronDown;
fasChevronLeft = fasChevronLeft;
fasChevronRight = fasChevronRight;
fasChevronsDown = fasChevronsDown;
fasChevronsLeft = fasChevronsLeft;
fasChevronSquareDown = fasChevronSquareDown;
fasChevronSquareLeft = fasChevronSquareLeft;
fasChevronSquareRight = fasChevronSquareRight;
fasChevronSquareUp = fasChevronSquareUp;
fasChevronsRight = fasChevronsRight;
fasChevronsUp = fasChevronsUp;
fasChevronUp = fasChevronUp;
fasChild = fasChild;
fasChildren = fasChildren;
fasChildRifle = fasChildRifle;
fasChimney = fasChimney;
fasChocolateBar = fasChocolateBar;
fasChopsticks = fasChopsticks;
fasChurch = fasChurch;
fasCircle = fasCircle;
fasCircle0 = fasCircle0;
fasCircle1 = fasCircle1;
fasCircle2 = fasCircle2;
fasCircle3 = fasCircle3;
fasCircle4 = fasCircle4;
fasCircle5 = fasCircle5;
fasCircle6 = fasCircle6;
fasCircle7 = fasCircle7;
fasCircle8 = fasCircle8;
fasCircle9 = fasCircle9;
fasCircleA = fasCircleA;
fasCircleAmpersand = fasCircleAmpersand;
fasCircleArrowDown = fasCircleArrowDown;
fasCircleArrowDownLeft = fasCircleArrowDownLeft;
fasCircleArrowDownRight = fasCircleArrowDownRight;
fasCircleArrowLeft = fasCircleArrowLeft;
fasCircleArrowRight = fasCircleArrowRight;
fasCircleArrowUp = fasCircleArrowUp;
fasCircleArrowUpLeft = fasCircleArrowUpLeft;
fasCircleArrowUpRight = fasCircleArrowUpRight;
fasCircleB = fasCircleB;
fasCircleBolt = fasCircleBolt;
fasCircleBookmark = fasCircleBookmark;
fasCircleBookOpen = fasCircleBookOpen;
fasCircleC = fasCircleC;
fasCircleCalendar = fasCircleCalendar;
fasCircleCamera = fasCircleCamera;
fasCircleCaretDown = fasCircleCaretDown;
fasCircleCaretLeft = fasCircleCaretLeft;
fasCircleCaretRight = fasCircleCaretRight;
fasCircleCaretUp = fasCircleCaretUp;
fasCircleCheck = fasCircleCheck;
fasCircleChevronDown = fasCircleChevronDown;
fasCircleChevronLeft = fasCircleChevronLeft;
fasCircleChevronRight = fasCircleChevronRight;
fasCircleChevronUp = fasCircleChevronUp;
fasCircleD = fasCircleD;
fasCircleDashed = fasCircleDashed;
fasCircleDivide = fasCircleDivide;
fasCircleDollar = fasCircleDollar;
fasCircleDollarToSlot = fasCircleDollarToSlot;
fasCircleDot = fasCircleDot;
fasCircleDown = fasCircleDown;
fasCircleDownLeft = fasCircleDownLeft;
fasCircleDownRight = fasCircleDownRight;
fasCircleE = fasCircleE;
fasCircleEllipsis = fasCircleEllipsis;
fasCircleEllipsisVertical = fasCircleEllipsisVertical;
fasCircleEnvelope = fasCircleEnvelope;
fasCircleExclamation = fasCircleExclamation;
fasCircleExclamationCheck = fasCircleExclamationCheck;
fasCircleF = fasCircleF;
fasCircleG = fasCircleG;
fasCircleH = fasCircleH;
fasCircleHalf = fasCircleHalf;
fasCircleHalfStroke = fasCircleHalfStroke;
fasCircleHeart = fasCircleHeart;
fasCircleI = fasCircleI;
fasCircleInfo = fasCircleInfo;
fasCircleJ = fasCircleJ;
fasCircleK = fasCircleK;
fasCircleL = fasCircleL;
fasCircleLeft = fasCircleLeft;
fasCircleLocationArrow = fasCircleLocationArrow;
fasCircleM = fasCircleM;
fasCircleMicrophone = fasCircleMicrophone;
fasCircleMicrophoneLines = fasCircleMicrophoneLines;
fasCircleMinus = fasCircleMinus;
fasCircleN = fasCircleN;
fasCircleNodes = fasCircleNodes;
fasCircleNotch = fasCircleNotch;
fasCircleO = fasCircleO;
fasCircleP = fasCircleP;
fasCircleParking = fasCircleParking;
fasCirclePause = fasCirclePause;
fasCirclePhone = fasCirclePhone;
fasCirclePhoneFlip = fasCirclePhoneFlip;
fasCirclePhoneHangup = fasCirclePhoneHangup;
fasCirclePlay = fasCirclePlay;
fasCirclePlus = fasCirclePlus;
fasCircleQ = fasCircleQ;
fasCircleQuarter = fasCircleQuarter;
fasCircleQuarters = fasCircleQuarters;
fasCircleQuestion = fasCircleQuestion;
fasCircleR = fasCircleR;
fasCircleRadiation = fasCircleRadiation;
fasCircleRight = fasCircleRight;
fasCircleS = fasCircleS;
fasCircleSmall = fasCircleSmall;
fasCircleSort = fasCircleSort;
fasCircleSortDown = fasCircleSortDown;
fasCircleSortUp = fasCircleSortUp;
fasCircleStar = fasCircleStar;
fasCircleStop = fasCircleStop;
fasCircleT = fasCircleT;
fasCircleThreeQuarters = fasCircleThreeQuarters;
fasCircleTrash = fasCircleTrash;
fasCircleU = fasCircleU;
fasCircleUp = fasCircleUp;
fasCircleUpLeft = fasCircleUpLeft;
fasCircleUpRight = fasCircleUpRight;
fasCircleUser = fasCircleUser;
fasCircleV = fasCircleV;
fasCircleVideo = fasCircleVideo;
fasCircleW = fasCircleW;
fasCircleWaveformLines = fasCircleWaveformLines;
fasCircleX = fasCircleX;
fasCircleXmark = fasCircleXmark;
fasCircleY = fasCircleY;
fasCircleZ = fasCircleZ;
fasCitrus = fasCitrus;
fasCitrusSlice = fasCitrusSlice;
fasCity = fasCity;
fasClapperboard = fasClapperboard;
fasClapperboardPlay = fasClapperboardPlay;
fasClarinet = fasClarinet;
fasClawMarks = fasClawMarks;
fasClinicMedical = fasClinicMedical;
fasClipboard = fasClipboard;
fasClipboardCheck = fasClipboardCheck;
fasClipboardList = fasClipboardList;
fasClipboardListCheck = fasClipboardListCheck;
fasClipboardMedical = fasClipboardMedical;
fasClipboardPrescription = fasClipboardPrescription;
fasClipboardQuestion = fasClipboardQuestion;
fasClipboardUser = fasClipboardUser;
fasClock = fasClock;
fasClockDesk = fasClockDesk;
fasClockEight = fasClockEight;
fasClockEightThirty = fasClockEightThirty;
fasClockEleven = fasClockEleven;
fasClockElevenThirty = fasClockElevenThirty;
fasClockFive = fasClockFive;
fasClockFiveThirty = fasClockFiveThirty;
fasClockFour = fasClockFour;
fasClockFourThirty = fasClockFourThirty;
fasClockNine = fasClockNine;
fasClockNineThirty = fasClockNineThirty;
fasClockOne = fasClockOne;
fasClockOneThirty = fasClockOneThirty;
fasClockRotateLeft = fasClockRotateLeft;
fasClockSeven = fasClockSeven;
fasClockSevenThirty = fasClockSevenThirty;
fasClockSix = fasClockSix;
fasClockSixThirty = fasClockSixThirty;
fasClockTen = fasClockTen;
fasClockTenThirty = fasClockTenThirty;
fasClockThree = fasClockThree;
fasClockThreeThirty = fasClockThreeThirty;
fasClockTwelve = fasClockTwelve;
fasClockTwelveThirty = fasClockTwelveThirty;
fasClockTwo = fasClockTwo;
fasClockTwoThirty = fasClockTwoThirty;
fasClone = fasClone;
fasClose = fasClose;
fasClosedCaptioning = fasClosedCaptioning;
fasClosedCaptioningSlash = fasClosedCaptioningSlash;
fasClothesHanger = fasClothesHanger;
fasCloud = fasCloud;
fasCloudArrowDown = fasCloudArrowDown;
fasCloudArrowUp = fasCloudArrowUp;
fasCloudBolt = fasCloudBolt;
fasCloudBoltMoon = fasCloudBoltMoon;
fasCloudBoltSun = fasCloudBoltSun;
fasCloudCheck = fasCloudCheck;
fasCloudDownload = fasCloudDownload;
fasCloudDownloadAlt = fasCloudDownloadAlt;
fasCloudDrizzle = fasCloudDrizzle;
fasCloudExclamation = fasCloudExclamation;
fasCloudFog = fasCloudFog;
fasCloudHail = fasCloudHail;
fasCloudHailMixed = fasCloudHailMixed;
fasCloudMeatball = fasCloudMeatball;
fasCloudMinus = fasCloudMinus;
fasCloudMoon = fasCloudMoon;
fasCloudMoonRain = fasCloudMoonRain;
fasCloudMusic = fasCloudMusic;
fasCloudPlus = fasCloudPlus;
fasCloudQuestion = fasCloudQuestion;
fasCloudRain = fasCloudRain;
fasCloudRainbow = fasCloudRainbow;
fasClouds = fasClouds;
fasCloudShowers = fasCloudShowers;
fasCloudShowersHeavy = fasCloudShowersHeavy;
fasCloudShowersWater = fasCloudShowersWater;
fasCloudSlash = fasCloudSlash;
fasCloudSleet = fasCloudSleet;
fasCloudsMoon = fasCloudsMoon;
fasCloudSnow = fasCloudSnow;
fasCloudsSun = fasCloudsSun;
fasCloudSun = fasCloudSun;
fasCloudSunRain = fasCloudSunRain;
fasCloudUpload = fasCloudUpload;
fasCloudUploadAlt = fasCloudUploadAlt;
fasCloudWord = fasCloudWord;
fasCloudXmark = fasCloudXmark;
fasClover = fasClover;
fasClub = fasClub;
fasCny = fasCny;
fasCocktail = fasCocktail;
fasCoconut = fasCoconut;
fasCode = fasCode;
fasCodeBranch = fasCodeBranch;
fasCodeCommit = fasCodeCommit;
fasCodeCompare = fasCodeCompare;
fasCodeFork = fasCodeFork;
fasCodeMerge = fasCodeMerge;
fasCodePullRequest = fasCodePullRequest;
fasCodePullRequestClosed = fasCodePullRequestClosed;
fasCodePullRequestDraft = fasCodePullRequestDraft;
fasCodeSimple = fasCodeSimple;
fasCoffee = fasCoffee;
fasCoffeeBean = fasCoffeeBean;
fasCoffeeBeans = fasCoffeeBeans;
fasCoffeePot = fasCoffeePot;
fasCoffeeTogo = fasCoffeeTogo;
fasCoffin = fasCoffin;
fasCoffinCross = fasCoffinCross;
fasCog = fasCog;
fasCogs = fasCogs;
fasCoin = fasCoin;
fasCoinBlank = fasCoinBlank;
fasCoinFront = fasCoinFront;
fasCoins = fasCoins;
fasCoinVertical = fasCoinVertical;
fasColon = fasColon;
fasColonSign = fasColonSign;
fasColumns = fasColumns;
fasColumns3 = fasColumns3;
fasComet = fasComet;
fasComma = fasComma;
fasCommand = fasCommand;
fasComment = fasComment;
fasCommentAlt = fasCommentAlt;
fasCommentAltArrowDown = fasCommentAltArrowDown;
fasCommentAltArrowUp = fasCommentAltArrowUp;
fasCommentAltCaptions = fasCommentAltCaptions;
fasCommentAltCheck = fasCommentAltCheck;
fasCommentAltDollar = fasCommentAltDollar;
fasCommentAltDots = fasCommentAltDots;
fasCommentAltEdit = fasCommentAltEdit;
fasCommentAltExclamation = fasCommentAltExclamation;
fasCommentAltImage = fasCommentAltImage;
fasCommentAltLines = fasCommentAltLines;
fasCommentAltMedical = fasCommentAltMedical;
fasCommentAltMinus = fasCommentAltMinus;
fasCommentAltMusic = fasCommentAltMusic;
fasCommentAltPlus = fasCommentAltPlus;
fasCommentAltQuote = fasCommentAltQuote;
fasCommentAltSlash = fasCommentAltSlash;
fasCommentAltSmile = fasCommentAltSmile;
fasCommentAltText = fasCommentAltText;
fasCommentAltTimes = fasCommentAltTimes;
fasCommentArrowDown = fasCommentArrowDown;
fasCommentArrowUp = fasCommentArrowUp;
fasCommentArrowUpRight = fasCommentArrowUpRight;
fasCommentCaptions = fasCommentCaptions;
fasCommentCheck = fasCommentCheck;
fasCommentCode = fasCommentCode;
fasCommentDollar = fasCommentDollar;
fasCommentDots = fasCommentDots;
fasCommentEdit = fasCommentEdit;
fasCommentExclamation = fasCommentExclamation;
fasCommentImage = fasCommentImage;
fasCommenting = fasCommenting;
fasCommentLines = fasCommentLines;
fasCommentMedical = fasCommentMedical;
fasCommentMiddle = fasCommentMiddle;
fasCommentMiddleAlt = fasCommentMiddleAlt;
fasCommentMiddleTop = fasCommentMiddleTop;
fasCommentMiddleTopAlt = fasCommentMiddleTopAlt;
fasCommentMinus = fasCommentMinus;
fasCommentMusic = fasCommentMusic;
fasCommentPen = fasCommentPen;
fasCommentPlus = fasCommentPlus;
fasCommentQuestion = fasCommentQuestion;
fasCommentQuote = fasCommentQuote;
fasComments = fasComments;
fasCommentsAlt = fasCommentsAlt;
fasCommentsAltDollar = fasCommentsAltDollar;
fasCommentsDollar = fasCommentsDollar;
fasCommentSlash = fasCommentSlash;
fasCommentSmile = fasCommentSmile;
fasCommentSms = fasCommentSms;
fasCommentsQuestion = fasCommentsQuestion;
fasCommentsQuestionCheck = fasCommentsQuestionCheck;
fasCommentText = fasCommentText;
fasCommentTimes = fasCommentTimes;
fasCommentXmark = fasCommentXmark;
fasCompactDisc = fasCompactDisc;
fasCompass = fasCompass;
fasCompassDrafting = fasCompassDrafting;
fasCompassSlash = fasCompassSlash;
fasCompress = fasCompress;
fasCompressAlt = fasCompressAlt;
fasCompressArrows = fasCompressArrows;
fasCompressArrowsAlt = fasCompressArrowsAlt;
fasCompressWide = fasCompressWide;
fasComputer = fasComputer;
fasComputerClassic = fasComputerClassic;
fasComputerMouse = fasComputerMouse;
fasComputerMouseScrollwheel = fasComputerMouseScrollwheel;
fasComputerSpeaker = fasComputerSpeaker;
fasConciergeBell = fasConciergeBell;
fasConstruction = fasConstruction;
fasContactBook = fasContactBook;
fasContactCard = fasContactCard;
fasContainerStorage = fasContainerStorage;
fasConveyorBelt = fasConveyorBelt;
fasConveyorBeltAlt = fasConveyorBeltAlt;
fasConveyorBeltBoxes = fasConveyorBeltBoxes;
fasConveyorBeltEmpty = fasConveyorBeltEmpty;
fasCookie = fasCookie;
fasCookieBite = fasCookieBite;
fasCopy = fasCopy;
fasCopyright = fasCopyright;
fasCorn = fasCorn;
fasCorner = fasCorner;
fasCouch = fasCouch;
fasCouchSmall = fasCouchSmall;
fasCow = fasCow;
fasCowbell = fasCowbell;
fasCowbellCirclePlus = fasCowbellCirclePlus;
fasCowbellMore = fasCowbellMore;
fasCrab = fasCrab;
fasCrateApple = fasCrateApple;
fasCrateEmpty = fasCrateEmpty;
fasCreditCard = fasCreditCard;
fasCreditCardAlt = fasCreditCardAlt;
fasCreditCardBlank = fasCreditCardBlank;
fasCreditCardFront = fasCreditCardFront;
fasCreemee = fasCreemee;
fasCricket = fasCricket;
fasCricketBatBall = fasCricketBatBall;
fasCroissant = fasCroissant;
fasCrop = fasCrop;
fasCropAlt = fasCropAlt;
fasCropSimple = fasCropSimple;
fasCross = fasCross;
fasCrosshairs = fasCrosshairs;
fasCrow = fasCrow;
fasCrown = fasCrown;
fasCrutch = fasCrutch;
fasCrutches = fasCrutches;
fasCruzeiroSign = fasCruzeiroSign;
fasCrystalBall = fasCrystalBall;
fasCube = fasCube;
fasCubes = fasCubes;
fasCubesStacked = fasCubesStacked;
fasCucumber = fasCucumber;
fasCupcake = fasCupcake;
fasCupStraw = fasCupStraw;
fasCupStrawSwoosh = fasCupStrawSwoosh;
fasCupTogo = fasCupTogo;
fasCurling = fasCurling;
fasCurlingStone = fasCurlingStone;
fasCustard = fasCustard;
fasCut = fasCut;
fasCutlery = fasCutlery;
fasD = fasD;
fasDagger = fasDagger;
fasDash = fasDash;
fasDashboard = fasDashboard;
fasDatabase = fasDatabase;
fasDeaf = fasDeaf;
fasDeafness = fasDeafness;
fasDebug = fasDebug;
fasDedent = fasDedent;
fasDeer = fasDeer;
fasDeerRudolph = fasDeerRudolph;
fasDeleteLeft = fasDeleteLeft;
fasDeleteRight = fasDeleteRight;
fasDemocrat = fasDemocrat;
fasDesktop = fasDesktop;
fasDesktopAlt = fasDesktopAlt;
fasDesktopArrowDown = fasDesktopArrowDown;
fasDesktopCode = fasDesktopCode;
fasDesktopMedical = fasDesktopMedical;
fasDesktopSlash = fasDesktopSlash;
fasDewpoint = fasDewpoint;
fasDharmachakra = fasDharmachakra;
fasDiagnoses = fasDiagnoses;
fasDiagramCells = fasDiagramCells;
fasDiagramLeanCanvas = fasDiagramLeanCanvas;
fasDiagramNested = fasDiagramNested;
fasDiagramNext = fasDiagramNext;
fasDiagramPredecessor = fasDiagramPredecessor;
fasDiagramPrevious = fasDiagramPrevious;
fasDiagramProject = fasDiagramProject;
fasDiagramSankey = fasDiagramSankey;
fasDiagramSubtask = fasDiagramSubtask;
fasDiagramSuccessor = fasDiagramSuccessor;
fasDiagramVenn = fasDiagramVenn;
fasDial = fasDial;
fasDialHigh = fasDialHigh;
fasDialLow = fasDialLow;
fasDialMax = fasDialMax;
fasDialMed = fasDialMed;
fasDialMedHigh = fasDialMedHigh;
fasDialMedLow = fasDialMedLow;
fasDialMin = fasDialMin;
fasDialOff = fasDialOff;
fasDiamond = fasDiamond;
fasDiamondExclamation = fasDiamondExclamation;
fasDiamondTurnRight = fasDiamondTurnRight;
fasDice = fasDice;
fasDiceD10 = fasDiceD10;
fasDiceD12 = fasDiceD12;
fasDiceD20 = fasDiceD20;
fasDiceD4 = fasDiceD4;
fasDiceD6 = fasDiceD6;
fasDiceD8 = fasDiceD8;
fasDiceFive = fasDiceFive;
fasDiceFour = fasDiceFour;
fasDiceOne = fasDiceOne;
fasDiceSix = fasDiceSix;
fasDiceThree = fasDiceThree;
fasDiceTwo = fasDiceTwo;
fasDigging = fasDigging;
fasDigitalTachograph = fasDigitalTachograph;
fasDiploma = fasDiploma;
fasDirections = fasDirections;
fasDiscDrive = fasDiscDrive;
fasDisease = fasDisease;
fasDisplay = fasDisplay;
fasDisplayArrowDown = fasDisplayArrowDown;
fasDisplayCode = fasDisplayCode;
fasDisplayMedical = fasDisplayMedical;
fasDisplaySlash = fasDisplaySlash;
fasDistributeSpacingHorizontal = fasDistributeSpacingHorizontal;
fasDistributeSpacingVertical = fasDistributeSpacingVertical;
fasDitto = fasDitto;
fasDivide = fasDivide;
fasDizzy = fasDizzy;
fasDna = fasDna;
fasDog = fasDog;
fasDogLeashed = fasDogLeashed;
fasDollar = fasDollar;
fasDollarCircle = fasDollarCircle;
fasDollarSign = fasDollarSign;
fasDollarSquare = fasDollarSquare;
fasDolly = fasDolly;
fasDollyBox = fasDollyBox;
fasDollyEmpty = fasDollyEmpty;
fasDollyFlatbed = fasDollyFlatbed;
fasDollyFlatbedAlt = fasDollyFlatbedAlt;
fasDollyFlatbedEmpty = fasDollyFlatbedEmpty;
fasDolphin = fasDolphin;
fasDonate = fasDonate;
fasDongSign = fasDongSign;
fasDoNotEnter = fasDoNotEnter;
fasDonut = fasDonut;
fasDoorClosed = fasDoorClosed;
fasDoorOpen = fasDoorOpen;
fasDotCircle = fasDotCircle;
fasDoughnut = fasDoughnut;
fasDove = fasDove;
fasDown = fasDown;
fasDownFromDottedLine = fasDownFromDottedLine;
fasDownFromLine = fasDownFromLine;
fasDownLeft = fasDownLeft;
fasDownLeftAndUpRightToCenter = fasDownLeftAndUpRightToCenter;
fasDownload = fasDownload;
fasDownLong = fasDownLong;
fasDownRight = fasDownRight;
fasDownToBracket = fasDownToBracket;
fasDownToDottedLine = fasDownToDottedLine;
fasDownToLine = fasDownToLine;
fasDraftingCompass = fasDraftingCompass;
fasDragon = fasDragon;
fasDrawCircle = fasDrawCircle;
fasDrawPolygon = fasDrawPolygon;
fasDrawSquare = fasDrawSquare;
fasDreidel = fasDreidel;
fasDriversLicense = fasDriversLicense;
fasDrone = fasDrone;
fasDroneAlt = fasDroneAlt;
fasDroneFront = fasDroneFront;
fasDroplet = fasDroplet;
fasDropletDegree = fasDropletDegree;
fasDropletPercent = fasDropletPercent;
fasDropletSlash = fasDropletSlash;
fasDrum = fasDrum;
fasDrumSteelpan = fasDrumSteelpan;
fasDrumstick = fasDrumstick;
fasDrumstickBite = fasDrumstickBite;
fasDryer = fasDryer;
fasDryerAlt = fasDryerAlt;
fasDryerHeat = fasDryerHeat;
fasDuck = fasDuck;
fasDumbbell = fasDumbbell;
fasDumpster = fasDumpster;
fasDumpsterFire = fasDumpsterFire;
fasDungeon = fasDungeon;
fasE = fasE;
fasEar = fasEar;
fasEarDeaf = fasEarDeaf;
fasEarListen = fasEarListen;
fasEarMuffs = fasEarMuffs;
fasEarth = fasEarth;
fasEarthAfrica = fasEarthAfrica;
fasEarthAmerica = fasEarthAmerica;
fasEarthAmericas = fasEarthAmericas;
fasEarthAsia = fasEarthAsia;
fasEarthEurope = fasEarthEurope;
fasEarthOceania = fasEarthOceania;
fasEclipse = fasEclipse;
fasEclipseAlt = fasEclipseAlt;
fasEdit = fasEdit;
fasEgg = fasEgg;
fasEggFried = fasEggFried;
fasEggplant = fasEggplant;
fasEject = fasEject;
fasElephant = fasElephant;
fasElevator = fasElevator;
fasEllipsis = fasEllipsis;
fasEllipsisH = fasEllipsisH;
fasEllipsisHAlt = fasEllipsisHAlt;
fasEllipsisStroke = fasEllipsisStroke;
fasEllipsisStrokeVertical = fasEllipsisStrokeVertical;
fasEllipsisV = fasEllipsisV;
fasEllipsisVAlt = fasEllipsisVAlt;
fasEllipsisVertical = fasEllipsisVertical;
fasEmptySet = fasEmptySet;
fasEngine = fasEngine;
fasEngineExclamation = fasEngineExclamation;
fasEngineWarning = fasEngineWarning;
fasEnvelope = fasEnvelope;
fasEnvelopeBadge = fasEnvelopeBadge;
fasEnvelopeCircle = fasEnvelopeCircle;
fasEnvelopeCircleCheck = fasEnvelopeCircleCheck;
fasEnvelopeDot = fasEnvelopeDot;
fasEnvelopeOpen = fasEnvelopeOpen;
fasEnvelopeOpenDollar = fasEnvelopeOpenDollar;
fasEnvelopeOpenText = fasEnvelopeOpenText;
fasEnvelopes = fasEnvelopes;
fasEnvelopesBulk = fasEnvelopesBulk;
fasEnvelopeSquare = fasEnvelopeSquare;
fasEquals = fasEquals;
fasEraser = fasEraser;
fasEscalator = fasEscalator;
fasEthernet = fasEthernet;
fasEur = fasEur;
fasEuro = fasEuro;
fasEuroSign = fasEuroSign;
fasExchange = fasExchange;
fasExchangeAlt = fasExchangeAlt;
fasExclamation = fasExclamation;
fasExclamationCircle = fasExclamationCircle;
fasExclamationSquare = fasExclamationSquare;
fasExclamationTriangle = fasExclamationTriangle;
fasExpand = fasExpand;
fasExpandAlt = fasExpandAlt;
fasExpandArrows = fasExpandArrows;
fasExpandArrowsAlt = fasExpandArrowsAlt;
fasExpandWide = fasExpandWide;
fasExplodingHead = fasExplodingHead;
fasExplosion = fasExplosion;
fasExternalLink = fasExternalLink;
fasExternalLinkAlt = fasExternalLinkAlt;
fasExternalLinkSquare = fasExternalLinkSquare;
fasExternalLinkSquareAlt = fasExternalLinkSquareAlt;
fasEye = fasEye;
fasEyedropper = fasEyedropper;
fasEyeDropper = fasEyeDropper;
fasEyeDropperEmpty = fasEyeDropperEmpty;
fasEyeDropperFull = fasEyeDropperFull;
fasEyeDropperHalf = fasEyeDropperHalf;
fasEyeEvil = fasEyeEvil;
fasEyeLowVision = fasEyeLowVision;
fasEyes = fasEyes;
fasEyeSlash = fasEyeSlash;
fasF = fasF;
fasFaceAngry = fasFaceAngry;
fasFaceAngryHorns = fasFaceAngryHorns;
fasFaceAnguished = fasFaceAnguished;
fasFaceAnxiousSweat = fasFaceAnxiousSweat;
fasFaceAstonished = fasFaceAstonished;
fasFaceAwesome = fasFaceAwesome;
fasFaceBeamHandOverMouth = fasFaceBeamHandOverMouth;
fasFaceClouds = fasFaceClouds;
fasFaceConfounded = fasFaceConfounded;
fasFaceConfused = fasFaceConfused;
fasFaceCowboyHat = fasFaceCowboyHat;
fasFaceDiagonalMouth = fasFaceDiagonalMouth;
fasFaceDisappointed = fasFaceDisappointed;
fasFaceDisguise = fasFaceDisguise;
fasFaceDizzy = fasFaceDizzy;
fasFaceDotted = fasFaceDotted;
fasFaceDowncastSweat = fasFaceDowncastSweat;
fasFaceDrooling = fasFaceDrooling;
fasFaceExhaling = fasFaceExhaling;
fasFaceExplode = fasFaceExplode;
fasFaceExpressionless = fasFaceExpressionless;
fasFaceEyesXmarks = fasFaceEyesXmarks;
fasFaceFearful = fasFaceFearful;
fasFaceFlushed = fasFaceFlushed;
fasFaceFrown = fasFaceFrown;
fasFaceFrownOpen = fasFaceFrownOpen;
fasFaceFrownSlight = fasFaceFrownSlight;
fasFaceGlasses = fasFaceGlasses;
fasFaceGrimace = fasFaceGrimace;
fasFaceGrin = fasFaceGrin;
fasFaceGrinBeam = fasFaceGrinBeam;
fasFaceGrinBeamSweat = fasFaceGrinBeamSweat;
fasFaceGrinHearts = fasFaceGrinHearts;
fasFaceGrinSquint = fasFaceGrinSquint;
fasFaceGrinSquintTears = fasFaceGrinSquintTears;
fasFaceGrinStars = fasFaceGrinStars;
fasFaceGrinTears = fasFaceGrinTears;
fasFaceGrinTongue = fasFaceGrinTongue;
fasFaceGrinTongueSquint = fasFaceGrinTongueSquint;
fasFaceGrinTongueWink = fasFaceGrinTongueWink;
fasFaceGrinWide = fasFaceGrinWide;
fasFaceGrinWink = fasFaceGrinWink;
fasFaceHandOverMouth = fasFaceHandOverMouth;
fasFaceHandPeeking = fasFaceHandPeeking;
fasFaceHandYawn = fasFaceHandYawn;
fasFaceHeadBandage = fasFaceHeadBandage;
fasFaceHoldingBackTears = fasFaceHoldingBackTears;
fasFaceHushed = fasFaceHushed;
fasFaceIcicles = fasFaceIcicles;
fasFaceKiss = fasFaceKiss;
fasFaceKissBeam = fasFaceKissBeam;
fasFaceKissClosedEyes = fasFaceKissClosedEyes;
fasFaceKissWinkHeart = fasFaceKissWinkHeart;
fasFaceLaugh = fasFaceLaugh;
fasFaceLaughBeam = fasFaceLaughBeam;
fasFaceLaughSquint = fasFaceLaughSquint;
fasFaceLaughWink = fasFaceLaughWink;
fasFaceLying = fasFaceLying;
fasFaceMask = fasFaceMask;
fasFaceMeh = fasFaceMeh;
fasFaceMehBlank = fasFaceMehBlank;
fasFaceMelting = fasFaceMelting;
fasFaceMonocle = fasFaceMonocle;
fasFaceNauseated = fasFaceNauseated;
fasFaceNoseSteam = fasFaceNoseSteam;
fasFaceParty = fasFaceParty;
fasFacePensive = fasFacePensive;
fasFacePersevering = fasFacePersevering;
fasFacePleading = fasFacePleading;
fasFacePouting = fasFacePouting;
fasFaceRaisedEyebrow = fasFaceRaisedEyebrow;
fasFaceRelieved = fasFaceRelieved;
fasFaceRollingEyes = fasFaceRollingEyes;
fasFaceSadCry = fasFaceSadCry;
fasFaceSadSweat = fasFaceSadSweat;
fasFaceSadTear = fasFaceSadTear;
fasFaceSaluting = fasFaceSaluting;
fasFaceScream = fasFaceScream;
fasFaceShush = fasFaceShush;
fasFaceSleeping = fasFaceSleeping;
fasFaceSleepy = fasFaceSleepy;
fasFaceSmile = fasFaceSmile;
fasFaceSmileBeam = fasFaceSmileBeam;
fasFaceSmileHalo = fasFaceSmileHalo;
fasFaceSmileHearts = fasFaceSmileHearts;
fasFaceSmileHorns = fasFaceSmileHorns;
fasFaceSmilePlus = fasFaceSmilePlus;
fasFaceSmileRelaxed = fasFaceSmileRelaxed;
fasFaceSmileTear = fasFaceSmileTear;
fasFaceSmileTongue = fasFaceSmileTongue;
fasFaceSmileUpsideDown = fasFaceSmileUpsideDown;
fasFaceSmileWink = fasFaceSmileWink;
fasFaceSmilingHands = fasFaceSmilingHands;
fasFaceSmirking = fasFaceSmirking;
fasFaceSpiralEyes = fasFaceSpiralEyes;
fasFaceSunglasses = fasFaceSunglasses;
fasFaceSurprise = fasFaceSurprise;
fasFaceSwear = fasFaceSwear;
fasFaceThermometer = fasFaceThermometer;
fasFaceThinking = fasFaceThinking;
fasFaceTired = fasFaceTired;
fasFaceTissue = fasFaceTissue;
fasFaceTongueMoney = fasFaceTongueMoney;
fasFaceTongueSweat = fasFaceTongueSweat;
fasFaceUnamused = fasFaceUnamused;
fasFaceViewfinder = fasFaceViewfinder;
fasFaceVomit = fasFaceVomit;
fasFaceWeary = fasFaceWeary;
fasFaceWoozy = fasFaceWoozy;
fasFaceWorried = fasFaceWorried;
fasFaceZany = fasFaceZany;
fasFaceZipper = fasFaceZipper;
fasFalafel = fasFalafel;
fasFamily = fasFamily;
fasFamilyDress = fasFamilyDress;
fasFamilyPants = fasFamilyPants;
fasFan = fasFan;
fasFanTable = fasFanTable;
fasFarm = fasFarm;
fasFastBackward = fasFastBackward;
fasFastForward = fasFastForward;
fasFaucet = fasFaucet;
fasFaucetDrip = fasFaucetDrip;
fasFax = fasFax;
fasFeather = fasFeather;
fasFeatherAlt = fasFeatherAlt;
fasFeatherPointed = fasFeatherPointed;
fasFeed = fasFeed;
fasFemale = fasFemale;
fasFence = fasFence;
fasFerrisWheel = fasFerrisWheel;
fasFerry = fasFerry;
fasFieldHockey = fasFieldHockey;
fasFieldHockeyStickBall = fasFieldHockeyStickBall;
fasFighterJet = fasFighterJet;
fasFile = fasFile;
fasFileAlt = fasFileAlt;
fasFileArchive = fasFileArchive;
fasFileArrowDown = fasFileArrowDown;
fasFileArrowUp = fasFileArrowUp;
fasFileAudio = fasFileAudio;
fasFileAward = fasFileAward;
fasFileBinary = fasFileBinary;
fasFileCaretDown = fasFileCaretDown;
fasFileCaretUp = fasFileCaretUp;
fasFileCertificate = fasFileCertificate;
fasFileChartColumn = fasFileChartColumn;
fasFileChartLine = fasFileChartLine;
fasFileChartPie = fasFileChartPie;
fasFileCheck = fasFileCheck;
fasFileCircleCheck = fasFileCircleCheck;
fasFileCircleExclamation = fasFileCircleExclamation;
fasFileCircleInfo = fasFileCircleInfo;
fasFileCircleMinus = fasFileCircleMinus;
fasFileCirclePlus = fasFileCirclePlus;
fasFileCircleQuestion = fasFileCircleQuestion;
fasFileCircleXmark = fasFileCircleXmark;
fasFileClipboard = fasFileClipboard;
fasFileCode = fasFileCode;
fasFileContract = fasFileContract;
fasFileCsv = fasFileCsv;
fasFileDashedLine = fasFileDashedLine;
fasFileDownload = fasFileDownload;
fasFileEdit = fasFileEdit;
fasFileExcel = fasFileExcel;
fasFileExclamation = fasFileExclamation;
fasFileExport = fasFileExport;
fasFileHeart = fasFileHeart;
fasFileImage = fasFileImage;
fasFileImport = fasFileImport;
fasFileInvoice = fasFileInvoice;
fasFileInvoiceDollar = fasFileInvoiceDollar;
fasFileLines = fasFileLines;
fasFileLock = fasFileLock;
fasFileMagnifyingGlass = fasFileMagnifyingGlass;
fasFileMedical = fasFileMedical;
fasFileMedicalAlt = fasFileMedicalAlt;
fasFileMinus = fasFileMinus;
fasFileMusic = fasFileMusic;
fasFilePdf = fasFilePdf;
fasFilePen = fasFilePen;
fasFilePlus = fasFilePlus;
fasFilePlusMinus = fasFilePlusMinus;
fasFilePowerpoint = fasFilePowerpoint;
fasFilePrescription = fasFilePrescription;
fasFiles = fasFiles;
fasFileSearch = fasFileSearch;
fasFileShield = fasFileShield;
fasFileSignature = fasFileSignature;
fasFileSlash = fasFileSlash;
fasFilesMedical = fasFilesMedical;
fasFileSpreadsheet = fasFileSpreadsheet;
fasFileText = fasFileText;
fasFileTimes = fasFileTimes;
fasFileUpload = fasFileUpload;
fasFileUser = fasFileUser;
fasFileVideo = fasFileVideo;
fasFileWaveform = fasFileWaveform;
fasFileWord = fasFileWord;
fasFileXmark = fasFileXmark;
fasFileZipper = fasFileZipper;
fasFill = fasFill;
fasFillDrip = fasFillDrip;
fasFilm = fasFilm;
fasFilmAlt = fasFilmAlt;
fasFilmCanister = fasFilmCanister;
fasFilms = fasFilms;
fasFilmSimple = fasFilmSimple;
fasFilmSlash = fasFilmSlash;
fasFilter = fasFilter;
fasFilterCircleDollar = fasFilterCircleDollar;
fasFilterCircleXmark = fasFilterCircleXmark;
fasFilterList = fasFilterList;
fasFilters = fasFilters;
fasFilterSlash = fasFilterSlash;
fasFingerprint = fasFingerprint;
fasFire = fasFire;
fasFireAlt = fasFireAlt;
fasFireBurner = fasFireBurner;
fasFireExtinguisher = fasFireExtinguisher;
fasFireFlame = fasFireFlame;
fasFireFlameCurved = fasFireFlameCurved;
fasFireFlameSimple = fasFireFlameSimple;
fasFireHydrant = fasFireHydrant;
fasFireplace = fasFireplace;
fasFireSmoke = fasFireSmoke;
fasFirewall = fasFirewall;
fasFirstAid = fasFirstAid;
fasFish = fasFish;
fasFishBones = fasFishBones;
fasFishCooked = fasFishCooked;
fasFishFins = fasFishFins;
fasFishingRod = fasFishingRod;
fasFistRaised = fasFistRaised;
fasFlag = fasFlag;
fasFlagAlt = fasFlagAlt;
fasFlagCheckered = fasFlagCheckered;
fasFlagPennant = fasFlagPennant;
fasFlagSwallowtail = fasFlagSwallowtail;
fasFlagUsa = fasFlagUsa;
fasFlame = fasFlame;
fasFlashlight = fasFlashlight;
fasFlask = fasFlask;
fasFlaskPoison = fasFlaskPoison;
fasFlaskPotion = fasFlaskPotion;
fasFlaskRoundPoison = fasFlaskRoundPoison;
fasFlaskRoundPotion = fasFlaskRoundPotion;
fasFlaskVial = fasFlaskVial;
fasFlatbread = fasFlatbread;
fasFlatbreadStuffed = fasFlatbreadStuffed;
fasFloppyDisk = fasFloppyDisk;
fasFloppyDiskCircleArrowRight = fasFloppyDiskCircleArrowRight;
fasFloppyDiskCircleXmark = fasFloppyDiskCircleXmark;
fasFloppyDiskPen = fasFloppyDiskPen;
fasFloppyDisks = fasFloppyDisks;
fasFloppyDiskTimes = fasFloppyDiskTimes;
fasFlorinSign = fasFlorinSign;
fasFlower = fasFlower;
fasFlowerDaffodil = fasFlowerDaffodil;
fasFlowerTulip = fasFlowerTulip;
fasFlushed = fasFlushed;
fasFlute = fasFlute;
fasFluxCapacitor = fasFluxCapacitor;
fasFlyingDisc = fasFlyingDisc;
fasFog = fasFog;
fasFolder = fasFolder;
fasFolderArrowDown = fasFolderArrowDown;
fasFolderArrowUp = fasFolderArrowUp;
fasFolderBlank = fasFolderBlank;
fasFolderBookmark = fasFolderBookmark;
fasFolderClosed = fasFolderClosed;
fasFolderCog = fasFolderCog;
fasFolderDownload = fasFolderDownload;
fasFolderGear = fasFolderGear;
fasFolderGrid = fasFolderGrid;
fasFolderHeart = fasFolderHeart;
fasFolderImage = fasFolderImage;
fasFolderMagnifyingGlass = fasFolderMagnifyingGlass;
fasFolderMedical = fasFolderMedical;
fasFolderMinus = fasFolderMinus;
fasFolderMusic = fasFolderMusic;
fasFolderOpen = fasFolderOpen;
fasFolderPlus = fasFolderPlus;
fasFolders = fasFolders;
fasFolderSearch = fasFolderSearch;
fasFolderTimes = fasFolderTimes;
fasFolderTree = fasFolderTree;
fasFolderUpload = fasFolderUpload;
fasFolderUser = fasFolderUser;
fasFolderXmark = fasFolderXmark;
fasFonduePot = fasFonduePot;
fasFont = fasFont;
fasFontAwesome = fasFontAwesome;
fasFontAwesomeFlag = fasFontAwesomeFlag;
fasFontAwesomeLogoFull = fasFontAwesomeLogoFull;
fasFontCase = fasFontCase;
fasFootball = fasFootball;
fasFootballBall = fasFootballBall;
fasFootballHelmet = fasFootballHelmet;
fasFork = fasFork;
fasForkKnife = fasForkKnife;
fasForklift = fasForklift;
fasFort = fasFort;
fasForward = fasForward;
fasForwardFast = fasForwardFast;
fasForwardStep = fasForwardStep;
fasFragile = fasFragile;
fasFrame = fasFrame;
fasFrancSign = fasFrancSign;
fasFrenchFries = fasFrenchFries;
fasFrog = fasFrog;
fasFrostyHead = fasFrostyHead;
fasFrown = fasFrown;
fasFrownOpen = fasFrownOpen;
fasFunction = fasFunction;
fasFunnelDollar = fasFunnelDollar;
fasFutbol = fasFutbol;
fasFutbolBall = fasFutbolBall;
fasG = fasG;
fasGalaxy = fasGalaxy;
fasGalleryThumbnails = fasGalleryThumbnails;
fasGameBoard = fasGameBoard;
fasGameBoardAlt = fasGameBoardAlt;
fasGameBoardSimple = fasGameBoardSimple;
fasGameConsoleHandheld = fasGameConsoleHandheld;
fasGamepad = fasGamepad;
fasGamepadAlt = fasGamepadAlt;
fasGamepadModern = fasGamepadModern;
fasGarage = fasGarage;
fasGarageCar = fasGarageCar;
fasGarageOpen = fasGarageOpen;
fasGarlic = fasGarlic;
fasGasPump = fasGasPump;
fasGasPumpSlash = fasGasPumpSlash;
fasGauge = fasGauge;
fasGaugeCircleBolt = fasGaugeCircleBolt;
fasGaugeCircleMinus = fasGaugeCircleMinus;
fasGaugeCirclePlus = fasGaugeCirclePlus;
fasGaugeHigh = fasGaugeHigh;
fasGaugeLow = fasGaugeLow;
fasGaugeMax = fasGaugeMax;
fasGaugeMed = fasGaugeMed;
fasGaugeMin = fasGaugeMin;
fasGaugeSimple = fasGaugeSimple;
fasGaugeSimpleHigh = fasGaugeSimpleHigh;
fasGaugeSimpleLow = fasGaugeSimpleLow;
fasGaugeSimpleMax = fasGaugeSimpleMax;
fasGaugeSimpleMed = fasGaugeSimpleMed;
fasGaugeSimpleMin = fasGaugeSimpleMin;
fasGaveDandy = fasGaveDandy;
fasGavel = fasGavel;
fasGbp = fasGbp;
fasGear = fasGear;
fasGears = fasGears;
fasGem = fasGem;
fasGenderless = fasGenderless;
fasGhost = fasGhost;
fasGif = fasGif;
fasGift = fasGift;
fasGiftCard = fasGiftCard;
fasGifts = fasGifts;
fasGingerbreadMan = fasGingerbreadMan;
fasGlass = fasGlass;
fasGlassChampagne = fasGlassChampagne;
fasGlassCheers = fasGlassCheers;
fasGlassCitrus = fasGlassCitrus;
fasGlassEmpty = fasGlassEmpty;
fasGlasses = fasGlasses;
fasGlassesAlt = fasGlassesAlt;
fasGlassesRound = fasGlassesRound;
fasGlassHalf = fasGlassHalf;
fasGlassHalfEmpty = fasGlassHalfEmpty;
fasGlassHalfFull = fasGlassHalfFull;
fasGlassMartini = fasGlassMartini;
fasGlassMartiniAlt = fasGlassMartiniAlt;
fasGlassWater = fasGlassWater;
fasGlassWaterDroplet = fasGlassWaterDroplet;
fasGlassWhiskey = fasGlassWhiskey;
fasGlassWhiskeyRocks = fasGlassWhiskeyRocks;
fasGlobe = fasGlobe;
fasGlobeAfrica = fasGlobeAfrica;
fasGlobeAmericas = fasGlobeAmericas;
fasGlobeAsia = fasGlobeAsia;
fasGlobeEurope = fasGlobeEurope;
fasGlobeOceania = fasGlobeOceania;
fasGlobeSnow = fasGlobeSnow;
fasGlobeStand = fasGlobeStand;
fasGloveBoxing = fasGloveBoxing;
fasGoalNet = fasGoalNet;
fasGolfBall = fasGolfBall;
fasGolfBallTee = fasGolfBallTee;
fasGolfClub = fasGolfClub;
fasGolfFlagHole = fasGolfFlagHole;
fasGopuram = fasGopuram;
fasGraduationCap = fasGraduationCap;
fasGramophone = fasGramophone;
fasGrapes = fasGrapes;
fasGrate = fasGrate;
fasGrateDroplet = fasGrateDroplet;
fasGreaterThan = fasGreaterThan;
fasGreaterThanEqual = fasGreaterThanEqual;
fasGrid = fasGrid;
fasGrid2 = fasGrid2;
fasGrid2Plus = fasGrid2Plus;
fasGrid3 = fasGrid3;
fasGrid4 = fasGrid4;
fasGrid5 = fasGrid5;
fasGridDividers = fasGridDividers;
fasGridHorizontal = fasGridHorizontal;
fasGrimace = fasGrimace;
fasGrin = fasGrin;
fasGrinAlt = fasGrinAlt;
fasGrinBeam = fasGrinBeam;
fasGrinBeamSweat = fasGrinBeamSweat;
fasGrinHearts = fasGrinHearts;
fasGrinSquint = fasGrinSquint;
fasGrinSquintTears = fasGrinSquintTears;
fasGrinStars = fasGrinStars;
fasGrinTears = fasGrinTears;
fasGrinTongue = fasGrinTongue;
fasGrinTongueSquint = fasGrinTongueSquint;
fasGrinTongueWink = fasGrinTongueWink;
fasGrinWink = fasGrinWink;
fasGrip = fasGrip;
fasGripDots = fasGripDots;
fasGripDotsVertical = fasGripDotsVertical;
fasGripHorizontal = fasGripHorizontal;
fasGripLines = fasGripLines;
fasGripLinesVertical = fasGripLinesVertical;
fasGripVertical = fasGripVertical;
fasGroupArrowsRotate = fasGroupArrowsRotate;
fasGuaraniSign = fasGuaraniSign;
fasGuitar = fasGuitar;
fasGuitarElectric = fasGuitarElectric;
fasGuitars = fasGuitars;
fasGun = fasGun;
fasGunSlash = fasGunSlash;
fasGunSquirt = fasGunSquirt;
fasH = fasH;
fasH1 = fasH1;
fasH2 = fasH2;
fasH3 = fasH3;
fasH4 = fasH4;
fasH5 = fasH5;
fasH6 = fasH6;
fasHamburger = fasHamburger;
fasHammer = fasHammer;
fasHammerCrash = fasHammerCrash;
fasHammerWar = fasHammerWar;
fasHamsa = fasHamsa;
fasHand = fasHand;
fasHandBackFist = fasHandBackFist;
fasHandBackPointDown = fasHandBackPointDown;
fasHandBackPointLeft = fasHandBackPointLeft;
fasHandBackPointRibbon = fasHandBackPointRibbon;
fasHandBackPointRight = fasHandBackPointRight;
fasHandBackPointUp = fasHandBackPointUp;
fasHandcuffs = fasHandcuffs;
fasHandDots = fasHandDots;
fasHandFingersCrossed = fasHandFingersCrossed;
fasHandFist = fasHandFist;
fasHandHeart = fasHandHeart;
fasHandHolding = fasHandHolding;
fasHandHoldingBox = fasHandHoldingBox;
fasHandHoldingDollar = fasHandHoldingDollar;
fasHandHoldingDroplet = fasHandHoldingDroplet;
fasHandHoldingHand = fasHandHoldingHand;
fasHandHoldingHeart = fasHandHoldingHeart;
fasHandHoldingMagic = fasHandHoldingMagic;
fasHandHoldingMedical = fasHandHoldingMedical;
fasHandHoldingSeedling = fasHandHoldingSeedling;
fasHandHoldingSkull = fasHandHoldingSkull;
fasHandHoldingUsd = fasHandHoldingUsd;
fasHandHoldingWater = fasHandHoldingWater;
fasHandHorns = fasHandHorns;
fasHandLizard = fasHandLizard;
fasHandLove = fasHandLove;
fasHandMiddleFinger = fasHandMiddleFinger;
fasHandPaper = fasHandPaper;
fasHandPeace = fasHandPeace;
fasHandPointDown = fasHandPointDown;
fasHandPointer = fasHandPointer;
fasHandPointLeft = fasHandPointLeft;
fasHandPointRibbon = fasHandPointRibbon;
fasHandPointRight = fasHandPointRight;
fasHandPointUp = fasHandPointUp;
fasHandReceiving = fasHandReceiving;
fasHandRock = fasHandRock;
fasHands = fasHands;
fasHandsAmericanSignLanguageInterpreting = fasHandsAmericanSignLanguageInterpreting;
fasHandsAslInterpreting = fasHandsAslInterpreting;
fasHandsBound = fasHandsBound;
fasHandsBubbles = fasHandsBubbles;
fasHandScissors = fasHandScissors;
fasHandsClapping = fasHandsClapping;
fasHandshake = fasHandshake;
fasHandshakeAlt = fasHandshakeAlt;
fasHandshakeAltSlash = fasHandshakeAltSlash;
fasHandshakeAngle = fasHandshakeAngle;
fasHandshakeSimple = fasHandshakeSimple;
fasHandshakeSimpleSlash = fasHandshakeSimpleSlash;
fasHandshakeSlash = fasHandshakeSlash;
fasHandsHeart = fasHandsHeart;
fasHandsHelping = fasHandsHelping;
fasHandsHolding = fasHandsHolding;
fasHandsHoldingChild = fasHandsHoldingChild;
fasHandsHoldingCircle = fasHandsHoldingCircle;
fasHandsHoldingDiamond = fasHandsHoldingDiamond;
fasHandsHoldingDollar = fasHandsHoldingDollar;
fasHandsHoldingHeart = fasHandsHoldingHeart;
fasHandSparkles = fasHandSparkles;
fasHandSpock = fasHandSpock;
fasHandsPraying = fasHandsPraying;
fasHandsUsd = fasHandsUsd;
fasHandsWash = fasHandsWash;
fasHandWave = fasHandWave;
fasHanukiah = fasHanukiah;
fasHardDrive = fasHardDrive;
fasHardHat = fasHardHat;
fasHardOfHearing = fasHardOfHearing;
fasHashtag = fasHashtag;
fasHashtagLock = fasHashtagLock;
fasHatChef = fasHatChef;
fasHatCowboy = fasHatCowboy;
fasHatCowboySide = fasHatCowboySide;
fasHatHard = fasHatHard;
fasHatSanta = fasHatSanta;
fasHatWinter = fasHatWinter;
fasHatWitch = fasHatWitch;
fasHatWizard = fasHatWizard;
fasHdd = fasHdd;
fasHeader = fasHeader;
fasHeading = fasHeading;
fasHeadphones = fasHeadphones;
fasHeadphonesAlt = fasHeadphonesAlt;
fasHeadphonesSimple = fasHeadphonesSimple;
fasHeadset = fasHeadset;
fasHeadSide = fasHeadSide;
fasHeadSideBrain = fasHeadSideBrain;
fasHeadSideCough = fasHeadSideCough;
fasHeadSideCoughSlash = fasHeadSideCoughSlash;
fasHeadSideGoggles = fasHeadSideGoggles;
fasHeadSideHeadphones = fasHeadSideHeadphones;
fasHeadSideHeart = fasHeadSideHeart;
fasHeadSideMask = fasHeadSideMask;
fasHeadSideMedical = fasHeadSideMedical;
fasHeadSideVirus = fasHeadSideVirus;
fasHeadVr = fasHeadVr;
fasHeart = fasHeart;
fasHeartbeat = fasHeartbeat;
fasHeartBroken = fasHeartBroken;
fasHeartCircle = fasHeartCircle;
fasHeartCircleBolt = fasHeartCircleBolt;
fasHeartCircleCheck = fasHeartCircleCheck;
fasHeartCircleExclamation = fasHeartCircleExclamation;
fasHeartCircleMinus = fasHeartCircleMinus;
fasHeartCirclePlus = fasHeartCirclePlus;
fasHeartCircleXmark = fasHeartCircleXmark;
fasHeartCrack = fasHeartCrack;
fasHeartHalf = fasHeartHalf;
fasHeartHalfAlt = fasHeartHalfAlt;
fasHeartHalfStroke = fasHeartHalfStroke;
fasHeartMusicCameraBolt = fasHeartMusicCameraBolt;
fasHeartPulse = fasHeartPulse;
fasHeartRate = fasHeartRate;
fasHeartSquare = fasHeartSquare;
fasHeat = fasHeat;
fasHelicopter = fasHelicopter;
fasHelicopterSymbol = fasHelicopterSymbol;
fasHelmetBattle = fasHelmetBattle;
fasHelmetSafety = fasHelmetSafety;
fasHelmetUn = fasHelmetUn;
fasHexagon = fasHexagon;
fasHexagonCheck = fasHexagonCheck;
fasHexagonDivide = fasHexagonDivide;
fasHexagonExclamation = fasHexagonExclamation;
fasHexagonImage = fasHexagonImage;
fasHexagonMinus = fasHexagonMinus;
fasHexagonPlus = fasHexagonPlus;
fasHexagonVerticalNft = fasHexagonVerticalNft;
fasHexagonVerticalNftSlanted = fasHexagonVerticalNftSlanted;
fasHexagonXmark = fasHexagonXmark;
fasHighDefinition = fasHighDefinition;
fasHighlighter = fasHighlighter;
fasHighlighterLine = fasHighlighterLine;
fasHiking = fasHiking;
fasHillAvalanche = fasHillAvalanche;
fasHillRockslide = fasHillRockslide;
fasHippo = fasHippo;
fasHistory = fasHistory;
fasHockeyMask = fasHockeyMask;
fasHockeyPuck = fasHockeyPuck;
fasHockeyStickPuck = fasHockeyStickPuck;
fasHockeySticks = fasHockeySticks;
fasHollyBerry = fasHollyBerry;
fasHome = fasHome;
fasHomeAlt = fasHomeAlt;
fasHomeBlank = fasHomeBlank;
fasHomeHeart = fasHomeHeart;
fasHomeLg = fasHomeLg;
fasHomeLgAlt = fasHomeLgAlt;
fasHomeUser = fasHomeUser;
fasHoneyPot = fasHoneyPot;
fasHoodCloak = fasHoodCloak;
fasHorizontalRule = fasHorizontalRule;
fasHorse = fasHorse;
fasHorseHead = fasHorseHead;
fasHorseSaddle = fasHorseSaddle;
fasHose = fasHose;
fasHoseReel = fasHoseReel;
fasHospital = fasHospital;
fasHospitalAlt = fasHospitalAlt;
fasHospitals = fasHospitals;
fasHospitalSymbol = fasHospitalSymbol;
fasHospitalUser = fasHospitalUser;
fasHospitalWide = fasHospitalWide;
fasHotdog = fasHotdog;
fasHotel = fasHotel;
fasHotTub = fasHotTub;
fasHotTubPerson = fasHotTubPerson;
fasHourglass = fasHourglass;
fasHourglass1 = fasHourglass1;
fasHourglass2 = fasHourglass2;
fasHourglass3 = fasHourglass3;
fasHourglassClock = fasHourglassClock;
fasHourglassEmpty = fasHourglassEmpty;
fasHourglassEnd = fasHourglassEnd;
fasHourglassHalf = fasHourglassHalf;
fasHourglassStart = fasHourglassStart;
fasHouse = fasHouse;
fasHouseBlank = fasHouseBlank;
fasHouseBuilding = fasHouseBuilding;
fasHouseChimney = fasHouseChimney;
fasHouseChimneyBlank = fasHouseChimneyBlank;
fasHouseChimneyCrack = fasHouseChimneyCrack;
fasHouseChimneyHeart = fasHouseChimneyHeart;
fasHouseChimneyMedical = fasHouseChimneyMedical;
fasHouseChimneyUser = fasHouseChimneyUser;
fasHouseChimneyWindow = fasHouseChimneyWindow;
fasHouseCircleCheck = fasHouseCircleCheck;
fasHouseCircleExclamation = fasHouseCircleExclamation;
fasHouseCircleXmark = fasHouseCircleXmark;
fasHouseCrack = fasHouseCrack;
fasHouseDamage = fasHouseDamage;
fasHouseDay = fasHouseDay;
fasHouseFire = fasHouseFire;
fasHouseFlag = fasHouseFlag;
fasHouseFlood = fasHouseFlood;
fasHouseFloodWater = fasHouseFloodWater;
fasHouseFloodWaterCircleArrowRight = fasHouseFloodWaterCircleArrowRight;
fasHouseHeart = fasHouseHeart;
fasHouseLaptop = fasHouseLaptop;
fasHouseLeave = fasHouseLeave;
fasHouseLock = fasHouseLock;
fasHouseMedical = fasHouseMedical;
fasHouseMedicalCircleCheck = fasHouseMedicalCircleCheck;
fasHouseMedicalCircleExclamation = fasHouseMedicalCircleExclamation;
fasHouseMedicalCircleXmark = fasHouseMedicalCircleXmark;
fasHouseMedicalFlag = fasHouseMedicalFlag;
fasHouseNight = fasHouseNight;
fasHousePersonArrive = fasHousePersonArrive;
fasHousePersonDepart = fasHousePersonDepart;
fasHousePersonLeave = fasHousePersonLeave;
fasHousePersonReturn = fasHousePersonReturn;
fasHouseReturn = fasHouseReturn;
fasHouseSignal = fasHouseSignal;
fasHouseTree = fasHouseTree;
fasHouseTsunami = fasHouseTsunami;
fasHouseTurret = fasHouseTurret;
fasHouseUser = fasHouseUser;
fasHouseWater = fasHouseWater;
fasHouseWindow = fasHouseWindow;
fasHryvnia = fasHryvnia;
fasHryvniaSign = fasHryvniaSign;
fasHSquare = fasHSquare;
fasHumidity = fasHumidity;
fasHundredPoints = fasHundredPoints;
fasHurricane = fasHurricane;
fasHyphen = fasHyphen;
fasI = fasI;
fasIceCream = fasIceCream;
fasIceSkate = fasIceSkate;
fasIcicles = fasIcicles;
fasIcons = fasIcons;
fasIconsAlt = fasIconsAlt;
fasICursor = fasICursor;
fasIdBadge = fasIdBadge;
fasIdCard = fasIdCard;
fasIdCardAlt = fasIdCardAlt;
fasIdCardClip = fasIdCardClip;
fasIgloo = fasIgloo;
fasIls = fasIls;
fasImage = fasImage;
fasImageLandscape = fasImageLandscape;
fasImagePolaroid = fasImagePolaroid;
fasImagePolaroidUser = fasImagePolaroidUser;
fasImagePortrait = fasImagePortrait;
fasImages = fasImages;
fasImageSlash = fasImageSlash;
fasImagesUser = fasImagesUser;
fasImageUser = fasImageUser;
fasInbox = fasInbox;
fasInboxArrowDown = fasInboxArrowDown;
fasInboxArrowUp = fasInboxArrowUp;
fasInboxes = fasInboxes;
fasInboxFull = fasInboxFull;
fasInboxIn = fasInboxIn;
fasInboxOut = fasInboxOut;
fasIndent = fasIndent;
fasIndianRupee = fasIndianRupee;
fasIndianRupeeSign = fasIndianRupeeSign;
fasIndustry = fasIndustry;
fasIndustryAlt = fasIndustryAlt;
fasIndustryWindows = fasIndustryWindows;
fasInfinity = fasInfinity;
fasInfo = fasInfo;
fasInfoCircle = fasInfoCircle;
fasInfoSquare = fasInfoSquare;
fasInhaler = fasInhaler;
fasInputNumeric = fasInputNumeric;
fasInputPipe = fasInputPipe;
fasInputText = fasInputText;
fasInr = fasInr;
fasInstitution = fasInstitution;
fasIntegral = fasIntegral;
fasIntersection = fasIntersection;
fasInventory = fasInventory;
fasIslandTreePalm = fasIslandTreePalm;
fasIslandTropical = fasIslandTropical;
fasItalic = fasItalic;
fasJ = fasJ;
fasJackOLantern = fasJackOLantern;
fasJar = fasJar;
fasJarWheat = fasJarWheat;
fasJedi = fasJedi;
fasJetFighter = fasJetFighter;
fasJetFighterUp = fasJetFighterUp;
fasJoint = fasJoint;
fasJournalWhills = fasJournalWhills;
fasJoystick = fasJoystick;
fasJpy = fasJpy;
fasJug = fasJug;
fasJugDetergent = fasJugDetergent;
fasK = fasK;
fasKaaba = fasKaaba;
fasKazoo = fasKazoo;
fasKerning = fasKerning;
fasKey = fasKey;
fasKeyboard = fasKeyboard;
fasKeyboardBrightness = fasKeyboardBrightness;
fasKeyboardBrightnessLow = fasKeyboardBrightnessLow;
fasKeyboardDown = fasKeyboardDown;
fasKeyboardLeft = fasKeyboardLeft;
fasKeynote = fasKeynote;
fasKeySkeleton = fasKeySkeleton;
fasKeySkeletonLeftRight = fasKeySkeletonLeftRight;
fasKhanda = fasKhanda;
fasKidneys = fasKidneys;
fasKipSign = fasKipSign;
fasKiss = fasKiss;
fasKissBeam = fasKissBeam;
fasKissWinkHeart = fasKissWinkHeart;
fasKitchenSet = fasKitchenSet;
fasKite = fasKite;
fasKitMedical = fasKitMedical;
fasKiwiBird = fasKiwiBird;
fasKiwiFruit = fasKiwiFruit;
fasKnife = fasKnife;
fasKnifeKitchen = fasKnifeKitchen;
fasKrw = fasKrw;
fasL = fasL;
fasLacrosseStick = fasLacrosseStick;
fasLacrosseStickBall = fasLacrosseStickBall;
fasLadderWater = fasLadderWater;
fasLambda = fasLambda;
fasLamp = fasLamp;
fasLampDesk = fasLampDesk;
fasLampFloor = fasLampFloor;
fasLampStreet = fasLampStreet;
fasLandmark = fasLandmark;
fasLandmarkAlt = fasLandmarkAlt;
fasLandmarkDome = fasLandmarkDome;
fasLandmarkFlag = fasLandmarkFlag;
fasLandMineOn = fasLandMineOn;
fasLandscape = fasLandscape;
fasLanguage = fasLanguage;
fasLaptop = fasLaptop;
fasLaptopArrowDown = fasLaptopArrowDown;
fasLaptopCode = fasLaptopCode;
fasLaptopFile = fasLaptopFile;
fasLaptopHouse = fasLaptopHouse;
fasLaptopMedical = fasLaptopMedical;
fasLaptopMobile = fasLaptopMobile;
fasLaptopSlash = fasLaptopSlash;
fasLariSign = fasLariSign;
fasLasso = fasLasso;
fasLassoSparkles = fasLassoSparkles;
fasLaugh = fasLaugh;
fasLaughBeam = fasLaughBeam;
fasLaughSquint = fasLaughSquint;
fasLaughWink = fasLaughWink;
fasLayerGroup = fasLayerGroup;
fasLayerGroupMinus = fasLayerGroupMinus;
fasLayerGroupPlus = fasLayerGroupPlus;
fasLayerMinus = fasLayerMinus;
fasLayerPlus = fasLayerPlus;
fasLeaf = fasLeaf;
fasLeafHeart = fasLeafHeart;
fasLeafMaple = fasLeafMaple;
fasLeafOak = fasLeafOak;
fasLeafyGreen = fasLeafyGreen;
fasLeft = fasLeft;
fasLeftFromLine = fasLeftFromLine;
fasLeftLong = fasLeftLong;
fasLeftLongToLine = fasLeftLongToLine;
fasLeftRight = fasLeftRight;
fasLeftToLine = fasLeftToLine;
fasLegal = fasLegal;
fasLemon = fasLemon;
fasLessThan = fasLessThan;
fasLessThanEqual = fasLessThanEqual;
fasLevelDown = fasLevelDown;
fasLevelDownAlt = fasLevelDownAlt;
fasLevelUp = fasLevelUp;
fasLevelUpAlt = fasLevelUpAlt;
fasLifeRing = fasLifeRing;
fasLightbulb = fasLightbulb;
fasLightbulbDollar = fasLightbulbDollar;
fasLightbulbExclamation = fasLightbulbExclamation;
fasLightbulbExclamationOn = fasLightbulbExclamationOn;
fasLightbulbOn = fasLightbulbOn;
fasLightbulbSlash = fasLightbulbSlash;
fasLightCeiling = fasLightCeiling;
fasLightEmergency = fasLightEmergency;
fasLightEmergencyOn = fasLightEmergencyOn;
fasLightsHoliday = fasLightsHoliday;
fasLightSwitch = fasLightSwitch;
fasLightSwitchOff = fasLightSwitchOff;
fasLightSwitchOn = fasLightSwitchOn;
fasLineChart = fasLineChart;
fasLineColumns = fasLineColumns;
fasLineHeight = fasLineHeight;
fasLinesLeaning = fasLinesLeaning;
fasLink = fasLink;
fasLinkHorizontal = fasLinkHorizontal;
fasLinkHorizontalSlash = fasLinkHorizontalSlash;
fasLinkSimple = fasLinkSimple;
fasLinkSimpleSlash = fasLinkSimpleSlash;
fasLinkSlash = fasLinkSlash;
fasLips = fasLips;
fasLiraSign = fasLiraSign;
fasList = fasList;
fasList12 = fasList12;
fasListAlt = fasListAlt;
fasListCheck = fasListCheck;
fasListDots = fasListDots;
fasListDropdown = fasListDropdown;
fasListMusic = fasListMusic;
fasListNumeric = fasListNumeric;
fasListOl = fasListOl;
fasListRadio = fasListRadio;
fasListSquares = fasListSquares;
fasListTimeline = fasListTimeline;
fasListTree = fasListTree;
fasListUl = fasListUl;
fasLitecoinSign = fasLitecoinSign;
fasLoader = fasLoader;
fasLobster = fasLobster;
fasLocation = fasLocation;
fasLocationArrow = fasLocationArrow;
fasLocationCheck = fasLocationCheck;
fasLocationCircle = fasLocationCircle;
fasLocationCrosshairs = fasLocationCrosshairs;
fasLocationCrosshairsSlash = fasLocationCrosshairsSlash;
fasLocationDot = fasLocationDot;
fasLocationDotSlash = fasLocationDotSlash;
fasLocationExclamation = fasLocationExclamation;
fasLocationMinus = fasLocationMinus;
fasLocationPen = fasLocationPen;
fasLocationPin = fasLocationPin;
fasLocationPinLock = fasLocationPinLock;
fasLocationPinSlash = fasLocationPinSlash;
fasLocationPlus = fasLocationPlus;
fasLocationQuestion = fasLocationQuestion;
fasLocationSlash = fasLocationSlash;
fasLocationSmile = fasLocationSmile;
fasLocationXmark = fasLocationXmark;
fasLock = fasLock;
fasLockA = fasLockA;
fasLockAlt = fasLockAlt;
fasLockHashtag = fasLockHashtag;
fasLockKeyhole = fasLockKeyhole;
fasLockKeyholeOpen = fasLockKeyholeOpen;
fasLockOpen = fasLockOpen;
fasLockOpenAlt = fasLockOpenAlt;
fasLocust = fasLocust;
fasLollipop = fasLollipop;
fasLollypop = fasLollypop;
fasLongArrowAltDown = fasLongArrowAltDown;
fasLongArrowAltLeft = fasLongArrowAltLeft;
fasLongArrowAltRight = fasLongArrowAltRight;
fasLongArrowAltUp = fasLongArrowAltUp;
fasLongArrowDown = fasLongArrowDown;
fasLongArrowLeft = fasLongArrowLeft;
fasLongArrowRight = fasLongArrowRight;
fasLongArrowUp = fasLongArrowUp;
fasLoveseat = fasLoveseat;
fasLowVision = fasLowVision;
fasLuchador = fasLuchador;
fasLuchadorMask = fasLuchadorMask;
fasLuggageCart = fasLuggageCart;
fasLungs = fasLungs;
fasLungsVirus = fasLungsVirus;
fasM = fasM;
fasMace = fasMace;
fasMagic = fasMagic;
fasMagicWandSparkles = fasMagicWandSparkles;
fasMagnet = fasMagnet;
fasMagnifyingGlass = fasMagnifyingGlass;
fasMagnifyingGlassArrowRight = fasMagnifyingGlassArrowRight;
fasMagnifyingGlassChart = fasMagnifyingGlassChart;
fasMagnifyingGlassDollar = fasMagnifyingGlassDollar;
fasMagnifyingGlassLocation = fasMagnifyingGlassLocation;
fasMagnifyingGlassMinus = fasMagnifyingGlassMinus;
fasMagnifyingGlassPlus = fasMagnifyingGlassPlus;
fasMailbox = fasMailbox;
fasMailBulk = fasMailBulk;
fasMailForward = fasMailForward;
fasMailReply = fasMailReply;
fasMailReplyAll = fasMailReplyAll;
fasMakiRoll = fasMakiRoll;
fasMakizushi = fasMakizushi;
fasMale = fasMale;
fasManatSign = fasManatSign;
fasMandolin = fasMandolin;
fasMango = fasMango;
fasManhole = fasManhole;
fasMap = fasMap;
fasMapLocation = fasMapLocation;
fasMapLocationDot = fasMapLocationDot;
fasMapMarked = fasMapMarked;
fasMapMarkedAlt = fasMapMarkedAlt;
fasMapMarker = fasMapMarker;
fasMapMarkerAlt = fasMapMarkerAlt;
fasMapMarkerAltSlash = fasMapMarkerAltSlash;
fasMapMarkerCheck = fasMapMarkerCheck;
fasMapMarkerEdit = fasMapMarkerEdit;
fasMapMarkerExclamation = fasMapMarkerExclamation;
fasMapMarkerMinus = fasMapMarkerMinus;
fasMapMarkerPlus = fasMapMarkerPlus;
fasMapMarkerQuestion = fasMapMarkerQuestion;
fasMapMarkerSlash = fasMapMarkerSlash;
fasMapMarkerSmile = fasMapMarkerSmile;
fasMapMarkerTimes = fasMapMarkerTimes;
fasMapMarkerXmark = fasMapMarkerXmark;
fasMapPin = fasMapPin;
fasMapSigns = fasMapSigns;
fasMarker = fasMarker;
fasMars = fasMars;
fasMarsAndVenus = fasMarsAndVenus;
fasMarsAndVenusBurst = fasMarsAndVenusBurst;
fasMarsDouble = fasMarsDouble;
fasMarsStroke = fasMarsStroke;
fasMarsStrokeH = fasMarsStrokeH;
fasMarsStrokeRight = fasMarsStrokeRight;
fasMarsStrokeUp = fasMarsStrokeUp;
fasMarsStrokeV = fasMarsStrokeV;
fasMartiniGlass = fasMartiniGlass;
fasMartiniGlassCitrus = fasMartiniGlassCitrus;
fasMartiniGlassEmpty = fasMartiniGlassEmpty;
fasMask = fasMask;
fasMaskFace = fasMaskFace;
fasMaskLuchador = fasMaskLuchador;
fasMaskSnorkel = fasMaskSnorkel;
fasMasksTheater = fasMasksTheater;
fasMaskVentilator = fasMaskVentilator;
fasMattressPillow = fasMattressPillow;
fasMaximize = fasMaximize;
fasMeat = fasMeat;
fasMedal = fasMedal;
fasMedkit = fasMedkit;
fasMegaphone = fasMegaphone;
fasMeh = fasMeh;
fasMehBlank = fasMehBlank;
fasMehRollingEyes = fasMehRollingEyes;
fasMelon = fasMelon;
fasMelonSlice = fasMelonSlice;
fasMemo = fasMemo;
fasMemoCircleCheck = fasMemoCircleCheck;
fasMemoCircleInfo = fasMemoCircleInfo;
fasMemoPad = fasMemoPad;
fasMemory = fasMemory;
fasMenorah = fasMenorah;
fasMercury = fasMercury;
fasMerge = fasMerge;
fasMessage = fasMessage;
fasMessageArrowDown = fasMessageArrowDown;
fasMessageArrowUp = fasMessageArrowUp;
fasMessageArrowUpRight = fasMessageArrowUpRight;
fasMessageBot = fasMessageBot;
fasMessageCaptions = fasMessageCaptions;
fasMessageCheck = fasMessageCheck;
fasMessageCode = fasMessageCode;
fasMessageDollar = fasMessageDollar;
fasMessageDots = fasMessageDots;
fasMessageEdit = fasMessageEdit;
fasMessageExclamation = fasMessageExclamation;
fasMessageImage = fasMessageImage;
fasMessageLines = fasMessageLines;
fasMessageMedical = fasMessageMedical;
fasMessageMiddle = fasMessageMiddle;
fasMessageMiddleTop = fasMessageMiddleTop;
fasMessageMinus = fasMessageMinus;
fasMessageMusic = fasMessageMusic;
fasMessagePen = fasMessagePen;
fasMessagePlus = fasMessagePlus;
fasMessageQuestion = fasMessageQuestion;
fasMessageQuote = fasMessageQuote;
fasMessages = fasMessages;
fasMessagesDollar = fasMessagesDollar;
fasMessageSlash = fasMessageSlash;
fasMessageSmile = fasMessageSmile;
fasMessageSms = fasMessageSms;
fasMessagesQuestion = fasMessagesQuestion;
fasMessageText = fasMessageText;
fasMessageTimes = fasMessageTimes;
fasMessageXmark = fasMessageXmark;
fasMessaging = fasMessaging;
fasMeteor = fasMeteor;
fasMeter = fasMeter;
fasMeterBolt = fasMeterBolt;
fasMeterDroplet = fasMeterDroplet;
fasMeterFire = fasMeterFire;
fasMicrochip = fasMicrochip;
fasMicrochipAi = fasMicrochipAi;
fasMicrophone = fasMicrophone;
fasMicrophoneAlt = fasMicrophoneAlt;
fasMicrophoneAltSlash = fasMicrophoneAltSlash;
fasMicrophoneCircle = fasMicrophoneCircle;
fasMicrophoneCircleAlt = fasMicrophoneCircleAlt;
fasMicrophoneLines = fasMicrophoneLines;
fasMicrophoneLinesSlash = fasMicrophoneLinesSlash;
fasMicrophoneSlash = fasMicrophoneSlash;
fasMicrophoneStand = fasMicrophoneStand;
fasMicroscope = fasMicroscope;
fasMicrowave = fasMicrowave;
fasMillSign = fasMillSign;
fasMindShare = fasMindShare;
fasMinimize = fasMinimize;
fasMinus = fasMinus;
fasMinusCircle = fasMinusCircle;
fasMinusHexagon = fasMinusHexagon;
fasMinusOctagon = fasMinusOctagon;
fasMinusSquare = fasMinusSquare;
fasMistletoe = fasMistletoe;
fasMitten = fasMitten;
fasMobile = fasMobile;
fasMobileAlt = fasMobileAlt;
fasMobileAndroid = fasMobileAndroid;
fasMobileAndroidAlt = fasMobileAndroidAlt;
fasMobileButton = fasMobileButton;
fasMobileIphone = fasMobileIphone;
fasMobileNotch = fasMobileNotch;
fasMobilePhone = fasMobilePhone;
fasMobileRetro = fasMobileRetro;
fasMobileScreen = fasMobileScreen;
fasMobileScreenButton = fasMobileScreenButton;
fasMobileSignal = fasMobileSignal;
fasMobileSignalOut = fasMobileSignalOut;
fasMoneyBill = fasMoneyBill;
fasMoneyBill1 = fasMoneyBill1;
fasMoneyBill1Wave = fasMoneyBill1Wave;
fasMoneyBillAlt = fasMoneyBillAlt;
fasMoneyBills = fasMoneyBills;
fasMoneyBillsAlt = fasMoneyBillsAlt;
fasMoneyBillSimple = fasMoneyBillSimple;
fasMoneyBillSimpleWave = fasMoneyBillSimpleWave;
fasMoneyBillsSimple = fasMoneyBillsSimple;
fasMoneyBillTransfer = fasMoneyBillTransfer;
fasMoneyBillTrendUp = fasMoneyBillTrendUp;
fasMoneyBillWave = fasMoneyBillWave;
fasMoneyBillWaveAlt = fasMoneyBillWaveAlt;
fasMoneyBillWheat = fasMoneyBillWheat;
fasMoneyCheck = fasMoneyCheck;
fasMoneyCheckAlt = fasMoneyCheckAlt;
fasMoneyCheckDollar = fasMoneyCheckDollar;
fasMoneyCheckDollarPen = fasMoneyCheckDollarPen;
fasMoneyCheckEdit = fasMoneyCheckEdit;
fasMoneyCheckEditAlt = fasMoneyCheckEditAlt;
fasMoneyCheckPen = fasMoneyCheckPen;
fasMoneyFromBracket = fasMoneyFromBracket;
fasMoneySimpleFromBracket = fasMoneySimpleFromBracket;
fasMonitorHeartRate = fasMonitorHeartRate;
fasMonitorWaveform = fasMonitorWaveform;
fasMonkey = fasMonkey;
fasMonument = fasMonument;
fasMoon = fasMoon;
fasMoonCloud = fasMoonCloud;
fasMoonOverSun = fasMoonOverSun;
fasMoonStars = fasMoonStars;
fasMoped = fasMoped;
fasMortarBoard = fasMortarBoard;
fasMortarPestle = fasMortarPestle;
fasMosque = fasMosque;
fasMosquito = fasMosquito;
fasMosquitoNet = fasMosquitoNet;
fasMotorcycle = fasMotorcycle;
fasMound = fasMound;
fasMountain = fasMountain;
fasMountainCity = fasMountainCity;
fasMountains = fasMountains;
fasMountainSun = fasMountainSun;
fasMouse = fasMouse;
fasMouseAlt = fasMouseAlt;
fasMousePointer = fasMousePointer;
fasMp3Player = fasMp3Player;
fasMug = fasMug;
fasMugHot = fasMugHot;
fasMugMarshmallows = fasMugMarshmallows;
fasMugSaucer = fasMugSaucer;
fasMugTea = fasMugTea;
fasMugTeaSaucer = fasMugTeaSaucer;
fasMultiply = fasMultiply;
fasMuseum = fasMuseum;
fasMushroom = fasMushroom;
fasMusic = fasMusic;
fasMusicAlt = fasMusicAlt;
fasMusicAltSlash = fasMusicAltSlash;
fasMusicNote = fasMusicNote;
fasMusicNoteSlash = fasMusicNoteSlash;
fasMusicSlash = fasMusicSlash;
fasN = fasN;
fasNairaSign = fasNairaSign;
fasNarwhal = fasNarwhal;
fasNavicon = fasNavicon;
fasNestingDolls = fasNestingDolls;
fasNetworkWired = fasNetworkWired;
fasNeuter = fasNeuter;
fasNewspaper = fasNewspaper;
fasNfc = fasNfc;
fasNfcLock = fasNfcLock;
fasNfcMagnifyingGlass = fasNfcMagnifyingGlass;
fasNfcPen = fasNfcPen;
fasNfcSignal = fasNfcSignal;
fasNfcSlash = fasNfcSlash;
fasNfcSymbol = fasNfcSymbol;
fasNfcTrash = fasNfcTrash;
fasNigiri = fasNigiri;
fasNotdef = fasNotdef;
fasNote = fasNote;
fasNotebook = fasNotebook;
fasNoteMedical = fasNoteMedical;
fasNotEqual = fasNotEqual;
fasNotes = fasNotes;
fasNotesMedical = fasNotesMedical;
fasNoteSticky = fasNoteSticky;
fasO = fasO;
fasObjectExclude = fasObjectExclude;
fasObjectGroup = fasObjectGroup;
fasObjectIntersect = fasObjectIntersect;
fasObjectsAlignBottom = fasObjectsAlignBottom;
fasObjectsAlignCenterHorizontal = fasObjectsAlignCenterHorizontal;
fasObjectsAlignCenterVertical = fasObjectsAlignCenterVertical;
fasObjectsAlignLeft = fasObjectsAlignLeft;
fasObjectsAlignRight = fasObjectsAlignRight;
fasObjectsAlignTop = fasObjectsAlignTop;
fasObjectsColumn = fasObjectsColumn;
fasObjectSubtract = fasObjectSubtract;
fasObjectUngroup = fasObjectUngroup;
fasObjectUnion = fasObjectUnion;
fasOctagon = fasOctagon;
fasOctagonCheck = fasOctagonCheck;
fasOctagonDivide = fasOctagonDivide;
fasOctagonExclamation = fasOctagonExclamation;
fasOctagonMinus = fasOctagonMinus;
fasOctagonPlus = fasOctagonPlus;
fasOctagonXmark = fasOctagonXmark;
fasOilCan = fasOilCan;
fasOilCanDrip = fasOilCanDrip;
fasOilTemp = fasOilTemp;
fasOilTemperature = fasOilTemperature;
fasOilWell = fasOilWell;
fasOlive = fasOlive;
fasOliveBranch = fasOliveBranch;
fasOm = fasOm;
fasOmega = fasOmega;
fasOnion = fasOnion;
fasOption = fasOption;
fasOrnament = fasOrnament;
fasOtter = fasOtter;
fasOutdent = fasOutdent;
fasOutlet = fasOutlet;
fasOven = fasOven;
fasOverline = fasOverline;
fasP = fasP;
fasPage = fasPage;
fasPageBreak = fasPageBreak;
fasPageCaretDown = fasPageCaretDown;
fasPageCaretUp = fasPageCaretUp;
fasPager = fasPager;
fasPaintbrush = fasPaintbrush;
fasPaintBrush = fasPaintBrush;
fasPaintbrushAlt = fasPaintbrushAlt;
fasPaintBrushAlt = fasPaintBrushAlt;
fasPaintbrushFine = fasPaintbrushFine;
fasPaintBrushFine = fasPaintBrushFine;
fasPaintbrushPencil = fasPaintbrushPencil;
fasPaintRoller = fasPaintRoller;
fasPalette = fasPalette;
fasPaletteBoxes = fasPaletteBoxes;
fasPallet = fasPallet;
fasPalletAlt = fasPalletAlt;
fasPalletBox = fasPalletBox;
fasPalletBoxes = fasPalletBoxes;
fasPancakes = fasPancakes;
fasPanelEws = fasPanelEws;
fasPanelFire = fasPanelFire;
fasPanFood = fasPanFood;
fasPanFrying = fasPanFrying;
fasPanorama = fasPanorama;
fasPaperclip = fasPaperclip;
fasPaperclipVertical = fasPaperclipVertical;
fasPaperPlane = fasPaperPlane;
fasPaperPlaneAlt = fasPaperPlaneAlt;
fasPaperPlaneTop = fasPaperPlaneTop;
fasParachuteBox = fasParachuteBox;
fasParagraph = fasParagraph;
fasParagraphLeft = fasParagraphLeft;
fasParagraphRtl = fasParagraphRtl;
fasParentheses = fasParentheses;
fasParenthesis = fasParenthesis;
fasParking = fasParking;
fasParkingCircle = fasParkingCircle;
fasParkingCircleSlash = fasParkingCircleSlash;
fasParkingSlash = fasParkingSlash;
fasPartyBack = fasPartyBack;
fasPartyBell = fasPartyBell;
fasPartyHorn = fasPartyHorn;
fasPassport = fasPassport;
fasPastafasrianism = fasPastafasrianism;
fasPaste = fasPaste;
fasPause = fasPause;
fasPauseCircle = fasPauseCircle;
fasPaw = fasPaw;
fasPawAlt = fasPawAlt;
fasPawClaws = fasPawClaws;
fasPawSimple = fasPawSimple;
fasPeace = fasPeace;
fasPeach = fasPeach;
fasPeanut = fasPeanut;
fasPeanuts = fasPeanuts;
fasPeapod = fasPeapod;
fasPear = fasPear;
fasPedestal = fasPedestal;
fasPegasus = fasPegasus;
fasPen = fasPen;
fasPenAlt = fasPenAlt;
fasPenAltSlash = fasPenAltSlash;
fasPencil = fasPencil;
fasPencilAlt = fasPencilAlt;
fasPencilPaintbrush = fasPencilPaintbrush;
fasPencilRuler = fasPencilRuler;
fasPencilSlash = fasPencilSlash;
fasPencilSquare = fasPencilSquare;
fasPenCircle = fasPenCircle;
fasPenClip = fasPenClip;
fasPenClipSlash = fasPenClipSlash;
fasPenFancy = fasPenFancy;
fasPenFancySlash = fasPenFancySlash;
fasPenField = fasPenField;
fasPenLine = fasPenLine;
fasPennant = fasPennant;
fasPenNib = fasPenNib;
fasPenNibSlash = fasPenNibSlash;
fasPenPaintbrush = fasPenPaintbrush;
fasPenRuler = fasPenRuler;
fasPenSlash = fasPenSlash;
fasPenSquare = fasPenSquare;
fasPenSwirl = fasPenSwirl;
fasPenToSquare = fasPenToSquare;
fasPeople = fasPeople;
fasPeopleArrows = fasPeopleArrows;
fasPeopleArrowsLeftRight = fasPeopleArrowsLeftRight;
fasPeopleCarry = fasPeopleCarry;
fasPeopleCarryBox = fasPeopleCarryBox;
fasPeopleDress = fasPeopleDress;
fasPeopleDressSimple = fasPeopleDressSimple;
fasPeopleGroup = fasPeopleGroup;
fasPeopleLine = fasPeopleLine;
fasPeoplePants = fasPeoplePants;
fasPeoplePantsSimple = fasPeoplePantsSimple;
fasPeoplePulling = fasPeoplePulling;
fasPeopleRobbery = fasPeopleRobbery;
fasPeopleRoof = fasPeopleRoof;
fasPeopleSimple = fasPeopleSimple;
fasPepper = fasPepper;
fasPepperHot = fasPepperHot;
fasPercent = fasPercent;
fasPercentage = fasPercentage;
fasPeriod = fasPeriod;
fasPerson = fasPerson;
fasPersonArrowDownToLine = fasPersonArrowDownToLine;
fasPersonArrowUpFromLine = fasPersonArrowUpFromLine;
fasPersonBiking = fasPersonBiking;
fasPersonBikingMountain = fasPersonBikingMountain;
fasPersonBooth = fasPersonBooth;
fasPersonBreastfeeding = fasPersonBreastfeeding;
fasPersonBurst = fasPersonBurst;
fasPersonCane = fasPersonCane;
fasPersonCarry = fasPersonCarry;
fasPersonCarryBox = fasPersonCarryBox;
fasPersonChalkboard = fasPersonChalkboard;
fasPersonCircleCheck = fasPersonCircleCheck;
fasPersonCircleExclamation = fasPersonCircleExclamation;
fasPersonCircleMinus = fasPersonCircleMinus;
fasPersonCirclePlus = fasPersonCirclePlus;
fasPersonCircleQuestion = fasPersonCircleQuestion;
fasPersonCircleXmark = fasPersonCircleXmark;
fasPersonDigging = fasPersonDigging;
fasPersonDolly = fasPersonDolly;
fasPersonDollyEmpty = fasPersonDollyEmpty;
fasPersonDotsFromLine = fasPersonDotsFromLine;
fasPersonDress = fasPersonDress;
fasPersonDressBurst = fasPersonDressBurst;
fasPersonDressSimple = fasPersonDressSimple;
fasPersonDrowning = fasPersonDrowning;
fasPersonFalling = fasPersonFalling;
fasPersonFallingBurst = fasPersonFallingBurst;
fasPersonFromPortal = fasPersonFromPortal;
fasPersonHalfDress = fasPersonHalfDress;
fasPersonHarassing = fasPersonHarassing;
fasPersonHiking = fasPersonHiking;
fasPersonMilitaryPointing = fasPersonMilitaryPointing;
fasPersonMilitaryRifle = fasPersonMilitaryRifle;
fasPersonMilitaryToPerson = fasPersonMilitaryToPerson;
fasPersonPinball = fasPersonPinball;
fasPersonPraying = fasPersonPraying;
fasPersonPregnant = fasPersonPregnant;
fasPersonRays = fasPersonRays;
fasPersonRifle = fasPersonRifle;
fasPersonRunning = fasPersonRunning;
fasPersonSeat = fasPersonSeat;
fasPersonSeatReclined = fasPersonSeatReclined;
fasPersonShelter = fasPersonShelter;
fasPersonSign = fasPersonSign;
fasPersonSimple = fasPersonSimple;
fasPersonSkating = fasPersonSkating;
fasPersonSkiing = fasPersonSkiing;
fasPersonSkiingNordic = fasPersonSkiingNordic;
fasPersonSkiJumping = fasPersonSkiJumping;
fasPersonSkiLift = fasPersonSkiLift;
fasPersonSledding = fasPersonSledding;
fasPersonSnowboarding = fasPersonSnowboarding;
fasPersonSnowmobiling = fasPersonSnowmobiling;
fasPersonSwimming = fasPersonSwimming;
fasPersonThroughWindow = fasPersonThroughWindow;
fasPersonToDoor = fasPersonToDoor;
fasPersonToPortal = fasPersonToPortal;
fasPersonWalking = fasPersonWalking;
fasPersonWalkingArrowLoopLeft = fasPersonWalkingArrowLoopLeft;
fasPersonWalkingArrowRight = fasPersonWalkingArrowRight;
fasPersonWalkingDashedLineArrowRight = fasPersonWalkingDashedLineArrowRight;
fasPersonWalkingLuggage = fasPersonWalkingLuggage;
fasPersonWalkingWithCane = fasPersonWalkingWithCane;
fasPesetaSign = fasPesetaSign;
fasPesoSign = fasPesoSign;
fasPhone = fasPhone;
fasPhoneAlt = fasPhoneAlt;
fasPhoneArrowDown = fasPhoneArrowDown;
fasPhoneArrowDownLeft = fasPhoneArrowDownLeft;
fasPhoneArrowUp = fasPhoneArrowUp;
fasPhoneArrowUpRight = fasPhoneArrowUpRight;
fasPhoneCircle = fasPhoneCircle;
fasPhoneCircleAlt = fasPhoneCircleAlt;
fasPhoneCircleDown = fasPhoneCircleDown;
fasPhoneFlip = fasPhoneFlip;
fasPhoneHangup = fasPhoneHangup;
fasPhoneIncoming = fasPhoneIncoming;
fasPhoneIntercom = fasPhoneIntercom;
fasPhoneLaptop = fasPhoneLaptop;
fasPhoneMissed = fasPhoneMissed;
fasPhoneOffice = fasPhoneOffice;
fasPhoneOutgoing = fasPhoneOutgoing;
fasPhonePlus = fasPhonePlus;
fasPhoneRotary = fasPhoneRotary;
fasPhoneSlash = fasPhoneSlash;
fasPhoneSquare = fasPhoneSquare;
fasPhoneSquareAlt = fasPhoneSquareAlt;
fasPhoneSquareDown = fasPhoneSquareDown;
fasPhoneVolume = fasPhoneVolume;
fasPhoneXmark = fasPhoneXmark;
fasPhotoFilm = fasPhotoFilm;
fasPhotoFilmMusic = fasPhotoFilmMusic;
fasPhotoVideo = fasPhotoVideo;
fasPi = fasPi;
fasPiano = fasPiano;
fasPianoKeyboard = fasPianoKeyboard;
fasPickleball = fasPickleball;
fasPie = fasPie;
fasPieChart = fasPieChart;
fasPig = fasPig;
fasPiggyBank = fasPiggyBank;
fasPills = fasPills;
fasPinata = fasPinata;
fasPinball = fasPinball;
fasPineapple = fasPineapple;
fasPingPongPaddleBall = fasPingPongPaddleBall;
fasPipe = fasPipe;
fasPipeCircleCheck = fasPipeCircleCheck;
fasPipeCollar = fasPipeCollar;
fasPipeSection = fasPipeSection;
fasPipeSmoking = fasPipeSmoking;
fasPipeValve = fasPipeValve;
fasPizza = fasPizza;
fasPizzaSlice = fasPizzaSlice;
fasPlaceOfWorship = fasPlaceOfWorship;
fasPlane = fasPlane;
fasPlaneAlt = fasPlaneAlt;
fasPlaneArrival = fasPlaneArrival;
fasPlaneCircleCheck = fasPlaneCircleCheck;
fasPlaneCircleExclamation = fasPlaneCircleExclamation;
fasPlaneCircleXmark = fasPlaneCircleXmark;
fasPlaneDeparture = fasPlaneDeparture;
fasPlaneEngines = fasPlaneEngines;
fasPlaneLock = fasPlaneLock;
fasPlaneProp = fasPlaneProp;
fasPlaneSlash = fasPlaneSlash;
fasPlaneTail = fasPlaneTail;
fasPlanetMoon = fasPlanetMoon;
fasPlanetRinged = fasPlanetRinged;
fasPlaneUp = fasPlaneUp;
fasPlaneUpSlash = fasPlaneUpSlash;
fasPlantWilt = fasPlantWilt;
fasPlateUtensils = fasPlateUtensils;
fasPlateWheat = fasPlateWheat;
fasPlay = fasPlay;
fasPlayCircle = fasPlayCircle;
fasPlayPause = fasPlayPause;
fasPlug = fasPlug;
fasPlugCircleBolt = fasPlugCircleBolt;
fasPlugCircleCheck = fasPlugCircleCheck;
fasPlugCircleExclamation = fasPlugCircleExclamation;
fasPlugCircleMinus = fasPlugCircleMinus;
fasPlugCirclePlus = fasPlugCirclePlus;
fasPlugCircleXmark = fasPlugCircleXmark;
fasPlus = fasPlus;
fasPlusCircle = fasPlusCircle;
fasPlusHexagon = fasPlusHexagon;
fasPlusMinus = fasPlusMinus;
fasPlusOctagon = fasPlusOctagon;
fasPlusSquare = fasPlusSquare;
fasPodcast = fasPodcast;
fasPodium = fasPodium;
fasPodiumStar = fasPodiumStar;
fasPoliceBox = fasPoliceBox;
fasPoll = fasPoll;
fasPollH = fasPollH;
fasPollPeople = fasPollPeople;
fasPompebled = fasPompebled;
fasPoo = fasPoo;
fasPooBolt = fasPooBolt;
fasPool8Ball = fasPool8Ball;
fasPoop = fasPoop;
fasPooStorm = fasPooStorm;
fasPopcorn = fasPopcorn;
fasPopsicle = fasPopsicle;
fasPortalEnter = fasPortalEnter;
fasPortalExit = fasPortalExit;
fasPortrait = fasPortrait;
fasPotato = fasPotato;
fasPotFood = fasPotFood;
fasPoundSign = fasPoundSign;
fasPowerOff = fasPowerOff;
fasPray = fasPray;
fasPrayingHands = fasPrayingHands;
fasPrescription = fasPrescription;
fasPrescriptionBottle = fasPrescriptionBottle;
fasPrescriptionBottleAlt = fasPrescriptionBottleAlt;
fasPrescriptionBottleMedical = fasPrescriptionBottleMedical;
fasPresentation = fasPresentation;
fasPresentationScreen = fasPresentationScreen;
fasPretzel = fasPretzel;
fasPrint = fasPrint;
fasPrintMagnifyingGlass = fasPrintMagnifyingGlass;
fasPrintSearch = fasPrintSearch;
fasPrintSlash = fasPrintSlash;
fasPro = fasPro;
fasProcedures = fasProcedures;
fasProjectDiagram = fasProjectDiagram;
fasProjector = fasProjector;
fasPump = fasPump;
fasPumpkin = fasPumpkin;
fasPumpMedical = fasPumpMedical;
fasPumpSoap = fasPumpSoap;
fasPuzzle = fasPuzzle;
fasPuzzlePiece = fasPuzzlePiece;
fasPuzzlePieceAlt = fasPuzzlePieceAlt;
fasPuzzlePieceSimple = fasPuzzlePieceSimple;
fasQ = fasQ;
fasQrcode = fasQrcode;
fasQuestion = fasQuestion;
fasQuestionCircle = fasQuestionCircle;
fasQuestionSquare = fasQuestionSquare;
fasQuidditch = fasQuidditch;
fasQuidditchBroomBall = fasQuidditchBroomBall;
fasQuoteLeft = fasQuoteLeft;
fasQuoteLeftAlt = fasQuoteLeftAlt;
fasQuoteRight = fasQuoteRight;
fasQuoteRightAlt = fasQuoteRightAlt;
fasQuotes = fasQuotes;
fasQuran = fasQuran;
fasR = fasR;
fasRabbit = fasRabbit;
fasRabbitFast = fasRabbitFast;
fasRabbitRunning = fasRabbitRunning;
fasRacquet = fasRacquet;
fasRadar = fasRadar;
fasRadiation = fasRadiation;
fasRadiationAlt = fasRadiationAlt;
fasRadio = fasRadio;
fasRadioAlt = fasRadioAlt;
fasRadioTuner = fasRadioTuner;
fasRainbow = fasRainbow;
fasRaindrops = fasRaindrops;
fasRam = fasRam;
fasRampLoading = fasRampLoading;
fasRandom = fasRandom;
fasRankingStar = fasRankingStar;
fasRaygun = fasRaygun;
fasReceipt = fasReceipt;
fasRecordVinyl = fasRecordVinyl;
fasRectangle = fasRectangle;
fasRectangleAd = fasRectangleAd;
fasRectangleBarcode = fasRectangleBarcode;
fasRectangleCode = fasRectangleCode;
fasRectangleHd = fasRectangleHd;
fasRectangleHistory = fasRectangleHistory;
fasRectangleHistoryCirclePlus = fasRectangleHistoryCirclePlus;
fasRectangleHistoryCircleUser = fasRectangleHistoryCircleUser;
fasRectangleLandscape = fasRectangleLandscape;
fasRectangleList = fasRectangleList;
fasRectanglePortrait = fasRectanglePortrait;
fasRectanglePro = fasRectanglePro;
fasRectangleSd = fasRectangleSd;
fasRectanglesMixed = fasRectanglesMixed;
fasRectangleTerminal = fasRectangleTerminal;
fasRectangleTimes = fasRectangleTimes;
fasRectangleVertical = fasRectangleVertical;
fasRectangleVerticalHistory = fasRectangleVerticalHistory;
fasRectangleWide = fasRectangleWide;
fasRectangleXmark = fasRectangleXmark;
fasRecycle = fasRecycle;
fasRedo = fasRedo;
fasRedoAlt = fasRedoAlt;
fasReel = fasReel;
fasRefresh = fasRefresh;
fasRefrigerator = fasRefrigerator;
fasRegistered = fasRegistered;
fasRemove = fasRemove;
fasRemoveFormat = fasRemoveFormat;
fasReorder = fasReorder;
fasRepeat = fasRepeat;
fasRepeat1 = fasRepeat1;
fasRepeat1Alt = fasRepeat1Alt;
fasRepeatAlt = fasRepeatAlt;
fasReply = fasReply;
fasReplyAll = fasReplyAll;
fasReplyClock = fasReplyClock;
fasReplyTime = fasReplyTime;
fasRepublican = fasRepublican;
fasRestroom = fasRestroom;
fasRestroomSimple = fasRestroomSimple;
fasRetweet = fasRetweet;
fasRetweetAlt = fasRetweetAlt;
fasRhombus = fasRhombus;
fasRibbon = fasRibbon;
fasRight = fasRight;
fasRightFromBracket = fasRightFromBracket;
fasRightFromLine = fasRightFromLine;
fasRightLeft = fasRightLeft;
fasRightLong = fasRightLong;
fasRightLongToLine = fasRightLongToLine;
fasRightToBracket = fasRightToBracket;
fasRightToLine = fasRightToLine;
fasRing = fasRing;
fasRingsWedding = fasRingsWedding;
fasRmb = fasRmb;
fasRoad = fasRoad;
fasRoadBarrier = fasRoadBarrier;
fasRoadBridge = fasRoadBridge;
fasRoadCircleCheck = fasRoadCircleCheck;
fasRoadCircleExclamation = fasRoadCircleExclamation;
fasRoadCircleXmark = fasRoadCircleXmark;
fasRoadLock = fasRoadLock;
fasRoadSpikes = fasRoadSpikes;
fasRobot = fasRobot;
fasRobotAstromech = fasRobotAstromech;
fasRocket = fasRocket;
fasRocketLaunch = fasRocketLaunch;
fasRodAsclepius = fasRodAsclepius;
fasRodSnake = fasRodSnake;
fasRollerCoaster = fasRollerCoaster;
fasRotate = fasRotate;
fasRotateBack = fasRotateBack;
fasRotateBackward = fasRotateBackward;
fasRotateExclamation = fasRotateExclamation;
fasRotateForward = fasRotateForward;
fasRotateLeft = fasRotateLeft;
fasRotateRight = fasRotateRight;
fasRouble = fasRouble;
fasRoute = fasRoute;
fasRouteHighway = fasRouteHighway;
fasRouteInterstate = fasRouteInterstate;
fasRouter = fasRouter;
fasRows = fasRows;
fasRss = fasRss;
fasRssSquare = fasRssSquare;
fasRub = fasRub;
fasRuble = fasRuble;
fasRubleSign = fasRubleSign;
fasRug = fasRug;
fasRugbyBall = fasRugbyBall;
fasRuler = fasRuler;
fasRulerCombined = fasRulerCombined;
fasRulerHorizontal = fasRulerHorizontal;
fasRulerTriangle = fasRulerTriangle;
fasRulerVertical = fasRulerVertical;
fasRunning = fasRunning;
fasRupee = fasRupee;
fasRupeeSign = fasRupeeSign;
fasRupiahSign = fasRupiahSign;
fasRv = fasRv;
fasS = fasS;
fasSack = fasSack;
fasSackDollar = fasSackDollar;
fasSackXmark = fasSackXmark;
fasSadCry = fasSadCry;
fasSadTear = fasSadTear;
fasSailboat = fasSailboat;
fasSalad = fasSalad;
fasSaltShaker = fasSaltShaker;
fasSandwich = fasSandwich;
fasSatellite = fasSatellite;
fasSatelliteDish = fasSatelliteDish;
fasSausage = fasSausage;
fasSave = fasSave;
fasSaveCircleArrowRight = fasSaveCircleArrowRight;
fasSaveCircleXmark = fasSaveCircleXmark;
fasSaveTimes = fasSaveTimes;
fasSaxHot = fasSaxHot;
fasSaxophone = fasSaxophone;
fasSaxophoneFire = fasSaxophoneFire;
fasScaleBalanced = fasScaleBalanced;
fasScaleUnbalanced = fasScaleUnbalanced;
fasScaleUnbalancedFlip = fasScaleUnbalancedFlip;
fasScalpel = fasScalpel;
fasScalpelLineDashed = fasScalpelLineDashed;
fasScalpelPath = fasScalpelPath;
fasScanner = fasScanner;
fasScannerGun = fasScannerGun;
fasScannerImage = fasScannerImage;
fasScannerKeyboard = fasScannerKeyboard;
fasScannerTouchscreen = fasScannerTouchscreen;
fasScarecrow = fasScarecrow;
fasScarf = fasScarf;
fasSchool = fasSchool;
fasSchoolCircleCheck = fasSchoolCircleCheck;
fasSchoolCircleExclamation = fasSchoolCircleExclamation;
fasSchoolCircleXmark = fasSchoolCircleXmark;
fasSchoolFlag = fasSchoolFlag;
fasSchoolLock = fasSchoolLock;
fasScissors = fasScissors;
fasScreencast = fasScreencast;
fasScreenshot = fasScreenshot;
fasScreenUsers = fasScreenUsers;
fasScrewdriver = fasScrewdriver;
fasScrewdriverWrench = fasScrewdriverWrench;
fasScribble = fasScribble;
fasScroll = fasScroll;
fasScrollOld = fasScrollOld;
fasScrollRibbon = fasScrollRibbon;
fasScrollTorah = fasScrollTorah;
fasScrubber = fasScrubber;
fasScythe = fasScythe;
fasSdCard = fasSdCard;
fasSdCards = fasSdCards;
fasSeal = fasSeal;
fasSealExclamation = fasSealExclamation;
fasSealQuestion = fasSealQuestion;
fasSearch = fasSearch;
fasSearchDollar = fasSearchDollar;
fasSearchLocation = fasSearchLocation;
fasSearchMinus = fasSearchMinus;
fasSearchPlus = fasSearchPlus;
fasSeatAirline = fasSeatAirline;
fasSection = fasSection;
fasSeedling = fasSeedling;
fasSemicolon = fasSemicolon;
fasSend = fasSend;
fasSendBack = fasSendBack;
fasSendBackward = fasSendBackward;
fasSensor = fasSensor;
fasSensorAlert = fasSensorAlert;
fasSensorCloud = fasSensorCloud;
fasSensorFire = fasSensorFire;
fasSensorOn = fasSensorOn;
fasSensorSmoke = fasSensorSmoke;
fasSensorTriangleExclamation = fasSensorTriangleExclamation;
fasServer = fasServer;
fasShapes = fasShapes;
fasShare = fasShare;
fasShareAll = fasShareAll;
fasShareAlt = fasShareAlt;
fasShareAltSquare = fasShareAltSquare;
fasShareFromSquare = fasShareFromSquare;
fasShareNodes = fasShareNodes;
fasShareSquare = fasShareSquare;
fasSheep = fasSheep;
fasSheetPlastic = fasSheetPlastic;
fasShekel = fasShekel;
fasShekelSign = fasShekelSign;
fasShelves = fasShelves;
fasShelvesEmpty = fasShelvesEmpty;
fasSheqel = fasSheqel;
fasSheqelSign = fasSheqelSign;
fasShield = fasShield;
fasShieldAlt = fasShieldAlt;
fasShieldBlank = fasShieldBlank;
fasShieldCat = fasShieldCat;
fasShieldCheck = fasShieldCheck;
fasShieldCross = fasShieldCross;
fasShieldDog = fasShieldDog;
fasShieldExclamation = fasShieldExclamation;
fasShieldHalved = fasShieldHalved;
fasShieldHeart = fasShieldHeart;
fasShieldKeyhole = fasShieldKeyhole;
fasShieldMinus = fasShieldMinus;
fasShieldPlus = fasShieldPlus;
fasShieldQuartered = fasShieldQuartered;
fasShieldSlash = fasShieldSlash;
fasShieldTimes = fasShieldTimes;
fasShieldVirus = fasShieldVirus;
fasShieldXmark = fasShieldXmark;
fasShip = fasShip;
fasShippingFast = fasShippingFast;
fasShippingTimed = fasShippingTimed;
fasShirt = fasShirt;
fasShirtLongSleeve = fasShirtLongSleeve;
fasShirtRunning = fasShirtRunning;
fasShirtTankTop = fasShirtTankTop;
fasShishKebab = fasShishKebab;
fasShoePrints = fasShoePrints;
fasShop = fasShop;
fasShopLock = fasShopLock;
fasShoppingBag = fasShoppingBag;
fasShoppingBasket = fasShoppingBasket;
fasShoppingBasketAlt = fasShoppingBasketAlt;
fasShoppingCart = fasShoppingCart;
fasShopSlash = fasShopSlash;
fasShortcake = fasShortcake;
fasShovel = fasShovel;
fasShovelSnow = fasShovelSnow;
fasShower = fasShower;
fasShowerAlt = fasShowerAlt;
fasShowerDown = fasShowerDown;
fasShredder = fasShredder;
fasShrimp = fasShrimp;
fasShuffle = fasShuffle;
fasShutters = fasShutters;
fasShuttlecock = fasShuttlecock;
fasShuttleSpace = fasShuttleSpace;
fasShuttleVan = fasShuttleVan;
fasSickle = fasSickle;
fasSidebar = fasSidebar;
fasSidebarFlip = fasSidebarFlip;
fasSigma = fasSigma;
fasSign = fasSign;
fasSignal = fasSignal;
fasSignal1 = fasSignal1;
fasSignal2 = fasSignal2;
fasSignal3 = fasSignal3;
fasSignal4 = fasSignal4;
fasSignal5 = fasSignal5;
fasSignalAlt = fasSignalAlt;
fasSignalAlt1 = fasSignalAlt1;
fasSignalAlt2 = fasSignalAlt2;
fasSignalAlt3 = fasSignalAlt3;
fasSignalAlt4 = fasSignalAlt4;
fasSignalAltSlash = fasSignalAltSlash;
fasSignalBars = fasSignalBars;
fasSignalBarsFair = fasSignalBarsFair;
fasSignalBarsGood = fasSignalBarsGood;
fasSignalBarsSlash = fasSignalBarsSlash;
fasSignalBarsStrong = fasSignalBarsStrong;
fasSignalBarsWeak = fasSignalBarsWeak;
fasSignalFair = fasSignalFair;
fasSignalGood = fasSignalGood;
fasSignalPerfect = fasSignalPerfect;
fasSignalSlash = fasSignalSlash;
fasSignalStream = fasSignalStream;
fasSignalStreamSlash = fasSignalStreamSlash;
fasSignalStrong = fasSignalStrong;
fasSignalWeak = fasSignalWeak;
fasSignature = fasSignature;
fasSignatureLock = fasSignatureLock;
fasSignatureSlash = fasSignatureSlash;
fasSignHanging = fasSignHanging;
fasSignIn = fasSignIn;
fasSignInAlt = fasSignInAlt;
fasSigning = fasSigning;
fasSignLanguage = fasSignLanguage;
fasSignOut = fasSignOut;
fasSignOutAlt = fasSignOutAlt;
fasSignsPost = fasSignsPost;
fasSimCard = fasSimCard;
fasSimCards = fasSimCards;
fasSink = fasSink;
fasSiren = fasSiren;
fasSirenOn = fasSirenOn;
fasSitemap = fasSitemap;
fasSkating = fasSkating;
fasSkeleton = fasSkeleton;
fasSkiBoot = fasSkiBoot;
fasSkiBootSki = fasSkiBootSki;
fasSkiing = fasSkiing;
fasSkiingNordic = fasSkiingNordic;
fasSkiJump = fasSkiJump;
fasSkiLift = fasSkiLift;
fasSkull = fasSkull;
fasSkullCow = fasSkullCow;
fasSkullCrossbones = fasSkullCrossbones;
fasSlash = fasSlash;
fasSlashBack = fasSlashBack;
fasSlashForward = fasSlashForward;
fasSledding = fasSledding;
fasSleigh = fasSleigh;
fasSlider = fasSlider;
fasSliders = fasSliders;
fasSlidersH = fasSlidersH;
fasSlidersHSquare = fasSlidersHSquare;
fasSlidersSimple = fasSlidersSimple;
fasSlidersUp = fasSlidersUp;
fasSlidersV = fasSlidersV;
fasSlidersVSquare = fasSlidersVSquare;
fasSlotMachine = fasSlotMachine;
fasSmile = fasSmile;
fasSmileBeam = fasSmileBeam;
fasSmilePlus = fasSmilePlus;
fasSmileWink = fasSmileWink;
fasSmog = fasSmog;
fasSmoke = fasSmoke;
fasSmoking = fasSmoking;
fasSmokingBan = fasSmokingBan;
fasSms = fasSms;
fasSnake = fasSnake;
fasSnooze = fasSnooze;
fasSnowBlowing = fasSnowBlowing;
fasSnowboarding = fasSnowboarding;
fasSnowflake = fasSnowflake;
fasSnowflakes = fasSnowflakes;
fasSnowman = fasSnowman;
fasSnowmanHead = fasSnowmanHead;
fasSnowmobile = fasSnowmobile;
fasSnowplow = fasSnowplow;
fasSoap = fasSoap;
fasSoccerBall = fasSoccerBall;
fasSocks = fasSocks;
fasSoftServe = fasSoftServe;
fasSolarPanel = fasSolarPanel;
fasSolarSystem = fasSolarSystem;
fasSort = fasSort;
fasSortAlphaAsc = fasSortAlphaAsc;
fasSortAlphaDesc = fasSortAlphaDesc;
fasSortAlphaDown = fasSortAlphaDown;
fasSortAlphaDownAlt = fasSortAlphaDownAlt;
fasSortAlphaUp = fasSortAlphaUp;
fasSortAlphaUpAlt = fasSortAlphaUpAlt;
fasSortAlt = fasSortAlt;
fasSortAmountAsc = fasSortAmountAsc;
fasSortAmountDesc = fasSortAmountDesc;
fasSortAmountDown = fasSortAmountDown;
fasSortAmountDownAlt = fasSortAmountDownAlt;
fasSortAmountUp = fasSortAmountUp;
fasSortAmountUpAlt = fasSortAmountUpAlt;
fasSortAsc = fasSortAsc;
fasSortCircle = fasSortCircle;
fasSortCircleDown = fasSortCircleDown;
fasSortCircleUp = fasSortCircleUp;
fasSortDesc = fasSortDesc;
fasSortDown = fasSortDown;
fasSortNumericAsc = fasSortNumericAsc;
fasSortNumericDesc = fasSortNumericDesc;
fasSortNumericDown = fasSortNumericDown;
fasSortNumericDownAlt = fasSortNumericDownAlt;
fasSortNumericUp = fasSortNumericUp;
fasSortNumericUpAlt = fasSortNumericUpAlt;
fasSortShapesDown = fasSortShapesDown;
fasSortShapesDownAlt = fasSortShapesDownAlt;
fasSortShapesUp = fasSortShapesUp;
fasSortShapesUpAlt = fasSortShapesUpAlt;
fasSortSizeDown = fasSortSizeDown;
fasSortSizeDownAlt = fasSortSizeDownAlt;
fasSortSizeUp = fasSortSizeUp;
fasSortSizeUpAlt = fasSortSizeUpAlt;
fasSortUp = fasSortUp;
fasSortUpDown = fasSortUpDown;
fasSoup = fasSoup;
fasSpa = fasSpa;
fasSpaceShuttle = fasSpaceShuttle;
fasSpaceStationMoon = fasSpaceStationMoon;
fasSpaceStationMoonAlt = fasSpaceStationMoonAlt;
fasSpaceStationMoonConstruction = fasSpaceStationMoonConstruction;
fasSpade = fasSpade;
fasSpaghettiMonsterFlying = fasSpaghettiMonsterFlying;
fasSparkles = fasSparkles;
fasSpeaker = fasSpeaker;
fasSpeakers = fasSpeakers;
fasSpellCheck = fasSpellCheck;
fasSpider = fasSpider;
fasSpiderBlackWidow = fasSpiderBlackWidow;
fasSpiderWeb = fasSpiderWeb;
fasSpinner = fasSpinner;
fasSpinnerThird = fasSpinnerThird;
fasSplit = fasSplit;
fasSplotch = fasSplotch;
fasSpoon = fasSpoon;
fasSportsball = fasSportsball;
fasSprayCan = fasSprayCan;
fasSprayCanSparkles = fasSprayCanSparkles;
fasSprinkler = fasSprinkler;
fasSprinklerCeiling = fasSprinklerCeiling;
fasSprout = fasSprout;
fasSquare = fasSquare;
fasSquare0 = fasSquare0;
fasSquare1 = fasSquare1;
fasSquare2 = fasSquare2;
fasSquare3 = fasSquare3;
fasSquare4 = fasSquare4;
fasSquare5 = fasSquare5;
fasSquare6 = fasSquare6;
fasSquare7 = fasSquare7;
fasSquare8 = fasSquare8;
fasSquare9 = fasSquare9;
fasSquareA = fasSquareA;
fasSquareALock = fasSquareALock;
fasSquareAmpersand = fasSquareAmpersand;
fasSquareArrowDown = fasSquareArrowDown;
fasSquareArrowDownLeft = fasSquareArrowDownLeft;
fasSquareArrowDownRight = fasSquareArrowDownRight;
fasSquareArrowLeft = fasSquareArrowLeft;
fasSquareArrowRight = fasSquareArrowRight;
fasSquareArrowUp = fasSquareArrowUp;
fasSquareArrowUpLeft = fasSquareArrowUpLeft;
fasSquareArrowUpRight = fasSquareArrowUpRight;
fasSquareB = fasSquareB;
fasSquareBolt = fasSquareBolt;
fasSquareC = fasSquareC;
fasSquareCaretDown = fasSquareCaretDown;
fasSquareCaretLeft = fasSquareCaretLeft;
fasSquareCaretRight = fasSquareCaretRight;
fasSquareCaretUp = fasSquareCaretUp;
fasSquareCheck = fasSquareCheck;
fasSquareChevronDown = fasSquareChevronDown;
fasSquareChevronLeft = fasSquareChevronLeft;
fasSquareChevronRight = fasSquareChevronRight;
fasSquareChevronUp = fasSquareChevronUp;
fasSquareCode = fasSquareCode;
fasSquareD = fasSquareD;
fasSquareDashed = fasSquareDashed;
fasSquareDivide = fasSquareDivide;
fasSquareDollar = fasSquareDollar;
fasSquareDown = fasSquareDown;
fasSquareDownLeft = fasSquareDownLeft;
fasSquareDownRight = fasSquareDownRight;
fasSquareE = fasSquareE;
fasSquareEllipsis = fasSquareEllipsis;
fasSquareEllipsisVertical = fasSquareEllipsisVertical;
fasSquareEnvelope = fasSquareEnvelope;
fasSquareExclamation = fasSquareExclamation;
fasSquareF = fasSquareF;
fasSquareFragile = fasSquareFragile;
fasSquareFull = fasSquareFull;
fasSquareG = fasSquareG;
fasSquareH = fasSquareH;
fasSquareHeart = fasSquareHeart;
fasSquareI = fasSquareI;
fasSquareInfo = fasSquareInfo;
fasSquareJ = fasSquareJ;
fasSquareK = fasSquareK;
fasSquareKanban = fasSquareKanban;
fasSquareL = fasSquareL;
fasSquareLeft = fasSquareLeft;
fasSquareList = fasSquareList;
fasSquareM = fasSquareM;
fasSquareMinus = fasSquareMinus;
fasSquareN = fasSquareN;
fasSquareNfi = fasSquareNfi;
fasSquareO = fasSquareO;
fasSquareP = fasSquareP;
fasSquareParking = fasSquareParking;
fasSquareParkingSlash = fasSquareParkingSlash;
fasSquarePen = fasSquarePen;
fasSquarePersonConfined = fasSquarePersonConfined;
fasSquarePhone = fasSquarePhone;
fasSquarePhoneFlip = fasSquarePhoneFlip;
fasSquarePhoneHangup = fasSquarePhoneHangup;
fasSquarePlus = fasSquarePlus;
fasSquarePollHorizontal = fasSquarePollHorizontal;
fasSquarePollVertical = fasSquarePollVertical;
fasSquareQ = fasSquareQ;
fasSquareQuarters = fasSquareQuarters;
fasSquareQuestion = fasSquareQuestion;
fasSquareQuote = fasSquareQuote;
fasSquareR = fasSquareR;
fasSquareRight = fasSquareRight;
fasSquareRing = fasSquareRing;
fasSquareRoot = fasSquareRoot;
fasSquareRootAlt = fasSquareRootAlt;
fasSquareRootVariable = fasSquareRootVariable;
fasSquareRss = fasSquareRss;
fasSquareS = fasSquareS;
fasSquareShareNodes = fasSquareShareNodes;
fasSquareSliders = fasSquareSliders;
fasSquareSlidersVertical = fasSquareSlidersVertical;
fasSquareSmall = fasSquareSmall;
fasSquareStar = fasSquareStar;
fasSquareT = fasSquareT;
fasSquareTerminal = fasSquareTerminal;
fasSquareThisWayUp = fasSquareThisWayUp;
fasSquareU = fasSquareU;
fasSquareUp = fasSquareUp;
fasSquareUpLeft = fasSquareUpLeft;
fasSquareUpRight = fasSquareUpRight;
fasSquareUser = fasSquareUser;
fasSquareV = fasSquareV;
fasSquareVirus = fasSquareVirus;
fasSquareW = fasSquareW;
fasSquareWineGlassCrack = fasSquareWineGlassCrack;
fasSquareX = fasSquareX;
fasSquareXmark = fasSquareXmark;
fasSquareY = fasSquareY;
fasSquareZ = fasSquareZ;
fasSquid = fasSquid;
fasSquirrel = fasSquirrel;
fasStaff = fasStaff;
fasStaffAesculapius = fasStaffAesculapius;
fasStaffSnake = fasStaffSnake;
fasStairs = fasStairs;
fasStamp = fasStamp;
fasStandardDefinition = fasStandardDefinition;
fasStar = fasStar;
fasStarAndCrescent = fasStarAndCrescent;
fasStarChristmas = fasStarChristmas;
fasStarCircle = fasStarCircle;
fasStarExclamation = fasStarExclamation;
fasStarfighter = fasStarfighter;
fasStarfighterAlt = fasStarfighterAlt;
fasStarfighterAltAdvanced = fasStarfighterAltAdvanced;
fasStarfighterTwinIonEngine = fasStarfighterTwinIonEngine;
fasStarfighterTwinIonEngineAdvanced = fasStarfighterTwinIonEngineAdvanced;
fasStarHalf = fasStarHalf;
fasStarHalfAlt = fasStarHalfAlt;
fasStarHalfStroke = fasStarHalfStroke;
fasStarOfDavid = fasStarOfDavid;
fasStarOfLife = fasStarOfLife;
fasStars = fasStars;
fasStarSharp = fasStarSharp;
fasStarSharpHalf = fasStarSharpHalf;
fasStarSharpHalfAlt = fasStarSharpHalfAlt;
fasStarSharpHalfStroke = fasStarSharpHalfStroke;
fasStarship = fasStarship;
fasStarshipFreighter = fasStarshipFreighter;
fasStarShooting = fasStarShooting;
fasSteak = fasSteak;
fasSteeringWheel = fasSteeringWheel;
fasStepBackward = fasStepBackward;
fasStepForward = fasStepForward;
fasSterlingSign = fasSterlingSign;
fasStethoscope = fasStethoscope;
fasStickyNote = fasStickyNote;
fasStocking = fasStocking;
fasStomach = fasStomach;
fasStop = fasStop;
fasStopCircle = fasStopCircle;
fasStopwatch = fasStopwatch;
fasStopwatch20 = fasStopwatch20;
fasStore = fasStore;
fasStoreAlt = fasStoreAlt;
fasStoreAltSlash = fasStoreAltSlash;
fasStoreLock = fasStoreLock;
fasStoreSlash = fasStoreSlash;
fasStrawberry = fasStrawberry;
fasStream = fasStream;
fasStreetView = fasStreetView;
fasStretcher = fasStretcher;
fasStrikethrough = fasStrikethrough;
fasStroopwafel = fasStroopwafel;
fasSubscript = fasSubscript;
fasSubtract = fasSubtract;
fasSubway = fasSubway;
fasSubwayTunnel = fasSubwayTunnel;
fasSuitcase = fasSuitcase;
fasSuitcaseMedical = fasSuitcaseMedical;
fasSuitcaseRolling = fasSuitcaseRolling;
fasSun = fasSun;
fasSunAlt = fasSunAlt;
fasSunBright = fasSunBright;
fasSunCloud = fasSunCloud;
fasSunDust = fasSunDust;
fasSunglasses = fasSunglasses;
fasSunHaze = fasSunHaze;
fasSunPlantWilt = fasSunPlantWilt;
fasSunrise = fasSunrise;
fasSunset = fasSunset;
fasSuperscript = fasSuperscript;
fasSurprise = fasSurprise;
fasSushi = fasSushi;
fasSushiRoll = fasSushiRoll;
fasSwatchbook = fasSwatchbook;
fasSwimmer = fasSwimmer;
fasSwimmingPool = fasSwimmingPool;
fasSword = fasSword;
fasSwordLaser = fasSwordLaser;
fasSwordLaserAlt = fasSwordLaserAlt;
fasSwords = fasSwords;
fasSwordsLaser = fasSwordsLaser;
fasSymbols = fasSymbols;
fasSynagogue = fasSynagogue;
fasSync = fasSync;
fasSyncAlt = fasSyncAlt;
fasSyringe = fasSyringe;
fasT = fasT;
fasTable = fasTable;
fasTableCells = fasTableCells;
fasTableCellsLarge = fasTableCellsLarge;
fasTableColumns = fasTableColumns;
fasTableLayout = fasTableLayout;
fasTableList = fasTableList;
fasTablePicnic = fasTablePicnic;
fasTablePivot = fasTablePivot;
fasTableRows = fasTableRows;
fasTablet = fasTablet;
fasTabletAlt = fasTabletAlt;
fasTabletAndroid = fasTabletAndroid;
fasTabletAndroidAlt = fasTabletAndroidAlt;
fasTabletButton = fasTabletButton;
fasTableTennis = fasTableTennis;
fasTableTennisPaddleBall = fasTableTennisPaddleBall;
fasTableTree = fasTableTree;
fasTabletRugged = fasTabletRugged;
fasTablets = fasTablets;
fasTabletScreen = fasTabletScreen;
fasTabletScreenButton = fasTabletScreenButton;
fasTachographDigital = fasTachographDigital;
fasTachometer = fasTachometer;
fasTachometerAlt = fasTachometerAlt;
fasTachometerAltAverage = fasTachometerAltAverage;
fasTachometerAltFast = fasTachometerAltFast;
fasTachometerAltFastest = fasTachometerAltFastest;
fasTachometerAltSlow = fasTachometerAltSlow;
fasTachometerAltSlowest = fasTachometerAltSlowest;
fasTachometerAverage = fasTachometerAverage;
fasTachometerFast = fasTachometerFast;
fasTachometerFastest = fasTachometerFastest;
fasTachometerSlow = fasTachometerSlow;
fasTachometerSlowest = fasTachometerSlowest;
fasTaco = fasTaco;
fasTag = fasTag;
fasTags = fasTags;
fasTally = fasTally;
fasTally1 = fasTally1;
fasTally2 = fasTally2;
fasTally3 = fasTally3;
fasTally4 = fasTally4;
fasTally5 = fasTally5;
fasTamale = fasTamale;
fasTanakh = fasTanakh;
fasTankWater = fasTankWater;
fasTape = fasTape;
fasTarp = fasTarp;
fasTarpDroplet = fasTarpDroplet;
fasTasks = fasTasks;
fasTasksAlt = fasTasksAlt;
fasTaxi = fasTaxi;
fasTaxiBus = fasTaxiBus;
fasTeddyBear = fasTeddyBear;
fasTeeth = fasTeeth;
fasTeethOpen = fasTeethOpen;
fasTelescope = fasTelescope;
fasTeletype = fasTeletype;
fasTeletypeAnswer = fasTeletypeAnswer;
fasTelevision = fasTelevision;
fasTemperature0 = fasTemperature0;
fasTemperature1 = fasTemperature1;
fasTemperature2 = fasTemperature2;
fasTemperature3 = fasTemperature3;
fasTemperature4 = fasTemperature4;
fasTemperatureArrowDown = fasTemperatureArrowDown;
fasTemperatureArrowUp = fasTemperatureArrowUp;
fasTemperatureDown = fasTemperatureDown;
fasTemperatureEmpty = fasTemperatureEmpty;
fasTemperatureFrigid = fasTemperatureFrigid;
fasTemperatureFull = fasTemperatureFull;
fasTemperatureHalf = fasTemperatureHalf;
fasTemperatureHigh = fasTemperatureHigh;
fasTemperatureHot = fasTemperatureHot;
fasTemperatureList = fasTemperatureList;
fasTemperatureLow = fasTemperatureLow;
fasTemperatureQuarter = fasTemperatureQuarter;
fasTemperatureSnow = fasTemperatureSnow;
fasTemperatureSun = fasTemperatureSun;
fasTemperatureThreeQuarters = fasTemperatureThreeQuarters;
fasTemperatureUp = fasTemperatureUp;
fasTenge = fasTenge;
fasTengeSign = fasTengeSign;
fasTennisBall = fasTennisBall;
fasTent = fasTent;
fasTentArrowDownToLine = fasTentArrowDownToLine;
fasTentArrowLeftRight = fasTentArrowLeftRight;
fasTentArrowsDown = fasTentArrowsDown;
fasTentArrowTurnLeft = fasTentArrowTurnLeft;
fasTents = fasTents;
fasTerminal = fasTerminal;
fasText = fasText;
fasTextHeight = fasTextHeight;
fasTextSize = fasTextSize;
fasTextSlash = fasTextSlash;
fasTextWidth = fasTextWidth;
fasTh = fasTh;
fasTheaterMasks = fasTheaterMasks;
fasThermometer = fasThermometer;
fasThermometer0 = fasThermometer0;
fasThermometer1 = fasThermometer1;
fasThermometer2 = fasThermometer2;
fasThermometer3 = fasThermometer3;
fasThermometer4 = fasThermometer4;
fasThermometerEmpty = fasThermometerEmpty;
fasThermometerFull = fasThermometerFull;
fasThermometerHalf = fasThermometerHalf;
fasThermometerQuarter = fasThermometerQuarter;
fasThermometerThreeQuarters = fasThermometerThreeQuarters;
fasTheta = fasTheta;
fasThLarge = fasThLarge;
fasThList = fasThList;
fasThoughtBubble = fasThoughtBubble;
fasThumbsDown = fasThumbsDown;
fasThumbsUp = fasThumbsUp;
fasThumbtack = fasThumbtack;
fasThumbTack = fasThumbTack;
fasThunderstorm = fasThunderstorm;
fasThunderstormMoon = fasThunderstormMoon;
fasThunderstormSun = fasThunderstormSun;
fasTick = fasTick;
fasTicket = fasTicket;
fasTicketAirline = fasTicketAirline;
fasTicketAlt = fasTicketAlt;
fasTicketsAirline = fasTicketsAirline;
fasTicketSimple = fasTicketSimple;
fasTilde = fasTilde;
fasTimeline = fasTimeline;
fasTimelineArrow = fasTimelineArrow;
fasTimer = fasTimer;
fasTimes = fasTimes;
fasTimesCircle = fasTimesCircle;
fasTimesHexagon = fasTimesHexagon;
fasTimesOctagon = fasTimesOctagon;
fasTimesRectangle = fasTimesRectangle;
fasTimesSquare = fasTimesSquare;
fasTimesToSlot = fasTimesToSlot;
fasTint = fasTint;
fasTintSlash = fasTintSlash;
fasTire = fasTire;
fasTired = fasTired;
fasTireFlat = fasTireFlat;
fasTirePressureWarning = fasTirePressureWarning;
fasTireRugged = fasTireRugged;
fasToggleOff = fasToggleOff;
fasToggleOn = fasToggleOn;
fasToilet = fasToilet;
fasToiletPaper = fasToiletPaper;
fasToiletPaperAlt = fasToiletPaperAlt;
fasToiletPaperBlank = fasToiletPaperBlank;
fasToiletPaperBlankUnder = fasToiletPaperBlankUnder;
fasToiletPaperReverse = fasToiletPaperReverse;
fasToiletPaperReverseAlt = fasToiletPaperReverseAlt;
fasToiletPaperReverseSlash = fasToiletPaperReverseSlash;
fasToiletPaperSlash = fasToiletPaperSlash;
fasToiletPaperUnder = fasToiletPaperUnder;
fasToiletPaperUnderSlash = fasToiletPaperUnderSlash;
fasToiletPortable = fasToiletPortable;
fasToiletsPortable = fasToiletsPortable;
fasTomato = fasTomato;
fasTombstone = fasTombstone;
fasTombstoneAlt = fasTombstoneAlt;
fasTombstoneBlank = fasTombstoneBlank;
fasToolbox = fasToolbox;
fasTools = fasTools;
fasTooth = fasTooth;
fasToothbrush = fasToothbrush;
fasTorah = fasTorah;
fasToriiGate = fasToriiGate;
fasTornado = fasTornado;
fasTowerBroadcast = fasTowerBroadcast;
fasTowerCell = fasTowerCell;
fasTowerControl = fasTowerControl;
fasTowerObservation = fasTowerObservation;
fasTractor = fasTractor;
fasTrademark = fasTrademark;
fasTrafficCone = fasTrafficCone;
fasTrafficLight = fasTrafficLight;
fasTrafficLightGo = fasTrafficLightGo;
fasTrafficLightSlow = fasTrafficLightSlow;
fasTrafficLightStop = fasTrafficLightStop;
fasTrailer = fasTrailer;
fasTrain = fasTrain;
fasTrainSubway = fasTrainSubway;
fasTrainSubwayTunnel = fasTrainSubwayTunnel;
fasTrainTrack = fasTrainTrack;
fasTrainTram = fasTrainTram;
fasTrainTunnel = fasTrainTunnel;
fasTram = fasTram;
fasTransformerBolt = fasTransformerBolt;
fasTransgender = fasTransgender;
fasTransgenderAlt = fasTransgenderAlt;
fasTransporter = fasTransporter;
fasTransporter1 = fasTransporter1;
fasTransporter2 = fasTransporter2;
fasTransporter3 = fasTransporter3;
fasTransporter4 = fasTransporter4;
fasTransporter5 = fasTransporter5;
fasTransporter6 = fasTransporter6;
fasTransporter7 = fasTransporter7;
fasTransporterEmpty = fasTransporterEmpty;
fasTrash = fasTrash;
fasTrashAlt = fasTrashAlt;
fasTrashAltSlash = fasTrashAltSlash;
fasTrashArrowTurnLeft = fasTrashArrowTurnLeft;
fasTrashArrowUp = fasTrashArrowUp;
fasTrashCan = fasTrashCan;
fasTrashCanArrowTurnLeft = fasTrashCanArrowTurnLeft;
fasTrashCanArrowUp = fasTrashCanArrowUp;
fasTrashCanCheck = fasTrashCanCheck;
fasTrashCanClock = fasTrashCanClock;
fasTrashCanList = fasTrashCanList;
fasTrashCanPlus = fasTrashCanPlus;
fasTrashCanSlash = fasTrashCanSlash;
fasTrashCanUndo = fasTrashCanUndo;
fasTrashCanXmark = fasTrashCanXmark;
fasTrashCheck = fasTrashCheck;
fasTrashCircle = fasTrashCircle;
fasTrashClock = fasTrashClock;
fasTrashList = fasTrashList;
fasTrashPlus = fasTrashPlus;
fasTrashRestore = fasTrashRestore;
fasTrashRestoreAlt = fasTrashRestoreAlt;
fasTrashSlash = fasTrashSlash;
fasTrashUndo = fasTrashUndo;
fasTrashUndoAlt = fasTrashUndoAlt;
fasTrashXmark = fasTrashXmark;
fasTreasureChest = fasTreasureChest;
fasTree = fasTree;
fasTreeAlt = fasTreeAlt;
fasTreeChristmas = fasTreeChristmas;
fasTreeCity = fasTreeCity;
fasTreeDeciduous = fasTreeDeciduous;
fasTreeDecorated = fasTreeDecorated;
fasTreeLarge = fasTreeLarge;
fasTreePalm = fasTreePalm;
fasTrees = fasTrees;
fasTrianBalbot = fasTrianBalbot;
fasTriangle = fasTriangle;
fasTriangleCircleSquare = fasTriangleCircleSquare;
fasTriangleExclamation = fasTriangleExclamation;
fasTriangleInstrument = fasTriangleInstrument;
fasTriangleMusic = fasTriangleMusic;
fasTrianglePersonDigging = fasTrianglePersonDigging;
fasTrillium = fasTrillium;
fasTrophy = fasTrophy;
fasTrophyAlt = fasTrophyAlt;
fasTrophyStar = fasTrophyStar;
fasTrowel = fasTrowel;
fasTrowelBricks = fasTrowelBricks;
fasTruck = fasTruck;
fasTruckArrowRight = fasTruckArrowRight;
fasTruckBolt = fasTruckBolt;
fasTruckClock = fasTruckClock;
fasTruckContainer = fasTruckContainer;
fasTruckContainerEmpty = fasTruckContainerEmpty;
fasTruckCouch = fasTruckCouch;
fasTruckDroplet = fasTruckDroplet;
fasTruckFast = fasTruckFast;
fasTruckField = fasTruckField;
fasTruckFieldUn = fasTruckFieldUn;
fasTruckFlatbed = fasTruckFlatbed;
fasTruckFront = fasTruckFront;
fasTruckLoading = fasTruckLoading;
fasTruckMedical = fasTruckMedical;
fasTruckMonster = fasTruckMonster;
fasTruckMoving = fasTruckMoving;
fasTruckPickup = fasTruckPickup;
fasTruckPlane = fasTruckPlane;
fasTruckPlow = fasTruckPlow;
fasTruckRamp = fasTruckRamp;
fasTruckRampBox = fasTruckRampBox;
fasTruckRampCouch = fasTruckRampCouch;
fasTruckTow = fasTruckTow;
fasTrumpet = fasTrumpet;
fasTry = fasTry;
fasTshirt = fasTshirt;
fasTShirt = fasTShirt;
fasTty = fasTty;
fasTtyAnswer = fasTtyAnswer;
fasTugrikSign = fasTugrikSign;
fasTurkey = fasTurkey;
fasTurkishLira = fasTurkishLira;
fasTurkishLiraSign = fasTurkishLiraSign;
fasTurnDown = fasTurnDown;
fasTurnDownLeft = fasTurnDownLeft;
fasTurnDownRight = fasTurnDownRight;
fasTurntable = fasTurntable;
fasTurnUp = fasTurnUp;
fasTurtle = fasTurtle;
fasTv = fasTv;
fasTvAlt = fasTvAlt;
fasTvMusic = fasTvMusic;
fasTvRetro = fasTvRetro;
fasTypewriter = fasTypewriter;
fasU = fasU;
fasUfo = fasUfo;
fasUfoBeam = fasUfoBeam;
fasUmbrella = fasUmbrella;
fasUmbrellaAlt = fasUmbrellaAlt;
fasUmbrellaBeach = fasUmbrellaBeach;
fasUmbrellaSimple = fasUmbrellaSimple;
fasUnderline = fasUnderline;
fasUndo = fasUndo;
fasUndoAlt = fasUndoAlt;
fasUnicorn = fasUnicorn;
fasUniformMartialArts = fasUniformMartialArts;
fasUnion = fasUnion;
fasUniversalAccess = fasUniversalAccess;
fasUniversity = fasUniversity;
fasUnlink = fasUnlink;
fasUnlock = fasUnlock;
fasUnlockAlt = fasUnlockAlt;
fasUnlockKeyhole = fasUnlockKeyhole;
fasUnsorted = fasUnsorted;
fasUp = fasUp;
fasUpDown = fasUpDown;
fasUpDownLeftRight = fasUpDownLeftRight;
fasUpFromBracket = fasUpFromBracket;
fasUpFromDottedLine = fasUpFromDottedLine;
fasUpFromLine = fasUpFromLine;
fasUpLeft = fasUpLeft;
fasUpload = fasUpload;
fasUpLong = fasUpLong;
fasUpRight = fasUpRight;
fasUpRightAndDownLeftFromCenter = fasUpRightAndDownLeftFromCenter;
fasUpRightFromSquare = fasUpRightFromSquare;
fasUpToDottedLine = fasUpToDottedLine;
fasUpToLine = fasUpToLine;
fasUsbDrive = fasUsbDrive;
fasUsd = fasUsd;
fasUsdCircle = fasUsdCircle;
fasUsdSquare = fasUsdSquare;
fasUser = fasUser;
fasUserAlien = fasUserAlien;
fasUserAlt = fasUserAlt;
fasUserAltSlash = fasUserAltSlash;
fasUserAstronaut = fasUserAstronaut;
fasUserBountyHunter = fasUserBountyHunter;
fasUserChart = fasUserChart;
fasUserCheck = fasUserCheck;
fasUserChef = fasUserChef;
fasUserCircle = fasUserCircle;
fasUserClock = fasUserClock;
fasUserCog = fasUserCog;
fasUserConstruction = fasUserConstruction;
fasUserCowboy = fasUserCowboy;
fasUserCrown = fasUserCrown;
fasUserDoctor = fasUserDoctor;
fasUserDoctorHair = fasUserDoctorHair;
fasUserDoctorHairLong = fasUserDoctorHairLong;
fasUserDoctorMessage = fasUserDoctorMessage;
fasUserEdit = fasUserEdit;
fasUserFriends = fasUserFriends;
fasUserGear = fasUserGear;
fasUserGraduate = fasUserGraduate;
fasUserGroup = fasUserGroup;
fasUserGroupCrown = fasUserGroupCrown;
fasUserHair = fasUserHair;
fasUserHairBuns = fasUserHairBuns;
fasUserHairLong = fasUserHairLong;
fasUserHairMullet = fasUserHairMullet;
fasUserHardHat = fasUserHardHat;
fasUserHeadset = fasUserHeadset;
fasUserHelmetSafety = fasUserHelmetSafety;
fasUserInjured = fasUserInjured;
fasUserLarge = fasUserLarge;
fasUserLargeSlash = fasUserLargeSlash;
fasUserLock = fasUserLock;
fasUserMd = fasUserMd;
fasUserMdChat = fasUserMdChat;
fasUserMinus = fasUserMinus;
fasUserMusic = fasUserMusic;
fasUserNinja = fasUserNinja;
fasUserNurse = fasUserNurse;
fasUserNurseHair = fasUserNurseHair;
fasUserNurseHairLong = fasUserNurseHairLong;
fasUserPen = fasUserPen;
fasUserPilot = fasUserPilot;
fasUserPilotTie = fasUserPilotTie;
fasUserPlus = fasUserPlus;
fasUserPolice = fasUserPolice;
fasUserPoliceTie = fasUserPoliceTie;
fasUserRobot = fasUserRobot;
fasUserRobotXmarks = fasUserRobotXmarks;
fasUsers = fasUsers;
fasUsersBetweenLines = fasUsersBetweenLines;
fasUsersClass = fasUsersClass;
fasUsersCog = fasUsersCog;
fasUsersCrown = fasUsersCrown;
fasUserSecret = fasUserSecret;
fasUsersGear = fasUsersGear;
fasUserShakespeare = fasUserShakespeare;
fasUserShield = fasUserShield;
fasUserSlash = fasUserSlash;
fasUsersLine = fasUsersLine;
fasUsersMedical = fasUsersMedical;
fasUsersRays = fasUsersRays;
fasUsersRectangle = fasUsersRectangle;
fasUsersSlash = fasUsersSlash;
fasUsersViewfinder = fasUsersViewfinder;
fasUserTag = fasUserTag;
fasUserTie = fasUserTie;
fasUserTieHair = fasUserTieHair;
fasUserTieHairLong = fasUserTieHairLong;
fasUserTimes = fasUserTimes;
fasUserUnlock = fasUserUnlock;
fasUserVisor = fasUserVisor;
fasUserVneck = fasUserVneck;
fasUserVneckHair = fasUserVneckHair;
fasUserVneckHairLong = fasUserVneckHairLong;
fasUserXmark = fasUserXmark;
fasUtensilFork = fasUtensilFork;
fasUtensilKnife = fasUtensilKnife;
fasUtensils = fasUtensils;
fasUtensilsAlt = fasUtensilsAlt;
fasUtensilSpoon = fasUtensilSpoon;
fasUtensilsSlash = fasUtensilsSlash;
fasUtilityPole = fasUtilityPole;
fasUtilityPoleDouble = fasUtilityPoleDouble;
fasV = fasV;
fasVacuum = fasVacuum;
fasVacuumRobot = fasVacuumRobot;
fasValueAbsolute = fasValueAbsolute;
fasVanShuttle = fasVanShuttle;
fasVault = fasVault;
fasVcard = fasVcard;
fasVectorCircle = fasVectorCircle;
fasVectorPolygon = fasVectorPolygon;
fasVectorSquare = fasVectorSquare;
fasVentDamper = fasVentDamper;
fasVenus = fasVenus;
fasVenusDouble = fasVenusDouble;
fasVenusMars = fasVenusMars;
fasVest = fasVest;
fasVestPatches = fasVestPatches;
fasVhs = fasVhs;
fasVial = fasVial;
fasVialCircleCheck = fasVialCircleCheck;
fasVials = fasVials;
fasVialVirus = fasVialVirus;
fasVideo = fasVideo;
fasVideoArrowDownLeft = fasVideoArrowDownLeft;
fasVideoArrowUpRight = fasVideoArrowUpRight;
fasVideoCamera = fasVideoCamera;
fasVideoCircle = fasVideoCircle;
fasVideoHandheld = fasVideoHandheld;
fasVideoPlus = fasVideoPlus;
fasVideoSlash = fasVideoSlash;
fasVihara = fasVihara;
fasViolin = fasViolin;
fasVirus = fasVirus;
fasVirusCovid = fasVirusCovid;
fasVirusCovidSlash = fasVirusCovidSlash;
fasViruses = fasViruses;
fasVirusSlash = fasVirusSlash;
fasVoicemail = fasVoicemail;
fasVolcano = fasVolcano;
fasVolleyball = fasVolleyball;
fasVolleyballBall = fasVolleyballBall;
fasVolume = fasVolume;
fasVolumeControlPhone = fasVolumeControlPhone;
fasVolumeDown = fasVolumeDown;
fasVolumeHigh = fasVolumeHigh;
fasVolumeLow = fasVolumeLow;
fasVolumeMedium = fasVolumeMedium;
fasVolumeMute = fasVolumeMute;
fasVolumeOff = fasVolumeOff;
fasVolumeSlash = fasVolumeSlash;
fasVolumeTimes = fasVolumeTimes;
fasVolumeUp = fasVolumeUp;
fasVolumeXmark = fasVolumeXmark;
fasVoteNay = fasVoteNay;
fasVoteYea = fasVoteYea;
fasVrCardboard = fasVrCardboard;
fasW = fasW;
fasWaffle = fasWaffle;
fasWagonCovered = fasWagonCovered;
fasWalker = fasWalker;
fasWalkieTalkie = fasWalkieTalkie;
fasWalking = fasWalking;
fasWallBrick = fasWallBrick;
fasWallet = fasWallet;
fasWand = fasWand;
fasWandMagic = fasWandMagic;
fasWandMagicSparkles = fasWandMagicSparkles;
fasWandSparkles = fasWandSparkles;
fasWarehouse = fasWarehouse;
fasWarehouseAlt = fasWarehouseAlt;
fasWarehouseFull = fasWarehouseFull;
fasWarning = fasWarning;
fasWasher = fasWasher;
fasWashingMachine = fasWashingMachine;
fasWatch = fasWatch;
fasWatchApple = fasWatchApple;
fasWatchCalculator = fasWatchCalculator;
fasWatchFitness = fasWatchFitness;
fasWatchSmart = fasWatchSmart;
fasWater = fasWater;
fasWaterArrowDown = fasWaterArrowDown;
fasWaterArrowUp = fasWaterArrowUp;
fasWaterLadder = fasWaterLadder;
fasWaterLower = fasWaterLower;
fasWatermelonSlice = fasWatermelonSlice;
fasWaterRise = fasWaterRise;
fasWaveform = fasWaveform;
fasWaveformCircle = fasWaveformCircle;
fasWaveformLines = fasWaveformLines;
fasWaveformPath = fasWaveformPath;
fasWavePulse = fasWavePulse;
fasWaveSine = fasWaveSine;
fasWaveSquare = fasWaveSquare;
fasWaveTriangle = fasWaveTriangle;
fasWebcam = fasWebcam;
fasWebcamSlash = fasWebcamSlash;
fasWeight = fasWeight;
fasWeightHanging = fasWeightHanging;
fasWeightScale = fasWeightScale;
fasWhale = fasWhale;
fasWheat = fasWheat;
fasWheatAlt = fasWheatAlt;
fasWheatAwn = fasWheatAwn;
fasWheatAwnCircleExclamation = fasWheatAwnCircleExclamation;
fasWheatAwnSlash = fasWheatAwnSlash;
fasWheatSlash = fasWheatSlash;
fasWheelchair = fasWheelchair;
fasWheelchairAlt = fasWheelchairAlt;
fasWheelchairMove = fasWheelchairMove;
fasWhiskeyGlass = fasWhiskeyGlass;
fasWhiskeyGlassIce = fasWhiskeyGlassIce;
fasWhistle = fasWhistle;
fasWifi = fasWifi;
fasWifi1 = fasWifi1;
fasWifi2 = fasWifi2;
fasWifi3 = fasWifi3;
fasWifiExclamation = fasWifiExclamation;
fasWifiFair = fasWifiFair;
fasWifiSlash = fasWifiSlash;
fasWifiStrong = fasWifiStrong;
fasWifiWeak = fasWifiWeak;
fasWind = fasWind;
fasWindCircleExclamation = fasWindCircleExclamation;
fasWindow = fasWindow;
fasWindowAlt = fasWindowAlt;
fasWindowClose = fasWindowClose;
fasWindowFlip = fasWindowFlip;
fasWindowFrame = fasWindowFrame;
fasWindowFrameOpen = fasWindowFrameOpen;
fasWindowMaximize = fasWindowMaximize;
fasWindowMinimize = fasWindowMinimize;
fasWindowRestore = fasWindowRestore;
fasWindsock = fasWindsock;
fasWindTurbine = fasWindTurbine;
fasWindWarning = fasWindWarning;
fasWineBottle = fasWineBottle;
fasWineGlass = fasWineGlass;
fasWineGlassAlt = fasWineGlassAlt;
fasWineGlassCrack = fasWineGlassCrack;
fasWineGlassEmpty = fasWineGlassEmpty;
fasWon = fasWon;
fasWonSign = fasWonSign;
fasWorm = fasWorm;
fasWreath = fasWreath;
fasWrench = fasWrench;
fasWrenchSimple = fasWrenchSimple;
fasX = fasX;
fasXmark = fasXmark;
fasXmarkCircle = fasXmarkCircle;
fasXmarkHexagon = fasXmarkHexagon;
fasXmarkLarge = fasXmarkLarge;
fasXmarkOctagon = fasXmarkOctagon;
fasXmarksLines = fasXmarksLines;
fasXmarkSquare = fasXmarkSquare;
fasXmarkToSlot = fasXmarkToSlot;
fasXRay = fasXRay;
fasY = fasY;
fasYen = fasYen;
fasYenSign = fasYenSign;
fasYinYang = fasYinYang;
fasZ = fasZ;
fasZap = fasZap;
fasZzz = fasZzz;

  }