import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  constructor(private router: Router) {
    router.events.subscribe((val) => {
      this.display_sub_page = '';
      const position = router.url.split('/').length - 1;
      this.page_header = router.url.split('/')[position].replace('-', ' ').toUpperCase();
      //this.page_header = router.url.split('/')[2].replace('-',' ').toUpperCase();

      switch (this.page_header) {
        case 'BILLING':
          this.page_icon = 'fa-solid fa-file-invoice'
          this.header_background = 'n-bg-billing'
          break;
        case 'HOME':
          this.page_icon = 'fa-solid fa-house'
          this.header_background = 'n-bg-white'
          break;
        case 'REQUESTS':
          this.page_icon = 'fa-solid fa-hand-point-up'
          this.header_background = 'n-bg-white'
          break;
        case 'TIMECARDS':
          this.page_icon = 'fa-solid fa-user-clock'
          this.header_background = 'n-bg-white'
          break;
        case 'JOBS':
          this.page_icon = 'fa-solid fa-tickets-perforated'
          this.header_background = 'n-bg-white'
          break;
        case 'PROFILE':
          this.page_icon = 'fa-solid fa-address-card'
          this.header_background = 'n-bg-white'
          break;
        case 'CALENDAR':
          this.page_icon = 'fa-solid fa-calendar-days'
          this.header_background = 'n-bg-white'
          break;
        case 'ADMIN':
          this.page_icon = 'fa-solid fa-gears'
          this.header_background = 'n-bg-admin'
          break;
        default:
          this.page_icon = 'fa-solid fa-rocket'
          this.header_background = 'n-bg-white'
          break;
      }

    });
  }

  page_header: string = 'DASHBOARD';
  header_background: string = 'n-bg-white';
  page_icon: string = 'fa-solid fa-chart-line';
  sub_page_header: string = '';
  display_sub_page: string = '';

  displaySubPage(page: string) {
    this.display_sub_page = page;
    this.sub_page_header = page.replace('-', ' ').toUpperCase();
  }
}
